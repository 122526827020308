import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  btnContainer: {
    width: '160px',
    fontSize: 12,
    fontWeight: 300,
    color: '#fff',
  },

  btn: {
    width: '95%',
    background: '#1D85D6 !important',
    borderColor: '#fff !important',
  },

  createFolderBtn: {
    width: '100%',
    margin: '1rem 0',
    fontSize: '11px',
  },

  toggleBtn: {
    minWidth: '0 !important',
    background: '#1D85D6 !important',
  },
  listRoot: {
    marginBottom: 8,
    [theme.breakpoints.up('xl')]: {
      marginBottom: 20,
    },
  },
}));
