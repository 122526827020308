import React, { FC } from 'react';
import {
  makeStyles,
  GridList,
  GridListTile,
  GridListTileBar,
} from '@material-ui/core';

import {
  getValue,
  API_URL,
  CONFIG_CURRENCY_SYMBOL,
} from '../../core/configProvider';
import useWidth from '../useWidth';
import { isEmpty } from '../../helper/data-helper';

interface PatternInfo {
  name: string;
  imagePath: string;
  imageFileName: string;
  price: string;
}

interface CategoryListMobileViewInterfaceProps {
  data: object;
  ids: number[];
  pattern: PatternInfo;
  onClick: Function;
}

const styles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    paddingTop: 5,
  },

  gridList: {
    height: '100%',
    width: '100%',
    alignContent: 'flex-start',
  },

  gridListRoot: {},

  gridListTile: {
    borderRadius: theme.shape.borderRadius,
  },

  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

const GridListTileView: FC<CategoryListMobileViewInterfaceProps> = props => {
  const { data, ids, pattern, onClick } = props;
  const classes = styles();
  const width = useWidth();
  const { name, imagePath, imageFileName, price } = pattern;
  const apiUrl = getValue(API_URL);
  const configCurrencySymbol = getValue(CONFIG_CURRENCY_SYMBOL);

  const gridListTileStyle = {
    root: classes.gridListRoot,
    tile: classes.gridListTile,
  };

  const gridListTileBarStyle = {
    root: classes.titleBar,
  };

  const handleClick = item => event => onClick(item);

  let cols = 2;
  let spacing = 8;
  switch (width) {
    case 'xs':
    case 'sm':
      cols = 2;
      break;
    case 'md':
      cols = 4;
      break;
    case 'lg':
      cols = 6;
      spacing = 20;
      break;
    case 'xl':
      cols = 8;
      spacing = 20;
      break;
  }

  return (
    <div className={classes.root}>
      <GridList
        cellHeight={180}
        spacing={spacing}
        className={classes.gridList}
        cols={cols}
      >
        {ids.map(id => {
          const item = data[id];
          return (
            <GridListTile
              key={id}
              classes={gridListTileStyle}
              onClick={handleClick(item)}
            >
              <img
                alt={item[name]}
                src={`${apiUrl}/${item[imagePath]}/thumbnail/${item[imageFileName]}`}
              />
              <GridListTileBar
                title={item[name]}
                classes={gridListTileBarStyle}
                subtitle={
                  !isEmpty(item[price]) ? (
                    <span>
                      {item[price]} {configCurrencySymbol}
                    </span>
                  ) : null
                }
              />
            </GridListTile>
          );
        })}
      </GridList>
    </div>
  );
};

export default GridListTileView;
