import moment from 'moment-jalaali';
import { SERVER_DATE_FORMAT } from '../core/configProvider';

export const jalaliDateTimeFormat = 'jYYYY-jMM-jDD HH:mm:ss';
export const gregorianDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export const jalaliDateFormat = 'jYYYY/jMM/jDD';
export const gregorianDateFormat = 'YYYY/MM/DD';

const list = {
  'wm/saleinvoice': {
    title: 'id',
    start: 'createdate',
    end: 'createdate',
    allDay: true,
  },
};

export const getCalendarGridInfo = resource => {
  if (typeof list[resource] === 'undefined') {
    throw new Error(`Resource "${resource}" is not defined!`);
  }

  return list[resource];
};

export const getCalendarDefaultFilter = (resource, isJalali) => {
  const { start } = getCalendarGridInfo(resource);

  const from = moment()
    .startOf(isJalali ? 'jMonth' : 'month')
    .add(-6, 'days') // make sure we cover dates from last month that are in gray visible area
    .locale('en')
    .format(SERVER_DATE_FORMAT);

  const until = moment()
    .endOf(isJalali ? 'jMonth' : 'month')
    .add(6, 'days') // make sure we cover dates from next month that are in gray visible area
    .locale('en')
    .format(SERVER_DATE_FORMAT);

  return {
    [start]: [start, 'between', from, until],
  };
};

export const getServerDateFormat = () => SERVER_DATE_FORMAT;

/**
 * Check `calendarType`.
 * @function isJalali
 * @param {string} calendarType
 * @returns {boolean}
 */
export const isJalali = calendarType => {
  return calendarType === 'jalali';
};
