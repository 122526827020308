import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import lodashDebounce from 'lodash/debounce';

import {
  ColumnsInterface,
  FormFieldInterface,
  GridPropsInterface,
  GroupColumnsInterface,
  LastFocusCell,
  onChangeInputParameter,
  OnEditingStartInterface,
  TotalSummaryItemsInterface,
} from './grid.type';
import GridView from './grid.view';
import {
  COLOR_FIELD,
  DROPDOWN_FIELD,
  getTypeByField,
  ICON_FIELD,
  inputTypes,
} from '../../helper/InputHelper';
import lodashGet from 'lodash/get';
import { useLocale, useTranslate } from 'react-admin';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
  FormKeyMode,
  URLInfo,
} from '../../type/actor-setup';
import { FieldType, MetaDataBase } from '../../helper/Types';
import { clone, isEmpty, isEmptyObject } from '../../helper/data-helper';
import LoadingBox from '../LoadingBox';
import { getAppSettings, setAppSettings } from '../../helper/settings-helper';
import {
  CONFIG_CELL_WIDTH,
  CONFIG_LIST_COLUMN_WIDTH,
  CONFIG_GROUP_COLUMN,
  getValue,
} from '../../core/configProvider';
import lodashMap from 'lodash/map';
import { DataGrid } from 'devextreme-react';
import { isGridInlineEditable } from '../../helper/MetaHelper';
import {
  extractAllFieldsFromTabList,
  getFormDefaultValues,
} from '../form/form.helper';
import lodashFilter from 'lodash/filter';
import { handleInlineEdit } from '../../api/grid-api';
import lodashFind from 'lodash/find';
import { findRowStateColorKey, getColorById } from '../../helper/RowColorHelper';
import { useStyles } from './grid.style';
import { runServiceValidationClient } from '../../helper/validation-helper';

import { showNotification } from '../../helper/general-function-helper';
import { useFormSave } from '../form/hooks/use-form-save';
import lodashMerge from 'lodash/merge';
import { isRelationCellEditableWithoutTypeChecking } from '../../helper/RelationHelper';
import { formatNumber } from '../../helper/NumberHelper';
import { wrapNegativeNumberWithParentheses } from '../dynamic-input/number-input/number-input.helper';
import { prepareViewFields } from '../show-record-with-relation/show-record-with-relation.helper';
import dxDataGrid from 'devextreme/ui/data_grid';

let formAsyncActionList: Promise<unknown>[] = [];
const GridController: FC<GridPropsInterface> = memo(props => {
  const locale = useLocale();

  const {
    resource,
    setListSelectedIds,
    onSelectCheckbox,
    parentInfo,
    isReport,
    relation,
    parentRecord,
    setSort,
    fields,
    hasEdit,
    metaData,
    onRowClick,
    relationMode,
    showImageDialog,
    addToFilterRequestList,
    hasShow,
    basePath,
    redirect,
    hasCreate,
    ids,
    data,
    relationResource,
    quickEditButton,
    setFilters,
    enableSelection = true,
    isTopFilterOpen,
    filterValues,
    idDropDown = null,
    selectedRows,
    sort,
    onQuickEditCellSuccess,
    enableClientExportExcel,
    isGroupingOpen = false,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();

  const [forceRefresh, setForceRefresh] = useState(false);

  const [columnWidthSetting, setColumnWidthSetting] = useState<Record<
    string,
    unknown
  > | null>(null);
  const groupColumns = getAppSettings(`${CONFIG_GROUP_COLUMN}_${resource}`, true)
    ?.value as Array<GroupColumnsInterface>;
  const [formMode, setFormMode] = useState<'edit' | 'add'>('edit');

  const gridRef = useRef<DataGrid>(null);
  const preparedFieldsRef = useRef<FormFieldInterface>({
    allFields: [],
    relationHiddenFields: null,
    disabledFields: null,
  });
  const defaultFormValuesRef = useRef<Record<string, unknown>>({});
  const lastSearchedDropDownData = useRef<Record<string, unknown>[]>([{}]);
  const lastFocusCellRef = useRef<LastFocusCell>({});

  const [forceIsTopFilterOpen, setForceIsTopFilterOpen] = useState<boolean>(false);
  const formSave = useFormSave('gridForm');

  const preparedIdsFromData = data?.map?.(item => item?.id);
  const summaryData = actorGetActionValue('additionalData', resource)?.summary;

  useEffect(() => {
    actorSetActionValue('gridIDs', ids ?? preparedIdsFromData, {
      path: `${props.resource}.allIDs`,
    });
    selectRowsByDebounce(selectedRows);
  }, [ids, preparedIdsFromData]);

  /**
   * @function selectRowsByDebounce
   * @returns { void }
   */
  const selectRowsByDebounce = useCallback(
    lodashDebounce((selectedIds: number[]) => {
      gridRef.current?.instance.selectRows(selectedIds, false);
    }, 1000),
    [],
  );

  useEffect(() => {
    //show loading
    showLoadPanel();

    const columnWidthSetting =
      getAppSettings<Record<string, unknown>>(getConfigColumnWidthKey(), true)
        .value ?? {};

    setColumnWidthSetting(columnWidthSetting);
  }, [resource, relationResource, idDropDown]);

  useEffect(() => {
    prepareAllFields();
    actorOnDispatch('isTopFilterOpen', response => {
      setForceIsTopFilterOpen(response?.[relationResource as string]);
    });

    //click on process button should close grid form
    actorOnDispatch('loading', loadingList => {
      if (loadingList?.processChangeLineButtons) {
        prepareAllFields(); //get allFields again ,when process changed;
        setFormMode('edit');
      }
    });

    //click on process button should close grid form
    actorOnDispatch('quickDialog', () => {
      setFormMode('edit');
    });
  }, []);

  /**
   * @function showLoadPanel
   * @returns {void}
   */
  const showLoadPanel = (): void => {
    if (formMode == 'add' || formMode == 'edit') {
      actorOnDispatch('loading', detail => {
        if (detail?.[resource as string]) {
          gridRef.current?.instance.beginCustomLoading(
            translate('form.sendingData'),
          );
        } else {
          gridRef.current?.instance.endCustomLoading();
        }
      });
    }
  };

  /**
   * @function allowAddInGrid
   * @returns {boolean}
   */
  const allowAddInGrid = (): boolean => {
    //allow add in relation panel
    const allowAddInGrid = lodashGet(metaData, ['config', 'allowAddInGrid']);
    return hasCreate && !!allowAddInGrid && !isReport && !isEmptyObject(parentInfo);
  };

  /**
   * get all fields from meta
   * @function prepareAllFields
   * @returns {void}
   */
  const prepareAllFields = async (): Promise<void> => {
    if (!allowAddInGrid() && !isGridInlineEditable(metaData)) {
      return;
    }

    const record = actorGetActionValue(
      'record',
      `${resource}.${FormKeyMode.ROOT}`,
    )! as {
      FORM?: Record<string, unknown>;
      PARENT_RECORD?: Record<string, unknown>;
      FULL?: Record<string, unknown>;
    };

    const tabList = prepareViewFields(record?.FORM ?? {}, metaData, resource);

    const allFields: Array<FieldType> = extractAllFieldsFromTabList(tabList);

    preparedFieldsRef.current = { ...preparedFieldsRef.current, allFields };
  };

  /**
   * @function allowEditInline
   * @param {FieldType} field
   * @returns {boolean}
   */
  const allowEditInline = (field: FieldType): boolean => {
    //show disable field value
    if (formMode == 'add') return true;

    //boolean field handled in handleInlineEdit
    //disable inline edit in dropdown popup
    if (
      !isEmpty(idDropDown) ||
      getTypeByField(field) == inputTypes.BOOLEAN_FIELD ||
      isReport ||
      !hasEdit
    )
      return false;

    return (
      isRelationCellEditableWithoutTypeChecking(field, metaData, hasEdit) &&
      isEnableInput(field.name)
    );
  };

  /**
   * get default value for add in grid
   * @function prepareDefaultValues
   * @returns {Promise<void>}
   */
  const prepareDefaultValues = async (): Promise<void> => {
    if (!isEmptyObject(defaultFormValuesRef.current)) {
      const gridFormData = actorGetActionValue('gridFormData')!;
      actorDispatch(
        'gridFormData',
        lodashMerge(gridFormData, clone(defaultFormValuesRef.current)),
        { replaceAll: true },
      );
      return;
    }

    const globalParameters = actorGetActionValue('globalParameters');
    const urlInfo = actorGetActionValue('urlInfo');
    const currentResource = { value: resource, type: FormKeyMode.RELATION };

    const formDefaultValues = await getFormDefaultValues(
      urlInfo as URLInfo,
      parentInfo,
      preparedFieldsRef.current.allFields,
      globalParameters,
      currentResource,
      true,
    );

    if (formDefaultValues) {
      defaultFormValuesRef.current = clone(formDefaultValues);

      // fixme : here additional data it not related to form
      const relationHiddenFields = actorGetActionValue(
        'recordAdditionalData',
        `${resource}.hidden`,
      ) as unknown as Record<string, boolean>;

      const disabledFields = actorGetActionValue(
        'recordAdditionalData',
        `${resource}.disabled`,
      ) as unknown as Record<string, boolean>;

      preparedFieldsRef.current = {
        ...preparedFieldsRef.current,
        relationHiddenFields,
        disabledFields,
      };
      const gridFormData = actorGetActionValue('gridFormData')!;
      actorDispatch(
        'gridFormData',
        lodashMerge(gridFormData, clone(formDefaultValues)),
        { replaceAll: true },
      );
    }
  };

  /**
   * @function onEditingStart
   * @param { OnEditingStartInterface } event
   * @returns { void } void
   */
  const onEditingStart = (event: OnEditingStartInterface): void => {
    if (formMode == 'add') return;

    if (!isEmptyObject(event.data)) {
      defaultFormValuesRef.current = {}; //dont use default value in edit mode

      actorDispatch('gridFormData', event.data, {
        replaceAll: true,
      });
    } else {
      actorDispatch(
        'gridFormData',
        {},
        {
          replaceAll: true,
        },
      );
      gridRef.current?.instance.cancelEditData();
      if (formMode != 'edit') {
        setFormMode('edit');
      }
    }
  };

  /**
   * @function onInitNewRow
   * @returns {void}
   */
  const onInitNewRow = (): void => {
    // fixme: this function will be called by DataGrid twice
    actorDispatch(
      'gridFormData',
      {},
      {
        replaceAll: true,
      },
    );

    prepareAllFields().then(() => {
      prepareDefaultValues().then(() => {
        if (formMode != 'add') {
          setFormMode('add');
          setTimeout(() => {
            if (!gridRef.current?.instance.hasEditData()) {
              gridRef.current?.instance.addRow();
            }
          }, 500);
        }
      });
    });
  };

  /**
   * @function onSuccessSaveForm
   * @returns {void}
   */
  const onSuccessSaveCreateForm = (response): void => {
    gridRef.current?.instance.endCustomLoading();

    const dataSource = gridRef.current?.instance.getDataSource();
    const store = dataSource?.store();

    store.insert(response.data).then(
      () => {
        dataSource?.reload();
      },
      error => {
        console.log('inline update error: ', error);
      },
    );
    const rows = getPreparedRows();

    if (rows.length === 1) {
      setForceRefresh(prev => !prev);
    }

    actorDispatch('gridFormData', clone(defaultFormValuesRef.current), {
      replaceAll: true,
    });
    actorSetActionValue('loading', false, { path: resource });
  };

  /**
   * @function onFailureSaveForm
   * @param {string | null} error
   * @returns {void}
   */
  const onFailureSaveForm = (error: string): void => {
    actorSetActionValue('loading', false, { path: resource });

    //Don't set "error" type in showNotification, because focus on cell not working
    if (typeof error == 'string') {
      if (typeof error?.split == 'function') showNotification(error?.split('^')[0]);
      else showNotification(error);
    }

    if (formMode != 'add') {
      const gridInstance = gridRef.current?.instance;
      setTimeout(() => {
        gridInstance?.editCell(
          lastFocusCellRef.current?.rowIndex as number,
          lastFocusCellRef.current?.columnIndex as number,
        );
      }, 50);
    }
    gridRef.current?.instance.endCustomLoading();
  };

  /**
   * @function saveRow
   * @param {Record<string, any>} e
   * @returns {Promise<void>}
   */
  const saveRow = async (e: Record<string, any>): Promise<void> => {
    if (formMode != 'add' || actorGetActionValue('loading', resource)) {
      return;
    }
    actorSetActionValue('loading', true, { path: resource });
    e.cancel = true; //don't close form

    //run service validation
    const gridInstance = gridRef.current?.instance;
    if (gridInstance) {
      gridInstance.focus(gridInstance.getCellElement(0, 1) as Element);
    }

    const { parentFieldName, childFieldName } = relation;

    let quickCreateData = {};
    if (!isEmpty(childFieldName)) {
      quickCreateData = {
        [childFieldName!]: lodashGet(parentRecord, parentFieldName),
      };
    }

    try {
      await Promise.all(formAsyncActionList);
      formAsyncActionList = [];
    } catch (error) {
      console.error('saving error => ', error);
    }

    const gridFormData = actorGetActionValue('gridFormData')!;
    formSave(gridFormData, {
      id: null,
      isCreateMode: true,
      additionalFormData: quickCreateData,
      relationMode,
      formName: 'gridForm',
      resource: resource,
      onFailure: onFailureSaveForm,
      onSuccess: onSuccessSaveCreateForm,
    });
  };

  /**
   * editingOnChangesChange
   * @function editCellValue
   * @param { string } fieldName
   * @returns { void }void
   */
  const editCellValue = (fieldName: string): void => {
    const gridFormData = actorGetActionValue('gridFormData')!;
    if (!isEmptyObject(gridFormData)) {
      const changedData = gridFormData[fieldName];
      const filedObject = getFieldObjectByInputName(fieldName);
      if (!filedObject) return;

      const rowInfo = { id: gridFormData.id };
      const formData = { [filedObject.name]: changedData };

      if (filedObject) {
        if (getTypeByField(filedObject) === DROPDOWN_FIELD) {
          const selectedDropDownInfo = lodashFilter(
            lastSearchedDropDownData.current[filedObject.name]?.result,
            formData,
          );
          rowInfo[`__dropdownvalue_${filedObject.name}`] = selectedDropDownInfo[0];
        }
        handleInlineEdit(
          formData,
          rowInfo,
          resource,
          onFailureSaveForm,
          onSuccessInlineEditForm,
        );
      }
    }
  };

  /**
   * @function getConfigColumnWidthKey
   * @returns {string}
   */
  const getConfigColumnWidthKey = (): string => {
    let key = `${CONFIG_LIST_COLUMN_WIDTH}_`;
    key += relationMode ? relationResource : resource;

    if (idDropDown) {
      key += '_dropDown_' + idDropDown;
    }
    return key;
  };

  /**
   * @function getPreparedRows : prepare rows for grid
   * @returns { Record<string, unknown>[]}
   */
  const getPreparedRows = (): Record<string, unknown>[] => {
    return ids ? Object.values(data) : (data as Array<Record<string, unknown>>);
  };

  /**
   * getColumnWidth
   * @param {FieldType} field
   * @returns {number}
   */
  const getColumnWidth = (field: FieldType): number => {
    const defaultCellWidth = getValue(CONFIG_CELL_WIDTH);

    if (isEmptyObject(columnWidthSetting)) {
      return !isEmpty(field.width) ? field.width : defaultCellWidth;
    }

    if (!isEmpty(columnWidthSetting![field.id])) {
      return columnWidthSetting![field.id] ?? defaultCellWidth;
    }

    if (!isEmpty(columnWidthSetting![field.name])) {
      return columnWidthSetting![field.name] ?? defaultCellWidth;
    }

    return defaultCellWidth;
  };

  /**
   * getColumns
   * @returns {ColumnsInterface[]}
   */
  const getColumns = (): ColumnsInterface[] => {
    const preparedColumns: ColumnsInterface[] = [];
    const statusFields: FieldType[] = [];

    fields.forEach(field => {
      // "rowstatecolor" contains only color info for row
      if (!field || (field && field.name === 'rowstatecolor')) {
        return;
      }
      if (
        getTypeByField(field) === COLOR_FIELD ||
        getTypeByField(field) === ICON_FIELD
      ) {
        statusFields.push(field);
        return;
      }

      field.width = getColumnWidth(field);
      preparedColumns.push({
        field, // to not find field again from list of fields
        name: field.relatedName, // use relatedName for all fields
        title: lodashGet(field, ['translatedCaption', locale], field.caption),
        columnName: field.relatedName, // used by filter
      });
    });
    if (statusFields.length) {
      preparedColumns.push({
        name: 'statusFields',
        title: ' ',
        fields: statusFields,
      });
    }

    return preparedColumns;
  };

  /**
   * getTotalSummaryItems
   * @returns {TotalSummaryItemsInterface[]}
   */
  const getTotalSummaryItems = (): TotalSummaryItemsInterface[] => {
    const tempTotalSummaryItems: TotalSummaryItemsInterface[] = [];
    fields.forEach((field: FieldType) => {
      if (!field) {
        return;
      }

      if (field.hasSummary) {
        tempTotalSummaryItems.push({
          columnName: String(field.name),
          type: 'sum',
          format: field.format,
        });
      }
    });
    return tempTotalSummaryItems;
  };

  /**
   * getFieldObjectByInputName
   * @param inputName
   * @returns any
   */
  const getFieldObjectByInputName = (inputName: string): FieldType | null => {
    const { allFields, relationHiddenFields } = preparedFieldsRef.current;
    const field = lodashFilter(
      allFields,
      row => row.name == inputName || row.relatedName == inputName,
    )[0];

    if (!isEmptyObject(field)) {
      const isHiddenField = lodashGet(relationHiddenFields, field.name);
      return isHiddenField ? null : field;
    }

    return null;
  };

  /**
   * changeCheckboxSelection
   * @param checkBoxSelectedInfo
   * @returns {TotalSummaryItemsInterface[]}
   */
  const changeCheckboxSelection = (checkBoxSelectedInfo): void => {
    const { selectedRowKeys, currentDeselectedRowKeys } = checkBoxSelectedInfo;

    actorSetActionValue('gridIDs', selectedRowKeys, {
      path: `${resource}.selectedIDs`,
    });

    if (onSelectCheckbox && typeof onSelectCheckbox === 'function') {
      onSelectCheckbox(selectedRowKeys, currentDeselectedRowKeys);
    }
  };

  /**
   * setGridSetting
   * @param {Record<string, number>} columnWidthList
   * @returns {void}
   */
  const setGridSetting = (columnWidthList: Record<string, number>): void => {
    const key = getConfigColumnWidthKey();

    setAppSettings({
      key,
      value: columnWidthList,
      forUser: true,
    });
  };
  /**
   * @function setGroupGridSetting
   * @param groupColumns : group columns
   * @returns {void}
   */
  const setGroupGridSetting = (groupColumns: GroupColumnsInterface[]): void => {
    setAppSettings({
      key: `${CONFIG_GROUP_COLUMN}_${resource}`,
      value: groupColumns,
      forUser: true,
    });
  };

  /**
   * getGridClass
   * @returns any
   */
  const getGridClass = (): string => {
    const rows = getPreparedRows();

    if (relationMode) {
      if (rows?.length == 0) {
        return classes.emptyRelationGrid;
      }
      return classes.relationGrid;
    } else {
      return classes.grid;
    }
  };

  /**
   * @function setRowColor
   * @param {any} e
   * @returns {void} void
   */
  const setRowColor = (e: any): void => {
    if (e.rowType != 'data') return;

    const rowStateColorKey = findRowStateColorKey(
      lodashGet(metaData, 'fields', lodashGet(metaData, 'columns')),
    );

    const colorValue = e.data?.[rowStateColorKey];
    const isHexColor = /^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$/i.test(colorValue);
    const isValidColor =
      (!Number.isNaN(colorValue) && colorValue >= 0) || isHexColor;

    if (rowStateColorKey && e.rowType === 'data' && isValidColor) {
      const customRowColor = isHexColor ? colorValue : getColorById(colorValue);

      if (customRowColor !== null) {
        e.rowElement.style.backgroundColor = customRowColor;
        e.rowElement.setAttribute('class', 'dx-row dx-data-row');
        //if we dont set this, the row get zebra style(rowAlternationEnabled property)
      }
    }
    e.rowElement.setAttribute('data-test-grid-row', `${e.data?.id}`);
    e.rowElement.setAttribute('data-style-grid-row', 'gridRow');
  };

  /**
   * @function onCellClick
   * @param {any} e
   * @returns {void} void
   */
  const onCellClick = (e: any): void => {
    if (e.rowType == 'header' && e.column?.command !== 'select') {
      changeSorting(e);
    }
  };

  /**
   * @function changeSorting
   * @param {Record<string, any>} element
   * @returns {void}
   */
  const changeSorting = (element: Record<string, any>): void => {
    let direction = element.column.sortOrder;
    if (direction == undefined || direction == 'undefined') {
      direction = 'desc';
    }
    const columnName = element.column.name;
    setSort?.(columnName, direction);
  };
  /**
   * @function getGridGroupItems
   * @param grid
   * @returns object[]
   * help: https://supportcenter.devexpress.com/ticket/details/t823647/datagrid-get-current-grouping-columns
   */
  const getGridGroupItems = (grid: dxDataGrid): GroupColumnsInterface[] => {
    const columnsOptions: GroupColumnsInterface[] = [];
    const count = grid.columnCount();
    for (let index = 0; index < count; index++) {
      const columnOptions = grid.columnOption(index);
      if (columnOptions.groupIndex !== undefined) {
        columnsOptions.push({
          columnName: columnOptions.name,
          groupIndex: columnOptions.groupIndex,
        });
      }
    }

    return columnsOptions;
  };
  /**
   * onOptionChanged
   * @param e
   * @returns {void}
   */
  const onOptionChanged = lodashDebounce((e: any): void => {
    if (e && e.fullName && e.fullName.indexOf('width') != -1) {
      const columns = getColumns();
      const visibleColumns = e.component?.getVisibleColumns();

      const columnWidthList: Record<string, number> = {};
      lodashMap(visibleColumns, gridColumn => {
        const columnInfo = lodashFind(columns, ['name', gridColumn.name]);
        if (!isEmptyObject(columnInfo)) {
          const columnKey = columnInfo.field.id
            ? columnInfo.field.id
            : gridColumn.name;
          columnWidthList[columnKey] =
            gridColumn.width > 500 ? 500 : gridColumn.width;
        }
      });

      setGridSetting(columnWidthList);
    }
    if (e && e.fullName && e.fullName.indexOf('groupIndex') != -1) {
      setGroupGridSetting(getGridGroupItems(e.component));
    }
    if (
      typeof setFilters == 'function' &&
      e &&
      e.fullName &&
      e.fullName.indexOf('filterValue') !== -1
    ) {
      let combinedFilter = e.component?.getCombinedFilter(true);

      if (combinedFilter?.length > 0) {
        combinedFilter = Array.isArray(combinedFilter[0])
          ? combinedFilter
          : [combinedFilter];

        const filters = {};
        lodashMap(combinedFilter, item => {
          if (Array.isArray(item)) {
            filters[item[0]] = item[2];
          }
        });
        setFilters(filters);
      } else {
        setFilters([]);
      }
    }
  }, 200);

  /**
   * @function runServiceValidation
   * @param { string } fieldName
   * @returns { Promise<void> } Promise<void>
   */
  const runServiceValidation = async (fieldName: string): Promise<void> => {
    const gridFormData = actorGetActionValue('gridFormData')!;
    await runServiceValidationClient(
      preparedFieldsRef.current.allFields,
      fieldName,
      gridFormData,
      locale,
      metaData as MetaDataBase,
      resource,
      relationResource,
      (fieldName, value) => (gridFormData[fieldName] = value), //update form data
      {},
      translate,
      true,
      showNotification,
      false,
    ).then(() => {
      actorDispatch('gridFormData', gridFormData, { replaceAll: true });
    });
  };

  /**
   * @function focusOnLastInput
   * @return { void } void
   */
  const focusOnLastInput = (): void => {
    const gridInstance = gridRef.current?.instance;

    if (!gridInstance) return;

    const cellElement = gridInstance.getCellElement(
      lastFocusCellRef.current?.rowIndex as number,
      lastFocusCellRef.current?.columnIndex as number,
    );

    if (cellElement) {
      gridInstance.focus(cellElement);
    }
  };

  /**
   * @function onChangeInput
   * @param { string } fieldName string
   * @param { unknown } value Can be any type, boolean, string, array, etc...
   * @param { boolean } submitValue
   * @returns { Promise<void> } Promise<void>
   */
  const onChangeInput = async (
    parameters: onChangeInputParameter,
  ): Promise<void> => {
    const { fieldName, value, submitValue = true } = parameters;

    let gridFormData = actorGetActionValue('gridFormData')!;

    if (value == gridFormData[fieldName]) return; //if value not changed, return

    try {
      const field = getFieldObjectByInputName(fieldName);
      if (field) {
        gridFormData = { ...gridFormData, [fieldName]: value };
        actorSetActionValue('gridFormData', gridFormData);

        if (submitValue) {
          formMode == 'add' &&
            formAsyncActionList.push(runServiceValidation(fieldName));
          formMode == 'edit' && editCellValue(fieldName); //don't need to run service in inline edit
        }
      }
    } catch (error) {
      console.log('changeFormValueWithValidate failed: ', error);
    }
  };

  /**
   * @function isEnableInput
   * @param {string} fieldName string
   * @returns {boolean} boolean
   */
  const isEnableInput = (fieldName: string): boolean => {
    const field = getFieldObjectByInputName(fieldName);

    //disable grid form in dropdown popup
    if (
      !field ||
      field.disabled ||
      field.hidden ||
      !isEmpty(idDropDown) ||
      isReport ||
      !hasEdit
    ) {
      return false;
    }

    const existInDisableList = Boolean(
      preparedFieldsRef.current.disabledFields?.[field.name],
    );

    const existInHiddenList = Boolean(
      preparedFieldsRef.current.relationHiddenFields?.[field.name],
    );

    return !existInDisableList && !existInHiddenList;
  };

  /**
   * @function onEditCanceled
   * @returns {void} void
   */
  const onEditCanceled = (): void => {
    actorDispatch(
      'gridFormData',
      {},
      {
        replaceAll: true,
      },
    );
    if (formMode != 'edit') setFormMode('edit');
  };

  /**
   * onEditorPreparing
   * @param e
   * @returns {void}
   */
  const onEditorPreparing = (e: Record<string, any>): void => {
    //set default text field for filter row
    if (e.parentType === 'filterRow') {
      e.editorName = 'dxTextBox';
      return;
    }
  };

  /**
   * save grid data
   * @param data
   * @returns {void}
   */
  const onSaving = (event: Record<string, any>): void => {
    if (formMode == 'add') {
      event.cancel = true;
      saveRow(event);
      lastFocusCellRef.current = {};
    }
  };

  /**
   * @function onSuccessInlineEditForm
   * @param {Record<string, unknown>} editedData
   * @returns {void}
   */
  const onSuccessInlineEditForm = (editedData: Record<string, unknown>): void => {
    const dataSource = gridRef.current?.instance.getDataSource();
    const store = dataSource?.store();

    store.update(editedData.id, editedData).then(
      () => {
        dataSource?.reload();
      },
      error => {
        console.log('inline update error: ', error);
      },
    );
  };

  /**
   * @function formatTotalSummaryCell
   * @param {string} columnName
   * @returns {string}
   */
  const formatTotalSummaryCell = (columnName: string): string => {
    const summaryValue = lodashGet(summaryData, columnName, 0); //typescript error
    return String(wrapNegativeNumberWithParentheses(summaryValue?.toFixed(2), true));
  };

  /**
   * @function handleOnRowClick
   * @param {Record<string, any>} data
   * @returns {void}
   */
  const handleOnRowClick = (data: Record<string, unknown>): void => {
    if (typeof onRowClick == 'function') {
      return onRowClick(data);
    }
  };

  /**
   * onOptionChanged
   * @param e
   * @returns {void}
   */
  const onCellPrepared = (event: Record<string, any>): void => {
    if (event.data && event.rowType === 'data' && event.column.name) {
      const colorColumnName = `__${event.column.name}_backgroundcolor`;
      if (event.data[colorColumnName] && event.data[colorColumnName] != '')
        event.cellElement.style.backgroundColor = event.data[colorColumnName];
    }
  };

  if (columnWidthSetting == null) {
    return <LoadingBox />;
  }

  return (
    <GridView
      fields={fields}
      rows={getPreparedRows()}
      columns={getColumns()}
      totalSummaryItems={getTotalSummaryItems()}
      parentInfo={parentInfo}
      changeCheckboxSelection={changeCheckboxSelection}
      resource={resource}
      hasEdit={hasEdit}
      metaData={metaData}
      relationMode={relationMode}
      showImageDialog={showImageDialog}
      addToFilterRequestList={addToFilterRequestList}
      hasShow={hasShow}
      redirect={redirect}
      basePath={basePath}
      key={resource}
      sort={sort}
      relationResource={relationResource}
      quickEditButton={quickEditButton}
      enableSelection={enableSelection}
      isTopFilterOpen={isTopFilterOpen || forceIsTopFilterOpen} //all component should be move to actor
      filterValues={filterValues}
      gridRef={gridRef}
      formMode={formMode}
      onEditingStart={onEditingStart}
      onInitNewRow={onInitNewRow}
      getGridClass={getGridClass}
      onCellClick={onCellClick}
      setRowColor={setRowColor}
      onOptionChanged={onOptionChanged}
      allowAddInGrid={allowAddInGrid}
      allowEditInline={allowEditInline}
      onChangeInput={onChangeInput}
      onEditCanceled={onEditCanceled}
      lastFocusCell={lastFocusCellRef}
      onEditorPreparing={onEditorPreparing}
      onSaving={onSaving}
      formatTotalSummaryCell={formatTotalSummaryCell}
      handleOnRowClick={handleOnRowClick}
      onCellPrepared={onCellPrepared}
      enableClientExportExcel={enableClientExportExcel}
      isGroupingOpen={isGroupingOpen}
      groupColumns={groupColumns}
      locale={locale}
      onFailureSaveForm={onFailureSaveForm}
      isEnableInput={isEnableInput}
      focusOnLastInput={focusOnLastInput}
    />
  );
});

export default GridController;
