import React, { FC, useState } from 'react';
import { useTranslate, useLocale } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import lodashGet from 'lodash/get';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Radio,
  Switch,
  DialogActions,
  Button,
} from '@material-ui/core';

import { isEmpty, isEmptyObject } from '../../helper/data-helper';
import { CustomTheme } from '../../core/themeProvider';
import { FieldType } from '../../helper/Types';

interface CardListFilterDialogProps {
  open: boolean;
  onClose: (name: string) => void;
  filterFieldList: Partial<FieldType>[];
  sortFieldList: Partial<FieldType>[];
  setFilters: (filter: object) => void;
  filterValues: any;
  setSort: (name: string) => void;
  currentSort: {
    field: string;
    order: string;
  };
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  title: {
    paddingBottom: 10,
  },

  listItemRoot: {
    marginBottom: 20,
  },

  listItemIconRoot: {
    minWidth: 'auto',
  },

  button: {
    color: theme.palette.primary.appSecondaryTextColor,
  },
}));

const CardListFilterDialog: FC<CardListFilterDialogProps> = props => {
  const {
    setFilters,
    onClose,
    open,
    filterValues,
    filterFieldList,
    sortFieldList,
    currentSort,
    setSort,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();
  const locale = useLocale();

  const [sortField, setSortField] = useState(currentSort.field);
  const [filterField, setFilterField] = useState(filterValues);

  const handleSetFilter = fieldName => event => {
    setFilterField({ ...filterField, [fieldName]: event.target.checked });
  };

  const handleSetSort = fieldName => event => {
    setSortField(fieldName);
  };

  const handleConfirmButton = () => {
    if (!isEmpty(sortField)) {
      setSort(sortField);
    }

    if (!isEmptyObject(filterField)) {
      setFilters(filterField);
    }
    onClose('openDialogFilter');
  };

  const customClassesListItem = {
    root: classes.listItemRoot,
  };

  const customClassesListItemIcon = {
    root: classes.listItemIconRoot,
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose('openDialogFilter')}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent>
        {open && (
          <List>
            <Typography className={classes.title} variant="body2">
              {translate('sellin.inventory')}
            </Typography>
            {filterFieldList.map(field => {
              return (
                <ListItem
                  classes={customClassesListItem}
                  key={field.id}
                  role={undefined}
                  disableGutters
                  button
                  dense
                >
                  <ListItemIcon classes={customClassesListItemIcon}>
                    <Switch
                      checked={lodashGet(filterField, field.name)}
                      onChange={handleSetFilter(field.name)}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="caption">
                        {lodashGet(field, ['translatedCaption', locale], field.name)}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
            <Typography className={classes.title} variant="body2">
              {translate('sellin.ordering')}
            </Typography>
            {sortFieldList.map((field, index) => {
              return (
                <ListItem
                  key={`${field.name}-${index}`}
                  role={undefined}
                  onClick={handleSetSort(field.name)}
                  disableGutters
                  button
                  dense
                >
                  <ListItemIcon classes={customClassesListItemIcon}>
                    <Radio checked={sortField === field.name} disableRipple />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="caption">
                        {lodashGet(field, ['translatedCaption', locale], field.name)}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose('openDialogFilter')} color="secondary">
          {translate('ra.action.cancel')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleConfirmButton}
          className={classes.button}
        >
          {translate('ra.action.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CardListFilterDialog;
