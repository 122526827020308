import { ReferenceResponse } from './mail-detail/mail-detail-header';

export enum DocTypeEnum {
  inmail = 1,
  outmail = 2,
  innermail = 3,
  tasks = 4,
  message = 5,
}

export enum FolderTypeEnum {
  inbox = 1,
  outbox = 2,
  draft = 3,
}

export interface MailFolderInterface {
  folders_id: number;
  folderstitle: string;
  parentfolders_id: number | null;
  personinfo_id: number;
  uniqueid: string;
  treetitle: string;
  itemcount: number;
  unreadcount?: number;
}

export interface ReferencesDataInterface {
  messagereferences_id: number;
  createdate: string;
  frompersoninfo_id: number;
  fromcontactinfo: string;
  frompersonimage: string;
  contactkindstitle: string;
  messageparaph: string;
  tocontactinfo: string;
  topersonimage: string;
  isunread: true;
  seendate: string;
  referdate: string;
  inboxresidencemin: number;
  fromreference_id: number | null;
  colorcode: string;
  messages_id: number;
  doctype_id: number;
}

export interface SelectedMailInterface {
  doctype_id: number;
  doc_id: number;
  refrences_id: number;
  uniqueid: string;
  createdate: string;
  messageparaph: string | null;
  subject: string;
  messagebody: string;
  paraphtext: string;
  contactinfo: string;
  createuserid: number;
  fromperson: string;
  frompersoninfo_id: number;
  personimage: string;
  personinfo_id: number;
  topersoninfo_id: string | null;
  ccpersoninfo_id: string | null;
  bccpersoninfo_id: string | null;
  deadlinedate: string | null;
  isflag: number;
  attachfiles: string;
  hasattachment: number;
  isarchived: boolean;
  isimportant: number;
  isstared: boolean;
  isunread: boolean;
  isrecallactive: boolean;
  isreplyactive: boolean;
  labelsarray: string;
  fromcontactimage: string | null;
  fromcontactinfo: string | null;
  tocontactinfo: string | null;
  referdate: string;
  flagreminderactive: boolean;
  flagstartdate: string | null;
  flagduedate: string | null;
  reminddatetime: string | null;
  delegation_id: number | null;
  delegationlink: number | null;
  __bccpersoninfo_id_value: string;
  __ccpersoninfo_id_value: string;
  __doc_id_link: string;
  __topersoninfo_id_value: string;
  __referbccpersoninfo_id_value: string | null;
  __referccpersoninfo_id_value: string | null;
  __refertopersoninfo_id_value: string | null;
  replyall_to_id: string | null;
  replyall_cc_id: string | null;
  replyall_cc_value: string | null;
  replyall_to_value: string | null;
}

export interface MailInterface {
  abstract: string;
  contactinfo: string;
  contactkindstitle: string;
  createdate: string;
  createuserid: number;
  deadlinedate: string | null;
  doc_id: number;
  docdate: string;
  docno: number;
  doctype_id: number;
  folderstitle: string | null;
  hasattachment: boolean;
  immediacytitle?: string;
  isarchived: boolean;
  isimportant: number;
  isflag: boolean;
  isremider: boolean;
  isstared: number;
  isunread: boolean;
  labelsarray: string;
  lablecolor1?: string;
  lablecolor2?: string;
  lablecolor3?: string;
  lettertype: string;
  personinfo_id: number;
  referdate: string;
  refrences_id: number;
  toperson: string;
  uniqueid: string;
  __doc_id_link: string;
}

export type MailLabelType = {
  colorcode: string;
  lables_id: number;
  lablestitle: string;
  parentlables_id: number | null;
  personinfo_id: number;
  uniqueid: string;
};

export type MailActionsHandler = (type: string, payload?: unknown) => void;

export interface StyleProps {
  isNewMessageFormFullScreen: boolean;
}

export enum MailActions {
  OnRefreshMailData = 'REFRESH_MAIL_DATA',
  onRefreshMailDataDetail = 'REFRESH_MAIL_DATA_DETAIL',
  onSearchMailData = 'SEARCH_MAIL_DATA',
  onChangeCheckedMails = 'CHANGE_CHECKED_MAILS',
  onHandleMasterCheckbox = 'HANDLE_MASTER_CHECKBOX',
  onChangePageHandler = 'CHANGE_PAGE_HANDLER',
  onStarMail = 'STAR_MAIL',
  onImportantMail = 'IMPORTANT_MAIL',
  onMarkUnRead = 'MARK_AS_UNREAD',
  onShowDetail = 'SHOW_DETAIL',
  onCompose = 'COMPOSE',
  onOpenReferenceForm = 'OPEN_REFERENCE_FORM',
  onOpenNewFolderForm = 'OPEN_NEW_FOLDER_FORM',
  onReference = 'REFERENCE',
  onArchive = 'ARCHIVE',
  onUnArchive = 'UNARCHIVE',
  onSubmitLabels = 'SUBMIT_LABELS',
  onCreateNewLabel = 'CREATE_NEW_LABEL',
  onSendNewMessage = 'SEND_NEW_MESSAGE',
  onToggleMailFormFullScreen = 'TOGGLE_MAIL_FORM_FULL_SCREEN',
  onGetReferenceData = 'GET_REFERENCE_DATA',
  onGetLabels = 'GET_LABELS',
  onGetFolders = 'GET_FOLDERS',
  onRecall = 'RECALL',
  onMoveToFolder = 'MOVE_TO_FOLDER',
  onDeleteFolder = 'DELETE_FOLDER',
  onOpenForwardMessageForm = 'OPEN_FORWARD_MESSAGE_FORM',
  onSetLocalStorage = 'SET_LOCAL_STORAGE',
  onOpenFabMessageForm = 'OPEN_FAB_MESSAGE_FORM',
  onRemoveFabMessageForm = 'REMOVE_FAB_MESSAGE_FORM',
}

export interface MailSectionViewInterface {
  checkedMails: MailInterface[];
  mailActionsHandler: MailActionsHandler;
  isNewMessageFormOpen: boolean;
  isReferenceFormOpen: boolean;
  isNewFolderFormOpen: boolean;
  handleCloseNewMessageForm: () => void;
  handleCloseReferenceForm: () => void;
  handleCloseNewFolderForm: () => void;
  isMailFormFullScreen: boolean;
  referenceFormTitle?: string;
  onCreateNewFolder: () => void;
  handleFabOpen?: (fabData: SavedFabData) => void;
  handleFabRemove?: (event, fabData: SavedFabData) => void;
  draftMails?: SavedFabData[];
}

export interface SavedFabData {
  fabId: string;
  formData: Record<string, unknown>;
  selectedDoc?: Record<string, unknown>;
}
// action params
export interface OnChangeCheckedMailsParams {
  mail: MailInterface;
}

export interface OnHandleMasterCheckboxParams {
  event: React.ChangeEvent<HTMLInputElement>;
}

export interface OnChangePageHandlerParams {
  page: number;
}

export interface OnStarMailParams {
  mailsData: MailInterface[] | null;
  isStarred: boolean;
}

export interface OnImportantMailParams {
  mailsData: MailInterface[] | null;
  isImportant: boolean;
}

export interface OnMarkUnReadParams {
  mailsData: MailInterface[] | null;
  IsUnRead: boolean;
}

export interface OnShowDetailParams {
  mail: MailInterface;
  event?: React.MouseEvent<HTMLElement>;
}

export interface OnComposeParams {
  selectedIndex: number;
}

export interface OnSubmitLabelsParams {
  LabelsArray: MailLabelType[];
}

export interface OnCreateNewLabelParams {
  onSuccessNewLabelCallback: (response: Record<string, unknown>) => void;
}

export interface OnSendNewMessageParams {
  successCallback: () => void;
  isSaveAsDraft: boolean;
  failureCallback: () => void;
}

export interface OnForwardMessageParams {
  formData: Record<string, unknown>;
  formTitle: string;
}

export interface OnOpenFabMessageFormParams {
  fabData: SavedFabData;
  formTitle: string;
}

export interface OnRemoveFabMessageParams {
  fabData: SavedFabData;
}

export interface OnGetReferenceDataParams {
  id: string;
  IsChart: boolean;
  pagination: Record<string, unknown>;
  successCallback: (response: ReferenceResponse) => void;
}

export interface OnRecallParams {
  mailsData: MailInterface[] | null;
  successCallback: () => void;
  failureCallback: (error: any) => void;
}

export interface OnArchiveParams {
  mailsData: MailInterface[] | null;
}

export interface OnOpenReferenceFormParams {
  formData: Record<string, unknown>;
  formTitle?: string;
}

export interface OnGetLabelsParams {
  successCallback: (response: Record<string, unknown>) => void;
}

export interface OnMoveToFolderParams {
  Folders_ID: number;
}

export interface OnGetFoldersParams {
  isForInbox: number;
  successCallback?: (response: Record<string, unknown>) => void;
}

export interface OnDeleteFolderParams {
  Folders_ID: number;
  successCallback: (response: Record<string, unknown>) => void;
}

export interface MessageServiceParamsInterface {
  params: Record<string, unknown>;
  SaveDraft: boolean;
  successCallback: () => void;
  handleFailure: () => void;
}
