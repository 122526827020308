import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import { createStyles, makeStyles, Typography, Chip } from '@material-ui/core';

import ApiDetailGetter from '../../container/ApiDetailGetter';

interface SellinProductTagViewInterfaceProps {
  parentId: number;
  resource: string;
}

const styles = makeStyles(theme =>
  createStyles({
    productTagContainer: {
      paddingTop: 15,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },

    productTagTitle: {
      marginBottom: 5,
    },

    chip: {
      fontSize: 10,
      height: 25,
      margin: 2,
    },

    chipContainer: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    },
  }),
);

const SellinProductTagView: FC<SellinProductTagViewInterfaceProps> = props => {
  const { parentId, resource } = props;
  const translate = useTranslate();
  const classes = styles();

  return (
    <div className={classes.productTagContainer}>
      <Typography variant="subtitle2" className={classes.productTagTitle}>
        {translate('sellin.tags')}
      </Typography>

      <div className={classes.chipContainer}>
        <ApiDetailGetter resource={resource} id={parentId}>
          {apiDetailGetterProps =>
            apiDetailGetterProps.data.map(item => {
              return (
                <Chip
                  variant="outlined"
                  color="secondary"
                  className={classes.chip}
                  label={item.title}
                  key={item.id}
                />
              );
            })
          }
        </ApiDetailGetter>
      </div>
    </div>
  );
};

export default SellinProductTagView;
