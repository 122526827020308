import { useState, useEffect, FC, memo, useCallback, useRef } from 'react';
import { useTranslate } from 'react-admin';

import { isRecordEditable, isMetaEditable } from '../../helper/MetaHelper';
import ShowRecordWithRelationView from './show-record-with-relation.view';
import { RelationPanelControllerInterface } from '../new-relation-panel';
import {
  customRefresh,
  prepareRelationList,
} from './show-record-with-relation.helper';
import { prepareViewFields } from './show-record-with-relation.helper';
import LoadingBox from '../LoadingBox';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
  FormKeyMode,
  RecordKeyMode,
} from '../../type/actor-setup';

import type { MetaData } from '../../helper/Types';
import type {
  ShowRecordWithRelationInterface,
  TabInterFace,
} from './show-record-with-relation.type';

import NotFound from '../NotFound';
import { isEmptyObject } from '../../helper/data-helper';

const ShowRecordWithRelation: FC<ShowRecordWithRelationInterface> = props => {
  const { isReport, match, resource } = props;
  const [record, setRecord] = useState<Record<string, unknown>>();
  const [loading, setLoading] = useState(true);
  const [hasRelationNote, setHasRelationNote] = useState(false);

  const recordId = match?.params?.id;
  const translate = useTranslate();
  // const previousVersion = useRef(version); // TODO: delete after updateData useEffect complete
  const actorOnDispatchCallbackIdRef = useRef<symbol>();

  useEffect(() => {
    refreshData(false);
  }, [recordId, resource]);

  useEffect(() => {
    actorSetActionValue('gridData', {}, { replaceAll: true });
    setLoading(true);
  }, [resource]);

  useEffect(() => {
    actorOnDispatchCallbackIdRef.current = actorOnDispatch(
      'refreshView',
      viewName => {
        if (viewName !== 'showRecordPage') return;
        refreshData();
      },
    );

    actorOnDispatch('record', records => {
      const currentResource = actorGetActionValue('resources')?.current;
      if (currentResource == null) return;

      const targetRecord =
        records?.[currentResource.value]?.[FormKeyMode.ROOT]?.[RecordKeyMode.FULL];
      if (targetRecord) {
        Promise.resolve().then(() => {
          setRecord(targetRecord);
          setLoading(false);
        });
      }
    });

    //remove data from actor when component unmounted RCT-2498
    return () => {
      actorSetActionValue('gridData', {}, { replaceAll: true });

      actorRemoveAction({
        actionName: 'refreshView',
        listenerId: actorOnDispatchCallbackIdRef.current,
      });
    };
  }, []);

  const metaData = actorGetActionValue('metaData', resource) as unknown as MetaData;

  const recordIsEditable =
    !isReport && isRecordEditable(metaData, record) && isMetaEditable(metaData);

  const defaultTitle = translate('ra.page.show', {
    name: `${resource}`, // resource name
    recordId,
    record,
  });

  const processInfo = {
    processuniqueid: record?.__processuniqueid as string | null,
    positionid: record?.positionid as string | null,
    stateid: record?.stateid as string | null,
  };

  let tabList: TabInterFace[] = [];
  if (metaData && record) {
    tabList = prepareViewFields(record, metaData, resource);
  }

  const relations: Array<RelationPanelControllerInterface> = prepareRelationList(
    tabList,
    resource,
    recordIsEditable,
    recordId,
    processInfo,
  );

  useEffect(() => {
    checkHasRelationNote();
  }, [relations]);

  /**
   * check relation note is exist
   * @function checkHasRelationNote
   * @returns {void} void
   */
  const checkHasRelationNote = useCallback((): void => {
    const result = relations.filter(rel => rel.relationType === 'note');
    setHasRelationNote(result.length > 0);
  }, [relations]);

  /**
   * @function refreshData
   * @param {boolean} disableNotification
   * @returns {void}
   */
  const refreshData = (disableNotification = true) => {
    customRefresh({ resource, recordId, disableNotification, setLoading });
  };

  const currentRecord = actorGetActionValue(
    'record',
    `${resource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
  ) as Record<string, unknown>; // RCT-2677 - parent record should be exist in actor!

  if (loading || isEmptyObject(currentRecord)) {
    return <LoadingBox />;
  } else if (!loading && !record) {
    return <NotFound />;
  }

  if (!(Array.isArray(tabList) && tabList.length > 0)) {
    // TODO: show correct error message
    return null;
  }

  return (
    <ShowRecordWithRelationView
      record={record!} // record exist in this step because tablist could not have length without record
      tabList={tabList}
      recordIsEditable={recordIsEditable}
      resource={resource}
      isReport={isReport}
      customRefresh={refreshData}
      defaultTitle={defaultTitle}
      metaData={metaData}
      relations={relations}
      match={match}
      hasRelationNote={hasRelationNote}
    />
  );
};

export default memo(ShowRecordWithRelation, (prevProps, nextProps) => {
  return (
    prevProps.match.params.id === nextProps.match.params.id &&
    prevProps.resource === nextProps.resource
  );
});
