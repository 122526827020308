import { Icon, IconButton, Link as MaterialLink, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import lodashGet from 'lodash/get';
import { FC, memo } from 'react';
import { BooleanField, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import DateFieldContainer from '../../../container/DateFieldContainer';
import { apiUrl } from '../../../core/dataProvider';
import { isEmpty, isEmptyObject } from '../../../helper/data-helper';
import {
  BOOLEAN_FIELD,
  DATE_FIELD,
  DECIMAL_FIELD,
  FILE_DOWNLOAD_ICON,
  FILE_FIELD,
  FILE_STREAM_FIELD,
  getTypeByField,
  ICON_FIELD,
  MULTI_FILE_STREAM_FIELD,
  NUMBER_FIELD,
  RESOURCE_LINK_FIELD,
  RICH_TEXT_EDITOR_FIELD,
  TABLE_QUICK_ACCESS_FIELD,
  URL_FIELD,
  COMPUTED_FIELD,
} from '../../../helper/InputHelper';
import { getFractionDigitCountFromFormat } from '../../../helper/NumberHelper';
import GridColumnLink from '../../devExGrid/GridColumnLink';
import { wrapNegativeNumberWithParentheses } from '../../dynamic-input/number-input/number-input.helper';
import { ColorField } from '../../field/color-field';
import { IconField } from '../../field/icon-field';
import { NumberField } from '../../field/NumberField';
import ResourceLinkField from '../../formLayout/ResourceLinkField';
import { useStyles } from './grid-cell.style';
import { GridCellViewPropsInterface } from './grid-cell.type';

const GridCellView: FC<GridCellViewPropsInterface> = props => {
  const {
    row,
    value,
    column,
    basePath,
    metaData,
    hasShow,
    relationMode,
    hasEdit,
    handleSaveFile,
    openMultiFileListDialog,
    handleTableQuickAccessFieldClick,
    handleFileFieldClick,
    setCurrentValueToFilter,
    handleBooleanFieldInlineEdit,
  } = props;

  const translate = useTranslate();
  const theme = useTheme();
  const { name, field, title } = column;
  const classes = useStyles();

  if (!field) {
    return <div>{value}</div>;
  }

  const { relatedName, name: fieldName, format, dataType, linkName } = field;

  if (name === 'statusFields') {
    const { fields } = column;
    return (
      <div className={classes.statusFields}>
        {fields.map(field =>
          getTypeByField(field) === ICON_FIELD ? (
            <IconField field={field} data={row} />
          ) : (
            <ColorField field={field} data={row} />
          ),
        )}
      </div>
    );
  }

  const relationValue = lodashGet(row, relatedName, value);
  const linkValue = lodashGet(row, linkName, null);

  const record = !isEmptyObject(row) ? row : { [name]: value };
  const className = classNames(classes.fieldValue, classes.fieldLink);

  let component;

  const fieldType = getTypeByField(field);

  switch (fieldType) {
    case RESOURCE_LINK_FIELD:
      component = (
        <ResourceLinkField
          record={record}
          source={`${relatedName}`}
          label={value}
          field={field}
        />
      );
      break;
    case RICH_TEXT_EDITOR_FIELD:
      component = (
        <span
          className={className}
          data-test-editor-field-name={fieldName}
          title={translate('grid.print')}
          data-style-editor={fieldType}
        >
          {translate('grid.print')}
        </span>
      );
      break;

    case BOOLEAN_FIELD:
      component = (
        <GridColumnLink
          basePath={basePath}
          row={row}
          hasShow={hasShow}
          source={`${relatedName}`}
          field={field}
          metaData={metaData}
          hasEdit={hasEdit}
        >
          <BooleanField
            field={field}
            label={title}
            source={`${relatedName}`}
            record={record}
            className={classes.booleanField}
            data-test-boolean-field-name={name}
            data-style-boolean-field-name={fieldType}
            onClick={handleBooleanFieldInlineEdit}
          />
        </GridColumnLink>
      );

      break;

    case FILE_FIELD:
      const fileName = lodashGet(row, fieldName, value);
      component = (
        <span
          className={className}
          onClick={handleFileFieldClick}
          data-test-grid-column-link-name={fieldName}
          title={relationValue}
          data-style-column-link-name={FILE_FIELD}
        >
          {fileName}
        </span>
      );
      break;

    case TABLE_QUICK_ACCESS_FIELD:
      const tableQuickAccessFieldText = lodashGet(row, fieldName, value);
      component = (
        <span
          className={className}
          onClick={handleTableQuickAccessFieldClick}
          data-test-grid-column-link-name={fieldName}
          title={tableQuickAccessFieldText}
          data-style-column-link-name={fieldType}
        >
          {tableQuickAccessFieldText}
        </span>
      );
      break;
    case NUMBER_FIELD:
    case DECIMAL_FIELD:
      if (!isEmpty(format)) {
        component = (
          <GridColumnLink
            basePath={basePath}
            row={row}
            hasShow={hasShow}
            source={`${relatedName}`}
            field={field}
            metaData={metaData}
            hasEdit={hasEdit}
          >
            <NumberField
              field={field}
              label={title}
              source={`${relatedName}`}
              record={record}
              className={classes.textField}
              options={{
                minimumFractionDigits: getFractionDigitCountFromFormat(format),
                maximumFractionDigits: getFractionDigitCountFromFormat(format),
              }}
              data-test-number-field-name={fieldName}
              data-style-number-field-name={fieldType}
              ignoreNumberFormat={isEmpty(format)}
            />
          </GridColumnLink>
        );
      } else {
        component = (
          <GridColumnLink
            basePath={basePath}
            row={row}
            hasShow={hasShow}
            source={relatedName}
            field={field}
            metaData={metaData}
            hasEdit={hasEdit}
          >
            <span
              data-test-number-field-name={fieldName}
              title={relationValue}
              data-style-number-field-name={fieldType}
            >
              {wrapNegativeNumberWithParentheses(relationValue)}
            </span>
          </GridColumnLink>
        );
      }
      break;

    case DATE_FIELD:
      component = (
        <GridColumnLink
          basePath={basePath}
          row={row}
          hasShow={hasShow}
          source={`${relatedName}`}
          field={field}
          metaData={metaData}
          hasEdit={hasEdit}
        >
          <DateFieldContainer
            field={field}
            label={title}
            source={`${relatedName}`}
            record={record}
          />
        </GridColumnLink>
      );
      break;

    case URL_FIELD:
      component = (
        <MaterialLink
          className={className}
          href={relationValue}
          data-test-grid-column-link-name={fieldName}
          data-style-column-link-name={fieldType}
          title={relationValue}
        >
          {wrapNegativeNumberWithParentheses(relationValue)}
        </MaterialLink>
      );
      break;

    case FILE_STREAM_FIELD: {
      const fileStream = lodashGet(row, fieldName, value); //type script error
      component = (
        <a
          className={className}
          href={`${apiUrl}/${linkValue}`}
          data-test-field-link-name={fieldName}
          data-style-field-link-name-stream={fieldType}
          target="_blank"
          download
        >
          {fileStream}
        </a>
      );
      break;
    }

    case MULTI_FILE_STREAM_FIELD:
      component = (
        <IconButton
          className={classes.IconButton}
          onClick={openMultiFileListDialog(field)}
          color="primary"
        >
          <Icon className={classNames(classes.icon, 'fa fa-list')} />
        </IconButton>
      );
      break;

    case FILE_DOWNLOAD_ICON:
      component = (
        <IconButton
          className={classes.IconButton}
          onClick={handleSaveFile}
          color="primary"
        >
          <Icon className={classNames(classes.icon, 'fa fa-save')} />
        </IconButton>
      );
      break;

    case COMPUTED_FIELD:
      if (linkName && linkValue) {
        component = (
          <Link
            className={className}
            to={`${linkValue.toLowerCase()}/show`}
            data-test-grid-column-link-name={fieldName}
            data-style-column-link-name={fieldType}
            title={relationValue}
          >
            {value && dataType.simple === 'number' && !isEmpty(value)
              ? wrapNegativeNumberWithParentheses(value, true)
              : wrapNegativeNumberWithParentheses(relationValue, false)}
          </Link>
        );
      } else {
        component = (
          <GridColumnLink
            basePath={basePath}
            row={row}
            hasShow={hasShow}
            source={relatedName}
            field={field}
            metaData={metaData}
            hasEdit={hasEdit}
          >
            <span
              data-test-number-field-name={fieldName}
              title={relationValue}
              data-style-number-field-name={fieldType}
            >
              {value && dataType.simple === 'number' && !isEmpty(value)
                ? wrapNegativeNumberWithParentheses(value, true)
                : wrapNegativeNumberWithParentheses(relationValue, false)}
            </span>
          </GridColumnLink>
        );
      }

      break;

    default: {
      if (linkName && linkValue) {
        component = (
          <Link
            className={className}
            to={`${linkValue.toLowerCase()}/show`}
            style={{ color: theme.palette.primary.main }}
            data-test-grid-column-link-name={fieldName}
            data-style-column-link-name={fieldType}
            title={relationValue}
          >
            {wrapNegativeNumberWithParentheses(relationValue)}
          </Link>
        );
      } else {
        component = (
          <GridColumnLink
            basePath={basePath}
            row={row}
            hasShow={hasShow}
            source={relatedName}
            field={field}
            metaData={metaData}
            hasEdit={hasEdit}
          >
            <span
              data-test-number-field-name={fieldName}
              title={relationValue}
              data-style-number-field-name={fieldType}
            >
              {wrapNegativeNumberWithParentheses(relationValue)}
            </span>
          </GridColumnLink>
        );
      }
    }
  }

  return (
    <>
      {!!field.parameterKey && !relationMode && (
        <IconButton
          className={classes.iconButton}
          onClick={setCurrentValueToFilter}
          color="primary"
          data-test-filter-button-id={relationValue}
          data-style-filter-button={fieldType}
        >
          <Icon
            fontSize="small"
            className={classNames(classes.icon, 'fa fa-filter')}
          />
        </IconButton>
      )}
      {component}
    </>
  );
};

export default memo(GridCellView);
