import { FC, useRef } from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { TitleForRecord, useLocale } from 'react-admin';
import classnames from 'classnames';
import lodashGet from 'lodash/get';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

import { getTypeByField, ICON_FIELD } from '../../helper/InputHelper';
import { useStyles } from './show-record-with-relation.style';
import { CustomAccordion } from '../custom-accordion';
import { Table as CustomFormTable } from '../table';
import { ColorField } from '../field/color-field';
import { IconField } from '../field/icon-field';
import ShowActions from '../ShowActions';
import ShowTitle from '../ShowTitle';
import PinnedNotesController from '../new-relation-panel/note-relation/pinned-note/pinned-notes-controller';

import {
  RelationPanel,
  RelationPanelControllerInterface,
} from '../new-relation-panel';

import type {
  ShowRecordWithRelationViewInterface,
  TabInterFace,
} from './show-record-with-relation.type';
import { isEmptyObject } from '../../helper/data-helper';

const ShowRecordWithRelationView: FC<
  ShowRecordWithRelationViewInterface
> = props => {
  const {
    record,
    tabList = [],
    recordIsEditable,
    resource,
    isReport,
    customRefresh,
    metaData,
    defaultTitle,
    relations,
    match,
    hasRelationNote,
  } = props;

  const tabsTitlesRef = useRef<Record<number, HTMLParagraphElement | null>>({}); // todo use it 😑

  const classes = useStyles();
  const theme = useTheme();
  const locale = useLocale();

  return (
    <>
      <TitleForRecord
        title={<ShowTitle metaData={metaData} />}
        record={record}
        defaultTitle={defaultTitle}
      />

      <ShowActions
        metaData={metaData}
        locale={locale}
        hasEdit={!isReport}
        data={record}
        basePath={`/${resource}`}
        resource={resource}
        customRefresh={customRefresh}
      />

      <div className={classnames(classes.viewContainer)}>
        {hasRelationNote && <PinnedNotesController match={match} />}
        <Tabs
          defaultActiveKey={'0'}
          direction={theme.direction}
          className={classes.tabParent}
          data-style-tab-relation="tabsRelation"
        >
          <>
            {tabList.map((tab: TabInterFace, index) => {
              return (
                <TabPane
                  data-style-tab-item="tabItem"
                  key={index}
                  tab={
                    <p
                      ref={tabRef => {
                        tabsTitlesRef.current[index] = tabRef;
                      }}
                    >
                      {lodashGet(tab, ['translatedTitle', locale]) ||
                        tab.title ||
                        tab.name ||
                        tab.id}
                    </p>
                  }
                >
                  <div className={classes.tabGroupsContainer}>
                    {tab.groupList &&
                      tab.groupList.length &&
                      tab?.groupList.map((group, index: number) => {
                        if (isEmptyObject(group)) return null;

                        const { translatedTitle = {}, id } = group;
                        const title: string =
                          translatedTitle?.[locale] ?? String(id) ?? '';
                        return (
                          <div key={index} className={classes.groupContainer}>
                            <CustomAccordion
                              id={`showRecord_${id}`}
                              defaultExpanded={true}
                              enableChild
                              summary={
                                <div className={classes.titleSummary}>
                                  <Typography variant="body2">{title}</Typography>
                                  {group.specialFields &&
                                    group.specialFields.map(field =>
                                      getTypeByField(field) === ICON_FIELD ? (
                                        <IconField field={field} data={record} />
                                      ) : (
                                        <ColorField field={field} data={record} />
                                      ),
                                    )}
                                </div>
                              }
                            >
                              <CustomFormTable
                                className={classes.table}
                                group={group}
                                type="grid"
                                record={record}
                                tab={tab}
                                recordIsEditable={recordIsEditable}
                              />
                            </CustomAccordion>
                          </div>
                        );
                      })}
                  </div>
                </TabPane>
              );
            })}
          </>
        </Tabs>
        {relations &&
          relations.map(
            (relationProps: RelationPanelControllerInterface, relationIndex) => {
              return (
                <div className={classes.relationItem} key={relationIndex}>
                  <RelationPanel {...relationProps} relationIndex={relationIndex} />
                </div>
              );
            },
          )}
      </div>
    </>
  );
};

export default ShowRecordWithRelationView;
