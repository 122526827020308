import { FC, memo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { NavCollapseInterface } from './nav-collapse.type';
import NavCollapseView from './nav-collapse.view';

const NavCollapseController: FC<NavCollapseInterface> = memo(props => {
  const { item, level, baseUrl } = props;
  const location = useLocation();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(
    location.hash.includes(`open${item?.folderId}`) ? true : false,
  );
  const [isContextMenuOpen, setContextMenuIsOpen] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  /**
   * @function handleContextMenu
   * @param { React.MouseEvent } event
   * @returns { void }
   */
  const handleContextMenu = (event: React.MouseEvent): void => {
    if (item?.hasContextMenu) {
      event.preventDefault();
      setContextMenuIsOpen(
        isContextMenuOpen === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : null,
      );
    }
  };

  /**
   * @function handleCloseContextMenu
   * @returns { void }
   */
  const handleCloseContextMenu = (): void => {
    setContextMenuIsOpen(null);
  };
  /**
   * @function openMenu
   * @returns { void }
   */
  const openMenu = (): void => {
    setIsOpen(true);
  };
  /**
   * @function onToggle
   * @returns { void }
   */
  const onToggle = (): void => {
    setIsOpen(!isOpen);
    if (!isOpen && item?.onClick && typeof item.onClick === 'function') {
      item.onClick();
    }

    history.push(item?.url ?? '');
  };
  /**
   * @function onIconToggle
   * @returns { void }
   */
  const onIconToggle = (): void => {
    setIsOpen(prevOpen => !prevOpen);
  };
  return (
    <NavCollapseView
      item={item}
      level={level}
      baseUrl={baseUrl}
      openMenu={openMenu}
      open={isOpen}
      onToggle={onToggle}
      onIconToggle={onIconToggle}
      isContextMenuOpen={isContextMenuOpen}
      onContextMenu={handleContextMenu}
      handleCloseContextMenu={handleCloseContextMenu}
      contextMenuNode={item?.contextMenuNode}
      folderId={item?.folderId}
    />
  );
});

export default NavCollapseController;
