import React, { Component } from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { translate } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import { Icon, IconButton, TableFooter, Typography } from '@material-ui/core';

import { mergeAndClone, clone, isEmpty } from '../../helper/data-helper';
import { getInputWidth } from '../../helper/InputHelper';
import RelationFormIteratorRow from './RelationFormIteratorRow';
import { getFractionDigitCountFromFormat } from '../../helper/NumberHelper';
import {
  getFormDefaultValue,
  getGridColumns,
  getPrimaryField,
  getFieldsById,
  getIsRowReOrderEnabled,
} from '../../helper/MetaHelper';
import LocaleHoc from '../LocaleHoc';
import { NumberField } from '../field/NumberField';
import { getAppSettings, setAppSettings } from '../../helper/settings-helper';
import { CONFIG_LIST_COLUMN_CHOICE, DEFAULT } from '../../core/configProvider';

const styles = theme => ({
  header: {
    display: 'flex',
    padding: '15px 0',
  },

  action: {
    margin: '0 10px',
  },

  tableContainer: {
    overflow: 'auto',
    border: `1px solid ${theme.palette.divider}`,
    minHeight: 250,
    maxHeight: 250,
  },

  tableHead: {
    backgroundColor: theme.palette.background.default,
  },

  headerTableCell: {
    borderBottom: `4px double ${theme.palette.divider}`,
    padding: '0 10px',
  },

  TableHeaderCellAction: {
    borderBottom: `4px double ${theme.palette.divider}`,
    padding: '0',
    width: 40,
    textAlign: 'center',
  },

  icon: {
    margin: '0 5px',
    color: theme.palette.action.active,
  },

  dynamicInputWithError: {
    borderBottom: '1px solid red',
    margin: 0,
  },

  rowOrderCell: {
    width: 26,
    padding: 0,
  },

  actionCellIconButton: {
    padding: '7px 2px',
  },

  numberField: {
    display: 'inline',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
});

const defaultGlobalParameters = {};

export class RelationFormIterator extends Component {
  state = {
    relationInputList: [],
    isOrderListChanged: true,
  };

  static getDerivedStateFromProps(props, prevState) {
    if (!prevState.isOrderListChanged) {
      return prevState;
    }

    return {
      ...prevState,
      relationInputList: RelationFormIterator.getRelationInputList(props),
      isOrderListChanged: false,
    };
  }

  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this.refList = {};
    this.isFocusedOnceList = {};

    this.dragged = null;
    this.dragFromIndex = null;

    this.draggedCol = null;
    this.dragFromIndexCol = null;
  }

  dragStart = e => {
    this.dragged = e.target;
    this.dragged.style.opacity = 0.05;
    const children = Array.from(this.dragged.parentNode.children);
    this.dragFromIndex = children.indexOf(this.dragged);
  };

  dragOver = e => {
    e.preventDefault();
  };

  dragEnd = e => {
    this.dragged.style.opacity = 1;
  };

  dragEnter = e => {
    const selectedElement = e.target;
    const dragged = this.dragged;
    const dropped =
      selectedElement.nodeName !== 'TR'
        ? selectedElement.closest('TR')
        : selectedElement;

    if (dragged) {
      const children = Array.from(dragged.parentNode.children);

      const fromIndex = children.indexOf(dragged);
      const toIndex = children.indexOf(dropped);

      if (toIndex > fromIndex) dropped.after(dragged);
      else dropped.before(dragged);
    }
  };

  dropRow = event => {
    const { fields } = this.props;
    const selectedElement = event.target;
    const dragged = this.dragged;
    const dropped =
      selectedElement.nodeName !== 'TR'
        ? selectedElement.closest('TR')
        : selectedElement;
    if (dragged) {
      const children = Array.from(dragged.parentNode.children);
      const toIndex = children.indexOf(dropped);
      const fromField = fields.value[this.dragFromIndex];
      const toField = fields.value[toIndex];
      const fromRowOrder = clone(fromField.roworder);
      const toRowOrder = clone(toField.roworder);

      fromField.roworder = toRowOrder;
      toField.roworder = fromRowOrder;
      this.moveRow(this.dragFromIndex, toIndex);
    }
  };

  dragStartCol = e => {
    this.draggedCol = e.target;
    this.draggedCol.style.opacity = 0.05;
    const children = Array.from(this.draggedCol.parentNode.children);
    this.dragFromIndexCol = children.indexOf(this.draggedCol);
  };

  dragOverCol = e => {
    e.preventDefault();
  };

  dragEndCol = e => {
    this.draggedCol.style.opacity = 1;
  };

  dragEnterCol = e => {
    const selectedElement = e.target;
    const dragged = this.draggedCol;
    const dropped =
      selectedElement.nodeName !== 'TH'
        ? selectedElement.closest('TH')
        : selectedElement;
    const table = selectedElement.closest('TABLE');
    const rows = table.children[1].rows;

    if (dragged) {
      const children = Array.from(dragged.parentNode.children);

      const fromIndex = children.indexOf(dragged);
      const toIndex = children.indexOf(dropped);

      if (toIndex > fromIndex) {
        dropped.after(dragged);
      } else {
        dropped.before(dragged);
      }

      for (let rowNumber = 0; rowNumber < rows.length; rowNumber++) {
        const columnFrom = rows[rowNumber].children[fromIndex];
        const columnTo = rows[rowNumber].children[toIndex];

        if (toIndex > fromIndex) {
          columnTo.after(columnFrom);
        } else {
          columnTo.before(columnFrom);
        }
      }
    }
  };

  dropCol = event => {
    const { relationPath } = this.props;
    const orderList = [];
    for (const column of event.target.parentNode.children) {
      if (column.id) {
        orderList.push(column.id);
      }
    }

    setAppSettings({
      key: CONFIG_LIST_COLUMN_CHOICE + '_' + relationPath,
      value: orderList,
      forUser: true,
    });

    this.setState({ isOrderListChanged: true });
  };

  componentDidMount() {
    const { relationResource, getRelationRef } = this.props;

    if (typeof getRelationRef === 'function') {
      getRelationRef(relationResource, this.myRef.current);
    }
  }

  removeField = index => () => {
    const { fields } = this.props;

    const selectedItem = fields.value[index];
    // isNewRecord is only added in client-side, so we can check with it
    if (!selectedItem.isNewRecord) {
      const deletedItem = mergeAndClone(selectedItem, { isdeleted: 1 });
      fields.update(index, deletedItem);
    }
    // is not yet created, so we can remove it completely
    else {
      fields.remove(index);
    }
  };

  // Returns a boolean to indicate whether to disable the remove button for certain fields.
  // If disableRemove is a function, then call the function with the current record to
  // determing if the button should be disabled. Otherwise, use a boolean property that
  // enables or disables the button for all of the fields.
  disableRemoveField = (record, disableRemove) => {
    if (typeof disableRemove === 'boolean') {
      return disableRemove;
    }
    return disableRemove && disableRemove(record);
  };

  addField = () => {
    const { fields, relationMetaData, globalParameters } = this.props;
    const relationInputList = getGridColumns({ metaData: relationMetaData });
    const defaultData = getFormDefaultValue(relationInputList, globalParameters);

    fields.push({
      ...defaultData,
      id: Date.now() + '-' + Math.random(), // give fake id to track errors in relation form
      isNewRecord: true,
    });
  };

  moveRow(fromIndex, toIndex) {
    const { fields, source } = this.props;
    fields.move(source, fromIndex, toIndex);
  }

  getRefFromInput = index => (name, input) => {
    const { fields } = this.props;
    const rowList = fields.value;

    if (!this.refList[index]) {
      this.refList[index] = {};
    }

    // no need to setState
    this.refList[index][name] = input;

    if (
      this.isFocusedOnceList &&
      !this.isFocusedOnceList[index] &&
      rowList &&
      rowList[index] &&
      rowList[index].isNewRecord
    ) {
      this.isFocusedOnceList[index] = true;
      input.focus();
    }
  };

  changeFocusOnAnotherRow = index => (fieldName, isMoveToNext) => {
    const { fields } = this.props;
    const focusedRow = index + (isMoveToNext ? 1 : -1);

    const rowList = fields.value;

    if (typeof rowList[focusedRow] === 'undefined') {
      console.log('skipping change', {
        index,
        fieldName,
        focusedRow,
      });
      return;
    }

    if (
      typeof this.refList[focusedRow] === 'undefined' ||
      typeof this.refList[focusedRow][fieldName] === 'undefined'
    ) {
      console.log('input ref not found', {
        focusedRow,
        fieldName,
        refList: this.refList,
      });
      return;
    }

    this.refList[focusedRow][fieldName].focus();
  };

  handleEnterPress = index => fieldName => {
    const { fields } = this.props;

    const rowList = fields.value;

    if (typeof rowList[index + 1] !== 'undefined') {
      const firstField = Object.keys(this.refList[index])[0];
      this.changeFocusOnAnotherRow(index)(firstField, true);
    } else {
      this.addField();
    }
  };

  static getRelationInputList = props => {
    const { relationMetaData, columnList, relationPath, panelFeatures = {} } = props;
    const { deActiveFields = [] } = panelFeatures;

    // some fields are marked disabled by process
    const defaultSelected = getAppSettings(
      DEFAULT + '_' + CONFIG_LIST_COLUMN_CHOICE + '_' + relationPath,
    );
    const userSelected = getAppSettings(
      CONFIG_LIST_COLUMN_CHOICE + '_' + relationPath,
      true,
    );

    let relationInputList = clone(
      columnList || getGridColumns({ metaData: relationMetaData }),
    );

    if (
      (userSelected && userSelected.length) ||
      (defaultSelected && defaultSelected.length)
    ) {
      const selectedIds =
        userSelected && userSelected.length ? userSelected : defaultSelected;
      const selectedFields = getFieldsById(relationMetaData, selectedIds).map(
        field => {
          if (!field) {
            return null;
          }

          if (!deActiveFields.length || deActiveFields.indexOf(field.id) === -1) {
            return field;
          }

          return {
            ...field,
            required: false,
            disabled: true,
          };
        },
      );

      relationInputList = [...selectedFields];
    }
    return relationInputList;
  };

  render() {
    const {
      basePath,
      classes = {},
      fields,
      meta: { error, submitFailed },
      record,
      formData,
      resource,
      relation,
      relationResource,
      source,
      translate,
      disableRemove,
      relationMetaData,
      locale,
      title,
      formName,
      panelFeatures = {},
      changeFormValue,
      addToRelationArray,
      disableDropdownQuickCreate,
      disableDropdownSearchPopup,
      relationPath,
      relationExceptions = [],
      setRelationExceptions,
      allRelationExceptions,
    } = this.props;

    const { relationInputList } = this.state;

    const { disableAdd = false } = panelFeatures;

    if (!fields || !relationMetaData || !record) {
      return <div />;
    }

    const records = record[source];
    // some fields are marked disabled by process

    const primaryField = getPrimaryField(relationMetaData);

    const ignoreShowingColumnObj = {
      // primary key of table
      [primaryField.name]: true,
      // foreign key
      [relation.childFieldName]: true,
      // row color
      rowstatecolor: true,
    };

    const isReOrderEnabled = getIsRowReOrderEnabled(relationMetaData);

    return (
      <div ref={this.myRef} data-test-relation-form-iterator={relationResource}>
        <div className={classes.header}>
          <Typography variant="body1">{title}</Typography>
          {!disableAdd && (
            <span className={classes.action}>
              <Button size="small" onClick={this.addField} data-test-add-row="true">
                <AddIcon className={classes.icon} />
                {translate('ra.action.add')}
              </Button>
            </span>
          )}
        </div>

        {/*{submitFailed && error && <FormHelperText error>{error}</FormHelperText>}*/}

        {fields && !!fields.length && (
          <div className={classes.tableContainer}>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {isReOrderEnabled && (
                    <TableCell
                      className={classNames(
                        classes.TableHeaderCellAction,
                        classes.rowOrderCell,
                      )}
                    />
                  )}
                  <TableCell className={classes.TableHeaderCellAction} />
                  {relationInputList.map(relationInput => {
                    // if this field is ignored
                    if (
                      ignoreShowingColumnObj[relationInput.name] ||
                      relationInput.hidden
                    ) {
                      return null;
                    }

                    const cellWidth = getInputWidth(relationInput);
                    return (
                      <TableCell
                        className={classes.headerTableCell}
                        key={relationInput.id}
                        id={relationInput.id}
                        style={{
                          minWidth: cellWidth,
                          maxWidth: cellWidth,
                          cursor: 'move',
                        }}
                        onDragStart={this.dragStartCol}
                        onDragOver={this.dragOverCol}
                        onDragEnd={this.dragEndCol}
                        onDrop={this.dropCol}
                        onDragEnter={this.dragEnterCol}
                        draggable={true}
                      >
                        {lodashGet(
                          relationInput,
                          ['translatedCaption', locale],
                          relationInput.name,
                        )}
                        {!!relationInput.required && ' * '}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {fields.map((member, index) => {
                  const row = fields.value[index];
                  if (row.isdeleted) {
                    return null;
                  }

                  return (
                    <RelationFormIteratorRow
                      key={row.id}
                      resource={resource}
                      relationInfo={relation}
                      relationResource={relationResource}
                      relationSource={source}
                      basePath={basePath}
                      formName={formName}
                      member={member}
                      row={{ ...record, ...row }}
                      formData={formData}
                      disableUp={index === 0}
                      disableDown={index === fields.length - 1}
                      relationInputList={relationInputList}
                      ignoreShowingColumnObj={ignoreShowingColumnObj}
                      allowMoveFields={
                        !this.disableRemoveField(
                          (records && records[index]) || {},
                          disableRemove,
                        )
                      }
                      onRemove={this.removeField(index)}
                      changeFocusOnAnotherRow={this.changeFocusOnAnotherRow(index)}
                      getRefFromInput={this.getRefFromInput(index)}
                      handleEnterPress={this.handleEnterPress(index)}
                      onDragStart={this.dragStart}
                      onDragEnd={this.dragEnd}
                      onDragEnter={this.dragEnter}
                      onDrop={this.dropRow}
                      onDragOver={this.dragOver}
                      draggable={isReOrderEnabled}
                      changeFormValue={changeFormValue}
                      addToRelationArray={addToRelationArray}
                      disableDropdownQuickCreate={disableDropdownQuickCreate}
                      disableDropdownSearchPopup={disableDropdownSearchPopup}
                      relationMetaData={relationMetaData}
                      relationPath={relationPath}
                      rowIndex={index}
                      translate={translate}
                      exception={relationExceptions.filter(
                        exception => exception['rowIndex'] === index,
                      )}
                      locale={locale}
                      setRelationExceptions={setRelationExceptions}
                      allRelationExceptions={allRelationExceptions}
                    />
                  );
                })}
              </TableBody>

              <TableFooter>
                <TableRow>
                  {isReOrderEnabled && (
                    <TableCell
                      className={classNames(
                        classes.TableHeaderCellAction,
                        classes.rowOrderCell,
                      )}
                    />
                  )}
                  {!disableAdd && (
                    <TableCell className={classes.TableHeaderCellAction}>
                      <IconButton
                        size="small"
                        onClick={this.addField}
                        className={classes.actionCellIconButton}
                      >
                        <Icon className={classes.icon} size="small">
                          add
                        </Icon>
                      </IconButton>
                    </TableCell>
                  )}
                  {relationInputList.map(relationInput => {
                    // if this field is ignored
                    if (
                      ignoreShowingColumnObj[relationInput.name] ||
                      relationInput.hidden
                    ) {
                      return null;
                    }
                    const cellWidth = getInputWidth(relationInput);

                    let sumResult = '';
                    if (relationInput.hasSummary) {
                      sumResult = fields.value.reduce((sum, row) => {
                        if (row.isdeleted) {
                          return sum;
                        }
                        let value = 0;

                        if (
                          relationInput.dataType.erp === 'computed' &&
                          relationInput.javaScriptFormula
                        ) {
                          const formula = relationInput.javaScriptFormula;
                          if (formula && formula.indexOf('return') !== -1) {
                            try {
                              const computed = new Function('row', `${formula}`);
                              value = computed(row);
                            } catch (error) {
                              value = 0;
                            }
                          }
                        } else if (parseFloat(row[relationInput.name])) {
                          value = parseFloat(row[relationInput.name]);
                        }

                        return sum + value;
                      }, 0);
                    }

                    return (
                      <TableCell
                        className={classes.headerTableCell}
                        key={relationInput.id}
                        style={{
                          minWidth: cellWidth,
                          maxWidth: cellWidth,
                        }}
                      >
                        <NumberField
                          className={classes.numberField}
                          source="value"
                          record={{ value: sumResult }}
                          options={
                            relationInput.format
                              ? {
                                  maximumFractionDigits:
                                    getFractionDigitCountFromFormat(
                                      relationInput.format,
                                    ),
                                }
                              : undefined
                          }
                          ignoreNumberFormat={isEmpty(relationInput.format)}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        )}
      </div>
    );
  }
}

RelationFormIterator.defaultProps = {
  disableRemove: false,
};

RelationFormIterator.propTypes = {
  defaultValue: PropTypes.any,
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.object,
  meta: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  relationResource: PropTypes.string.isRequired,
  relationPath: PropTypes.string.isRequired,
  title: PropTypes.string,
  translate: PropTypes.func,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  relation: PropTypes.object,
  getRelationRef: PropTypes.func,
  disableDropdownQuickCreate: PropTypes.bool,
  disableDropdownSearchPopup: PropTypes.bool,
};

const mapStateToProps = state => ({
  globalParameters: lodashGet(
    state,
    'profile.globalParameters',
    defaultGlobalParameters,
  ),
});

export default compose(
  translate,
  withStyles(styles, { withTheme: true }),
  withWidth(),
  connect(mapStateToProps),
  LocaleHoc,
)(RelationFormIterator);
