import { DialogContent, DialogTitle } from '@material-ui/core';
import { ReactElement } from 'react';
import { getTranslatedName } from '../../helper/MetaHelper';
import { isCleanStringMatch } from '../../helper/TextHelper';
import { DropdownMetaBase } from '../dynamic-input/dropdown-input';
import { CustomForm, FormController, FormWithTabs } from '../form';
import QuickCreateButtonToolbar from '../QuickCreateButtonToolbar';
import { useStyles } from './dialogs-stack.style';
import { DialogViewProps } from './dialogs-stack.type';

export const QuickCreateDropdownDialogView = (
  props: DialogViewProps,
): ReactElement => {
  const { dialogData, locale, currentResource, closeDialogHandler, dialogType } =
    props;

  const classes = useStyles();

  return (
    <>
      <DialogTitle id="quickCreateDropdownDialogTitle">
        {isCleanStringMatch(getTranslatedName(dialogData.metaData, locale), 'ي')}
      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
        data-style-dialog-content="dialogContent"
      >
        <FormController formName={dialogType} isQuickForm>
          <CustomForm
            isBottomToolbar
            Toolbar={
              <QuickCreateButtonToolbar
                isFromDropdown
                disableFullFormButton={false}
                closeDialog={closeDialogHandler}
                parentInfo={dialogData.parentInfo as Record<string, unknown>}
                resource={currentResource!.value}
                dropdownId={dialogData.dropdownId as number}
                dropdownMeta={dialogData.dropdownMeta as DropdownMetaBase}
                onCreate={dialogData.onCreate as () => void}
                mustRefresh={dialogData.mustRefresh as boolean}
              />
            }
          >
            <FormWithTabs />
          </CustomForm>
        </FormController>
      </DialogContent>
    </>
  );
};
