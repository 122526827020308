import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    height: '100%',
    maxHeight: '40px',

    '& legend': {
      display: 'none',
    },
    '& fieldset': {
      backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    },
  },

  notchedOutline: {
    top: 0,
  },

  outlineRoot: {
    height: '100%',
  },

  disabled: {
    color: theme.palette.grey[700],
    '& fieldset': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  input: {
    zIndex: 1,
    '&:-webkit-autofill': {
      border: `1px solid ${theme.palette.primary.appPrimaryBorderInputColor}`,
    },
  },

  wmsInput: {
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,

    [theme.breakpoints.down('md')]: {
      '& label': {
        transform: 'scale(1) translate(14px, 12px)',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      '& input': {
        padding: 7,
        lineHeight: 0,
      },
      '& label': {
        transform: 'scale(1) translate(14px, 10px)',
        fontSize: 10,
      },
      '& div': {
        fontSize: 10,
      },
    },
  },

  error: {
    '& + p': {
      position: 'absolute',
      right: 0,
    },
  },

  iconButton: {
    zIndex: 1,
  },
}));
