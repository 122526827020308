import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  customAccordionView: {
    width: '100%',
    borderRadius: theme.shape.borderRadius,
  },

  accordionRoot: {
    borderRadius: theme.shape.borderRadius,
  },

  AccordionDetails: {
    borderTop: '1px solid black',
    '& > div': {
      width: '100%',
    },
  },

  contentRoot: {
    maxHeight: '45px !important',
    minHeight: '45px !important',
  },

  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));
