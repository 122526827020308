import { useCallback, useEffect, useState } from 'react';
import lodashFind from 'lodash/find';
import lodashGet from 'lodash/get';

import { clone, isEmptyObject } from '../helper/data-helper';
import { dummyFunc, ui } from '../helper/InputHelper';
import { FieldType } from '../helper/Types';

export interface InputUiControlType {
  field: Partial<FieldType>;
  formData?: object;
  dropdownState?: any[] | { id?: string | number | undefined } | null;
  metaData?: { fields: Partial<FieldType>[] };
  findDropdownData?: Function;
  triggerDropdownFetchData?: Function;
}

export const useInputUiControl = (
  props: InputUiControlType,
): { uiVisibled: boolean; uiEnabled: boolean } => {
  const {
    field,
    formData = {},
    dropdownState,
    metaData = { fields: {} },
    findDropdownData,
    triggerDropdownFetchData = dummyFunc,
  } = props;

  const [relatedDropDownValue, setRelatedDropDownValue] = useState<object>({});
  const [uiVisibled, setUiVisibled] = useState(true);
  const [uiEnabled, setUiEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (field.uiEnable) getRelatedDropdownValue('uiEnable');
    if (field.uiVisible) getRelatedDropdownValue('uiVisible');
  }, [formData, dropdownState]);

  useEffect(() => {
    if (field.uiEnable || field.uiVisible) checkUi();
  }, [formData, relatedDropDownValue, dropdownState]);

  /**
   * check `field` and run `javascriptUiEnable` and `javascriptUiVisible` beased on `formData` and `relatedDropDownValue` and then ran `setUiVisibled` or `setUiEnabled`
   * @function checkUi
   * @returns {void}
   */
  const checkUi = useCallback(() => {
    const { uiVisible, uiEnable, javaScriptUiVisible, javaScriptUiEnable } = field;

    if (uiVisible && uiVisible.length && javaScriptUiVisible) {
      if (javaScriptUiVisible.indexOf('return') !== -1) {
        if (
          lodashGet(field, ['uiVisible', 0, ui.column], '') !== '' &&
          relatedDropDownValue
        ) {
          try {
            const execute = new Function(
              'relatedDropDownValue',
              `${javaScriptUiVisible}`,
            );
            setUiVisibled(execute(clone(relatedDropDownValue)));
          } catch (error) {
            console.log('javaScriptUiVisible error on %s', field.name, error);
          }
        } else if (
          lodashGet(field, ['uiVisible', 0, ui.column], '') === '' &&
          formData &&
          !isEmptyObject(formData)
        ) {
          try {
            const execute = new Function('formData', `${javaScriptUiVisible}`);
            setUiVisibled(execute(clone(formData)));
          } catch (error) {
            console.log('javaScriptUiVisible error on %s', field.name, error);
          }
        }
      }
    }

    if (uiEnable && uiEnable.length && javaScriptUiEnable) {
      if (javaScriptUiEnable.indexOf('return') !== -1) {
        if (
          lodashGet(field, ['uiEnable', 0, ui.column], '') !== '' &&
          relatedDropDownValue
        ) {
          try {
            const execute = new Function(
              'relatedDropDownValue',
              `${javaScriptUiEnable}`,
            );
            setUiEnabled(execute(clone(relatedDropDownValue)));
          } catch (error) {
            console.log('javaScriptUiEnable error on %s', field.name, error);
          }
        } else if (
          lodashGet(field, ['uiEnable', 0, ui.column], '') === '' &&
          formData &&
          !isEmptyObject(formData)
        ) {
          try {
            const execute = new Function('formData', `${javaScriptUiEnable}`);
            setUiEnabled(!!execute(clone(formData)));
          } catch (error) {
            console.log('javaScriptUiEnable error on %s', field.name, error);
          }
        }
      }
    }
  }, [field, formData, relatedDropDownValue]);

  /**
   * check field and get dropdown data from redux state
   * @function getRelatedDropdownValue
   * @param {String} uiCheckType
   * @returns {void}
   */
  const getRelatedDropdownValue = useCallback(
    (uiCheckType: string) => {
      if (
        !isLoading &&
        dropdownState === undefined &&
        isEmptyObject(relatedDropDownValue)
      ) {
        // Fetch dropdwon data
        const fieldName: string = lodashGet(
          field,
          [uiCheckType, 0, ui.fieldName],
          '',
        );
        const dropdownMeta: object = lodashFind(metaData.fields, {
          name: fieldName,
        }).dropdown;
        triggerDropdownFetchData({ formData, dropdownMeta, findDropdownData }, '');
        setIsLoading(true);
      } else {
        // Use dropdwon data which is availabe in Redux State
        setIsLoading(false);
        const fieldName: string = lodashGet(
          field,
          [uiCheckType, 0, ui.fieldName],
          '',
        );
        const dropName: string = lodashGet(field, [uiCheckType, 0, ui.column], '');
        const selectedDropColId =
          formData[fieldName] !== null ? +formData[fieldName] : null;
        if (selectedDropColId !== null) {
          const activeDropColumn: object = lodashFind(dropdownState, {
            id: selectedDropColId,
          });

          if (activeDropColumn) {
            setRelatedDropDownValue({ [dropName]: activeDropColumn[dropName] });
          }
        } else {
          setRelatedDropDownValue({});
        }
      }
    },
    [dropdownState, formData],
  );

  return { uiVisibled, uiEnabled };
};
