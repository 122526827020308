import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { requestRelationData } from '../relation-panel.helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../../../type/actor-setup';
import { clone } from '../../../helper/data-helper';

import { apiRequestResultRelationHandler } from '../../../helper/crud-api.helper';
import { prepareReportFilter } from '../../../helper/MetaHelper';
import { getRootTableId } from '../../../helper/meta-helper';
import type { RelationActionBarProps } from './relation-action-bar.type';
import RelationActionBarView from './relation-action-bar.view';
import { customRecordNotePin } from '../note-relation/create-edit-note/consistent';

const RelationActionBar: FC<RelationActionBarProps> = props => {
  const {
    relationResource,
    relationMetaData,
    mockedRelation,
    parentRecord,
    parentInfo,
    relationType,
    relationData,
  } = props;
  const { parentFieldName, childFieldName } = mockedRelation;

  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
  const selectedIdsAccessRef = useRef<Array<string>>();

  const quickCreateData: {
    [key: string]: unknown;
  } = {
    [childFieldName]: parentRecord?.[parentFieldName],
  };

  useEffect(() => {
    selectedIdsAccessRef.current = selectedIds;
  }, [selectedIds]);

  useEffect(() => {
    actorOnDispatch('gridData', gridData => {
      const prevSelectedIds = selectedIdsAccessRef.current ?? [];
      const newSelectedIds = gridData[relationResource]?.selectedIds ?? [];

      if (prevSelectedIds.length !== newSelectedIds.length) {
        setSelectedIds(clone(newSelectedIds));
      }
    });
  }, []);

  /**
   * reset relation  data
   * @function onDeleteSuccessCallback
   * @returns {void} void
   */
  const onDeleteSuccessCallback = (): void => {
    requestRelationData(
      relationResource,
      relationType,
      parentInfo.parentId,
      mockedRelation.childFieldName,
      relationMetaData,
      undefined, // no custom request parameters
      apiRequestResultRelationHandler,
    );
    actorDispatch('loading', false, { path: relationResource });
    actorDispatch('refreshView', 'showRecordPage');
  };

  // --------------- prepare filters ---------------
  /**
   * it will toggle `IsTopFilterOpen` state to decide show or dont show filters in each column in grid
   * and also it should call `clearFilters` function if it going to set false in state
   * @function toggleShowFilters
   * @returns {void} void
   */
  const toggleShowFilters = useCallback(() => {
    const isTopFilterOpen = actorGetActionValue('isTopFilterOpen', relationResource);

    actorDispatch('isTopFilterOpen', { [relationResource]: !isTopFilterOpen });
  }, []);

  /**
   * @function getFilterValues
   * @returns Record<string, unknown>[] | undefined
   */
  const getFilterValues = (): (string[] | string)[] | undefined => {
    if (relationType === 'report' || relationType === 'multiReport') {
      return prepareReportFilter(relationMetaData, parentRecord);
    } else {
      return [
        [mockedRelation.childFieldName, '=', parentInfo.parentId],
        ['tableid', '=', String(getRootTableId())],
      ];
    }
  };

  const allowExport = !(
    (relationType === 'table' || relationType === 'oneToOneTable') &&
    (relationData?.totalCount === 0 || !relationMetaData?.config?.allowExport)
  );

  return (
    <RelationActionBarView
      {...props}
      onDeleteSuccessCallback={onDeleteSuccessCallback}
      quickCreateData={quickCreateData}
      selectedIds={selectedIds}
      toggleShowFilters={toggleShowFilters}
      allowExport={allowExport}
      getFilterValues={getFilterValues}
    />
  );
};
export default RelationActionBar;
