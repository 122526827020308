import React, { PureComponent } from 'react';
import lodashDebounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { translate, addField, FieldTitle } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';

import { findOneAction as findTagData } from '../../redux/tag/action';
import {
  NoOptionsMessage,
  inputComponent,
  Placeholder,
} from '../DropdownCommonComponents';
import { dummyFunc } from '../../helper/InputHelper';

const styles = theme => ({
  select: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    // '& label': {
    //   transform: 'translate(-14px, 12px) scale(1)',
    // },
    fontSize: 13,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: 10,
    },
  },

  disabledDrop: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[700],
  },

  selectNoLabel: {
    '& > div': {
      '& > div': {
        padding: 2,
      },
    },
  },

  control: {
    width: '100%',
    '& > div': {
      height: 40,
      padding: 0,
    },
    '& label': {
      fontSize: 13,
    },
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        padding: 0,
        height: 27,
      },
      '& label': {
        fontSize: 10,
      },
    },
  },

  input: {
    display: 'flex',
    width: '100%',
    padding: 0,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },

  placeholder: {
    fontSize: 13,
    position: 'absolute',
    left: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },

  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },

  chip: {
    margin: '0 2px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },

  chipFocused: {
    color: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08,
    ),
  },

  error: {
    '& + p': {
      position: 'absolute',
      right: 0,
    },
  },

  indicatorSeparator: {
    alignSelf: 'stretch',
    backgroundColor: theme.palette.primary.appPrimaryDividerColor,
    width: 1,
    boxSizing: 'border-box',
  },

  valueContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    position: 'relative',
    overflow: 'auto',
    boxSizing: 'border-box',
    padding: '0 5px',
  },

  clearIndicator: {
    display: 'flex',
    cursor: 'pointer',
    transition: 'color 150ms',
    color: theme.palette.primary.appPrimaryDisableIconColor,
  },

  chipIcon: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      fontSize: 16,
    },
  },
});

class Control extends React.PureComponent {
  constructor(params) {
    super(params);

    this.handleChangeText = lodashDebounce(
      this.unDebouncedHandleChangeText.bind(this),
      700,
    );
  }

  unDebouncedHandleChangeText(value) {
    const { selectProps } = this.props;
    const { resource, source, findTagData } = selectProps;

    findTagData({ resource, source, value });
  }

  render() {
    const { selectProps, innerRef, children, customError, ...props } = this.props;
    const { classes, meta, textFieldProps } = selectProps;
    const { touched, error } = meta;
    const hasError = !!(touched && error);

    return (
      <TextField
        className={classes.control}
        variant="outlined"
        error={!!customError || hasError}
        {...textFieldProps}
        onChange={event => {
          this.handleChangeText(event.target.value);
        }}
        helperText={customError ? customError : hasError ? error : undefined}
        InputProps={{
          inputComponent,
          inputProps: {
            ...props.innerProps,
            className: classes.input,
            inputRef: innerRef,
            children: children,
          },
          classes: {
            error: classes.error,
          },
        }}
      />
    );
  }
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={
        <Icon
          {...props.removeProps}
          fontSize="small"
          className={props.selectProps.classes.chipIcon}
        >
          close
        </Icon>
      }
    />
  );
}

const IndicatorSeparator = props => {
  return <span />;
};

const ValueContainer = props => {
  return (
    <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
  );
};

const ClearIndicator = props => {
  const { classes } = props.selectProps;
  return (
    <div className={classNames(classes.clearIndicator, 'clearIndicator')}>
      <Icon
        fontSize="small"
        onClick={props.clearValue}
        onTouchStart={props.clearValue}
      >
        close
      </Icon>
    </div>
  );
};

const components = {
  NoOptionsMessage,
  Placeholder,
  Control,
  MultiValue,
  IndicatorSeparator,
  ValueContainer,
  ClearIndicator,
};

class TagInput extends PureComponent {
  handleChange = selectedItem => {
    const currentValueArray =
      selectedItem && selectedItem.length ? selectedItem : [];
    const value = currentValueArray.map(item => item.value).join('#');

    this.props.onChange(value);
    this.props.input.onChange(value);
  };

  handleBlur = event => {
    this.props.onBlur(this.props.input.value);
    this.props.input.onBlur(this.props.input.value);
  };

  // componentDidMount() {
  //   const { record, resource, tag = {}, source, findTagData } = this.props;
  //   const { resultList = {}, loadingList = {} } = tag;
  //
  //   // already have data for this
  //   // or this property has no value!
  //   if (loadingList[source] || resultList[source] || !record) {
  //     return;
  //   }
  //
  //   findTagData({ resource, source, value: '' });
  // }

  render() {
    const {
      classes,
      theme,
      label,
      tag = {},
      disabled,
      findTagData,
      translate,
      record,
      resource,
      source,
      input = {},
      id,
      field,
      ...rest
    } = this.props;

    const { loadingList = {}, errorList = {}, resultList = {} } = tag;

    const isLoading =
      typeof loadingList[resource] !== 'undefined' && loadingList[resource] === true;

    if (errorList && errorList[resource]) {
      return <div>error loading</div>;
    }

    const currentValueArray =
      input.value && input.value.length ? input.value.split('#') : [];
    const preparedValue = currentValueArray.map(string => {
      return string && string.length ? { value: string, label: string } : null;
    });

    return (
      <CreatableSelect
        {...rest}
        isMulti
        source={source}
        isClearable
        classes={classes}
        resource={resource}
        value={preparedValue}
        isDisabled={disabled}
        translate={translate}
        isLoading={isLoading}
        components={components}
        findTagData={findTagData}
        closeMenuOnSelect={false}
        className={classNames(
          classes.select,
          !label ? classes.selectNoLabel : disabled ? classes.disabledDrop : null,
        )}
        onChange={this.handleChange}
        placeholder={translate('dropdown.placeholder')}
        loadingMessage={() => {
          return translate('ra.page.loading');
        }}
        formatCreateLabel={inputValue => {
          return translate('ra.page.create', { name: inputValue });
        }}
        options={
          resultList[resource]
            ? resultList[resource].map(tagItem => ({
                value: tagItem,
                label: tagItem,
              }))
            : []
        }
        textFieldProps={{
          label: (
            <FieldTitle
              label={label}
              source={source}
              resource={this.props.resource}
              isRequired={this.props.isRequired}
            />
          ),
          InputLabelProps: {
            shrink: true,
          },
        }}
      />
    );
  }
}

TagInput.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  customError: PropTypes.string,
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyObj = {};

TagInput.defaultProps = {
  onBlur: dummyFunc,
  onChange: dummyFunc,
  options: emptyObj,
};

const mapStateToProps = state => ({
  tag: state.tag, // app tag data object
});

const mapDispatchToProps = {
  findTagData,
};

const connectedTag = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(translate(TagInput)));

export default addField(connectedTag);
