import { ReactElement, useEffect, useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../../../helper/general-function-helper';

import { GeneralMetaData } from '../../../../helper/Types';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../../../../type/actor-setup';
import { FormActions, FormActionsHandler, InputRefContent } from '../../../form';
import { updateValuesOfInputsAndFormDataAfterSubmit } from '../../wms.helper';
import { WMSTableControllerProps } from './wms-table.type';
import WMSTableView from './wms-table.view';

/**
 * Prepare resource name.
 * @function getResource
 * @returns {string}
 */
const getResource = (metaData: GeneralMetaData | null): string | null => {
  if (metaData == null) {
    console.error('`getResource`: `tabMeta` is null');
    return null;
  }

  const moduleName = metaData.config?.moduleName;
  const tableName = metaData.config?.moduleTableName;

  return `${moduleName}/${tableName}`;
};

let resource: string | null;
let formActionsHandler: FormActionsHandler;

const WMSTableController = (props: WMSTableControllerProps): ReactElement | null => {
  const { tableMetaData, formTabIndex, activeTabIndex } = props;
  const { location, params } = actorGetActionValue('urlInfo')!;

  const dispatch = useDispatch();
  const translate = useTranslate();

  useMemo(() => {
    resource = getResource(tableMetaData);
  }, []);

  useEffect(() => {
    formActionsHandler = actorGetActionValue('formGlobalProps')!.formActionsHandler;

    if (resource != null) {
      dispatch({
        type: 'RA/REGISTER_RESOURCE',
        payload: {
          name: resource,
          options: {},
          hasList: true,
          hasEdit: false,
          hasShow: false,
          hasCreate: false,
        },
      });
    }
  }, []);

  if (formTabIndex !== activeTabIndex) return <></>;

  /**
   * This function call `crudCreate`.
   * @function onSubmit
   * @param {object} values
   * @param {object} params
   * @returns {void}
   */
  const onSubmit = (): void => {
    // clear any previous message in the stack
    //TODO: create hidden notification
    actorDispatch('notification', null);

    formActionsHandler(FormActions.Save, {
      resource,
      isCRUDCreate: true,
      callback: reduxActionResult => {
        const { error, payload } = reduxActionResult;

        if (error) {
          showNotification(reduxActionResult.error, 'error');
          return;
        }

        showNotification(translate('ra.notification.created'), 'info');
        // const rootResource = actorGetActionValue('resources')!.stack[0];
        // actorSetActionValue('formData', payload.data, {
        //   path: `${rootResource.value}.${rootResource.type}`,
        //   callerScopeName: 'WMSTableController => onSubmit',
        // });

        const currentResource = actorGetActionValue('resources')!.current;

        const refs = actorGetActionValue(
          'inputsRef',
          `${currentResource.value}.${currentResource.type}`,
        )! as Record<string, InputRefContent>;

        const fieldNameList =
          actorGetActionValue('formGlobalProps')!.inputNameListSortedByPriority;

        updateValuesOfInputsAndFormDataAfterSubmit({
          fieldNameList,
          inputsRef: refs,
          newFormData: payload.data,
        });

        requestAnimationFrame(() => {
          actorDispatch('refreshView', null);
        });
      },
      disableNotification: true,
      executeCallbackOnFailure: true,
      options: {},
    });

    // FIXME: What is it?
    // must bring focus down on grid footer
    // if (gridFooterRef) {
    //   gridFooterRef.scrollIntoView();
    // }
  };

  if (resource == null) {
    return null;
  }

  return (
    <WMSTableView
      tableMetaData={tableMetaData}
      resource={resource}
      urlInfo={{ location, params }}
      formTabIndex={formTabIndex}
      activeTabIndex={activeTabIndex}
      onSubmit={onSubmit}
    />
  );
};

export default WMSTableController;
