import React, { useState, useContext, useEffect } from 'react';
import compose from 'recompose/compose';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  crudDelete as crudDeleteAction,
  refreshView as refreshViewAction,
  translate,
  useLocale,
  GET_ONE,
} from 'react-admin';
import lodashGet from 'lodash/get';
import lodashDebounce from 'lodash/debounce';
import { Typography } from '@material-ui/core';
import TodoTaskItemSidebarView from './TodoTaskItemSidebarView';

import {
  closeDialogAction,
  deleteTodoTaskAction,
  insertStepAction,
} from '../../redux/todoList/action';
import LoadingBox from '../LoadingBox';
import { crudUpdateWithCallbackAction } from '../../redux/crud/action';
import { NewMetaContext } from '../../container/NewMetaContext';
import { parseDate } from '../../helper/DateHelper';
import {
  getTodoTaskDetailsPatternInfo,
  getTodoListPatternInfo,
} from '../../helper/PatternMetaHelper';
import dataProvider from '../../core/dataProvider';
import { isEmptyObject } from '../../helper/data-helper';
import { showNotification } from '../../helper/general-function-helper';

const useStyles = makeStyles(theme => ({
  error: {
    textAlign: 'center',
    margin: 10,
  },
}));

const TodoTaskItemSidebarContainer = props => {
  const {
    resource,
    taskId,
    closeDialog,
    update,
    refreshView,
    insertStep,
    translate,
    selectedTodoList,
    listResource,
    deleteTask,
    crudDelete,
    detailResource,
  } = props;
  const classes = useStyles();
  const locale = useLocale();

  const [queryOptionForGetDataAgain, setQueryOptionForGetDataAgain] = useState(0);

  const { getMeta } = useContext(NewMetaContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState(null);

  const isTaskListShared = () => {
    if (!listResource || isEmptyObject(selectedTodoList)) return;
    const { isSharedList } = getTodoListPatternInfo(listResource);
    return selectedTodoList[isSharedList];
  };

  useEffect(() => {
    setLoading(true);
    setData(null);
    dataProvider(GET_ONE, detailResource, {
      id: taskId,
      // queryParams: { withFile: true, withRelation: true },
    })
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [taskId, queryOptionForGetDataAgain]);

  if (!detailResource) {
    return <div />;
  }

  const taskDetailsPattern = getTodoTaskDetailsPatternInfo(detailResource);
  const { stepsResource, stepsRelationChildFieldName } = taskDetailsPattern;
  const metaData = getMeta(detailResource);

  const handleParseDate = date => {
    if (!date) {
      return;
    }
    return parseDate(date, translate);
  };

  const getStepsList = data => {
    const stepsRelation = `${stepsResource}/${stepsRelationChildFieldName}`;
    if (!data || !data[stepsRelation] || !data[stepsRelation].TotalCount) {
      return [];
    }
    return data[stepsRelation].Data;
  };

  /**
   * Update task every 2 seconds when `change` event fires by a child
   * @function handleUpdateTask
   * @returns {void}
   */
  const handleUpdateTask = () =>
    lodashDebounce(params => {
      const {
        data,
        ref = null,
        itemResource = detailResource,
        itemId = taskId,
        previousData,
        shouldRefresh = false,
      } = params;

      update(
        itemResource,
        itemId,
        data,
        previousData,
        () => {
          if (shouldRefresh) {
            handleRefreshDataTask();
          } else if (ref && ref.current && ref.current.focus) {
            setTimeout(() => {
              ref.current.focus();
            }, 1);
          }
        },
        true,
      );
    }, 2000);

  const handleDeleteTask = serviceId => {
    const {
      sort,
      pagination,
      reportResource,
      taskId: taskIdPattern,
    } = getTodoListPatternInfo(listResource);
    const data = {
      [taskIdPattern]: taskId,
    };
    deleteTask(
      serviceId,
      data,
      detailResource,
      reportResource,
      sort,
      pagination,
      '',
    );
    closeDialog();
  };

  const handleDeleteStep = (itemId, resource) => {
    try {
      crudDelete(resource, itemId, {}, null, false);
      // give a little timeout for delete request to complete, and then refresh
      setTimeout(() => {
        handleRefreshDataTask();
      }, 100);
    } catch (error) {
      showNotification(error.message, 'warning');
    }
  };

  const handleInsertTask = (resource, data) => {
    try {
      insertStep(resource, data);
      // give a little timeout for POST request to complete, and then refresh
      setTimeout(() => {
        handleRefreshDataTask();
        refreshView();
      }, 100);
    } catch (error) {
      showNotification(error.message, 'warning');
    }
  };

  const handleRefreshDataTask = () => {
    setQueryOptionForGetDataAgain(counter => counter + 1);
  };

  if (loading) {
    return <LoadingBox />;
  }

  if (error) {
    const userMessage = lodashGet(
      error,
      ['response', 'data', 'userMessage'],
      error?.userMessage ?? error,
    ).toString();
    return (
      <Typography variant="body1" className={classes.error}>
        {userMessage}
      </Typography>
    );
  }
  if (!data) return null;

  return (
    <TodoTaskItemSidebarView
      dataTask={data}
      steps={getStepsList(data)}
      taskId={taskId}
      locale={locale}
      resource={resource}
      detailResource={detailResource}
      metaData={metaData}
      selectedTodoList={selectedTodoList}
      isTaskListShared={isTaskListShared()}
      listResource={listResource}
      taskDetailsPattern={taskDetailsPattern}
      handleDeleteTask={handleDeleteTask}
      handleUpdateTask={handleUpdateTask}
      handleInsertTask={handleInsertTask}
      handleParseDateTask={handleParseDate}
      handleRefreshDataTask={handleRefreshDataTask}
      handleDeleteStep={handleDeleteStep}
    />
  );
};

TodoTaskItemSidebarContainer.propTypes = {};

const mapStateToProps = state => ({
  resource: state.todoList.dialogTaskResource,
  taskId: state.todoList.dialogDataTaskId,
  selectedTodoList: state.todoList.dialogSelectedTodoList,
  listResource: state.todoList.dialogListResource,
  isOpen: state.todoList.isOpen,
});

const mapDispatchToProps = {
  deleteTask: deleteTodoTaskAction,
  closeDialog: closeDialogAction,
  refreshView: refreshViewAction,
  update: crudUpdateWithCallbackAction,
  insertStep: insertStepAction,
  crudDelete: crudDeleteAction,
};

export default compose(
  translate,
  connect(mapStateToProps, mapDispatchToProps),
)(TodoTaskItemSidebarContainer);
