import React, { ReactElement } from 'react';
import { useTranslate } from 'react-admin';

import { WMSLayoutWithTabs } from './wms-layout-with-tabs';
import { useStyles } from './wms.style';
import { FinalLayoutViewProps, WMSLayoutProps } from './wms.type';
import WMSAction from './wms-layouts/wms-action/wms-action.controller';
import WMSReport from './wms-layouts/wms-report/wms-report.controller';
import WMSTable from './wms-layouts/wms-table/wms-table.controller';
import NotFound from '../NotFound';

export const FinalLayoutView = (props: FinalLayoutViewProps): ReactElement => {
  const { wmsMetaData, tabData, formTabIndex, activeTabIndex } = props;

  const translate = useTranslate();

  if (tabData?.item == null) {
    return <NotFound title={translate('meta.resourceIsNotDefined')} />;
  }

  const { item: tabItemData } = tabData;
  const actionMetaData = tabItemData.action;
  const tableMetaData = tabItemData.table;
  const reportMetaData = tabItemData.report;

  return (
    <>
      {actionMetaData != null && (
        <WMSAction
          actionMetaData={actionMetaData}
          actionExecutionConfirm={tabItemData.actionExecutionConfirm}
          validationActionId={wmsMetaData.validationActionId}
          formTabIndex={formTabIndex}
          activeTabIndex={activeTabIndex}
        />
      )}
      {tableMetaData != null && (
        <WMSTable
          tableMetaData={tableMetaData}
          formTabIndex={formTabIndex}
          activeTabIndex={activeTabIndex}
        />
      )}
      {reportMetaData != null && (
        <WMSReport
          reportMetaData={reportMetaData}
          layoutRows={tabItemData.layoutRows}
          formTabIndex={formTabIndex}
          activeTabIndex={activeTabIndex}
        />
      )}
    </>
  );
};

const WMSLayout = (props: WMSLayoutProps): ReactElement | null => {
  const { wmsMetaData } = props;

  const classes = useStyles();

  if (Array.isArray(wmsMetaData.tabs) && wmsMetaData.tabs.length > 1) {
    return (
      <div className={classes.wmsLayoutContainer}>
        <div className={classes.tabsContainer}>
          <WMSLayoutWithTabs wmsMetaData={wmsMetaData} />
        </div>
      </div>
    );
  }

  const tabData = wmsMetaData.tabs[0];
  return (
    <div className={classes.wmsLayoutContainer}>
      <FinalLayoutView wmsMetaData={wmsMetaData} tabData={tabData} />
    </div>
  );
};

export default WMSLayout;
