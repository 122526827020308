import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { Box } from '@material-ui/core';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

const NoUserScreen = (): ReactElement => {
  const translate = useTranslate();

  return (
    <Box id="noneUser">
      <ChatBubbleOutlineIcon />
      <Box component="p">{translate('chat.startChat')}</Box>
    </Box>
  );
};

export default NoUserScreen;
