import { makeStyles } from '@material-ui/core';
import { StyleProps } from '.';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  appsContainer: (props: StyleProps) => ({
    height: '98%',
    display: 'flex',
  }),
  appsMainContent: props => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${props.fullView ? 0 : 17}rem)`,
      paddingLeft: props.fullView ? 0 : 10,
    },
    [theme.breakpoints.up('xl')]: {
      width: `calc(100% - ${props.fullView ? 0 : 22}rem)`,
    },
  }),
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuIcon: {
    width: 35,
    height: 35,
  },
  scLauncher: {
    '& .sc-header, & .sc-message--content.sent .sc-message--text, & .sc-header--close-button:hover':
      {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
  },
}));
