import { makeStyles } from '@material-ui/core';
import { getColorBaseOfStatus } from '../../input-base/input-message/input-message.helper';
import { CustomTheme } from './../../../core/themeProvider';
import { StyleProps } from './file-input.type';

export const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  root: {
    border: '1px solid #BDBDBD',
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.primary.appSecondaryBackgroundColor,
    padding: 0,
    margin: 0,
    height: '100%',
    flexGrow: 1,
    flexBasis: 200,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    contain: 'content',
  },
  disabled: {
    backgroundColor: '#e0e0e0',
    cursor: 'default',
    color: '#bababa',
  },

  legend: {
    fontSize: 10,
    lineHeight: 0,
    color: theme.palette.primary.appSecondaryTextColor,
    display: 'block',
    padding: '0 3px',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
    },
  },

  fieldsetMessage: {
    border: props => `1px solid ${getColorBaseOfStatus(props.messageType, theme)}`,
  },

  legendError: {
    color: props => getColorBaseOfStatus(props.messageType, theme),
  },

  customFileInput: {
    width: 0,
    opacity: 0,
    cursor: 'pointer',
    padding: 0,
    height: 35,
  },

  input: {
    '&[disabled]': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },
  },

  error: {
    '& + p': {
      position: 'absolute',
      right: 0,
    },
  },

  errorText: {
    position: 'absolute',
    right: 10,
    top: 10,

    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
      top: 2,
    },
  },

  inputFileValueName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    overflow: 'hidden',
    height: '100%',
    width: '50%',
    padding: 4,
    flex: 2,
    [theme.breakpoints.down('sm')]: {
      padding: '4px 10px',
      fontSize: 8,
    },
  },

  uploadArea: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.appPrimaryBackgroundColor,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2rem',
    },
  },

  uploadBox: {
    border: `1px dashed ${theme.palette.grey[300]}`,
    padding: '1rem',
  },

  previewImage: {
    display: 'flex',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '90%',
    width: '90%',
    maxWidth: ({ calcHeight }) => calcHeight,
  },
  fileName: {
    display: 'flex',
    flex: 1,
    paddingRight: 10,
    paddingLeft: 5,
    width: '20px',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
  },
  fileSizeSpan: {
    direction: 'initial',
  },
  wrap: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
}));
