import { FC } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import {
  Button,
  Icon,
  IconButton,
  makeStyles,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import ConfirmDialogHOC from './ConfirmDialogHOC';
import { crudDeleteMany as crudDeleteManyAction } from '../redux/crud/action';
import { AdditionalPropsInterface } from '../helper/Types';
import { refreshRelationAction } from '../redux/relation/action';
import { actorDispatch } from '../type/actor-setup';
interface BulkDeleteButtonPropsInterface {
  resource: string;
  selectedIds: number[];
  basePath: string;
  bulkDeleteButtonOnViewPage: boolean;
  disabled: boolean;
  treeLevel: number;
  disableDelete: boolean;
  openConfirmDialog: Function;
  additionalProps: AdditionalPropsInterface;
  crudDeleteMany: Function;
  clearSelection: Function;
  onDeleteSuccessCallback?: () => void;
  relationMode?: boolean;
}

const useStyles = makeStyles(theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },

  IconButton: {
    padding: 7,
    margin: '0 5px',
  },

  deleteButtonContainer: {
    display: 'flex',
  },
}));

const BulkDeleteButton: FC<BulkDeleteButtonPropsInterface> = props => {
  const {
    bulkDeleteButtonOnViewPage,
    disabled,
    treeLevel,
    disableDelete,
    openConfirmDialog,
    basePath,
    crudDeleteMany,
    resource,
    selectedIds,
    onDeleteSuccessCallback,
    relationMode,
    clearSelection,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();
  const theme = useTheme();

  /**
   * run `openConfirmDialog`
   * @function handleClick
   * @returns {void}
   */
  const handleClick = (event): void => {
    openConfirmDialog({
      content: translate('confirm.areYouSureYouWantToDeleteThisItem'),
      onConfirm: handleClickDelete,
      color: theme.palette.error.main,
    });
  };

  /**
   * Run `crudDeleteMany` with callback.
   * @function handleClickDelete
   * @returns {void}
   */
  const handleClickDelete = (): void => {
    actorDispatch('loading', true, {
      path: resource,
    });
    crudDeleteMany(resource, selectedIds, basePath, true, false, () => {
      //after refactor this component we can remove bellow line
      actorDispatch('loading', false, {
        path: resource,
      });

      if (relationMode) {
        onDeleteSuccessCallback?.();
      } else {
        clearSelection();
      }
    });
  };

  const treeCondition =
    treeLevel && typeof treeLevel === 'number' && +selectedIds.length > 1;

  return bulkDeleteButtonOnViewPage ? (
    <Tooltip title={translate('ra.action.delete')}>
      <div className={classes.deleteButtonContainer}>
        <IconButton
          disabled={disabled || treeCondition || disableDelete}
          color="primary"
          className={classes.IconButton}
          onClick={handleClick}
          id="deleteButton"
        >
          <Icon fontSize="small">delete</Icon>
        </IconButton>
      </div>
    </Tooltip>
  ) : (
    <div>
      <Button
        disabled={disabled || treeCondition || disableDelete}
        onClick={handleClick}
        className={classes.deleteButton}
      >
        <Icon>delete</Icon>
        {translate('ra.action.delete')}
      </Button>
    </div>
  );
};

const mapDispatchToProps = {
  crudDeleteMany: crudDeleteManyAction,
  refreshRelation: refreshRelationAction,
};

export default compose(
  ConfirmDialogHOC,
  connect(null, mapDispatchToProps),
)(BulkDeleteButton);
