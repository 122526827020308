import React, {
  FC,
  Children,
  useRef,
  useState,
  useEffect,
  ReactChildren,
} from 'react';
import { connect } from 'react-redux';
import {
  Icon,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { useDrag, useDrop } from 'react-dnd';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useContextMenu } from 'react-contexify';

import GroupIcon from '../../icon/GroupIcon';
import TodoListContextMenu from './TodoListContextMenu';
import { isEnterPressed, isEscapePressed } from '../../helper/FormHelper';
import lodashGet from 'lodash/get';
import { toggleGroupExpandedAction } from '../../redux/todoList/action';
import { ContextMenuItemType, TodoListPatternType } from '../../helper/Types';
import { CustomTheme } from '../../core/themeProvider';

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 15px',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#FAFAFA',
    },
  },

  icon: {
    margin: '0 6px 0 0',
    padding: 0,
    fontSize: 25,
  },

  groupListContainer: {
    opacity: 0,
  },

  expansionPanel: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: theme.palette.primary.main,
    padding: '10px 0 10px 15px',
    transition: 'background-color 200ms',
    '&:hover': {
      backgroundColor: '#FAFAFA',
      transition: 'background-color 200ms',
    },
  },

  expanded: {
    margin: '0 !important',
  },

  expansionPanelSummaryRoot: {
    margin: '0 !important',
    padding: '0 !important',
    minHeight: 'auto !important',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  groupTitle: {},

  content: {
    margin: '0 !important',
    alignItems: 'center',
  },

  expandIcon: {
    padding: 0,
    margin: '0 9px',
  },

  expansionPanelDetails: {
    borderLeft: `2px solid ${theme.palette.primary.appPrimaryDividerColor}`,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 7,
    padding: 0,
    transition: 'background-color 200ms',
    '& > div:hover': {
      backgroundColor: theme.palette.primary.appPrimaryBackgroundColor,
      transition: 'background-color 200ms',
    },
  },

  editGroupInput: {
    border: 'none',
    outline: 'none',
    padding: '5px 0',
    margin: '0 10px 0 10px',
    flex: 1,
    fontFamily: theme.typography.fontFamily,
  },
}));

interface TodoMenuGroupItemType {
  children: ReactChildren;
  dragListAcceptType: string;
  dragGroupAcceptType: string;
  addChildToGroup: Function;
  updateRowOrder: Function;
  updateGroupName: Function;
  listChild: [];
  todoListPattern: TodoListPatternType;
  uniqueKey: string;
  lastRowOrder: number;
  record: object;
  deleteGroup: Function;
  ungroup: Function;
  expandedGroupObj: object;
  toggleGroupExpanded: Function;
  title: string;
  resource: string;
}

const TodoMenuGroupItem: FC<TodoMenuGroupItemType> = props => {
  const {
    children,
    dragListAcceptType,
    dragGroupAcceptType,
    addChildToGroup,
    updateRowOrder,
    updateGroupName,
    listChild,
    todoListPattern,
    uniqueKey,
    lastRowOrder,
    record,
    deleteGroup,
    ungroup,
    expandedGroupObj,
    toggleGroupExpanded,
  } = props;
  const classes = useStyles(props);
  const translate = useTranslate();

  let title = props.title;
  const { rowOrder, groupId, idListMember, groupTitle } = todoListPattern;

  const [showEditGroupInput, setShowEditGroupInput] = useState(false);
  const [editGroupName, setEditGroupName] = useState(title);

  const ref = useRef(null);
  const groupInputRef = useRef<HTMLInputElement>(document.createElement('input'));

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideGroupInput);
  }, []);

  /**
   * Call specified function after each context menu item click
   * @function contextMenuOnItemClickHandler
   * @param {ContextMenuItemType} item
   * @returns {void}
   */
  const handleClickOutsideGroupInput = event => {
    if (
      groupInputRef &&
      groupInputRef.current &&
      !groupInputRef.current.contains(event.target)
    ) {
      setShowEditGroupInput(false);
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: dragListAcceptType,

    drop(item) {
      if (item[groupId]) {
        return;
      }

      const data = {
        data: {
          [groupId]: record[groupId],
        },
        id: item[idListMember],
      };
      addChildToGroup(data);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  const [{ groupIsOverGroup }, dropGroup] = useDrop({
    accept: dragGroupAcceptType,

    drop(item) {
      const newRowOrder = (lastRowOrder + record[rowOrder]) / 2;

      const data = {
        data: {
          [rowOrder]: newRowOrder,
        },
        id: item[idListMember],
      };

      updateRowOrder(data);
    },
    collect: monitor => ({
      groupIsOverGroup: monitor.isOver(),
    }),
  });

  const [, drag] = useDrag({
    item: {
      type: dragGroupAcceptType,
      [groupId]: record[groupId],
      [rowOrder]: record[rowOrder],
      [idListMember]: record[idListMember],
    },
  });

  dropGroup(drop(drag(ref)));

  const handleChangeGroupText = event => {
    setEditGroupName(event.target.value);
  };

  const groupInputKeyPress = event => {
    if (isEnterPressed(event)) {
      title = editGroupName;
      setEditGroupName(editGroupName);
      setShowEditGroupInput(false);

      const data = {
        data: {
          [groupTitle]: editGroupName,
        },
        id: record[groupId],
      };
      updateGroupName(data);
    }

    if (isEscapePressed(event)) {
      setEditGroupName(title);
      setShowEditGroupInput(false);
    }
  };

  const showEditGroupName = () => {
    setShowEditGroupInput(true);

    setTimeout(() => {
      if (
        groupInputRef &&
        groupInputRef.current &&
        'focus' in groupInputRef.current
      ) {
        groupInputRef.current.focus();
      }
    }, 100);
  };

  const handleDeleteGroup = () => {
    const data = {
      [groupId]: record[groupId],
    };
    deleteGroup(data);
  };

  const handleUngroup = () => {
    const data = {
      [groupId]: record[groupId],
    };
    ungroup(data);
  };

  const handleExpandedChange = () => {
    toggleGroupExpanded(record[idListMember]);
  };

  const contextMenuItems: ContextMenuItemType[] = [
    {
      title: translate('todo.renameGroup'),
      icon: 'edit',
      dataTestAttribute: 'rename',
    },
  ];

  if (listChild.length) {
    contextMenuItems.push({
      title: translate('todo.ungroup'),
      icon: 'delete',
      dataTestAttribute: 'ungroup',
    });
  } else {
    contextMenuItems.push({
      title: translate('todo.deleteGroup'),
      icon: 'delete',
      dataTestAttribute: 'delete',
    });
  }

  /**
   * Fire item onClick based on `dataTestAttribute`
   * @function contextMenuOnItemClickHandler
   * @param {ContextMenuItemType} item
   * @returns {void}
   */
  const contextMenuOnItemClickHandler = (item: ContextMenuItemType) => {
    switch (item.dataTestAttribute) {
      case 'rename':
        showEditGroupName();
        break;
      case 'ungroup':
        handleUngroup();
        break;
      case 'delete':
        handleDeleteGroup();
        break;

      default:
        break;
    }
  };

  const { show } = useContextMenu({
    id: uniqueKey,
  });

  return showEditGroupInput ? (
    <div>
      <input
        className={classes.editGroupInput}
        ref={groupInputRef}
        onKeyDown={groupInputKeyPress}
        value={editGroupName}
        data-test-todo-group-name={editGroupName}
        onChange={event => {
          handleChangeGroupText(event);
        }}
      />
    </div>
  ) : (
    <div
      ref={ref}
      style={{
        backgroundColor: isOver ? '#FAFAFA' : 'transparent',
        borderTop: groupIsOverGroup ? '1px dotted #999' : 'unset',
      }}
    >
      <TodoListContextMenu
        handleItemClick={contextMenuOnItemClickHandler}
        contextMenuItems={contextMenuItems}
        uniqueKey={uniqueKey}
      />
      <ExpansionPanel
        onContextMenu={show}
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expanded,
        }}
        expanded={!!lodashGet(expandedGroupObj, record[idListMember])}
        onChange={handleExpandedChange}
      >
        <ExpansionPanelSummary
          classes={{
            root: classes.expansionPanelSummaryRoot,
            content: classes.content,
            expandIcon: classes.expandIcon,
          }}
          expandIcon={<Icon fontSize="small">expand_more</Icon>}
        >
          <GroupIcon className={classes.icon} />
          <Typography data-test-group-name={title} variant="caption">
            {title}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: classes.expansionPanelDetails,
          }}
        >
          {/* @ts-ignore */}
          {Children.map(children, item => item)}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

const mapStateToProps = state => ({
  expandedGroupObj: lodashGet(state, ['todoList', 'expandedGroupObj']),
});

const mapDispatchToProps = {
  toggleGroupExpanded: toggleGroupExpandedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TodoMenuGroupItem);
