import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
    },
    '&:hover': {
      '&::-webkit-scrollbar': {
        width: 3,
      },
    },
  },

  avatar: {
    width: 50,
    height: 50,
  },
  breakAll: {
    wordBreak: 'break-all',
  },
  pointer: {
    cursor: 'pointer',
  },
  btnRoot: {
    paddingLeft: 32,
    paddingRight: 32,
    color: '#fff',
  },

  referenceBtn: {
    background: '#0079D5',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
    },
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
  },

  replayBtn: {
    [theme.breakpoints.down('sm')]: {
      // display: 'block',
      width: '100%',
      marginTop: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
  },

  editorContainer: {
    '& .ck.ck-toolbar': {
      border: 'none !important',
    },
  },
  formContainer: {
    minHeight: '400px',
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: '0 1rem',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
  },

  inputContainer: {
    flexGrow: 1,
    margin: '.5rem 0',
    maxHeight: '40px',
    alignItems: 'inherit !important',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& .ck.ck-editor__main': {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    '& .ck-editor__editable': {
      minHeight: '200px !important',
      minWidth: '100%',
    },
  },

  moreIcon: {
    background: '#e8eaed',
    borderRadius: 8,
    width: 24,
  },
  attachmentContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 10,
  },
  chip: {
    borderRadius: '8px',
    marginTop: '5px',
  },
  downloadAllChip: {
    borderRadius: '8px',
    marginTop: '2px',
    backgoroundColor: '#0079D5',
  },
  actionButtonContainer: {
    marginTop: '2px',
  },
}));
