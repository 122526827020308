import { saveAs } from 'save-files';
import { Translate } from '../../../helper/Types';
import type { AttachedFile } from '../../mail-section/mail-detail/mail-detail-body/mail-detail-body.type';

/**
 * generate error message
 * @function generateErrorMessage
 * @param {Array} largerThanAllowedFiles
 * @param {Array} apiErrors
 * @param {function} translate
 * @returns {string} errorMessage
 */
export const generateErrorMessage = (
  largerThanAllowedFiles: Array<File>,
  apiErrors: Array<{ fileName: string; message: string }>,
  translate: Translate,
): string => {
  let errorMessage = '';

  if (largerThanAllowedFiles.length) {
    if (largerThanAllowedFiles.length === 1) {
      // has one large file
      errorMessage += `${translate('file.file')} ${
        largerThanAllowedFiles[0].name
      } ${translate('file.couldNotSendFileBecauseItsLargeSize')}`;
    } else {
      // has few large file
      errorMessage += `${translate('file.files')}‍ `;

      largerThanAllowedFiles.forEach((file, index) => {
        errorMessage += file.name;

        // `largerThanAllowedFiles.length - 1` is last item of errors
        if (index !== largerThanAllowedFiles.length - 1) {
          errorMessage += ` ${translate('file.and')}‍ `;
        }

        errorMessage += '\n';
      });

      errorMessage += `${translate('file.couldNotSendFilesBecauseItsLargeSize')}‍`;
    }
  }

  if (apiErrors.length) {
    if (errorMessage) {
      // add some space between errors
      errorMessage += '\n\n';
    }

    apiErrors.forEach(error => {
      const { fileName, message } = error;

      if (message.includes('^')) {
        // its not require to add request id in this case
        const errorArray = message.split('^');
        errorMessage += `${translate('file.file')} ${fileName} : ${errorArray[0]}`;
      } else {
        errorMessage += `${translate('file.file')} ${fileName} : ${message}`;
      }

      errorMessage += '\n';
    });
  }
  return errorMessage;
};

/**
 * @function delay
 * @param milliseconds
 * @returns {Promise<void>}
 */
export const delay = milliseconds =>
  new Promise(resolve => {
    setTimeout(resolve, milliseconds);
  });

/**
 * It creates a link to download a file with real and clear name
 * @function downloadAllAttachmentsHandler
 * @param { AttachedFile[] } files
 * @param { string } apiUrl
 * @returns { Promise<void> } function
 */
export const downloadAll = async (files: AttachedFile[], apiUrl: string): Promise<void> => {
  for (const [index, file] of files.entries()) {
    saveAs(`${apiUrl}/${file.filePath}`, file.realFileName);
    await delay(index * 1000);
  }
};
