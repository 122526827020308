import React, { FC, useEffect, useRef, useState, ReactElement } from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

import { crudGetListWithCustomQueryAction } from '../../redux/crud/action';
import { customSort, isEmptyObject } from '../../helper/data-helper';
import { createTreeDataList } from '../../helper/TreeHelper';
import { isTreeHasDelete } from '../../helper/MetaHelper';
import CheckResourceReady from '../CheckResourceReady';
import ListActions from '../../component/ListActions';
import SettingToolbar from '../../component/SettingToolbar';
import TreeViewContainer from './TreeViewContainer';
import { TreeRow } from '../../helper/Types';

interface TreeController {
  ViewComponent: ReactElement;
  crudGetListWithCustomQuery: Function;
  data: object;
  ids: (number | string)[];
  resource: string;
  locale: string;
  metaData: { idPattern: string };
  listActionComponent: ReactElement;
  settingToolbarComponent: ReactElement;
  treeLevel?: number;
  viewVersion: number;
  isDropdown: boolean;
  useInFilterInput?: boolean;
}

interface ViewProps {
  tree: TreeRow[];
  selectedId?: string | number;
  setSelectedId: Function;
  isDropdown: boolean;
  setDisableDelete: Function;
  displayField: string;
  ids: (number | string)[];
}
interface ListActionProps {
  resource: string;
  locale: string;
  metaData: object;
  hasCreate: boolean;
  hasDelete: boolean;
  treeLevel: number;
  disableDelete: boolean;
  selectionRef: object;
}

const TreeController: FC<TreeController> = props => {
  const {
    ViewComponent = TreeViewContainer,
    crudGetListWithCustomQuery,
    metaData,
    data,
    resource,
    ids,
    locale,
    listActionComponent = <ListActions quickCreateMustRefresh hidePrint />,
    settingToolbarComponent = <SettingToolbar />,
    viewVersion,
    isDropdown = false,
    useInFilterInput = false,
  } = props;

  const [tree, setTree] = useState<TreeRow[]>([]);
  const [selectedId, setSelectedId] = useState<string | number>();
  const [disableDelete, setDisableDelete] = useState<boolean>(false);

  const selectionRef = useRef();

  // constants
  const hasCreate = true;
  const hasDelete: boolean = isTreeHasDelete(metaData);
  const treeLevel: number = isDropdown
    ? props.treeLevel
    : lodashGet(metaData, ['config', 'treeLevel']);

  const displayField: string = isDropdown
    ? lodashGet(props, ['metaData', 'displayMember'])
    : lodashGet(
        metaData,
        [
          'fields',
          lodashGet(metaData, ['config', 'treeDisplayField']),
          'relatedName',
        ],
        undefined,
      );

  useEffect(() => {
    // get tree data
    if (!isDropdown) {
      crudGetListWithCustomQuery(
        resource,
        { page: 1, perPage: 10000 },
        { field: 'id', order: 'asc' },
      );
    }
  }, [viewVersion]);

  useEffect(() => {
    // make tree data from flat data
    const dataValues: TreeRow[] = isDropdown
      ? !isEmptyObject(data)
        ? Object.values(data)
        : []
      : !isEmptyObject(data)
      ? Object.values(data)
      : [];

    const sortedData: TreeRow[] = isDropdown
      ? customSort(dataValues, displayField)
      : customSort(
          dataValues.filter(row => ids.includes(+row.id)),
          displayField,
        );

    setTree(
      isDropdown
        ? createTreeDataList(
            sortedData,
            selectedId,
            treeLevel,
            displayField,
            isDropdown,
            lodashGet(metaData, 'idPattern'),
          )
        : createTreeDataList(sortedData, selectedId, treeLevel, displayField),
    );
  }, [data]);

  const viewProps: ViewProps = {
    tree,
    selectedId,
    setSelectedId,
    isDropdown,
    setDisableDelete,
    displayField,
    ids,
  };

  const listActionProps: ListActionProps = {
    resource,
    locale,
    metaData,
    hasCreate,
    hasDelete,
    treeLevel,
    disableDelete,
    selectionRef,
  };

  const settingToolbarProps = {
    locale,
    metaData,
    resource,
    isFilterEnable: false,
    isGroupingEnable: false,
    isColumnChoiceEnable: false,
    fields: Object.values(lodashGet(metaData, 'fields', {})),
  };

  if (isDropdown) {
    return (
      <ViewComponent
        useInFilterInput={useInFilterInput}
        {...props}
        {...viewProps}
        actions={null}
      />
    );
  } else {
    return (
      <CheckResourceReady resourceName={resource}>
        <ViewComponent
          {...props}
          {...viewProps}
          actions={React.cloneElement(listActionComponent, listActionProps)}
          selectionRef={selectionRef}
          settingToolbar={
            !isDropdown
              ? React.cloneElement(settingToolbarComponent, settingToolbarProps)
              : null
          }
          useInFilterInput={useInFilterInput}
        />
      </CheckResourceReady>
    );
  }
};

function mapStateToProps(state, props) {
  const { isDropdown = false, data = {} } = props;
  const resourceState = lodashGet(state, ['admin', 'resources', props.resource]);

  return {
    ids: lodashGet(resourceState, ['list', 'ids']),
    selectedIds: lodashGet(resourceState, ['list', 'selectedIds']),
    data: isDropdown ? data : lodashGet(resourceState, 'data'),
    viewVersion: lodashGet(state, ['admin', 'ui', 'viewVersion']),
  };
}

const mapDispatchToProps = {
  crudGetListWithCustomQuery: crudGetListWithCustomQueryAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TreeController);
