import { Button, Card } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import moment from 'moment-jalaali';
import { ReactElement } from 'react';
import { useLocale } from 'react-admin';
import { Calendar } from 'react-datepicker2-samian';
import ServiceButtonContainer from '../../container/ServiceButtonContainer';
import { SERVER_DATE_TIME_FORMAT } from '../../core/configProvider';
import { BigCalendar } from '../big-calendar';
import ListFilter from '../ListFilter';
import { ReportCalendarFilter } from './report-calendar-filter';
import { ReportCalendarToolbar } from './report-calendar-toolbar';
import { useStyles } from './report-calendar.style';
import { ReportCalendarViewInterface } from './report-calendar.type';
const defaultStyles = {
  calendarContainer: 'calendarContainer',
  heading: 'heading',
  prev: 'prev',
  next: 'next',
  title: 'title',
  dayWrapper: 'dayWrapper',
  currentMonth: 'currentMonth',
  daysOfWeek: 'daysOfWeek',
  yearsList: 'yearsList',
  monthsList: 'monthsList',
  selected: 'selected',
  today: 'today',
  dayPickerContainer: 'dayPickerContainer',
  disabled: 'disabled',
};

const ReportCalendarView = (props: ReportCalendarViewInterface): ReactElement => {
  const {
    openMenuInResponsiveMode,
    metaData,
    filters,
    calendarEvents,
    isLoading,
    resource,
    anchorEl,
    selectedCalendarEvent,
    eventCustomProps,
    listFilterProps,
    calendarEventsForFilterSection,
    addFilter,
    setOpenMenuInResponsiveMode,
    setCalendarEvents,
    onRangeChangeCallback,
    updateCalendarEventCallback,
    onSelectSlotCallback,
    getCalendarData,
    onViewCallback,
    customEmptyState,
    visibleServices,
  } = props;
  const classes = useStyles();
  const locale = useLocale();

  return (
    <div className={classes.root}>
      <ListFilter
        metaData={metaData}
        locale={locale}
        resource={resource}
        {...listFilterProps}
      />
      <Card className={classes.card}>
        <Card
          className={`${classes.right} ${
            openMenuInResponsiveMode ? classes.rightOpen : classes.rightClose
          }`}
        >
          <div className={classes.rootRight}>
            <div className={classes.top}>
              <div id="toolbar-big-calendar" />
              <Calendar
                styles={{
                  ...defaultStyles,
                  calendarContainer: `${defaultStyles.calendarContainer} ${classes.containerCalender}`,
                }}
                isGregorian={false}
                calendarClass={classes.calendar}
                onSelect={date =>
                  addFilter({
                    date: date.format(SERVER_DATE_TIME_FORMAT),
                  })
                }
                value={moment(filters.date)}
                selectedDay={moment(filters.date)}
              />
            </div>
            <div>
              <ReportCalendarFilter
                metaData={metaData}
                calendarEventsForFilter={calendarEventsForFilterSection}
                calendarEvents={calendarEvents}
                setCalendarEvents={setCalendarEvents}
              />
            </div>
          </div>
          <Button
            size="small"
            className={classes.closeArrow}
            onClick={() => setOpenMenuInResponsiveMode(!openMenuInResponsiveMode)}
          >
            {openMenuInResponsiveMode ? (
              <KeyboardArrowRightIcon />
            ) : (
              <KeyboardArrowLeftIcon />
            )}
          </Button>
        </Card>
        <div className={classes.left}>
          <BigCalendar
            CustomToolbarView={ReportCalendarToolbar}
            currentDate={new Date(filters.date)}
            calendarEvents={calendarEvents}
            setCalendarEvents={setCalendarEvents}
            isLoading={isLoading}
            calendarEventCustomProps={eventCustomProps}
            onRangeChangeCallback={onRangeChangeCallback}
            updateCalendarEventCallback={updateCalendarEventCallback}
            onSelectSlotCallback={onSelectSlotCallback}
            onViewCallback={onViewCallback}
            view={filters.type}
          />
          <ServiceButtonContainer
            customRefresh={getCalendarData}
            locale={locale}
            resource={resource}
            selectedIds={selectedCalendarEvent ? [selectedCalendarEvent?.id] : []}
            metaData={metaData}
            initialData={selectedCalendarEvent ? [selectedCalendarEvent] : []}
            customAnchorEl={anchorEl}
            visibleServices={visibleServices}
            customEmptyState={customEmptyState}
            withoutButton
          />
        </div>
      </Card>
    </div>
  );
};

export default ReportCalendarView;
