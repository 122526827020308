import { put, takeEvery } from 'redux-saga/effects';
import { UPDATE_REPORT_DATA } from './constant';
import { refreshView } from 'react-admin';

import dataProvider, { UPDATE_REPORT } from '../../core/dataProvider';
import { showNotification } from '../../helper/general-function-helper';

function* updateOneReportRow({ resource, params, callback }) {
  try {
    const data = yield dataProvider(UPDATE_REPORT, resource, params);

    yield put(refreshView());
    if (typeof callback === 'function') {
      callback(null, data);
    }
  } catch (error) {
    yield showNotification(error, 'error');

    if (typeof callback === 'function') {
      callback(error, null);
    }
  }
}

export default function* reportSaga() {
  yield takeEvery(UPDATE_REPORT_DATA, updateOneReportRow);
}
