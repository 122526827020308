import React, { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import DataGrid, {
  Column,
  Editing,
  Selection,
  Summary,
  TotalItem,
  Scrolling,
  FilterRow,
  KeyboardNavigation,
  Export,
  GroupPanel,
  GroupItem,
} from 'devextreme-react/data-grid';
import _ from 'lodash';

import { GridCell } from './grid-cell';
import { useStyles } from './grid.style';
import ActionEditTableCell from '../devExGrid/ActionEditTableCell';
import './customStyle.css';
import { handleInlineEdit } from '../../api/grid-api';
import { replaceFarsiCharactersWithArabic } from '../../helper/TextHelper';
import { GridCustomInput } from './grid-custom-input';

import type { GridViewPropsInterface } from './grid.type';

const GridView = (props: GridViewPropsInterface): ReactElement => {
  const {
    changeCheckboxSelection,
    formatTotalSummaryCell,
    addToFilterRequestList,
    onFailureSaveForm,
    onEditorPreparing,
    handleOnRowClick,
    focusOnLastInput,
    allowEditInline,
    onOptionChanged,
    showImageDialog,
    onEditCanceled,
    allowAddInGrid,
    onEditingStart,
    onCellPrepared,
    isEnableInput,
    onChangeInput,
    onInitNewRow,
    getGridClass,
    onCellClick,
    setRowColor,
    onSaving,
    enableClientExportExcel,
    totalSummaryItems,
    relationResource,
    quickEditButton,
    enableSelection,
    isTopFilterOpen,
    isGroupingOpen,
    lastFocusCell,
    relationMode,
    groupColumns,
    filterValues,
    parentInfo,
    basePath,
    redirect,
    resource,
    metaData,
    formMode,
    hasShow,
    hasEdit,
    gridRef,
    columns,
    locale,
    sort,
    rows,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.container} id="gridContainer">
      {/* @ts-ignore */}
      <DataGrid
        ref={gridRef}
        dataSource={rows}
        keyExpr="id"
        showBorders={true}
        rowAlternationEnabled={true}
        showRowLines={!!relationMode} //hide horizontal border in main grid
        showColumnLines={!!relationMode} //hide horizontal border in main grid
        rtlEnabled={locale !== 'en'}
        allowColumnResizing={true}
        columnResizingMode={'widget'}
        paging={{ enabled: false }}
        onEditingStart={onEditingStart}
        onInitNewRow={onInitNewRow}
        onEditCanceled={onEditCanceled}
        onSelectionChanged={data => changeCheckboxSelection(data)}
        className={getGridClass()}
        onRowClick={({ data }) => handleOnRowClick(data)}
        onSaving={onSaving}
        onRowInserting={data => (data.cancel = true)}
        remoteOperations={{ sorting: true, filtering: true }}
        onCellClick={onCellClick}
        noDataText={translate('imageDialog.dataNotFound')}
        onRowPrepared={setRowColor}
        onOptionChanged={onOptionChanged}
        onCellPrepared={onCellPrepared}
        onEditorPreparing={onEditorPreparing}
        onFocusedCellChanged={e => {
          //don't set focus when click on checkbox
          if (!e.column?.type || e.column?.type != 'selection') {
            lastFocusCell.current = e;
          }
        }}
        repaintChangesOnly={true}
      >
        <GroupPanel
          emptyPanelText={translate('grid.groupingEmptyText')}
          visible={!!isGroupingOpen}
        />
        <FilterRow showOperationChooser={false} visible={isTopFilterOpen} />

        <Scrolling
          useNative={true}
          showScrollbar={'always'}
          preloadEnabled={true}
          mode={relationMode ? 'standard' : 'virtual'}
        />
        {enableSelection && (
          <Selection
            mode="multiple"
            selectAllMode={'page'}
            showCheckBoxesMode={'always'}
          />
        )}

        <KeyboardNavigation editOnKeyPress={true} />

        {formMode == 'add' ? (
          <Editing
            allowUpdating={true}
            allowAdding={allowAddInGrid()}
            mode={'row'}
          />
        ) : (
          <Editing
            allowUpdating={true}
            allowAdding={allowAddInGrid()}
            mode={'cell'}
            useIcons={true}
          />
        )}

        {hasEdit && (
          <Column
            type="buttons"
            width={'40px'}
            cellRender={({ data }) => (
              <ActionEditTableCell
                quickEditButton={quickEditButton}
                hasEdit={hasEdit}
                classes={classes}
                basePath={basePath}
                currentUrl={redirect}
                translate={translate}
                metaData={metaData}
                parentInfo={parentInfo}
                relationMode={relationMode}
                relationResource={relationResource}
                row={data}
              />
            )}
          />
        )}
        {_.map(columns, columnInfo => (
          // @ts-ignore
          <Column
            key={columnInfo.name}
            dataField={columnInfo.name}
            groupIndex={
              isGroupingOpen
                ? groupColumns?.find(column => column.columnName === columnInfo.name)
                    ?.groupIndex ?? undefined
                : -1
            }
            // validationRules={[{ type: 'numeric' }]}
            caption={columnInfo.title}
            sortIndex={sort && columnInfo.columnName === sort?.field ? 1 : undefined}
            sortOrder={
              sort && columnInfo.columnName === sort?.field
                ? sort?.order.toLowerCase()
                : undefined
            }
            // dataType={getColumnDataType(columnInfo.field)}
            allowEditing={allowEditInline(columnInfo.field)}
            allowSorting={columnInfo.field?.allowSort}
            cellRender={cellData => (
              <GridCell
                key={`gridCell${columnInfo.name}`}
                saveInlineEdit={handleInlineEdit}
                column={columnInfo}
                row={cellData.data}
                basePath={basePath}
                redirect={redirect}
                hasShow={hasShow}
                resource={resource}
                relationMode={!!relationMode}
                metaData={metaData}
                showImageDialog={showImageDialog}
                addToFilterRequestList={addToFilterRequestList}
                value={cellData?.data[columnInfo?.field?.name]}
                hasEdit={hasEdit && cellData.data.iseditable}
                onFailureSaveForm={onFailureSaveForm}
              />
            )}
            editCellComponent={() => (
              <GridCustomInput
                focusOnLastInput={focusOnLastInput}
                onChangeInput={onChangeInput}
                isDisabled={!isEnableInput(columnInfo.field.name)}
                field={columnInfo.field}
                resource={resource}
              />
            )}
            width={columnInfo.field?.width}
            selectedFilterOperation={'contains'}
            defaultSelectedFilterOperation={'contains'}
            filterValue={
              isTopFilterOpen && filterValues?.[columnInfo.field?.name]
                ? replaceFarsiCharactersWithArabic(
                    filterValues?.[columnInfo.field?.name] as string,
                  )
                : null
            } //set top filter value
          ></Column>
        ))}
        {enableClientExportExcel && <Export enabled={true} />}
        {/* @ts-ignore */}
        <Summary>
          {_.map(totalSummaryItems, totalItem => (
            <TotalItem
              column={totalItem.columnName}
              summaryType={totalItem.type}
              customizeText={() => formatTotalSummaryCell(totalItem.columnName)}
              cssClass={`${classes.totalSummary} data-test-column-sum-${totalItem.columnName}`}
            />
          ))}
          {isGroupingOpen &&
            _.map(totalSummaryItems, totalItem => (
              <GroupItem
                column={totalItem.columnName}
                summaryType={totalItem.type}
                displayFormat="{0}"
              />
            ))}
        </Summary>
      </DataGrid>
    </div>
  );
};

export default React.memo(GridView);
