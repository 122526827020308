import React, { useRef } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useDrop, useDrag } from 'react-dnd';
import { getIconClassName } from '@uifabric/styling';
import { translate } from 'react-admin';
import { useContextMenu } from 'react-contexify';

import TodoListContextMenu from './TodoListContextMenu';
import { TODO } from '../../core/configRouteConstant';
import {
  deleteTodoListAction,
  openTodoShareListDialogAction,
  setSelectTodoList,
} from '../../redux/todoList/action';
import { getTodoListPatternInfo } from '../../helper/PatternMetaHelper';
import { getValue, USER_ID } from '../../core/configProvider';
import { ContextMenuItemType } from '../../helper/Types';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 15px',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 200ms',
    '&:hover': {
      backgroundColor: '#FAFAFA',
      transition: 'background-color 200ms',
    },
  },

  title: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  icon: {
    margin: '0 10px 0 0',
    fontSize: 18,
    padding: 2,
  },

  linkContainer: {
    textDecoration: 'none',
  },
}));

const TodoMenuListItem = props => {
  const {
    dropTaskAcceptType,
    dragListAcceptType,
    dragGroupAcceptType,
    addTaskToNewList,
    updateRowOrder,
    record,
    todoListPattern,
    lastRowOrder,
    resource,
    deleteTodoList,
    translate,
    color,
    selected,
    openTodoShareListDialog,
    uniqueKey,
    todoListSelectedId,
    setSelectTodoList,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const {
    id,
    title,
    counter,
    groupId,
    rowOrder,
    staticFilterList,
    listMemberResource,
    isSharedList,
    idListMember,
    reportTaskDetailsResource,
    createUserId,
  } = todoListPattern;

  const ref = useRef(null);
  const userId = getValue(USER_ID);

  const [, drag] = useDrag({
    item: {
      type: dragListAcceptType,
      [idListMember]: record[idListMember],
      [groupId]: record[groupId],
    },
  });

  const [{ isOver }, drop] = useDrop({
    accept: dragListAcceptType,

    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
    drop(item: any) {
      const data = {
        data: {},
        id: item[idListMember],
      };
      const newRowOrder = (lastRowOrder + record[rowOrder]) / 2;

      if (record[groupId] && record[id] && !item[groupId]) {
        // putting a list inside a group has been handled on TodoMenuGroupItem component
        return;
      } else if (item[groupId] && !record[groupId]) {
        // get out a list from group
        Object.assign(data.data, {
          [groupId]: null,
          [rowOrder]: newRowOrder,
        });
      } else {
        if (!lastRowOrder) {
          return;
        }
        // reOrder List
        Object.assign(data.data, {
          [rowOrder]: newRowOrder,
        });
      }
      updateRowOrder(data);
    },
  });

  const [{ isTaskOverList }, dropTask] = useDrop({
    accept: dropTaskAcceptType,

    collect: monitor => ({
      isTaskOverList: monitor.isOver(),
    }),
    drop(item: any) {
      addTaskToNewList(item.id, record[id]);
    },
  });

  const [{ isOverGroup }, dropGroup] = useDrop({
    accept: dragGroupAcceptType,

    collect: monitor => ({
      isOverGroup: monitor.isOver(),
    }),
    drop(item: any) {
      const newRowOrder = (lastRowOrder + record[rowOrder]) / 2;

      const data = {
        data: {
          [rowOrder]: newRowOrder,
        },
        id: item[idListMember],
      };
      updateRowOrder(data);
    },
  });

  drag(drop(dropGroup(dropTask(ref))));

  const getColor = () => {
    return {
      color: color ? color : theme.palette.primary.main,
    };
  };

  const selectedStyle = () => {
    return {
      backgroundColor: selected ? '#e4eaec' : 'unset',
      borderTop:
        isOver || isOverGroup || isTaskOverList ? '2px solid #ababab' : 'unset',
      borderBottom: isTaskOverList ? '2px solid #ababab' : 'unset',
    };
  };

  /**
   * Dispatch `deleteTodoList` action.
   * @function deleteTodo
   * @returns {void}
   */
  const deleteTodo = () => {
    const {
      reportResource,
      sort,
      pagination,
      deleteService,
      id,
      reportTaskDetailsResource,
    } = getTodoListPatternInfo(resource);

    const data = { [id]: record[id] };

    const isCurrentList = record[id] === todoListSelectedId;

    deleteTodoList(
      resource,
      deleteService,
      data,
      reportResource,
      sort,
      pagination,
      isCurrentList,
      reportTaskDetailsResource,
    );
  };

  /**
   * it will call the openTodoShareListDialog actio from redux with four parameters
   *  @function shareList
   *  @returns {void}
   */
  const shareList = (): void => {
    const { shareListParentId } = getTodoListPatternInfo(resource);
    openTodoShareListDialog(resource, listMemberResource, record, shareListParentId);
  };

  const contextMenuItems = [
    {
      title: translate('todo.shareList'),
      icon: 'addFriend',
      dataTestAttribute: 'share',
    },
    {
      title: translate('todo.deleteTodoList'),
      icon: 'delete',
      dataTestAttribute: 'delete',
      disable: record[createUserId] != userId,
    },
  ];

  const url = `#/${TODO}/${resource}?detail=${reportTaskDetailsResource}&filter={"${staticFilterList.listId}":["${staticFilterList.listId}","=","${record[id]}"]}`;

  const handleListClick = () => {
    setSelectTodoList(record);
  };

  /**
   * Call specified function after each context menu item click
   * @function contextMenuOnItemClickHandler
   * @param {ContextMenuItemType} item
   * @returns {void}
   */
  const contextMenuOnItemClickHandler = (item: ContextMenuItemType) => {
    switch (item.dataTestAttribute) {
      case 'share':
        shareList();
        break;
      case 'delete':
        deleteTodo();
        break;

      default:
        break;
    }
  };

  const { show } = useContextMenu({
    id: uniqueKey,
  });

  return (
    <>
      <TodoListContextMenu
        handleItemClick={contextMenuOnItemClickHandler}
        contextMenuItems={contextMenuItems}
        uniqueKey={uniqueKey}
      />
      <a
        className={classes.linkContainer}
        href={url}
        onClick={handleListClick}
        onContextMenu={show}
      >
        <div ref={ref} className={classes.container} style={selectedStyle()}>
          <i
            className={`${classes.icon} ${getIconClassName(
              'BulletedList2Mirrored',
            )}`}
            style={getColor()}
          ></i>
          <Typography
            variant="caption"
            className={classes.title}
            style={getColor()}
            data-test-is-sub-list={record[groupId] ? true : false}
            data-test-list-name={record[title]}
          >
            {record[title]}
          </Typography>
          {record[isSharedList] && (
            <i
              className={`${classes.icon} ${getIconClassName('people')}`}
              style={getColor()}
            ></i>
          )}
          <Typography variant="caption" style={getColor()}>
            {record[counter]}
          </Typography>
        </div>
      </a>
    </>
  );
};

TodoMenuListItem.propTypes = {
  color: PropTypes.string,
  selected: PropTypes.bool,
  record: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  deleteTodoList: deleteTodoListAction,
  openTodoShareListDialog: openTodoShareListDialogAction,
  setSelectTodoList,
};

export default compose(
  translate,
  connect(null, mapDispatchToProps),
)(TodoMenuListItem);
