import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import DroppableFieldCell from './DroppableFieldCell';
import lodashGet from 'lodash/get';
import { actorGetActionValue } from '../../type/actor-setup';
import { getDrawerWidth } from '../app-layout/drawer/drawer.helper';

//todo: move to useStaye
const isDrawerOpen = actorGetActionValue('isDrawerOpen');

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
    height: '100%',
    width: getDrawerWidth(isDrawerOpen),
  },

  field: {
    margin: '7px 12px',
    padding: '15px 20px',
    backgroundColor: '#ffffff',
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    border: `1px solid ${theme.palette.divider}`,
  },
});

class FormLayoutSidebar extends PureComponent {
  getFieldsOnPage() {
    const { tabList } = this.props;
    const result = {};

    tabList.forEach(tab =>
      tab.groupList.forEach(group =>
        group.layout.forEach(row =>
          row.forEach(field => {
            if (field) {
              result[field.id] = true;
            }
          }),
        ),
      ),
    );

    return result;
  }

  render() {
    const { classes, fields = {}, locale, removeField } = this.props;
    const usedFields = this.getFieldsOnPage();

    return (
      <div>
        <div className={classes.container}>
          {Object.keys(fields)
            // we only need to show fields that are not in the page
            .filter(fieldId => !usedFields[fieldId])
            .map((fieldId, index) => {
              const field = fields[fieldId];

              return (
                <DroppableFieldCell
                  className={classes.field}
                  field={field}
                  index={index}
                  key={field.id}
                  source={`field/${field.id}`}
                  caption={lodashGet(
                    field,
                    ['translatedCaption', locale],
                    field.name,
                  )}
                  locale={locale}
                  moveField={removeField}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

FormLayoutSidebar.propTypes = {
  fields: PropTypes.object.isRequired,
  removeField: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(FormLayoutSidebar);
