import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { MailSidebarViewInterface } from './mail-sidebar.type';
import { useStyles } from './mail-sidebar.style';
import Box from '@material-ui/core/Box';
import { AppDrawer } from '../../app-drawer';
import { SplitButton } from '../../split-button';

const MailSidebarView = (props: MailSidebarViewInterface): ReactElement => {
  const { mailSidebarRoutesConfig, onCompose } = props;
  const classes = useStyles();
  const translate = useTranslate();

  const splitButtonOptions = [
    `${translate('mail.newMessage')}`,
    `${translate('mail.inMail')}`,
    `${translate('mail.newMail')}`,
  ];

  return (
    <div data-test="mail-sidebar-container" data-style-mailSidebar="mailSidebar">
      <Box display="flex" justifyContent="center" pt={{ xs: 4, md: 5 }} pb={2}>
        <SplitButton
          disableButton={false}
          className={classes.btnContainer}
          buttonClassName={classes.btn}
          toggleButtonClassName={classes.toggleBtn}
          options={splitButtonOptions}
          onClickItem={onCompose}
          data-test="mail-sidebar-split-button"
        />
      </Box>

      <AppDrawer
        routesConfig={mailSidebarRoutesConfig}
        baseUrl="/mail"
        data-test="mail-sidebar-drawer"
      />
    </div>
  );
};

export default MailSidebarView;
