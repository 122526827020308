import { cloneElement, ReactElement } from 'react';
import { ListItem, ListItemText, Menu } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { NavItemViewInterface } from './nav-item.type';
import { useStyles } from './nav-item.style';
import { NavLink } from '../nav-link';
import { isEmpty } from '../../../../helper/data-helper';

const NavItemView = (props: NavItemViewInterface): ReactElement => {
  const {
    item,
    level,
    baseUrl,
    isContextMenuOpen,
    onContextMenu,
    handleCloseContextMenu,
    contextMenuNode,
    isActive,
    handleSetActive,
  } = props;

  const classes = useStyles({ level, isActive });

  return (
    <ListItem
      data-style-navigation-item="navItem"
      onContextMenu={onContextMenu}
      button
      component={NavLink}
      to={item.url}
      activeClassName={classes.active}
      className={classes.navItem}
      data-test={item?.dataTest}
      isActive={(match, location) => {
        if (item.exact && !location?.search) {
          handleSetActive(true);
          return true;
        }
        if (!location || !location.search) {
          handleSetActive(false);
          return false;
        }

        const search = location.search;
        handleSetActive(`${baseUrl}${search}`.includes(item.url as string));
        return `${baseUrl}${search}`.includes(item.url as string);
      }}
    >
      {item.icon && (
        <Box height={20} className="nav-item-icon" component="span" mr={1}>
          {item.icon}
        </Box>
      )}
      <ListItemText primary={item.title} classes={{ primary: 'nav-item-text' }} />
      {item?.count && item?.count > 0 ? (
        <Box className={classes.countContainer} mr={1} component="span">
          {item?.count}
        </Box>
      ) : null}
      {!isEmpty(isContextMenuOpen) && (
        <Menu
          onClick={(event: React.MouseEvent<HTMLElement>) => event.preventDefault()}
          open={isContextMenuOpen !== null}
          onClose={handleCloseContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            isContextMenuOpen !== null &&
            isContextMenuOpen?.mouseX &&
            isContextMenuOpen?.mouseY
              ? { top: isContextMenuOpen.mouseY, left: isContextMenuOpen.mouseX }
              : undefined
          }
        >
          {contextMenuNode &&
            cloneElement(
              contextMenuNode as React.ReactElement<
                any,
                string | React.JSXElementConstructor<any>
              >,
              { ...item },
            )}
        </Menu>
      )}
    </ListItem>
  );
};

export default NavItemView;
