import React, { Component, isValidElement } from 'react';
import lodashGet from 'lodash/get';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  setListSelectedIds as setListSelectedIdsAction,
  toggleListItem as toggleListItemAction,
  Responsive,
  translate as withTranslate,
} from 'react-admin';
import checkMinimumRequiredProps from './admin/checkMinimumRequiredProps';
import { Icon, Typography } from '@material-ui/core';
import { crudGetListAction } from '../redux/gridList/action';
import { mergeAndClone } from '../helper/data-helper';
import { prepareReportFilter } from '../helper/MetaHelper';
import { prepareFilterFromObject } from '../core/dataProvider';
import { actorOnDispatch, actorRemoveAction } from '../type/actor-setup';

const SORT_ASC = 'ASC';
const SORT_DESC = 'DESC';

class ListContainerApiSimpleController extends Component {
  #onDispatchId;

  constructor(params) {
    super(params);
    this.state = {
      sortOrder: this.props.sort.order,
      sortField: this.props.sort.field,
      page: this.props.page || 1,
      perPage: this.props.perPage || 10,
      lastResource: this.props.resource,
      filter: this.props.filter,
      searchFilters: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.relationVersion !== nextProps.relationVersion) {
      this.updateData(nextProps);
    }
  }

  componentWillUnmount() {
    if (this.#onDispatchId) {
      actorRemoveAction({
        actionName: 'refreshView',
        listenerId: this.#onDispatchId,
      });
    }
  }

  componentDidMount() {
    if (this.props.isResourceDisabled) {
      return;
    }

    if (this.props.filter && isValidElement(this.props.filter)) {
      throw new Error(
        '<ListContainer> received a React element as `filter` props. If you intended to set the list filter elements, use the `filters` (with an s) prop instead. The `filter` prop is internal and should not be set by the developer.',
      );
    }

    this.#onDispatchId = actorOnDispatch('refreshView', this.updateData);

    this.updateData();
  }

  updateData(props = this.props) {
    const { sortField, sortOrder, page, perPage, filter, searchFilters } =
      this.state;

    const pagination = {
      page: parseInt(page, 10),
      perPage: parseInt(perPage, 10),
    };

    props.crudGetList(
      props.resource,
      pagination,
      { field: sortField, order: sortOrder },
      filter,
      props.resourceId,
      searchFilters,
    );
  }

  setSort = sortField =>
    this.setState(
      {
        sortField,
        sortOrder: this.state.sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC,
      },
      this.updateData,
    );

  setPage = page => this.setState({ page }, this.updateData);

  setPerPage = perPage => this.setState({ perPage }, this.updateData);

  //use in report relation grid
  setSearchFilters = newFilters => {
    if (newFilters) {
      this.setState({ searchFilters: newFilters, page: 1 }, this.updateData);
    } else {
      this.setState({ searchFilters: [], page: 1 }, this.updateData);
    }
  };

  handleSelect = ids => {
    this.props.setSelectedIds(this.props.resource, ids);
  };

  handleUnselectItems = () => {
    this.props.setSelectedIds(this.props.resource, []);
  };

  handleToggleItem = id => {
    this.props.toggleItem(this.props.resource, id);
  };

  render() {
    const {
      basePath,
      children,
      resource,
      hasCreate,
      data,
      ids,
      loadedOnce,
      total,
      isLoading,
      translate,
      version,
      selectedIds,
      isResourceDisabled,
      classes,
    } = this.props;

    const { sortField, sortOrder, page, perPage } = this.state;

    if (isResourceDisabled) {
      return (
        <Responsive
          className={classes.resourceIsDisabled}
          small={
            <div>
              <Icon style={{ fontSize: 40 }}>report_problem</Icon>
              <Typography className={classes.resourceIsDisabledText} variant="h5">
                {translate('grid.resourceIsDisabled')}
              </Typography>
            </div>
          }
          medium={
            <div>
              <Icon style={{ fontSize: 90 }}>report_problem</Icon>
              <Typography className={classes.resourceIsDisabledText} variant="h2">
                {translate('grid.resourceIsDisabled')}
              </Typography>
            </div>
          }
        />
      );
    }

    return children({
      basePath,
      currentSort: {
        field: sortField,
        order: sortOrder,
      },
      data,
      defaultTitle: resource,
      displayedFilters: {},
      filterValues: this.state.searchFilters,
      hasCreate,
      hideFilter: null,
      ids,
      isLoading,
      loadedOnce,
      onSelect: this.handleSelect,
      onToggleItem: this.handleToggleItem,
      onUnselectItems: this.handleUnselectItems,
      page: page,
      perPage: perPage,
      resource,
      selectedIds,
      setFilters: this.setSearchFilters,
      setPage: this.setPage,
      setPerPage: this.setPerPage,
      setSort: this.setSort,
      showFilter: null,
      showFilterByList: null,
      translate,
      total,
      version,
    });
  }
}

ListContainerApiSimpleController.defaultProps = {
  debounce: 500,
  filter: {},
  perPage: 10,
  sort: {
    field: 'id',
    order: SORT_DESC,
  },
};

const emptyArray = [];
const emptyObject = {};

function mapStateToProps(state, props) {
  const { resource, resourceId } = props;
  // depending on if resource id is provided, read from react-admin store, or our custom store
  const resourceState = resourceId
    ? // our custom store
      state.gridList[resourceId]
    : // default store
      state.admin.resources[resource];

  if (!state.admin.resources[resource]) {
    return {
      isResourceDisabled: true,
      isLoading: state.admin.loading > 0,
      version: state.admin.ui.viewVersion,
      relationVersion: lodashGet(state, ['relation', 'relationVersion'], 0),
    };
  }

  return {
    isResourceDisabled: false,
    ids: lodashGet(resourceState, 'list.ids', emptyArray),
    loadedOnce: lodashGet(resourceState, 'list.loadedOnce', false),
    selectedIds: lodashGet(resourceState, 'list.selectedIds', emptyArray),
    total: lodashGet(resourceState, 'list.total', 0),
    data: lodashGet(resourceState, 'data', emptyObject),
    isLoading: state.admin.loading > 0,
    version: state.admin.ui.viewVersion,
    relationVersion: lodashGet(state, ['relation', 'relationVersion'], 0),
  };
}

export default compose(
  withTranslate,
  checkMinimumRequiredProps('List', ['basePath', 'location', 'resource']),
  connect(mapStateToProps, {
    crudGetList: crudGetListAction,
    setSelectedIds: setListSelectedIdsAction,
    toggleItem: toggleListItemAction,
  }),
)(ListContainerApiSimpleController);
