import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  inputLabel: {
    fontSize: theme.typography.subtitle2.fontSize,
    width: 100,
    minWidth: 100,
    maxWidth: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
    '&>span': {
      color: `${theme.palette.error.main}`,
    },
  },
}));
