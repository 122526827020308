import { FC, memo } from 'react';

import { UserInfoInterface } from './user-info.type';
import UserInfoView from './user-info.view';

const UserInfoController: FC<UserInfoInterface> = memo(props => {
  const { user, showStatus = false, isOnline = false } = props;

  return <UserInfoView user={user} showStatus={showStatus} isOnline={isOnline} />;
});

export default UserInfoController;
