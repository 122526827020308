import { OPEN_DIALOG, CLOSE_DIALOG } from './constant';
export function openDialogAction({
  resource,
  data = {},
  dropdownId = null,
  source = null,
  mustRefresh = false,
  redirect = null,
  onCreate = () => {},
  disabledFieldList = null,
  parentInfo = null,
  dropdownMeta = null,
  additionalProps = {},
  relationMode = false,
  childFieldName = null,
  parentFieldName = null,
}) {
  return {
    type: OPEN_DIALOG,
    resource,
    data,
    dropdownId,
    source,
    mustRefresh,
    redirect,
    onCreate,
    disabledFieldList,
    parentInfo,
    dropdownMeta,
    additionalProps,
    relationMode,
    childFieldName,
    parentFieldName,
  };
}

export function closeDialogAction() {
  return {
    type: CLOSE_DIALOG,
  };
}
