import { isEmptyObject } from '../../../helper/data-helper';
import lodashFind from 'lodash/find';
import { getProfileSettingDropdownPatternInfo } from '../../../helper/PatternMetaHelper';
import { actorGetActionValue } from '../../../type/actor-setup';
import { updateInputsState } from '../form.helper';
import { ValidationError } from '../../../helper/Types';
import { InitialData, InputRefContent } from '../form.type';

/**
 * Prepare global parameters from `dropdownMeta`.
 * @function prepareGlobalParameters
 * @param {number} dropId
 * @returns {object}
 */
export const prepareGlobalParameters = (
  dropId: number,
  field: Record<string, unknown>,
): object => {
  if (isNaN(dropId)) return {};

  const dropdownMeta = getProfileSettingDropdownPatternInfo(
    'profileSettingDropdown',
  );

  const { uniqueId } = dropdownMeta;

  const dropDownData = actorGetActionValue('dropDownData');

  const allDropDownData = (dropDownData![uniqueId] ??
    dropDownData![`${uniqueId}-${field.id}`])!.DATA;
  const globalParameters = actorGetActionValue('globalParameters')!;

  if (!isEmptyObject(dropdownMeta)) {
    const { valueMember, valueMember2, currentWareHouseId, currentDepId } =
      dropdownMeta;

    const dropdownItem: object = lodashFind(allDropDownData, {
      [valueMember]: dropId,
    });

    return {
      ...globalParameters,
      [currentDepId.toLowerCase()]: dropdownItem[valueMember2],
      [currentDepId]: dropdownItem[valueMember2],
      [currentWareHouseId.toLowerCase()]: dropdownItem[valueMember],
      [currentWareHouseId]: dropdownItem[valueMember],
    };
  } else {
    return globalParameters;
  }
};

/**
 * @functions updateInputValues
 * @param { boolean } withInitialData
 * @returns { void } void
 */
export const updateInputValues = (withInitialData = false): void => {
  // currentResource get again because need resources to be updated
  const currentResource = actorGetActionValue('resources')!.current;
  const inputsRef = actorGetActionValue(
    'inputsRef',
    `${currentResource.value}.${currentResource.type}`,
  ) as Record<string, InputRefContent> | undefined;

  let data = {};
  if (withInitialData) {
    data =
      (actorGetActionValue('initialData', [
        currentResource.value,
        currentResource.type,
      ]) as InitialData | null) ?? {};
  } else {
    data =
      (actorGetActionValue('formData', [
        currentResource.value,
        currentResource.type,
      ]) as FormData | null) ?? {};
  }

  const formErrors =
    (actorGetActionValue('formMessages', [
      currentResource.value,
      currentResource.type,
    ]) as ValidationError | null) ?? {};

  updateInputsState(inputsRef, formErrors, data);
};
