import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({});

function TomorrowIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M15.9997 4.9997h1v1h3.001v14H3.9997v-14h3v-1h1v1h8v-1zm-11 14h14v-9h-14v9zm12-10.999h-1l.0003-1H7.9997v1h-1v-1h-2v2h14.001v-2h-2.001v1z"></path>
      <path d="M11.6465 16.6465l.707.707 2.854-2.853-2.854-2.854-.707.707 1.646 1.647h-4.292v1h4.292z"></path>
    </SvgIcon>
  );
}

export default withStyles(styles)(TomorrowIcon);
