import { makeStyles } from '@material-ui/core';
import { StyleProps } from '.';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  navItem: {
    height: 40,
    marginTop: 2,
    marginBottom: 2,
    paddingLeft: theme.direction === 'ltr' ? ({ level }) => 17 + 40 * level : 12,
    paddingRight: theme.direction === 'rtl' ? ({ level }) => 17 + 40 * level : 12,
    color: theme.palette.text.secondary,
    fontWeight: 700,
    fontSize: 14,
    cursor: 'pointer',
    textDecoration: 'none!important',

    [theme.breakpoints.up('xl')]: {
      // fontSize: 16,
      marginTop: 4,
      marginBottom: 4,
      paddingLeft: theme.direction === 'ltr' ? ({ level }) => 24 + 40 * level : 12,
      paddingRight: theme.direction === 'rtl' ? ({ level }) => 24 + 40 * level : 12,
    },
    '&.nav-item-header': {
      textTransform: 'uppercase',
    },
  },
}));
