import { ReactElement } from 'react';
import { useStyles } from './print-report.style';
import { PrintReportViewInterface } from './print-report.type';

const PrintReportView = (props: PrintReportViewInterface): ReactElement => {
  const { getData, iframeKey } = props;
  const classes = useStyles();

  return (
    <iframe
      src="/print.html"
      onLoad={getData}
      key={iframeKey}
      className={classes.root}
      name="report-print"
      id="report-print"
    />
  );
};

export default PrintReportView;
