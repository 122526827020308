import React, { FC, useState } from 'react';
import { useTranslate } from 'react-admin';
import compose from 'recompose/compose';
import {
  makeStyles,
  Paper,
  Typography,
  Button,
  Radio,
  InputLabel,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import classNames from 'classnames';

import { isEmpty } from '../../helper/data-helper';
import ConfirmDialogHOC from '../../container/ConfirmDialogHOC';
import { CustomTheme } from '../../core/themeProvider';

interface PatternInterface {
  finalPrice: string;
  totalDiscountPrice: string;
  totalWholePrice: string;
  id: string;
  settlementTypeTitle: string;
  description: string;
  settlementTypeId: string;
}

interface SellinFinalizeBasketViewInterfaceProps {
  order: object;
  settlementType: object[];
  pattern: PatternInterface;
  submitOrder: Function;
  defaultSettlementValue: string;
  openConfirmDialog: Function;
}

const styles = makeStyles((theme: CustomTheme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  main: {},

  paper: {
    padding: 10,
    margin: 10,
  },

  paperContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 10,
  },

  submitButton: {
    borderRadius: 0,
    color: theme.palette.primary.appSecondaryTextColor,
  },

  inputTextarea: {
    padding: 12,
    resize: 'none',
    outline: 'none',
    width: '100%',
    height: 100,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'transparent',
  },

  radioItemContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },

  radioLabel: {
    flexGrow: 1,
  },

  orderItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    paddingTop: 10,
  },

  orderItemTitle: {
    flexBasis: '30%',
  },

  orderTotalWholePrice: {
    color: 'green',
  },

  orderTotalDiscountPrice: {
    color: 'red',
  },
}));

const SellinFinalizeBasketView: FC<
  SellinFinalizeBasketViewInterfaceProps
> = props => {
  const {
    order,
    settlementType,
    pattern,
    submitOrder,
    defaultSettlementValue,
    openConfirmDialog,
  } = props;
  const classes = styles();
  const translate = useTranslate();
  const {
    finalPrice,
    totalDiscountPrice,
    totalWholePrice,
    id,
    settlementTypeTitle,
    description,
    settlementTypeId,
  } = pattern;

  const [settlementTypeValue, setSettlementTypeValue] = useState(
    defaultSettlementValue,
  );
  const [descriptionValue, setDescriptionValue] = useState('');

  const handleChange = event => {
    setSettlementTypeValue(event.target.value);
  };

  const handleConfirm = () => {
    openConfirmDialog({
      content: translate('sellin.areYouConfidentInFinalizingYourOrder'),
      onConfirm: handleSubmitOrder,
    });
  };

  const handleSubmitOrder = () => {
    const data = {
      [description]: !isEmpty(descriptionValue) ? descriptionValue : null,
      [settlementTypeId]: parseInt(settlementTypeValue, 10),
    };
    submitOrder(data);
  };

  const handleTextareaChange = event => {
    setDescriptionValue(event.target.value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <Paper className={classes.paper}>
          <Typography variant="subtitle1">
            {translate('sellin.priceDetails')}
          </Typography>
          <div className={classes.paperContent}>
            <div className={classes.orderItem}>
              <Typography variant="body2" className={classes.orderItemTitle}>
                {translate('sellin.finalPrice')}
              </Typography>
              <Typography variant="body2">{order[finalPrice]}</Typography>
            </div>
            <div
              className={classNames(
                classes.orderItem,
                classes.orderTotalDiscountPrice,
              )}
            >
              <Typography variant="body2" className={classes.orderItemTitle}>
                {translate('sellin.totalDiscountPrice')}
              </Typography>
              <Typography variant="body2">{order[totalDiscountPrice]}</Typography>
            </div>
            <div
              className={classNames(classes.orderItem, classes.orderTotalWholePrice)}
            >
              <Typography variant="body2" className={classes.orderItemTitle}>
                {translate('sellin.totalWholePrice')}
              </Typography>
              <Typography variant="body2">{order[totalWholePrice]}</Typography>
            </div>
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="subtitle1">
            {translate('sellin.description')}
          </Typography>
          <div className={classes.paperContent}>
            <textarea
              className={classes.inputTextarea}
              value={descriptionValue}
              onChange={handleTextareaChange}
            ></textarea>
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="subtitle1">
            {translate('sellin.settlementType')}
          </Typography>
          <div className={classes.paperContent}>
            {settlementType &&
              settlementType.map(item => {
                return (
                  <div key={item[id]} className={classes.radioItemContent}>
                    <Radio
                      id={`${item[id]}`}
                      checked={settlementTypeValue === `${item[id]}`}
                      onChange={handleChange}
                      value={`${item[id]}`}
                      inputProps={{ 'aria-label': `${item[id]}` }}
                    />
                    <InputLabel
                      className={classes.radioLabel}
                      htmlFor={`${item[id]}`}
                    >
                      <Typography variant="body2">
                        {item[settlementTypeTitle]}
                      </Typography>
                    </InputLabel>
                  </div>
                );
              })}
          </div>
        </Paper>
      </div>
      <Button
        className={classes.submitButton}
        variant="contained"
        color="secondary"
        size="large"
        onClick={handleConfirm}
      >
        {translate('sellin.submitOrder')}
      </Button>
    </div>
  );
};

const mapDispatchToProps = {
  redirectToPage: push,
};

export default compose(
  ConfirmDialogHOC,
  connect(null, mapDispatchToProps),
)(SellinFinalizeBasketView);
