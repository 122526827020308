import React, { useEffect } from 'react';
import lodashGet from 'lodash/get';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { translate, crudGetList as crudGetListAction } from 'react-admin';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import NoteStreamCard from './NoteStreamCard';
import { getNoteInfo } from '../helper/MetaHelper';
import { setNoteStreamPageAction } from '../redux/listPage/action';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

const PER_PAGE_COUNT = 15;

const NoteStreamSidebar = props => {
  const {
    noteStreamPage,
    resource,
    crudGetList,
    setNoteStreamPage,
    classes,
    isLoading,
    parentResource,
    translate,
    relation,
    noteData = {},
  } = props;

  useEffect(() => {
    // if it's the first time to load open this note stream
    if (!noteStreamPage) {
      fetchData();
    }
  }, []);

  const fetchData = () => {
    const currentPage = noteStreamPage ? noteStreamPage : 0;

    crudGetList(
      resource,
      { page: currentPage + 1, perPage: PER_PAGE_COUNT },
      { field: 'createdate', order: 'DESC' },
    );

    setNoteStreamPage({
      resource,
      page: currentPage + 1,
    });
  };

  const currentPage = noteStreamPage ? noteStreamPage : 0;
  const sortedIdList = Object.keys(noteData).sort((a, b) => b - a);

  return (
    <div
      className={classes.container}
      data-test-is-list-has-more-items={
        isLoading || currentPage * PER_PAGE_COUNT > sortedIdList.length
      }
      data-test-sidebar-notes-length={sortedIdList.length}
    >
      {sortedIdList.map(id => (
        <NoteStreamCard
          parentResource={parentResource}
          resource={resource}
          key={id}
          id={id}
          model={noteData[id]}
          parentFK={relation.parentFieldName}
          relation={relation}
          noteStreamInSidebar
        />
      ))}
      <Button
        variant="contained"
        color="primary"
        onClick={fetchData}
        disabled={isLoading || currentPage * PER_PAGE_COUNT > sortedIdList.length}
      >
        {translate('noteStream.fetch')}
      </Button>
    </div>
  );
};

NoteStreamSidebar.propTypes = {
  isLoading: PropTypes.bool,
  parentResource: PropTypes.string.isRequired,
  crudGetList: PropTypes.func.isRequired,
  setNoteStreamPage: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { parentMetaData }) => {
  const relation = getNoteInfo(parentMetaData) || {};
  const { moduleName, moduleTableName } = relation;
  const resource = `${moduleName}/${moduleTableName}`;

  return {
    resource,
    relation,
    isLoading: !!state.admin.loading,
    noteData: lodashGet(state, ['admin', 'resources', resource, 'data']),
    noteStreamPage: state.listPage.noteStreamPage[resource],
  };
};

const mapDispatchToProps = {
  crudGetList: crudGetListAction,
  setNoteStreamPage: setNoteStreamPageAction,
};

export default compose(
  translate,
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(NoteStreamSidebar);
