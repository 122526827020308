import { getTypeByField, SEARCH_FIELD } from '../../../../helper/InputHelper';
import {
  actorGetActionValue,
  actorSetActionValue,
} from '../../../../type/actor-setup';
import { FormData, InputRefContent } from '../../../form';
import { updateValuesOfInputsAndFormDataAfterSubmit } from '../../wms.helper';
import { WMSFormHelperVariablesRef } from './wms-form.type';

/**
 * It handles all necessary process of form's behaviors(e.g. focus to the next input, submitting the form on the last input under different situations)
 * @function formHandlerOnPressingEnterKey
 * @param { KeyboardEvent } event
 * @param { WMSFormHelperVariablesRef } helperVariables
 * @returns void
 */
export const formHandlerOnPressingEnterKey = async (
  helperVariables: WMSFormHelperVariablesRef,
): Promise<void> => {
  try {
    const wmsAsyncActionList = actorGetActionValue('wmsAsyncActionList') ?? [];
    await Promise.all(wmsAsyncActionList);
  } catch (error) {
    console.error('`formActionsHandler`: saving error => ', error);
  }

  const currentResource = actorGetActionValue('resources')!.current;
  const formData = actorGetActionValue(
    'formData',
    `${currentResource.value}.${currentResource.type}`,
  ) as FormData | null;

  const tabIndex = currentResource.value.split('tab-')[1];
  if (tabIndex) {
    helperVariables.currentFormIndex = Number(tabIndex);
  } else {
    helperVariables.currentFormIndex = 0;
  }

  const currentFocusedInputName = Object.keys(helperVariables.refs!).find(
    inputName => helperVariables.refs![inputName]['focused'] === true,
  );

  const nextFieldName =
    helperVariables.formFocusManagementFunctions!.focusOnNextInput(
      helperVariables.refs!,
      currentFocusedInputName,
      formData,
      () => {
        if (
          typeof helperVariables.submitFunctions[
            helperVariables.currentFormIndex
          ] !== 'function'
        ) {
          return;
        }

        helperVariables.submitFunctions[helperVariables.currentFormIndex]?.();
      },
    );

  helperVariables.lastInputSelectedData[helperVariables.currentFormIndex] =
    nextFieldName;
};

/**
 * It resets the current form inputs and changes focus to the first input
 * @function resetWMSForm
 * @param { Array<FieldType> } fieldList
 * @returns { void }
 */
export const resetWMSForm = (fieldNameList: string[], hardReset = false): void => {
  const currentResource = actorGetActionValue('resources')!.current;
  const inputsRef = actorGetActionValue(
    'inputsRef',
    `${currentResource.value}.${currentResource.type}`,
  )! as Record<string, InputRefContent> | undefined;

  if (inputsRef == null) return;

  const formFocusManagementFunctions =
    actorGetActionValue('formGlobalProps')!.formFocusManagementFunctions;

  const formData = actorGetActionValue(
    'formData',
    `${currentResource.value}.${currentResource.type}`,
  ) as FormData | null;

  updateValuesOfInputsAndFormDataAfterSubmit({
    inputsRef,
    fieldNameList,
    newFormData: formData,
    forceEmptyFields: hardReset,
  });

  formFocusManagementFunctions.focusOnFirstInputAfterSubmit();
};

/**
 * @function submitHandler
 * @param { WMSFormHelperVariablesRef } wmsFormHelperVariablesRef
 * @param { () => void } onSubmit
 * @returns { void } void
 */
export const submitHandler =
  (
    wmsFormHelperVariablesRef: WMSFormHelperVariablesRef,
    onSubmit?: (formData: FormData | undefined) => void,
  ) =>
  (): void => {
    const rootResource = actorGetActionValue('resources')!.stack[0];
    const currentResource = actorGetActionValue('resources')!.current;

    const currentFormData = actorGetActionValue(
      'formData',
      `${currentResource.value}.${currentResource.type}`,
    ) as FormData | undefined;

    actorSetActionValue('formData', currentFormData, {
      path: `${rootResource.value}.${rootResource.type}`,
    });

    onSubmit?.(currentFormData);

    wmsFormHelperVariablesRef.refs = {};

    const { focusOnFirstInputAfterSubmit } =
      actorGetActionValue('formGlobalProps')!.formFocusManagementFunctions;

    focusOnFirstInputAfterSubmit();

    wmsFormHelperVariablesRef.refs = actorGetActionValue(
      'inputsRef',
      `${currentResource.value}.${currentResource.type}`,
    ) as Record<string, InputRefContent> | null;
  };

/**
 * @function checkCurrentFocusedFieldIsSearchField
 * @param { Record<string, InputRefContent> | null } inputsRef
 * @returns { boolean } boolean
 */
export const checkCurrentFocusedFieldIsSearchField = (
  inputsRef: Record<string, InputRefContent> | null,
): boolean => {
  if (inputsRef == null) {
    console.error('`currentFocusedFieldIsSearchField`: inputsRef is null');
    return false;
  }

  const focusedInputName = Object.keys(inputsRef).find(
    item => inputsRef[item]['focused'] === true,
  );

  if (
    focusedInputName &&
    inputsRef![focusedInputName]?.field &&
    getTypeByField(inputsRef![focusedInputName]!.field) === SEARCH_FIELD
  ) {
    return true;
  }

  return false;
};
