import { ReactElement, useCallback, useEffect, useState } from 'react';
import { exportLatLngFromGeography } from '../../dynamic-input/location-input/location-input.helper';

import {
  LocationFieldControllerPropsInterface,
  LocationInterface,
} from './location-field.type';
import LocationFieldView from './location-field.view';

const LocationFieldController = (
  props: LocationFieldControllerPropsInterface,
): ReactElement => {
  const { field, record } = props;
  const [location, setLocation] = useState<LocationInterface | null | undefined>(
    undefined,
  );

  useEffect(() => {
    const value = (record?.[field.name] as string) ?? null;
    setLocation(exportLatLngFromGeography(value));
  }, [record]);

  if (location == undefined) {
    return <></>;
  }

  return <LocationFieldView location={location} field={field} />;
};

export default LocationFieldController;
