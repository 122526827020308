import {
  CREATE,
  UPDATE,
  GET_LIST,
  GET_ONE,
  GET_MANY_REFERENCE,
  DELETE,
} from 'react-admin';

import {
  actorOnDispatch,
  actorSetActionValue,
  NoteRequestParametersInterface,
} from '../type/actor-setup';
import {
  CrudCreateApiPayload,
  CrudDeleteOneApiPayload,
  CrudGetListApiPayload,
  CrudGetManyReferenceApiPayload,
  CrudGetOneApiPayload,
  CrudRunServiceApiPayload,
  CrudUpdateApiPayload,
} from '../type/data-provider';
import { getRootTableId } from '../helper/meta-helper';
import dataProvider, {
  CUSTOM_GET,
  CUSTOM_POST,
  RUN_SERVICE,
} from '../core/dataProvider';

actorOnDispatch('crudAction', async crudActionPayload => {
  const { type, entity } = crudActionPayload; // destruct common options

  switch (type) {
    case CREATE:
    case 'CRUD_CREATE': {
      const { data, resource, onSuccess, onFailure } =
        crudActionPayload as CrudCreateApiPayload; // destruct special type options

      if (entity === 'appSettings') {
        try {
          const { id } = data;
          delete data.id; //TODO: check to not delete by reference

          const result = await dataProvider(CREATE, 'appcore/websetting', {
            data,
            id,
          });

          onSuccess?.(result?.data);
        } catch (error) {
          onFailure?.(error);
        }

        break;
      } else {
        // grid
        try {
          const result = await dataProvider(CREATE, resource, { data });
          onSuccess?.(result);
        } catch (error) {
          onFailure?.(error);
        }
        break;
      }
    }

    case UPDATE: {
      const { data, onSuccess, onFailure, id, resource } =
        crudActionPayload as CrudUpdateApiPayload; // destruct special type options
      if (entity === 'appSettings') {
        try {
          const { id } = data;
          delete data.id; //TODO: check to not delete by reference

          const result = await dataProvider(UPDATE, 'appcore/websetting', {
            data,
            id,
          });

          onSuccess?.(result?.data);
        } catch (error) {
          onFailure?.(error);
        }
      } else {
        // grid
        try {
          const result = await dataProvider(UPDATE, resource, { id, data });

          onSuccess?.(result);
        } catch (error) {
          onFailure?.(error);
        }
      }
      break;
    }

    case GET_LIST: {
      const { resource, requestParameters, onSuccess, onFailure } =
        crudActionPayload as CrudGetListApiPayload;

      try {
        let finalResource = resource;
        let finalRequestParameters = requestParameters;

        if (requestParameters.customRecordNotePin) {
          finalRequestParameters =
            requestParameters as NoteRequestParametersInterface;
          finalResource = 'report/' + finalRequestParameters.customRecordNotePin;

          finalRequestParameters.filter = [
            ['KeyID', '=', finalRequestParameters.KeyID ?? ''],
            'and',
            ['IsPin', '=', String(finalRequestParameters.IsPin)],
            'and',
            ['tableid', '=', String(getRootTableId() ?? '')],
          ];
        }

        const result = await dataProvider(
          GET_LIST,
          finalResource,
          finalRequestParameters,
        );

        actorSetActionValue('additionalData', result?.additionalData, {
          path: resource,
        });

        onSuccess?.(result);

        if (entity === 'relation') {
          onSuccess?.({
            [GET_LIST]: {
              [entity]: {
                status: 'success',
                data: result,
                payload: {},
                resource,
              },
            },
          });
        }
      } catch (error) {
        onFailure?.(error);
        if (entity === 'relation') {
          onFailure?.({
            [GET_LIST]: {
              [entity]: {
                status: 'failure',
                data: error,
                payload: {},
                resource,
              },
            },
          });
        }
      }
      break;
    }

    case GET_ONE: {
      const { disableNotification, resource, recordId, onSuccess, onFailure } =
        crudActionPayload as CrudGetOneApiPayload;

      try {
        const result = await dataProvider(GET_ONE, resource, { id: recordId });

        // todo: set loading when requesting in another card
        onSuccess?.({
          [GET_ONE]: {
            [entity]: {
              status: 'success',
              data: result,
              payload: { disableNotification, recordId },
              resource,
            },
          },
        });
      } catch (error) {
        onFailure?.({
          [GET_ONE]: {
            [entity]: {
              status: 'failure',
              data: error,
              payload: { disableNotification, recordId },
              resource,
            },
          },
        });
      }

      break;
    }
    case CUSTOM_GET: {
      const { resource, onSuccess, onFailure } =
        crudActionPayload as CrudGetOneApiPayload;

      try {
        const result = await dataProvider(CUSTOM_GET, resource);
        onSuccess?.(result?.data);
      } catch (error) {
        onFailure?.(error);
      }
      break;
    }

    case CUSTOM_POST: {
      const { resource, data, onSuccess, onFailure } =
        crudActionPayload as CrudCreateApiPayload;
      try {
        const result = await dataProvider(CUSTOM_POST, resource, { data });
        onSuccess?.(result);
      } catch (error) {
        onFailure?.(error);
      }
      break;
    }

    case GET_MANY_REFERENCE: {
      const { requestParameters, resource, target, id, onSuccess, onFailure } =
        crudActionPayload as CrudGetManyReferenceApiPayload;

      const { sort, pagination, filter } = requestParameters;

      try {
        const result = await dataProvider(GET_MANY_REFERENCE, resource, {
          pagination,
          sort,
          target,
          id,
          filter,
        });

        actorSetActionValue('additionalData', result?.additionalData, {
          path: resource,
        });

        onSuccess?.({
          [GET_MANY_REFERENCE]: {
            [entity]: {
              status: 'success',
              data: result,
              payload: {},
              resource,
            },
          },
        });
      } catch (error) {
        onFailure?.({
          [GET_MANY_REFERENCE]: {
            [entity]: {
              status: 'failure',
              data: error,
              payload: {},
              resource,
            },
          },
        });
      }

      break;
    }

    case RUN_SERVICE: {
      const { actionUniqueId, data, onSuccess, onFailure } =
        crudActionPayload as CrudRunServiceApiPayload;

      try {
        const result = await dataProvider(RUN_SERVICE, '', {
          actionUniqueId,
          data,
        });
        onSuccess?.(result);
      } catch (error) {
        onFailure?.(error);
      }
      break;
    }

    case DELETE: {
      const { resource, id, onSuccess, onFailure } =
        crudActionPayload as CrudDeleteOneApiPayload;

      try {
        const result = await dataProvider(DELETE, resource, {
          id,
        });
        onSuccess?.(result);
      } catch (error) {
        onFailure?.(error);
      }

      break;
    }

    default:
      break;
  }
});
