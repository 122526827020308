import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslate, useLocale } from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Box,
  Typography,
  Divider,
  Grid,
  Card,
  CardContent,
} from '@material-ui/core';

import { AnnouncementPosts } from '../component/announcement-posts';
import dashboardImage from '../images/dashboard.svg';
import ListContainerApiSimpleController from '../container/ListContainerApiSimpleController';
import DashboardAdvertiseView from '../component/DashboardAdvertiseView';
import LoadingBox from '../component/loading-box';
import { getAdvertisementPatternInfo } from '../helper/PatternMetaHelper';

import {
  CONFIG_DASHBOARD_ADVERTISEMENT_RESOURCE,
  getValue,
  SESSION_ID,
  DASHBOARD_CALENDAR_REPORT_ADDRESS,
} from '../core/configProvider';
import CheckResourceReady from '../container/CheckResourceReady';
import { actorDispatch, FormKeyMode } from '../type/actor-setup';
import dataProvider, { GET_REPORT } from '../core/dataProvider';
import { isEmpty, isEmptyObject } from '../helper/data-helper';
import { getSessionIdInUrl } from '../helper/UrlHelper';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: props =>
      props.isAdvEnabled
        ? 'transparent'
        : theme.palette.primary.appSecondaryBackgroundColor,
  },
  calendarContainer: {
    height: '100%',
    padding: '1rem 0',
  },
  mainBackground: {
    backgroundImage: props =>
      props.isAdvEnabled ? 'none' : `url(${dashboardImage})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    height: props => (props.isAdvEnabled ? '100%' : 'unset'),
    minHeight: props => (props.isAdvEnabled ? 'unset' : '100vh'),
  },

  cardContent: {
    padding: '0 !important',
    height: props => (props.isAdvEnabled ? '100%' : 'unset'),
  },
  headerContainer: {
    padding: '0 1rem',
  },

  newsBox: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  calendarBox: {
    order: 1,
    maxHeight: '400px',
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
}));

const advFilterValue = {
  forpage: 'home',
};

const DashboardPage = props => {
  const { advResource, isAdvEnabled, location } = props;

  const classes = useStyles(props);
  const translate = useTranslate();
  const locale = useLocale();

  const announcementReportId = '63443850-5823-4A41-8A78-1E4D47EB0295';
  const userSessionId = getValue(SESSION_ID);

  const [announcementData, setAnnouncementdata] = useState(null);
  const [currentPage, setcurrentPage] = useState(1);
  const [initialLoading, setInitialLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const calendarReportAddress = getValue(DASHBOARD_CALENDAR_REPORT_ADDRESS) ?? null;
  const newsBoxWidthSize = calendarReportAddress ? 9 : 12;

  /**
   *@function getAnnouncementReportData
   * @param {string} reportId
   * @param { number | undefined } page
   * @param { function } setLoading
   * @returns { Provise<void> }
   */
  const getAnnouncementReportData = async (
    reportId,
    page = currentPage,
    setLoading,
  ) => {
    try {
      setLoading(true);
      const data = await dataProvider(GET_REPORT, '', {
        reportId,
        pagination: { page, perPage: 10 },
      });

      if (data && !isEmptyObject(data)) {
        setAnnouncementdata(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  /**
   * @function onChangePageHandler
   * @param {number} page
   * @returns { void }
   */
  const onChangePageHandler = page => {
    setcurrentPage(page);
    getAnnouncementReportData(announcementReportId, page, setIsLoading);
  };

  useEffect(() => {
    actorDispatch(
      'setDocumentTitle',
      { recordTitle: `iMaster`, locale: locale },
      { replaceAll: true },
    );

    //remove breadcrumb in custom-app-bar.view
    actorDispatch(
      'urlInfo',
      {
        location,
        params: {},
      },
      {
        replaceAll: true,
      },
    );

    actorDispatch(
      'resources',
      {
        stack: [
          {
            type: FormKeyMode.ROOT,
            value: '/',
          },
        ],
        current: {
          type: FormKeyMode.ROOT,
          value: '/',
        },
      },
      { replaceAll: true },
    );

    if (!isEmpty(userSessionId) || getSessionIdInUrl()) {
      getAnnouncementReportData(announcementReportId, undefined, setInitialLoading);
    }
  }, []);

  if (initialLoading) {
    return <LoadingBox />;
  }

  return (
    <Grid container justify="flex-start">
      <Grid
        item
        xl={newsBoxWidthSize}
        lg={newsBoxWidthSize}
        md={newsBoxWidthSize}
        sm={12}
        className={classes.newsBox}
      >
        <Card className={classes.container}>
          {isEmptyObject(announcementData?.data) ? (
            <CardContent className={classes.cardContent}>
              <div className={classes.mainBackground}>
                {isAdvEnabled && (
                  <CheckResourceReady resourceName={advResource}>
                    <ListContainerApiSimpleController
                      {...props}
                      basePath={`/${advResource}`}
                      page={1}
                      perPage={1000}
                      resource={advResource}
                      classes={classes}
                      filter={advFilterValue}
                    >
                      {params => (
                        <DashboardAdvertiseView
                          {...params}
                          pattern={getAdvertisementPatternInfo(advResource)}
                          location={location}
                        />
                      )}
                    </ListContainerApiSimpleController>
                  </CheckResourceReady>
                )}
              </div>
            </CardContent>
          ) : (
            <AnnouncementPosts
              data={announcementData?.data}
              isLoading={isLoading}
              paginationParams={{ total: announcementData?.total, currentPage }}
              onChangePageHandler={onChangePageHandler}
            />
          )}
        </Card>
      </Grid>
      {calendarReportAddress && (
        <Grid item xl={3} lg={3} md={3} sm={12} className={classes.calendarBox}>
          <div className={classes.calendarContainer}>
            <Box className={classes.headerContainer}>
              <Typography variant="h6" gutterBottom>
                {translate('calendar.calendar')}
              </Typography>
            </Box>

            <Divider />
            <iframe
              src={`./#/${calendarReportAddress}`}
              style={{ height: '100%', width: '100%' }}
              frameBorder="0"
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = state => {
  const advResource = getValue(CONFIG_DASHBOARD_ADVERTISEMENT_RESOURCE);

  return {
    advResource,
    isAdvEnabled: advResource ? !!state.admin.resources[advResource] : false,
  };
};

export default connect(mapStateToProps)(DashboardPage);
