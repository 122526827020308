import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { getIconClassName } from '@uifabric/styling';

import { TODO } from '../../core/configRouteConstant';
import { getTodoListPatternInfo } from '../../helper/PatternMetaHelper';
import { getSortParams } from '../../helper/TodoListHelper';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 15px',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 200ms',
    '&:hover': {
      backgroundColor: '#FAFAFA',
      transition: 'background-color 200ms',
    },
  },
  linkContainer: {
    textDecoration: 'none',
  },

  title: {
    flexGrow: 1,
  },

  icon: {
    margin: '0 10px 0 0',
    fontSize: 18,
    padding: 2,
  },
}));

const TodoMenuAssignToMeItem = props => {
  const {
    title,
    icon,
    resource,
    filterType,
    color,
    todoListSelectedFilter,
    todoListSelectedId,
    userSort,
  } = props;
  const classes = useStyles();
  const { staticFilterList, reportTaskDetailsResource } =
    getTodoListPatternInfo(resource);
  const { filterName } = staticFilterList;

  const [url, setUrl] = useState(
    `#/${TODO}/${resource}?detail=${reportTaskDetailsResource}&filter={"${filterName}":["assignedtome","=","1"]}`,
  );

  useEffect(() => {
    setUrl(
      `#/${TODO}/${resource}?detail=${reportTaskDetailsResource}&filter={"${filterName}":["assignedtome","=","1"]}${getSortParams(
        userSort,
      )}`,
    );
  }, [userSort]);

  const getColor = () => {
    return {
      color,
    };
  };

  const selectedStyle = () => {
    return {
      backgroundColor:
        isNaN(todoListSelectedId) &&
        todoListSelectedFilter &&
        todoListSelectedFilter === filterType
          ? '#e4eaec'
          : 'unset',
    };
  };

  return (
    <a
      className={classes.linkContainer}
      href={url}
      data-test-todo-menu={title}
      data-test-todo-menu-icon={icon}
    >
      <div className={classes.container} style={selectedStyle()}>
        <i
          className={`${classes.icon} ${getIconClassName(icon)}`}
          style={getColor()}
        ></i>
        <Typography variant="caption" className={classes.title} style={getColor()}>
          {title}
        </Typography>
      </div>
    </a>
  );
};

TodoMenuAssignToMeItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  counter: PropTypes.number,
  color: PropTypes.string,
  selected: PropTypes.bool,
  resource: PropTypes.string,
  filterType: PropTypes.string,
  todoListSelectedFilter: PropTypes.string,
  todoListSelectedId: PropTypes.number,
  changeResource: PropTypes.func,
  onClick: PropTypes.func,
  userSort: PropTypes.object,
};

TodoMenuAssignToMeItem.defaultProps = {
  color: 'rgba(0, 0, 0, 0.54)',
};

export default TodoMenuAssignToMeItem;
