import { ReactElement } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { useStyles } from './text-input.style';
import { TextInputViewProps } from './text-input.type';
import InputBase from '../input-base';

export const TextInputView = (props: TextInputViewProps): ReactElement => {
  const {
    getRef,
    field,
    value,
    inputMessage,
    label,
    hint,
    disabled,
    handleKeyDown,
    resource,
    handleBlur,
    handleFocus,
    handleChange,
    visibleClass,
    inputContainerClass,
    customTestAttribute,
    passwordShown,
    setPasswordShown,
  } = props;

  const classes = useStyles(props);
  const { name, required } = field;

  return (
    <InputBase
      label={label}
      hint={hint}
      inputMessage={inputMessage}
      required={required}
      field={field}
      className={`${visibleClass} ${inputContainerClass}`}
    >
      <TextField
        {...customTestAttribute}
        ref={getRef}
        type={field.isHashed ? (!passwordShown ? 'password' : 'text') : 'text'}
        value={value ?? ''}
        name={name}
        required={required}
        classes={{
          root: classes.root,
        }}
        variant="outlined"
        InputProps={{
          disabled,
          onKeyDown: handleKeyDown,
          classes: {
            root: classes.outlineRoot,
            notchedOutline: classes.notchedOutline,
            disabled: classes.disabled,
          },
          endAdornment: field.isHashed ? (
            <InputAdornment position="end">
              <IconButton
                className={classes.icon}
                aria-label="toggle password visibility"
                onClick={() => setPasswordShown(perv => !perv)}
              >
                {!passwordShown ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
        inputProps={{
          'data-test-input-name': `${resource}/${name}`,
          'data-test-input-field-priority': field.priority,
          maxLength: field.maxLength,
        }}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        disabled={disabled}
        data-test="inputContainerTextField"
        data-test-has-error={inputMessage?.messageType === 'error'}
        data-test-has-warning={inputMessage?.messageType === 'warning'}
        size="small"
      />
    </InputBase>
  );
};
