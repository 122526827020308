import React, { FC, ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import SortIcon from '@material-ui/icons/Sort';
import Grid from '@material-ui/core/Grid';
import FilterIcon from '@material-ui/icons/FilterList';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslate } from 'react-admin';
import { IconButton } from '@material-ui/core';

interface CardListActionViewProps {
  openFilter: () => void;
  children: ReactNode;
}

const useStyles = makeStyles(theme => ({
  buttonStyle: {
    padding: 5,
    width: 'auto',
    height: 'auto',
  },
}));

const CardListActionView: FC<CardListActionViewProps> = props => {
  const { openFilter, children } = props;
  const classes = useStyles();

  return (
    <>
      <IconButton
        onClick={openFilter}
        color="inherit"
        className={classes.buttonStyle}
      >
        <FilterIcon />
      </IconButton>
      {children}
    </>
  );
};

export default CardListActionView;
