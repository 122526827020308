import lodashGet from 'lodash/get';

import dataProvider, { GET_QUICK_ACCESS } from '../core/dataProvider';
import { isEmpty, isEmptyObject } from './data-helper';
import { ActionListInterface } from './Types';
import { validUrl } from './UrlHelper';

export const actionList: ActionListInterface[] = [
  {
    name: 'quickAccess',
    title: 'quickAccess',
  },
];

/**
 * @function callQuickAccess
 * @param {string} shortcutKey
 * @returns {Promise<string>}
 */
export const callQuickAccess = async (shortcutKey: string): Promise<string> => {
  if (isEmpty(shortcutKey)) {
    return '';
  }

  try {
    const response = await dataProvider(GET_QUICK_ACCESS, null, {
      shortcutKey,
    });

    if (!isEmptyObject(response)) {
      return lodashGet(response, 'data', '');
    }
  } catch (error) {
    throw lodashGet(error, 'userMessage');
  }

  return '';
};

/**
 * Check URL and open new tab.
 * @function openNewTab
 * @param {string} url
 * @returns {void}
 */
export const openNewTab = (url: string, target = '_self'): void => {
  const finalUrl = validUrl(url) ? url : `#${url}`;

  if (target == '_self') {
    window.open(finalUrl, target);
  } else {
    const a = document.createElement('a');
    a.href = finalUrl;
    const evt = document.createEvent('MouseEvents');
    evt.initMouseEvent(
      'click',
      true,
      true,
      window,
      0,
      0,
      0,
      0,
      0,
      true,
      false,
      false,
      false,
      0,
      null,
    );
    a.dispatchEvent(evt);
  }
};

/**
 * It handles opening given `url` in different states('blank' | 'newtab' | 'popup')
 * @function linkOpenHandler
 * @param { string } url
 * @param { 'blank' | 'newtab' | 'popup' } type
 * @param { Record<string, unknown> } params
 * @returns void
 */
export const linkOpenHandler = (
  url: string,
  type: 'blank' | 'newtab' | 'popup',
  params?: Record<string, unknown>,
): void => {
  switch (type) {
    case 'blank':
      openNewTab(url);
      break;

    case 'newtab':
      openNewTab(url, '_blank');
      break;

    default:
      //TODO: handle `popup` type
      break;
  }
};
