import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import lodashFilter from 'lodash/filter';
import lodashMerge from 'lodash/merge';
import lodashMap from 'lodash/map';
import lodashDebounce from 'lodash/debounce';

import GridDropDownInputView from './grid-dropdown-input.view';
import { DropdownMeta } from '../../../dynamic-input/dropdown-input';
import { getDropdownRequestParams } from '../../../../helper/dropdown-api.helper';
import dataProvider, { GET_DROPDOWN } from '../../../../core/dataProvider';
import { isEmptyObject } from '../../../../helper/data-helper';
import { getLabelForDropdownOption } from '../../../dynamic-input/dropdown-input-old/dropdown-input.helper';

import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../../../../type/actor-setup';

import type { DropDownInEditModeInterface } from '../../grid.type';
import type { GridDropDownInputInterface } from './grid-dropdown-input.type';

const GridDropDownInputController: FC<GridDropDownInputInterface> = props => {
  const { field, onChangeInput, resource, isDisabled } = props;
  const { dropdown: dropdownMeta, name: fieldName } = field;
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<DropDownInEditModeInterface[]>([]);
  const [dropdownValue, setDropdownValue] = useState<string>('');
  const lastSearchedDropDownData = useRef<Record<string, unknown>[]>([{}]);
  const loading = isOpen && options.length === 0;

  const translate = useTranslate();

  useEffect(() => {
    actorOnDispatch('gridFormData', (gridFormData: Record<string, unknown>) => {
      const value = gridFormData[field.name] ?? '';
      setDropdownValue(value as string);
    });
  }, []);

  useEffect(() => {
    prepareDropdownData('');
  }, [isOpen]);

  useEffect(() => {
    debouncedSearch(dropdownValue);
  }, [dropdownValue]);

  /**
   * @function dropDownSearchByKey
   * @param {string} searchValue
   * @returns {void} void
   */
  const dropDownSearchByKey = async (searchValue: string) => {
    const gridFormData = actorGetActionValue('gridFormData')!;
    const dropdownId = dropdownMeta?.id;

    const params = {
      dropdownId: dropdownId,
      dropdownResource: resource,
      fieldName: fieldName,
      dropdownValueMember: dropdownMeta?.valueMember,
    };

    const finalParams = lodashMerge(
      getDropdownRequestParams({
        dropdownMeta,
        record: gridFormData,
        search: searchValue ?? ' ',
        isGridDropdown: true,
        resource,
      }),
      params,
    );
    return dataProvider(GET_DROPDOWN, dropdownId, finalParams);
  };

  /**
   * @function prepareDropdownData
   * @param { string } searchValue
   * @returns { Promise<void> }  Promise<void>
   */
  const prepareDropdownData = async (searchValue: string): Promise<void> => {
    const { valueMember } = dropdownMeta;

    const data = await dropDownSearchByKey(searchValue);

    const dropdownData: DropDownInEditModeInterface[] = [];

    if (data.result.length > 0) {
      dropdownData.push({
        value: null,
        label: translate('dropdown.noneLabel'),
      });
      lastSearchedDropDownData.current[fieldName] = data.result;
    }

    lodashMap(data.result, dropRes => {
      const label = getLabelForDropdownOption(dropdownMeta as DropdownMeta, dropRes);
      dropdownData.push({
        value: valueMember ? dropRes[valueMember] : '',
        label: label,
      });
    });

    if (dropdownData) {
      setOptions(dropdownData);
    }
  };

  /**
   * @function handleToggle
   * @returns {void}
   */
  const handleToggleAutoComplete = useCallback((): void => {
    setOptions([]);
    setIsOpen(prevIsOpen => !prevIsOpen);
  }, [setIsOpen]);

  /**
   * this function handle value change in search input drop down
   * @function handleChangeInput
   * @param {React.ChangeEvent<HTMLInputElement>} {event}
   * @return {void}
   */
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedSearch(event.target.value);
  };

  /**
   * @function debouncedSearch
   * @param { string } value
   * @returns { void }
   */
  const debouncedSearch = useCallback(
    lodashDebounce((value: string) => {
      prepareDropdownData(value);
    }, 500),
    [],
  );

  /**
   * @function handleDropdownChange
   * @param {FieldType} field
   * @param {unknown} value Can be any type, boolean, string, array, etc...
   * @returns {void} void
   */
  const mapDropdownData = (value: unknown): void => {
    const gridFormData = actorGetActionValue('gridFormData')!;
    gridFormData[fieldName] = value;

    const mapArray: { to: string; from: string }[] = dropdownMeta?.maps as {
      to: string;
      from: string;
    }[];
    const selectedDropDownInfo = lodashFilter(
      lastSearchedDropDownData.current[fieldName],
      {
        [fieldName]: value,
      },
    )[0];

    mapArray.forEach(item => {
      const target = item.to;

      const targetValue: string | undefined = selectedDropDownInfo?.[item.from];
      // if "item.from" is not defined, no need to trigger a change in form
      if (typeof targetValue !== 'undefined') {
        gridFormData[target] = targetValue;
      }
    });

    actorDispatch('gridFormData', gridFormData);
  };

  /**
   * Turn values into a string chain and emit onChange
   * @function handleChangeValue
   * @param {React.ChangeEvent<{}>} {event}
   * @param {string} {value}
   * @param {AutocompleteChangeReason} {reason}
   * @returns {void}
   */
  const handleChangeValue = (_event, dropdownValue): void => {
    if (!dropdownValue) return;

    onChangeInput({ fieldName, value: dropdownValue.value });
    mapDropdownData(dropdownValue.value);
    setDropdownValue(dropdownValue.value);
  };

  const getDropdownDefaultValue = () => {
    if (!dropdownMeta) {
      console.warn(
        'GridDropDownInputController: getDropdownDefaultValue => dropdownMeta is NULL',
      );

      return null;
    }

    const selectedDropDownInfo =
      lodashFilter(lastSearchedDropDownData.current[fieldName], {
        [dropdownMeta.valueMember]: dropdownValue,
      })[0] ?? {};

    if (!isEmptyObject(selectedDropDownInfo)) {
      const label = getLabelForDropdownOption(
        dropdownMeta as DropdownMeta,
        selectedDropDownInfo,
      );

      return {
        value: dropdownValue,
        label: label,
      };
    }
    return null;
  };

  return (
    <GridDropDownInputView
      handleChangeValue={handleChangeValue}
      toggleAutoComplete={handleToggleAutoComplete}
      isOpen={isOpen}
      items={options}
      loading={loading}
      handleChangeInput={handleChangeInput}
      isDisabled={isDisabled}
      dropdownValue={getDropdownDefaultValue()}
    />
  );
};

export default memo(GridDropDownInputController);
