import React, { Component } from 'react';
import { withStyles, Icon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { API_URL, getValue } from '../core/configProvider';

const styles = theme => ({
  file: {
    padding: '10px 5px',
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    margin: '0 7px',
    cursor: 'pointer',
  },

  fileName: {
    color: theme.palette.primary.main,
  },
});

class PreviewFile extends Component {
  handleClick = () => {
    const { onDelete, index } = this.props;
    onDelete(index);
  };

  render() {
    const { classes, file, index } = this.props;
    const apiUrl = getValue(API_URL);

    const fileUrl = `${apiUrl}/${file.folderPath}/${
      file.uploadedFileName ? file.uploadedFileName : file.fileName
    }`;

    return (
      <div className={classes.file} data-test-preview-file-index={`${index}`}>
        <Icon color="action" className={classes.icon} onClick={this.handleClick}>
          remove_circle
        </Icon>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={fileUrl}
          className={classes.fileName}
        >
          {file.originalFileName}
        </a>
      </div>
    );
  }
}

PreviewFile.propTypes = {
  file: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default withStyles(styles, { withTheme: true })(PreviewFile);
