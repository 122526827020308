import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(theme => ({
  relationPanelContainer: {
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: 20,
  },
}));
