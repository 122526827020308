import { ReactElement } from 'react';
import classNames from 'classnames';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Icon, IconButton, Typography } from '@material-ui/core';

import { useStyles } from './multi-file-stream-input.style';
import { FileStreamCustomChipInterface } from './multi-file-stream-input.type';

import { API_URL, getValue } from '../../../core/configProvider';
import { getFileSize } from '../../chat-section/chat-content/messages-screen/messages-list/messages-list.helper';
import { isEmpty } from '../../../helper/data-helper';

const FileStreamCustomChip = (
  props: FileStreamCustomChipInterface,
): ReactElement => {
  const {
    realFileName,
    filePath,
    onItemClick,
    onDeleteButtonClick,
    disabled,
    index,
    size,
  } = props;

  const classes = useStyles({ disabled });
  const apiUrl = getValue(API_URL);

  const chipClickHandler = (event: React.MouseEvent): void => {
    event.stopPropagation();
    if (!disabled) {
      onItemClick?.(filePath);
    }
  };

  /**
   * check file is image or not
   * @function isImage
   * @param {string} path
   * @returns {boolean} void
   */
  function isImage(path: string): boolean {
    return path?.match(/\.(jpeg|jpg|gif|png|svg|JPG|PNG|JPEG|GIF|SVG)$/) != null;
  }

  if (isEmpty(realFileName) || isEmpty(filePath)) return <></>;

  const fileExtension = realFileName?.split('.').at(-1);

  return (
    <div
      onClick={chipClickHandler}
      className={classNames(
        classes.chipContainerImage,
        disabled ? classes.disabled : '',
      )}
    >
      <div className={classes.chipImage}>
        <div className={classes.containerText}>
          <Typography className={classes.chipText}>{realFileName}</Typography>
          <span className={classes.chipTextSize}>{getFileSize(size!)}</span>
        </div>

        {isImage(filePath) ? (
          <img src={`${apiUrl}/${filePath}`} className={classes.preiviewImg} />
        ) : fileExtension ? (
          <FileIcon extension={fileExtension} {...defaultStyles[fileExtension]} />
        ) : null}

        {onDeleteButtonClick && (
          <IconButton
            onClick={onDeleteButtonClick(index)}
            disabled={disabled}
            size="small"
          >
            <Icon fontSize="small">clear</Icon>
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default FileStreamCustomChip;
