export default {
  ra: {
    action: {
      delete: 'حذف',
      show: 'نمایش',
      list: 'لیست',
      save: 'ذخیره',
      pin: 'ذخیره و سنجاق کردن',
      create: 'ایجاد',
      edit: 'ویرایش',
      confirm: 'تایید',
      cancel: 'انصراف',
      refresh: 'بروز‌رسانی',
      add_filter: 'اضافه‌کردن فیلتر',
      remove_filter: 'حذف این فیلتر',
      back: 'بازگشت',
      add: 'اضافه',
      reset: 'بازنشانی',
      bulk_actions:
        '۱ آیتم انتخاب شد |||| %{smart_count} عدد از آیتم‌ها انتخاب شدند',
      clear_input_value: 'پاک‌کردن مقدار',
      clone: 'شبیه‌سازی',
      export: 'دریافت خروجی',
      remove: 'حذف',
      search: 'جست‌وجو',
      sort: 'مرتب‌سازی',
      undo: 'لغو',
      unselect: 'لغو انتخاب',
      downloadExcelFile: 'دانلود فایل اکسل',
      downloadEncodingTextFile: 'دانلود فایل متنی (encoding)',
      downloadAsciiTextFile: 'دانلود فایل متنی (ascii)',
    },
    boolean: {
      true: 'بله',
      false: 'خیر',
    },
    page: {
      create: 'ایجاد %{name}',
      dashboard: 'داشبورد',
      edit: '%{name} #%{id}',
      error: 'مشکلی ایجاد شد',
      list: 'لیست %{name}',
      loading: 'در حال بارگزاری',
      not_found: 'پیدا نشد',
      show: '%{name} #%{id}',
    },
    input: {
      file: {
        upload_several:
          'تعدادی فایل برای آپلود دراپ کنید، یا برای انتخاب آن‌ها کلیک کنید.',
        upload_single: 'فایلی را برای آپلود دراپ کنید، یا برای انتخاب آن کلیک کنید',
      },
      image: {
        upload_several:
          'تعدادی عکس برای آپلود دراپ کنید، یا برای انتخاب آن‌ها کلیک کنید.',
        upload_single: 'عکسی را برای آپلود دراپ کنید، یا برای انتخاب آن کلیک کنید',
      },
      references: {
        all_missing: 'امکان پیدا کردن اطلاعات ارجاعی وجود ندارد.',
        many_missing: 'حداقل یکی از مراجع در دسترس نیست.',
        single_missing: 'مرجع مورد نظر در دسترس نیست.',
      },
      map: {
        open_map: 'باز کردن نقشه',
      },
      upload: 'بارگذاری کنید',
      noFileSelected: 'فایلی انتخاب نشده است',
      selectFile: 'انتخاب فایل',
    },
    message: {
      yes: 'بله',
      no: 'خیر',
      are_you_sure: 'آیا اطمینان دارید ؟',
      about: 'درباره',
      not_found:
        'شما یک نشانی اینترنتی اشتباه تایپ کردید یا پیغام بدی را دنبال کردید.',
      bulk_delete_content:
        'آیا از حذف  %{name} اطمینان دارید؟ |||| آیا از حدف %{smart_count} عدد از آیتم‌ها اطمینان دارید؟',
      bulk_delete_title:
        'حذف %{name} |||| حذف %{smart_count} عدد از آیتم‌های %{name}',
      delete_content: 'آیا از حذف این آیتم اطمینان دارید؟',
      delete_title: 'حذف %{name} #%{id}',
      details: 'جزییات',
      error: 'خطایی در مرورگر رخ داد. درخواست شما کامل نشد',
      invalid_form: 'فرم درست پر نشده است. لطفا خطاها را بررسی کنید',
      loading: 'صفحه در حال بارگزاری است، چند لحظه صبر کنید',
    },
    navigation: {
      no_results: 'نتیجه‌ای پیدا نشد',
      page_out_of_boundaries: 'شماره صفحه %{page} خارج از محدوده است',
      page_out_from_end: 'نمی‌توان به بعد از صفحه آخر رفت',
      page_out_from_begin: 'نمی‌توان به قبل از صفحه اول رفت',
      page_range_info: '%{offsetBegin}-%{offsetEnd}',
      next: 'بعدی',
      prev: 'قبلی',
      no_more_results:
        'شماره صفحه‌ی %{page} خارج از محدوده مجاز است. صفحه قبل را امتحان کنید.',
      page_rows_per_page: 'تعداد ردیف‌ها در صفحه:',
    },
    auth: {
      username: 'نام‌کاربری',
      password: 'رمز عبور',
      sign_in: 'ورود',
      sign_in_error: 'شناسایی با شکست مواجه شد، دوباره تلاش کنید',
      logout: 'خروج',
      user_menu: 'پروفایل',
      authError: 'دسترسی غیر مجاز',
    },
    notification: {
      updated: 'المان بروز‌رسانی شد',
      created: 'المان ایجاد شد',
      deleted: 'المان حذف شد',
      item_doesnt_exist: 'المان پیدا نشد',
      http_error: 'خطا در برقراری ارتباط با سرور',
      code_error: 'بروز خطا در برنامه',
      bad_item: 'المان اشتباه',
      data_provider_error: 'خطا در دریافت اطلاعات',
      canceled: 'لغو شد',
      notifications: 'اعلان ها',
      thereAreNoNewNotifications: 'هیچ اعلان جدیدی وجود ندارد.',
    },
    validation: {
      required: 'اجباری',
      minLength: 'حداقل باید %{min} کارکتر باشد',
      maxLength: 'باید %{max} کارکتر یا کمتر باشد',
      minValue: 'حداقل باید %{min} باشد',
      maxValue: 'باید %{max} یا کمتر باشد',
      number: 'باید یک عدد باشد',
      email: 'باید یک آدرس ایمیل صحیح باشد',
      oneOf: 'باید انتخابی از این گزینه‌ها باشد: %{options}',
      regex: 'باید با فرمت خاصی هماهنگ باشد (regexp): %{pattern}',
      correctUrl: 'فیلد آدرس به درستی وارد نشده است',
    },
  },
};
