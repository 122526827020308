import { FC, memo, useEffect, useState } from 'react';
import { useLocale } from 'react-admin';

import { setAppSettings } from '../../helper/settings-helper';
import lodashGet from 'lodash/get';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../../type/actor-setup';
import { MenuItemParams } from '../menu/sidebar-menu';
import ChildMenuView from './child-menu.view';

import type { ChildMenuControllerInterface } from './child-menu.type';

const ChildMenuController: FC<ChildMenuControllerInterface> = memo(() => {
  const [menuData, setMenuData] = useState<MenuItemParams[]>([]);
  const [favoriteItemsData, setFavoriteItemsData] = useState<MenuItemParams[]>([]); //todo: check
  const [childMenuData, setChildMenuData] = useState<MenuItemParams[]>([]);
  const [isModuleItemSelected, setIsModuleItemSelected] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const foundedItem = menuData.map((item: MenuItemParams) => item.id);
  const currentSelectedParentMenuId = actorGetActionValue('selectedParentMenuId')!;
  const locale = useLocale();

  /**
   * @function onExpandHandler
   * @returns {void}
   */
  const onExpandHandler = () => {
    actorDispatch('shouldAllItemsExpand', {
      [currentSelectedParentMenuId]: true,
    });
  };

  /**
   * @function onShrinkHandler
   * @returns {void}
   */
  const onShrinkHandler = () => {
    actorDispatch('shouldAllItemsExpand', {
      [currentSelectedParentMenuId]: false,
    });
  };

  /**
   * @function onCloseHandler
   * @returns {void}
   */
  const onCloseHandler = () => {
    actorDispatch('isChildMenuOpen', false);
  };

  /**
   * @function onAddToRecentItemsHandler
   * @param {MenuItemParams} newItem
   * @return {void}
   */
  const onAddToRecentItemsHandler = (newItem: MenuItemParams): void => {
    const currentRecentItemsData = actorGetActionValue('recentItemsData') || [];
    const updatedRecentItems =
      currentRecentItemsData.length === 10
        ? currentRecentItemsData.slice(1)
        : currentRecentItemsData.map(item => item.id).includes(newItem.id)
        ? currentRecentItemsData
        : [...currentRecentItemsData, newItem];
    actorDispatch('recentItemsData', updatedRecentItems);
  };

  /**
   * @function onItemClickHandler
   * @param {React.MouseEvent<HTMLElement>} event
   * @param {MenuItemParams} item
   * @returns {void}
   */
  const onItemClickHandler = (
    event: React.MouseEvent<HTMLElement>,
    item: MenuItemParams,
  ): void => {
    event.stopPropagation();
    onAddToRecentItemsHandler(item);
    onCloseHandler();
  };

  /**
   * @function isInfavoriteItems
   * @param {number} id
   * @returns {boolean}
   */
  const isInfavoriteItems = (id: number): boolean => {
    const currentFavoriteItemsData = actorGetActionValue('favoriteItemsData')!;
    return currentFavoriteItemsData?.some(item => item.id === id);
  };

  /**
   * @function onToggleFavoriteItemsHandler
   * @param {React.MouseEvent<HTMLElement>} event
   * @param {MenuItemParams} item
   * @returns {void}
   */
  const onToggleFavoriteItemsHandler = (
    event: React.MouseEvent<HTMLElement>,
    item: MenuItemParams,
  ): void => {
    event.stopPropagation();
    isInfavoriteItems(item.id)
      ? onDeleteFromFavoriteItemsHandler(item.id)
      : onAddToFavoriteItemsHandler(item);
  };

  /**
   * @function onDeleteFromFavoriteItemsHandler
   * @param {number} id
   * @returns {void}
   */
  const onDeleteFromFavoriteItemsHandler = (id: number): void => {
    const currentFavoriteItemsData = actorGetActionValue('favoriteItemsData') || [];
    const updateFavoriteItemsData = currentFavoriteItemsData.filter(
      item => item.id !== id,
    );
    actorDispatch('favoriteItemsData', updateFavoriteItemsData);

    setAppSettings({
      key: 'fav_menu_data_for_user',
      value: updateFavoriteItemsData,
      forUser: true,
    });
  };

  /**
   * @function onAddToFavoriteItemsHandler
   * @param {MenuItemParams} item
   * @return {void}
   */
  const onAddToFavoriteItemsHandler = (item: MenuItemParams): void => {
    const currentFavoriteItemsData = actorGetActionValue('favoriteItemsData') || [];
    actorDispatch('favoriteItemsData', [...currentFavoriteItemsData, item]);

    setAppSettings({
      key: 'fav_menu_data_for_user',
      value: [...currentFavoriteItemsData, item],
      forUser: true,
    });
  };

  /**
   * @function getTitle
   * @param { Record<string, unknown> } item
   * @returns {string}
   */
  const getTitle = (item: Record<string, unknown>): string => {
    return lodashGet(item, ['translatedTitle', locale], item.title);
  };

  useEffect(() => {
    actorOnDispatch('isModuleItemSelected', isModuleItemSelected => {
      setIsModuleItemSelected(isModuleItemSelected);
    });
  }, []);

  useEffect(() => {
    actorOnDispatch('isChildMenuOpen', isChildMenuOpen => {
      setIsOpen(isChildMenuOpen);
    });
  }, []);

  useEffect(() => {
    actorOnDispatch('menuData', data => {
      data && setMenuData(data);
    });
  }, []);

  useEffect(() => {
    actorOnDispatch('childMenuData', data => {
      setChildMenuData(data);
    });
  }, []);

  //todo: check
  useEffect(() => {
    actorOnDispatch('favoriteItemsData', data => {
      data && setFavoriteItemsData(data);
    });
  }, []);

  return (
    <ChildMenuView
      isOpen={isOpen}
      foundedItem={foundedItem}
      menuData={menuData}
      childData={childMenuData}
      isModuleSelected={isModuleItemSelected}
      onExpandHandler={onExpandHandler}
      onShrinkHandler={onShrinkHandler}
      onCloseHandler={onCloseHandler}
      onItemClickHandler={onItemClickHandler}
      isInfavoriteItems={isInfavoriteItems}
      onToggleFavoriteItemsHandler={onToggleFavoriteItemsHandler}
      getTitle={getTitle}
    />
  );
});

export default ChildMenuController;
