import { put, takeEvery } from 'redux-saga/effects';
import { GET_LIST } from 'react-admin';
import { FIND_ONE } from './constant';
import { findOneSuccessAction, findOneFailedAction } from './action';

import dataProvider from '../../core/dataProvider';

const loadingList = {};

function* findOne({ resource, source, value = '' }) {
  try {
    if (loadingList[resource]) {
      return;
    }

    // add to list to prevent multiple calls to same place
    loadingList[resource] = true;

    const params = {
      pagination: {
        page: 1,
        perPage: 100,
      },
      sort: {
        field: source,
        order: 'ASC',
      },
      filter: [[source, 'contains', value]],
      fields: [source],
      queryParams: {
        distinctSelect: 'true',
      },
    };

    const { data } = yield dataProvider(GET_LIST, resource, params);
    yield put(findOneSuccessAction({ resource, data, source }));
  } catch (error) {
    yield put(findOneFailedAction({ resource, error }));
  } finally {
    delete loadingList[resource];
  }
}

export default function* tagSaga() {
  yield takeEvery(FIND_ONE, findOne);
}
