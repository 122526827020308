import { put, takeEvery } from 'redux-saga/effects';
import { GET_ONE, CRUD_CREATE_FAILURE, translate } from 'react-admin';
import { push as redirectTo } from 'connected-react-router';

import dataProvider, { RUN_SERVICE } from '../../core/dataProvider';
import { CRUD_GET_ONE_DISABLE_NOTIFICATION_ACTION } from './action';
import { showNotification } from '../notification/action';
import { handleServerSideValidationErrors } from '../../helper/validation-helper';
import { isEmptyObject } from '../../helper/data-helper';
import { actorGetActionValue } from '../../type/actor-setup';

function* findOne({ resource, params, callback }) {
  try {
    const data = yield dataProvider(RUN_SERVICE, resource, params);

    if (typeof callback === 'function') {
      callback(null, data);
    }
  } catch (error) {
    console.log(error);
    if (typeof callback === 'function') {
      callback(error);
    }
  }
}

function* getOneAfterRunService({ resource, id, basePath }) {
  try {
    const data = yield dataProvider(GET_ONE, resource, {
      id: id,
    });

    yield put({
      type: 'RA/CRUD_GET_ONE_SUCCESS',
      payload: data,
      requestPayload: {
        id: id,
      },
      meta: {
        resource: resource,
        basePath: basePath,
        fetchResponse: 'GET_ONE',
        fetchStatus: 'RA/FETCH_END',
      },
    });
  } catch (response) {
    if (response.code !== 7008) {
      yield put(showNotification(response.userMessage, 'error'));
    }
    yield put(redirectTo(basePath));
  }
}

/**
 * Check api response and show error.
 * @generator
 * @function showApiError
 * @param {object} props
 * @yields handleApiErrors
 */
function* showApiError(props) {
  try {
    const { meta, error } = props;
    const { options = {} } = meta;

    const currentResource = actorGetActionValue('resources')?.current;
    const formData = actorGetActionValue('formData', [
      currentResource.value,
      currentResource.type,
    ]);

    if (isEmptyObject(options)) {
      console.log('serviceSaga.js:73 showApiError not supported');
    } else {
      const { metaData, fieldList, showNotification, translate, locale } = options;
      const { requestId, data } = error;

      if (typeof error === 'string') {
        yield put(showNotification(error, 'error'));
        return;
      }

      yield handleServerSideValidationErrors(
        metaData,
        fieldList,
        { apiErrors: data, requestId },
        formData,
        showNotification,
        translate,
        locale,
      );
    }
  } catch (response) {
    yield put(showNotification(response.userMessage, 'error'));
  }
}

export default function* serviceSaga() {
  yield takeEvery(RUN_SERVICE, findOne);
  yield takeEvery(CRUD_GET_ONE_DISABLE_NOTIFICATION_ACTION, getOneAfterRunService);
  yield takeEvery(CRUD_CREATE_FAILURE, showApiError);
}
