import React, { ReactElement, useEffect, useRef, useState } from 'react';
import lodashGet from 'lodash/get';
import { CodingInputInterface } from './coding-input.type';
import { CodingInputView } from './coding-input.view';
import { getTreeParentFieldName } from '../../../helper/MetaHelper';
import { isEmpty, isEmptyObject } from '../../../helper/data-helper';
import { getCodingPattern } from '../../../helper/InputHelper';
import { ChangeFormValueParams, FormActions, OnBlurParams } from '../../form';
import {
  actorGetActionValue,
  actorOnDispatch,
  RecordKeyMode,
} from '../../../type/actor-setup';

const CodingInput = (props: CodingInputInterface): ReactElement => {
  // --------------------------------------- destruct props ----------------------------------------------
  const {
    metaData,
    field,
    label,
    hint,
    className,
    disabled,
    value,
    inputMessage,
    visibleClass,
    customTestAttribute,
    getRef,
  } = props;

  const { name } = field;

  // --------------------------------------- Actor -----------------------------------------------
  const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;

  const { current: currentResource } = actorGetActionValue('resources')!;

  const record = actorGetActionValue('record', [
    currentResource.value,
    currentResource.type,
    RecordKeyMode.FULL,
  ]);
  const formData =
    (actorGetActionValue('formData', [
      currentResource.value,
      currentResource.type,
    ]) as FormData | null) ?? {};

  // --------------------------------------- states ----------------------------------------------

  const [codingValues, setCodingValues] = useState([]);
  const [maxLength, setMaxLength] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const prevIdTreeParent = useRef(
    lodashGet(formData, getTreeParentFieldName(metaData)),
  );

  useEffect(() => {
    const newIdTreeParent = lodashGet(formData, getTreeParentFieldName(metaData));

    if (prevIdTreeParent.current != newIdTreeParent) {
      prevIdTreeParent.current = newIdTreeParent;
    }
  }, [formData]);

  // --------------------------------------- to fetch coding default value ----------------------------------------------

  /**
   * Handle get coding data
   * @function getCodingDefaultValueHandler
   * @returns {void}
   */
  const getCodingDefaultValueHandler = (): void => {
    if (!isEmptyObject(record) && !isEmpty(record?.id)) {
      formActionsHandler(FormActions.GetCodingDefaultValue, {
        rowId: null,
        field: field,
        record: record,
      });
    } else if (prevIdTreeParent.current) {
      formActionsHandler(FormActions.GetCodingDefaultValue, {
        rowId: prevIdTreeParent.current,
        field: field,
        record: record,
      });
    }
  };

  useEffect(() => {
    getCodingDefaultValueHandler();
  }, [prevIdTreeParent.current]);

  useEffect(() => {
    actorOnDispatch(
      'codingInputData',
      codingInputData => {
        setIsLoading(codingInputData?.isLoading);
        if (!codingInputData?.isLoading) {
          const resMaxLength = getCodingPattern(
            field.codingPattern ?? '',
            codingInputData
              ? codingInputData?.data && codingInputData?.data['currentlevel']
              : lodashGet(formData, 'currentLevel'),
          );
          resMaxLength && setMaxLength(resMaxLength);
          setCodingValues(lodashGet(codingInputData?.data, '__codingvalues'));
        }
      },
      { preserve: false },
    );

    actorOnDispatch(
      'resetForm',
      () => {
        getCodingDefaultValueHandler();
      },
      { preserve: false },
    );
  }, []);

  /**
   * Handle Blur event
   * @function handleBlur
   * @returns {void} void
   */
  const handleBlur = (): void => {
    formActionsHandler(FormActions.InputBlur, {
      fieldName: name,
      value,
    } as OnBlurParams);
  };

  /**
   * Handle focus event
   * @function handleFocus
   * @returns {void} void
   */
  const handleFocus = (): void => {
    formActionsHandler(FormActions.InputFocus, {
      fieldName: name,
      value,
    } as ChangeFormValueParams);
  };

  /**
   * Handle Change event
   * @function handleChange
   * @param {ChangeEvent} event
   * @returns void
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value: event.target.value,
    } as ChangeFormValueParams);
  };

  return (
    <CodingInputView
      getRef={getRef}
      field={field}
      isLoading={isLoading}
      label={label}
      hint={hint}
      value={value}
      inputMessage={inputMessage}
      maxLength={maxLength}
      className={className}
      disabled={disabled}
      codingValues={codingValues}
      handleBlur={handleBlur}
      handleFocus={handleFocus}
      handleChange={handleChange}
      visibleClass={visibleClass}
      customTestAttribute={customTestAttribute}
    />
  );
};

export default CodingInput;
