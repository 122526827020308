import { Component } from 'react';
import { connect } from 'react-redux';
import lodashIsEqual from 'lodash/isEqual';
import lodashGet from 'lodash/get';
import lodashDebounce from 'lodash/debounce';
import { crudGetManyReference as crudGetManyReferenceAction } from 'react-admin';

import { getIds, getReferences, getTotal, nameRelatedTo } from './oneToMany';
import { mergeAndClone } from '../../helper/data-helper';

const SORT_ASC = 'ASC';
const SORT_DESC = 'DESC';

class ReferenceManyFieldController extends Component {
  constructor(params) {
    super(params);

    this.state = {
      sort: this.props.sort,
      page: 1,
      perPage: this.props.perPage,
      filterValues: this.props.filter,
      hasInitData: typeof this.props.initialCount !== 'undefined',
    };
  }

  componentDidMount() {
    if (!this.state.hasInitData) {
      this.fetchReferences();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.record.id !== nextProps.record.id ||
      !lodashIsEqual(this.props.filter, nextProps.filter) ||
      this.props.relationVersion !== nextProps.relationVersion ||
      !lodashIsEqual(this.props.resourceData, nextProps.resourceData) ||
      this.props.oneRelationVersion !== nextProps.oneRelationVersion
    ) {
      this.fetchReferences(nextProps);
    }
  }

  setSort = field => {
    const order =
      this.state.sort.field === field && this.state.sort.order === SORT_ASC
        ? SORT_DESC
        : SORT_ASC;
    this.setState({ sort: { field, order } }, this.fetchReferences);
  };

  setPage = page => this.setState({ page }, this.fetchReferences);

  setPerPage = perPage => this.setState({ perPage }, this.fetchReferences);

  setFilters = newFilters => {
    // merge clone into new variable
    const mergedFilterValues = mergeAndClone(this.state.filterValues, newFilters);

    // only apply filters with value
    Object.keys(mergedFilterValues).forEach(key => {
      if (!newFilters[key]) {
        delete mergedFilterValues[key];
      }
    });

    this.setState(
      { filterValues: mergedFilterValues, page: 1, hasInitData: false },
      this.fetchReferences,
    );
  };

  fetchReferences(
    { reference, record, resource, target, source, parentFieldName, filter } = this
      .props,
  ) {
    const { crudGetManyReference } = this.props;
    const { page, perPage, sort, filterValues } = this.state;
    const relatedTo = nameRelatedTo(
      reference,
      record[parentFieldName],
      resource,
      target,
      filter,
      filterValues,
    );

    crudGetManyReference(
      reference,
      target,
      record[parentFieldName],
      relatedTo,
      { page, perPage },
      sort,
      filterValues,
      source,
    );
  }

  render() {
    const {
      resource,
      reference,
      data,
      ids,
      children,
      basePath,
      total,
      initialData,
    } = this.props;
    const { page, perPage, hasInitData } = this.state;

    const referenceBasePath = basePath.replace(resource, reference);
    const mustUseInitData = page === 1 && hasInitData;

    const preparedData = {};
    if (mustUseInitData) {
      for (const row of initialData) {
        preparedData[row.id] = row;
      }
    }

    return children({
      currentSort: this.state.sort,
      data: mustUseInitData ? preparedData : data,
      ids: mustUseInitData ? initialData.map(row => row.id) : ids,
      loadedOnce: typeof ids !== 'undefined',
      page,
      perPage,
      referenceBasePath,
      setPage: this.setPage,
      setPerPage: this.setPerPage,
      setFilters: this.setFilters,
      setSort: this.setSort,
      total: total,
      fetchReferences: this.fetchReferences.bind(this),
    });
  }
}

ReferenceManyFieldController.defaultProps = {
  filter: {},
  perPage: 10,
  sort: { field: 'id', order: 'DESC' },
  source: 'id',
  parentFieldName: '',
};

function mapStateToProps(state, props) {
  const { parentFieldName, source, record, reference, resource } = props;

  const relatedTo = nameRelatedTo(
    props.reference,
    lodashGet(record, parentFieldName, source),
    props.resource,
    props.target,
    props.filter,
  );

  return {
    data: getReferences(state, props.reference, relatedTo),
    ids: getIds(state, relatedTo),
    total: getTotal(state, relatedTo),
    relationVersion: lodashGet(state, ['relation', 'relationVersion'], 0),
    resourceData: lodashGet(
      state,
      ['admin', 'resources', props.reference, 'data'],
      {},
    ),
    oneRelationVersion: lodashGet(
      state,
      ['relation', 'oneRelationVersion', resource, 'version'],
      lodashGet(state, ['relation', 'oneRelationVersion', reference, 'version'], 0),
    ),
  };
}

const mapDispatchToProps = {
  crudGetManyReference: crudGetManyReferenceAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReferenceManyFieldController);
