import { ReactElement } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';

import { BooleanInputViewProps } from './boolean-input.type';
import { useStyles } from './boolean-input.style';
import sanitizeRestProps from '../../inputs/sanitizeRestProps';
import InputBase from '../../input-base';
import { isEmptyObject } from '../../../helper/data-helper';

const BooleanInputView = (props: BooleanInputViewProps): ReactElement => {
  const {
    visibleClass,
    className,
    id,
    disabled,
    value,
    getRef,
    resource,
    label,
    hint,
    source,
    handleChange,
    handleFocus,
    handleBlur,
    field,
    isFocused,
    isBlurred,
    options,
    inputMessage,
    ...rest
  } = props;

  const { name } = field;

  const classes = useStyles({ messageType: inputMessage?.messageType });

  return (
    <InputBase
      className={`${visibleClass}`}
      label={label}
      hint={hint}
      inputMessage={inputMessage}
      required={field.required}
      field={field}
    >
      <div
        className={`${classes.booleanInputViewContainer} ${
          !isEmptyObject(inputMessage) ? classes.booleanInputViewContainerError : ''
        }`}
      >
        <FormGroup
          className={className}
          data-focus={isFocused}
          data-blur={isBlurred}
          {...sanitizeRestProps(rest as any)}
          id="switchButton"
        >
          <Switch
            ref={getRef}
            data-test-bool-input-value={!!value}
            {...options}
            id={id}
            color="primary"
            checked={!!value}
            name={name}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
          />
        </FormGroup>
      </div>
    </InputBase>
  );
};

export default BooleanInputView;
