import React, { FC, useEffect, useState, memo, useRef } from 'react';

import {
  actorGetActionValue,
  actorOnDispatch,
  waitForAction,
} from '../type/actor-setup';
import LoadingBox from '../component/LoadingBox';
import { ShowRecordWithRelation } from '../component/show-record-with-relation';
import NotFound from '../component/NotFound';
import { MetaData } from '../helper/Types';
import { useTranslate } from 'react-admin';

interface ShowRecordPageInterface {
  location: Location;
  match: Record<string, unknown> & {
    params: { id: string; moduleName: string; moduleTableName: string };
  };
}

const ShowRecordPage: FC<ShowRecordPageInterface> = props => {
  const { location, match } = props;
  const { moduleName, moduleTableName } = match.params;
  const { current: currentResource } = actorGetActionValue('resources')!;

  const resource = `${moduleName}/${moduleTableName}`;

  const [loading, setLoading] = useState(true); //fixme it will not turn true when resource change
  const currentMetaData = useRef<MetaData | null>(null);
  const translate = useTranslate();

  // loading
  useEffect(() => {
    actorOnDispatch('urlInfo', () => {
      setLoading(true);
      currentMetaData.current = null;
    });

    actorOnDispatch('metaData', async metaData => {
      await waitForAction('showSettingsLoading');
      const showSettingsLoading = actorGetActionValue('showSettingsLoading');

      currentMetaData.current = metaData[resource];

      if (showSettingsLoading === false && currentMetaData.current != null) {
        setLoading(false);
      }
    });
  }, []);

  if (loading) {
    return <LoadingBox />;
  }

  if (!currentMetaData.current) {
    return <NotFound />;
  }

  if (currentMetaData.current?.['error'] === 'networkError') {
    return <NotFound title={translate('ra.notification.http_error')} />;
  }

  const isReport = currentResource.value.indexOf('report') === 0;

  return (
    <ShowRecordWithRelation
      resource={resource}
      location={location}
      match={match}
      isReport={isReport}
    />
  );
};

export default memo(ShowRecordPage, (prevProps, nextProps) => {
  const prevResource = `${prevProps.match.params.moduleName}/${prevProps.match.params.moduleTableName}`;
  const nextResource = `${nextProps.match.params.moduleName}/${nextProps.match.params.moduleTableName}`;

  return (
    prevResource === nextResource &&
    prevProps.match.params.id === nextProps.match.params.id
  );
});
