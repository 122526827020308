import { makeStyles } from '@material-ui/core';
import { StyleProps } from '.';
import { CustomTheme } from '../../../core/themeProvider';
import { getColorBaseOfStatus } from '../../input-base/input-message/input-message.helper';

export const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  fieldset: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '0px !important',
    '& div:nth-child(2)': {
      height: 'auto !important',
    },
    '& button': {
      marginBottom: 2,
    },

    '& .datepicker-input': {
      visibility: 'hidden !important',
    },
    justifyContent: 'space-between',
  },

  InputAdornment: {
    width: 'auto !important',
    padding: 0,
    margin: 0,
  },

  datePickerIcon: {
    margin: '0 2px 0 5px',
    cursor: 'pointer',
    pointerEvents: ({ disabled }) => (disabled ? 'none' : 'all'),
  },

  error: {
    position: 'absolute',
    right: 0,
    top: 0,
  },

  legendError: {
    border: props => `1px solid ${getColorBaseOfStatus(props.messageType, theme)}`,
  },

  legend: {
    fontSize: 10,
    lineHeight: 0,
    color: theme.palette.primary.appSecondaryTextColor,
    display: 'block',
    padding: '0 3px',

    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
    },
  },

  inputMask: {
    // minWidth: '70%',
    fontSize: 11,
  },
  gridDateInput: {
    border: 'none !important',
  },
  closeIcon: {
    fonSize: '8px',
  },
  containerInInput: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexInInput: {
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dateInput: { width: '0px !important', marginRight: '-7px' },
}));
