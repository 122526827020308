import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';

import MetaProvider from '../../container/MetaProvider';
import DateFilterInput from '../filter/DateFilterInput';
import JalaliDateInput from '../JalaliDateInput';
import { InputViewType } from './InputTypes';
import { InputStyles } from './InputStyles';
import {
  getTypeByField,
  BOOLEAN_FIELD,
  COMPUTED_FIELD,
  DATE_FIELD,
  DROPDOWN_FIELD,
  LOCATION_FIELD,
  LONG_TEXT_FIELD,
  NUMBER_FIELD,
  POLYGON_FIELD,
  SEARCH_FIELD,
  TAG_FIELD,
  CODING_FIELD,
  DECIMAL_FIELD,
  FILE_FIELD,
  STRING_SELECT_FIELD,
  STRING_MULTI_SELECT_FIELD,
  DROP_BASE_MULTI_SELECT_FIELD,
  TYPE_CODING,
  TIME_FIELD,
  TYPE_DROPDOWN,
  TYPE_SEARCH_INPUT,
} from '../../helper/InputHelper';

import {
  ComputedInput,
  BooleanInput,
  LatLngInput,
  PolygonInput,
  CodingInput,
  DropdownInput,
  StringSelectInput,
  AutocompleteInput,
  SearchInput,
  TagInput,
  NumberInput,
  LongTextInput,
  FileInput,
  TimeInput,
  TextInput,
} from '../inputs-old';

const InputView: FC<InputViewType> = props => {
  const {
    field,
    inputProps,
    resource,
    inputInPuzzleForm,
    isServiceMode,
    handleClick,
    noLabel,
    filterMode,
    additionalProps,
    handleDropdownChange,
    disableDropdownQuickCreate,
    disableDropdownSearchPopup,
    triggerGoToNext,
    myRef,
    locale,
    defaultOperator,
    onlyEqualCondition,
    isInputFocusable,
    changeFormValue,
    relationResource,
    relationSource,
    relationInfo,
    addToRelationArray,
    ...rest
  } = props;

  const [view, setView] = useState<JSX.Element>(<div />);

  const classes = InputStyles();

  useEffect(() => {
    switch (getTypeByField(field)) {
      case POLYGON_FIELD:
        setView(<PolygonInput {...rest} {...inputProps} />);
        break;

      case LOCATION_FIELD:
        setView(<LatLngInput {...rest} {...inputProps} />);
        break;

      case CODING_FIELD:
        setView(
          <MetaProvider resourceName={resource}>
            <CodingInput
              {...rest}
              {...inputProps}
              className={classNames(
                classes.inputStyle,
                inputInPuzzleForm
                  ? classes.inputStylePuzzleForm
                  : noLabel
                  ? classes.inputStyleNoLabel
                  : null,
              )}
              onClick={handleClick}
              locale={locale}
              customChangeHandler={handleDropdownChange(TYPE_CODING)}
            />
          </MetaProvider>,
        );
        break;

      case COMPUTED_FIELD:
        setView(
          <ComputedInput
            {...rest}
            {...inputProps}
            formula={field.javaScriptFormula || ''}
            className={classNames(
              classes.inputStyle,
              inputInPuzzleForm
                ? classes.inputStylePuzzleForm
                : noLabel
                ? classes.inputStyleNoLabel
                : null,
            )}
          />,
        );
        break;

      case DROPDOWN_FIELD:
        setView(
          <DropdownInput
            {...rest}
            {...inputProps}
            dropdownMeta={field.dropdown}
            dropdownInFilter={filterMode}
            customChangeHandler={handleDropdownChange(TYPE_DROPDOWN)}
            disableDropdownQuickCreate={disableDropdownQuickCreate}
            disableDropdownSearchPopup={disableDropdownSearchPopup}
            triggerGoToNext={triggerGoToNext}
            dropdownInPuzzleForm={inputInPuzzleForm}
            isService={isServiceMode}
            myRef={myRef}
            relationResource={relationResource}
            relationSource={relationSource}
            relationInfo={relationInfo}
            changeFormValue={changeFormValue}
            addToRelationArray={addToRelationArray}
            additionalProps={additionalProps}
          />,
        );
        break;

      case STRING_SELECT_FIELD:
        setView(
          <StringSelectInput
            {...inputProps}
            inputInPuzzleForm={inputInPuzzleForm}
          />,
        );
        break;

      case STRING_MULTI_SELECT_FIELD:
        setView(
          <StringSelectInput
            {...inputProps}
            multiple={true}
            inputInPuzzleForm={inputInPuzzleForm}
          />,
        );
        break;

      case DROP_BASE_MULTI_SELECT_FIELD:
        setView(
          <AutocompleteInput
            {...rest}
            {...inputProps}
            dropdownMeta={field.dropdown}
            changeFormValue={changeFormValue}
            inputInPuzzleForm={inputInPuzzleForm}
            additionalProps={additionalProps}
          />,
        );
        break;

      case SEARCH_FIELD:
        setView(
          <SearchInput
            {...rest}
            {...inputProps}
            searchDialogMeta={field.searchDialog}
            data-disabled-for-style={!isInputFocusable}
            customChangeHandler={handleDropdownChange(TYPE_SEARCH_INPUT)}
            className={classNames(
              classes.inputStyle,
              inputInPuzzleForm
                ? classes.inputStylePuzzleForm
                : noLabel
                ? classes.inputStyleNoLabel
                : null,
            )}
            triggerGoToNext={triggerGoToNext}
          />,
        );
        break;

      case TAG_FIELD:
        setView(<TagInput {...rest} {...inputProps} field={field} />);
        break;

      case BOOLEAN_FIELD:
        setView(
          <BooleanInput
            {...rest}
            {...inputProps}
            className={classNames(
              classes.booleanStyle,
              inputInPuzzleForm ? classes.inputStylePuzzleForm : null,
            )}
          />,
        );
        break;

      case DECIMAL_FIELD:
        setView(
          <NumberInput
            {...rest}
            {...inputProps}
            className={classNames(
              classes.inputStyle,
              inputInPuzzleForm
                ? classes.inputStylePuzzleForm
                : noLabel
                ? classes.inputStyleNoLabel
                : null,
            )}
            decimalField={true}
            onClick={handleClick}
          />,
        );
        break;

      case NUMBER_FIELD:
        setView(
          <NumberInput
            {...rest}
            {...inputProps}
            className={classNames(
              classes.inputStyle,
              inputInPuzzleForm
                ? classes.inputStylePuzzleForm
                : noLabel
                ? classes.inputStyleNoLabel
                : null,
            )}
            onClick={handleClick}
          />,
        );
        break;

      case DATE_FIELD:
        filterMode
          ? setView(
              <DateFilterInput
                {...rest}
                {...inputProps}
                defaultOperator={defaultOperator}
                onlyEqualCondition={onlyEqualCondition}
              />,
            )
          : setView(
              <JalaliDateInput
                {...rest}
                {...inputProps}
                DateInputInPuzzleForm={inputInPuzzleForm}
              />,
            );
        break;

      case LONG_TEXT_FIELD:
        setView(
          <LongTextInput
            {...rest}
            {...inputProps}
            className={classNames(
              classes.inputStyle,
              classes.longInputStyle,
              inputInPuzzleForm
                ? classes.inputStylePuzzleForm
                : noLabel
                ? classes.longTextInputStyleNoLabel
                : null,
            )}
            onClick={handleClick}
          />,
        );
        break;

      case FILE_FIELD:
        setView(
          <FileInput
            {...rest}
            {...inputProps}
            className={classNames(
              classes.inputStyle,
              inputInPuzzleForm
                ? classes.inputStylePuzzleForm
                : noLabel
                ? classes.inputStyleNoLabel
                : null,
            )}
            onClick={handleClick}
          />,
        );
        break;

      case TIME_FIELD:
        setView(
          <TimeInput
            {...rest}
            {...inputProps}
            className={classNames(
              classes.inputStyle,
              inputInPuzzleForm
                ? classes.inputStylePuzzleForm
                : noLabel
                ? classes.inputStyleNoLabel
                : null,
            )}
            onClick={handleClick}
          />,
        );
        break;

      default:
        setView(
          <TextInput
            {...rest}
            {...inputProps}
            className={classNames(
              classes.inputStyle,
              inputInPuzzleForm
                ? classes.inputStylePuzzleForm
                : noLabel
                ? classes.inputStyleNoLabel
                : null,
            )}
            onClick={handleClick}
          />,
        );
    }
  }, [field, inputProps]);

  return view;
};

export default InputView;
