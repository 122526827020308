import produce from 'immer';
import { USER_LOGOUT } from 'react-admin';
import { FIND_ALL, FIND_ALL_FAILED, FIND_ALL_SUCCESS } from './constant';

import {
  setValue,
  getValue,
  removeValue,
  CONFIG_CACHED_MENU,
} from '../../core/configProvider';

const initialState = {
  isLoadedOnce: false,
  isLoading: false,
  list: getValue(CONFIG_CACHED_MENU),
  error: null,
};

const myReducer = (state = initialState, { type, id, data, error, menu }) =>
  produce(state, draft => {
    // eslint-disable-next-line default-case
    switch (type) {
      case USER_LOGOUT:
        draft.isLoadedOnce = false;
        draft.isLoading = false;
        draft.list = null;
        draft.error = null;
        break;

      case FIND_ALL:
        draft.isLoading = true;
        draft.error = null;
        break;

      case FIND_ALL_SUCCESS:
        setValue(CONFIG_CACHED_MENU, data);

        draft.isLoadedOnce = true;
        draft.isLoading = false;
        draft.list = data;
        draft.error = null;
        break;

      case FIND_ALL_FAILED:
        removeValue(CONFIG_CACHED_MENU);

        draft.isLoadedOnce = true;
        draft.isLoading = false;
        draft.list = data;
        draft.error = error.toString();
        break;
    }
  });

export default myReducer;
