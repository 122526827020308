import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useTranslate, useLocale } from 'react-admin';

import '../api/global-api';
import '../api/wms-api';
import '../api/dropdown-api';
import '../api/profile-api';
import '../api/service-api';
import '../api/stream-file-api';
import '../api/visitorCalendar-api';
import '../api/mail-api';
import '../api/chat-api';
import '../api/pivot-api';
import '../api/crud-api';
import '../api/print-api';
import '../api/quick-access-menu-api';

import { getValue, USER_TOKEN } from '../core/configProvider';
import {
  actorDispatch,
  actorGetActionValue,
  actorRemoveAction,
  actorSetActionValue,
  FormKeyMode,
} from '../type/actor-setup';
import { isEmpty, isEmptyObject } from '../helper/data-helper';
import { MAIL, VISITOR_CALENDAR, WMS } from '../core/configRouteConstant';
import { MailPageResource } from '../component/mail-section/mail-section.helper';
import { visitorCalenderResource } from '../page/VisitorCalendarPage';
import { getSessionIdInUrl } from '../helper/UrlHelper';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { computedMatch } = rest;
  const { params } = computedMatch;
  let newResource: string | undefined;
  const currentResource = actorGetActionValue('resources');

  const preventToShowLoadingInWMS = useRef(false);

  const translate = useTranslate();
  const locale = useLocale();

  useEffect(() => {
    if (!actorGetActionValue('reactAdminHelpers')) {
      actorSetActionValue('reactAdminHelpers', {
        translate,
        locale,
      });
    }
  }, []);

  actorDispatch('closeDialogs', true);

  // In `wms`, after first loading and getting `metData` we don't need to show `loading`
  if (!preventToShowLoadingInWMS.current) {
    actorDispatch('showLoading', true);
  }

  actorDispatch('urlInfo', {
    location: {
      ...location,
      pathname: rest.location.pathname, // We need `pathname` key in `rest.location`
    },
    params,
  });

  if (params.moduleName && params.moduleTableName) {
    newResource = `${params.moduleName}/${params.moduleTableName}`;
  } else if (location.href.includes(WMS)) {
    newResource = `${WMS}/${params.id}`;
    preventToShowLoadingInWMS.current = true;
  }
  // simulate resource (for InboxPage)
  else if (location.href.includes(MAIL)) {
    newResource = MailPageResource;
  }
  // simulate resource (for visitor calender)
  else if (location.href.includes(VISITOR_CALENDAR)) {
    newResource = visitorCalenderResource;
  } else if (computedMatch?.path === '/multi-report/:reportId/:tabIndex?') {
    newResource = `report/${computedMatch.params.reportId.toLowerCase()}`;
  }
  if (newResource) {
    //FIXME: Think and write better codes here
    if (!isEmptyObject(currentResource) && !location.href.includes('/create')) {
      const { type, value } = currentResource!.current;
      actorDispatch(
        'formMessages',
        {},
        {
          path: `${value}.${type}`,
          replaceAll: true,
        },
      );

      actorRemoveAction({
        actionName: 'formData',
        path: `${value}.${type}`,
      });

      actorRemoveAction({
        actionName: 'record',
        path: `${value}.${type}`,
      });
    }

    const parentUrl = actorGetActionValue(
      'quickCreateSupplementaryData',
      `${newResource}.${FormKeyMode.RELATION}.parentUrl`,
    );

    actorDispatch('resources', {
      stack: !isEmpty(parentUrl)
        ? [
            {
              type: FormKeyMode.ROOT,
              value: currentResource?.current?.value,
            },
            {
              type: FormKeyMode.RELATION,
              value: newResource,
            },
          ]
        : [
            {
              type: FormKeyMode.ROOT,
              value: newResource,
            },
          ],
      current: {
        type: !isEmpty(parentUrl) ? FormKeyMode.RELATION : FormKeyMode.ROOT,
        value: newResource,
      },
    });
  }

  const currentUrl =
    actorGetActionValue('urlInfo')?.location?.href ?? window.location.href ?? '';

  return (
    <Route
      {...rest}
      render={props =>
        getValue(USER_TOKEN) ||
        (currentUrl && currentUrl.toLowerCase().includes('sessionid')) ||
        getSessionIdInUrl() ? (
          <Component {...props} />
        ) : (
          <>
            <Redirect to="/" />
            {sessionStorage.setItem('redirectUrl', location.hash.slice(2))}
          </>
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
};

export default PrivateRoute;
