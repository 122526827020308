import { memo, FC } from 'react';
import { getTypeByField, inputTypes } from '../../../helper/InputHelper';
import { GridBooleanInput } from './grid-boolean-input';
import { GridDateInput } from './grid-date-input';
import { GridDropDownInput } from './grid-dropdown-input';
import { GridTextInput } from './grid-text-input';

import type { GridCustomInputInterface } from './grid-custom-input.type';

const GridCustomInputController: FC<GridCustomInputInterface> = memo(props => {
  const {
    focusOnLastInput,
    onChangeInput,
    isDisabled = false,
    isAddingMode,
    resource,
    field,
  } = props;

  // if (
  //   isEmptyObject(field) ||
  //   (!isAddingMode && isEmptyObject(selectedRowInfoForEdit))
  // ) {
  //   return null;
  // }

  const fieldType = getTypeByField(field);
  switch (fieldType) {
    case inputTypes.DATE_FIELD:
      return (
        <GridDateInput
          field={field}
          focusOnLastInput={focusOnLastInput}
          onChangeInput={onChangeInput}
          isDisabled={isDisabled}
        />
      );
    case inputTypes.DROPDOWN_FIELD:
      return (
        <GridDropDownInput
          field={field}
          onChangeInput={onChangeInput}
          resource={resource}
          isDisabled={isDisabled}
        />
      );
    case inputTypes.BOOLEAN_FIELD:
      return (
        <GridBooleanInput
          field={field}
          onChangeInput={onChangeInput}
          isDisabled={isDisabled}
        />
      );
    default:
      return (
        <GridTextInput
          field={field}
          onChangeInput={onChangeInput}
          isDisabled={isDisabled}
        />
      );
  }
});
export default GridCustomInputController;
