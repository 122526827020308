export const TOGGLE_TODO_MENU_SIDEBAR = 'TODO_LIST/TOGGLE_TODO_MENU_SIDEBAR';
export const CLOSE_TODO_MENU_SIDEBAR = 'TODO_LIST/CLOSE_TODO_MENU_SIDEBAR';
export const GET_TODO_LIST_ACTION = 'TODO_LIST/GET_TODO_LIST_ACTION';
export const GET_LIST_SUCCESS_ACTION = 'TODO_LIST/GET_LIST_SUCCESS_ACTION';
export const INSERT_TASK_ACTION = 'TODO_LIST/INSERT_TASK_ACTION';
export const INSERT_STEP_ACTION = 'TODO_LIST/INSERT_STEP_ACTION';
export const INSERT_TASK_FAILED_ACTION = 'TODO_LIST/INSERT_TASK_FAILED_ACTION';
export const GET_LIST_FAILED_ACTION = 'TODO_LIST/GET_LIST_FAILED_ACTION';
export const PLUS_LIST_COUNTER_ACTION = 'TODO_LIST/PLUS_LIST_COUNTER_ACTION';
export const ADD_NEW_TODO_LIST_OR_GROUP = 'TODO_LIST/ADD_NEW_TODO_LIST_OR_GROUP';
export const ADD_NEW_TODO_LIST_OR_GROUP_END =
  'TODO_LIST/ADD_NEW_TODO_LIST_OR_GROUP_END';
export const UPDATE_LIST_MEMBER = 'TODO_LIST/UPDATE_LIST_MEMBER';
export const ADD_LIST_TO_GROUP_ACTION = 'TODO_LIST/ADD_LIST_TO_GROUP_ACTION';
export const UPDATE_TODO_TASK_DETAIL = 'TODO_LIST/UPDATE_TODO_TASK_DETAIL';
export const DELETE_TODO_LIST = 'TODO_LIST/DELETE_TODO_LIST';
export const RENAME_TODO_LIST = 'TODO_LIST/RENAME_TODO_LIST';
export const OPEN_DIALOG = 'TODO_LIST/OPEN_DIALOG';
export const CLOSE_DIALOG = 'TODO_LIST/CLOSE_DIALOG';
export const OPEN_TODO_SHARE_LIST_DIALOG_ACTION =
  'TODO_LIST/OPEN_TODO_SHARE_LIST_DIALOG_ACTION';
export const CLOSE_TODO_SHARE_LIST_DIALOG_ACTION =
  'TODO_LIST/CLOSE_TODO_SHARE_LIST_DIALOG_ACTION';
export const REMOVE_MEMBER_ACTION = 'TODO_LIST/REMOVE_MEMBER_ACTION';
export const GET_SHARED_LIST_MEMBER_ACTION =
  'TODO_LIST/GET_SHARED_LIST_MEMBER_ACTION';
export const DELETE_TODO_GROUP = 'TODO_LIST/DELETE_TODO_GROUP';
export const TOGGLE_GROUP_EXPANDED = 'TODO_LIST/TOGGLE_GROUP_EXPANDED';
export const SELECT_TODO_LIST = 'TODO_LIST/SELECT_TODO_LIST';
export const DELETE_TODO_TASK = 'TODO_LIST/DELETE_TODO_TASK';
export const SYSTEM_TASK_ASSIGN = 'TODO_LIST/SYSTEM_TASK_ASSIGN';
export const GET_SYSTEM_TASK_FILTERS = 'TODO_LIST/GET_SYSTEM_TASK_FILTERS';
export const SET_SYSTEM_TASK_FILTERS = 'TODO_LIST/SET_SYSTEM_TASK_FILTERS';
