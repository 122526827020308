import React, { FC } from 'react';
import { NumberField, useTranslate } from 'react-admin';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import CartCardView from './CartCardView';
import LoadingBox from '../LoadingBox';
import { getSellinHistoryOrderDetailPatternInfo } from '../../helper/PatternMetaHelper';
import { getValue, API_URL } from '../../core/configProvider';
import { isEmpty } from '../../helper/data-helper';
import NotFound from '../NotFound';
import { CustomTheme } from '../../core/themeProvider';

interface SellinHistoryOrderDetailViewInterfaceProps {
  data: any;
  resource: string;
  translate: Function;
  additionalData: object;
  loading: boolean;
  error: string;
}

const styles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      height: '100%',

      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
      },
    },

    cards: {
      flexGrow: 1,
      overflow: 'auto',
      [theme.breakpoints.up('lg')]: {
        flex: 3,
      },
    },

    finalCart: {
      borderTop: `1px solid ${theme.palette.divider}`,
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'space-between',
      padding: 6,
      alignItems: 'center',

      [theme.breakpoints.up('lg')]: {
        alignSelf: 'flex-start',
        borderTop: 'none',
        flex: 1,
        margin: 10,
        boxShadow: theme.shadows[1],
        borderRadius: theme.shape.borderRadius,
        padding: 10,

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      },
    },

    titlePrice: {
      [theme.breakpoints.up('lg')]: {
        marginBottom: 5,
      },
    },

    price: {
      fontWeight: 600,
      color: green[500],
      display: 'inline',
      margin: '0 5px',
    },

    addToCartText: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 1,
      padding: 10,
    },
  }),
);

const SellinHistoryOrderDetailView: FC<
  SellinHistoryOrderDetailViewInterfaceProps
> = props => {
  const { data, resource, additionalData, loading, error } = props;
  const translate = useTranslate();
  const classes = styles();

  if (!isEmpty(error)) {
    return <NotFound title={error} notFoundIcon="block" />;
  }

  if (loading || !data.length) {
    return <LoadingBox />;
  }

  const pattern = getSellinHistoryOrderDetailPatternInfo(resource);
  const preparedAdditionalData = additionalData
    ? additionalData[pattern.additionalDataOrder]
    : {};

  return (
    <div className={classes.container}>
      <div className={classes.cards}>
        {data.map(item => {
          return (
            <CartCardView
              key={item.id}
              resource={pattern.showProductResource}
              product={item}
              pattern={pattern}
              apiUrl={getValue(API_URL)}
              onDelete={() => ({})}
              disableAction
            />
          );
        })}
      </div>
      <div className={classes.finalCart}>
        <div className={classes.addToCartText}>
          <div>
            <Typography variant="caption" className={classes.titlePrice}>
              {translate('sellin.totalItemCount')}:
            </Typography>
            <NumberField
              className={classes.price}
              source={'value'}
              record={{
                value: preparedAdditionalData[pattern.totalItemCount],
              }}
            />
          </div>
          <div>
            <Typography variant="caption" className={classes.titlePrice}>
              {translate('sellin.totalWholePrice')}:
            </Typography>
            <NumberField
              className={classes.price}
              source={'value'}
              record={{
                value: preparedAdditionalData[pattern.totalWholePrice],
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellinHistoryOrderDetailView;
