import moment from 'jalali-moment';
import { FC } from 'react';
import { Views, Calendar } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { BigCalendarViewInterface } from '.';
import jalaliLocalizer from '../../helper/localizers-jalaali-calendar/jalali-moment';
import ExtraParamsHoc from '../ExtraParamsHoc';
import LoadingBox from '../loading-box';
import { useStyles } from './big-calendar.style';
import './big-calendar.style.css';
import { BigCalendarToolbarView, EventWrapper } from './custom-components-calendar';

const DragAndDropCalendar = withDragAndDrop(Calendar);

const BigCalendarView: FC<BigCalendarViewInterface> = props => {
  const classes = useStyles();
  const {
    moveEvent,
    onDropFromOutside,
    resizeEvent,
    onSelectSlot,
    onRangeChange,
    onSelectEvent,
    eventStyleGetter,
    onView,
    view,
    CustomToolbarView = BigCalendarToolbarView,
    currentDate,
    isLoading,
    events,
    eventCustomProps,
  } = props;
  return (
    <div className={classes.calendar} data-style-main-calender="calender">
      {isLoading && <LoadingBox absolute />}
      <DragAndDropCalendar
        resizable
        selectable
        events={events}
        localizer={jalaliLocalizer}
        defaultDate={moment()}
        date={currentDate}
        defaultView={Views.MONTH}
        onEventDrop={moveEvent}
        onSelectSlot={onSelectSlot}
        components={{
          toolbar: CustomToolbarView,
          event: ExtraParamsHoc({
            eventCustomProps,
          })(EventWrapper),
        }}
        onEventResize={resizeEvent}
        onDropFromOutside={onDropFromOutside}
        onSelectEvent={onSelectEvent}
        onNavigate={onRangeChange}
        eventPropGetter={eventStyleGetter}
        onView={onView}
        view={view}
        showAllEvents
        rtl
      />
    </div>
  );
};

export default BigCalendarView;
