import { ReactElement, useEffect } from 'react';
import { InboxSection } from '../component/mail-section';

const InboxPage = (): ReactElement => {
  /**
   * set default title when page has been changed
   */
  useEffect(() => {
    return () => {
      document.title = 'iMaster';
    };
  }, []);

  return <InboxSection />;
};

export default InboxPage;
