import { FIND_ALL, FIND_ALL_FAILED, FIND_ALL_SUCCESS } from './constant';

export function findAllAction() {
  return {
    type: FIND_ALL,
  };
}

export function findAllSuccessAction(data) {
  return {
    type: FIND_ALL_SUCCESS,
    data,
  };
}

export function findAllFailedAction(error) {
  return {
    type: FIND_ALL_FAILED,
    error,
  };
}
