/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  useState,
  useRef,
  FC,
  cloneElement,
  ReactElement,
  useEffect,
} from 'react';
import { Checkbox, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDrag, useDrop } from 'react-dnd';
import { CustomTheme } from '../../core/themeProvider';

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: props => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 200ms',
    '&:hover': {
      backgroundColor:
        props['mode'] === 'step'
          ? theme.palette.primary.appPrimaryLightBackgroundColor
          : 'none',
    },
  }),

  title: props => ({
    flexGrow: 1,
    fontWeight: props['mode'] === 'task' ? 'initial' : 100,
    outline: 'none',
    border: 'none',
    padding: 5,
    height: 30,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor:
        props['mode'] === 'task'
          ? theme.palette.primary.appPrimaryLightBackgroundColor
          : 'inherit',
    },
    fontFamily: theme.typography.fontFamily,
  }),

  checkbox: props => ({
    padding: props['mode'] === 'task' ? 9 : 6,
  }),

  checkboxIcon: props => ({
    fontSize: props['mode'] === 'task' ? 20 : 15,
    paddingLeft: 20,
  }),

  borderBottomContainer: props => ({
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    transition: 'background-color 0ms',
    borderBottom:
      props['mode'] === 'task'
        ? 'unset'
        : `1px solid ${theme.palette.primary.appPrimaryDividerColor}`,
  }),

  deleteButton: {
    color: theme.palette.primary.appPrimaryDisableIconColor,
    fontSize: '1em',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.appPrimaryIconColor,
    },
  },

  importantIcon: {
    color: theme.palette.primary.appPrimaryDisableIconColor,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.appPrimaryIconColor,
    },
  },
}));

interface TodoItemEditViewProps {
  item: object;
  submit: Function;
  pattern: Pattern;
  lastItemRowOrder?: number;
  dndType: string;
  ActionElement: ReactElement;
  mode: string;
}
interface Pattern {
  title: string;
  isDone: string;
  id: string;
  rowOrder: string;
}
interface DropItem {
  type: string;
  id: number;
  order: number;
  title: string;
}

const TodoItemEditView: FC<TodoItemEditViewProps> = props => {
  const { item, submit, pattern, lastItemRowOrder, dndType, ActionElement, mode } =
    props;
  const classes = useStyles({ mode });

  const { title, isDone, id, rowOrder } = pattern;
  const [itemIsDone, setItemIsDone] = useState(item[isDone]);
  const [itemTitle, setItemTitle] = useState(item[title]);

  const handleInputFocus = event => {
    event.target.select();
  };

  const changeName = e => {
    setItemTitle(e.target.value);
    submit({ [title]: e.target.value }, item[id]);
  };

  useEffect(() => {
    setItemIsDone(item[isDone]); // it causes pessimistic update
  }, [item]);

  const doneItem = event => {
    setItemIsDone(event.target.checked); // it causes optimistic update
    submit({ [isDone]: event.target.checked }, item[id]);
  };

  const ref = useRef(null);
  let isItemDragging = false;
  if (mode === 'step') {
    const [, drag] = useDrag({
      item: {
        type: dndType,
        id: item[id],
        order: item[rowOrder],
        title: item[title],
      },
    });

    const [{ isDragging }, drop] = useDrop({
      accept: dndType,

      collect: monitor => ({
        isDragging: monitor.isOver(),
      }),
      drop(dropItem) {
        const newOrder = (lastItemRowOrder + item[rowOrder]) / 2;

        const data = { [rowOrder]: newOrder };
        submit(data, dropItem['id']);
      },
    });
    isItemDragging = isDragging;
    drag(drop(ref));
  }
  const draggingStyle = (isDragging: boolean) => {
    return {
      borderTop: isDragging ? '2px solid #ababab' : 'unset',
    };
  };

  return (
    <div
      className={classes.container}
      style={draggingStyle(isItemDragging)}
      ref={ref}
      data-test-is-step={mode === 'step'}
    >
      <Checkbox
        value={itemIsDone}
        checked={itemIsDone}
        data-test-task-view-isdone={itemIsDone}
        onChange={doneItem}
        color="secondary"
        className={classes.checkbox}
        icon={<Icon className={classes.checkboxIcon}>radio_button_unchecked</Icon>}
        checkedIcon={<Icon className={classes.checkboxIcon}>check_circle</Icon>}
      />
      <div
        className={`${classes.borderBottomContainer} ${classes.borderBottomContainer}`}
      >
        <input
          className={classes.title}
          value={itemTitle}
          onFocus={handleInputFocus}
          data-test-task-view-name={itemTitle}
          onChange={changeName}
        />
        {cloneElement(ActionElement, {})}
      </div>
    </div>
  );
};

export default TodoItemEditView;
