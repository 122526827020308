import lodashGet from 'lodash/get';
import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import { isEmpty, isEmptyObject, megaByteToByte } from '../../../helper/data-helper';

import { toBase64, isFileTypeImage } from '../../../helper/FileHelper';
import { showNotification } from '../../../helper/general-function-helper';
import { useWindowSize } from '../../../hooks/useWindowSize';
import {
  actorGetActionValue,
  actorOnDispatch,
  RecordKeyMode,
  actorRemoveAction,
  actorSetActionValue,
} from '../../../type/actor-setup';
import {
  ChangeFormValueParams,
  FormActions,
  GetFileParams,
} from '../../form/form.type';
import { formatBytes } from './file-input.helper';
import { FileInputInterface } from './file-input.type';
import FileInputView from './file-input.view';

const FileInputController: FC<FileInputInterface> = memo(props => {
  const {
    value,
    formActionsHandler,
    inputMessage,
    resource,
    field,
    label,
    hint,
    inputRef,
    disabled,
    visibleClass,
  } = props;
  const { name, required } = field;

  const [fileName, setFileName] = useState<string | null>(null);
  const [fileSize, setFileSize] = useState<string | null>(null);
  const [isImage, setIsImage] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dragCounter, setDragCounter] = useState<number>(0);
  const [fileInputData, setFileInputData] = useState({});

  const globalParameters = actorGetActionValue('globalParameters');
  const fileUploadLimitMB = lodashGet(globalParameters, 'fileUploadLimitMB', null);

  const translate = useTranslate();
  const { width } = useWindowSize();
  const currentResource = actorGetActionValue('resources')!.current;
  const record: any = actorGetActionValue('record', [
    currentResource.value,
    currentResource.type,
    RecordKeyMode.FULL,
  ]);
  const url: string = lodashGet(record, lodashGet(field, 'relatedName'));
  const rowSpan: number =
    width && width < 1260
      ? 1
      : width && width > 1260 && lodashGet(field, 'rowSpan')
      ? lodashGet(field, 'rowSpan')
      : 1; // Don't set `1` to the third `lodashGet` parameter.

  const calcHeight = `${rowSpan * 30}px`;

  const dropRef = useRef<HTMLFieldSetElement>(null);
  const actorOnDispatchCallbackIdRef = useRef<symbol>();

  useEffect(() => {
    try {
      const valueSpilt: string[] = String(value).split('#');
      const _isImage = isFileTypeImage(valueSpilt[0]) || false;
      setIsImage(_isImage);
      setFileName(value ? valueSpilt[0] : null);
      setFileSize(value ? formatBytes(valueSpilt[1]?.length) : null);
      setImageSrc(
        value
          ? _isImage
            ? `data:image/jpeg;base64,${valueSpilt[1]}`
            : valueSpilt[1]
          : '',
      );
    } catch (error) {
      console.error(error);
    }
  }, [value]);

  useEffect(() => {
    actorOnDispatch('loading', loading => {
      setLoading(lodashGet(loading, [`${resource}.${name}`]));
    });

    actorOnDispatchCallbackIdRef.current = actorOnDispatch(
      'fileInputData',
      response => {
        if (response?.[resource]?.[name]?.['src']) {
          setFileInputData(response);
        }
      },
    );

    return () => {
      actorRemoveAction({
        actionName: 'fileInputData',
        listenerId: actorOnDispatchCallbackIdRef.current,
      });

      actorSetActionValue('fileInputData', null, { path: `${resource}.${name}` });
    };
  }, []);

  useEffect(() => {
    const _data = lodashGet(fileInputData, [resource, name]);
    if (!isEmptyObject(_data)) {
      setIsImage(_data.isImage);
      setFileSize(formatBytes(_data.src.length));
      setImageSrc(_data.src);
      setFileName(lodashGet(record, lodashGet(field, 'name')));
    }
  }, [fileInputData]);

  useEffect(() => {
    if (!loading && isEmpty(value) && record && url) {
      formActionsHandler(FormActions.GetFileInput, {
        url,
        fieldName: name,
      } as GetFileParams);
    }
  }, [record]);

  useEffect(() => {
    const div = dropRef.current;
    if (div) {
      div.addEventListener('dragenter', handleDragIn);
      div.addEventListener('dragleave', handleDragOut);
      div.addEventListener('dragover', handleDrag);
      div.addEventListener('drop', handleDrop);
    }
    return () => {
      const div = dropRef.current;
      if (div) {
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('dragover', handleDrag);
        div.removeEventListener('drop', handleDrop);
      }
    };
  }, []);

  /**
   * we need to prevent the default browser behavior on that event, which is to open the dropped file.
   * @param {DragEvent} event
   * @function handleDrop
   * @returns {void}
   */
  const handleDrag = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
  };

  /**
   * define dragIn behavior and if there is dataTransferItems, setDragging(true)
   * @param {DragEvent} event
   * @function handleDragIn
   * @returns {void}
   */
  const handleDragIn = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    setDragCounter(prev => prev++);
    if (event?.dataTransfer?.items?.length) {
      setDragging(true);
    }
  };

  /**
   * define dragOut behaviour and  setDragging(false)
   * @param {DragEvent} event
   * @function handleDragOut
   * @returns {void}
   */
  const handleDragOut = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    setDragCounter(prev => prev--);
    if (dragCounter > 0) return;
    setDragging(false);
  };

  /**
   * function recives file from event.dataTrasfer.files and send it to onFileChange
   * @param {DragEvent} event
   * @function handleDrop
   * @returns {void}
   */
  const handleDrop = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    if (event?.dataTransfer?.items?.length) {
      const dropFile: any = {
        target: {
          files: event.dataTransfer.files,
        },
      };
      onFileChange(dropFile);
      event.dataTransfer.clearData();
      setDragCounter(0);
    }
  };

  /**
   * encode user selected file to base64, add file-name before the `#` indicator and fire form onChange
   * @function onFileChange
   * @param {Event} event selected file in input
   * @returns {void}
   */
  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event?.target?.files == null) return;

      if (
        fileUploadLimitMB &&
        !isEmpty(fileUploadLimitMB) &&
        megaByteToByte(+fileUploadLimitMB) < event.target.files[0]?.size
      ) {
        showNotification(
          translate('file.fileUploadLimitMB', {
            fileUploadLimitMB,
          }),
          'error',
        );
      } else {
        event.target.files &&
          setIsImage(isFileTypeImage(event.target.files[0].name));
        const fileMeta = lodashGet(event.target.files, '0');
        if (!fileMeta) {
          return;
        }
        toBase64(fileMeta).then(file => {
          const base64File = `${fileMeta.name}#${(file as string).substring(
            (file as string).indexOf(',') + 1,
          )}`;
          handleChange(base64File);
        });
      }
    },
    [formActionsHandler],
  );

  /**
   * triger `onClick` input file
   * @function handleClick
   * @returns {void}
   */
  const handleClick = (): void => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  /**
   * Remove file from formData
   * @function handleRemoveFile
   * @returns {void}
   */
  const handleRemoveFile = (): void => {
    fileName && setFileName(null);
    imageSrc && setImageSrc(null);
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
    handleChange(null);
  };

  /**
   * Handle Change event
   * @function handleChange
   * @param {string | null} value
   * @returns void
   */
  const handleChange = (value: string | null): void => {
    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value: value,
    } as ChangeFormValueParams);
  };

  return (
    <FileInputView
      dropRef={dropRef}
      disabled={disabled}
      resource={resource}
      label={label}
      hint={hint}
      required={required}
      name={name}
      onFileChange={onFileChange}
      inputFileRef={inputRef}
      handleClick={handleClick}
      dragging={dragging}
      isImage={isImage}
      imageSrc={imageSrc}
      fileName={fileName}
      value={value}
      handleRemoveFile={handleRemoveFile}
      inputMessage={inputMessage}
      fileSize={fileSize}
      loading={loading}
      calcHeight={calcHeight}
      visibleClass={visibleClass}
    />
  );
});

export default FileInputController;
