import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { useStyles } from './chat-sidebar.style';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import { SearchList } from './search-list';
import { isEmpty } from '../../../helper/data-helper';
import { ChatSidebarViewInterface } from './chat-sidebar.type';
import { ContactInterface } from '../chat-section.type';
import { UserList } from './user-list';

const ChatSidebarView = (props: ChatSidebarViewInterface): ReactElement => {
  const {
    chatListData,
    chatsLoading,
    fetchMoreChats,
    refreshChats,
    hasMoreChats,
    onUserSelect,
    selectedUser,
    searchValue,
    onSearch,
    foundedContacts,
    foundedMessages,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box className={classes.chatContent} id="boxChat">
      <Box px={3} mb={1} width="100%">
        <TextField
          autoFocus={true}
          variant="outlined"
          className={classes.chatSearch}
          placeholder={translate('chat.search')}
          value={searchValue}
          InputProps={{
            classes: {
              input: classes.input,
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={onSearch}
          id="inputhChat"
        />
      </Box>
      {!isEmpty(searchValue) ? (
        <SearchList
          contacts={foundedContacts}
          messages={foundedMessages}
          onUserSelect={onUserSelect}
          selectedUser={selectedUser as ContactInterface}
        />
      ) : (
        <Box mt={2} width={1}>
          <UserList
            data={chatListData}
            selectedUser={selectedUser}
            loading={chatsLoading}
            fetchMore={fetchMoreChats}
            refreshFunction={refreshChats}
            hasMore={hasMoreChats}
            onUserSelect={onUserSelect}
            isChatMode
          />
        </Box>
      )}
    </Box>
  );
};

export default ChatSidebarView;
