import {
  localStorageGetItem,
  parseJSON,
  setValue,
  USER_ID,
  USER_SETTING_VERSION,
  WEB_SETTING_ITEMS,
} from '../core/configProvider';
import lodashFind from 'lodash/find';
import lodashIsObject from 'lodash/isObject';
import lodashFindIndex from 'lodash/findIndex';

import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../type/actor-setup';
import { isEmpty } from '../helper/data-helper';

export type SettingResponse = {
  settingkey: string;
  settingvalue: string;
  id: string;
};

/**
 * @function updateLocalStorage
 * @param callBack
 * @returns
 */
const updateLocalStorage = (data: SettingResponse) => {
  const { settingkey, settingvalue, id } = data;
  const webSettingItems =
    localStorageGetItem<Record<string, unknown>[]>(WEB_SETTING_ITEMS);

  if (webSettingItems && !isEmpty(webSettingItems)) {
    const newSettingObject = {
      id: id,
      settingvalue: settingvalue ?? null,
      settingkey: settingkey,
    };

    const settingIndex = lodashFindIndex(webSettingItems, {
      settingkey: settingkey,
    });
    if (settingIndex !== -1) {
      webSettingItems.splice(settingIndex, 1, newSettingObject);
    } else {
      webSettingItems.push(newSettingObject);
    }
    setValue(WEB_SETTING_ITEMS, webSettingItems);
  }
};

/**
 * @function setAppSettings
 * @param { key: string; value: unknown | null; forUser?: boolean; onSuccess?: (...args) => void; onFailure?: (...args) => void; } settingData
 * @returns { Promise<void> } void
 */
export const setAppSettings = (settingData: {
  key: string;
  value: unknown | null;
  forUser?: boolean;
  onSuccess?: (...args) => void;
  onFailure?: (...args) => void;
}): void => {
  const { key, value, forUser, onSuccess, onFailure } = settingData;

  const currentSettingData = getAppSettings<Record<string, unknown>>(key, forUser);

  const preparedData =
    lodashIsObject(value) || Array.isArray(value) ? JSON.stringify(value) : value;

  if (currentSettingData.value) {
    // Now If `record` has a value, it means we have that in `appSettings`
    actorDispatch(
      'crudAction',
      {
        type: 'UPDATE',
        entity: 'appSettings',
        data: {
          settingkey: currentSettingData.key,
          settingvalue: preparedData,
          id: currentSettingData.settingId,
        },
        onSuccess: apiResultData => {
          updateLocalStorage(apiResultData);

          actorSetActionValue(
            'appSettings',
            { id: currentSettingData.settingId, value },
            {
              path: `objectList.${currentSettingData.key}`,
            },
          );

          onSuccess?.();
        },
        onFailure,
      },
      { disableDebounce: true },
    );
  } else {
    // Now If `record` doesn't have a value, it means we have to set that in `appSettings`
    actorDispatch(
      'crudAction',
      {
        type: 'CREATE',
        entity: 'appSettings',
        data: { settingkey: currentSettingData.key, settingvalue: preparedData },
        onSuccess: apiResultData => {
          updateLocalStorage(apiResultData);

          actorSetActionValue(
            'appSettings',
            { id: apiResultData.id, value: parseJSON(apiResultData?.settingvalue) },
            {
              path: `objectList.${currentSettingData.key}`,
            },
          );

          onSuccess?.();
        },
        onFailure,
      },
      { disableDebounce: true },
    );
  }
};

/**
 * @function getAppSettings
 * @param { string } key
 * @param { boolean } forUser
 * @returns { key: string; settingId: number | null; value: T | null; } object
 */
export const getAppSettings = <T>(
  key: string,
  forUser?: boolean,
): {
  key: string;
  settingId: number | null;
  value: T | null;
} => {
  let _key = `${localStorageGetItem<string>(USER_SETTING_VERSION)}_${key}`;

  if (forUser) {
    const userId = localStorageGetItem<number>(USER_ID);

    if (!isEmpty(userId)) {
      _key = `${localStorageGetItem<string>(USER_SETTING_VERSION)}_${userId}_${key}`;
    }
  }

  const appSettings = actorGetActionValue('appSettings');

  if (appSettings?.original == null) {
    return {
      key: _key,
      settingId: null,
      value: null,
    };
  }

  let settingData = appSettings.objectList[_key];
  if (settingData == null) {
    settingData = lodashFind(appSettings.original, { settingkey: _key }) ?? null;

    if (settingData) {
      appSettings.objectList[_key] = {
        id: settingData.id,
        value: parseJSON(settingData['settingvalue'] as string) ?? null,
      };
    }
  }

  let settingId: number | null = null;
  let value: T | null = null;
  if (appSettings.objectList[_key]) {
    value = appSettings.objectList[_key].value as T;
    settingId = Number(appSettings.objectList[_key].id);
  }

  return {
    key: _key,
    settingId,
    value,
  };
};
