import { FC, MutableRefObject } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Menu, animation } from 'react-contexify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslate } from 'react-admin';

import { CustomTheme } from '../../core/themeProvider';
import NotificationPanelItem from './NotificationPanelItem';
import {
  NotificationItemInterface,
  NotificationItemPatternType,
} from '../../helper/Types';
import LoadingBox from '../LoadingBox';
import { isEmptyObject } from '../../helper/data-helper';

interface NotificationPanelInterfaceProps {
  uniqueKey: string;
  notificationList: NotificationItemInterface[];
  fetchMoreData: () => void;
  hasMore: boolean;
  handleHiddenMenu: () => void;
  notificationItemPattern: NotificationItemPatternType;
  itemIdRefs: MutableRefObject<(string | number)[]>;
}

const styles = makeStyles((theme: CustomTheme) => ({
  munuContainer: {
    overflow: 'visible',
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    '&::after': {
      content: '""',
      display: 'block',
      padding: 10,
      position: 'absolute',
      top: '-3px',
      right: 25,
      backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
      transform: 'rotate(45deg)',
      zIndex: -1,
    },
  },

  itemContainer: {
    overflow: 'auto',
    maxHeight: 500,
  },

  infiniteScroll: {
    minHeight: 110,
  },

  notificationNotFound: {
    padding: 0,
    margin: 10,
    textAlign: 'center',
  },
}));

const NotificationPanel: FC<NotificationPanelInterfaceProps> = props => {
  const {
    uniqueKey,
    notificationList,
    fetchMoreData,
    hasMore,
    handleHiddenMenu,
    notificationItemPattern,
    itemIdRefs,
  } = props;
  const classes = styles();
  const translate = useTranslate();

  const { id } = notificationItemPattern;

  return (
    <Menu
      animation={animation.fade}
      id={uniqueKey}
      className={classes.munuContainer}
      onHidden={handleHiddenMenu}
    >
      <div className={classes.itemContainer}>
        <InfiniteScroll
          dataLength={
            notificationList && notificationList.length
              ? notificationList.length
              : 10
          }
          next={fetchMoreData}
          hasMore={isEmptyObject(notificationList) ? false : hasMore}
          loader={<LoadingBox size={25} />}
          endMessage={
            <div className={classes.notificationNotFound}>
              <Typography variant="body2" color="textSecondary">
                {translate('ra.notification.thereAreNoNewNotifications')}
              </Typography>
            </div>
          }
          className={classes.infiniteScroll}
          height={
            notificationList && notificationList.length >= 10
              ? 500
              : notificationList.length * 94 // 94 height of notification item
          }
        >
          {notificationList &&
            notificationList.length &&
            notificationList.map(record => {
              return (
                <NotificationPanelItem
                  key={record[id]}
                  record={record}
                  pattern={notificationItemPattern}
                  itemIdRefs={itemIdRefs}
                />
              );
            })}
        </InfiniteScroll>
      </div>
    </Menu>
  );
};

export default NotificationPanel;
