import {
  TOGGLE_TODO_MENU_SIDEBAR,
  CLOSE_TODO_MENU_SIDEBAR,
  GET_TODO_LIST_ACTION,
  GET_LIST_SUCCESS_ACTION,
  GET_LIST_FAILED_ACTION,
  INSERT_TASK_ACTION,
  INSERT_STEP_ACTION,
  INSERT_TASK_FAILED_ACTION,
  PLUS_LIST_COUNTER_ACTION,
  ADD_NEW_TODO_LIST_OR_GROUP,
  UPDATE_LIST_MEMBER,
  ADD_LIST_TO_GROUP_ACTION,
  DELETE_TODO_LIST,
  RENAME_TODO_LIST,
  OPEN_DIALOG,
  CLOSE_DIALOG,
  OPEN_TODO_SHARE_LIST_DIALOG_ACTION,
  CLOSE_TODO_SHARE_LIST_DIALOG_ACTION,
  REMOVE_MEMBER_ACTION,
  GET_SHARED_LIST_MEMBER_ACTION,
  UPDATE_TODO_TASK_DETAIL,
  DELETE_TODO_GROUP,
  TOGGLE_GROUP_EXPANDED,
  ADD_NEW_TODO_LIST_OR_GROUP_END,
  SELECT_TODO_LIST,
  DELETE_TODO_TASK,
  SYSTEM_TASK_ASSIGN,
  GET_SYSTEM_TASK_FILTERS,
  SET_SYSTEM_TASK_FILTERS,
} from './constant';

export function toggleTodoMenuSidebarAction() {
  return {
    type: TOGGLE_TODO_MENU_SIDEBAR,
  };
}

export function closeTodoMenuSidebarAction() {
  return {
    type: CLOSE_TODO_MENU_SIDEBAR,
  };
}

export function getTodoListAction(
  resource,
  reportResource,
  sort,
  pagination,
  fieldId,
  todoListPattern,
) {
  return {
    type: GET_TODO_LIST_ACTION,
    resource,
    reportResource,
    sort,
    pagination,
    fieldId,
    todoListPattern,
  };
}

export function getListSuccessAction(params) {
  return {
    type: GET_LIST_SUCCESS_ACTION,
    ...params,
  };
}

export function insertTaskAction(resource, body, parentList) {
  return {
    type: INSERT_TASK_ACTION,
    resource,
    body,
    parentList,
  };
}

export function insertStepAction(resource, body) {
  return {
    type: INSERT_STEP_ACTION,
    resource,
    body,
  };
}

export function insertTaskFailedAction({ resource, error }) {
  return {
    type: INSERT_TASK_FAILED_ACTION,
    resource,
    error,
  };
}

export function getListFailedAction({ resource, error }) {
  return {
    type: GET_LIST_FAILED_ACTION,
    resource,
    error,
  };
}

export function plusListCounterAction({
  parentResource,
  listId,
  patternListId,
  patternCounter,
}) {
  return {
    type: PLUS_LIST_COUNTER_ACTION,
    resource: parentResource,
    id: listId,
    patternListId,
    patternCounter,
  };
}

export function addNewListOrGroupAction(
  resources,
  data,
  listMemberRowOrder,
  userId,
  sort,
  pagination,
  userIdPattern,
  insertFieldIdPattern,
  todoListPattern,
) {
  return {
    type: ADD_NEW_TODO_LIST_OR_GROUP,
    resources,
    data,
    listMemberRowOrder,
    userId,
    sort,
    pagination,
    userIdPattern,
    insertFieldIdPattern,
    todoListPattern,
  };
}

export const addNewListOrGroupEndAction = () => ({
  type: ADD_NEW_TODO_LIST_OR_GROUP_END,
});

export function updateListMemberAction(
  resource,
  data,
  listResource,
  reportResource,
  sort,
  pagination,
  getList = true,
) {
  return {
    type: UPDATE_LIST_MEMBER,
    resource,
    data,
    listResource,
    reportResource,
    sort,
    pagination,
    getList,
  };
}

export function addListToGroupAction(
  resource,
  data,
  reportResource,
  sort,
  pagination,
  getTodoListResource,
) {
  return {
    type: ADD_LIST_TO_GROUP_ACTION,
    resource,
    data,
    reportResource,
    sort,
    pagination,
    getTodoListResource,
  };
}

export function updateTodoTaskDetailAction(
  resource,
  data,
  options,
  listResource = null,
) {
  return {
    type: UPDATE_TODO_TASK_DETAIL,
    resource,
    data,
    options,
    listResource,
  };
}

export function renameTodoListAction(
  resource,
  data,
  reportResource,
  sort,
  pagination,
  getTodoListResource,
) {
  return {
    type: RENAME_TODO_LIST,
    resource,
    data,
    reportResource,
    sort,
    pagination,
    getTodoListResource,
  };
}

export function deleteTodoListAction(
  resource,
  deleteService,
  data,
  reportResource,
  sort,
  pagination,
  isCurrentList,
  reportTaskDetailsResource,
) {
  return {
    type: DELETE_TODO_LIST,
    resource,
    deleteService,
    data,
    reportResource,
    sort,
    pagination,
    isCurrentList,
    reportTaskDetailsResource,
  };
}

export function deleteEmptyGroupAction(
  resource,
  deleteService,
  data,
  reportResource,
  sort,
  pagination,
) {
  return {
    type: DELETE_TODO_GROUP,
    resource,
    deleteService,
    data,
    reportResource,
    sort,
    pagination,
  };
}

export function openDialogAction(resource, id, selectedTodoList, listResource) {
  return {
    type: OPEN_DIALOG,
    resource,
    id,
    selectedTodoList,
    listResource,
  };
}

export function closeDialogAction() {
  return {
    type: CLOSE_DIALOG,
  };
}

export function openTodoShareListDialogAction(
  resource,
  listMemberResource,
  todolistItem,
  shareListParentId,
) {
  return {
    type: OPEN_TODO_SHARE_LIST_DIALOG_ACTION,
    resource,
    listMemberResource,
    todolistItem,
    shareListParentId,
  };
}

export function closeTodoShareListDialogAction() {
  return {
    type: CLOSE_TODO_SHARE_LIST_DIALOG_ACTION,
  };
}

export function removeMemberAction(
  resource,
  listMemberResource,
  data,
  pattern,
  listSharedMember,
  sort,
  pagination,
  reportResource,
  translate,
  reportSharedListMember,
  options,
) {
  return {
    type: REMOVE_MEMBER_ACTION,
    resource,
    listMemberResource,
    data,
    pattern,
    listSharedMember,
    sort,
    pagination,
    reportResource,
    translate,
    reportSharedListMember,
    options,
  };
}

export function getSharedListMemberAction(resource, option, previousData) {
  return {
    type: GET_SHARED_LIST_MEMBER_ACTION,
    resource,
    option,
    previousData,
  };
}

export const toggleGroupExpandedAction = idListMember => ({
  type: TOGGLE_GROUP_EXPANDED,
  id: idListMember,
});

export const setSelectTodoList = listMember => ({
  type: SELECT_TODO_LIST,
  selectedListMember: listMember,
});

export const deleteTodoTaskAction = (
  serviceId,
  data,
  resource,
  reportResource,
  sort,
  pagination,
  parentList,
) => ({
  type: DELETE_TODO_TASK,
  serviceId,
  data,
  resource,
  reportResource,
  sort,
  pagination,
  parentList,
});

export function systemTaskAssign(
  serviceId,
  data,
  resource,
  reportResource,
  sort,
  pagination,
) {
  return {
    type: SYSTEM_TASK_ASSIGN,
    serviceId,
    data,
    resource,
    reportResource,
    sort,
    pagination,
  };
}

export function getSysTaskFilters(resource, options) {
  return {
    type: GET_SYSTEM_TASK_FILTERS,
    resource,
    options,
  };
}

export function setSysTaskFilters(sysTaskFilters) {
  return {
    type: SET_SYSTEM_TASK_FILTERS,
    sysTaskFilters,
  };
}
