import React, { ReactElement } from 'react';
import lodashGet from 'lodash/get';
import Typography from '@material-ui/core/Typography';

import EditButton from '../EditButton';
import QuickCreateButton from '../../container/QuickCreateButton';
import { isRecordEditable } from '../../helper/MetaHelper';
import { isEmptyObject } from '../../helper/data-helper';

interface TreeRow {
  title: string;
  id: string | number;
  compositid: string;
  currentlevel: number;
  text: string;
  items: [];
  selected: boolean;
  expanded: boolean;
}

interface Classes {
  itemContainer: string;
  item: string;
  editButton: string;
}

/**
 * Render item for tree (it will pass to TreeView)
 * @function TreeRowItem
 * @param {object} row data object of this row
 * @param {string} treeParentFieldName
 * @param {object} classes styles
 * @param {Function} handleClickOnItem
 * @param {boolean} isDropdown
 * @param {number} treeLevel max level of tree depth
 * @param {string} basePath
 * @param {ReactElement} contentCreate
 * @param {string|boolean} redirect
 * @param {object} metaData
 * @param {string} resource
 * @param {string} displayField
 * @param {function} translate
 * @returns {ReactElement}
 */
const TreeRowItem = (
  row: TreeRow,
  treeParentFieldName: string,
  classes: Classes,
  handleClickOnItem: Function,
  isDropdown: boolean,
  treeLevel: number,
  basePath: string,
  contentCreate: ReactElement,
  redirect: string | boolean,
  metaData: object,
  resource: string,
  displayField: string,
  translate: Function,
): ReactElement => {
  const { id, currentlevel } = row;

  const quickCreateData: object = !isEmptyObject(row)
    ? { [treeParentFieldName]: id, currentlevel: currentlevel }
    : {};
  //TODO: At the time of testing there is a div that `display:none`, check why this div is created.
  return (
    <div className={classes.itemContainer}>
      <div
        className={classes.item}
        onClick={handleClickOnItem(row)}
        data-test-has-child={row.items && row.items.length ? 'true' : 'false'}
      >
        <Typography>{row[displayField]}</Typography>

        {!isDropdown && (
          <EditButton
            classes={{
              IconButton: classes.editButton,
            }}
            record={row}
            basePath={basePath}
            icon={contentCreate}
            redirect={redirect}
            disabled={!isRecordEditable(metaData, row)}
            id="editBranchButtonInRow"
            data-test-edit-button-id={lodashGet(row, 'id')}
          />
        )}

        {!isDropdown && currentlevel !== treeLevel - 1 && (
          <QuickCreateButton
            data={quickCreateData}
            resource={resource}
            label={translate('grid.quickCreate')}
            mustRefresh={true}
            redirect={redirect}
            id="createNewBranchButtonInRow"
            relationMode={true} // disable fullForm
            disabled={currentlevel === treeLevel - 1}
            data-test-new-branch-row={lodashGet(row, 'id')}
            parentFieldName={treeParentFieldName}
          />
        )}
      </div>
    </div>
  );
};

export default TreeRowItem;
