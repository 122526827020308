import React, { Fragment, useState } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  MenuItem,
  Popper,
  Paper,
  ListItemIcon,
  Theme,
} from '@material-ui/core';
import { getIconClassName } from '@uifabric/styling';
import { isEmpty } from '../../helper/data-helper';

const styles: any = (theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  icon: {
    margin: '0 5px',
    fontSize: 16,
  },

  menuTitle: {
    textAlign: 'center',
    padding: '15px 30px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  value: {
    fontSize: 10,
    paddingTop: 4,
  },

  withValue: {
    color: theme.palette.secondary.main,
  },

  overlay: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: '1200',
    display: 'none',
  },

  showOverlay: {
    display: 'block !important',
  },

  submenuParent: {
    overflow: 'unset',
    '&:hover .submenu': {
      display: 'block',
    },
  },

  submenu: {
    position: 'absolute',
    left: '100%',
    backgroundColor: '#FFF',
    padding: '10px',
    boxShadow: '0px 1px 4px #999',
    display: 'none',
  },
});

const ContextMenu = props => {
  const {
    classes,
    title,
    icon,
    items,
    btnTitle,
    isOpen,
    value,
    onClick,
    onItemClick,
    fieldName,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    onClick();
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
  };

  const handleClose = event => {
    onClick();
    setAnchorEl(null);
  };

  const handleItemClick = item => event => {
    onItemClick(item, fieldName);
    handleClose(event);
  };

  const id = isOpen ? 'simple-popper' : undefined;

  return (
    <Fragment>
      <div
        onClick={handleClose}
        className={`${classes.overlay} ${isOpen ? classes.showOverlay : ''}`}
      ></div>
      <div
        aria-describedby={id}
        onClick={handleClick}
        className={classes.container}
        data-test-context-menu={icon}
      >
        <i
          className={`${classes.icon} ${getIconClassName(icon)} ${
            !isEmpty(value) ? classes.withValue : ''
          }`}
        ></i>
        <div className={classes.titleContainer}>
          <Typography
            variant="caption"
            color={!isEmpty(value) ? 'secondary' : 'textPrimary'}
          >
            {btnTitle ? btnTitle : title}
          </Typography>
          {value && (
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.value}
            >
              {value}
            </Typography>
          )}
        </div>
      </div>
      <Popper
        style={{ zIndex: 1300 }}
        id={id}
        anchorEl={anchorEl}
        open={isOpen}
        disablePortal={false}
        placement="bottom"
      >
        {({ TransitionProps }) => (
          <Paper>
            <Typography variant="body2" className={classes.menuTitle}>
              {title}
            </Typography>
            {items &&
              items.map(item => {
                if (item.disable) {
                  return null;
                }
                const submenuKeyExistInItem = item?.submenu != null ? true : false;
                return (
                  <MenuItem
                    className={submenuKeyExistInItem && classes.submenuParent}
                    key={item.title + item.icon}
                    onClick={handleItemClick(item)}
                    data-test-context-menu-item={item.name}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <Typography variant="body2">{item.title}</Typography>
                    {submenuKeyExistInItem && (
                      <i className={`${getIconClassName('ChevronLeft')}`}></i>
                    )}
                    {submenuKeyExistInItem ? (
                      <div className={`submenu ${classes.submenu}`}>
                        {item.submenu}
                      </div>
                    ) : null}
                  </MenuItem>
                );
              })}
          </Paper>
        )}
      </Popper>
    </Fragment>
  );
};

ContextMenu.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  items: PropTypes.array,
  fieldName: PropTypes.string,
  isOpen: PropTypes.bool,
  value: PropTypes.string,
};

ContextMenu.defaultProps = {
  isOpen: false,
};

export default compose(withStyles(styles, { withTheme: true }))(ContextMenu);
