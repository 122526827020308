import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth/withWidth';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import compose from 'recompose/compose';
import { useTranslate } from 'react-admin';
import LoadingBox from '../component/LoadingBox';
import { Map } from '../component/map/';
import NoteStreamSidebar from '../component/NoteStreamSidebar';
import { PivotTable } from '../component/pivot-table';
import { ReportCalendar } from '../component/report-calendar';
import CheckResourceReady from '../container/CheckResourceReady';
import DynamicList from '../container/DynamicList';
import { NewMetaContext } from '../container/NewMetaContext';
import { getNoteInfo, hasNote, hasReportEditable } from '../helper/MetaHelper';
import { closeNoteStreamAction } from '../redux/listPage/action';
import { updateReportWithCallbackAction } from '../redux/report/action';
import { PrintReport } from '../component/print-report';
import { isEmpty, isNetworkError } from '../helper/data-helper';
import NotFound from '../component/NotFound';

const ListRecordPage = props => {
  const {
    showNoteStream,
    closeNoteStream,
    showDevExtremeTopFilter,
    showDevExtremeGrouping,
    updateReport,
    theme,
    width,
    resource,
    ...rest
  } = props;

  const [metaDataError, setMetaDataError] = useState(null);

  const { getMeta } = useContext(NewMetaContext);
  const history = useHistory();
  const translate = useTranslate();

  useEffect(() => {
    setMetaDataError(null);
  }, [resource]);

  const failureGetMeta = message => {
    setMetaDataError(
      isNetworkError(message)
        ? translate('ra.notification.http_error')
        : message?.split?.('^')[0],
    );
  };

  if (!isEmpty(metaDataError)) {
    return <NotFound title={metaDataError} hideBackButton />;
  }

  const metaData = getMeta(resource, null, failureGetMeta);

  if (!metaData && isEmpty(metaDataError)) {
    return <LoadingBox />;
  }

  const defaultView = metaData?.defaultView;

  const isCalendarView = defaultView === 'Calendar';
  const isPivotView = defaultView === 'Pivot';
  const isMapView = defaultView === 'Map';
  const isPrintView = defaultView === 'Print';

  const isReport = resource.indexOf('report') === 0;
  const isReportEditable = hasReportEditable(metaData);

  const resourceHasNoteStream = hasNote(metaData);
  if (resourceHasNoteStream) {
    // trigger to get meta
    const noteRelation = getNoteInfo(metaData);
    getMeta(`${noteRelation.moduleName}/${noteRelation.moduleTableName}`);
  }

  const isSlideOpen = () => {
    return resourceHasNoteStream && showNoteStream;
  };

  const isOnMobile = theme.breakpoints.width(width) < theme.breakpoints.width('md');
  const closeSlideIfOpen = () => {
    if (isSlideOpen()) {
      closeNoteStream();
    }
  };

  const hasCreate = lodashGet(metaData, ['config', 'allowAdd'], true);
  const hasEdit = lodashGet(metaData, ['config', 'allowEdit'], true);
  const hasDelete = lodashGet(metaData, ['config', 'allowDelete'], true);
  const onRowClick = row => {
    history.push(`/${resource}/${row.id}/show`);
  };

  return (
    <CheckResourceReady resourceName={resource}>
      {isCalendarView ? (
        <ReportCalendar metaData={metaData} />
      ) : isPivotView || isMapView ? (
        <DynamicList
          {...rest}
          resource={resource}
          metaData={metaData}
          viewComponent={isPivotView ? <PivotTable /> : <Map />}
          hasList={true}
          hasCreate={false}
          hasEdit={false}
          hasShow={false}
          hasDelete={false}
          enableSelection={false}
          basePath={`/${resource}`}
          slideComponent={null}
          isSlideOpen={false}
          onRootClick={undefined}
          isTopFilterOpen={false}
          isGroupingOpen={false}
          quickEditRowCallback={null}
          data-test-is-slide-open={false}
          onRowClick={undefined}
          isSelectedItemsCount={false}
          perPage={1000000}
          pagination={null}
          isGroupingEnable={false}
          isColumnChoiceEnable={false}
          fields={false}
          settingToolbarComponent={null}
        />
      ) : isPrintView ? (
        <DynamicList
          {...rest}
          resource={resource}
          metaData={metaData}
          viewComponent={<PrintReport />}
          hasList={true}
          hasCreate={false}
          hasEdit={false}
          hasShow={false}
          hasDelete={false}
          enableSelection={false}
          basePath={`/${resource}`}
          slideComponent={null}
          isSlideOpen={false}
          onRootClick={undefined}
          isTopFilterOpen={false}
          isGroupingOpen={false}
          quickEditRowCallback={null}
          data-test-is-slide-open={false}
          onRowClick={undefined}
          isSelectedItemsCount={false}
          perPage={1000000}
          pagination={null}
          isGroupingEnable={false}
          isColumnChoiceEnable={false}
          fields={false}
          settingToolbarComponent={null}
        />
      ) : (
        <DynamicList
          {...rest}
          resource={resource}
          metaData={metaData}
          hasList={true}
          hasCreate={!isReport && hasCreate}
          hasEdit={!isReport && hasEdit}
          hasShow={!isReport}
          hasDelete={!isReport && hasDelete}
          enableSelection={true}
          basePath={`/${resource}`}
          slideComponent={resourceHasNoteStream ? <NoteStreamSidebar /> : null}
          isSlideOpen={isSlideOpen()}
          onRootClick={isOnMobile ? closeSlideIfOpen : undefined}
          isTopFilterOpen={showDevExtremeTopFilter}
          isGroupingOpen={showDevExtremeGrouping}
          quickEditRowCallback={isReportEditable ? updateReport : null}
          data-test-is-slide-open={isSlideOpen()}
          onRowClick={!isReport && onRowClick}
          isSelectedItemsCount={false}
          isAccessPath
        />
      )}
    </CheckResourceReady>
  );
};

ListRecordPage.propTypes = {
  resource: PropTypes.string.isRequired,
  updateReport: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { match }) => {
  const { moduleName, moduleTableName } = match.params;
  const resource = `${moduleName}/${moduleTableName}`;

  return {
    resource: resource.toLowerCase(),
    showNoteStream: state.listPage.showNoteStream,
    showDevExtremeTopFilter: state.listPage.showDevExtremeTopFilter,
    showDevExtremeGrouping: state.listPage.showDevExtremeGrouping,
  };
};

const mapDispatchToProps = {
  closeNoteStream: closeNoteStreamAction,
  updateReport: updateReportWithCallbackAction,
};

export default compose(
  withWidth(),
  withStyles({}, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(ListRecordPage);
