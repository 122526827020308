import { put, takeLatest } from 'redux-saga/effects';
import { FIND_ALL } from './constant';
import { findAllSuccessAction, findAllFailedAction } from './action';

import dataProvider, { GET_MENU } from '../../core/dataProvider';
import {
  CONFIG_FIXED_MENU,
  API_NAME,
  getValue,
  USER_TOKEN,
} from '../../core/configProvider';

function* findOne() {
  if (!getValue(USER_TOKEN)) {
    yield put(findAllFailedAction('error'));
    return;
  }

  const fixedMenu = getValue(CONFIG_FIXED_MENU);
  if (fixedMenu && fixedMenu.length > 0) {
    yield put(findAllSuccessAction(fixedMenu));
    return;
  }

  const apiName = getValue(API_NAME);
  try {
    const data = yield dataProvider(GET_MENU, `account/${apiName}/menu`, {
      pagination: {
        page: 1,
        perPage: 1000,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
    });

    if (!data || data.length === 0) return;
    yield put(findAllSuccessAction(data));
  } catch (error) {
    yield put(findAllFailedAction(error));
  }
}

export default function* menuSaga() {
  yield takeLatest(FIND_ALL, findOne);
}
