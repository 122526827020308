import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({});

function WeeklyIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M19 6c0-.553-.448-1-1-1s-1 .447-1 1c0 .553.448 1 1 1s1-.447 1-1zm-2 6c0 .553.448 1 1 1s1-.447 1-1c0-.553-.448-1-1-1s-1 .447-1 1zM5 12c0 .553.448 1 1 1s1-.447 1-1c0-.553-.448-1-1-1s-1 .447-1 1zm0 6c0 .553.448 1 1 1s1-.447 1-1c0-.553-.448-1-1-1s-1 .447-1 1zm6-8h3v4h-4v-4h1zm0 3h2v-2h-2v2zm0 3h3v4h-4v-4h1zm0 3h2v-2h-2v2zm0-15h3v4h-4V4h1zm0 3h2V5h-2v2z"
      ></path>
    </SvgIcon>
  );
}

export default withStyles(styles)(WeeklyIcon);
