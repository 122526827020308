import { GET_ONE } from 'react-admin';
import { useLocale } from 'react-admin';
import lodashDebounce from 'lodash/debounce';

import { prepareOverrideParams } from '../component/form/form.helper';
import { getMetaFromApi } from '../container/NewMetaContext';
import { WMS } from '../core/configRouteConstant';
import dataProvider from '../core/dataProvider';
import {
  isEmpty,
  isEmptyObject,
  isNetworkError,
  separateRecordAndRelationRecord,
} from '../helper/data-helper';
import { showNotification } from '../helper/general-function-helper';
import { MetaData } from '../helper/Types';
import { getParamFromUrl } from '../helper/UrlHelper';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
  FormKeyMode,
  RecordKeyMode,
  ResourcesInterface,
  actorDispatch,
} from '../type/actor-setup';
import lodashGet from 'lodash/get';

const changeResourcesHandler = async (
  resources: ResourcesInterface,
): Promise<void> => {
  if (
    resources?.current == null ||
    resources.current.value === '/' ||
    resources.current.value.includes(WMS)
  ) {
    return;
  }

  actorDispatch('showLoading', true);

  // FIXME: We have to use this `setTimeout` because of `PrivateRoute` behavior
  const currentResource = resources.current;

  const urlInfo = actorGetActionValue('urlInfo')!;

  if (currentResource.type === FormKeyMode.DROPDOWN) {
    //TODO: Complete this section;
    actorDispatch('loading', false, {
      path: currentResource.value,
    });
    return;
  }

  let metaData = actorGetActionValue(
    'metaData',
    currentResource.value,
  ) as MetaData | null;

  let hasNetworkError = false;

  if (metaData == null) {
    try {
      const allMetaData = await getMetaFromApi(currentResource.value);
      metaData = allMetaData.find(
        item => item.name === currentResource.value.toLowerCase(),
      )?.meta;

      if (hasNetworkError) hasNetworkError = false;
    } catch (error) {
      console.log('getMetaFromApi Error: %o', error);
      actorDispatch('showLoading', false);
      showNotification(error, 'error');

      if (isNetworkError(error) && !hasNetworkError) hasNetworkError = true;
    }
  }

  actorDispatch('metaData', hasNetworkError ? { error: 'networkError' } : metaData, {
    path: currentResource.value,
  });

  // record should handle in its controller component in show mode. so ignore get resource in this situation
  if (!urlInfo.location.hash.includes(`${urlInfo?.params?.id}/show`)) {
    const entityId = urlInfo?.params?.id;
    const computedOverrideParams = prepareOverrideParams();
    if (!isEmpty(entityId)) {
      const { data: responseData, additionalData } =
        (await dataProvider(GET_ONE, currentResource.value, {
          id: entityId,
        }).catch(error => {
          console.log('form-api.ts:69 GET_ONE request error: ', error);
        })) ?? {};

      const { recordWithoutRelationData = {}, relationRecord = {} } =
        separateRecordAndRelationRecord(
          responseData,
          metaData,
          computedOverrideParams,
        );

      actorSetActionValue('recordAdditionalData', additionalData, {
        path: currentResource.value,
      });

      actorDispatch('record', {
        [currentResource.value]: {
          [FormKeyMode.ROOT]: {
            [RecordKeyMode.FULL]: { ...responseData, ...computedOverrideParams },
            [RecordKeyMode.FORM]: recordWithoutRelationData,
            [RecordKeyMode.PARENT_RECORD]: relationRecord,
          },
        },
      });
    } else {
      actorDispatch('record', {
        [currentResource.value]: {
          [FormKeyMode.ROOT]: {
            [RecordKeyMode.FULL]: computedOverrideParams,
          },
        },
      });
    }
  }
  actorDispatch('showLoading', false);
};

actorOnDispatch('resources', lodashDebounce(changeResourcesHandler, 1500));

actorOnDispatch('urlInfo', async urlInfo => {
  if (isEmptyObject(urlInfo)) return;

  let prevParentResource = '';
  let prevParentId = '';

  const { location } = urlInfo;
  const splitLocation = location?.hash.split('?');
  const search = splitLocation[1] ?? '';
  const parentResource = getParamFromUrl(search, 'parentResource');
  const parentId = getParamFromUrl(search, 'parentId');

  if (
    !isEmpty(parentResource) &&
    !isEmpty(parentId) &&
    parentResource !== prevParentResource &&
    parentId !== prevParentId
  ) {
    const { data: responseData } = await dataProvider(GET_ONE, parentResource, {
      id: parentId,
    });

    prevParentResource = parentResource!;
    prevParentId = parentId!;

    actorSetActionValue('record', responseData, {
      path: `${parentResource}.${FormKeyMode.ROOT}.${RecordKeyMode.FORM}`,
    });
  }
});

// trigger when edit button in relation panel clicked

actorOnDispatch('quickDialog', async details => {
  if (isEmptyObject(details)) return;
  if (details.relationEditIsOpen) {
    const { resource, parentId, id } = details?.data?.['data'];

    actorDispatch('loading', true, {
      path: `${resource}-edit`,
    });
    if (!isEmpty(resource) && !isEmpty(id)) {
      const { data: responseData, additionalData } = await dataProvider(
        GET_ONE,
        resource,
        {
          id: id,
        },
      );

      actorSetActionValue('recordAdditionalData', additionalData, {
        path: resource,
      });

      actorDispatch('record', {
        [resource]: {
          [FormKeyMode.RELATION]: {
            [RecordKeyMode.FULL]: responseData,
            [RecordKeyMode.FORM]: responseData,
            [RecordKeyMode.PARENT_RECORD]: { parentRecord: parentId },
          },
        },
      });
      responseData &&
        actorDispatch('loading', false, {
          path: `${resource}-edit`,
        });
    }
  }
});

/**
 * @function setDocumentTitle
 * @param {MetaData} metaData
 * @param {string} prepareRecordName
 */
actorOnDispatch('setDocumentTitle', ({ metaData, recordTitle, locale }) => {
  if (isEmpty(metaData) && isEmpty(recordTitle)) return;
  let title = '';

  if (!isEmptyObject(metaData?.translatedTitle)) {
    title = metaData?.translatedTitle?.[locale];
  } else if (!isEmptyObject(metaData?.config?.translatedCaption)) {
    title = metaData.config?.translatedCaption?.[locale];
  }

  if (!isEmpty(recordTitle)) {
    title += ' ' + recordTitle;
  }

  if (!isEmpty(title)) {
    document.title = title;
  }
});
