import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ListContainerApiController from '../container/ListContainerApiController';
import SellinHistoryOrderView from '../component/shop/SellinHistoryOrderView';
import { getSellinHistoryOrderPatternInfo } from '../helper/PatternMetaHelper';
import CheckResourceReady from '../container/CheckResourceReady';

const SellinPreviousOrderPage = props => {
  const { resource, ...rest } = props;

  const [myResource, setMyResource] = useState('');

  useEffect(() => {
    if (myResource !== resource) {
      setMyResource(resource);
    }
  }, [resource]);

  const patternData = getSellinHistoryOrderPatternInfo(resource);

  return (
    <CheckResourceReady resourceName={resource}>
      <ListContainerApiController {...rest} resource={myResource} perPage={1000000}>
        {controllerProps => (
          <SellinHistoryOrderView
            {...rest}
            {...controllerProps}
            patternData={patternData}
          />
        )}
      </ListContainerApiController>
    </CheckResourceReady>
  );
};

SellinPreviousOrderPage.propTypes = {
  resource: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { match }) => {
  const { moduleName, moduleTableName } = match.params;
  const resource = `${moduleName}/${moduleTableName}`;
  const basePath = `/${resource}`;

  return {
    resource,
    basePath,
  };
};

export default connect(mapStateToProps)(SellinPreviousOrderPage);
