import {
  RelationPanelControllerInterface,
  RelationInMetaData,
} from '../new-relation-panel';
import { GET_ONE } from 'react-admin';

import { getAppSettings } from '../../helper/settings-helper';
import {
  actorDispatch,
  actorGetActionValue,
  ApiRequestResultInterface,
} from '../../type/actor-setup';
import { isEmptyObject } from '../../helper/data-helper';
import { GeneralMetaData, MetaData } from '../../helper/Types';
import {
  getColumnCount,
  getFileInfo,
  getNoteInfo,
  getRelationList,
  mergeTabDataWithSetting,
  getSingleRecordReportRelationList,
  getProcessList,
} from '../../helper/MetaHelper';

import type {
  CustomRefreshParamsInterface,
  TabInterFace,
} from './show-record-with-relation.type';
import { CONFIG_FORM_LAYOUT } from '../../core/configProvider';
import { getShowTabList } from '../../helper/meta-helper';
import { ProcessInformation } from '../form';
import {
  apiRequestFailureResultHandler,
  apiRequestResultGeneralHandler,
} from '../../helper/crud-api.helper';

/**
 * it change som properties of one to one tab by reference
 * @param tab
 * @returns
 */
const prepareOneToOneTabData = (
  tab: TabInterFace,
  record: Record<string, unknown>,
): void => {
  const oneToOneRelationResource = `${tab.moduleName}/${tab.tableName}`;

  //TODO: check oneToOneRelationMeta values
  const oneToOneRelationMeta = actorGetActionValue(
    'metaData',
    oneToOneRelationResource,
  )! as unknown as MetaData;

  if (!oneToOneRelationMeta) {
    tab['tableRelationList'] = [];
    tab['reportRelationList'] = [];
    tab['fileRelation'] = [];
    tab['noteRelation'] = null;
    return;
  }

  tab['tableRelationList'] = getRelationList(oneToOneRelationMeta, {
    processuniqueid: record.__processuniqueid,
    positionid: record.positionid,
    stateid: record.stateid,
  });

  tab['reportRelationList'] = getSingleRecordReportRelationList(
    oneToOneRelationMeta,
    {
      processuniqueid: record.__processuniqueid,
      positionid: record.positionid,
      stateid: record.stateid,
    },
  );
  tab['fileRelation'] = getFileInfo(oneToOneRelationMeta);
  tab['noteRelation'] = getNoteInfo(oneToOneRelationMeta);
};

/**
 * prepare fields list based on metadata, setting, current state
 * @function prepareViewFields
 * @returns {void}
 */
export const prepareViewFields = (
  record: Record<string, unknown>,
  metaData: MetaData,
  resource: string,
  isShowMode = true,
): Array<TabInterFace> => {
  // TODO : this section should change
  const { __processuniqueid: processuniqueid, positionid, stateid } = record;

  const storedValue = getAppSettings(`${CONFIG_FORM_LAYOUT}'_'${resource}`).value;
  const columnCount = getColumnCount(metaData);
  const relationList = getRelationList(metaData, {
    processuniqueid,
    positionid,
    stateid,
  });

  const reportRelationList: Array<Record<string, unknown>> | null =
    getSingleRecordReportRelationList(metaData, {
      processuniqueid: record.__processuniqueid,
      positionid: record.positionid,
      stateid: record.stateid,
    });

  const noteRelation = getNoteInfo(metaData);
  const processList = getProcessList(metaData);

  const processInfo = {
    processuniqueid: record.__processuniqueid as string | null,
    positionid: record.positionid as string | null,
    stateid: record.stateid as string | null,
  };

  const tempPreparedGroupList: Array<TabInterFace> = mergeTabDataWithSetting(
    metaData,
    processList,
    getShowTabList({
      metaData: metaData as GeneralMetaData,
      defaultColumnCount: columnCount,
      processInfo,
      isShowMode,
      resource,
    }),
    storedValue,
    relationList,
    reportRelationList as Array<Record<string, unknown>> | undefined, //todo : it should accept null
    noteRelation,
  );

  tempPreparedGroupList?.forEach(tab => {
    if (tab.isOneToOne) {
      prepareOneToOneTabData(tab, record);
    }
  });

  return tempPreparedGroupList;
};

const extractRelationsFromTabList = (tabList: Array<TabInterFace>) => {
  const tableRelations: Array<RelationInMetaData> = [];
  const reportRelations: Array<RelationInMetaData> = [];
  const noteRelations: Array<RelationInMetaData> = [];

  tabList.forEach((tab: TabInterFace) => {
    const { tableRelationList, reportRelationList, noteRelation } = tab;
    if (Array.isArray(tableRelationList)) {
      tableRelationList.forEach(tableRelation => {
        tableRelations.push({
          ...tableRelation,
          isOneToOneRelation: tab.isOneToOne,
        });
      });
    }

    if (Array.isArray(reportRelationList)) {
      reportRelationList.forEach(reportRelation => {
        reportRelations.push({
          ...reportRelation,
          isOneToOneRelation: tab.isOneToOne,
        });
      });
    }

    if (!isEmptyObject(noteRelation)) {
      noteRelations.push({
        ...noteRelation!,
        isOneToOneRelation: tab.isOneToOne,
      });
    }
  });

  return [tableRelations, reportRelations, noteRelations];
};

export const prepareRelationList = (
  tabList: Array<TabInterFace>,
  parentResource: string,
  parentRecordIsEditable: boolean,
  parentRecordId: string | undefined,
  processInfo: ProcessInformation,
): Array<RelationPanelControllerInterface> => {
  const preparedRelations: Array<RelationPanelControllerInterface> = [];

  const [tableRelations, reportRelations, noteRelations] =
    extractRelationsFromTabList(tabList);

  tableRelations.forEach(tableRelation => {
    const relationProps: RelationPanelControllerInterface = {
      relationItemInMetaData: tableRelation,
      relationType: tableRelation.isOneToOneRelation ? 'oneToOneTable' : 'table',
      parentResource,
      parentRecordIsEditable,
      parentRecordId,
      processInfo,
    };

    preparedRelations.push(relationProps);
  });

  reportRelations.forEach(reportRelation => {
    const isMultiReport =
      reportRelation?.['reportType'] === 'MultiResult' ||
      reportRelation?.['reportType'] === 'ParentChild';

    const relationProps: RelationPanelControllerInterface = {
      relationItemInMetaData: reportRelation,
      relationType: isMultiReport ? 'multiReport' : 'report',
      parentResource,
      parentRecordIsEditable,
      parentRecordId,
      processInfo,
    };

    preparedRelations.push(relationProps);
  });

  noteRelations.forEach(noteRelation => {
    const relationProps: RelationPanelControllerInterface = {
      relationItemInMetaData: noteRelation,
      relationType: 'note',
      parentResource,
      parentRecordIsEditable,
      parentRecordId,
      processInfo,
    };

    preparedRelations.push(relationProps);
  });

  return preparedRelations;
};

/**
 * @function customRefresh
 * @param {CustomRefreshParamsInterface} params
 * @returns {void} void
 */
export const customRefresh = (params: CustomRefreshParamsInterface): void => {
  const { resource, recordId, disableNotification, setLoading } = params;
  actorDispatch(
    'crudAction',
    {
      type: GET_ONE,
      entity: 'showRecordPage',
      disableNotification,
      resource,
      recordId,
      onSuccess: apiRequestResultGeneralHandler,
      onFailure: (error: ApiRequestResultInterface): void => {
        apiRequestFailureResultHandler(error, GET_ONE, 'showRecordPage');
        if (typeof setLoading === 'function') {
          setLoading(false);
        }
      },
    },
    { disableDebounce: true },
  );
};
