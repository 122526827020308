import {
  actorDispatch,
  actorSetActionValue,
  ApiRequestResultInterface,
  FormKeyMode,
  RecordKeyMode,
} from '../type/actor-setup';
import { GET_ONE, GET_MANY_REFERENCE, GET_LIST } from 'react-admin';
import { CRUDActionsType, CrudResultInterface } from '../type/data-provider';
import { addFakeIdsToEachRecordOfDataArrayByReference } from '../component/new-relation-panel/relation-panel.helper';
import { showNotification } from '../helper/general-function-helper';

export const apiRequestResultGeneralHandler = (
  apiSignal: ApiRequestResultInterface,
): void => {
  const relatedSignal: CrudResultInterface | undefined =
    apiSignal[GET_ONE]?.showRecordPage;

  if (relatedSignal) {
    const { status, data, payload, resource } = relatedSignal;
    const { disableNotification, recordId } = payload as {
      disableNotification: boolean;
      recordId: string;
    };

    if (status === 'success') {
      actorSetActionValue(
        'recordAdditionalData',
        (data as { additionalData: Record<string, unknown> })?.additionalData,
        {
          path: resource,
        },
      );

      actorSetActionValue(
        'formData',
        (data as { data: Record<string, unknown> })?.data,
        {
          path: `${resource}.${FormKeyMode.ROOT}`,
        },
      );

      actorDispatch('record', (data as { data: Record<string, unknown> })?.data, {
        path: `${resource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
        disableDebounce: true,
      });
    } else {
      if (!disableNotification) {
        showNotification('ra.notification.http_error', 'error'); // fixme: add translate
      }
    }

    // remove result from actor state
    actorSetActionValue('apiRequestResult', null, {
      path: `${GET_ONE}.showRecordPage`,
    });
  }
};

export const apiRequestResultRelationHandler = (
  apiSignal: ApiRequestResultInterface,
): void => {
  let relatedSignal: CrudResultInterface | undefined;
  let requestType: string | undefined;

  const getManyReferenceSignal: CrudResultInterface | undefined =
    apiSignal[GET_MANY_REFERENCE]?.relation;

  const getListSignal: CrudResultInterface | undefined =
    apiSignal[GET_LIST]?.relation;

  if (getManyReferenceSignal) {
    requestType = GET_MANY_REFERENCE;
    relatedSignal = getManyReferenceSignal;
  } else if (apiSignal[GET_LIST]?.relation) {
    relatedSignal = getListSignal;
    requestType = GET_LIST;
  }

  if (relatedSignal) {
    const { status } = relatedSignal;

    if (status === 'success') {
      const { data, resource } = relatedSignal;
      const {
        data: serverData,
        total,
        requestId,
      } = data as {
        data: Array<Record<string, unknown>>;
        total: number;
        requestId: string;
      };

      actorSetActionValue('gridData', total ?? 0, {
        path: `${resource}.totalCount`,
      });

      actorSetActionValue('gridData', requestId, {
        path: `${resource}.lastRequestId`,
      });

      addFakeIdsToEachRecordOfDataArrayByReference(serverData);

      actorDispatch('gridData', serverData, {
        path: `${resource}.data`,
        disableDebounce: true,
      });
    } else {
      // if (!disableNotification) {
      //   showNotification('ra.notification.http_error', 'error'); // fixme: add translate
      // }
    }

    // remove result from actor state
    actorSetActionValue('apiRequestResult', null, {
      path: `${requestType}.relation`,
    });
  }
};

//TODO: complete this function to handle all api request error results if needed
/**
 * @function apiRequestFailureResultHandler
 * @param { ApiRequestResultInterface } error
 * @param { CRUDActionsType } type
 * @param { string } entity
 * @returns { void }
 */
export const apiRequestFailureResultHandler = (
  apiErrorResult: ApiRequestResultInterface,
  type: CRUDActionsType,
  entity: string,
): void => {
  if (!apiErrorResult) {
    return;
  }

  const relatedApiErrorResult = apiErrorResult?.[type]?.[entity];

  showNotification(
    (relatedApiErrorResult as Record<string, any>)?.['data']?.['userMessage'],
    'error',
  );
};
