import { CREATE, useTranslate } from 'react-admin';
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';
import FileSystemItem from 'devextreme/file_management/file_system_item';
import { FC, memo, useState } from 'react';
import dataProvider, {
  GET_REPORT,
  POST_STREAM_FILE,
  RUN_SERVICE,
} from '../../core/dataProvider';
import {
  copyDirectoryId,
  copyFileId,
  createDirectoryId,
  createFileLink,
  deleteDirectoryId,
  findType,
  moveItemId,
  renameDirectoryId,
  reportId,
  uploadFileChunkResource,
  uploadStreamResource,
} from './file-manager.helper';
import FileManagerView from './file-manager.view';
import { callQuickAccess, openNewTab } from '../../helper/QuickAccessHelper';
import { FileManagerControllerInterface, FileManagerData } from '.';
import { isEmpty } from '../../helper/data-helper';
import { showNotification } from '../../helper/general-function-helper';

const FileManagerController: FC<FileManagerControllerInterface> = memo(props => {
  const { fileUploadLimitMB } = props;
  const translate = useTranslate();

  const [itemViewMode, setItemViewMode] = useState<string>('thumbnails');

  /**
   * to get file manager data
   * @function getItems
   * @param { FileSystemItem } pathInfo
   * @returns { Promise<void> }
   */
  const getItems = async (pathInfo: FileSystemItem): Promise<void> => {
    try {
      const data = await dataProvider(GET_REPORT, '', {
        reportId,
        pagination: { perPage: 9999999 },
        filters: pathInfo?.dataItem?.['doctype_id'] && [
          [
            findType(pathInfo) === 'archive'
              ? 'mainarchive_id'
              : findType(pathInfo) === 'folder'
              ? 'folder_id'
              : '',
            'equal',
            pathInfo?.dataItem?.['doc_id'],
          ],
        ],
      });

      return data?.data?.map((item: FileManagerData) => ({
        ...item,
        isfolder: item?.isfolder === 1 || item?.isfolder === 2,
      }));
    } catch (error) {
      showNotification(error, 'error');
    }
  };

  /**
   * to create a new directory
   * @function createDirectory
   * @param { FileSystemItem } parentDirectory
   * @param { string } name
   * @returns { Promise<void> }
   */
  const createDirectory = async (
    parentDirectory: FileSystemItem,
    name: string,
  ): Promise<void> => {
    try {
      await dataProvider(RUN_SERVICE, '', {
        actionUniqueId: createDirectoryId,
        data: {
          params: {
            doc_id: parentDirectory?.dataItem?.['doc_id'],
            doctype: 'فولدر',
            ftitle: name,
          },
        },
      });
    } catch (error) {
      showNotification(error, 'error');
    }
  };

  /**
   * to upload file stream
   * @function uploadFileStream
   * @param { File } file
   * @param { string } resource
   */
  const uploadFileStream = async (file: File, resource: string) => {
    try {
      const response = await dataProvider(POST_STREAM_FILE, resource, {
        data: { file },
      });
      return response?.data ?? {};
    } catch (error) {
      showNotification(error, 'error');
    }
  };

  /**
   * to upload file chunk
   * @function uploadFileChunk
   * @param { File } fileData
   * @param { unknown } uploadInfo
   * @param { FileSystemItem } destinationDirectory
   * @returns { Promise<void> }
   */
  const uploadFileChunk = async (
    fileData: File,
    uploadInfo: unknown,
    destinationDirectory: FileSystemItem,
  ): Promise<void> => {
    const fileAddress = await uploadFileStream(fileData, uploadStreamResource);
    try {
      await dataProvider(CREATE, uploadFileChunkResource, {
        data: {
          fileaddress: fileAddress,
          filetitle: fileData.name,
          folder_id: destinationDirectory?.dataItem?.['doc_id'],
          filesize: fileData?.size,
        },
      });
    } catch (error) {
      showNotification(error, 'error');
    }
  };

  /**
   * to download item
   * @function downloadItems
   * @param { FileSystemItem[] } items
   * @returns { void }
   */
  const downloadItems = (items: FileSystemItem[]): void => {
    const fileAddress = items[0]?.dataItem?.['fileaddress'];
    const fileLink = createFileLink(fileAddress);
    window.open(fileLink, '_blank');
  };

  /**
   * to rename item
   * @function renameItem
   * @param { FileSystemItem } item
   * @param { string } name
   * @returns { Promise<void> }
   */
  const renameItem = async (item: FileSystemItem, name: string): Promise<void> => {
    try {
      await dataProvider(RUN_SERVICE, '', {
        actionUniqueId: renameDirectoryId,
        data: {
          params: {
            doc_id: item?.dataItem?.['doc_id'],
            doctype_id: item?.dataItem?.['doctype_id'],
            newname: name,
          },
        },
      });
    } catch (error) {
      showNotification(error, 'error');
    }
  };

  /**
   * to delete item
   * @function deleteItem
   * @param { FileSystemItem } item
   * @returns { Promise<void> }
   */
  const deleteItem = async (item: FileSystemItem): Promise<void> => {
    try {
      await dataProvider(RUN_SERVICE, '', {
        actionUniqueId: deleteDirectoryId,
        data: {
          params: {
            doc_id: item?.dataItem?.['doc_id'],
            doctype_id: item?.dataItem?.['doctype_id'],
          },
        },
      });
    } catch (error) {
      showNotification(error, 'error');
    }
  };

  /**
   * to copy files
   * @function copyFile
   * @param { number } currentItemId
   * @param { number } destFolderId
   * @returns { Promise<void> }
   */
  const copyFile = async (
    currentItemId: number,
    destFolderId: number,
  ): Promise<void> => {
    try {
      await dataProvider(RUN_SERVICE, '', {
        actionUniqueId: copyFileId,
        data: {
          params: {
            file_id: currentItemId,
            destfolder_id: destFolderId,
          },
        },
      });
    } catch (error) {
      showNotification(error, 'error');
    }
  };

  /**
   * to copy directories
   * @function copyDirectory
   * @param { number } currentItemId
   * @param { number } destFolderId
   * @returns { Promise<void> }
   */
  const copyDirectory = async (
    currentItemId: number,
    destFolderId: number,
  ): Promise<void> => {
    try {
      await dataProvider(RUN_SERVICE, '', {
        actionUniqueId: copyDirectoryId,
        data: {
          params: {
            sourcefolderid: currentItemId,
            destfolderid: destFolderId,
          },
        },
      });
    } catch (error) {
      showNotification(error, 'error');
    }
  };

  /**
   * to copy item
   * @function copyItem
   * @param { FileSystemItem } item
   * @param { FileSystemItem } destinationDirectory
   * @returns { Promise<void> }
   */
  const copyItem = async (
    item: FileSystemItem,
    destinationDirectory: FileSystemItem,
  ): Promise<void> => {
    const currentItemId = item?.dataItem?.['doc_id'];
    const destFolderId = destinationDirectory?.dataItem?.['doc_id'];
    if (findType(item) === 'file') {
      await copyFile(currentItemId, destFolderId);
    } else {
      await copyDirectory(currentItemId, destFolderId);
    }
  };

  /**
   * to move item
   * @function moveItem
   * @param { FileSystemItem } item
   * @param { FileSystemItem } destinationDirectory
   * @returns { Promise<void> }
   */
  const moveItem = async (
    item: FileSystemItem,
    destinationDirectory: FileSystemItem,
  ): Promise<void> => {
    const currentItemId = item?.dataItem?.['doc_id'];
    const docTypeId = item?.dataItem?.['doctype_id'];
    const destFolderId = destinationDirectory?.dataItem?.['doc_id'];

    try {
      await dataProvider(RUN_SERVICE, '', {
        actionUniqueId: moveItemId,
        data: {
          params: {
            doctype_id: docTypeId,
            doc_id: currentItemId,
            newfolder_id: destFolderId,
          },
        },
      });
    } catch (error) {
      showNotification(error, 'error');
    }
  };

  /**
   * Creates a custom file system provider
   * @function customFileProvider
   * @returns { CustomFileSystemProvider }
   */
  const customFileProvider = new CustomFileSystemProvider({
    isDirectoryExpr: 'isfolder',
    sizeExpr: 'filesize',
    nameExpr: 'title',
    dateModifiedExpr: 'createdate',
    // Function to get file system items
    getItems,
    // Functions to handle file operations
    createDirectory,
    uploadFileChunk,
    renameItem,
    downloadItems,
    deleteItem,
    copyItem,
    moveItem,
  });

  /**
   * to change view mode of file manager content section
   * @function onOptionChanged
   * @param { Record<string, any> } e
   * @returns { void }
   */
  const onOptionChanged = (e: Record<string, any>): void => {
    if (e.fullName === 'itemView.mode') {
      setItemViewMode(e.value);
    }
  };

  /**
   * @function onSelectedFileOpened
   * @param {  Record<string, any> } e
   * @returns { void }
   */
  const onSelectedFileOpened = (e: Record<string, any>): void => {
    const fileAddress = e?.file?.dataItem?.['fileaddress'];
    const fileLink = createFileLink(fileAddress);
    window.open(fileLink, '_blank');
  };

  /**
   * this function trigger callQuickAccess to open quickAccessUrl in new tab
   * @function handleQuickAccessFile
   * @param { string } linkNameValueUrl
   * @returns {Promise<void>} Promise<void>
   */
  const handleQuickAccessFile = async (linkNameValueUrl: string): Promise<void> => {
    try {
      const linkNameValue = linkNameValueUrl.substring(
        linkNameValueUrl.lastIndexOf('/') + 1,
      );
      const url = await callQuickAccess(linkNameValue);
      openNewTab(url);
    } catch (error) {
      showNotification(error, 'warning');
    }
  };

  /**
   * when click on context-menu items
   * @function onContextMenuItemClick
   * @param { Record<string, any> } param
   */
  const onContextMenuItemClick = ({
    itemData,
    fileSystemItem,
  }: Record<string, any>) => {
    if (itemData?.name === 'properties') {
      const docIdLink = fileSystemItem?.dataItem?.['__doc_id_link'];
      if (!isEmpty(docIdLink)) {
        handleQuickAccessFile(docIdLink);
      } else {
        showNotification(translate('fileManager.propertiesError'), 'warning');
      }
    }
  };

  return (
    <FileManagerView
      customFileProvider={customFileProvider}
      onOptionChanged={onOptionChanged}
      onSelectedFileOpened={onSelectedFileOpened}
      itemViewMode={itemViewMode}
      onContextMenuItemClick={onContextMenuItemClick}
      maxFileSize={fileUploadLimitMB}
    />
  );
});

export default FileManagerController;
