import { ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'classnames';
import { Tooltip } from '@material-ui/core';

import { UserInfoViewInterface } from './user-info.type';
import { useStyles } from './user-info.style';
import { getUserAvatar } from './user-info.helper';
import {
  API_URL,
  API_VERSION,
  getValue,
  SESSION_ID,
} from '../../../../core/configProvider';
import { isEmptyObject } from '../../../../helper/data-helper';

const UserInfoView = (props: UserInfoViewInterface): ReactElement => {
  const { user, showStatus = false, isOnline = false } = props;
  const classes = useStyles();
  const sessionId = getValue(SESSION_ID);
  const apiUrl = getValue(API_URL);
  const apiVersion = getValue(API_VERSION);

  let userIsEmpty = false;
  if (isEmptyObject(user)) {
    userIsEmpty = true;
  }

  return (
    <Box display="flex" alignItems="center">
      <Box className={classes.avatarRoot}>
        {!userIsEmpty && user.photoURL ? (
          <Tooltip title={user.displayName ?? ''}>
            <Avatar
              className={classes.profilePic}
              src={`${apiUrl}/oauth2/${sessionId}/${apiVersion}${user.photoURL}`}
            />
          </Tooltip>
        ) : (
          <Tooltip title={user.displayName ?? ''}>
            <Avatar className={classes.profilePic}>{getUserAvatar(user)}</Avatar>
          </Tooltip>
        )}
        {showStatus && (
          <Box
            className={classes.userStatus}
            bgcolor={isOnline ? green[600] : red[600]}
          />
        )}
      </Box>
      <Box ml={4} className={clsx(classes.userInfo, 'user-info')}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Tooltip title={user.displayName ?? ''}>
            <Box className={clsx(classes.userName)}>
              {!userIsEmpty ? user.displayName : user.email}
            </Box>
          </Tooltip>
        </Box>
        {/*
         TODO: implement status
        <Box className={classes.adminRoot}>{isOnline ? 'Online' : 'Offline'}</Box> */}
      </Box>
    </Box>
  );
};

export default UserInfoView;
