import { USER_LOGOUT } from 'react-admin';
import { OPEN_DIALOG, CLOSE_DIALOG } from './constant';
import produce from 'immer';

interface State {
  resource: string | null;
  isOpen: boolean;
  isLoading: boolean;
  defaultData: object | null;
  dropdownId: number | null;
  source: string | null;
  mustRefresh: boolean;
  redirect: object | null;
  onCreate: Function | null;
  disabledFieldList: object | null;
  parentInfo: object | null;
  dropdownMeta: object | null;
}

interface Payload {
  type: string;
  resource: string;
  data: object;
  dropdownId: number;
  source: string;
  mustRefresh: boolean;
  redirect: object;
  onCreate: Function;
  disabledFieldList: object;
  parentInfo: object;
  dropdownMeta: object;
}

const initialState: State = {
  resource: null,
  isOpen: false,
  isLoading: false,
  defaultData: null,
  dropdownId: null,
  source: null,
  mustRefresh: false,
  redirect: null,
  onCreate: null,
  disabledFieldList: null,
  parentInfo: null,
  dropdownMeta: null,
};

const myReducer = (
  state: State = initialState,
  {
    type,
    resource,
    data,
    dropdownId,
    source,
    mustRefresh,
    redirect,
    onCreate,
    disabledFieldList,
    parentInfo,
    dropdownMeta,
  }: Payload,
) =>
  produce(state, draft => {
    switch (type) {
      case USER_LOGOUT:
      case CLOSE_DIALOG:
        draft.resource = null;
        draft.isOpen = false;
        draft.isLoading = false;
        draft.defaultData = null;
        draft.dropdownId = null;
        draft.source = null;
        draft.mustRefresh = false;
        draft.redirect = null;
        draft.onCreate = null;
        draft.disabledFieldList = null;
        draft.parentInfo = null;
        draft.dropdownMeta = null;
        break;

      case OPEN_DIALOG:
        draft.resource = resource;
        draft.isOpen = true;
        draft.isLoading = false;
        draft.defaultData = data;
        draft.dropdownId = dropdownId;
        draft.source = source;
        draft.mustRefresh = mustRefresh;
        draft.redirect = redirect;
        draft.onCreate = onCreate;
        draft.disabledFieldList = disabledFieldList;
        draft.parentInfo = parentInfo;
        draft.dropdownMeta = dropdownMeta;
        break;
    }
  });

export default myReducer;
