import React, { ReactElement, useRef } from 'react';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import PivotGrid, {
  FieldChooser,
  Export,
  FieldPanel,
} from 'devextreme-react/pivot-grid';

import LoadingBox from '../LoadingBox';
import { useTranslate } from 'react-admin';
import { useStyles } from './pivot-table.style';
import CustomFormButton from '../form-component-old/CustomFormButton';

const PivotTableView = (props: Record<string, any>): ReactElement => {
  const { fields, data, saveSetting } = props;

  const pivotRef = useRef(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const translate = useTranslate();
  const classes = useStyles();

  if (!data || !fields) {
    return <LoadingBox />;
  }

  const dataSource = new PivotGridDataSource({
    fields: fields,
    store: data,
    retrieveFields: false,
  });

  /**
   * reset grid setting
   * @function onResetClick
   * @returns {void}
   */
  const onResetClick = (): void => {
    dataSource.state({});
  };

  /**
   * get fields from setting and pass to props
   * @function onSaveClick
   * @returns {void}
   */
  const onSaveClick = (): void => {
    const fields = dataSource.fields();
    saveSetting(fields);
  };

  return (
    <React.Fragment>
      <div className={classes.action}>
        <div className={classes.buttonActions}>
          <CustomFormButton
            id="formMainSaveButton"
            onClick={onSaveClick}
            disabled={false}
            variant="contained"
            label={translate('ra.action.save')}
            buttonRef={buttonRef}
          />

          <CustomFormButton
            id="formMainSaveButton"
            onClick={onResetClick}
            disabled={false}
            variant="contained"
            label={translate('ra.action.reset')}
            buttonRef={buttonRef}
            icon={'loop'}
          />
        </div>
      </div>

      <div>
        {/* @ts-ignore */}
        <PivotGrid
          id="pivotgrid"
          dataSource={dataSource}
          allowFiltering={true}
          showBorders={true}
          rtlEnabled={true}
          ref={pivotRef}
          allowExpandAll={true}
          texts={{
            collapseAll: translate('pivot.collapseAll'),
            dataNotAvailable: translate('pivot.dataNotAvailable'),
            expandAll: translate('pivot.expandAll'),
            exportToExcel: translate('pivot.exportToExcel'),
            showFieldChooser: translate('pivot.showFieldChooser'),
            sortColumnBySummary: translate('pivot.sortColumnBySummary'),
            sortRowBySummary: translate('pivot.sortRowBySummary'),
            grandTotal: translate('pivot.grandTotal'),
          }}
          allowSorting={true}
          showColumnTotals={true}
          showColumnGrandTotals={true}
          showRowTotals={true}
          showRowGrandTotals={true}
          allowSortingBySummary={false}
        >
          <FieldPanel
            showColumnFields={true}
            showDataFields={true}
            showFilterFields={true}
            showRowFields={true}
            allowFieldDragging={true}
            visible={true}
            texts={{
              columnFieldArea: translate('pivot.columnFieldArea'),
              dataFieldArea: translate('pivot.dataFieldArea'),
              filterFieldArea: translate('pivot.filterFieldArea'),
              rowFieldArea: translate('pivot.rowFieldArea'),
            }}
          />
          <Export fileName={translate('pivot.allFields')} enabled={true} />
          <FieldChooser
            enabled={true}
            title={translate('pivot.fieldChooserTitle')}
            texts={{
              allFields: translate('pivot.allFields'),
              columnFields: translate('pivot.columnFields'),
              dataFields: translate('pivot.dataFields'),
              filterFields: translate('pivot.filterFields'),
              rowFields: translate('pivot.rowFields'),
            }}
            allowSearch={true}
          />
        </PivotGrid>
      </div>
    </React.Fragment>
  );
};

export default PivotTableView;
