export default {
  meta: {
    resourceIsNotDefined: 'Resource data is not defined correctly!',
    getMetaFailure: 'Error receiving meta',
  },
  general: {
    download: 'download',
    requestId: 'Request ID',
    copied: 'copied',
    noRecordFound: 'No Record Found!',
    back: 'back',
    more: 'more',
    apply: 'Apply',
    decline: 'Decline',
    search: 'search ...',
    delete: 'Delete',
    start: 'Start',
    pause: 'Pause',
    reset: 'Reset',
    noData: 'There is no data to display',
    pin: 'pinned note',
    theMenusAreNotAvailable: 'The menus are not available.',
  },
  form: {
    thereIsNoReturnInTheFormula: 'there is no "return" in the formula',
    thereIsNoRecordOrFormula: 'there is no record or formula',
    errorInComputing: 'Error in computing',
    recordNotExist: 'There is no record',
    formulaIsNotValid: 'The computational formula is invalid',
    showLess: 'Show less',
    showMore: 'Show more',
    showFull: 'Show full',
    chooseFile: 'Choose a file',
    sendingData: 'Sending data',
    createAndNew: 'Create and new',
    createAndView: 'Create and View',
    saveAsDefault: 'Save as default',
    restoreDefault: 'Restore default',
    newTab: 'New Tab',
    editTabInfo: 'Edit tab info',
    editGroupInfo: 'Edit group info',
    editFieldInfo: 'Edit field info',
    translatedTitle: {
      fa: 'Persian translation',
      en: 'English translation',
      ar: 'Arabic translation',
    },
    translatedCaption: {
      fa: 'Persian translation',
      en: 'English translation',
      ar: 'Arabic translation',
    },
    columnCount: 'Column count',
    submitWasAlmostSuccessfulPleaseCheckRelationsAndFixErrors:
      'submit was almost successful please check relations and fix errors',
    areYouSureAboutThisOperation: 'Are you sure about this operation?',
    download: 'Download',
    processSelection: 'Process selection',
  },
  grid: {
    groupByColumn: "To group by a field, drag it's header here",
    filterPlaceholder: '',
    services: 'Services',
    shiftProcess: 'Shift Process',
    serviceIsRunning: 'Service is running, please wait ...',
    notes: 'Notes',
    columnFilters: 'Column filters',
    grouping: 'Grouping columns',
    quickCreate: 'quick create',
    filter: 'filter',
    sum: 'sum',
    resourceIsDisabled: 'Resource is disabled',
    print: 'print',
    quickEdit: 'Quick Edit',
    refresh: 'Refresh',
    inlineEdit: 'click to edit',
    noColumns: 'No columns to show',
    groupingEmptyText: 'Drag a column header here to group by that column',
  },
  noteStream: {
    fetch: 'More',
    newNote: 'New note',
    invalidNote: 'Note with more than 50 character and no space is invalid.',
  },
  process: {
    name: 'Process',
  },
  menu: {
    home: 'Home',
    uploadProfilePicture: 'Upload profile picture',
    menuNotFound: 'Menu not found',
    expand: 'expand',
    shrink: 'shrink',
    close: 'close',
    favorites: 'Favorites',
    recent: 'Recent',
    module: 'Module',
    changePassword: 'change password',
  },
  dropdown: {
    placeholder: 'Please select an item',
    noOptionsMessage: 'No items found',
    error: 'Error getting Drop Down information',
    loading: 'Loading',
    searchPlaceholder: 'Search',
    noneLabel: 'None',
  },
  filter: {
    list: 'Saved filters',
    showEveryFilterInList: 'Show all available filters',
    clearOnlyFilterValuesFromList: 'Clear all values from filters',
    clearList: 'Clear grid filters',
    saveFilter: 'Save current filters',
    pleaseGiveANameToYourNewFilter: 'Please give this filter a name',
    name: 'Name',
    nameIsNotValid: 'Please enter a better name',
    date: {
      mode: 'Search',
      on: 'on',
      from: 'from',
      until: 'until',
      between: 'between',
      opposite: 'opposite of',
    },
    apply: 'Apply',
  },
  listColumnChoice: {
    columnConfig: 'Column settings',
    selectColumns: 'Select columns',
    selectColumnsThatYouWantToShow: 'Please select the columns you want to display',
    changeAll: 'Change all',
    currentChoicesAreByDefault: 'Current choices are by default',
    currentChoicesAreByUser: 'Current choices are by user',
    noColumnsToSelect: 'No columns to select',
  },
  quickCreate: {
    fullForm: 'Complete form',
  },
  confirm: {
    areYouSureYouWantToDeleteThisNote: 'Are you sure you want to delete this note?',
    areYouSureYouWantToDeleteThisItem: 'Are you sure you want to delete this item?',
    areYouSureYouWantToDeleteWholeTable:
      'Are you sure you want to delete whole table?',
    areYouSureYouWantToDeleteThisCountOfItem:
      'Are you sure you want to delete %{smart_count} of item?',
    areYouSureYouWantToUnPinWholeTable: 'Are you sure you want to remove the pin?',
    areYouSureYouWantToPinWholeTable: 'Are you sure you want to pin?',
    yes: 'Yes',
    no: 'No',
    ok: 'Ok',
    cancel: 'Cancel',
  },
  puzzle: {
    reset: 'Reset',
  },
  profile: {
    profileSettings: 'Profile settings',
    editProfile: 'Edit Profile',
    firstName: 'First Name',
    lastName: 'Last Name',
    address: 'Address',
    email: 'Email',
    nationalCode: 'National Code',
    phoneNumber: 'Phone Number',
    mobileNumber: 'Mobile Number',
    accountingSoftware: 'Has Accounting Software',
  },
  formLayout: {
    addNewSection: 'add new section',
  },
  print: {
    pleaseGiveBrowserAccessToOpenPopup: 'Please give browser access to open popup!',
    ErrorInDetectingOpenedWindow: 'Error in detecting opened window!',
    printIdNotFoundInMeta: 'This print ID does not exist in the meta',
  },
  tree: {
    tree: 'Tree',
    noDataToDisplay: 'no data to display',
    itIsNotPossibleToSelectFromThisLevel:
      'It is not possible to select from this level.',
  },
  pos: {
    discount: 'Discount',
    subtotal: 'Subtotal',
    tax: 'Tax',
    total: 'Total',
    addCustomer: 'Add Customer',
    note: 'Note...',
    discard: 'Discard',
    park: 'Park',
    pay: 'Pay',
    searchForProductNameOrBarcode: 'Search for a product name or barcode',
    giftCard: 'Gift card',
    customSale: 'Custom sale',
  },
  todo: {
    thereIsNothingTapNewTaskToGetStarted:
      'there is nothing. tap "New Task" to get started.',
    newTask: 'new task',
    newStep: 'new step',
    add: 'add',
    tasks: 'Tasks',
    newList: 'new list',
    newGroup: 'new group',
    deleteGroup: 'delete group',
    ungroup: 'Ungroup',
    renameGroup: 'rename group',
    IsAddedToMyDay: 'Added to my day',
    IsImportant: 'Important',
    DueDate: 'scheduled',
    assignedtome: 'Assigned To Me',
    sysitem: 'System Tasks',
    deleteTodoList: 'delete todo list',
    renameTodoList: 'rename todo list',
    renamedSuccessfully: 'Rename list successfully done!',
    areYouSureYouWantToDeleteThisTask: 'are you sure you want to delete this task?',
    areYouSureYouWantToDeleteThisStep: 'are you sure you want to delete this step?',
    areYouSureYouWantToDeleteThisList: 'are you sure you want to delete this list?',
    note: 'Note',
    isDone: 'Completed',
    hideCompleted: 'Hide Completed Tasks',
    showCompleted: 'Show Completed Tasks',
    title: 'Alphabetical',
    createDate: 'Creation date',
    options: 'Options',
    sort: 'Sort',
    sortBy: 'Sort By',
    asc: 'ascending',
    desc: 'descending',
    today: 'today',
    tomorrow: 'tomorrow',
    overmorrow: 'overmorrow',
    nextWeek: 'next week',
    customDateTime: 'Pick a date/time',
    customDate: 'Pick a date',
    remindMe: 'remind me',
    dueDate: 'end date',
    addToMyDay: 'add to my day',
    daily: 'daily',
    weekly: 'weekly',
    monthly: 'monthly',
    yearly: 'yearly',
    repeat: 'repeat',
    noOptionsMessage: 'Undefined repeatability',
    areYouSureYouWantToDeleteThisFile: 'are you sure you want to delete this file?',
    myDay: 'my day',
    duplicateList: 'duplicate list',
    shareList: 'share list',
    assignTo: 'assign to',
    dragListToEmptyGroup: 'Drag here to add lists',
    listIsAlreadyShared: 'This list has been already shared with this person.',
    selectAPerson: 'Select a person.',
    notYourself: 'You cannot share your list with yourself.',
    assingedToMe: 'Assigned to Me',
    assingToMe: 'Assign to Me',
    quickFilters: 'Filters',
    invalidDate: 'Invalid Date',
    link: 'link',
  },
  sellin: {
    id: 'id',
    createDate: 'create date',
    itemCount: 'item count',
    totalItemCount: 'total item count',
    totalDiscountPrice: 'total discount price',
    totalWholePrice: 'total whole price',
    finalPrice: 'final price',
    settlementTypeTitle: 'type of settlement',
    orderStatusTitle: 'order status',
    successfulOnlinePayment: 'successful online payment',
    orderInformation: 'order information',
    orderStatusDescription: 'order status',
    detail: 'detail',
    showProduct: 'show product',
    price: 'price',
    tags: 'tags',
    description: 'description',
    addToCart: 'add to cart',
    priceDetails: 'price details',
    settlementType: 'settlement type',
    submitOrder: 'submit order',
    areYouConfidentInFinalizingYourOrder:
      'Are you confident in finalizing your order?',
    yourOrderHasBeenRegistered: 'Your order has been registered.',
    addedToBasketSuccessfully: 'Item added to basket successfully',
    pleaseEnterTheNameOfTheDesiredProductInTheBoxAbove:
      'Please enter the name of the desired product in the box above.',
    noProductFound: 'No product found!',
    inventory: 'Inventory',
    ordering: 'Ordering',
    barcodes: 'Banslatearcodes',
  },
  service: {
    success: 'Service completed successfully.',
  },
  customValidation: {
    validationErrorOnTab: 'Validation error in %{tabName}',
    separator: 'and',
    valueOfField: 'value of field ',
    valueOfFields: 'value of fields ',
    notValid: ' should be change',
    areNotValid: ' should be change',
    invalidValue: 'The value entered is invalid',
    unknownTab: 'unknown tab',
  },
  toolbar: {
    importComponent: {
      import: 'import',
      importExcelFile: 'Import excel file',
      downloadSampleFile: 'Download sample file',
      uploadExcelFile: 'Upload',
      chooseAfile: 'Choose a file',
    },
  },
  quickAccess: {
    quickAccess: 'Quick access',
    actionDescription: {
      quickAccess: 'Type your desired shortcut in the box and press Enter.',
    },
  },
  imageDialog: {
    dataNotFound: 'There is no data to display!',
  },
  editor: {
    placeholder: 'Type the content here!',
    staticVariables: 'Static variables',
  },
  fileManager: {
    properties: 'Properties',
    propertiesError: 'Cannot Show properties for non-selected item',
  },
  notification: {
    changePassword: {
      success: 'Password change completed successfully',
    },
    messages: 'Messages',
  },
  blog: {
    recentNotes: 'announcements',
    continueReading: 'Continue reading...',
  },
  file: {
    fileUploadLimitMB: 'File size is more than %{fileUploadLimitMB} megabyte',
    file: 'file',
    files: 'files',
    and: 'and',
    couldNotSendFileBecauseItsLargeSize:
      'could not send due to volume higher than allowed',
    couldNotSendFilesBecauseItsLargeSize:
      'they were not sent due to the higher volume than the allowed amount',
  },
  calendar: {
    items: 'items',
    today: 'Today',
    back: 'Back',
    next: 'Next',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    require: 'require',
    calendar: 'calendar',
  },
  chat: {
    startChat: 'Select User to Start Chat',
    sayHi: 'SayHi To',
    messages: 'Messages',
    contacts: 'Contacts',
    search: 'Search Here ...',
    reply: 'Reply',
    deleteMessage: 'Delete Message',
    edit: 'Edit',
    deleteConfirmMessage: 'Are you sure you want to delete this message ?',
    messagePlaceHolder: 'type a message ...',
    noUserFound: 'No User Found!',
    noMessagesFound: 'No Messages Found!',
    selectedFiles: '%{count} files selected',
  },
  mail: {
    automation: 'automation',
    newMessage: 'New Message',
    downloadAll: 'Download All',
    newMail: 'New Mail',
    inMail: 'In Mail',
    folders: 'messages',
    labels: 'Labels',
    send: 'Send',
    saveAsDraft: 'Save as Draft',
    reply: 'Reply',
    replyAll: 'Reply All',
    replySubject: 'Reply',
    forward: 'Forward',
    sender: 'sender',
    attachedFiles: 'Attached Files',
    successReply: 'Message Successfully Replied',
    successArchive: 'Message Successfully Archived',
    successRecall: 'Recall Service Successfully Done',
    recallConfirmMessage: 'Are You Sure Running Recall Service',
    confirmDeleteFolderMessage:
      'Are you sure you want to delete folder: %{folderName} ?',
    mailNotFound: 'Mail Not Found!',
    toStar: 'Starred',
    toArchive: 'Archive',
    toUnArchive: 'Un Archive',
    toReference: 'Reference',
    markAsRead: 'Mark As Read',
    markAsUnRead: 'Mark As UnRead',
    markAsImportant: 'Mark As Important',
    markAsStarred: 'Mark As Starred',
    removeStarred: 'Remove Starred',
    removeImportant: 'Remove Important',
    applyLabel: 'Apply Label',
    newLabel: 'New Label',
    labelTitle: 'Label Title',
    colorCode: 'Color Code',
    parentLabel: 'Parent Label',
    template: 'Message Templates',
    noTemplateData: 'No Template Data',
    followUp: 'Follow Up',
    messageText: 'Message Text',
    references: 'References',
    cycle: 'Cycle',
    cc: 'CC',
    bcc: 'BCC',
    recall: 'Recall',
    personalFolders: 'Personal Folders',
    createFolder: 'Create New Folder',
    moveTo: 'Move To',
    from: 'From',
    to: 'To',
    notSeen: 'Not Seen',
    delegation: 'delegation of authority',
    mailSubject: 'Subject',
    seeAll: 'see',
    docType: {
      allMail: 'All Mail',
      inbox: 'Inbox',
      outbox: 'Outbox',
      unread: 'UnRead',
      archived: 'Archived',
      starred: 'Starred',
      important: 'Important',
      innerMail: 'Inner Mail',
      systemTasks: 'System Tasks',
      message: 'Message',
      draft: 'Draft',
    },
    referenceListHeader: {
      date: 'date',
      sender: 'sender',
      receiver: 'receiver',
      referenceParaph: 'reference paraph',
      seenDate: 'seen date',
      kind: 'kind',
    },
  },
  pivot: {
    fieldChooserTitle: 'Field Chooser',
    allFields: 'All Fields',
    filterFields: 'Filter Fields',
    dataFields: 'Data Fields',
    columnFields: 'Column Fields',
    rowFields: 'Row Fields',
    excelExportFileName: 'Pivot Grid',

    sortColumnBySummary: 'Sort Column By Summary',
    sortRowBySummary: 'Sort Row By Summary',
    showFieldChooser: 'Show Field Chooser',
    exportToExcel: 'Export To Excel',
    expandAll: 'Expand All',
    dataNotAvailable: 'Data Not Available',
    collapseAll: 'Collapse All',

    columnFieldArea: 'Column Field Area',
    dataFieldArea: 'Data Field Area',
    filterFieldArea: 'Filter Field Area',
    rowFieldArea: 'Row Field Area',
    settingSavedSuccessfully: 'Setting Saved Successfully',
    grandTotal: 'Grand Total',
  },
  dynamicInput: {
    emptyValue: 'empty value',
  },
  datePicker: {
    placeholder: 'YYYY/MM/DD',
    dateTimePlaceholder: 'YYYY/MM/DD hh:mm',
  },
  css: {
    drawer: 'Dynamic style',
    save: 'save',
    success: 'done successfully',
  },
};
