import { isEmptyObject } from '../../../../helper/data-helper';
import { AuthUser } from './user-info.type';

/**
 * to generate user avatar when user has not uploaded his/her profile photo yet
 * @function getUserAvatar
 * @param { CurrentUser } user
 * @returns { string }
 */
export const getUserAvatar = (user: AuthUser | undefined): string => {
  if (isEmptyObject(user)) {
    return '';
  }

  // Because we checked that `user` was not empty by `isEmptyObject`, now `user` is an object that has some keys(we can use `!`)
  if (user!.displayName) {
    return user!.displayName.charAt(0).toUpperCase();
  }

  if (user!.email) {
    return user!.email.charAt(0).toUpperCase();
  }

  return '';
};
