import {
  actorOnDispatch,
  actorDispatch,
  actorGetActionValue,
} from '../type/actor-setup';
import dataProvider, { GET_REPORT } from '../core/dataProvider';
import { showNotification } from '../helper/general-function-helper';

actorOnDispatch(
  'getCalendarData',
  detail => {
    const { reportId, params, successCallback } = detail;
    const { current: currentResource } = actorGetActionValue('resources')!;

    actorDispatch('loading', true, {
      path: `${currentResource.value}`,
    });
    dataProvider(GET_REPORT, '', {
      reportId,
      filters: Object.entries(params).map(([key, value]) =>
        Array.isArray(value) ? value : [key, 'equal', value],
      ),
      pagination: {
        page: 0,
        perPage: 999999,
      },
      sort: {
        field: 'id',
        order: 'DESC',
      },
    })
      .then(response => {
        successCallback?.(response);
        actorDispatch('loading', false, {
          path: `${currentResource.value}`,
        });
      })
      .catch(error => {
        showNotification(error, 'error');
        actorDispatch('loading', false, {
          path: `${currentResource.value}`,
        });
      });
  },
  {
    preserve: false,
  },
);
