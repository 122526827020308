import { FC, memo, useCallback, useEffect, useState } from 'react';
import { isEmpty, isJsonEncodedString } from '../../../helper/data-helper';
import {
  ChangeFormValueParams,
  FormActions,
  OnBlurParams,
  OnFocusParams,
} from '../../form';
import {
  convertSecondsToTimeFormat,
  convertTimeFormatToSeconds,
  isCurrentFieldHasValidationActions,
} from './timer-input.helper';

import {
  TimerInputActionType,
  TimerInputInterface,
  TimerInputValuesType,
} from './timer-input.type';
import TimerInputView from './timer-input.view';

const TimerInputController: FC<TimerInputInterface> = memo(props => {
  const {
    value,
    formActionsHandler,
    inputMessage,
    resource,
    field,
    label,
    getRef,
    className,
    disabled,
    visibleClass,
    inputContainerClass,
    customTestAttribute,
  } = props;

  const { name, values, id } = field;

  const [action, setAction] = useState<TimerInputActionType | null>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(true);
  const [time, setTime] = useState<number>(0);

  /**
   * Handle Run Service Validation
   * @function handleRunServiceValidation
   * @param { action } value
   * @param { Function } onSuccess
   * @returns { Promise<void> }
   */
  const handleRunServiceValidation = async (
    action: TimerInputActionType,
    onSuccess: () => void,
  ): Promise<void> => {
    const serviceValue = {
      value: convertSecondsToTimeFormat(time),
      Action: action,
    };

    const stringifiedServiceValue = JSON.stringify(serviceValue);

    formActionsHandler(FormActions.InputBlur, {
      fieldName: name,
      value: stringifiedServiceValue,
      successRunValidationCallback: onSuccess,
    } as OnBlurParams);
  };

  /**
   * Handle focus event
   * @function handleFocus
   * @returns {void} void
   */
  const handleFocus = (): void => {
    formActionsHandler(FormActions.InputFocus, {
      fieldName: name,
      value,
    } as OnFocusParams);
  };

  /**
   * Handle Change event
   * @function handleSubmit
   * @param { number } time
   * @returns { void }
   */
  const handleChange = (): void => {
    const convertedTime = convertSecondsToTimeFormat(time);

    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value: convertedTime,
    } as ChangeFormValueParams);
  };

  /**
   * @function onSuccessStartCallback
   * @returns { void }
   */
  const onSuccessStartCallback = (): void => {
    Promise.resolve().then(() => {
      setIsActive(true);
      setIsPaused(false);
    });
  };

  /**
   * @function onStart
   * @returns { void }
   */
  const onStart = (): void => {
    isCurrentFieldHasValidationActions(id)
      ? handleRunServiceValidation('start', onSuccessStartCallback)
      : onSuccessStartCallback();
  };

  /**
   * @function onSuccessPauseCallback
   * @returns { void }
   */
  const onSuccessPauseCallback = (): void => {
    setIsPaused(prev => !prev);
    handleChange();
  };

  /**
   * @function onPause
   * @returns { void }
   */
  const onPause = (): void => {
    isCurrentFieldHasValidationActions(id)
      ? handleRunServiceValidation('pause', onSuccessPauseCallback)
      : onSuccessPauseCallback();
  };

  /**
   * @function onSuccessResetCallback
   * @returns { void }
   */
  const onSuccessResetCallback = (): void => {
    Promise.resolve().then(() => {
      setIsActive(false);
      setIsPaused(true);
      setTime(0);
      handleChange();
    });
  };

  /**
   * @function onReset
   * @returns { void }
   */
  const onReset = (): void => {
    isCurrentFieldHasValidationActions(id)
      ? handleRunServiceValidation('reset', onSuccessResetCallback)
      : onSuccessResetCallback();
  };

  /**
   * @function handleTimeIncrement
   * @returns  { void }
   */
  const handleTimeIncrement = useCallback((): void => {
    setTime(prev => prev + 1);
  }, [setTime]);

  /**
   * @function handleTimeDecrement
   * @returns  { void }
   */
  const handleTimeDecrement = useCallback((): void => {
    setTime(prev => {
      if (prev > 0) {
        return prev - 1;
      } else {
        onPause();
        return 0;
      }
    });
  }, [setTime]);

  useEffect(() => {
    if (!isEmpty(value)) {
      if (isJsonEncodedString(value)) {
        const parsedValue = JSON.parse(value);

        Promise.resolve().then(() => {
          parsedValue?.value &&
            setTime(convertTimeFormatToSeconds(parsedValue.value));
          parsedValue?.Action && setAction(parsedValue.Action);
        });
      } else {
        setTime(convertTimeFormatToSeconds(value));
      }
    }
  }, [value]);

  useEffect(() => {
    if (action) {
      switch (action) {
        case 'start':
          onStart();
          break;
        case 'pause':
          onPause();
          break;
        case 'reset':
          onReset();
          break;
        default:
          break;
      }
    }

    return () => {
      setAction(null);
    };
  }, [action]);

  useEffect(() => {
    let interval: NodeJS.Timer | null = null;

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        if ((values as unknown as TimerInputValuesType)?.reverse === true) {
          handleTimeDecrement();
        } else {
          handleTimeIncrement();
        }
      }, 1000);
    } else {
      interval && clearInterval(interval);
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [isActive, isPaused]);

  return (
    <TimerInputView
      getRef={getRef}
      resource={resource}
      field={field}
      formActionsHandler={formActionsHandler}
      inputMessage={inputMessage}
      value={convertSecondsToTimeFormat(time)}
      label={label}
      disabled={disabled}
      className={className}
      visibleClass={visibleClass}
      inputContainerClass={inputContainerClass}
      customTestAttribute={customTestAttribute}
      handleFocus={handleFocus}
      isPaused={isPaused}
      onStart={onStart}
      onPause={onPause}
      onReset={onReset}
    />
  );
});

export default TimerInputController;
