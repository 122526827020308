import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import lodashIsEqual from 'lodash/isEqual';
import { compose } from 'recompose';
import { Button, withStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import FormLayoutSidebar from '../component/formLayout/FormLayoutSidebar';
import {
  getColumnCount,
  getFields,
  getTabList,
  mergeTabDataWithSetting,
} from '../helper/MetaHelper';
import FormLayoutEditor from '../component/formLayout/FormLayoutEditor';
import { clone, mergeAndClone } from '../helper/data-helper';
import FormLayoutTabInfoDialog from '../component/formLayout/FormLayoutTabInfoDialog';
import FormLayoutGroupInfoDialog from '../component/formLayout/FormLayoutGroupInfoDialog';
import FormLayoutFieldInfoDialog from '../component/formLayout/FormLayoutFieldInfoDialog';
import LoadingBox from '../component/LoadingBox';
import LocaleHoc from '../component/LocaleHoc';
import { getAppSettings, setAppSettings } from '../helper/settings-helper';
import { CONFIG_FORM_LAYOUT } from '../core/configProvider';

const styles = {
  container: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
  },

  actionButton: {
    marginRight: 10,
  },
};

const getNewGroupData = () => ({
  id: Date.now(),
  translatedTitle: {
    fa: 'قسمت جدید',
    en: 'New Section',
    ar: 'قسم جديد',
  },
  columnCount: 2,
  layout: [[null, null]],
});

class FormLayoutContainer extends PureComponent {
  state = {
    formResource: null,
    tabList: [],
    editTabIndex: null,
    editGroupIndex: null,
    editFieldSource: null,
    activeTabIndex: 0,
  };

  componentDidMount() {
    this.showTabDataOnForm(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { resource: nextResource, metaData: nextMetaData } = nextProps;

    if (nextResource && nextResource !== this.state.formResource && nextMetaData) {
      this.showTabDataOnForm(nextProps);
    }
  }

  showTabDataOnForm(props) {
    const { metaData, resource } = props;

    const storedValue = getAppSettings(this.getNameOfSetting());

    const defaultColumnCount = getColumnCount(metaData);
    this.setState({
      formResource: resource,
      tabList: mergeTabDataWithSetting(
        metaData,
        null, // processList parameter which is always null in this component
        getTabList({ list: metaData, defaultColumnCount }),
        storedValue,
      ),
    });
  }

  onActiveTabChange = (tab, index) => this.setState({ activeTabIndex: index });

  getAllFields() {
    const { metaData } = this.props;

    return getFields(metaData);
  }

  openTabInfoDialog = tabIndex => this.setState({ editTabIndex: tabIndex });

  openGroupInfoDialog = groupIndex => this.setState({ editGroupIndex: groupIndex });

  closeTabInfoDialog = () => this.setState({ editTabIndex: null });

  closeGroupInfoDialog = () => this.setState({ editGroupIndex: null });

  onTabConfigChange = values => {
    const { editTabIndex, tabList } = this.state;

    const clonedTabList = clone(tabList);
    clonedTabList[editTabIndex] = mergeAndClone(clonedTabList[editTabIndex], values);

    this.setState({ editTabIndex: null, tabList: clonedTabList });
  };

  onGroupConfigChange = values => {
    const { activeTabIndex, editGroupIndex, tabList } = this.state;
    const clonedTabList = clone(tabList);

    const groupInfo = mergeAndClone(
      clonedTabList[activeTabIndex].groupList[editGroupIndex],
      values,
    );

    const columnCount = groupInfo.columnCount;
    if (columnCount) {
      const groupLength = groupInfo.layout.length;
      for (let i = 0; i < groupLength; i++) {
        // need to remove extra columns
        if (groupInfo.layout[i].length >= columnCount) {
          groupInfo.layout[i].splice(columnCount, 10000);
        }
        // fill with empty items until column count
        else {
          while (groupInfo.layout[i].length < columnCount) {
            groupInfo.layout[i].push(null);
          }
        }
      }
    }

    clonedTabList[activeTabIndex].groupList[editGroupIndex] = groupInfo;
    this.setState({ editGroupIndex: null, tabList: clonedTabList });
  };

  handleMove = (source, target) => {
    if (source.indexOf('field') === 0) {
      // put new field in
      this.addNewField(source, target);
    } else {
      this.swapField(source, target);
    }
  };

  swapField(source, target) {
    const { tabList, activeTabIndex } = this.state;
    const [sourceGroupIndex, sourceRowIndex, sourceIndex] = source.split('/');
    const [targetGroupIndex, targetRowIndex, targetIndex] = target.split('/');

    const clonedTabList = clone(tabList);
    const clonedList = clonedTabList[activeTabIndex];

    // get the source field out
    const [movedSourceField] = clonedList.groupList[sourceGroupIndex].layout[
      sourceRowIndex
    ].splice(
      sourceIndex,
      1,
      null, // put null to preserve count of original list before change
    );

    // prettier-ignore
    const [replacedTargetField] = clonedList.groupList[targetGroupIndex].layout[targetRowIndex].splice(
      targetIndex,
      1,
      movedSourceField,
    );

    // put replaced field in source place
    clonedList.groupList[sourceGroupIndex].layout[sourceRowIndex].splice(
      sourceIndex,
      1, // get null out
      replacedTargetField,
    );

    clonedTabList[activeTabIndex] = clonedList;
    this.setState({ tabList: clonedTabList });
  }

  addNewField(source, target) {
    const [, fieldId] = source.split('/');
    const [targetGroupIndex, targetRowIndex, targetIndex] = target.split('/');

    const { metaData } = this.props;
    const allFieldList = getFields(metaData);

    const newField = allFieldList[fieldId];

    const { tabList, activeTabIndex } = this.state;
    const clonedTabList = clone(tabList);

    clonedTabList[activeTabIndex].groupList[targetGroupIndex].layout[
      targetRowIndex
    ].splice(
      targetIndex,
      1, // get the null out
      newField,
    );

    this.setState({ tabList: clonedTabList });
  }

  removeField = source => {
    // just remove source
    const [sourceGroupIndex, sourceRowIndex, sourceIndex] = source.split('/');

    const { tabList, activeTabIndex } = this.state;
    const clonedTabList = clone(tabList);

    // prettier-ignore
    clonedTabList[activeTabIndex].groupList[sourceGroupIndex].layout[sourceRowIndex].splice(
      sourceIndex,
      1,
      null, // put back a null to maintain count of columns
    );

    this.setState({ tabList: clonedTabList });
  };

  addNewGroup = () => {
    const { activeTabIndex, tabList } = this.state;
    const clonedTabList = clone(tabList);

    clonedTabList[activeTabIndex].groupList.push(getNewGroupData());

    this.setState({
      tabList: clonedTabList,
      editGroupIndex: clonedTabList[activeTabIndex].groupList.length - 1,
    });
  };

  removeGroup = groupIndex => {
    const { activeTabIndex, tabList } = this.state;
    const clonedTabList = clone(tabList);

    clonedTabList[activeTabIndex].groupList.splice(groupIndex, 1);

    this.setState({ tabList: clonedTabList });
  };

  removeTab = tabIndex => {
    const { tabList } = this.state;
    const clonedTabList = clone(tabList);

    clonedTabList.splice(tabIndex, 1);

    this.setState({ tabList: clonedTabList });
  };

  addNewTab = () => {
    const { tabList } = this.state;
    const clonedTabList = clone(tabList);

    clonedTabList.push({
      id: Date.now(),
      translatedTitle: {
        fa: 'بخش جدید',
        en: 'New Tab',
        ar: 'قسم جديد',
      },
      groupList: [getNewGroupData()],
    });

    this.setState({
      tabList: clonedTabList,
      editTabIndex: clonedTabList.length - 1,
    });
  };

  addNewGroupRow = (tabIndex, groupIndex) => {
    const { tabList } = this.state;
    const clonedTabList = clone(tabList);

    const columnCount = clonedTabList[tabIndex].groupList[groupIndex].columnCount;

    clonedTabList[tabIndex].groupList[groupIndex].layout.push(
      new Array(columnCount).fill(null),
    );

    this.setState({ tabList: clonedTabList });
  };

  removeGroupRow = (tabIndex, groupIndex, rowIndex) => {
    const { tabList } = this.state;
    const clonedTabList = clone(tabList);

    clonedTabList[tabIndex].groupList[groupIndex].layout.splice(rowIndex, 1);

    this.setState({ tabList: clonedTabList });
  };

  saveFormLayout = () => {
    const { tabList } = this.state;
    const fields = this.getAllFields();

    // work on cloned data, try to delete duplicate data
    const cleanClonedTabList = clone(tabList);

    // t = tab index
    // g = group index
    // l = layoutRow index
    // f = field index
    for (const t in cleanClonedTabList) {
      for (const g in cleanClonedTabList[t].groupList) {
        for (const l in cleanClonedTabList[t].groupList[g].layout) {
          for (const f in cleanClonedTabList[t].groupList[g].layout[l]) {
            const clonedField = cleanClonedTabList[t].groupList[g].layout[l][f];
            // if this position has no field in it
            if (!clonedField) {
              continue;
            }

            const originalField = fields[clonedField.id];

            for (const key in clonedField) {
              // don't delete id property
              if (key === 'id') {
                continue;
              }

              // if data is equal to original, no need to send it to server
              if (lodashIsEqual(clonedField[key], originalField[key])) {
                // because clonedField field is reference to var in main clonedTabList, deleting like this will work!
                delete clonedField[key];
              }
            }
          }
        }
      }
    }

    setAppSettings({
      key: this.getNameOfSetting(),
      value: cleanClonedTabList,
      onSuccess: () => {
        window.history.back();
      },
    });
  };

  getNameOfSetting() {
    const { resource } = this.props;

    return CONFIG_FORM_LAYOUT + '_' + resource;
  }

  deleteFormLayout = () => {
    const { metaData, resource } = this.props;

    const defaultColumnCount = getColumnCount(metaData);
    this.setState({
      formResource: resource,
      tabList: getTabList({ list: metaData, defaultColumnCount }), // no need to merge with anything!
    });

    // remove by giving null value
    setAppSettings({
      key: this.getNameOfSetting(),
      value: null,
    });
  };

  openFieldInfoDialog = fieldSource =>
    this.setState({ editFieldSource: fieldSource });

  closeFieldInfoDialog = () => this.setState({ editFieldSource: null });

  onFieldConfigChange = (tabIndex, groupIndex, rowIndex, fieldIndex, newData) => {
    const { tabList, activeTabIndex } = this.state;
    const clonedTabList = clone(tabList);

    clonedTabList[activeTabIndex].groupList[groupIndex].layout[rowIndex][
      fieldIndex
    ] = newData;

    this.setState({ tabList: clonedTabList, editFieldSource: null });
  };

  goBack = () => window.history.back();

  render() {
    const { classes, translate, metaData, metaDataLoading, locale } = this.props;
    const {
      tabList,
      activeTabIndex,
      editTabIndex,
      editGroupIndex,
      editFieldSource,
    } = this.state;
    const fields = this.getAllFields();

    if (metaDataLoading || !metaData || !tabList || !tabList.length) {
      return <LoadingBox />;
    }

    return (
      <Fragment>
        <DndProvider backend={HTML5Backend}>
          <div className={classes.container}>
            <FormLayoutEditor
              locale={locale}
              tabList={tabList}
              addNewTab={this.addNewTab}
              openTabInfoDialog={this.openTabInfoDialog}
              removeTab={this.removeTab}
              openGroupInfoDialog={this.openGroupInfoDialog}
              moveField={this.handleMove}
              removeField={this.removeField}
              onActiveTabChange={this.onActiveTabChange}
              addNewGroup={this.addNewGroup}
              removeGroup={this.removeGroup}
              addNewGroupRow={this.addNewGroupRow}
              removeGroupRow={this.removeGroupRow}
              openFieldInfoDialog={this.openFieldInfoDialog}
            >
              <Button
                className={classes.actionButton}
                color="primary"
                variant="contained"
                size="large"
                onClick={this.saveFormLayout}
              >
                {translate('ra.action.save')}
              </Button>

              <Button
                className={classes.actionButton}
                color="primary"
                variant="text"
                size="large"
                onClick={this.deleteFormLayout}
              >
                {translate('form.restoreDefault')}
              </Button>

              <Button
                className={classes.actionButton}
                color="default"
                variant="text"
                size="large"
                onClick={this.goBack}
              >
                {translate('ra.action.cancel')}
              </Button>
            </FormLayoutEditor>
            <FormLayoutSidebar
              tabList={tabList}
              locale={locale}
              fields={fields}
              removeField={this.removeField}
            />
          </div>
        </DndProvider>
        <FormLayoutTabInfoDialog
          tabList={tabList}
          tabIndex={editTabIndex}
          onCloseDialog={this.closeTabInfoDialog}
          onChange={this.onTabConfigChange}
        />

        <FormLayoutGroupInfoDialog
          tabList={tabList}
          tabIndex={activeTabIndex}
          groupIndex={editGroupIndex}
          onCloseDialog={this.closeGroupInfoDialog}
          onChange={this.onGroupConfigChange}
        />

        {editFieldSource && (
          <FormLayoutFieldInfoDialog
            tabList={tabList}
            tabIndex={activeTabIndex}
            fieldSource={editFieldSource}
            onCloseDialog={this.closeFieldInfoDialog}
            onChange={this.onFieldConfigChange}
          />
        )}
      </Fragment>
    );
  }
}

FormLayoutContainer.propTypes = {
  locale: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  metaData: PropTypes.object.isRequired,
};

export default compose(
  translate,
  withStyles(styles),
  LocaleHoc,
)(FormLayoutContainer);
