import { ChangeEvent, FC, memo, useEffect, useState, useRef } from 'react';

import { actorOnDispatch, actorRemoveAction } from '../../../../type/actor-setup';
import { useStyles } from '../../grid.style';
import GridTextInputView from './grid-text-input.view';

import type { GridTextInputInterface } from './grid-text-input.type';

const GridTextInputController: FC<GridTextInputInterface> = props => {
  const { field, onChangeInput, isDisabled } = props;
  const classes = useStyles();

  const [internalValue, setInternalValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const internalValueRef = useRef('');

  useEffect(() => {
    const listenerId = actorOnDispatch('gridFormData', gridFormData => {
      const value = gridFormData[field.name] ?? '';
      (isFocused || value) && !internalValueRef.current
        ? setInternalValue(value as string)
        : setIsFocused(true);
    });

    return () => {
      actorRemoveAction({
        actionName: 'gridFormData',
        listenerId,
      });
    };
  }, []);

  /**
   * handle change date
   * @function handleChangeValue
   * @param { ChangeEvent<HTMLInputElement> } event
   * @returns { void } void
   */
  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>): void => {
    setInternalValue(event.target.value);
    internalValueRef.current = event.target.value;
    onChangeInput({
      fieldName: field.name,
      value: internalValue ?? event.target.value,
      submitValue: false,
    });
  };

  /**
   * handle blur
   * @function handleBlur
   * @returns { void } void
   */
  const handleBlur = (): void => {
    onChangeInput({ fieldName: field.name, value: internalValue });
  };

  /**
   * handle focus
   * @function handleFocus
   * @param { React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element> } event
   * @returns { void } void
   */
  const handleFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ): void => {
    event.target.select();
  };
  return (
    <div className={classes.datePickerContainer}>
      <GridTextInputView
        handleChangeValue={handleChangeValue}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        isDisabled={isDisabled}
        value={internalValue}
      />
    </div>
  );
};

export default memo(GridTextInputController);
