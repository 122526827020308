import lodashGet from 'lodash/get';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push as redirectToPage } from 'connected-react-router';
import {
  crudGetList,
  GET_ONE,
  GET_LIST,
  CREATE,
  showNotification,
} from 'react-admin';

import dataProvider from '../../core/dataProvider';

import {
  REMOVE_BASKET_ITEM,
  SET_BASKET_ITEM,
  GET_ORDER_DETAIL_ITEM,
  GET_ORDER_DETAIL,
  FINALIZE_BASKET,
  REFRESH_BASKET,
  GET_SUB_CATEGORY_DATA,
} from './constant';
import {
  crudCreateWithCallbackAction,
  crudDeleteWithCallbackAction,
} from '../crud/action';
import {
  getOrderDetailItemSuccessAction,
  getOrderDetailItemFailedAction,
  getOrderDetailSuccessAction,
  getSubCategorySuccess,
} from './action';
import { CART } from '../../core/configRouteConstant';

function* removeFromBasket({ resource, id, parentResource, defaultSort }) {
  yield put(
    crudDeleteWithCallbackAction(
      resource,
      id,
      {},
      function* () {
        // input parameters are like the ones in crudDeleteSuccess
        yield refreshBasket({
          payload: {
            data: {
              parentResource,
              defaultSort,
            },
          },
        });
      },
      true,
    ),
  );
}

function* setBasket({ data, resource, parentResource, defaultSort }) {
  yield put(
    crudCreateWithCallbackAction(
      resource,
      data,
      // success callback will trigger refresh
      parentResource
        ? function* ({ payload }) {
            // input parameters are like the ones in crudDeleteSuccess
            yield refreshBasket({
              payload: {
                data: {
                  parentResource,
                  defaultSort,
                },
              },
            });
          }
        : {},
      true, //disableNotification
    ),
  );
}

function* refreshBasket({ payload }) {
  yield put(
    crudGetList(
      lodashGet(payload, ['data', 'parentResource']),
      // just get all basket items
      {
        page: 1,
        perPage: 1000,
      },
      lodashGet(payload, ['data', 'defaultSort']),
      {}, // no filter necessary
    ),
  );
}

function* getOrderDetailItem({ resource, id }) {
  try {
    const { data, additionalData } = yield dataProvider(GET_ONE, resource, {
      id: id,
      rawResponse: true,
    });
    yield put(
      getOrderDetailItemSuccessAction({ resource, data, additionalData, id }),
    );
  } catch (catchError) {
    const error = lodashGet(
      catchError,
      ['response', 'data', 'userMessage'],
      catchError.toString(),
    );
    yield put(getOrderDetailItemFailedAction({ resource, error }));
  }
}

function* getOrderDetail({ resource, cartResource }) {
  try {
    const response = yield dataProvider(GET_LIST, resource, {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'id', order: 'DESC' },
      rawResponse: true,
    });

    if (response.status) {
      yield put(getOrderDetailSuccessAction({ resource, data: response.data }));
    } else {
      const message = lodashGet(response, ['userMessage']);
      yield put(redirectToPage(`/${CART}/${cartResource}`));
      yield put(showNotification(message, 'error'));
    }
  } catch (catchError) {
    const error = lodashGet(
      catchError,
      ['response', 'data', 'userMessage'],
      catchError.toString(),
    );
    yield put(getOrderDetailItemFailedAction({ resource, error }));
  }
}

function* finalizeBasket({ resource, data, redirectResource }) {
  try {
    const { data: id } = yield dataProvider(CREATE, resource, {
      data: data,
      rawResponse: true,
    });
    yield put(redirectToPage(`${redirectResource}/${id}`));
    yield put(showNotification('sellin.yourOrderHasBeenRegistered', 'info'));
  } catch (catchError) {
    const error = lodashGet(
      catchError,
      ['response', 'data', 'userMessage'],
      catchError.toString(),
    );
    yield put(showNotification(error, 'error'));
  }
}

function* getSubCategory({ resource, filter, key }) {
  try {
    const { data } = yield dataProvider(GET_LIST, resource, {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'Priority', order: 'DESC' },
      filter: filter,
    });
    yield put(getSubCategorySuccess({ resource, data, key }));
  } catch (catchError) {
    const error = lodashGet(
      catchError,
      ['response', 'data', 'userMessage'],
      catchError.toString(),
    );
    yield put(showNotification(error, 'error'));
  }
}

export default function* dropdownSaga() {
  yield takeEvery(REMOVE_BASKET_ITEM, removeFromBasket);
  yield takeEvery(SET_BASKET_ITEM, setBasket);
  yield takeEvery(GET_ORDER_DETAIL_ITEM, getOrderDetailItem);
  yield takeEvery(GET_ORDER_DETAIL, getOrderDetail);
  yield takeEvery(FINALIZE_BASKET, finalizeBasket);
  yield takeLatest(REFRESH_BASKET, refreshBasket);
  yield takeEvery(GET_SUB_CATEGORY_DATA, getSubCategory);
}
