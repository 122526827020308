import { FC, useEffect } from 'react';
import { Admin, Resource } from 'react-admin';
import { initializeIcons } from '@uifabric/icons';
import 'devextreme/dist/css/dx.light.css';

import dataProvider from './core/dataProvider';
import authProvider from './core/authProvider';
import i18nProvider from './core/i18n/i18nProvider';
import themeProvider, { AppLayoutDirection } from './core/themeProvider';
import customReducers from './redux/customReducers';
import customSagas from './redux/customSagas';
import customRoutes from './page/customRoutes';
import { AppLayout } from './component/app-layout';
import DashboardPage from './page/DashboardPage';
import LoginPage from './page/LoginPage';
import { NewMetaStore } from './container/NewMetaContext';
import { loadMessages } from 'devextreme/localization';
import faMessages from './core/devExpressTranslations/fa.json';
import ErrorBoundary from './core/ErrorBoundary';
import './api/settings-api';
import { actorSetActionValue } from './type/actor-setup';
import { USER_LOG_IN } from './core/configProvider';

/**
 * to load devExpress custom localization
 * @function initDevExpressMessages
 */
function initDevExpressMessages() {
  loadMessages(faMessages);
}

initializeIcons();
initDevExpressMessages();

const App: FC = () => {
  useEffect(() => {
    try {
      const channel = new BroadcastChannel('reactERP');
      actorSetActionValue('broadcastChannel', channel);
      channel.addEventListener('message', (event: MessageEvent) => {
        if (event.data.type === USER_LOG_IN) {
          if (window.location.pathname.indexOf('login') > -1) {
            window.location.href = '/';
          } else {
            window.location.reload();
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <ErrorBoundary>
      <AppLayoutDirection>
        <NewMetaStore>
          <Admin
            title="iMaster Web"
            dashboard={DashboardPage}
            dataProvider={dataProvider}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            theme={themeProvider}
            customReducers={customReducers}
            customSagas={customSagas}
            customRoutes={customRoutes}
            layout={AppLayout}
            loginPage={LoginPage}
            disableTelemetry
          >
            <Resource key="appcore/websetting" name="appcore/websetting" />
          </Admin>
        </NewMetaStore>
      </AppLayoutDirection>
    </ErrorBoundary>
  );
};

export default App;
