import { TextField, withStyles } from '@material-ui/core';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useInput, useTranslate } from 'react-admin';
import compose from 'recompose/compose';
import dataProvider, { GET_CODING } from '../../core/dataProvider';
import { isEmpty, isEmptyObject } from '../../helper/data-helper';
import { showNotification } from '../../helper/general-function-helper';
import { getCodingPattern } from '../../helper/InputHelper';
import { getTreeParentFieldName } from '../../helper/MetaHelper';
import LoadingBox from '../LoadingBox';
import sanitizeRestProps from './sanitizeRestProps';

const styles = theme => ({
  input: {
    '&[disabled]': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },
  },

  error: {
    '& + p': {
      position: 'absolute',
      right: 0,
    },
  },

  codingContainer: {
    display: 'flex',
    flexDirection: 'row',
  },

  textField: {
    flexBasis: '100px',
  },

  field: {
    paddingLeft: 20,
  },

  fieldCaption: {
    fontSize: 11,
    color: 'rgba(0, 0, 0, 0.54)',
  },

  fieldValue: {
    fontSize: 12,
  },
});

const CodingInput = props => {
  const {
    field,
    resource,
    className,
    input,
    options,
    record,
    classes,
    disabled,
    metaData,
    locale,
    customChangeHandler,
    customError,
    visibleClass,
    viewVersion,
    ...rest
  } = props;

  const [codingValues, setCodingValues] = useState([]);
  const [maxLength, setMaxLength] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const {
    input: { onChange, onBlur, onFocus, value },
    meta: { touched, error },
  } = useInput(props);
  const translate = useTranslate();

  const idTreeParent = lodashGet(record, getTreeParentFieldName(metaData));

  useEffect(() => {
    if (!isEmptyObject(record) && !isEmpty(record.id)) {
      fetchCodingDefaultValue();
    } else {
      fetchCodingDefaultValue(idTreeParent);
    }
  }, [idTreeParent, viewVersion]);

  const fetchCodingDefaultValue = async rowId => {
    try {
      const params = record.id
        ? {
            id: record.id,
            editRequest: true,
          }
        : rowId
        ? {
            id: rowId,
          }
        : {};
      const { data } = await dataProvider(GET_CODING, resource, params);

      const resMaxLength = getCodingPattern(
        field.codingPattern,
        data ? data.currentlevel : lodashGet(record, 'currentLevel'),
      );

      setCodingValues(lodashGet(data, '__codingvalues'));
      setMaxLength(resMaxLength);
      setIsLoading(false);

      customChangeHandler(data);
    } catch (error) {
      showNotification(error, 'error');
      setIsLoading(false);
    }
  };

  const handleBlur = event => {
    props.onBlur(event.target.value);
    onBlur(event.target.value);
  };

  const handleFocus = event => {
    props.onFocus(event);
    onFocus(event);
  };

  const handleChange = event => {
    props.onChange(event.target.value);
    onChange(event.target.value);
  };

  const [firstItem, ...restItems] = codingValues;

  const hasError = !!(touched && error);
  return (
    <div className={classes.codingContainer}>
      <TextField
        {...rest}
        {...options}
        {...input}
        {...sanitizeRestProps(rest)}
        error={!!customError || hasError}
        helperText={
          customError
            ? customError
            : hasError
            ? translate(lodashGet(error, 'message', error))
            : undefined
        }
        required={field.required}
        margin="normal"
        className={`${visibleClass} ${className} ${classes.textField}`}
        InputProps={{
          classes: {
            error: classes.error,
            input: classes.input,
          },
          inputProps: {
            maxLength: maxLength,
          },
        }}
        onChange={handleChange}
        variant="outlined"
        disabled={disabled}
        label={lodashGet(firstItem, ['translatedCaption', locale])}
        onBlur={handleBlur}
        onFocus={handleFocus}
        value={value}
      />
      {isLoading && <LoadingBox size={25} />}
      {restItems &&
        restItems.map(field => {
          return (
            <div className={classes.field} key={field.name}>
              <div className={classes.fieldCaption}>
                {lodashGet(field.translatedCaption, locale)}
              </div>
              <div className={classes.fieldValue}>
                {lodashGet(field, 'defaultValue')}
              </div>
            </div>
          );
        })}
    </div>
  );
};

CodingInput.propTypes = {
  metaData: PropTypes.object.isRequired,
  className: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
  locale: PropTypes.string,
  customError: PropTypes.string,
};

CodingInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  customChangeHandler: () => {},
  options: {},
  step: 'any',
  textAlign: 'right',
};

export default compose(withStyles(styles, { withTheme: true }))(CodingInput);
