import React from 'react';
import { useInput } from 'react-admin';
import PropTypes from 'prop-types';
import { makeStyles, Theme } from '@material-ui/core';
import { convertDigitsToEnglish } from '../../helper/NumberHelper';

// TODO: Make TextFilterInput && NumberFilterInput a single Component

interface NumberFilterInputType {
  value: string | number | null;
  onChange: Function;
  source?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    border: 'none',
    outline: 'none',
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    '&::-webkit-inner-spin-button,&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
}));

const NumberFilterInput = (props: NumberFilterInputType) => {
  const { value, source } = props;
  const classes = useStyles();

  const {
    input: { onChange },
  } = useInput(props);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = convertDigitsToEnglish(event.target.value);

    onChange(numberValue);
    props.onChange(numberValue);
  };

  return (
    <div style={{ flexGrow: 1 }} data-test-field-name={source}>
      <input
        data-test-range-input
        value={value || ''}
        onChange={handleChange}
        className={classes.input}
        type="number"
      />
    </div>
  );
};

NumberFilterInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default NumberFilterInput;
