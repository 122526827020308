import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, useTranslate } from 'react-admin';
import { Dialog, DialogTitle } from '@material-ui/core';
import { clone } from '../../helper/data-helper';
import NewSubmittableForm from '../NewSubmittableForm';
import QuickCreateButtonToolbar from '../QuickCreateButtonToolbar';
import { isEmptyObject } from '../../helper/data-helper';

const FormLayoutTabInfoDialog = props => {
  const { onChange, tabList, tabIndex, onCloseDialog } = props;
  const translate = useTranslate();
  const tabInfo = tabList[tabIndex] ? clone(tabList[tabIndex]) : null;

  return (
    <Dialog open={!!tabInfo} onClose={onCloseDialog}>
      <DialogTitle>{translate('form.editTabInfo')}</DialogTitle>
      <NewSubmittableForm
        save={onChange}
        record={tabInfo}
        // FIXME: comment these lines because `QuickCreateButtonToolbar` interface changed. should be uncomment when refactor FormLayout.
        // toolbar={
        //   <QuickCreateButtonToolbar
        //     disableFullFormButton
        //     closeDialog={onCloseDialog}
        //   />
        // }
      >
        {!isEmptyObject(tabInfo) &&
          !isEmptyObject(tabInfo.translatedTitle) &&
          Object.keys(tabInfo.translatedTitle).map(lang => (
            <TextInput
              key={lang}
              source={`translatedTitle.${lang}`}
              label={`form.translatedTitle.${lang}`}
            />
          ))}
      </NewSubmittableForm>
    </Dialog>
  );
};

FormLayoutTabInfoDialog.propTypes = {
  tabList: PropTypes.array.isRequired,
  tabIndex: PropTypes.number,
  onCloseDialog: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FormLayoutTabInfoDialog;
