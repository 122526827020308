import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import lodashForEach from 'lodash/forEach';

import { getFieldsForDisplay, requestRelationData } from '../relation-panel.helper';
import { showImageDialogAction } from '../../../redux/showImage/action';
import TableRelationView from './table-relation.view';
import Pagination from '../../Pagination';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
  FormKeyMode,
  RecordKeyMode,
  RequestParametersInterface,
} from '../../../type/actor-setup';
import { PrintReport } from '../../print-report';
import type { TableRelationControllerInterface } from './table-relation.type';
import { apiRequestResultRelationHandler } from '../../../helper/crud-api.helper';
import { getAppSettings, setAppSettings } from '../../../helper/settings-helper';
import {
  CONFIG_LIST_PER_PAGE,
  CONFIG_LIST_SORT,
} from '../../../core/configProvider';

const TableRelationController: FC<TableRelationControllerInterface> = props => {
  const { relationPanelBaseProps } = props;
  const {
    relationData,
    relationDataCount,
    relationPermission,
    relationMetaData,
    settings,
    relationResource,
    childFieldName,
    parentFieldName,
    currentUrl,
    parentInfo,
    relationType,
    refreshSignal,
  } = relationPanelBaseProps;

  const { hasCreate, hasEdit, disabledFieldList } = relationPermission;
  const { defaultSelected, userSelected } = settings;
  const { parentResource, parentId } = parentInfo;
  const defaultView = relationMetaData?.defaultView;
  const isPrintView = defaultView === 'Print';

  const reduxDispatch = useDispatch();

  const parentRecord = actorGetActionValue(
    'record',
    `${parentResource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
  ) as Record<string, unknown>; // when code arrive here , 100% parent record exists!

  const fields = useMemo(() => {
    const relationFieldsForDisplay = getFieldsForDisplay(
      defaultSelected,
      userSelected,
      relationMetaData,
      disabledFieldList,
    );

    actorSetActionValue(
      'relationFieldsForDisplay',
      relationFieldsForDisplay?.map(field => field.name).join(','),
      { path: relationResource },
    );

    return relationFieldsForDisplay;
  }, [
    relationResource,
    refreshSignal,
    relationMetaData?.['config']?.moduleName,
    relationMetaData?.['config']?.moduleTableName,
  ]);

  /**
   * change sort in grid data object in actor and request data with new parameters
   * @function setSort
   * @param {string} field
   * @returns {void} void
   */
  const setSort = (field: string) => {
    let newSort = {};

    const prevSort = actorGetActionValue(
      'gridData',
      `${relationResource}.requestParameters.sort`,
    ) as RequestParametersInterface['sort'];

    if (prevSort?.field === field) {
      newSort = {
        field,
        order: prevSort.order === 'asc' ? 'desc' : 'asc',
      };
    } else {
      newSort = {
        field,
        order: 'desc',
      };
    }

    actorSetActionValue('gridData', newSort, {
      path: `${relationResource}.requestParameters.sort`,
    });
    setAppSettings({
      key: `${CONFIG_LIST_SORT}_${relationResource}`,
      value: newSort,
      forUser: true,
    });
    requestRelationData(
      relationResource,
      relationType,
      parentId,
      childFieldName,
      relationMetaData,
      undefined, // no custom request parameters
      apiRequestResultRelationHandler,
    );
  };

  /**
   * change selected ids in grid data object in actor a
   * @function onSelectCheckbox
   * @param {Array<string|number>} selectedIds
   * @returns {void} void
   */
  const onSelectCheckbox = (selectedIds: Array<string | number>): void => {
    const castedValues = selectedIds.map(id => id?.toString());
    actorDispatch('gridData', castedValues, {
      path: `${relationResource}.selectedIds`,
    });
  };

  // --------------- pagination ---------------
  /**
   * change page in grid data object in actor and request data with new parameters
   * @function setPage
   * @param {string} page
   * @returns {function}
   */
  const setPage = (page: number): void => {
    const prevPage = actorGetActionValue(
      'gridData',
      `${relationResource}.requestParameters.pagination.page`,
    ) as unknown as number;
    if (prevPage === page) return;

    actorSetActionValue('gridData', page, {
      path: `${relationResource}.requestParameters.pagination.page`,
    });

    requestRelationData(
      relationResource,
      relationType,
      parentId,
      childFieldName,
      relationMetaData,
      undefined, // no custom request parameters
      apiRequestResultRelationHandler,
    );
  };

  /**
   * change perPage in grid data object in actor and request data with new parameters
   * @function serPerPage
   * @param {number} perPage
   * @returns {function}
   */
  const setPerPage = (perPage: number): void => {
    const prevPerPage = actorGetActionValue(
      'gridData',
      `${relationResource}.requestParameters.pagination.perPage`,
    ) as unknown as number;
    if (prevPerPage === perPage) return;

    actorSetActionValue('gridData', 1, {
      path: `${relationResource}.requestParameters.pagination.page`,
    });
    actorSetActionValue('gridData', perPage, {
      path: `${relationResource}.requestParameters.pagination.perPage`,
    });
    setAppSettings({
      key: `${CONFIG_LIST_PER_PAGE}_${relationResource}`,
      value: perPage,
      forUser: true,
    });
    requestRelationData(
      relationResource,
      relationType,
      parentId,
      childFieldName,
      relationMetaData,
      undefined, // no custom request parameters
      apiRequestResultRelationHandler,
    );
  };

  /**
   * change filter in grid data object in actor and request data with new parameters
   * @function setFilters
   * @param {number} newFilters
   * @returns {void} void
   */
  const setFilters = (newFilters: Record<string, unknown>): void => {
    actorSetActionValue('gridData', newFilters, {
      path: `${relationResource}.requestParameters.${
        relationType === 'report' ? 'searchFilters' : 'filter'
      }`,
      replaceAll: true,
    });

    requestRelationData(
      relationResource,
      relationType,
      parentId,
      childFieldName,
      relationMetaData,
      undefined, // no custom request parameters
      apiRequestResultRelationHandler,
    );
  };

  // --------------- table handlers ---------------
  /**
   * //TODO should complete
   * @function onRowClick
   */
  const onRowClick = row => {
    console.log('row: ', row);
  };

  /**
   * @function showImageDialog
   * @param {{url,isGetFileMode}} data
   */
  const showImageDialog = (data: Record<string, unknown>): void => {
    reduxDispatch(showImageDialogAction(data));
  };

  /**
   * //TODO should complete
   * @function addToFilterRequestList
   */
  const addToFilterRequestList = (data: Record<string, unknown>): void => {
    console.log('field: ', data.field);
  };

  const minimizedRelation = { parentFieldName, childFieldName };

  const currentRequestParams = actorGetActionValue(
    'gridData',
    `${relationResource}.requestParameters`,
  ) as unknown as RequestParametersInterface;
  const perPagePagination =
    (getAppSettings(`${CONFIG_LIST_PER_PAGE}_${relationResource}`, true)
      .value as number) || null;
  /**
   * refresh relation data
   * @function refreshRelation
   * @returns {void}
   */
  const refreshRelation = () => {
    requestRelationData(
      relationResource,
      relationType,
      parentId,
      childFieldName,
      relationMetaData,
      undefined, // no custom request parameters
      apiRequestResultRelationHandler,
    );
  };

  if (isPrintView) {
    const filterValues = {};

    lodashForEach(currentRequestParams?.filter, filter => {
      if (filter?.[2]) {
        filterValues[filter[0]] = filter;
      }
    });

    return (
      <div>
        <PrintReport metaData={relationMetaData} filterValues={filterValues} />
      </div>
    );
  }

  return (
    <div>
      <>
        <TableRelationView
          data={relationData}
          fields={fields}
          currentUrl={currentUrl}
          hasEdit={hasEdit}
          hasCreate={hasCreate}
          quickEditRowCallback={refreshRelation}
          onSelectCheckbox={onSelectCheckbox}
          onRowClick={onRowClick}
          parentInfo={parentInfo}
          parentRecord={parentRecord}
          setSort={setSort}
          sort={settings.userSort}
          metaData={relationMetaData}
          setFilters={setFilters}
          showImageDialog={showImageDialog}
          addToFilterRequestList={addToFilterRequestList}
          quickEditButton={hasEdit} // TODO: check carefully
          resource={relationResource}
          relation={minimizedRelation}
          refreshRelation={refreshRelation}
          isReport={relationType == 'report'}
        />

        {fields.length > 0 && (
          <Pagination
            page={currentRequestParams?.pagination.page ?? 1}
            perPage={perPagePagination ?? 100}
            setPage={setPage}
            setPerPage={setPerPage}
            total={relationDataCount}
            isRelation
          />
        )}
      </>
    </div>
  );
};

export default TableRelationController;
