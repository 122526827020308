import { isEmptyObject } from '../helper/data-helper';
import {
  actorOnDispatch,
  actorGetActionValue,
  actorDispatch,
} from '../type/actor-setup';
import dataProvider, {
  CHANGE_PASSWORD,
  GET_AUTHORITY_DELEGATION,
  PUT_AUTHORITY_DELEGATION,
  UPDATE_PROFILE,
} from '../core/dataProvider';
import { showNotification } from '../helper/general-function-helper';

actorOnDispatch('changeProfile', async details => {
  const { data, successCallback } = details;

  if (isEmptyObject(data)) return;
  try {
    await dataProvider(UPDATE_PROFILE, null, {
      data,
    });

    successCallback();
  } catch (error) {
    console.log('profile-api.ts:17 >> error', { error });
  }
});

actorOnDispatch('changePassword', async action => {
  const {
    successCallback,
    failureCallback,
    data: { params, props },
  } = action;

  if (isEmptyObject(params)) return;

  const { value } = actorGetActionValue('resources')!.current;
  try {
    await dataProvider(CHANGE_PASSWORD, value, {
      params,
    });
    successCallback(props);
  } catch (error) {
    failureCallback(error);
  }
});

actorOnDispatch('putPathDelegation', async detail => {
  const { resource, successCallback } = detail;
  await dataProvider(PUT_AUTHORITY_DELEGATION, resource, {})
    .then(() => {
      successCallback();
    })
    .catch(error => {
      showNotification(error, 'error');
    });
});

actorOnDispatch('getDelegationData', async () => {
  await dataProvider(GET_AUTHORITY_DELEGATION, null, {}).then(({ data }) => {
    actorDispatch('delegationData', data);
  });
});
