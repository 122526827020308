import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import Checkbox from '@material-ui/core/Checkbox/index';
import RefreshIcon from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip/index';
import Box from '@material-ui/core/Box';
import { Hidden, IconButton } from '@material-ui/core';
import { MailContentHeaderViewInterface } from './mail-content-header.type';
import { useStyles } from './mail-content-header.style';
import clsx from 'classnames';
import Pagination from '../../../Pagination';
import { isEmptyObject } from '../../../../helper/data-helper';
import { CheckedMailAction } from './checked-mail-action';
import { MoreOptions } from './more-options';
import { AppSearchBar } from '../../../app-searchbar';
import { getTotalCountByPagination } from '../../mail-section.helper';

const MailContentHeaderView = (
  props: MailContentHeaderViewInterface,
): ReactElement => {
  const {
    mailActionsHandler,
    mailData,
    checkedMails,
    onHandleMasterCheckbox,
    loading,
    pagination,
    onChangePageHandler,
    onRefreshMailData,
    onStarClick,
    onImportantClick,
    onMarkUnReadClick,
    shouldMarkAsRead,
    onArchiveClick,
    onUnArchiveClick,
    handleSearch,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Checkbox
          color="primary"
          className={classes.checkboxRoot}
          indeterminate={
            checkedMails.length > 0 && checkedMails.length < mailData.length
          }
          checked={mailData.length > 0 && checkedMails.length === mailData.length}
          onChange={onHandleMasterCheckbox}
          data-test="mail-master-checkbox"
        />

        {checkedMails.length > 0 && (
          <>
            <CheckedMailAction
              mailActionsHandler={mailActionsHandler}
              shouldMarkAsRead={shouldMarkAsRead}
              onMarkUnReadClick={onMarkUnReadClick}
              onArchiveClick={onArchiveClick}
              onUnArchiveClick={onUnArchiveClick}
              checkedMails={checkedMails}
            />{' '}
            <MoreOptions
              onImportantClick={onImportantClick}
              onMarkUnReadClick={onMarkUnReadClick}
              onStarClick={onStarClick}
            />{' '}
          </>
        )}
        {isEmptyObject(checkedMails) && (
          <Tooltip title={translate('grid.refresh')}>
            <Box component="span">
              <IconButton>
                <RefreshIcon
                  className={clsx(classes.pointer, classes.iconBlock)}
                  onClick={onRefreshMailData}
                  data-test="mail-refresh-icon"
                />
              </IconButton>
            </Box>
          </Tooltip>
        )}
      </Box>

      <AppSearchBar
        onChange={handleSearch}
        placeholder={translate('general.search')}
        data-test="mail-search-bar"
      />
      {/* @ts-ignore */}
      <Hidden xsDown>
        {!isEmptyObject(mailData) && (
          <Pagination
            total={getTotalCountByPagination(pagination)}
            page={pagination.currentPage}
            perPage={pagination.perPage}
            rowsPerPageOptions={[10]}
            setPage={onChangePageHandler}
            setPerPage={() => {}}
            isLoading={loading}
            isCompactMode={false}
          />
        )}
      </Hidden>
    </>
  );
};

export default MailContentHeaderView;
