import { ReactElement } from 'react';
import { useTranslate, useLocale } from 'react-admin';
import momentJalaali from 'moment-jalaali';
import clsx from 'classnames';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Avatar } from '@material-ui/core';
import DocIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import { MessageItemViewInterface } from './message-item.type';
import { useStyles } from './message-item.style';
import { getFileAddress } from '../messages-list.helper';
import { MessageInterface } from '../../../..';
import {
  API_URL,
  API_VERSION,
  getValue,
  SESSION_ID,
} from '../../../../../../core/configProvider';
import { isEmpty } from '../../../../../../helper/data-helper';
import { isImageFile } from '../../../../../dynamic-input/file-input/file-input.helper';
import { formatTextBreakLines } from './message-item.helper';

const MessageItemView = (props: MessageItemViewInterface): ReactElement => {
  const {
    item,
    isSender,
    onReplyClick,
    onReplyMessage,
    contextMenu,
    handleCloseContextMenu,
    handleContextMenu,
    onMoveToRepliedMessage,
    handleOpenDeleteConfirmDialog,
    onEditClick,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const locale = useLocale();
  momentJalaali.locale(locale);

  const apiUrl = getValue(API_URL);
  const sessionId = getValue(SESSION_ID);
  const apiVersion = getValue(API_VERSION);
  const fileAddress = getFileAddress(item.fileurl) ?? '';

  /**
   * @function getRepliedMessageContent
   * @param {  MessageInterface | undefined } item
   * @returns  { ReactElement }
   */
  const getRepliedMessageContent = (
    item: MessageInterface | undefined,
  ): ReactElement => {
    if (item == null) return <></>;
    const fileAddress = getFileAddress(item.replyfileurl) ?? '';

    if (!isEmpty(item.replychattext) && isEmpty(fileAddress)) {
      return (
        <Box
          onClick={onMoveToRepliedMessage}
          className={clsx(classes.repliedContent, 'pointer')}
          component="p"
          fontSize={13}
        >
          <Box mb={1}>{item?.replypersonname}</Box>
          {item.replychattext}
        </Box>
      );
    }

    if (!isEmpty(item.replychattext) && fileAddress && !isImageFile(fileAddress)) {
      return (
        <Box
          onClick={onMoveToRepliedMessage}
          className={clsx(classes.repliedContent, 'pointer')}
          display="flex"
          flexWrap="nowrap"
        >
          <Box
            component="span"
            className="pointer"
            display="flex"
            alignItems="center"
            flexWrap="nowrap"
          >
            <a className={classes.docIcon} style={{ marginLeft: '1rem' }}>
              <DocIcon style={{ color: '#fff' }} />
            </a>
            <Box component="span" display="inline-block" mr={2} fontSize={13}>
              <Box mb={1}>{item?.replypersonname}</Box>
              <Box>{item.replychattext}</Box>
            </Box>
          </Box>
        </Box>
      );
    }
    if (!isEmpty(item.replychattext) && fileAddress && isImageFile(fileAddress)) {
      return (
        <Box
          onClick={onMoveToRepliedMessage}
          className={clsx(classes.repliedContent, 'pointer')}
          display="flex"
          flexWrap="nowrap"
        >
          <a className={clsx(classes.repliedMediaWrapper)}>
            <img alt="" src={`${apiUrl}/${fileAddress}`} />
          </a>
          <Box component="span" fontSize={12} mb={1} ml={1}>
            {item?.replypersonname}
          </Box>
        </Box>
      );
    }
    return <Box />;
  };

  /**
   * @function getRepliedMessage
   * @param { MessageInterface } item
   * @returns { ReactElement }
   */
  const getRepliedMessage = (item: MessageInterface): ReactElement => {
    if (item == null) return <></>;

    if (!isEmpty(item.chattext) && isEmpty(fileAddress)) {
      return (
        <Box mb={1}>
          {getRepliedMessageContent(item)}
          <Box component="span" fontSize={13}>
            {formatTextBreakLines(item.chattext)}
          </Box>
        </Box>
      );
    }

    if (!isEmpty(item.chattext) && fileAddress && !isImageFile(fileAddress)) {
      return (
        <Box mb={1}>
          <Box style={{ fontStyle: 'italic' }} component="p" fontSize={13}>
            {getRepliedMessageContent(item)}
          </Box>
          <Box display="flex" flexWrap="nowrap">
            <Box
              component="span"
              className="pointer"
              display="flex"
              flexWrap="nowrap"
              alignItems="center"
            >
              <a
                href={`${apiUrl}/${fileAddress}`}
                download={`${apiUrl}/${fileAddress}`}
                target="_blank"
                className={classes.docIcon}
                style={{ marginLeft: '1rem' }}
              >
                <DocIcon style={{ color: '#fff' }} />
              </a>
              <Box component="span" display="inline-block" fontSize={13}>
                <Box>{formatTextBreakLines(item.chattext)}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    } else if (!isEmpty(item.chattext) && fileAddress && isImageFile(fileAddress)) {
      return (
        <Box mb={1}>
          <Box style={{ fontStyle: 'italic' }} component="p" fontSize={13}>
            {getRepliedMessageContent(item)}
          </Box>
          <a
            className={classes.mediaWrapper}
            href={`${apiUrl}/${fileAddress}`}
            target="_blank"
          >
            <img alt="" src={`${apiUrl}/${fileAddress}`} />
          </a>
        </Box>
      );
    }
    return <Box />;
  };

  /**
   * @function getMessage
   * @param { MessageInterface } item
   * @returns { ReactElement }
   */
  const getMessage = (item: MessageInterface): ReactElement => {
    if (item == null) return <></>;

    if (!isEmpty(item.replyofchat_id)) {
      return getRepliedMessage(item);
    }

    if (!isEmpty(item.chattext) && isEmpty(fileAddress)) {
      return (
        <Box component="p" fontSize={13} m={0}>
          {formatTextBreakLines(item.chattext)}
        </Box>
      );
    }

    if (!isEmpty(item.chattext) && fileAddress && !isImageFile(fileAddress)) {
      return (
        <Box display="flex" flexWrap="nowrap">
          <Box
            component="span"
            className="pointer"
            display="flex"
            alignItems="center"
            flexWrap="nowrap"
          >
            <a
              href={`${apiUrl}/${fileAddress}`}
              download={`${apiUrl}/${fileAddress}`}
              target="_blank"
              className={classes.docIcon}
              style={{
                marginLeft: '1rem',
              }}
            >
              <DocIcon style={{ color: '#fff' }} />
            </a>
            <Box component="span" display="inline-block" m={0} mr={2} fontSize={13}>
              <Box>{item.chattext}</Box>
            </Box>
          </Box>
        </Box>
      );
    }
    if (!isEmpty(item.chattext) && fileAddress && isImageFile(fileAddress)) {
      return (
        <>
          <a
            className={classes.mediaWrapper}
            href={`${apiUrl}/${fileAddress}`}
            target="_blank"
          >
            <img alt="" src={`${apiUrl}/${fileAddress}`} />
          </a>
          <Box mt={2} fontSize={13}>
            {item.chattext}
          </Box>
        </>
      );
    }

    return <Box />;
  };

  /**
   * it checks if date is equal to today then just shows time
   * else it shows date-time
   * @function getDate
   * @param { string } date
   * @returns { string }
   */
  const getDate = (date: string): string => {
    const startOfToday = momentJalaali().startOf('day');
    const startOfDate = momentJalaali(date).startOf('day');
    const daysDiff = startOfDate.diff(startOfToday, 'days');

    // its Today
    if (Math.abs(daysDiff) === 0) {
      return momentJalaali(date).format('hh:mm');
    } else {
      {
        if (locale === 'fa') {
          return momentJalaali(date).format('HH:mm,  jYY-jMM-jDD ');
        }

        return momentJalaali(date).format('YYYY-MM-DD HH:mm');
      }
    }
  };

  return (
    <Box
      id={`messageId-${item?.chat_id}`}
      className={clsx(classes.messageItemRoot, isSender ? 'left' : 'right')}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      ml={1}
    >
      <Avatar
        className={classes.profilePic}
        src={`${apiUrl}/oauth2/${sessionId}/${apiVersion}${item?.personimage}`}
        alt={item?.personname}
      />

      <Box
        className={classes.messageChatRoot}
        onDoubleClick={() => onReplyMessage(item)}
        onContextMenu={handleContextMenu}
      >
        <Box display="flex" flexDirection="row" justifyContent="flex-start">
          <Box className={classes.messageChat} ml="auto">
            <Box className="message-info">
              {getMessage(item)}
              <Box
                display="flex"
                justifyContent="flex-start"
                className={clsx(classes.messageTime, 'message-time')}
                component="span"
                mt={1.5}
              >
                {item.isedited && (
                  <EditIcon style={{ fontSize: '.9rem', marginLeft: '.4rem' }} />
                )}
                {getDate(item.chatdate)}
                {isSender &&
                  (item.isseen ? (
                    <DoneAllIcon
                      style={{ fontSize: '1.1rem', marginRight: '.6rem' }}
                    />
                  ) : (
                    <DoneIcon style={{ fontSize: '1.1rem', marginRight: '.6rem' }} />
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={classes.arrowIcon}>
        <Menu
          open={contextMenu !== null}
          onClose={handleCloseContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={onReplyClick}>{translate('chat.reply')}</MenuItem>
          {isSender && (
            <MenuItem onClick={onEditClick}>{translate('chat.edit')}</MenuItem>
          )}
          <MenuItem onClick={handleOpenDeleteConfirmDialog}>
            {translate('chat.deleteMessage')}
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default MessageItemView;
