import React, { useContext, useEffect, useState } from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MetaProvider from '../container/MetaProvider';
import MultipleReportListContainer from '../container/list/MultipleReportListContainer';

import LoadingBox from '../component/LoadingBox';
import CheckResourceReady from '../container/CheckResourceReady';
import DynamicList from '../container/DynamicList';
import { NewMetaContext } from '../container/NewMetaContext';
import { PrintReport } from '../component/print-report';
import { isEmpty } from '../helper/data-helper';
import NotFound from '../component/NotFound';

const MultipleReportListPage = props => {
  const {
    classes,
    resource,
    showDevExtremeTopFilter,
    showDevExtremeGrouping,
    ...rest
  } = props;

  const [metaDataError, setMetaDataError] = useState(null);
  const { getMeta } = useContext(NewMetaContext);

  useEffect(() => {
    setMetaDataError(null);
  }, [resource]);

  const failureGetMeta = message => {
    setMetaDataError(message?.split?.('^')[0]);
  };

  if (!isEmpty(metaDataError)) {
    return <NotFound title={metaDataError} hideBackButton />;
  }

  const metaData = getMeta(resource, null, failureGetMeta);

  if (!metaData && isEmpty(metaDataError)) {
    return <LoadingBox />;
  }

  const defaultTabIndex = lodashGet(props, 'match.params.tabIndex', 0);

  const defaultView = metaData?.defaultView;
  const isPrintView = defaultView === 'Print';

  return (
    <MetaProvider resourceName={resource}>
      <CheckResourceReady resourceName={resource}>
        {isPrintView ? (
          <DynamicList
            {...rest}
            resource={resource}
            metaData={metaData}
            viewComponent={<PrintReport />}
            hasList={true}
            hasCreate={false}
            hasEdit={false}
            hasShow={false}
            hasDelete={false}
            enableSelection={false}
            basePath={`/${resource}`}
            slideComponent={null}
            isSlideOpen={false}
            onRootClick={undefined}
            isTopFilterOpen={false}
            isGroupingOpen={false}
            quickEditRowCallback={null}
            data-test-is-slide-open={false}
            onRowClick={undefined}
            isSelectedItemsCount={false}
            perPage={1000000}
            pagination={null}
            isGroupingEnable={false}
            isColumnChoiceEnable={false}
            fields={false}
            settingToolbarComponent={null}
          />
        ) : (
          <MultipleReportListContainer
            {...rest}
            resource={resource}
            defaultTabIndex={defaultTabIndex}
            showDevExtremeTopFilter={showDevExtremeTopFilter}
            showDevExtremeGrouping={showDevExtremeGrouping}
          />
        )}
      </CheckResourceReady>
    </MetaProvider>
  );
};

MultipleReportListPage.propTypes = {
  resource: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { match }) => {
  const { reportId } = match.params;
  return {
    resource: `report/${reportId.toLowerCase()}`,
    showDevExtremeTopFilter: state.listPage.showDevExtremeTopFilter,
    showDevExtremeGrouping: state.listPage.showDevExtremeGrouping,
  };
};

export default connect(mapStateToProps)(MultipleReportListPage);
