import { useEffect, useMemo, useState, useRef } from 'react';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';
import {
  DialogContent,
  Typography,
  Icon,
  Dialog,
  CircularProgress,
} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import MenuIcon from '@material-ui/icons/Menu';
import grey from '@material-ui/core/colors/grey';

import {
  USER_ID,
  API_NAME,
  getValue,
  CONFIG_IS_PROFILE_EDIT_ENABLED,
  SESSION_ID,
} from '../core/configProvider';

import ProfileAvatar from '../component/ProfileAvatar';
import UploadFileContainer from './UploadFileContainer';
import {
  findOneAction as getProfileAction,
  updateProfileAction,
} from '../redux/profile/action';
import TabParent from '../component/form-component-old/TabParent';
import TabChild from '../component/form-component-old/TabChild';
import DynamicInput from '../component/dynamicInput/DynamicInput';
import NewSubmittableForm from '../component/NewSubmittableForm';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CustomForm, FormController } from '../component/form';
import QuickCreateButtonToolbar from '../component/QuickCreateButtonToolbar';
import { setResource, removeLastResource } from '../helper/resource-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  FormKeyMode,
} from '../type/actor-setup';
import { ProfileForm } from '../component/form/profile-form';
import { CustomTheme } from '../core/themeProvider';
import ProfileSettingItemView from '../component/profile/ProfileSettingItemView';
import { getSessionIdInUrl } from '../helper/UrlHelper';
import { ChangePasswordForm } from '../component/form/change-password-form';
import { changePasswordFields } from '../component/form/change-password-form/change-password-form.helper';
import { isEmpty } from '../helper/data-helper';

interface StyleProps {
  isDrawerOpen: boolean;
}

const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  simpleForm: {
    height: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexGrow: 1,

    '& > div.fieldContainer ': {
      overflowY: 'auto',
      flexGrow: 1,
      height: 1,
      '& > div': {
        height: '100%',
      },
    },
  },

  toolbarContainer: {
    padding: ({ isDrawerOpen }) => (isDrawerOpen ? '5px 15px 0' : '5px 0'),
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
  },

  avatarContainer: {
    margin: '0 15px',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '50%',
    width: 48,
    height: 48,
    cursor: 'pointer',
    '&:hover div:nth-child(2)': {
      transform: 'translate3d(0, 0, 0)',
    },
  },

  avatarIcon: {
    fontSize: 20,
    color: '#fafafa',
  },

  uploadImage: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    top: 'auto',
    width: 48,
    height: 48,
    backgroundColor: 'hsla(0, 0%, 0%, 0.28)',
    textAlign: 'center',
    cursor: 'pointer',
    transform: 'translate3d(0, 60px, 0)',
    transition: 'transform 0.28s cubic-bezier(0.4, 0, 0.2, 1)',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  profile: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    flexBasis: '50%',
  },

  dialogVisible: {
    overflowY: 'visible',
    padding: 0,
  },

  profileDisplayName: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  warehouseDisplayName: {
    fontSize: 10,
    fontWeight: 'bold',
    marginTop: '.4rem',
  },

  profileUserCompanies: {
    fontSize: 10,
  },
  topBurgerMenu: {
    cursor: 'pointer',
    padding: '.5rem',
  },

  burgerMenu: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem 0 0 0',
    cursor: 'pointer',
    padding: '1rem',
    '&:hover': {
      backgroundColor: grey[200],
    },
  },
}));

const profileFieldList = [
  {
    name: 'firstName',
    dataType: {
      simple: 'text',
      erp: 'text',
    },
    required: true,
  },
  {
    name: 'lastName',
    dataType: {
      simple: 'text',
      erp: 'text',
    },
    required: true,
  },
  {
    name: 'email',
    dataType: {
      simple: 'text',
      erp: 'text',
    },
  },
  {
    name: 'address',
    dataType: {
      simple: 'text',
      erp: 'text',
    },
  },
  {
    name: 'nationalCode',
    dataType: {
      simple: 'number',
      erp: 'number',
    },
  },
  {
    name: 'phoneNumber',
    dataType: {
      simple: 'number',
      erp: 'number',
    },
  },
  {
    name: 'mobileNumber',
    dataType: {
      simple: 'number',
      erp: 'number',
    },
  },
  {
    name: 'accountingSoftware',
    dataType: {
      simple: 'boolean',
      erp: 'boolean',
    },
  },
];

const HeaderProfileContainer = (props: any) => {
  const {
    width,
    profileData: profileDataInRedux,
    profileIsLoading,
    translate,
    profileUpdateLoading,
    getProfile,
    updateProfile,
    rest,
  } = props;

  const isSessionIdExistInUrl = getSessionIdInUrl();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const classes = useStyles({ isDrawerOpen });

  const globalParameters = actorGetActionValue('globalParameters');
  const fileUploadLimitMB = lodashGet(globalParameters, 'fileUploadLimitMB', null);
  const activeTab = useRef<string | unknown>('profile');
  const [isOpen, setIsOpen] = useState(false);
  const [dummyCounter, setDummyCounter] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileData, setProfileData] = useState(
    isSessionIdExistInUrl ? actorGetActionValue('profile') : profileDataInRedux,
  );

  useEffect(() => {
    const userSessionId = getValue(SESSION_ID);

    if (!isEmpty(userSessionId) && !isSessionIdExistInUrl && !profileDataInRedux) {
      getProfile();
      actorDispatch('getDelegationData', null);
    }

    actorOnDispatch('loading', loading => {
      setIsLoading(loading?.[`account/${apiName}/${activeTab.current}`]);
    });

    actorOnDispatch('profile', profile => {
      if (profile && isSessionIdExistInUrl) {
        setProfileData(profile);
      }
    });
  }, []);

  useEffect(() => {
    if (profileDataInRedux && !isSessionIdExistInUrl) {
      setProfileData(profileDataInRedux);
    }
  }, [isSessionIdExistInUrl, profileDataInRedux]);

  const handleNewProfileCreate = () => {
    setIsOpen(false);
    setDummyCounter(dummyCounter + 1);
  };

  const currentUserId = getValue(USER_ID);
  const apiName = getValue(API_NAME);
  const isEditEnabled = getValue(CONFIG_IS_PROFILE_EDIT_ENABLED);
  const resource = useMemo(
    () => (isOpen ? actorGetActionValue('resources')!.current.value : ''),
    [isOpen],
  );

  const openDialog = () => {
    setResource(`account/${apiName}/${activeTab.current}`, FormKeyMode.PROFILE);
    setIsOpen(true);
  };

  const closeDialog = () => {
    actorDispatch('remove', {
      resource: `account/${apiName}/${activeTab.current}`,
      type: FormKeyMode.PROFILE,
    });
    activeTab.current = 'profile';
    setIsOpen(false);
  };

  const handleProfileSave = data => {
    updateProfile({ data });

    closeDialog();
  };

  useEffect(() => {
    actorDispatch('handleCloseProfileFormDialog', {
      handleCloseProfileFormDialog: closeDialog,
    });
  }, []);

  const profileSettingFields: any = [
    {
      caption: 'انبار کاربر',
      dataType: {
        erp: 'dropdown',
        sql: 'bigint',
        simple: 'number',
        id: 1,
        defaultOperator: 'Equals',
      },
      disabled: false,
      javaScriptFormula: '',
      dropdown: {
        columns: [
          {
            isHidden: false,
            width: 100,
            priority: 0,
            title: 'warehouse_id',
            format: '',
            dataType: {
              erp: 'bigint',
              sql: 'bigint',
              simple: 'number',
              id: 127,
              defaultOperator: 'Equals',
            },
            translatedTitle: {
              fa: 'انبار',
              en: 'WareHouse',
              ar: 'المخزن المتطلب',
            },
          },
          {
            isHidden: false,
            width: 250,
            priority: 1,
            title: 'warehousetitle',
            format: '',
            dataType: {
              erp: 'string',
              sql: 'nvarchar(max)',
              simple: 'string',
              id: 167,
              defaultOperator: 'Contains',
            },
            translatedTitle: {
              fa: 'نام انبار',
              en: 'نام انبار',
              ar: 'نام انبار',
            },
          },
        ],
        displayMember: 'warehousetitle',
        displayMember2: null,
        forceTreeLevel: false,
        id: '46a93036-0de8-4f4e-bc71-b8b9bab0f0e9',
        moduleName: 'task',
        parameters: [],
        remoteSearch: false,
        translatedComment: null,
        translatedTitle: {
          fa: 'انبار/شعبه کاربر جاری',
          en: 'Warehouse/Branch current user',
          ar: 'المستودع / فرع المستخدم الحالي',
        },
        type: 'Simple',
        uniqueId: '46a93036-0de8-4f4e-bc71-b8b9bab0f0e9',
        valueMember: 'warehouse_id',
        name: 'warehouse_id',
        title: 'warehousetitle',
      },
      hidden: false,
      id: 0,
      name: 'currentWareHouseID',
      translatedCaption: {
        fa: 'انبار/شعبه کاربر جاری',
        en: 'Warehouse/Branch current user',
        ar: 'المستودع / فرع المستخدم الحالي',
      },
      relatedName: '__currentWareHouseID_value',
      required: true,
      values: null,
      resource: '',
    },
    {
      caption: 'تفویض اختیار',
      dataType: {
        erp: 'delegationDropdown',
        sql: 'bigint',
        simple: 'number',
        id: 1,
        defaultOperator: 'Equals',
      },
      disabled: false,
      javaScriptFormula: '',
      dropdown: {
        columns: null,
        displayMember: 'caption',
        displayMember2: null,
        forceTreeLevel: false,
        id: '46a93036-0de8-4f4e-bc71-b8b9bab0f0e9',
        moduleName: 'task',
        parameters: [],
        remoteSearch: false,
        translatedComment: null,
        translatedTitle: {
          fa: 'تفویض اختیار',
          en: 'Delegation of authority',
          ar: 'تفويض السلطة',
        },
        type: 'Simple',
        uniqueId: '46a93036-0de8-4f4e-bc71-b8b9bab0f0e9',
        valueMember: 'path',
        name: 'authorityDelegation',
        title: 'caption',
      },
      hidden: false,
      id: 0,
      name: 'authorityDelegation',
      translatedCaption: {
        fa: 'تفویض اختیار',
        en: 'Delegation of authority',
        ar: 'تفويض السلطة',
      },
      relatedName: '__AuthorityDelegation_value',
      required: false,
      values: null,
      resource: '',
    },
  ];

  /**
   * @function toggleDrawer
   * @returns {void}
   */
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    actorDispatch('isDrawerOpen', !isDrawerOpen);
  };

  const resetPasswordFields = useMemo(() => changePasswordFields(translate), []);

  const onTabChange = (
    tab: Record<string, Record<string, unknown>>,
    tabIndex: number,
  ): void => {
    const name = tab.props?.name ?? '';
    removeLastResource(FormKeyMode.PROFILE);
    setResource(`account/${apiName}/${name}`, FormKeyMode.PROFILE);
    activeTab.current = name;
  };

  return (
    <>
      <div className={classes.toolbarContainer}>
        <div className={classes.avatarContainer} id="profileAvatar">
          <ProfileAvatar
            userId={currentUserId}
            dummyCounter={dummyCounter}
            bigAvatar
            {...rest}
          />
          <div
            className={classes.uploadImage}
            onClick={openDialog}
            id="settingProfile"
          >
            <Icon className={classes.avatarIcon}>settings</Icon>
          </div>
        </div>

        {(profileIsLoading || profileUpdateLoading) && <CircularProgress />}

        {isDrawerOpen && profileData && (
          <div className={classes.profile} id="profileSidebar">
            <Typography
              variant="caption"
              noWrap
              className={classes.profileDisplayName}
            >
              {profileData.displayName ||
                (profileData.firstName || profileData.lastName
                  ? `${profileData.firstName} ${profileData.lastName}`
                  : profileData.mobileNumber)}
            </Typography>

            {/* <Typography variant="caption" className={classes.profileUserCompanies}>
              {profileData.userCompanies && profileData.userCompanies.length
                ? profileData.userCompanies.map(item => item.title).join(', ')
                : profileData.shopName}
            </Typography> */}
            <ProfileSettingItemView className={classes.warehouseDisplayName} />
          </div>
        )}

        {isDrawerOpen && (
          <div
            onClick={toggleDrawer}
            className={classes.topBurgerMenu}
            id="toggleMenuIcon"
          >
            <MenuIcon fontSize="large" />
          </div>
        )}

        <Dialog
          open={isOpen}
          onClose={closeDialog}
          maxWidth={width}
          aria-labelledby="simple-dialog-title"
          classes={{ paper: classes.dialogVisible }}
        >
          <DialogContent classes={{ root: classes.dialogVisible }}>
            <TabParent onChange={onTabChange}>
              {isEditEnabled && (
                <TabChild label={translate('profile.editProfile')}>
                  <NewSubmittableForm
                    className={classes.simpleForm}
                    redirect={false}
                    save={handleProfileSave}
                    record={profileData}
                    validationFieldList={profileFieldList}
                  >
                    {profileFieldList.map(field => (
                      <DynamicInput
                        key={field.name}
                        source={field.name}
                        field={field}
                        label={translate(`profile.${field.name}`)}
                        disableDropdownQuickCreate
                        disableDropdownSearchPopup
                        disabled={profileUpdateLoading}
                        {...rest}
                      />
                    ))}
                  </NewSubmittableForm>
                </TabChild>
              )}
              <TabChild
                label={translate('profile.profileSettings')}
                name={'profile'}
              >
                <FormController formName="profileForm" {...rest}>
                  <CustomForm
                    Toolbar={
                      <QuickCreateButtonToolbar
                        closeDialog={closeDialog}
                        disableFullFormButton={true}
                        justSaveAndClose={true}
                        isFromDropdown={false}
                        resource={resource}
                        isLoading={isLoading}
                      />
                    }
                    isBottomToolbar
                  >
                    <ProfileForm fields={profileSettingFields} />
                  </CustomForm>
                </FormController>
              </TabChild>

              <TabChild
                label={translate('menu.uploadProfilePicture')}
                name={'profile'}
              >
                <div>
                  <UploadFileContainer
                    onChange={handleNewProfileCreate}
                    resource={`account/${apiName}/profile`}
                    fileUploadLimitMB={fileUploadLimitMB}
                  />
                </div>
              </TabChild>

              <TabChild
                label={translate('menu.changePassword')}
                name={'changepassword'}
              >
                <FormController formName="changePassword" {...rest}>
                  <CustomForm
                    Toolbar={
                      <QuickCreateButtonToolbar
                        closeDialog={closeDialog}
                        disableFullFormButton={true}
                        justSaveAndClose={true}
                        isFromDropdown={false}
                        resource={resource}
                        isLoading={isLoading}
                      />
                    }
                    isBottomToolbar
                  >
                    <ChangePasswordForm fields={resetPasswordFields} />
                  </CustomForm>
                </FormController>
              </TabChild>
            </TabParent>
          </DialogContent>
        </Dialog>
      </div>
      {!isDrawerOpen && (
        <div
          data-test="toggleDrawer"
          onClick={toggleDrawer}
          className={classes.burgerMenu}
        >
          <MenuIcon />
        </div>
      )}
    </>
  );
};

HeaderProfileContainer.propTypes = {
  profileData: PropTypes.object,
  profileSettingFields: PropTypes.array,
  profileIsLoading: PropTypes.bool,
};

const mapStateToProps = state => ({
  profileData: state.profile.data,
  profileSettingFields: state.profile.settingFields,
  profileIsLoading: state.profile.isLoading,
  profileUpdateLoading: state.profile.updateLoading,
});

const mapDispatchToProps = {
  getProfile: getProfileAction,
  updateProfile: updateProfileAction,
};

export default compose(
  withWidth(),
  translate,
  connect(mapStateToProps, mapDispatchToProps),
)(HeaderProfileContainer);
