import 'antd/dist/antd.css';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  formWithTabContainer: {
    overflow: 'auto',
    height: '100% !important',
    margin: '0 0 3rem',
  },

  tabGroupsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  groupHeader: {
    padding: '15px 15px 0 15px',
    display: 'flex',
    alignItems: 'center',
  },

  quickFormWithTabContainer: {
    height: '100%',
    overflow: 'auto',
    margin: '0 0 5rem',
  },

  groupContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[50],
  },

  '@global': {
    '.ant-tabs': {
      overflow: 'unset',
    },

    '.ant-tabs-content-holder': {
      // overflow: 'auto',
      padding: 15,
    },

    '.quickFormWithTabContainer .ant-tabs-content-holder': {
      height: 1,
    },

    '.quickFormWithTabContainer > .ant-tabs.ant-tabs-top.ant-tabs-rtl': {
      height: '100%',
    },

    '.ant-tabs-nav': {
      padding: '15px 15px 0 15px',
    },

    '.ant-tabs-rtl .ant-tabs-tab-active': {
      backgroundColor: theme.palette.secondary['withOpacity'],
    },

    '.ant-tabs-rtl .ant-tabs-tab-btn': {
      textTransform: 'uppercase',
      fontSize: '13px',
    },

    '.ant-tabs-rtl .ant-tabs-tab-active .ant-tabs-tab-btn': {
      color: theme.palette.secondary.main,
    },

    '.ant-tabs-rtl .ant-tabs-tab[has-error]': {
      backgroundColor: theme.palette.error['withOpacity'],
    },

    '.ant-tabs-rtl .ant-tabs-tab[has-error] .ant-tabs-tab-btn > p': {
      color: theme.palette.error.main,
    },

    '.ant-tabs-rtl .ant-tabs-tab-active[has-error] ~ .ant-tabs-ink-bar': {
      backgroundColor: theme.palette.error.main,
    },

    '.ant-tabs-rtl .ant-tabs-tab[has-warning]': {
      backgroundColor: theme.palette.warning['withOpacity'],
    },

    '.ant-tabs-rtl .ant-tabs-tab[has-warning] .ant-tabs-tab-btn > p': {
      color: theme.palette.warning.main,
    },

    '.ant-tabs-rtl .ant-tabs-tab-active[has-warning] ~ .ant-tabs-ink-bar': {
      backgroundColor: theme.palette.warning.main,
    },

    '.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab': {
      margin: '0',
      padding: '10px 18px',
    },

    '.ant-tabs-nav-list .ant-tabs-ink-bar': {
      backgroundColor: theme.palette.secondary.main,
    },

    '.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav':
      {
        margin: '0',
      },

    '.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before':
      {
        border: 'none',
      },
  },

  table: {
    borderCollapse: 'unset !important' as any,
    borderSpacing: '15px !important',
    tableLayout: 'fixed',
  },

  relationContainer: {
    borderRadius: theme.shape.borderRadius,
    margin: 15,
    contain: 'content',
  },
  titleSummary: {
    display: 'flex',
    alignItems: 'center',
  },

  relationItem: {
    contain: 'content',
    borderRadius: theme.shape.borderRadius,
    margin: '0 1rem',
  },
}));
