import { useState, memo } from 'react';
import { isEmpty } from '../../../../helper/data-helper';
import { actorDispatch, actorGetActionValue } from '../../../../type/actor-setup';
import { FormActions, OnBlurParams } from '../../../form';
import { dropdownId, dropdownMeta, field } from './template-action.helper';

import { MessageTemplateData } from './template-action.type';
import TemplateActionView from './template-action.view';

const TemplateActionController = memo(() => {
  const { name, id: fieldId } = field;

  const { valueMember } = dropdownMeta;
  const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;
  const [isTemplateLoading, setIsTemplateLoading] = useState<boolean>(false);
  const [templateData, setTemplateData] = useState<MessageTemplateData[]>([]);

  const [isMoreIcon, onOpenMoreIcon] = useState<(EventTarget & HTMLElement) | null>(
    null,
  );

  /**
   * @function successCallback
   * @param data
   * @returns { void }
   */
  const successCallback = (data: {
    ALL?: Record<string, unknown>[] | undefined;
    DATA?: MessageTemplateData[];
    TOTAL?: number | undefined;
  }): void => {
    const { DATA = [] } = data;
    if (!isEmpty(DATA)) {
      setTemplateData(DATA);
      setIsTemplateLoading(false);
    }
  };

  /**
   * @function onViewMoreOpen
   * @param event
   * @returns { void }
   */
  const onViewMoreOpen = (event: any): void => {
    setIsTemplateLoading(true);
    actorDispatch(
      'fetchDropdownData',
      {
        dropdownId,
        option: {
          dropdownInPuzzleForm: false,
          isProfile: false,
          isService: false,
          fieldName: name,
          fieldId,
          perPage: 1000,
          page: 1,
        },
        dropdownMeta,
        resource: 'automation/messages',
        resourceType: 'ROOT',
        searchValue: '',
        hasMore: false,
        successCallback,
      },
      {
        disableDebounce: true,
      },
    );
    onOpenMoreIcon(event.currentTarget);
  };

  /**
   * @function onSelectTemplate
   * @param { MessageTemplateData } template
   * @returns { void }
   */
  const onSelectTemplate = (template: MessageTemplateData): void => {
    formActionsHandler(FormActions.InputBlur, {
      fieldName: name,
      value: (template as MessageTemplateData)?.[valueMember as string],
    } as OnBlurParams);

    onViewMoreClose();
  };

  /**
   * @function onViewMoreClose
   * @returns { void }
   */
  const onViewMoreClose = (): void => {
    onOpenMoreIcon(null);
  };

  return (
    <TemplateActionView
      isMoreIcon={isMoreIcon}
      onViewMoreOpen={onViewMoreOpen}
      onViewMoreClose={onViewMoreClose}
      isTemplateLoading={isTemplateLoading}
      templateData={templateData}
      onSelectTemplate={onSelectTemplate}
    />
  );
});

export default TemplateActionController;
