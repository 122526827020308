import { ReactElement, useCallback } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import CloseIcon from '@material-ui/icons/Close';

import classes from './child-menu.styles.module.css';
import { ExpandChildMenuIcon } from '../../icon/ExpandChildMenuIcon';
import { CloseChildMenuIcon } from '../../icon/CloseChildMenuIcon';
import { getTodoListPatternInfo } from '../../helper/PatternMetaHelper';
import { isHeaderItem, isTopHeaderItem } from './child-menu.helper';
import { TODO } from '../../core/configRouteConstant';
import { AccordionItem } from './accordion-item';
import { MenuItemParams } from '../menu/sidebar-menu';

import type { ChildMenuViewInterface } from './child-menu.type';

const ChildMenuView = (props: ChildMenuViewInterface): ReactElement => {
  const {
    isOpen,
    isModuleSelected,
    menuData,
    childData,
    foundedItem,
    isInfavoriteItems,
    onCloseHandler,
    onExpandHandler,
    onShrinkHandler,
    onItemClickHandler,
    onToggleFavoriteItemsHandler,
    getTitle,
  } = props;

  const translate = useTranslate();
  const locale = useLocale();

  //TODO: pass all events  with closure to make it cleaner

  /**
   * @function renderSubChild
   * @param {MenuItemParams} menu
   */
  const renderSubChild = useCallback(
    menu => {
      const title = getTitle(menu);
      const { moduleName, moduleTableName, reportId, url, pageName } = menu;

      if (menu && pageName === 'Tree') {
        return (
          <span className={classes.itemContainer}>
            <a
              href={`#/tree/${moduleName}/${moduleTableName}`}
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onItemClickHandler(event, menu)
              }
              data-style-child-tree="childTree"
            >
              {title}
            </a>
            <span
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onToggleFavoriteItemsHandler(event, menu)
              }
              className={classes.starIcon}
            >
              {isInfavoriteItems(menu.id) ? (
                <StarIcon />
              ) : (
                <span>
                  <StarBorderIcon className={classes.starBorder} />
                </span>
              )}
            </span>
          </span>
        );
      } else if (menu && pageName === 'SingleRecord') {
        return (
          <span className={classes.itemContainer}>
            <a
              href={`#/single-record/${moduleName}/${moduleTableName}`}
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onItemClickHandler(event, menu)
              }
              data-style-child-single="childSingle"
            >
              {title}
            </a>
            <span
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onToggleFavoriteItemsHandler(event, menu)
              }
              className={classes.starIcon}
            >
              {isInfavoriteItems(menu.id) ? (
                <StarIcon />
              ) : (
                <span>
                  <StarBorderIcon className={classes.starBorder} />
                </span>
              )}
            </span>
          </span>
        );
      } else if (menu && pageName === 'ParentReport') {
        return (
          <span className={classes.itemContainer}>
            <a
              href={`#/multi-report/${reportId}`}
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onItemClickHandler(event, menu)
              }
              data-style-child-parent="childParent"
            >
              {title}
            </a>
            <span
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onToggleFavoriteItemsHandler(event, menu)
              }
              className={classes.starIcon}
            >
              {isInfavoriteItems(menu.id) ? (
                <StarIcon />
              ) : (
                <span>
                  <StarBorderIcon className={classes.starBorder} />
                </span>
              )}
            </span>
          </span>
        );
      } else if (moduleName && moduleTableName) {
        return (
          <span className={classes.itemContainer}>
            <a
              href={`#/${moduleName}/${moduleTableName}`}
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onItemClickHandler(event, menu)
              }
              data-style-child-module="childModule"
            >
              {title}
            </a>
            <span
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onToggleFavoriteItemsHandler(event, menu)
              }
              className={classes.starIcon}
            >
              {isInfavoriteItems(menu.id) ? (
                <StarIcon />
              ) : (
                <span>
                  <StarBorderIcon className={classes.starBorder} />
                </span>
              )}
            </span>
          </span>
        );
      } else if (reportId) {
        return (
          <span className={classes.itemContainer}>
            <a
              href={`#/report/${reportId}`}
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onItemClickHandler(event, menu)
              }
              data-style-child-report="childReport"
            >
              {title}
            </a>
            <span
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onToggleFavoriteItemsHandler(event, menu)
              }
              className={classes.starIcon}
            >
              {isInfavoriteItems(menu.id) ? (
                <StarIcon />
              ) : (
                <span>
                  <StarBorderIcon className={classes.starBorder} />
                </span>
              )}
            </span>
          </span>
        );
      } else if (url) {
        return (
          <span className={classes.itemContainer}>
            <a
              href={url}
              target={menu.linkTarget ? menu.linkTarget : '_blank'}
              rel="noopener noreferrer"
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onItemClickHandler(event, menu)
              }
              data-style-child-url="childUrl"
            >
              {title}
            </a>
            <span
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onToggleFavoriteItemsHandler(event, menu)
              }
              className={classes.starIcon}
            >
              {isInfavoriteItems(menu.id) ? (
                <StarIcon />
              ) : (
                <span>
                  <StarBorderIcon className={classes.starBorder} />
                </span>
              )}
            </span>
          </span>
        );
      } else if (menu.type === 'page' && pageName && pageName === 'menuPage') {
        return (
          <span className={classes.itemContainer}>
            <a
              href={`#/menu/${menu.id}`}
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onItemClickHandler(event, menu)
              }
              data-style-child-page="childPage"
            >
              {title}
            </a>
            <span
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onToggleFavoriteItemsHandler(event, menu)
              }
              className={classes.starIcon}
            >
              {isInfavoriteItems(menu.id) ? (
                <StarIcon />
              ) : (
                <span>
                  <StarBorderIcon className={classes.starBorder} />
                </span>
              )}
            </span>
          </span>
        );
      } else if (menu.type === 'page' && pageName && pageName === 'todo') {
        const { reportTaskDetailsResource } = getTodoListPatternInfo('task/list');
        return (
          <span className={classes.itemContainer}>
            <a
              data-test-todo-menu-link
              href={`#/${TODO}/task/list?detail=${reportTaskDetailsResource}`}
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onItemClickHandler(event, menu)
              }
              data-style-child-todo="childTodo"
            >
              {title}
            </a>
            <span
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onToggleFavoriteItemsHandler(event, menu)
              }
              className={classes.starIcon}
            >
              {isInfavoriteItems(menu.id) ? (
                <StarIcon />
              ) : (
                <span>
                  <StarBorderIcon className={classes.starBorder} />
                </span>
              )}
            </span>
          </span>
        );
      } else {
        return null;
      }
    },
    [locale],
  );

  /**
   * @function renderChild
   * @param {MenuItemParams[]} items
   * @returns {ReactElement}
   */
  const renderChild = (items: MenuItemParams[]) => {
    return (
      <>
        {items.map((item: any) => (
          <div
            key={item.id}
            className={classes.itemsWrapper}
            data-style-item-child="itemChild"
          >
            {item.children && item.children.length > 0 ? (
              <AccordionItem
                id={item.id}
                title={getTitle(item)}
                shouldOpen={isHeaderItem(item.parentId, foundedItem) ? true : false}
                containerClassName={
                  isHeaderItem(item.parentId, foundedItem)
                    ? classes['mega-menu-list-item-header']
                    : classes['mega-menu-list-item-sub-header']
                }
                collapseClassName={`collapse-css-transition ${
                  isHeaderItem(item.parentId, foundedItem) ? classes['flex'] : null
                }`}
                data-style-acc-child="accChildItem"
              >
                {renderChild(item.children)}
              </AccordionItem>
            ) : item.children.length === 0 &&
              isHeaderItem(item.parentId, foundedItem) ? (
              <li
                className={
                  isHeaderItem(item.parentId, foundedItem)
                    ? classes['mega-menu-list-item-header']
                    : classes['mega-menu-list-item-sub-header']
                }
                data-style-text-noneChild="noneTextChild"
              >
                <span>{getTitle(item)}</span>
              </li>
            ) : (
              <li
                className={
                  item?.currentLevel === 2
                    ? classes['mega-menu-list-item-sub-header']
                    : classes['mega-menu-list-item-label']
                }
                data-style-text-Child="TextChild"
              >
                {renderSubChild(item)}
              </li>
            )}
          </div>
        ))}
      </>
    );
  };

  /**
   * @function renderModuleData
   * @param {MenuItemParams[]} items
   * @returns {ReactElement}
   */
  const renderModuleData = (items: MenuItemParams[]) => {
    return (
      <>
        {items.map((item: any) => (
          <div key={item.id} className={classes.itemsWrapper}>
            {item.children && item.children.length > 0 ? (
              <AccordionItem
                id={item.id}
                title={getTitle(item)}
                shouldOpen={isTopHeaderItem(item) ? true : false}
                containerClassName={
                  isTopHeaderItem(item)
                    ? classes['mega-menu-list-item-top-header']
                    : isHeaderItem(item.parentId, foundedItem)
                    ? classes['mega-menu-list-item-header']
                    : classes['mega-menu-list-item-sub-header']
                }
                data-style-data-child="dataChild"
                collapseClassName={`collapse-css-transition ${
                  isTopHeaderItem(item) ? classes['flex'] : null
                }`}
              >
                {renderModuleData(item.children)}
              </AccordionItem>
            ) : item.children.length === 0 && isTopHeaderItem(item) ? (
              <li
                className={
                  isTopHeaderItem(item)
                    ? classes['mega-menu-list-item-top-header']
                    : isHeaderItem(item.parentId, foundedItem)
                    ? classes['mega-menu-list-item-header']
                    : classes['mega-menu-list-item-sub-header']
                }
                data-style-none-child="noneDataChild"
              >
                <span>{getTitle(item)}</span>
              </li>
            ) : (
              <li className={classes['mega-menu-list-item-label']}>
                {renderSubChild(item)}
              </li>
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      {isOpen && (
        <div
          data-test="child-menu"
          style={isModuleSelected ? { width: '100%' } : {}}
          className={classes.wrapper}
        >
          <div className={classes.topNavHeader} id="openChildText">
            <span onClick={onExpandHandler} className={classes.topHeaderIcon}>
              <ExpandChildMenuIcon width={18} height={18} color="#000" />
              <span style={{ padding: '0 .5rem' }}>{translate('menu.expand')}</span>
            </span>

            <span onClick={onShrinkHandler} className={classes.topHeaderIcon}>
              <CloseChildMenuIcon width={18} height={18} color="#000" />
              <span style={{ padding: '0 .5rem' }}>{translate('menu.shrink')}</span>
            </span>

            <span onClick={onCloseHandler} className={classes.topHeaderIcon}>
              <CloseIcon width={18} height={18} />
              <span style={{ padding: '0 .5rem' }}>{translate('menu.close')}</span>
            </span>
          </div>

          <ul
            className={
              isModuleSelected
                ? classes['mega-items-list-module']
                : classes['mega-items-list']
            }
            data-style-child-menu="childMenu"
          >
            {isModuleSelected
              ? renderModuleData(
                  menuData.filter(
                    (item: MenuItemParams) => item.children?.length > 0,
                  ),
                )
              : renderChild(childData)}
          </ul>
        </div>
      )}
    </>
  );
};

export default ChildMenuView;
