import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  mailListContainer: {
    paddingTop: 0,
    paddingBottom: 0,
    overflow: 'auto !important',
    '&::-webkit-scrollbar': {
      width: 3,
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-track-piece': {
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#fff',
    },
    '&:hover': {
      '&::-webkit-scrollbar': {
        backgroundColor: 'initial',
      },
      '&::-webkit-scrollbar-track-piece': {
        backgroundColor: 'initial',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0, .3)',
      },
    },
  },
}));
