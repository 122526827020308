import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  appHeaderContainer: {
    borderBottom: 'none !important',
    height: 'auto !important',
  },

  appContentContainer: {
    padding: '.2rem 1rem',
    overflow: 'auto !important',
    '&::-webkit-scrollbar': {
      width: 3,
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-track-piece': {
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#fff',
    },
    '&:hover': {
      '&::-webkit-scrollbar': {
        backgroundColor: 'initial',
      },
      '&::-webkit-scrollbar-track-piece': {
        backgroundColor: 'initial',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0, .3)',
      },
    },
  },
}));
