import lodashMerge from 'lodash/merge';
import lodashGet from 'lodash/get';
import { isEmpty, isEmptyObject } from '../../../helper/data-helper';
import {
  ComputeParameterValueFunc,
  DropRequestParams,
  FieldInterface,
  GetDropdownRequestParamsFunc,
  GetLabelForDropdownOptionFunc,
  PreparedOptions,
  TriggerDropdownFetchDataFunc,
} from './dropdown-input.type';
import { FormActions, GetDropDataParams } from '../../form';
import {
  actorDispatch,
  actorGetActionValue,
  FormKeyMode,
  RecordKeyMode,
} from '../../../type/actor-setup';

/**
 * Get dropdown request parameter from form data.
 * @function getDropdownRequestParams
 * @param {object} -
 * {
 *   dropdownMeta: object,
 *   page: number,
 *   perPage: number,
 *   record: object,
 *   search: string,
 *   filterValues: object,
 *   forceTreeLevel: boolean,
 *   additionalProps: object,
 *   resource: string,
 *   sort:string
 * }
 * @returns {object}
 */
// FIXME: // compose this function with triggerDropdownFetchData and make it a function that returns params for formActionsHandler to called with proper parameters
export const getDropdownRequestParams: GetDropdownRequestParamsFunc = ({
  dropdownMeta,
  page = 1,
  perPage = 10,
  record = {},
  search = '',
  filterValues = {},
  forceTreeLevel = dropdownMeta.forceTreeLevel,
  resource = '',
  sort = {},
}) => {
  const { parameters: dropdownRequiredParameters = [] } = dropdownMeta;

  const result: DropRequestParams = {
    pagination: {
      page,
      perPage,
    },
    sort,
    search,
    filter: filterValues,
  };

  const parameters = {};
  dropdownRequiredParameters.forEach(item => {
    if (!item) {
      return;
    }

    const { to, from, moduleName, moduleTableName, defaultValue } = item;
    const dropdownParameterResource = `${moduleName}/${moduleTableName}`;
    const formFieldName = !isEmpty(from) ? from.toLowerCase() : '';
    const paramFieldName = !isEmpty(to) ? to.toLowerCase() : '';
    if (dropdownParameterResource === resource) {
      const parameterValue = computeParameterValue(
        record,
        formFieldName,
        defaultValue,
      );
      if (!isEmpty(parameterValue)) {
        parameters[paramFieldName] = parameterValue;
      }
    } else {
      const originalRecord = actorGetActionValue('record', [
        dropdownParameterResource,
        FormKeyMode.ROOT,
        RecordKeyMode.FORM,
      ]) as Record<string, unknown>;

      const parameterValue = computeParameterValue(
        originalRecord,
        formFieldName,
        defaultValue,
      );

      if (!isEmpty(parameterValue)) {
        parameters[paramFieldName] = parameterValue;
      }
    }
  });

  if (Object.keys(parameters).length > 0) {
    result.parameters = JSON.stringify(parameters);
  }

  result.forceTreeLevel = forceTreeLevel;
  return result;
};

/**
 * Check `fieldName` in `data` and return value.
 * @function computeParameterValue
 * @param {object} data
 * @param {string} fieldName
 * @param {number | string | null} defaultValue
 * @returns {number | string | null}
 */
const computeParameterValue: ComputeParameterValueFunc = (
  data,
  fieldName,
  defaultValue = '',
) => {
  return data && !isEmpty(data[fieldName])
    ? `${data[fieldName]}`
    : !isEmpty(defaultValue)
    ? defaultValue
    : null;
};

/**
 * Get options labels based on display member
 * @function getLabelForDropdownOption
 * @param { DropdownMeta } dropdownMeta
 * @param { Record<string, string> } row
 * @returns {string}
 */
export const getLabelForDropdownOption: GetLabelForDropdownOptionFunc = (
  dropdownMeta,
  row,
): string => {
  const { displayMember, displayMember2 } = dropdownMeta;

  let label = row[displayMember];

  if (typeof row[displayMember2] !== 'undefined') {
    label += ' - ' + row[displayMember2];
  }

  return String(label);
};

/**
 * this function will call the getDropdownRequestParams function to compute dropdown parameters
 * for HTTP requests then merge them with custom params that have been received from the third parameter.
 * then trigger the findDropdownData action that has been destructed from the first parameter (props)
 * @function triggerDropdownFetchData
 * @param {object} props
 * @param {string} value
 * @param {object} customParams constant parameters
 * @returns {void}
 */
export const triggerDropdownFetchData: TriggerDropdownFetchDataFunc = (
  props,
  value,
  customParams = {},
) => {
  const {
    dropdownMeta,
    resource,
    perPage = null,
    forceTreeLevel,
    filterValues,
    page = null,
    dropdownInPuzzleForm,
    isTodo,
    isProfile,
    isService,
    sort,
    isSearching,
  } = props;
  const { id, uniqueId } = dropdownMeta;

  const currentResource = actorGetActionValue('resources')!.current;

  const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;

  const record = actorGetActionValue('record', [
    currentResource.value,
    currentResource.type,
    RecordKeyMode.FULL,
  ]) as Record<string, unknown>;

  const formData =
    (actorGetActionValue('formData', [
      currentResource.value,
      currentResource.type,
    ]) as FormData | null) ?? {};

  actorDispatch('loading', {
    [uniqueId]: true,
  });

  actorDispatch('isSearchingOnDropDown', {
    [uniqueId]: isSearching ? true : false,
  });

  const finalParams =
    dropdownInPuzzleForm || isTodo || isProfile || isService
      ? { ...customParams, fieldName: undefined, dropId: undefined }
      : customParams;

  const params = lodashMerge(
    getDropdownRequestParams({
      dropdownMeta,
      record: !isEmptyObject(formData) ? formData : record,
      search: !isEmpty(value) ? value : '',
      resource: currentResource.value,
      perPage: !isEmpty(perPage) ? perPage : null,
      page: !isEmpty(page) ? page : null,
      forceTreeLevel: !isEmpty(forceTreeLevel) ? forceTreeLevel : null,
      filterValues: !isEmpty(filterValues) ? filterValues : {},
      sort: !isEmptyObject(sort) ? sort : {},
    }),
    finalParams,
  );

  formActionsHandler!(FormActions.GetDropData, {
    id,
    params,
    meta: dropdownMeta,
    uniqueId,
  } as GetDropDataParams);
};

export const dropdownPreFilledName = (field: FieldInterface): string => {
  return field.relatedName;
};

/**
 * it will compute the dropdown label that should be displayed and the dropdown value that
 * should send in requests and returns this info in an object to use in the dropdown field.
 * @function findSelectedItemFromDropdownData
 * @param {Object} includes dropdownMeta,dataArray,record,value,field
 * @returns {Object} dropdown lable and value
 */
export const findSelectedItemFromDropdownData = ({
  dropdownMeta,
  dataArray,
  record,
  value,
  field,
}) => {
  const { valueMember } = dropdownMeta;
  // if we have any data from api, use that first!
  if (dataArray && dataArray.length && !isEmpty(value)) {
    const dropdownItem = dataArray.find(item => {
      // keep loose comparison, because server might give number inside string
      // eslint-disable-next-line eqeqeq
      const computedValue = Array.isArray(value) ? lodashGet(value, 2) : value;
      return item[valueMember] == computedValue;
    });

    if (dropdownItem) {
      return {
        ...dropdownItem,
        value: dropdownItem[valueMember],
        label: getLabelForDropdownOption(dropdownMeta, dropdownItem),
      };
    }
  }

  const preFilledName = dropdownPreFilledName(field);
  if (!isEmpty(value) && record && record[preFilledName]) {
    return {
      value: value,
      label: record[preFilledName],
    };
  }

  return null;
};

/**
 * Convert any type of drop value to string
 * @param {string | number | null | undefined} value drop value
 * @returns {string}
 */
export const sanitizeValue = (value: string | number | null | undefined): string => {
  if (value == 0 || value) {
    return `${value}`;
  } else {
    return '';
  }
};

/**
 * this function used for determining if a data from api has next or not
 * @function hasNextDataAvailable
 * @param {number | null} totalItemsNumber
 * @param {number | null} currentItemsNumber
 * @returns {boolean}
 */
export const hasNextDataAvailable = (
  totalItemsNumber: number | null,
  currentItemsNumber: number | null,
): boolean => {
  if (!totalItemsNumber || !currentItemsNumber) {
    return false;
  }
  return totalItemsNumber - currentItemsNumber > 0;
};

/**
 * this function generate customUniqueId by combine uniqueId by field id
 * @function getCustomUniqueId
 * @param {string | unknown} uniqueId
 * @param {string} id
 * @return {string}
 */
export const getCustomUniqueId = (uniqueId: string, id: string | unknown): string =>
  `${uniqueId}_${id}`;
