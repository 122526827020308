import React, { FC, memo, useEffect, useRef, useState } from 'react';
import lodashGet from 'lodash/get';
import { useTranslate } from 'react-admin';

import {
  DragPayload,
  FileStreamInputInterface,
  MultiFileStreamItem,
  SuccessResponse,
} from './multi-file-stream-input.type';
import FileStreamInputView from './multi-file-stream-input.view';
import { actorDispatch, actorGetActionValue } from '../../../type/actor-setup';
import {
  ChangeFormValueParams,
  FormActions,
  FormData,
  OnBlurParams,
} from '../../form';
import {
  clone,
  isEmpty,
  isEmptyObject,
  isJsonEncodedString,
  megaByteToByte,
} from '../../../helper/data-helper';
import { generateErrorMessage } from './multi-file-stream-input.helper';
import { showNotification } from '../../../helper/general-function-helper';
import lodashUnionBy from 'lodash/unionBy';

const FileStreamInputController: FC<FileStreamInputInterface> = memo(props => {
  const {
    disabled,
    label,
    hint,
    field,
    inputRef,
    value = [],
    inputMessage,
    visibleClass,
    inputContainerClass,
    formActionsHandler,
  } = props;

  const [dragging, setDragging] = useState<boolean>(false);
  const [dragCounter, setDragCounter] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const fieldsetRef = useRef<HTMLFieldSetElement>(null);

  const translate = useTranslate();

  const currentResource = actorGetActionValue('resources')!.current;
  const globalParameters = actorGetActionValue('globalParameters');

  const fileUploadLimitMB = lodashGet(globalParameters, 'fileUploadLimitMB', null);

  // set `drag` event listeners
  useEffect(() => {
    const div = fieldsetRef.current;
    if (div) {
      div.addEventListener('dragenter', handleDragIn);
      div.addEventListener('dragleave', handleDragOut);
      div.addEventListener('dragover', handleDragOver);
      div.addEventListener('drop', handleDrop);
    }
    return () => {
      const div = fieldsetRef.current;
      if (div) {
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('dragover', handleDragOver);
        div.removeEventListener('drop', handleDrop);
      }
    };
  }, []);

  /**
   * we need to prevent the default browser behavior on that event, which is to open the dropped file.
   * @function handleDragOver
   * @param {DragEvent} event
   * @returns {void} void
   */
  const handleDragOver = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
  };

  /**
   * define dragIn behavior and if there is dataTransferItems, setDragging(true)
   * @function handleDragIn
   * @param {DragEvent} event
   * @returns {void} void
   */
  const handleDragIn = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    setDragCounter(prev => prev + 1);

    if (event.dataTransfer?.items?.length) {
      setDragging(true);
    }
  };

  /**
   * define dragOut behavior and  setDragging(false)
   * @param {DragEvent} event
   * @function handleDragOut
   * @returns {void} void
   */
  const handleDragOut = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    setDragCounter(prev => prev - 1);

    if (dragCounter > 0) return;

    setDragging(false);
  };

  /**
   * function receives file from event.dataTransfer.files and send it to onFileChange
   * @param {DragEvent} event
   * @function handleDrop
   * @returns {void} void
   */
  const handleDrop = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    setDragging(false);

    if (event.dataTransfer?.items?.length) {
      // simulate a change event
      const dropFile = {
        target: {
          files: event.dataTransfer.files,
        },
      } as DragPayload;

      onFileChange(dropFile);
      event.dataTransfer?.clearData();
      setDragCounter(0);
    }
  };

  /**
   * get current form data
   * @function onFileSent
   * @return {MultiFileStreamItem[]}
   */
  const getPrevFieldValue = (): MultiFileStreamItem[] => {
    const formData = actorGetActionValue(
      'formData',
      `${currentResource.value}.${currentResource.type}`,
    ) as FormData | null;

    const prevValue: Array<MultiFileStreamItem> = formData
      ? isJsonEncodedString(formData[field.name] as Array<MultiFileStreamItem>)
        ? JSON.parse(formData[field.name] as string)
        : formData[field.name] ?? []
      : value;

    return prevValue;
  };

  /**
   * add new value to form data
   * @function onFileSent
   * @param {object} successResponse
   * @return {void}
   */
  const onFileSent = (successResponse: SuccessResponse): void => {
    if (isEmptyObject(successResponse.data)) return;
    const { filePath, realFileName } = successResponse.data;
    const { size } = successResponse;

    setLoading(false);

    const prevValue = getPrevFieldValue();

    formActionsHandler(FormActions.InputChange, {
      fieldName: field.name,
      value: [...prevValue, { filePath, realFileName, size }],
    } as ChangeFormValueParams);
  };

  /**
   * it should show error messages
   * @function handleSendFilesErrors
   * @param {string} error
   * @returns {void} void
   */
  const handleSendFilesErrors =
    (largerThanAllowedFiles: Array<File>) =>
    (apiErrors: Array<{ fileName: string; message: string }>) => {
      setLoading(false);

      showNotification(
        generateErrorMessage(largerThanAllowedFiles, apiErrors, translate),
        'error',
      );
    };

  /**
   * check the size of coming files, separate the valid and invalid files
   * put invalid files to errors array and make request with valid files
   * @function onFileChange
   * @param {Event} event selected file in input
   * @returns {void} void
   */
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const validFiles: Array<File> = [];
    if (!event.target.files?.length) return;

    const largerThanAllowedFiles: Array<File> = [];

    for (const file of event.target.files) {
      if (!file) continue;

      // limit files size
      if (
        fileUploadLimitMB &&
        !isEmpty(fileUploadLimitMB) &&
        megaByteToByte(+fileUploadLimitMB) < file.size
      ) {
        largerThanAllowedFiles.push(file);
        continue;
      }
      validFiles.push(file);
    }

    if (validFiles.length) {
      setLoading(true);

      actorDispatch('uploadStreamMultipleFile', {
        param: {
          resource: `${currentResource.value}/multifilestream/${field.name}`,
          files: validFiles,
        },
        successCallback: onFileSent,
        failureCallback: handleSendFilesErrors(largerThanAllowedFiles),
        successAllFilesUploadedCallback: successAllFilesUploadedCallback,
      });
    } else if (largerThanAllowedFiles.length) {
      handleSendFilesErrors(largerThanAllowedFiles)([]);
    }
  };

  /**
   * remove an item from files array in form data
   * @function onDeleteItem
   * @param {number} index
   * @returns {void} void
   */
  const onDeleteItem = (index: number) => (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!value) return;

    const newValue: MultiFileStreamItem[] = clone(value);
    newValue.splice(index, 1);

    formActionsHandler(FormActions.InputChange, {
      fieldName: field.name,
      value: newValue,
    } as ChangeFormValueParams);

    formActionsHandler(FormActions.InputBlur, {
      fieldName: field.name,
      value: newValue,
    } as OnBlurParams);
  };

  /**
   * trigger `onClick` input file
   * @function handleClick
   * @returns {void}
   */
  const handleClick = (event: React.MouseEvent): void => {
    event.stopPropagation();

    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  /**
   * Handle successAllFilesUploadedCallback
   * @function successAllFilesUploadedCallback
   * @param {MultiFileStreamItem[]} inputValue
   * @returns {void} void
   */
  const successAllFilesUploadedCallback = (
    inputValue: MultiFileStreamItem[],
  ): void => {
    const prevValue = getPrevFieldValue();
    const newValue = lodashUnionBy(prevValue, inputValue, 'filePath');

    formActionsHandler(FormActions.InputBlur, {
      fieldName: field.name,
      value: newValue,
    } as OnBlurParams);
  };

  return (
    <FileStreamInputView
      fieldsetRef={fieldsetRef}
      disabled={disabled}
      label={label}
      hint={hint}
      required={field.required}
      name={field.name}
      onFileChange={onFileChange}
      inputFileRef={inputRef}
      handleClick={handleClick}
      dragging={dragging}
      value={value}
      inputMessage={inputMessage}
      loading={loading}
      visibleClass={visibleClass}
      inputContainerClass={inputContainerClass}
      onDelete={onDeleteItem}
      field={field}
    />
  );
});

export default FileStreamInputController;
