import { makeStyles } from '@material-ui/core';
import { StyleProps } from '.';
import { CustomTheme } from '../../../../core/themeProvider';
import { blue } from '@material-ui/core/colors';

export const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  navItem: {
    height: 30,
    marginTop: 2,
    marginBottom: 2,
    cursor: 'pointer',
    textDecoration: 'none !important',
    width: 'calc(100% - 16px)',
    borderRadius: '0 30px 30px 0',
    paddingLeft: theme.direction === 'ltr' ? ({ level }) => 24 + 40 * level : 12,
    paddingRight: theme.direction === 'rtl' ? ({ level }) => 24 + 40 * level : 12,
    '&.nav-item-header': {
      textTransform: 'uppercase',
    },

    '& .nav-item-icon': {
      '& svg': {
        height: 18,
        width: 18,
      },
    },

    '&:hover, &:focus': {
      '& .nav-item-text': {
        color: theme.palette.primary.appPrimaryTextColor,
      },

      '& .nav-item-icon': {
        color: theme.palette.primary.appPrimaryTextColor,
      },

      '& .nav-item-icon-arrow': {
        color: theme.palette.primary.appPrimaryTextColor,
      },
    },
    '& .nav-item-text': {
      color: theme.palette.primary.appPrimaryTextColor,
      fontSize: 13,
    },
  },

  active: {
    backgroundColor: blue[300],
    transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',

    '&:hover, &:focus': {
      backgroundColor: blue[300],
    },

    '& .nav-item-text': {
      color: theme.palette.common.white + '!important',
      fontWeight: 300,
    },
    '& .nav-item-icon': {
      color: '#fff !important',
    },
    '& svg': {
      stroke: '#fff !important',
      '& path': {
        stroke: '#fff !important',
      },
    },
  },
  listIcon: {
    fontSize: 18,
  },
  listItemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 300,
  },

  countContainer: {
    color: ({ isActive }) => (isActive ? '#fff' : '#000'),
  },
}));
