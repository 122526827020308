import produce from 'immer';
import { USER_LOGOUT } from 'react-admin';
import { FIND_ONE, FIND_ONE_SUCCESS, FIND_ONE_FAILED } from './constant';

const initialState = {
  loadingList: {},
  list: {},
  errorList: {},
};

const myReducer = (state = initialState, { type, resource, source, data, error }) =>
  produce(state, draft => {
    // eslint-disable-next-line default-case
    switch (type) {
      case USER_LOGOUT:
        draft.loadingList = {};
        draft.list = {};
        draft.errorList = {};
        break;

      case FIND_ONE:
        draft.loadingList[resource] = true;
        draft.list[resource] = null;
        draft.errorList[resource] = null;
        break;

      case FIND_ONE_SUCCESS:
        let _data = [];

        for (const i in data) {
          if (!data[i][source] || typeof data[i][source].split !== 'function') {
            continue;
          }
          _data = _data.concat(data[i][source].split('#'));
        }

        _data = _data.filter((item, index) => {
          return _data.indexOf(item) >= index;
        });

        draft.loadingList[resource] = false;
        draft.list[resource] = _data;
        draft.errorList[resource] = null;
        break;

      case FIND_ONE_FAILED:
        draft.loadingList[resource] = false;
        draft.list[resource] = null;
        draft.errorList[resource] = error.toString();
    }
  });

export default myReducer;
