import {
  FIND_ONE,
  FIND_ONE_SUCCESS,
  FIND_ONE_FAILED,
  SET_GLOBAL_PARAMETERS_ACTION,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILED,
} from './constant';

export function findOneAction() {
  return {
    type: FIND_ONE,
  };
}

export function findOneSuccessAction(data, additionalData) {
  return {
    type: FIND_ONE_SUCCESS,
    data,
    additionalData,
  };
}

export function findOneFailedAction(error) {
  return {
    type: FIND_ONE_FAILED,
    error,
  };
}

export function setGlobalParametersAction(data) {
  return {
    type: SET_GLOBAL_PARAMETERS_ACTION,
    data,
  };
}

export function updateProfileAction({ data }) {
  return {
    type: UPDATE_PROFILE,
    data,
  };
}

export function updateProfileSuccessAction() {
  return {
    type: UPDATE_PROFILE_SUCCESS,
  };
}

export function updateProfileFailedAction(error) {
  return {
    type: UPDATE_PROFILE_FAILED,
    error,
  };
}
