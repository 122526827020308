import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  styleTextArea: {
    width: '100%',
    margin: 'auto',
    marginTop: 10,
    height: '100%',
    outline: 'none',
  },
  container: {
    direction: 'rtl',
    padding: 2,
  },
  btnCss: { float: 'left' },
}));
