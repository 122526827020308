import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import lodashDebounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { refreshView as refreshViewAction, translate } from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  withStyles,
  withWidth,
  TextField,
} from '@material-ui/core';

import {
  getFormDefaultValue,
  getGridColumns,
  getShowSummaryColumnList,
  getTranslatedName,
} from '../../helper/MetaHelper';
import {
  findOneAction as findDropdownDataAction,
  findOneAction as refreshDropdownAction,
} from '../../redux/dropdown-old/action';
import TreeController from '../../container/tree/TreeController';

import DevExGrid from '../../component/DevExGrid';
import LoadingBox from '../../component/loading-box';
import TabParent from '../../component/form-component-old/TabParent';
import TabChild from '../../component/form-component-old/TabChild';
import {
  clone,
  isEmpty,
  isEmptyObject,
  mergeAndClone,
} from '../../helper/data-helper';
import Pagination from '../../component/Pagination';
import { triggerDropdownFetchData } from '../../helper/DropdownHelper';
import LocaleHoc from '../../component/LocaleHoc';
import FormRelationBulkInsertPopupDialogContainer from '../../container/FormRelationBulkInsertPopupDialogContainer';
import MetaProvider from '../../container/MetaProvider';

const defaultSort = {
  field: {
    field: 'id',
    order: 'DESC',
  },
  order: 'DESC',
};

const styles = theme => ({
  dialog: {
    height: 700,
  },

  list: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  ListCard: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  childrenContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  dialogAction: {
    flexDirection: 'row-reverse',
    margin: 0,
    padding: 10,
    backgroundColor: '#f5f5f5',
  },

  searchContainer: {
    padding: '8px 20px',
  },
});

const getDefaultState = () => ({
  preparedForId: null,
  preparedData: {},
  preparedDataLastLevel: {},
  preparedIds: [],
  preparedFields: null,
  prevDataLength: 0,
  currentPage: 1,
  perPage: 10,
  prevDataIds: null,
  filterValues: {},
  forceTreeLevel: true,
  selectedIds: [],
  isBulkDialogOpen: false,
  bulkDefaultData: null,
  textInputSearch: '',
  sort: { field: '', order: '' },
});

const defaultGlobalParameters = {};

class SearchPopupDialogContainer extends PureComponent {
  state = getDefaultState();

  /**
   * In the `searchPopupDialogContainer`, by clicking on the title of each column,
   * the field title and sort type are entered into this function as a parameter,
   * and it sorts the clicked column and sends a request.
   * @function sortPopupDialog
   * @param {string} titleField
   * @param {string} sortType
   * @returns {void}
   */
  sortPopupDialog = (titleField, sortType) => {
    this.setState(
      { sort: { field: titleField, order: sortType } },
      this.makeRequest,
    );
  };

  static getDerivedStateFromProps(props, prevState) {
    const { dropdownData, dropdownMeta, dropdownId } = props;
    if (
      typeof dropdownData === 'undefined' ||
      typeof dropdownMeta.columns === 'undefined'
    ) {
      return prevState;
    }

    if (
      dropdownId === prevState.preparedForId &&
      dropdownData.length === prevState.prevDataLength &&
      JSON.stringify(dropdownData.map(row => row.id)) === prevState.prevDataIds
    ) {
      return prevState;
    }

    const preparedIds = [];
    const preparedData = {};

    if (dropdownData) {
      dropdownData.forEach(row => {
        preparedIds.push(row.id);
        preparedData[row.id] = row;
      });
    }

    const preparedFields = [];
    const columns = lodashGet(props, 'dropdownMeta.columns');

    if (columns) {
      columns.forEach(item => {
        const field = clone(item);
        if (!field.name && field.title) {
          field.name = field.title;
        }

        if (!field.relatedName && field.title) {
          field.relatedName = field.title;
        }

        if (
          isEmptyObject(field.translatedCaption) &&
          !isEmptyObject(field.translatedTitle)
        ) {
          field.translatedCaption = field.translatedTitle;
        }

        preparedFields.push(field);
      });
    } else {
      console.log(
        `columns is missing for dropdown ${dropdownId}, so can't make grid!`,
      );
    }

    const preparedDataLastLevel = {};
    const preparedLastLevelIds = [];
    if (dropdownMeta.type === 'Tree') {
      Object.keys(preparedData).forEach(key => {
        if (
          dropdownMeta.treeLevel === 0 ||
          preparedData[key].currentlevel === dropdownMeta.treeLevel - 1
        ) {
          preparedLastLevelIds.push(preparedData[key].id);
          preparedDataLastLevel[preparedData[key].id] = preparedData[key];
        }
      });
    }

    return {
      preparedIds,
      preparedData,
      preparedDataLastLevel,
      preparedLastLevelIds,
      preparedFields,
      preparedForId: dropdownId,
      prevDataLength: dropdownData.length,
      prevDataIds: JSON.stringify(dropdownData.map(row => row.id)),
    };
  }

  closeDialog = () => {
    const { closeDialog } = this.props;

    this.setState(getDefaultState(), closeDialog);
  };

  /**
   * it will change form value and update edited formData then close dialog
   * @function handleRowClick
   * @param {object} row
   * @returns {void}
   */
  handleRowClick = row => {
    const {
      source,
      dropdownMeta,
      changeFormValue,
      dropdownMultipleSelection,
      useInFilterInput,
    } = this.props;

    const { valueMember, filterValueMember } = dropdownMeta;
    const valueFieldName =
      useInFilterInput && !!filterValueMember ? filterValueMember : valueMember;
    if (row && !isEmpty(row[valueFieldName])) {
      if (dropdownMultipleSelection) {
        changeFormValue(String(row[valueFieldName]), true);
      } else {
        changeFormValue(source, row[valueFieldName]);
      }
    }

    this.closeDialog();
  };

  /**
   * it should compute request pasams and call findDropdownData function with computed parameters.
   * @function makeRequest
   * @returns {void}
   */
  makeRequest = lodashDebounce(() => {
    const {
      dropdownMeta,
      findDropdownData,
      record,
      parentResource,
      additionalProps,
      dropdownId,
      fieldName,
    } = this.props;
    const {
      perPage,
      currentPage,
      filterValues,
      forceTreeLevel,
      textInputSearch,
      sort,
    } = this.state;

    // get data for dropdown
    triggerDropdownFetchData(
      {
        ...this.props,
        resource: parentResource,
        perPage,
        page: currentPage,
        filterValues,
        forceTreeLevel,
        textInputSearch,
        sort,
      },
      textInputSearch,
      {
        dropId: dropdownId,
        fieldName,
        dropResource: parentResource,
      },
    );
  }, 200);

  changePerPage = perPage => {
    this.setState({ perPage }, this.makeRequest);
  };

  changePage = pageNo => {
    this.setState({ currentPage: pageNo }, this.makeRequest);
  };

  /**
   * set state for get `dropdownData` and call `makeRequest`.
   * @returns {void}
   */
  loadDataForGrid = () =>
    this.setState(
      {
        perPage: 10,
        currentPage: 1,
        forceTreeLevel: lodashGet(this.props.dropdownMeta, 'forceTreeLevel', false),
      },
      this.makeRequest,
    );

  loadDataForTree = () =>
    this.setState(
      {
        perPage: 100000,
        currentPage: 1,
        forceTreeLevel: false,
      },
      this.makeRequest,
    );

  setFilters = lodashDebounce(newFilters => {
    const { filterValues } = this.state;
    // merge clone into new variable
    const mergedFilterValues = mergeAndClone(filterValues, newFilters);

    // only apply filters with value
    Object.keys(mergedFilterValues).forEach(key => {
      if (!newFilters[key]) {
        delete mergedFilterValues[key];
      }
    });

    this.setState(
      {
        filterValues: mergedFilterValues,
        currentPage: 1,
      },
      this.makeRequest,
    );
  }, 500);

  onSelect = selectedIds => this.setState({ selectedIds });

  /**
   * open quick create for selected ids.
   * @returns {void}
   */
  openQuickCreateWithForSelectedIds = () => {
    const { globalParameters, relationSource, record, source, relationMetaData } =
      this.props;
    const { selectedIds } = this.state;

    const summaryFields = getShowSummaryColumnList(relationMetaData);
    const fields = getGridColumns({ metaData: relationMetaData, isRelation: true });
    // sometimes, summary fields are not part of grid fields, su get them both and merge them
    const defaultRowData = getFormDefaultValue(
      [...fields, ...summaryFields],
      globalParameters,
    );

    const sourceParts = source.split('.');
    const fieldName = sourceParts[sourceParts.length - 1];

    const defaultData = clone(record);
    lodashSet(
      defaultData,
      relationSource,
      selectedIds.map(dropdownId => ({
        ...defaultRowData,
        [fieldName]: dropdownId,
        id: Date.now() + '-' + Math.random(), // give fake id to track errors in relation form
        isNewRecord: true,
      })),
    );

    this.setState({
      selectedIds: [],
      isBulkDialogOpen: true,
      bulkDefaultData: defaultData,
    });
  };

  /**
   * should seprate item values and join them with ',' and call onChange
   * then close the dialog;
   * @function handleMultipleSelect
   * @returns {void}
   */
  handleMultipleSelect = () => {
    const { selectedIds } = this.state;
    const {
      dropdownMeta,
      changeFormValue,
      dropdownData = [],
      dropBaseValue = [],
    } = this.props;
    const list = Array.from(selectedIds, item => item.toString());
    // create unique list
    // eslint-disable-next-line no-undef
    const concatList = new Set([...list, ...dropBaseValue]);
    const selectedItems = [...concatList].join(',');

    changeFormValue(selectedItems);
    this.closeDialog();
  };

  closeBulkDialog = () => {
    this.setState({
      isBulkDialogOpen: false,
      bulkDefaultData: null,
    });
  };

  /**
   * Takes the searched value and sends it to the DropdownHelpers to perform the search operation.
   * @function handleDebounceTrigger
   * @returns {void}
   */
  handleDebounceTrigger = lodashDebounce(value => {
    triggerDropdownFetchData(this.props, value, {
      dropId: this.props.dropdownId,
      fieldName: this.props.fieldName,
      dropResource: this.props.parentResource,
    });
  }, 700);

  /**
   * Sets the search entry in the state and using the handleDebounceTrigger, it takes the entered value into the DropdownHelpers
   * and finally changes the page number in the state to one.
   * @function handleChange
   * @returns {void}
   */
  handleChange = event => {
    this.setState({ textInputSearch: event.target.value });
    this.handleDebounceTrigger(event.target.value);
    this.setState({ currentPage: 1 });
  };

  /**
   * it will filter `dropdownData` to find selected items and return it
   * @function getInitialSelection
   * @returns {Array<object>}
   */
  getInitialSelection = () => {
    const {
      dropdownMeta,
      dropdownData = [],
      dropBaseValue,
      useInFilterInput,
    } = this.props;

    const { valueMember, filterValueMember } = dropdownMeta;
    const valueFieldName =
      useInFilterInput && !!filterValueMember ? filterValueMember : valueMember;
    const selectedItemsInDropdownData = dropdownData.filter(item =>
      dropBaseValue.includes(String(item[valueFieldName])),
    );
    return selectedItemsInDropdownData;
  };

  render() {
    const {
      classes,
      resource,
      locale,
      isOpen,
      isLoading,
      dropdownMeta,
      dropdownData,
      dropdownTotal,
      translate,
      relationResource,
      relationSource,
      metaData,
      relationInfo,
      relationMetaData,
      addToRelationArray,
      dropdownMultipleSelection,
      dropBaseValue,
      useInFilterInput,
    } = this.props;

    if (!dropdownData) {
      return <div />;
    }

    const dropForceTreeLevel = lodashGet(dropdownMeta, 'forceTreeLevel', false);
    const dropTreeLevel = lodashGet(dropdownMeta, 'treeLevel');

    const {
      currentPage,
      perPage,
      filterValues,
      selectedIds,
      preparedIds,
      preparedData,
      preparedDataLastLevel,
      preparedLastLevelIds,
      preparedFields,
      isBulkDialogOpen,
      bulkDefaultData,
    } = this.state;

    const isSelectionEnabled =
      !!(relationResource && relationSource && relationMetaData) ||
      !!dropdownMultipleSelection;

    return (
      <>
        <Dialog
          id="search-popup-dialog-div"
          open={isOpen}
          onClose={this.closeDialog}
          maxWidth={this.props.width}
          classes={{
            paper: classes.dialog,
          }}
          aria-labelledby="show-image-dialog-title"
        >
          {(isLoading || !preparedFields) && <LoadingBox absolute />}
          <DialogTitle id="show-image-dialog-title">
            {isOpen && resource ? getTranslatedName(metaData, locale) : ''}
          </DialogTitle>
          {dropdownMeta.remoteSearch && (
            <div className={classes.searchContainer}>
              <TextField
                variant="outlined"
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
                margin="dense"
                label={translate('ra.action.search')}
                onChange={this.handleChange}
              />
            </div>
          )}
          {isOpen && (
            <TabParent className={classes.childrenContainer}>
              {preparedFields && resource && (
                <TabChild
                  label={translate('ra.action.list')}
                  onClick={this.loadDataForGrid}
                >
                  <DevExGrid
                    isDropDown={true}
                    hasShow={false}
                    hasEdit={false}
                    resource={resource}
                    data={
                      dropForceTreeLevel && dropdownMeta.type === 'Tree'
                        ? preparedDataLastLevel
                        : preparedData
                    }
                    ids={
                      dropForceTreeLevel && dropdownMeta.type === 'Tree'
                        ? preparedLastLevelIds
                        : preparedIds
                    }
                    // we don't want to enable sort
                    setSort={this.sortPopupDialog}
                    currentSort={this.state.sort}
                    fields={preparedFields}
                    relationMode={false}
                    setFilters={this.setFilters}
                    filterValues={filterValues}
                    isTopFilterOpen={true}
                    isGroupingOpen={false}
                    onRowClick={this.handleRowClick}
                    metaData={metaData}
                    enableSelection={isSelectionEnabled}
                    selectedIds={selectedIds}
                    onSelect={this.onSelect}
                    getInitialSelection={this.getInitialSelection}
                  />
                  <Pagination
                    page={currentPage}
                    perPage={perPage}
                    total={dropdownTotal}
                    setPerPage={this.changePerPage}
                    setPage={this.changePage}
                  />
                </TabChild>
              )}
              {dropdownMeta.type === 'Tree' && (
                <TabChild
                  label={translate('tree.tree')}
                  onClick={this.loadDataForTree}
                >
                  <TreeController
                    data={preparedData}
                    onRowClick={this.handleRowClick}
                    metaData={dropdownMeta}
                    forceTreeLevel={dropForceTreeLevel}
                    treeLevel={dropTreeLevel}
                    isDropdown={true}
                    useInFilterInput={useInFilterInput}
                  />
                </TabChild>
              )}
            </TabParent>
          )}
          {isSelectionEnabled && (
            <DialogActions className={classes.dialogAction}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                disabled={!selectedIds || !selectedIds.length}
                id="searchAddButton"
                onClick={
                  dropdownMultipleSelection
                    ? this.handleMultipleSelect
                    : this.openQuickCreateWithForSelectedIds
                }
              >
                {translate('ra.action.add')}
              </Button>
            </DialogActions>
          )}
        </Dialog>

        {isSelectionEnabled && (
          <MetaProvider resourceName={relationResource} noLoading>
            <FormRelationBulkInsertPopupDialogContainer
              isOpen={isBulkDialogOpen}
              defaultData={bulkDefaultData}
              resource={relationResource}
              source={relationSource}
              relationInfo={relationInfo}
              closeDialog={this.closeBulkDialog}
              addToRelationArray={addToRelationArray}
            />
          </MetaProvider>
        )}
      </>
    );
  }
}

SearchPopupDialogContainer.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  refreshDropdown: PropTypes.func,
  findDropdownData: PropTypes.func,
  refreshView: PropTypes.func,
  locale: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  resource: PropTypes.string,
  dropdownMeta: PropTypes.object,
  dropdownId: PropTypes.number,
  record: PropTypes.object,
  dropdownMultipleSelection: PropTypes.bool,
  useInFilterInput: PropTypes.bool,
};

const mapStateToProps = (state, { dropdownId }) => ({
  isLoading: !!state.dropdownOld[dropdownId + '_loading'],
  dropdownData: state.dropdownOld[dropdownId],
  dropdownTotal: state.dropdownOld[dropdownId + '_total'] || 0,
  globalParameters: lodashGet(
    state,
    'profile.globalParameters',
    defaultGlobalParameters,
  ),
});

const mapDispatchToProps = {
  refreshDropdown: refreshDropdownAction,
  refreshView: refreshViewAction,
  findDropdownData: findDropdownDataAction,
};

export default compose(
  withWidth(),
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  translate,
  LocaleHoc,
)(SearchPopupDialogContainer);
