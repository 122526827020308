import React, { useContext, FC } from 'react';
import { useLocale } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

import DynamicList from '../DynamicList';
import { updateReportWithCallbackAction } from '../../redux/report/action';
import {
  isReportExecutable,
  hasReportEditable,
  getTranslatedName,
} from '../../helper/MetaHelper';
import LoadingBox from '../../component/LoadingBox';
import { NewMetaContext } from '../../container/NewMetaContext';
import { getReportChildren } from '../../helper/meta-helper';
import { GeneralMetaData } from '../../helper/Types';

interface MultipleReportListContainerInterfaceProps {
  defaultTabIndex: string;
  match: Record<string, unknown>;
  metaData: GeneralMetaData;
  resource: string;
  showDevExtremeGrouping: boolean;
  showDevExtremeTopFilter: boolean;
  updateReport: Function;
  enableSelection: boolean;
  inAccordion?: boolean;
  accordionPortalId?: string;
}

interface ChildResourceInterface {
  metaData: GeneralMetaData;
  quickEditButton: boolean;
  quickEditRowCallback: Function | null;
  resource: string;
  title: string;
}

const MultipleReportListContainer: FC<
  MultipleReportListContainerInterfaceProps
> = props => {
  const {
    metaData,
    resource,
    updateReport,
    showDevExtremeTopFilter,
    showDevExtremeGrouping,
    defaultTabIndex,
    enableSelection = true,
    ...rest
  } = props;
  const locale = useLocale();

  const { getMeta } = useContext(NewMetaContext);

  const childResourceList: ChildResourceInterface[] = [];

  // if parent report is executable
  if (isReportExecutable(metaData)) {
    const isParentReportEditable = hasReportEditable(metaData);

    childResourceList.push({
      title: getTranslatedName(metaData, locale),
      resource,
      metaData,
      quickEditButton: isParentReportEditable,
      quickEditRowCallback: isParentReportEditable ? updateReport : null,
    });
  }

  getReportChildren(metaData, locale).forEach(({ childResource, title }, index) => {
    const childMetaData = getMeta(childResource);
    const isReportEditable = hasReportEditable(childMetaData);

    childResourceList.push({
      title: title || getTranslatedName(childMetaData, locale) || index,
      resource: childResource as string,
      metaData: childMetaData as GeneralMetaData,
      quickEditButton: isReportEditable,
      quickEditRowCallback: isReportEditable ? updateReport : null,
    });
  });

  // default tab's meta must be loaded before continuing
  if (!lodashGet(childResourceList, [defaultTabIndex, 'metaData'])) {
    return <LoadingBox />;
  }

  return (
    <DynamicList
      {...rest}
      metaData={metaData}
      resource={resource}
      childResourceList={childResourceList}
      defaultTabIndex={parseInt(defaultTabIndex)}
      hasList={true}
      hasCreate={false}
      hasEdit={false}
      hasShow={false}
      hasDelete={false}
      enableSelection={enableSelection}
      basePath={`/${resource}`}
      isTopFilterOpen={showDevExtremeTopFilter}
      isGroupingOpen={showDevExtremeGrouping}
      isMultiReport={true}
    />
  );
};

const mapDispatchToProps = {
  updateReport: updateReportWithCallbackAction,
};

export default compose(connect(null, mapDispatchToProps))(
  MultipleReportListContainer,
);
