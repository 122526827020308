import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    width: '100%',
  },
  btnRoot: {
    margin: '5px auto',
    background: '#1D85D6',
    color: '#fff',
  },
}));
