import { ReactElement, useEffect } from 'react';
import { useTranslate } from 'react-admin';

import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../../../../type/actor-setup';
import { FormActions, FormActionsHandler, InputRefContent } from '../../../form';
import { updateValuesOfInputsAndFormDataAfterSubmit } from '../../wms.helper';

import { WMSActionControllerProps } from './wms-action.type';
import { WMSActionView } from './wms-action.view';

let formActionsHandler: FormActionsHandler;
const WMSActionController = (props: WMSActionControllerProps): ReactElement => {
  const {
    actionMetaData,
    validationActionId,
    actionExecutionConfirm,
    formTabIndex,
    activeTabIndex,
  } = props;

  const translate = useTranslate();

  useEffect(() => {
    formActionsHandler = actorGetActionValue('formGlobalProps')!.formActionsHandler;
  }, []);

  /**
   * It calls main `formActionsHandler` to save the current data and rest the form
   * @function onSubmit
   * @returns void
   */
  // FIXME: Check this function to handle all necessary situations
  const onSubmit = () => {
    if (actionExecutionConfirm) {
      actorDispatch('quickDialog', {
        confirmationIsOpen: true,
        data: {
          content: translate('form.areYouSureAboutThisOperation'),
          onConfirm: () => {
            saveData();
          },
        },
      });
    } else {
      saveData();
    }
  };

  /**
   * It calls `save` section in the main controller to save the data
   * @function saveData
   * @returns void
   */
  const saveData = (): void => {
    formActionsHandler(FormActions.Save, {
      validationActionId,
      actionUniqueId: actionMetaData.uniqueId,
      onSuccess: response => {
        const { data } = response;

        // const rootResource = actorGetActionValue('resources')!.stack[0];
        // actorSetActionValue('formData', data, {
        //   path: `${rootResource.value}.${rootResource.type}`,
        //   replaceAll: true,
        //   callerScopeName: 'WMSActionController => saveData',
        // });

        const currentResource = actorGetActionValue('resources')!.current;

        const refs = actorGetActionValue(
          'inputsRef',
          `${currentResource.value}.${currentResource.type}`,
        )! as Record<string, InputRefContent>;

        const fieldNameList =
          actorGetActionValue('formGlobalProps')!.inputNameListSortedByPriority;

        updateValuesOfInputsAndFormDataAfterSubmit({
          fieldNameList,
          inputsRef: refs,
          newFormData: data,
        });
      },
      onFailure: () => {
        const formGlobalProps = actorGetActionValue('formGlobalProps')!;
        formGlobalProps.formFocusManagementFunctions.focusOnFirstInputAfterSubmit();
      },
    });
  };

  return (
    <WMSActionView
      formTabIndex={formTabIndex}
      onSubmit={onSubmit}
      activeTabIndex={activeTabIndex}
    />
  );
};

export default WMSActionController;
