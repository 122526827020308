import { FC, memo, useEffect, useState } from 'react';
import lodashGet from 'lodash/get';
import { MailListInterface, PaginationParams } from '.';
import {
  MailActions,
  MailInterface,
  OnChangePageHandlerParams,
  OnImportantMailParams,
  OnStarMailParams,
} from '..';
import { isEmptyObject } from '../../../helper/data-helper';
import { actorOnDispatch, actorSetActionValue } from '../../../type/actor-setup';

import MailListView from './mail-list.view';
import { setGridIdsForHandlePrevNextButton } from '../mail-section.helper';

const MailListController: FC<MailListInterface> = memo(props => {
  const { mailActionsHandler, checkedMails, visibleClassName } = props;

  const [mailData, setMailData] = useState<MailInterface[]>([]);
  const [pagination, setPagination] = useState<PaginationParams | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * @function onChangePageHandler
   * @param { number } page
   * @returns { void }
   */
  const onChangePageHandler = (page: number): void => {
    mailActionsHandler(MailActions.onChangePageHandler, {
      page,
    } as OnChangePageHandlerParams);
  };

  /**
   * @function onStarClick
   * @param {  MailInterface[] | null } mailsData
   * @param { boolean } isStarred
   * @returns { void }
   */
  const onStarClick = (
    mailsData: MailInterface[] | null,
    isStarred: boolean,
  ): void => {
    mailActionsHandler(MailActions.onStarMail, {
      mailsData,
      isStarred,
    } as OnStarMailParams);
  };

  /**
   * @function onImportantClick
   * @param {  MailInterface[] | null } mailsData
   * @param { boolean } isStarred
   * @returns { void }
   */
  const onImportantClick = (
    mailsData: MailInterface[] | null,
    isImportant: boolean,
  ): void => {
    mailActionsHandler(MailActions.onImportantMail, {
      mailsData,
      isImportant,
    } as OnImportantMailParams);
  };

  useEffect(() => {
    actorOnDispatch('mailData', res => {
      if (!isEmptyObject(res)) {
        res.data && setMailData(res.data);
        res.pagination && setPagination(res.pagination);
      }
    });
    actorOnDispatch('loading', loading => {
      setLoading(lodashGet(loading, [`mailList`]));
    });
  }, []);

  mailData.length && setGridIdsForHandlePrevNextButton(mailData);

  return (
    <MailListView
      mailActionsHandler={mailActionsHandler}
      mailData={mailData}
      checkedMails={checkedMails}
      loading={loading}
      pagination={pagination ?? ({} as PaginationParams)}
      onChangePageHandler={onChangePageHandler}
      onStarClick={onStarClick}
      onImportantClick={onImportantClick}
      visibleClassName={visibleClassName}
    />
  );
});

export default MailListController;
