import React, { FC } from 'react';
import { useLocale } from 'react-admin';
import ListActions from '../../ListActions';

import type { RelationActionBarViewProps } from './relation-action-bar.type';

const RelationActionBarView: FC<RelationActionBarViewProps> = props => {
  const {
    clearAllSelectedCheckboxes,
    onDeleteSuccessCallback,
    allowExport,
    relationPermission,
    toggleShowFilters,
    relationResource,
    relationMetaData,
    additionalProps,
    refreshRelation,
    quickCreateData,
    parentResource,
    mockedRelation,
    parentRecord,
    relationData,
    relationPath,
    selectedIds,
    currentUrl,
    parentInfo,
    getFilterValues,
  } = props;

  const { hasCreate, hasEdit, hasDelete, disabledFieldList } = relationPermission;
  const { childFieldName } = mockedRelation;

  const locale = useLocale();

  return (
    <ListActions
      clearAllSelectedCheckboxes={clearAllSelectedCheckboxes}
      onDeleteSuccessCallback={onDeleteSuccessCallback}
      toggleRefreshButton={refreshRelation}
      toggleShowFilters={toggleShowFilters}
      disabledFieldList={disabledFieldList}
      quickCreateData={quickCreateData}
      additionalProps={additionalProps}
      initialData={relationData?.data}
      childFieldName={childFieldName}
      filterValues={getFilterValues()}
      columnChoiceKey={relationResource}
      metaData={relationMetaData}
      resource={relationResource}
      selectedIds={selectedIds}
      allowExport={allowExport}
      parentInfo={parentInfo}
      redirect={currentUrl}
      isColumnChoice={true}
      hasCreate={hasCreate}
      hasDelete={hasDelete}
      hasEdit={hasEdit}
      locale={locale}
      listColumnChoiceMustRefresh
      quickCreateMustRefresh
      isRefreshEnabled
      relationMode
      hasShow
    />
  );
};

export default RelationActionBarView;
