import { makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { CustomTheme } from '../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  btnRoot: {
    '& .MuiIconButton-label': {
      width: 24,
      height: 24,
      transform: 'rotate(180deg)',
      color: blue[300],
    },
  },
  replySection: {
    borderRadius: 8,
  },

  chatInput: {
    '& input': {
      padding: 0,
    },
    '& fieldset': {
      border: 'none',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '10px 14px',
      border: 'none',
    },
  },
}));
