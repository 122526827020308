import React, { FC, memo } from 'react';
import { convertDigitsToEnglish } from '../../../helper/NumberHelper';
import { actorGetActionValue } from '../../../type/actor-setup';
import { ChangeFormValueParams, FormActions, OnBlurParams } from '../../form';

import { LongtextInputInterface } from './longtext-input.type';
import LongtextInputView from './longtext-input.view';

const LongtextInputController: FC<LongtextInputInterface> = memo(props => {
  //-----------------------------DESTRUCTURE PROPS--------------------------------------------------------
  const {
    className,
    textAlign = 'right',
    disabled,
    visibleClass,
    field,
    value,
    inputMessage,
    label,
    getRef,
    hint,
  } = props;

  const { name } = field;

  //-----------------------------ACTOR--------------------------------------------------------
  const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;

  /**
   * Handle Blur event
   * @function handleBlur
   * @returns {void}
   */
  const handleBlur = (): void => {
    formActionsHandler(FormActions.InputBlur, {
      fieldName: name,
      value,
    } as OnBlurParams);
  };

  /**
   * Handle focus event
   * @function handleFocus
   * @returns {void}
   */
  const handleFocus = (): void => {
    formActionsHandler(FormActions.InputFocus, {
      fieldName: name,
      value,
    } as ChangeFormValueParams);
  };

  /**
   * Handle Change event
   * @function handleChange
   * @param {ChangeEvent} event
   * @returns {void}
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const formattedValue = convertDigitsToEnglish(event.target.value);
    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value: formattedValue,
    } as ChangeFormValueParams);
  };

  return (
    <LongtextInputView
      getRef={getRef}
      field={field}
      value={value}
      inputMessage={inputMessage}
      className={className}
      visibleClass={visibleClass}
      label={label}
      hint={hint}
      disabled={disabled}
      handleBlur={handleBlur}
      handleFocus={handleFocus}
      handleChange={handleChange}
    />
  );
});

export default LongtextInputController;
