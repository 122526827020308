import { FC, memo, useEffect } from 'react';

import { RelationActionTopToolbarInterface } from './relation-action-top-toolbar.type';
import RelationActionTopToolbarView from './relation-action-top-toolbar.view';
import { isEmpty } from '../../helper/data-helper';
import { KEY_SCROLL_TO } from '../new-relation-panel/relation-panel.helper';

const RelationActionTopToolbarController: FC<RelationActionTopToolbarInterface> =
  memo(props => {
    const { list } = props;
    /**
     * useful to prevent click bubbling in a data grid with rowClick
     * @function stopPropagations
     * @param {React.MouseEvent<HTMLButtonElement>} event
     * @returns {void}
     */
    const stopPropagation = (event: React.MouseEvent<HTMLButtonElement>): void => {
      event.stopPropagation();

      const violation = document.getElementById(
        KEY_SCROLL_TO + event?.currentTarget.id,
      );
      if (violation) {
        violation.scrollIntoView({
          behavior: 'smooth',
        });
      }
    };
    useEffect(() => {
      const element = document.getElementById('main-toolbar-samian');
      if (!isEmpty(list) && list.length > 0) {
        if (element) {
          element.style.marginBottom = '44px';
        }
      }
      return () => {
        if (element) {
          element.style.marginBottom = '0px';
        }
      };
    });
    return (
      <RelationActionTopToolbarView stopPropagation={stopPropagation} list={list} />
    );
  });

export default RelationActionTopToolbarController;
