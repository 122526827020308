import React, { FC, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';
import lodashIsEqual from 'lodash/isEqual';
import compose from 'recompose/compose';

import { findOneAction as findDropdownDataAction } from '../../redux/dropdown/action';
import { getDropdownRequestParams } from '../../helper/DropdownHelper';
import MonthlyIcon from '../../icon/MonthlyIcon';
import YearlyIcon from '../../icon/YearlyIcon';
import WeeklyIcon from '../../icon/WeeklyIcon';
import DailyIcon from '../../icon/DailyIcon';
import BlockIcon from '@material-ui/icons/Block';
import TodoTaskDate from './TodoTaskDate';
import {
  getTodoDateRepeatTypeDropdownPatternInfo,
  getTodoTaskDetailsPatternInfo,
} from '../../helper/PatternMetaHelper';
import { isEmpty, isEmptyObject } from '../../helper/data-helper';
import { ContextMenuItemType } from '../../helper/Types';

interface TempItems {
  [name: string]: ContextMenuItemType;
}

interface Items {
  [name: string]: string;
}

interface DropdownMetaProps {
  id: string | number;
}

interface TodoTaskRepeatDateProps {
  isOpen: boolean;
  onClick: (event: Event) => void;
  findDropdownData: (object: object, callback?: Function) => void;
  dropdownMeta: DropdownMetaProps;
  dropdownData: Items[];
  loading: boolean;
  task: object;
  resource: string;
  taskId: number;
  onItemClick: Function;
  translate: Function;
  onRemoveValue: Function;
}

const TodoTaskRepeatDate: FC<TodoTaskRepeatDateProps> = props => {
  const {
    findDropdownData,
    dropdownData,
    loading,
    task,
    resource,
    onItemClick,
    onRemoveValue,
    dropdownMeta,
  } = props;

  const [items, setItems] = useState<ContextMenuItemType[]>([]);

  const translate = useTranslate();

  const { repeatEveryX, repeatType, repeatTypeTitle } =
    getTodoTaskDetailsPatternInfo(resource);

  useEffect(() => {
    if (!isEmptyObject(dropdownMeta)) {
      const params = getDropdownRequestParams({
        dropdownMeta,
        record: task,
        // search: !isEmpty(input.value) ? input.value : '',
      });
      if (!loading && !dropdownData) {
        findDropdownData({ id: dropdownMeta.id, params, meta: dropdownMeta });
      }
    }

    if (dropdownData && !items.length) {
      makeItemsForTodoTaskDate(dropdownData);
    }
  }, [dropdownMeta, items, dropdownData]);

  const makeItemsForTodoTaskDate = (data: Items[]) => {
    const tempItems: TempItems = {
      days: {
        dataTestAttribute: 'daily',
        title: translate('todo.daily'),
        icon: <DailyIcon />,
        [repeatEveryX]: 1,
      },
      weeks: {
        dataTestAttribute: 'weekly',
        title: translate('todo.weekly'),
        icon: <WeeklyIcon />,
        [repeatEveryX]: 1,
      },
      month: {
        dataTestAttribute: 'monthly',
        title: translate('todo.monthly'),
        icon: <MonthlyIcon />,
        [repeatEveryX]: 1,
      },
      year: {
        dataTestAttribute: 'yearly',
        title: translate('todo.yearly'),
        icon: <YearlyIcon />,
        [repeatEveryX]: 1,
      },
      // { name: 'weekDays', type: 'weeks', title: 'روزهای هفته', icon: <WeekDaysIcon /> },
    };

    let preparedItems: ContextMenuItemType[] = [];
    if (data && data.length) {
      preparedItems = data.map(item => {
        return {
          ...item,
          ...tempItems[item[repeatTypeTitle]],
        };
      });
    } else {
      preparedItems = [
        {
          dataTestAttribute: 'notFound',
          title: translate('todo.noOptionsMessage'),
          icon: <BlockIcon />,
          [repeatEveryX]: 0,
        },
      ];
    }

    if (!lodashIsEqual(items, preparedItems)) {
      setItems(preparedItems);
    }
  };

  /**
   *
   * parse selected date and returns its title
   * @function todoTaskDateParseDate
   * @param {string | null} id
   * @returns {string}
   */
  const todoTaskDateParseDate = (id: string | null): string => {
    if (!isEmpty(id) && items.length) {
      const found = items.find(item => item[repeatType] === id);
      if (found) {
        return found.title;
      }
    }
    return '';
  };

  return (
    <Fragment>
      <TodoTaskDate
        icon="RecurringEvent"
        title={translate('todo.repeat')}
        items={items}
        onItemClick={onItemClick}
        onRemoveValue={onRemoveValue}
        value={todoTaskDateParseDate(task[repeatType])}
        fieldName="repeat"
      />
    </Fragment>
  );
};

const mapStateToProps = (state, props) => {
  const dropdownMeta = getTodoDateRepeatTypeDropdownPatternInfo(
    'todoDateRepeatTypeDropdown',
  );
  const dropdownId = lodashGet(dropdownMeta, 'id');

  return {
    loading: !!lodashGet(state, ['dropdown', dropdownId + '_loading']), // loading status of data
    dropdownData: lodashGet(state, ['dropdown', dropdownId]),
    allLoadedData: lodashGet(state, ['dropdown', dropdownId + '_all'], []), // all previously loaded data
    dropdownError: lodashGet(state, ['dropdown', dropdownId + '_error']), // app dropdown data object
    task: lodashGet(
      state,
      ['admin', 'resources', props.resource, 'data', props.taskId],
      {},
    ),
    dropdownMeta,
  };
};

const mapDispatchToProps = {
  findDropdownData: findDropdownDataAction,
};

// do not remove compose!
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TodoTaskRepeatDate,
);
