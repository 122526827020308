import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import SellinHistoryOrderDetailView from '../component/shop/SellinHistoryOrderDetailView';
import ApiDetailGetter from '../container/ApiDetailGetter';

const SellinHistoryOrderDetailPage = props => {
  const { resource, id, ...rest } = props;

  return (
    <ApiDetailGetter {...rest} resource={resource} id={id}>
      {apiDetailGetterProps => (
        <SellinHistoryOrderDetailView {...rest} {...apiDetailGetterProps} />
      )}
    </ApiDetailGetter>
  );
};

SellinHistoryOrderDetailPage.propTypes = {
  resource: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { match }) => {
  const { moduleName, moduleTableName, id } = match.params;
  const resource = `${moduleName}/${moduleTableName}`;
  const basePath = `/${resource}`;

  return {
    resource,
    basePath,
    id,
  };
};

export default compose(connect(mapStateToProps))(SellinHistoryOrderDetailPage);
