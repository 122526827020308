import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import lodashFind from 'lodash/find';

import SellinFinalizeBasketView from '../component/shop/SellinFinalizeBasketView';
import ApiDetailGetter from '../container/ApiDetailGetter';
import { getSellinBasketFinalizePatternInfo } from '../helper/PatternMetaHelper';
import LoadingBox from '../component/LoadingBox';
import { isEmptyObject } from '../helper/data-helper';
import { finalizeBasketAction } from '../redux/shop/action';
import NotFound from '../component/NotFound';
import { SELLIN_HISTORY_ORDER_DETAIL } from '../core/configRouteConstant';

const SellinFinalizeBasketPage = props => {
  const { resource, finalizeBasket, ...rest } = props;

  const pattern = getSellinBasketFinalizePatternInfo(resource);
  const {
    settlementType,
    order,
    isDefault,
    id,
    redirectFinalizeResource,
    redirectCartResource,
  } = pattern;

  const submitOrder = data => {
    finalizeBasket({
      resource,
      data,
      redirectResource: `/${SELLIN_HISTORY_ORDER_DETAIL}/${redirectFinalizeResource}`,
    });
  };

  return (
    <ApiDetailGetter
      {...rest}
      resource={pattern.validateResource}
      cartResource={redirectCartResource}
    >
      {({ data, loading, error, ...apiDetailGetterProps }) => {
        if (error) {
          return <NotFound title={error} />;
        }

        if (loading || !data || isEmptyObject(data)) {
          return <LoadingBox />;
        }

        const defaultSettlementValue = `${
          lodashFind(data[settlementType], { [isDefault]: true })[id]
        }`;

        return (
          <SellinFinalizeBasketView
            {...rest}
            {...apiDetailGetterProps}
            order={data[order]}
            settlementType={data[settlementType]}
            defaultSettlementValue={defaultSettlementValue}
            resource={resource}
            pattern={pattern}
            submitOrder={submitOrder}
          />
        );
      }}
    </ApiDetailGetter>
  );
};

SellinFinalizeBasketPage.propTypes = {
  resource: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { match }) => {
  const { moduleName, moduleTableName } = match.params;
  const resource = `${moduleName}/${moduleTableName}`;
  const basePath = `/${resource}`;

  return {
    resource,
    basePath,
  };
};

const mapDispatchToProps = {
  finalizeBasket: finalizeBasketAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SellinFinalizeBasketPage,
);
