import React from 'react';
import compose from 'recompose/compose';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, IconButton } from '@material-ui/core';
import { getIconClassName } from '@uifabric/styling';
import { connect } from 'react-redux';

import { getTodoListPatternInfo } from '../../helper/PatternMetaHelper';
import { openTodoShareListDialogAction } from '../../redux/todoList/action';
import { isEmptyObject } from '../../helper/data-helper';

const useStyles = makeStyles(theme => ({
  iconButton: {
    margin: '0 5px',
    padding: 7,
  },

  icon: {
    fontSize: 16,
  },
}));

const TodoShareListButton = props => {
  const { resource, record, openTodoShareListDialog } = props;
  const classes = useStyles();

  const { listMemberResource } = getTodoListPatternInfo(resource);

  /**
   * it will call the openTodoShareListDialog actio from redux with four parameters
   *  @function shareList
   *  @returns {void}
   */
  const shareList = (): void => {
    const { shareListParentId } = getTodoListPatternInfo(resource);
    openTodoShareListDialog(resource, listMemberResource, record, shareListParentId);
  };

  return (
    <Tooltip title="add person">
      <div>
        <IconButton
          color="primary"
          className={classes.iconButton}
          onClick={shareList}
          disabled={isEmptyObject(record)}
        >
          <i className={`${classes.icon} ${getIconClassName('addFriend')}`}></i>
        </IconButton>
      </div>
    </Tooltip>
  );
};

TodoShareListButton.propTypes = {};

const mapDispatchToProps = {
  openTodoShareListDialog: openTodoShareListDialogAction,
};

export default compose(connect(null, mapDispatchToProps))(TodoShareListButton);
