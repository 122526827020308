import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { useStyles } from './mail-section.style';
import { AppContainer } from '../app-container';
import { MailSidebar } from './mail-sidebar';
import { MailList } from './mail-list';
import { MailDetail } from './mail-detail';
import { getParamFromUrl } from '../../helper/UrlHelper';
import { isEmpty } from '../../helper/data-helper';
import { AppDialog } from '../app-dialog';
import { MailForm, NewFolderForm } from './mail-form';
import { MailFormDialogActions } from './mail-form-dialog-actions';
import { MailSectionViewInterface, SavedFabData } from './mail-section.type';
import {
  staticArchiveFormFields,
  staticNewFolderFields,
  staticNewMessageFormFieldsData,
} from './mail-form/mail-form.helper';
import { MailFormToolbar } from './mail-form-toolbar';
import { MailFormAction } from './mail-form/mail-form-action';
import { MailFab } from '../app-drawer/mail-fab';

const MailSectionView = (props: MailSectionViewInterface): ReactElement => {
  const {
    mailActionsHandler,
    checkedMails,
    isNewMessageFormOpen,
    isReferenceFormOpen,
    isNewFolderFormOpen,
    handleCloseReferenceForm,
    handleCloseNewMessageForm,
    handleCloseNewFolderForm,
    isMailFormFullScreen,
    referenceFormTitle,
    onCreateNewFolder,
    handleFabOpen,
    handleFabRemove,
    draftMails,
  } = props;
  const classes = useStyles();
  const location = useLocation();
  const translate = useTranslate();

  /**
   * @function onGetMainComponent
   * @returns { ReactElement }
   */
  const onGetMainComponent = (): ReactElement => {
    const uniqueId: string = getParamFromUrl(location.search, 'uniqueid')!;
    const refrencesId: string = getParamFromUrl(location.search, 'refrencesid')!;
    const isMailListHidden = !isEmpty(uniqueId) && refrencesId != 'null';
    return (
      <>
        <MailList
          mailActionsHandler={mailActionsHandler}
          checkedMails={checkedMails}
          visibleClassName={isMailListHidden ? 'displayNone' : ''}
        />
        {isMailListHidden && <MailDetail mailActionsHandler={mailActionsHandler} />}
      </>
    );
  };

  return (
    <AppContainer
      sidebarContent={<MailSidebar mailActionsHandler={mailActionsHandler} />}
    >
      {onGetMainComponent()}
      {isNewMessageFormOpen && (
        <AppDialog
          open={isNewMessageFormOpen}
          handleClose={handleCloseNewMessageForm}
          dialogTitle={
            <MailFormToolbar
              mailActionsHandler={mailActionsHandler}
              title={translate('mail.newMessage')}
              onClose={handleCloseNewMessageForm}
              isFullScreen={isMailFormFullScreen}
            />
          }
          dialogContent={
            <MailForm
              fields={staticNewMessageFormFieldsData}
              formContainerClass={classes.formContainer}
              inputContainerClass={classes.inputContainer}
              data-test="new-message-form"
            />
          }
          dialogActions={
            <MailFormDialogActions
              mailActionsHandler={mailActionsHandler}
              handleCloseNewMessageForm={handleCloseNewMessageForm}
              formName={'newMessage'}
            />
          }
          maxWidth="md"
          fullScreen={isMailFormFullScreen}
          disableBackdropClick={true}
        />
      )}

      {isReferenceFormOpen && (
        <AppDialog
          open={isReferenceFormOpen}
          handleClose={handleCloseReferenceForm}
          dialogTitle={
            <MailFormToolbar
              mailActionsHandler={mailActionsHandler}
              title={
                !isEmpty(referenceFormTitle)
                  ? referenceFormTitle
                  : translate('mail.toReference')
              }
              onClose={handleCloseReferenceForm}
            />
          }
          dialogContent={
            <MailForm
              fields={staticArchiveFormFields}
              formContainerClass={classes.referenceFormContainer}
              inputContainerClass={classes.inputContainer}
              data-test="refer-form"
              showBCCTextButton={false}
            />
          }
          dialogActions={
            <MailFormDialogActions
              mailActionsHandler={mailActionsHandler}
              handleCloseNewMessageForm={handleCloseReferenceForm}
              formName={'reply'}
            />
          }
          maxWidth="md"
          disableBackdropClick={true}
        />
      )}

      {isNewFolderFormOpen && (
        <AppDialog
          open={isNewFolderFormOpen}
          contentClassName={classes.newFolderFormContainer}
          handleClose={handleCloseNewFolderForm}
          dialogTitle={translate('mail.createFolder')}
          dialogContent={<NewFolderForm fields={staticNewFolderFields} />}
          dialogActions={
            <MailFormAction
              onCancel={handleCloseNewFolderForm}
              onSubmit={onCreateNewFolder}
            />
          }
          maxWidth="sm"
          disableBackdropClick={true}
        />
      )}
      {draftMails && (
        <div className={classes.fabContainer}>
          {draftMails.map((mail: SavedFabData) => (
            <MailFab
              mail={mail}
              handleFabOpen={handleFabOpen}
              handleFabRemove={handleFabRemove}
            />
          ))}
        </div>
      )}
    </AppContainer>
  );
};

export default MailSectionView;
