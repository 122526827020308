import React from 'react';
import { compose } from 'recompose';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  IconButton,
  Table,
  TableBody,
  Typography,
  withStyles,
} from '@material-ui/core';
import { translate } from 'react-admin';

import TabParent from '../form-component-old/TabParent';
import TabChild from '../form-component-old/TabChild';
import DroppableFieldCell from './DroppableFieldCell';
import DroppableEmptyCell from './DroppableEmptyCell';
import ConfirmDialogHOC from '../../container/ConfirmDialogHOC';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: '100%',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
  },

  groupContainer: {
    margin: 30,
    display: 'flex',
    flexDirection: 'column',
  },

  groupItem: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '0 0 5px 5px',
  },

  tabChildLabel: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  table: {
    borderCollapse: 'unset',
    borderSpacing: 10,
    tableLayout: 'fixed',
  },

  groupHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    backgroundColor: theme.palette.grey[200],
    borderRadius: '5px 5px 0 0',
  },

  fieldItem: {
    padding: '15px 20px',
    margin: '10px 0',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& span': {
      opacity: 0,
      transition: 'all 200ms',
    },
    '&:hover span': {
      opacity: 1,
      transition: 'all 250ms',
    },
  },

  fieldIcon: {
    color: theme.palette.grey[500],
    cursor: 'pointer',
    margin: '0 2px',
  },

  newSectionBtn: {
    margin: '7px 12px',
    padding: '12px 20px',
  },

  tab: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  buttonToolbar: {
    padding: 10,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
});

const FormLayoutEditor = props => {
  const {
    classes,
    translate,
    children,
    openConfirmDialog,
    locale,
    tabList,
    addNewTab,
    openTabInfoDialog,
    removeTab,
    openGroupInfoDialog,
    moveField,
    removeField,
    onActiveTabChange,
    addNewGroup,
    removeGroup,
    addNewGroupRow,
    removeGroupRow,
    openFieldInfoDialog,
    theme,
  } = props;

  const componentList = tabList.map((tab, tabIndex) => (
    <TabChild
      key={tab.id}
      label={
        <div className={classes.tab}>
          <Typography>
            {lodashGet(tab, ['translatedTitle', locale], tab.id)}
          </Typography>
          <div style={{ display: 'flex' }}>
            <Icon
              className={classes.fieldIcon}
              onClick={() => openTabInfoDialog(tabIndex)}
            >
              settings
            </Icon>
            <Icon
              className={classes.fieldIcon}
              onClick={() => {
                openConfirmDialog({
                  content: translate('ra.message.delete_content'),
                  onConfirm: () => {
                    removeTab(tabIndex);
                  },
                  color: theme.palette.error.main,
                });
              }}
            >
              close
            </Icon>
          </div>
        </div>
      }
    >
      {tab.groupList.map((group, groupIndex) => (
        <div key={group.id} className={classes.groupContainer}>
          <div className={classes.groupHeader}>
            <Typography variant="body2">
              {lodashGet(group, ['translatedTitle', locale], group.id)}
            </Typography>
            <div>
              <IconButton onClick={() => openGroupInfoDialog(groupIndex)}>
                <Icon>settings</Icon>
              </IconButton>
              <IconButton
                onClick={() => {
                  openConfirmDialog({
                    content: translate('ra.message.delete_content'),
                    onConfirm: () => {
                      removeGroup(groupIndex);
                    },
                    color: theme.palette.error.main,
                  });
                }}
              >
                <Icon>close</Icon>
              </IconButton>
            </div>
          </div>
          <div className={classes.groupItem}>
            <Table className={classes.table}>
              <TableBody>
                {group.layout.map((rowArray, rowIndex) => (
                  <tr key={rowIndex}>
                    {rowArray.map((field, index) => {
                      if (field) {
                        return (
                          <DroppableFieldCell
                            field={field}
                            index={index}
                            key={`${groupIndex}/${rowIndex}/${index}/${Date.now()}`}
                            source={`${groupIndex}/${rowIndex}/${index}`}
                            caption={lodashGet(
                              field,
                              ['translatedCaption', locale],
                              field.name,
                            )}
                            locale={locale}
                            moveField={moveField}
                            removeField={removeField}
                            openFieldInfoDialog={openFieldInfoDialog}
                            isTable
                          />
                        );
                      }

                      return (
                        <DroppableEmptyCell
                          key={`${groupIndex}/${rowIndex}/${index}/${Date.now()}`}
                          source={`${groupIndex}/${rowIndex}/${index}`}
                          index={index}
                          moveField={moveField}
                        />
                      );
                    })}
                    <td style={{ width: 50 }}>
                      <IconButton
                        className={classes.fieldIcon}
                        onClick={() => {
                          openConfirmDialog({
                            content: translate('ra.message.delete_content'),
                            onConfirm: () => {
                              removeGroupRow(tabIndex, groupIndex, rowIndex);
                            },
                            color: theme.palette.error.main,
                          });
                        }}
                      >
                        <Icon>close</Icon>
                      </IconButton>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <IconButton
                      className={classes.fieldIcon}
                      onClick={() => addNewGroupRow(tabIndex, groupIndex)}
                    >
                      <Icon>add</Icon>
                    </IconButton>
                  </td>
                </tr>
              </TableBody>
            </Table>
          </div>
        </div>
      ))}
      <div>
        <Button
          variant="contained"
          color="primary"
          className={classes.newSectionBtn}
          onClick={addNewGroup}
        >
          {translate('formLayout.addNewSection')}
        </Button>
      </div>
    </TabChild>
  ));

  componentList.push(
    <TabChild key="new tab" label={translate('form.newTab')} onClick={addNewTab}>
      <div />
    </TabChild>,
  );

  return (
    <div className={classes.root}>
      <TabParent className={classes.container} onChange={onActiveTabChange}>
        {componentList}
      </TabParent>
      <div className={classes.buttonToolbar}>{children}</div>
    </div>
  );
};

FormLayoutEditor.propTypes = {
  locale: PropTypes.string.isRequired,
  tabList: PropTypes.array.isRequired,
  addNewTab: PropTypes.func.isRequired,
  openTabInfoDialog: PropTypes.func.isRequired,
  removeTab: PropTypes.func.isRequired,
  openGroupInfoDialog: PropTypes.func.isRequired,
  onActiveTabChange: PropTypes.func.isRequired,
  moveField: PropTypes.func.isRequired,
  removeField: PropTypes.func.isRequired,
  addNewGroup: PropTypes.func.isRequired,
  removeGroup: PropTypes.func.isRequired,
  addNewGroupRow: PropTypes.func.isRequired,
  removeGroupRow: PropTypes.func.isRequired,
  openFieldInfoDialog: PropTypes.func.isRequired,
};

export default compose(
  ConfirmDialogHOC,
  withStyles(styles, { withTheme: true }),
  translate,
)(FormLayoutEditor);
