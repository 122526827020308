import { GET_LIST } from 'react-admin';
import lodashDebounce from 'lodash/debounce';

import dataProvider from '../core/dataProvider';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../type/actor-setup';
import {
  localStorageGetItem,
  removeValue,
  setValue,
  WEB_SETTING_ITEMS,
  getValue,
  SESSION_ID,
} from '../core/configProvider';
import { isEmpty } from '../helper/data-helper';

const settingResource = 'appcore/websetting';

const changeResourcesHandler = async (): Promise<void> => {
  const userSessionId = getValue(SESSION_ID);
  if (isEmpty(userSessionId)) return;

  actorDispatch('showSettingsLoading', true);

  const appSettings = actorGetActionValue('appSettings');
  if (appSettings?.original != null) {
    actorDispatch('showSettingsLoading', false);
    return;
  }

  //get setting from localStorage
  const webSettingItems = localStorageGetItem(WEB_SETTING_ITEMS);
  if (webSettingItems && !isEmpty(webSettingItems)) {
    actorDispatch('appSettings', {
      original: webSettingItems,
      objectList: {},
    });
    actorDispatch('showSettingsLoading', false);
    return;
  }

  try {
    const getSettingsResult = await dataProvider(GET_LIST, settingResource, {
      pagination: {
        page: 1,
        perPage: 999999,
      },
      sort: {
        field: 'id',
        order: 'DESC',
      },
    });

    setValue(WEB_SETTING_ITEMS, getSettingsResult?.data);
    actorDispatch('appSettings', {
      original: getSettingsResult?.data,
      objectList: {},
    });
  } catch (error) {
    console.error('Getting the app settings error ', error);

    removeValue(WEB_SETTING_ITEMS);
    actorDispatch('appSettings', {
      original: [],
      objectList: {},
    });
  }

  actorDispatch('showSettingsLoading', false);
};

actorOnDispatch('resources', lodashDebounce(changeResourcesHandler, 100));
