import { FC } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useLocale } from 'react-admin';

import { RelationActionBar } from '../../new-relation-panel/relation-action-bar';

import type { GridDataInterface } from '../../../type/actor-setup';
import type { GeneralMetaData } from '../../../helper/Types';
import type {
  RelationInMetaData,
  RelationPermissions,
  RelationType,
} from '../../new-relation-panel';

const useStyles = makeStyles(() => ({
  relationActionBarContainer: {
    display: 'flex',
  },
}));

interface RelationPanelSummaryViewProps {
  relationType: RelationType;
  relationItemInMetaData: RelationInMetaData;
  relationResource: string;
  parentResource: string;
  relationPermission: RelationPermissions;
  relationPath: string;
  currentUrl: string;
  relationMetaData: GeneralMetaData | null;
  relationData: GridDataInterface | null;
  parentInfo: {
    parentResource: string;
    parentId: string;
    parentProcessUniqueId: string | null;
    parentPositionId: string | null;
    parentStateId: string | null;
  };
  parentRecord: Record<string, unknown>;
  clearAllSelectedCheckboxes: () => void;
  refreshRelation: () => void;
  hideActions: boolean;
}

const RelationPanelSummaryView: FC<RelationPanelSummaryViewProps> = props => {
  const {
    relationType,
    relationItemInMetaData,
    relationResource,
    parentResource,
    relationPermission,
    relationPath,
    currentUrl,
    relationMetaData,
    relationData,
    parentInfo,
    parentRecord,
    clearAllSelectedCheckboxes,
    refreshRelation,
    hideActions,
  } = props;

  const locale = useLocale();
  const classes = useStyles();

  if (!relationMetaData) {
    return (
      <Typography variant="body2">
        {relationItemInMetaData?.['translatedTitle']?.[locale] ??
          relationItemInMetaData?.['title'] ??
          relationItemInMetaData?.['moduleTableTitle']}
      </Typography>
    );
  }

  const {
    moduleTableTitle,
    translatedTitle,
    title,
    reportId,

    moduleName,
    moduleTableName,
    childFieldName,
    parentFieldName,
  } = relationItemInMetaData ?? {};

  const relationTitle = translatedTitle?.[locale] ?? moduleTableTitle ?? title;

  return (
    <>
      <Typography variant="body2">{relationTitle}</Typography>
      {!hideActions && (
        <div
          id={'action-bar-accordion-' + reportId}
          className={classes.relationActionBarContainer}
        >
          {relationItemInMetaData && relationType !== 'note' && (
            <RelationActionBar
              relationType={relationType}
              relationResource={relationResource}
              parentResource={parentResource}
              mockedRelation={{
                moduleName: moduleName!,
                moduleTableName: moduleTableName!,
                childFieldName: childFieldName!,
                parentFieldName: parentFieldName!,
              }}
              relationPermission={relationPermission}
              relationPath={relationPath}
              currentUrl={currentUrl}
              relationMetaData={relationMetaData}
              relationData={relationData}
              parentInfo={parentInfo}
              parentRecord={parentRecord}
              clearAllSelectedCheckboxes={clearAllSelectedCheckboxes}
              refreshRelation={refreshRelation}
              additionalProps={{
                // TODO: remove this poison ASAP 🤦🏽‍♂️ 🤦🏽‍♂️
                originalRecord: parentRecord,
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default RelationPanelSummaryView;
