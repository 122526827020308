import { FIND_ONE, FIND_ONE_SUCCESS, FIND_ONE_FAILED } from './constant';

export function findOneAction({ resource, params }) {
  return {
    type: FIND_ONE,
    resource,
    params,
  };
}

export function findOneSuccessAction({ resource, data }) {
  return {
    type: FIND_ONE_SUCCESS,
    resource,
    data,
  };
}

export function findOneFailedAction({ resource, error }) {
  return {
    type: FIND_ONE_FAILED,
    resource,
    error,
  };
}
