import React, { FC, useRef } from 'react';
import querystring from 'qs';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import { SaveButton, Toolbar, useTranslate } from 'react-admin';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core';

import { isEmpty, isEmptyObject } from '../helper/data-helper';
import { isSingleRecordTable } from '../helper/MetaHelper';
import { closeDialogAction } from '../redux/quickCreate/action';
import CustomFormButton from './form-component-old/CustomFormButton';

interface DumbButtonInterface {
  children: React.ReactNode;
  id: string;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

interface QuickCreateButtonToolbarInterface {
  formData?: object;
  closeDialog: Function;
  metaData?: object;
  disableFullFormButton: boolean;
  saving?: boolean;
  record?: { __processuniqueid: string | number };
  redirectToPage?: Function;
  resource?: string;
  redirect?: string;
  parentInfo?: object;
  handleSubmit?: Function;
  customSubmit?: Function;
  isFromDropdown?: boolean;
  closeMainDialog?: Function;
  editedFormData?: object;
}

const useStyles = makeStyles(() => ({
  dumbButton: {
    margin: '0 5px',
  },

  saveIconButton: {
    margin: '0 5px',
  },

  spacer: {
    display: 'none',
  },

  mobileToolbar: {
    padding: 0,
  },
}));

// for render buttonwith custom props
const DumbButton = (props: DumbButtonInterface) => {
  const { onClick, children, id, disabled } = props;
  const classes = useStyles();

  return (
    <Button
      className={classes.dumbButton}
      onClick={onClick}
      id={id}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

// for render save icon in button
const SaveIconButton = () => {
  const classes = useStyles();

  return (
    <Icon className={classes.saveIconButton} fontSize="small">
      save
    </Icon>
  );
};

/**
 * this function will close dialog and redirect page into another page base of params
 * @function redirectWithData
 * @param {function} closeDialog
 * @param {function} redirectToPage
 * @param {string} resource
 * @param {object|null} formData
 * @param {string} redirect
 * @param {object} parentInfo
 * @returns {void}
 */
const redirectWithData = (
  closeDialog: Function,
  redirectToPage: Function,
  resource: string,
  formData: object = {},
  redirect: string,
  parentInfo: object = {},
  isFromDropdown = false,
  closeMainDialog: Function,
) => {
  const parentResource = lodashGet(parentInfo, 'parentResource', null);
  const parentProcessUniqueId = lodashGet(parentInfo, 'parentProcessUniqueId', null);
  const parentPositionId = lodashGet(parentInfo, 'parentPositionId', null);
  const parentStateId = lodashGet(parentInfo, 'parentStateId', null);

  closeDialog();

  if (isFromDropdown) {
    closeMainDialog(); // dropdown parent quick create/edit diolog
  }

  if (parentInfo && !isEmptyObject(parentInfo)) {
    redirectToPage(
      `/${resource}/create?${querystring.stringify(formData, {
        strictNullHandling: true,
      })}` +
        (!isEmpty(parentResource) ? `&parentResource=${parentResource}` : '') +
        (!isEmpty(parentProcessUniqueId)
          ? `&parentProcessUniqueId=${parentProcessUniqueId}`
          : '') +
        (!isEmpty(parentPositionId) ? `&parentPositionId=${parentPositionId}` : '') +
        (!isEmpty(parentStateId) ? `&parentStateId=${parentStateId}` : '') +
        (!isEmpty(redirect) ? `&redirect=${redirect}` : ''),
    );
  } else {
    redirectToPage(
      `/${resource}/create?${querystring.stringify(formData, {
        strictNullHandling: true,
      })}` + (!isEmpty(redirect) ? `&redirect=${redirect}` : ''),
    );
  }
};

const emptyFunction = () => {};

const QuickCreateButtonToolbar: FC<QuickCreateButtonToolbarInterface> = props => {
  const {
    formData,
    closeDialog,
    metaData,
    disableFullFormButton,
    saving,
    record,
    redirectToPage = emptyFunction,
    resource = '',
    redirect = '',
    parentInfo,
    customSubmit,
    isFromDropdown = false,
    closeMainDialog = emptyFunction,
    editedFormData,
    ...rest
  } = props;

  const classes = useStyles();
  const translate = useTranslate();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  // it should not render save and new button if it was singleRecord
  const isSingleRecord = isSingleRecordTable(metaData);

  /**
   * it will call closeDialog function from props (its a redux action to close dialog)
   * @function handleCloseDialogClick
   * @returns {void}
   */
  const handleCloseDialogClick = (): void => {
    closeDialog();
  };

  /**
   * Handle call `submitAndView` or submitAndNew
   * @function handleCustomSave
   * @returns {void}
   */
  const handleCustomSave = type => (): void => {
    if (customSubmit) {
      customSubmit(formData, null, { [type]: true });
    }
  };

  return (
    <Toolbar
      {...rest}
      classes={{ spacer: classes.spacer, mobileToolbar: classes.mobileToolbar }}
    >
      <SaveButton
        id={isFromDropdown ? 'dropdownQuickCreateFormSave' : 'quickCreateFormSave'}
        classes={{
          button: classes.dumbButton,
        }}
        icon={<SaveIconButton />}
        label="ra.action.save"
        redirect={false}
        submitOnEnter={false}
      />

      {!isSingleRecord && (
        <CustomFormButton
          id={
            isFromDropdown
              ? 'dropdownFormSaveAndViewButton'
              : 'formSaveAndViewButton'
          }
          onClick={handleCustomSave('isSaveAndView')}
          disabled={saving || !!record!.__processuniqueid}
          variant="text"
          label={translate('form.createAndView')}
          buttonRef={buttonRef}
        />
      )}

      {!isSingleRecord && (
        <CustomFormButton
          id={
            isFromDropdown ? 'dropdownFormSaveAndNewButton' : 'formSaveAndNewButton'
          }
          onClick={handleCustomSave('isSaveAndNew')}
          disabled={saving || !!record!.__processuniqueid}
          variant="text"
          label={translate('form.createAndNew')}
          buttonRef={buttonRef}
        />
      )}

      {!disableFullFormButton && (
        <DumbButton
          id={
            isFromDropdown
              ? 'dropdownQuickCreateFormFullInsertButton'
              : 'quickCreateFormFullInsertButton'
          }
          onClick={() =>
            redirectWithData(
              closeDialog,
              redirectToPage,
              resource,
              editedFormData,
              redirect,
              parentInfo,
              isFromDropdown,
              closeMainDialog,
            )
          }
          disabled={!formData}
        >
          {translate('quickCreate.fullForm')}
        </DumbButton>
      )}

      <DumbButton
        onClick={handleCloseDialogClick}
        id={isFromDropdown ? 'dropdownCancellButton' : 'cancellButton'}
      >
        {translate('ra.action.cancel')}
      </DumbButton>
    </Toolbar>
  );
};

const mapDispatchToProps = {
  redirectToPage: push,
  closeMainDialog: closeDialogAction,
};

export default connect(null, mapDispatchToProps)(QuickCreateButtonToolbar);
