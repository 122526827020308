import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    backgroundImage: ` url("/assets/image/telegram-bg.jpg")`,
    minHeight: '80%',
    height: '-webkit-fill-available',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  scrollDiv: {
    width: '100%',
    '&::-webkit-scrollbar': {
      width: 3,
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-track-piece': {
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#fff',
    },
    '&:hover': {
      '&::-webkit-scrollbar': {
        backgroundColor: 'initial',
      },
      '&::-webkit-scrollbar-track-piece': {
        backgroundColor: 'initial',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0, .3)',
      },
    },
    height: 740,
    [theme.breakpoints.up('xl')]: {
      height: '98%',
    },
    [theme.breakpoints.down('lg')]: {
      height: '98%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  infiniteScroll: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  scrollDownBtn: {
    background: theme.palette.grey[400],
    color: '#fff',
  },
  customBadge: {
    color: '#fff',
  },
}));
