import lodashFind from 'lodash/find';
import lodashGet from 'lodash/get';
import { isEmptyObject } from './data-helper';

const colorTable = {
  0: '#ef5350',
  1: '#ba68c8',
  2: '#42a5f5',
  3: '#29b6f6',
  4: '#66bb6a',
  5: '#9ccc65',
  6: '#ffee58',
  7: '#ffa762',
  8: '#bdbdbd',
  9: null,
};

export const getColorById = id => {
  return colorTable[id];
};

/**
 * gets all fields and finds row state color `relatedName`.
 * @function findRowStateColorKey
 * @param {object} fields
 * @returns {String} row state color key
 */
export const findRowStateColorKey = fields => {
  if (isEmptyObject(fields)) {
    return;
  }

  const targetField = lodashFind(
    fields,
    field => field.name === 'rowstatecolor' || field.title === 'rowstatecolor',
  );

  return targetField?.relatedName ?? targetField?.title ?? undefined;
};
