import { FC, memo } from 'react';
import { UploadedFile } from '../..';
import { isEmptyObject } from '../../../../helper/data-helper';
import { actorDispatch, actorGetActionValue } from '../../../../type/actor-setup';

import { FileCardInterface } from './file-card.type';
import FileCardView from './file-card.view';

const FileCardController: FC<FileCardInterface> = memo(props => {
  const { file } = props;

  /**
   * @function onDeleteFileHandler
   * @returns { void }
   */
  const onDeleteFileHandler = (): void => {
    const currentUploadedFilesData = actorGetActionValue('uploadedFiles')!;
    if (!isEmptyObject(currentUploadedFilesData))
      [
        actorDispatch(
          'uploadedFiles',
          currentUploadedFilesData.filter(
            (item: UploadedFile) => item.filePath != file.filePath,
          ),
        ),
      ];
  };

  return <FileCardView file={file} onDeleteFileHandler={onDeleteFileHandler} />;
});

export default FileCardController;
