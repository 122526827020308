import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  checkboxRoot: {
    marginRight: 8,
  },
  pointer: {
    cursor: 'pointer',
  },
  iconBlock: {
    display: 'block',
  },
}));
