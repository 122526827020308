import { ReactElement } from 'react';
import { useLocale } from 'react-admin';
import { useTheme } from '@material-ui/core';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

import { FinalLayoutView } from '../wms.view';
import { useStyles } from '../wms.style';
import { WMSLayoutWithTabsViewPropsInterface } from './wms-layout-with-tabs.type';

export const WMSLayoutWithTabsView = (
  props: WMSLayoutWithTabsViewPropsInterface,
): ReactElement => {
  const { currentTabIndex, handleTabChange, wmsMetaData } = props;
  const { tabs: wmsTabList } = wmsMetaData;

  const theme = useTheme();
  const classes = useStyles();
  const locale = useLocale();

  return (
    <>
      <Tabs
        className={classes.tabsRoot}
        defaultActiveKey={'0'}
        direction={theme.direction}
        onChange={handleTabChange}
      >
        {wmsTabList.map((tabData, index) => {
          return (
            <TabPane
              className={classes.tabRoot}
              key={index}
              tab={<p>{tabData.translatedTitle?.[locale] ?? tabData.title ?? ''}</p>}
            >
              <FinalLayoutView
                wmsMetaData={wmsMetaData}
                tabData={tabData}
                formTabIndex={index}
                activeTabIndex={currentTabIndex}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </>
  );
};
