import { actorDispatch } from '../type/actor-setup';

import type { ShowNotification, Translate } from './Types';

/**
 * @function showNotification
 * @return {void}
 */
export const showNotification: ShowNotification = (
  message,
  type = 'info',
  options,
): void => {
  let _message = message;
  let requestId;
  let codeNumber;
  if (String(message).includes('^') && !options?.forceSnackbar) {
    const msgArr = String(message).split('^');
    _message = msgArr[0];
    if (String(message).includes('CODE')) {
      requestId = msgArr[1]?.split('CODE')[0];
      codeNumber = msgArr[1]?.split('CODE')[1];
    } else {
      requestId = msgArr[1];
    }
  }
  const _options = { ...options, requestId, codeNumber };
  actorDispatch('notification', { message: _message, type, options: _options });
};

/**
 * @function hiddenNotifications
 * @returns {void}
 */
export const hiddenNotifications = (): void => {
  actorDispatch('notification', null, { replaceAll: true });
};

/**
 * @function setLoading
 * @param { string } path
 * @param { boolean } loading
 * @returns { void }
 */
export const setLoading = (path: string, loading: boolean): void => {
  actorDispatch('loading', loading, {
    path,
  });
};

/**
 * handle unknown error
 * @function showNotificationForUnknownError
 * @param {unknown} error
 * @param {Translate} translate
 * @param {boolean} showInDialog
 * @returns {void} void
 */
export const showNotificationForUnknownError = (
  error: unknown,
  translate: Translate,
  showInDialog?: boolean,
): void => {
  if (typeof error === 'string') {
    showNotification(error, 'error', { forceSnackbar: showInDialog ? false : true });
  } else if (typeof error === 'object' && error?.['message']) {
    showNotification(error['message'], 'error', {
      forceSnackbar: showInDialog ? false : true,
    });
  } else {
    showNotification(translate('ra.notification.data_provider_error'), 'error', {
      forceSnackbar: showInDialog ? false : true,
    });
  }
};
