import { ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Box from '@material-ui/core/Box';
import { MessagesListViewInterface } from './messages-list.type';
import { MessageInterface, SelectedUserType } from '../../..';
import LoadingBox from '../../../../loading-box';
import { MessageItem } from './message-item';
import { useStyles } from './messages-list.style';
import { Badge, Fab } from '@material-ui/core';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';

const MessagesListView = (props: MessagesListViewInterface): ReactElement => {
  const {
    chatActionsHandler,
    userMessages,
    fetchMoreOnTop,
    hasMoreMessages,
    authUser,
    selectedUser,
    scrollRef,
    onScrollDown,
    onScrollTop,
    showScrollDownBtn,
    handleOnScroll,
  } = props;

  const _authUser = authUser as SelectedUserType;

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <div
        id="scrollableDiv"
        className={classes.scrollDiv}
        style={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        <InfiniteScroll
          data-style-list-message="listMessage"
          ref={scrollRef}
          dataLength={userMessages && userMessages.length ? userMessages.length : 50}
          scrollableTarget="scrollableDiv"
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
          inverse={true}
          next={fetchMoreOnTop}
          hasMore={hasMoreMessages}
          loader={<LoadingBox size={20} style={{ height: '4rem' }} />}
          className={classes.infiniteScroll}
          onScroll={handleOnScroll}
        >
          {userMessages?.map((item: MessageInterface) => {
            if (+item.isfrommyself === 1) {
              return (
                <MessageItem
                  key={item?.frompersoninfo_id + item.chat_id}
                  chatActionsHandler={chatActionsHandler}
                  user={_authUser}
                  item={item}
                  isSender
                  onScrollTop={onScrollTop}
                  data-style-item-message-from="itemMessageFrom"
                />
              );
            } else {
              return (
                <MessageItem
                  key={item?.topersoninfo_id + item.chat_id}
                  chatActionsHandler={chatActionsHandler}
                  user={selectedUser}
                  item={item}
                  onScrollTop={onScrollTop}
                  data-style-item-message-top="itemMessageTop"
                />
              );
            }
          })}
        </InfiniteScroll>
        {showScrollDownBtn && (
          <Fab
            onClick={onScrollDown}
            className={classes.scrollDownBtn}
            style={{ position: 'absolute', bottom: 140, left: '3%' }}
            aria-label="scroll-down"
            size="small"
          >
            <Badge
              max={10000}
              color="secondary"
              classes={{ badge: classes.customBadge }}
              badgeContent={
                selectedUser?.sumnotseen >= 0 ? selectedUser?.sumnotseen : 0
              }
              style={{ top: '-20px', left: '-10px' }}
            />
            <ArrowDownIcon />
          </Fab>
        )}
      </div>
    </Box>
  );
};

export default MessagesListView;
