import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import {
  Button,
  DialogActions,
  DialogTitle,
  TextField,
  DialogContent,
} from '@material-ui/core';

import { useStyles } from './dynamic-dialog.style';
import type { DynamicDialogViewInterface } from './dynamic-dialog.type';

export const DynamicDialogView = (
  props: DynamicDialogViewInterface,
): ReactElement => {
  const { Question, closeDialogHandler, responseHandler, handleInputChange } = props;

  const translate = useTranslate();
  const classes = useStyles();

  return (
    <>
      <DialogTitle className={classes.dialogTitle}>{Question.Text}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {Question.InputValue.map((item, index) => {
          return (
            <TextField
              key={index}
              data-tag={index}
              label={item.Label}
              onChange={handleInputChange(index)}
              margin="normal"
              variant="outlined"
            />
          );
        })}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <div>
          {Question.Responses.map((item, index) => {
            return (
              <Button
                key={index}
                data-value={item.Value}
                style={{ color: `${item.Color}` }}
                onClick={responseHandler}
              >
                {item.Text}
              </Button>
            );
          })}

          <Button
            onClick={closeDialogHandler}
            variant="contained"
            id="dialogButtonConfirmCancel"
            className={classes.cancelBtn}
          >
            {translate('confirm.cancel')}
          </Button>
        </div>
      </DialogActions>
    </>
  );
};
