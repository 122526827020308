import {
  FC,
  useRef,
  useState,
  useMemo,
  useLayoutEffect,
  useEffect,
  useCallback,
} from 'react';
import { useLocale } from 'react-admin';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

import {
  DynamicInputControllerProps,
  ExtraProps,
  StateType,
  ui,
} from './dynamic-input.type';
import DynamicInputView from './dynamic-input.view';
import {
  actorGetActionValue,
  actorSetActionValue,
  RecordKeyMode,
} from '../../type/actor-setup';
import { getDropDownListFromState } from '../../helper/MetaHelper';
import { getTypeByField, inputTypes } from '../../helper/InputHelper';
import { isEmpty, isJsonEncodedString } from '../../helper/data-helper';

import type { InputMessage } from '../form';

const DynamicInputController: FC<DynamicInputControllerProps> = props => {
  const {
    defaultValue,
    field,
    noLabel,
    label,
    isServiceMode,
    isProfile,
    disabled,
    inputContainerClass,
    inputInPuzzleForm,
  } = props;

  const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;
  const currentResource = actorGetActionValue('resources')!.current;
  const FieldType = getTypeByField(field);

  const record = actorGetActionValue('record', [
    currentResource.value,
    currentResource.type,
    RecordKeyMode.FORM,
  ]) as Record<string, unknown>;

  //always use "relatedParameterName" in service form (RCT-2348)
  if (isServiceMode && field.relatedParameterName) {
    field.name = field.relatedParameterName as string;
  }

  const {
    name: fieldName,
    disabled: fieldDisabled,
    hidden: fieldHidden,
    caption,
    translatedCaption,
    translatedComment,
  } = field;

  // TODO: implement javaScriptUI features and complete javaScriptUI.test.js

  const [isUiVisible, setIsUiVisible] = useState(false);
  const [isUiEnabled, setIsUiEnabled] = useState(false);
  const [inputValue, setInputValue] = useState<unknown>();
  const [inputMessage, setInputMessage] = useState<InputMessage>();
  const [forceResetWMSValue, setForceResetWMSValue] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>();

  const locale = useLocale();
  const fieldType = getTypeByField(field);

  const getRef = (ref: HTMLElement) => {
    if (['searchDialog', 'dropdown'].includes(field?.dataType?.erp)) {
      inputRef.current = ref as HTMLInputElement;
      return;
    }

    if (ref?.['input'] != null) {
      // DatePicker component
      inputRef.current = ref['input'];
    } else if (ref?.['open']) {
      // Dropdown component
      inputRef.current = ref['open'];
    } else {
      inputRef.current = ref?.querySelector?.('input');
    }
  };

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  /**
   * @function resetWMSDropInput
   * @param { string } value - input value
   * @returns { void } void
   */
  const resetWMSDropInput = useCallback((value: string): void => {
    setForceResetWMSValue(false);
    setInputValue(value);

    if (value === '') {
      requestAnimationFrame(() => {
        setForceResetWMSValue(true);
      });
    }
  }, []);

  useLayoutEffect(() => {
    actorSetActionValue(
      'inputsRef',
      {
        [fieldName]: {
          inputRef,
          field,
          inputValue,
          setInputValue:
            inputInPuzzleForm && field.dataType.erp === 'searchDialog'
              ? resetWMSDropInput
              : setInputValue,
          setInputMessage,
          setIsUiVisible,
          setIsUiEnabled,
          isFirstLoad: true,
          uiVisibleInputsAction: [],
          uiEnableInputsAction: [],
        },
      },
      {
        path: [currentResource.value, currentResource.type],
        callerScopeName: 'DynamicInputController => useLayoutEffect',
      },
    );
  }, []);

  const fieldInAdditionalData: { disabled: boolean | null; hidden: boolean | null } =
    {
      disabled: null,
      hidden: null,
    };

  const additionalData = actorGetActionValue('recordAdditionalData')?.[
    currentResource.value
  ];

  if (!isEmpty(additionalData?.disabled?.[fieldName])) {
    fieldInAdditionalData.disabled = additionalData!.disabled![fieldName];
  }

  if (!isEmpty(additionalData?.hidden?.[fieldName])) {
    fieldInAdditionalData.hidden = additionalData!.hidden![fieldName];
  }

  const prepareVisibleClass = () => {
    const isHidden =
      (fieldHidden && (fieldInAdditionalData.hidden ?? true)) ||
      (!isEmpty(field.javaScriptUiVisible?.trim()) && !isUiVisible);

    return isHidden ? 'displayNone' : '';
  };

  /**
   * Determine if the input is disabled or not
   * @function isDisabled
   * @returns {boolean}
   */
  const isDisabled = (): boolean => {
    if (field.readOnly) {
      return true;
    } else {
      return (
        (fieldDisabled && (fieldInAdditionalData.disabled ?? true)) ||
        (!isEmpty(field.javaScriptUiEnable?.trim()) && !isUiEnabled)
      );
    }
  };

  const isInputDisabled = useMemo(
    () => isDisabled(),
    [field, isUiEnabled, record, inputValue],
  );

  const computedValue =
    FieldType === inputTypes.MULTI_FILE_STREAM_FIELD
      ? isJsonEncodedString(inputValue)
        ? JSON.parse(inputValue as string)
        : inputValue
      : inputValue;

  const inputProps = {
    value: computedValue,
    formActionsHandler,
    inputMessage,
    resource: currentResource.value,
    resourceType: currentResource.type,
    field,
    label: !noLabel ? label ?? translatedCaption?.[locale] ?? caption : '',
    hint: lodashGet(translatedComment, locale, ''),
    inputRef,
    getRef,
    visibleClass: prepareVisibleClass(),
    inputContainerClass: inputContainerClass,
    disabled: disabled ? disabled : isInputDisabled,
    isServiceMode,
    isProfile,
    inputInPuzzleForm,
    forceResetWMSInputValue: forceResetWMSValue,
  };

  const customTestAttribute = {
    'data-test-field-name': field.name || '',
    'data-test-value': computedValue,
    'data-test-quick-button-icon': 'checkbox',
    'data-test-max-value': field.maxValue,
    'data-test-min-value': field.minValue,
    'data-test-max-length': field.maxLength ? field.maxLength : 'dosent_matter',
    'data-test-field-type': fieldType,
    'data-test-field-hidden': field.hidden ? field.hidden : null,
    'data-style-dynamic-input': fieldType,
  };

  return (
    <DynamicInputView
      inputProps={inputProps}
      customTestAttribute={customTestAttribute}
      fieldType={FieldType}
      disableDropdownSearchPopup={props.disableDropdownSearchPopup}
    />
  );
};

const mapStateToInputProps = (state: StateType, props) => {
  const { metaData } = actorGetActionValue('formGlobalProps')!;

  const extraProps: ExtraProps = {
    globalParameters: lodashGet(state, 'profile.globalParameters', {}),
    dropdownState: [],
    viewVersion: state.admin.ui.viewVersion,
  };

  const { field } = props;
  if (
    field.uiVisible &&
    field.uiVisible.length &&
    field.uiVisible[0].length === 3 &&
    field.uiVisible[0][ui.column] !== ''
  ) {
    extraProps.dropdownState = getDropDownListFromState(
      field,
      'uiVisible',
      state,
      metaData,
    );
  } else if (
    field.uiEnable &&
    field.uiEnable.length &&
    field.uiEnable[0].length === 3 &&
    field.uiEnable[0][ui.column] !== ''
  ) {
    extraProps.dropdownState = getDropDownListFromState(
      field,
      'uiEnable',
      state,
      metaData,
    );
  }

  return extraProps;
};

export const DynamicInput = connect(
  mapStateToInputProps,
  null,
)(DynamicInputController);
