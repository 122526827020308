import { makeStyles } from '@material-ui/core';
import { ValidationErrorMessageType } from '../../../helper/Types';
import { getColorBaseOfStatus } from '../../input-base/input-message/input-message.helper';
import { CustomTheme } from './../../../core/themeProvider';

export const useStyles = makeStyles<
  CustomTheme,
  { messageType?: ValidationErrorMessageType }
>(theme => ({
  root: {
    border: '1px solid #BDBDBD',
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.primary.appSecondaryBackgroundColor,
    padding: 0,
    margin: 0,
    height: '100%',
    flexGrow: 1,
    flexBasis: 200,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    contain: 'content',
  },

  disabled: {
    backgroundColor: '#e0e0e0',
    cursor: 'default',
    color: '#bababa',
  },

  fieldsetError: {
    border: props => `1px solid ${getColorBaseOfStatus(props.messageType, theme)}`,
  },

  customFileInput: {
    width: 0,
    opacity: 0,
    cursor: 'pointer',
    padding: 0,
    height: 35,
  },

  inputFileValueName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    overflow: 'hidden',
    height: '100%',
    width: '50%',
    padding: 4,
    flex: 2,
    [theme.breakpoints.down('sm')]: {
      padding: '4px 10px',
      fontSize: 8,
    },
  },

  uploadArea: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.appPrimaryBackgroundColor,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2rem',
    },
  },

  uploadBox: {
    border: `1px dashed ${theme.palette.grey[300]}`,
    padding: '1rem',
  },

  fileName: {
    display: 'flex',
    flex: 1,
    paddingRight: 10,
    paddingLeft: 5,
    width: '20px',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
  },

  wrap: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  fileIcon: {
    color: theme.palette.primary.appPrimaryBorderInputColor,
  },
}));
