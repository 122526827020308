// TODO: delete this file after all of its functionalities implemented in the new form controller

import React, { FC, useState, useRef } from 'react';
import { connect } from 'react-redux';
import lodashDebounce from 'lodash/debounce';
import lodashGet from 'lodash/get';
import lodashPick from 'lodash/pick';

import { clone, isEmpty, isEmptyObject } from '../helper/data-helper';
import { getFieldByName } from '../helper/MetaHelper';
import { DATE_FIELD, getTypeByField } from '../helper/InputHelper';

interface SubmittableFormDefaultValueHOC {
  component: any;
  defaultValue: any;
  defaultValueRef: any;
  initialValues: any;
  fieldList: any;
  todoShareListParentId: any;
  overrideParams: any;
  childFieldName: any;
  treeParentFieldName: any;
  record: any;
  metaData: any;
  puzzleMode: any;
  formData: any;
  save: any;
  isCreateMode?: boolean;
}

const SubmittableFormDefaultValueHOC: FC<SubmittableFormDefaultValueHOC> = props => {
  const {
    component: ChildComponent,
    defaultValue,
    defaultValueRef,
    initialValues = {},
    fieldList = [],
    todoShareListParentId,
    overrideParams,
    childFieldName,
    treeParentFieldName,
    record = {},
    metaData,
    puzzleMode,
    formData,
    save,
    isCreateMode = true, // FIXME: I didn't know that what it was, @mersad tell me about this
    ...rest
  } = props;

  const [mergedValues, setMergedValues] = useState();

  // because change to state variable is slow, we have to keep a normal variable
  // and setState clone of this variable
  const internalChangedDefault = useRef({});

  const updateDefaultValue = (name, value, skipIfNotExists = false) => {
    if (
      typeof internalChangedDefault.current[name] === 'undefined' &&
      skipIfNotExists
    ) {
      return;
    }

    internalChangedDefault.current[name] = value;

    updateInternalState();
  };

  // reset the stored default values to meta default values
  const resetDefaultValues = () => {
    const resetIgnoreFields = fieldList
      .filter(field => lodashGet(field, 'keepValueAfterSubmit'))
      .map(field => field.name);

    const necessaryValuesBeforReset = lodashPick(mergedValues, resetIgnoreFields);

    internalChangedDefault.current = initialValues;

    setMergedValues({
      ...initialValues,
      ...necessaryValuesBeforReset,
    });
  };

  const updateInternalState = lodashDebounce(() => {
    const mergedData = {
      ...defaultValue,
      ...initialValues,
      ...internalChangedDefault.current,
    };

    setMergedValues(mergedData);
    // actorDispatch('temporaryData', mergedData);
  }, 200);

  /**
   * This function will look for necessary information like parent field names or
   * process unique id and get the correct value of this important data and inject them to `additionalInfo` object.
   * @function computeAdditionalInfo
   * @param {object} formRecord equal with `props.record`
   * @returns {object} additionalInfo
   */
  const computeAdditionalInfo = formRecord => {
    const additionalInfo =
      overrideParams && !isEmptyObject(overrideParams) ? overrideParams : {};

    const positionid = lodashGet(formRecord, 'positionid');
    const stateid = lodashGet(formRecord, 'stateid');
    const __processuniqueid = lodashGet(formRecord, '__processuniqueid');

    if (positionid) {
      additionalInfo.positionid = positionid;
    }
    if (stateid) {
      additionalInfo.stateid = stateid;
    }
    if (__processuniqueid) {
      additionalInfo.__processuniqueid = __processuniqueid;
    }

    if (!isEmpty(childFieldName)) {
      additionalInfo[childFieldName] = lodashGet(formRecord, childFieldName);
    }

    if (!isEmpty(treeParentFieldName)) {
      additionalInfo[treeParentFieldName] = lodashGet(
        formRecord,
        treeParentFieldName,
      );
    }

    if (!isEmpty(todoShareListParentId)) {
      additionalInfo[todoShareListParentId] = lodashGet(
        formRecord,
        todoShareListParentId,
      );
    }

    return additionalInfo;
  };

  /**
   *This function will remove extra values that exist in the form record but no need to send to the server
   * it will be different for puzzle forms because in puzzle record is equal with `formData`.
   * @function sanitizeRecordOrDefaultValues
   * @param {object} values edited form data
   * @returns {object} clearFormData
   */
  const sanitizeRecordOrDefaultValues = values => {
    let clearFormData = {};
    const formRecord = clone(record);

    if (puzzleMode) {
      // dear developer , clearFormData used to be `props.formData` before form refactor
      // in this situation, but now changed to `values` in RCT-1608... , if any problem
      // accrues with WMS data you should looking for issue on next line :))
      clearFormData = values;
    } else {
      clearFormData = computeAdditionalInfo(formRecord);
      Object.keys(values).forEach(key => {
        const field = getFieldByName(metaData, key);
        const fieldType = field ? getTypeByField(field) : null;
        const keyValueInInitialValues = lodashGet(initialValues, [key]);
        const keyValueInRecord = lodashGet(formRecord, key);

        if (!isEmpty(keyValueInRecord)) {
          // here field exist in record so its EDIT
          if (keyValueInRecord !== values[key] || fieldType === DATE_FIELD) {
            clearFormData[key] = values[key];
          } else if (
            (isCreateMode && field && field.defaultValue == values[key]) ||
            key == 'redirect'
          ) {
            delete clearFormData[key];
          }
        } else {
          // here field not exist in record so its CREATE
          if (!isEmpty(keyValueInInitialValues)) {
            // has initial value
            if (fieldType !== DATE_FIELD) {
              if (keyValueInInitialValues !== String(values[key])) {
                clearFormData[key] = values[key];
              }
            } else {
              clearFormData[key] = values[key];
            }
          } else {
            // has not initial value
            clearFormData[key] = values[key];
          }
        }
      });
    }
    return clearFormData;
  };

  /**
   * This function will pass to `NewSubmittableForm` as main save function.
   * It should receive form data(just edited) and parameters that we wanna use them in main API cal function like default values
   * and septate them to tow parameters(formData, params) with merge params and params to call `props.save`.
   * @function manipulatedSave
   * @param {object|null} data
   * @param {object|null} params
   * @param {object|null} customParams
   * @returns {void}
   */
  const manipulatedSave = (data, params = {}, customParams = {}) => {
    const finalData = sanitizeRecordOrDefaultValues(data);

    // also give back default data with form data
    save(finalData, {
      ...params,
      ...customParams,
      defaultValue: mergedValues,
      resetDefaultValues: resetDefaultValues,
    });
  };

  if (defaultValueRef && defaultValueRef.current) {
    defaultValueRef.current = {
      defaultValue: mergedValues,
      resetDefaultValues: resetDefaultValues,
    };
  }

  return (
    <ChildComponent
      {...rest}
      updateDefaultValue={updateDefaultValue}
      initialValues={mergedValues}
      save={manipulatedSave}
    />
  );
};

function mapStateToProps(state) {
  return {
    viewVersion: state.admin.ui.viewVersion,
    childFieldName: lodashGet(state, ['quickCreate', 'childFieldName']),
    treeParentFieldName: lodashGet(state, ['quickCreate', 'parentFieldName']),
    todoShareListParentId: lodashGet(state, ['todoList', 'shareListParentId']),
  };
}

const ConnectedHOC = connect(mapStateToProps, null)(SubmittableFormDefaultValueHOC);

export default HocComponent => props =>
  <ConnectedHOC {...props} component={HocComponent} />;
