import { ReactElement } from 'react';
import { Box, Tooltip } from '@material-ui/core';

import { QuickAccessMenuViewInterface } from './quick-access-menu.type';
import { useStyles } from './quick-access-menu.style';
import { QuickAccessMenuDataInterface } from '.';
import LoadingBox from '../loading-box';

const QuickAccessMenuView = (props: QuickAccessMenuViewInterface): ReactElement => {
  const { data, isLoading, onClick } = props;
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      mr={1}
      data-test="quick-access-menu-container"
      id="accessMenu"
    >
      {isLoading && <LoadingBox size={12} />}

      {data?.map((item: QuickAccessMenuDataInterface) => (
        <Tooltip key={item.id} title={item?.title ?? ''}>
          <i
            onClick={() => onClick(item)}
            data-test-quick-access-menu-icon={item.id}
            className={`fa fa-${item?.icon} ${classes.icon}`}
          ></i>
        </Tooltip>
      ))}
    </Box>
  );
};

export default QuickAccessMenuView;
