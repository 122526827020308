import produce from 'immer';
import { USER_LOGOUT } from 'react-admin';
import lodashGet from 'lodash/get';

import {
  FIND_ONE,
  FIND_ONE_SUCCESS,
  FIND_ONE_FAILED,
  SET_GLOBAL_PARAMETERS_ACTION,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILED,
} from './constant';
import {
  CONFIG_PROFILE_SETTING,
  setValue,
  getValue,
} from '../../core/configProvider';
import { objectToLowerCaseProperties } from '../../helper/data-helper';

const initialState = {
  isLoading: false,
  data: null,
  globalParameters: null,
  error: null,
  updateLoading: false,
};

const myReducer = (state = initialState, { type, data, additionalData, error }) =>
  produce(state, draft => {
    // eslint-disable-next-line default-case
    switch (type) {
      case USER_LOGOUT:
        draft.isLoading = false;
        draft.data = null;
        draft.globalParameters = null;
        draft.error = null;
        break;

      case FIND_ONE:
        draft.isLoading = true;
        draft.data = null;
        draft.globalParameters = null;
        draft.settingFields = null;
        draft.error = null;
        break;

      case FIND_ONE_SUCCESS:
        draft.isLoading = false;
        draft.data = data;
        draft.error = null;
        break;

      case FIND_ONE_FAILED:
        draft.isLoading = false;
        draft.data = null;
        draft.globalParameters = null;
        draft.error = error.toString();
        break;

      case SET_GLOBAL_PARAMETERS_ACTION:
        setValue(CONFIG_PROFILE_SETTING, data);
        draft.globalParameters = data;
        break;

      case UPDATE_PROFILE:
        draft.updateLoading = true;
        break;

      case UPDATE_PROFILE_SUCCESS:
        draft.updateLoading = false;
        break;

      case UPDATE_PROFILE_FAILED:
        draft.updateLoading = false;
        break;
    }
  });

export default myReducer;
