import { ReactElement, useEffect, useState } from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { useLocale } from 'react-admin';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

import { useStyles } from './form-with-tab.style';
import { FormWithTabsViewProps } from '../form-with-tabs';
import { Table as CustomFormTable } from '../../table';

import { CustomAccordion } from '../../custom-accordion';
import { RelationActionTopToolbar } from '../../relation-action-top-toolbar';
import { getRelationsInForm } from '../../../helper/MetaHelper';
import { getProcessInformationFromRecord } from '../../../helper/meta-helper';
import { DynamicInput } from '../../dynamic-input/dynamic-input.controller';
import {
  RelationPanel,
  RelationPanelControllerInterface,
} from '../../new-relation-panel';
import PinnedNotesController from '../../new-relation-panel/note-relation/pinned-note/pinned-notes-controller';

const FormWithTabsView = (props: FormWithTabsViewProps): ReactElement | null => {
  const {
    tabList = [],
    showRelations = false,
    selectedTab,
    metaData,
    record,
    relations,
    tabsTitlesRef,
    tabChangeHandler,
    match,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const locale = useLocale();

  const processInfo = getProcessInformationFromRecord(record);
  const [isRelationNote, setIsRelationNote] = useState<
    RelationPanelControllerInterface[]
  >([]);

  useEffect(() => {
    checkHasRelationNote();
  }, [relations]);

  if (!(Array.isArray(tabList) && tabList.length > 0)) {
    return null;
  }
  /**
   * check realstion note is exist
   * @function checkHasRelationNote
   * @returns {void} void
   */
  const checkHasRelationNote = (): void => {
    const result = relations?.filter(rel => rel.relationType === 'note');
    setIsRelationNote(result);
  };

  return (
    <div className={classes.formWithTabContainer} data-style-form-tab="mainForm">
      {isRelationNote?.length >= 1 && <PinnedNotesController match={match} />}
      {showRelations && (
        <RelationActionTopToolbar
          list={getRelationsInForm(
            metaData,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            processInfo,
          ).map(item => {
            return {
              title: item.translatedTitle?.[locale] ?? item.moduleTableTitle,
              id: item.moduleTableName,
            };
          })}
        />
      )}
      <Tabs
        defaultActiveKey={selectedTab ?? '0'}
        direction={theme.direction}
        onChange={tabChangeHandler}
        data-style-tabs="tabs"
      >
        {tabList.map((tab, index) => {
          return (
            <TabPane
              key={tab.id}
              tab={
                <p
                  ref={tabRef => {
                    tabsTitlesRef.current[index] = tabRef;
                  }}
                >
                  {tab.translatedTitle?.[locale] ?? tab.title ?? ''}
                </p>
              }
            >
              <div className={classes.tabGroupsContainer}>
                {tab.groupList &&
                  tab.groupList.length &&
                  tab?.groupList.map((group, index: number) => {
                    const { translatedTitle = {}, id } = group;
                    const title: string =
                      translatedTitle?.[locale] ?? String(id) ?? '';
                    return (
                      <div key={index} className={classes.groupContainer}>
                        <CustomAccordion
                          id={`formWithTabAccordion_${id}`}
                          enableChild
                          defaultExpanded
                          summary={
                            <div className={classes.titleSummary}>
                              <Typography
                                variant="body2"
                                data-style-accordion-text="accordionText"
                              >
                                {title}
                              </Typography>
                              {group.specialFields &&
                                group.specialFields.map(field => (
                                  //TODO: use field like code below
                                  // getTypeByField(field) === ICON_FIELD ? (
                                  //   <IconField field={field} data={record} />
                                  // ) : (
                                  //   <ColorField field={field} data={record} />
                                  // )
                                  <DynamicInput key={field.id} field={field} />
                                ))}
                            </div>
                          }
                        >
                          <CustomFormTable
                            className={classes.table}
                            group={group}
                            type="form"
                          />
                        </CustomAccordion>
                      </div>
                    );
                  })}
              </div>
            </TabPane>
          );
        })}
      </Tabs>

      {showRelations &&
        relations &&
        relations.map(
          (relationProps: RelationPanelControllerInterface, relationIndex) => {
            return (
              <div className={classes.relationItem} key={relationIndex}>
                <RelationPanel {...relationProps} relationIndex={relationIndex} />
              </div>
            );
          },
        )}
    </div>
  );
};

export { FormWithTabsView };
