import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import Box from '@material-ui/core/Box';

import { useStyles } from './messages-screen.style';
import { MessagesScreenViewInterface } from './messages-screen.type';
import { AppHeader } from '../../../app-container/app-header';
import { AppFooter } from '../../../app-container/app-footer';
import { MessagesHeader } from './messages-header';
import { MessagesList } from './messages-list';
import { NewMessage } from './new-message';
import { isEmptyObject } from '../../../../helper/data-helper';
import LoadingBox from '../../../loading-box';

const MessagesScreenView = (props: MessagesScreenViewInterface): ReactElement => {
  const {
    chatActionsHandler,
    selectedUser,
    userMessages,
    currentUser,
    hasMoreMessages,
    messagesLoading,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box display="flex" height={1} flexDirection="column">
      <AppHeader className={classes.headerContainer}>
        <MessagesHeader selectedUser={selectedUser} />
      </AppHeader>

      {!isEmptyObject(userMessages) && currentUser ? (
        <MessagesList
          chatActionsHandler={chatActionsHandler}
          userMessages={userMessages}
          hasMoreMessages={hasMoreMessages}
          authUser={currentUser}
          selectedUser={selectedUser}
          messagesLoading={messagesLoading}
        />
      ) : messagesLoading ? (
        <Box className={classes.loadingContainer} id="messageLoading">
          {' '}
          <LoadingBox />{' '}
        </Box>
      ) : (
        <Box className={classes.scrollChatNomain} component="span">
          <Box component="span" className={classes.noMessage}>
            {translate('chat.sayHi')} {selectedUser?.personname}
          </Box>
        </Box>
      )}

      <AppFooter className={classes.messagesScreenFooter}>
        <NewMessage chatActionsHandler={chatActionsHandler} currentMessage="" />
      </AppFooter>
    </Box>
  );
};

export default MessagesScreenView;
