import dataProvider, { INLINE_CELL_UPDATE } from '../core/dataProvider';
import { isEmptyObject } from '../helper/data-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../type/actor-setup';
import lodashMap from 'lodash/map';

/**
 * edit inline cell and save
 * note : rowInfo fill in handleBooleanFieldInlineEdit
 * @function handleInlineEdit
 * @param {Record<string, unknown>} changedData
 * @param {Record<string, unknown>} rowInfo
 * @param {Function} onFailureSaveForm
 * @param {string} resource
 * @returns {Promise<void>}
 */
export const handleInlineEdit = async (
  changedData: Record<string, unknown>,
  rowInfo: Record<string, unknown> = {},
  resource: string,
  onFailureSaveForm?: (error: string) => void,
  onSuccessSaveForm?: (editedData: Record<string, unknown>) => void,
  forceReloadResource: boolean = false,
) => {
  if (isEmptyObject(rowInfo)) return;

  const changedColumn = Object.keys(changedData);
  if (!changedColumn.length) {
    return;
  }

  const cleanData = { ...rowInfo, ...changedData };

  try {
    const response = await dataProvider(INLINE_CELL_UPDATE, resource, {
      data: cleanData,
      id: rowInfo.id,
      target: changedColumn,
    });

    actorDispatch('loading', { [resource]: false });

    const { exceptions, data: editedData } = response;

    if (isEmptyObject(exceptions)) {
      //update actor data
      const currentData = actorGetActionValue('gridData', `${resource}.data`);
      const newData = lodashMap(currentData, function (rowInfo) {
        return rowInfo.id === editedData.id ? editedData : rowInfo;
      });
      actorSetActionValue('gridData', newData, {
        path: `${resource}.data`,
      });

      //update grid data
      if (typeof onSuccessSaveForm == 'function') onSuccessSaveForm(editedData);

      // We need it in `relationMode`
      actorDispatch('refreshView', 'showRecordPage', { disableDebounce: true });
      if (forceReloadResource) {
        actorDispatch('refreshView', resource, { disableDebounce: true });
      }
    }
  } catch (error) {
    if (typeof onFailureSaveForm == 'function') onFailureSaveForm(error as string);
  }
};
