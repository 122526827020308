import { FC } from 'react';

import { inputTypes } from '../../helper/InputHelper';
import { BooleanInput } from './boolean-input';
import { StringSelectInput } from './stringselect-input';
import { TagInput } from './tag-input';
import { LongTextInput } from './longtext-input';
import { CodingInput } from './coding-input';
import { FileInput } from './file-input/index';
import TextInput from '../text-input';
import { NumberInput } from './number-input';
import { TimeInput } from './time-input';
import MetaProvider from '../../container/MetaProvider';

import { ComputedInput } from './computed-input';
import DropdownInput from './dropdown-input';
import { AutoCompleteInput } from './auto-complete-input';
import { JalaliDateInput } from './jalali-date-input';
import DateFilterInput from '../filter/DateFilterInput';
import { ColorInput } from './color-input';
import { IconInput } from './icon-input';
import { RichTextEditorInput } from './rich-text-editor-input';
import { DelegationDropdown } from './delegation-dropdown';
import FileStreamInput from './file-stream-input';
import MultiFileStreamInput from './multi-file-stream-input';
import { HiddenFieldsHandler } from '../hidden-fields-handler';
import { ColorPickerInput } from './color-picker-input';
import { ButtonInput } from './button-input';
import { TimerInput } from './timer-input';
import { LocationInput } from './location-input';
//TODO: complete interface on card https://jira.samiansoft.com/browse/RCT-1444
const DynamicInputView: FC<any> = props => {
  const {
    inputProps,
    handleClick,
    noLabel,
    filterMode,
    handleDropdownChange,
    disableDropdownQuickCreate,
    disableDropdownSearchPopup,
    triggerGoToNext,
    myRef,
    locale,
    defaultOperator,
    onlyEqualCondition,
    isInputFocusable,
    relationResource,
    relationSource,
    relationInfo,
    addToRelationArray,
    customTestAttribute,
    fieldType,
    forceResetWMSInputValue,
    ...rest
  } = props;

  const { value, onChange, visibleClass, resource, field, inputInPuzzleForm } =
    inputProps;

  let inputComponent;
  switch (fieldType) {
    case inputTypes.CODING_FIELD:
      inputComponent = (
        <MetaProvider resourceName={resource}>
          <CodingInput
            {...rest}
            {...inputProps}
            customTestAttribute={customTestAttribute}
            onClick={handleClick}
          />
        </MetaProvider>
      );
      break;

    case inputTypes.COMPUTED_FIELD:
      inputComponent = (
        <ComputedInput
          {...rest}
          {...inputProps}
          formula={field.javaScriptFormula || ''}
        />
      );
      break;

    case inputTypes.SEARCH_FIELD:
      inputComponent = (
        <DropdownInput
          {...inputProps}
          customTestAttribute={customTestAttribute}
          dropdownMeta={field.searchDialog as Record<string, unknown>}
          value={value}
          isSearchInput
          disableDropdownSearchPopup
        />
      );
      break;

    case inputTypes.DROPDOWN_FIELD:
      inputComponent = (
        <DropdownInput
          {...inputProps}
          customTestAttribute={customTestAttribute}
          dropdownMeta={field.dropdown as Record<string, unknown>}
          value={value}
          forceResetWMSInputValue={forceResetWMSInputValue}
        />
      );
      break;

    case inputTypes.LOCATION_FIELD: {
      inputComponent = (
        <LocationInput
          {...inputProps}
          customTestAttribute={customTestAttribute}
          dropdownMeta={field.dropdown as Record<string, unknown>}
          value={value}
        />
      );
      break;
    }
    case inputTypes.DELEGATION_FIELD:
      inputComponent = <DelegationDropdown {...inputProps} value={value} />;
      break;

    case inputTypes.STRING_SELECT_FIELD:
      inputComponent = (
        <StringSelectInput
          {...inputProps}
          customTestAttribute={customTestAttribute}
          inputInPuzzleForm={inputInPuzzleForm}
        />
      );
      break;

    case inputTypes.STRING_MULTI_SELECT_FIELD:
      inputComponent = (
        <StringSelectInput
          {...rest}
          {...inputProps}
          customTestAttribute={customTestAttribute}
          multiple={true}
          inputInPuzzleForm={inputInPuzzleForm}
        />
      );
      break;

    case inputTypes.DROP_BASE_MULTI_SELECT_FIELD:
      inputComponent = (
        <AutoCompleteInput
          {...rest}
          {...inputProps}
          customTestAttribute={customTestAttribute}
          dropdownMeta={field.dropdown}
          onChange={onChange}
          inputInPuzzleForm={inputInPuzzleForm}
        />
      );
      break;

    case inputTypes.TAG_FIELD:
      inputComponent = <TagInput {...rest} {...inputProps} field={field} />;
      break;

    case inputTypes.BOOLEAN_FIELD:
      inputComponent = <BooleanInput {...rest} {...inputProps} />;
      break;

    case inputTypes.DECIMAL_FIELD:
    case inputTypes.NUMBER_FIELD:
      inputComponent = (
        <NumberInput
          {...rest}
          {...inputProps}
          customTestAttribute={customTestAttribute}
          onClick={handleClick}
        />
      );
      break;

    case inputTypes.COLOR_FIELD:
      inputComponent = (
        <ColorInput {...rest} {...inputProps} onClick={handleClick} />
      );
      break;

    case inputTypes.ICON_FIELD:
      inputComponent = <IconInput {...rest} {...inputProps} onClick={handleClick} />;
      break;

    case inputTypes.DATE_FIELD:
      inputComponent = filterMode ? (
        <DateFilterInput
          {...rest}
          {...inputProps}
          defaultOperator={defaultOperator}
          onlyEqualCondition={onlyEqualCondition}
        />
      ) : (
        <JalaliDateInput
          {...rest}
          {...inputProps}
          DateInputInPuzzleForm={inputInPuzzleForm}
        />
      );
      break;

    case inputTypes.FILE_FIELD:
      inputComponent = <FileInput {...rest} {...inputProps} onClick={handleClick} />;
      break;

    case inputTypes.FILE_STREAM_FIELD:
      inputComponent = (
        <FileStreamInput {...rest} {...inputProps} onClick={handleClick} />
      );
      break;

    case inputTypes.MULTI_FILE_STREAM_FIELD:
      inputComponent = (
        <MultiFileStreamInput
          {...rest}
          {...inputProps}
          customTestAttribute={customTestAttribute}
          onClick={handleClick}
        />
      );
      break;

    case inputTypes.LONG_TEXT_FIELD:
      inputComponent = (
        <LongTextInput {...rest} {...inputProps} onClick={handleClick} />
      );
      break;

    case inputTypes.TIME_FIELD:
      inputComponent = (
        <TimeInput
          {...rest}
          {...inputProps}
          customTestAttribute={customTestAttribute}
          onClick={handleClick}
        />
      );
      break;
    case inputTypes.RICH_TEXT_EDITOR_FIELD:
      inputComponent = <RichTextEditorInput {...rest} {...inputProps} />;
      break;

    case inputTypes.COLOR_PICKER_FIELD:
      inputComponent = <ColorPickerInput {...inputProps} value={value} />;
      break;

    case inputTypes.BUTTON_FIELD:
      inputComponent = (
        <ButtonInput
          {...rest}
          {...inputProps}
          customTestAttribute={customTestAttribute}
        />
      );
      break;
    case inputTypes.TIMER_FIELD:
      inputComponent = (
        <TimerInput
          {...rest}
          {...inputProps}
          customTestAttribute={customTestAttribute}
        />
      );
      break;

    default:
      inputComponent = (
        <TextInput
          {...rest}
          {...inputProps}
          customTestAttribute={customTestAttribute}
        />
      );
  }

  if (!inputInPuzzleForm) {
    return (
      // @ts-ignore
      <HiddenFieldsHandler visibleClass={visibleClass} fieldType={fieldType}>
        {inputComponent}
      </HiddenFieldsHandler>
    );
  }

  return inputComponent;
};

export default DynamicInputView;
