import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { getIconClassName } from '@uifabric/styling';
import { useTranslate } from 'react-admin';

import {
  getTodoListPatternInfo,
  getTodoTaskDetailsPatternInfo,
} from '../../helper/PatternMetaHelper';
import { systemTaskAssign } from '../../redux/todoList/action';
import { CustomTheme } from '../../core/themeProvider';
import { getValue, USER_ID } from '../../core/configProvider';
import ProfileAvatar from '../ProfileAvatar';

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    padding: 5,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    transition: 'background-color 200ms',
    '&:hover': {
      backgroundColor: theme.palette.primary.appPrimaryLightBackgroundColor,
      transition: 'background-color 200ms',
    },
  },

  taskTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: 5,
  },

  taskTitle: {
    paddingLeft: 10,
    flexGrow: 1,
  },

  assignBtn: {
    marginRight: 15,
    padding: 5,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.appPrimaryIconColor,
    },
  },

  itemContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  item: {
    display: 'flex',
    alignItems: 'center',
  },

  itemIcon: {
    fontSize: 13,
    color: theme.palette.secondary.main,
  },

  itemTitle: {
    margin: 4,
  },

  userAvatar: {
    width: 25,
    height: 25,
  },
}));

const TodoSystemTaskItem = props => {
  const { taskId, task, listResource, resource, detailResource, systemTaskAssign } =
    props;
  const classes = useStyles();
  const translate = useTranslate();

  const {
    sysTitle,
    sysurl,
    note,
    assignUser,
    SysAssigned,
    sysTaskAssignToMeService,
  } = getTodoTaskDetailsPatternInfo(detailResource);

  const currentUserId = +getValue(USER_ID);

  /**
   * Assign System task to current user
   * @function handleAssignToMe
   * @param event {event}
   * @returns {void}
   */
  const handleAssignToMe = () => {
    const { pagination, reportResource } = getTodoListPatternInfo(listResource);

    const data = {
      processid: taskId,
      data: { [SysAssigned]: currentUserId },
    };
    systemTaskAssign(
      sysTaskAssignToMeService,
      data,
      resource,
      reportResource,
      {},
      pagination,
    );
  };

  return (
    <div
      className={classes.container}
      data-test-todo-system-task-item={task['sysurl']}
    >
      <a
        target="_blank"
        href={`#/${String(task[sysurl]).toLowerCase()}`}
        title={task[note]}
        className={classes.taskTitleContainer}
        data-test-task-item={task[sysTitle]}
      >
        <div className={classes.itemContainer}>
          <div className={classes.item}>
            <i className={`${classes.itemIcon} ${getIconClassName('system')}`}></i>
            <Typography
              variant="caption"
              className={classes.taskTitle}
              data-test-task-item-name={task[sysTitle]}
            >
              {task[sysTitle]}
            </Typography>
          </div>
        </div>
      </a>
      {task[assignUser] !== null && task[assignUser] == currentUserId ? (
        <ProfileAvatar
          userId={currentUserId}
          data-test-system-task-assigned-to-me={true}
        />
      ) : (
        <Button
          className={classes.assignBtn}
          color="primary"
          onClick={handleAssignToMe}
          data-test-system-task-assigned-to-me={false}
        >
          {translate('todo.assingToMe')}
        </Button>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  systemTaskAssign,
};

export default connect(null, mapDispatchToProps)(TodoSystemTaskItem);
