import React, { ReactElement } from 'react';
import List from '@material-ui/core/List';
import { NavigationContainerInterface } from './navigation-container.type';
import { RoutesConfigInterface } from '.';
import { NavGroup } from './nav-group';
import { NavCollapse } from './nav-collapse';
import { NavItem } from './nav-item';

const NavigationContainerView = (
  props: NavigationContainerInterface,
): ReactElement => {
  const { routesConfig, baseUrl } = props;

  return (
    <List data-style-navigation="navigation">
      {routesConfig.map((item: RoutesConfigInterface) => (
        <React.Fragment key={item.id}>
          {item.type === 'group' && (
            <NavGroup item={item} level={0} baseUrl={baseUrl} />
          )}

          {item.type === 'collapse' && (
            <NavCollapse item={item} level={0} baseUrl={baseUrl} />
          )}

          {item.type === 'item' && (
            <NavItem item={item} level={0} baseUrl={baseUrl} />
          )}
          {item.type === 'action' && <>{item?.actionNode}</>}
        </React.Fragment>
      ))}
    </List>
  );
};

export default NavigationContainerView;
