import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import compose from 'recompose/compose';
import { makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import lodashFind from 'lodash/find';
import lodashDebounce from 'lodash/debounce';

import CheckResourceReady from '../../container/CheckResourceReady';
import ListContainerApiController, {
  ListViewProps,
} from '../../container/ListContainerApiController';
import CartDataListContainer, {
  CartDataListContainerChildProps,
} from './CartDataListContainer';
import { getSellinBarcodePatternInfo } from '../../helper/PatternMetaHelper';
import CardListItemView from '../card/CardListItemView';
import BarcodeListItemView from './BarcodeListItemView';

interface SellinProductBarcodeViewInterfaceProps {
  resource: string;
  parentId: number;
  notifyCountChange: Function;
  onDelete: Function;
  record: object;
}

const styles = makeStyles(theme => ({
  productBarcodeTitle: {
    marginTop: 15,
  },
}));

const SellinProductBarcodeView: FC<
  SellinProductBarcodeViewInterfaceProps
> = props => {
  const { resource, parentId, notifyCountChange, onDelete, record, ...rest } = props;
  const classes = styles();
  const translate = useTranslate();

  const pattern = getSellinBarcodePatternInfo(resource);
  const defaultFilter = {
    [pattern.justAvailableNumber]: false,
    [pattern.parentId]: parentId,
  };

  const findBasketInfo = (basketData, basketIds, id, keyPattern) => {
    let tempBasketInfo = null;
    basketIds.forEach(basketId => {
      if (basketData[basketId][keyPattern] === id) {
        tempBasketInfo = basketData[basketId];
      }
    });
    return tempBasketInfo;
  };

  return (
    <CheckResourceReady resourceName={resource}>
      <ListContainerApiController
        {...rest}
        basePath={`/${resource}`}
        resource={resource}
        perPage={1000000}
        sort={pattern.defaultSort}
        filterDefaultValues={defaultFilter}
      >
        {(controllerProps: ListViewProps) => (
          <CartDataListContainer basketResource={pattern.basketResource}>
            {(basketProps: CartDataListContainerChildProps) => {
              const { ids, data } = controllerProps;
              const { basketData, basketIds } = basketProps;
              return (
                <div>
                  <Typography
                    variant="subtitle2"
                    className={classes.productBarcodeTitle}
                  >
                    {translate('sellin.barcodes')}
                  </Typography>
                  {ids.map(id => {
                    const basketInfo = findBasketInfo(
                      basketData,
                      basketIds,
                      id,
                      pattern.barcodeId,
                    );

                    return (
                      <BarcodeListItemView
                        key={id}
                        parentRecord={record}
                        record={data[id]}
                        pattern={pattern}
                        onAddToCart={notifyCountChange}
                        onRemoveFromCart={onDelete}
                        basketInfo={basketInfo}
                        count={
                          basketInfo && basketInfo[pattern.count] > 0
                            ? basketInfo[pattern.count]
                            : 0
                        }
                      />
                    );
                  })}
                </div>
              );
            }}
          </CartDataListContainer>
        )}
      </ListContainerApiController>
    </CheckResourceReady>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SellinProductBarcodeView,
);
