import { REFRESH_RELATION_ACTION, REFRESH_ONE_RELATION_ACTION } from './constant';
import { OneRelationVersionPayload } from './type';

/**
 * this function refresh relation data trigger.
 * @function refreshRelationAction
 * @returns {object}
 */
export const refreshRelationAction = (): object => ({
  type: REFRESH_RELATION_ACTION,
});

/**
 * this function refresh one relation data trigger.
 * @function refreshOneRelationAction
 * @returns {object}
 */
export const refreshOneRelationAction: OneRelationVersionPayload = ({
  resource,
}): object => ({
  type: REFRESH_ONE_RELATION_ACTION,
  resource,
});
