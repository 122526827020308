import { REFRESH_GRID, RESET } from './constant';

export function resetPuzzlePageParamsAction() {
  return {
    type: RESET,
  };
}

export function refreshPuzzlePageGridAction() {
  return {
    type: REFRESH_GRID,
  };
}
