import { ReactElement } from 'react';
import { TextField } from '@material-ui/core';

import { useStyles } from './number-text-field.style';
import { NumberTextFieldViewInterface } from './number-text-field.type';
import InputBase from '../../../input-base';

const NumberTextFieldView = (props: NumberTextFieldViewInterface): ReactElement => {
  const {
    getRef,
    field,
    resource,
    inputMessage,
    label,
    hint,
    customOnChange,
    onKeyDown,
    onMouseUp,
    onFocus,
    onBlur,
    value,
    inputMode,
    type,
    onClick,
    visibleClass,
    disabled,
    customTestAttribute,
  } = props;
  const classes = useStyles({ messageType: inputMessage?.messageType });
  const { required, precision, name, maxLength } = field;

  return (
    <InputBase
      className={`${visibleClass}`}
      label={label}
      hint={hint}
      required={required}
      inputMessage={inputMessage}
      field={field}
    >
      <TextField
        {...customTestAttribute}
        ref={getRef}
        variant="outlined"
        value={value ?? ''}
        inputMode={'decimal'}
        type={type}
        name={name}
        classes={{
          root: classes.root,
        }}
        inputProps={{
          'data-test-input-name': `${resource}/${name}`,
          'data-test-precision': precision,
          maxLength,
          inputMode: 'decimal',
        }}
        InputProps={{
          disabled,
          onKeyDown,
          onMouseUp,
          onFocus,
          onBlur,
          classes: {
            root: classes.outlineRoot,
            notchedOutline: classes.notchedOutline,
            disabled: classes.disabled,
          },
          autoComplete: 'off',
        }}
        required={required}
        disabled={disabled}
        onChange={customOnChange}
        onClick={onClick}
        size="small"
        data-test-has-error={inputMessage?.messageType === 'error'}
        data-test-has-warning={inputMessage?.message === 'warning'}
      />
    </InputBase>
  );
};

export default NumberTextFieldView;
