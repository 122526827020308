import dataProvider, { GET_META } from '../core/dataProvider';
import { WMS } from '../core/configRouteConstant';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  ResourcesInterface,
} from '../type/actor-setup';
import { WMSFetchMetaResult } from '../component/wms';
import lodashDebounce from 'lodash/debounce';

interface GetMetaDataCatchError {
  response: { data: { userMessage: string } };
}

/**
 * It gets `metaData` and return it based on `wms` structure
 * @function getMetaData
 * @param { string } resource
 * @returns { Promise<WMSFetchMetaResult> } A promise include `wms` data structure
 */
export const getMetaData = async (resource: string): Promise<WMSFetchMetaResult> => {
  try {
    const data = await dataProvider(GET_META, resource);
    const receivedMetaData = data[0]; // `data` is an array: [metaData, null]

    return {
      data: receivedMetaData,
      error: null,
    };
  } catch (error) {
    const _error =
      (error as GetMetaDataCatchError)?.response?.data?.userMessage ??
      (error as GetMetaDataCatchError).toString();

    return {
      data: null,
      error: _error,
    };
  }
};

/**
 * It gets `metaData` based on `wms` structure and dispatch it
 * @function changeResourcesHandler
 * @param { ResourcesInterface } resources
 * @returns { Promise<void> } a promise of void
 */
const changeResourcesHandler = async (
  resources: ResourcesInterface,
): Promise<void> => {
  if (
    resources?.current == null ||
    resources.current.value === '/' ||
    !resources.current.value.includes(WMS)
  ) {
    return;
  }

  actorDispatch('showLoading', true);

  const currentResource = resources.current;

  let metaData = actorGetActionValue(
    'metaData',
    currentResource.value,
  ) as WMSFetchMetaResult | null;

  if (metaData?.data == null) {
    metaData = await getMetaData(currentResource.value);
  }

  actorDispatch(
    'metaData',
    {
      data: metaData.data,
      error: metaData.error,
    },
    {
      path: currentResource.value,
    },
  );

  actorDispatch('showLoading', false);
};

actorOnDispatch(
  'resources',
  lodashDebounce(changeResourcesHandler, 250, { leading: true }),
);
