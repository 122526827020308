import React, { useCallback, useState, FC, useRef, useEffect } from 'react';
import { IconButton, Icon, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useInput, useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { CustomTheme } from '../../core/themeProvider';
import { getBase64File, isFileTypeImage, toBase64 } from '../../helper/FileHelper';
import { isEmpty } from '../../helper/data-helper';
import { FieldType } from '../../helper/Types';
import { useWindowSize } from '../../hooks/useWindowSize';

interface StyleProps {
  calcHeight: string;
}

interface FileInputInterface {
  disabled: boolean;
  label: string;
  source: string;
  visibleClass: string;
  customError: string;
  onChange: Function;
  field: Partial<FieldType>;
  className: string;
  onClick: Function;
}

const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  root: {
    position: 'relative',
    border: '1px solid #BDBDBD',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    padding: 0,
    margin: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },

  legend: {
    fontSize: 10,
    lineHeight: 0,
    color: theme.palette.primary.appSecondaryTextColor,
    display: 'block',
    padding: '0 3px',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
    },
  },

  fieldsetError: {
    border: `1px solid ${theme.palette.error.main}`,
  },

  legendError: {
    color: theme.palette.error.main,
  },

  customFileInput: {
    width: 0,
    opacity: 0,
    cursor: 'pointer',
  },

  input: {
    '&[disabled]': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },
  },

  error: {
    '& + p': {
      position: 'absolute',
      right: 0,
    },
  },

  errorText: {
    position: 'absolute',
    right: 10,
    top: 10,

    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
      top: 2,
    },
  },

  inputFileValueName: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    overflow: 'hidden',
    display: 'block',
    width: 50,
    flexGrow: 1,
    padding: '10px 10px',
    [theme.breakpoints.down('sm')]: {
      padding: '4px 10px',
      fontSize: 8,
    },
  },

  uploadArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.appPrimaryBackgroundColor,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    height: '95%',
    padding: '0 3rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0 3rem',
    },
  },

  uploadBox: {
    border: `1px dashed ${theme.palette.grey[300]}`,
    padding: '1rem',
  },

  previewImage: {
    display: 'flex',
    justifyContent: 'center',
    height: ({ calcHeight }) => calcHeight,
  },
}));

interface FileInputInterface {
  disabled: boolean;
  label: string;
  source: string;
  visibleClass: string;
  customError: string;
  onChange: Function;
  record?: object;
  field: Partial<FieldType>;
  className: string;
  onClick: Function;
}

const FileInput: FC<FileInputInterface> = props => {
  const {
    disabled,
    label,
    source,
    visibleClass,
    customError,
    field,
    record = {},
  } = props;
  const translate = useTranslate();
  const [fileName, setFileName] = useState<string | null>(null);
  const [isImage, setIsImage] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [dragCounter, setDragCounter] = useState<number>(0);

  const inputFileRef = useRef<HTMLInputElement>(null);
  const dropRef = useRef<HTMLFieldSetElement>(null);
  const { width } = useWindowSize();

  const rowSpan: number =
    width && width < 1260
      ? 1
      : width && width > 1260 && lodashGet(field, 'rowSpan')
      ? lodashGet(field, 'rowSpan')
      : 1; // Don't set `1` to the third `lodashGet` parameter.

  const calcHeight = `${rowSpan * 12}px`;

  const classes = useStyles({ calcHeight });

  const url: string = lodashGet(record, lodashGet(field, 'relatedName'));

  /**
   * Get File from API and set data to specified variables
   * @function getFile
   * @return {void}
   */
  const getFile = async (): Promise<void> => {
    const { src, isImage } = await getBase64File({ link: url });
    setImageSrc(src.split(',')[1]);
    setIsImage(isImage);
  };

  const {
    input: { name, onChange, value },
    meta: { touched, error },
  } = useInput(props);

  useEffect(() => {
    setIsImage(isFileTypeImage(String(value).split('#')[0]) || false);
    setFileName(String(value).split('#')[0] || null);
    setImageSrc(String(value).split('#')[1]);
  }, [value]);

  useEffect(() => {
    if (!record || !url) {
      return;
    }
    getFile();
  }, [record]);

  useEffect(() => {
    const div = dropRef.current;
    div && div.addEventListener('dragenter', handleDragIn);
    div && div.addEventListener('dragleave', handleDragOut);
    div && div.addEventListener('dragover', handleDrag);
    div && div.addEventListener('drop', handleDrop);
    return () => {
      const div = dropRef.current;
      div && div.removeEventListener('dragenter', handleDragIn);
      div && div.removeEventListener('dragleave', handleDragOut);
      div && div.removeEventListener('dragover', handleDrag);
      div && div.removeEventListener('drop', handleDrop);
    };
  }, []);

  /**
   * we need to prevent the default browser behavior on that event, which is to open the dropped file.
   * @param {DragEvent} event
   * @returns {void}
   */
  const handleDrag = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
  };

  /**
   * define dragIn behavior and if there is dataTransferItems, setDragging(true)
   * @param {DragEvent} event
   * @function handleDragIn
   * @returns {void}
   */
  const handleDragIn = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    setDragCounter(prev => prev++);
    if (
      event &&
      event.dataTransfer &&
      event.dataTransfer.items &&
      event.dataTransfer.items.length > 0
    ) {
      setDragging(true);
    }
  };

  /**
   * define dragOut behaviour and  setDragging(false)
   * @param {DragEvent} event
   * @function handleDragOut
   * @returns {void}
   */
  const handleDragOut = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    setDragCounter(prev => prev--);
    if (dragCounter > 0) return;
    setDragging(false);
  };

  /**
   * function recives file from event.dataTrasfer.files and send it to onFileChange
   * @param {DragEvent} event
   * @function handleDrop
   * @returns {void}
   */
  const handleDrop = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    if (
      event &&
      event.dataTransfer &&
      event.dataTransfer.files &&
      event.dataTransfer.files.length > 0
    ) {
      const dropFile: any = {
        target: {
          files: event.dataTransfer.files,
        },
      };
      console.log('drop file', dropFile);
      onFileChange(dropFile);
      event.dataTransfer.clearData();
      setDragCounter(0);
    }
  };

  /**
   * encode user selected file to base64, add file-name before the `#` indicator and fire form onChange
   * @function onFileChange
   * @param {Event} event selected file in input
   * @returns {void}
   */
  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.target.files && setIsImage(isFileTypeImage(event.target.files[0].name));
      const fileMeta = lodashGet(event.target.files, '0');

      if (!fileMeta) {
        return;
      }

      toBase64(fileMeta).then(file => {
        const base64File = `${fileMeta.name}#${(file as string).substring(
          (file as string).indexOf(',') + 1,
        )}`;

        props.onChange(base64File);
        onChange(base64File);
      });
    },
    [props.onChange, onChange],
  );

  const hasError = !!(touched && error);

  /**
   * triger `onClick` input file
   * @function handleClick
   * @returns {void}
   */
  const handleClick = (): void => {
    if (inputFileRef && inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  /**
   * Remove file from formData
   * @function handleRemoveFile
   * @returns {void}
   */
  const handleRemoveFile = (): void => {
    fileName && setFileName(null);
    imageSrc && setImageSrc(null);
    props.onChange(null);
    onChange(null);
  };

  return (
    <fieldset
      ref={dropRef}
      className={`${visibleClass} ${classes.root} ${
        !!customError || hasError ? classes.fieldsetError : ''
      }`}
      data-test-field-name={source}
      disabled={disabled}
    >
      {label && !isEmpty(label) && (
        <legend
          className={`${classes.legend} ${
            !!customError || hasError ? classes.legendError : ''
          }`}
        >
          {label}
          {field.required ? '*' : ''}
        </legend>
      )}

      <input
        name={name}
        id={name}
        onChange={onFileChange}
        type="file"
        className={classes.customFileInput}
        disabled={disabled}
        required={field.required}
        data-test-has-error={!!customError || hasError}
        ref={inputFileRef}
      />
      <div onClick={handleClick} className={classes.uploadArea}>
        {dragging ? (
          <div className={classes.uploadBox} />
        ) : (
          <InsertDriveFileIcon style={{ color: 'rgba(0, 0, 0, 0.3)' }} />
        )}
      </div>
      <div className={classes.inputFileValueName} onClick={handleClick}>
        {isImage ? (
          <div className={classes.previewImage}>
            <img
              title={fileName ? fileName : ''}
              src={`data:image/jpeg;base64,${imageSrc}`}
              alt=""
            />
          </div>
        ) : !isImage && fileName ? (
          <span title={fileName ? fileName : ''}>{fileName}</span>
        ) : (
          translate('ra.input.selectFile')
        )}
      </div>
      {!isEmpty(value) && (
        <IconButton
          color="primary"
          size="small"
          onClick={handleRemoveFile}
          onTouchStart={handleRemoveFile}
          data-test-info={`${name}-removeFile`}
          disabled={disabled}
        >
          <Icon fontSize="small">clear</Icon>
        </IconButton>
      )}
      {hasError && (
        <Typography className={classes.errorText} variant="caption" color="error">
          {customError ? customError : translate(lodashGet(error, 'message', error))}
        </Typography>
      )}
    </fieldset>
  );
};

export default FileInput;
