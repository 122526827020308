import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { getIconClassName } from '@uifabric/styling';
import { refreshView as refreshViewAction } from 'react-admin';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

import UploadFileContainer from '../../container/UploadFileContainer';
import { CustomTheme } from '../../core/themeProvider';

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  uploadContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  icon: {
    margin: 12,
    fontSize: 20,
    color: theme.palette.primary.appPrimaryIconColor,
  },

  uploadFileContainer: {
    padding: 0,
    margin: 0,
    flexGrow: 1,
  },

  fileInput: {
    padding: 0,
    margin: 0,
  },

  dropZone: {
    padding: 0,
    margin: 0,
    backgroundColor: 'transparent',
    fontSize: 10,
    textAlign: 'start',
  },

  addToMyDayIconSelected: {},
}));

const TodoTaskAddFile = props => {
  const { onClick, task, resource, taskId, refreshView, onChange } = props;
  const classes = useStyles();

  const handleClick = () => {
    onClick()();
  };

  const handleNewAttachment = (fileInfo: object) => {
    onChange();
  };

  return (
    <div
      className={classes.container}
      onClick={handleClick}
      data-test-task-file-upload
    >
      <div className={classes.uploadContainer}>
        <i className={`${classes.icon} ${getIconClassName('Attach')}`}></i>
        <UploadFileContainer
          classes={{
            simpleForm: classes.uploadFileContainer,
            fileInput: classes.fileInput,
            dropZone: classes.dropZone,
          }}
          onChange={handleNewAttachment}
          resource={`${resource}/${taskId}`}
        />
      </div>
    </div>
  );
};

TodoTaskAddFile.propTypes = {
  resource: PropTypes.string.isRequired,
  taskId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  return {
    task: lodashGet(
      state,
      ['admin', 'resources', props.resource, 'data', props.taskId],
      {},
    ),
  };
};

const mapDispatchToProps = {
  refreshView: refreshViewAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TodoTaskAddFile,
);
