import { ReactElement, useMemo, useRef } from 'react';
import { Marker } from 'react-leaflet';
import { icon } from 'leaflet';

import { LocationMarkerViewInterface } from './location-marker.type';

const LocationMarker = (props: LocationMarkerViewInterface): ReactElement => {
  const { onChangeLocation, location, isDraggable = true } = props;

  const markerRef = useRef<any>(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          onChangeLocation(marker.getLatLng());
        }
      },
    }),
    [],
  );

  return (
    <Marker
      draggable={isDraggable}
      eventHandlers={eventHandlers}
      position={location}
      ref={markerRef}
      icon={icon({
        iconUrl: require(`../../../../images/icon/map/defaultMarkerMap.svg`).default,
        iconSize: [32, 32],
        iconAnchor: [16, 32],
      })}
    ></Marker>
  );
};

export default LocationMarker;
