import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getSellinListPatternInfo } from '../helper/PatternMetaHelper';
import ProductCardListView from '../component/card/ProductCardListView';
import ListContainerApiController, {
  ListViewProps,
} from '../container/ListContainerApiController';
import { PageType, SellinListPatternType } from '../helper/Types';
import LoadingBox from '../component/LoadingBox';
import { setBasketItemAction, removeBasketItemAction } from '../redux/shop/action';
import CartDataListContainer, {
  CartDataListContainerChildProps,
} from '../component/shop/CartDataListContainer';
import CheckResourceReady from '../container/CheckResourceReady';

interface SellinProductListPageProps extends PageType {
  resource: string;
  dispatch: Function;
  setBasketItem: Function;
  removeBasketItem: Function;
}

const defaultFilter = { JustAvailableNumber: false };

const SellinProductListPage: FC<SellinProductListPageProps> = props => {
  const {
    staticContext,
    dispatch,
    resource,
    setBasketItem,
    removeBasketItem,
    ...rest
  } = props;

  const pattern: SellinListPatternType = getSellinListPatternInfo(resource);

  const onAddToCart = (record, count) => {
    setBasketItem({
      resource: pattern.setBasketResource,
      data: {
        ...record,
        [pattern.basketCount]: count,
        [pattern.basketBarcodeId]: record[pattern.barcodeId],
        [pattern.basketItemPrice]: record[pattern.itemPrice],
        [pattern.basketProductId]: record[pattern.productId],
      },
      parentResource: pattern.basketResource,
      defaultSort: pattern.defaultSort,
    });
  };

  const onRemoveFromCart = basketProductId => {
    removeBasketItem({
      resource: pattern.removeBasketResource,
      id: basketProductId,
      parentResource: pattern.basketResource,
      defaultSort: pattern.defaultSort,
    });
  };

  return (
    <CheckResourceReady resourceName={resource}>
      <ListContainerApiController
        {...rest}
        basePath={`/${resource}`}
        resource={resource}
        perPage={1000000}
        sort={{ field: pattern.name }}
        filterDefaultValues={defaultFilter}
      >
        {(controllerProps: ListViewProps) => (
          <CartDataListContainer basketResource={pattern.listBasketResource}>
            {(basketProps: CartDataListContainerChildProps) => (
              <ProductCardListView
                {...rest}
                {...controllerProps}
                {...basketProps}
                pattern={pattern}
                onAddToCart={onAddToCart}
                onRemoveFromCart={onRemoveFromCart}
              />
            )}
          </CartDataListContainer>
        )}
      </ListContainerApiController>
    </CheckResourceReady>
  );
};

SellinProductListPage.propTypes = {
  resource: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { match }) => {
  const { moduleName, moduleTableName } = match.params;
  const resource = `${moduleName}/${moduleTableName}`;

  return {
    resource,
  };
};

const mapDispatchToProps = {
  setBasketItem: setBasketItemAction,
  removeBasketItem: removeBasketItemAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SellinProductListPage);
