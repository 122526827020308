import produce from 'immer';
import { USER_LOGOUT } from 'react-admin';
import { REFRESH_RELATION_ACTION, REFRESH_ONE_RELATION_ACTION } from './constant';
import { RelationVersionInterface } from './type';

const initialState: RelationVersionInterface = {
  relationVersion: 0,
  oneRelationVersion: {},
};

const myReducer = (state = initialState, { type, resource }) =>
  produce(state, draft => {
    // eslint-disable-next-line default-case
    switch (type) {
      case USER_LOGOUT:
        draft.relationVersion = 0;
        break;

      case REFRESH_RELATION_ACTION:
        draft.relationVersion = draft.relationVersion + 1;
        break;

      case REFRESH_ONE_RELATION_ACTION:
        draft.oneRelationVersion = {
          ...draft.oneRelationVersion,
          [resource]: {
            version: draft.oneRelationVersion[resource]?.version
              ? draft.oneRelationVersion[resource]?.version + 1
              : 1,
          },
        };
        break;
    }
  });

export default myReducer;
