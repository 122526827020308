import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '98%',
    marginTop: '1rem',
  },
  btnRoot: {
    margin: '5px auto',
  },

  splitBtnRoot: {
    fontSize: 12,
    fontWeight: 300,
    color: '#fff',
  },
  btn: {
    background: '#1D85D6 !important',
    borderColor: '#fff !important',
  },

  toggleBtn: {
    minWidth: '0 !important',
    background: '#1D85D6 !important',
  },

  formContainer: {
    minWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
  },

  inputContainer: {
    flexGrow: 1,
    maxHeight: '40px',
    width: 500,
    margin: '.5rem 0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& input': {
      marginTop: '10px',
    },
  },
}));
