import React from 'react';
import NumberFormat from 'react-number-format';

import { isEmpty } from '../../../../helper/data-helper';
import NumberFormatCustom from '../number-format-custom';
import { BaseNumberInputProps } from './base-number-input.type';

export default function BaseNumberInput(props: BaseNumberInputProps) {
  const {
    className,
    onChange,
    onAnimationStart,
    precision,
    format,
    value,
    ...other
  } = props;
  return (
    <NumberFormat
      value={value}
      // @ts-ignore
      inputCustomProps={{ precision, ...other }}
      onChange={onChange}
      isNumericString
      customInput={NumberFormatCustom}
      decimalScale={precision}
      thousandSeparator={!!format}
    />
  );
}
