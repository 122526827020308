import { FC, memo, useEffect, useState } from 'react';
import lodashGet from 'lodash/get';
import { SelectedMailInterface } from '..';
import { actorOnDispatch } from '../../../type/actor-setup';

import { MailDetailInterface } from './mail-detail.type';
import MailDetailView from './mail-detail.view';

const MailDetailController: FC<MailDetailInterface> = memo(props => {
  const { mailActionsHandler } = props;
  const [selectedMail, setSelectedMail] = useState<SelectedMailInterface[] | null>(
    null,
  );
  const [selectedMailLoading, setSelectedMailLoading] = useState<boolean>(false);

  useEffect(() => {
    actorOnDispatch('selectedMail', data => {
      data && setSelectedMail(data as SelectedMailInterface[]);
    });

    actorOnDispatch('loading', loading => {
      loading != undefined &&
        setSelectedMailLoading(lodashGet(loading, [`mailDetail`]));
    });
  }, []);

  return (
    <MailDetailView
      mailActionsHandler={mailActionsHandler}
      selectedMail={selectedMail}
      selectedMailLoading={selectedMailLoading}
    />
  );
});

export default MailDetailController;
