import { useEffect, useState } from 'react';
import { actorOnDispatch } from '../type/actor-setup';

/**
 * this function listen to actor resources and get current resource value.
 * @function useActorResource
 * @returns {string}
 */
export const useActorResource = (): string => {
  const [resource, setResource] = useState<string>('');
  useEffect(() => {
    actorOnDispatch('resources', details => {
      setResource(details?.current.value);
    });
  }, []);
  return resource;
};
