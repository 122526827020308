import type { ReactElement } from 'react';
import { useTranslate } from 'react-admin';

import { AppContainer } from '../app-container';
import { AppDrawer } from '../app-drawer';
import CustomCssContentView from './custom-css-content-view';
import getCssSidebarRoutesConfig from './custom-css.helper';
import type { CustomCssViewType } from './custom-css.type';

const CustomCssView = (props: CustomCssViewType): ReactElement => {
  const { onChangeDataStyle, value, sendData } = props;

  const translate = useTranslate();

  return (
    <AppContainer
      sidebarContent={
        <div data-style-cssSidebar="cssSidebarCss">
          <AppDrawer
            routesConfig={getCssSidebarRoutesConfig(translate)}
            baseUrl="/customcss"
            data-test="css-sidebar-drawer"
          />
        </div>
      }
    >
      <CustomCssContentView
        onChangeDataStyle={onChangeDataStyle}
        value={value}
        sendData={sendData}
      />
    </AppContainer>
  );
};

export default CustomCssView;
