import {
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import lodashFind from 'lodash/find';
import lodashGet from 'lodash/get';
import React, {
  ChangeEvent,
  FC,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslate } from 'react-admin';
import { isEmpty } from '../../helper/data-helper';
import {
  CLEAR_FILTER_VALUE,
  getModeList,
  HIDE_FILTER,
  onlyEqualModeList,
} from '../../helper/FilterHelper';
import {
  BOOLEAN_FIELD,
  DATE_FIELD,
  DECIMAL_FIELD,
  DROPDOWN_FIELD,
  dummyFunc,
  getTypeByField,
  NUMBER_FIELD,
} from '../../helper/InputHelper';
import { FieldType, ModeItemInterface } from '../../helper/Types';
import BooleanFilterInput from './BooleanFilterInput';
import DateFilterInput from './DateFilterInput';
import DropdownFilterInput from './DropdownFilterInput';
import NumberFilterInput from './NumberFilterInput';
import TextFilterInput from './TextFilterInput';

const useStyle = makeStyles(theme => ({
  container: {
    position: 'static',
    border: '1px solid #BDBDBD',
    borderRadius: theme.shape.borderRadius,
    padding: '4px 7px',
    margin: '5px 7px',
    flexGrow: 1,
    [theme.breakpoints.up('xs')]: {
      flexBasis: '100%',
    },
    [theme.breakpoints.up(850)]: {
      flexBasis: '47%',
    },
    [theme.breakpoints.up(1100)]: {
      flexBasis: '46%',
    },
    [theme.breakpoints.up(1400)]: {
      flexBasis: '23%',
    },
    display: 'flex',
    flexDirection: 'row',
  },

  fieldsetError: {
    border: `1px solid ${theme.palette.error.main}`,
  },

  legend: {
    lineHeight: '11px',
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
    width: 'auto',
  },

  legendError: {
    width: 'auto',
    lineHeight: '11px',
    fontSize: 12,
    color: theme.palette.error.main,
  },

  selectOperatorContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },

  select: {
    margin: '0 3px',
    '&:before': {
      border: 'none',
    },

    '&:after': {
      border: 'none',
    },
  },

  selectRoot: {
    fontSize: 12,
  },

  selectMenu: {
    padding: 0,
    paddingRight: 32,
  },

  divider: {
    border: `1px solid ${theme.palette.divider}`,
    height: 25,
  },

  closeFilterIcon: {
    fontSize: 16,
    margin: '0 5px',
    color: 'rgba(0, 0, 0, 0.4)',
    cursor: 'pointer',
  },

  menuItem: {
    fontSize: 12,
    padding: 5,
  },

  // to silence warnings, set empty classes
  form: {},
  body: {},
  icon: {},
}));

interface DynamicFilterInputInterface {
  field: FieldType;
  input: { onBlur: Function; onChange: Function; value: any[] };
  source: string;
  label: string;
  defaultOperator: string;
  handleHide: Function;
  onlyEqualCondition: boolean;
  resource: string;
  onChange: Function;
  onBlur: Function;
}

const DynamicFilterInput: FC<DynamicFilterInputInterface> = props => {
  const {
    handleHide,
    source,
    field,
    onlyEqualCondition,
    resource,
    label,
    input,
    onChange = dummyFunc,
    onBlur = dummyFunc,
    defaultOperator,
    ...rest
  } = props;

  const classes = useStyle();
  const translate = useTranslate();

  const modeList: ModeItemInterface[] = useMemo(
    () => (onlyEqualCondition ? onlyEqualModeList : getModeList(field)),
    [field],
  );

  // If the "defaultOperator" has a value and the "onlyEqualCondition" does not have a value,
  // then the "operator" value is equal to the "defaultOperator" value. Otherwise, it will be set to "on"
  const [operator, setOperator] = useState<string>(
    defaultOperator && !onlyEqualCondition ? defaultOperator : modeList[0].operator,
  );

  const [firstInputValue, setFirstInputValue] = useState<string>('');
  const [secondInputValue, setSecondInputValue] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);

  const prevValue = useRef<string>('');

  const { dropdown, name, required } = field;

  const isReport = resource.indexOf('report') === 0;
  const isMultiReport = resource.indexOf('multi-report') === 0;

  const modeInfo: ModeItemInterface = useMemo(
    () => lodashFind(modeList, { operator }),
    [operator],
  );

  useEffect(() => {
    const { value } = input;
    if (!isEmpty(value)) {
      const tempPrevValue = Array.isArray(value) ? JSON.stringify(value) : value;

      if (tempPrevValue !== prevValue.current) {
        if (Array.isArray(value)) {
          setOperator(value[1]);
          setFirstInputValue(value[2]);
          setSecondInputValue(value[3]);
        } else if (value && operator !== 'between') {
          setFirstInputValue(value);
        }

        // "like" is exactly "contains" for us, and we use only "contains" everywhere
        if (operator === 'like') {
          setOperator('contains');
        }

        prevValue.current = tempPrevValue;
      }
    } else {
      clearFilterValue();
    }
  }, [input]);

  useEffect(() => {
    notifyChange();
  }, [operator, firstInputValue, secondInputValue]);

  /**
   * `select` HTML tag trigger this function when change the mode item.
   * @function handleModeChange
   * @param event
   * @returns {void}
   */
  const handleModeChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ): void => {
    const operator = lodashGet(event, ['target', 'value']);

    if (operator === HIDE_FILTER) {
      handleHide(source);
      return;
    }
    if (operator === CLEAR_FILTER_VALUE) {
      clearFilterValue();
      return;
    }

    setOperator(operator);
  };

  /**
   * Check `modeInfo` and create `params` for run input `onChange`.
   * @function notifyChange
   * @returns {void}
   */
  const notifyChange = (): void => {
    // no ready to notify parent form
    if (
      !modeInfo ||
      (!modeInfo.both && isEmpty(firstInputValue)) ||
      (modeInfo.both && (isEmpty(firstInputValue) || isEmpty(secondInputValue)))
    ) {
      return;
    }

    const params = [source, modeInfo.operator, firstInputValue];
    if (modeInfo.both) {
      params.push(secondInputValue);
    }

    input.onChange(params);
    onChange(params);

    input.onBlur(params);
    onBlur(params);
  };

  /**
   * Set error for twin inputs.
   * @function handleTwinInputsError
   * @param {boolean} shouldShowError
   * @returns {void}
   */
  const handleTwinInputsError = (shouldShowError: boolean): void => {
    // should show error base of both inputs value
    const hasEmptyInput = isEmpty(firstInputValue) || isEmpty(secondInputValue);

    if (shouldShowError && field && required && hasEmptyInput) {
      if (hasError === false) {
        setHasError(true);
      }
    } else {
      // should not show Error at all because its not report or multi report
      if (hasError === true) {
        setHasError(false);
      }
    }
  };

  /**
   * Set error for a single input.
   * @function handleSingleInputError
   * @param {boolean} shouldShowError
   * @param {string | number | null} value
   * @returns {void}
   */
  const handleSingleInputError = (
    shouldShowError: boolean,
    value: string | number | null,
  ): void => {
    // should show error base of single input value
    if (shouldShowError && field && required && isEmpty(value)) {
      if (hasError === false) {
        setHasError(true);
      }
    } else {
      // should not show Error at all because its not report or multi report
      if (hasError === true) {
        setHasError(false);
      }
    }
  };

  /**
   * Filters validation check only required fields.
   * So here should set hasError state to 'true' to show required error on the rendered input.
   * It will only set state to 'true' when resource be a report or multi report.
   * @function handleInputError
   * @param {boolean} twinInputs
   * @param {string | number | null} value
   * @returns {void}
   */
  const handleInputError = (twinInputs, value) => {
    const isReport = resource.indexOf('report') === 0;
    const isMultiReport = resource.indexOf('multi-report') === 0;

    const shouldShowError = isReport || isMultiReport;

    if (twinInputs) {
      handleTwinInputsError(shouldShowError);
    } else {
      handleSingleInputError(shouldShowError, value);
    }
  };

  /**
   * This function decide which type of input should be render based on field type.
   * Also should check the value and required param in field and set the `hasError` state if needed.
   * Set `hasError` state depends on `twinInputs` prop. if it was twin inputs, it should check
   * both input1 and input2 value for setting state. Otherwise, it should only check current input value.
   * @function renderInput
   * @param {string|null} value - Value in input
   * @param {function} onChange
   * @param {boolean} twinInputs
   * @returns {JSX.Element} - An input to render
   */
  const renderInput = (
    value: string | number | null,
    onChange: Function,
    twinInputs: boolean,
  ): JSX.Element => {
    const params = {
      ...rest,
      field,
      value,
      onChange,
      input,
    };

    handleInputError(twinInputs, value);

    switch (getTypeByField(field)) {
      case DATE_FIELD:
        return <DateFilterInput {...params} />;

      case BOOLEAN_FIELD:
        return <BooleanFilterInput {...params} />;

      case DROPDOWN_FIELD:
        return (
          <DropdownFilterInput
            {...params}
            dropdownMeta={dropdown}
            parentResource={resource}
          />
        );

      case NUMBER_FIELD:
      case DECIMAL_FIELD:
        return <NumberFilterInput {...params} />;

      default:
        return <TextFilterInput {...params} />;
    }
  };

  /**
   * Clear filter value.
   * @function clearFilterValue
   * @returns {void}
   */
  const clearFilterValue = (): void => {
    input.onChange('');
    onChange('');

    setFirstInputValue('');
    setSecondInputValue('');
  };

  return (
    <fieldset
      className={`${classes.container} ${hasError ? classes.fieldsetError : null}`}
      data-test-has-error={hasError}
      data-test-field-name={name}
      data-style-filter-field="filterField"
    >
      <legend
        className={`${hasError ? classes.legendError : classes.legend}`}
        onDoubleClick={clearFilterValue}
        data-style-legend-field="legendField"
      >
        {label}
        {(isReport || isMultiReport) && required ? '*' : ''}
      </legend>
      <div className={classes.selectOperatorContainer}>
        <FormControl>
          <Select
            id={`operatorInput${name}`}
            className={classes.select}
            value={operator}
            onChange={handleModeChange}
            inputProps={{
              name: 'operator',
              id: 'operatorInput' + name,
            }}
            classes={{
              root: classes.selectRoot,
              selectMenu: classes.selectMenu,
            }}
          >
            {(isReport || isMultiReport) && required ? null : (
              <MenuItem
                value={HIDE_FILTER}
                className={classes.menuItem}
                data-test-filter-operator-item={HIDE_FILTER}
              >
                {translate('ra.action.remove_filter')}
              </MenuItem>
            )}

            <MenuItem
              value={CLEAR_FILTER_VALUE}
              className={classes.menuItem}
              data-test-filter-operator-item={CLEAR_FILTER_VALUE}
            >
              {translate('filter.clearFilterValue')}
            </MenuItem>
            {modeList &&
              modeList.map(item => (
                <MenuItem
                  key={item.name}
                  value={item.operator}
                  className={classes.menuItem}
                  data-test-filter-operator-item={item.operator}
                >
                  {translate(`filter.mode.${item.name}`)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {renderInput(firstInputValue, setFirstInputValue, modeInfo && modeInfo.both)}

        {modeInfo && modeInfo.both && (
          <Fragment>
            <span className={classes.divider} />
            {renderInput(
              secondInputValue,
              setSecondInputValue,
              modeInfo && modeInfo.both,
            )}
          </Fragment>
        )}
      </div>
      {hasError && (
        <Typography variant="caption" color="error">
          {translate('ra.validation.required')}
        </Typography>
      )}
    </fieldset>
  );
};

export default DynamicFilterInput;
