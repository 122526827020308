import { FC, memo } from 'react';

import {
  actorGetActionValue,
  actorSetActionValue,
  RecordKeyMode,
} from '../../../type/actor-setup';
import { QuickEditDialogFormInterface } from './quick-edit-dialog-form.type';
import QuickEditDialogFormView from './quick-edit-dialog-form.view';

const QuickEditDialogFormController: FC<QuickEditDialogFormInterface> = memo(
  props => {
    // Get resources
    const { current: currentResource } = actorGetActionValue('resources')!;

    const record = actorGetActionValue(
      'record',
      `${currentResource.value}.${currentResource.type}`,
    )!;

    actorSetActionValue('formData', record[RecordKeyMode.FULL], {
      path: `${currentResource.value}.${currentResource.type}`,
      replaceAll: true,
    });

    return <QuickEditDialogFormView {...props} />;
  },
);

export default QuickEditDialogFormController;
