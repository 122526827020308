import { FC, memo, useEffect, useState } from 'react';
import { useActorResource } from '../../hooks/useActorResource';
import { actorGetActionValue, actorOnDispatch } from '../../type/actor-setup';

import HomeTitleView from './home-title.view';

const HomeTitleController: FC = memo(() => {
  const [customText, setCustomText] = useState('');
  const resource = useActorResource();

  const menuPagePattern = /\/menu\/[0-9]*/;

  useEffect(() => {
    actorOnDispatch('urlInfo', urlInfo => {
      if (menuPagePattern.test(urlInfo?.location?.hash ?? '')) {
        const pageTitle = actorGetActionValue('setDocumentTitle')?.recordTitle ?? '';
        setCustomText(pageTitle);
      } else {
        setCustomText('');
      }
    });
  }, []);

  return <HomeTitleView resource={resource} customText={customText} />;
});

export default HomeTitleController;
