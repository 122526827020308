import { FC, memo } from 'react';

import { DiagramCardInterface } from './diagram-card.type';
import DiagramCardView from './diagram-card.view';

const DiagramCardController: FC<DiagramCardInterface> = memo(props => {
  const { item } = props;

  return <DiagramCardView item={item} />;
});

export default DiagramCardController;
