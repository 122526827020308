import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Dropdown from '@SamianSoft/dropdown';
import { useTranslate } from 'react-admin';
import lodashDebounce from 'lodash/debounce';
import clsx from 'classnames';

import { DropdownInputInterface } from './dropdown-input.type';
import {
  actorGetActionValue,
  actorSetActionValue,
  ResourceInterface,
} from '../../../type/actor-setup';
import {
  ChangeFormValueParams,
  FetchDropdownDataPayload,
  FormActions,
  InputRefContent,
  OnBlurParams,
  OnFocusParams,
} from '../../form';
import { isEmpty, isEmptyObject } from '../../../helper/data-helper';
import { SearchPopupButton } from '../../search-popup-button';
import { CustomTheme } from '../../../core/themeProvider';
import { useTheme } from '@material-ui/core';
import { isEnterPressed } from '../../../helper/FormHelper';
import { showNotification } from '../../../helper/general-function-helper';
import { replaceFarsiCharactersWithArabic } from '../../../helper/TextHelper';

const DropdownInputController: FC<DropdownInputInterface> = props => {
  const {
    getRef,
    dropdownMeta,
    value,
    formActionsHandler,
    inputInPuzzleForm,
    isProfile,
    isServiceMode,
    field,
    label,
    hint,
    disabled,
    inputMessage,
    addToRelationArray,
    visibleClass,
    disableDropdownSearchPopup,
    isSearchInput,
    customTestAttribute,
    inputContainerClass,
    forceResetWMSInputValue,
  } = props;

  const currentResource = actorGetActionValue('resources')!.current;
  const formData = actorGetActionValue(
    'formData',
    `${currentResource.value}.${currentResource.type}`,
  ) as Record<string, unknown> | undefined;

  const formGlobalProps = actorGetActionValue('formGlobalProps')!;

  const [isLoading, setIsLoading] = useState(false);
  const [dropdownUniqueId, setDropdownUniqueId] = useState<string | null>(null);
  const [selectedItem, setSelectedItem] = useState<Record<string, unknown> | null>(
    null,
  );
  const [dropdownData, setDropdownData] = useState<{
    items: Record<string, unknown>[];
    totalItemsCount: number;
  }>({
    items: [],
    totalItemsCount: 0,
  });

  const theme: CustomTheme = useTheme();

  const specialDropdownVariables = useRef<{
    isFirstLoad: boolean;
    currentPage: number;
    searchValue: string;
    currentResource: ResourceInterface;
    preventToAutoBlur: boolean;
    preventToAutoSelectFirstItem: boolean;
  }>({
    currentPage: 1,
    isFirstLoad: true,
    searchValue: '',
    currentResource,
    preventToAutoBlur: false,
    preventToAutoSelectFirstItem: false,
  });

  const buttonRef = useRef(); //TODO : this ref has not been used yet! remove or use it when dropdown completed
  const translate = useTranslate();

  const { relatedName, required, name, id: fieldId } = field;

  useEffect(() => {
    if (dropdownMeta == null) return;

    if (isProfile) {
      const profileDropdownData: {
        ALL?: Record<string, unknown>[] | undefined;
        DATA?: Record<string, string>[] | undefined;
        TOTAL?: number | undefined;
      } | null = actorGetActionValue('dropDownData', `${dropdownMeta['uniqueId']}`);

      if (profileDropdownData) {
        const { DATA, TOTAL } = profileDropdownData;
        setDropdownData({
          items: DATA!,
          totalItemsCount: Number(TOTAL),
        });
      }
    }

    setDropdownUniqueId(`${dropdownMeta.uniqueId}-${fieldId}`);

    return () => {
      specialDropdownVariables.current = {
        currentPage: 1,
        isFirstLoad: true,
        searchValue: '',
        currentResource,
        preventToAutoBlur: false,
        preventToAutoSelectFirstItem: false,
      };
    };
  }, []);

  useEffect(() => {
    actorSetActionValue('inputsRef', selectedItem, {
      path: `${currentResource.value}.${currentResource.type}.${name}.selectedItem`,
      callerScopeName: 'DropdownInputController: useEffect[selectedItem]',
    });
  }, [selectedItem]);

  /**
   * It makes selected options based on current drop data & will make a request to get data which not exist now
   * @function prepareSelectedItem
   * @returns { void } void
   */
  const prepareSelectedItem = (): void => {
    if (dropdownMeta == null) return;

    const { valueMember, displayMember, displayMember2 } = dropdownMeta;

    const nameInFormData = formData?.[name as string];

    if (inputInPuzzleForm && isSearchInput) {
      const relatedNameInFormData = formData?.[displayMember as string];
      const item = {
        [valueMember as string]: nameInFormData ?? value,
        [displayMember as string]: relatedNameInFormData ?? value,
      };

      if (displayMember2) {
        item[displayMember2 as string] = formData?.[displayMember2 as string];
      }

      setSelectedItem(item);
      return;
    }

    const relatedNameInFormData = formData?.[relatedName as string];

    const shouldSetSelectedDropdownItemByFormDataInfo = Boolean(
      !inputInPuzzleForm &&
        specialDropdownVariables.current.isFirstLoad === true &&
        !isProfile &&
        nameInFormData === value && // With current changes, we have to use `formData` instead of 'record'
        !(
          formGlobalProps.isCreateMode &&
          !isEmpty(nameInFormData) &&
          !relatedNameInFormData
        ),
    );

    if (shouldSetSelectedDropdownItemByFormDataInfo) {
      const item = {
        [valueMember as string]: nameInFormData ?? value,
        [displayMember as string]: relatedNameInFormData ?? value,
      };

      if (displayMember2) {
        item[displayMember2 as string] = formData?.[displayMember2 as string];
      }

      setSelectedItem(item);
    } else {
      const targetDropItemIndex = dropdownData.items.findIndex(
        item => item[valueMember as string] == value,
      );

      if (targetDropItemIndex === -1 && !isSearchInput) {
        handleOnSearch(String(value));
        specialDropdownVariables.current.searchValue =
          replaceFarsiCharactersWithArabic(value);
      } else {
        setSelectedItem(dropdownData.items[targetDropItemIndex]);
      }
    }
  };

  useEffect(() => {
    if (value === undefined) return; // Bypass the first render with `undefined` value

    if (!isEmpty(value)) {
      prepareSelectedItem();
      specialDropdownVariables.current.isFirstLoad = false;
    } else {
      specialDropdownVariables.current.isFirstLoad = false;
      setSelectedItem(null); // Handle `none` item selecting
    }
  }, [value]);

  useEffect(() => {
    if (dropdownMeta == null) return;
    const { valueMember, displayMember } = dropdownMeta;

    if (!isEmpty(specialDropdownVariables.current.searchValue)) {
      const targetItem = dropdownData.items.find(
        item =>
          item[valueMember as string] == value ||
          (item[displayMember as string] as string).indexOf(
            specialDropdownVariables.current.searchValue,
          ) > -1,
      ) ?? {
        [valueMember as string]: specialDropdownVariables.current.searchValue,
        [displayMember as string]: specialDropdownVariables.current.searchValue,
      };

      dropdownOnChange(targetItem);

      specialDropdownVariables.current.searchValue = '';
    } else {
      //if one record found, first item should be selected
      if (
        dropdownData &&
        dropdownData.totalItemsCount === 1 &&
        !specialDropdownVariables.current.preventToAutoSelectFirstItem &&
        !isSearchInput
      ) {
        dropdownOnChange(dropdownData.items[0]);
        requestAnimationFrame(() => {
          onBlur(dropdownData.items[0]);
        });
      }
    }
  }, [dropdownData]);

  /**
   * update dropdown data state
   * @function updateDropdownData
   * @param {object} data
   * @returns {void} void
   */
  const updateDropdownData = (data: {
    ALL?: Record<string, unknown>[] | undefined;
    DATA?: Record<string, string>[] | undefined;
    TOTAL?: number | undefined;
  }): void => {
    const { DATA = [], TOTAL = null } = data;

    setDropdownData({
      items: DATA,
      totalItemsCount: Number(TOTAL),
    });

    setIsLoading(false);
  };

  /**
   * it should clear previous dropdown data in actor state every time it will
   * open if it has data then call formActionsHandler method to get data
   * @function fetchDropdownDataHandler
   * @returns {void} void
   */
  const fetchDropdownDataHandler = (resumeGettingData = false): void => {
    if (dropdownMeta == null) return;

    const { currentResource } = specialDropdownVariables.current;

    if (!resumeGettingData && !isProfile && dropdownUniqueId) {
      setIsLoading(true); // We want to show loading when getting data on the first load every time, not always

      specialDropdownVariables.current.currentPage = 1;
      actorSetActionValue(
        'dropDownData',
        { DATA: [], TOTAL: 0 },
        {
          path: dropdownUniqueId,
          replaceAll: true,
        },
      );
    }

    formActionsHandler(FormActions.FetchDropdownData, {
      dropdownId: dropdownMeta.id,
      option: {
        dropdownInPuzzleForm: inputInPuzzleForm,
        isProfile,
        isService: isServiceMode,
        fieldName: name,
        fieldId,
        perPage: 10,
        page: resumeGettingData ? ++specialDropdownVariables.current.currentPage : 1,
      },
      dropdownMeta,
      resource: currentResource.value,
      resourceType: currentResource.type,
      hasMore: dropdownData.totalItemsCount > 10,
      successCallback: updateDropdownData,
      failureCallback: () => {
        setIsLoading(false);
      },
    } as FetchDropdownDataPayload);
  };

  /**
   * it will trigger on input change in form controller , then do same for map dropdown action
   * @function dropdownOnChange
   * @param {Record<string, unknown>}
   * @returns {void} void
   */
  const dropdownOnChange = (value: Record<string, unknown> | null): void => {
    if (inputInPuzzleForm && isSearchInput) return;

    setSelectedItem(null);

    specialDropdownVariables.current.preventToAutoSelectFirstItem =
      isEmptyObject(value);

    /**
     * On every change we first will empty the current selected item
     *  and after an animation frame, reset the current selected item
     *  that's why if current selected item is the same as previous item, this item should can select again
     */
    requestAnimationFrame(() => {
      const { valueMember, displayMember } = dropdownMeta;

      const { currentResource } = specialDropdownVariables.current;

      // use `isEmpty` function to handle dropdown items with value `0` or ''
      const computedValue =
        (value as Record<string, unknown>)?.[valueMember as string] ?? null;

      formActionsHandler(FormActions.InputChange, {
        fieldName: name,
        value: computedValue,
      });

      let toChangeFormDataPath = 'relatedName';
      if (inputInPuzzleForm) {
        toChangeFormDataPath = 'displayMember';
      }

      // To handle going from `quickForm` to `completeForm` 3140044
      actorSetActionValue(
        'formData',
        (value as Record<string, unknown>)?.[displayMember as string],
        {
          path: `${currentResource.value}.${currentResource.type}.${toChangeFormDataPath}`,
        },
      );

      formActionsHandler(FormActions.MapDropData, {
        field,
        params: {
          value: computedValue,
          selectedRecord: value,
          skipIfFormHasAlreadyValue: false,
        },
      });

      setSelectedItem(value);

      /**
       * In `WMS` and similar situations, when an item is selected by menu, `value` will change too,
       * then `prepareSelectedItem` will run and if `displayMember2` be `null` result will be a number instead of a correct label while
       * selected value has the complete data, to prevent this from happening we have to set `isFirstLoad` to `false` here
       */
      specialDropdownVariables.current.isFirstLoad = false;
      wmsDropdownInputBlurHandler();
    });
  };

  /**
   * it should trigger `fetchDropdownData` function in form controller to make request
   * @param {string | number} _searchValue
   * @returns {void} void
   */
  const handleOnSearch = (_searchValue: string | number): void => {
    if (isEmpty(_searchValue)) {
      if (inputInPuzzleForm && isSearchInput) return;

      setSelectedItem(null);
      fetchDropdownDataHandler();
      return;
    }

    // After searching and selecting an item we have to run `blur` event
    specialDropdownVariables.current.preventToAutoSelectFirstItem = false;

    let currentResource;
    if (inputInPuzzleForm) {
      currentResource = actorGetActionValue('resources')!.current;
    } else {
      currentResource = specialDropdownVariables.current.currentResource;
    }

    specialDropdownVariables.current.currentPage = 1;

    actorSetActionValue(
      'dropDownData',
      { DATA: [], TOTAL: 0 },
      {
        path: dropdownUniqueId!,
        replaceAll: true,
      },
    );

    const searchValueFixedFormat = replaceFarsiCharactersWithArabic(
      String(_searchValue),
    )
      .toString()
      .trim();

    // It is for `WMS` `searchDialog`
    if (inputInPuzzleForm && isSearchInput) {
      actorSetActionValue('formData', searchValueFixedFormat, {
        path: `${currentResource.value}.${currentResource.type}.${field.name}`,
      });
    }

    setIsLoading(true);

    const details = {
      dropdownId: dropdownMeta.id,
      option: {
        dropdownInPuzzleForm: inputInPuzzleForm,
        isProfile,
        isService: isServiceMode,
        fieldName: name,
        fieldId,
        perPage: 10,
        page: 1,
      },
      dropdownMeta,
      resource: currentResource.value,
      resourceType: currentResource.type,
      searchValue: searchValueFixedFormat,
      hasMore: dropdownData.totalItemsCount > 10,
      successCallback: (data: {
        ALL?: Record<string, unknown>[] | undefined;
        DATA?: Record<string, string>[] | undefined;
        TOTAL?: number | undefined;
      }) => {
        updateDropdownData(data);

        if (inputInPuzzleForm && data.TOTAL === 0) {
          showNotification('general.noRecordFound', 'error');
        }
      },
      failureCallback: (failureParams: {
        result: unknown[];
        error: unknown;
      }): void => {
        const { error } = failureParams;
        const _error =
          typeof error === 'object'
            ? (error as Record<string, unknown>).toString()
            : (error as string);

        showNotification(_error, 'error');
        setIsLoading(false);
      },
    } as FetchDropdownDataPayload;

    formActionsHandler(FormActions.FetchDropdownData, details);

    // finally should set dropdown items
  };

  /**
   * @function debouncedSearch
   * @param { string } value
   * @returns { void }
   */
  const debouncedSearch = useCallback(
    lodashDebounce((value: string) => {
      if (inputInPuzzleForm) {
        if (isSearchInput) return;

        formActionsHandler(FormActions.InputChange, {
          fieldName: name,
          value,
        } as ChangeFormValueParams);

        return;
      }

      handleOnSearch(value);
    }, 500),
    [],
  );

  /**
   * Handle Blur event
   * @function onBlur
   * @returns {void} void
   */
  const onBlur = (value?: Record<string, unknown> | null): void => {
    if (specialDropdownVariables.current.preventToAutoBlur) return;

    if (isEmptyObject(selectedItem) && inputInPuzzleForm && !isSearchInput) {
      dropdownOnChange(dropdownData.items[0]);
    }

    const finalValue =
      value?.[dropdownMeta.valueMember as string] ??
      /**
       * If `value` equal `undefined` maybe it means `onBlur` of internal input has been ran
       *  and if `selectedItem` has a value we have to send this value to the main `InputBlur`
       */
      selectedItem?.[dropdownMeta.valueMember as string] ??
      null;

    formActionsHandler(FormActions.InputBlur, {
      fieldName: name,
      value: finalValue,
    } as OnBlurParams);
  };

  /**
   * Handle focus event
   * @function onFocus
   * @returns {void} void
   */
  const onFocus = (): void => {
    formActionsHandler(FormActions.InputFocus, {
      fieldName: name,
      value,
    } as OnFocusParams);
  };

  const wmsDropdownInputBlurHandler = useCallback((): void => {
    if (isSearchInput || !inputInPuzzleForm) return;

    specialDropdownVariables.current.preventToAutoBlur = true;

    const formFocusManagementFunctions =
      actorGetActionValue('formGlobalProps')!.formFocusManagementFunctions;

    const inputRefs = actorGetActionValue(
      'inputsRef',
      `${currentResource.value}.${currentResource.type}`,
    ) as Record<string, InputRefContent>;

    const formData = actorGetActionValue(
      'formData',
      `${currentResource.value}.${currentResource.type}`,
    );

    formFocusManagementFunctions.focusOnNextInput(inputRefs, field.name, formData);

    // A delay to set `preventToAutoBlur` to `false` for other type of dropdowns
    requestAnimationFrame(() => {
      specialDropdownVariables.current.preventToAutoBlur = false;
    });
  }, [value, isSearchInput, inputInPuzzleForm]);

  /**
   * Handle `keyup` event of `dropdown` main input
   * @function keydownHandler
   * @param { React.KeyboardEvent<HTMLInputElement> } event
   * @returns {void} void
   */
  const keyupHandler = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (!isEnterPressed(event)) return;

    handleOnSearch((event.target as HTMLInputElement).value);
  };

  /**
   * @function handleChangeSearchPopupValue
   * @param {Record<string, unknown>} value
   * @returns {void} void
   */
  const handleChangeValueInSearchPopup = (
    value: Record<string, unknown> | null,
  ): void => {
    if (value) {
      setDropdownData(prevDropDownData => {
        return {
          items: [...prevDropDownData.items, value],
          totalItemsCount: prevDropDownData.totalItemsCount + 1,
        };
      });
    }
    dropdownOnChange(value);
  };

  return (
    <Dropdown
      clearable
      inputProps={customTestAttribute}
      dropdownMeta={dropdownMeta}
      dropdownData={dropdownData.items}
      value={selectedItem}
      rawValue={value ?? ''}
      className={clsx(visibleClass, inputContainerClass)}
      onChange={dropdownOnChange}
      focusHandler={onFocus}
      blurHandler={onBlur}
      fetchDropdownData={fetchDropdownDataHandler}
      fetchMoreDropdownData={() => fetchDropdownDataHandler(true)}
      onSearch={debouncedSearch}
      isLoading={isLoading}
      label={label}
      hint={hint}
      inputMessage={inputMessage}
      searchPlaceholder={translate('dropdown.searchPlaceholder')}
      noneLabel={translate('dropdown.noneLabel')}
      noDataFoundLabel={translate('dropdown.noOptionsMessage')}
      required={required}
      disabled={disabled}
      name={name}
      // @ts-ignore
      theme={theme}
      isSearchInput={isSearchInput} //dont Show Dropdown Menu
      isWMSInput={inputInPuzzleForm}
      hasMore={
        dropdownData.totalItemsCount > 10 &&
        specialDropdownVariables.current.currentPage * 10 <
          dropdownData.totalItemsCount
      }
      customOption={{
        ...field.customOption,
        widthPercent: Number(field.widthPercent),
      }}
      onKeyUp={keyupHandler}
      getRef={getRef}
      forceResetWMSInputValue={forceResetWMSInputValue}
      searchButton={
        !disabled && !disableDropdownSearchPopup && dropdownMeta != null ? (
          <SearchPopupButton
            formActionsHandler={formActionsHandler}
            resource={`${dropdownMeta.moduleName}/${dropdownMeta.tableName}`}
            parentResource={specialDropdownVariables.current.currentResource.value}
            source={name}
            dropdownMeta={dropdownMeta}
            relationResource={''} //TODO: pass correct value
            relationSource={''} //TODO: pass correct value
            relationInfo={{}}
            disabled={disabled}
            changeFormValue={handleChangeValueInSearchPopup}
            changeFormValueByClickingOnRow={handleChangeValueInSearchPopup}
            formData={formData ?? {}}
            fieldName={name}
            dropdownInPuzzleForm={inputInPuzzleForm}
            isTodo={false}
            isService={isServiceMode}
            isProfile={isProfile}
            fieldId={fieldId}
            parentResourceType={
              specialDropdownVariables.current.currentResource.type
            }
            label="label"
            field={field}
            addToRelationArray={addToRelationArray}
            buttonRef={buttonRef}
            dropdownMultipleSelection={false} // this will be used when `dropBaseMultiSelect` wanna use this new dropdown
            dropBaseValue={[]} // this will be used when `dropBaseMultiSelect` wanna use this new dropdown
            buttonCustomStyle={{}} // this will be used when `dropBaseMultiSelect` wanna use this new dropdown
          />
        ) : (
          <></>
        )
      }
    />
  );
};

// TODO: add memo
// export default memo(DropdownInputController, (prevProps, nextProps) => {
//   const prevResource = `${prevProps.dropdownMeta?.moduleName}/${prevProps.dropdownMeta?.moduleTableName}`;
//   const nextResource = `${nextProps.dropdownMeta?.moduleName}/${nextProps.dropdownMeta?.moduleTableName}`;

//   const prevVisibleClass = prevProps.visibleClass;
//   const nextVisibleClass = nextProps.visibleClass;

//   return (
//     prevProps.value === nextProps.value &&
//     prevResource === nextResource &&
//     prevVisibleClass === nextVisibleClass &&
//     prevProps.disabled === nextProps.disabled
//   );
// });

export default DropdownInputController;
