import { FIND_ONE, FIND_ONE_SUCCESS, FIND_ONE_FAILED } from './constant';

export function findOneAction(params) {
  return {
    type: FIND_ONE,
    ...params,
  };
}

export function findOneSuccessAction(params) {
  return {
    type: FIND_ONE_SUCCESS,
    ...params,
  };
}

export function findOneFailedAction(params) {
  return {
    type: FIND_ONE_FAILED,
    ...params,
  };
}
