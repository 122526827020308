import { FC, useState, memo } from 'react';

import { AppContainerInterface } from './app-container.type';
import AppContainerView from './app-container.view';

const AppContainerController: FC<AppContainerInterface> = memo(props => {
  const {
    title = '',
    cardStyle,
    sidebarContent,
    fullView = false,
    children,
    ...rest
  } = props;

  const [isAppDrawerOpen, setIsAppDrawerOpen] = useState<boolean>(false);

  /**
   * @function onToggleDrawer
   * @returns { void }
   */
  const onToggleDrawer = (): void => {
    setIsAppDrawerOpen(!isAppDrawerOpen);
  };

  /**
   * @function onCloseDrawer
   * @returns { void }
   */
  const onCloseDrawer = (): void => {
    setIsAppDrawerOpen(false);
  };

  return (
    <AppContainerView
      {...rest}
      title={title}
      isAppDrawerOpen={isAppDrawerOpen}
      onToggleDrawer={onToggleDrawer}
      onCloseDrawer={onCloseDrawer}
      cardStyle={cardStyle}
      sidebarContent={sidebarContent}
      fullView={fullView}
      children={children}
    />
  );
});

export default AppContainerController;
