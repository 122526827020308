import { FC, memo } from 'react';
import TextField from '@material-ui/core/TextField';

import { useStyles } from './grid-text-input.style';

import type { GridTextInputViewInterface } from './grid-text-input.type';

const GridTextInputView: FC<GridTextInputViewInterface> = props => {
  const { handleChangeValue, handleBlur, handleFocus, value, isDisabled } = props;
  const classes = useStyles();
  return (
    <div className={classes.textFieldContainer}>
      <TextField
        autoFocus={true}
        variant="standard"
        value={value}
        onChange={handleChangeValue}
        disabled={isDisabled}
        onBlur={handleBlur}
        InputProps={{ disableUnderline: true }}
        onFocus={handleFocus}
      />
    </div>
  );
};

export default memo(GridTextInputView);
