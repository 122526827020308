import { FC, memo } from 'react';

import { MessagesHeaderInterface } from './messages-header.type';
import MessagesHeaderView from './messages-header.view';

const MessagesHeaderController: FC<MessagesHeaderInterface> = memo(props => {
  const { selectedUser } = props;

  return <MessagesHeaderView selectedUser={selectedUser} />;
});

export default MessagesHeaderController;
