import { makeStyles } from '@material-ui/core';
import { StyleProps } from './diagram-card.type';
import { CustomTheme } from '../../../../../../core/themeProvider';
import { isEmpty } from '../../../../../../helper/data-helper';

export const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  cardContainer: {
    display: 'inline-flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    background: theme.palette.primary.appSecondaryBackgroundColor,
    padding: '0 20px 0 0',
    height: 130,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 4,
  },

  contentContainer: {
    height: '75%',
    display: 'flex',
    position: 'relative',
  },

  cardColorBox: {
    height: '100%',
    width: 12,
    background: ({ colorcode }) => (!isEmpty(colorcode) ? colorcode : '#16E0BD'),
  },

  truncate: {
    textAlign: 'start',
    maxWidth: '94%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'initial',
  },

  infoContainer: {
    width: 'max-content',
    minWidth: '200px',
    maxWidth: '250px',
  },

  avatar: {
    width: 55,
    height: 55,
  },

  tick: {
    alignSelf: 'flex-end',
    marginBottom: '.5rem',
  },

  fromContactInfoText: {
    color: theme.palette.grey[300],
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
}));
