import React, { useState, useCallback } from 'react';
import { InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getTodoTaskDetailsPatternInfo } from '../../helper/PatternMetaHelper';
import { getIconClassName } from '@uifabric/styling';
import { isEmpty } from '../../helper/data-helper';

const useStyles = makeStyles(theme => ({
  containerSearch: {
    backgroundColor: '#ffffff',
    display: 'inline-flex',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    padding: '0 5px',
    border: `1px solid ${theme.palette.divider}`,
    width: '250px',
    [theme.breakpoints.down('xs')]: {
      width: '150px',
    },
  },

  iconSearch: {
    fontSize: 16,
  },

  input: {
    marginLeft: 8,
    flex: 1,
    fontSize: 13,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
}));

const TodoSearchBox = props => {
  const { translate, resource, setFilters, handleSelectedList } = props;
  const classes = useStyles();

  const [myRef, setMyRef] = useState(props.ref);
  const [searchValue, setSearchValue] = useState('');
  const { title, parentId } = getTodoTaskDetailsPatternInfo(resource);

  const handleChange = useCallback(
    event => {
      if (event.target.value !== '') {
        setFilters({ [title]: [title, 'contains', event.target.value] });
      } else {
        setFilters({ [parentId]: [parentId, 'is', 'null'] });
      }
      setSearchValue(event.target.value);
      handleSelectedList();
    },
    [setFilters, title, parentId],
  );

  const handleClick = () => {
    if (myRef) {
      myRef.select();
    }
  };

  const toggleSearchBox = () => {
    setFilters({ [parentId]: [parentId, 'is', 'null'] });
    setSearchValue('');
  };

  return (
    <div className={classes.containerSearch}>
      <i className={`${classes.iconSearch} ${getIconClassName('Zoom')}`}></i>
      <InputBase
        className={classes.input}
        placeholder={translate('ra.action.search')}
        onChange={handleChange}
        inputProps={{ 'aria-label': translate('ra.action.search') }}
        onClick={handleClick}
        value={searchValue}
        inputRef={ref => setMyRef(ref)}
        id="todoSearchBox"
        autoFocus
      />
      {!isEmpty(searchValue) && (
        <i
          onClick={toggleSearchBox}
          className={`${classes.iconSearch} ${getIconClassName('Cancel')}`}
        ></i>
      )}
    </div>
  );
};

export default TodoSearchBox;
