import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';
import { ValidationErrorMessageType } from '../../../helper/Types';
import { getColorBaseOfStatus } from '../../input-base/input-message/input-message.helper';

export const useStyles = makeStyles<
  CustomTheme,
  { messageType?: ValidationErrorMessageType }
>(theme => ({
  InputAdornment: {
    width: 'auto !important',
  },

  root: {
    flexGrow: 1,
    margin: 0,
    height: '100%',

    '& legend': {
      display: 'none',
    },

    '& input': {
      textAlign: 'center',
    },
  },

  notchedOutline: {
    top: 0,
    borderColor: props => getColorBaseOfStatus(props.messageType, theme),
  },

  outlineRoot: {
    height: '100%',
  },

  disabled: {
    backgroundColor: theme.palette.grey[300],
  },

  icon: {
    zIndex: 1,
  },
}));
