import produce from 'immer';
import { USER_LOGOUT } from 'react-admin';
import {
  GET_ORDER_DETAIL_ITEM,
  GET_ORDER_DETAIL_ITEM_SUCCESS,
  GET_ORDER_DETAIL_ITEM_FAILED,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDER_DETAIL,
  SET_BASKET_PRODUCT_ID,
  GET_SUB_CATEGORY_SUCCESS,
} from './constant';

const initialState = {};

const myReducer = (
  state = initialState,
  { type, resource, id, data, additionalData, error, key },
) =>
  produce(state, draft => {
    // eslint-disable-next-line default-case
    switch (type) {
      case USER_LOGOUT:
        return initialState;

      case GET_ORDER_DETAIL_ITEM:
      case GET_ORDER_DETAIL:
        draft[resource] = {
          loading: true,
          error: null,
          data: null,
        };
        break;

      case GET_ORDER_DETAIL_ITEM_SUCCESS:
        draft[resource] = {
          loading: false,
          error: null,
          data: {
            [id]: data,
            [id + '_additionalData']: additionalData,
          },
        };
        break;

      case GET_ORDER_DETAIL_SUCCESS:
        draft[resource] = {
          loading: false,
          error: null,
          data: data,
        };
        break;

      case GET_ORDER_DETAIL_ITEM_FAILED:
        draft[resource] = {
          loading: false,
          error: error,
          data: null,
        };
        break;

      case SET_BASKET_PRODUCT_ID:
        draft[key] = id ? id : 0;
        break;

      case GET_SUB_CATEGORY_SUCCESS:
        draft[`${resource}/${key}`] = data;
        break;
    }
  });

export default myReducer;
