import React from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import { TextInput, minValue, maxValue, useTranslate } from 'react-admin';
import { Dialog, DialogTitle } from '@material-ui/core';
import NewSubmittableForm from '../NewSubmittableForm';
import QuickCreateButtonToolbar from '../QuickCreateButtonToolbar';
import { isEmptyObject } from '../../helper/data-helper';

const columnCountValidation = [minValue(1), maxValue(5)];

const FormLayoutGroupInfoDialog = props => {
  const translate = useTranslate();

  const { tabList, tabIndex, groupIndex, onCloseDialog, onChange } = props;
  const groupInfo = lodashGet(tabList, [tabIndex, 'groupList', groupIndex]);

  return (
    <Dialog open={!!groupInfo} onClose={onCloseDialog}>
      <DialogTitle>{translate('form.editGroupInfo')}</DialogTitle>
      <NewSubmittableForm
        save={onChange}
        record={groupInfo}
        // FIXME: comment these lines because `QuickCreateButtonToolbar` interface changed. should be uncomment when refactor FormLayout.
        // toolbar={
        //   <QuickCreateButtonToolbar
        //     disableFullFormButton
        //     closeDialog={onCloseDialog}
        //   />
        // }
      >
        {!isEmptyObject(groupInfo) &&
          !isEmptyObject(groupInfo.translatedTitle) &&
          Object.keys(groupInfo.translatedTitle).map(lang => (
            <TextInput
              key={lang}
              source={`translatedTitle.${lang}`}
              label={`form.translatedTitle.${lang}`}
            />
          ))}
        <TextInput
          source="columnCount"
          label="form.columnCount"
          validate={columnCountValidation}
        />
      </NewSubmittableForm>
    </Dialog>
  );
};

FormLayoutGroupInfoDialog.propTypes = {
  tabList: PropTypes.array.isRequired,
  tabIndex: PropTypes.number,
  groupIndex: PropTypes.number,
  onCloseDialog: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FormLayoutGroupInfoDialog;
