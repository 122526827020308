import React, { useContext, useState, useEffect, useMemo } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth/withWidth';
import lodashGet from 'lodash/get';
import lodashFind from 'lodash/find';
import querystring from 'qs';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import DynamicList from '../container/DynamicList';
import { NewMetaContext } from '../container/NewMetaContext';
import LoadingBox from '../component/LoadingBox';
import {
  closeTodoMenuSidebarAction,
  closeDialogAction,
  setSelectTodoList,
} from '../redux/todoList/action';
import TodoMenuSidebar from '../component/todo/TodoMenuSidebar';
import TodoActions from '../component/todo/TodoActions';
import TodoTaskListView from '../component/todo/TodoTaskListView';
import { getTodoListPatternInfo } from '../helper/PatternMetaHelper';
import NotFound from '../component/NotFound';
import CheckResourceReady from '../container/CheckResourceReady';
import { isEmpty, isEmptyObject } from '../helper/data-helper';
import TodoTaskItemSidebarContainer from '../component/todo/TodoTaskItemSidebarContainer';
import { findSelectedTodoList, findTodoListId } from '../helper/TodoListHelper';

const styles = theme => ({
  slideComponent: {
    padding: 0,
  },
});

const defaultEmptyObject = {};
const defaultEmptyArray = [];

const TodoPage = props => {
  const {
    staticContext,
    theme,
    width,
    closeTodoMenuSidebar,
    showTodoMenuSidebar,
    isSidebarItemOpen,
    resource,
    classes,
    listError,
    todoList,
    closeDialog,
    setSelectTodoList,
    selectedTodoList,
    ...rest
  } = props;

  const { getMeta } = useContext(NewMetaContext);
  const {
    staticFilterList,
    sort,
    id,
    taskDetailsResource,
    reportTaskDetailsResource,
  } = getTodoListPatternInfo(resource);

  const [todoListSelectedFilter, setTodoListSelectedFilter] = useState(null);
  const [todoListSelectedId, setTodoListSelectedId] = useState(null);
  const [userSort, setUserSort] = useState(sort);
  const [detailResource, setDetailResource] = useState(reportTaskDetailsResource);

  const params = querystring.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const metaData = getMeta(detailResource);

  useEffect(() => {
    if (params.detail && detailResource !== params.detail) {
      setDetailResource(params.detail);
    }
  }, [props.location]);

  useEffect(() => {
    processFilter();
  }, [params, todoList]);

  const latestSelectedTodoList = useMemo(() => {
    if (isEmptyObject(selectedTodoList) || isEmptyObject(todoList)) {
      return;
    }

    const updatedTodoList = lodashFind(todoList, {
      [id]: selectedTodoList[id],
    });

    setSelectTodoList(updatedTodoList);
    return updatedTodoList;
  }, [todoList, selectedTodoList, params]);

  useEffect(() => {
    closeDialog();
  }, [selectedTodoList]);

  useEffect(() => {
    if (
      showTodoMenuSidebar &&
      theme.breakpoints.width(width) <= theme.breakpoints.width('md')
    ) {
      closeTodoMenuSidebar();
    }
  }, [theme.breakpoints.width(width)]);

  const additionalViewComponentProps = useMemo(() => {
    return {
      selectedTodoList: latestSelectedTodoList,
      todoListResource: resource,
    };
  }, [resource, selectedTodoList, todoList]);

  const dynamicListClasses = useMemo(
    () => ({
      slide: classes.slideComponent,
    }),
    [classes.slideComponent],
  );

  const isReport = resource.indexOf('report') === 0;

  const onRootClick = () => {
    if (theme.breakpoints.width(width) < theme.breakpoints.width('md')) {
      if (showTodoMenuSidebar) {
        closeTodoMenuSidebar();
      }
    }
  };

  /**
   * Set filters based on URL params to determine selected list
   * @function processFilter
   * @returns {(void|undefiend)}
   */
  const processFilter = () => {
    let filterParams = params.filter;

    if (
      params.filter &&
      params.filter.includes('assignedtome') &&
      !params.filter.includes('sysitem')
    ) {
      setTodoListSelectedFilter('agentuser');
      setTodoListSelectedId(null);
      return;
    }

    if (!filterParams || (filterParams && filterParams.includes('detailtitle'))) {
      // Search in tasks list
      setTodoListSelectedFilter(null);
      setSelectTodoList(defaultEmptyObject);
    }
    if (!filterParams) {
      // Default task list
      setTodoListSelectedFilter(null);
      setTodoListSelectedId(null);
      return;
    }

    filterParams = JSON.parse(filterParams);

    const filterWithLabelParams = filterParams[staticFilterList.filterName];

    if (filterWithLabelParams) {
      const filterLabel = filterWithLabelParams[0];

      if (filterLabel && filterLabel !== todoListSelectedFilter) {
        setTodoListSelectedFilter(filterLabel);
        setTodoListSelectedId(null);
      }
    }

    const filterWithListIdParams = filterParams[staticFilterList.listId];
    if (filterWithListIdParams) {
      const filterListIdValue = filterWithListIdParams[2];

      if (filterListIdValue !== todoListSelectedId) {
        setTodoListSelectedId(filterListIdValue);
        setTodoListSelectedFilter(null);

        const list =
          todoList &&
          todoList.filter(
            item => parseInt(item[id]) === parseInt(filterListIdValue),
          );
        if (list && list.length) {
          setSelectTodoList(list[0]);
        } else {
          setSelectTodoList(defaultEmptyObject);
        }
      }
    }
  };

  if (listError) {
    return <NotFound title={listError} />;
  }

  if (!isEmptyObject(metaData) && !isEmpty(metaData.error)) {
    return <NotFound title={metaData.error} />;
  }

  if (!metaData || (!isEmptyObject(params) && params.detail !== detailResource)) {
    return <LoadingBox />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <CheckResourceReady resourceName={detailResource}>
        <DynamicList
          {...rest}
          resource={detailResource}
          metaData={metaData}
          hasList={true}
          hasCreate={!isReport}
          hasEdit={!isReport}
          hasShow={!isReport}
          hasDelete={!isReport}
          enableSelection={true}
          basePath={`/${detailResource}`}
          slideComponent={
            <TodoMenuSidebar
              {...rest}
              todoListSelectedFilter={todoListSelectedFilter}
              todoListSelectedId={todoListSelectedId}
              resource={resource}
              detailResource={taskDetailsResource}
              userSort={userSort}
            />
          }
          sidebarItemComponent={
            <TodoTaskItemSidebarContainer detailResource={taskDetailsResource} />
          }
          listActionComponent={
            <TodoActions
              todoListResource={resource}
              detailResource={taskDetailsResource}
              selectedTodoList={selectedTodoList}
              setUserSort={setUserSort}
            />
          }
          isSlideOpen={showTodoMenuSidebar}
          isSidebarItemOpen={isSidebarItemOpen}
          onRootClick={onRootClick}
          isTopFilterOpen={false}
          isGroupingOpen={false}
          quickEditRowCallback={null}
          settingToolbarComponent={null}
          listFilterComponent={null}
          pagination={null}
          perPage={1000}
          sort={
            params.filter && params.filter.includes('sysitem')
              ? { field: 'id', order: 'ASC' }
              : userSort
          } // set defult sort on system tasks
          viewComponent={
            <TodoTaskListView
              detailResource={taskDetailsResource}
              todoListSelectedFilter={todoListSelectedFilter}
            />
          }
          classes={dynamicListClasses}
          additionalViewComponentProps={additionalViewComponentProps}
          enableSetSetting={false}
          withFile
        />
      </CheckResourceReady>
    </DndProvider>
  );
};

TodoPage.propTypes = {
  resource: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { match, location }) => {
  const { moduleName, moduleTableName } = match.params;
  const resource = `${moduleName}/${moduleTableName}`.toLowerCase();
  const todoList = lodashGet(
    state,
    ['todoList', 'resultList', resource],
    defaultEmptyArray,
  );
  const selectedListMember = lodashGet(state, ['todoList', 'selectedListMember']);

  const { staticFilterList } = getTodoListPatternInfo(resource);

  const todoListId = findTodoListId(staticFilterList, lodashGet(location, 'search'));

  const selectedTodoList = findSelectedTodoList(
    staticFilterList,
    todoList,
    selectedListMember,
    todoListId,
  );

  return {
    resource,
    todoList,
    showTodoMenuSidebar: lodashGet(state, ['todoList', 'showTodoMenuSidebar']),
    listError: lodashGet(state, ['todoList', 'errorList', resource]),
    isSidebarItemOpen: lodashGet(state, ['todoList', 'isOpen']),
    selectedTodoList,
  };
};

const mapDispatchToProps = {
  closeTodoMenuSidebar: closeTodoMenuSidebarAction,
  closeDialog: closeDialogAction,
  setSelectTodoList,
};

export default compose(
  withWidth(),
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(TodoPage);
