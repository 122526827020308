import { FC } from 'react';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { LogoutButtonViewInterface } from './logout-button.type';
import { useTranslate } from 'react-admin';

const LogoutButtonView: FC<LogoutButtonViewInterface> = props => {
  const { userLogoutHandler } = props;
  const translate = useTranslate();

  return (
    <Tooltip title={translate('ra.auth.logout')}>
      <IconButton
        color="inherit"
        onClick={userLogoutHandler}
        data-test-logout-button
        id="logoutIcon"
      >
        <ExitIcon />
      </IconButton>
    </Tooltip>
  );
};

export default LogoutButtonView;
