import { FC, memo } from 'react';
import { useLocale } from 'react-admin';
import lodashGet from 'lodash/get';
import { API_URL, getValue } from '../../../core/configProvider';
import { isEmpty } from '../../../helper/data-helper';
import { showNotification } from '../../../helper/general-function-helper';
import {
  callQuickAccess,
  linkOpenHandler,
  openNewTab,
} from '../../../helper/QuickAccessHelper';
import { FieldType } from '../../../helper/Types';
import { actorDispatch } from '../../../type/actor-setup';
import { downloadFile } from '../../field/multi-file-stream-field/multi-file-stream-field.helper';
import { GridCellControllerInterface } from './grid-cell.type';
import GridCellView from './grid-cell.view';

const GridCellViewController: FC<GridCellControllerInterface> = props => {
  const {
    row,
    value,
    column,
    basePath,
    redirect,
    metaData,
    hasShow,
    showImageDialog,
    relationMode,
    addToFilterRequestList,
    saveInlineEdit,
    resource,
    hasEdit,
    onFailureSaveForm,
  } = props;

  const { name, field } = column;
  const locale = useLocale();

  /**
   * It applies the field to filter when onClick triggered.
   * @function setCurrentValueToFilter
   * @returns {void}
   */
  const setCurrentValueToFilter = (): void => {
    if (!field) return;
    const { parameterKey, relatedName } = field;
    const value = lodashGet(row, relatedName);

    if (typeof addToFilterRequestList == 'function') {
      addToFilterRequestList({ name: parameterKey, value });
    }
  };

  /**
   * call showImageDialog for show file or image when onClick triggered.
   * @function handleFileFieldClick
   * @returns {void}
   */
  const handleFileFieldClick = (event: React.MouseEvent): void => {
    event.stopPropagation(); // to prevent trigger grid row click
    showImageDialog({
      url: lodashGet(row, lodashGet(column, ['field', 'relatedName'])),
      isGetFileMode: true,
      fileName: lodashGet(row, lodashGet(column, ['field', 'name'])),
    });
  };

  /**
   * this function trigger callQuickAccess to open quickAccessUrl in new tab
   * @function handleTableQuickAccessFieldClick
   * @returns {Promise<void>} Promise<void>
   */
  const handleTableQuickAccessFieldClick = async (): Promise<void> => {
    try {
      // Based on last changes (backend), in tables we have to check `__FieldName_AdditionalData` and process it if there is
      // https://doc.samiansoft.com/pages/viewpage.action?pageId=13403866
      let additionalDataLinkInfo = row?.[
        `__${field?.relatedName}_additionaldata`
      ] as string;
      if (!isEmpty(additionalDataLinkInfo)) {
        additionalDataLinkInfo = additionalDataLinkInfo.replaceAll('\r\n  ', ''); //remove extra space
        const parsedLinkData = JSON.parse(additionalDataLinkInfo);
        linkOpenHandler(parsedLinkData.link, parsedLinkData.linktype);
      } else {
        const linkNameValueUrl = lodashGet(
          row,
          lodashGet(column, ['field', 'linkName']),
          value,
        );

        const linkNameValue = linkNameValueUrl.substring(
          linkNameValueUrl.lastIndexOf('/') + 1,
        );

        const url = await callQuickAccess(linkNameValue);
        openNewTab(url);
      }
    } catch (error) {
      showNotification(error, 'warning');
    }
  };

  /**
   * onClick boolean field to inline edit it
   * @function handleBooleanFieldInlineEdit
   * @returns { void }
   */
  const handleBooleanFieldInlineEdit = (): void => {
    const changedData = { [name]: !value };
    saveInlineEdit(changedData, row, resource, onFailureSaveForm, undefined, true);
  };

  /**
   * open files dialog
   * @function openMultiFileListDialog
   * @param {FieldType} field
   * @param {any} event
   * @returns { void } void
   */
  const openMultiFileListDialog = (field: FieldType) => (event: any) => {
    event.stopPropagation(); // to prevent trigger grid row click

    actorDispatch('filesDialog', {
      isOpen: true,
      data: lodashGet(row, lodashGet(column, ['field', 'relatedName']), value),
      title: field?.translatedCaption?.[locale] ?? '',
    });
  };

  /**
   * download an element with simulation <a/> tag
   * @function saveFile
   * @returns { void } void
   */
  const handleSaveFile = (): void => {
    const filePath = lodashGet(
      row,
      lodashGet(column, ['field', 'relatedName']),
      value,
    );
    const fullUrl = getValue(API_URL);

    downloadFile(filePath, fullUrl);
  };

  return (
    <GridCellView
      handleSaveFile={handleSaveFile}
      openMultiFileListDialog={openMultiFileListDialog}
      handleBooleanFieldInlineEdit={handleBooleanFieldInlineEdit}
      handleTableQuickAccessFieldClick={handleTableQuickAccessFieldClick}
      handleFileFieldClick={handleFileFieldClick}
      setCurrentValueToFilter={setCurrentValueToFilter}
      row={row}
      value={value}
      column={column}
      basePath={basePath}
      redirect={redirect}
      metaData={metaData}
      hasShow={hasShow}
      relationMode={relationMode}
      resource={resource}
      hasEdit={hasEdit}
    />
  );
};

export default memo(GridCellViewController);
