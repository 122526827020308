import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import lodashFilter from 'lodash/filter';
import lodashGet from 'lodash/get';
import lodashKeys from 'lodash/keys';
import compose from 'recompose/compose';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { GET_LIST } from 'react-admin';

import { CATEGORY_LIST, SELLIN_LIST } from '../../core/configRouteConstant';
import { API_URL, getValue } from '../../core/configProvider';
import LoadingBox from '../LoadingBox';
import { isEmpty } from '../../helper/data-helper';
import dataProvider from '../../core/dataProvider';

const styles = theme => ({
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
  },

  listContainer: {
    flexGrow: 1,
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
  },

  avatar: {
    backgroundColor: theme.palette.grey[300],
  },
});

const CategoryListView = props => {
  const { classes, data, patternData, idParent, resource } = props;
  const firstIdParent = !isEmpty(idParent)
    ? idParent
    : lodashGet(lodashKeys(data), 0);
  const [parent, setParent] = useState(parseInt(firstIdParent, 10));

  const [subCatList, setSubCatList] = useState([]);
  const [isLoadingSubCategory, setIsLoadingSubCategory] = useState(false);

  useEffect(() => {
    handleChange(null, firstIdParent);
  }, [idParent]);

  const listOfSubCats = async idParent => {
    let arrData = [];
    const apiUrl = getValue(API_URL);

    if (idParent) {
      const data = await dataProvider(GET_LIST, resource, {
        sort: { field: 'Priority', order: 'DESC' },
        pagination: { page: 1, perPage: 1000 }, // only change pagination
        filter: { [patternData.idParent]: idParent },
      });

      const subCategories = data.data;
      arrData = subCategories.map(record => {
        const link = record[patternData.hasChild]
          ? `/${CATEGORY_LIST}/${resource}/${record.id}`
          : `/${SELLIN_LIST}/${patternData.targetResource}?filter={"${patternData.targetResourceParentId}":"${record.id}"}`;

        return (
          <ListItem key={record[patternData.id]} button component={Link} to={link}>
            <Avatar
              className={classes.avatar}
              alt={record[patternData.name]}
              src={`${apiUrl}/${record[patternData.imagePath]}/${
                record[patternData.imageFileName]
              }`}
            />
            <ListItemText primary={record[patternData.name]} disableTypography />
          </ListItem>
        );
      });
    }

    return arrData;
  };

  const handleChange = (event, value) => {
    setParent(parseInt(value, 10));
    setIsLoadingSubCategory(true);

    listOfSubCats(value).then(list => {
      setSubCatList(list);
      setIsLoadingSubCategory(false);
    });
  };

  return (
    <div className={classes.rootContainer}>
      <AppBar position="static" color="default">
        <Tabs
          onChange={handleChange}
          value={parent}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {lodashFilter(data).map(record => {
            return (
              <Tab
                label={record[patternData.name]}
                key={record[patternData.id]}
                value={record[patternData.id]}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {isLoadingSubCategory ? (
        <LoadingBox />
      ) : (
        <div className={classes.listContainer}>
          <List component="nav">{subCatList}</List>
        </div>
      )}
    </div>
  );
};

CategoryListView.propTypes = {
  patternData: PropTypes.object,
  idParent: PropTypes.string,
};

export default compose(withStyles(styles, { withTheme: true }))(CategoryListView);
