import { FC, memo } from 'react';

import { UserItemInterface } from './user-item.type';
import UserItemView from './user-item.view';

const UserItemController: FC<UserItemInterface> = memo(props => {
  const { selectedUser, item, onUserSelect } = props;

  /**
   * @function handleOnUserSelect
   * @returns { void }
   */
  const handleOnUserSelect = (): void => {
    onUserSelect(item);
  };

  return (
    <UserItemView
      selectedUser={selectedUser}
      item={item}
      handleOnUserSelect={handleOnUserSelect}
    />
  );
});

export default UserItemController;
