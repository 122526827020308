import produce from 'immer';
import { USER_LOGOUT, CRUD_CREATE_SUCCESS } from 'react-admin';
import { REFRESH_GRID, RESET } from './constant';

const initialState = {
  refreshCounter: 0,
  scrollCounter: 0,
};

const myReducer = (state = initialState, { type }) =>
  produce(state, draft => {
    // eslint-disable-next-line default-case
    switch (type) {
      case RESET:
      case USER_LOGOUT:
        draft.refreshCounter = 0;
        draft.scrollCounter = 0;
        break;
      case REFRESH_GRID:
        draft.refreshCounter++;
        break;
      case CRUD_CREATE_SUCCESS:
        draft.scrollCounter++;
        break;
    }
  });

export default myReducer;
