import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { DateField as GregorianDateField } from 'react-admin';
import lodashGet from 'lodash/get';

import JalaliDateField from '../component/JalaliDateField';
import { getCalendarType } from '../helper/DateHelper';

const DateFieldContainer = props => {
  const { field, label, record, source } = props;

  const currentCalendar = getCalendarType(lodashGet(field, 'fixCalendar'));
  const shouldShowTime = lodashGet(field, ['dataType', 'simple']) === 'datetime';

  return currentCalendar === 'jalali' ? (
    <JalaliDateField
      {...props}
      format={shouldShowTime ? 'jYYYY-jMM-jDD HH:mm:ss' : 'jYYYY-jMM-jDD'}
    />
  ) : (
    <GregorianDateField
      field={field}
      label={label}
      record={{ [source]: record[source] }}
      source={source}
      locales={'en-CA'}
      showTime={shouldShowTime}
    />
  );
};

DateFieldContainer.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.any,
  source: PropTypes.string,
  record: PropTypes.object,
};

export default memo(DateFieldContainer);
