import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton, Tooltip, withStyles, Icon } from '@material-ui/core';
import { translate } from 'react-admin';
import { askNameForAction } from '../redux/listFilter/action';

const styles = theme => ({
  icon: {
    margin: 8,
    color: 'hsl(0, 0%, 60%)',
  },

  IconButton: {
    padding: 7,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
});

const defaultResources = {};

class SaveFilterButton extends PureComponent {
  saveFilter = event => {
    const { resource, askNameAndSaveFilter, resources } = this.props;

    const filter = resources[resource].list.params.filter;

    askNameAndSaveFilter({
      resource,
      data: filter,
    });
  };

  render() {
    const { classes, translate, resources, resource } = this.props;
    const filter = lodashGet(resources, [resource, 'list', 'params', 'filter'], {});

    return (
      <Tooltip title={translate('filter.saveFilter')}>
        <div>
          <IconButton
            color="primary"
            size="small"
            className={classes.IconButton}
            onClick={this.saveFilter}
            disabled={!Object.keys(filter).length}
            id="saveFilterButton"
          >
            <Icon fontSize="small">save</Icon>
          </IconButton>
        </div>
      </Tooltip>
    );
  }
}

SaveFilterButton.propTypes = {
  resource: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  resources: lodashGet(state, `admin.resources`, defaultResources),
});

const mapDispatchToProps = {
  askNameAndSaveFilter: askNameForAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(translate(SaveFilterButton)));
