import { makeStyles } from '@material-ui/core/styles';

export const InputStyles = makeStyles(theme => ({
  inputStyle: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    '& input': {
      padding: 13,
      lineHeight: 0,
    },
    '& label': {
      transform: 'scale(1) translate(14px, 16px)',
      fontSize: 13,
    },
    '& div': {
      fontSize: 13,
      height: '100%',
    },
    margin: 0,
    [theme.breakpoints.down('md')]: {
      '& label': {
        transform: 'scale(1) translate(14px, 12px)',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      '& input': {
        padding: 7,
        lineHeight: 0,
      },
      '& label': {
        transform: 'scale(1) translate(14px, 10px)',
        fontSize: 10,
      },
      '& div': {
        fontSize: 10,
      },
    },
  },

  longInputStyle: {
    width: '100%',
    height: '100%',
    '& > div': {
      padding: 13,
    },
  },

  longTextInputStyleNoLabel: {
    borderRadius: 4,
    width: '100%',
    height: '100%',
    '& > div': {
      padding: 10,
    },
  },

  booleanStyle: {
    height: '100%',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: '0 3px',
    margin: 0,
    width: '100%',
    '& label': {
      height: '100%',
      margin: 0,
      '& span': {
        fontSize: 13,
      },
    },
    '&[data-focus=false]:hover': {
      border: `1px solid`,
    },
    '&[data-focus=true]': {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.main}`,
    },
    '&[data-blur=true]': {
      boxShadow: 'none',
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
    position: 'relative',
  },

  inputStyleNoLabel: {
    borderRadius: 4,
    width: '100%',
    height: '100%',
    '&[data-disabled-for-style=true]': {
      backgroundColor: theme.palette.grey[300],
    },
    '& input': {
      padding: 10,
      lineHeight: 0,
    },
  },

  inputStylePuzzleForm: {
    margin: '7px 3px 0',
    '&[data-disabled-for-style=true]': {
      backgroundColor: theme.palette.grey[300],
    },
    height: 'auto',
  },
}));
