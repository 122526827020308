import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { getIconClassName } from '@uifabric/styling';

import { TODO } from '../../core/configRouteConstant';
import { getTodoListPatternInfo } from '../../helper/PatternMetaHelper';

interface TodoMenuSystemTaskItemType {
  title: string;
  icon: string;
  resource: string;
  filterType: string;
  color?: string;
  todoListSelectedFilter: string;
  todoListSelectedId: number;
  onClick: Function;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 15px',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 200ms',
    '&:hover': {
      backgroundColor: '#FAFAFA',
      transition: 'background-color 200ms',
    },
  },
  linkContainer: {
    textDecoration: 'none',
  },

  title: {
    flexGrow: 1,
  },

  icon: {
    margin: '0 10px 0 0',
    fontSize: 18,
    padding: 2,
  },
}));

const TodoMenuSystemTaskItem: FC<TodoMenuSystemTaskItemType> = props => {
  const {
    title,
    icon,
    resource,
    filterType,
    color = 'rgba(0, 0, 0, 0.54)',
    todoListSelectedFilter,
    todoListSelectedId,
    onClick,
  } = props;
  const classes = useStyles();
  const { reportTaskDetailsResource, staticFilterList } =
    getTodoListPatternInfo(resource);
  const { filterName } = staticFilterList;

  const url = `#/${TODO}/${resource}?detail=${reportTaskDetailsResource}&filter={"${filterName}":["${filterType}","=","1"]}`;
  const getColor = () => {
    return {
      color,
    };
  };

  /**
   * highlight bg color if the item is selected
   */
  const selectedStyle = (): object => {
    return {
      backgroundColor:
        isNaN(todoListSelectedId) &&
        todoListSelectedFilter &&
        todoListSelectedFilter === filterType
          ? '#e4eaec'
          : 'unset',
    };
  };

  /**
   * Dispatch an action to clear selected list in redux store
   * @function handleClick
   * @returns {void}
   */
  const handleClick = (): void => {
    onClick();
  };

  return (
    <a
      className={classes.linkContainer}
      href={url}
      onClick={handleClick}
      data-test-todo-menu={title}
      data-test-todo-menu-icon={icon}
    >
      <div className={classes.container} style={selectedStyle()}>
        <i
          className={`${classes.icon} ${getIconClassName(icon)}`}
          style={getColor()}
        ></i>
        <Typography variant="caption" className={classes.title} style={getColor()}>
          {title}
        </Typography>
      </div>
    </a>
  );
};

export default TodoMenuSystemTaskItem;
