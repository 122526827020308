import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FieldType, MetaData, ValidationError } from '../../../helper/Types';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
  FormKeyMode,
  RecordKeyMode,
} from '../../../type/actor-setup';
import {
  FormActions,
  InitialData,
  InputRefContent,
  OnBlurParams,
} from '../form.type';
import { FormWithTabsProps } from './form-with-tab.type';
import { FormWithTabsView } from './form-with-tab.view';

//TODO: turn setting HOC to hook on https://jira.samiansoft.com/browse/RCT-1518
import { clone, isEmpty, isEmptyObject } from '../../../helper/data-helper';
import LoadingBoxView from '../../loading-box';
import { QuickFormWithTabsView } from './quick-form-with-tab.view';
import { useGetFormDefaultValues } from '../hooks/use-get-form-default-values';
import {
  checkTabErrors,
  getProcessInfoFromUrl,
  setDefaultFormValues,
  updateLayoutUiSpecs,
} from './form-with-tabs.helper';
import { useTranslate } from 'react-admin';
import { ParentInfo } from './form-with-tab.type';
import {
  findIdFromUrlInEditRelationRecord,
  getParamFromUrl,
} from '../../../helper/UrlHelper';
import {
  prepareRelationList,
  prepareViewFields,
} from '../../show-record-with-relation/show-record-with-relation.helper';
import { RelationPanelControllerInterface } from '../../new-relation-panel';
import { isMetaEditable, isRecordEditable } from '../../../helper/MetaHelper';
import { extractAllFieldsFromTabList, updateInputsState } from '../form.helper';
import { showNotification } from '../../../helper/general-function-helper';

const FormWithTabController = (props: FormWithTabsProps): ReactElement => {
  const { isQuickForm, isCreateMode, location, match, basePath, dialogData } = props;

  const [isLoading, setIsLoading] = useState(true);

  const prevParentResource = useRef<string | null>(null);
  const prevParentId = useRef<number | null>(null);
  const firstUpdatedRef = useRef<boolean>(false);
  const tabsTitlesRef = useRef<Record<number, HTMLParagraphElement | null>>({});
  const onDispatchRef = useRef<{ actionName: keyof ActorActionList; id: symbol }[]>(
    [],
  );

  const { current: currentResource, stack: resourceStack } =
    actorGetActionValue('resources')!;
  const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;
  const urlInfo = actorGetActionValue('urlInfo')!;
  const translate = useTranslate();

  const metaData = (clone(actorGetActionValue('metaData', currentResource.value)) ??
    {}) as unknown as MetaData;

  const record = actorGetActionValue(
    'record',
    `${currentResource.value}.${currentResource.type}`,
  )! as {
    FORM?: Record<string, unknown>;
    PARENT_RECORD?: Record<string, unknown>;
    FULL?: Record<string, unknown>;
  };

  //when isloading false data is exist and isloading true should notification
  if (isEmptyObject(record?.FULL) && !isCreateMode && isLoading) {
    showNotification(translate('general.noData'), 'error');
  }

  const parentId = findIdFromUrlInEditRelationRecord(location.search) || null;
  const parentResource =
    getParamFromUrl(location.search, 'parentResource') ??
    resourceStack[resourceStack.length - 2]?.value ??
    null;

  const isReport = currentResource.value.indexOf('report') === 0;

  const recordIsEditable =
    !isReport && isRecordEditable(metaData, record) && isMetaEditable(metaData);

  const finalTabList = prepareViewFields(
    record?.FORM ?? getProcessInfoFromUrl(),
    metaData,
    currentResource.value,
    false,
  );

  const allFields: Array<FieldType> = extractAllFieldsFromTabList(finalTabList);
  actorSetActionValue('allFields', allFields, {
    path: `${currentResource.value}.${currentResource.type}`,
    callerScopeName: 'FormWithTabController',
  });

  const globalParameters = actorGetActionValue('globalParameters');

  const { formDefaultData, errorMessage } = useGetFormDefaultValues({
    allFields,
    globalParameters,
    isCreateMode,
    resource: currentResource,
    urlInfo,
    isLoading,
    parentInfo: (dialogData?.parentInfo as ParentInfo) ?? {},
  });

  useMemo(() => {
    if (errorMessage !== null) {
      setIsLoading(false);
      showNotification(errorMessage, 'error');
      return;
    }
    if (formDefaultData == null) return;
    setDefaultFormValues(
      record,
      formDefaultData,
      currentResource,
      isEmpty(match.params.id),
    );
    setIsLoading(false);
  }, [formDefaultData, errorMessage]);

  useEffect(() => {
    /**
     * To run service validation
     * for parentField when create form in relation opens
     * immediately onBlur(runServiceValidation) should happens!
     */
    if (!firstUpdatedRef.current) {
      runServiceValidationOnParentField();
      firstUpdatedRef.current = true;
    }

    actorSetActionValue('checkTabErrors', () => {
      checkTabErrors(currentResource, tabsTitlesRef);
    });

    //TODO: convert to hook if possible
    let id = actorOnDispatch(
      'initialData',
      () => {
        updateTabContents(true);
      },
      { preserve: false },
    );

    onDispatchRef.current.push({ actionName: 'initialData', id });

    id = actorOnDispatch(
      'formMessages',
      () => {
        if (typeof checkTabErrors === 'function') {
          checkTabErrors(currentResource, tabsTitlesRef);
        }
        updateTabContents();
      },
      { preserve: false },
    );

    onDispatchRef.current.push({ actionName: 'formMessages', id });

    id = actorOnDispatch(
      'resetForm',
      parameters => {
        setIsLoading(true);
        if (parameters?.saveType === 'saveAndNew') {
          runServiceValidationOnParentField();
          setIsLoading(false);
        }
      },
      {
        onDispatchDelay: true,
      },
    );

    onDispatchRef.current.push({ actionName: 'resetForm', id });

    return () => {
      actorSetActionValue('inputsRef', null, {
        path: `${currentResource.value}.${currentResource.type}`,
        replaceAll: true,
      });

      for (const onDispatchData of onDispatchRef.current) {
        actorRemoveAction({
          actionName: onDispatchData.actionName,
          listenerId: onDispatchData.id,
        });
      }
    };
  }, []);

  useEffect(() => {
    if (
      !isEmpty(parentId) &&
      !isEmpty(parentResource) &&
      !isEmptyObject(record?.[RecordKeyMode.FULL])
    ) {
      if (
        parentResource !== prevParentResource.current &&
        parentId !== prevParentId.current
      ) {
        formActionsHandler(FormActions.GetOrUpdateDataWithRedux, {
          resource: parentResource,
          id: parentId,
          basePath: `/${parentResource}`,
        });

        prevParentResource.current = parentResource;
        prevParentId.current = parentId;
      }
    }
  }, [record?.[RecordKeyMode.FORM]]);

  useEffect(() => {
    if (isLoading) return;

    updateTabContents();
    updateLayoutUiSpecs(currentResource);
  }, [isLoading]);

  const runServiceValidationOnParentField = useCallback(() => {
    if (!isQuickForm) return;

    if (dialogData == null || currentResource.type !== FormKeyMode.RELATION) {
      return;
    }
    // We need to run the following code after other `resetForm` happens
    const childFieldName = dialogData.childFieldName as string;
    const childFieldNameValue = dialogData[childFieldName];

    formActionsHandler(FormActions.InputBlur, {
      fieldName: childFieldName,
      value: childFieldNameValue,
    } as OnBlurParams);
  }, []);

  const tabChangeHandler = useCallback(() => {
    requestAnimationFrame(() => {
      updateTabContents(false);
      updateLayoutUiSpecs(currentResource);
    });
  }, [currentResource]);

  const updateTabContents = useCallback(
    (withInitialData = false) => {
      const inputsRef = actorGetActionValue('inputsRef', [
        currentResource.value,
        currentResource.type,
      ]) as Record<string, InputRefContent> | null;

      let data = {};
      if (withInitialData) {
        data =
          (actorGetActionValue('initialData', [
            currentResource.value,
            currentResource.type,
          ]) as InitialData | null) ?? {};
      } else {
        data =
          (actorGetActionValue('formData', [
            currentResource.value,
            currentResource.type,
          ]) as FormData | null) ?? {};
      }

      const formErrors =
        (actorGetActionValue('formMessages', [
          currentResource.value,
          currentResource.type,
        ]) as ValidationError | null) ?? {};

      updateInputsState(inputsRef, formErrors, data);
    },
    [currentResource.value],
  );

  if (isLoading) {
    return <LoadingBoxView />;
  }

  const recordId = match.params.id;

  const { __processuniqueid, positionid, stateid } =
    record?.[RecordKeyMode.FORM] ?? {};
  const processInfo = {
    processuniqueid: (__processuniqueid ?? null) as string | null,
    positionid: (positionid ?? null) as string | null,
    stateid: (stateid ?? null) as string | null,
  };

  const relations: Array<RelationPanelControllerInterface> = prepareRelationList(
    finalTabList,
    currentResource.value,
    recordIsEditable,
    recordId ?? '', // its ok because of create mode
    processInfo,
  );

  if (isQuickForm) {
    return (
      <QuickFormWithTabsView
        tabList={finalTabList} // final tab list
        basePath={basePath}
        metaData={metaData}
        record={record?.[RecordKeyMode.FORM]}
        tabChangeHandler={tabChangeHandler}
        tabsTitlesRef={tabsTitlesRef}
      />
    );
  }

  return (
    <FormWithTabsView
      tabList={finalTabList} // final tab list
      match={match}
      showRelations={!isCreateMode} // If `isCreateMode` is `true` then `showRelations` should be `false`
      metaData={metaData}
      basePath={basePath}
      record={record?.[RecordKeyMode.FORM]}
      relations={relations}
      tabChangeHandler={tabChangeHandler}
      tabsTitlesRef={tabsTitlesRef}
    />
  );
};

FormWithTabController.defaultProps = {
  metaData: {
    quickTabPages: [
      {
        quickGroups: [],
        name: '',
        tableName: '',
        moduleName: '',
        title: '',
        priority: 0,
        translatedTitle: {},
      },
    ],
  },
  isCreateMode: false,
  isQuickForm: false,
  location: { search: '' },
  match: {},
  basePath: '',
};

export default FormWithTabController;
