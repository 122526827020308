import React from 'react';

import MetaProvider from '../container/MetaProvider';
import FormLayoutContainer from '../container/FormLayoutContainer';
import CheckResourceReady from '../container/CheckResourceReady';

const FormLayoutPage = ({ match }) => {
  const { moduleName, moduleTableName } = match.params;
  const resource = `${moduleName}/${moduleTableName}`;

  return (
    <MetaProvider resourceName={resource}>
      <CheckResourceReady resourceName={resource}>
        <FormLayoutContainer resource={resource} />
      </CheckResourceReady>
    </MetaProvider>
  );
};

export default FormLayoutPage;
