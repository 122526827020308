import { useTheme } from '@material-ui/core';
import { FC, memo } from 'react';
import { EventUpdateHandler } from '.';
import { ThemeParams } from '../../core/themeProvider';
import { createTempId, isAllDayChecker } from './big-calendar.helper';
import { BigCalendarInterface, CalendarEventInterface } from './big-calendar.type';
import BigCalendarView from './big-calendar.view';

const BigCalendarController: FC<BigCalendarInterface> = memo(props => {
  const {
    draggedCalendarEvent,
    CustomToolbarView,
    currentDate,
    calendarEvents = [],
    isLoading,
    createNewCalendarEvent,
    onSelectCalendarEventCallback,
    updateCalendarEventCallback,
    onSelectSlotCallback,
    onRangeChangeCallback,
    setCalendarEvents,
    onViewCallback,
    calendarEventCustomProps,
    view,
  } = props;
  const theme = useTheme<ThemeParams>();

  /**
   * this function handle sources form out side calendar
   *@function onDropFromOutside
   * @param {CalendarEventInterface} calendarEvent
   * @returns {void}
   */
  const onDropFromOutside = (calendarEvent: CalendarEventInterface): void => {
    const { start, end, allDay } = calendarEvent;
    const newId = createTempId(calendarEvents);
    const customCalendarEvent: CalendarEventInterface = {
      id: newId,
      title: draggedCalendarEvent?.title,
      start,
      end,
      allDay,
    };
    setCalendarEvents(calendarEvents.concat([customCalendarEvent]));

    createNewCalendarEvent?.(customCalendarEvent, () => {
      setCalendarEvents(
        calendarEvents.filter(item => item.id !== customCalendarEvent.id),
      );
    });
  };

  /**
   * this function handle update event
   *@function handleUpdateEvent
   * @param {EventUpdateHandler} eventUpdateHandler
   * @returns {void}
   */
  const handleUpdateEvent = (eventUpdateHandler: EventUpdateHandler): void => {
    const { event, start, end, isAllDay: droppedOnAllDaySlot } = eventUpdateHandler;
    const idx = calendarEvents.indexOf(event);
    let allDay = event.allDay;

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }

    const updatedCalendarEvent: CalendarEventInterface = {
      ...event,
      start,
      end,
      allDay,
    };
    const nextCalendarEvents: CalendarEventInterface[] = [...calendarEvents];
    nextCalendarEvents.splice(idx, 1, updatedCalendarEvent);
    setCalendarEvents(nextCalendarEvents);
    updateCalendarEventCallback?.(updatedCalendarEvent, () => {
      const oldCalendarEvent: CalendarEventInterface[] = [...calendarEvents];
      oldCalendarEvent.splice(idx, 1, event);
      setCalendarEvents(oldCalendarEvent);
    });
  };

  /**
   * this function handle when select on events
   * @function onSelectEvent
   * @param {CalendarEventInterface} calendarEvent
   * @param {React.SyntheticEvent<HTMLElement>} eventHtmlElement
   * @returns {void}
   */
  const onSelectEvent = (
    calendarEvent: CalendarEventInterface,
    eventHtmlElement: React.SyntheticEvent<HTMLElement>,
  ): void => {
    onSelectCalendarEventCallback?.(calendarEvent, eventHtmlElement);
  };

  /**
   * this function handle change range in calendar
   * @function onRangeChange
   * @param {Date} date
   * @param {string} type
   * @param {string} position
   * @returns {void}
   */
  const onRangeChange = (date: Date, type: string, position: string): void => {
    onRangeChangeCallback?.(date, type, position);
  };

  /**
   * this function handle click on events
   *@function onSelectSlot
   * @param {Omit<CalendarEventInterface, 'allDay'>} calendarEvent
   * @returns {void}
   */
  const onSelectSlot = (
    calendarEvent: Omit<CalendarEventInterface, 'allDay'>,
  ): void => {
    // set default time
    if (isAllDayChecker(calendarEvent)) {
      onSelectSlotCallback?.({ ...calendarEvent, allDay: true });
    } else {
      onSelectSlotCallback?.(calendarEvent);
    }
  };

  /**
   * this onView handle click
   *@function onView
   * @param {string} type
   * @returns {void}
   */
  const onView = (type: string): void => {
    onViewCallback?.(type);
  };

  /**
   * this function get color event in calendar
   *@function eventStyleGetter
   * @param {CalendarEventInterface} calendarEvent
   * @returns {Record<string, Record<string, string | number | undefined>>}
   */
  const eventStyleGetter = (
    calendarEvent: CalendarEventInterface,
  ): Record<string, Record<string, string | number | undefined>> => {
    const backgroundColor = calendarEvent.hexColor;
    const style = {
      backgroundColor: backgroundColor,
      textAlign: 'right',
      float: 'right',
      borderRadius: 0,
      borderRight: `4px solid ${theme.palette.primary.appBorderInput}`,
      color: theme.palette.secondary.contrastText,
      padding: '4px',
    };
    return {
      style: style,
    };
  };

  return (
    <BigCalendarView
      moveEvent={handleUpdateEvent}
      onDropFromOutside={onDropFromOutside}
      resizeEvent={handleUpdateEvent}
      onSelectSlot={onSelectSlot}
      onRangeChange={onRangeChange}
      onSelectEvent={onSelectEvent}
      events={calendarEvents}
      isLoading={isLoading}
      eventStyleGetter={eventStyleGetter}
      CustomToolbarView={CustomToolbarView}
      currentDate={currentDate}
      eventCustomProps={calendarEventCustomProps}
      onView={onView}
      view={view}
    />
  );
});

export default BigCalendarController;
