import React, { useContext, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { GET_LIST, translate, refreshView, useLocale } from 'react-admin';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import { Button, Icon, IconButton } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import lodashGet from 'lodash/get';

import {
  getPrintResource,
  PrintPopupOpener,
  getPrintList,
} from '../helper/PrintMetaHelper';
import dataProvider from '../core/dataProvider';
import {
  getReportInfo,
  getDefaultReportSort,
  prepareReportFilter,
} from '../helper/MetaHelper';
import { NewMetaContext } from './NewMetaContext';
import { actorGetActionValue } from '../type/actor-setup';
import { showNotification } from '../helper/general-function-helper';
import { getCurrentRecord } from '../helper/ActorHelper';

const styles = theme => ({
  iconButton: {
    padding: 7,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },

  icon: {
    fontSize: 20,
  },
});

const PrintButtonContainer = props => {
  const {
    classes,
    selectedIds,
    isForceEnabled = false,
    button,
    translate,
    resource,
    appLoadedResources,
    filterValues,
    currentSort,
  } = props;
  const locale = useLocale();
  const [anchorEl, setAnchorEl] = useState(null);
  const { getMeta } = useContext(NewMetaContext);

  const metaData = actorGetActionValue('metaData', resource) ?? getMeta(resource);

  /**
   * it will compute a list of reports from metadata and set their disable property base of their report id
   * @constant printList
   * @returns {Array<Object>} print list
   */
  const printList = useMemo(() => {
    const list = getPrintList(metaData);
    if (!list) {
      return [];
    }
    for (const print of list) {
      print.disable = print.reportId ? false : true;
    }
    return list;
  }, [resource]);

  const isPrintEnabled = printList && printList.length;

  const disableAll =
    !selectedIds || !selectedIds.length || (selectedIds && selectedIds.length > 1)
      ? true
      : false;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * it will create a new promise and pass resolve and reject functions to get meta in meta context
   * then execute one of them when `getMeta` function completed (success or failure)
   * @function getPrintMeta
   * @param {Object} print
   * @returns {Promise<Object>}
   */
  const getPrintMeta = print => {
    return new Promise((res, rej) => {
      if (
        !printList ||
        !printList.filter(printInPrintList => printInPrintList.id === print.id)
          .length
      ) {
        rej(translate('print.printIdNotFoundInMeta'));
      }
      const printResource = getPrintResource(print.id);
      getMeta(printResource, res, rej);
    });
  };

  const computeReportParameter = reportId => {
    const reportMeta = getReportInfo(metaData, reportId);
    const idSelected = selectedIds[0];
    const record = lodashGet(appLoadedResources, [resource, 'data', idSelected]);

    const recordInActor = getCurrentRecord();

    return prepareReportFilter(reportMeta, record ?? recordInActor);
  };

  /**
   * iit will compute the parameters that the data provider needs to get print data, then trigger it to get
   * with received metadata and computed parameters. then open a print PrintPopupOpener if data got
   * successfully of call `handleClose` on failure
   * @function getPrintData
   * @param {Object} print
   * @returns {void}
   */
  const getPrintData = async print => {
    let reportData;

    if (isForceEnabled) {
      const filter = filterValues;
      const sort = getDefaultReportSort(metaData, print.reportId);
      const response = await dataProvider(GET_LIST, `report/${print.reportId}`, {
        filter: filter,
        sort: sort,
        pagination: { page: 1, perPage: 10000 }, // only change pagination
        queryParams: {
          putCaptionInHeader: true,
        },
        // server might give back an object and break everything!
        rawResponse: true,
      });

      reportData = response.data;
    } else if (print && print.id && print.reportId) {
      const filter = computeReportParameter(print.reportId);
      const sort = getDefaultReportSort(metaData, print.reportId);

      const response = await dataProvider(GET_LIST, `report/${print.reportId}`, {
        filter: filter,
        pagination: { page: 1, perPage: 10000 },
        sort,
        queryParams: {
          putCaptionInHeader: true,
        },
        // server might give back an object and break everything!
        rawResponse: true,
      });

      reportData = response.data;
    } else {
      console.log('no report in print info: ', print);
      handleClose();
      return;
    }

    const printResource = getPrintResource(print.id);
    const opener = new PrintPopupOpener();
    opener.setTranslate(translate);
    opener.openPrintInNewWindow(
      lodashGet(print, ['translatedTitle', locale], print.id),
      getMeta(printResource),
      reportData,
    );

    handleClose();
  };

  /**
   * it will call `getPrintMeta` function to get print metadata and wait for it succeeds or fails
   * then call `getPrintData` if meta received successfully or log an error message to the console
   * @function handlePrintReport
   * @param {Object} print
   * @returns {void}
   */
  const handlePrintReport = print => () => {
    getPrintMeta(print)
      .then(() => {
        getPrintData(print);
      })
      .catch(printMetaError => {
        showNotification(printMetaError, 'error', {
          forceSnackbar: true,
        });
      });
  };

  return (
    <>
      {button ? (
        <Button onClick={handleClick} color="primary" disabled={!isPrintEnabled}>
          <Icon>print</Icon>
          {translate('grid.print')}
        </Button>
      ) : (
        <IconButton
          className={classes.iconButton}
          onClick={handleClick}
          color="primary"
          disabled={!isPrintEnabled}
          id="printButton"
        >
          <Icon className={classNames(classes.icon)}>print</Icon>
        </IconButton>
      )}

      <Menu
        id="service-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {printList &&
          printList.map(print => (
            <MenuItem
              key={print.id}
              data-test-print-id={print.id}
              className={classes.menuItem}
              disabled={
                (!isForceEnabled && disableAll) ||
                print.disable ||
                (metaData &&
                  !metaData.reportId &&
                  !getReportInfo(metaData, print.reportId))
              }
              onClick={handlePrintReport(print)}
            >
              {lodashGet(
                print,
                ['translatedTitle', locale],
                lodashGet(print, 'title'),
              )}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

PrintButtonContainer.propTypes = {
  refreshView: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  appLoadedResources: PropTypes.object.isRequired,
  selectedIds: PropTypes.array,
  isForceEnabled: PropTypes.bool,
  button: PropTypes.bool,
};

const mapStateToProps = state => ({
  appLoadedResources: state.admin.resources,
});

const mapDispatchToProps = {
  refreshView,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(translate(PrintButtonContainer)));
