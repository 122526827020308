import React, { useState } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslate } from 'react-admin';
import { TextField, Icon, IconButton, Tooltip, Popover } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import FilterButtonMenuItem from './FilterButtonMenuItem';
import { replaceArabicCharacters } from '../helper/TextHelper';
import { COLOR_FIELD, getTypeByField, ICON_FIELD } from '../helper/InputHelper';

const useStyles = makeStyles(theme => ({
  root: { display: 'inline-block' },

  searchFilter: {
    display: 'flex',
    padding: '10px 16px',
    '&:focus': {
      outline: 'none',
    },
  },

  icon: {
    fontSize: 15,
  },

  IconButton: {
    padding: 7,
    margin: '2px 5px',
    // [theme.breakpoints.down('sm')]: {
    //   padding: 14,
    //   margin: 0,
    // },
  },
}));

const FilterButton = props => {
  const {
    filters,
    displayedFilters,
    filterValues,
    showFilter,
    className,
    resource,
    ...rest
  } = props;
  const classes = useStyles(props);
  const translate = useTranslate();

  const [open, setOpen] = useState(false);
  const [searchFilterWord, setSearchFilterWord] = useState('');

  const [button, setButton] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const getHiddenFilters = () =>
    filters.filter(filterElement => {
      const {
        props: { alwaysOn, source, field },
      } = filterElement;
      return (
        !alwaysOn &&
        !displayedFilters[source] &&
        !filterValues[source] &&
        getTypeByField(field) !== COLOR_FIELD &&
        getTypeByField(field) !== ICON_FIELD
      );
    });

  const handleClickButton = event => {
    // This prevents ghost click.
    event.preventDefault();

    setOpen(true);
    setAnchorEl(findDOMNode(button));
  };

  const handleRequestClose = () => setOpen(false);

  const handleShow = ({ source, defaultValue }) => {
    showFilter(source, defaultValue);
    setOpen(false);
    setSearchFilterWord('');
  };

  const getButtonRef = node => {
    setButton(node);
  };

  const handleInputTyping = event => setSearchFilterWord(event.target.value);

  const hiddenFilters = getHiddenFilters();

  return (
    hiddenFilters.length > 0 && (
      <div
        className={classNames(classes.root, className)}
        {...rest}
        id="addFilterToGridTestId"
      >
        <Tooltip title={translate('ra.action.add_filter')}>
          <IconButton
            className={classes.IconButton}
            ref={getButtonRef}
            onClick={handleClickButton}
            color="primary"
          >
            <Icon className={classNames(classes.icon, 'fa fa-filter')} />
          </IconButton>
        </Tooltip>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleRequestClose}
          style={{ marginTop: '75px' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <TextField
            className={classes.searchFilter}
            placeholder={translate('ra.action.search')}
            value={searchFilterWord}
            onChange={handleInputTyping}
            id="search-filter-input"
          />
          {hiddenFilters
            .filter(filterElement => {
              const isMatch1 =
                replaceArabicCharacters(filterElement.props.label)
                  ?.toString()
                  .indexOf(searchFilterWord) !== -1;
              const isMatch2 =
                replaceArabicCharacters(filterElement.props.source)
                  ?.toString()
                  .indexOf(searchFilterWord) !== -1;
              return isMatch1 || isMatch2;
            })
            .map(filterElement => (
              <FilterButtonMenuItem
                key={filterElement.props.source}
                filter={filterElement.props}
                resource={resource}
                onShow={handleShow}
              />
            ))}
        </Popover>
      </div>
    )
  );
};

FilterButton.propTypes = {
  resource: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.node).isRequired,
  displayedFilters: PropTypes.object.isRequired,
  filterValues: PropTypes.object.isRequired,
  showFilter: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default FilterButton;
