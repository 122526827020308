export default {
  meta: {
    resourceIsNotDefined: 'لم يتم تعريف بيانات الموارد بشكل صحيح!',
    getMetaFailure: 'خطأ في تلقي التعريف',
  },
  general: {
    download: 'تحميل',
    requestId: 'معرف الطلب',
    copied: 'نسخ',
    noRecordFound: 'لا يوجد سجلات!',
    back: 'الى الخلف',
    more: 'أكثر',
    apply: 'تطبيق',
    decline: 'يتناقص',
    search: 'بحث ...',
    delete: 'حذف',
    start: 'يبدأ',
    pause: 'وقفة',
    reset: 'إعادة ضبط',
    noData: 'لا توجد بيانات لعرضها',
    pin: 'ثبّت رسالة',
    theMenusAreNotAvailable: 'قوائم غير متوفرة.',
  },
  form: {
    thereIsNoReturnInTheFormula: 'لا توجد صيغة "عودة"',
    thereIsNoRecordOrFormula: 'لا يوجد سجل أو صيغة',
    recordNotExist: 'لا يوجد سجل',
    formulaIsNotValid: 'الصيغة الحسابية غير صالحة',
    errorInComputing: 'خطأ في الحوسبة',
    showLess: 'عرض أقل',
    showMore: 'أظهر المزيد',
    showFull: 'عرض كامل',
    chooseFile: 'اختيار ملف',
    sendingData: 'إرسال البيانات',
    createAndNew: 'إنشاء والجديد',
    createAndView: 'إنشاء وعرض',
    saveAsDefault: 'إحفظ كافتراضي',
    restoreDefault: 'استعادة الافتراضي',
    newTab: 'علامة تبويب جديدة',
    editTabInfo: 'تحرير معلومات علامة التبويب',
    editGroupInfo: 'تحرير معلومات المجموعة',
    editFieldInfo: 'تحرير معلومات المجال',
    translatedTitle: {
      fa: 'الترجمة الفارسية',
      en: 'الترجمة إلى الإنجليزية',
      ar: 'الترجمة العربية',
    },
    translatedCaption: {
      fa: 'الترجمة الفارسية',
      en: 'الترجمة إلى الإنجليزية',
      ar: 'الترجمة العربية',
    },
    columnCount: 'عدد الأعمدة',
    submitWasAlmostSuccessfulPleaseCheckRelationsAndFixErrors:
      'تم إرسال البيانات بنجاح ولكن بعض الإصدارات تحتاج إلى تصحيح. يرجى إصلاح الأخطاء وإعادة الحفظ.',
    areYouSureAboutThisOperation: 'هل أنت متأكد من هذه العملية؟',
    download: 'تحميل.',
    processSelection: 'اختيار العملية',
  },
  grid: {
    groupByColumn: 'للتجميع حسب حقل ، اسحب العنوان هنا',
    filterPlaceholder: '',
    services: 'خدمات',
    shiftProcess: 'نقل المعالجة',
    serviceIsRunning: 'الخدمة قيد التشغيل ، يرجى الانتظار ...',
    notes: 'ملاحظات',
    columnFilters: 'مرشحات العمود',
    grouping: 'تجميع الأعمدة',
    quickCreate: 'إنشاء سريع',
    filter: 'منقي',
    sum: 'مجموع',
    resourceIsDisabled: 'المصدر معطل',
    print: 'طباعة',
    quickEdit: 'تعديل سريع',
    refresh: 'تحديث',
    inlineEdit: 'إضغط للتعديل',
    noColumns: 'لا توجد أعمدة',
    groupingEmptyText: 'اسحب العنوان هنا للتجميع',
  },
  noteStream: {
    fetch: 'جلب',
    newNote: 'ملاحظة جديدة',
    invalidNote: 'ملاحظة تحتوي على أكثر من 50 حرفًا ولا توجد مسافات غير صالحة.',
  },
  process: {
    name: 'معالجة',
  },
  menu: {
    home: 'الصفحة الرئيسية',
    uploadProfilePicture: 'تحميل صورة الملف الشخصي',
    menuNotFound: 'القائمة غير موجودة',
    expand: 'وسعت',
    shrink: 'إنكمش',
    close: 'قريب',
    favorites: 'المفضلة',
    recent: 'الأخيرة',
    module: 'وحدة',
    changePassword: 'تغيير كلمة المرور',
  },
  dropdown: {
    placeholder: 'حدد كمية القطرات',
    noOptionsMessage: 'تعذر العثور على بعض العناصر',
    error: 'خطأ في الحصول على معلومات المنسدلة',
    loading: 'جار التحميل',
    searchPlaceholder: 'بحث',
    noneLabel: 'لا أحد',
  },
  filter: {
    list: 'المرشحات المحفوظة',
    showEveryFilterInList: 'عرض جميع المرشحات المتاحة',
    clearOnlyFilterValuesFromList: 'مسح جميع القيم من المرشحات',
    clearList: 'مرشحات الشبكة واضحة',
    saveFilter: 'حفظ المرشحات الحالية',
    pleaseGiveANameToYourNewFilter: 'يرجى إعطاء هذا الفلتر اسمًا',
    name: 'اسم',
    nameIsNotValid: 'الرجاء إدخال اسم أفضل',
    date: {
      mode: 'بحث',
      on: 'في التاريخ',
      from: 'من التاريخ',
      until: 'حتى الآن',
      between: 'في الفاصل الزمني',
      opposite: 'عکس',
    },
    apply: 'أجراءات',
  },
  listColumnChoice: {
    columnConfig: 'إعدادات العمود',
    selectColumns: 'اختيار الأعمدة',
    selectColumnsThatYouWantToShow: 'يرجى تحديد الأعمدة التي تريد عرضها',
    changeAll: 'تغير الكل',
    currentChoicesAreByDefault: 'الخيارات الحالية هي افتراضيا.',
    currentChoicesAreByUser: 'الخيارات الحالية تعتمد على اختيارات المستخدم.',
    noColumnsToSelect: 'لا توجد أعمدة للتحديد',
  },
  quickCreate: {
    fullForm: 'شكل كامل',
  },
  confirm: {
    areYouSureYouWantToDeleteThisNote: 'هل أنت متأكد أنك تريد حذف الملاحظة؟',
    areYouSureYouWantToDeleteThisItem: 'هل أنت متأكد أنك تريد حذف هذا العنصر؟',
    areYouSureYouWantToDeleteWholeTable:
      'هل أنت متأكد من أنك تريد حذف الجدول بأكمله؟',
    areYouSureYouWantToDeleteThisCountOfItem:
      'هل تريد بالتأكيد حذف %{smart_count} من العنصر؟',
    areYouSureYouWantToUnPinWholeTable: 'هل أنت متأكد أنك تريد إزالة الدبوس؟',
    areYouSureYouWantToPinWholeTable: 'هل أنت متأكد من التثبيت؟',
    yes: 'نعم',
    no: 'خیر',
    ok: 'حسنا',
    cancel: 'إلغاء',
  },
  puzzle: {
    reset: 'إعادة',
  },
  profile: {
    profileSettings: 'إعدادات الملف الشخصي',
    editProfile: 'تعديل الملف الشخصي',
    firstName: 'الاسم الاول',
    lastName: 'الكنية',
    address: 'عنوان',
    email: 'البريد الإلكتروني',
    nationalCode: 'رمز دولي',
    phoneNumber: 'رقم الهاتف',
    mobileNumber: 'رقم الهاتف المحمول',
    accountingSoftware: 'لديه برنامج محاسبة',
  },
  formLayout: {
    addNewSection: 'إضافة قسم جديد',
  },
  print: {
    pleaseGiveBrowserAccessToOpenPopup:
      'يرجى منح المتصفح حق الوصول لفتح نافذة منبثقة!',
    ErrorInDetectingOpenedWindow: 'خطأ في الكشف عن نافذة مفتوحة!',
    printIdNotFoundInMeta: 'لا يوجد معرف طباعة في التعريف',
  },
  tree: {
    tree: 'شجرة',
    noDataToDisplay: 'لا توجد بيانات لعرضها',
    itIsNotPossibleToSelectFromThisLevel: 'لا يمكن الاختيار من هذا المستوى.',
  },
  pos: {
    discount: 'خصم',
    subtotal: 'حاصل الجمع',
    tax: 'ضريبة',
    total: 'مجموع',
    addCustomer: 'إضافة العملاء',
    note: 'ملحوظة...',
    discard: 'تجاهل',
    park: 'منتزه',
    pay: 'دفع',
    searchForProductNameOrBarcode: 'ابحث عن اسم المنتج أو الرمز الشريطي',
    giftCard: 'كرت هدية',
    customSale: 'بيع مخصص',
  },
  todo: {
    thereIsNothingTapNewTaskToGetStarted:
      'ليس هناك شئ. اضغط على "مهمة جديدة" للبدء.',
    newTask: 'مهمة جديدة',
    newStep: 'خطوة جديدة',
    add: 'إضافة',
    tasks: 'مهام',
    newList: 'قائمة جديدة',
    newGroup: 'مجموعة جديدة',
    deleteGroup: 'حذف المجموعة',
    ungroup: 'فك التجميع',
    renameGroup: 'إعادة تسمية المجموعة',
    IsAddedToMyDay: 'يومي',
    IsImportant: 'معلومات هامة',
    DueDate: 'المقرر',
    assignedtome: 'الودائع المتلقى',
    sysitem: 'مهام النظام',
    deleteTodoList: 'حذف القائمة',
    renameTodoList: 'إعادة تسمية القائمة',
    renamedSuccessfully: 'تمت إعادة تسمية القائمة بنجاح!',
    areYouSureYouWantToDeleteThisTask: 'هل أنت متأكد أنك تريد حذف هذه المهمة؟',
    areYouSureYouWantToDeleteThisStep: 'هل أنت متأكد أنك تريد حذف هذه الخطوة؟',
    areYouSureYouWantToDeleteThisList: 'هل أنت متأكد أنك تريد حذف هذه القائمة؟',
    note: 'ملحوظة',
    isDone: 'منجز',
    hideCompleted: 'إخفاء المهام المكتملة',
    showCompleted: 'إظهار المهام المكتملة',
    title: 'أبجدي',
    createDate: 'تاريخ الإنشاء',
    options: 'خيارات',
    sort: 'رتب',
    sortBy: 'ترتيب حسب',
    asc: 'تصاعدي',
    desc: 'تنازلي',
    today: 'اليوم',
    tomorrow: 'غدا',
    overmorrow: 'تغلب',
    nextWeek: 'الاسبوع القادم',
    customDateTime: 'اختر تاريخًا / وقتًا',
    customDate: 'اختر تاريخًا',
    remindMe: 'ذكرني',
    dueDate: 'تاريخ الانتهاء',
    addToMyDay: 'أضف إلى يومي',
    daily: 'اليومي',
    weekly: 'أسبوعي',
    monthly: 'شهريا',
    yearly: 'سنوي',
    repeat: 'كرر',
    noOptionsMessage: 'التكرار غير محدد',
    areYouSureYouWantToDeleteThisFile: 'هل أنت متأكد أنك تريد حذف هذا الملف؟',
    myDay: 'يومي',
    duplicateList: 'نسخ القائمة',
    shareList: 'مشاركة القائمة',
    assignTo: 'تعيين',
    dragListToEmptyGroup: 'اسحب هنا لإضافة قوائم',
    listIsAlreadyShared: 'تمت مشاركة هذه القائمة بالفعل مع هذا الشخص.',
    selectAPerson: 'اختر شخصا.',
    notYourself: 'لا يمكنك مشاركة قائمتك مع نفسك.',
    assingedToMe: 'تعيين لي',
    assingToMe: 'خصص لي',
    quickFilters: 'المرشحات',
    invalidDate: 'تاريخ غير صالح',
    link: 'حلقة الوصل',
  },
  sellin: {
    id: 'هوية شخصية',
    createDate: 'قم بإنشاء تاريخ',
    itemCount: 'رقم',
    totalItemCount: 'العدد الإجمالي',
    totalDiscountPrice: 'تخفیف کل',
    totalWholePrice: 'السعر الإجمالي',
    finalPrice: 'السعر النهائي',
    settlementTypeTitle: 'التسوية',
    orderStatusTitle: 'حالة الطلب',
    successfulOnlinePayment: 'نجاح الدفع عبر الإنترنت',
    orderInformation: 'معلومات الطلب',
    orderStatusDescription: 'حالة الطلب',
    detail: 'التفاصيل',
    showProduct: 'عرض المنتج',
    price: 'السعر',
    tags: 'العلامات',
    description: 'الوصف',
    addToCart: 'أضف إلى عربة التسوق',
    priceDetails: 'تفاصيل السعر',
    settlementType: 'نوع التسوية',
    submitOrder: 'تسجيل الطلب',
    areYouConfidentInFinalizingYourOrder: 'هل أنت واثق من إنهاء طلبك؟',
    yourOrderHasBeenRegistered: 'تم تسجيل طلبك.',
    addedToBasketSuccessfully: 'تمت إضافة العنصر إلى السلة بنجاح',
    pleaseEnterTheNameOfTheDesiredProductInTheBoxAbove:
      'الرجاء إدخال اسم المنتج المطلوب في المربع أعلاه.',
    noProductFound: 'لم يتم العثور على منتج!',
    inventory: 'الجرد',
    ordering: 'فرز',
    barcodes: 'الباركود',
  },
  service: {
    success: 'اكتملت الخدمة بنجاح.',
  },
  customValidation: {
    validationErrorOnTab: 'خطأ في التحقق من الصحة في %{tabName}.',
    separator: 'و',
    valueOfField: 'قيمة الحقل ',
    valueOfFields: 'كمية الحقول ',
    notValid: ' يجب إصلاحه',
    areNotValid: ' هم بحاجة إلى تصحيح',
    invalidValue: 'القيمة التي تم إدخالها غير صالحة',
    unknownTab: 'علامة تبويب غير معروفة',
  },
  toolbar: {
    importComponent: {
      import: 'استيراد',
      importExcelFile: 'استيراد ملف Excel',
      downloadSampleFile: 'تنزيل ملف نموذج',
      uploadExcelFile: 'تحميل',
      chooseAfile: 'حدد ملف',
    },
  },
  quickAccess: {
    quickAccess: 'وصول سريع',
    actionDescription: {
      quickAccess: 'اكتب الاختصار الذي تريده في المربع واضغط على Enter.',
    },
  },
  imageDialog: {
    dataNotFound: 'لا توجد بيانات لعرضها!',
  },
  editor: {
    placeholder: 'اكتب المحتوى هنا!',
    staticVariables: 'المتغيرات الثابتة',
  },
  fileManager: {
    properties: 'الخصائص',
    propertiesError: 'لا يمكن إظهار الخصائص لعنصر غير محدد',
  },
  notification: {
    changePassword: {
      success: 'تغيير كلمة مرور لموفق أنجم شاد',
    },
    messages: 'رسائل',
  },
  blog: {
    recentNotes: 'اعلاناتة',
    continueReading: 'أكمل القراءة...',
  },
  file: {
    fileUploadLimitMB: 'حجم الملف أكبر من %{fileUploadLimitMB} ميغا بايت',
    file: 'ملف',
    files: 'الملفات',
    and: 'و',
    couldNotSendFileBecauseItsLargeSize: 'تعذر الإرسال بسبب حجم أكبر من المسموح به',
    couldNotSendFilesBecauseItsLargeSize:
      'لم يتم إرسالها بسبب الحجم الأكبر من المبلغ المسموح به',
  },
  calendar: {
    items: 'العناصر',
    today: 'اليوم',
    back: 'الى الخلف',
    next: 'التالي',
    month: 'شهر',
    week: 'أسبوع',
    day: 'يوم',
    require: 'يتطلب',
    calendar: 'تقويم',
  },
  chat: {
    startChat: 'حدد المستخدم لبدء الدردشة',
    sayHi: 'قل مرحبا',
    messages: 'رسائل',
    contacts: 'جهات الاتصال',
    search: 'ابحث هنا ...',
    reply: 'رد',
    deleteMessage: 'حذف رسالة',
    deleteConfirmMessage: 'هل أنت متأكد أنك تريد حذف هذه الرسالة ؟',
    edit: 'يحرر',
    messagePlaceHolder: 'اكتب رسالة ...',
    noUserFound: 'لم يتم العثور على المستخدم!',
    noMessagesFound: 'لا توجد رسائل!',
    selectedFiles: '%{count} ملفات مختارة',
  },
  mail: {
    automation: 'أتمتة',
    downloadAll: 'تحميل الكل',
    newMessage: 'رسالة جديدة',
    newMail: 'بريد جديد',
    inMail: 'في البريد',
    folders: 'رسائل',
    labels: 'ملصقات',
    send: 'يرسل',
    saveAsDraft: 'حفظ كمسودة',
    reply: 'رد',
    replyAll: 'رد على الكل',
    replySubject: 'رد على ',
    attachedFiles: 'الملفات المرفقة',
    successReply: 'تم الرد على الرسالة بنجاح',
    successArchive: 'عملیات ارجاع و آرشیو با موفقیت انجام شد',
    successRecall: 'تم استدعاء الخدمة بنجاح',
    recallConfirmMessage: 'هل أنت متأكد من تشغيل خدمة الاستدعاء',
    confirmDeleteFolderMessage: 'هل أنت متأكد أنك تريد حذف المجلد: %{folderName} ?',
    mailNotFound: 'البريد غير موجود!',
    forward: 'إلى الأمام',
    sender: 'مرسل',
    toStar: 'مميز بنجمة',
    toArchive: 'أرشيف',
    toUnArchive: 'إلغاء الآرشيف',
    toReference: 'المرجعي',
    markAsRead: 'ضع إشارة مقروء',
    markAsUnRead: 'وضع علامة كغير مقروءة',
    markAsImportant: 'وضع علامة كمهمة',
    markAsStarred: 'وضع علامة بنجمة',
    removeStarred: 'إزالة المميزة بنجمة',
    removeImportant: 'إزالة هام',
    applyLabel: 'تطبيق التسمية',
    newLabel: 'تسمية جديدة',
    labelTitle: 'عنوان التسمية',
    colorCode: 'رمز اللون',
    parentLabel: 'تسمية الأصل',
    template: 'قوالب الرسائل',
    noTemplateData: 'لا توجد بيانات قالب',
    followUp: 'متابعة',
    messageText: 'رسالة نصية',
    references: 'مراجع',
    cycle: 'دورة',
    recall: 'اعد الاتصال',
    personalFolders: 'المجلدات الشخصية',
    createFolder: 'إنشاء مجلد جديد',
    moveTo: 'الانتقال إلى',
    cc: 'CC',
    bcc: 'BCC',
    from: 'من',
    to: 'ل',
    mailSubject: 'موضوع',
    notSeen: 'لم يرى',
    delegation: 'تفويض السلطة',
    seeAll: 'رأي',
    docType: {
      allMail: 'كل البريد',
      inbox: 'صندوق الوارد',
      outbox: 'صندوق الصادر',
      unread: 'غير مقروء',
      archived: 'مؤرشف',
      starred: 'مميز بنجمة',
      important: 'الأهمية',
      innerMail: 'البريد الداخلي',
      systemTasks: 'مهام النظام',
      message: 'رسالة',
      draft: 'مشروع',
    },
    referenceListHeader: {
      date: 'تاريخ',
      sender: 'مرسل',
      receiver: 'المتلقي',
      referenceParaph: 'مرجع paraph',
      seenDate: 'تاريخ المشاهدة',
      kind: 'نوع',
    },
  },
  pivot: {
    fieldChooserTitle: 'منتقي المجال',
    allFields: 'جميع المجالات',
    filterFields: 'تصفية الحقول',
    dataFields: 'حقول البيانات',
    columnFields: 'حقول العمود',
    rowFields: 'حقول الصف',
    excelExportFileName: 'حقول البيانات',

    sortColumnBySummary: 'فرز العمود حسب الملخص',
    sortRowBySummary: 'فرز الصف حسب الملخص',
    showFieldChooser: 'إظهار منتقي الحقول',
    exportToExcel: 'تصدير إلى Excel',
    expandAll: 'توسيع الكل',
    dataNotAvailable: 'البيانات غير متوفرة',
    collapseAll: 'انهيار جميع',
    columnFieldArea: 'ضع أعمدة الجدول هنا',
    dataFieldArea: 'ضع معلومات الجدول هنا',
    filterFieldArea: ' ضع حقول البحث هنا',
    rowFieldArea: ' ضع صفوف الجدول هنا ',
    settingSavedSuccessfully: 'تم حفظ الإعداد بنجاح',
    grandTotal: 'مجموع',
  },
  dynamicInput: {
    emptyValue: 'إلغاء',
  },
  datePicker: {
    placeholder: 'YYYY/MM/DD',
    dateTimePlaceholder: 'YYYY/MM/DD hh:mm',
  },
  css: {
    drawer: 'أسلوب ديناميكي',
    save: 'متجر',
    success: 'فعلت بنجاح',
  },
};
