import React, { useCallback, useMemo } from 'react';
import lodashGet from 'lodash/get';
import { Dialog } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useLocale } from 'react-admin';

import { getShowSummaryColumnList, getTranslatedName } from '../helper/MetaHelper';
import QuickCreateButtonToolbar from '../component/QuickCreateButtonToolbar';
import RelationFormIterator from '../component/form-component-old/RelationFormIterator';
import ArrayInput from '../component/inputs/ArrayInput';
import NewSubmittableForm from '../component/NewSubmittableForm';

const useStyles = makeStyles(theme => ({
  arrayInput: {
    width: '100%',
  },
}));

const arrayDefaultValue = [];

const FormRelationBulkInsertPopupDialogContainer = props => {
  const {
    addToRelationArray,
    metaData,
    isOpen,
    resource,
    source,
    defaultData,
    relationInfo,
    closeDialog,
  } = props;
  const locale = useLocale();
  const classes = useStyles();

  const handleSave = useCallback(formData => {
    const relationFormData = lodashGet(formData, source);

    for (const row of relationFormData) {
      addToRelationArray(source, row);
    }

    closeDialog();
  }, []);

  const columnList = useMemo(
    () => (metaData ? getShowSummaryColumnList(metaData) : null),
    [metaData],
  );
  const translatedTitle = useMemo(
    () => (metaData ? getTranslatedName(metaData, locale) : null),
    [metaData, locale],
  );

  if (!resource || !metaData) {
    return <div />;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      fullWidth
      maxWidth={'xl'}
      classes={{
        paper: classes.dialog,
      }}
      aria-labelledby="show-image-dialog-title"
    >
      {isOpen && (
        <NewSubmittableForm
          allowSaveOnUnchangedForm
          redirect={false}
          resource={resource}
          save={handleSave}
          saving={false}
          record={defaultData}
          toolbar={
            <QuickCreateButtonToolbar
              disableFullFormButton
              closeDialog={closeDialog}
            />
          }
        >
          <ArrayInput
            className={classes.arrayInput}
            key={source}
            source={source}
            label=""
            defaultValue={arrayDefaultValue}
            relationMetaData={metaData}
          >
            <RelationFormIterator
              disableDropdownQuickCreate
              disableDropdownSearchPopup
              relation={relationInfo}
              relationResource={resource}
              relationPath={`${resource}/${relationInfo.childFieldName}`}
              title={translatedTitle}
              columnList={columnList}
            />
          </ArrayInput>
        </NewSubmittableForm>
      )}
    </Dialog>
  );
};

export default FormRelationBulkInsertPopupDialogContainer;
