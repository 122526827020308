/**
 * this helper function handle - char in input
 * @function checkNumberForMinus
 * @param input string | number
 * @returns string
 */
export const checkNumberForMinus = (input: string | number): string => {
  if (!input) {
    return input.toString();
  }
  if (/([a-z]|[A-z])+/g.test(input.toString())) {
    return '';
  }
  const amount = input.toString();
  const isNegative = amount.indexOf('-') === 0;
  if (amount.length === 1) {
    return amount;
  }
  let result = amount;
  if (isNegative && amount[1] !== '-') {
    result = '-' + amount.substring(1).split('-').join('000');
  } else if (!isNegative && amount.length >= 1) {
    result = amount.split('-').join('000');
  }
  return result;
};
