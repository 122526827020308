import produce from 'immer';
import { USER_LOGOUT } from 'react-admin';
import { SHOW_IMAGE_DIALOG, CLOSE_IMAGE_DIALOG } from './constant';

const initialState = {
  isOpen: false,
  imageUrl: null,
  imageTitle: null,
  imageInfo: null,
  isGetFileMode: false,
};

const myReducer = (state = initialState, { type, data }) =>
  produce(state, draft => {
    switch (type) {
      case USER_LOGOUT:
        draft.isOpen = false;
        draft.imageUrl = null;
        draft.fullUrl = null;
        draft.imageTitle = null;
        draft.imageInfo = null;
        draft.isGetFileMode = false;
        draft.fileName = null;
        break;

      case SHOW_IMAGE_DIALOG:
        draft.isOpen = true;
        draft.imageUrl = data.url;
        draft.fullUrl = data.fullUrl;
        draft.imageTitle = data.title;
        draft.imageInfo = data.info;
        draft.isGetFileMode = data.isGetFileMode;
        draft.fileName = data.fileName;
        break;

      case CLOSE_IMAGE_DIALOG:
        draft.isOpen = false;
        break;
    }
  });

export default myReducer;
