import { isEmptyObject } from './data-helper';
import {
  BOOLEAN_FIELD,
  DATE_FIELD,
  DECIMAL_FIELD,
  DROPDOWN_FIELD,
  getTypeByField,
  NUMBER_FIELD,
} from './InputHelper';
import { FieldType, ModeItemInterface } from './Types';

export const HIDE_FILTER = 'HIDE_FILTER';
export const CLEAR_FILTER_VALUE = 'CLEAR_FILTER_VALUE';

export const onlyEqualModeList = [
  {
    name: 'equals',
    operator: 'equal',
    both: false,
  },
];

const dateModeList = [
  {
    name: 'onDate',
    operator: '=',
    both: false,
  },
  {
    name: 'fromDate',
    operator: '>=',
    both: false,
  },
  {
    name: 'untilDate',
    operator: '<=',
    both: false,
  },
  {
    name: 'between',
    operator: 'between',
    both: true,
  },
  {
    name: 'opposite',
    operator: 'notequal',
    both: false,
  },
];

const textModeList = [
  {
    name: 'contains',
    operator: 'contains',
    both: false,
  },
  {
    name: 'equals',
    operator: 'equal',
    both: false,
  },
  {
    name: 'opposite',
    operator: 'notequal',
    both: false,
  },
];

const strictModeList = [
  {
    name: 'equals',
    operator: 'equal',
    both: false,
  },
  {
    name: 'opposite',
    operator: 'notequal',
    both: false,
  },
];

const numberModeList = [
  {
    name: 'contains',
    operator: 'contains',
    both: false,
  },
  {
    name: 'equals',
    operator: 'equal',
    both: false,
  },
  {
    name: 'greaterThanEquals',
    operator: '>=',
    both: false,
  },
  {
    name: 'lessThanEquals',
    operator: '<=',
    both: false,
  },
  {
    name: 'between',
    operator: 'between',
    both: true,
  },
  {
    name: 'opposite',
    operator: 'notequal',
    both: false,
  },
];

/**
 * get `modeList` object base on the field type.
 * @function getModeList
 * @param {object} field
 * @returns {ModeItemInterface[]}
 */
export const getModeList = (field: Partial<FieldType>): ModeItemInterface[] => {
  if (isEmptyObject(field)) {
    return textModeList;
  }

  switch (getTypeByField(field)) {
    case DATE_FIELD:
      return dateModeList;

    case BOOLEAN_FIELD:
    case DROPDOWN_FIELD:
      return strictModeList;

    case NUMBER_FIELD:
    case DECIMAL_FIELD:
      return numberModeList;

    default:
      return textModeList;
  }
};

/**
 * add fake (maximum or minimum) time to an string date
 * @function addFakeTimeToDate
 * @param {string} date expect format: YYYY-MM-DD
 * @param {string} time just `min` or `max`
 * @returns {string} string date as format: YYYY-MM-DD HH:mm:ss
 */
const addFakeTimeToDate = (date: string, time: 'min' | 'max'): string => {
  if (date && date.length > 10) return date;
  if (!date) return '';

  return `${date} ${time === 'min' ? '00:00:00' : '23:59:59'}`;
};

/**
 * handle simple date filter without time and add fake time to it base of its operation
 * @function prepareDateTimeFilterValue
 * @param {Array<string>} simpleValue date filter without time
 * @returns {{Array<string>} date filter with time
 */
export const prepareDateTimeFilterValue = (simpleValue: string[]): string[] => {
  const [filterName, operator, filterValue, secondFilterValue] = simpleValue;
  const preparedValue = [filterName];

  switch (operator) {
    case '=': {
      preparedValue.push('between');
      preparedValue.push(addFakeTimeToDate(filterValue, 'min'));
      preparedValue.push(addFakeTimeToDate(filterValue, 'max'));
      break;
    }

    case 'notequal': {
      preparedValue.push('notbetween');
      preparedValue.push(addFakeTimeToDate(filterValue, 'min'));
      preparedValue.push(addFakeTimeToDate(filterValue, 'max'));
      break;
    }

    case 'between': {
      preparedValue.push(operator);
      preparedValue.push(addFakeTimeToDate(filterValue, 'min'));
      preparedValue.push(addFakeTimeToDate(secondFilterValue, 'max'));
      break;
    }

    case '>=': {
      preparedValue.push(operator);
      preparedValue.push(addFakeTimeToDate(filterValue, 'min'));
      break;
    }

    case '<=': {
      preparedValue.push(operator);
      preparedValue.push(addFakeTimeToDate(filterValue, 'max'));
      break;
    }

    default: {
      return simpleValue;
    }
  }

  return preparedValue;
};
