import React, { FC, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslate, CREATE, refreshView } from 'react-admin';
import lodashGet from 'lodash/get';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';

import dataProvider from '../core/dataProvider';
import { CustomTheme } from '../core/themeProvider';
import { downloadFile, getBlobFile } from '../helper/FileHelper';
import { isEmpty } from '../helper/data-helper';
import { refreshRelationAction as refreshRelation } from '../redux/relation/action';
import { showNotification } from '../helper/general-function-helper';
import { actorDispatch } from '../type/actor-setup';

interface ImportButtonInterfaceProps {
  metaData: object;
  resource: string;
  refreshView: Function;
  refreshRelation: () => void;
  fileButtonTitle?: string; // this type for using in style component
  parentResource?: string;
  parentId?: number;
}

const styles = makeStyles((theme: CustomTheme) => ({
  iconButton: {
    padding: 5,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px',
      margin: 0,
    },
  },

  customFileInput: {
    backgroundColor: theme.palette.primary.appPrimaryBackgroundColor,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    padding: '15px 10px',
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    outline: 'none',
    '&::-webkit-file-upload-button': {
      visibility: 'hidden',
    },

    '&::before': {
      content: (props: ImportButtonInterfaceProps) => `"${props.fileButtonTitle}"`,
      display: 'inline-block',
    },
  },

  dialogPadding: {
    padding: 15,
  },

  importButtonContainer: {
    display: 'flex',
  },
}));

const ImportButton: FC<ImportButtonInterfaceProps> = props => {
  const {
    metaData,
    resource,
    refreshView,
    parentResource,
    parentId,
    refreshRelation,
  } = props;
  const translate = useTranslate();
  const fileButtonTitle = translate('toolbar.importComponent.chooseAfile');
  const classes = styles({ ...props, fileButtonTitle });

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<object | null>();

  /**
   * Open dialog with change `isOpen` state.
   * @function handleClick
   * @returns {void}
   */
  const handleClick = () => {
    setIsOpen(true);
  };

  /**
   * Close dialog and remove uploaded file.
   * @function handleClose
   * @returns {void}
   */
  const handleClose = () => {
    setUploadedFile(null);
    setIsOpen(false);
  };

  /**
   * This is upload file and then refresh view.
   * @function handleUploadExcel
   * @returns {void}
   */
  const handleUploadExcel = () => {
    let preparedResource = `${resource}/import/excel`;

    if (!isEmpty(parentResource) && !isEmpty(parentId)) {
      preparedResource = `${parentResource}/${parentId}/${resource}/import/excel`;
    }
    setLoading(true);
    dataProvider(CREATE, preparedResource, {
      data: { file: uploadedFile },
    })
      .then(() => {
        refreshView();

        if (!isEmpty(parentResource)) {
          refreshRelation();
          actorDispatch('refreshView', resource);
        }
        setLoading(false);
      })
      .catch(error => {
        showNotification(error.userMessage || error.toString(), 'error');
        setLoading(false);
      })
      .finally(() => {
        handleClose();
      });
  };

  /**
   * Get file from `event` and set `uploadedFile` state.
   * @function onFileChange
   * @param {event} event
   * @returns {void}
   */
  const onFileChange = useCallback(event => {
    const file = event.target.files[0];
    setUploadedFile(file);
  }, []);

  /**
   * Get file and close dialog.
   * @function handleDownloadSampleFile
   * @returns {void}
   */
  const handleDownloadSampleFile = useCallback(async () => {
    let preparedLink = `/${resource}/import/excel`;
    if (!isEmpty(parentResource) && !isEmpty(parentId)) {
      preparedLink = `/${parentResource}/${parentId}/${resource}/import/excel`;
    }

    const param = {
      link: preparedLink,
    };

    downloadFile(await getBlobFile(param), resource);
    handleClose();
  }, []);

  return (
    <>
      <Tooltip title={translate('toolbar.importComponent.import')}>
        <div className={classes.importButtonContainer}>
          <IconButton
            className={classes.iconButton}
            onClick={handleClick}
            color="primary"
            disabled={!lodashGet(metaData, ['config', 'hasImportFromExcel'])}
            data-test-import-button
          >
            <Icon>publish</Icon>
          </IconButton>
        </div>
      </Tooltip>

      <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle className={classes.dialogPadding}>
          {translate('toolbar.importComponent.importExcelFile')}
        </DialogTitle>
        <DialogContent className={classes.dialogPadding}>
          <input
            name="uploadFile"
            onChange={onFileChange}
            type="file"
            className={classes.customFileInput}
            id="importFileinput"
          />
        </DialogContent>
        <DialogActions className={classes.dialogPadding}>
          <Button variant="text" onClick={handleDownloadSampleFile}>
            {translate('toolbar.importComponent.downloadSampleFile')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUploadExcel}
            disabled={loading || !uploadedFile}
            data-test-upload-button
          >
            {loading ? (
              <CircularProgress size={'20px'} />
            ) : (
              translate('toolbar.importComponent.uploadExcelFile')
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapDispatchToProps = {
  refreshView,
  refreshRelation,
};

export default connect(null, mapDispatchToProps)(ImportButton);
