import React, { FC, ReactElement, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getIconClassName } from '@uifabric/styling';
import { Typography, Popper } from '@material-ui/core';
import moment, { MomentInput } from 'moment';
import { Menu, Item, ItemParams } from 'react-contexify';

import { CustomTheme } from '../../core/themeProvider';
import { Calendar } from 'react-datepicker2-samian';
import { CONFIG_CALENDAR, getValue } from '../../core/configProvider';
import { ContextMenuItemType } from '../../helper/Types';

const useStyles = makeStyles((theme: CustomTheme) => ({
  menu: {
    margin: 0,
    padding: 0,
  },

  menuItems: {
    borderBottom: `2px solid ${theme.palette.primary.appPrimaryDividerColor}`,
    '&:last-child': {
      borderBottom: 'none',
    },
    '& > div': {
      padding: '10px 12px',
      display: 'flex',
      alignItems: 'center',
    },
    '&:hover > div': {
      backgroundColor: `${theme.palette.primary.appPrimaryLightBackgroundColor} !important`,
      color: `${theme.palette.primary.appPrimaryTextColor} !important`,
      transition: 'all 200ms',
    },
  },

  itemIcon: {
    fontSize: 15,
    color: theme.palette.secondary.main,
  },

  itemText: {
    margin: '0 8px',
  },
}));

interface TodoListContextMenuType {
  contextMenuItems: ContextMenuItemType[];
  uniqueKey: string;
  handleItemClick: Function;
}

const TodoListContextMenu: FC<TodoListContextMenuType> = props => {
  const { contextMenuItems, uniqueKey, handleItemClick } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>(null);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const calendarConfig = getValue(CONFIG_CALENDAR);

  /**
   * Handle multiple types of icons set and returns an icon component ready to render
   * @function renderIcon
   * @param icon `ReactComponent` or a `string` of an icon name
   * @returns React Component
   */
  const renderIcon = (icon: string | ReactElement): HTMLElement | ReactElement => {
    if (typeof icon === 'string') {
      return <i className={`${classes.itemIcon} ${getIconClassName(icon)}`}></i>;
    }

    return icon;
  };

  /**
   * Update task with selected date, hide popper and close submenu
   * @function handleMenuClick
   * @param {ContextMenuItemType} contains a dataTestAttribute key to convert to a valid date
   * @returns {void}
   */
  const handleMenuClick = (item: ContextMenuItemType) => (): void => {
    handleItemClick(item);
    setAnchorEl(null);
    setIsSubmenuOpen(false);
  };

  /**
   * Open calander on upper layer of context menu
   * @function handleSubMenuClick
   * @param {event} event
   * @returns {void}
   */
  const handleSubMenuClick = ({ event }: ItemParams<{}, any>): void => {
    const { currentTarget } = event;

    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(currentTarget);
    setIsSubmenuOpen(true);
  };

  /**
   * Update task with selected custom date
   * @function handleCalenderChange
   * @param {MomentInput} event
   * @param {ContextMenuItemType} item
   * @returns {void}
   */
  const handleCalenderChange =
    (item: ContextMenuItemType) =>
    (event: MomentInput): void => {
      item.value = moment(event).locale('en').format('YYYY-MM-DD');
      handleItemClick(item);
      setAnchorEl(null);
      setIsSubmenuOpen(false);
    };

  /**
   * When Menu is closed, this handler will be fired to close contextMenu and popper
   * @function onHidden
   * @returns {void}
   */
  const onHidden = () => {
    setAnchorEl(null);
    setIsSubmenuOpen(false);
  };

  return (
    <Menu id={uniqueKey} className={classes.menu} onHidden={onHidden}>
      {contextMenuItems &&
        contextMenuItems.map(item => {
          if (!item.submenu) {
            return (
              // @ts-ignore
              <Item
                data-test-context-menu={item.dataTestAttribute}
                onClick={handleMenuClick(item)}
                className={classes.menuItems}
                key={item.title}
                disabled={!!item.disable}
              >
                <>
                  {renderIcon(item.icon)}
                  <Typography variant="caption" className={classes.itemText}>
                    {item.title}
                  </Typography>
                </>
              </Item>
            );
          } else {
            return (
              <div>
                {/* @ts-ignore */}
                <Item
                  className={classes.menuItems}
                  onClick={handleSubMenuClick}
                  disabled={!!item.disable}
                  data-test-context-menu={item.dataTestAttribute}
                >
                  <>
                    {renderIcon(item.icon)}
                    <Typography variant="caption" className={classes.itemText}>
                      {item.title}
                      <Popper
                        placement={calendarConfig === 'gregorian' ? 'left' : 'right'}
                        anchorEl={anchorEl}
                        id={item.dataTestAttribute}
                        open={isSubmenuOpen}
                        disablePortal={false}
                      >
                        <Calendar
                          onChange={handleCalenderChange(item)}
                          isGregorian={calendarConfig === 'gregorian'}
                        />
                      </Popper>
                    </Typography>
                  </>
                </Item>
              </div>
            );
          }
        })}
    </Menu>
  );
};

export default TodoListContextMenu;
