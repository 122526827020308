import { clone, isEmptyObject } from '../../helper/data-helper';
import { DropdownMeta } from '../dynamic-input/dropdown-input';
import { DropdownData } from './search-popup-dialog.type';

/**
 * it will get ray data that received from api with some other props like meta and ... , then
 * process it to create a new object of dropdown data with a format that dropdown search dialog need it
 * also should handle data of tree if dropdown has tree in its second tab
 * @function prepareDropdownData
 * @param {DropdownMeta} dropdownMeta
 * @param {Array<Record<string, unknown> & { id: number }>} dropdownData
 * @param {string | number} dropdownId
 * @param {number} totalDataCount
 * @returns {DropdownData | null}
 */
export const prepareDropdownData = (
  dropdownMeta: DropdownMeta,
  dropdownData: Array<Record<string, unknown> & { id: number }>,
  dropdownId: string | number, // fixme: number or string ? -__-
  totalDataCount: number,
): DropdownData | null => {
  if (!dropdownData || !dropdownData.length || !dropdownMeta?.columns) return null;

  const _columns = dropdownMeta?.columns;

  const _preparedIds: Array<number> = [];
  const _preparedData: Record<string, unknown> = {};
  const _preparedDataLastLevel: Record<string, unknown> = {};
  const _preparedLastLevelIds: Array<number> = [];

  if (dropdownData) {
    dropdownData?.forEach((row, index) => {
      _preparedIds.push(row.id);
      _preparedData[index] = row;
    });
  }

  if (dropdownMeta.type === 'Tree') {
    Object.keys(_preparedData).forEach(key => {
      const _row = _preparedData[key] as Record<string, unknown>;
      if (
        dropdownMeta.treeLevel === 0 ||
        _row.currentlevel === dropdownMeta.treeLevel - 1
      ) {
        _preparedLastLevelIds.push(_row.id as number);
        _preparedDataLastLevel[_row.id as number] = _row;
      }
    });
  }

  return {
    preparedIds: _preparedIds,
    preparedData: _preparedData,
    preparedDataLastLevel: _preparedDataLastLevel,
    preparedLastLevelIds: _preparedLastLevelIds,
    totalDataCount,
  };
};

/**
 * it will receive dropdown meta , then add necessary props like `name` to each column , then make a
 * new array of grid columns with props that `devExGrid` needs
 * @function prepareGridColumns
 * @param {DropdownMeta} dropdownMeta
 * @returns Array<Record<string, unknown>>
 */
export const prepareGridColumns = (
  dropdownMeta: DropdownMeta,
): Array<Record<string, unknown>> => {
  const _columns = dropdownMeta?.columns;
  const _preparedFields: Array<Record<string, unknown>> = [];

  _columns?.forEach(_item => {
    const _field = clone(_item);
    if (!_field.name && _field.title) {
      _field.name = _field.title;
    }

    if (!_field.relatedName && _field.title) {
      _field.relatedName = _field.title;
    }

    if (
      isEmptyObject(_field.translatedCaption) &&
      !isEmptyObject(_field.translatedTitle)
    ) {
      _field.translatedCaption = _field.translatedTitle;
    }

    _preparedFields.push(_field);
  });

  return _preparedFields;
};
