import { ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Box from '@material-ui/core/Box';
import { UserListViewInterface } from './user-list.type';
import { UserItem } from './user-item';
import LoadingBox from '../../../loading-box';
import { ChatItem } from './chat-item';
import { ChatInterface, ContactInterface } from '../../chat-section.type';
import { useStyles } from './user-list.style';

const UserListView = (props: UserListViewInterface): ReactElement => {
  const {
    data,
    fetchMore,
    refreshFunction,
    hasMore,
    loading,
    isChatMode,
    onUserSelect,
    selectedUser,
  } = props;

  const classes = useStyles();

  return (
    <Box
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      flex={1}
    >
      {loading ? (
        <LoadingBox size={20} />
      ) : (
        <InfiniteScroll
          dataLength={data && data.length ? data.length : 10}
          next={fetchMore}
          hasMore={hasMore}
          loader={<LoadingBox size={20} />}
          refreshFunction={refreshFunction}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
            <p style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</p>
          }
          releaseToRefreshContent={
            <p style={{ textAlign: 'center' }}>&#8593; Release to refresh</p>
          }
          height={750}
          className={classes.infiniteScroll}
        >
          {data?.map((item: ChatInterface | ContactInterface, index: number) => (
            <Box key={'list-item-' + index}>
              {isChatMode ? (
                <ChatItem
                  key={item?.personinfo_id}
                  item={item as ChatInterface}
                  onUserSelect={onUserSelect}
                  selectedUser={selectedUser}
                />
              ) : (
                <UserItem
                  key={item?.personinfo_id}
                  item={item}
                  onUserSelect={onUserSelect}
                  selectedUser={selectedUser}
                />
              )}
            </Box>
          ))}
        </InfiniteScroll>
      )}
    </Box>
  );
};

export default UserListView;
