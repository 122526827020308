import { memo, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslate, useLocale } from 'react-admin';
import lodashIsEqual from 'lodash/isEqual';
import { v4 as uuidv4 } from 'uuid';

import { isEmpty, isEmptyObject } from '../../helper/data-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
  FormKeyMode,
} from '../../type/actor-setup';
import {
  archiveMailId,
  currentUserFoldersReportId,
  currentUserLabelsReportId,
  defaultMailFormValue,
  deleteFolderServiceId,
  generateMainSearchParameter,
  getFolderId,
  getMailDetailReportId,
  getReferenceReportDataId,
  handleUpdateIsReadMailsData,
  mailReportId,
  newFolderId,
  newLabelId,
  newLetterQuickAccessLink,
  newMessageId,
  onArchiveMailId,
  onImportantMailId,
  onMoveToFolderServiceId,
  onStarMailId,
  onUnReadMailId,
  inMailLink,
  recallServiceId,
  removeQuestionSignFromParam,
  submitLabelsId,
  handleUpdateMailsData,
} from './mail-section.helper';
import {
  DocTypeEnum,
  MailActions,
  MailInterface,
  MailLabelType,
  OnChangeCheckedMailsParams,
  OnChangePageHandlerParams,
  OnComposeParams,
  OnCreateNewLabelParams,
  OnHandleMasterCheckboxParams,
  OnImportantMailParams,
  OnMarkUnReadParams,
  OnShowDetailParams,
  OnStarMailParams,
  OnSendNewMessageParams,
  OnOpenReferenceFormParams,
  OnGetLabelsParams,
  OnGetFoldersParams,
  OnGetReferenceDataParams,
  SelectedMailInterface,
  OnRecallParams,
  OnArchiveParams,
  OnMoveToFolderParams,
  OnDeleteFolderParams,
  OnForwardMessageParams,
  MessageServiceParamsInterface,
  SavedFabData,
  OnOpenFabMessageFormParams,
  OnRemoveFabMessageParams,
} from './mail-section.type';
import MailSectionView from './mail-section.view';
import { callQuickAccess, openNewTab } from '../../helper/QuickAccessHelper';
import { getParamFromUrl, validUrl } from '../../helper/UrlHelper';
import { FolderTypeEnum, OnSubmitLabelsParams } from '.';
import dataProvider, { GET_REPORT } from '../../core/dataProvider';
import { usePrevious } from '../../hooks/usePrevious';
import { ExtraParamsInterface, FormActions, InputRefContent } from '../form';
import { showNotification } from '../../helper/general-function-helper';
import lodashMap from 'lodash/map';
import LoadingBox from '../LoadingBox';
import { localStorageGetItem } from '../../core/configProvider';
// should be set location changes
let folderType: number | null = null;
let docTypeId: number | null = null;
let folderId: number | null = null;
let isArchived: number | null = null;
let isImportant: number | null = null;
let isStarred: number | null = null;
let isRead: number | null = null;
let referenceFormTitle = '' as string;

const InboxSectionController = memo(() => {
  const translate = useTranslate();
  const history = useHistory();
  const location = useLocation();
  const locale = useLocale();
  const prevLocation = usePrevious(location);

  const uniqueId: string = getParamFromUrl(location.search, 'uniqueid')!;
  const refrencesId: string = getParamFromUrl(location.search, 'refrencesid')!;
  const mainSearchParameter = generateMainSearchParameter(location.search);
  const prevUniqueId = usePrevious(uniqueId);
  const prevMainSearchParameter = usePrevious(mainSearchParameter);
  const mailDataPerPage = 25;

  const [checkedMails, setCheckedMails] = useState<MailInterface[]>([]);

  const [isReferenceFormOpen, setIsReferenceFormOpen] = useState<boolean>(false);
  const [isNewMessageFormOpen, setIsNewMessageFormOpen] = useState<boolean>(false);
  const [isMailFormFullScreen, setIsMailFormFullScreen] = useState<boolean>(false);
  const [isNewFolderFormOpen, setIsNewFolderFormOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [forceRefresh, setForceRefresh] = useState<boolean>(false);

  /**
   * @function getDraftMails
   * @returns { savedFabData[] }
   */
  const getDraftMails = (): SavedFabData[] => {
    try {
      const draftMails = JSON.parse(localStorage.getItem('draftMails') || '[]');
      return draftMails;
    } catch (e) {
      return [];
    }
  };
  const draftMails = getDraftMails();

  /**
   * @function successGetMailDataCallback
   * @param { MailInterface[] } data
   * @param { number } page
   * @returns { void }
   */
  const successGetMailDataCallback = ({ data, total }, page): void => {
    if (isEmpty(data)) {
      actorDispatch('loading', {
        mailList: false,
      });
      return;
    }

    actorDispatch(
      'mailData',
      {
        data,
        pagination: {
          total,
          currentPage: page,
          perPage: mailDataPerPage,
        },
      },
      { disableDebounce: true },
    );

    actorDispatch('loading', {
      mailList: false,
    });
  };

  /**
   * @function successGetMailDetailCallback
   * @param { MailDetailInterface } data
   * @returns { void }
   */
  const successGetMailDetailCallback = ({ data }): void => {
    if (isEmpty(data) || isEmptyObject(data)) {
      actorDispatch('loading', {
        mailDetail: false,
      });
      return;
    }

    actorDispatch('selectedMail', data);

    if (!data[0]?.isunread) {
      handleUpdateIsReadMailsData(data[0]?.doc_id);
      getUserFolders();
    }

    actorDispatch('loading', {
      mailDetail: false,
    });

    getMailData(false, true);
  };

  /**
   * @function failureCallback
   * @param error
   * @returns { void }
   */
  const failureCallback = (error: any): void => {
    showNotification(error, 'error');
  };

  /**
   * to get all contacts
   * @function getMailData
   * @param { Boolean } shouldLoading
   * @param { string } searchKeyWord
   * @param { Boolean } onlyRefreshSWCache
   * @returns { void }
   */

  const getMailData = useCallback(
    (shouldLoading = false, onlyRefreshSWCache = false) => {
      const page = actorGetActionValue('mailData')?.pagination?.currentPage ?? 1;

      const mailSearchWord = actorGetActionValue(
        'inputsRef',
        `mail.${FormKeyMode.ROOT}.headerSearchInput`,
      ) as Record<string, InputRefContent> | undefined;

      shouldLoading &&
        actorDispatch('loading', {
          mailList: true,
        });
      actorDispatch(
        'getMailReport',
        {
          successCallback: response =>
            !onlyRefreshSWCache
              ? successGetMailDataCallback(response, page)
              : undefined,
          params: {
            reportId: mailReportId,
            pagination: { page: 0, perPage: mailDataPerPage },
            filters: [
              ['foldertype', 'equal', folderType],
              ['doctype_id', 'equal', docTypeId],
              ['folders_id', 'equal', folderId],
              ['isarchived', 'equal', isArchived],
              ['isimportant', 'equal', isImportant],
              ['isstared', 'equal', isStarred],
              ['isread', 'equal', isRead],
              ['Abstract', 'equal', mailSearchWord?.current?.value],
              ['takeCount', 'equal', mailDataPerPage], //RCT-2679
              ['skip', 'equal', (page - 1) * mailDataPerPage], //RCT-2679
            ],
            sort: {
              field: 'createdate',
              order: 'DESC',
            },
          },
          failureCallback,
        },
        {
          disableDebounce: true,
        },
      );
    },
    [],
  );

  /**
   * @function successGetFoldersCallback
   * @returns { void }
   */
  const successGetFoldersCallback = ({ data, total }): void => {
    data && actorDispatch('mailFolders', data);
  };

  type GetUserFolders = (isForInbox?: number, successCallback?: any) => void;

  /**
   * to get current user folders
   * @function getUserFolders
   * @param { number } isForInbox
   * @param { function } successCallback
   * @returns { void }
   */
  const getUserFolders = useCallback<GetUserFolders>(
    (isForInbox = 1, successCallback = null) => {
      actorDispatch(
        'getMailReport',
        {
          successCallback: successCallback ?? successGetFoldersCallback,
          params: {
            reportId: currentUserFoldersReportId,
            pagination: { perPage: 9999 },
            filters: [['ForInbox', 'equal', isForInbox]],
          },
          failureCallback,
        },
        {
          disableDebounce: true,
        },
      );
    },
    [],
  );

  /**
   * to get current user labels
   * @function getUserLabels
   * @param { Function } successGetLabelsCallback
   * @returns { void }
   */
  const getUserLabels = useCallback(successGetLabelsCallback => {
    actorDispatch(
      'getMailReport',
      {
        successCallback: successGetLabelsCallback,
        params: {
          reportId: currentUserLabelsReportId,
          pagination: { perPage: 9999 },
        },
        failureCallback,
      },
      {
        disableDebounce: true,
      },
    );
  }, []);

  /**
   * @function onMailPageChangeHandler
   * @param { number } page
   * @returns { void }
   */
  const onMailPageChangeHandler = (page: number): void => {
    const currentMailData = actorGetActionValue('mailData')!;
    actorDispatch('mailData', {
      ...currentMailData,
      pagination: {
        ...currentMailData?.pagination,
        currentPage: page,
      },
    });
    getMailData(true);
  };

  /**
   * @function onRefreshMailDataPage
   * @returns { void }
   */
  const onRefreshMailDataPage = (): void => {
    const currentMailData = actorGetActionValue('mailData')!;
    const currentMailDataPage = currentMailData?.pagination?.currentPage;

    currentMailDataPage !== 1 &&
      actorDispatch('mailData', {
        ...currentMailData,
        pagination: {
          ...currentMailData?.pagination,
          currentPage: 1,
        },
      });
  };

  /**
   * to refresh mail data
   * @function OnRefreshMailData
   * @returns { void }
   */
  const OnRefreshMailData = (): void => {
    getMailData(true);
  };

  /**
   * @function onSuccessServiceCallback
   * @returns { void }
   */
  const onSuccessServiceCallback = (): void => {
    if (!isEmpty(uniqueId)) {
      onGetMailDetail(false, uniqueId, refrencesId);
    } else {
      getMailData(false, true);
      !isEmptyObject(checkedMails) && setCheckedMails([]);
    }
    showNotification(translate('service.success'), 'info');
    getUserFolders();
    getMailData(false);
  };

  /**
   * onSuccessStartMailService
   * @param {number} docId
   * @param {boolean} isStarred
   * @returns void
   */
  const onSuccessStartMailService = (docId: number, isStarred: boolean): void => {
    //update actor data
    handleUpdateMailsData(docId, { isstared: isStarred });
    onSuccessServiceCallback();
  };
  /**
   * to star mail
   * @function onStarMailService
   * @param { unknown } toUpdateMailList
   * @param { boolean } isStarred
   * @returns { Promise<void>  }
   */
  const onStarMailService = async (
    toUpdateMailList,
    isStarred: boolean,
  ): Promise<void> => {
    await actorDispatch(
      'runMailService',
      {
        successCallback: () =>
          onSuccessStartMailService(toUpdateMailList[0].docid, isStarred),
        params: {
          actionUniqueId: onStarMailId,
          data: {
            params: {
              json: toUpdateMailList,
              isstared: isStarred,
            },
          },
        },
        failureCallback: failureCallback,
      },
      {
        disableDebounce: true,
      },
    );
  };

  /**
   * @function onStarMail
   * @param { MailInterface[] } mailsData
   * @param { boolean } isStarred
   * @returns { Promise<void>  }
   */
  const onStarMail = async (
    mailsData: MailInterface[] | null,
    isStarred: boolean,
  ): Promise<void> => {
    let data = mailsData;
    if (!mailsData) {
      data = checkedMails;
    }
    const toUpdateMailList = data?.map((mail: MailInterface) => ({
      doctype_id: mail.doctype_id,
      docid: mail.doc_id,
      refrences_id: mail.refrences_id,
    }));
    await onStarMailService(toUpdateMailList, isStarred);
  };

  /**
   * @function onSuccessReferenceCallback
   * @returns { void }
   */
  const onSuccessReferenceCallback = (): void => {
    showNotification(`${translate('mail.successArchive')}`, 'info');
    handleCloseReferenceForm();
    !isEmptyObject(checkedMails) && setCheckedMails([]);

    if (!isEmpty(uniqueId)) {
      onGetMailDetail(false, uniqueId, refrencesId);
    }
  };

  /**
   * to reference mail
   * @function onReferenceMailService
   * @param { unknown } toUpdateMailList
   * @param { Record<string, unknown> } data
   * @returns { Promise<void>  }
   */
  const onReferenceMailService = async (
    toUpdateMailList,
    data: Record<string, unknown>,
  ): Promise<void> => {
    await actorDispatch(
      'runMailService',
      {
        successCallback: onSuccessReferenceCallback,
        params: {
          actionUniqueId: archiveMailId,
          data: {
            params: {
              json: toUpdateMailList,
              ...data,
            },
          },
        },
        failureCallback: failureCallback,
      },
      {
        disableDebounce: true,
      },
    );
  };

  /**
   * @function onSetDefaultFormValue
   * @param { Record<string, unknown> } data
   * @returns { void }
   */
  const onSeFormDefaultValue = (data: Record<string, unknown>): void => {
    actorDispatch('formData', data, {
      path: `automation/messages.${FormKeyMode.ROOT}`,
    });
  };

  /**
   * @function handleCloseReferenceForm
   * @returns { void }
   */
  const handleCloseReferenceForm = useCallback(() => {
    actorRemoveAction({
      actionName: 'formData',
      path: `automation/messages.${FormKeyMode.ROOT}`,
    });

    actorDispatch('selectedDoc', null, {
      replaceAll: true,
    });

    onSeFormDefaultValue(defaultMailFormValue);
    setIsReferenceFormOpen(false);
  }, [setIsReferenceFormOpen]);

  /**
   * @function handleOpenReferenceForm
   * @param { Record<string, unknown> } formData
   * @returns { void }
   */
  const handleOpenReferenceForm = useCallback(
    (formData: Record<string, unknown>) => {
      formData.IsReplyForm = true; //use in template-action service
      onSeFormDefaultValue(formData);
      setIsReferenceFormOpen(true);
    },
    [setIsReferenceFormOpen],
  );

  /**
   * @function handleCloseNewMessageForm
   * @returns { void }
   */
  const handleCloseNewMessageForm = useCallback(() => {
    actorRemoveAction({
      actionName: 'formData',
      path: `automation/messages.${FormKeyMode.ROOT}`,
    });

    onSeFormDefaultValue(defaultMailFormValue);
    setIsNewMessageFormOpen(false);
  }, [setIsNewMessageFormOpen]);

  /**
   * @function handleCloseNewFolderForm
   * @returns { void }
   */
  const handleCloseNewFolderForm = useCallback(() => {
    actorRemoveAction({
      actionName: 'formData',
      path: `automation/messages.${FormKeyMode.ROOT}`,
    });

    onSeFormDefaultValue(defaultMailFormValue);
    setIsNewFolderFormOpen(false);
  }, [setIsNewFolderFormOpen]);

  /**
   * @function handleOpenNewMessageForm
   * @param { Record<string, unknown> } formData
   * @returns { void }
   */
  const handleOpenNewMessageForm = useCallback(
    (formData: Record<string, unknown>) => {
      onSeFormDefaultValue(formData);
      setIsNewMessageFormOpen(true);
    },
    [setIsNewMessageFormOpen],
  );

  /**
   * @function onReferenceHandler
   * @returns { void }
   */
  const onReferenceHandler = (): void => {
    let mailsData = checkedMails;
    const selectedDoc = actorGetActionValue('selectedDoc')!;
    if (isEmptyObject(checkedMails) && selectedDoc) {
      mailsData = [selectedDoc as MailInterface];
    }

    const toUpdateMailList = mailsData?.map((mail: MailInterface) => ({
      doctype_id: mail.doctype_id,
      docid: mail.doc_id,
      refrences_id: mail.refrences_id,
    }));

    const currentResource = actorGetActionValue('resources')!.current;
    const formData = actorGetActionValue('formData', [
      currentResource.value,
      currentResource.type,
    ]) as Record<string, unknown>;

    const ReferenceData = {
      ToPersonInfo_Id: formData?.['topersoninfo_id'],
      CCPersonInfo_Id: formData?.['ccpersoninfo_id'],
      BCCPersonInfo_Id: formData?.['bccpersoninfo_id'],
      ReferenceType: formData?.['referencetype'],
      ParaphTemplate_Id: formData?.['paraphtemplate_id'],
      LetterParaph: formData?.['LetterParaph'],
      ReferAttachments: formData?.['attachfiles'],
      ParaphText: formData?.['paraphtext'],
      IsArchieved: formData?.['IsArchieved'],
    };

    onReferenceMailService(toUpdateMailList, ReferenceData);
  };

  /**
   * onSuccessImportantService
   * @function onSuccessImportantService
   * @param {number} docId
   * @param {boolean} isImportant
   * @returns void
   */
  const onSuccessImportantService = (docId: number, isImportant: boolean): void => {
    //update actor data
    handleUpdateMailsData(docId, { isimportant: isImportant });
    onSuccessServiceCallback();
  };
  /**
   * to make  important a mail
   * @function onImportantMailService
   * @param { unknown } toUpdateMailList
   * @param { boolean } isImportant
   * @returns { Promise<void>  }
   */
  const onImportantMailService = async (
    toUpdateMailList,
    isImportant: boolean,
  ): Promise<void> => {
    await actorDispatch(
      'runMailService',
      {
        successCallback: onSuccessImportantService(
          toUpdateMailList[0].docid,
          isImportant,
        ),
        params: {
          actionUniqueId: onImportantMailId,
          data: {
            params: {
              json: toUpdateMailList,
              isimportant: isImportant,
            },
          },
        },
        failureCallback: failureCallback,
      },
      {
        disableDebounce: true,
      },
    );
  };

  /**
   * @function onImportantMail
   * @param { MailInterface[] } mailsData
   * @param { boolean } isImportant
   * @returns { Promise<void> }
   */
  const onImportantMail = async (
    mailsData: MailInterface[] | null,
    isImportant: boolean,
  ): Promise<void> => {
    let data = mailsData;
    if (!mailsData) {
      data = checkedMails;
    }
    const toUpdateMailList = data?.map((mail: MailInterface) => ({
      doctype_id: mail.doctype_id,
      docid: mail.doc_id,
    }));

    await onImportantMailService(toUpdateMailList, isImportant);
  };

  /**
   * @function onArchiveMailService
   * @param { unknown } toUpdateMailList
   * @returns { Promise<void>  }
   */
  const onArchiveMailService = async (toArchiveMailList): Promise<void> => {
    await actorDispatch(
      'runMailService',
      {
        successCallback: onSuccessServiceCallback,
        params: {
          actionUniqueId: onArchiveMailId,
          data: {
            params: {
              json: toArchiveMailList,
              IsArchieved: 1,
            },
          },
        },
        failureCallback: failureCallback,
      },
      {
        disableDebounce: true,
      },
    );
  };
  /**
   * @function onUnArchiveMailService
   * @param { unknown } toUpdateMailList
   * @returns { Promise<void>  }
   */
  const onUnArchiveMailService = async (toArchiveMailList): Promise<void> => {
    await actorDispatch(
      'runMailService',
      {
        successCallback: onSuccessServiceCallback,
        params: {
          actionUniqueId: onArchiveMailId,
          data: {
            params: {
              json: toArchiveMailList,
              IsArchieved: 0,
            },
          },
        },
        failureCallback: failureCallback,
      },
      {
        disableDebounce: true,
      },
    );
  };

  /**
   * @function onArchiveHandler
   * @param { MailInterface[] } mailsData
   * @returns { void }
   */
  const onArchiveHandler = (mailsData: MailInterface[] | null): void => {
    let data = mailsData;
    if (!mailsData) {
      data = checkedMails;
    }
    const toArchiveMailList = data?.map((mail: MailInterface) => ({
      doctype_id: mail.doctype_id,
      docid: mail.doc_id,
      refrences_id: mail.refrences_id,
    }));

    onArchiveMailService(toArchiveMailList);
  };
  /**
   * @function onArchiveHandler
   * @param { MailInterface[] } mailsData
   * @returns { void }
   */
  const onUnArchiveHandler = (mailsData: MailInterface[] | null): void => {
    let data = mailsData;
    if (!mailsData) {
      data = checkedMails;
    }
    const toUnArchiveMailList = data?.map((mail: MailInterface) => ({
      doctype_id: mail.doctype_id,
      docid: mail.doc_id,
      refrences_id: mail.refrences_id,
    }));
    onUnArchiveMailService(toUnArchiveMailList);
  };

  /**
   * onSuccessStartMailService
   * @param {number} docId
   * @param {boolean} isStarred
   * @returns void
   */
  const onSuccessMarkUnReadMailService = (
    toUpdateMailList: number,
    IsUnRead: boolean,
  ) => {
    //update actor data
    lodashMap(toUpdateMailList, docItem => {
      handleUpdateMailsData(docItem.docid, { isunread: IsUnRead });
    });
    onSuccessServiceCallback();
  };

  /**
   * to mark  unRead a mail
   * @function onMarkUnReadMailService
   * @param { unknown } toUpdateMailList
   * @param { boolean } isImportant
   * @returns { Promise<void>  }
   */
  const onMarkUnReadMailService = async (
    toUpdateMailList,
    IsUnRead: boolean,
  ): Promise<void> => {
    await actorDispatch(
      'runMailService',
      {
        successCallback: () =>
          onSuccessMarkUnReadMailService(toUpdateMailList, IsUnRead),
        params: {
          actionUniqueId: onUnReadMailId,
          data: {
            params: {
              json: toUpdateMailList,
              isunread: +IsUnRead,
            },
          },
        },
        failureCallback: failureCallback,
      },
      {
        disableDebounce: true,
      },
    );
  };

  /**
   * @function onMarkUnRead
   * @param { MailInterface[] } mailsData
   * @param { boolean } IsUnRead
   * @returns { Promise<void> }
   */
  const onMarkUnRead = async (
    mailsData: MailInterface[] | null,
    IsUnRead: boolean,
  ): Promise<void> => {
    let data = mailsData;
    if (!mailsData) {
      data = checkedMails;
    }
    const toUpdateMailList = data?.map((mail: MailInterface) => ({
      doctype_id: mail.doctype_id,
      docid: mail.doc_id,
      refrences_id: mail.refrences_id,
    }));

    await onMarkUnReadMailService(toUpdateMailList, IsUnRead);
  };

  /**
   * to get  mail detail
   * @function onGetMailDetail
   * @param { boolean } shouldLoading
   * @param { string } uniqueId
   * @param { string } MessageReferences
   * @returns { void }
   */
  const onGetMailDetail = useCallback(
    async (shouldLoading = false, uniqueId: string, MessageReferences: string) => {
      shouldLoading &&
        actorDispatch('loading', {
          mailDetail: true,
        });
      actorDispatch(
        'getMailReport',
        {
          successCallback: successGetMailDetailCallback,
          params: {
            reportId: getMailDetailReportId,
            pagination: { perPage: 999999 },
            filters: [
              ['UniqueID', 'equal', uniqueId],
              ['MessageReferences', 'equal', MessageReferences],
            ],
            sort: { field: 'docdate', order: 'desc' },
          },
          failureCallback,
        },
        {
          disableDebounce: true,
        },
      );
    },
    [],
  );

  /**
   * @function onGetReferenceDataHandler
   * @param { string } id
   * @param { boolean } isChart
   * @param { Record<string, unknown> } pagination
   * @param { Function } successCallback
   * @returns { <Promise<void> }
   */
  const onGetReferenceDataHandler = useCallback(
    async (id: string, isChart: boolean, pagination, successCallback) => {
      actorDispatch(
        'getMailReport',
        {
          successCallback: successCallback,
          params: {
            reportId: getReferenceReportDataId,
            pagination: pagination,
            filters: [
              ['UniqueID', 'equal', id],
              ['isChart', 'equal', +isChart],
            ],
          },

          failureCallback,
        },
        {
          disableDebounce: true,
        },
      );
    },
    [],
  );

  /**
   * @function handleOpenQuickAccess
   * @returns { Promise<void> }
   */
  const handleOpenQuickAccess = async (
    link: string,
    openInNewTab = false,
  ): Promise<void> => {
    try {
      const target = openInNewTab ? '_blank' : '_self';
      //if target=="_blank" then open url in new tab and stay in current tab
      const newTab = window.open('', target);
      if (newTab) {
        const url = await callQuickAccess(link);
        const finalUrl = validUrl(url) ? url : `#${url}`;
        newTab.location = finalUrl;
      }
    } catch (error) {
      showNotification(error, 'warning');
    }
  };

  /**
   * to get draft data
   * @function getDraftData
   * @param { string } uniqueId
   * @returns {  Promise<void> }
   */
  const getDraftData = async (uniqueId: string): Promise<void> => {
    try {
      const params = {
        reportId: getMailDetailReportId,
        pagination: { perPage: 999999 },
        filters: [
          ['UniqueID', 'equal', uniqueId],
          ['MessageReferences', 'equal', ''],
        ],
        sort: { field: 'docdate', order: 'desc' },
      };
      const response = await dataProvider(GET_REPORT, '', params);

      return response?.data ?? {};
    } catch (error) {
      failureCallback(error);
    }
  };

  /**
   * @function handleOpenDraft
   * @param { MailInterface } mail
   * @returns { Promise<void> }
   */
  const handleOpenDraft = async (mail: MailInterface): Promise<void> => {
    const response = await getDraftData(mail.uniqueid);
    const draftData: SelectedMailInterface = response[0];

    if (!isEmptyObject(draftData)) {
      const newFormData = {
        ccinputisactive: true,
        bccinputisactive: true,
        docId: draftData?.doc_id,
        flagreminderactive: draftData?.flagreminderactive,
        isflag: draftData ? Boolean(draftData.isflag) : false,
        topersoninfo_id: draftData?.topersoninfo_id,
        ccpersoninfo_id: draftData?.ccpersoninfo_id,
        bccpersoninfo_id: draftData?.bccpersoninfo_id,
        subject: draftData?.subject,
        attachfiles:
          draftData && draftData.attachfiles && JSON.parse(draftData.attachfiles),
        messagebody: draftData?.messagebody,
        deadlinedate: draftData?.deadlinedate,
        flagstartdate: draftData?.flagstartdate,
        flagduedate: draftData?.flagduedate,
        reminddatetime: draftData?.reminddatetime,
        __bccpersoninfo_id_value: draftData?.__bccpersoninfo_id_value,
        __ccpersoninfo_id_value: draftData?.__ccpersoninfo_id_value,
        __topersoninfo_id_value: draftData?.__topersoninfo_id_value,
      };

      handleOpenNewMessageForm(newFormData);
    }
  };

  /**
   * to go to detail page
   * @function onShowDetailHandler
   * @param { MailInterface } mail
   * @returns { void }
   */
  const onShowDetailHandler = (
    mail: MailInterface,
    event?: React.MouseEvent<HTMLElement>,
  ): void => {
    const { pathname, search } = location;
    const isOpenNewTab = event?.ctrlKey || event?.metaKey;

    if (mail.doctype_id === 5) {
      const pathAddress = `${pathname}${search}&uniqueid=${mail.uniqueid}&refrencesid=${mail.refrences_id}`;
      isOpenNewTab ? openNewTab(pathAddress, '_blank') : history.push(pathAddress);
    } else if (mail.doctype_id === 6) {
      handleOpenDraft(mail);
    } else {
      const link = mail.__doc_id_link.substring(
        mail.__doc_id_link.lastIndexOf('/') + 1,
      );
      handleOpenQuickAccess(link, isOpenNewTab);
    }
  };

  /**
   * to add new label
   * @function newLabelService
   * @param { function } onSuccessNewLabelCallback
   * @returns { void  }
   */
  const newLabelService = (onSuccessNewLabelCallback): void => {
    const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;

    formActionsHandler(FormActions.Save, {
      actionUniqueId: newLabelId,
      onSuccess: onSuccessNewLabelCallback,
    } as ExtraParamsInterface);
  };

  /**
   * onSuccessSubmitLabelsService
   * @param {Record<string, unknown>} toUpdateMailList
   * @param {MailLabelType[]} LabelsArray
   * @returns void
   */
  const onSuccessSubmitLabelsService = (
    toUpdateMailList: Record<string, unknown>,
    LabelsArray: MailLabelType[],
  ) => {
    //update actor data
    lodashMap(toUpdateMailList, docItem => {
      handleUpdateMailsData(docItem.docid, {
        labelsarray: JSON.stringify(LabelsArray),
      });
    });
    onSuccessServiceCallback();
  };

  /**
   * to submit labels to mail or mails
   * @function onSubmitLabelsService
   * @param  mailsData
   * @param { MailLabelType[] } LabelsArray
   * @returns { void  }
   */
  const onSubmitLabelsService = (mailsData, LabelsArray: MailLabelType[]): void => {
    actorDispatch(
      'runMailService',
      {
        successCallback: () => onSuccessSubmitLabelsService(mailsData, LabelsArray),
        params: {
          actionUniqueId: submitLabelsId,
          data: {
            params: {
              json: mailsData,
              LabelsArray,
            },
          },
        },
        failureCallback: failureCallback,
      },
      {
        disableDebounce: true,
      },
    );
  };

  /**
   * @function onSubmitLabels
   * @param { MailLabelType[] } LabelsArray
   * @returns { void }
   */
  const onSubmitLabels = (LabelsArray: MailLabelType[]): void => {
    if (!isEmptyObject(checkedMails)) {
      const toLabeledMailData = checkedMails?.map((mail: MailInterface) => ({
        doctype_id: mail.doctype_id,
        docid: mail.doc_id,
        refrences_id: mail.refrences_id,
      }));

      onSubmitLabelsService(toLabeledMailData, LabelsArray);
    }
  };

  /**
   * to compose new message or letter
   * @function onCompose
   * @param { number } selectedIndex
   */
  const onCompose = (selectedIndex: number) => {
    if (selectedIndex === 0) {
      //new message
      handleOpenNewMessageForm(defaultMailFormValue);
    } else if (selectedIndex === 1) {
      //InMail
      history.push(inMailLink);
    } else if (selectedIndex === 2) {
      //new letter - OutMail
      handleOpenQuickAccess(newLetterQuickAccessLink);
    }
  };

  /**
   * @function onChangeCheckedMails
   * @param { MailInterface } mail
   * @returns { void }
   */
  const onChangeCheckedMails = (mail: MailInterface): void => {
    if (checkedMails.includes(mail)) {
      setCheckedMails((prev: MailInterface[]) => {
        return prev.filter(
          (prevMail: MailInterface) => prevMail.uniqueid !== mail.uniqueid,
        );
      });
    } else {
      setCheckedMails((prev: MailInterface[]) => [...prev, mail]);
    }
  };

  /**
   * to check or unCheck all checkbox items
   * @function onHandleMasterCheckbox
   * @param { React.ChangeEvent<HTMLInputElement> } event
   * @returns { void }
   */
  const onHandleMasterCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (event.target.checked) {
      const allMailListData = actorGetActionValue('mailData')
        ?.data as MailInterface[];
      setCheckedMails(allMailListData);
    } else {
      setCheckedMails([]);
    }
  };

  /**
   * to send new message
   * @function newMessageService
   * @param { Record<string, unknown> } params
   * @param { boolean } SaveDraft
   * @param { Function } successCallback
   * @returns { void  }
   */
  const newMessageService = (
    messageServiceParams: MessageServiceParamsInterface,
  ): void => {
    const { params, SaveDraft, handleFailure, successCallback } =
      messageServiceParams;

    actorDispatch(
      'runMailService',
      {
        successCallback: successCallback,
        params: {
          actionUniqueId: newMessageId,
          data: {
            params: {
              ToPersonInfo_IDList: params['topersoninfo_id'],
              CCPersonInfo_IDList: params['ccpersoninfo_id'],
              BCCPersonInfo_IDList: params['bccpersoninfo_id'],
              Letters_ID: params['letters_id'],
              AttachFiles: params['attachfiles'],
              MessageSubject: params['subject'],
              MessageBody: params['messagebody'],
              DeadLineDate: params['deadlinedate'],
              isflag: params['isflag'] ?? false,
              FlagStartDate: params['flagstartdate'],
              FlagDueDate: params['flagduedate'],
              FlagReminderActive: params['flagreminderactive'] ?? false,
              RemindDateTime: params['reminddatetime'],
              Messages_Id: params?.['docId'],
              savedraft: +SaveDraft,
            },
          },
        },
        failureCallback: handleFailure,
      },
      {
        disableDebounce: true,
      },
    );
    getMailData(false);
  };

  /**
   * @function onSendNewMessage
   * @param { Function } successCallback
   * @param { boolean } isSaveAsDraft
   * @returns { void }
   */
  const onSendNewMessage = (
    successCallback: Function,
    isSaveAsDraft: boolean,
    failureCallback: () => void,
  ): void => {
    const currentResource = actorGetActionValue('resources')!.current;

    const formData = actorGetActionValue('formData', [
      currentResource.value,
      currentResource.type,
    ]) as Record<string, unknown>;

    newMessageService({
      params: formData,
      SaveDraft: isSaveAsDraft,
      handleFailure: failureCallback,
      successCallback: () => {
        successCallback();
        if (location.search.includes('outbox')) {
          getMailData(true);
        }
      },
    });
  };

  /**
   * handle fab click
   * @function handleFabOpen
   * @param {SavedFabData} fabData
   * @returns {void}
   */
  const handleFabOpen = (fabData: SavedFabData): void => {
    mailActionsHandler?.(MailActions.onOpenFabMessageForm, { fabData });
  };

  /**
   * @function handleRemove
   * @param {SavedFabData} fabData
   * @param {React.MouseEvent<HTMLElement>} event
   * @returns {void}
   */
  const handleFabRemove = (
    event: React.MouseEvent<HTMLElement>,
    fabData: SavedFabData,
  ): void => {
    mailActionsHandler?.(MailActions.onRemoveFabMessageForm, { fabData });
    setForceRefresh(!forceRefresh);
    event.stopPropagation();
  };

  /**
   * to recall
   * @function onRecallService
   * @param { unknown } data
   * @param { Function } successCallback
   * @param { Function } failureCallback
   * @returns { void  }
   */
  const onRecallService = (
    data: unknown,
    successCallback,
    failureCallback,
  ): void => {
    actorDispatch(
      'runMailService',
      {
        successCallback: successCallback,
        params: {
          actionUniqueId: recallServiceId,
          data: {
            params: {
              json: data,
            },
          },
        },
        failureCallback,
      },
      {
        disableDebounce: true,
      },
    );
  };

  /**
   * @function onRecallHandler
   * @param { MailInterface[] | null } mailsData
   * * @param { Function } successCallback
   * @returns { void }
   */
  const onRecallHandler = (
    mailsData: MailInterface[] | null,
    successCallback,
    failureCallback,
  ): void => {
    let data = mailsData;
    if (!mailsData) {
      data = checkedMails;
    }
    const toRecallMailList = data?.map((mail: MailInterface) => ({
      doctype_id: mail.doctype_id,
      docid: mail.doc_id,
      refrences_id: mail.refrences_id,
    }));
    onRecallService(toRecallMailList, successCallback, failureCallback);
  };

  /**
   * @function handleToggleMailFormFullScreen
   * @returns { void }
   */
  const handleToggleMailFormFullScreen = useCallback(() => {
    setIsMailFormFullScreen(prev => !prev);
  }, [setIsMailFormFullScreen]);

  /**
   * @function onSuccessNewFolderCallback
   * @returns { void }
   */
  const onSuccessNewFolderCallback = (): void => {
    handleCloseNewFolderForm();
    getUserFolders();
  };

  /**
   * @function onCreateNewFolder
   * @returns { void }
   */
  const onCreateNewFolder = (): void => {
    const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;

    formActionsHandler(FormActions.Save, {
      actionUniqueId: newFolderId,
      onSuccess: onSuccessNewFolderCallback,
    } as ExtraParamsInterface);
  };

  /**
   * onSuccessStartMailService
   * @param {number} docId
   * @param {boolean} isStarred
   * @returns void
   */
  const onSuccessMoveMailsToFolder = (docId: number, isStarred: boolean): void => {
    //update actor data
    onSuccessServiceCallback();
    getMailData(false);
  };

  /**
   * to move mails to folder
   * @function onMoveToFolderService
   * @param { Record<string, unknown> } params
   * @returns { void  }
   */
  const onMoveMailsToFolderService = (params: Record<string, unknown>): void => {
    actorDispatch(
      'runMailService',
      {
        successCallback: onSuccessMoveMailsToFolder,
        params: {
          actionUniqueId: onMoveToFolderServiceId,
          data: {
            params,
          },
        },
        failureCallback,
      },
      {
        disableDebounce: true,
      },
    );
  };

  /**
   * @function onDeleteFolderHandler
   * @param { number } Folders_ID
   * @param { Function } successCallback
   * @returns { void }
   */
  const onDeleteFolderHandler = (
    Folders_ID: number,
    successCallback: Function,
  ): void => {
    actorDispatch(
      'runMailService',
      {
        successCallback,
        params: {
          actionUniqueId: deleteFolderServiceId,
          data: {
            params: {
              Folders_ID,
            },
          },
        },
        failureCallback,
      },
      {
        disableDebounce: true,
      },
    );
  };

  /**
    this function sets draft mails on local storage
   * @function onSetLocalStorage
   * @returns { void }
   */
  const onSetLocalStorage = (): void => {
    const currentResource = actorGetActionValue('resources')!.current;
    const savedFabData: SavedFabData[] = localStorageGetItem('draftMails') ?? [];
    const formData = actorGetActionValue('formData', [
      currentResource.value,
      currentResource.type,
    ]) as Record<string, unknown>;

    const fabData = {
      formData: formData,
      fabId: uuidv4(),
    };

    savedFabData.push(
      formData.IsReplyForm
        ? { ...fabData, selectedDoc: actorGetActionValue('selectedDoc')! }
        : fabData,
    );
    localStorage.setItem('draftMails', JSON.stringify(savedFabData));
  };

  /**
   * this function sets draft mails on local storage
   * @function onRemoveFabMessage
   * @param {SavedFabData} fabData
   * @returns { void }
   */
  const onRemoveFabMessage = (fabData: SavedFabData): void => {
    const savedFabData: SavedFabData[] = localStorageGetItem('draftMails') ?? [];
    const newSavedFabData = savedFabData.filter(
      data => data.fabId !== fabData.fabId,
    );
    localStorage.setItem('draftMails', JSON.stringify(newSavedFabData));
  };

  /**
   * @function handleOpenFabMessageForm
   * @param {SavedFabData} fabData
   * @returns { void }
   */
  const handleOpenFabMessageForm = (fabData: SavedFabData): void => {
    const draftMails: SavedFabData[] = localStorageGetItem('draftMails') ?? [];
    draftMails.splice(
      draftMails.findIndex(item => item.fabId === fabData.fabId),
      1,
    );
    onSeFormDefaultValue(fabData.formData);
    if (fabData.formData.IsReplyForm) {
      fabData.selectedDoc && actorSetActionValue('selectedDoc', fabData.selectedDoc);
      setIsReferenceFormOpen(true);
    } else {
      setIsNewMessageFormOpen(true);
    }
    localStorage.setItem('draftMails', JSON.stringify(draftMails));
  };

  /**
   * @function onMoveMailsToFolderHandler
   * @returns { void }
   */
  const onMoveMailsToFolderHandler = (Folders_ID): void => {
    const mailList = checkedMails?.map((mail: MailInterface) => ({
      doctype_id: mail.doctype_id,
      docid: mail.doc_id,
      refrences_id: mail.refrences_id,
    }));

    const params = {
      Folders_ID,
      json: mailList,
    };

    onMoveMailsToFolderService(params);
  };

  /**
   * @async
   * @function mailActionsHandler
   * @param {string} type
   * @param {Record<string, unknown>} data
   * @returns {Promise<void>} promise of void
   */
  const mailActionsHandler = async (
    type: string,
    payload?: unknown,
  ): Promise<void> => {
    switch (type) {
      case MailActions.OnRefreshMailData:
        getMailData(true);
        getUserFolders();
        break;
      case MailActions.onRefreshMailDataDetail:
        onGetMailDetail(true, uniqueId, refrencesId);
        break;
      case MailActions.onSearchMailData:
        {
          getMailData(true);
        }

        break;
      case MailActions.onChangeCheckedMails:
        {
          const { mail } = payload as OnChangeCheckedMailsParams;
          onChangeCheckedMails(mail);
        }
        break;
      case MailActions.onHandleMasterCheckbox:
        {
          const { event } = payload as OnHandleMasterCheckboxParams;
          onHandleMasterCheckbox(event);
        }
        break;
      case MailActions.onSetLocalStorage:
        {
          onSetLocalStorage();
        }
        break;
      case MailActions.onChangePageHandler:
        {
          const { page } = payload as OnChangePageHandlerParams;
          onMailPageChangeHandler(page);
        }
        break;
      case MailActions.onStarMail:
        {
          const { mailsData, isStarred } = payload as OnStarMailParams;
          onStarMail(mailsData, isStarred);
        }
        break;

      case MailActions.onImportantMail:
        {
          const { mailsData, isImportant } = payload as OnImportantMailParams;
          onImportantMail(mailsData, isImportant);
        }
        break;
      case MailActions.onMarkUnRead:
        {
          const { mailsData, IsUnRead } = payload as OnMarkUnReadParams;
          onMarkUnRead(mailsData, IsUnRead);
        }
        break;
      case MailActions.onShowDetail:
        {
          const { mail, event } = payload as OnShowDetailParams;

          onShowDetailHandler(mail, event);
          getUserFolders();
        }
        break;

      case MailActions.onCompose:
        {
          const { selectedIndex } = payload as OnComposeParams;
          onCompose(selectedIndex);
        }
        break;

      case MailActions.onSubmitLabels:
        {
          const { LabelsArray } = payload as OnSubmitLabelsParams;
          onSubmitLabels(LabelsArray);
        }
        break;
      case MailActions.onCreateNewLabel:
        {
          const { onSuccessNewLabelCallback } = payload as OnCreateNewLabelParams;
          newLabelService(onSuccessNewLabelCallback);
        }
        break;
      case MailActions.onSendNewMessage:
        {
          const { successCallback, isSaveAsDraft, failureCallback } =
            payload as OnSendNewMessageParams;
          onSendNewMessage(successCallback, isSaveAsDraft, failureCallback);
        }
        break;

      case MailActions.onOpenForwardMessageForm:
        {
          const { formData, formTitle } = payload as OnForwardMessageParams;
          handleOpenNewMessageForm(formData);
        }

        break;
      case MailActions.onOpenFabMessageForm:
        {
          const { fabData, formTitle } = payload as OnOpenFabMessageFormParams;
          handleOpenFabMessageForm(fabData);
        }
        break;
      case MailActions.onRemoveFabMessageForm:
        {
          const { fabData } = payload as OnRemoveFabMessageParams;
          onRemoveFabMessage(fabData);
        }
        break;
      case MailActions.onToggleMailFormFullScreen:
        {
          handleToggleMailFormFullScreen();
        }
        break;
      case MailActions.onOpenReferenceForm:
        {
          const { formData, formTitle } = payload as OnOpenReferenceFormParams;
          referenceFormTitle = formTitle ?? '';
          !isEmptyObject(formData) && handleOpenReferenceForm(formData);
        }
        break;
      case MailActions.onReference:
        {
          onReferenceHandler();
        }
        break;
      case MailActions.onGetLabels:
        {
          const { successCallback } = payload as OnGetLabelsParams;
          getUserLabels(successCallback);
        }
        break;
      case MailActions.onGetFolders:
        {
          const { isForInbox, successCallback } = payload as OnGetFoldersParams;
          // @ts-ignore
          getUserFolders(isForInbox, successCallback);
        }
        break;

      case MailActions.onGetReferenceData:
        {
          const { id, IsChart, pagination, successCallback } =
            payload as OnGetReferenceDataParams;
          onGetReferenceDataHandler(id, IsChart, pagination, successCallback);
        }
        break;
      case MailActions.onRecall:
        {
          const { mailsData, successCallback, failureCallback } =
            payload as OnRecallParams;
          onRecallHandler(mailsData, successCallback, failureCallback);
        }
        break;
      case MailActions.onArchive:
        {
          const { mailsData } = payload as OnArchiveParams;
          onArchiveHandler(mailsData);
        }
        break;
      case MailActions.onUnArchive:
        {
          const { mailsData } = payload as OnArchiveParams;
          onUnArchiveHandler(mailsData);
        }
        break;
      case MailActions.onOpenNewFolderForm:
        {
          setIsNewFolderFormOpen(true);
        }
        break;
      case MailActions.onMoveToFolder:
        {
          const { Folders_ID } = payload as OnMoveToFolderParams;
          onMoveMailsToFolderHandler(Folders_ID);
        }
        break;
      case MailActions.onDeleteFolder:
        {
          const { Folders_ID, successCallback } = payload as OnDeleteFolderParams;
          onDeleteFolderHandler(Folders_ID, successCallback);
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    getUserFolders();

    actorOnDispatch('metaData', details => {
      if (details['automation/messages']) {
        setIsLoading(false);
      }
    });

    actorOnDispatch('refreshView', refreshResource => {
      if (
        location.pathname.includes('mail') &&
        refreshResource === 'RefreshMailbox'
      ) {
        getMailData(false);
        getUserFolders();
      }
    });
  }, []);

  // click again on active link
  useEffect(() => {
    if (lodashIsEqual(location, prevLocation)) {
      getMailData(true);
    }

    actorDispatch(
      'setDocumentTitle',
      {
        recordTitle: translate('mail.automation'),
        locale: locale,
      },
      { replaceAll: true },
    );
  }, [location]);

  useEffect(() => {
    const allMailListData = actorGetActionValue('mailData')?.data as MailInterface[];
    !isEmptyObject(checkedMails) && setCheckedMails([]);

    if (isEmpty(location.search)) {
      onRefreshMailDataPage();
      history.push('/mail?inbox');
      return;
    }

    // we came back from detail page so we do'nt need to make request
    if (
      !isEmpty(prevUniqueId) &&
      lodashIsEqual(mainSearchParameter, prevMainSearchParameter) &&
      !isEmptyObject(allMailListData)
    ) {
      actorDispatch('selectedMail', null, {
        replaceAll: true,
      });
      return;
    }

    const searchParam: string = removeQuestionSignFromParam(location.search);
    const FolderType = FolderTypeEnum[searchParam];
    const DocTypeId = DocTypeEnum[searchParam];
    const FolderId = getFolderId(searchParam);

    if (isEmpty(uniqueId && refrencesId)) {
      if (searchParam === 'archived') {
        onRefreshMailDataPage();
        docTypeId = null;
        folderType = null;
        folderId = null;
        isStarred = null;
        isImportant = null;
        isArchived = 1;
        isRead = null;
        getMailData(true);
        return;
      }
      if (searchParam === 'important') {
        onRefreshMailDataPage();
        docTypeId = null;
        folderType = null;
        folderId = null;
        isStarred = null;
        isImportant = 1;
        isArchived = null;
        isRead = null;
        getMailData(true);
        return;
      }
      if (searchParam === 'unread') {
        onRefreshMailDataPage();
        docTypeId = null;
        folderType = null;
        folderId = null;
        isStarred = null;
        isImportant = null;
        isArchived = null;
        isRead = 0;
        getMailData(true);
        return;
      }
      if (searchParam === 'starred') {
        onRefreshMailDataPage();
        docTypeId = null;
        folderType = null;
        folderId = null;
        isStarred = 1;
        isImportant = null;
        isArchived = null;
        isRead = null;
        getMailData(true);
        return;
      } else if (!isEmpty(DocTypeId)) {
        onRefreshMailDataPage();
        docTypeId = DocTypeId;
        folderType = null;
        folderId = null;
        isStarred = null;
        isImportant = null;
        isArchived = null;
        isRead = null;
        getMailData(true);
        return;
      } else if (!isEmpty(FolderType)) {
        onRefreshMailDataPage();
        docTypeId = null;
        isStarred = null;
        isImportant = null;
        isArchived = null;
        folderId = null;
        folderType = FolderType;
        isRead = null;
        getMailData(true);
        return;
      } else if (!isEmpty(FolderId)) {
        onRefreshMailDataPage();
        docTypeId = null;
        isStarred = null;
        isImportant = null;
        isArchived = null;
        folderType = null;
        folderId = FolderId;
        isRead = null;
        getMailData(true);
        return;
      }
    } else {
      //get mail detail
      onGetMailDetail(true, uniqueId, refrencesId);
    }
  }, [location.search]);

  if (isLoading) return <LoadingBox />;

  return (
    <MailSectionView
      mailActionsHandler={mailActionsHandler}
      checkedMails={checkedMails}
      isNewMessageFormOpen={isNewMessageFormOpen}
      isReferenceFormOpen={isReferenceFormOpen}
      isNewFolderFormOpen={isNewFolderFormOpen}
      handleCloseNewMessageForm={handleCloseNewMessageForm}
      handleCloseReferenceForm={handleCloseReferenceForm}
      handleCloseNewFolderForm={handleCloseNewFolderForm}
      isMailFormFullScreen={isMailFormFullScreen}
      referenceFormTitle={referenceFormTitle}
      onCreateNewFolder={onCreateNewFolder}
      handleFabOpen={handleFabOpen}
      handleFabRemove={handleFabRemove}
      draftMails={draftMails}
    />
  );
});

export default InboxSectionController;
