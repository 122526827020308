import { createRef, FC, MutableRefObject, useEffect } from 'react';
import { Avatar, makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Item } from 'react-contexify';
import classNames from 'classnames';
import momentJalaali from 'moment-jalaali';

import { CustomTheme } from '../../core/themeProvider';
import { isEmpty } from '../../helper/data-helper';
import { callQuickAccess } from '../../helper/QuickAccessHelper';
import { openNewTab } from '../../helper/QuickAccessHelper';
import { CONFIG_CALENDAR, getValue } from '../../core/configProvider';
import { isJalali } from '../../helper/CalendarMetaHelper';
import {
  NotificationItemInterface,
  NotificationItemPatternType,
} from '../../helper/Types';
import { apiUrl } from '../../core/dataProvider';
import { useOnScreen } from '../../hooks/useOnScreen';
import { showNotification } from '../../helper/general-function-helper';

interface NotificationPanelItemInterfaceProps {
  record: NotificationItemInterface;
  pattern: NotificationItemPatternType;
  itemIdRefs: MutableRefObject<(string | number)[]>;
}

const styles = makeStyles((theme: CustomTheme) => ({
  notifyItem: {
    padding: 0,
    '& .react-contexify__item__content': {
      padding: 0,
      margin: 0,
      borderRadius: 5,
      transition: 'all 200ms',
    },
    '&.react-contexify__item:hover > .react-contexify__item__content': {
      transition: 'all 200ms',
      backgroundColor: theme.palette.primary.appPrimaryLightBackgroundColor,
      color: 'unset',
    },
  },

  isSeen: {
    backgroundColor: theme.palette.primary.appNotificationItem,
  },

  notifytext: {
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.primary.appPrimaryTextColor,
  },
  seenNotifyText: {
    fontWeight: 'bold',
  },
  createdate: {
    color: theme.palette.primary.appSecondaryTextColor,
    paddingTop: 5,
  },

  notificationCard: {
    width: 300,
    display: 'flex',
    padding: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  notificationCardBody: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '100%',
    width: 1,
    alignItems: 'center',
  },

  notificationCardIcon: {
    transform: 'rotate(45deg)',
    color: theme.palette.primary.main,
  },
  avatar: {
    marginRight: 10,
  },
}));

const NotificationPanelItem: FC<NotificationPanelItemInterfaceProps> = props => {
  const { record, pattern, itemIdRefs } = props;
  const classes = styles();

  const { notifyText, link, isSeen, id, imageUrl } = pattern;
  const itemRef = createRef<HTMLDivElement>();
  const isVisited = useOnScreen(itemRef);

  useEffect(() => {
    if (isVisited && itemRef.current && !record[isSeen]) {
      itemIdRefs.current = [...itemIdRefs.current, itemRef.current.id];
    }
  }, [itemRef]);

  const currentCalendar: string = getValue(CONFIG_CALENDAR);
  momentJalaali.locale(isJalali(currentCalendar) ? 'fa' : 'en');

  /**
   * Call `openNewTab` if `link` exist.
   * @function handleClickOnNotificationItem
   * @returns {Promise<void>}
   */
  const handleClickOnNotificationItem = async (): Promise<void> => {
    try {
      if (!isEmpty(record[link])) {
        if (/([0-9]\.[0-9])/g.test(record[link])) {
          const url: string = await callQuickAccess(record[link]);
          openNewTab(url);
        } else {
          openNewTab(record[link]);
        }
      }
    } catch (error) {
      showNotification(error, 'warning');
    }
  };

  return (
    <Item
      className={classNames(
        classes.notifyItem,
        !record[isSeen] ? classes.isSeen : null,
      )}
      onClick={handleClickOnNotificationItem}
    >
      <div
        ref={itemRef}
        id={record[id]}
        className={classes.notificationCard}
        data-test-notification-item={`${record[id]}`}
        data-style-card-notify="cardNotify"
      >
        <div
          className={classes.notificationCardBody}
          data-style-row-notify="rowNotify"
        >
          <Avatar
            data-test="avatarChange"
            className={classNames(classes.avatar)}
            alt={'notification'}
            src={record[imageUrl] ? `${apiUrl}/${record[imageUrl]}` : ''}
            data-style-avatar-notify="avatarNotify"
          />
          <Typography
            variant="body1"
            className={classNames(
              classes.notifytext,
              !record[isSeen] ? classes.seenNotifyText : null,
            )}
            data-style-text-notify="textNotify"
          >
            {record[notifyText]}
          </Typography>
          {/* <Typography variant="caption" className={classes.createdate}>
            {!isEmpty(record[createDate]) &&
              (isJalali(currentCalendar)
                ? momentJalaali(record[createDate]).format(jalaliDateTimeFormat)
                : momentJalaali(record[createDate]).format(gregorianDateTimeFormat))}
          </Typography> */}
        </div>
        {/* <div className={classes.notificationCardIcon}>
          {!isEmpty(record[link]) && (
            <Icon data-test-notification-link-icon>link</Icon>
          )}
        </div> */}
      </div>
    </Item>
  );
};

export default NotificationPanelItem;
