import lodashIsEqual from 'lodash/isEqual';
import lodashFind from 'lodash/find';

import {
  ChatInterface,
  ChatTotalUnseenInterface,
  MessageInterface,
  SignalRMessageType,
} from '.';
import { isEmptyObject } from '../../helper/data-helper';
import { actorDispatch, actorGetActionValue } from '../../type/actor-setup';

export const contactsReportId = '6ed80f00-76b9-4365-a004-a601e580f71b';
export const chatsReportId = '5d466dd3-8cf2-4e22-b99b-f8e480f7846f';
export const userContentsReportId = '1f9b97cd-5180-48d6-9a43-8b32d3f92f88';
export const sendMessageReportId = 'aa774db4-8a78-469f-8786-811215482f84';
export const deleteMessageServiceId = 'c0e69d65-5155-4cc1-aa40-0ea58508f5b7';
export const editMessageServiceId = '80e931ef-8fdc-4f38-8402-071a6a665971';
export const searchInMessagesReportId = '59bbbd72-3c94-4296-b530-57273164d8e4';
export const fileUploadResource = 'General/Chat/filestream/fileurl';
export const seenMessageServiceId = 'e6bae5b0-9f41-4445-a4a9-268dd90fe3a1';

/**
 * set messagesData reaches end
 * by setting hasMore: false
 * @function setReachEndOfMessages
 * @returns { void }
 */
export const setReachEndOfMessages = (): void => {
  const currentMessagesData = actorGetActionValue('messagesData');
  actorDispatch('messagesData', { ...currentMessagesData, hasMore: false });
};

/**
 * to seen messages
 * @function handleSeenMessage
 * @param { Record<string, unknown> } params
 * @returns { void }
 */
export const handleSeenMessage = (params: Record<string, unknown>): void => {
  actorDispatch(
    'runChatService',
    {
      successCallback: () => {},
      params: {
        actionUniqueId: seenMessageServiceId,
        data: {
          params,
        },
      },
      failureCallback: () => {},
    },
    {
      disableDebounce: true,
    },
  );
};

/**
 * to determine how to insert contents
 * on messagesList view
 * @function handleInsertContentOnView
 * @param { MessageInterface[] }newData
 * @param { boolean } isUp
 * @returns { void }
 */
export const handleInsertContentOnView = (
  newData: MessageInterface[],
  isUp: boolean,
): void => {
  const currentMessagesData = actorGetActionValue('messagesData');
  if (currentMessagesData) {
    !isUp
      ? actorDispatch('messagesData', {
          ...currentMessagesData,
          hasMore: true,
          data: [...newData, ...currentMessagesData?.data],
        })
      : actorDispatch('messagesData', {
          ...currentMessagesData,
          hasMore: true,
          data: [...currentMessagesData?.data, ...newData],
        });
  }
};

/**
 * to create new chat
 * @function handleCreateNewChat
 * @param { ChatInterface } newChat
 * @returns { void }
 */
export const handleCreateNewChat = (newChat: ChatInterface): void => {
  const currentChatsData = actorGetActionValue('chatsData')!;
  actorDispatch(
    'chatsData',
    currentChatsData
      ? {
          ...currentChatsData,
          data: [newChat, ...currentChatsData.data],
        }
      : {
          hasMore: true,
          data: [newChat],
        },
  );
};

/**
 * to update existed chat
 * @function handleUpdateExistedChat
 * @param { ChatInterface } updatedChat
 * @returns { void }
 */
export const handleUpdateExistedChat = (updatedChat: ChatInterface): void => {
  const currentChatsData = actorGetActionValue('chatsData')!;
  actorDispatch('chatsData', {
    ...currentChatsData,
    data: currentChatsData.data.map((item: ChatInterface) =>
      lodashIsEqual(item.personinfo_id, updatedChat.personinfo_id)
        ? {
            ...item,
            chattext: updatedChat.chattext,
            chatdate: updatedChat.chatdate,
          }
        : item,
    ),
  });
};

/**
 * handle update unseen messages on sidebar
 * @function handleUpdateUnseenMessages
 * @param { ChatTotalUnseenInterface } newMessage
 * @returns { void }
 */
export const handleUpdateUnseenMessages = (
  newMessage: ChatTotalUnseenInterface,
): void => {
  const currentSelectedUser = actorGetActionValue('selectedUser')!;
  const currentChatsData = actorGetActionValue('chatsData')!;

  /**
   * we don't want to update unseen messages of active chat on sidebar
   */
  if (
    !lodashIsEqual(currentSelectedUser?.personinfo_id, newMessage.otherpersoninfo_id)
  ) {
    actorDispatch('chatsData', {
      ...currentChatsData,
      data: currentChatsData.data.map((item: ChatInterface) =>
        lodashIsEqual(item.personinfo_id, newMessage.otherpersoninfo_id)
          ? {
              ...item,
              sumnotseen: newMessage?.sumnotseen,
            }
          : item,
      ),
    });
    /**
     * but we should  update unseen messages of active chat on sidebar if it has more than 0 unseen messages
     */
  } else {
    if (currentSelectedUser?.sumnotseen > 0) {
      actorDispatch('chatsData', {
        ...currentChatsData,
        data: currentChatsData.data.map((item: ChatInterface) =>
          lodashIsEqual(item.personinfo_id, newMessage.otherpersoninfo_id)
            ? {
                ...item,
                sumnotseen: newMessage?.sumnotseen,
              }
            : item,
        ),
      });

      actorDispatch(
        'selectedUser',
        currentSelectedUser
          ? {
              ...currentSelectedUser,
              sumnotseen: newMessage?.sumnotseen,
            }
          : null,
      );
    }
  }
};

/**
 * @function handleAfterSuccessSendMessage
 * @param { boolean } shouldNotScrollDown
 * @returns { void }
 */
export const handleAfterSuccessSendMessage = (
  shouldNotScrollDown: boolean,
): void => {
  actorDispatch('chatText', '');
  !shouldNotScrollDown && actorDispatch('isNewContentSent', true);

  actorDispatch('selectedMessageData', null, {
    replaceAll: true,
  });

  actorDispatch('uploadedFiles', null, {
    replaceAll: true,
  });
};

/**
 * to insert new message
 * in messageData
 * @function handleCreateNewMessage
 * @param { MessageInterface } newChat
 * @returns { void }
 */
export const handleCreateNewMessage = (newMessage: MessageInterface): void => {
  const currentMessagesData = actorGetActionValue('messagesData')!;
  actorDispatch('messagesData', {
    ...currentMessagesData,
    data: [newMessage, ...currentMessagesData?.data],
  });
};

/**
 * to update messagesData when
 * we edit a specific message
 * @function handleEditMessage
 * @param { MessageInterface } updatedMessage
 * @returns { void }
 */
export const handleEditMessage = (updatedMessage: MessageInterface): void => {
  const currentMessagesData = actorGetActionValue('messagesData')!;
  actorDispatch('messagesData', {
    ...currentMessagesData,
    data: currentMessagesData.data.map((item: MessageInterface) =>
      item.chat_id === updatedMessage.chat_id
        ? {
            ...item,
            chattext: updatedMessage?.chattext,
            fileurl: updatedMessage?.fileurl,
            isedited: true,
          }
        : item,
    ),
  });
};

/**
 * to update messagesData when
 * @function handleUpdateMessage
 * @param { MessageInterface } updatedMessage
 * @returns { void }
 */
export const handleUpdateMessage = (updatedMessage: MessageInterface): void => {
  const currentMessagesData = actorGetActionValue('messagesData')!;
  currentMessagesData &&
    actorDispatch('messagesData', {
      ...currentMessagesData,
      data: currentMessagesData.data.map((item: MessageInterface) =>
        item.chat_id === updatedMessage.chat_id ? updatedMessage : item,
      ),
    });
};

/**
 * to update messagesData when
 * we delete a specific message
 * @function handleِDeleteMessage
 * @param { MessageInterface } toBeDeletedMessage
 * @returns { void }
 */
export const handleِDeleteMessage = (toBeDeletedMessage: MessageInterface): void => {
  const currentMessagesData = actorGetActionValue('messagesData')!;
  actorDispatch('messagesData', {
    ...currentMessagesData,
    data: currentMessagesData?.data?.filter(
      (messageItem: MessageInterface) =>
        messageItem.chat_id !== toBeDeletedMessage.chat_id,
    ),
  });
};

/**
 * @function handleUpdateSignalRChat
 * @param { MessageInterface } signalRMessage
 * @returns { void }
 */
export const handleUpdateSignalRChat = (signalRMessage: MessageInterface): void => {
  const currentChatsData = actorGetActionValue('chatsData')!;
  const newChat = {
    chatdate: signalRMessage.chatdate,
    chattext: signalRMessage.chattext,
    personimage: signalRMessage?.personimage,
    personinfo_id: signalRMessage.frompersoninfo_id,
    personname: signalRMessage?.personname,
    sumnotseen: 1,
  } as ChatInterface;

  const existedChat = lodashFind(currentChatsData.data, [
    'personinfo_id',
    newChat.personinfo_id,
  ]);
  if (existedChat && !isEmptyObject(existedChat)) {
    handleUpdateExistedChat(newChat);
  } else {
    handleCreateNewChat(newChat);
  }
};

/**
 * @function handleInsertNewSignalRMessage
 * @param { MessageInterface } signalRMessage
 * @returns { void }
 */
export const handleInsertNewSignalRMessage = (
  signalRMessage: MessageInterface,
): void => {
  const currentSelectedUser = actorGetActionValue('selectedUser')!;

  if (currentSelectedUser) {
    if (
      lodashIsEqual(
        currentSelectedUser.personinfo_id,
        signalRMessage.frompersoninfo_id,
      )
    ) {
      // insert new message to this active chat
      handleCreateNewMessage(signalRMessage);
      // seen that message
      handleSeenMessage({
        otherpersoninfo_id: signalRMessage?.frompersoninfo_id,
        FromChatID: signalRMessage?.chat_id,
        ToChatID: signalRMessage?.chat_id,
      });
    } else {
      // update sidebar chats
      !signalRMessage?.isfrommyself && handleUpdateSignalRChat(signalRMessage);
    }
  } else {
    // update sidebar chats
    !signalRMessage?.isfrommyself && handleUpdateSignalRChat(signalRMessage);
  }
};

/**
 * @function getSignalRMessageType
 * @param { MessageInterface } signalRMessage
 * @returns { SignalRMessageType }
 */
export const getSignalRMessageType = (
  signalRMessage: MessageInterface,
): SignalRMessageType => {
  if (signalRMessage.isdeleted) {
    return 'DELETE';
  } else if (signalRMessage.isedited || signalRMessage.isseen) {
    return 'UPDATE';
  } else {
    return 'INSERT';
  }
};
