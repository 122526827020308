import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../../core/themeProvider';
import { alpha } from '@material-ui/core';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  userInfoRoot: {
    fontSize: 14,
    paddingLeft: 16,
    width: 'calc(100% - 40px)',
  },
  avatar: {
    width: 40,
    height: 40,
  },
  avatarRoot: {
    minWidth: 0,
    position: 'relative',
  },
  userStatus: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    zIndex: 1,
    width: 14,
    height: 14,
    borderRadius: '50%',
    border: `1px solid white`,
  },
  listItemRoot: {
    display: 'flex',
    paddingLeft: 24,
    paddingRight: 24,
    cursor: 'pointer',
    transition: 'all .2s ease',
    transform: 'scale(1)',
    '&.active': {
      backgroundColor: alpha(theme.palette.primary.main, 0.07),
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      transform: 'translateY(-2px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.primary.main, 0.2)}`,
    },
  },
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
