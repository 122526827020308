import { CircularProgress, TextField } from '@material-ui/core';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Autocomplete } from '@material-ui/lab';
import { FC, Fragment, memo, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker2-samian';
import { useStyles } from '../../grid.style';
import { GridDropDownInputViewInterface } from './grid-dropdown-input.type';
import { useTranslate } from 'react-admin';

const GridDropDownInputView: FC<GridDropDownInputViewInterface> = props => {
  const {
    handleChangeValue,
    toggleAutoComplete,
    isOpen,
    items,
    loading,
    handleChangeInput,
    dropdownValue,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();
  return (
    <div className={classes.datePickerContainer}>
      <Autocomplete
        filterOptions={options => options}
        disableListWrap
        open={isOpen}
        onOpen={toggleAutoComplete}
        onClose={toggleAutoComplete}
        onChange={handleChangeValue}
        value={dropdownValue}
        // isOptionEqualToValue={(option) => option.label === dropdownValue.label}
        getOptionLabel={option => option?.label as string}
        options={items}
        loading={loading}
        loadingText={translate('dropdown.loading')}
        noOptionsText={translate('dropdown.noOptionsMessage')}
        renderInput={params => (
          <TextField
            onChange={handleChangeInput}
            // value={inputValue}
            {...params}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                <Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default memo(GridDropDownInputView);
