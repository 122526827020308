import React, { FC } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { translate } from 'react-admin';

import { isEmpty } from '../../helper/data-helper';
import { insertTaskAction } from '../../redux/todoList/action';
import TodoAddNewTaskView from './TodoAddNewTaskView';
import { TodoTaskPatternType, TodoListPatternType } from '../../helper/Types';
import { createDateWithType } from '../../helper/DateHelper';

interface TodoAddNewTaskPropsInterface {
  translate: Function;
  resource: string;
  insertTask: Function;
  listId: number;
  todoListPattern: TodoListPatternType;
  todoTaskPattern: TodoTaskPatternType;
  parentResource: string;
  todoListSelectedFilter: string;
  largestRowOrder: number;
}

const TodoAddNewTask: FC<TodoAddNewTaskPropsInterface> = props => {
  const {
    translate,
    resource,
    insertTask,
    listId,
    todoListPattern,
    todoTaskPattern,
    parentResource,
    largestRowOrder,
    todoListSelectedFilter,
  } = props;

  const createNewTask = inputValue => {
    if (isEmpty(inputValue)) return;
    const { counter: patternCounter } = todoListPattern;
    const { title, parentId, rowOrder, dueDate } = todoTaskPattern;
    const data = {
      [title]: inputValue,
      [parentId]: listId,
      [rowOrder]: largestRowOrder + 1,
    };
    if (todoListSelectedFilter) {
      if (todoListSelectedFilter.toLowerCase() === dueDate) {
        Object.assign(data, {
          [todoListSelectedFilter]: createDateWithType('today'),
        });
      } else {
        Object.assign(data, { [todoListSelectedFilter]: true });
      }
    }
    insertTask(resource, data, {
      parentResource,
      listId,
      parentId,
      patternCounter,
    });
  };

  return (
    <TodoAddNewTaskView
      placeholder={translate('todo.newTask')}
      submit={createNewTask}
    />
  );
};

const mapDispatchToProps = {
  insertTask: insertTaskAction,
};

export default compose(connect(null, mapDispatchToProps), translate)(TodoAddNewTask);
