import { ReactElement } from 'react';
import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';

import { LoadingBoxViewInterface } from './loading-box.type';
import { useStyles } from './loading-box.style';

const LoadingBoxView = (props: LoadingBoxViewInterface): ReactElement => {
  const { absolute = false, size = 50, color = 'secondary', style } = props;
  const classes = useStyles(props);

  return (
    <div
      className={classNames(
        classes.circularProgress,
        absolute ? classes.absolute : null,
      )}
      style={style}
    >
      <CircularProgress color={color} size={size} />
    </div>
  );
};

export default LoadingBoxView;
