import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import { push } from 'connected-react-router';

import LoadingBox from '../component/LoadingBox';
import CategoryListView from '../component/category/CategoryListView';
import { getCategoryPatternInfo } from '../helper/PatternMetaHelper';
import ListContainerApiController from '../container/ListContainerApiController';
import { isEmptyObject } from '../helper/data-helper';
import useWidth from '../component/useWidth';
import GridListTileView from '../component/category/GridListTileView';
import CheckResourceReady from '../container/CheckResourceReady';
import { SELLIN_LIST } from '../core/configRouteConstant';

const CategoryPage = props => {
  const { resource, getSubCategoryData, isReady, redirectToPage, ...rest } = props;
  const width = useWidth();
  const [filterValue, setFilterValue] = useState(null);
  if (!isReady) {
    return <LoadingBox />;
  }

  const idParent = lodashGet(props, 'match.params.id');
  const patternData = getCategoryPatternInfo(resource);

  const getSubCategory = id => {
    setFilterValue({ [patternData.idParent]: id });
  };

  const handleItemClick = item => {
    const { id, hasChild, targetResource, targetResourceParentId } = patternData;
    if (item[hasChild]) {
      getSubCategory(item[id]);
    } else {
      const url = `/${SELLIN_LIST}/${targetResource}?filter={"${targetResourceParentId}":"${item[id]}"}`;
      redirectToPage(url);
    }
  };

  return (
    <CheckResourceReady resourceName={resource}>
      <ListContainerApiController
        {...rest}
        basePath={`/${resource}`}
        resource={resource}
        perPage={1000000}
        sort={{ field: patternData.priority }}
        filter={filterValue}
      >
        {({ isLoading, data, ...controllerProps }) => {
          if (!isLoading && !isEmptyObject(data)) {
            if (width === 'xs') {
              return (
                <GridListTileView
                  {...rest}
                  {...controllerProps}
                  data={data}
                  isLoading={isLoading}
                  idParent={idParent}
                  pattern={patternData}
                  onClick={handleItemClick}
                />
              );
            }
            return (
              <CategoryListView
                {...rest}
                {...controllerProps}
                data={data}
                isLoading={isLoading}
                idParent={idParent}
                patternData={patternData}
              />
            );
          }

          return <LoadingBox />;
        }}
      </ListContainerApiController>
    </CheckResourceReady>
  );
};

CategoryPage.propTypes = {
  resource: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { match }) => {
  const { moduleName, moduleTableName } = match.params;
  const resource = `${moduleName}/${moduleTableName}`;

  return {
    resource,
    isReady: !!state.admin.resources[resource],
  };
};

const mapDispatchToProps = {
  redirectToPage: push,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage);
