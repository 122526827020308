import { formatNumber } from '../../../helper/NumberHelper';
/**
 * this function convert string to float
 *@function convertToFloat
 *@param {string | null} str
 *@return {number}
 */
export const convertToFloat = (str: string | null): number =>
  parseFloat(str?.replaceAll(',', '') ?? '');

/**
 * this function convert negative number to Parentheses and check format value
 *@function wrapNegativeNumberWithParentheses
 *@param {string | number} value
 *@param {boolean} shouldFormatted
 *@return {string | number}
 */
export const wrapNegativeNumberWithParentheses = (
  value: number | string,
  shouldFormatted = false,
): string | number => {
  if (value == null) {
    return '';
  }

  if (Number.isNaN(+value)) {
    return value;
  }

  let formattedValue: string | number = Math.abs(+value);
  formattedValue = shouldFormatted ? formatNumber(formattedValue) : formattedValue;

  return Number(value) >= 0 ? formattedValue : `(${formattedValue})`;
};
