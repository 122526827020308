import { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocale } from 'react-admin';
import { Moment } from 'moment';
import moment from 'moment-jalaali';
import Cleave from 'cleave.js/react';

import {
  SERVER_DATE_FORMAT,
  SERVER_DATE_TIME_FORMAT,
} from '../../../core/configProvider';
import { getCalendarType, getInputFormat } from '../../../helper/DateHelper';
import {
  ChangeFormValueParams,
  FormActions,
  OnBlurParams,
} from '../../form/form.type';
import {
  CleaveRawValueLength,
  JalaliDateInputInterface,
} from './jalali-date-input.type';
import JalaliDateInputView from './jalali-date-input.view';
import JalaliDateInputWMSView from './jalali-date-input-wms.view';
import lodashGet from 'lodash/get';
import { gregorianDateFormat } from '../../../helper/CalendarMetaHelper';
import {
  convertJalaliToGregorian,
  formatInvalidDateForNotLeapYear,
  getDatePickerType,
  isInvalidDateForNotLeapYear,
  isLeapYear,
} from './jalali-date-input.helper';
import JalaliDateInputGridView from './jalali-date-input-grid.view';
import lodashDebounce from 'lodash/debounce';

const JalaliDateInputController: FC<JalaliDateInputInterface> = memo(props => {
  const {
    value,
    formActionsHandler,
    inputMessage,
    DateInputInPuzzleForm,
    DateInputInGrid,
    resource,
    field,
    label,
    hint,
    getRef,
    disabled,
    visibleClass,
    options,
  } = props;
  const locale = useLocale();

  const { name, required, fixCalendar } = field;
  const calendarConfig = getCalendarType(fixCalendar);
  const inputFormat = getInputFormat(fixCalendar);
  const datePickerType = getDatePickerType(field);

  const simpleType = lodashGet(field, 'dataType.simple');
  const formatDate =
    simpleType === 'datetime' ? SERVER_DATE_TIME_FORMAT : SERVER_DATE_FORMAT;
  const datePickerRef = useRef<any>(null);

  useEffect(() => {
    if (typeof getRef === 'function' && datePickerRef?.current != null) {
      getRef(datePickerRef.current);

      // FIXME: Use `DataPicker` types, in this time I couldn't find it

      // (datePickerRef.current!['input'] as HTMLInputElement).onblur = handleBlur;
      (datePickerRef.current!['input'] as HTMLInputElement).onfocus = handleFocus;
    }
  }, [value]);

  /**
   * value for date picker
   */
  const internalValue = useMemo(() => {
    if (!value) {
      return null;
    }
    if (typeof value !== 'object') {
      return moment(value, formatDate);
    } else {
      const checkDate = new Date(value);
      if (moment.isDate(checkDate)) {
        return value;
      }
    }
    return moment(value, formatDate);
  }, [value]);

  /**
   * value for mask input
   */
  const maskInputValue = useMemo(() => {
    if (!value) {
      return '';
    }

    if (locale === 'fa' && calendarConfig !== 'gregorian') {
      return moment(value).format('jYYYY/jMM/jDD HH:mm');
    }
    return moment(value).format('YYYY/MM/DD HH:mm');
  }, [value]);

  /**
   *check date by formatDate form and  handle input change
   * @function handleChange
   * @param {Moment} changedDate
   * @returns {void}
   */
  const handleChange = (changedDate: Moment): void => {
    const newValue = changedDate
      ? changedDate.locale('en').format(formatDate)
      : null;

    if (newValue === value) {
      return;
    }

    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value: newValue,
    } as ChangeFormValueParams);

    setTimeout(() => {
      handleBlur(newValue);
    }, 0);
  };

  /**
   * @function handleInputMaskChange
   * @param { React.ChangeEvent<typeof Cleave> } event
   * @returns { void }
   */
  const handleInputMaskChange = (event: React.ChangeEvent<typeof Cleave>): void => {
    const { rawValue, value } = event.target;
    const year = +value.split('/')[0];

    /**
     * when we have complete date
     */
    if (rawValue?.length === CleaveRawValueLength[datePickerType]) {
      if (calendarConfig !== 'gregorian') {
        if (!isLeapYear(year) && isInvalidDateForNotLeapYear(value)) {
          formActionsHandler(FormActions.InputChange, {
            fieldName: name,
            value: convertJalaliToGregorian(
              formatInvalidDateForNotLeapYear(value),
              datePickerType,
            ),
          } as ChangeFormValueParams);
        } else {
          formActionsHandler(FormActions.InputChange, {
            fieldName: name,
            value: convertJalaliToGregorian(value, datePickerType),
          } as ChangeFormValueParams);
        }
      } else {
        formActionsHandler(FormActions.InputChange, {
          fieldName: name,
          value: moment(value).format(gregorianDateFormat),
        } as ChangeFormValueParams);
      }
    }
    if (year == 0) {
      formActionsHandler(FormActions.InputChange, {
        fieldName: name,
        value: '',
      } as ChangeFormValueParams);
    }
  };

  /**
   * for dateTime field Only
   * when user forget to type time
   * and just type date
   * on blur we automatically
   * set 00:00 time
   *
   * @function handleInputMaskBlur
   * @param { React.ChangeEvent<typeof Cleave> } event
   * @returns { void }
   */
  const handleInputMaskBlur = (event: React.ChangeEvent<typeof Cleave>) => {
    if (datePickerType === 'dateTime') {
      const { rawValue, value } = event.target;
      const year = +value.split('/')[0];

      const newValue = `${value} 00:00`;

      if (rawValue.length === 8) {
        if (locale !== 'en') {
          if (!isLeapYear(year) && isInvalidDateForNotLeapYear(value)) {
            formActionsHandler(FormActions.InputChange, {
              fieldName: name,
              value: convertJalaliToGregorian(
                formatInvalidDateForNotLeapYear(newValue),
                datePickerType,
              ),
            } as ChangeFormValueParams);
          } else {
            formActionsHandler(FormActions.InputChange, {
              fieldName: name,
              value: convertJalaliToGregorian(newValue, datePickerType),
            } as ChangeFormValueParams);
          }
        } else {
          formActionsHandler(FormActions.InputChange, {
            fieldName: name,
            value: moment(newValue).format(gregorianDateFormat),
          } as ChangeFormValueParams);
        }
      }
    }
  };

  /**
   * Handle Blur event
   * @function handleBlur
   * @returns {void} void
   */
  const handleBlur = lodashDebounce((inputValue: string) => {
    if (datePickerRef?.current == null) return;

    // FIXME: Use `DataPicker` types, in this time I couldn't find it
    if (datePickerRef.current['state']['isOpen']) {
      return;
    }

    formActionsHandler(FormActions.InputBlur, {
      fieldName: name,
      value: inputValue,
    } as OnBlurParams);
  }, 100);

  /**
   * Handle focus event
   * @function handleFocus
   * @returns {void} void
   */
  const handleFocus = (): void => {
    (datePickerRef.current!['setOpen'] as (...args) => void)(true);
    formActionsHandler(FormActions.InputFocus, {
      fieldName: name,
      value,
    } as ChangeFormValueParams);
  };

  /**
   * it will set null as input value
   * @function clearInput
   * @returns {void} void
   */
  const clearInput = () => {
    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value: null,
    } as ChangeFormValueParams);
  };

  const ViewComponent = DateInputInGrid
    ? JalaliDateInputGridView
    : DateInputInPuzzleForm
    ? JalaliDateInputWMSView
    : JalaliDateInputView;

  return (
    <ViewComponent
      visibleClass={visibleClass}
      resource={resource}
      inputMessage={inputMessage}
      disabled={disabled}
      DateInputInPuzzleForm={DateInputInPuzzleForm}
      label={label}
      hint={hint}
      required={required}
      name={name}
      inputFormat={inputFormat}
      handleChange={handleChange}
      handleInputMaskChange={handleInputMaskChange}
      handleInputMaskBlur={handleInputMaskBlur}
      internalValue={internalValue}
      maskInputValue={maskInputValue}
      calendarConfig={calendarConfig}
      getRef={getRef}
      datePickerRef={datePickerRef}
      options={options}
      onFocus={handleFocus}
      // onBlur={handleBlur}
      clearInput={clearInput}
      simpleType={simpleType}
      field={field}
      datePickerType={datePickerType}
    />
  );
});

export default JalaliDateInputController;
