import React, { FC, useEffect, useState } from 'react';
import lodashGet from 'lodash/get';
import { GET_LIST } from 'react-admin';

import CreateEditRecordPage from './CreateEditRecordPage';
import dataProvider from '../core/dataProvider';
import LoadingBox from '../component/LoadingBox';
import CheckResourceReady from '../container/CheckResourceReady';
import MetaProvider from '../container/MetaProvider';
import { actorDispatch } from '../type/actor-setup';

interface SingleRecordPageInterface {
  match: {
    params: {
      moduleName: string;
      moduleTableName: string;
    };
  };
}

const SingleRecordPage: FC<SingleRecordPageInterface> = props => {
  const { match } = props;
  const { moduleName, moduleTableName } = match.params;

  const resource = `${moduleName}/${moduleTableName}`;
  const [id, setId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * get record from API and check record id.
   * @returns {void}
   */
  const getRecord = async () => {
    const response = await dataProvider(GET_LIST, resource, {
      sort: { field: 'id', order: 'DESC' },
      pagination: { page: 1, perPage: 10 }, // only change pagination
    });

    const resId = lodashGet(response, 'data.0.id', null);
    actorDispatch('urlInfo', {
      params: { ...match.params, id: resId },
    });
    setIsLoading(false);
    setId(resId);
  };

  useEffect(() => {
    getRecord();
  }, []);

  if (isLoading) {
    return <LoadingBox />;
  }

  return (
    <MetaProvider resourceName={resource}>
      <CheckResourceReady resourceName={resource}>
        <CreateEditRecordPage {...props} resource={resource} id={id} />
      </CheckResourceReady>
    </MetaProvider>
  );
};

export default SingleRecordPage;
