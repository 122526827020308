import { MessagesDataInterface } from './chat-content/messages-screen';

export type ChatActionsHandler = (type: string, payload?: unknown) => void;

export enum ChatActions {
  onSendContent = 'SEND_CONTENT',
  onSendMessage = 'SEND_MESSAGE',
  onSendFile = 'SEND_FILE',
  onRefreshContacts = 'REFRESH_CONTACTS',
  onRefreshChats = 'REFRESH_CHATS',
  onfetchMoreContacts = 'FETCH_MORE_CONTACTS',
  onfetchMoreChats = 'FETCH_MORE_CHATS',
  onfetchMoreMessages = 'FETCH_MORE_MESSAGES',
  onSelectUser = 'SELECT_USER',
  onMoveLast = 'MOVE_LAST',
  onMoveToRepliedMessage = 'MOVE_TO_REPLIED_MESSAGE',
  onDeleteMessage = 'DELETE_MESSAGE',
}

export interface UploadedFile {
  filePath: string;
  realFileName: string;
}

export type CurrentUser = {
  currentUserID: number;
  displayName: string;
  email?: string;
  photoURL?: string;
};

export interface ContactInterface {
  personimage: string;
  personinfo_id: number;
  personname: string;
  sumnotseen: number;
}
export interface ChatInterface {
  chatdate: string;
  chattext: string;
  personimage: string;
  personinfo_id: number;
  personname: string;
  sumnotseen: number;
}

export type SelectedUserType = ContactInterface | ChatInterface;

export type SignalRMessageType = 'INSERT' | 'UPDATE' | 'DELETE';

export interface MessageInterface {
  chat_id: number;
  frompersoninfo_id: number;
  topersoninfo_id: number;
  replyofchat_id?: number;
  replypersonname: string;
  replypersonimage: string;
  replychattext: string | null;
  replyfileurl: string | null;
  tscode: number;
  isdeleted: boolean;
  isedited: boolean;
  isseen: boolean;
  isfrommyself: number;
  chatdate: string;
  fileurl: string | null;
  chattext: string;
  personname: string;
  personimage: string;
}

export interface SignalRMessageInterface extends MessageInterface {
  changetype: 'insert' | 'update' | 'delete';
}

export interface ChatTotalUnseenInterface {
  otherpersoninfo_id: number;
  sumnotseen: number;
}

export interface GetChatEventValueInterface {
  connectionUrl: string;
  signalREvent: string;
}

export interface ChatSectionInterface {
  signalRMessage: SignalRMessageInterface | null;
}

export interface ChatSectionViewInterface {
  chatActionsHandler: ChatActionsHandler;
  currentUser: CurrentUser;
  isFileUploadDialogOpen: boolean;
  handleCloseFileUploadDialog: () => void;
  handlePasteFromClipboard: (
    event: React.ClipboardEvent<HTMLTextAreaElement>,
  ) => void;
}

export interface OnSendContentParams {
  chattext: string;
  fileurl: string;
}

export interface OnSendFileParams {
  event: React.ChangeEvent<HTMLInputElement>;
}

export interface OnSelectUserParams {
  user: SelectedUserType;
}

export interface OnFetchMoreMessagesParams {
  IsUp: boolean;
}

export interface OnMoveLastParams {
  successCallback: (response: MessagesDataInterface) => void;
}

export interface OnMoveToRepliedMessageParams {
  chatId: number;
  successCallback: (response: MessagesDataInterface) => void;
}

export interface OnDeleteMessageParams {
  params: {
    OtherPersonInfo_ID: number;
    ChatID: number;
  };
  successCallback: () => void;
}

export type GetUserContents = (
  userId: number,
  chatdate?: string | null,
  IsUp?: number | boolean, //fixme: whats the meaning of number or boolean ? 😑
  MoveLast?: number,
  ChatID?: number | null,
  customSuccessCallback?: any,
) => void;
