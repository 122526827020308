import { FC, memo } from 'react';
import { UserListInterface } from './user-list.type';
import UserListView from './user-list.view';

const UserListController: FC<UserListInterface> = memo(props => {
  const {
    data,
    selectedUser,
    loading,
    fetchMore,
    refreshFunction,
    hasMore,
    isChatMode,
    onUserSelect,
  } = props;

  return (
    <UserListView
      data={data}
      fetchMore={fetchMore}
      refreshFunction={refreshFunction}
      hasMore={hasMore}
      selectedUser={selectedUser}
      loading={loading}
      isChatMode={isChatMode}
      onUserSelect={onUserSelect}
    />
  );
});

export default UserListController;
