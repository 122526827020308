import React, { FC, useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import compose from 'recompose/compose';
import {
  makeStyles,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Icon,
} from '@material-ui/core';
import { connect } from 'react-redux';

import placeholder from '../../images/placeholder.png';
import ShopAddToCartButtonCounter from './ShopAddToCartButtonCounter';
import { getValue, API_URL } from '../../core/configProvider';
import { CustomTheme } from '../../core/themeProvider';
import { SellinBarcodePatternType } from '../../helper/Types';

interface BarcodeListItemViewInterfaceProps {
  pattern: SellinBarcodePatternType;
  onAddToCart: Function;
  onRemoveFromCart: Function;
  count: number;
  basketInfo: object;
  record: object;
  parentRecord: object;
}

const styles = makeStyles((theme: CustomTheme) => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
    padding: '10px 0',
  },

  cardMedia: {
    width: '100%',
    backgroundImage: `url(${placeholder})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    display: 'flex',
    flexBasis: '30%',
  },

  cardContent: {
    flexGrow: 1,
    alignContent: 'flex-end',
    padding: '0 10px !important',
    display: 'flex',
    flexDirection: 'column',
    height: 100,
  },

  productName: {
    flexGrow: 1,
  },

  addToCartButton: {
    padding: '7px 16px',
    alignSelf: 'flex-end',
    color: theme.palette.primary.appSecondaryTextColor,
  },

  shopAddToCartButtonCounter: {
    alignSelf: 'flex-end',
  },
}));

const BarcodeListItemView: FC<BarcodeListItemViewInterfaceProps> = props => {
  const {
    record,
    pattern,
    onAddToCart,
    onRemoveFromCart,
    count,
    basketInfo = {},
    parentRecord,
  } = props;
  const { id, name, imagePath, imageFileName, barcodeId } = pattern;
  const classes = styles();
  const translate = useTranslate();
  const [counter, setCounter] = useState(count);

  useEffect(() => {
    setCounter(count);
  }, [count]);

  const apiUrl: string = getValue(API_URL);

  const addToCart = () => {
    const tempRecord = { ...parentRecord, [barcodeId]: record[id] };
    setCounter(counter + 1);
    if (typeof onAddToCart === 'function') {
      onAddToCart(tempRecord, counter + 1);
    }
  };

  const inputType = event => {
    const tempRecord = { ...parentRecord, [barcodeId]: record[id] };
    const parsedValue = parseFloat(event.target.value);
    const changedValue = isNaN(parsedValue) || parsedValue <= 1 ? 1 : parsedValue;
    if (typeof onAddToCart === 'function') {
      onAddToCart(tempRecord, changedValue);
    }
  };

  const removeOfCart = () => {
    if (counter === 1) {
      setCounter(counter - 1);
      if (typeof onRemoveFromCart === 'function') {
        onRemoveFromCart(basketInfo[id]);
      }
    } else {
      const tempRecord = { ...parentRecord, [barcodeId]: record[id] };
      setCounter(counter - 1);
      if (typeof onAddToCart === 'function') {
        onAddToCart(tempRecord, counter - 1);
      }
    }
  };

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image={
          record[imageFileName]
            ? `${apiUrl}/${record[imagePath]}/thumbnail/${record[imageFileName]}`
            : placeholder
        }
        title={record[name]}
      />
      <CardContent className={classes.cardContent}>
        <Typography
          component="h3"
          className={classes.productName}
          color="textPrimary"
        >
          {record[name]}
        </Typography>
        {typeof onAddToCart === 'function' && counter > 0 ? (
          <ShopAddToCartButtonCounter
            className={classes.shopAddToCartButtonCounter}
            counter={counter}
            onRemoveButton={removeOfCart}
            onAddButton={addToCart}
            onInputType={inputType}
          />
        ) : (
          <Button
            className={classes.addToCartButton}
            color="secondary"
            variant="contained"
            size="medium"
            onClick={addToCart}
          >
            <Icon fontSize="small">shopping_cart</Icon>
            &nbsp;&nbsp;{translate('sellin.addToCart')}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  BarcodeListItemView,
);
