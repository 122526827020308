import React, { useState, useMemo, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { useTranslate } from 'react-admin';
import {
  IconButton,
  Typography,
  MenuItem,
  Menu,
  Button,
  TextField,
  DialogContent,
  Dialog,
  DialogTitle,
  Icon,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import classNames from 'classnames';

import IconSaveFilter from '../component/IconSaveFilter';
import { closeDialogAction } from '../redux/listFilter/action';
import { USER_ID, getValue, CONFIG_SAVED_FILTER } from '../core/configProvider';
import { isCtrlEnterPressed } from '../helper/FormHelper';
import { getFilterColumns } from '../helper/MetaHelper';
import { isEmptyObject } from '../helper/data-helper';
import useWidth from '../component/useWidth';
import { SavedFilterItemType } from '../helper/Types';
import { getAppSettings, setAppSettings } from '../helper/settings-helper';
import { showNotification } from '../helper/general-function-helper';

interface ListSelectSavedFilterProps {
  closeDialogAndResetParams: Function;
  dialogResource: string;
  dialogData: string;
  setFilters: Function;
  isOpen: boolean;
  showFilterByList: Function;
  metaData: object;
  resource: string;
  displayedFilters: object;
}
type handleClickType = (event: SyntheticEvent<HTMLElement>) => void;
type handleNameChangeType = (event: React.ChangeEvent<HTMLInputElement>) => void;
type removeFilterType = (item: SavedFilterItemType) => void;
type handleKeyDownType = (event: React.KeyboardEvent<HTMLDivElement>) => void;
type getSettingKeyType = (resource: string) => string;

const useStyles = makeStyles(theme => ({
  menuItem: {
    fontSize: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },

  button: {
    marginTop: 20,
  },

  buttonCancel: {
    marginLeft: 10,
    marginRight: 10,
  },

  IconButton: {
    padding: 7,
    margin: '2px 5px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 7px',
      margin: 0,
    },
  },

  icon: {
    fontSize: 16,
  },

  filterName: {
    flexGrow: 1,
  },
}));

const ListSelectSavedFilter = (props: ListSelectSavedFilterProps) => {
  const {
    closeDialogAndResetParams,
    dialogResource,
    dialogData,
    setFilters,
    isOpen,
    showFilterByList,
    metaData,
    resource,
    displayedFilters,
  } = props;

  const classes = useStyles();
  const translate: Function = useTranslate();
  const width = useWidth();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [filterName, setFilterName] = useState<string>('');

  const handleClick: handleClickType = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeSelectMenu = (): void => {
    setAnchorEl(null);
  };

  const closeDialog = (): void => {
    setFilterName('');
    closeDialogAndResetParams();
  };

  const handleNameChange: handleNameChangeType = event =>
    setFilterName(event.target.value);

  const saveFilter = (): void => {
    if (!filterName || filterName.length < 3) {
      showNotification(translate('filter.nameIsNotValid'), 'error');
      return;
    }

    const id = Date.now();
    const key = getSettingKey(dialogResource);
    const savedFilterList = getAppSettings<Record<string, unknown>>(key).value ?? {};

    savedFilterList[id] = {
      id,
      name: filterName,
      data: dialogData,
    };

    setAppSettings({
      key,
      value: savedFilterList,
    });
    closeDialog();
  };

  const removeFilter: removeFilterType = item => {
    const key = getSettingKey(resource);
    const savedFilterList = getAppSettings<Record<string, unknown>>(key).value ?? {};

    delete savedFilterList[item.id];

    setAppSettings({
      key,
      value: savedFilterList,
    });
    closeSelectMenu();
  };

  const clearAllFilters =
    (removeFilter = false) =>
    (): void => {
      setFilters({}, removeFilter);
      closeSelectMenu();
    };

  const setNewListFilter = (item: SavedFilterItemType) => (): void => {
    setFilters(item.data, true);
    closeSelectMenu();
  };

  const handleKeyDown: handleKeyDownType = event => {
    if (isCtrlEnterPressed(event)) {
      event.preventDefault();

      saveFilter();
    }
  };

  const getSettingKey: getSettingKeyType = resource => {
    const userId = getValue(USER_ID);
    return userId + '_' + CONFIG_SAVED_FILTER + '_' + resource;
  };

  const showEveryFilterInList = (): void => {
    const nameList = getFilterColumns(metaData).map(field => field.name);

    showFilterByList(nameList);
    closeSelectMenu();
  };

  /**
   * Disable bulk filter actions (delete/clear)
   */
  const disableItem: boolean = useMemo(() => {
    if (isEmptyObject(displayedFilters)) {
      return true;
    } else if (Object.values(displayedFilters).includes(true)) {
      return false;
    }

    return true;
  }, [displayedFilters]);

  const savedFilterList = (getAppSettings(getSettingKey(resource)).value ??
    []) as SavedFilterItemType[];

  const idList = savedFilterList ? Object.keys(savedFilterList) : [];

  return (
    <div data-saved-filters>
      <Tooltip title={translate('filter.list')}>
        <IconButton
          color="primary"
          className={classes.IconButton}
          onClick={handleClick}
          data-test-saved-filter-button
        >
          <IconSaveFilter className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Menu
        id="saved-filter-list-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeSelectMenu}
        style={{ marginTop: '70px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={clearAllFilters(true)}
          className={classes.menuItem}
          data-saved-filters-click-fire
          disabled={disableItem}
        >
          {translate('filter.clearList')}
        </MenuItem>
        <MenuItem onClick={showEveryFilterInList} className={classes.menuItem}>
          {translate('filter.showEveryFilterInList')}
        </MenuItem>
        <MenuItem
          onClick={clearAllFilters(false)}
          className={classes.menuItem}
          disabled={disableItem}
          data-saved-clear-all-filters
        >
          {translate('filter.clearOnlyFilterValuesFromList')}
        </MenuItem>
        {idList.map(id => {
          const item: SavedFilterItemType = savedFilterList[id];

          return (
            <MenuItem
              key={id}
              className={classes.menuItem}
              data-test-filter-item={id}
            >
              <div
                className={classes.filterName}
                onClick={setNewListFilter(item)}
                data-test-saved-filter-item={id}
              >
                {item.name}
              </div>
              <IconButton
                color="primary"
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  removeFilter(item);
                }}
                data-test-delete-saved-filter-item
              >
                <Icon fontSize="small">delete</Icon>
              </IconButton>
            </MenuItem>
          );
        })}
      </Menu>

      <Dialog
        open={isOpen}
        onClose={closeDialog}
        maxWidth={width}
        aria-labelledby="show-image-dialog-title"
      >
        <DialogTitle id="show-image-dialog-title">
          {translate('filter.saveFilter')}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {translate('filter.pleaseGiveANameToYourNewFilter')}
          </Typography>
          <TextField
            label={translate('filter.name')}
            value={filterName}
            onChange={handleNameChange}
            onKeyDown={handleKeyDown}
            fullWidth
            autoFocus
            id="saveFilterDialogTextField"
          />
          <Button
            color="primary"
            className={classes.button}
            variant="contained"
            onClick={saveFilter}
            id="saveFilterDialogSaveButton"
          >
            {translate('ra.action.save')}
          </Button>
          <Button
            className={classNames(classes.button, classes.buttonCancel)}
            onClick={closeDialog}
          >
            {translate('ra.action.cancel')}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => ({
  isOpen: state.listFilter.isDialogOpen,
  dialogResource: state.listFilter.dialogResource,
  dialogData: state.listFilter.dialogData,
  savedFilterList: state.listFilter.list,
});

const mapDispatchToProps = {
  closeDialogAndResetParams: closeDialogAction,
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(ListSelectSavedFilter);
