import { ReactElement, useState } from 'react';
import { DialogContent, DialogTitle } from '@material-ui/core';

import { getTranslatedName } from '../../helper/MetaHelper';
import { isCleanStringMatch } from '../../helper/TextHelper';
import { MetaData } from '../../helper/Types';
import { CustomForm, FormController, FormWithTabs } from '../form';
import QuickCreateButtonToolbar from '../QuickCreateButtonToolbar';
import LoadingBox from '../LoadingBox';
import { actorOnDispatch } from '../../type/actor-setup';
import { useStyles } from './dialogs-stack.style';
import { DialogViewProps } from './dialogs-stack.type';

export const RelationEditDialogView = (props: DialogViewProps): ReactElement => {
  const { dialogData, locale, dialogType, currentResource, closeDialogHandler } =
    props;

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  actorOnDispatch('loading', response => {
    if (response && `${currentResource!.value}-edit` in response) {
      setIsLoading(response[`${currentResource!.value}-edit`]);
    }
  });

  if (isLoading) {
    return <LoadingBox />;
  }

  return (
    <>
      <DialogTitle id="relationEditDialogTitle">
        {isCleanStringMatch(getTranslatedName(dialogData.metaData, locale), 'ي')}
      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
        data-style-dialog-relation="dialogRelation"
      >
        <FormController formName={dialogType} isQuickForm>
          <CustomForm
            isBottomToolbar
            Toolbar={
              <QuickCreateButtonToolbar
                closeDialog={closeDialogHandler}
                redirect={''} // FIXME: What is it?!!!
                parentInfo={dialogData.parentInfo as Record<string, unknown>}
                metaData={dialogData.metaData as MetaData}
                disableFullFormButton={true}
                resource={currentResource!.value}
                relationMode={true}
                mustRefresh={dialogData.mustRefresh as boolean | undefined}
                isEdit={true}
              />
            }
          >
            <FormWithTabs />
          </CustomForm>
        </FormController>
      </DialogContent>
    </>
  );
};
