/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-case-declarations */
import querystring from 'qs';
import axios from 'axios';
import lodashGet from 'lodash/get';
import lodashClone from 'lodash/clone';

import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
  GET_MANY_REFERENCE,
} from 'react-admin';

import {
  API_URL,
  API_VERSION,
  USER_TOKEN,
  CONFIG_PROFILE_SETTING,
  getValue,
  setValue,
  CONFIG_FIXED_HEADER_PARAMS,
  CONFIG_ROUTE_PREFIX,
  API_NAME,
  USER_WAREHOUSE_ID,
  SESSION_ID,
} from './configProvider';

import {
  clone,
  arrayResultToObjectWithLowerCase,
  arrayResultToObjectWithLowerCaseForDropDown,
  objectToLowerCaseProperties,
  isEmpty,
  isEmptyObject,
} from '../helper/data-helper';
import { getOperatorByValueForFilter } from '../helper/TextHelper';
import { CONFIG_CALENDAR, CONFIG_LOCALE } from './configProvider';
import { getProfileSettingDropdownPatternInfo } from '../helper/PatternMetaHelper';
import { openNewTab } from '../helper/QuickAccessHelper';
import { getDropDownItem } from '../helper/DropdownHelper';
import { shouldUpdateProfile } from '../helper/ProfileHelper';
import {
  getSessionIdInUrl,
  createNewUrlBaseOfSessionIdInUrl,
} from '../helper/UrlHelper';
import { getRootTableId } from '../helper/meta-helper';
import { getRootResource } from '../helper/resource-helper';

import errorSound from '../sound/error-sound.mp3';
import warningSound from '../sound/warning-sound.mp3';
import defaultSound from '../sound/default-sound.mp3';

import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../type/actor-setup';
import { checkCache } from '../helper/WorkBoxHelper';
import { getFieldByName, getReportParameterFieldByKey } from '../helper/MetaHelper';
import { prepareDateTimeFilterValue } from '../helper/FilterHelper';
import { openPrintPreview } from '../helper/print-helper';
import { showNotification } from '../helper/general-function-helper';

export const apiUrl = getValue(API_URL);
export const apiVersion = getValue(API_VERSION);
const apiName = getValue(API_NAME);

export const GET_DROPDOWN = 'GET_DROPDOWN';
export const GET_MENU = 'GET_MENU';
export const GET_CODING = 'GET_CODING';
export const GET_META = 'GET_META';
export const RUN_SERVICE = 'RUN_SERVICE';
export const GET_REPORT = 'GET_REPORT';
export const UPDATE_REPORT = 'UPDATE_REPORT';
export const CUSTOM_UPDATE = 'CUSTOM_UPDATE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_FILE = 'GET_FILE';
export const GET_QUICK_ACCESS = 'GET_QUICK_ACCESS';
export const INLINE_CELL_UPDATE = 'INLINE_CELL_UPDATE';
export const GET_DROPDOWN_DEFAULT_VALUES = 'GET_DROPDOWN_DEFAULT_VALUES';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_AUTHORITY_DELEGATION = 'GET_AUTHORITY_DELEGATION';
export const PUT_AUTHORITY_DELEGATION = 'PUT_AUTHORITY_DELEGATION';
export const POST_STREAM_FILE = 'POST_STREAM_FILE';
export const POST_STREAM_MULTIPLE_FILE = 'POST_STREAM_MULTIPLE_FILE';
export const GET_DEFAULT_FORM_VALUES = 'GET_DEFAULT_FORM_VALUES';
export const RUN_VALIDATION = 'RUN_VALIDATION';
export const LOG_ERROR = 'LOG_ERROR';
export const CUSTOM_GET = 'CUSTOM_GET';
export const CUSTOM_POST = 'CUSTOM_POST';

export const httpClient = axios.create();
httpClient.defaults.timeout = 240000; // wait 240 seconds

export const isResponseOk = response => {
  return (
    response.data &&
    response.data.code >= 200 &&
    response.data.code < 400 &&
    (response.data.messageType === 'ok' || response.data.messageType === 'ignore')
  );
};

const showWarningsSnackbar = response => {
  const { messageType, userMessage } = response.data;
  if (messageType === 'warning' && userMessage) {
    showNotification(userMessage, 'warning', { forceSnackbar: true });
  }
};

export const getResponseMessage = response => {
  let errorType = response.data.messageType;
  const userMessage = lodashGet(response, 'data.userMessage');
  const code = lodashGet(response, 'data.code');
  const reqId = lodashGet(response, 'data.requestId');

  if (errorType == null) {
    errorType = 'Error';
  }

  const output = (userMessage || code || errorType) + `^${reqId}` + `CODE${code}`;

  return output;
};

/**
 * this function checks if the response error should parse or show `userMessage`
 * @function shouldParseResponseError
 * @param {object} response
 * @returns {boolean}
 */
export const shouldParseResponseError = response => {
  return (
    response.data.data &&
    !isEmptyObject(response.data.data) &&
    response.data.code !== 6033 &&
    response.data.code !== 6015 &&
    response.data.code !== 6057 &&
    response.data.code !== 7008 &&
    response.data.code !== 6076 &&
    response.data.code !== 6080 &&
    response.data.code !== 6081 &&
    response.data.code !== 6001
  );
};

/**
 * this function checks if the response additional data error should parse or show `userMessage`
 * @function shouldParseResponseAdditionalDataError
 * @param {object} response
 * @returns {boolean}
 */
export const shouldParseResponseAdditionalDataError = response => {
  return (
    !response.data.data &&
    !isEmptyObject(response.data.additionalData) &&
    response?.data?.additionalData[0]?.exception?.data
  );
};

export const getFilterByValue = (name, value) => {
  const operator = getOperatorByValueForFilter(value);
  return [name, operator, value];
};

/**
 * it will call only when there is a session id in url and this session id is different
 * with previous session id in url.
 * it will call a function that it will get branch of user data and merge its data with
 * new global parameters of user that its session id exist in url
 * then update the profile and global parameters keys in actor store and setting
 * @function getNewProfile
 * @param { string } sessionId
 * @returns { void } void
 */
const getNewProfile = async sessionId => {
  const getOneUrl = `${apiUrl}/${apiVersion}/account/${apiName}/profile`;

  const newProfileData = await httpClient.get(
    createNewUrlBaseOfSessionIdInUrl(getOneUrl, sessionId, apiUrl),
  );

  const dropdownMeta = getProfileSettingDropdownPatternInfo(
    'profileSettingDropdown',
  );

  const globalParameters = objectToLowerCaseProperties(
    lodashGet(newProfileData, ['data', 'data', 'globalParameters']),
  );

  let dropdownItem = await getDropDownItem(dropdownMeta);
  if (!dropdownItem) dropdownItem = {};

  const newGlobalParameters = {
    ...globalParameters,
    currentdepid: dropdownItem['departmentinfo_id'],
    currentDepID: dropdownItem['departmentinfo_id'],
    currentwarehouseid: dropdownItem['warehouse_id'],
    currentWareHouseID: dropdownItem['warehouse_id'],
  };

  // set in setting
  setValue(USER_WAREHOUSE_ID, +dropdownItem['warehouse_id']);

  if (sessionId) {
    setValue(USER_TOKEN, sessionId);
    setValue(SESSION_ID, sessionId);
  }

  // set to actor
  setValue(CONFIG_PROFILE_SETTING, newGlobalParameters);
  actorSetActionValue('globalParameters', newGlobalParameters);
  actorDispatch('profile', newProfileData?.data?.data);
};

export const prepareFilterFromObject = filterObject => {
  const preparedListFilter = [];
  const currentResource = actorGetActionValue('resources')?.current?.value;
  const metaData = actorGetActionValue('metaData', currentResource);

  for (const key in filterObject) {
    if (key === 'undefined') continue;
    const filterField = isEmpty(metaData?.reportId)
      ? getFieldByName(metaData, key)
      : getReportParameterFieldByKey(metaData, key);

    let isDateTimeField = false;
    if (filterField != null) {
      isDateTimeField =
        filterField.dataType.simple === 'date' ||
        filterField?.dataType.simple === 'datetime';
    }

    if (preparedListFilter.length) {
      preparedListFilter.push('and');
    }

    // if filter value is prepared
    if (Array.isArray(filterObject[key])) {
      preparedListFilter.push(
        isDateTimeField
          ? prepareDateTimeFilterValue(filterObject[key])
          : filterObject[key],
      );
    }
    // if it's key=value , then prepare as array
    else {
      preparedListFilter.push(
        isDateTimeField
          ? prepareDateTimeFilterValue(getFilterByValue(key, filterObject[key]))
          : getFilterByValue(key, filterObject[key]),
      );
    }
  }

  return preparedListFilter;
};

const checkResponseAndPlayAudio = response => {
  const messageSound = response?.messageSound;
  if (isEmpty(messageSound)) {
    return;
  }

  let soundFile;
  switch (messageSound) {
    case 'error':
      soundFile = errorSound;
      break;

    case 'warning':
      soundFile = warningSound;
      break;

    default:
      soundFile = defaultSound;
  }

  const responseAudio = new Audio(soundFile);
  responseAudio.loop = false;
  responseAudio.play();
};

/**
 * if in the data `recordOpenForm` or `recordKey` is existes this function opens it in new tab.
 * @function checkResponseForOpenNewTab
 * @param {Object} response
 * @returns {void | undefined}
 */
const checkResponseForOpenNewTab = (response, filters) => {
  if (
    isEmptyObject(response) ||
    !response.actionOutput ||
    isEmptyObject(response.actionOutput)
  ) {
    return;
  }

  const {
    recordKey = [],
    recordOpenForm = [],
    recordEditForm = [],
    printReport = [],
  } = lodashGet(response, ['actionOutput'], {});

  const newTabs = [];
  let index = 0;

  // check recordKey
  if (recordKey && recordKey.length) {
    recordKey.forEach(item => {
      newTabs[index++] = item;
    });
  }

  // check recordOpenForm
  if (recordOpenForm && recordOpenForm.length) {
    recordOpenForm.forEach(item => {
      newTabs[index++] = item;
    });
  }

  // check recordEditForm
  if (recordEditForm && recordEditForm.length) {
    recordEditForm.forEach(item => {
      newTabs[index++] = item;
    });
  }

  // Open new tabs
  if (newTabs.length) {
    newTabs.forEach(item => {
      openNewTab(item);
    });
  }

  if (printReport.length) {
    for (const print of printReport) {
      if (typeof print !== 'object') continue;

      openPrintPreview(print, filters);
      // TODO:  directly send to printer
    }
  }
};

const dataProvider = async (type, resource, params = {}) => {
  const {
    rawResponse = false,
    skipPrefix = false,
    queryParams = {},
    fieldName,
    dropdownId,
    dropdownResource,
    dropdownValueMember,
    parameters,
  } = params;

  const sessionIdInUrl = getSessionIdInUrl();

  if (sessionIdInUrl && shouldUpdateProfile(sessionIdInUrl)) {
    actorSetActionValue('currentSessionIdInUrl', sessionIdInUrl);
    await getNewProfile(sessionIdInUrl);
  }

  const apiVersion = getValue(API_VERSION);
  const prefix =
    !skipPrefix && getValue(CONFIG_ROUTE_PREFIX)
      ? `${getValue(CONFIG_ROUTE_PREFIX)}/`
      : '';
  const token = getValue(USER_TOKEN);
  const profileSetting = getValue(CONFIG_PROFILE_SETTING) || {};
  const fixedHeaderParams = getValue(CONFIG_FIXED_HEADER_PARAMS) || {};

  const calendarLocale = getValue(CONFIG_CALENDAR);
  const locale = getValue(CONFIG_LOCALE);
  const requestConfig = {
    headers: {
      ...fixedHeaderParams,
      calendarLocale,
      locale,
      settings: encodeURI(JSON.stringify(profileSetting)),
      authorization: `Bearer ${token}`,
    },
  };
  switch (type) {
    case GET_DROPDOWN:
      const dropdownPerPage = lodashGet(params, 'pagination.perPage') || 10;
      const dropdownPage = lodashGet(params, 'pagination.page') || 1;

      const dropdownFilter =
        params.filter && Object.keys(params.filter).length > 0
          ? JSON.stringify(prepareFilterFromObject(params.filter))
          : '';

      const dropdownQueryParameters = querystring.stringify({
        search: `${params.search}` || '',
        parameters: parameters || '',
        skip: (dropdownPage - 1) * dropdownPerPage,
        takeCount: dropdownPerPage,
        sort: lodashGet(params, ['sort', 'field']),
        sortType: lodashGet(params, ['sort', 'order']),
        forceTreeLevel: params.forceTreeLevel ? 'true' : 'false',
        filters: params.forceTreeLevel ? dropdownFilter : null,
      });

      const dropdownUrl =
        !isEmpty(fieldName) && !isEmpty(dropdownId)
          ? `${apiUrl}/${apiVersion}/${prefix}${dropdownResource}/dropdown/${dropdownId}/${fieldName}?${dropdownQueryParameters}`
          : `${apiUrl}/${apiVersion}/${prefix}dropdown/${resource}?${dropdownQueryParameters}`;
      const dropdownResponse = await httpClient.get(
        createNewUrlBaseOfSessionIdInUrl(dropdownUrl, sessionIdInUrl, apiUrl),
        requestConfig,
      );

      checkResponseAndPlayAudio(dropdownResponse.data);
      checkResponseForOpenNewTab(dropdownResponse.data, dropdownFilter);
      showWarningsSnackbar(dropdownResponse);

      if (!isResponseOk(dropdownResponse)) {
        throw getResponseMessage(dropdownResponse);
      }

      if (rawResponse) {
        return dropdownResponse.data;
      }

      return {
        result: arrayResultToObjectWithLowerCaseForDropDown(
          dropdownResponse.data.data,
          dropdownValueMember ? dropdownValueMember : fieldName,
        ),
        total: dropdownResponse.data.totalCount,
        userMessage: dropdownResponse.data.userMessage,
        messageType: dropdownResponse.data.messageType,
      };

    case UPDATE_REPORT:
      const updateReportUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}?columns=${params.columns}`;
      const updateReportData = params.data;

      const updateReportResponse = await axios.put(
        createNewUrlBaseOfSessionIdInUrl(updateReportUrl, sessionIdInUrl, apiUrl),
        updateReportData,
        requestConfig,
      );

      showWarningsSnackbar(updateReportResponse);

      if (!isResponseOk(updateReportResponse)) {
        console.log(
          'dataProvider.js update report response is NOT OK',
          updateReportResponse,
        );
        throw getResponseMessage(updateReportResponse);
      }
      return objectToLowerCaseProperties(updateReportResponse);

    case GET_MENU:
      const menuUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}`;

      const menuResponse = await httpClient.get(
        createNewUrlBaseOfSessionIdInUrl(menuUrl, sessionIdInUrl, apiUrl),
        requestConfig,
      );

      showWarningsSnackbar(menuResponse);

      if (!isResponseOk(menuResponse)) {
        throw getResponseMessage(menuResponse);
      }

      await checkCache(menuResponse.data.metaVersion);

      if (rawResponse) {
        return menuResponse.data;
      }

      return arrayResultToObjectWithLowerCase(menuResponse.data.data);

    case GET_META: {
      let metadataQueryStrings = '?';

      // add parent information to url
      if (resource === 'workflow/processtaskexecutionhistory') {
        const [parentModule, parentTable] = getRootResource();

        if (parentModule && parentTable) {
          metadataQueryStrings += `${
            metadataQueryStrings === '?' ? '' : '&'
          }parentModule=${parentModule}&parentTable=${parentTable}`;
        }
      }

      const metaUrl = `${apiUrl}/${apiVersion}/${prefix}meta/${resource}${metadataQueryStrings}`;
      const metaResponse = await httpClient.get(
        await createNewUrlBaseOfSessionIdInUrl(metaUrl, sessionIdInUrl, apiUrl),
        requestConfig,
      );

      showWarningsSnackbar(metaResponse);

      if (!isResponseOk(metaResponse)) {
        throw getResponseMessage(metaResponse);
      }

      let relationsMeta = [];
      if (
        metaResponse.data.additionalData &&
        metaResponse.data.additionalData.relationsMeta
      ) {
        relationsMeta = metaResponse.data.additionalData.relationsMeta;
      }

      if (rawResponse) {
        return metaResponse.data;
      }

      return [metaResponse.data.data, ...relationsMeta];
    }

    case GET_LIST:
      const page = params.pagination?.page || 1;
      const perPage = params.pagination?.perPage || 20;
      let filtersForSendInPrints = [];
      // check is report
      const isReport = resource.indexOf('report') === 0;

      let finalSortParams = null;
      let finalSortTypeParams = null;
      if (params.sort) {
        finalSortParams = params.sort.field;
        finalSortTypeParams = params.sort.order;
      } else if (!isReport) {
        finalSortParams = 'id';
        finalSortTypeParams = 'ASC';
      }

      const listObjectForQueryParameters = {
        ...queryParams,
        skip: (page - 1) * perPage,
        takeCount: perPage,
        sort: finalSortParams,
        sortType: finalSortTypeParams,
      };

      const hasSearchFilters =
        params.searchFilters && Object.keys(params.searchFilters).length;

      if (Array.isArray(params.filter) && params.filter.length) {
        listObjectForQueryParameters.filters = JSON.stringify(params.filter);
      } else if (!isEmptyObject(params.filter)) {
        const preparedFilters = prepareFilterFromObject(params.filter);
        listObjectForQueryParameters.filters = JSON.stringify(preparedFilters);

        filtersForSendInPrints = preparedFilters;
      }

      // use in report relation filter
      if (hasSearchFilters) {
        const preparedSearchFilters = prepareFilterFromObject(params.searchFilters);

        listObjectForQueryParameters.searchFilters =
          JSON.stringify(preparedSearchFilters);

        filtersForSendInPrints = [
          ...filtersForSendInPrints,
          ...preparedSearchFilters,
        ];
      }

      if (params.fields && Array.isArray(params.fields)) {
        listObjectForQueryParameters.fields = params.fields.join(',');
      }

      const queryParameters = querystring.stringify(listObjectForQueryParameters);

      const listUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}${
        String(resource).includes('?') ? '&' : '?'
      }${queryParameters}`;

      const response = await httpClient.get(
        createNewUrlBaseOfSessionIdInUrl(listUrl, sessionIdInUrl, apiUrl),
        requestConfig,
      );

      await checkCache(response.data.metaVersion);

      checkResponseAndPlayAudio(response.data);
      checkResponseForOpenNewTab(
        response.data,
        filtersForSendInPrints.length ? filtersForSendInPrints : null,
      );

      if (rawResponse) {
        return response.data;
      }

      showWarningsSnackbar(response);

      if (!isResponseOk(response)) {
        throw getResponseMessage(response);
      }

      return {
        additionalData: response.data.additionalData,
        data: arrayResultToObjectWithLowerCase(response.data.data, {
          perPage,
          page,
        }),
        total: response.data.totalCount,
        requestId: response.data.requestId,
      };

    case GET_ONE:
      const getOneParams = { ...queryParams };
      // prettier-ignore
      const getOneUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/${params.id}?${querystring.stringify(getOneParams)}`;

      const getOneResponse = await httpClient.get(
        createNewUrlBaseOfSessionIdInUrl(getOneUrl, sessionIdInUrl, apiUrl),
        requestConfig,
      );

      await checkCache(getOneResponse.data.metaVersion);

      checkResponseAndPlayAudio(getOneResponse.data);
      checkResponseForOpenNewTab(getOneResponse.data, getOneParams?.filters);

      showWarningsSnackbar(getOneResponse);

      if (!isResponseOk(getOneResponse)) {
        throw getOneResponse.data;
        // TODO: When it isResponseOk === true , check that it works correctly
      }

      if (rawResponse) {
        return getOneResponse.data;
      }

      //TODO: Why do we have key `data`?
      return {
        data: objectToLowerCaseProperties(
          getOneResponse.data.data,
          undefined,
          undefined,
          params.id,
        ),
        additionalData: getOneResponse.data.additionalData,
      };

    case GET_CODING:
      let codingUrl = '';

      if (!isEmptyObject(params)) {
        if (params.editRequest) {
          codingUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/patternCodingValue/${params.id}`;
        } else {
          codingUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/codingDefaultValue/${params.id}`;
        }
      } else {
        codingUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/codingDefaultValue`;
      }

      const getCodingResponse = await axios.get(
        createNewUrlBaseOfSessionIdInUrl(codingUrl, sessionIdInUrl, apiUrl),
        requestConfig,
      );

      checkResponseAndPlayAudio(getCodingResponse.data);
      checkResponseForOpenNewTab(getCodingResponse.data);
      showWarningsSnackbar(getCodingResponse);

      if (!isResponseOk(getCodingResponse)) {
        throw getResponseMessage(getCodingResponse);
      }
      if (rawResponse) {
        return getCodingResponse.data;
      }

      return {
        data: getCodingResponse.data.data,
      };

    case UPDATE:
      const updateData = clone(params.data);
      delete updateData._meta;
      if (updateData.__relationsData) {
        delete updateData.__relationsData;
      }

      const updateUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/${params.id}`;

      const updateResponse = await httpClient.put(
        createNewUrlBaseOfSessionIdInUrl(updateUrl, sessionIdInUrl, apiUrl),
        updateData,
        requestConfig,
      );

      checkResponseAndPlayAudio(updateResponse.data);
      checkResponseForOpenNewTab(updateResponse.data, params?.filters);

      if (rawResponse) {
        return updateResponse.data;
      }

      showWarningsSnackbar(updateResponse);

      if (!isResponseOk(updateResponse)) {
        shouldParseResponseError(updateResponse);
        throw shouldParseResponseError(updateResponse)
          ? updateResponse.data
          : shouldParseResponseAdditionalDataError(updateResponse)
          ? {
              requestId: updateResponse.data.requestId,
              data: updateResponse.data.additionalData[0].exception.data,
            }
          : getResponseMessage(updateResponse);
      }

      const { additionalData: updateAdditionalData = {} } = updateResponse.data;

      return {
        ...updateAdditionalData,
        data: objectToLowerCaseProperties(updateResponse.data.data),
      };
    case CHANGE_PASSWORD:
      const changePasswordData = clone(params.params);

      const changePasswordUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}`;

      const changePasswordResponse = await httpClient.put(
        createNewUrlBaseOfSessionIdInUrl(changePasswordUrl, sessionIdInUrl, apiUrl),
        changePasswordData,
        requestConfig,
      );

      checkResponseAndPlayAudio(changePasswordResponse.data);
      checkResponseForOpenNewTab(changePasswordResponse.data);

      if (rawResponse) {
        return changePasswordResponse.data;
      }

      showWarningsSnackbar(changePasswordResponse);

      if (!isResponseOk(changePasswordResponse)) {
        throw shouldParseResponseError(changePasswordResponse)
          ? changePasswordResponse.data
          : shouldParseResponseAdditionalDataError(changePasswordResponse)
          ? {
              requestId: changePasswordResponse.data.requestId,
              data: changePasswordResponse.data.additionalData[0].exception.data,
            }
          : getResponseMessage(changePasswordResponse);
      }

      const { additionalData: updatePasswordAdditionalData = {} } =
        changePasswordResponse.data;

      return {
        ...updatePasswordAdditionalData,
        data: objectToLowerCaseProperties(changePasswordResponse.data.data),
      };

    case INLINE_CELL_UPDATE:
      const inlineUpdateData = clone(params.data);
      delete inlineUpdateData._meta;
      if (inlineUpdateData.__relationsData) {
        delete inlineUpdateData.__relationsData;
      }

      const inlineUpdateUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/${params.id}/inline/${params.target}`;

      const inlineUpdateResponse = await httpClient.put(
        createNewUrlBaseOfSessionIdInUrl(inlineUpdateUrl, sessionIdInUrl, apiUrl),
        inlineUpdateData,
        requestConfig,
      );

      if (rawResponse) {
        return inlineUpdateResponse.data;
      }

      showWarningsSnackbar(inlineUpdateResponse);

      if (!isResponseOk(inlineUpdateResponse)) {
        throw shouldParseResponseError(inlineUpdateResponse)
          ? inlineUpdateResponse.data
          : shouldParseResponseAdditionalDataError(inlineUpdateResponse)
          ? {
              requestId: inlineUpdateResponse.data.requestId,
              data: inlineUpdateResponse.data.additionalData[0].exception.data,
            }
          : getResponseMessage(inlineUpdateResponse);
      }

      const { additionalData: inlineUpdateAdditionalData = {} } =
        inlineUpdateResponse.data;

      return {
        ...inlineUpdateAdditionalData,
        data: objectToLowerCaseProperties(inlineUpdateResponse.data.data),
      };

    case CUSTOM_UPDATE:
      let customUpdateData = '';
      params.data.map(item => {
        customUpdateData += item;
      });
      const customUpdateUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/${params.id}/${customUpdateData}`;

      const customUpdateResponse = await httpClient.put(
        createNewUrlBaseOfSessionIdInUrl(customUpdateUrl, sessionIdInUrl, apiUrl),
        {},
        requestConfig,
      );

      checkResponseAndPlayAudio(customUpdateResponse.data);
      checkResponseForOpenNewTab(customUpdateResponse.data, params?.filters);

      if (rawResponse) {
        return customUpdateResponse.data;
      }

      showWarningsSnackbar(customUpdateResponse);

      if (!isResponseOk(customUpdateResponse)) {
        throw getResponseMessage(customUpdateResponse);
      }

      const { additionalData: customUpdateAdditionalData = {} } =
        customUpdateResponse.data;

      return {
        ...customUpdateAdditionalData,
        data: objectToLowerCaseProperties(customUpdateResponse.data.data),
      };

    case CREATE:
      let createData = clone(params.data);
      delete createData._meta;

      // if there is a file attached, send differently
      if (lodashGet(params, 'data.file.type')) {
        requestConfig.headers['content-type'] = 'multipart/form-data';

        createData = new FormData();
        createData.append('file', params.data.file);
        for (const key in params.data) {
          if (key === 'file') continue;
          createData.append(key, params.data[key]);
        }
      }

      const createUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}`;

      const createResponse = await httpClient.post(
        createNewUrlBaseOfSessionIdInUrl(createUrl, sessionIdInUrl, apiUrl),
        createData,
        requestConfig,
      );

      checkResponseAndPlayAudio(createResponse.data);
      checkResponseForOpenNewTab(createResponse.data, params?.filters);

      if (rawResponse) {
        return createResponse.data;
      }

      showWarningsSnackbar(createResponse);

      if (!isResponseOk(createResponse)) {
        throw shouldParseResponseError(createResponse)
          ? createResponse.data
          : shouldParseResponseAdditionalDataError(createResponse)
          ? {
              requestId: createResponse.data.requestId,
              data: createResponse.data.additionalData[0].exception.data,
            }
          : getResponseMessage(createResponse);
      }
      const { additionalData: createAdditionalData = {} } = createResponse.data;

      return {
        ...createAdditionalData,
        data: objectToLowerCaseProperties(createResponse.data.data),
      };

    case RUN_SERVICE:
      const {
        actionUniqueId,
        data,
        serviceModuleName = '',
        serviceModuleTableName = '',
        reportId = '',
        macroId = '',
        fieldId = '',
      } = params;

      const runServiceUrl = `${apiUrl}/${apiVersion}/${prefix}action/${actionUniqueId}?moduleName=${serviceModuleName}&moduleTableName=${serviceModuleTableName}&reportId=${reportId}&macroId=${macroId}&fieldId=${fieldId}`;

      const runServiceResponse = await httpClient.post(
        createNewUrlBaseOfSessionIdInUrl(runServiceUrl, sessionIdInUrl, apiUrl),
        data,
        requestConfig,
      );

      checkResponseAndPlayAudio(runServiceResponse.data);
      checkResponseForOpenNewTab(runServiceResponse.data, params?.filters);

      if (rawResponse) {
        return runServiceResponse.data;
      }
      showWarningsSnackbar(runServiceResponse);

      if (
        !runServiceResponse?.data?.actionOutput?.additionalData &&
        !isResponseOk(runServiceResponse)
      ) {
        shouldParseResponseError(runServiceResponse);
        throw shouldParseResponseError(runServiceResponse)
          ? runServiceResponse.data
          : shouldParseResponseAdditionalDataError(runServiceResponse)
          ? {
              requestId: runServiceResponse.data.requestId,
              data: runServiceResponse.data.additionalData[0].exception.data,
            }
          : getResponseMessage(runServiceResponse);
      }

      return {
        ...runServiceResponse.data,
        data: objectToLowerCaseProperties(runServiceResponse.data.data),
      };

    case RUN_VALIDATION: {
      const { data, fieldName = '' } = params;

      const runValidationUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/validationactions/${fieldName}`;

      const runValidationResponse = await httpClient.post(
        createNewUrlBaseOfSessionIdInUrl(runValidationUrl, sessionIdInUrl, apiUrl),
        data,
        requestConfig,
      );

      checkResponseAndPlayAudio(runValidationResponse.data);
      checkResponseForOpenNewTab(runValidationResponse.data, params?.filters);

      if (rawResponse) {
        return runValidationResponse.data;
      }

      if (!isResponseOk(runValidationResponse)) {
        throw {
          response: runValidationResponse,
          shouldParseResponseError: shouldParseResponseError(runValidationResponse),
          shouldParseResponseAdditionalDataError:
            shouldParseResponseAdditionalDataError(runValidationResponse),
          message: getResponseMessage(runValidationResponse),
        };
      }

      return {
        ...runValidationResponse.data,
        data: objectToLowerCaseProperties(runValidationResponse.data.data),
      };
    }
    case DELETE:
      const deleteUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/${params.id}`;

      const deleteResponse = await httpClient.delete(
        createNewUrlBaseOfSessionIdInUrl(deleteUrl, sessionIdInUrl, apiUrl),
        requestConfig,
      );

      checkResponseAndPlayAudio(deleteResponse.data);
      checkResponseForOpenNewTab(deleteResponse.data, params?.filters);

      if (rawResponse) {
        return deleteResponse.data;
      }

      showWarningsSnackbar(deleteResponse);

      if (!isResponseOk(deleteResponse)) {
        throw getResponseMessage(deleteResponse);
      }

      return {
        data: params.previousData,
      };

    case DELETE_MANY:
      const deleteManyUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/bulk`;

      const deleteManyResponse = await httpClient.delete(
        createNewUrlBaseOfSessionIdInUrl(deleteManyUrl, sessionIdInUrl, apiUrl),
        {
          ...requestConfig,
          data: params.ids,
        },
      );

      checkResponseAndPlayAudio(deleteManyResponse.data);
      checkResponseForOpenNewTab(deleteManyResponse.data, params?.filters);

      if (rawResponse) {
        return deleteManyResponse.data;
      }

      showWarningsSnackbar(deleteManyResponse);

      if (!isResponseOk(deleteManyResponse)) {
        throw getResponseMessage(deleteManyResponse);
      }

      return { data: params.ids };

    case GET_MANY_REFERENCE: {
      const getManyPage = params.pagination.page || 1;
      const getManyPerPage = params.pagination.perPage || 20;
      const [parentModule, parentTable] = getRootResource();

      let relationFilter = [
        [params.target, '=', params.id],
        'and',
        ['tableid', '=', getRootTableId()],
      ];

      if (params.filter && Object.keys(params.filter).length > 0) {
        const finalFilter = prepareFilterFromObject(params.filter);

        relationFilter = [...relationFilter, 'and', ...finalFilter];
      }

      const queryParameters = {
        ...queryParams,
        skip: (getManyPage - 1) * getManyPerPage,
        takeCount: getManyPerPage,
        sort: params.sort.field,
        sortType: params.sort.order,
        filters: JSON.stringify(relationFilter),
      };

      if (parentModule) {
        queryParameters['parentModule'] = parentModule;
      }

      if (parentTable) {
        queryParameters['parentTable'] = parentTable;
      }

      const getManyQueryParameters = querystring.stringify(queryParameters);

      const getManyUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}?${getManyQueryParameters}`;

      const getManyResponse = await httpClient.get(
        createNewUrlBaseOfSessionIdInUrl(getManyUrl, sessionIdInUrl, apiUrl),
        requestConfig,
      );

      checkResponseAndPlayAudio(getManyResponse.data);
      checkResponseForOpenNewTab(getManyResponse.data, queryParameters.filters);
      if (rawResponse) {
        return getManyResponse.data;
      }

      showWarningsSnackbar(getManyResponse);

      if (!isResponseOk(getManyResponse)) {
        //TODO:  here should throw error as an object. but should check all notification handlers
        throw getResponseMessage(getManyResponse);
      }

      return {
        data: getManyResponse.data.data,
        total: getManyResponse.data.totalCount,
        requestId: getManyResponse.data.requestId,
        additionalData: getManyResponse.data?.additionalData,
      };
    }

    case GET_FILE:
      let getFileQueryParameters = '';
      if (!isEmptyObject(queryParams)) {
        const preparedParameters = {
          fields: queryParams.fields,
          sort: queryParams.sort?.field || 'id',
          sortType: queryParams.sort?.order || 'ASC',
          filters:
            queryParams.filter && Object.keys(queryParams.filter).length
              ? JSON.stringify(prepareFilterFromObject(queryParams.filter))
              : '',
        };

        if (String(params?.link).includes('export/text')) {
          preparedParameters.withheader = queryParams.withheader;
          preparedParameters.encoding = queryParams.encoding;
        }

        getFileQueryParameters = querystring.stringify(preparedParameters);
      }

      requestConfig.responseType = 'arraybuffer';
      const getFileUrl = `${apiUrl}/${
        params.ignoreApiVersion ? '' : apiVersion
      }${prefix}${params.link}${
        getFileQueryParameters ? '?' + getFileQueryParameters : ''
      }`;

      const getFileResponse = await httpClient.get(
        createNewUrlBaseOfSessionIdInUrl(getFileUrl, sessionIdInUrl, apiUrl),
        requestConfig,
      );

      return getFileResponse;

    // This case make a request to `quickaccess` to get new url
    // `shortcutKey` is important and required.
    // sample: `dataProvider(GET_QUICK_ACCESS, null, { shortcutKey })`
    case GET_QUICK_ACCESS:
      const getQuickAccessUrl = `${apiUrl}/${apiVersion}/${prefix}quickaccess/table/${params.shortcutKey}`;

      const getQuickAccessResponse = await httpClient.get(
        createNewUrlBaseOfSessionIdInUrl(getQuickAccessUrl, sessionIdInUrl, apiUrl),
        requestConfig,
      );

      checkResponseAndPlayAudio(getQuickAccessResponse.data);
      checkResponseForOpenNewTab(getQuickAccessResponse.data, params?.filters);

      showWarningsSnackbar(getQuickAccessResponse);

      if (!isResponseOk(getQuickAccessResponse)) {
        throw getQuickAccessResponse.data;
        // TODO: When it isResponseOk === true , check that it works correctly
      }

      return getQuickAccessResponse.data;

    case GET_REPORT:
      const reportPage = params.pagination.page || 1;
      const reportPerPage = params.pagination.perPage || 20;

      const ReportObjectForQueryParameters = {
        skip: (reportPage - 1) * reportPerPage,
        takeCount: reportPerPage,
        filters: JSON.stringify(params.filters),
        sort: params.sort?.field || null,
        sortType: params.sort?.order || null,
      };

      const reportQueryParameters = querystring.stringify(
        ReportObjectForQueryParameters,
      );

      const reportUrl = `${apiUrl}/${apiVersion}/report/${params?.reportId}?${reportQueryParameters}`;

      const getReportResponse = await httpClient.get(
        createNewUrlBaseOfSessionIdInUrl(reportUrl, sessionIdInUrl, apiUrl),
        requestConfig,
      );

      showWarningsSnackbar(getReportResponse);

      if (!isResponseOk(getReportResponse)) {
        throw getResponseMessage(getReportResponse);
      }

      return {
        data: getReportResponse.data.data,
        total: getReportResponse.data.totalCount,
        additionalData: getReportResponse.data?.additionalData,
      };

    case GET_DROPDOWN_DEFAULT_VALUES:
      const { processUniqueId, urlParameters } = params;

      const getDropdownDefaultValuesUrl = processUniqueId
        ? `${apiUrl}/${apiVersion}/${prefix}${resource}/dropdowns/defaultvalues/process/${processUniqueId}?${urlParameters}`
        : `${apiUrl}/${apiVersion}/${prefix}${resource}/dropdowns/defaultvalues?${urlParameters}`;

      const getDropdownDefaultValuesResponse = await httpClient.get(
        createNewUrlBaseOfSessionIdInUrl(
          getDropdownDefaultValuesUrl,
          sessionIdInUrl,
          apiUrl,
        ),
        requestConfig,
      );

      return getDropdownDefaultValuesResponse.data;

    case GET_DEFAULT_FORM_VALUES: {
      const { processUniqueId, parentId, parentResource } = params;

      let getDefaultValuesUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/defaultvalues`;
      if (processUniqueId && parentId) {
        getDefaultValuesUrl = `${apiUrl}/${apiVersion}/${prefix}${parentResource}/${parentId}/${resource}/defaultvalues/process/${processUniqueId}`;
      } else if (parentId) {
        getDefaultValuesUrl = `${apiUrl}/${apiVersion}/${prefix}${parentResource}/${parentId}/${resource}/defaultvalues`;
      } else if (processUniqueId) {
        getDefaultValuesUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/defaultvalues/process/${processUniqueId}`;
      }

      const getDefaultValuesResponse = await httpClient.get(
        createNewUrlBaseOfSessionIdInUrl(
          getDefaultValuesUrl,
          sessionIdInUrl,
          apiUrl,
        ),
        requestConfig,
      );

      return getDefaultValuesResponse.data;
    }

    case UPDATE_PROFILE:
      const profileData = clone(params.data);
      const profileUrl = `${apiUrl}/${apiVersion}/account/${apiName}/settings`;

      const profileResponse = await httpClient.put(
        createNewUrlBaseOfSessionIdInUrl(profileUrl, sessionIdInUrl, apiUrl),
        profileData,
        requestConfig,
      );

      checkResponseAndPlayAudio(profileResponse.data);
      checkResponseForOpenNewTab(profileResponse.data, params?.filters);

      if (rawResponse) {
        return profileResponse.data;
      }

      showWarningsSnackbar(profileResponse);

      if (!isResponseOk(profileResponse)) {
        shouldParseResponseError(profileResponse);
        throw shouldParseResponseError(profileResponse)
          ? profileResponse.data
          : shouldParseResponseAdditionalDataError(profileResponse)
          ? {
              requestId: profileResponse.data.requestId,
              data: profileResponse.data.additionalData[0].exception.data,
            }
          : getResponseMessage(profileResponse);
      }

      return {
        data: objectToLowerCaseProperties(profileResponse.data.data),
      };

    case GET_AUTHORITY_DELEGATION:
      const delegationUrl = `${apiUrl}/${apiVersion}/account/${apiName}/AuthorityDelegation`;

      const delegationResponse = await httpClient.get(
        createNewUrlBaseOfSessionIdInUrl(delegationUrl, sessionIdInUrl, apiUrl),
        requestConfig,
      );

      checkResponseAndPlayAudio(delegationResponse.data);
      checkResponseForOpenNewTab(delegationResponse.data, params?.filters);

      if (rawResponse) {
        return delegationResponse.data;
      }

      showWarningsSnackbar(delegationResponse);

      if (!isResponseOk(delegationResponse)) {
        shouldParseResponseError(delegationResponse);
        throw shouldParseResponseError(delegationResponse)
          ? delegationResponse.data
          : shouldParseResponseAdditionalDataError(delegationResponse)
          ? {
              requestId: delegationResponse.data.requestId,
              data: delegationResponse.data.additionalData[0].exception.data,
            }
          : getResponseMessage(delegationResponse);
      }

      return {
        data: objectToLowerCaseProperties(delegationResponse.data.data),
      };

    case PUT_AUTHORITY_DELEGATION:
      const putDelegationUrl = `${apiUrl}/${apiVersion}${resource}`;

      const putDelegationResponse = await httpClient.put(
        createNewUrlBaseOfSessionIdInUrl(putDelegationUrl, sessionIdInUrl, apiUrl),
        {},
        requestConfig,
      );

      checkResponseAndPlayAudio(putDelegationResponse.data);
      checkResponseForOpenNewTab(putDelegationResponse.data, params?.filters);

      if (rawResponse) {
        return putDelegationResponse.data;
      }

      showWarningsSnackbar(putDelegationResponse);

      if (!isResponseOk(putDelegationResponse)) {
        shouldParseResponseError(putDelegationResponse);
        throw shouldParseResponseError(putDelegationResponse)
          ? putDelegationResponse.data
          : shouldParseResponseAdditionalDataError(putDelegationResponse)
          ? {
              requestId: putDelegationResponse.data.requestId,
              data: putDelegationResponse.data.additionalData[0].exception.data,
            }
          : getResponseMessage(putDelegationResponse);
      }

      return {
        data: objectToLowerCaseProperties(putDelegationResponse.data.data),
      };

    case POST_STREAM_FILE:
      let streamData = clone(params.data);
      delete streamData._meta;
      // if there is a file attached, send differently
      requestConfig.headers['content-type'] = 'multipart/form-data';

      streamData = new FormData();
      streamData.append('file', params.data.file);
      for (const key in params.data) {
        if (key === 'file') continue;
        streamData.append(key, params.data[key]);
      }

      const streamUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}`;

      const streamResponse = await httpClient.post(
        createNewUrlBaseOfSessionIdInUrl(streamUrl, sessionIdInUrl, apiUrl),
        streamData,
        requestConfig,
      );

      checkResponseAndPlayAudio(streamResponse.data);
      checkResponseForOpenNewTab(streamResponse.data, params?.filters);

      if (rawResponse) {
        return streamResponse.data;
      }

      showWarningsSnackbar(streamResponse);

      if (!isResponseOk(streamResponse)) {
        throw shouldParseResponseError(streamResponse)
          ? streamResponse.data
          : shouldParseResponseAdditionalDataError(streamResponse)
          ? {
              requestId: streamResponse.data.requestId,
              data: streamResponse.data.additionalData[0].exception.data,
            }
          : getResponseMessage(streamResponse);
      }
      const { additionalData: streamAdditionalData = {} } = streamResponse.data;

      return {
        ...streamAdditionalData,
        data: streamResponse.data.data,
      };

    case POST_STREAM_MULTIPLE_FILE: {
      const {
        successCallback,
        successAllFilesUploadedCallback,
        failureCallback,
        data,
      } = params;
      let multipleStreamData = lodashClone(data);

      const streamErrors = [];
      const streamSuccessResponse = [];

      if (data.files == null || data.files.length === 0) return;

      requestConfig.headers['content-type'] = 'multipart/form-data';
      for (const file of data.files) {
        try {
          multipleStreamData = new FormData();
          multipleStreamData.append('file', file);

          for (const key in data) {
            if (key === 'files') continue;
            multipleStreamData.append(key, data[key]);
          }

          // create url
          const streamUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}`;

          // make request
          const multipleFilesStreamResponse = await httpClient.post(
            createNewUrlBaseOfSessionIdInUrl(streamUrl, sessionIdInUrl, apiUrl),
            multipleStreamData,
            requestConfig,
          );

          checkResponseAndPlayAudio(multipleFilesStreamResponse.data);
          checkResponseForOpenNewTab(
            multipleFilesStreamResponse.data,
            params?.filters,
          );

          showWarningsSnackbar(multipleFilesStreamResponse);

          if (!isResponseOk(multipleFilesStreamResponse)) {
            const errorMessage = shouldParseResponseError(
              multipleFilesStreamResponse,
            )
              ? multipleFilesStreamResponse.data
              : shouldParseResponseAdditionalDataError(multipleFilesStreamResponse)
              ? {
                  requestId: multipleFilesStreamResponse.data.requestId,
                  data: multipleFilesStreamResponse.data.additionalData[0].exception
                    .data,
                }
              : getResponseMessage(multipleFilesStreamResponse);

            streamErrors.push({
              fileName: file.name,
              message: errorMessage,
            });
          }

          const { additionalData: streamAdditionalData = {} } =
            multipleFilesStreamResponse.data;

          successCallback({
            ...streamAdditionalData,
            data: multipleFilesStreamResponse.data.data,
            size: file.size,
          });
          streamSuccessResponse.push(multipleFilesStreamResponse.data.data);
        } catch (error) {
          const errorMessage = error.message ? String(error.message) : String(error);

          streamErrors.push({
            fileName: file.name,
            message: errorMessage,
          });
        }
      }
      successAllFilesUploadedCallback?.(streamSuccessResponse);
      if (streamErrors.length) failureCallback(streamErrors);
      break;
    }

    case LOG_ERROR: {
      const logUrl = `${apiUrl}/${apiVersion}/client-log`;
      await httpClient.post(logUrl, params, requestConfig);
      break;
    }

    case CUSTOM_GET: {
      const urlGet = `${apiUrl}/${prefix}${resource}`;

      const getData = await httpClient.get(
        createNewUrlBaseOfSessionIdInUrl(urlGet, sessionIdInUrl, apiUrl),
        requestConfig,
      );
      return getData;
    }

    case CUSTOM_POST: {
      const sendUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}`;

      const sendData = await httpClient.post(
        createNewUrlBaseOfSessionIdInUrl(sendUrl, sessionIdInUrl, apiUrl),
        params?.data,
        requestConfig,
      );
      return sendData;
    }

    default: {
      // console.log(type + ' is not defined!', resource, params);
      return { data: null };
    }
  }
};

export default dataProvider;
