/* eslint-disable @typescript-eslint/no-empty-function */
import lodashDebounce from 'lodash/debounce';
import { useState, useEffect, ChangeEvent, KeyboardEvent, useMemo } from 'react';
import lodashIsEqual from 'lodash/isEqual';
import { useLocale } from 'react-admin';
import lodashGet from 'lodash/get';

import { isEmpty, isEmptyObject } from '../helper/data-helper';
import { useInputUiControl } from './useInputUiControl-old';
import {
  TYPE_CODING,
  BOOLEAN_FIELD,
  DATE_FIELD,
  dummyFunc,
  getTypeByField,
  TYPE_SEARCH_INPUT,
} from '../helper/InputHelper';
import {
  isUpPressed,
  isEnterPressed,
  isCtrlEnterPressed,
  isDownPressed,
} from '../helper/FormHelper';
import {
  InputLogicType,
  InputPropsType,
} from '../component/dynamicInput/InputTypes';

export const useInputLogic = (props: InputLogicType) => {
  const {
    initialValues = {},
    field,
    version,
    viewVersion,
    inputInPuzzleForm,
    inputInQuickCreateDialog,
    isCreateMode,
    changeFormValue = dummyFunc,
    globalParameters,
    formData,
    dropdownState,
    metaData = {},
    triggerDropdownFetchData,
    findDropdownData,
    validationErrors,
    dispatch, // get it out
    source,
    resource = '',
    getInputRef, // get it out
    triggerFormSubmit,
    noLabel,
    label,
    focusOnNextInput,
    updateDefaultValue = dummyFunc, // get it out
    addToIgnoreFocusOnInit = dummyFunc, // get it out
    getRelationRef, // it should be used by "RelationFormIterator"
    recordPath,
    changeFocusOnAnotherRow,
    clearValidationErrorForThisField,
    additionalProps,
    isServiceMode,
    ...rest
  } = props;

  const [prevInitialValues, setPrevInitialValues] = useState<object>(initialValues);
  const [prevVersion, setPrevVersion] = useState<number>(0);
  const [element, setElement] = useState<HTMLInputElement | null>(null);

  /**
   * Handle UI Enable & UI Visible via a custom hook
   */
  const { uiVisibled, uiEnabled } = useInputUiControl(props);

  const locale: string = useLocale();

  /**
   * Handle initial values
   */
  useEffect(() => {
    // for handle initial values
    const { name = '' } = field;
    const computedVersion = version ? version : viewVersion;
    const tabChangeCondition =
      prevVersion !== computedVersion &&
      prevInitialValues[name] === null &&
      initialValues[name] === null;

    if (
      inputInPuzzleForm ||
      inputInQuickCreateDialog ||
      isServiceMode ||
      (isCreateMode && computedVersion)
    ) {
      if (!lodashIsEqual(prevInitialValues, initialValues) || tabChangeCondition) {
        const value =
          !isEmptyObject(initialValues) && !isEmpty(initialValues[name])
            ? initialValues[name]
            : getTypeByField(field) === BOOLEAN_FIELD
            ? false
            : null;
        const allowChangeEdited =
          getTypeByField(field) === DATE_FIELD ||
          getTypeByField(field) === BOOLEAN_FIELD
            ? true
            : false;
        changeFormValue(name, value, allowChangeEdited);
        setPrevInitialValues(initialValues);
      }
      setPrevVersion(computedVersion);
    }
  }, [initialValues, version]);

  /**
   * Component did mount
   */
  useEffect(() => {
    const {
      defaultValue,
      name = '',
      keepFocusAfterSubmit,
      keepValueAfterSubmit,
      globalParameter,
    } = field;

    if (!isEmpty(globalParameter) && isEmpty(formData![name])) {
      updateDefaultValue(
        name,
        lodashGet(globalParameters, globalParameter!.toLowerCase()),
      );
    }

    const isBoolean = getTypeByField(field) === BOOLEAN_FIELD;
    if (!isEmpty(defaultValue) || keepValueAfterSubmit) {
      // because boolean fields default value is '1' or '0' in field object !
      // this variable below turn the value to true or false if it was BOOLEAN_FIELD.
      let adoptedDefaultValue = defaultValue;

      if (
        (isBoolean && defaultValue == '0') ||
        (isBoolean && isEmpty(defaultValue))
      ) {
        adoptedDefaultValue = false;
      } else if (isBoolean && defaultValue == '1') {
        adoptedDefaultValue = true;
      } else {
        // if it wasn't a BOOLEAN_FIELD
        adoptedDefaultValue = defaultValue;
      }

      updateDefaultValue(
        name,
        initialValues && (initialValues[name] || initialValues[name] === false)
          ? initialValues[name]
          : adoptedDefaultValue,
      );
    } else if (isBoolean) {
      updateDefaultValue(name, false);
    }

    if (!keepFocusAfterSubmit && typeof addToIgnoreFocusOnInit === 'function') {
      addToIgnoreFocusOnInit(name);
    }
  }, []);

  /**
   * Find current input validation error
   * @function findCustomErrors
   * @returns {string}
   */
  const findCustomErrors = (): string => {
    let customError = '';
    if (validationErrors && validationErrors.length) {
      const selectedError = validationErrors.filter(err => err.name === field.name);
      if (selectedError && selectedError.length) {
        customError = selectedError[0].message;
      }
    }
    return customError;
  };

  /**
   * its a closure that will be handle dropdown change and also change che form value
   * for all fields that exist in changed dropdoen "map" parameter in meta.
   * also call function for changed dropdown and any other
   * fields that hass been change in map process if exist.
   * @function handleDropdownChange
   * @param {string} mapFieldName
   * @returns {function}
   */
  const handleDropdownChange =
    (mapFieldName: string): Function =>
    (value: object, selectedRecord: object, skipIfFormHasAlreadyValue = false) => {
      const { record, changeFormValue = dummyFunc, field, recordPath } = props;

      // like in field.dropdown.maps
      const mapArray: { to: string; from: string }[] = lodashGet(field, [
        mapFieldName,
        'maps',
      ]);
      // search input must put all values in the form
      if (mapFieldName === TYPE_SEARCH_INPUT && selectedRecord) {
        Object.keys(selectedRecord).forEach(key => {
          const target = key.toLowerCase();
          if (skipIfFormHasAlreadyValue && !isEmpty(record[target])) {
            return;
          }

          changeFormValue(target, selectedRecord[key]);
        });
      }

      if (mapFieldName === TYPE_CODING) {
        Object.keys(value).forEach(key => {
          const target = key.toLowerCase();
          if (skipIfFormHasAlreadyValue && !isEmpty(record[target])) {
            return;
          }
          // TODO: remove `setTimeout` after form refactor.
          setTimeout(() => {
            changeFormValue(target, value[key]);
          }, 500);
        });
      }

      if (!mapArray || !Array.isArray(mapArray) || !mapArray.length) {
        return;
      }

      const prefix = recordPath ? recordPath + '.' : '';
      // trigger change in other form inputs
      mapArray.forEach(item => {
        const target = `${prefix}${item.to}`;
        if (skipIfFormHasAlreadyValue && !isEmpty(lodashGet(record, target))) {
          return;
        }

        const targetValue: string | undefined = lodashGet(selectedRecord, item.from);
        // if "item.from" is not defined, no need to trigger a change in form
        if (typeof targetValue !== 'undefined') {
          changeFormValue(target, targetValue);
        }
      });
    };

  /**
   * Handle Submit (On Ctrl + Enter press for WMS)
   * @var triggerSubmit
   * @returns { Function }
   */
  const triggerSubmit: Function = lodashDebounce((): void => {
    const { triggerFormSubmit } = props;

    if (typeof triggerFormSubmit === 'function') {
      triggerFormSubmit();
    } else {
      console.log('triggerFormSubmit is not defined, so can not trigger submit');
    }
  }, 200);

  /**
   * Go to next input (on Enter press)
   * @function triggerGoToNext
   * @returns { void }
   */
  const triggerGoToNext = (): void => {
    const { focusOnNextInput, field } = props;

    if (typeof focusOnNextInput === 'function') {
      focusOnNextInput(field.name);
    }
  };

  /**
   * Go to next row (On Down arrow press)
   * @function triggerChangeFocusOnAnotherRow
   * @param { boolean } isMoveToNext
   * @returns { void }
   */
  const triggerChangeFocusOnAnotherRow = (isMoveToNext: boolean): void => {
    const { changeFocusOnAnotherRow, field } = props;

    if (typeof changeFocusOnAnotherRow === 'function') {
      changeFocusOnAnotherRow(field.name, isMoveToNext);
    }
  };

  /**
   * Handle special keys press
   * @function handleKeyDown
   * @param { SyntheticEvent } event
   * @returns { void }
   */
  const handleKeyDown = (event: KeyboardEvent<HTMLElement>): void => {
    // if form is submitted
    if (isCtrlEnterPressed(event)) {
      event.preventDefault();
      event.stopPropagation();

      triggerSubmit();
    }
    // if wants to go to next element
    else if (isEnterPressed(event)) {
      event.preventDefault();
      event.stopPropagation();

      triggerGoToNext();
    } else if (isDownPressed(event)) {
      event.preventDefault();
      event.stopPropagation();

      triggerChangeFocusOnAnotherRow(true);
    } else if (isUpPressed(event)) {
      event.preventDefault();
      event.stopPropagation();

      triggerChangeFocusOnAnotherRow(false);
    }
  };

  /**
   * this function will recive a new value and also extract the name of field from props
   * then handle the form change value and update form value , default value and editted foem data.
   * @function internalOnChange
   * @param {ChangeEvent<HTMLInputElement>} value
   * @returns {void}
   */
  const internalOnChange = (value: ChangeEvent<HTMLInputElement>): void => {
    const { keepValueAfterSubmit, name } = field;
    let tempValue: ChangeEvent<HTMLInputElement> | boolean = value;

    // eslint-disable-next-line no-prototype-builtins
    if (value && typeof value === 'object' && value.hasOwnProperty('nativeEvent')) {
      tempValue = value.target.checked; // only BooleanInput
    }

    // if value should be kept when form is reset, keep value in default value
    if (keepValueAfterSubmit) {
      updateDefaultValue(name, tempValue);
    }

    if (typeof changeFormValue === 'function') {
      changeFormValue(name, tempValue);
    }

    // for validation in relation
    if (typeof clearValidationErrorForThisField === 'function') {
      clearValidationErrorForThisField(name);
    }
  };

  /**
   * Determine if the input is focusable or not
   * @function isFocusable
   * @returns {boolean}
   */
  const isFocusable = (): boolean => {
    const { field, disabled } = props;
    return !disabled && !field.readOnly && !field.disabled && uiEnabled;
  };

  /**
   * Access to element refrence
   * @function getDynamicInputRef
   * @param { HTMLInputElement } ref
   * @returns { void }
   */
  const getDynamicInputRef = (ref: HTMLInputElement): void => {
    const { field, getInputRef = dummyFunc } = props;

    setElement(ref);

    if (isFocusable()) {
      getInputRef(field.name || '', ref, resource);
    }
  };

  /**
   * Select input contents on user click
   * @function handleClick
   * @returns { void }
   */
  const handleClick = (): void => {
    if (element && typeof element.select === 'function') {
      element!.select();
    }
  };

  const isInputFocusable = useMemo(
    () => isFocusable(),
    [field, props.disabled, uiEnabled],
  );

  const inputProps: InputPropsType = {
    'data-test-field-name': field.name || '',
    'data-test-max-value': field.maxValue ? field.maxValue + '' : '',
    'data-test-min-value': field.minValue ? field.minValue + '' : '',
    'data-test-max-length': field.maxLength ? field.maxLength + '' : 'dosent_matter',
    'data-test-field-type': getTypeByField(field),
    'data-test-field-hidden': field.hidden ? field.hidden + '' : 'false',
    field,
    formData,
    source,
    resource,
    label: !noLabel
      ? label || lodashGet(field, ['translatedCaption', locale], field.caption)
      : '',
    // required: field.required, // validation will handle required
    disabled: !isInputFocusable,
    options: {
      inputProps: {
        disabled: !isInputFocusable,
      },
      inputRef: getDynamicInputRef,
      onKeyDown: isInputFocusable ? handleKeyDown : undefined,
    },
    onChange: internalOnChange,
    visibleClass: uiVisibled && !field.hidden ? '' : 'displayNone',
    customError: findCustomErrors(),
    viewVersion,
  };

  if (field.widthPercent) {
    Object.assign(inputProps, { style: { width: `${field.widthPercent}%` } });
  }

  const viewProps = {
    ...rest,
    field,
    inputProps,
    resource,
    inputInPuzzleForm,
    isServiceMode,
    noLabel,
    additionalProps,
    element,
    isInputFocusable,
    changeFormValue,
    handleClick,
    handleDropdownChange,
    triggerGoToNext,
  };

  return { viewProps };
};
