import { ReactElement, useEffect } from 'react';

import { actorGetActionValue, actorRemoveAction } from '../../type/actor-setup';
import { WMSFetchMetaResult } from './wms.type';
import WMSLayout from './wms.view';
import NotFound from '../NotFound';

const WMSController = (): ReactElement | null => {
  const currentResource = actorGetActionValue('resources')!.current;
  const wmsMetaData = actorGetActionValue(
    'metaData',
    currentResource.value,
  ) as unknown as WMSFetchMetaResult;

  useEffect(() => {
    return () => {
      actorRemoveAction({
        actionName: 'formData',
        prune: true,
      });
    };
  }, []);

  if (wmsMetaData?.data == null) {
    return null;
  }

  if (wmsMetaData.error != null) {
    return <NotFound title={wmsMetaData.error} />;
  }

  return (
    <>
      <WMSLayout wmsMetaData={wmsMetaData.data!} />
    </>
  );
};

export default WMSController;
