import { FC, memo, useState, useEffect } from 'react';
import lodashGet from 'lodash/get';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../../../../type/actor-setup';
import {
  MessagesDataInterface,
  MessagesScreenInterface,
} from './messages-screen.type';
import MessagesScreenView from './messages-screen.view';
import { ChatInterface } from '../..';

const MessagesScreenController: FC<MessagesScreenInterface> = memo(props => {
  const { chatActionsHandler, selectedUser, currentUser } = props;

  const [messagesData, setMessagesData] = useState<MessagesDataInterface>({
    data: [],
    hasMore: true,
  });
  const [messagesLoading, setMessagesLoading] = useState<boolean>(false);

  useEffect(() => {
    actorOnDispatch('loading', loading => {
      loading != undefined &&
        setMessagesLoading(lodashGet(loading, [`messagesLoading`]));
    });

    actorOnDispatch('messagesData', (newMessagesData: MessagesDataInterface) => {
      newMessagesData && setMessagesData(newMessagesData);
    });
  }, []);

  useEffect(() => {
    if (selectedUser && messagesData?.data?.[0]) {
      const currentChatsData = actorGetActionValue('chatsData')!;
      actorDispatch('chatsData', {
        ...currentChatsData,
        data: currentChatsData?.data?.map((item: ChatInterface) =>
          item.personinfo_id === selectedUser.personinfo_id
            ? {
                ...item,
                chattext: messagesData.data[0].chattext,
                chatdate: messagesData.data[0].chatdate,
              }
            : item,
        ),
      });
    }
  }, [messagesData?.data?.[0]]);

  return (
    <MessagesScreenView
      chatActionsHandler={chatActionsHandler}
      currentUser={currentUser}
      selectedUser={selectedUser}
      userMessages={messagesData?.data}
      hasMoreMessages={messagesData.hasMore}
      messagesLoading={messagesLoading}
    />
  );
});

export default MessagesScreenController;
