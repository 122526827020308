import React, { Fragment, useState, useEffect } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { Button, Card, Icon, Typography, withStyles } from '@material-ui/core';
import { Responsive, NumberField, translate } from 'react-admin';

import ShopAddToCartButtonCounter from './ShopAddToCartButtonCounter';
import { isEmpty } from '../../helper/data-helper';
import { CONFIG_CURRENCY_SYMBOL, getValue } from '../../core/configProvider';

const styles = theme => ({
  addToCartStyle: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.appSecondaryTextColor,
    display: 'flex',
    justifyContent: 'space-between',
    padding: 6,
    alignItems: 'center',
  },

  divider: {
    border: `1px solid ${theme.palette.primary.appSecondaryTextColor}`,
    height: 40,
  },

  addToCartText: {
    padding: 5,
    flex: 1,
    textAlign: 'center',
  },

  addToCartChip: {
    flex: 1,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  addToCartContainer: {},

  cardContainer: {
    padding: 10,
    minWidth: 300,
    display: 'flex',
    flexDirection: 'column',
  },

  priceContainer: {
    marginBottom: 10,
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },

  productPriceStrike: {
    textDecoration: 'line-through',
    color: theme.palette.text.disabled,
  },

  numberFieldDiscount: {
    display: 'inline',
    fontSize: 28,
  },

  addToCartPrice: {
    color: theme.palette.primary.appSecondaryTextColor,
    display: 'inline',
  },

  addToCartPriceCurrency: {
    fontSize: 12,
    color: theme.palette.primary.appSecondaryTextColor,
  },
});

const SellinProductAddToCartButton = props => {
  const {
    classes,
    theme,
    field,
    translate,
    notifyCountChange,
    count,
    patternData,
    onDelete,
    basketInfo,
  } = props;

  const { price, discount, id } = patternData;

  const [counter, setCounter] = useState(count);

  useEffect(() => {
    setCounter(count);
  }, [count]);

  const addToCart = () => {
    setCounter(counter + 1);
    notifyCountChange(field, counter + 1);
  };

  const inputType = event => {
    const parsedValue = parseFloat(event.target.value);
    const changedValue = isNaN(parsedValue) || parsedValue <= 1 ? 1 : parsedValue;

    notifyCountChange(field, changedValue);
  };

  const removeOfCart = () => {
    if (counter === 1) {
      setCounter(counter - 1);
      onDelete(basketInfo[id]);
    } else {
      setCounter(counter - 1);
      notifyCountChange(field, counter - 1);
    }
  };

  return (
    <Fragment>
      <Responsive
        medium={
          counter > 0 ? (
            <div
              className={classes.addToCartStyle}
              style={{
                borderTop: `1px solid ${theme.palette.primary.main}`,
                backgroundColor: theme.palette.primary.appPrimaryBackgroundColor,
              }}
            >
              <div className={classes.addToCartChip}>
                <ShopAddToCartButtonCounter
                  counter={counter}
                  onRemoveButton={removeOfCart}
                  onAddButton={addToCart}
                  onInputType={inputType}
                />
              </div>
              <div
                className={classes.divider}
                style={{
                  border: `1px solid ${theme.palette.primary.appPrimaryTextColor}`,
                }}
              />
              <div className={classes.addToCartText}>
                <NumberField
                  className={classes.addToCartPrice}
                  source={'value'}
                  record={{ value: field[price] }}
                  style={{ color: theme.palette.primary.appPrimaryTextColor }}
                />
                <span
                  className={classes.addToCartPriceCurrency}
                  style={{ color: theme.palette.primary.appPrimaryTextColor }}
                >
                  {' '}
                  {getValue(CONFIG_CURRENCY_SYMBOL) ||
                    translate('shop.basket.currency')}
                </span>
              </div>
            </div>
          ) : (
            <div className={classes.addToCartStyle} onClick={addToCart}>
              <Typography className={classes.addToCartText}>
                {translate('sellin.addToCart')}
              </Typography>
              <div className={classes.divider} />
              <div className={classes.addToCartText}>
                <NumberField
                  className={classes.addToCartPrice}
                  source={'value'}
                  record={{ value: field[price] }}
                />
                <span className={classes.addToCartPriceCurrency}>
                  {' '}
                  {getValue(CONFIG_CURRENCY_SYMBOL) ||
                    translate('shop.basket.currency')}
                </span>
              </div>
            </div>
          )
        }
        large={
          <div className={classes.addToCartContainer}>
            <Card className={classes.cardContainer}>
              <div className={classes.priceContainer}>
                {!isEmpty(field[patternData.discount]) && (
                  <NumberField
                    className={classes.productPriceStrike}
                    source={'value'}
                    record={{ value: field[price] }}
                  />
                )}
                <div>
                  <NumberField
                    className={classes.numberFieldDiscount}
                    source={'value'}
                    record={{
                      value: !isEmpty(field[discount])
                        ? field[discount]
                        : field[price],
                    }}
                  />
                  <span
                    className={classes.addToCartPriceCurrency}
                    style={{ color: theme.palette.primary.appPrimaryTextColor }}
                  >
                    {' '}
                    {getValue(CONFIG_CURRENCY_SYMBOL) ||
                      translate('shop.basket.currency')}
                  </span>
                </div>
              </div>
              {counter > 0 ? (
                <ShopAddToCartButtonCounter
                  counter={counter}
                  onRemoveButton={removeOfCart}
                  onAddButton={addToCart}
                  onInputType={inputType}
                />
              ) : (
                <Button
                  color="secondary"
                  variant="contained"
                  size="large"
                  onClick={addToCart}
                  style={{ color: theme.palette.primary.appSecondaryTextColor }}
                >
                  <Icon>shopping_cart</Icon>
                  &nbsp;&nbsp;{translate('sellin.addToCart')}
                </Button>
              )}
            </Card>
          </div>
        }
      />
    </Fragment>
  );
};

SellinProductAddToCartButton.propTypes = {
  patternData: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  notifyCountChange: PropTypes.func.isRequired,
  count: PropTypes.number,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  translate,
)(SellinProductAddToCartButton);
