import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getTodoSharedListMemberPatternInfo } from '../../helper/PatternMetaHelper';
import { Typography } from '@material-ui/core';
import { getIconClassName } from '@uifabric/styling';
import PropTypes from 'prop-types';

import ProfileAvatar from '../ProfileAvatar';
import LoadingBox from '../LoadingBox';
import { CustomTheme } from '../../core/themeProvider';

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },

  main: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    cursor: 'pointer',
  },

  name: {
    margin: '0 7px',
  },

  icon: {
    margin: 10,
    fontSize: 12,
    color: theme.palette.primary.appPrimaryIconColor,
    cursor: 'pointer',
  },
}));

const TodoSharedMemberItem = props => {
  const {
    member,
    sharedListMemberResource,
    removeMember,
    disableRemoveMember,
    onClick,
  } = props;
  const classes = useStyles(props);

  if (!member) {
    return <LoadingBox size={20} />;
  }

  const { personId, name } = getTodoSharedListMemberPatternInfo(
    sharedListMemberResource,
  );

  const handleRemoveMember = () => {
    removeMember(member);
  };

  const handleClick = event => {
    if (typeof onClick === 'function') {
      onClick(member)();
    }
  };

  return (
    <div className={classes.container} data-test-list-share-member>
      <div className={classes.main} onClick={handleClick}>
        <ProfileAvatar userId={member[personId]} />
        <Typography className={classes.name} variant="body2">
          {member[name]}
        </Typography>
      </div>
      {!disableRemoveMember && (
        <i
          onClick={handleRemoveMember}
          className={`${classes.icon} ${getIconClassName('cancel')}`}
        ></i>
      )}
    </div>
  );
};

TodoSharedMemberItem.propTypes = {
  listResource: PropTypes.string,
  sharedListMemberResource: PropTypes.string,
  member: PropTypes.object,
  classes: PropTypes.object,
  disableRemoveMember: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TodoSharedMemberItem;
