import React from 'react';
import { useInput } from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core';
import { convertDigitsToEnglish } from '../../helper/NumberHelper';

// TODO: Make TextFilterInput && NumberFilterInput a single Component

interface TextFilterInputType {
  value: string | number | null;
  onChange: Function;
  source?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    border: 'none',
    outline: 'none',
    width: '100%',
    fontFamily: theme.typography.fontFamily,
  },
}));

const TextFilterInput = (props: TextFilterInputType) => {
  const { value } = props;
  const classes = useStyles();

  const {
    input: { onChange },
  } = useInput(props);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(convertDigitsToEnglish(event.target.value));
    props.onChange(convertDigitsToEnglish(event.target.value));
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <input
        value={value || ''}
        onChange={handleChange}
        className={classes.input}
        data-test-field-name={props.source}
      />
    </div>
  );
};

export default TextFilterInput;
