import React from 'react';

import {
  TODO,
  FILE_MANAGER,
  MAIL,
  CHAT,
  POS,
  SELLIN_SHOW,
  CART,
  TREE,
  MENU,
  WMS,
  CALENDAR,
  SELLIN_LIST,
  FORM_LAYOUT,
  MULTI_REPORT,
  SINGLE_RECORD,
  CATEGORY_LIST,
  SELLIN_HISTORY_ORDER,
  SELLIN_HISTORY_ORDER_DETAIL,
  SELLIN_FINALIZE,
  SELLIN_SEARCH,
  SELLIN_SELECT_COMPANY,
  VISITOR_CALENDAR,
  REPORT_CALENDAR,
  MAP,
  PIVOT_TABLE,
} from '../core/configRouteConstant';
import PrivateRoute from '../container/PrivateRoute';
import TodoPage from './TodoPage';
import MenuPage from './MenuPage';
import ShowCartPage from './ShowCartPage';
import TreeListPage from './TreeListPage';
import CalendarPage from './CalendarPage';
import CategoryPage from './CategoryPage';
import ListRecordPage from './ListRecordPage';
import ShowRecordPage from './ShowRecordPage';
import SingleRecordPage from './SingleRecordPage';
import CreateEditRecordPage from './CreateEditRecordPage';
import SellinProductShowPage from './SellinProductShowPage';
import MultipleReportListPage from './MultipleReportListPage';
import PointOfSaleView from '../component/pos/PointOfSaleView';
import SellinProductListPage from './SellinProductListPage';
import SellinHistoryOrderPage from './SellinHistoryOrderPage';
import SellinHistoryOrderDetailPage from './SellinHistoryOrderDetailPage';
import SellinFinalizeBasketPage from './SellinFinalizeBasketPage';
import SellinSearchPage from './SellinSearchPage';
import SellinCompanyPage from './SellinCompanyPage';
import WMSPage from './wms-page';
import FileManagerPage from './FileManagerPage';
import VisitorCalendarPage from './VisitorCalendarPage';
import InboxPage from './InboxPage';
import ChatPage from './ChatPage';
import { PivotTable } from '../component/pivot-table';
import CustomCssController from '../component/custom-css/custom-css-controller';

let FormLayoutPage = null;
// that component has some issues with jest, so we will exclude it in test env
if (
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'production'
) {
  FormLayoutPage = require('./FormLayoutPage').default;
}

// NOTE: first pattern that matches will be used
// prettier-ignore
const list = [
  <PrivateRoute
    exact
    path={`/${SELLIN_SELECT_COMPANY}`}
    component={SellinCompanyPage}
  />,
  <PrivateRoute
    exact
    path={`/${SELLIN_SEARCH}/:moduleName`}
    component={SellinSearchPage}
  />,
  <PrivateRoute
    exact
    path={`/${SELLIN_FINALIZE}/:moduleName/:moduleTableName/`}
    component={SellinFinalizeBasketPage}
  />,
  <PrivateRoute
    exact
    path={`/${SELLIN_HISTORY_ORDER_DETAIL}/:moduleName/:moduleTableName/:id`}
    component={SellinHistoryOrderDetailPage}
  />,
  <PrivateRoute
    exact
    path={`/${SELLIN_HISTORY_ORDER}/:moduleName/:moduleTableName`}
    component={SellinHistoryOrderPage}
  />,
  <PrivateRoute
    exact
    path={`/${SELLIN_LIST}/:moduleName/:moduleTableName`}
    component={SellinProductListPage}
  />, // card list
  <PrivateRoute
    exact
    path={`/${SELLIN_SHOW}/:moduleName/:moduleTableName/:id`}
    component={SellinProductShowPage}
  />,
  <PrivateRoute
    exact
    path={`/${CATEGORY_LIST}/:moduleName/:moduleTableName`}
    component={CategoryPage}
  />,
  <PrivateRoute
    exact
    path={`/${CATEGORY_LIST}/:moduleName/:moduleTableName/:id`}
    component={CategoryPage}
  />,
  <PrivateRoute
    exact
    path={`/${CART}/:moduleName/:moduleTableName`}
    component={ShowCartPage}
  />,
  <PrivateRoute
    exact
    path={`/${TODO}/:moduleName/:moduleTableName`}
    component={TodoPage}
  />,
  <PrivateRoute
    exact
    path={`/${POS}/:moduleName/:moduleTableName`}
    component={PointOfSaleView}
  />,
  <PrivateRoute
    exact
    path={`/${SINGLE_RECORD}/:moduleName/:moduleTableName`}
    component={SingleRecordPage}
  />,
  <PrivateRoute
    exact
    path={`/${MULTI_REPORT}/:reportId/:tabIndex?`}
    component={MultipleReportListPage}
  />,
  <PrivateRoute
    exact
    path={`/${CALENDAR}/:moduleName/:moduleTableName`}
    component={CalendarPage}
  />,
  <PrivateRoute
    exact
    path={`/${TREE}/:moduleName/:moduleTableName`}
    component={TreeListPage}
  />,
  <PrivateRoute exact path={`/${MENU}/:id`} component={MenuPage} />,
  <PrivateRoute exact path={`/${WMS}`} component={MenuPage} />,
  <PrivateRoute exact path={`/${WMS}/:id`} component={WMSPage} />,
  <PrivateRoute exact path={`/${FILE_MANAGER}`} component={FileManagerPage} />,
  <PrivateRoute exact path={`/${MAIL}`} component={InboxPage} />,
  <PrivateRoute exact path={`/${CHAT}`} component={ChatPage} />,
  <PrivateRoute
    exact
    path={`/${VISITOR_CALENDAR}`}
    component={VisitorCalendarPage}
  />,
  <PrivateRoute exact path={`/${PIVOT_TABLE}/:id`} component={PivotTable} />,
  <PrivateRoute
    exact
    path={`/:moduleName/:moduleTableName/create`}
    component={CreateEditRecordPage}
  />,
  <PrivateRoute
    exact
    path={`/:moduleName/:moduleTableName/:id/show`}
    component={ShowRecordPage}
  />,
  <PrivateRoute
    exact
    path={`/:moduleName/:moduleTableName/:id`}
    component={CreateEditRecordPage}
  />,
  <PrivateRoute
    exact
    path={`/:moduleName/:moduleTableName`}
    component={ListRecordPage}
  />,
  <PrivateRoute exact path={'/customcss/'} component={CustomCssController} />,
];

if (FormLayoutPage) {
  // add it as first parameter in list, because of higher pattern matching
  list.unshift(
    <PrivateRoute
      exact
      path={`/${FORM_LAYOUT}/:moduleName/:moduleTableName`}
      component={FormLayoutPage}
    />,
  );
}

export default list;
