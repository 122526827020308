import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { Icon, IconButton, withStyles } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DragHandleIcon from '@material-ui/icons/DragHandle';

import { getInputWidth } from '../../helper/InputHelper';
import DynamicInput from '../dynamicInput/DynamicInput';
import { getColorById } from '../../helper/RowColorHelper';
import { handleServerSideValidationErrors } from '../../helper/validation-helper';
import { isEmptyObject } from '../../helper/data-helper';

const styles = theme => ({
  hoverRow: {
    '&:hover button': {
      opacity: 1,
      cursor: 'pointer',
      transition: 'opacity 200ms',
    },
  },

  rowOrderCell: {
    width: 26,
    padding: 0,
  },

  arrowCellIconButton: {
    padding: 1,
  },

  TableBodyCellAction: {
    padding: 0,
    width: 40,
    textAlign: 'center',
  },

  deleteActionCellIconButton: {
    padding: '7px 2px',
    opacity: 0,
    cursor: 'default',
    transition: 'opacity 200ms',
  },

  icon: {
    margin: '0 5px',
    color: theme.palette.action.active,
  },

  bodyTableCell: {
    padding: '0 10px',
  },

  inputStyle: {
    margin: 0,
  },

  rowWithError: {
    height: 30,
  },

  cellWithError: {
    margin: 0,
    color: theme.palette.error.main,
  },

  dragHandleIconStyle: {
    color: '#747474',
    cursor: 'move',
    padding: '6px 5px 0 5px',
    fontSize: 34,
    margin: '7px 10px 7px 10px',
  },
});

class RelationFormIteratorRow extends PureComponent {
  render() {
    const {
      classes,
      basePath,
      resource,
      row,
      member,
      allowMoveFields,
      relationInputList,
      ignoreShowingColumnObj,
      onRemove,
      changeFocusOnAnotherRow,
      getRefFromInput,
      handleEnterPress,
      onDragStart,
      onDragEnd,
      onDragEnter,
      onDrop,
      onDragOver,
      relationResource,
      relationSource,
      relationInfo,
      draggable,
      changeFormValue,
      addToRelationArray,
      disableDropdownQuickCreate,
      disableDropdownSearchPopup,
      formData,
      relationMetaData,
      relationPath,
      translate,
      rowIndex,
      exception,
      locale,
      allRelationExceptions,
      setRelationExceptions,
    } = this.props;

    const thisRowException =
      exception.length > 0 ? lodashGet(exception[0], 'exception') : null;

    // run handleApiErrors when an exception happen
    const dirtyFields =
      thisRowException && !isEmptyObject(thisRowException['Data'])
        ? handleServerSideValidationErrors(
            relationMetaData,
            relationInputList,
            {
              apiErrors: thisRowException['Data'],
              requestId: '', // doesn't matter because it's not gonna show notification
            },
            formData,
            null, // no notification
            translate,
            locale,
          )
        : null;

    // extract error message from dirty fields
    let errorMessage;
    if (thisRowException) {
      if (dirtyFields && dirtyFields.preparedErrorMessage) {
        errorMessage = dirtyFields.preparedErrorMessage;
      } else {
        if (thisRowException['UserMessage']) {
          errorMessage = thisRowException['UserMessage'];
        }
      }
    }

    // extract validation error object from dirty fields
    let validationErrors = [];
    if (thisRowException) {
      if (dirtyFields && dirtyFields.preparedValidationErrors) {
        validationErrors = dirtyFields.preparedValidationErrors;
      }
    }

    /**
     * this function receives an API error object for the current row and a name of a field that has been changed.
     * the API response for types of validation is different. for example, maxLength in Object and required is Array in response.
     * and remove the similar key from the API response.
     * @function removeOneItemFromValidationErrors
     * @param {object} rowExceptionData api response errors
     * @param {string} fieldName field name that has been changed
     * @returns {object} new object for this row
     * */
    const removeOneItemFromValidationErrors = (rowExceptionData, fieldName) => {
      // exceptions in API response are diffrent base their type . some Array and some Object.
      // so here should seprate them base of their types and remove changed value from that.
      const cleanApiData = {};
      Object.keys(rowExceptionData).forEach(errorType => {
        if (
          errorType === 'maxLength' ||
          errorType === 'maxValue' ||
          errorType === 'minValue' ||
          errorType === 'regex' ||
          errorType === 'wrongvalue'
        ) {
          const cleanObject = {};

          Object.keys(rowExceptionData[errorType]).forEach(name => {
            if (name !== fieldName) {
              cleanObject[name] = rowExceptionData[errorType][name];
            }
          });

          if (!isEmptyObject(cleanObject)) {
            // push it to new array
            cleanApiData[errorType] = cleanObject;
          }
        } else {
          const cleanArray = rowExceptionData[errorType].filter(
            name => name !== fieldName,
          );

          if (cleanArray.length > 0) {
            // push it to new array
            cleanApiData[errorType] = cleanArray;
          }
        }
      });
      return cleanApiData;
    };

    /**
     * this function should get the name of field that has been changed recently and find this field with
     * this direction in all fields exceptions .
     * make a copy of all fields extentions and remove changed item from that exception.
     * @function clearValidationErrorForThisField
     * @param {string} fieldName name of field that recently has been changed
     * @returns {void}
     * */
    const clearValidationErrorForThisField = fieldName => {
      if (thisRowException && !isEmptyObject(thisRowException['Data'])) {
        const thisRowExceptionData = thisRowException['Data'];
        const cleanApiData = removeOneItemFromValidationErrors(
          thisRowExceptionData,
          fieldName,
        );

        // create a new object for replace with API error object.
        const preparedErrorObject = {
          exception: cleanApiData,
          relationPath: relationPath,
          rowIndex: rowIndex,
        };

        // this line compute where should this object replace.
        const thisexceptionIndex = allRelationExceptions.indexOf(exception[0]);
        const preparedNewState =
          thisexceptionIndex !== -1
            ? allRelationExceptions.splice(
                thisexceptionIndex,
                1,
                preparedErrorObject,
              )
            : allRelationExceptions;

        // set state will couse to reRender the page so Error of that field will be desapear.
        setRelationExceptions(preparedNewState);
      }
    };

    const rowStyle = {};
    if (row.rowstatecolor && getColorById(row.rowstatecolor)) {
      rowStyle.backgroundColor = getColorById(row.rowstatecolor);
    }

    return (
      <Fragment>
        <TableRow
          className={classes.hoverRow}
          style={rowStyle}
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDragEnd={onDragEnd}
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          draggable={draggable}
          data-test-table-row="true"
        >
          {draggable && (
            <TableCell className={classes.rowOrderCell}>
              <DragHandleIcon className={classes.dragHandleIconStyle} />
            </TableCell>
          )}
          {allowMoveFields && (
            <TableCell className={classes.TableBodyCellAction}>
              <IconButton
                size="small"
                onClick={onRemove}
                className={classes.deleteActionCellIconButton}
              >
                <Icon className={classes.icon} size="small">
                  delete
                </Icon>
              </IconButton>
            </TableCell>
          )}
          {relationInputList.map(relationInput => {
            // if this field is ignored
            if (ignoreShowingColumnObj[relationInput.name] || relationInput.hidden) {
              return null;
            }
            const cellWidth = getInputWidth(relationInput);
            return (
              <TableCell
                key={relationInput.id}
                className={classes.bodyTableCell}
                style={{
                  minWidth: cellWidth,
                  maxWidth: cellWidth,
                }}
              >
                <DynamicInput
                  noLabel
                  source={`${member}.${relationInput.name}`}
                  recordPath={member}
                  field={relationInput}
                  classes={{
                    inputStyle: classes.inputStyle,
                  }}
                  getInputRef={getRefFromInput}
                  changeFocusOnAnotherRow={changeFocusOnAnotherRow}
                  focusOnNextInput={handleEnterPress}
                  relationResource={relationResource}
                  relationSource={relationSource}
                  relationInfo={relationInfo}
                  changeFormValue={changeFormValue}
                  addToRelationArray={addToRelationArray}
                  disableDropdownQuickCreate={disableDropdownQuickCreate}
                  disableDropdownSearchPopup={disableDropdownSearchPopup}
                  record={row}
                  formData={formData}
                  basePath={basePath}
                  resource={resource}
                  metaData={relationMetaData}
                  relationPath={relationPath}
                  validationErrors={validationErrors}
                  clearValidationErrorForThisField={clearValidationErrorForThisField}
                />
              </TableCell>
            );
          })}
        </TableRow>
        {errorMessage && (
          <TableRow className={classes.rowWithError}>
            <TableCell
              className={classes.cellWithError}
              colSpan={relationInputList.length}
            >
              {errorMessage}
            </TableCell>
          </TableRow>
        )}
      </Fragment>
    );
  }
}

RelationFormIteratorRow.propTypes = {
  row: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  focusedField: PropTypes.string,
  draggable: PropTypes.bool,
  disableDropdownQuickCreate: PropTypes.bool,
  disableDropdownSearchPopup: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(RelationFormIteratorRow);
