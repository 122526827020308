import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';
import grey from '@material-ui/core/colors/grey';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  rootDropdown: {
    width: '100%',
    position: 'relative',
    height: '100%',
  },

  rootTooltip: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid' + theme.palette.secondary.main,
    color: theme.palette.common.black,
  },

  paper: {
    margin: 0,
    color: theme.palette.primary.appPrimaryTextColor,
    fontSize: 13,
  },

  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 0,
    direction: 'ltr',
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
  },

  popperDisablePortal: {
    position: 'relative',
    width: '100% !important',
    '& .MuiAutocomplete-loading': {
      direction: 'ltr',
    },
    '& .MuiAutocomplete-noOptions': {
      direction: 'ltr',
    },
  },

  rootAutocomplete: {
    '& .MuiInputBase-root': {
      padding: 0,
      color: theme.palette.grey[900],
      backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    },
  },

  search: {
    width: '100%',
  },

  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    minHeight: 27,
    padding: 8,
    border: '1px solid ' + theme.palette.primary.appBorderInput,
    borderRadius: 4,
  },

  rootError: {
    border: `1px solid ${theme.palette.error.main}`,
  },

  rootRef: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    minHeight: 40,
  },

  rootPopupbottom: {
    borderRadius: '4px 4px 0 0 !important',
    borderBottom: 'none !important',
  },

  rootPopuptop: {
    borderRadius: '0 0 4px 4px !important',
    borderTop: 'none !important',
  },

  rootPopup: {
    borderRadius: '4px 4px 0 0 !important',
    borderBottom: 'none',
  },

  listboxbottom: {
    borderRadius: '0 0 4px 4px !important',
    borderTop: 'none !important',
  },

  listboxtop: {
    borderRadius: '4px 4px 0 0 !important',
    borderBottom: 'none !important',
    display: 'flex',
    flexDirection: 'column-reverse',
  },

  rootAuto: {
    flex: 'auto',
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: '0 !important',
    },
  },

  inputRoot: {
    // '& .MuiOutlinedInput-input': {
    //   padding: 8,
    // },
    // '& .MuiOutlinedInput-notchedOutline': {
    //   top: '0px !important',
    //   borderColor: theme.palette.primary.appPrimaryDividerColor + ' !important',
    // },
    // margin: '0 11px',
    // display: 'flex !important',
    direction: 'ltr',
    '& > legend': {
      display: 'none !important',
    },
  },

  notched: {
    '& > legend': {
      display: 'none !important',
    },
  },

  label: {
    display: 'block',
  },

  input: {
    width: 200,
  },

  listbox: {
    width: '100%',
    margin: 0,
    paddingTop: 0,
    border: '1px solid ' + theme.palette.primary.appBorderInput,
    zIndex: 100,
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
    backgroundColor: theme.palette.background.paper,
    direction: 'rtl',
    '& .MuiAutocomplete-option[data-focus="true"]': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .MuiAutocomplete-option:hover': {
      backgroundColor: theme.palette.primary.appPrimaryDividerColor,
    },
  },

  popper: {
    zIndex: 999999,
  },

  button: {
    margin: '3px',
    borderRadius: 6,
    maxWidth: 100,
    background: theme.palette.primary.appPrimaryLightBackgroundColor,
    '&:hover': {
      background: theme.palette.primary.appPrimaryLightBackgroundColor,
    },
    '& .MuiButton-endIcon': {
      flexShrink: '1 !important',
    },
  },

  typography: {
    margin: '0px 8px',
  },

  clearIcon: {
    color: theme.palette.common.white,
    width: 16,
    height: 16,
    fill: 'currentColor',
    background: grey[400],
    borderRadius: '50%',
    pointerEvents: 'auto',

    '&:hover': {
      background: grey[500],
    },
  },

  drop: {
    pointerEvents: 'auto',
    cursor: 'pointer',
  },

  ulBox: {
    listStyleType: 'none',
    maxHeight: 200,
    width: '100%',
    padding: 0,
    direction: 'ltr',
    '& li[data-focus="true"]': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
    display: 'flex',
    alignItems: 'center',
  },

  rootTags: {
    display: 'flex',
    padding: '0px',
    flexWrap: 'wrap',
    height: '100%',
  },

  padding: {
    padding: 0,
  },

  paddingInput: {
    height: '100%',
  },

  errorText: {
    position: 'absolute',
    right: 100,
    top: 10,
  },

  mouseOver: {
    cursor: 'pointer',
    pointerEvents: 'auto',
    margin: '0 8px 5px 8px',
    lineHeight: 0,
  },

  dropButton: {
    padding: 0,
    marginLeft: 'auto',
  },

  searchIcon: {
    marginRight: 4,
  },

  checkBox: {
    padding: '8px 2px',
  },

  arrowTooltip: {
    '&::before': {
      backgroundColor: theme.palette.common.white,
      border: '1px solid' + theme.palette.secondary.main,
    },
  },

  checkBoxOutline: {
    color: theme.palette.primary.appSecondaryTextColor,
    borderRadius: 4,
  },

  checkBoxOutlineBlank: {
    borderRadius: 4,
  },

  listboxAuto: {
    margin: '8px 0px !important',
    padding: '8px !important',
  },

  spanItemButton: {
    flexGrow: 2,
  },

  endAdornment: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    top: 'calc(50% - 14px)',
    height: '100%',
    right: 9,
    position: 'absolute',
    '& .MuiAutocomplete-endAdornment': {
      position: 'relative !important',
      right: '0px !important',
    },
  },
}));

export default useStyles;
