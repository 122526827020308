import { connect } from 'react-redux';
import React, { FC } from 'react';

import { findOneAction as findDropdownDataAction } from '../../redux/dropdown/action';
import { mapStateToInputProps } from '../../helper/InputHelper';
import { useInputLogic } from '../../hooks/useInputLogic';
import { InputLogicType } from './InputTypes';
import InputView from './InputView';

const DynamicInput: FC<InputLogicType> = props => {
  const { viewProps } = useInputLogic(props);

  return <InputView {...viewProps} />;
};

const mapDispatchToProps = {
  findDropdownData: findDropdownDataAction,
};

export default connect(mapStateToInputProps, mapDispatchToProps)(DynamicInput);
