import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslate, useInput } from 'react-admin';
import { Dropdown } from 'semantic-ui-react';
import lodashGet from 'lodash/get';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { CustomTheme } from '../../core/themeProvider';
import { isEmpty } from '../../helper/data-helper';
import { FieldType } from '../../helper/Types';

interface OptionsInterface {
  key: number;
  value: string;
  text: string;
}

interface StringSelectInputInterface {
  label?: string;
  source?: string;
  field: Partial<FieldType>;
  onChange: Function;
  multiple?: boolean;
  visibleClass: string;
  inputInPuzzleForm?: boolean;
  style?: CSSProperties;
  customError?: string;
}

type onChangeFunc = (
  event: SyntheticEvent<HTMLElement>,
  data: {
    value?: any;
  },
) => void;

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    position: 'relative',
    border: '1px solid #BDBDBD',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    paddingBottom: 0,
    paddingTop: 0,
    margin: 0,
    width: '100%',
    height: '100%',
    minHeight: 40,
    [theme.breakpoints.down('sm')]: {
      minHeight: 30,
    },

    [theme.breakpoints.up('lg')]: {
      paddingTop: 2,
    },

    '&[disabled]': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },

    '& .ui.fluid.dropdown': {
      border: 'none',
      borderRadius: 0,
      background: 'none',
      padding: '2px 0',
      minHeight: 'auto',
      boxShadow: 'none',
      display: 'flex',
      flexBasis: '100%',
    },

    '& .ui.selection.active.dropdown:hover': {
      border: 'none',
      boxShadow: 'none',
    },

    '& input': {
      fontFamily: theme.typography.fontFamily,
      padding: '2px 0 !important',
    },

    '& .dropdown.icon': {
      color: theme.palette.primary.appPrimaryDisableIconColor,
      padding: '2px 5px !important',
      top: (props: StringSelectInputInterface) =>
        props.multiple ? '14px !important' : '8px !important',
    },

    '& .ui.loading.selection.dropdown>i.icon': {
      padding: '8px 10px !important',
    },

    '& .ui.dropdown .menu': {
      top: '133%',
      [theme.breakpoints.down('sm')]: {
        top: '112%',
      },
    },

    '& .ui.selection.active.dropdown .menu': {
      border: `1px solid ${theme.palette.primary.appPrimaryDividerColor}`,
    },

    '& .ui.selection.active.dropdown:hover .menu': {
      border: `1px solid ${theme.palette.primary.appPrimaryDividerColor}`,
    },

    '& .ui.selection.dropdown .menu>.item': {
      fontSize: 13,
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },

    '& .ui.selection.dropdown .menu': {
      width: 'auto',
      minWidth: 'auto',
    },

    '& .ui.upward.dropdown>.menu': {
      bottom: 'auto',
    },
    '& i.icon': {
      fontFamily: 'DropDown',
    },
  },

  legend: {
    fontSize: 10,
    lineHeight: 0,
    color: theme.palette.primary.appSecondaryTextColor,
    display: 'block',
    padding: '0 3px',

    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
    },
  },

  fieldsetError: {
    border: `1px solid ${theme.palette.error.main}`,
  },

  legendError: {
    color: theme.palette.error.main,
  },

  dropdownContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    '& span': {
      cursor: 'pointer',
    },

    [theme.breakpoints.down('sm')]: {
      height: 25,
    },

    margin: 0,
    justifyContent: 'space-between',
    flexBasis: '100%',

    position: 'absolute',
    width: '95%',
  },

  errorText: {
    position: 'absolute',
    right: 100,
    top: 10,
  },

  label: {
    fontSize: 13,
    color: theme.palette.primary.appSecondaryTextColor,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },

  inputInPuzzleForm: {
    margin: '7px 3px 0',
  },
}));

const StringSelectInput = (props: StringSelectInputInterface) => {
  const {
    label,
    source,
    field,
    onChange: onChangeProps,
    multiple,
    visibleClass,
    inputInPuzzleForm,
    style,
    customError,
  } = props;
  const { values } = field;
  const translate = useTranslate();
  const classes = useStyles(props);

  const [preparedOptions, setPreparedOptions] = useState<OptionsInterface[]>([]);

  const {
    input: { onChange, value },
    meta: { touched, error },
  } = useInput(props);

  const getOptions = useCallback(() => {
    const options: OptionsInterface[] = [];
    (values as Array<string>).map((val, index) => {
      options.push({
        key: index,
        text: val,
        value: val,
      });
    });
    return options;
  }, [values]);

  useEffect(() => {
    setPreparedOptions(getOptions());
  }, []);

  const handleChange: onChangeFunc = useCallback(
    (event, { value }) => {
      if (multiple) {
        value = value.join();
      }
      onChange(value);
      onChangeProps(value);
    },
    [onChangeProps, onChange],
  );
  const hasError = !!(touched && error);

  const fieldText = useMemo(() => {
    return !isEmpty(value) ? value : field.required ? `${label} *` : label;
  }, [value]);

  return (
    <fieldset
      className={`${visibleClass} ${classes.root} ${
        hasError ? classes.fieldsetError : ''
      } ${inputInPuzzleForm ? classes.inputInPuzzleForm : null}`}
      style={style ? style : undefined}
      data-test-field-name={source}
    >
      {!isEmpty(value) && !isEmpty(label) && (
        <legend
          className={`${classes.legend} ${hasError ? classes.legendError : ''}`}
        >
          {label}
          {field.required ? '*' : ''}
        </legend>
      )}
      <div className={classes.dropdownContainer}>
        <Dropdown
          data-test-dropdown-search-input
          fluid
          selection
          clearable
          closeOnBlur
          labeled
          value={
            !isEmpty(value) && multiple
              ? value.split(',')
              : !isEmpty(value)
              ? value
              : null
          }
          text={fieldText}
          error={!!customError || hasError}
          options={preparedOptions}
          onChange={handleChange}
          multiple={!!multiple}
        />
      </div>

      {!!customError || hasError ? (
        <Typography className={classes.errorText} variant="caption" color="error">
          {customError ? customError : translate(lodashGet(error, 'message', error))}
        </Typography>
      ) : null}
    </fieldset>
  );
};

export default StringSelectInput;
