import { cloneElement, ReactElement, useEffect, useRef } from 'react';
import { actorRemoveAction, RecordKeyMode } from '../../type/actor-setup';
import { useTranslate, useLocale, crudGetOne, GET_LIST } from 'react-admin';
import { useDispatch } from 'react-redux';
import lodashGet from 'lodash/get';
import lodashPick from 'lodash/pick';

import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
  ResourceInterface,
} from '../../type/actor-setup';

import {
  ChangeFormValue,
  CustomFormInterface,
  ToolbarProps,
  OnBlur,
  FormControllerProps,
  ExtraParamsInterface,
  FormControllerPropsFromRedux,
  ChangeFormValueParams,
  OnBlurParams,
  FormActions,
  FormData,
  GetFileParams,
  UpdateDataWithRedux,
  UpdateDataWithReduxParams,
  CustomChangeHandlerPayloadType,
  GetCodingDefaultValueParams,
  CodingInputData,
  tagInputData,
  FindTagInputDataWithActor,
  FindTagInputDataWithActorParams,
  ValidateInputParams,
  GetDropDataParams,
  MapDropDataParams,
  InputRefContent,
  OnFocusParams,
  OnFocus,
  GetDropdownData,
  FetchDropdownDataPayload,
} from './form.type';
import {
  FieldType,
  GeneralMetaData,
  MetaData,
  MetaDataBase,
  RecordInterface,
  ValidationError,
} from '../../helper/Types';
import { isEmpty, isEmptyObject } from '../../helper/data-helper';
import { findOneAction as findDropdownDataAction } from '../../redux/dropdown/action';
import {
  afterValidationInputs,
  changeFormToolbarDisableState,
  convertMultiFileStreamArraysToJson,
  sanitizeFormData,
  updateInputsState,
  validateInput,
} from './form.helper';
import {
  runServiceValidationClient,
  validateClientSideFields,
} from '../../helper/validation-helper';
import { FormWithTabsProps } from './form-with-tabs';
import {
  DROPDOWN_FIELD,
  getTypeByField,
  inputTypes,
  SEARCH_FIELD,
  TYPE_CODING,
  TYPE_SEARCH_INPUT,
} from '../../helper/InputHelper';
import { getBase64File } from '../../helper/FileHelper';
import { QuickEditDialogProps } from './form-with-tabs/form-with-tab.type';
import dataProvider, { GET_CODING } from '../../core/dataProvider';
import { useFormSave } from './hooks/use-form-save';
import { WMSFetchMetaResult, WMSMetaData } from '../wms';
import { WMSFormProps } from '../wms/wms-layouts/wms-form';
import { CheckValidationClientSide } from './form.type';
import { showNotification } from '../../helper/general-function-helper';
import { checkUiControl } from './form-with-tabs/form-with-tabs.helper';
import NotFound from '../NotFound';
import { findFieldByName } from '../../helper/meta-helper';

let formAsyncActionList: Promise<unknown>[] = [];
const FormController = (
  props: Partial<FormControllerProps> & Partial<FormControllerPropsFromRedux>,
): ReactElement => {
  // --------------------------------------- destruct props ----------------------------------------------
  const {
    children = <div />,
    formName,
    isQuickForm = false,
    disableValidationErrorNotification = false,
  } = props;

  const reduxDispatch = useDispatch();

  const { params, location } = actorGetActionValue('urlInfo')!;
  const { id } = params;

  const isChangedInputs = useRef({});
  let resource = actorGetActionValue('resources')!.current.value;
  if (formName === 'wms') {
    resource = actorGetActionValue('resources')!.stack[0].value;
  }

  const metaData = actorGetActionValue('metaData', resource)! as unknown as MetaData;

  // --------------------------------------- declare refs ----------------------------------------------
  const validationErrorsRef = useRef<Record<string, ValidationError> | null>(); // to store prev validation errors value
  const formDataRef = useRef<Record<string, unknown>>({}); // to store prev formData value

  // --------------------------------------- custom hooks ----------------------------------------------
  const formSave = useFormSave(formName!);

  const translate = useTranslate();
  const locale = useLocale();

  useEffect(() => {
    actorOnDispatch(
      'resetForm',
      parameters => {
        const currentResource =
          parameters?.resource ?? actorGetActionValue('resources')!.current;

        if (formName === 'wms' || isEmptyObject(currentResource)) return;

        const allFields = actorGetActionValue(
          'allFields',
          `${currentResource.value}.${currentResource.type}`,
        )! as unknown as Array<FieldType>;

        let resetIgnoreFields: Array<string> = [];

        if (allFields && Array.isArray(allFields)) {
          resetIgnoreFields = allFields
            .filter(field => field?.keepValueAfterSubmit)
            .map(field => field.name);
        }

        const formData =
          (actorGetActionValue('formData', [
            currentResource.value,
            currentResource.type,
          ]) as FormData | null) ?? {};

        const quickCreateSupplementaryData = actorGetActionValue(
          'quickCreateSupplementaryData',
          `${currentResource.value}.${currentResource.type}`,
        );

        const initialFormData = actorGetActionValue(
          'initialData',
          `${currentResource.value}.${currentResource.type}`,
        );

        const necessaryValuesBeforeReset = lodashPick(formData, resetIgnoreFields);

        const newFormData = {
          ...initialFormData,
          ...quickCreateSupplementaryData,
          ...necessaryValuesBeforeReset,
        };

        actorSetActionValue(
          'formMessages',
          {},
          {
            path: `${currentResource!.value}.${currentResource!.type}`,
            replaceAll: true,
          },
        );

        actorDispatch('formData', newFormData, {
          path: `${currentResource.value}.${currentResource.type}`,
          replaceAll: true,
        });

        actorSetActionValue(
          'quickDialog',
          {},
          { path: 'data.data', replaceAll: true },
        );

        actorDispatch('initialData', newFormData, {
          path: `${currentResource.value}.${currentResource.type}`,
        });
      },
      { preserve: false },
    );
  }, []);

  useEffect(() => {
    actorOnDispatch('formData', formData => {
      const currentResource = actorGetActionValue('resources')?.current;
      if (formName === 'wms' || currentResource == null) return;

      const currentFormData =
        formData?.[currentResource.value]?.[currentResource.type];
      formDataRef.current = currentFormData ?? {};

      const currentFormMessages =
        actorGetActionValue('formMessages')?.[currentResource.value]?.[
          currentResource.type
        ] ?? {};

      const inputsRef =
        actorGetActionValue('inputsRef')?.[currentResource.value]?.[
          currentResource.type
        ];

      updateInputsState(inputsRef, currentFormMessages, currentFormData);
    });

    actorOnDispatch('record', records => {
      const currentResource = actorGetActionValue('resources')?.current;
      if (currentResource == null) return;

      const currentRecordData =
        records?.[currentResource.value]?.[currentResource.type]?.[
          RecordKeyMode.FULL
        ] ?? {};

      formDataRef.current = { ...formDataRef.current, ...currentRecordData };
    });
  }, []);

  if (metaData?.['error'] === 'networkError') {
    return <NotFound title={translate('ra.notification.http_error')} />;
  }

  // --------------------------------------- check validation ----------------------------------------------
  const checkValidationClientSide: CheckValidationClientSide = (
    prevValidationErrors,
    mergedFormData,
    allFields,
    currentResource,
  ) => {
    const validationMessages = validateClientSideFields(
      prevValidationErrors,
      mergedFormData,
      allFields,
      showNotification,
      translate,
      locale,
      isQuickForm,
      disableValidationErrorNotification,
    );

    if (!isEmptyObject(validationMessages)) {
      actorDispatch('formMessages', validationMessages, {
        path: `${currentResource.value}.${currentResource.type}`,
      });
      return false;
    }

    return true;
  };

  // --------------------------------------- form submits ----------------------------------------------
  /**
   * @async
   * @function handleActions
   * @param {string} type
   * @param {Record<string, unknown>} data
   * @param {Record<string, unknown>} payload
   * @returns {Promise<void>} promise of void
   */
  const formActionsHandler = async (
    type: string,
    payload?: unknown,
  ): Promise<void> => {
    const currentResource = actorGetActionValue('resources')!.current;
    const allFields = actorGetActionValue('allFields', [
      currentResource.value,
      currentResource.type,
    ])! as unknown as Array<FieldType>;

    const saveExtraParams = payload as ExtraParamsInterface;
    const currentFormName = saveExtraParams?.formName ?? formName;

    switch (type) {
      case FormActions.Save: {
        changeFormToolbarDisableState({
          currentResource,
          disabled: true,
        });

        try {
          await Promise.all(formAsyncActionList);
          formAsyncActionList = [];
        } catch (error) {
          console.error('`formActionsHandler`: saving error => ', error);
          changeFormToolbarDisableState({
            currentResource,
          });
          return;
        }

        const prevValidationMessages =
          (actorGetActionValue('formMessages', [
            currentResource.value,
            currentResource.type,
          ]) as ValidationError | null) ?? {};

        const formData =
          (actorGetActionValue('formData', [
            currentResource.value,
            currentResource.type,
          ]) as FormData | null) ?? {};

        if (formName !== 'wms') {
          convertMultiFileStreamArraysToJson(formData, metaData as GeneralMetaData); // pass formData by reference
        }

        let mergedFormData = {};
        let target: string | null = null;

        if (currentFormName === 'quickEditDialog') {
          const isAllFieldsReady = Array.isArray(allFields);

          if (!isAllFieldsReady || (isAllFieldsReady && allFields.length > 1)) {
            console.log('all Fields array is not valid ', allFields);
          } else {
            const field = allFields[0];

            if (getTypeByField(field) === DROPDOWN_FIELD) {
              const dropDownDataInActor = actorGetActionValue(
                'inputsRef',
                `${currentResource.value}.${currentResource.type}.${field.name}.selectedItem`,
              );

              mergedFormData[`__dropdownvalue_${field.name}`] = dropDownDataInActor;
            }

            mergedFormData[field.name] = formData[field.name];
            target = field.name;

            for (const errorKey in prevValidationMessages) {
              mergedFormData[errorKey] = prevValidationMessages[errorKey].value;
            }
          }
        } else {
          mergedFormData = { ...formData };
          for (const errorKey in prevValidationMessages) {
            mergedFormData[errorKey] = prevValidationMessages[errorKey].value;
          }
        }

        if (
          ['gridForm', 'wms'].indexOf(currentFormName as string) === -1 && //dont check validation in grid form RCT-2266
          !checkValidationClientSide(
            prevValidationMessages,
            mergedFormData,
            allFields,
            currentResource,
          )
        ) {
          if (typeof saveExtraParams.onFailure === 'function') {
            saveExtraParams.onFailure();
          }

          changeFormToolbarDisableState({
            currentResource,
          });

          return;
        }

        const validationParams: ValidateInputParams = {
          validationErrorsRef,
          formDataRef,
          metaData: metaData as MetaDataBase,
          showNotification,
          translate,
          locale,
          isQuickForm,
          allFields,
        };

        if (currentFormName === 'createEditRecordPage') {
          formSave(sanitizeFormData(mergedFormData), {
            ...saveExtraParams,
            id: id ? Number(id) : null,
            isQuickForm: false,
            validationParams,
          });
        }

        if (currentFormName === 'relationEditDialogForm') {
          const dialogData = actorGetActionValue('quickDialog')!;
          const relationEditIsOpen = dialogData.relationEditIsOpen;
          const { id } = dialogData?.data?.['data'];
          formSave(sanitizeFormData(mergedFormData), {
            ...saveExtraParams,
            id: id ? Number(id) : null,
            isQuickForm,
            validationParams,
            isRelationEditDialogOpen: relationEditIsOpen,
          });
        }

        if (currentFormName === 'quickCreateDialog') {
          formSave(sanitizeFormData(mergedFormData), {
            ...saveExtraParams,
            isQuickForm,
            validationParams,
          });
        }

        if (currentFormName === 'serviceDialogForm') {
          const currentResource = actorGetActionValue('resources')!.stack[0]; // We need first item here
          const gridIDs = actorGetActionValue('gridIDs', `${currentResource.value}`);

          const { service } = actorGetActionValue('selectedService')!;

          formSave(mergedFormData, {
            ...saveExtraParams,
            selectedService: service,
            selectedIds: gridIDs?.selectedIDs ?? [],
            parentResource: saveExtraParams?.customExternalResource,
          });
        }

        if (currentFormName === 'profileForm') {
          formSave(mergedFormData, {
            ...saveExtraParams,
            validationParams,
            fields: allFields,
          });
        }

        if (currentFormName === 'dropdownQuickCreateForm') {
          formSave(mergedFormData, {
            ...saveExtraParams,
            validationParams,
            resourceType: currentResource.type,
          });
        }

        if (currentFormName === 'quickEditDialog') {
          formSave(mergedFormData, {
            ...saveExtraParams,
            id: id ? Number(id) : null,
            validationParams,
            resourceType: currentResource.type,
            target,
          });
        }

        if (currentFormName === 'wms') {
          const rootResource = actorGetActionValue('resources')!.stack[0];
          mergedFormData =
            (actorGetActionValue('formData', [
              rootResource.value,
              rootResource.type,
            ]) as FormData | null) ?? {};

          formSave(mergedFormData, { ...saveExtraParams });
        }

        if (currentFormName === 'changePassword') {
          formSave(mergedFormData, {
            ...saveExtraParams,
            validationParams,
            fields: allFields,
          });
        }

        if (currentFormName === 'simpleForm') {
          formSave(mergedFormData, { ...saveExtraParams, validationParams });
        }

        break;
      }

      // TODO: use form-api
      case FormActions.GetDropData: {
        const { id: dropId, params, meta, uniqueId } = payload as GetDropDataParams;
        getDropData({ id: dropId, params, meta, uniqueId });
        break;
      }

      case FormActions.InputChange: {
        const { fieldName, value, changeWithoutValidate } =
          payload as ChangeFormValueParams;
        isChangedInputs.current = { [fieldName]: true };
        if (changeWithoutValidate) {
          changeFormValueWithoutValidate(fieldName, value);
        } else {
          changeFormValueWithValidate(fieldName, value);
        }

        break;
      }

      case FormActions.InputBlur: {
        const { fieldName, value, successRunValidationCallback } =
          payload as OnBlurParams;

        const inputsRef = actorGetActionValue(
          'inputsRef',
          `${currentResource.value}.${currentResource.type}`,
        ) as Record<string, InputRefContent> | undefined;

        if (inputsRef?.[fieldName]) {
          inputsRef[fieldName]['focused'] = false;
        }

        //api runs server validation in inline mode
        if (currentFormName === 'quickEditDialog') break;

        const formData =
          (actorGetActionValue('formData', [
            currentResource.value,
            currentResource.type,
          ]) as FormData | null) ?? {};

        //check input changed from onChange or changed from serviceValidation
        if (!isChangedInputs.current[fieldName] && formData[fieldName] === value) {
          break;
        }

        try {
          await Promise.all(formAsyncActionList);
          formAsyncActionList = [];
        } catch (error) {
          console.error('`formActionsHandler`: `InputBlur` error => ', error);
          return;
        }
        //run services and validation
        onInputBlur(fieldName, value, currentResource, successRunValidationCallback);

        isChangedInputs.current = { [fieldName]: false };
        break;
      }

      case FormActions.InputKeyDown: {
        // const { fieldName, value, event } = payload as ChangeFormValueParams;
        break;
      }

      case FormActions.MapDropData: {
        const { field, params } = payload as MapDropDataParams;
        customChangeHandler(field.dataType.erp, field, '', {
          ...params,
          value: { [field.name]: params.value },
        });
        break;
      }

      case FormActions.InputFocus: {
        const { fieldName } = payload as OnFocusParams;
        onInputFocus(fieldName, currentResource);
        break;
      }

      // TODO: use form-api
      case FormActions.GetFileInput: {
        const { url, fieldName } = payload as GetFileParams;
        customGetFileInputData(url, fieldName);
        break;
      }

      // TODO: use form-api
      case FormActions.GetOrUpdateDataWithRedux: {
        const { resource, id, basePath } = payload as UpdateDataWithReduxParams;
        updateDataWithRedux(resource, id, basePath);
        break;
      }

      // TODO: use form-api
      case FormActions.GetCodingDefaultValue: {
        const { rowId, record, field } = payload as GetCodingDefaultValueParams;
        getCodingDefaultValue(rowId, record, field);
        break;
      }

      // TODO: use form-api
      case FormActions.FindTagInputDataWithRedux: {
        const { resource, source, value } =
          payload as FindTagInputDataWithActorParams;
        findTagInputDataWithActor(resource, source, value);
        break;
      }

      case FormActions.FetchDropdownData: {
        const {
          dropdownId,
          option,
          dropdownMeta,
          resource,
          resourceType,
          searchValue,
          hasMore,
          successCallback,
          failureCallback,
        } = payload as FetchDropdownDataPayload;

        actorDispatch(
          'fetchDropdownData',
          {
            dropdownId,
            option,
            dropdownMeta,
            resource,
            resourceType,
            searchValue,
            hasMore,
            successCallback,
            failureCallback,
          },
          {
            disableDebounce: true,
          },
        );
        break;
      }

      default:
        console.log('DEFAULT');
        break;
    }

    changeFormToolbarDisableState({
      currentResource,
    });
  };

  // --------------------------------------- customGetFileInputData --------------------------------------------
  /**
   * this function get file data and set to fileInputData value
   * @function customGetFileInputData
   * @param {string} url
   * @param {string} fieldName
   * @return {Promise<void>}
   */
  const customGetFileInputData = (url: string, fieldName: string): void => {
    actorDispatch('loading', true, {
      path: `${resource}-${fieldName}`,
    });
    getBase64File({ link: url })
      .then(response => {
        actorDispatch('loading', false, {
          path: `${resource}-${fieldName}`,
        });
        actorDispatch('fileInputData', response, {
          path: `${resource}.${fieldName}`,
        });
      })
      .catch(() => {
        actorDispatch('loading', false, {
          path: `${resource}-${fieldName}`,
        });
      });
  };

  // --------------------------------------- customChangeHandler -----------------------------------------------------

  /**
   * @function customChangeHandler
   * @param {string} mapFieldName
   * @param {FieldType} field
   * @param {string} recordPath
   * @param {CustomChangeHandlerPayloadType} payload
   * @returns {void}
   */
  const customChangeHandler = (
    mapFieldName: string,
    field: FieldType,
    recordPath: string,
    payload: CustomChangeHandlerPayloadType,
  ) => {
    handleDropdownChange(mapFieldName, field, recordPath, payload);
  };

  actorSetActionValue('formGlobalProps', {
    formActionsHandler,
    customChangeHandler,
    checkValidationClientSide,
    metaData,
  });

  // --------------------------------------- to handle data in edit case ----------------------------------------------
  /**
   * Get one record with `crudGetOne`.
   * @function updateDataWithRedux
   * @param {string} resource
   * @param {number} id
   * @param {string} basePath
   * @returns {void}
   */
  const updateDataWithRedux: UpdateDataWithRedux = (resource, id, basePath) => {
    reduxDispatch(crudGetOne(resource, id, basePath));
  };

  /** to get CodingInput default value in edit mode
   * @function getCodingDefaultValue
   * @param {number | null} rowId
   * @param {RecordInterface} record
   * @param {FieldType} field
   * @returns {Promise<void>}
   */
  const getCodingDefaultValue = async (
    rowId: number | null,
    record: RecordInterface,
    field: FieldType,
  ): Promise<void> => {
    let codingInputData = {} as CodingInputData;

    codingInputData = {
      ...codingInputData,
      isLoading: true,
      data: null,
    };

    actorDispatch('codingInputData', codingInputData);

    try {
      const params = record?.id
        ? {
            id: record?.id,
            editRequest: true,
          }
        : rowId
        ? {
            id: rowId,
          }
        : {};
      const { data } = await dataProvider(GET_CODING, resource, params);
      codingInputData = {
        ...codingInputData,
        isLoading: false,
        data: data,
      };
      actorDispatch('codingInputData', codingInputData);
      customChangeHandler(TYPE_CODING, field, '', { value: data });
    } catch (error) {
      codingInputData = {
        ...codingInputData,
        isLoading: false,
        data: null,
      };
      actorDispatch('codingInputData', codingInputData);
      showNotification(error, 'error');
    }
  };

  /**
   * Find Tag Input Data with help of actor and dataProvider
   * @function findTagInputDataWithActor
   * @param {string} resource
   * @param {string | undefined} source
   * @param {string} value
   * @returns {void}
   */

  const findTagInputDataWithActor: FindTagInputDataWithActor = (
    resource,
    source,
    value,
  ) => {
    let setData = {} as tagInputData;
    setData = {
      ...setData,
      isLoading: true,
      resultList: null,
      errorList: null,
    };

    //set initial tagData to actor
    actorDispatch('tagInputData', setData);

    const params = {
      pagination: {
        page: 1,
        perPage: 100,
      },
      sort: {
        field: source,
        order: 'ASC',
      },
      filter: [[source, 'contains', value]],
      fields: [source],
      queryParams: {
        distinctSelect: 'true',
      },
    };

    dataProvider(GET_LIST, resource, params)
      .then(res => {
        let _data = [];
        const foundedTagDataFromRes = res.data[0][source];
        _data = _data.concat(foundedTagDataFromRes.split('#'));
        _data = _data.filter((item, index) => _data.indexOf(item) >= index);
        setData = {
          ...setData,
          isLoading: false,
          resultList: _data,
          errorList: null,
        };
        //set succeed tagData to actor
        actorDispatch('tagInputData', setData);
      })
      .catch(error => {
        setData = {
          ...setData,
          isLoading: false,
          resultList: null,
          errorList: error.toString(),
        };

        //set failure tagData to actor
        actorDispatch('tagInputData', setData);
      });
  };

  // --------------------------------------- toolbar ----------------------------------------------
  const toolbarProps: ToolbarProps = {
    formActionsHandler,
  };

  // --------------------------------------- handle changes ----------------------------------------------
  /**
   * @function changeFormValueWithoutValidate
   * @param {String} name string
   * @param {Unknown} value Can be any type, boolean, string, array, etc...
   * @returns {Void} void
   */
  const changeFormValueWithoutValidate: ChangeFormValue = (name, value) => {
    const currentResource = actorGetActionValue('resources')!.current;
    const inputsRef = actorGetActionValue('inputsRef')?.[currentResource.value]?.[
      currentResource.type
    ] as Record<string, InputRefContent> | null;

    actorSetActionValue(
      'formData',
      { [name]: value },
      {
        path: `${currentResource.value}.${currentResource.type}`,
      },
    );

    if (!isEmptyObject(inputsRef)) {
      inputsRef![name]?.setInputValue(value);
      checkUiControl(name, currentResource);
    }
  };

  /**
   * it get an object than contains new values , validate them one by one
   * then set them value by ref after validation complete
   * @function bulkChangeFormValueWithValidate
   * @param {Record<string,unknown>} newValues
   * @returns void
   */
  const bulkChangeFormValueWithValidate = async newValues => {
    //TODO: merge this function with changeFormValueWithValidate as one single function
    const inputsRef = actorGetActionValue('inputsRef')!;
    const checkTabErrors = actorGetActionValue('checkTabErrors');
    const currentResource = actorGetActionValue('resources')!.current;
    const allFields = actorGetActionValue('allFields', [
      currentResource.value,
      currentResource.type,
    ])! as unknown as Array<FieldType>;

    const prevValidationMessages =
      (actorGetActionValue('formMessages', [
        currentResource.value,
        currentResource.type,
      ]) as ValidationError | null) ?? {};

    for (const errorKey in prevValidationMessages) {
      formDataRef.current[errorKey] = prevValidationMessages[errorKey].value;
    }

    const validationParams: ValidateInputParams = {
      validationErrorsRef,
      formDataRef,
      metaData: metaData as MetaDataBase,
      showNotification,
      translate,
      locale,
      isQuickForm,
      allFields,
    };

    const newValueKeys = Object.keys(newValues);
    for await (const target of newValueKeys) {
      validateInput(target, newValues[target], validationParams)
        .then(validationResult => {
          const [isFieldValid, fieldMessageList] = validationResult;

          if (!isFieldValid) {
            const { message: fieldErrorMessage } = fieldMessageList[target];
            // Set error message and don't touch `formData`
            inputsRef?.[currentResource.value]?.[currentResource.type][
              target
            ].setInputMessage({ message: fieldErrorMessage, messageType: 'error' });

            actorSetActionValue(
              'formMessages',
              { ...fieldMessageList[target] },
              {
                path: `${currentResource.value}.${currentResource.type}.${target}`,
              },
            );

            if (typeof checkTabErrors === 'function') {
              checkTabErrors();
            }

            return;
          }

          // Ok, the input value is valid, update `formData`
          inputsRef?.[currentResource.value]?.[currentResource.type][
            target
          ]?.setInputMessage(undefined);

          actorRemoveAction({
            actionName: 'formMessages',
            path: `${currentResource.value}.${currentResource.type}.${target}`,
          });

          const formMessages = actorGetActionValue('formMessages', [
            currentResource.value,
            currentResource.type,
          ]) as Record<string, ValidationError> | null;

          if (formMessages) {
            actorSetActionValue('formMessages', formMessages, {
              path: `${currentResource.value}.${currentResource.type}`,
            });
          }
        })
        .catch(error => {
          console.log('changeFormValueWithValidate failed: ', {
            error,
            inputName: target,
            resource: currentResource.value,
          });
        });
    }

    // We have to update `formDataRef` because some other actions need last changes of it and `onDispatch` maybe run after those actions running(e.g. `onInputBlur`)
    formDataRef.current = { ...formDataRef.current, ...newValues };

    actorDispatch('formData', newValues, {
      path: `${currentResource.value}.${currentResource.type}`,
    });

    if (typeof checkTabErrors === 'function') {
      checkTabErrors();
    }
  };

  /**
   * @function changeFormValueWithValidate
   * @param {string} name string
   * @param {unknown} value Can be any type, boolean, string, array, etc...
   * @returns {void} void
   */
  const changeFormValueWithValidate: ChangeFormValue = (fieldName, value) => {
    const checkTabErrors = actorGetActionValue('checkTabErrors')!;
    const currentResource = actorGetActionValue('resources')!.current;
    const inputsRef = actorGetActionValue('inputsRef')?.[currentResource.value]?.[
      currentResource.type
    ] as Record<string, InputRefContent> | null;

    const allFields = actorGetActionValue('allFields', [
      currentResource.value,
      currentResource.type,
    ])! as unknown as Array<FieldType>;

    const prevValidationMessages =
      (actorGetActionValue('formMessages', [
        currentResource.value,
        currentResource.type,
      ]) as ValidationError | null) ?? {};

    inputsRef?.[fieldName]?.setInputValue(value);

    for (const errorKey in prevValidationMessages) {
      formDataRef.current[errorKey] = prevValidationMessages[errorKey].value;
    }

    const validationParams: ValidateInputParams = {
      validationErrorsRef,
      formDataRef,
      metaData: metaData as MetaDataBase,
      showNotification,
      translate,
      locale,
      isQuickForm,
      allFields,
    };

    validateInput(fieldName, value, validationParams)
      .then(validationResult => {
        const [isFieldValid, fieldErrorMessageList, fieldType] = validationResult;

        if (!isFieldValid) {
          const { message: fieldErrorMessage } = fieldErrorMessageList[fieldName];

          if (fieldType === inputTypes.DROPDOWN_FIELD) {
            actorDispatch(
              'formData',
              {
                [fieldName]: value,
              },
              {
                path: `${currentResource.value}.${currentResource.type}`,
              },
            );
          }

          // Set error message and don't touch `formData`
          inputsRef?.[fieldName].setInputMessage({
            message: fieldErrorMessage,
            messageType: 'error',
          });

          actorSetActionValue(
            'formMessages',
            { ...fieldErrorMessageList[fieldName] },
            {
              path: `${currentResource.value}.${currentResource.type}.${fieldName}`,
            },
          );

          if (typeof checkTabErrors === 'function') {
            checkTabErrors();
          }

          return;
        }

        // Ok, the input value is valid, update `formData`
        actorDispatch(
          'formData',
          {
            [fieldName]: value,
          },
          {
            path: `${currentResource.value}.${currentResource.type}`,
          },
        );

        formDataRef.current = { ...formDataRef.current, [fieldName]: value };

        inputsRef?.[fieldName].setInputMessage(undefined);

        actorRemoveAction({
          actionName: 'formMessages',
          path: `${currentResource.value}.${currentResource.type}.${fieldName}`,
        });

        const formMessages = actorGetActionValue('formMessages', [
          currentResource.value,
          currentResource.type,
        ]) as Record<string, ValidationError> | null;

        if (formMessages) {
          actorSetActionValue('formMessages', formMessages, {
            path: `${currentResource.value}.${currentResource.type}`,
          });
        }

        if (typeof checkTabErrors === 'function') {
          checkTabErrors();
        }

        if (!isEmptyObject(inputsRef)) {
          checkUiControl(fieldName, currentResource);
        }
      })
      .catch(error => {
        console.log('changeFormValueWithValidate failed: ', error);
      });
  };

  /**
   * @function handleDropdownValueChangesToForm
   * @param {Object} value
   * @param {Record<string, unknown>} record
   * @param {boolean} skipIfFormHasAlreadyValue
   * @returns {void}
   */
  const handleDropdownValueChangesToForm = (
    value: Record<string, unknown>,
    record: Record<string, unknown> | undefined,
    skipIfFormHasAlreadyValue: boolean,
  ): void => {
    const currentResource = actorGetActionValue('resources')!.current;

    const fixedKeysFormData = {};
    Object.keys(value).forEach(key => {
      const target = key.toLowerCase();

      if (skipIfFormHasAlreadyValue && !isEmpty(record?.[target])) {
        return;
      }

      fixedKeysFormData[target] = value[key];
    });

    actorDispatch('formData', fixedKeysFormData, {
      path: `${currentResource.value}.${currentResource.type}`,
    });
  };

  // --------------------------------------- handle Dropdown changes ----------------------------------------------
  /**
   * its a function that will be handle dropdown change and also change che form value
   * for all fields that exist in changed dropdown "map" parameter in meta.
   * also call function for changed dropdown and any other
   * fields that has been change in map process if exist.
   * @function handleDropdownChange
   * @param {string} mapFieldName
   * @param {FieldType} field
   * @param {string} recordPath
   * @param {CustomChangeHandlerPayloadType} payload
   * @returns {void}
   */
  const handleDropdownChange = (
    mapFieldName: string,
    field: FieldType,
    recordPath: string,
    payload: CustomChangeHandlerPayloadType,
  ): void => {
    const { value, selectedRecord, skipIfFormHasAlreadyValue = false } = payload;
    const { record } = props;
    // like in field.dropdown.maps
    const mapArray: { to: string; from: string }[] = lodashGet(field, [
      mapFieldName,
      'maps',
    ]);

    // search input must put all values in the form
    if (mapFieldName === TYPE_SEARCH_INPUT && selectedRecord) {
      handleDropdownValueChangesToForm(
        selectedRecord,
        record,
        skipIfFormHasAlreadyValue,
      );
    }

    if (mapFieldName === TYPE_CODING) {
      handleDropdownValueChangesToForm(value, record, skipIfFormHasAlreadyValue);
    }

    if (!mapArray || !Array.isArray(mapArray) || !mapArray.length) {
      return;
    }

    const prefix = recordPath && recordPath !== '' ? recordPath + '.' : '';

    const newValues = {};
    // trigger change in other form inputs

    mapArray.forEach(item => {
      const target = `${prefix}${item.to}`;

      if (skipIfFormHasAlreadyValue && !isEmpty(lodashGet(record, target))) {
        return;
      }

      const targetValue: string | undefined = lodashGet(
        selectedRecord,
        item.from,
        null, // because undefined will be ignored in dropdown controller but null will clear value
      );
      newValues[target] = targetValue;
    });

    formAsyncActionList.push(bulkChangeFormValueWithValidate(newValues));
  };

  /**
   * @function runOnlyServiceValidationClient
   * @param {string} fieldName string
   * @param {unknown} value Can be any type, boolean, string, array, etc...
   * @param { Function | undefined } successRunValidationCallback
   * @returns {void} void
   */
  const runOnlyServiceValidationClient: ChangeFormValue = async (
    fieldName,
    value,
    successRunValidationCallback = undefined,
  ) => {
    const currentResource = actorGetActionValue('resources')!.current;
    const allFields = actorGetActionValue('allFields', [
      currentResource.value,
      currentResource.type,
    ])! as unknown as Array<FieldType>;
    const prevFormData = formDataRef?.current ?? {};
    const prevValidationErrors = validationErrorsRef?.current ?? {};

    let formData = { ...prevFormData, [fieldName]: value };
    let finalMetaData = metaData;
    let finalResource = resource;
    if (formName === 'wms') {
      finalMetaData = (
        (metaData as WMSFetchMetaResult).data as unknown as WMSMetaData
      ).tabs[0].item.table!;
      if (finalMetaData == null) return;

      const currentFormData = actorGetActionValue(
        'formData',
        `${currentResource.value}.${currentResource.type}`,
      ) as Record<string, unknown>;

      formData = { ...currentFormData, ...formData };

      finalMetaData = (
        (metaData as WMSFetchMetaResult).data as unknown as WMSMetaData
      ).tabs[0].item.table!;

      const { config } = finalMetaData;
      if (config) {
        finalResource = `${config.moduleName}/${config.moduleTableName}`;
      }
    }

    try {
      const validationErrors = await runServiceValidationClient(
        allFields,
        fieldName,
        formData,
        locale,
        finalMetaData as MetaDataBase,
        finalResource,
        '', // here relationResource is empty
        changeFormValueWithValidate,
        prevValidationErrors,
        translate,
        disableValidationErrorNotification,
        showNotification,
        isQuickForm,
        successRunValidationCallback,
      );

      if (formName === 'wms' && fieldName in validationErrors) {
        showNotification(validationErrors[fieldName].message, 'error');
        return;
      }

      validationErrorsRef.current = validationErrors;
      return afterValidationInputs(
        [!validationErrors[fieldName], validationErrors],
        fieldName,
      );
    } catch (error) {
      console.error('runOnlyServiceValidationClient failed: ', error);
    }
  };

  // --------------------------------------- handle input blur ----------------------------------------------
  /**
   * @function onInputBlur
   * @param {string} fieldName string
   * @param {unknown} value Can be any type, boolean, string, array, etc...
   * @param {ResourceInterface} currentResource
   * @returns {void} void
   */
  const onInputBlur: OnBlur = async (
    fieldName: string,
    value: unknown,
    currentResource: ResourceInterface,
    successRunValidationCallback?: () => void,
  ): Promise<void> => {
    const allFields = actorGetActionValue('allFields', [
      currentResource.value,
      currentResource.type,
    ])! as unknown as Array<FieldType>;

    const fieldToValidate = findFieldByName(
      metaData as MetaDataBase,
      allFields,
      fieldName,
    );

    if (fieldToValidate?.hasValidationActions) {
      const serviceValidationResult = runOnlyServiceValidationClient(
        fieldName,
        value,
        successRunValidationCallback,
      ) as unknown as Promise<void>;
      formAsyncActionList.push(serviceValidationResult);
    }
  };

  /**
   * @function onInputFocus
   * @param {string} fieldName string
   * @param {unknown} value Can be any type, boolean, string, array, etc...
   * @param {ResourceInterface} currentResource
   * @returns {void} void
   */
  const onInputFocus: OnFocus = (
    fieldName: string,
    currentResource: ResourceInterface,
  ): void => {
    const inputsRef = actorGetActionValue(
      'inputsRef',
      `${currentResource.value}.${currentResource.type}`,
    ) as Record<string, InputRefContent> | undefined;

    if (inputsRef?.[fieldName]) {
      inputsRef[fieldName]['focused'] = true;
    }
  };

  /**
   * Dispatch get drop down data action to fill the redux & actor store
   * @function getDropData
   * @param {object} param
   * @param {number} param.id
   * @param {object} param.params
   * @param {object} param.meta
   * @param {string} param.uniqueId
   * @returns {void} void
   */
  const getDropData: GetDropdownData = ({ id, params, meta, uniqueId }) => {
    // FIXME: Should not use redux
    reduxDispatch(findDropdownDataAction({ id, params, meta, uniqueId }));
  };

  /**
   * It does some processes to find next input that should be focused
   * @function focusOnNextInput
   * @param { Record<string, InputRefContent> } inputsRef
   * @param { string } currentName
   * @param { @typedef () => void } submitFormCallback
   * @returns { string | undefined }
   */
  const focusOnNextInput = (
    inputsRef: Record<string, InputRefContent>,
    currentName: string | undefined,
    formData: FormData | null,
    submitFormCallback: () => void,
  ): string | undefined => {
    if (!inputsRef || !currentName) {
      console.log(
        'focusOnNextInput: `fieldRefList` or `currentName` is empty, so can not focus on next input',
      );
      return;
    }

    const fieldNameList = actorGetActionValue('formGlobalProps')!
      .inputNameListSortedByPriority as string[];
    let indexOfCurrentField = fieldNameList.indexOf(currentName);

    if (indexOfCurrentField === -1) {
      console.log('focusOnNextInput: current field is not in fieldRefList', {
        fieldRefList: inputsRef,
      });
      return;
    }

    while (inputsRef[fieldNameList[indexOfCurrentField]]['focused'] === false) {
      indexOfCurrentField++;
    }

    const fieldType = getTypeByField(inputsRef![currentName].field);
    if (
      inputsRef[currentName].field.stayCursor ||
      (fieldType === SEARCH_FIELD &&
        (isEmpty(formData?.[currentName]) ||
          inputsRef[currentName].inputRef.current?.value === ''))
    ) {
      return;
    }

    /**
     * If we have one input then we don't have to set its `focused` to `false`
     */
    if (fieldNameList.length > 1) {
      inputsRef[currentName]['focused'] = false;
    }

    let nextFieldName: string | null = null;
    const { ignoreToFocusFieldNameList } = actorGetActionValue('formGlobalProps')!;
    do {
      nextFieldName = fieldNameList[++indexOfCurrentField];

      if (indexOfCurrentField > fieldNameList.length - 1) {
        break;
      }

      if (
        nextFieldName &&
        ignoreToFocusFieldNameList.indexOf(nextFieldName) === -1 &&
        inputsRef[nextFieldName].field.priority !== 0
      ) {
        focusOnInput(inputsRef[nextFieldName!].inputRef.current);
        break;
      } else {
        nextFieldName = null;
      }
    } while (!nextFieldName);
    // if next is not available, because on last field, we submit the form
    if (!nextFieldName) {
      submitFormCallback();

      console.warn('focusOnNextInput: nextFieldName is NULL ', {
        fieldType,
        field: inputsRef[currentName],
        formData,
        inputsRef,
      });
    }

    return nextFieldName;
  };

  // FIXME: Based on last input ref changes, complete this function
  /**
   * Based on input element received, focuses on that input
   * @function focusOnInput
   * @param { unknown | any } inputElement
   * @returns { void }
   */
  const focusOnInput = (inputElement): void => {
    if (!inputElement) return;

    requestAnimationFrame(() => {
      if (inputElement.input) {
        inputElement.input.focus?.();
        inputElement.input.select?.();
      } else {
        inputElement.focus?.();
        inputElement.select?.();
      }
    });
  };

  /**
   * It focuses on the first element of received the ref list
   * @function focusOnFirstInput
   * @param { Record<string, InputRefContent> } inputsRef
   * @returns { void }
   */
  const focusOnFirstInput = (inputsRef: Record<string, InputRefContent>): void => {
    const fieldNameList = Object.keys(inputsRef);
    const { ignoreToFocusFieldNameList } = actorGetActionValue('formGlobalProps')!;

    for (const fieldName of fieldNameList) {
      if (ignoreToFocusFieldNameList.indexOf(fieldName) > -1) {
        continue;
      }

      focusOnInput(inputsRef[fieldName]?.inputRef.current);
      break;
    }
  };

  /**
   * When it's called, finds the first of the received `ref` inputs that should be focused
   * @function focusOnFirstInputAfterSubmit
   * @param { Record<string, InputRefContent> } inputsRef
   * @returns { void }
   */
  const focusOnFirstInputAfterSubmit = (): void => {
    const currentResource = actorGetActionValue('resources')!.current;
    const inputsRef = actorGetActionValue(
      'inputsRef',
      `${currentResource.value}.${currentResource.type}`,
    ) as Record<string, InputRefContent> | null;
    if (inputsRef == null) return;

    console.log('focusOnFirstInputAfterSubmit: inputsRef ', inputsRef);

    const { keepFocusAfterSubmitFieldNameList } =
      actorGetActionValue('formGlobalProps')!;

    const targetInputRef = inputsRef[keepFocusAfterSubmitFieldNameList[0]];
    if (targetInputRef) {
      targetInputRef['focused'] = true;
      focusOnInput(targetInputRef.inputRef.current);
    } else {
      console.warn('focusOnFirstInputAfterSubmit `targetInputRef` is null');
    }
  };

  actorSetActionValue(
    'formGlobalProps',
    {
      focusOnInput,
      focusOnNextInput,
      focusOnFirstInput,
      focusOnFirstInputAfterSubmit,
    },
    {
      path: 'formFocusManagementFunctions',
    },
  );

  // --------------------------------------- prepare form props ---------------------------------------------
  let controllerTemplate: JSX.Element = <></>;

  if (
    formName === 'createEditRecordPage' ||
    formName === 'dropdownQuickCreateForm'
  ) {
    actorSetActionValue('formGlobalProps', isEmpty(id), {
      path: 'isCreateMode',
    });

    const formWithTabsProps: FormWithTabsProps = {
      metaData: metaData as GeneralMetaData,
      isCreateMode: isEmpty(id),
      isQuickForm,
      location: location,
      match: { params },
      basePath: `/${resource}`,
      // TODO: prepare form with tabs controller require props
    };
    controllerTemplate = (
      <>
        {cloneElement<CustomFormInterface>(children, {
          childProps: formWithTabsProps,
          toolbarProps,
        })}
      </>
    );
  } else if (
    formName === 'quickCreateDialog' ||
    formName === 'relationEditDialogForm'
  ) {
    actorSetActionValue('formGlobalProps', formName === 'quickCreateDialog', {
      path: 'isCreateMode',
    });

    const formWithTabsProps: FormWithTabsProps = {
      metaData: metaData as GeneralMetaData,
      isCreateMode: formName === 'quickCreateDialog',
      isQuickForm,
      location: location,
      match: { params },
      basePath: `/${resource}`,
      // TODO: prepare form with tabs controller require props
    };
    controllerTemplate = (
      <>
        {cloneElement<CustomFormInterface>(children, {
          childProps: formWithTabsProps,
          toolbarProps,
        })}
      </>
    );
  } else if (formName === 'serviceDialogForm' || formName === 'profileForm') {
    controllerTemplate = (
      <>
        {cloneElement<CustomFormInterface>(children, {
          toolbarProps,
        })}
      </>
    );
  } else if (formName === 'quickEditDialog') {
    const quickEditDialogProps: QuickEditDialogProps = {
      metaData: metaData as GeneralMetaData,
      isCreateMode: false,
      isQuickForm,
      location: location,
      match: { params },
      basePath: `/${resource}`,
      // TODO: prepare form with tabs controller require props
    };

    controllerTemplate = (
      <>
        {cloneElement<CustomFormInterface>(children, {
          childProps: quickEditDialogProps,
          toolbarProps,
        })}
      </>
    );
  } else if (formName === 'wms') {
    const wmsFormProps: WMSFormProps = {
      wmsMetaData: (metaData as WMSFetchMetaResult)?.data,
    };

    controllerTemplate = (
      <>
        {cloneElement<CustomFormInterface>(children, {
          childProps: wmsFormProps,
          formName,
        })}
      </>
    );
  } else if (formName === 'changePassword') {
    controllerTemplate = (
      <>
        {cloneElement<CustomFormInterface>(children, {
          toolbarProps,
        })}
      </>
    );
  } else if (formName === 'VisitorForm') {
    controllerTemplate = <>{cloneElement<CustomFormInterface>(children, {})}</>;
  } else if (formName === 'simpleForm') {
    controllerTemplate = (
      <>
        {cloneElement<CustomFormInterface>(children, {
          toolbarProps,
        })}
      </>
    );
  } else {
    console.error(`formName ${formName} not support`);
  }
  // TODO: add loading on card https://jira.samiansoft.com/browse/RCT-1484

  return controllerTemplate;
};

export { FormController };
