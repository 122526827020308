import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { useDispatch } from 'react-redux';
import { useLocale, setSidebarVisibility } from 'react-admin';

import { DrawerInterface, DrawerView } from '../drawer';
import { useStyles } from './custom-nav.style';
import { CustomNavInterface } from './custom-nav.type';
import { actorDispatch, actorOnDispatch } from '../../../type/actor-setup';
import { getDrawerWidth } from '../drawer/drawer.helper';

const CustomNav: FC<CustomNavInterface> = props => {
  const { container, logout, hasDashboard } = props;

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);

  const drawerWidth = useMemo(() => {
    return getDrawerWidth(isDrawerOpen);
  }, [isDrawerOpen]);

  const classes = useStyles({ drawerWidth });
  const locale = useLocale();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSidebarVisibility(true));
  }, [setSidebarVisibility]);

  useEffect(() => {
    actorOnDispatch('isDrawerOpen', setIsDrawerOpen, {
      preserve: false,
    });
  }, []);

  /**
   * toggle drawer locally with state and dispatch new state in actor
   * @function toggleMenuDrawer
   * @returns {void}
   */
  const toggleMenuDrawer = useCallback(() => {
    setIsDrawerOpen(!isDrawerOpen);
    actorDispatch('isDrawerOpen', !isDrawerOpen);
  }, []);

  const drawerProps: DrawerInterface = {
    isDrawerOpen,
    logout,
    hasDashboard,
    locale,
    toggleMenuDrawer,
  };

  return (
    <nav className={classes.drawer}>
      {/* @ts-ignore */}
      <Hidden smUp implementation="js">
        <Drawer
          style={{ transition: 'all 0.2s ease' }}
          container={container}
          variant="temporary"
          anchor="left"
          open={isDrawerOpen}
          onClose={toggleMenuDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          id="drawerUp"
        >
          <DrawerView {...drawerProps} />
        </Drawer>
      </Hidden>

      {/* @ts-ignore */}
      <Hidden xsDown implementation="css">
        <Drawer
          anchor="left"
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open={isDrawerOpen}
          id="drawerDown"
        >
          <DrawerView {...drawerProps} />
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default CustomNav;
