import React, { useState, useRef, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

import { getTodoTaskDetailsPatternInfo } from '../../helper/PatternMetaHelper';

interface TodoTaskAddNotePropsInterface {
  resource: string;
  task: object;
  taskId: number;
  onChange: Function;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    height: '100%',
  },

  todoTaskAddNoteTxtarea: {
    padding: 12,
    resize: 'none',
    outline: 'none',
    width: '100%',
    height: '100%',
    border: 'none',
    backgroundColor: 'transparent',
    fontFamily: theme.typography.fontFamily,
    lineHeight: 'normal',
  },
}));

const TodoTaskAddNote: FC<TodoTaskAddNotePropsInterface> = props => {
  const { resource, task, onChange } = props;
  const classes = useStyles();
  const translate = useTranslate();

  const { note } = getTodoTaskDetailsPatternInfo(resource);
  const [noteValue, setNoteValue] = useState(task[note]);
  const [taskData, setTaskData] = useState(task);

  const element = useRef(null);

  const handleInputBlur = event => {
    setNoteValue(event.target.value);
    submitValues({ [note]: event.target.value });
  };

  const handleInputFocus = event => {
    event.target.select();
  };

  const handleInputChange = event => {
    setNoteValue(event.target.value);
    submitValues({ [note]: event.target.value });
  };

  /**
   * Submit changes to update task
   * @function submitValues
   * @param {object} input
   * @returns {undefined}
   */
  const submitValues = (input: object): undefined => {
    const data = {};
    let changeCounter = 0;
    Object.keys(input).forEach(key => {
      if (taskData[key] !== input[key]) {
        data[key] = input[key];
        changeCounter++;
      }
    });

    if (changeCounter) {
      setTaskData({ ...task, ...data });
      onChange({ data, ref: element });
    }
    return;
  };

  return (
    <div className={classes.container} data-test-task-add-note>
      <textarea
        ref={element}
        value={noteValue}
        onDoubleClick={handleInputFocus}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        className={classes.todoTaskAddNoteTxtarea}
        placeholder={translate('todo.note')}
        data-style-add-note="addNote"
      ></textarea>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    task: lodashGet(
      state,
      ['admin', 'resources', props.resource, 'data', props.taskId],
      {},
    ),
  };
};

export default connect(mapStateToProps, {})(TodoTaskAddNote);
