import { ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'classnames';

import { UserItemViewInterface } from './user-item.type';
import { useStyles } from './user-item.style';
import {
  API_URL,
  API_VERSION,
  getValue,
  SESSION_ID,
} from '../../../../../core/configProvider';

const UserItemView = (props: UserItemViewInterface): ReactElement => {
  const { selectedUser, item, handleOnUserSelect } = props;
  const classes = useStyles();
  const sessionId = getValue(SESSION_ID);
  const apiUrl = getValue(API_URL);
  const apiVersion = getValue(API_VERSION);

  return (
    <ListItem
      button
      className={clsx(classes.listItemRoot, 'item-hover', {
        active: selectedUser && selectedUser.personinfo_id === item.personinfo_id,
      })}
      onClick={handleOnUserSelect}
    >
      <Box>
        <ListItemAvatar className={classes.avatarRoot}>
          <>
            <Avatar
              className={classes.avatar}
              src={`${apiUrl}/oauth2/${sessionId}/${apiVersion}${item.personimage}`}
            />

            {/*  //TODO: implement userStatus later
            <Box
              className={classes.userStatus}
              // bgcolor={item?.status === 'online' ? green[600] : red[600]}
              bgcolor={green[600]}
            /> */}
          </>
        </ListItemAvatar>
      </Box>
      <Box className={classes.userInfoRoot}>
        <Box
          component="span"
          fontWeight={300}
          display="block"
          mb={0.5}
          fontSize={14}
        >
          {item?.personname}
        </Box>
      </Box>
    </ListItem>
  );
};

export default UserItemView;
