import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { FieldType } from '../../../helper/Types';

/**
 * check field should render or nor
 * @param { string | FieldType | null} field
 * @param { string } width
 * @returns {boolean}
 */
export const shouldHideField = (
  field: 'empty' | FieldType | null,
  width: Breakpoint,
): boolean => {
  return (
    (field === 'empty' && (width === 'lg' || width === 'xl')) ||
    field?.['hidden'] ||
    (field as FieldType)?.dataType?.erp === 'button'
  );
};
