import moment from 'moment';
import {
  CalendarEventInterface,
  EventTypeOnChange,
  EventTypeRemote,
  ResponseEvent,
} from '.';
import { SERVER_DATE_FORMAT, SERVER_TIME_FORMAT } from '../../core/configProvider';
import { themeParams } from '../../core/themeProvider';
import { visitorItems } from '../visitor-calendar/visitor-calendar.helper';
import lodashFind from 'lodash/find';
import { convertDateToBigCalenderReadableFormat } from '../../helper/data-helper';

/**
 * this function sanitize response data to EventType
 * @function convertToEventObject
 * @param {ResponseEvent[]} list
 * @returns {CalendarEventInterface[]}
 */
export const convertToEventObject = (
  list: ResponseEvent[],
): CalendarEventInterface[] => {
  return list.map(obj => {
    const {
      enddate,
      isalldayevent,
      message,
      startdate,
      visitcalendar_id,
      visitcalendartype_id,
      starttime,
      endtime,
    } = obj;
    return {
      id: visitcalendar_id,
      title: visitorItems[visitcalendartype_id] ?? message,
      allDay: isalldayevent,
      start: convertDateToBigCalenderReadableFormat(
        startdate.replaceAll('00:00:00', starttime),
      ),
      end: convertDateToBigCalenderReadableFormat(
        enddate.replaceAll('00:00:00', endtime),
      ),
      hexColor:
        visitcalendartype_id === 1
          ? themeParams.palette.primary.eventColorPrimary
          : visitcalendartype_id === 2
          ? themeParams.palette.primary.eventColorSecondary
          : '',
    };
  });
};

/**
 * this function sanitize event form remote date
 * @function sanitizerEventRemote
 * @param {EventTypeOnChange} event
 * @param {number} visitCalendarTypeId
 * @param {number} visitorpersoninfo_id
 * @returns {EventTypeRemote}
 */
export const sanitizerEventRemote = (
  event: EventTypeOnChange,
  visitorPersonInfoId?: number,
  visitCalendarTypeId?: number,
): EventTypeRemote => {
  return {
    startdate: moment(event.start as Date).format(SERVER_DATE_FORMAT),
    enddate: moment(event.end as Date).format(SERVER_DATE_FORMAT),
    starttime: moment(event.start as Date).format(SERVER_TIME_FORMAT),
    endtime: moment(event.end as Date).format(SERVER_TIME_FORMAT),
    visitorpersoninfo_id: visitorPersonInfoId,
    visitpath_id: undefined,
    mainaccount_id: undefined,
    message: event.title,
    isalldayevent: event.allDay,
    visitcalendartype_id: visitCalendarTypeId,
    visitcalendar_id: event?.id,
  };
};

/**this function create temp id for events
 * @function createTempId
 * @param {CalendarEventInterface[]} events
 * @returns {number}
 */
export const createTempId = (events: CalendarEventInterface[]): number => {
  const idList = events.map(a => a.id ?? 1);
  return Math.max(...idList) + 1;
};

/**
 * @function isAllDayChecker
 * @param {CalendarEventInterface} event
 * @param {string} key
 * @returns {boolean}
 */
export const isAllDayChecker = (
  event: CalendarEventInterface,
  key = 'resourceId',
): boolean => {
  return !Object.keys(event).find(item => item === key);
};
