import lodashMerge from 'lodash/merge';

import {
  actorGetActionValue,
  RecordKeyMode,
  actorSetActionValue,
  actorDispatch,
} from './../type/actor-setup';
import { clone, isEmpty } from './../helper/data-helper';
import dataProvider, { GET_DROPDOWN } from '../core/dataProvider';
import { isEmptyObject } from '../helper/data-helper';
import { actorOnDispatch } from '../type/actor-setup';
import { getDropdownRequestParams } from '../helper/dropdown-api.helper';
import { FormData } from '../component/form';
import { showNotification } from '../helper/general-function-helper';

actorOnDispatch('fetchDropdownData', async toFetchDropdownData => {
  const {
    dropdownId,
    option = {},
    dropdownMeta,
    resource,
    resourceType,
    searchValue,
    hasMore,
    successCallback,
    failureCallback,
  } = toFetchDropdownData;

  const {
    dropdownInPuzzleForm = false,
    isTodo = false,
    isProfile = false,
    isService = false,
    perPage,
    page,
    forceTreeLevel,
    filterValues,
    sort,
    fieldName,
    fieldId,
  } = option;

  const record = clone(
    actorGetActionValue('record', [resource, resourceType, RecordKeyMode.FULL]),
  ) as Record<string, unknown>;

  let formData: FormData | null = clone(
    actorGetActionValue('formData', `${resource}.${resourceType}`),
  );

  if (dropdownInPuzzleForm && formData == null) {
    const rootResource = actorGetActionValue('resources')!.stack[0];
    formData = actorGetActionValue(
      'formData',
      `${rootResource.value}.${rootResource.type}`,
    );
  }

  const myDynamicCondition =
    dropdownInPuzzleForm || isTodo || isProfile || isService;

  const checker = (result, condition = myDynamicCondition) =>
    condition ? undefined : result;

  const params = {
    dropdownId: checker(dropdownMeta.uniqueId ?? dropdownId),
    dropdownResource: checker(resource),
    fieldName: checker(fieldName),
    dropdownValueMember: dropdownMeta?.valueMember,
  };

  let finalRecord: FormData | undefined;
  if (dropdownInPuzzleForm) {
    finalRecord = formData!;
  } else {
    if (!isEmptyObject(formData)) {
      finalRecord = formData!;
    } else {
      finalRecord = record;
    }
  }

  const finalParams = lodashMerge(
    getDropdownRequestParams({
      dropdownMeta,
      record: finalRecord ?? {},
      perPage: !isEmpty(perPage) ? (perPage as number) : null,
      page: !isEmpty(page) ? (page as number) : null,
      forceTreeLevel: !isEmpty(forceTreeLevel) ? (forceTreeLevel as boolean) : null,
      filterValues: !isEmpty(filterValues)
        ? (filterValues as Record<string, unknown>)
        : {},
      sort: !isEmptyObject(sort) ? (sort as Record<string, unknown>) : {},
      search: searchValue ?? '',
      isWMS: dropdownInPuzzleForm as boolean,
      resource,
    }),
    params,
  );

  try {
    const responsePromise = dataProvider(
      GET_DROPDOWN,
      dropdownMeta.uniqueId ?? dropdownId,
      {
        ...(finalParams as Record<string, unknown>),
      },
    );

    if (dropdownInPuzzleForm) {
      const wmsAsyncActionList = actorGetActionValue('wmsAsyncActionList') ?? [];
      actorSetActionValue('wmsAsyncActionList', [
        ...wmsAsyncActionList,
        responsePromise,
      ]);
    }

    const { result, total, userMessage } = await responsePromise;

    if (dropdownInPuzzleForm && result?.[0] != null) {
      const currentResource = actorGetActionValue('resources')!.current;

      actorDispatch('dropDownData', result[0], {
        path: `${currentResource.value}.${currentResource.type}`,
        replaceAll: true,
      });
    }

    if (hasMore === true) {
      const dropDownDataInActor =
        actorGetActionValue(
          'dropDownData',
          `${dropdownMeta?.uniqueId}-${fieldId}.DATA`,
        ) ?? [];

      actorSetActionValue('dropDownData', {
        [`${dropdownMeta?.uniqueId}-${fieldId}`]: {
          DATA: [
            ...(dropDownDataInActor as unknown as Record<string, unknown>[]),
            ...result,
          ],
          TOTAL: total,
        },
      });

      successCallback({
        DATA: [
          ...(dropDownDataInActor as unknown as Record<string, unknown>[]),
          ...result,
        ],
        TOTAL: total,
      });
    } else {
      actorSetActionValue('dropDownData', {
        [`${dropdownMeta?.uniqueId}-${fieldId}`]: {
          ALL: result,
          DATA: result,
          TOTAL: total,
        },
      });

      if (dropdownInPuzzleForm && !isEmpty(userMessage)) {
        showNotification(userMessage, 'info');
      }

      successCallback({
        ALL: result,
        DATA: result,
        TOTAL: total,
      });
    }
  } catch (error) {
    if (typeof failureCallback === 'function') {
      failureCallback({ result: null, error });
    }
    console.log('dropdown-api.ts:78 error', { error });
  }
});

actorOnDispatch('getDropdownData', async details => {
  if (isEmptyObject(details)) return;
});
