import React, { useState, createRef, useEffect } from 'react';
import compose from 'recompose/compose';
import { Icon, makeStyles } from '@material-ui/core';
import { isEnterPressed } from '../../helper/FormHelper';
import { translate } from 'react-admin';
import { connect } from 'react-redux';
import { addNewListOrGroupAction } from '../../redux/todoList/action';
import {
  getTodoListPatternInfo,
  getListMemberPatternInfo,
} from '../../helper/PatternMetaHelper';
import { getValue, USER_ID } from '../../core/configProvider';
import { isEmpty } from '../../helper/data-helper';

const useStyles = makeStyles(theme => ({
  container: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    // padding: '0 15px',
  },

  addContainer: {
    flex: 1,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FAFAFA',
    },
  },

  newListInput: {
    border: 'none',
    outline: 'none',
    padding: '10px 0',
    backgroundColor: 'transparent',
    width: '105px',
    fontFamily: theme.typography.fontFamily,
  },

  newGroupInput: {
    border: 'none',
    outline: 'none',
    padding: '10px 0',
    margin: '0 10px 0 10px',
    backgroundColor: 'transparent',
    flex: 1,
    fontFamily: theme.typography.fontFamily,
  },

  iconCancel: {
    color: 'rgba(0, 0, 0, 0.7)',
    position: 'absolute',
    right: 5,
    cursor: 'pointer',
    fontSize: 20,
    marginTop: '2px',
    zIndex: 1,
  },

  icon: {
    margin: '0 10px 0 15px',
  },

  addGroup: {
    padding: '10px 13px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#FAFAFA',
    },
  },
}));

const TodoAddNewList = props => {
  const { resource, addNewTodoListOrGroup, translate, largestRowOrder } = props;
  const classes = useStyles();

  const [listName, setListName] = useState('');
  const [groupName, setGroupName] = useState('');
  const [showGroupInput, setShowGroupInput] = useState(false);

  const {
    reportResource,
    sort,
    pagination,
    listMemberResource,
    title,
    groupResource,
    rowOrder,
    staticFilterList,
    reportTaskDetailsResource,
    id: TodoListIdPattern,
    groupId: TodoListGroupIdPattern,
    idListMember,
  } = getTodoListPatternInfo(resource);

  const {
    groupName: listMemberGroupName,
    userId: userIdPattern,
    groupId,
    listId,
  } = getListMemberPatternInfo(listMemberResource);

  const groupInputRef = createRef<HTMLInputElement>();

  const handleClickOutsideGroupInput = event => {
    if (
      groupInputRef &&
      groupInputRef.current &&
      !groupInputRef.current.contains(event.target)
    ) {
      if (groupName) {
        addNewListOrGroup(groupId);
      } else {
        setShowGroupInput(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideGroupInput);
    return () =>
      document.removeEventListener('mousedown', handleClickOutsideGroupInput);
  });

  const handleChangeText = event => {
    setListName(event.target.value);
  };

  const handleChangeGroupText = event => {
    setGroupName(event.target.value);
  };

  const addNewListOrGroup = filedPattern => {
    const currentUserId = getValue(USER_ID);
    const data = {};
    const listMemberRowOrder = { [rowOrder]: largestRowOrder + 1 };
    const resources = {
      listMemberResource,
      reportResource,
      listResource: resource,
      fieldResource: resource,
    };
    if (filedPattern === listId) {
      // If It's a LIST
      if (isEmpty(listName)) return;
      Object.assign(data, { [title]: listName });
      setListName('');
    } else {
      // If It's a GROUP
      if (isEmpty(groupName)) return;
      Object.assign(data, { [listMemberGroupName]: groupName });
      resources.fieldResource = groupResource;
      setGroupName('');
      setShowGroupInput(false);
    }

    addNewTodoListOrGroup(
      resources,
      data,
      listMemberRowOrder,
      currentUserId,
      sort,
      pagination,
      userIdPattern,
      filedPattern,
      {
        staticFilterList,
        reportTaskDetailsResource,
        groupId: TodoListGroupIdPattern,
        listId: TodoListIdPattern,
        listMemberId: idListMember,
      },
    );
  };

  const keyPress = event => {
    if (isEnterPressed(event)) {
      addNewListOrGroup(listId);
    }
  };

  const toggleGroupBox = () => {
    setShowGroupInput(!showGroupInput);
    setGroupName('');

    setTimeout(() => {
      if (groupInputRef && groupInputRef.current) {
        groupInputRef.current.focus();
      }
    }, 100);
  };

  const groupInputKeyPress = event => {
    if (isEnterPressed(event)) {
      addNewListOrGroup(groupId);
    }
  };

  const handleAddNewListClick = () => {
    addNewListOrGroup(listId);
  };

  return (
    <div className={classes.container}>
      {showGroupInput && (
        <div className={classes.addContainer}>
          <input
            className={classes.newGroupInput}
            data-test-group="inputName"
            ref={groupInputRef}
            placeholder={translate('todo.newGroup')}
            onKeyPress={groupInputKeyPress}
            value={groupName}
            onChange={event => {
              handleChangeGroupText(event);
            }}
          />
        </div>
      )}
      <div className={classes.addContainer}>
        <Icon
          className={classes.icon}
          fontSize="small"
          color={!isEmpty(listName) ? 'secondary' : 'disabled'}
          onClick={handleAddNewListClick}
        >
          add
        </Icon>

        <input
          className={classes.newListInput}
          placeholder={translate('todo.newList')}
          onKeyPress={keyPress}
          value={listName}
          onChange={handleChangeText}
          data-test-todo="newList"
        />
        <div className={classes.addGroup} data-test-group="addButton">
          <Icon fontSize="small" color="secondary" onClick={toggleGroupBox}>
            playlist_add
          </Icon>
        </div>
      </div>
    </div>
  );
};

TodoAddNewList.propTypes = {};

const mapDispatchToProps = {
  addNewTodoListOrGroup: addNewListOrGroupAction,
};

export default compose(connect(null, mapDispatchToProps), translate)(TodoAddNewList);
