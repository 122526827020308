import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';

import { FileStreamInputInterface } from './file-stream-input.type';
import FileStreamInputView from './file-stream-input.view';
import { actorDispatch, actorGetActionValue } from '../../../type/actor-setup';
import { ChangeFormValueParams, FormActions, OnBlurParams } from '../../form';
import { isEmpty, megaByteToByte } from '../../../helper/data-helper';
import { showNotification } from '../../../helper/general-function-helper';

const FileStreamInputController: FC<FileStreamInputInterface> = memo(props => {
  const {
    disabled,
    label,
    hint,
    field,
    inputRef,
    value,
    inputMessage,
    visibleClass,
    formActionsHandler,
  } = props;

  const translate = useTranslate();

  const [dragging, setDragging] = useState<boolean>(false);
  const [dragCounter, setDragCounter] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const fieldsetRef = useRef<HTMLFieldSetElement>(null);
  const currentResource = actorGetActionValue('resources')!.current;
  const globalParameters = actorGetActionValue('globalParameters');
  const fileUploadLimitMB = lodashGet(globalParameters, 'fileUploadLimitMB', null);

  useEffect(() => {
    const div = fieldsetRef.current;
    if (div) {
      div.addEventListener('dragenter', handleDragIn);
      div.addEventListener('dragleave', handleDragOut);
      div.addEventListener('dragover', handleDrag);
      div.addEventListener('drop', handleDrop);
    }
    return () => {
      const div = fieldsetRef.current;
      if (div) {
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('dragover', handleDrag);
        div.removeEventListener('drop', handleDrop);
      }
    };
  }, []);

  /**
   * we need to prevent the default browser behavior on that event, which is to open the dropped file.
   * @param {DragEvent} event
   * @function handleDrop
   * @returns {void}
   */
  const handleDrag = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
  };

  /**
   * define dragIn behavior and if there is dataTransferItems, setDragging(true)
   * @param {DragEvent} event
   * @function handleDragIn
   * @returns {void}
   */
  const handleDragIn = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    setDragCounter(prev => prev + 1);
    if (event?.dataTransfer?.items?.length) {
      setDragging(true);
    }
  };

  /**
   * define dragOut behavior and  setDragging(false)
   * @param {DragEvent} event
   * @function handleDragOut
   * @returns {void}
   */
  const handleDragOut = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    setDragCounter(prev => prev - 1);
    if (dragCounter > 0) return;
    setDragging(false);
  };

  /**
   * function receives file from event.dataTransfer.files and send it to onFileChange
   * @param {DragEvent} event
   * @function handleDrop
   * @returns {void}
   */
  const handleDrop = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    if (event?.dataTransfer?.items?.length) {
      const dropFile = {
        target: {
          files: event.dataTransfer.files,
        },
      } as React.ChangeEvent<HTMLInputElement> & {
        target: HTMLInputElement & { files: FileList };
      };
      onFileChange(dropFile);
      event?.dataTransfer?.clearData();
      setDragCounter(0);
    }
  };

  /**
   * success callback after upload file
   * @function successCallback
   * @param {object} value
   * @return {void}
   */
  const successCallback = useCallback(value => {
    formActionsHandler(FormActions.InputChange, {
      fieldName: field.name,
      value: value,
    } as ChangeFormValueParams);
    handleBlur(value);
    setLoading(false);
  }, []);

  /**
   * failure callback after upload file
   * @function failureCallback
   * @param {string} error
   * @returns {void}
   */
  const failureCallback = useCallback(error => {
    showNotification(error, 'error');
    setLoading(false);
  }, []);

  /**
   * encode user selected file to base64, add file-name before the `#` indicator and fire form onChange
   * @function onFileChange
   * @param {Event} event selected file in input
   * @returns {void}
   */
  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileMeta = lodashGet(event.target.files, '0');
      if (!fileMeta) {
        return;
      }
      if (
        fileUploadLimitMB &&
        !isEmpty(fileUploadLimitMB) &&
        megaByteToByte(+fileUploadLimitMB) < fileMeta?.size
      ) {
        showNotification(
          translate('file.fileUploadLimitMB', {
            fileUploadLimitMB,
          }),
          'error',
        );
        return;
      }
      if (event.target.files) {
        setLoading(true);
        actorDispatch('uploadStreamFile', {
          successCallback,
          param: {
            resource: `${currentResource.value}/filestream/${field.name}`,
            file: fileMeta,
          },
          failureCallback,
        });
      }
    },
    [formActionsHandler],
  );

  /**
   * trigger `onClick` input file
   * @function handleClick
   * @returns {void}
   */
  const handleClick = (): void => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  /**
   * this `clearClick clear selected file
   * @function clearClick
   * @returns {void}
   */
  const clearClick = () => {
    formActionsHandler(FormActions.InputChange, {
      fieldName: field.name,
      value: null,
    } as ChangeFormValueParams);

    handleBlur(null);
  };

  /**
   * Handle Blur event
   * @function handleBlur
   * @returns {void} void
   */
  const handleBlur = (inputValue): void => {
    formActionsHandler(FormActions.InputBlur, {
      fieldName: field.name,
      value: inputValue,
    } as OnBlurParams);
  };

  return (
    <FileStreamInputView
      fieldsetRef={fieldsetRef}
      disabled={disabled}
      label={label}
      hint={hint}
      required={field.required}
      name={field.name}
      onFileChange={onFileChange}
      inputFileRef={inputRef}
      handleClick={handleClick}
      dragging={dragging}
      fileName={(value?.realFileName as string) ?? value}
      inputMessage={inputMessage}
      loading={loading}
      visibleClass={visibleClass}
      clearClick={clearClick}
      field={field}
    />
  );
});

export default FileStreamInputController;
