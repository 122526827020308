import lodashFind from 'lodash/find';
import lodashGet from 'lodash/get';

import { ProcessInformation } from '../component/form/form-with-tabs';
import { getFieldByName } from './MetaHelper';
import {
  actorGetActionValue,
  FormKeyMode,
  RecordKeyMode,
} from '../type/actor-setup';
import {
  arrayToObjectWithSpecificKey,
  clone,
  isEmpty,
  isEmptyObject,
} from './data-helper';
import {
  checkUiEnable,
  checkUiVisible,
} from '../component/form/form-with-tabs/form-with-tabs.helper';

import type { ProcessTaskInterface } from '../component/ShiftProcessButton';
import type { Locale } from '../type/global-types';
import type {
  LayoutField,
  FieldType,
  MetaData,
  GeneralMetaData,
  MetaDataBase,
  AdditionalData,
  MetaDataQuickTab,
  MetaDataTab,
} from './Types';

import type {
  GetTabListInterface,
  RawShowTabs,
  FieldLayoutInterface,
} from './meta-helper.type';

// ------------------- form related functions -------------------

/**
 * this function receives a name of field and found the related field with getFieldByName from MetaHelper.
 * then try to find similar field from fieldList array from props.
 * if field exist on field list return that else return original field from getFieldByName.
 * @function findFieldByName
 * @param {MetaData} metaData
 * @param {Array<FieldType>} fieldsWithHigherPriority
 * @param {string} fieldName
 * @returns {FieldType | null} a field if found it or null
 */
export const findFieldByName = (
  metaData: MetaDataBase,
  fieldsWithHigherPriority: Array<FieldType>,
  fieldName: string,
): FieldType | null => {
  // find field by name from meta
  const dirtyFieldFromMeta = getFieldByName(metaData, fieldName);

  if (!isEmptyObject(dirtyFieldFromMeta)) {
    // find similar field in fieldsWithHigherPriority
    const similarFieldInFieldsArray = fieldsWithHigherPriority?.filter(
      field => field['name'] === dirtyFieldFromMeta['name'],
    );

    if (similarFieldInFieldsArray && similarFieldInFieldsArray.length > 0) {
      return similarFieldInFieldsArray[0]; // field with tabId and tabTitle
    } else {
      return dirtyFieldFromMeta; // field without tabId and tabTitle
    }
  } else {
    return null;
  }
};

/**
 * get record and extract process information keys from it
 * @param {Record<string, unknown>} record
 * @returns {ProcessInformation} ProcessInformation
 */
export const getProcessInformationFromRecord = (
  record: Record<string, unknown> | undefined,
): ProcessInformation => {
  return {
    processuniqueid: (record?.__processuniqueid as string) ?? null,
    positionid: (record?.positionid as string) ?? null,
    stateid: (record?.stateid as string) ?? null,
  };
};

/**
 * it will return table id of root table
 * @function getRootTableId
 * @returns {number|null}
 */
export const getRootTableId = (): number | null => {
  const resources = actorGetActionValue('resources');
  if (!resources) return null;

  const rootMetaData = actorGetActionValue(
    'metaData',
    resources.stack[0].value,
  ) as unknown as MetaDataBase;
  if (!rootMetaData) return null;

  return rootMetaData.config?.moduleTableId ?? null;
};

/**
 * extract valid services from meta data
 * @function getVisibleServices
 * @param {MetaData} metaData
 * @param {Record<string, unknown>} Record
 * @returns {Array<Record<string, unknown>> | null }
 */
export const getVisibleServices = (
  metaData: MetaData,
  record: Record<string, unknown>,
): Array<Record<string, unknown>> | null => {
  let visibleServices: Array<Record<string, unknown>> | null = null;

  if (Array.isArray(metaData?.['actions'])) {
    if (record?.iseditable) {
      visibleServices = metaData['actions'];
    } else {
      const activeServices = metaData['actions'].filter(
        action => action?.runOnNonEditableRecords,
      );

      if (activeServices.length) {
        visibleServices = activeServices;
      }
    }
  }

  return visibleServices;
};

/**
 * // TODO: complete jsDoc
 * @param metaData
 * @param locale
 * @returns
 */
export const getTranslatedName = (
  metaData: GeneralMetaData,
  locale: Locale,
): string | null => {
  if (isEmptyObject(metaData) || !locale) {
    return null;
  }

  const translatedTitleLocale =
    metaData?.translatedTitle?.[locale] ?? metaData.title;

  if (translatedTitleLocale) {
    return translatedTitleLocale;
  }

  const translatedCaptionLocale =
    metaData.config?.translatedCaption?.[locale] ?? metaData.config?.caption;

  if (translatedCaptionLocale) {
    return translatedCaptionLocale;
  }

  return metaData.config?.title ?? null;
};

// ------------------- grid related functions -------------------

/**
 * creates an empty layout (matrix) according to `rowCount`, and `columnCount`.
 * @function createEmptyLayout
 * @param {Number} rowCount
 * @param {Number} columnCount
 * @returns {Array<Array<String>>}
 */
export const createEmptyLayout = (
  rowCount: number,
  columnCount: number,
): 'empty'[][] => {
  return [...Array(rowCount + 1)].map(() => Array(columnCount).fill('empty'));
};

/**
 * `getMaxMinOfRow` finds the last row-index and checks row-span and returns max of rows.
 * @function getMaxMinOfRow
 * @param {Array<FieldType | FieldLayoutInterface>} fieldsLayout
 * @returns {Number}
 */
export const getMaxMinOfRow = (
  fieldsLayout: Array<FieldType | FieldLayoutInterface>,
): number => {
  return fieldsLayout.reduce((accumulator, { rowIndex, rowSpan }) => {
    if (rowIndex >= accumulator) {
      accumulator = rowIndex;

      if (rowSpan) {
        accumulator = accumulator + rowSpan - 1;
      }
    }

    return accumulator;
  }, 0);
};

/**
 * `createLayout` with using `rowIndex`, `columnIndex`, `rowSpan` and `colSpan` make a layout for the form .
 * @function createLayout
 * @param {Array<FieldLayoutInterface>} fieldsLayout
 * @param {Number} columnCount
 * @param {Object} fieldSet
 * @returns {Array}
 */
export const createLayout = (
  fieldsLayout: Array<FieldLayoutInterface>,
  columnCount: number,
  fieldSet: Record<number, FieldType>,
): LayoutField[][] => {
  if (
    !(Array.isArray(fieldsLayout) && fieldsLayout.length > 0) ||
    isEmptyObject(fieldSet)
  ) {
    return [];
  }

  const maxRow = getMaxMinOfRow(fieldsLayout);
  const preparedGroup = clone(
    createEmptyLayout(maxRow, columnCount),
  ) as LayoutField[][];

  fieldsLayout.forEach(item => {
    const { fieldId, rowIndex, columnIndex, rowSpan = 0, colSpan = 0 } = item;

    // TODO: check why changing fieldset by reference
    fieldSet[fieldId] = {
      ...fieldSet[fieldId],
      colSpan: colSpan as number,
      rowSpan: rowSpan as number,
    };

    if (rowSpan) {
      for (let i = 0; i < rowSpan; i++) {
        if (preparedGroup![rowIndex + i] == null) continue;

        if (colSpan) {
          for (let j = columnIndex; j < columnIndex + colSpan; j++) {
            preparedGroup![rowIndex + i][j] = null;
          }
        } else {
          preparedGroup![rowIndex + i][columnIndex] = null;
        }
      }
    } else if (colSpan) {
      for (let j = columnIndex; j < columnIndex + colSpan; j++) {
        preparedGroup![rowIndex][j] = null;
      }
    } else {
      preparedGroup![rowIndex][columnIndex] = null;
    }

    preparedGroup![rowIndex].splice(columnIndex, 1, fieldSet[fieldId]);
  });

  return preparedGroup;
};

/**
 * disable field if exist in disabledFields object
 * @function findDisabledFields
 * @param {Object} prevFieldList
 * @param {Object} disabledFields
 * @returns {Object} new default values
 */
export const findDisabledFields = (
  prevFieldList: Record<number, FieldType>,
  disabledFields,
) => {
  const fields = clone(prevFieldList);

  Object.keys(disabledFields).forEach(fieldId => {
    if (fields[fieldId]) {
      fields[fieldId].disabled = true;
    }
  });

  return fields;
};

const additionalDataShowMode = (
  fieldId,
  isShowMode,
  additionalData,
  prevFieldList,
) => {
  return isShowMode && additionalData && !isEmptyObject(additionalData)
    ? {
        hidden: prevFieldList[fieldId]?.hidden,
        disabled: prevFieldList[fieldId]?.disabled,
      }
    : {};
};

const checkViewPattern = (
  taskField,
  fieldId,
  isShowMode,
  additionalData,
  prevFieldList,
) => {
  if (taskField && taskField?.disabled === true && taskField?.hidden === true) {
    return { disabled: taskField?.disabled, hidden: taskField?.hidden };
  } else if (taskField && taskField?.disabled === true) {
    return {
      disabled: taskField?.disabled,
      hidden:
        additionalDataShowMode(fieldId, isShowMode, additionalData, prevFieldList)
          ?.hidden ?? taskField?.hidden,
    };
  } else if (taskField && taskField?.hidden === true)
    return {
      disabled:
        additionalDataShowMode(fieldId, isShowMode, additionalData, prevFieldList)
          ?.disabled ?? taskField?.disabled,
      hidden: taskField?.hidden,
    };
  else {
    return additionalDataShowMode(
      fieldId,
      isShowMode,
      additionalData,
      prevFieldList,
    );
  }
};

export const getProcessFieldList = (
  metaData: GeneralMetaData,
  prevFieldList: Record<number, FieldType>,
  processInfo: ProcessInformation,
  isShowMode = false,
  additionalData: AdditionalData = {
    disabled: {},
    hidden: {},
  },
): Record<number, FieldType> => {
  // fields in metaData have some properties, but it may a field has some higher priority properties in a specific state of a process.
  // in this situation higher priority properties will override on default field properties . `fieldsWithProcessNewProperties` is the result of this merge.
  const fieldsWithProcessNewProperties = prevFieldList;

  // if isShowMode true apply hidden and disabled form additional to field
  const { processuniqueid, positionid, stateid } = processInfo;

  // find process in metaData
  const processList = lodashFind(metaData?.processes, {
    uniqueId: processuniqueid,
  });

  if (processList) {
    let taskInfo: ProcessTaskInterface | null = null;

    if (positionid && stateid) {
      taskInfo = lodashFind(processList.tasks, {
        positionId: +positionid,
        stateId: +stateid,
      });

      if (!taskInfo) {
        console.error(
          `unable to find task with position "${positionid}" and state "${stateid}" in process "${processuniqueid}"`,
        );
      }
    } else {
      console.log('going with default task of process');
      taskInfo = processList.firstTask;
    }

    if (taskInfo) {
      Object.keys(taskInfo.fields).forEach(fieldId => {
        const taskField = taskInfo!.fields[fieldId];
        const metaField = metaData.fields[fieldId];
        if (typeof metaField === 'undefined') {
          console.log(`fieldId ${fieldId} is not in fields!`);
        } else {
          fieldsWithProcessNewProperties[fieldId] = {
            ...metaField,
            ...taskField,
            ...checkViewPattern(
              taskField,
              fieldId,
              isShowMode,
              additionalData,
              prevFieldList,
            ),
          };
        }
      });

      // disable fields that not exist on task but exist in fieldsWithProcessNewProperties
      Object.keys(metaData.fields).forEach(fieldId => {
        if (
          typeof taskInfo!.fields[fieldId] === 'undefined' &&
          fieldsWithProcessNewProperties[fieldId]
        ) {
          fieldsWithProcessNewProperties[fieldId].disabled = true;
        }
      });
    }
  }

  return fieldsWithProcessNewProperties;
};

/**
 * find fields in meta data from an array of field ids
 * @function findFieldsFromMeta
 * @param {Object} metaDate
 * @param {Array<Number} fieldIds
 * @returns {object}
 */
export const findFieldsFromMetaData = (
  metaDate: GeneralMetaData,
  fieldIds: number[] = [],
  isShowMode = false, // fixme : remove this parameter if not use
): Record<number, FieldType> => {
  const fields: Record<number, FieldType> = {};

  fieldIds.forEach(id => {
    if (typeof metaDate.fields[id] !== 'undefined') {
      fields[id] = clone(metaDate.fields[id]);
    }
  });

  // fixme: remove this commented section
  // if (isShowMode && additionalData && !isEmptyObject(additionalData)) {
  //   fields = { ...fields, ...applyAdditionalDataFields(metaDate, additionalData) };
  // }
  return fields;
};

/**
 * it should update the default values of field in different conditions
 * @param {Object} metaData meta data
 * @param {Array<Number>} groupFields array of fields that exist in group lists in meta
 * @param {String} processUniqueId
 * @param {String} positionId
 * @param {String} stateid
 * @param {Object} disabledFields
 * @param {number[] | null} relationHiddenFields
 * @returns {Object} fields with default values
 */
export const getGroupFieldsFromFieldsList = (
  metaData: GeneralMetaData,
  groupFields: any,
  processInfo: ProcessInformation,
  isShowMode = false,
  resource: string,
  relationHiddenFields: number[] | null = null,
): Record<number, FieldType> => {
  let fieldSet = findFieldsFromMetaData(metaData, groupFields, isShowMode);

  if (processInfo.processuniqueid && metaData.processes) {
    fieldSet = getProcessFieldList(metaData, fieldSet, processInfo, isShowMode);
  }

  const additionalData = actorGetActionValue(
    'recordAdditionalData',
    resource,
  ) as unknown as AdditionalData;
  const currentRecord = actorGetActionValue(
    'record',
    `${resource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
  ) as Record<string, unknown> | null;

  if (currentRecord) {
    // const isReport = resource.indexOf('report') === 0;
    // const recordIsEditable =
    //   !isReport &&
    //   isRecordEditable(metaData, currentRecord) &&
    //   isMetaEditable(metaData);

    const disabledFieldsInAdditionalData = additionalData?.disabled ?? {};
    const hiddenFieldsInAdditionalData = additionalData?.hidden ?? {};

    for (const [, field] of Object.entries(fieldSet)) {
      if (isEmptyObject(field)) continue;

      try {
        let uiVisible = false;
        if (isShowMode && !isEmpty(field.javaScriptUiVisible?.trim())) {
          uiVisible = !checkUiVisible(field, currentRecord);
        }

        field.hidden = Boolean(
          field.hidden ||
            hiddenFieldsInAdditionalData?.[field.name] ||
            // in form, formulas are evaluated by dynamic input. so its not necessary to compute when creating tab list
            uiVisible,
        );
      } catch (error) {
        console.log('error in computing field.hidden: ', error);
      }

      try {
        let uiEnabled = false;
        if (isShowMode && !isEmpty(field.javaScriptUiEnable?.trim())) {
          uiEnabled = !checkUiEnable(field, currentRecord);
        }

        field.disabled = Boolean(
          field.disabled ||
            disabledFieldsInAdditionalData?.[field.name] ||
            // in form, formulas are evaluated by dynamic input. so its not necessary to compute when creating tab list
            uiEnabled,
        );
      } catch (error) {
        console.log('error in computing field.disabled: ', error);
      }
    }
  }

  if (relationHiddenFields && !isEmptyObject(relationHiddenFields)) {
    fieldSet = arrayToObjectWithSpecificKey(
      Object.values(fieldSet).map(field =>
        relationHiddenFields.includes(field['id'] as number)
          ? { ...field, hidden: true }
          : field,
      ),
      'id',
    ) as Record<number, FieldType>;
  }

  return fieldSet;
};

export const getGroups = (
  metaData: GeneralMetaData,
  quickMode = false,
): Record<string, any> => {
  let tempGroupList = lodashGet(metaData, ['groups']);
  if (quickMode && metaData.quickGroups && !isEmptyObject(metaData.quickGroups)) {
    tempGroupList = lodashGet(metaData, ['quickGroups']);
  }

  return tempGroupList;
};
export const prepareGroups = (
  metaData: GeneralMetaData,
  defaultColumnCount: number,
  processInfo: ProcessInformation,
  quickMode = false,
  isShowMode = false,
  resource: string,
  relationHiddenFields: number[] | null = null,
): Record<string, any> => {
  const groups = getGroups(metaData, quickMode);

  const preparedList: any = [];

  for (const groupId in groups) {
    const group = groups[groupId];
    const fieldSet = getGroupFieldsFromFieldsList(
      metaData,
      group?.fields,
      processInfo,
      isShowMode,
      resource,
      relationHiddenFields,
    );

    const createdLayout = createLayout(
      group.fieldsLayout,
      group.columnCount || defaultColumnCount,
      fieldSet,
    );

    preparedList.push({
      id: parseInt(groupId, 10),
      translatedTitle: group.translatedTitle, // always use translated strings
      columnCount: group.columnCount || defaultColumnCount,
      layout: createdLayout,
    });
  }

  return preparedList;
};

export const getTabsFromMetaData = (
  metaData: GeneralMetaData,
  quickMode = false,
): Array<MetaDataQuickTab> | Array<MetaDataTab> => {
  let tempTabPages = lodashGet(metaData, ['tabPages']);
  if (quickMode && metaData.quickTabPages && metaData.quickTabPages.length) {
    tempTabPages = lodashGet(metaData, ['quickTabPages']);
  }

  return tempTabPages;
};

export const getShowTabList = ({
  metaData,
  defaultColumnCount,
  processInfo,
  quickMode = false,
  isShowMode = false,
  relationHiddenFields,
  resource,
}: GetTabListInterface): RawShowTabs[] => {
  const tabList = getTabsFromMetaData(metaData, false);

  const allGroupList = prepareGroups(
    metaData,
    defaultColumnCount,
    processInfo,
    quickMode,
    isShowMode,
    resource,
    relationHiddenFields,
  );

  return tabList?.map((tab, index) => {
    const groupList =
      quickMode && tab.quickGroups && tab.quickGroups.length
        ? tab.quickGroups.map(groupId =>
            lodashFind(allGroupList, { id: parseInt(groupId, 10) }),
          )
        : tab.groups.map(groupId =>
            lodashFind(allGroupList, { id: parseInt(groupId, 10) }),
          );

    return {
      ...tab,
      id: index, // it was tab.name previously
      groupList,
      resource: `${tab.moduleName}/${tab.tableName}`,
    };
  });
};

/**
 * get childs of multi report
 * @function getReportChildren
 * @param {Object} metaData
 * @param {string} locale
 * @returns {Array<Record<string, unknown>>}
 */
export const getReportChildren = (
  metaData: GeneralMetaData,
  locale: Locale,
): Array<Record<string, unknown>> => {
  if (!metaData || !Array.isArray(metaData.childs)) return [];

  const { reportType, tabsColumns, childs, id: parentId } = metaData;

  if (reportType === 'MultiResult' && tabsColumns) {
    return Object.keys(tabsColumns).map((tab, index) => ({
      title: childs[index]?.translatedTitle?.[locale] ?? childs[index]?.title,
      childResource: `report/${parentId}/${index}`,
    }));
  }

  // report type is parent child
  return childs.map(({ reportId }) => ({
    title: null, // read from child metaData
    childResource: `report/${reportId}`,
  }));
};
