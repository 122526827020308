import { ReactElement, useCallback, useEffect, useState } from 'react';

import {
  LocationInputControllerPropsInterface,
  LocationInterface,
} from './location-input.type';
import LocationInputView from './location-input.view';
import { actorGetActionValue } from '../../../type/actor-setup';
import {
  ChangeFormValueParams,
  FormActions,
  OnBlurParams,
  OnFocusParams,
} from '../../form';
import { exportLatLngFromGeography, latLngToString } from './location-input.helper';

const LocationInputController = (
  props: LocationInputControllerPropsInterface,
): ReactElement => {
  const { value, getRef, disabled, label, hint, field, inputMessage, visibleClass } =
    props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [location, setLocation] = useState<LocationInterface | null>(null);
  const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;

  useEffect(() => {
    setDefaultLocation();
  }, [value]);

  const toggleOpen = () => setIsOpen(!isOpen);

  /**
   * @function onChangeLocation
   * @params {LocationInterface} changedLocation
   * @returns {void} void
   */
  const onChangeLocation = (changedLocation: LocationInterface): void => {
    setLocation(changedLocation);
  };

  /**
   * @function setDefaultLocation
   * @returns {void} void
   */
  const setDefaultLocation = useCallback(() => {
    setLocation(exportLatLngFromGeography(value));
  }, [value]);

  /**
   * @function handleCancelClick
   * @returns {void} void
   */
  const handleCancelClick = (): void => {
    setIsOpen(false);
    setDefaultLocation();
  };

  /**
   * @function confirmSelectedLocation
   * @returns {void} void
   */
  const confirmSelectedLocation = () => {
    formActionsHandler(FormActions.InputChange, {
      fieldName: field.name,
      value: latLngToString(location),
    } as ChangeFormValueParams);
    toggleOpen();
  };

  /**
   * Handle Blur event
   * @function handleBlur
   * @returns {void} void
   */
  const handleBlur = (): void => {
    formActionsHandler(FormActions.InputBlur, {
      fieldName: field.name,
      value: latLngToString(location),
    } as OnBlurParams);
  };

  /**
   * Handle focus event
   * @function handleFocus
   * @returns {void} void
   */
  const handleFocus = (): void => {
    formActionsHandler(FormActions.InputFocus, {
      fieldName: field.name,
      value: latLngToString(location),
    } as OnFocusParams);
  };

  return (
    <LocationInputView
      toggleOpen={toggleOpen}
      handleCancelClick={handleCancelClick}
      isOpen={isOpen}
      handleOkClick={confirmSelectedLocation}
      onChangeLocation={onChangeLocation}
      location={location}
      field={field}
      label={label}
      visibleClass={visibleClass}
      getRef={getRef}
      inputMessage={inputMessage}
      hint={hint}
      disabled={disabled}
      handleBlur={handleBlur}
      handleFocus={handleFocus}
    />
  );
};

export default LocationInputController;
