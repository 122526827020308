import { ReactElement, useMemo } from 'react';
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { Map as MapContainer, TileLayer } from 'react-leaflet';

import { useStyles } from './location-input.style';
import { LocationInputViewPropsInterface } from './location-input.type';
import { LocationMarker } from './location-marker';
import InputBase from '../../input-base';
import RoomIcon from '@material-ui/icons/Room';
import { centerLocation, latLngToString } from './location-input.helper';

const LocationInputView = (props: LocationInputViewPropsInterface): ReactElement => {
  const {
    toggleOpen,
    handleCancelClick,
    isOpen,
    handleOkClick,
    onChangeLocation,
    location,
    field,
    label,
    getRef,
    inputMessage,
    hint,
    disabled,
    handleBlur,
    handleFocus,
    visibleClass,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
      <InputBase
        className={`${visibleClass}`}
        label={label}
        hint={hint}
        required={field.required}
        inputMessage={inputMessage}
        field={field}
      >
        <fieldset
          className={`${visibleClass} datePickerContainer ${classes.fieldset}`}
          data-error={!!inputMessage?.message}
          data-label={!!label || label !== ''}
          disabled={disabled}
          onClick={toggleOpen}
          onBlur={handleBlur}
          onFocus={handleFocus}
          ref={getRef}
        >
          <InputAdornment position="start" className={classes.InputAdornment}>
            <div
              data-test="date-picker-icon"
              onClick={toggleOpen}
              className={classes.datePickerIcon}
            >
              <RoomIcon style={{ fontSize: '18px' }} />
            </div>
          </InputAdornment>
          {latLngToString(location)}
        </fieldset>
      </InputBase>

      <Dialog open={isOpen} onClose={handleCancelClick} fullWidth>
        <DialogContent className={classes.dialogContent}>
          {!disabled && (
            <DialogActions className={classes.dialogActions}>
              <Button variant="contained" color="primary" onClick={handleOkClick}>
                {translate('ra.action.confirm')}
              </Button>
              <Button variant="contained" onClick={handleCancelClick}>
                {translate('ra.action.cancel')}
              </Button>
            </DialogActions>
          )}
          <MapContainer
            center={location ?? centerLocation}
            zoom={12.25}
            style={{ width: '100%', height: 500 }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />

            <LocationMarker
              onChangeLocation={onChangeLocation}
              location={location ?? centerLocation}
              isDraggable={!disabled}
            />
          </MapContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LocationInputView;
