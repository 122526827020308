import { FC } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { useTranslate } from 'react-admin';

import { FilesPopupDialogViewInterface } from './files-popup-dialog.type';
import useWidth from '../useWidth';
import { useStyles } from './files-popup-dialog.style';
import DevExGrid from '../DevExGrid';
import { mockedFields } from './files-popup-dialog.helper';

const FilesPopupDialogView: FC<FilesPopupDialogViewInterface> = props => {
  const { isOpen, closeDialog, data, ids, title } = props;

  const classes = useStyles();
  const width = useWidth();
  const translate = useTranslate();

  return (
    <Dialog
      id="search-popup-dialog-div"
      open={isOpen}
      onClose={closeDialog}
      maxWidth={width}
      classes={{
        paper: classes.dialog,
      }}
      aria-labelledby="show-image-dialog-title"
    >
      {isOpen && (
        <>
          <DialogTitle>{title}</DialogTitle>
          <DevExGrid
            data={data}
            ids={ids}
            fields={mockedFields}
            hideLoading
            enableSelection={false}
            relationMode
          />

          <DialogActions>
            <Button onClick={closeDialog}>{translate('ra.action.cancel')}</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default FilesPopupDialogView;
