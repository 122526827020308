import { Hidden } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';

import HeaderProfileContainer from '../../../container/HeaderProfileContainer';
import { SidebarMenu } from '../../menu/sidebar-menu';
import { DrawerInterface } from './drawer.type';
import { useStyles } from './drawer.style';
import { actorOnDispatch } from '../../../type/actor-setup';

const Drawer: FC<DrawerInterface> = props => {
  const { logout, hasDashboard, locale, toggleMenuDrawer } = props;

  const classes = useStyles();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);

  useEffect(() => {
    actorOnDispatch('isDrawerOpen', setIsDrawerOpen);
  }, []);

  return (
    <div className={classes.menuContainer} id="sideBar">
      <HeaderProfileContainer isDrawerOpen={isDrawerOpen} />
      <span style={{ marginTop: '.5rem' }} />
      {/* @ts-ignore */}
      <Hidden smUp implementation="js">
        <SidebarMenu
          logout={logout}
          hasDashboard={hasDashboard}
          locale={locale}
          onSelect={toggleMenuDrawer}
          isDrawerOpen={isDrawerOpen}
        />
      </Hidden>
      {/* @ts-ignore */}
      <Hidden xsDown implementation="js">
        <SidebarMenu
          logout={logout}
          hasDashboard={hasDashboard}
          locale={locale}
          isDrawerOpen={isDrawerOpen}
        />
      </Hidden>
    </div>
  );
};

export default Drawer;
