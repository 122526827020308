import React, { FC, useState } from 'react';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import {
  createStyles,
  makeStyles,
  Icon,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import classNames from 'classnames';
import { CustomTheme } from '../../core/themeProvider';
import {
  CONFIG_CURRENCY_SYMBOL,
  getValue,
  HEADER_HEIGHT,
} from '../../core/configProvider';

interface SellinHistoryOrderWebViewInterfaceProps {
  translate: Function;
  handleOpenSellinHistoryOrderDetail: Function;
  preparedData: any;
  patternData: PatternDataInterfaceProps;
}

interface PatternDataInterfaceProps {
  [x: string]: string;
}

const headerHeight = getValue(HEADER_HEIGHT);

const styles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      height: '100%',
      overflowX: 'unset',
    },

    table: {},

    tableHeaderRow: {
      backgroundColor: theme.palette.primary.appPrimaryLightBackgroundColor,
    },

    tableRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
      },
      '&:hover .tableCellDetails': {
        backgroundColor: theme.palette.primary.appPrimaryLightBackgroundColor,
      },
    },

    tableCell: {
      textAlign: 'center',
    },

    tableCellDetails: {
      color: theme.palette.primary.appPrimaryIconColor,
      cursor: 'pointer',
    },

    tableTh: {
      top: headerHeight,
    },
  }),
);

const SellinHistoryOrderWebView: FC<
  SellinHistoryOrderWebViewInterfaceProps
> = props => {
  const { translate, preparedData, handleOpenSellinHistoryOrderDetail } = props;
  const {
    id,
    createDate,
    totalItemCount,
    finalPrice,
    settlementTypeTitle,
    orderStatusTitle,
    itemCount,
    totalDiscountPrice,
    totalWholePrice,
    successfulOnlinePayment,
  } = props.patternData;

  const classes = styles();

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeaderRow}>
            <TableCell
              component="th"
              className={`${classes.tableCell} ${classes.tableTh}`}
            >
              {translate('sellin.id')}
            </TableCell>
            <TableCell
              component="th"
              className={`${classes.tableCell} ${classes.tableTh}`}
            >
              {translate('sellin.createDate')}
            </TableCell>
            <TableCell
              component="th"
              className={`${classes.tableCell} ${classes.tableTh}`}
            >
              {translate('sellin.itemCount')}
            </TableCell>
            <TableCell
              component="th"
              className={`${classes.tableCell} ${classes.tableTh}`}
            >
              {translate('sellin.totalItemCount')}
            </TableCell>
            <TableCell
              component="th"
              className={`${classes.tableCell} ${classes.tableTh}`}
            >
              {translate('sellin.totalDiscountPrice')}
            </TableCell>
            <TableCell
              component="th"
              className={`${classes.tableCell} ${classes.tableTh}`}
            >
              {translate('sellin.totalWholePrice')}
            </TableCell>
            <TableCell
              component="th"
              className={`${classes.tableCell} ${classes.tableTh}`}
            >
              {translate('sellin.finalPrice')}
            </TableCell>
            <TableCell
              component="th"
              className={`${classes.tableCell} ${classes.tableTh}`}
            >
              {translate('sellin.settlementTypeTitle')}
            </TableCell>
            <TableCell
              component="th"
              className={`${classes.tableCell} ${classes.tableTh}`}
            >
              {translate('sellin.orderStatusTitle')}
            </TableCell>
            <TableCell
              component="th"
              className={`${classes.tableCell} ${classes.tableTh}`}
            >
              {translate('sellin.detail')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {preparedData.map(row => {
            return (
              <TableRow key={row[id]} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>{row[id]}</TableCell>
                <TableCell className={classes.tableCell}>
                  {row[createDate]}
                </TableCell>
                <TableCell className={classes.tableCell}>{row[itemCount]}</TableCell>
                <TableCell className={classes.tableCell}>
                  {row[totalItemCount]}{' '}
                  {getValue(CONFIG_CURRENCY_SYMBOL) ||
                    translate('shop.basket.currency')}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row[totalDiscountPrice]}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row[totalWholePrice]}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row[finalPrice]}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row[settlementTypeTitle]}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row[orderStatusTitle]}
                </TableCell>
                <TableCell
                  className={classNames(
                    classes.tableCell,
                    classes.tableCellDetails,
                    'tableCellDetails',
                  )}
                  onClick={handleOpenSellinHistoryOrderDetail(row[id])}
                >
                  <Icon>chevron_left</Icon>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// const mapDispatchToProps = {
//   toggleTodoMenuSidebar: toggleTodoMenuSidebarAction,
// };
// connect(null, mapDispatchToProps),

export default compose(translate)(SellinHistoryOrderWebView);
