import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    minHeight: '90%',
    maxHeight: '90%',
    overflow: 'auto',
    zoom: 0.5,
  },
  zoomButton: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.appPrimaryBackgroundColor,
    alignSelf: 'center',
    margin: '3px',
    height: 30,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  zoomButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    width: '100%',
  },
}));
