import dropdown from './dropdown/saga';
import dropdownOld from './dropdown-old/saga';
import tag from './tag/saga';
import puzzleMetaData from './puzzleMetaData/saga';
import profile from './profile/saga';
import viewPage from './viewPage/saga';
import serviceSaga from './crud/serviceSaga';
import menuSaga from './menu/saga';
import reportSaga from './report/saga';
import todoListSaga from './todoList/saga';
import loginWithSMS from './loginWithSMS/saga';
import shop from './shop/saga';
import gridList from './gridList/saga';
import notification from './notification/saga';

export default [
  dropdownOld,
  dropdown,
  puzzleMetaData,
  profile,
  tag,
  viewPage,
  serviceSaga,
  menuSaga,
  reportSaga,
  todoListSaga,
  loginWithSMS,
  shop,
  gridList,
  notification,
];
