import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({});

function TodayIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M15.9997 4.9997h1v1h3.001v14H3.9997v-14h3v-1h1v1h8v-1zm-11 14h14v-9h-14v9zm12-10.999h-1V7l-8 .0007v1h-1V7h-2v2.0007h14.001V7h-2.001v1.0007z"></path>
      <path d="M11 15h2v-2h-2v2zm-1 1h4v-4h-4v4z"></path>
    </SvgIcon>
  );
}

export default withStyles(styles)(TodayIcon);
