import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker2-samian';
import moment from 'moment-jalaali';
import { withStyles } from '@material-ui/core';
import { useLocale } from 'react-admin';
import '../JalaliDateInput.css';
import {
  getValue,
  CONFIG_CALENDAR,
  SERVER_DATE_FORMAT,
} from '../../core/configProvider';

const styles = theme => ({});

const DateFilterInput = props => {
  const { value, onChange, field, getRef } = props;
  const calendarConfig = getValue(CONFIG_CALENDAR);
  const [preparedValue, setPreparedValue] = useState();
  const locale = useLocale();

  useEffect(() => {
    setPreparedValue(
      value && !isNaN(new Date(value)) ? moment(value, SERVER_DATE_FORMAT) : null,
    );
  }, [value]);

  const handleChangeDate = changedDate => {
    const newValue = changedDate
      ? changedDate.locale('en').format(SERVER_DATE_FORMAT)
      : null;

    onChange(newValue);
  };

  return (
    <div className="filterDatePickerContainer" data-test-field-name={field.name}>
      <DatePicker
        ref={getRef}
        inputFormat="jYYYY-jMM-jDD"
        value={preparedValue}
        isGregorian={calendarConfig === 'gregorian'}
        timePicker={false}
        showTodayButton={false}
        setTodayOnBlur={false}
        persianDigits={false}
        onChange={handleChangeDate}
        tetherAttachment="bottom center"
        locale={locale === 'ar' ? 'fa' : locale}
      />
    </div>
  );
};

DateFilterInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default compose(withStyles(styles, { withTheme: true }))(DateFilterInput);
