import { FC, useState, memo, useEffect } from 'react';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../../../type/actor-setup';

import { AccordionItemInterface } from './accordion-item.type';
import AccordionItemView from './accordion-item.view';

const AccordionItemController: FC<AccordionItemInterface> = memo(props => {
  const { shouldOpen, title, id, containerClassName, collapseClassName, children } =
    props;
  const [isOpen, setIsOpen] = useState(shouldOpen);
  const currentSelectedParentMenuId = actorGetActionValue('selectedParentMenuId')!;

  /**
   * @function isInExpandedItems
   * @param {number} id
   * @returns {boolean}
   */
  const isInExpandedItems = (id: number) => {
    const currentIdsOfCurrentParentMenu = actorGetActionValue(
      'expandedItemsId',
      `${currentSelectedParentMenuId}`,
    )! as any;
    return currentIdsOfCurrentParentMenu?.some(item => item === id);
  };

  /**
   * @function onToggle
   * @returns {void}
   */
  const onToggle = (): void => {
    !isInExpandedItems(id) ? onOpenHandler() : onCloseHandler();
  };

  /**
   * @function onOpenHandler
   * @returns {void}
   */
  const onOpenHandler = () => {
    const currentExpandedItemsId = actorGetActionValue('expandedItemsId')!;
    const lastIdsOfCurrentParentMenu =
      actorGetActionValue('expandedItemsId', `${currentSelectedParentMenuId}`) ||
      ([] as any);

    actorDispatch('expandedItemsId', {
      ...currentExpandedItemsId,
      [currentSelectedParentMenuId]: [...lastIdsOfCurrentParentMenu, id],
    });
    setIsOpen(true);
  };

  /**
   * @function onCloseHandler
   * @returns {void}
   */
  const onCloseHandler = () => {
    const currentExpandedItemsId = actorGetActionValue('expandedItemsId')!;
    const lastIdsOfCurrentParentMenu =
      actorGetActionValue('expandedItemsId', `${currentSelectedParentMenuId}`) ||
      ([] as any);

    actorDispatch('expandedItemsId', {
      ...currentExpandedItemsId,
      [currentSelectedParentMenuId]: lastIdsOfCurrentParentMenu.filter(
        item => item !== id,
      ),
    });
    setIsOpen(false);
  };

  useEffect(() => {
    actorOnDispatch('shouldAllItemsExpand', res => {
      !shouldOpen && setIsOpen(res?.[currentSelectedParentMenuId]);
    });
  }, []);

  useEffect(() => {
    actorOnDispatch('expandedItemsId', expandedItemsId => {
      isInExpandedItems(id) && setIsOpen(true);
    });
  }, []);

  return (
    <AccordionItemView
      title={title}
      containerClassName={containerClassName}
      collapseClassName={collapseClassName}
      isOpen={isOpen}
      onToggle={onToggle}
      children={children}
    />
  );
});

export default AccordionItemController;
