import { makeStyles } from '@material-ui/core';
import { TextInputViewProps } from '.';
import { getColorBaseOfStatus } from '../input-base/input-message/input-message.helper';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme, TextInputViewProps>(theme => ({
  root: {
    flexGrow: 1,
    margin: 0,
    height: '100%',
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,

    '& legend': {
      display: 'none',
    },
  },

  notchedOutline: {
    top: 0,
    borderColor: props =>
      getColorBaseOfStatus(props.inputMessage?.messageType, theme),
  },

  outlineRoot: {
    height: '100%',
  },

  input: {
    width: '100%',
    zIndex: 1,
    height: '45%',
    '&[disabled]': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },

    '&:-webkit-autofill': {
      borderLeft: ({ field }) =>
        field.isHashed
          ? `0px`
          : `1px solid ${theme.palette.primary.appPrimaryTextColor}`,
      '-webkit-box-shadow': `0 0 0 100px ${theme.palette.primary.appSecondaryBackgroundColor} inset`,
      '-webkit-text-fill-color': '#000',
      borderRight: ({ field }) =>
        field?.isHashed
          ? `1px solid ${theme.palette.primary.appPrimaryBorderInputColor}`
          : `1px solid ${theme.palette.primary.appPrimaryTextColor}`,
      borderRadius: ({ field }) => (field?.isHashed ? '0px 4px 4px 0px' : '4px'),
      [theme.breakpoints.down('md')]: {
        border: `1px solid ${theme.palette.primary.appPrimaryBorderInputColor}`,
        borderLeft: ({ field }) => '0px',
      },
      '&:hover': {
        borderRight: `0.1px solid ${theme.palette.primary.appPrimaryBorderInputColor}`,
        borderLeft: ({ field }) =>
          field.isHashed
            ? `0px`
            : `0.1px solid ${theme.palette.primary.appPrimaryBorderInputColor}`,
      },
    },

    [theme.breakpoints.up('lg')]: {
      height: '35%',
    },
    [theme.breakpoints.up('xl')]: {
      height: '40%',
    },
    [theme.breakpoints.down('md')]: {
      '&:hover': {
        border: ({ field }) =>
          field.isHashed && `1px solid ${theme.palette.primary.appPrimaryTextColor}`,
        borderLeft: ({ field }) => field.isHashed && `0px`,
      },
    },

    '&:hover': {
      borderRight: ({ field }) =>
        field.isHashed
          ? `0.1px solid ${theme.palette.primary.appPrimaryTextColor}`
          : `0px`,
    },
  },

  wmsInput: {
    width: '100%',
    zIndex: 1,
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    '&[disabled]': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },

    [theme.breakpoints.down('md')]: {
      '& label': {
        transform: 'scale(1) translate(14px, 12px)',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      '& input': {
        lineHeight: 0,
      },
      '& label': {
        transform: 'scale(1) translate(14px, 10px)',
        fontSize: 10,
      },
      '& div': {
        fontSize: 10,
      },
    },
  },

  disabled: {
    backgroundColor: theme.palette.grey[300],
  },

  icon: {
    zIndex: 1,
  },

  inputStylePuzzleForm: {
    margin: '7px 3px 0',
    '&[data-disabled-for-style=true]': {
      backgroundColor: theme.palette.grey[300],
    },
    height: 'auto',
  },
}));
