import moment, { MomentInput } from 'moment';
import jMoment from 'moment-jalaali';

import { CONFIG_CALENDAR, CONFIG_LOCALE, getValue } from '../core/configProvider';
import { isEmpty } from './data-helper';

/**
 * Return a date format based on user preference and type calendar
 * @function getInputFormat
 * @param {string} fieldCalendarType
 * @returns {string}
 */
export const getInputFormat = (fieldCalendarType: string): string => {
  const localeConfig = getValue(CONFIG_LOCALE);
  const typeCalendar = getCalendarType(fieldCalendarType);
  return typeCalendar === 'gregorian' || localeConfig === 'en'
    ? 'YYYY-MM-DD'
    : 'jYYYY-jMM-jDD';
};

/**
 * Convert date to a human readable string
 * @function parseDate
 * @param {MomentInput} date
 * @param {Function} translate
 * @returns {string}
 */
export const parseDate = (
  date: MomentInput,
  translate: Function,
): string | undefined => {
  if (!date) {
    return;
  }
  const diff: number = moment(date).diff(moment().format('YYYY-MM-DD'), 'days');

  let output = '';

  if (diff < 0 || isNaN(diff)) {
    return translate('todo.invalidDate');
  } else if (diff == 0) {
    output = 'today';
  } else if (diff == 1) {
    output = 'tomorrow';
  } else if (diff == 2) {
    output = 'overmorrow';
  } else if (diff == 7) {
    output = 'nextWeek';
  }
  return isEmpty(output) ? formatDate(date) : translate(`todo.${output}`);
};

/**
 * Format a moment date to system date format based on user selected clanader
 * @function formatDate
 * @param {MomentInput} date
 * @returns {string}
 */
const formatDate = (date: MomentInput): string => {
  const localeConfig = getValue(CONFIG_LOCALE);
  const calendarConfig = getValue(CONFIG_CALENDAR);
  if (calendarConfig === 'gregorian') {
    return moment(date).locale(localeConfig).format('YYYY-MM-DD');
  }
  return jMoment(date).locale(localeConfig).format('jYYYY-jMM-jDD');
};

/**
 * Convert human formatted string to system formatted date
 * @function createDateWithType
 * @param {string} type
 * @returns {string}
 */
export const createDateWithType = (type: string): string => {
  const date = moment().locale('en');
  const format = 'YYYY-MM-DD';

  switch (type) {
    case 'tomorrow':
      return date.add(1, 'days').format(format);

    case 'nextWeek':
      return date.add(1, 'week').format(format);

    default:
      return date.format(format);
  }
};

/**
 * Specify the calendar type according to the value fixCalendar
 * @function getCalendarType
 * @param {string} fieldCalendarType
 * @returns {string}
 */
export const getCalendarType = (fieldCalendarType: string): string => {
  const calendarConfig = getValue(CONFIG_CALENDAR);
  return isEmpty(fieldCalendarType) ? calendarConfig : fieldCalendarType;
};
