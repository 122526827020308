/**
 * add fake id to each item of array
 * @param {Array<object>} arrayWithoutIds
 * @returns {Array<object>} arrayWithIds
 */
export const addFakeIdsToArrayItems = (
  arrayWithoutIds: Array<Record<string, unknown>>,
): {
  items: Array<Record<string, unknown> & { id: number }>;
  ids: Array<number>;
} => {
  if (!Array.isArray(arrayWithoutIds)) return { items: [], ids: [] };

  const preparedData: Array<Record<string, unknown> & { id: number }> = [];
  const ids: Array<number> = [];

  arrayWithoutIds.forEach((item, index) => {
    preparedData.push({ ...item, id: index });
    ids.push(index);
  });

  return { items: preparedData, ids };
};

export const mockedFields = [
  {
    relatedName: 'realFileName',
    translatedCaption: { fa: 'نام فایل', en: 'file name', ar: 'اسم الملف' },
    dataType: {
      erp: 'string',
      simple: 'string',
    },
  },
  {
    relatedName: 'filePath',
    translatedCaption: { fa: 'دانلود', en: 'download', ar: 'تحميل' },
    dataType: {
      erp: 'file_download_icon',
      simple: 'file_download_icon',
    },
  },
];
