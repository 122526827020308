import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../core/themeProvider';
import { StyleProps } from '../dynamic-input/file-input';

export const useStyles = makeStyles<CustomTheme, StyleProps>(() => ({
  mapContainer: {
    width: '100%',
    height: ({ isFullScreen }) => (isFullScreen ? '100%' : '50%'),
  },
}));
