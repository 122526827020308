import { FormRowsAndFieldsData, WMSLayoutRow, WMSTabData } from './wms.type';
import { FieldType } from '../../helper/Types';
import { isEmptyObject } from '../../helper/data-helper';
import { FormData, InputRefContent } from '../form';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../../type/actor-setup';

/**
 * Sorts the form fields by the order of `leftPriorityInRow` for each rows
 * @function sortByPriorityInRow
 * @param { WMSLayoutRow } a
 * @param { WMSLayoutRow } b
 * @returns { number }
 */
const sortByPriorityInRow = (a: WMSLayoutRow, b: WMSLayoutRow): number => {
  return a.leftPriorityInRow - b.leftPriorityInRow;
};

/**
 * It receives some section of `wms` metadata and calculates and categories information about layout fields
 * @function prepareRowListWMSForm
 * @param { WMSTabData } tabData
 * @returns { FormRowsAndFieldsData } an object
 */
export const prepareRowListWMSForm = (
  tabData: WMSTabData,
): FormRowsAndFieldsData => {
  const { id: tabId, title: tabTitle, item: tabItem } = tabData;

  const newLayoutRows: Array<Array<FieldType | null | 'empty'>> = [];
  const tempFields = {};
  const tempPreparedFieldList: Array<FieldType> = [];

  const layoutRows = tabItem.layoutRows ?? [];

  for (const row in layoutRows) {
    layoutRows[row].sort(sortByPriorityInRow);

    for (const field of layoutRows[row]) {
      const newField = { ...field, tabTitle, tabId } as unknown as FieldType;
      if (!newLayoutRows[row]) {
        newLayoutRows[row] = [];
      }
      newLayoutRows[row].push(newField);
    }
  }

  for (const row of newLayoutRows) {
    if (row) {
      for (const field of row) {
        if (!(field && typeof field === 'object' && Object.keys(field).length > 0)) {
          continue;
        }

        tempFields[field.id] = field;
        tempPreparedFieldList.push(field);
      }
    }
  }

  return {
    rowList: newLayoutRows,
    fields: tempFields,
    fieldList: tempPreparedFieldList,
  };
};

/**
 * It resets values of each input in the form based on meta data of each field
 * @function updateValuesOfInputsAfterSubmit
 * @param { Record<string, InputRefContent> | null | undefined } inputsRef
 * @param { Array<FieldType> } fieldNameList
 * @param { FormData | null } formData
 * @returns { void }
 */
export const updateValuesOfInputsAndFormDataAfterSubmit = (params: {
  inputsRef: Record<string, InputRefContent> | null | undefined;
  fieldNameList: string[];
  newFormData: FormData | null;
  forceEmptyFields?: boolean;
}): void => {
  const { inputsRef, fieldNameList, newFormData, forceEmptyFields } = params;
  if (isEmptyObject(inputsRef)) return;

  const currentResource = actorGetActionValue('resources')!.current;
  const currentFormData = actorGetActionValue(
    'formData',
    `${currentResource.value}.${currentResource.type}`,
  )! as FormData;

  const updatedFormData = {};
  for (const fieldName of fieldNameList) {
    const { keepValueAfterSubmit, priority, defaultValue } =
      inputsRef![fieldName].field;

    if (forceEmptyFields) {
      const inputValue = defaultValue ?? '';
      inputsRef![fieldName]?.setInputValue(inputValue);
      updatedFormData[fieldName] = inputValue;

      continue;
    }

    if (
      priority === 0 ||
      keepValueAfterSubmit ||
      newFormData?.[fieldName] === null
    ) {
      updatedFormData[fieldName] = currentFormData?.[fieldName] ?? ''; // Keep previous value in the `formData`
      continue;
    }

    const inputValue = newFormData?.[fieldName] ?? defaultValue ?? '';
    inputsRef![fieldName]?.setInputValue(inputValue);
    updatedFormData[fieldName] = inputValue;
  }

  actorSetActionValue('formData', updatedFormData, {
    path: `${currentResource.value}.${currentResource.type}`,
    replaceAll: true,
    callerScopeName: 'wms.helper => updateValuesOfInputsAndFormDataAfterSubmit',
  });

  const rootResource = actorGetActionValue('resources')!.stack[0];
  actorSetActionValue('formData', updatedFormData, {
    path: `${rootResource.value}.${rootResource.type}`,
    replaceAll: true,
    callerScopeName: 'WMSActionController => saveData',
  });

  requestAnimationFrame(() => {
    if (!forceEmptyFields) {
      const formDataKeys = Object.keys(newFormData ?? {});
      for (const key of formDataKeys) {
        if (newFormData?.[key] === null) continue;
        inputsRef![key]?.setInputValue(newFormData?.[key]);
      }
    }

    const { focusOnFirstInputAfterSubmit } =
      actorGetActionValue('formGlobalProps')!.formFocusManagementFunctions;

    focusOnFirstInputAfterSubmit();
  });
};

/**
 * It sets the value of each `field` after changes in `search` input based on data that received from `API`
 * @function updateValuesOfInputsAfterSearchInputChanges
 * @param { FormData } formData
 * @returns { void }
 */
export const updateValuesOfInputsAfterSearchInputChanges = (
  formData: FormData | undefined,
  inputsRef: Record<string, InputRefContent> | null,
  refreshGrid?: boolean,
): void => {
  if (isEmptyObject(formData) || isEmptyObject(inputsRef)) return;

  const currentResource = actorGetActionValue('resources')!.current;
  const rootResource = actorGetActionValue('resources')!.stack[0];

  // const inputsRef = actorGetActionValue(
  //   'inputsRef',
  //   `${currentResource.value}.${currentResource.type}`,
  // )! as Record<string, InputRefContent>;

  const fieldNameList =
    actorGetActionValue('formGlobalProps')!.inputNameListSortedByPriority;

  for (const fieldName of fieldNameList) {
    if (formData?.[fieldName] == null) continue;

    inputsRef![fieldName].setInputValue(formData[fieldName]);
  }

  actorSetActionValue('formData', formData, {
    path: `${currentResource.value}.${currentResource.type}`,
    callerScopeName: 'wms.helper 1 => updateValuesOfInputsAfterSearchInputChanges',
  });

  actorSetActionValue('formData', formData, {
    path: `${rootResource.value}.${rootResource.type}`,
    callerScopeName: 'wms.helper 2 => updateValuesOfInputsAfterSearchInputChanges',
  });

  if (refreshGrid) {
    actorDispatch('refreshView', null);
  }
};
