import React, { FC, useState, Fragment } from 'react';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import {
  createStyles,
  makeStyles,
  Icon,
  Paper,
  Typography,
} from '@material-ui/core';
import { NumberField, DateField as GregoranDateField } from 'react-admin';

import JalaliDateField from '../JalaliDateField';
import LoadingBox from '../LoadingBox';
import ConfirmDialogHOC from '../../container/ConfirmDialogHOC';
import { CustomTheme } from '../../core/themeProvider';

interface SellinHistoryOrderMobieViewInterfaceProps {
  translate: Function;
  openConfirmDialog: Function;
  handleOpenSellinHistoryOrderDetail: Function;
  preparedData: any;
  currentCalendar: string;
  patternData: PatternDataInterfaceProps;
}

interface PatternDataInterfaceProps {
  [x: string]: string;
}

const styles = makeStyles((theme: CustomTheme) =>
  createStyles({
    orderItem: {
      margin: 10,
      padding: 10,
    },

    header: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: 10,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },

    headerAction: {
      alignSelf: 'center',
    },

    headerDetail: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },

    body: {
      display: 'flex',
      flexDirection: 'row',
    },

    orderDetail: {
      flexGrow: 1,
    },

    orderDetailItem: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: 10,
    },

    orderDetailItemTitle: {
      flexBasis: '25%',
    },

    orderStatus: {
      flexGrow: 1,
    },

    headerDetailOrderId: {
      display: 'flex',
    },

    headerDetailOrderIdCaption: {
      flexBasis: '12%',
    },

    orderStatusDescription: {
      padding: '0 5px',
    },

    orderStatusIcon: {
      cursor: 'pointer',
      zIndex: 2,
    },
  }),
);

const SellinHistoryOrderMobieView: FC<
  SellinHistoryOrderMobieViewInterfaceProps
> = props => {
  const {
    translate,
    preparedData,
    currentCalendar,
    patternData,
    openConfirmDialog,
    handleOpenSellinHistoryOrderDetail,
  } = props;
  const {
    id,
    createDate,
    totalItemCount,
    finalPrice,
    settlementTypeTitle,
    orderStatusTitle,
    orderStatusDescription,
  } = patternData;

  const classes = styles();

  const handleOpenConfirmDialog = orderItem => event => {
    event.preventDefault();
    event.stopPropagation();
    openConfirmDialog({
      content: (
        <Typography variant="caption">
          {orderItem[orderStatusDescription]}
        </Typography>
      ),
      icon: 'fa fa-info-circle',
      enableOnlyOkButton: true,
    });
  };

  if (!preparedData) {
    return <LoadingBox />;
  }

  return preparedData.map(order => {
    return (
      <Fragment key={order[id]}>
        <Paper
          className={classes.orderItem}
          onClick={handleOpenSellinHistoryOrderDetail(order[id])}
        >
          <header className={classes.header}>
            <div className={classes.headerDetail}>
              {currentCalendar === 'jalali' ? (
                <JalaliDateField
                  source={'value'}
                  record={{ value: order[createDate] }}
                />
              ) : (
                <GregoranDateField
                  source={'value'}
                  record={{ value: order[createDate] }}
                />
              )}
              <div className={classes.headerDetailOrderId}>
                <Typography
                  variant="caption"
                  className={classes.headerDetailOrderIdCaption}
                >
                  {translate('sellin.id')}
                </Typography>
                <Typography variant="caption">{order[id]}</Typography>
              </div>
            </div>
            <div className={classes.headerAction}></div>
          </header>
          <div className={classes.body}>
            <div className={classes.orderDetail}>
              <div className={classes.orderDetailItem}>
                <Typography
                  variant="caption"
                  className={classes.orderDetailItemTitle}
                >
                  {translate('sellin.orderStatusTitle')}
                </Typography>
                <Typography variant="caption" className={classes.orderStatus}>
                  {order[orderStatusTitle]}
                </Typography>
                <Icon
                  className={classes.orderStatusIcon}
                  onClick={handleOpenConfirmDialog(order)}
                  color="secondary"
                  fontSize="small"
                >
                  information
                </Icon>
              </div>
              <div className={classes.orderDetailItem}>
                <Typography
                  variant="caption"
                  className={classes.orderDetailItemTitle}
                >
                  {translate('sellin.settlementTypeTitle')}
                </Typography>
                <Typography variant="caption">
                  {order[settlementTypeTitle]}
                </Typography>
              </div>
              <div className={classes.orderDetailItem}>
                <Typography
                  variant="caption"
                  className={classes.orderDetailItemTitle}
                >
                  {translate('sellin.totalItemCount')}
                </Typography>
                <NumberField
                  source={'value'}
                  record={{ value: order[totalItemCount] }}
                />
              </div>
              <div className={classes.orderDetailItem}>
                <Typography
                  variant="caption"
                  className={classes.orderDetailItemTitle}
                >
                  {translate('sellin.finalPrice')}
                </Typography>
                <NumberField
                  source={'value'}
                  record={{ value: order[finalPrice] }}
                />
              </div>
            </div>
          </div>
        </Paper>
      </Fragment>
    );
  });
};

export default compose(ConfirmDialogHOC, translate)(SellinHistoryOrderMobieView);
