import { FC, memo, useState } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';
import { RichTextEditorFieldInterface } from './rich-text-editor-field.type';
import RichTextEditorFieldView from './rich-text-editor-field.view';
import { actorGetActionValue } from '../../type/actor-setup';
import { clone, isEmpty } from '../../helper/data-helper';
import { removePageBreakFromHtmlString } from './rich-text-editor-field.helper';
import NewWindow from '../NewWindow';

const RichTextEditorFieldController: FC<RichTextEditorFieldInterface> = memo(
  props => {
    const { fieldName, isPrintMode, externalValue } = props;
    const [showPrintPopOut, setShowPrintPopOut] = useState<boolean>(false);
    const resources = isEmpty(externalValue)
      ? clone(actorGetActionValue('resources')!)
      : '';

    const records = isEmpty(externalValue)
      ? clone(
          actorGetActionValue('record', [
            resources?.['current']?.value,
            resources?.['current']?.type,
          ])!,
        )
      : '';

    const locale = useLocale();
    const translate = useTranslate();

    const value = isEmpty(externalValue)
      ? lodashGet(records?.['FULL'], fieldName)
      : externalValue;

    const editorConfiguration = {
      language: locale ? locale : 'fa',
      fontFamily: {
        options: [
          'IranSans',
          'BNAZANIN',
          'BMITRA',
          'BLOTUS',
          'BTitr',
          'BYagut',
          'Ubuntu, Arial, sans-serif',
          'Ubuntu Mono, Courier New, Courier, monospace',
        ],
      },

      fontSize: {
        options: [9, 11, 13, 'default', 17, 19, 21, 24, 30, 36],
      },
      image: {
        toolbar: [
          'imageTextAlternative',
          'toggleImageCaption',
          '|',
          'imageStyle:alignLeft',
          'imageStyle:alignCenter',
          'imageStyle:alignRight',
        ],
      },
      table: {
        contentToolbar: [
          'toggleTableCaption',
          '|',
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableProperties',
          'tableCellProperties',
        ],
      },
      htmlEmbed: {
        showPreviews: true,
        sanitizeHtml: inputHtml => {
          // Strip unsafe elements and attributes, e.g.:
          // the `<script>` elements and `on*` attributes.
          const outputHtml = inputHtml;

          return {
            html: outputHtml,
            // true or false depending on whether the sanitizer stripped anything.
            hasChanged: true,
          };
        },
      },
    };

    /**
     * @function handlePrint
     * @returns { void }
     */
    const handlePrint = (): void => {
      setShowPrintPopOut(true);
    };

    const PrintDialogPopOut = () => (
      <NewWindow
        title={translate('grid.print')}
        copyStyles={true}
        features={{
          height: +screen.height,
          width: +screen.width,
          top: 0,
          left: 0,
          fullscreen: 'yes',
        }}
        onUnload={() => setShowPrintPopOut(false)}
        onBlock={() => setShowPrintPopOut(false)}
      >
        <RichTextEditorFieldView
          value={removePageBreakFromHtmlString(value)}
          isPrintMode={true}
          config={editorConfiguration}
        />
      </NewWindow>
    );

    return (
      <>
        {showPrintPopOut && <PrintDialogPopOut />}
        <RichTextEditorFieldView
          value={value}
          isPrintMode={isPrintMode}
          config={editorConfiguration}
          handlePrint={handlePrint}
        />
      </>
    );
  },
);

export default RichTextEditorFieldController;
