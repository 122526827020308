import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';
import { ValidationErrorMessageType } from '../../../helper/Types';
import { getColorBaseOfStatus } from '../../input-base/input-message/input-message.helper';

export const useStyles = makeStyles<
  CustomTheme,
  { messageType: ValidationErrorMessageType | undefined }
>(theme => ({
  root: {
    flexGrow: 1,
    margin: 0,
    height: 'auto',

    '& legend': {
      display: 'none',
    },
    '& fieldset': {
      backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    },
  },

  notchedOutline: {
    top: 0,
    borderColor: props => getColorBaseOfStatus(props.messageType, theme),
  },

  outlineRoot: {
    height: '100%',
  },

  input: {
    zIndex: 1,
    padding: 'inherit',
    height: '100%',
    '&:-webkit-autofill': {
      border: `1px solid ${theme.palette.primary.appPrimaryBorderInputColor}`,
    },
  },

  multiline: {
    padding: '10.5px 14px',
  },

  disabled: {
    color: theme.palette.grey[700],
    '& fieldset': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));
