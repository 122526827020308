import { ReactElement } from 'react';
import { ChatSectionViewInterface } from './chat-section.type';
import { AppContainer } from '../app-container';
import { ChatSidebar } from './chat-sidebar';
import { ChatContent } from './chat-content';
import { AppDialog } from '../app-dialog';
import { UploadFilesForm } from './upload-files-form';

const ChatSectionView = (props: ChatSectionViewInterface): ReactElement => {
  const {
    chatActionsHandler,
    currentUser,
    handleCloseFileUploadDialog,
    isFileUploadDialogOpen,
    handlePasteFromClipboard,
  } = props;

  return (
    <>
      <AppContainer
        onPaste={handlePasteFromClipboard}
        sidebarContent={
          <ChatSidebar
            chatActionsHandler={chatActionsHandler}
            currentUser={currentUser}
          />
        }
      >
        <ChatContent
          chatActionsHandler={chatActionsHandler}
          currentUser={currentUser}
        />
      </AppContainer>
      {isFileUploadDialogOpen && (
        <AppDialog
          open={isFileUploadDialogOpen}
          handleClose={handleCloseFileUploadDialog}
          dialogTitle=""
          dialogContent={<UploadFilesForm chatActionsHandler={chatActionsHandler} />}
          dialogActions={<div />}
          maxWidth="md"
        />
      )}
    </>
  );
};

export default ChatSectionView;
