import React, { FC, useState } from 'react';
import { CardMedia, Typography, Link, Icon, IconButton } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { NumberField, Identifier, useTranslate } from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import {
  API_URL,
  CONFIG_CURRENCY_SYMBOL,
  getValue,
} from '../../core/configProvider';
import { SELLIN_SHOW, SELLIN_LIST } from '../../core/configRouteConstant';
import placeholder from '../../images/placeholder.png';
import { CustomTheme } from '../../core/themeProvider';
import useWidth from '../../component/useWidth';
import { AdvertisementPatternType } from '../../helper/Types';

const useStyles = makeStyles((theme: CustomTheme) => ({
  mainContainer: {
    display: 'flex',
    margin: '15px 0',
    justifyContent: 'center',
  },

  productSimilar: {
    width: '100%',
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    flexBasis: '78%',
    overflow: 'auto',
    padding: '10px 20px',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],

    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
      padding: 5,
      borderRadius: 0,
    },
  },

  productTagTitle: {
    margin: 8,
  },

  container: {
    '& > div': {
      height: '100%',
    },
  },

  card: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      minWidth: 150,
      maxWidth: 150,
    },
    borderRadius: theme.shape.borderRadius,
  },

  cardMedia: {
    minHeight: 230,
    margin: 5,
    flexBasis: '50%',
    flexGrow: 0,
    flexShrink: 0,
    backgroundImage: `url(${placeholder})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      minHeight: 120,
    },
  },

  divider: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },

  cardTitle: {
    margin: 5,
    flexBasis: '10%',
    flexGrow: 0,
    flexShrink: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.primary.appPrimaryTextColor,
  },

  cardPrice: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    display: 'inline',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },

  cardDiscount: {
    color: theme.palette.error.main,
    fontSize: 18,
    display: 'inline',
    textDecoration: 'line-through',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },

  priceContainer: {
    minHeight: '20%',
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '10%',
    flexGrow: 0,
    flexShrink: 0,
  },

  priceCurrency: {
    fontWeight: 'normal',
    color: theme.palette.secondary.main,
    margin: '0 2px',
    fontSize: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },

  discountCurrency: {
    fontSize: 15,
    fontWeight: 'normal',
    margin: '0 2px',
    color: theme.palette.error.main,
    textDecoration: 'line-through',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },

  link: {
    display: 'block',
    minWidth: 230,
    maxWidth: 230,
    margin: 5,
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    '&:hover': {
      boxShadow: theme.shadows[1],
      borderRadius: theme.shape.borderRadius,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 150,
      maxWidth: 150,
    },
  },

  swiperBtn: {
    alignSelf: 'center',
    position: 'relative',
    zIndex: 2,
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    '&:hover': {
      backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    },
  },

  swiperBtnPrev: {
    left: 25,
  },

  swiperBtnNext: {
    right: 25,
  },
}));

interface HorizontalScrollCardListViewPropsInterface {
  title?: string;
  ids: Identifier[];
  data: { [key: string]: object };
  pattern: AdvertisementPatternType;
}

class SwiperInterface {
  constructor(slideNext, slidePrev) {
    this.slideNext = slideNext;
    this.slidePrev = slidePrev;
  }

  slideNext: Function;
  slidePrev: Function;
}

const HorizontalScrollCardListView: FC<
  HorizontalScrollCardListViewPropsInterface
> = props => {
  const { ids, data, pattern, title } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const width = useWidth();

  const apiUrl = getValue(API_URL);

  const initializerSwiper: SwiperInterface = new SwiperInterface(
    () => {},
    () => {},
  );

  const [swiper, updateSwiper] = useState(initializerSwiper);

  const swiperParams = {
    slidesPerView: width === 'xs' ? 2 : 5,
    spaceBetween: width === 'xs' ? 10 : 30,
    loop: true,
    observer: true,
  };

  const goNext = () => {
    if (ids && swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (ids && swiper !== null) {
      swiper.slidePrev();
    }
  };

  return (
    <div className={classes.mainContainer}>
      {width !== 'xs' && (
        <IconButton
          className={`${classes.swiperBtn} ${classes.swiperBtnPrev}`}
          onClick={goPrev}
        >
          <Icon>keyboard_arrow_right</Icon>
        </IconButton>
      )}
      <div className={classes.productSimilar}>
        {!!title && (
          <>
            <Typography
              variant={width === 'xs' ? 'body2' : 'subtitle1'}
              className={classes.productTagTitle}
            >
              {title}
            </Typography>
            <div className={classes.divider} />
          </>
        )}
        <div className={classes.container}>
          {ids && !!ids.length && (
            <Swiper {...swiperParams} getSwiper={updateSwiper}>
              {ids.map((id, index) => {
                const item = data[id];

                let url = '/';
                if (pattern.productId && item[pattern.productId]) {
                  url = `/${SELLIN_SHOW}/${pattern.productShowResource}/${
                    item[pattern.productId]
                  }`;
                }
                if (pattern.categoryId && item[pattern.categoryId]) {
                  url = `/${SELLIN_LIST}/${
                    pattern.categoryListResource
                  }?filter={"ParentId":"${item[pattern.categoryId]}"}`;
                }
                if (pattern.tagId && item[pattern.tagId]) {
                  url = `/${SELLIN_LIST}/${
                    pattern.tagListResource
                  }?filter={"ParentId":"${item[pattern.tagId]}"}`;
                }

                return (
                  <Link
                    className={classes.link}
                    component={RouterLink}
                    to={url}
                    key={`${id}-${index}`}
                  >
                    <div className={classes.card}>
                      {item[pattern.imageFileName] && (
                        <CardMedia
                          className={classes.cardMedia}
                          image={`${apiUrl}/${item[pattern.imagePath]}/thumbnail/${
                            item[pattern.imageFileName]
                          }`}
                        />
                      )}

                      {item[pattern.title] && (
                        <Typography
                          className={classes.cardTitle}
                          align="center"
                          variant={width === 'xs' ? 'caption' : 'body2'}
                        >
                          {item[pattern.title]}
                        </Typography>
                      )}

                      <div className={classes.priceContainer}>
                        {!!item[pattern.price] &&
                          item[pattern.finalPrice] !== item[pattern.price] && (
                            <div>
                              <NumberField
                                className={classes.cardDiscount}
                                source={'value'}
                                record={{ value: item[pattern.price] }}
                              />
                              <span className={classes.discountCurrency}>
                                {getValue(CONFIG_CURRENCY_SYMBOL) ||
                                  translate('shop.basket.currency')}
                              </span>
                            </div>
                          )}

                        {!!item[pattern.finalPrice] && (
                          <div>
                            <NumberField
                              className={classes.cardPrice}
                              source={'value'}
                              record={{ value: item[pattern.finalPrice] }}
                            />
                            <span className={classes.priceCurrency}>
                              {getValue(CONFIG_CURRENCY_SYMBOL) ||
                                translate('shop.basket.currency')}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                );
              })}
            </Swiper>
          )}
        </div>
      </div>
      {width !== 'xs' && (
        <IconButton
          className={`${classes.swiperBtn} ${classes.swiperBtnNext}`}
          onClick={goNext}
        >
          <Icon>keyboard_arrow_lefts</Icon>
        </IconButton>
      )}
    </div>
  );
};

export default HorizontalScrollCardListView;
