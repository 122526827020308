import { ReactElement } from 'react';
import { MailDetailViewInterface } from './mail-detail.type';
import { Box } from '@material-ui/core';
import { AppHeader } from '../../app-container/app-header';
import { AppContent } from '../../app-container/app-content';
import { MailDetailHeader } from './mail-detail-header';
import { useStyles } from './mail-detail.style';
import { MultipleMailDetailBody } from './multiple-mail-detail-body';

const MailDetailView = (props: MailDetailViewInterface): ReactElement => {
  const { mailActionsHandler, selectedMail, selectedMailLoading } = props;
  const classes = useStyles();

  return (
    <Box height={1} display="flex" flexDirection="column">
      <AppHeader className={classes.appHeaderContainer}>
        <MailDetailHeader
          mailActionsHandler={mailActionsHandler}
          selectedMail={selectedMail?.[0] ?? null}
        />
      </AppHeader>
      <AppContent customClass={classes.appContentContainer} isDetailView>
        <MultipleMailDetailBody
          mailActionsHandler={mailActionsHandler}
          selectedMail={selectedMail}
          selectedMailLoading={selectedMailLoading}
        />
      </AppContent>
    </Box>
  );
};

export default MailDetailView;
