//todo: remove this file
import React from 'react';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';

import SellinProductShowView from '../component/shop/SellinProductShowView';
import { getSellinShowPatternInfo } from '../helper/PatternMetaHelper';
import { setBasketItemAction, removeBasketItemAction } from '../redux/shop/action';
import CheckResourceReady from '../container/CheckResourceReady';
import CartDataListContainer from '../component/shop/CartDataListContainer';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 3,
    },
  },

  main: {
    height: '100%',
    flexGrow: 1,
  },

  showCard: {
    height: '100%',
    borderRadius: 0,
  },
}));

const SellinProductShowPage = props => {
  const {
    resource,
    staticContext,
    dispatch,
    setBasketItem,
    removeBasketItem,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles();

  const pattern = getSellinShowPatternInfo(resource);
  const parentId = lodashGet(props, 'match.params.id');

  const onCountChange = (product, count) => {
    setBasketItem({
      resource: pattern.setBasketResource,
      data: {
        [pattern.count]: count,
        [pattern.productId]: product[pattern.id],
        [pattern.barcodeId]: product[pattern.barcodeId],
        [pattern.itemPrice]: product[pattern.price],
      },
      parentResource: pattern.basketResource,
      defaultSort: pattern.defaultSort,
    });
  };

  const onDelete = basketProductId => {
    removeBasketItem({
      resource: pattern.removeBasketResource,
      id: basketProductId,
      parentResource: pattern.basketResource,
      defaultSort: pattern.defaultSort,
    });
  };

  return <div>remove this file</div>;
};

SellinProductShowPage.propTypes = {
  resource: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { match }) => {
  const { moduleName, moduleTableName, id } = match.params;
  const resource = `${moduleName}/${moduleTableName}`;

  return {
    resource,
  };
};

const mapDispatchToProps = {
  setBasketItem: setBasketItemAction,
  removeBasketItem: removeBasketItemAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SellinProductShowPage);
