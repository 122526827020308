import { FC, memo, useEffect, useState } from 'react';
import { actorOnDispatch } from '../../../../type/actor-setup';
import { useStyles } from '../../grid.style';
import { GridBooleanInputInterface } from './grid-boolean-input.type';
import GridBooleanInputView from './grid-boolean-input.view';

const GridBooleanInputController: FC<GridBooleanInputInterface> = props => {
  const { field, onChangeInput, isDisabled } = props;
  const classes = useStyles();
  const [internalValue, setInternalValue] = useState<boolean>(false);

  useEffect(() => {
    actorOnDispatch('gridFormData', gridFormData => {
      const value = gridFormData[field.name] ?? false;
      setInternalValue(!!value);
    });
  }, []);

  /**
   * handle change date
   * @param { string } type
   * @param { unknown } payload
   * @returns void
   */
  const handleChangeValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void => {
    setInternalValue(checked);
    onChangeInput({ fieldName: field.name, value: internalValue });
  };

  return (
    <div className={classes.datePickerContainer}>
      <GridBooleanInputView
        value={internalValue}
        handleChangeValue={handleChangeValue}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default memo(GridBooleanInputController);
