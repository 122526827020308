import { useCallback, useMemo } from 'react';
import { useDispatch, useStore } from 'react-redux';
import {
  refreshView,
  setListSelectedIds,
  useLocale,
  useTranslate,
} from 'react-admin';
import lodashGet from 'lodash/get';
import lodashMerge from 'lodash/merge';

import {
  ApiResponseWithValidationErrors,
  Action,
  FormActionProps,
  CreateEditFullFormSaveParams,
  ValidateInputParams,
  ServiceDialogFormParams,
  ProfileFormParams,
  RelationEditDialogFormSaveParams,
  GridFormParamsInterface,
} from '../form.type';
import { push as redirectToPage } from 'connected-react-router';
import { setGlobalParametersAction } from '../../../redux/profile/action';
import { findAllAction as findAllMenuAction } from '../../../redux/menu/action';

import { clone, isEmpty, isEmptyObject } from '../../../helper/data-helper';
import { FieldType } from '../../../helper/Types';
import {
  actorDispatch,
  actorGetActionValue,
  actorRemoveAction,
  actorSetActionValue,
  FormKeyMode,
  GridDataInterface,
  RecordKeyMode,
} from '../../../type/actor-setup';
import {
  crudCreateWithCallbackAction as dispatchCrudCreate,
  crudUpdateWithCallbackAction as dispatchCrudUpdate,
} from '../../../redux/crud/action';
import { createOneSuccessAction as createOneSuccess } from '../../../redux/dropdown/action';
import { checkValidationErrorsInApiResponse } from '../form.helper';
import {
  QuickEditDialogFormSaveParams,
  DropdownQuickCreateFormSaveParams,
} from '../form.type';
import dataProvider, {
  INLINE_CELL_UPDATE,
  RUN_SERVICE,
} from '../../../core/dataProvider';
import { refreshRelationAction as refreshRelations } from '../../../redux/relation/action';
import { prepareGlobalParameters } from '../profile-form/profile-form.helper';
import {
  API_NAME,
  CONFIG_CACHED_MENU,
  getValue,
  removeValue,
  SERVICE_WORKER_CACHE_VERSION,
  setValue,
  USER_WAREHOUSE_ID,
  USER_WAREHOUSE_TITLE,
} from '../../../core/configProvider';
import { getSessionIdInUrl } from '../../../helper/UrlHelper';
import { ChangePasswordParams } from '..';
import { showNotification } from '../../../helper/general-function-helper';
import lodashMap from 'lodash/map';
import lodashFilter from 'lodash/filter';
import { getGridData } from './grid-data.helper';

export function useFormSave(
  type: keyof FormActionProps,
): (
  data: Record<string, unknown>,
  props: FormActionProps[typeof type],
) => Promise<void> {
  let handleAction;
  const reduxDispatch = useDispatch();
  const reduxStore = useStore();
  const reduxState = useMemo(() => reduxStore.getState(), [reduxStore]);
  const locale = useLocale();
  const translate = useTranslate();
  const sessionIdInUrl = getSessionIdInUrl();

  /**
   * Save dropdown quick form.
   * @function dropdownQuickCreateSave
   * @param {Record<string, unknown>} data
   * @param {DropdownQuickCreateFormSaveParams} params
   * @returns {void}
   */
  const dropdownQuickCreateSave = (
    data: Record<string, unknown>,
    params: DropdownQuickCreateFormSaveParams,
  ): void => {
    const currentResource = actorGetActionValue('resources')!.current;
    actorDispatch('loading', { [currentResource.value]: true });
    reduxDispatch(
      dispatchCrudCreate(
        currentResource.value,
        data,
        action => dropdownQuickCreateAfterSave(action, params),
        false, // disable notification
        true, // execute callback on failure
        {},
      ),
    );
  };

  /**
   * Handle after dropdown quick save.
   * @function dropdownQuickCreateAfterSave
   * @param {Action} action
   * @param {DropdownQuickCreateFormSaveParams} params
   * @returns {void}
   */
  const dropdownQuickCreateAfterSave = (
    action: Action,
    params: DropdownQuickCreateFormSaveParams,
  ): void => {
    const {
      dropdownMeta,
      isSaveAndView,
      isSaveAndNew,
      mustRefresh,
      onCreate,
      validationParams,
    } = params;
    const responseData = lodashGet(action, ['payload', 'data']);
    const newRecordId = lodashGet(responseData, 'id');
    const currentResource = actorGetActionValue('resources')!.current;
    const { uniqueId } = dropdownMeta;

    actorDispatch('loading', { [currentResource.value]: false });

    if (action?.error?.data) {
      handleError(action, validationParams);
    } else if (action.error && !action.error?.data) {
      showNotification(action.error, 'error');
    }

    if (dropdownMeta) {
      // add this into dropdown redux store
      reduxDispatch(
        createOneSuccess({
          id: uniqueId,
          newRecord: responseData,
          meta: dropdownMeta,
        }),
      );
      // Append new drop data to ACTOR store
      const dropDownData = actorGetActionValue('dropDownData');
      if (dropDownData && dropDownData![uniqueId]) {
        const allData = dropDownData![uniqueId]['ALL']?.concat([responseData]);
        actorDispatch('dropDownData', {
          [uniqueId]: {
            DATA: [responseData],
            ALL: allData,
            LOADING: false,
          },
        });
      }
    }

    if (mustRefresh) {
      reduxDispatch(refreshView());
    }

    if (isSaveAndView && !isEmpty(currentResource.value) && !isEmpty(newRecordId)) {
      reduxDispatch(
        redirectToPage(
          `/${currentResource.value}/${newRecordId}/show${
            sessionIdInUrl ? '?&sessionid=' + sessionIdInUrl : ''
          }`,
        ),
      );
      // should close dialog
      actorDispatch('closeDialogs', true);
    }

    if (isSaveAndNew) {
      actorDispatch('resetForm', {});
    } else {
      actorDispatch('remove', {
        resource: currentResource.value,
        type: currentResource.type,
      });
      // should close dialog
      actorDispatch('closeCurrentDialog', true);

      if (typeof onCreate === 'function') {
        setTimeout(() => {
          onCreate({
            ...responseData,
            value: newRecordId,
          });
        }, 500); // FIXME: use actor proxy
      }
    }
  };

  /**
   *
   * @param data
   * @param params
   */
  const gridFormSave = async (
    data: Record<string, unknown>,
    params: GridFormParamsInterface,
  ): Promise<void> => {
    const { isCreateMode, onFailure, onSuccess, additionalFormData, resource } =
      params;
    data = { ...data, ...additionalFormData };

    actorDispatch('loading', { [resource]: true });

    if (isCreateMode) {
      actorDispatch('crudAction', {
        type: 'CRUD_CREATE',
        entity: 'grid',
        resource: resource,
        data,
        onSuccess: async response => {
          getGridData(resource, response.data);

          successQuickCreateGrid(response, params);
          if (typeof onSuccess === 'function') {
            onSuccess(response);
          }
        },
        onFailure: error => {
          if (typeof onFailure === 'function') {
            onFailure(error);
          }
        },
      });
    }
  };

  const successQuickCreateGrid = (
    response: Record<any, any>,
    extraParams: GridFormParamsInterface,
  ): void => {
    const { relationMode, resource } = extraParams;

    const { exceptions } = response;

    if (isEmptyObject(exceptions)) {
      reduxDispatch(refreshView());

      if (relationMode) {
        actorDispatch('refreshView', 'showRecordPage', {
          disableDebounce: true,
        });
      }
    }
  };

  /**
   * Dispatch an action to send a request to API to save form data
   * @function save
   * @param {Record<string, unknown>} data The form data that should be saved within a request (update/create)
   * @param {object} params The parameters that we should send in our request
   * @returns {void}
   */
  const createEditFullFormSave = (
    data: Record<string, unknown>,
    params: CreateEditFullFormSaveParams,
  ): void => {
    const { id } = params;
    const currentResource = actorGetActionValue('resources')!.current;

    actorDispatch('loading', { [currentResource.value]: true });
    const recordWithoutRelationData = actorGetActionValue(
      'record',
      `${currentResource.value}.${currentResource.type}.${RecordKeyMode.FORM}`,
    )!;

    if (!isEmpty(id) && params) {
      reduxDispatch(
        dispatchCrudUpdate(
          currentResource.value,
          id,
          data,
          !isEmptyObject(recordWithoutRelationData) ? recordWithoutRelationData : {},
          action => createEditFullFormAfterSave(action, params),
          false, // disable notification
          {}, // other params
          true, // execute callback on failure
        ),
      );
      return;
    }

    reduxDispatch(
      dispatchCrudCreate(
        currentResource.value,
        data,
        action => createEditFullFormAfterSave(action, params),
        false, // disable notification
        true, // execute callback on failure
        {},
      ),
    );
  };

  /**
   * Handle validation after save.
   * @function handleError
   * @param {Action} action
   * @param {ValidateInputParams} validationParams
   * @returns {void}
   */
  const handleError = (
    action: Action,
    validationParams: ValidateInputParams,
  ): void => {
    const currentResource = actorGetActionValue('resources')!.current;
    const allFields = actorGetActionValue('allFields', [
      currentResource.value,
      currentResource.type,
    ])! as unknown as Array<FieldType>;

    // We sure that here we always have `formData` and `initialData` already
    const formData =
      (actorGetActionValue('formData', [
        currentResource.value,
        currentResource.type,
      ]) as FormData | null) ?? {};

    const preparedApiErrorObject = {
      apiErrors: action.error.data,
      requestId: action.error.requestId,
    } as ApiResponseWithValidationErrors;

    checkValidationErrorsInApiResponse(formData, preparedApiErrorObject, {
      ...validationParams,
      allFields,
    });
  };

  /**
   * `createEditFullFormAfterSave` is a function that passes to redux action to call when needed. it receives an action object from `reduxAction` that includes the result
   * of action like API response or API error or even exceptions. And the second parameter is an object that includes extra parameters that we
   * need like `resetDefaultValues` function. It should handle the API response and fill the error state if needed.
   *  @function createEditFullFormAfterSave
   *  @param {Action} action redux action
   *  @param {ExtraParamsInterface} extraParams - extra params
   *  @returns {void}
   */
  const createEditFullFormAfterSave = (
    action: Action,
    extraParams: RelationEditDialogFormSaveParams,
  ): void => {
    const {
      isQuickForm,
      isSaveAndNew,
      validationParams,
      mustRefresh,
      relationMode,
      isSaveAndView,
      isRelationEditDialogOpen,
      onSuccess,
      onFailure,
    } = extraParams;

    const currentResource = actorGetActionValue('resources')!.current;
    actorDispatch('loading', { [currentResource.value]: false });
    if (action?.error?.data) {
      if (typeof onFailure === 'function') {
        onFailure();
      }
      handleError(action, validationParams);
    } else if (action.error && !action.error?.data) {
      if (typeof onFailure === 'function') {
        onFailure();
      }
      showNotification(action.error, 'error');
    } else {
      if (typeof onSuccess === 'function') {
        onSuccess();
      }

      const { payload: response } = action;
      const { data: newData, exceptions } = response;

      const newRecordId = lodashGet(newData, 'id');

      if (isEmptyObject(exceptions)) {
        if (
          isSaveAndView &&
          !isEmpty(currentResource.value) &&
          !isEmpty(newRecordId)
        ) {
          // should close dialog
          actorDispatch('closeDialogs', true);

          reduxDispatch(
            redirectToPage(
              `/${currentResource.value}/${newRecordId}/show${
                sessionIdInUrl ? '?&sessionid=' + sessionIdInUrl : ''
              }`,
            ),
          );
        }

        if (isSaveAndNew) {
          if (relationMode) {
            getGridData(currentResource.value, newData);
          }

          actorDispatch('resetForm', { saveType: 'saveAndNew' });
        }

        if (isQuickForm && !isSaveAndNew && !isSaveAndView) {
          /* FIXME: After refactoring `show record page`, how we have to change content the following `if ` statement?
           *  we don't need to `reduxDispatch` and do we have to use `actorDispatch('refreshView', 'showRecordPage')` or
           *    `actorDispatch('refreshView', 'allRelations')` or both?
           */
          if (mustRefresh) {
            actorDispatch('refreshView', 'showRecordPage');
            reduxDispatch(refreshView());

            if (relationMode) {
              reduxDispatch(refreshRelations());
            }
          }

          //TODO: make in function
          if (relationMode || isRelationEditDialogOpen) {
            const currentData = actorGetActionValue(
              'gridData',
              `${currentResource.value}.data`,
            );

            const _newData = lodashMap(currentData, function (rowInfo) {
              return rowInfo.id === newData.id ? newData : rowInfo;
            });

            actorSetActionValue('gridData', (Math.random() * 10000).toFixed(), {
              path: `${currentResource.value}.lastRequestId`,
            });

            actorDispatch('gridData', _newData, {
              path: `${currentResource.value}.data`,
              disableDebounce: true,
            });

            actorDispatch('remove', {
              resource: currentResource.value,
              type: currentResource.type,
            });
          } else {
            actorDispatch(
              'formMessages',
              {},
              {
                path: `${currentResource.value}.${currentResource.type}`,
                replaceAll: true,
              },
            );

            actorRemoveAction({
              actionName: 'formData',
              path: `${currentResource.value}.${currentResource.type}`,
            });

            actorRemoveAction({
              actionName: 'record',
              path: `${currentResource.value}.${currentResource.type}`,
            });

            // just in quickCreateDialog
            type === 'quickCreateDialog' && actorDispatch('resetForm', {});
          }

          // should close dialog
          actorDispatch('closeCurrentDialog', true);
          // reduxDispatch(quickCloseDialog());
        }

        if (!isQuickForm && !isSaveAndNew) {
          actorDispatch('closeDialogs', true);
          const parentUrl = actorGetActionValue(
            'quickCreateSupplementaryData',
            `${currentResource.value}.${FormKeyMode.RELATION}.parentUrl`,
          );

          parentUrl && actorDispatch('resetForm', { resource: currentResource });

          reduxDispatch(
            redirectToPage(
              parentUrl ??
                `/${currentResource.value}/${newData.id}/show${
                  sessionIdInUrl ? '?&sessionid=' + sessionIdInUrl : ''
                }`,
            ),
          );
        }
        if (
          currentResource.type === FormKeyMode.RELATION &&
          !isSaveAndNew &&
          !isSaveAndView
        ) {
          const rootResource = actorGetActionValue('resources')!.stack;

          actorDispatch('resources', {
            stack: [
              {
                type: FormKeyMode.ROOT,
                value: rootResource[0].value,
              },
            ],
            current: {
              type: FormKeyMode.ROOT,
              value: rootResource[0].value,
            },
          });
        }
      }
    }
  };

  /**
   * Dispatch an action to send a request to API to edit form data
   * @function quickEditDialogFormSave
   * @param {Record<string, unknown>} data The form data that should be saved within a request (update/create)
   * @param {object} params The parameters that we should send in our request
   * @returns {void}
   */
  const quickEditDialogFormSave = async (
    data: Record<string, unknown>,
    params: QuickEditDialogFormSaveParams,
  ): Promise<void> => {
    const { id, target } = params;
    const currentResource = actorGetActionValue('resources')!.current;

    if (!isEmpty(id) && params && !isEmptyObject(data)) {
      actorDispatch('loading', { [currentResource.value]: true });

      try {
        const result = await dataProvider(
          INLINE_CELL_UPDATE,
          currentResource.value,
          {
            data,
            id,
            target,
          },
        );
        quickEditDialogFormAfterSave(result, params);
      } catch (error) {
        showNotification(error, 'error');
      }
    }
  };

  /**
   * check errors and show notification and close dialog or when success close dialog
   *  @function quickEditDialogFormAfterSave
   *  @param {Action} action redux action
   *  @param {QuickEditDialogFormSaveParams} extraParams - extra params
   *  @returns {void}
   */
  const quickEditDialogFormAfterSave = (
    action: Action,
    extraParams: QuickEditDialogFormSaveParams,
  ): void => {
    const { validationParams, closeDialog } = extraParams;
    const currentResource = actorGetActionValue('resources')!.current;
    actorDispatch('loading', { [currentResource.value]: false });
    const allFields = actorGetActionValue('allFields', [
      currentResource.value,
      currentResource.type,
    ])! as unknown as Array<FieldType>;

    // We sure that here we always have `formData` and `initialData` already
    const formData =
      (actorGetActionValue('formData', [
        currentResource.value,
        currentResource.type,
      ]) as FormData | null) ?? {};

    if (action.error && action.error.data) {
      // fill the state to pass to validation HOC

      const preparedApiErrorObject = {
        apiErrors: action.error.data,
        requestId: action.error.requestId,
      } as ApiResponseWithValidationErrors;

      checkValidationErrorsInApiResponse(formData, preparedApiErrorObject, {
        ...validationParams,
        allFields,
      });
    } else if (!isEmpty(action.error)) {
      showNotification(action.error.data || action.error.toString(), 'error');
      actorDispatch('refreshView', 'showRecordPage');
      closeDialog();

      // reduxDispatch(refreshView());
    } else {
      actorDispatch('record', action.data, {
        path: `${currentResource.value}.${currentResource.type}.${RecordKeyMode.FULL}`,
        replaceAll: true,
      });

      actorDispatch('refreshView', 'allRelations', { disableDebounce: true });
      closeDialog();

      // reduxDispatch(refreshView());
    }
  };

  /*
   * this is save function of a service-dialog-form
   *
   * @function serviceDialogFullFormSave
   * @param {Record<string, unknown>} params
   * @param {ServiceDialogFormParams} props
   * @returns {Promise<void>}
   */
  const serviceDialogFullFormSave = async (
    params: Record<string, unknown>,
    props: ServiceDialogFormParams,
  ): Promise<void> => {
    const {
      selectedService,
      selectedIds,
      customRefresh,
      parentResource,
      onFailure,
    } = props;

    const { current: currentResource, stack } = actorGetActionValue('resources')!;

    actorDispatch('loading', true, {
      path: currentResource.value,
    });

    // We need parentResource for this reason We get previous resource from stack resources
    const resource = !isEmpty(parentResource)
      ? parentResource
      : stack[stack.length - 2]?.value;

    if (!resource) return;

    let resourcesData: Record<string, unknown>[] | undefined = lodashGet(
      reduxState,
      ['admin', 'resources', resource, 'data'],
    );

    //in relation mode grid data saved in actor
    let selectedIdList = clone(selectedIds);
    if (isEmptyObject(resourcesData)) {
      const gridData = actorGetActionValue(
        'gridData',
        resource,
      ) as GridDataInterface;

      selectedIdList = gridData?.selectedIds?.map(Number) ?? [];
      resourcesData = gridData?.data;
    }

    const isReport = resource.indexOf('report') === 0;
    const [serviceModuleName, serviceModuleTableName] = resource.split('/');

    let serviceParameter: Record<string, unknown> = {
      actionUniqueId: selectedService?.uniqueId,
      data: {
        params:
          lodashGet(selectedService, 'related') === 'SingleRecord'
            ? lodashMerge(resourcesData?.[selectedIdList[0]], params)
            : params,
        items: !isEmptyObject(resourcesData)
          ? lodashFilter(resourcesData, (value, index) =>
              selectedIdList.includes(value.id as number),
            )
          : [params],
      },
    };

    if (isReport) {
      serviceParameter = {
        ...serviceParameter,
        reportId: serviceModuleTableName,
      };
    } else {
      serviceParameter = {
        ...serviceParameter,
        serviceModuleName,
        serviceModuleTableName,
      };
    }

    try {
      const response = await dataProvider(RUN_SERVICE, resource, serviceParameter);
      const message = lodashGet(response, 'userMessage');

      showNotification(!isEmpty(message) ? message : 'service.success', 'success', {
        forceSnackbar: true,
        fromQuickCreateDialog: true, // to render notification in <portal/> with id: `serviceCustomSnackContainer`
      }); // show success notification
      customRefresh?.();
      reduxDispatch(refreshRelations());
      // reduxDispatch(refreshOneRelationAction({ resource }));
      reduxDispatch(refreshView());
      actorDispatch('refreshView', 'allRelations', { disableDebounce: true });
      actorDispatch('closeCurrentDialog', true);
      setTimeout(() => {
        reduxDispatch(setListSelectedIds(resource, [])); // clear grid selection

        actorDispatch('loading', false, {
          path: currentResource.value,
        });
      }, 500);
    } catch (error) {
      onFailure?.(error);
      const _error = error as Record<string, string>;

      showNotification(_error.message ?? _error.toString(), 'error', {
        forceSnackbar: true,
        fromQuickCreateDialog: true, // to render notification in <portal/> with id: `serviceCustomSnackContainer`
      });

      actorDispatch('loading', false, {
        path: currentResource.value,
      });
    }
  };

  /**
   * it will dispatch `findAllMenuAction` to make a new request for get menu and update the menu list
   * @function updateMenuList
   * @returns {void} void
   */
  const updateMenuList = useCallback(() => {
    reduxDispatch(findAllMenuAction());
  }, []);

  const failureChangePasswordCallback = useCallback((action: string) => {
    if (typeof action === 'string') {
      showNotification(action, 'error');
    }
  }, []);

  const successChangePasswordCallback = useCallback(
    (props: ChangePasswordParams) => {
      props?.closeDialog();
      showNotification('notification.changePassword.success', 'success');
    },
    [],
  );

  const profileFormFullSave = (
    params: Record<string, unknown>,
    props: ProfileFormParams,
  ): void => {
    const { current: currentResource } = actorGetActionValue('resources')!;
    const { handleCloseProfileFormDialog } = actorGetActionValue(
      'handleCloseProfileFormDialog',
    )!;

    const { fields: profileSettingFields } = props;

    if (isEmptyObject(params)) return;

    actorDispatch('loading', true, {
      path: currentResource.value,
    });

    const value = +lodashGet(params, lodashGet(profileSettingFields, ['0', 'name']));
    const wareHouseTitle = lodashGet(params, '__currentWareHouseID_value');

    const newGlobalParamsData = prepareGlobalParameters(
      value,
      profileSettingFields[0],
    );

    reduxDispatch(setGlobalParametersAction(newGlobalParamsData));

    setValue(USER_WAREHOUSE_ID, value);
    setValue(USER_WAREHOUSE_TITLE, wareHouseTitle);

    //remove cache after change warehouse
    removeValue(CONFIG_CACHED_MENU);
    setValue(SERVICE_WORKER_CACHE_VERSION, 'removeServiceWorker');

    actorSetActionValue('globalParameters', newGlobalParamsData, {
      replaceAll: true,
    });

    actorSetActionValue('formData', value, {
      path: `account/${getValue(API_NAME)}/profile.${profileSettingFields[0].name}`,
    });

    actorDispatch('profileSetting', {
      USER_WAREHOUSE_ID: value,
    });
    handleCloseProfileFormDialog();

    actorDispatch('changeProfile', {
      data: newGlobalParamsData,
      successCallback: reloadPage,
    });

    setTimeout(() => {
      actorDispatch('loading', false, {
        path: currentResource.value,
      });
    }, 1000);
  };

  const reloadPage = () => {
    location.reload();
  };
  // FIXME: Clean up body of this function
  /**
   * It does some processes to send received data to the server
   * @function wmsSubmitForm
   * @param { object } data
   * @param { object } params
   * @returns { void }
   */
  const wmsSubmitForm = (
    data: Record<string, unknown>,
    params: Record<string, unknown>,
  ): void => {
    const currentResource = actorGetActionValue('resources')!.stack[0];
    const allFields = actorGetActionValue(
      'allFields',
      `${currentResource.value}.${currentResource.type}`,
    );

    let hasValidationCheck = false;
    const { validationActionId, actionUniqueId, onSuccess, isCRUDCreate } = params;

    if (isCRUDCreate) {
      const { resource, disableNotification, executeCallbackOnFailure, callback } =
        params;

      reduxDispatch(
        dispatchCrudCreate(
          resource as string,
          data,
          callback as (...args) => void,
          disableNotification as boolean,
          executeCallbackOnFailure as boolean,
          {
            filedList: allFields,
            translate,
            showNotification,
            locale,
          },
        ),
      );

      return;
    }

    // The following code is about SERVICEs
    if (validationActionId) {
      hasValidationCheck = true;

      actorDispatch('crudAction', {
        entity: 'wms',
        type: 'RUN_SERVICE',
        // payload: {
        actionUniqueId: validationActionId,
        data: {
          params: data,
        },
        onSuccess: (): void => {
          runWMSService({
            ...params,
            data: {
              params: data,
            },
          });
        },
        onFailure: (response: Record<string, unknown>): void => {
          let _userMessage;
          if (typeof response === 'string') {
            _userMessage = response;
          } else {
            _userMessage = response;
          }
          showNotification(_userMessage, 'error');

          if (typeof params.onFailure === 'function') {
            params.onFailure();
          }
        },
      });
    }

    if (!hasValidationCheck && actionUniqueId) {
      runWMSService({
        ...params,
        data: {
          params: data,
        },
      });

      return;
    }

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  };

  /**
   * @function runWMSService
   * @param { Record<string, unknown> } params
   * return { void } void
   */
  const runWMSService = (params: Record<string, unknown>): void => {
    const { actionUniqueId, data, onSuccess } = params;

    actorDispatch('crudAction', {
      entity: 'wms',
      type: 'RUN_SERVICE',
      actionUniqueId,
      data,
      onSuccess: (response: Record<string, unknown>): void => {
        const { userMessage } = response;

        showNotification(userMessage, 'info');

        if (typeof onSuccess === 'function') {
          onSuccess(response);
        }
      },
      onFailure: (error: unknown): void => {
        showNotification(error, 'error');

        if (typeof params.onFailure === 'function') {
          params.onFailure();
        }
      },
    });
  };

  /**
   *
   * @function simpleFormSubmit
   * @param { object } data
   * @param { object } params
   * @returns { void }
   */
  const simpleFormSubmit = (
    data: Record<string, unknown>,
    params: Record<string, unknown>,
  ): void => {
    actorDispatch('runActionsService', {
      actionUniqueId: params?.actionUniqueId,
      params: data,
      failureCallback: error => showNotification(error, 'error'),
      successCallback: params?.onSuccess,
    });
  };

  const changePasswordSave = (
    params: Record<string, unknown>,
    props: ChangePasswordParams,
  ): void => {
    const action = {
      failureCallback: failureChangePasswordCallback,
      successCallback: successChangePasswordCallback,
      data: { params, props },
    };
    actorDispatch('changePassword', action);
  };

  switch (type) {
    case 'createEditRecordPage':
    case 'quickCreateDialog':
    case 'relationEditDialogForm':
      handleAction = createEditFullFormSave;
      break;

    case 'gridForm':
      handleAction = gridFormSave;
      break;

    case 'dropdownQuickCreateForm':
      handleAction = dropdownQuickCreateSave;
      break;
    case 'quickEditDialog':
      handleAction = quickEditDialogFormSave;
      break;

    case 'serviceDialogForm':
      handleAction = serviceDialogFullFormSave;
      break;
    case 'profileForm':
      handleAction = profileFormFullSave;
      break;
    case 'changePassword':
      handleAction = changePasswordSave;
      break;

    case 'wms':
      handleAction = wmsSubmitForm;
      break;

    case 'simpleForm':
      handleAction = simpleFormSubmit;
      break;

    default:
      break;
  }

  return useCallback(handleAction, [type]);
}
