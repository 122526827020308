import { ReactElement } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { Checkbox, IconButton, Hidden } from '@material-ui/core';
import momentJalaali from 'moment-jalaali';
import clsx from 'classnames';
import Box from '@material-ui/core/Box';

import { useStyles } from './mail-summary.style';
import Avatar from '@material-ui/core/Avatar/index';
import ReplyIcon from '@material-ui/icons/Reply';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Tooltip from '@material-ui/core/Tooltip/index';

import {
  API_URL,
  getValue,
  API_VERSION,
  SESSION_ID,
} from '../../../../../core/configProvider';
import RecallIcon from '../../../../../icon/RecallIcon';
import { MailSummaryViewInterface } from './mail-summary.type';
import { MoreDetail } from './more-detail';
import { moreOptionForBigString } from '../../../../../helper/TextHelper';
import classNames from 'classnames';

const MailSummaryView = (props: MailSummaryViewInterface): ReactElement => {
  const {
    mail,
    isMainMail,
    expand,
    onShowDelegationHandler,
    onRecallHandler,
    onReplyToMail,
    onReplyAllMail,
    onStarClick,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();
  const locale = useLocale();
  const sessionId = getValue(SESSION_ID);
  const apiUrl = getValue(API_URL);
  const apiVersion = getValue(API_VERSION);

  momentJalaali.locale(locale);

  return (
    <>
      {mail && (
        <Box
          className={classNames('dontShowInPrint', 'MuiTypography-root')}
          color="text.secondary"
          width={1}
        >
          <Box
            className="MuiTypography-root"
            my={{ xs: 2, sm: 3 }}
            display="flex"
            flexDirection="row"
            alignItems="center"
          ></Box>

          <Box
            className="MuiTypography-root"
            mb={{ xs: 3, md: 2 }}
            display="flex"
            flexDirection={'row'}
            alignItems={{ xs: 'flex-start', md: 'center' }}
          >
            <Box className="MuiTypography-root" display="flex" alignItems="center">
              <Avatar
                src={`${apiUrl}/oauth2/${sessionId}/${apiVersion}${mail.fromcontactimage}`}
                alt={mail.contactinfo}
                className={clsx(classes.avatar, 'MuiTypography-root')}
                classes={{ img: 'MuiTypography-root' }}
              />

              <Box ml={2}>
                <Box
                  className="MuiTypography-root"
                  color="primary.main"
                  fontWeight="bold"
                  fontSize={13}
                >
                  {mail.fromcontactinfo}
                </Box>
                {!expand ? (
                  <Box
                    className="MuiTypography-root"
                    color="primary.main"
                    fontWeight="light"
                    fontSize={12}
                    title={mail?.messageparaph ?? ''}
                  >
                    {moreOptionForBigString(mail?.messageparaph, 100) ??
                      mail?.subject}
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center">
                    <Box
                      className={classes.contactInfo}
                      fontWeight="light"
                      fontSize={12}
                    >
                      {translate('mail.to')}:{' '}
                      {moreOptionForBigString(mail.tocontactinfo, 50)}{' '}
                      {mail?.delegationlink && (
                        <a onClick={onShowDelegationHandler}>{`(${translate(
                          'mail.delegation',
                        )})`}</a>
                      )}
                    </Box>

                    <MoreDetail mail={mail} isMainMail={isMainMail} />
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              ml={{ xs: 0, md: 'auto' }}
              mt={{ xs: 3, md: 0 }}
              display="flex"
              flexDirection="row"
              alignItems="center"
              className={classes.dateBox}
            >
              <Box className="MuiTypography-root" fontSize={12} mt={0.2}>
                ({momentJalaali(mail.referdate).startOf('second').fromNow()}){' '}
                {locale === 'fa'
                  ? momentJalaali(mail?.referdate).format('jDD jMMMM jYYYY,  HH:mm')
                  : momentJalaali(mail?.referdate).format('DD MMMM YYYY, HH:mm')}
              </Box>

              <Box ml={{ sm: 'auto' }} display="flex" alignItems="center">
                {!!mail.isreplyactive && (
                  <>
                    <Tooltip title={translate('mail.reply')}>
                      <IconButton
                        onClick={onReplyToMail}
                        data-test="mail-summary-reply-icon"
                      >
                        <ReplyIcon style={{ fontSize: '18px' }} />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={translate('mail.replyAll')}>
                      <IconButton
                        onClick={onReplyAllMail}
                        data-test="mail-summary-reply-all-icon"
                      >
                        <ReplyAllIcon style={{ fontSize: '18px' }} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                <Tooltip title={translate('mail.toStar')}>
                  <Box component="span" color="text.secondary" ml={0.5}>
                    <Checkbox
                      data-test="mail-summary-checkbox"
                      icon={
                        <StarBorderIcon
                          data-test="mail-summary-star-border-icon"
                          style={{ fontSize: '18px' }}
                        />
                      }
                      checkedIcon={
                        <StarIcon
                          data-test="mail-summary-star-icon"
                          style={{ color: '#D4AF37', fontSize: '18px' }}
                        />
                      }
                      checked={mail.isstared}
                      onChange={event =>
                        onStarClick(event, mail.isstared ? false : true)
                      }
                    />
                  </Box>
                </Tooltip>

                {mail.isrecallactive && (
                  <Tooltip title={translate('mail.recall')}>
                    <IconButton
                      onClick={onRecallHandler}
                      style={{ color: '#FF5350' }}
                    >
                      <RecallIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MailSummaryView;
