import React, { useState, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getIconClassName } from '@uifabric/styling';
import { Typography, Checkbox } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';

import { getTodoTaskDetailsPatternInfo } from '../../helper/PatternMetaHelper';
import { CustomTheme } from '../../core/themeProvider';

interface TodoTaskAddToMyDayPropsInterface {
  resource: string;
  task: object;
  taskId: number;
  onChange: Function;
  onClick: Function;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  addToMyDayContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  addToMyDayIcon: {
    fontSize: 20,
    color: theme.palette.primary.appPrimaryIconColor,
  },

  addToMyDayIconSelected: {
    fontSize: 20,
    color: theme.palette.secondary.main,
  },
}));

const TodoTaskAddToMyDay: FC<TodoTaskAddToMyDayPropsInterface> = props => {
  const { resource, onClick, task, onChange } = props;
  const classes = useStyles();
  const translate = useTranslate();

  const { isAddToMyDay } = getTodoTaskDetailsPatternInfo(resource);

  const [addToMyDayState, setAddToMyDayState] = useState(task[isAddToMyDay]);

  const handleClick = name => () => {
    onClick()();
    setAddToMyDayState(!addToMyDayState);
    submitValues({ [name]: !addToMyDayState });
  };

  const submitValues = data => {
    Object.keys(data).forEach(key => {
      if (task[key] !== data[key]) {
        onChange({ data });
      }
    });
  };

  return (
    <div
      className={classes.addToMyDayContainer}
      onClick={handleClick(isAddToMyDay)}
      data-test-task-view-addToMyDay
    >
      <Checkbox
        value={isAddToMyDay}
        checked={addToMyDayState}
        color="secondary"
        icon={
          <i
            className={`${classes.addToMyDayIcon} ${getIconClassName('sunny')}`}
          ></i>
        }
        checkedIcon={
          <i
            className={`${classes.addToMyDayIconSelected} ${getIconClassName(
              'sunny',
            )}`}
          ></i>
        }
        disableRipple
      />
      <Typography
        variant="caption"
        color={addToMyDayState ? 'secondary' : 'textPrimary'}
      >
        {translate('todo.addToMyDay')}
      </Typography>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    task: lodashGet(
      state,
      ['admin', 'resources', props.resource, 'data', props.taskId],
      {},
    ),
  };
};

export default connect(mapStateToProps, {})(TodoTaskAddToMyDay);
