import React, { useState, useRef, useEffect } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Icon, Typography } from '@material-ui/core';
import { getIconClassName } from '@uifabric/styling';
import { translate } from 'react-admin';
import { openDialogAction } from '../../redux/todoList/action';
import { getTodoTaskDetailsPatternInfo } from '../../helper/PatternMetaHelper';
import { parseDate } from '../../helper/DateHelper';
import ProfileAvatar from '../ProfileAvatar';
import { isEmpty } from '../../helper/data-helper';
import { useDrag, useDrop } from 'react-dnd';
import { CustomTheme } from '../../core/themeProvider';
import { getValue, USER_ID } from '../../core/configProvider';

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    transition: 'background-color 200ms',
    '&:hover': {
      backgroundColor: theme.palette.primary.appPrimaryLightBackgroundColor,
      transition: 'background-color 200ms',
    },
  },

  taskTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  taskTitle: {
    flexGrow: 1,
  },

  importantIcon: {
    color: theme.palette.primary.appPrimaryDisableIconColor,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.appPrimaryIconColor,
    },
  },

  itemContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  item: {
    display: 'flex',
    alignItems: 'center',
  },

  itemIcon: {
    fontSize: 13,
    color: theme.palette.secondary.main,
  },

  itemTitle: {
    margin: 4,
  },

  dott: {
    margin: '0 4px',
  },

  userAvatar: {
    width: 25,
    height: 25,
  },
}));

const TodoTaskItem = props => {
  const {
    taskTitle,
    taskId,
    currentSort,
    openDialog,
    task,
    onChange,
    translate,
    selectedTodoList,
    listResource,
    dragTaskAcceptType,
    reOrderTasks,
    selected,
    setSelectedTaskItem,
    resource,
    detailResource,
  } = props;
  const classes = useStyles();

  const {
    title,
    isImportant,
    isDone,
    isAddToMyDay,
    remindMeDate,
    dueDate,
    repeatType,
    note,
    filePath,
    assignUser,
    rowOrder,
    createDate,
  } = getTodoTaskDetailsPatternInfo(detailResource);

  const currentUserId = getValue(USER_ID);

  const [taskIsDone, setTaskIsDone] = useState(task[isDone]);
  const [taskIsImportant, setTaskIsImportant] = useState(task[isImportant]);
  const [isDraggable, setIsDraggable] = useState(true);

  const ref = useRef(null);

  useEffect(() => {
    setIsDraggable(
      currentSort.field && currentSort.field.toLowerCase() === rowOrder,
    );
  }, [currentSort, rowOrder]);

  useEffect(() => {
    if (isDraggable) {
      drag(drop(ref));
    }
  }, [isDraggable]);

  const [, drag] = useDrag({
    item: {
      type: dragTaskAcceptType,
      id: taskId,
      order: task[rowOrder],
      title: task[title],
    },
  });

  const [{ isDragging }, drop] = useDrop({
    accept: dragTaskAcceptType,

    collect: monitor => ({
      isDragging: monitor.isOver(),
    }),
    drop(item: any) {
      const id = item.id;
      const newOrder = (task['nextRowOrder'] + task[rowOrder]) / 2;

      if (isDraggable) reOrderTasks(id, newOrder);
    },
  });

  const draggingStyle = () => {
    return {
      borderBottom: isDragging && isDraggable ? '2px solid #ababab' : 'unset',
    };
  };

  const selectedStyle = () => {
    return {
      backgroundColor: selected ? '#e4eaec' : 'unset',
    };
  };

  /**
   * Set state and dispatch action when task clicked.
   * @function handleTaskClick
   * @returns {void}
   */
  const handleTaskClick = () => {
    openDialog(resource, taskId, selectedTodoList, listResource);
    setSelectedTaskItem(taskId);
  };

  const handleCheckboxChange = name => event => {
    onChange(taskId, { [name]: event.target.checked });
  };

  useEffect(() => {
    if (task[isDone] !== taskIsDone) {
      setTaskIsDone(task[isDone]);
    }
    if (task[isImportant] !== taskIsImportant) {
      setTaskIsImportant(task[isImportant]);
    }
  }, [task]);

  const handleParseDate = date => {
    if (!date) {
      return;
    }
    return parseDate(date, translate);
  };

  return (
    <div
      className={classes.container}
      ref={ref}
      style={Object.assign(draggingStyle(), selected ? selectedStyle() : null)}
      data-test-task-createdate={task[createDate]}
    >
      <Checkbox
        value={isDone}
        checked={taskIsDone}
        onChange={handleCheckboxChange(isDone)}
        color="secondary"
        icon={<Icon>radio_button_unchecked</Icon>}
        checkedIcon={<Icon>check_circle</Icon>}
        data-test-task-item-isdone={taskIsDone}
      />
      <div
        className={classes.taskTitleContainer}
        onClick={handleTaskClick}
        data-test-task-item={taskTitle}
      >
        <Typography
          variant="caption"
          className={classes.taskTitle}
          data-test-task-item-name={taskTitle}
        >
          {taskTitle}
        </Typography>
        <div className={classes.itemContainer}>
          {task[isAddToMyDay] && (
            <div className={classes.item}>
              <i className={`${classes.itemIcon} ${getIconClassName('sunny')}`}></i>
              <Typography
                className={classes.itemTitle}
                color="secondary"
                variant="caption"
                data-test-task-item-myDay={taskTitle}
              >
                {translate('todo.myDay')}
              </Typography>
              <span className={classes.dott}>.</span>
            </div>
          )}
          {task[remindMeDate] && (
            <div className={classes.item}>
              <i className={`${classes.itemIcon} ${getIconClassName('ringer')}`}></i>
              <span className={classes.dott}>.</span>
            </div>
          )}
          {task[dueDate] && (
            <div className={classes.item}>
              <i
                className={`${classes.itemIcon} ${getIconClassName('calendar')}`}
              ></i>
              <Typography
                className={classes.itemTitle}
                color="secondary"
                variant="caption"
                data-test-task-item-dueDate={taskTitle}
              >
                {handleParseDate(task[dueDate])}
              </Typography>
              <span className={classes.dott}>.</span>
            </div>
          )}
          {task[repeatType] && (
            <div className={classes.item}>
              <i
                className={`${classes.itemIcon} ${getIconClassName(
                  'recurringEvent',
                )}`}
              ></i>
              <span className={classes.dott}>.</span>
            </div>
          )}
          {task[filePath] && (
            <div className={classes.item}>
              <i className={`${classes.itemIcon} ${getIconClassName('attach')}`}></i>
              <span className={classes.dott}>.</span>
            </div>
          )}
          {task[note] && (
            <div className={classes.item}>
              <i
                className={`${classes.itemIcon} ${getIconClassName('quickNote')}`}
              ></i>
            </div>
          )}
        </div>
      </div>
      {!isEmpty(task[assignUser]) && task[assignUser] != currentUserId && (
        <ProfileAvatar
          classes={{ avatar: classes.userAvatar }}
          userId={task[assignUser]}
        />
      )}
      <Checkbox
        value={isImportant}
        checked={taskIsImportant}
        data-test-task-important={taskIsImportant}
        onChange={handleCheckboxChange(isImportant)}
        className={classes.importantIcon}
        color="secondary"
        icon={<Icon>star_border</Icon>}
        checkedIcon={<Icon>star</Icon>}
        disableRipple
      />
    </div>
  );
};

TodoTaskItem.propTypes = {
  taskTitle: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  task: PropTypes.object.isRequired,
  taskId: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  selectedTodoList: PropTypes.object,
  listResource: PropTypes.string,
  selected: PropTypes.bool,
  setSelectedTaskItem: PropTypes.func,
};

const mapDispatchToProps = {
  openDialog: openDialogAction,
};

export default compose(translate, connect(null, mapDispatchToProps))(TodoTaskItem);
