import { FC } from 'react';
import { Table as MaterialTable, TableBody } from '@material-ui/core';

import { TableRow } from './table-row';
import { TableRowGrid } from './table-row-grid';
import { TableProps } from './table.type';

export const Table: FC<TableProps> = props => {
  const {
    group,
    className,
    currentTabIndex,
    inputInPuzzleForm,
    type,
    record,
    tab,
    recordIsEditable,
  } = props;
  const { layout } = group;

  return (
    <div>
      <MaterialTable className={className}>
        <TableBody>
          {layout.map((rowFields, index) => {
            return type === 'form' ? (
              <TableRow
                key={index}
                fields={rowFields}
                dynamicInputProps={{ currentTabIndex, inputInPuzzleForm }}
              />
            ) : (
              <TableRowGrid
                key={index}
                fields={rowFields}
                dynamicFieldProps={{
                  record: record!,
                  tab: tab!,
                  recordIsEditable: recordIsEditable!,
                }}
              />
            );
          })}
        </TableBody>
      </MaterialTable>
    </div>
  );
};
