import React from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { TextInput, useTranslate } from 'react-admin';
import { Dialog, DialogTitle } from '@material-ui/core';
import NewSubmittableForm from '../NewSubmittableForm';
import QuickCreateButtonToolbar from '../QuickCreateButtonToolbar';
import { isEmptyObject } from '../../helper/data-helper';

const FormLayoutFieldInfoDialog = props => {
  const { onChange, tabList, tabIndex, onCloseDialog, fieldSource } = props;
  const translate = useTranslate();

  if (!fieldSource) return;

  const [sourceGroupIndex, sourceRowIndex, sourceIndex] = fieldSource.split('/');

  const fieldInfo = lodashGet(tabList, [
    tabIndex,
    'groupList',
    sourceGroupIndex,
    'layout',
    sourceRowIndex,
    sourceIndex,
  ]);

  return (
    <Dialog open={!!fieldInfo} onClose={onCloseDialog}>
      <DialogTitle>{translate('form.editTabInfo')}</DialogTitle>
      <NewSubmittableForm
        save={values =>
          onChange(tabIndex, sourceGroupIndex, sourceRowIndex, sourceIndex, values)
        }
        record={fieldInfo}
        // FIXME: comment these lines because `QuickCreateButtonToolbar` interface changed. should be uncomment when refactor FormLayout.
        // toolbar={
        //   <QuickCreateButtonToolbar
        //     disableFullFormButton
        //     closeDialog={onCloseDialog}
        //   />
        // }
      >
        {!isEmptyObject(fieldInfo) &&
          !isEmptyObject(fieldInfo.translatedCaption) &&
          Object.keys(fieldInfo.translatedCaption).map(lang => (
            <TextInput
              key={lang}
              source={`translatedCaption.${lang}`}
              label={`form.translatedCaption.${lang}`}
            />
          ))}
      </NewSubmittableForm>
    </Dialog>
  );
};

FormLayoutFieldInfoDialog.propTypes = {
  tabList: PropTypes.array.isRequired,
  tabIndex: PropTypes.number,
  onCloseDialog: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FormLayoutFieldInfoDialog;
