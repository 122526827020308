import React, {
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  FC,
} from 'react';
import lodashGet from 'lodash/get';
import lodashDebounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslate, useInput } from 'react-admin';
import { Dropdown } from 'semantic-ui-react';
import { useIsMount } from '../../hooks/useIsMount';
import { convertDigitsToEnglish } from '../../helper/NumberHelper';
import { findOneAction as findDropdownDataAction } from '../../redux/dropdown/action';
import {
  getLabelForDropdownOption,
  findSelectedItemFromDropdownData,
  dropdownPreFilledName,
  triggerDropdownFetchData,
  getDropdownRequestParams,
} from '../../helper/DropdownHelper';
import { isEmpty } from '../../helper/data-helper';
import SearchPopupButton from '../../component/search-popup-old/SearchPopupButton-old';
import QuickCreateButton from '../../container/QuickCreateButton';
import { CustomTheme } from '../../core/themeProvider';
import { dummyFunc } from '../../helper/InputHelper';

type onChangeFunc = (
  event: SyntheticEvent<HTMLElement>,
  data: {
    value?: any;
  },
) => void;

type onSearchChangeFunc = (
  event: SyntheticEvent<HTMLElement>,
  data: {
    searchQuery: string;
  },
) => void;

interface DropdownMeta {
  moduleName: string;
  tableName: string;
  id: string | number;
  valueMember: string;
}

interface Record {
  isNewRecord: boolean;
}

interface FieldInterface {
  name: string;
  required: boolean;
}

interface DropdownInputInterface {
  allLoadedData: object[];
  dropdownData: object[];
  dropdownMeta: DropdownMeta;
  customChangeHandler: Function;
  label: string;
  loading: boolean;
  source: string;
  record: Record;
  field: FieldInterface;
  disableDropdownQuickCreate?: boolean;
  disableDropdownSearchPopup?: boolean;
  relationResource?: string;
  relationSource?: string;
  relationInfo?: object;
  changeFormValue: Function;
  addToRelationArray: Function;
  formData: object;
  visibleClass: string;
  disabled: boolean;
  dropdownInPuzzleForm?: boolean;
  style?: object;
  customError?: string | null;
  onBlur: Function;
  onFocus: Function;
  onChange: Function;
  options: object;
  step: string;
  textAlign: string;
  additionalProps: object;
  resource: string;
  isTodo: boolean;
  isService: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    position: 'relative',
    border: '1px solid #BDBDBD',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    paddingBottom: 0,
    paddingTop: 4,
    margin: 0,
    height: '100%',
    minHeight: 40,
    [theme.breakpoints.down('sm')]: {
      minHeight: 30,
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: 2,
    },
    '&[disabled]': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },

    '& .ui.fluid.dropdown': {
      border: 'none',
      borderRadius: 0,
      background: 'none',
      padding: '14px 0 0',
      minHeight: '-webkit-fill-available',
      boxShadow: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      flexBasis: '90%',
    },

    '& .ui.selection.active.dropdown:hover': {
      border: 'none',
      boxShadow: 'none',
    },

    '& input': {
      fontFamily: theme.typography.fontFamily,
      padding: '2px 0 !important',
      height: '100%',
    },

    '& .dropdown.icon': {
      color: theme.palette.primary.appPrimaryDisableIconColor,
      padding: '2px 5px !important',
    },

    '& .ui.loading.selection.dropdown>i.icon': {
      padding: '8px 10px !important',
      zIndex: 1,
    },

    '& .ui.dropdown .menu': {
      top: '95%',
      [theme.breakpoints.down('sm')]: {
        top: '100%',
      },
    },

    '& .ui.selection.active.dropdown .menu': {
      border: `1px solid ${theme.palette.primary.appPrimaryDividerColor}`,
    },

    '& .ui.selection.active.dropdown:hover .menu': {
      border: `1px solid ${theme.palette.primary.appPrimaryDividerColor}`,
    },

    '& .ui.selection.dropdown .menu>.item': {
      fontSize: 13,
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },

    '& .ui.search.dropdown>.text': {
      fontSize: 13,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      flexBasis: '90%',
      [theme.breakpoints.up('md')]: {
        top: '-2px',
      },
      [theme.breakpoints.down('md')]: {
        top: '-8px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },

    '& .ui.selection.dropdown .menu': {
      width: 'max-content',
      minWidth: 'auto',
    },

    '& .ui.upward.dropdown>.menu': {
      bottom: 'auto',
    },

    '& .ui.fluid.dropdown>.dropdown.icon': {
      float: 'none',
      top: '20px',
      [theme.breakpoints.down('md')]: {
        top: 15,
      },
    },
  },

  legend: {
    fontSize: 10,
    lineHeight: 0,
    color: theme.palette.primary.appSecondaryTextColor,
    display: 'block',
    padding: '0 3px',

    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
    },
  },

  fieldsetError: {
    border: `1px solid ${theme.palette.error.main}`,
  },

  legendError: {
    color: theme.palette.error.main,
  },

  dropdownContainer: {
    height: '100%',
    alignItems: 'center',
    '& span': {
      cursor: 'pointer',
      zIndex: 1,
    },

    [theme.breakpoints.down('sm')]: {
      height: 25,
    },

    display: 'flex',
    margin: 0,
    justifyContent: 'space-between',
    flexBasis: '100%',

    position: 'absolute',
    width: '95%',
  },

  errorText: {
    position: 'absolute',
    right: 20,
    top: 10,
    width: 100,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 8,
    zIndex: 0,
    [theme.breakpoints.down('lg')]: {
      right: 20,
    },
    [theme.breakpoints.down('md')]: {
      right: 80,
    },
    [theme.breakpoints.down('sm')]: {
      top: 3,
      right: 40,
    },
    [theme.breakpoints.down('xs')]: {
      top: 3,
      right: 10,
    },
  },

  label: {
    fontSize: 13,
    color: theme.palette.primary.appSecondaryTextColor,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },

  dropdownInPuzzleForm: {
    margin: '7px 3px 0',
  },
  hideLegend: {
    width: 'auto',
    padding: 0,
    maxWidth: '0.01px',
    visibility: 'hidden',
  },
}));

const DropdownInput: FC<DropdownInputInterface> = props => {
  const {
    allLoadedData,
    dropdownData,
    dropdownMeta,
    customChangeHandler = dummyFunc,
    label,
    loading,
    source,
    record,
    field,
    disableDropdownQuickCreate,
    disableDropdownSearchPopup,
    relationResource,
    relationSource,
    relationInfo,
    changeFormValue,
    addToRelationArray,
    formData,
    visibleClass,
    disabled,
    dropdownInPuzzleForm,
    style,
    customError,
    additionalProps,
    resource,
    isTodo,
    isService,
  } = props;

  const { valueMember } = dropdownMeta;
  const translate = useTranslate();
  const classes = useStyles(props);

  // use this custom hook to understand if it is first time mounted or not
  const isMount: boolean = useIsMount();

  const dropdownCustomParams = {
    fieldName: field.name,
    dropId: dropdownMeta.id,
    dropResource: resource,
  };

  const [params, setParams] = useState(
    getDropdownRequestParams({
      dropdownMeta,
      record: formData,
    }),
  );

  const {
    input: { onChange, onBlur, onFocus, value },
    meta: { touched, error },
  } = useInput(props);
  const isRequired = field.required;

  useEffect(() => {
    const isDropdownParameters = !!lodashGet(
      props,
      'dropdownMeta.parameters.length',
    );

    const preFilledName = dropdownPreFilledName(field);

    // already have data for this
    if (
      loading ||
      !record ||
      (dropdownData && dropdownData.length) ||
      (record && !record[source] && isDropdownParameters) ||
      (!isEmpty(value) && !isEmpty(record[preFilledName]))
    ) {
      // if it's a new record, and we already have this dropdown's value, means we have not set mapped fields
      if (record && record.isNewRecord) {
        const dropdownItem = allLoadedData.find(item => {
          // keep loose comparison, because server might give number inside string
          // eslint-disable-next-line eqeqeq
          return item[valueMember] == value;
        });

        if (dropdownItem) {
          customChangeHandler(value, dropdownItem, true);
        }
      }

      return;
    }
  }, []);

  useEffect(() => {
    const selectedItem = findSelectedItemFromDropdownData({
      dropdownMeta,
      dataArray: allLoadedData,
      record,
      value,
      field,
    });
    // if we have a selected value, and no item can be found with this data
    if (!isEmpty(value)) {
      if (!selectedItem) {
        triggerDropdownFetchData(props, value, dropdownCustomParams);
      } else {
        !isMount &&
          customChangeHandler(lodashGet(selectedItem, 'value', null), selectedItem);
      }
    }
  }, [value]);

  const handleBlur = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      const { onBlur: onBlurProps = dummyFunc } = props;
      onBlur(event);
      onBlurProps(event);
    },
    [props.onBlur, onBlur],
  );

  const handleFocus = useCallback(
    (event: SyntheticEvent) => {
      const { onFocus: onFocusProps = dummyFunc } = props;
      onFocus(event);
      onFocusProps(event);

      const newParams = getDropdownRequestParams({
        dropdownMeta,
        record: formData,
      });

      if (!dropdownData || !dropdownData.length || newParams !== params) {
        triggerDropdownFetchData(props, '', dropdownCustomParams);
        setParams(newParams);
      }
    },
    [props.onFocus, onFocus, formData, params, additionalProps],
  );

  const handleChange: onChangeFunc = useCallback(
    (event, { value: newValue }) => {
      const userInput = convertDigitsToEnglish(newValue);
      const { onChange: onChangeProps = dummyFunc } = props;

      onChange(userInput);
      onChangeProps(userInput);
    },
    [props.onChange, onChange],
  );

  const handleSearchChange: onSearchChangeFunc = useCallback(
    lodashDebounce((event, { searchQuery }) => {
      triggerDropdownFetchData(props, searchQuery, dropdownCustomParams);
    }, 700),
    [props.formData],
  );

  const hasError = !!(touched && error);

  const preparedOptions = useMemo(
    () =>
      dropdownData.map((item, index) => ({
        text: getLabelForDropdownOption(dropdownMeta, item),
        value: item[valueMember] + '',
        key: item[valueMember] + '' + index,
      })),
    [dropdownData],
  );

  const selectedValueLabel = useMemo(() => {
    // if we have any data from api, use that first!
    const dropItem = findSelectedItemFromDropdownData({
      dropdownMeta,
      dataArray: allLoadedData,
      record,
      value,
      field,
    });

    if (dropItem) {
      return dropItem.label;
    }

    return value;
  }, [value, allLoadedData.length]);

  const getSelectRef = dropManager => {
    const parentGetRef = lodashGet(props, 'options.inputRef');
    if (
      !dropManager ||
      !parentGetRef ||
      !lodashGet(dropManager, 'searchRef.current')
    ) {
      return;
    }

    parentGetRef(lodashGet(dropManager, 'searchRef.current'));
  };

  const onCreate = useCallback(
    newRecord => {
      const dropdownNewInsertedValue = convertDigitsToEnglish(
        newRecord[valueMember],
      );
      const { onChange: onChangeProps = dummyFunc } = props;
      onChange(dropdownNewInsertedValue);
      onChangeProps(dropdownNewInsertedValue);
    },
    [props.onChange, onChange],
  );

  const { moduleName, tableName, id } = dropdownMeta;
  const dropResource = `${moduleName}/${tableName}`;

  const dropdownText = !isEmpty(selectedValueLabel)
    ? selectedValueLabel
    : isRequired
    ? `${label} *`
    : label;

  const handleSearch = useCallback(options => options, []);

  const onKeyDown = lodashGet(props, 'options.onKeyDown');
  const searchInputParams = useMemo(
    () => ({
      onKeyDown,
    }),
    [onKeyDown],
  );

  return (
    <fieldset
      className={`${visibleClass} ${classes.root} ${
        !!customError || hasError ? classes.fieldsetError : ''
      } ${dropdownInPuzzleForm ? classes.dropdownInPuzzleForm : null}`}
      data-test-field-name={source}
      data-test-dropdown-selected-id={value}
      disabled={disabled}
      data-test-is-dropdown-required={isRequired}
      data-test-has-error={!!customError || hasError}
      style={style ? style : undefined}
    >
      <legend
        className={`${classes.legend} ${
          selectedValueLabel && label && !isEmpty(label) ? '' : classes.hideLegend
        } ${!!customError || hasError ? classes.legendError : ''}`}
      >
        {label}
        {isRequired ? '*' : ''}
      </legend>
      <div className={classes.dropdownContainer}>
        <Dropdown
          data-test-dropdown-search-input
          fluid
          selection
          clearable
          closeOnBlur
          labeled
          error={!!customError || hasError}
          selectOnNavigation={false}
          ref={getSelectRef}
          noResultsMessage={translate('ra.navigation.no_results')}
          text={dropdownText}
          loading={loading}
          open={undefined}
          value={`${value}`}
          options={preparedOptions}
          onChange={handleChange}
          onSearchChange={handleSearchChange}
          selectOnBlur={false}
          onBlur={handleBlur}
          onFocus={handleFocus}
          searchInput={searchInputParams}
          search={handleSearch}
          disabled={disabled}
        />

        {!disabled &&
          !disableDropdownQuickCreate &&
          !isEmpty(moduleName) &&
          !isEmpty(tableName) && (
            <QuickCreateButton
              resource={dropResource}
              dropdownId={id}
              dropdownMeta={dropdownMeta}
              source={source}
              onCreate={onCreate}
              disabled={disabled || loading}
            />
          )}

        {!disabled &&
          !disableDropdownSearchPopup &&
          !isEmpty(moduleName) &&
          !isEmpty(tableName) && (
            <SearchPopupButton
              resource={dropResource}
              parentResource={resource}
              source={source}
              dropdownId={id}
              dropdownMeta={dropdownMeta}
              record={record}
              relationResource={relationResource}
              relationSource={relationSource}
              relationInfo={relationInfo}
              disabled={disabled || loading}
              changeFormValue={changeFormValue}
              addToRelationArray={addToRelationArray}
              formData={formData}
              additionalProps={additionalProps}
              fieldName={field.name}
              dropdownInPuzzleForm={dropdownInPuzzleForm}
              isTodo={isTodo}
              isService={isService}
            />
          )}
      </div>

      {(!!customError || hasError) && (
        <Typography className={classes.errorText} variant="caption" color="error">
          {customError ? customError : translate(lodashGet(error, 'message', error))}
        </Typography>
      )}
    </fieldset>
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-function

DropdownInput.defaultProps = {
  onFocus: dummyFunc,
  onBlur: dummyFunc,
  onChange: dummyFunc,
  customChangeHandler: dummyFunc,
  options: {},
  step: 'any',
  textAlign: 'right',
};

const defaultEmptyArray = [];

const mapStateToProps = (state, props) => {
  const dropdownId = lodashGet(props, 'dropdownMeta.id');

  return {
    loading: !!lodashGet(state, ['dropdown', dropdownId + '_loading']), // loading status of data
    dropdownData: lodashGet(state, ['dropdown', dropdownId], defaultEmptyArray), // app dropdown data object
    allLoadedData: lodashGet(
      state,
      ['dropdown', dropdownId + '_all'],
      defaultEmptyArray,
    ), // all previously loaded data
    dropdownError: lodashGet(state, ['dropdown', dropdownId + '_error']), // app dropdown data object
  };
};

const mapDispatchToProps = {
  findDropdownData: findDropdownDataAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownInput);
