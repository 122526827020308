import { FC, memo, useEffect, useState } from 'react';
import { FieldType } from '../../../helper/Types';
import {
  actorDispatch,
  actorGetActionValue,
  FormKeyMode,
} from '../../../type/actor-setup';
import { InputRefContent } from '../../form';
import { updateInputsUiSpecs } from '../../form/form-with-tabs/form-with-tabs.helper';
import { updateInputsState } from './mail-form.helper';

import { MailFormInterface } from './mail-form.type';
import MailFormView from './mail-form.view';

const MailFormController: FC<MailFormInterface> = memo(props => {
  const {
    fields,
    formContainerClass,
    inputContainerClass,
    showBCCTextButton = true,
    ...rest
  } = props;
  const [isBCCInputActive, setIsBCCInputActive] = useState<boolean>(false);

  /**
   * @function handleActiveBCCInput
   * @returns { void }
   */
  const handleActiveBCCInput = (): void => {
    const currentFormData = actorGetActionValue(
      'formData',
      `automation/messages.${FormKeyMode.ROOT}`,
    );
    actorDispatch(
      'formData',
      { ...currentFormData, bccinputisactive: true },
      {
        path: `automation/messages.${FormKeyMode.ROOT}`,
      },
    );
    setIsBCCInputActive(true);
  };

  useEffect(() => {
    setTimeout(() => {
      const inputsRef = actorGetActionValue(
        'inputsRef',
        `automation/messages.${FormKeyMode.ROOT}`,
      ) as Record<string, InputRefContent> | undefined;

      const formData = actorGetActionValue('formData', [
        'automation/messages',
        FormKeyMode.ROOT,
      ]) as Record<string, unknown>;
      updateInputsState(inputsRef, formData);
      if (inputsRef) updateInputsUiSpecs(inputsRef, fields as FieldType[]);
    }, 50);
  }, []);

  return (
    <MailFormView
      {...rest}
      fields={fields}
      formContainerClass={formContainerClass}
      inputContainerClass={inputContainerClass}
      handleActiveBCCInput={handleActiveBCCInput}
      isBCCInputActive={isBCCInputActive}
      showBCCTextButton={showBCCTextButton}
    />
  );
});

export default MailFormController;
