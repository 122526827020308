import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  icon: {
    margin: '0 8px',
    fontSize: 17,
    cursor: 'pointer',
  },
}));
