import { debounce } from '@material-ui/core';
import Dropdown from '@SamianSoft/dropdown';
import { FC, useState, memo, useEffect, useCallback } from 'react';
import { useTranslate } from 'react-admin';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../../../type/actor-setup';
import { FormActions } from '../../form';
import starkString from 'starkstring';
import { useDispatch } from 'react-redux';
import { findAllAction as findAllMenuAction } from '../../../redux/menu/action';
import { findOneAction as getProfileAction } from '../../../redux/profile/action';
import { DelegationDropdownInterface } from './delegation-dropdown.type';
import { DropdownMeta } from '../dropdown-input';

const DelegationDropdownController: FC<DelegationDropdownInterface> = memo(props => {
  const { formActionsHandler, field, label, inputMessage, value, hint } = props;

  const translate = useTranslate();
  const reduxDispatch = useDispatch();

  const delegationData = actorGetActionValue('delegationData');

  const [dropdownData, setDropdownData] = useState<{
    items: Record<string, unknown>[];
    totalItemsCount: number;
  }>({ items: [], totalItemsCount: 0 });
  const [selectedItem, setSelectedItem] = useState<Record<string, unknown>>({});

  const { required, name, dropdown: dropdownMeta, disabled } = field;
  const { valueMember, displayMember } = dropdownMeta;

  useEffect(() => {
    actorOnDispatch('delegationData', _delegationData => {
      updateDropdownData({ DATA: _delegationData, TOTAL: _delegationData?.length });
    });
    updateDropdownData({ DATA: delegationData, TOTAL: delegationData?.length });
  }, []);

  useEffect(() => {
    if (delegationData?.length) {
      const item = {
        [valueMember as string]: value,
        [displayMember as string]:
          delegationData?.find(item => item.path === value)?.[
            displayMember as string
          ] ?? value,
      };
      setSelectedItem(item);
    }
  }, [value]);

  /**
   * it will trigger on input change in form controller and send request to path
   * @function dropdownOnChange
   * @param {Record<string, unknown>}
   * @returns {void} void
   */
  const dropdownOnChange = (value: Record<string, unknown> | null): void => {
    // use `isEmpty` function to handle dropdown items with value `0` or ''
    const computedValue = (value as Record<string, unknown>)?.['path'] ?? null;

    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value: computedValue,
    });

    actorDispatch('putPathDelegation', { resource: computedValue, successCallback });
  };

  /**
   * this function get menu and profile after select delegation
   * @function successCallback
   * @return {void}
   */
  const successCallback = useCallback(() => {
    reduxDispatch(findAllMenuAction());
    reduxDispatch(getProfileAction());
  }, []);

  /**
   * update dropdown data state
   * @function updateDropdownData
   * @param {object} data
   * @returns {void} void
   */
  const updateDropdownData = (data: {
    DATA: Record<string, unknown>[] | null;
    TOTAL?: number | undefined;
  }): void => {
    const { DATA = [], TOTAL = null } = data;

    setDropdownData({
      items: DATA ?? [],
      totalItemsCount: Number(TOTAL),
    });
  };
  /**
   * this function is locally search in dropdown data by debounce
   * @function handleOnSearch
   * @param {string} value
   * @return {void}
   */
  const handleOnSearch = debounce((value: string) => {
    if (value.length > 2) {
      const searchedData =
        delegationData?.filter(item =>
          starkString(item.caption)
            .persianChar()
            .toString()
            .includes(starkString(value).persianChar().toString()),
        ) ?? [];
      updateDropdownData({ DATA: searchedData, TOTAL: searchedData?.length });
    } else {
      fetchDropdownDataHandler();
    }
  }, 700);

  /**
   * this function get dropdown data by request
   * @function fetchDropdownData
   * @return {void}
   */
  const fetchDropdownDataHandler = () => {
    actorDispatch('getDelegationData', null);
  };

  return (
    <Dropdown
      dropdownMeta={dropdownMeta as DropdownMeta}
      dropdownData={dropdownData.items}
      value={selectedItem}
      onChange={dropdownOnChange}
      fetchDropdownData={fetchDropdownDataHandler}
      fetchMoreDropdownData={() => {}}
      onSearch={handleOnSearch}
      isLoading={false}
      label={label}
      hint={hint}
      inputMessage={inputMessage}
      searchPlaceholder={translate('dropdown.searchPlaceholder')}
      noneLabel={translate('dropdown.noneLabel')}
      required={required}
      disabled={disabled}
      name={name}
      noDataFoundLabel={translate('dropdown.noOptionsMessage')}
      hasMore={false}
      className={''}
    />
  );
});

export default DelegationDropdownController;
