import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

import TodoMenuSidebarListView from './TodoMenuSidebarListView';
import { getTodoListAction } from '../../redux/todoList/action';
import { getTodoListPatternInfo } from '../../helper/PatternMetaHelper';
import MetaProvider from '../../container/MetaProvider';

const TodoMenuSidebar = props => {
  const {
    resource,
    detailResource,
    getTodoList,
    listData,
    todoListSelectedId,
    todoListSelectedFilter,
    loadedOnes,
    listLoading,
    createLoading,
    userSort,
  } = props;

  const todoListPattern = getTodoListPatternInfo(resource);
  const { reportResource, sort, pagination } = todoListPattern;

  useEffect(() => {
    if (!loadedOnes) {
      getTodoList(resource, reportResource, sort, pagination);
    }
  }, []);

  return (
    <MetaProvider resourceName={resource}>
      <TodoMenuSidebarListView
        data={listData}
        todoListPattern={todoListPattern}
        resource={resource}
        detailResource={detailResource}
        todoListSelectedFilter={todoListSelectedFilter}
        todoListSelectedId={parseInt(todoListSelectedId)}
        listLoading={listLoading}
        createLoading={createLoading}
        userSort={userSort}
      />
    </MetaProvider>
  );
};

const mapStateToProps = (state, { resource }) => ({
  createLoading: lodashGet(state, ['todoList', 'createLoading']),
  listLoading: !!lodashGet(state, ['todoList', 'loadingList', resource]),
  loadedOnes: !!lodashGet(state, ['todoList', 'loadedOnes', resource]),
  listData: lodashGet(state, ['todoList', 'resultList', resource]),
  listError: lodashGet(state, ['todoList', 'errorList', resource]),
});

const mapDispatchToProps = {
  getTodoList: getTodoListAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TodoMenuSidebar);
