import {
  TextField,
  Icon,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import {
  CONFIG_LIST_COLUMN_CHOICE,
  DEFAULT,
  getValue,
  IS_ADMIN_USER,
  USER_ID,
} from '../../../core/configProvider';
import { useTranslate } from 'react-admin';
import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import SortableItemController from '../../sortable-item';
import { setAppSettings } from '../../../helper/settings-helper';
import { FieldType } from '../../../helper/Types';
import { arrayMove } from 'react-sortable-hoc';
import lodashGet from 'lodash/get';
import lodashFindLastIndex from 'lodash/findLastIndex';
import { SortableContainer as reactSortableContainer } from 'react-sortable-hoc';
import { actorDispatch } from '../../../type/actor-setup';
import { useStyles } from './grid-column-selection-dialog.style';
import { isEmpty } from '../../../helper/data-helper';
import lodashDebounce from 'lodash/debounce';

const SortableContainer = reactSortableContainer(({ classes, children }) => {
  return (
    <ul
      className={classes.sortableItem}
      data-style-content-sortable="contentSortable"
    >
      {children}
    </ul>
  );
});

export const GridColumnsSelectionDialog = (props): ReactElement => {
  const { dialogData, closeDialogHandler } = props;
  const {
    fieldList: originalFieldList,
    resource,
    locale,
    settings,
  } = dialogData.data;

  const { defaultSelected, userSelected } = settings;

  const checkedFieldsRef = useRef<FieldType[]>([]);
  const checkedFieldsInSearchRef = useRef<FieldType[]>([]);
  const [fieldList, setFieldList] = useState<FieldType[]>(originalFieldList ?? []);
  const [searchColumnWord, setSearchColumnWord] = useState('');

  const classes = useStyles();
  const translate = useTranslate();

  const isAdmin = getValue(IS_ADMIN_USER);

  useMemo(() => {
    if (userSelected.length > 0) {
      for (const selectedItem of userSelected) {
        const targeIndex = fieldList.findIndex(
          field => field.id === selectedItem || field.name === selectedItem,
        );

        if (targeIndex > -1) {
          checkedFieldsRef.current.push(fieldList[targeIndex]);
        }
      }
    } else if (defaultSelected.length > 0) {
      for (const selectedItem of defaultSelected) {
        const targeIndex = fieldList.findIndex(
          field => field.id === selectedItem || field.name === selectedItem,
        );

        if (targeIndex > -1) {
          checkedFieldsRef.current.push(fieldList[targeIndex]);
        }
      }
    }
  }, []);

  const prepareFieldsToSave = useCallback(() => {
    /**
     * PAY ATTENTION
     * In some cases in `grid`s,
     *   some `field`s does not have `id` key and instead of it we will save `name` in its `field` to settings
     *   then here we have to find those `name`s in `field`s
     */

    const finalCheckedFields = Array.from(
      new Set([...checkedFieldsRef.current, ...checkedFieldsInSearchRef.current]),
    );
    return finalCheckedFields.map(field => field.id ?? field.name);
  }, [fieldList]);

  const saveSetting = (isDefault = false) => {
    const userId = getValue(USER_ID);

    const prefix = isDefault ? DEFAULT : userId;

    setAppSettings({
      key: prefix + '_' + CONFIG_LIST_COLUMN_CHOICE + '_' + resource,
      value: prepareFieldsToSave(),
      onSuccess: () => {
        actorDispatch('refreshView', resource);
        checkedFieldsInSearchRef.current = [];
      },
    });

    requestAnimationFrame(() => {
      closeDialogHandler();
    });
  };

  const handleChangeAll = event => {
    const isChecked = event.target.checked;

    if (isEmpty(searchColumnWord)) {
      const updatedFieldList = fieldList.map(item => {
        item.isChecked = isChecked;
        return item;
      });

      setFieldList(updatedFieldList);

      if (isChecked) {
        checkedFieldsRef.current = updatedFieldList;
      } else {
        checkedFieldsRef.current = [];
      }
    } else {
      finalFieldList.map(item => {
        item.isChecked = isChecked;
        return item;
      });

      if (!isChecked) {
        for (let index = 0; index < finalFieldList.length; index++) {
          const targetIndex = checkedFieldsRef.current.findIndex(
            item => item.name === finalFieldList[index].name,
          );
          if (targetIndex > -1) {
            checkedFieldsRef.current.splice(targetIndex, 1);
          }
        }
      } else {
        checkedFieldsRef.current.push(...finalFieldList);
      }

      setFieldList(Array.from(new Set([...fieldList, ...finalFieldList])));
    }
  };

  /**
   * receives 2 indexes and swap them in `fieldList`
   * @function onSortEnd
   * @param {Number} oldIndex
   * @param {Number} newIndex
   * @returns {Array}
   */
  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setFieldList(prevFieldList => {
      const newFieldList: FieldType[] = arrayMove(prevFieldList, oldIndex, newIndex);

      const lastChecked = lodashFindLastIndex(prevFieldList, {
        isChecked: true,
      });

      // if new checked item is between checked, make sure every item between is checked
      if (lastChecked && lastChecked >= newIndex) {
        for (let i = 0; i <= lastChecked; i++) {
          newFieldList[i].isChecked = true;
        }
      }

      if (isEmpty(searchColumnWord)) {
        checkedFieldsRef.current = newFieldList.filter(item => item.isChecked);
      } else {
        checkedFieldsInSearchRef.current = newFieldList.filter(
          item => item.isChecked,
        );
      }

      return newFieldList;
    });
  }, []);

  /**
   * handles changes of selection
   * @function changeHandler
   * @param { Event } event
   * @param { FieldType } field
   * @returns a function
   */
  const changeHandler = field => event => {
    const fieldIndex = fieldList.findIndex(item => item.name === field.name);

    fieldList[fieldIndex].isChecked = event.target.checked;

    checkedFieldsRef.current = fieldList.filter(item => item && item.isChecked);
    const notCheckedFields = fieldList.filter(item => item && !item.isChecked);

    setFieldList([
      // move checked fields up
      ...checkedFieldsRef.current,
      // then comes the rest of the fields
      ...notCheckedFields,
    ]);

    const lastScrollPosition =
      document.getElementById('dialog_content')?.scrollTop ?? 0;

    requestAnimationFrame(() => {
      document
        .getElementById('dialog_content')
        ?.scrollTo({ top: lastScrollPosition });
    });
  };

  const finalFieldList = useMemo(() => {
    if (isEmpty(searchColumnWord)) return fieldList;

    const filteredFieldList = fieldList.filter(fieldElement => {
      if (!fieldElement) {
        return false;
      }

      const isMatch1 =
        lodashGet(
          fieldElement,
          ['translatedCaption', locale],
          lodashGet(fieldElement, 'caption'),
        ).indexOf(searchColumnWord) > -1;

      const isMatch2 =
        lodashGet(fieldElement, 'name').toString().indexOf(searchColumnWord) > -1;

      return isMatch1 || isMatch2;
    });

    checkedFieldsInSearchRef.current = filteredFieldList.filter(
      item => item.isChecked,
    );

    return filteredFieldList;
  }, [fieldList, searchColumnWord]);

  const handleChangeSearch = lodashDebounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchColumnWord(event.target.value);
    },
    700,
  );

  const DialogCustomContent = () => {
    if (fieldList.length === 0) {
      return (
        <>
          <DialogContent>
            <Typography>
              {translate('listColumnChoice.noColumnsToSelect')}
            </Typography>
          </DialogContent>
        </>
      );
    }
    return (
      <>
        <DialogContent id="dialog_content">
          <Typography>
            {translate('listColumnChoice.selectColumnsThatYouWantToShow')}
          </Typography>
          <div className={classes.information}>
            <Icon fontSize="small">info</Icon>
            <Typography variant="caption">
              {userSelected && userSelected.length
                ? translate('listColumnChoice.currentChoicesAreByUser')
                : translate('listColumnChoice.currentChoicesAreByDefault')}
            </Typography>
          </div>
          <TextField
            className={classes.searchColumn}
            placeholder={translate('ra.action.search')}
            autoFocus
            onChange={handleChangeSearch}
            defaultValue={searchColumnWord}
            id="searchTextField"
          />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    (checkedFieldsRef.current.length > 0 &&
                      checkedFieldsRef.current.length === fieldList.length) ||
                    (checkedFieldsInSearchRef.current.length > 0 &&
                      checkedFieldsInSearchRef.current.length ===
                        finalFieldList.length)
                  }
                  onChange={handleChangeAll}
                  id="changeAllCheckbox"
                />
              }
              label={translate('listColumnChoice.changeAll')}
            />
          </div>
          {/* @ts-ignore */}
          <SortableContainer onSortEnd={onSortEnd} classes={classes}>
            {finalFieldList.map((field, index) => (
              <SortableItemController
                key={`item-${index}`}
                field={field}
                locale={locale}
                checked={!!field.isChecked}
                index={index}
                onChange={changeHandler(field)}
                sortDisabled={!isEmpty(searchColumnWord)}
              />
            ))}
          </SortableContainer>
        </DialogContent>
        <DialogActions data-style-dialog-sortable="dialogSortable">
          <Button
            variant="contained"
            color="primary"
            onClick={() => saveSetting(false)}
            id="SaveButton"
          >
            {translate('ra.action.save')}
          </Button>
          {isAdmin && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => saveSetting(true)}
              id="DefaultSaveButton"
            >
              {translate('form.saveAsDefault')}
            </Button>
          )}
          <Button onClick={closeDialogHandler}>
            {translate('ra.action.cancel')}
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <>
      <DialogTitle id={`list-column-choice-dialog-${resource}`}>
        {translate('listColumnChoice.selectColumns')}
      </DialogTitle>
      <DialogCustomContent />
    </>
  );
};
