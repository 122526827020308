import { GET_LIST } from 'react-admin';

import dataProvider, { GET_META } from '../core/dataProvider';
import { actorGetActionValue, actorSetActionValue } from '../type/actor-setup';
import { showNotification } from './general-function-helper';
import { getDefaultReportSort } from './MetaHelper';
import { PrintPopupOpener } from './PrintMetaHelper';

import type { TranslatedTitle } from '../component/form';
import type { MetaData } from './Types';

interface PrintInterface {
  // in meta
  id?: string;
  reportId?: string;
  title?: string;
  translatedTitle?: TranslatedTitle;

  // in action output
  printUniqueId?: string;
  report?: string;
  withPreview?: boolean;
}

/**
 * it will create a new promise and pass resolve and reject functions to get meta in meta context
 * then execute one of them when `getMeta` function completed (success or failure)
 * @function getPrintMeta
 * @param {Object} print
 * @returns {Promise<Object>}
 */
const getPrintMeta = async (printResource: string): Promise<void | string> => {
  return new Promise((resolve, reject) => {
    const prevMetaData = actorGetActionValue('metaData')?.[printResource];

    if (prevMetaData) resolve();

    dataProvider(GET_META, printResource, {})
      .then((metaData: [MetaData]) => {
        if (!metaData?.[0]) reject('meta data not found');
        actorSetActionValue('metaData', metaData[0], {
          path: printResource,
        });

        resolve();
      })
      .catch((error: string) => {
        reject(error);
      });
  });
};

/**
 * get print data and open print popup
 * @function getPrintData
 * @param { PrintInterface } print
 * @param { string | string[] } filters
 * @returns {Promise<void>}
 */
const getPrintData = async (
  print: PrintInterface,
  filters: (string | string[])[],
): Promise<void> => {
  const printResource = `print/${print.id ?? print.printUniqueId}`;
  const printMetaData = actorGetActionValue('metaData', printResource);
  const { translate, locale } = actorGetActionValue('reactAdminHelpers')!;

  const sort = getDefaultReportSort(printMetaData, print.reportId);

  const response = await dataProvider(
    GET_LIST,
    print.reportId
      ? `report/${print.reportId}` // call from print button
      : print.report?.charAt(0) === '/' // call from action output
      ? print.report.substring(1)
      : print.report,
    {
      // filter: filters, // 👉 it may change later
      sort: sort,
      pagination: { page: 1, perPage: 10000 }, // only change pagination
      queryParams: {
        putCaptionInHeader: true,
      },
      // server might give back an object and break everything!
      rawResponse: true,
    },
  );

  const opener = new PrintPopupOpener();
  opener.setTranslate(translate);
  opener.openPrintInNewWindow(
    print?.translatedTitle?.[locale] ?? print.id ?? print.printUniqueId,
    printMetaData,
    response?.data,
  );
};

/**
 * get print meta data then call get print data function
 * @function openPrintPreview
 * @param { PrintInterface } print
 * @param { string | string[] } filters
 * @returns {void}
 */
export const openPrintPreview = (
  print: PrintInterface,
  filters: (string | string[])[],
): void => {
  const printResource = `print/${print.id ?? print.printUniqueId}`;

  getPrintMeta(printResource)
    .then(() => {
      getPrintData(print, filters);
    })
    .catch(printMetaError => {
      showNotification(printMetaError, 'error', { forceSnackbar: true });
    });
};
