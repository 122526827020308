import { ReferencesDataInterface } from '../../..';
import lodashFind from 'lodash/find';
import { isEmptyObject } from '../../../../../helper/data-helper';

/**
 * to find parent item for diagram tree
 * @function findRoot
 * @param { ReferencesDataInterface[] } list
 * @returns { ReferencesDataInterface }
 */
export const findRoot = (
  list: ReferencesDataInterface[],
): ReferencesDataInterface => {
  return lodashFind(list, (item: ReferencesDataInterface) => !item.fromreference_id);
};

/**
 * to find children of an diagram item
 * @param { ReferencesDataInterface[] } list
 * @param { number } targetId
 * @returns { ReferencesDataInterface[] | null }
 */
export const findChildrenItems = (
  list: ReferencesDataInterface[],
  targetId: number,
): ReferencesDataInterface[] | null => {
  if (isEmptyObject(list)) {
    return null;
  }

  return list?.filter(
    (item: ReferencesDataInterface) => item.fromreference_id === targetId,
  );
};
