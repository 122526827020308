import { FC, memo } from 'react';
import {
  MailActions,
  MailInterface,
  OnChangeCheckedMailsParams,
  OnShowDetailParams,
} from '../..';
import { MailListItemInterface } from './mail-list-item.type';
import MailListItemView from './mail-list-item.view';

const MailListItemController: FC<MailListItemInterface> = memo(props => {
  const {
    mailActionsHandler,
    mail,
    onStarClick,
    onImportantClick,
    checkedMails,
    isEven,
  } = props;

  /**
   * @function onChangeCheckedMails
   * @param { MailInterface } mail
   * @returns { void }
   */
  const onChangeCheckedMails = (mail: MailInterface): void => {
    mailActionsHandler(MailActions.onChangeCheckedMails, {
      mail,
    } as OnChangeCheckedMailsParams);
  };

  /**
   * @function onShowDetail
   * @param { MailInterface } mail
   * @returns { void }
   */
  const onShowDetail = (
    mail: MailInterface,
    event?: React.MouseEvent<HTMLElement>,
  ): void => {
    mailActionsHandler(MailActions.onShowDetail, {
      mail,
      event,
    } as OnShowDetailParams);
  };

  return (
    <MailListItemView
      mailActionsHandler={mailActionsHandler}
      mail={mail}
      isRead={Boolean(!mail.isunread)}
      isEven={isEven}
      onStarClick={onStarClick}
      onImportantClick={onImportantClick}
      onShowDetail={onShowDetail}
      checkedMails={checkedMails}
      onChangeCheckedMails={onChangeCheckedMails}
    />
  );
});

export default MailListItemController;
