import { ReactElement } from 'react';
import { useLocale } from 'react-admin';
import momentJalaali from 'moment-jalaali';
import { DiagramCardInterface } from './diagram-card.type';
import { useStyles } from './diagram-card.style';
import { Avatar, Box, Tooltip, Typography } from '@material-ui/core';
import DoubleTickIcon from '../../../../../../icon/DoubleTickIcon';
import TickIcon from '../../../../../../icon/TickIcon';
import { isEmpty } from '../../../../../../helper/data-helper';
import {
  getValue,
  SESSION_ID,
  API_URL,
  API_VERSION,
} from '../../../../../../core/configProvider';
import {
  gregorianDateTimeFormat,
  jalaliDateTimeFormat,
} from '../../../../../../helper/CalendarMetaHelper';

const DiagramCardView = (props: DiagramCardInterface): ReactElement => {
  const { item } = props;
  const classes = useStyles({ colorcode: item.colorcode });
  const locale = useLocale();
  momentJalaali.locale(locale);

  const sessionId = getValue(SESSION_ID);
  const apiUrl = getValue(API_URL);
  const apiVersion = getValue(API_VERSION);

  return (
    <Box className={classes.cardContainer}>
      <Box className={classes.cardColorBox} />

      <Box ml={1} flexDirection="column" className={classes.contentContainer}>
        <Box ml={1} display="flex" flexDirection="row-reverse" alignItems="center">
          <Avatar
            src={`${apiUrl}/oauth2/${sessionId}/${apiVersion}${item?.topersonimage}`}
            alt={item?.tocontactinfo}
            className={classes.avatar}
          />
          <Box
            className={classes.infoContainer}
            display="flex"
            flexDirection="column"
            ml={1.5}
          >
            <Box component="p" fontSize={14} fontWeight="bold">
              {item?.tocontactinfo}
            </Box>
            <Box component="p" fontSize={12}>
              {locale === 'fa'
                ? momentJalaali(item?.createdate).format(jalaliDateTimeFormat)
                : momentJalaali(item?.createdate).format(gregorianDateTimeFormat)}
            </Box>
            <Tooltip title={item.messageparaph} arrow>
              <Box
                style={{ direction: locale === 'en' ? 'ltr' : 'rtl' }}
                component="span"
                fontSize={11}
                fontWeight="bold"
                className={classes.truncate}
              >
                {item.messageparaph?.length > 100
                  ? `${item.messageparaph.substring(0, 100)} ...`
                  : item.messageparaph}
              </Box>
            </Tooltip>
          </Box>
        </Box>

        {!isEmpty(item?.fromcontactinfo) && (
          <Typography className={classes.fromContactInfoText}>
            {item.fromcontactinfo}
          </Typography>
        )}
      </Box>
      <div className={classes.tick}>
        {!isEmpty(item?.seendate) ? <DoubleTickIcon /> : <TickIcon />}
      </div>
    </Box>
  );
};

export default DiagramCardView;
