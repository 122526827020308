import { ReactElement } from 'react';
import lodashGet from 'lodash/get';

import { SortableItemInterface } from './sortable-item.type';
import { SortableElement as sortableElement } from 'react-sortable-hoc';
import { useStyles } from './sortable-item.style';
import { Checkbox } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const SortableItemView = (props: SortableItemInterface): ReactElement => {
  const { field, locale, checked, onChange, index, sortDisabled } = props;
  const classes = useStyles();

  const title: string = lodashGet(
    field,
    ['translatedCaption', locale],
    lodashGet(field, 'caption'),
  );

  const UnsortableItem = () => (
    <li
      className={classes.dragHandelListItem}
      data-test-is-checked={!!field.isChecked}
      data-test-item-name={title}
      key={`sortable-item-key-${field.id}-${field.name}`}
      data-style-item-unSortable="itemUnSortable"
    >
      <Checkbox
        checked={checked}
        onChange={onChange}
        value="checkedB"
        color="primary"
        data-style-checkbox-unsortable="checkboxUnsortable"
      />
      <div>{title}</div>

      <div className={classes.widthContainer}></div>
    </li>
  );

  const SortableItem = sortableElement(() => (
    <li
      className={classes.dragHandelListItem}
      data-test-is-checked={!!field.isChecked}
      data-test-item-name={title}
      key={`sortable-item-key-${field.id}-${field.name}`}
      data-style-item-sortable="itemSortable"
    >
      <MenuIcon style={{ cursor: 'pointer', margin: 10 }} />
      <Checkbox
        checked={checked}
        onChange={onChange}
        value="checkedB"
        color="primary"
        data-style-checkbox-sortable="checkboxSortable"
      />
      <div>{title}</div>

      <div className={classes.widthContainer}></div>
    </li>
  ));

  return sortDisabled ? (
    <UnsortableItem key={`item-${title}`} />
  ) : (
    <SortableItem key={`item-${title}`} index={index ?? 0} />
  );
};

export default SortableItemView;
