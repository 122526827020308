import { ReactElement } from 'react';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import Badge from '@material-ui/core/Badge';
import clsx from 'classnames';

import { ChatItemViewInterface } from './chat-item.type';
import { useStyles } from './chat-item.style';
import {
  API_URL,
  API_VERSION,
  getValue,
  SESSION_ID,
} from '../../../../../core/configProvider';

const ChatItemView = (props: ChatItemViewInterface): ReactElement => {
  const { selectedUser, item, handleOnUserSelect } = props;

  const classes = useStyles();
  const sessionId = getValue(SESSION_ID);
  const apiUrl = getValue(API_URL);
  const apiVersion = getValue(API_VERSION);

  return (
    <ListItem
      button
      className={clsx(classes.listItemRoot, 'item-hover', {
        active: selectedUser && selectedUser.personinfo_id === item.personinfo_id,
      })}
      onClick={handleOnUserSelect}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width={1}
      >
        <Box display="flex" alignItems="center" justifyContent="center" id="boxChat">
          <Box>
            <ListItemAvatar
              className={classes.avatarRoot}
              data-style-avatar-chat="avatarChat"
            >
              <>
                <Avatar
                  className={classes.avatar}
                  src={`${apiUrl}/oauth2/${sessionId}/${apiVersion}${item?.personimage}`}
                />
                {/*

                //TODO: implement userStatus later
                <Box
                  className={classes.userStatus}
                  // bgcolor={item?.status === 'online' ? green[600] : red[600]}
                  bgcolor={green[600]}
                /> */}
              </>
            </ListItemAvatar>
          </Box>
          <Box
            component="span"
            fontWeight={300}
            display="block"
            ml={1}
            fontSize={13}
            data-style-person-chat="personChat"
          >
            {item?.personname}
          </Box>
        </Box>
        <Box
          component="span"
          fontWeight={300}
          display="block"
          fontSize={12}
          data-style-date-chat="dateChat"
        >
          {moment(item?.chatdate).startOf('second').fromNow()}
        </Box>
      </Box>

      <Box className={classes.userInfoRoot} mt={1}>
        <Box
          id={`chatId-${item?.chat_id}`}
          className={classes.truncate}
          component="p"
          color="text.secondary"
          display="block"
          fontSize={12}
          data-style-desc-chat="descChat"
        >
          {item?.chattext}
        </Box>
        <Box component="span" color="text.secondary" display="block">
          <Badge
            classes={{ badge: classes.customBadge }}
            badgeContent={item?.sumnotseen >= 0 ? item?.sumnotseen : 0}
            max={10000}
          ></Badge>
        </Box>
      </Box>
    </ListItem>
  );
};

export default ChatItemView;
