import { makeStyles } from '@material-ui/core';
import { StyleProps } from '.';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  container: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    position: 'relative',
    height: '100%',
  },
  sidebarBg: {
    backgroundColor: ({ backgroundColor }) =>
      backgroundColor ? backgroundColor : '#fff',
  },
  scrollAppSidebar: {
    paddingTop: 8,
    paddingBottom: 20,
    height: '80vh !important',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 3,
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-track-piece': {
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#fff',
    },
    '&:hover': {
      '&::-webkit-scrollbar': {
        backgroundColor: 'initial',
      },
      '&::-webkit-scrollbar-track-piece': {
        backgroundColor: 'initial',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0, .3)',
      },
    },

    [theme.breakpoints.up('xl')]: {
      height: '100%',
    },
  },
}));
