import lodashFind from 'lodash/find';
import lodashGet from 'lodash/get';
import moment from 'moment';
import { FC, memo, MouseEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FilterInterface } from '.';
import { SERVER_DATE_TIME_FORMAT } from '../../core/configProvider';
import { convertDateToBigCalenderReadableFormat } from '../../helper/data-helper';
import jalaliLocalizer from '../../helper/localizers-jalaali-calendar/jalali-moment';
import { getServices } from '../../helper/MetaHelper';
import { CalendarConfigFromMetaDataInterface } from '../../helper/Types';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../../type/actor-setup';
import { CalendarEventInterface } from '../big-calendar';
import { SelectedService } from '../form';
import { ReportCalendarInterface } from './report-calendar.type';
import ReportCalendarView from './report-calendar.view';

const ReportCalendarController: FC<ReportCalendarInterface> = memo(props => {
  const { current: currentResource } = actorGetActionValue('resources')!;
  const { metaData } = props;

  const calendarConfigForMetaData: Partial<CalendarConfigFromMetaDataInterface> =
    metaData?.calendarConfig ?? {};
  const services = getServices(metaData);
  const visibleServices = services?.filter(
    service =>
      service?.uniqueId !== calendarConfigForMetaData?.validationService &&
      service?.uniqueId !== calendarConfigForMetaData?.creationService,
  );
  const reportId = currentResource.value.split('/')[1];
  const displayedFiltersObject =
    (metaData?.parameters
      ?.filter(parameter => !parameter.isHidden)
      .reduce(
        (initialObject, currentParameter) => ({
          ...initialObject,
          [currentParameter.key]: true,
        }),
        {},
      ) as Record<string, unknown>) ?? {};

  const history = useHistory();

  const searchParamsFromUrl = new URLSearchParams(history?.location.search);

  //extraction date and type parameter from url and convert to object
  const {
    date: urlDate,
    type: urlType,
    ...restUrl
  } = (searchParamsFromUrl.has('type') && searchParamsFromUrl.has('date')
    ? Object.fromEntries(
        [...searchParamsFromUrl.entries()].map(urlParameters =>
          urlParameters[1].includes(',')
            ? [urlParameters[0], urlParameters[1].split(',')]
            : urlParameters,
        ),
      )
    : {}) as unknown as FilterInterface;

  const [filtersForServerRequest, setFiltersForServerRequest] =
    useState<FilterInterface>({
      date: urlDate ?? new Date().toISOString(),
      type: urlType ?? 'month',
      ...restUrl,
    });
  const [anchorElForServiceMenu, setAnchorElForServiceMenu] =
    useState<EventTarget>();
  const [selectCalendarEvent, setSelectCalendarEvent] =
    useState<CalendarEventInterface>();
  const [calendarEvents, setCalendarEvents] = useState<CalendarEventInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openMenuInResponsiveMode, setOpenMenuInResponsiveMode] =
    useState<boolean>(false);
  const [calendarEventsForFilterSection, setCalendarEventsForFilterSection] =
    useState<CalendarEventInterface[]>([]);

  useEffect(() => {
    actorOnDispatch('loading', detail => {
      setIsLoading(detail?.[currentResource.value as string]);
    });
  }, []);

  useEffect(() => {
    getCalendarData();

    const search = Object.entries(filtersForServerRequest).reduce(
      (accumulatorSearchString, currentFilterList) =>
        accumulatorSearchString + `${currentFilterList[0]}=${currentFilterList[1]}&`,
      '',
    );
    if (history?.location.search !== '?embed=true') {
      history.replace({
        search,
      });
    }
  }, [filtersForServerRequest]);

  /**
   * this function add filters to state
   *@function addFilter
   * @param {Record<string, string | []>} newFilter
   * @returns {void}
   */
  const addFilter = (newFilter: Record<string, string | []>): void => {
    setFiltersForServerRequest(filter => ({ ...filter, ...newFilter }));
  };

  /**
   * this function get calendar data form server
   * @function getCalendarData
   * @returns {void}
   */
  const getCalendarData = (): void => {
    const { date, type, ...rest } = filtersForServerRequest;
    const _visibleDays: Date[] = jalaliLocalizer.visibleDays(new Date(date));
    const fromDate = moment(_visibleDays[0]).format(SERVER_DATE_TIME_FORMAT);
    const toDate = moment(_visibleDays[_visibleDays.length - 1]).format(
      SERVER_DATE_TIME_FORMAT,
    );
    actorDispatch('getCalendarData', {
      successCallback: successCallbackGetCalendarData,
      reportId,
      params: {
        fromDate,
        toDate,
        ...rest,
      },
    });
  };

  /**
   * this function set event form api
   *@function successCallbackGetCalendarData
   * @param {Record<string,unknown>} response
   * @returns {void}
   */
  const successCallbackGetCalendarData = (response: Record<string, unknown>) => {
    const { showEvent, fromDate, toDate, eventColor, link, image, primary } =
      calendarConfigForMetaData;
    const data = response.data as [];
    const newEvents = data?.map((item: Record<string, unknown>) => ({
      id: lodashGet(item, primary),
      title: lodashGet(item, showEvent),
      allDay: false,
      start: convertDateToBigCalenderReadableFormat(lodashGet(item, fromDate)),
      end: convertDateToBigCalenderReadableFormat(lodashGet(item, toDate)),
      hexColor: `#${lodashGet(item, eventColor)}`,
      link: lodashGet(item, link),
      image: lodashGet(item, image),
      ...item,
    }));
    setCalendarEvents(newEvents);
    setCalendarEventsForFilterSection(newEvents);
  };

  /**
   * this callback for change range date in calendar
   *@function onRangeChangeCallback
   * @param {Date} date
   * @param {string} type
   * @param {string}position
   * @returns {void}
   */
  const onRangeChangeCallback = (
    date: Date,
    type: string,
    position: string,
  ): void => {
    const stringDate = date?.toISOString();
    addFilter({ date: stringDate, type });
  };

  /**
   * this function handle type of big calendar view
   *@function onViewCallback
   * @param {string} type
   * @returns {void}
   */
  const onViewCallback = (type: string): void => {
    addFilter({ type });
  };

  /**
   * this function handle update event
   *@function updateCalendarEventCallback
   * @param {CalendarEventInterface} updatedEvent
   * @param {void} failureCallback
   * @returns {Function}
   */
  const updateCalendarEventCallback = (
    updatedEvent: CalendarEventInterface,
    failureCallback: () => void,
  ) => {
    const { validationService, primary } = calendarConfigForMetaData;
    actorDispatch('runActionsService', {
      actionUniqueId: validationService,
      params: {
        fromdate: moment(updatedEvent.start as Date).format(SERVER_DATE_TIME_FORMAT),
        todate: moment(updatedEvent.end as Date).format(SERVER_DATE_TIME_FORMAT),
        [primary ?? '']: updatedEvent?.[primary ?? ''],
      },
      failureCallback: () => {
        failureCallback?.();
      },
    });
  };

  /**
   * this function handle click in calendar slot
   *@function onSelectSlotCallback
   * @param { Omit<CalendarEventInterface, "allDay">} calendarEvent
   * @returns {void}
   */
  const onSelectSlotCallback = (calendarEvent: CalendarEventInterface): void => {
    const { creationService } = calendarConfigForMetaData;
    const service = metaData.actions?.find(
      action => (action as SelectedService).uniqueId === creationService,
    );
    actorDispatch('runServiceDirectly', {
      service: service,
      serviceParams: {
        fromdate: moment(calendarEvent?.start).format(SERVER_DATE_TIME_FORMAT),
        todate: calendarEvent?.allDay
          ? moment(calendarEvent?.end)
              .add(-1, 'days')
              .format(SERVER_DATE_TIME_FORMAT)
          : moment(calendarEvent?.end).format(SERVER_DATE_TIME_FORMAT),
      },
    });
  };

  /**
   * this function handle right click in event on calendar
   *@function onContextMenuEvent
   * @param {MouseEvent<HTMLDivElement>} eventHtmlElement
   * @param {CalendarEventInterface} calendarEvent
   * @returns {void}
   */
  const onContextMenuEvent = (
    eventHtmlElement: MouseEvent<HTMLDivElement>,
    calendarEvent: CalendarEventInterface,
  ): void => {
    eventHtmlElement.preventDefault();
    setSelectCalendarEvent(calendarEvent);
    setAnchorElForServiceMenu(eventHtmlElement.currentTarget);
  };

  /**
   * empty state callback form service dialog
   * @function customEmptyState
   * @returns {void}
   */
  const customEmptyState = (): void => {
    setAnchorElForServiceMenu(undefined);
  };

  const summaryCalendarEvent = calendarConfigForMetaData?.summaryColumns?.map(
    item => {
      return lodashFind(metaData?.tabsColumns?.table, {
        name: item,
      });
    },
  );

  return (
    <ReportCalendarView
      openMenuInResponsiveMode={openMenuInResponsiveMode}
      metaData={metaData}
      setOpenMenuInResponsiveMode={setOpenMenuInResponsiveMode}
      addFilter={addFilter}
      filters={filtersForServerRequest}
      calendarEvents={calendarEvents}
      setCalendarEvents={setCalendarEvents}
      isLoading={isLoading}
      resource={currentResource.value}
      onRangeChangeCallback={onRangeChangeCallback}
      updateCalendarEventCallback={updateCalendarEventCallback}
      onSelectSlotCallback={onSelectSlotCallback}
      anchorEl={anchorElForServiceMenu}
      selectedCalendarEvent={selectCalendarEvent}
      eventCustomProps={{
        summaryCalendarEvent,
        onContextMenuEvent,
      }}
      visibleServices={visibleServices}
      calendarEventsForFilterSection={calendarEventsForFilterSection}
      getCalendarData={getCalendarData}
      customEmptyState={customEmptyState}
      onViewCallback={onViewCallback}
      listFilterProps={{
        displayedFilters: displayedFiltersObject,
        filterValues: filtersForServerRequest,
        setFilters: addFilter,
      }}
    />
  );
});

export default ReportCalendarController;
