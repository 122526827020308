import { useEffect, useState } from 'react';

import { UseGetFormDefaultValues } from '../form-with-tabs';
import { getFormDefaultValues } from '../form.helper';

export const useGetFormDefaultValues = (
  params: UseGetFormDefaultValues,
): {
  formDefaultData: Record<string, unknown> | null;
  errorMessage: string | null;
} => {
  const [formDefaultData, setFormDefaultData] = useState<Record<
    string,
    unknown
  > | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {
    allFields,
    globalParameters,
    isCreateMode,
    resource,
    urlInfo,
    parentInfo,
    isLoading,
  } = params;

  useEffect(() => {
    if (!isLoading) return;

    getFormDefaultValues(
      urlInfo,
      parentInfo,
      allFields,
      globalParameters,
      resource,
      isCreateMode,
    )
      .then(data => {
        setFormDefaultData(data);
      })
      .catch(error => {
        setErrorMessage(error);
      });
  }, [isLoading]);

  return { formDefaultData, errorMessage };
};
