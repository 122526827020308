import { ReactElement, useMemo } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import InputBase from '../../input-base';
import { TimeInputViewInterface } from './time-input.type';
import { useStyles } from './time-input.style';

const TimeInputView = (props: TimeInputViewInterface): ReactElement => {
  const {
    field,
    label,
    hint,
    inputMessage,
    value,
    clearInput,
    handleChange,
    disabled,
    source,
    visibleClass,
    customTestAttribute,
    getRef,
  } = props;
  const classes = useStyles();

  const inputPropsParams = useMemo(
    (): object => ({
      classes: {
        root: classes.outlineRoot,
        notchedOutline: classes.notchedOutline,
        error: classes.error,
        input: classes.input,
        disabled: classes.disabled,
      },
    }),
    [],
  );

  return (
    <InputBase
      className={`${visibleClass}`}
      required={field.required}
      field={field}
      inputMessage={inputMessage}
      label={label}
      hint={hint}
    >
      <TextField
        {...customTestAttribute}
        margin="normal"
        classes={{
          root: classes.root,
        }}
        ref={getRef}
        InputProps={{
          ...inputPropsParams,
          endAdornment: field.required ? null : (
            <InputAdornment position="end">
              <IconButton
                className={classes.iconButton}
                onClick={clearInput}
                edge="end"
                disabled={disabled}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{
          'data-test-input-name': source,
          maxLength: field.maxLength,
        }}
        onChange={handleChange}
        variant="outlined"
        disabled={disabled}
        value={value}
        data-test-has-error={inputMessage?.messageType === 'error'}
        data-test-has-warning={inputMessage?.messageType === 'warning'}
      />
    </InputBase>
  );
};

export default TimeInputView;
