import { FC } from 'react';
import { useLocale } from 'react-admin';

import useWidth from '../../useWidth';
import { useStyles } from './table-row-grid.style';
import { TableRowGridProps } from './table-row-grid.type';
import DynamicField from '../../../container/DynamicField';
import { shouldHideField } from './table-row-grid.helper';

export const TableRowGrid: FC<TableRowGridProps> = props => {
  const { key, fields, dynamicFieldProps } = props;
  const { record, tab, recordIsEditable } = dynamicFieldProps;

  const classes = useStyles();
  const width = useWidth();
  const locale = useLocale();

  return (
    <tr key={key}>
      {fields.map((field, index) => {
        if (shouldHideField(field, width)) {
          return (
            <td key={index} className={`${classes.emptyTableCell} displayNone`} />
          );
        } else if (field && field !== 'empty' && !field.hidden) {
          return (
            <td
              key={index}
              rowSpan={field.rowSpan ? field.rowSpan : 1}
              colSpan={field.colSpan ? field.colSpan : 1}
              className={classes.tableCell}
              data-test-td-name={field.name}
            >
              <DynamicField
                customLabel
                key={field.id}
                source={field?.relatedName ?? field.name}
                field={field}
                label={field?.['translatedCaption']?.[locale] ?? field.caption}
                record={record}
                resource={tab.resource}
                basePath={`/${tab.resource}`}
                hasEdit={recordIsEditable}
              />
            </td>
          );
        }
        return null;
      })}
    </tr>
  );
};
