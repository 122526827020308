import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';

import CartShowList from '../component/shop/CartShowList';
import ListContainerApiSimpleController from '../container/ListContainerApiSimpleController';
import { getSellinBasketPattern } from '../helper/PatternMetaHelper';
import { API_URL, getValue } from '../core/configProvider';
import { removeBasketItemAction, setBasketItemAction } from '../redux/shop/action';
import ConfirmDialogHOC from '../container/ConfirmDialogHOC';
import CheckResourceReady from '../container/CheckResourceReady';

const ShowCartPage = props => {
  const { resource, setBasketItem, removeBasketItem, openConfirmDialog } = props;
  const translate = useTranslate();
  const pattern = getSellinBasketPattern(resource);

  const onCountChange = (product, count) => {
    setBasketItem({
      resource: pattern.setBasketResource,
      data: {
        ...product,
        [pattern.count]: count,
      },
      parentResource: resource,
      defaultSort: pattern.defaultSort,
    });
  };

  const onDelete = id => {
    openConfirmDialog({
      content: translate('ra.message.delete_content'),
      onConfirm: () =>
        removeBasketItem({
          resource: pattern.removeBasketResource,
          id,
          parentResource: resource,
          defaultSort: pattern.defaultSort,
        }),
    });
  };

  return (
    <CheckResourceReady resourceName={resource}>
      <ListContainerApiSimpleController
        {...props}
        basePath={`/${resource}`}
        page={1}
        perPage={1000}
        resource={resource}
        sort={pattern.defaultSort}
      >
        {params => (
          <CartShowList
            {...params}
            pattern={pattern}
            apiUrl={getValue(API_URL)}
            onCountChange={onCountChange}
            onDelete={onDelete}
          />
        )}
      </ListContainerApiSimpleController>
    </CheckResourceReady>
  );
};

ShowCartPage.propTypes = {};

const mapStateToProps = (state, { match }) => {
  const { moduleName, moduleTableName } = match.params;
  const resource = `${moduleName}/${moduleTableName}`;

  return {
    resource,
  };
};

const mapDispatchToProps = {
  setBasketItem: setBasketItemAction,
  removeBasketItem: removeBasketItemAction,
};

export default compose(
  ConfirmDialogHOC,
  connect(mapStateToProps, mapDispatchToProps),
)(ShowCartPage);
