import { ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import clsx from 'classnames';
import { AppDrawerInterface } from './app-drawer.type';
import { useStyles } from './app-drawer.style';
import { NavigationContainer } from './navigation-container';

const AppDrawerView = (props: AppDrawerInterface): ReactElement => {
  const { routesConfig, baseUrl, sidebarClasses, backgroundColor, ...rest } = props;
  const classes = useStyles({ backgroundColor });

  return (
    <Box
      {...rest}
      height="100%"
      className={clsx(classes.container, 'app-sidebar')}
      data-style-drawer="drawer"
    >
      <Box className={clsx(classes.sidebarBg, sidebarClasses)}>
        <Box className={classes.scrollAppSidebar}>
          <NavigationContainer routesConfig={routesConfig} baseUrl={baseUrl} />
        </Box>
      </Box>
    </Box>
  );
};

export default AppDrawerView;
