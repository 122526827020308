import React, { useEffect, useState } from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { useInput } from 'react-admin';
import { withStyles, TextField } from '@material-ui/core';
import OkIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Warning';
import BadTextIcon from '@material-ui/icons/Block';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

import { clone, isEmpty } from '../../helper/data-helper';
import { findOneAction as findDropdownDataAction } from '../../redux/dropdown/action';
import { isEnterPressed } from '../../helper/FormHelper';
import { refreshPuzzlePageGridAction } from '../../redux/puzzlePage/action';
import { showNotification } from '../../helper/general-function-helper';

const styles = theme => ({
  searchInputContainer: {
    display: 'inline-flex',
    '&[disabled] > div': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },
  },

  icon: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },

  error: {
    '& + p': {
      position: 'absolute',
      right: 0,
    },
  },

  input: {
    '&[disabled]': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },
  },
});

const SearchInput = props => {
  const {
    searchDialogMeta,
    style,
    classes,
    readonly,
    disabled,
    options,
    visibleClass,
    customError,
    field,
    ...rest
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isSearchedOnce, setIsSearchedOnce] = useState(false);
  const [hasError, setHasError] = useState(null);
  const [internalValue, setInternalValue] = useState(null);
  const [internalText, setInternalText] = useState('');
  const [, setMapNull] = useState(null);
  const [isTrueValue, setIsTrueValue] = useState(false);
  const [myRef, setMyRef] = useState(null);

  const {
    input: { onChange, onBlur, onFocus, value },
    meta: { touched, error },
  } = useInput(props);

  useEffect(() => {
    // const oldValue = lodashGet(this.props, 'input.value');
    // const newValue = lodashGet(nextProps, 'input.value');
    const oldValue = internalValue;
    const newValue = value;

    if (
      (oldValue && !newValue) ||
      (!oldValue && newValue) ||
      oldValue !== newValue
    ) {
      setIsLoading(false);
      setIsSearchedOnce(false);
      setHasError(null);
      setInternalValue(newValue);
      setInternalText(newValue);
      setIsTrueValue(!!newValue);
      setMapNull(null);
    }
  }, [value]);

  const setNullMapArray = response => {
    const clonedResponse = clone(response);
    Object.keys(clonedResponse).forEach(item => {
      clonedResponse[item] = null;
    });

    return clonedResponse;
  };

  /**
   * this function will receive the value of input & then trim it and add parameters
   * from form data (if not exist use record instead), compute request params and
   * mapped parameters and add it top request, handle loading and call findDropdownData
   * function to start fetching data, then update the form component.
   * @function getResult
   * @param {string} value
   * @returns {void}
   */
  const getResult = value => {
    // TODO: props should be destruct in top of component
    const {
      searchDialogMeta,
      record = {},
      findDropdownData,
      customChangeHandler,
      triggerGoToNext,
      onChange: parentOnChange,
      refreshPuzzlePageGrid,
      formData = {},
    } = props;

    const {
      id,
      parameters: dropdownRequiredParameters,
      valueMember,
      displayMember,
    } = searchDialogMeta;

    setIsSearchedOnce(true);
    setIsTrueValue(false);

    if (isEmpty(value)) {
      setInternalValue(null);
      return;
    }

    setIsLoading(true);
    setHasError(null);

    const params = { skip: 0, takeCount: 100, search: value.trim() };
    let hasParams = false;
    const parameters = {};
    dropdownRequiredParameters.forEach(item => {
      if (!item) {
        return;
      }

      parameters[item.to] = lodashGet(
        formData,
        item.from,
        lodashGet(record, item.from),
      );
      hasParams = true;
    });

    if (hasParams) {
      params.parameters = JSON.stringify(parameters);
    }

    findDropdownData(
      { id, params, meta: searchDialogMeta },
      (error, serverResponse) => {
        refreshPuzzlePageGrid();

        // api has given something, even if our display/value member is not present
        // means ok response
        const response = lodashGet(serverResponse, 'result.0', null);
        if (response && serverResponse.messageType !== 'error') {
          const isCurrentTextAsValue = typeof response[valueMember] === 'undefined';
          const responseId = isCurrentTextAsValue
            ? internalText
            : response[valueMember];

          let responseTitle = '';
          // if current text is supposed to be used as value, then it's also our title
          if (isCurrentTextAsValue) {
            responseTitle = internalText;
          }
          // value member must be filled for us to consider display member
          else if (!isEmpty(response[valueMember])) {
            responseTitle = response[displayMember];
          }

          setIsLoading(false);
          setInternalValue(responseId);
          setInternalText(responseTitle);
          setIsTrueValue(!responseId); // only consider having id to be truthy
          setMapNull(setNullMapArray(response));
          setHasError(null);

          setTimeout(() => {
            onChange(responseId);
            parentOnChange(responseId);
            customChangeHandler(responseId, response);

            // if we ok response, we should go to next input
            if (isCurrentTextAsValue || !isEmpty(response[valueMember])) {
              setTimeout(triggerGoToNext, 10);
            } else {
              myRef.focus();
            }
          }, 10);
        } else if (error || (!response && serverResponse.messageType === 'ok')) {
          showNotification(
            lodashGet(
              error,
              ['response', 'data', 'userMessage'],
              error ? error.toString() : 'ra.navigation.no_results',
            ),
            'error',
          );

          if (myRef && myRef.select) {
            // bring focus back
            myRef.select();
          }

          setIsLoading(false);
          setHasError(error ? error.toString() : null);
          setInternalValue(null);
          setInternalText(null);
          setIsTrueValue(false);
        } else {
          setIsLoading(false);
          setHasError(null);
          setInternalValue(null);
          setInternalText(null);
          setIsTrueValue(false);
        }
      },
    );
  };

  const metaIsMissing = () => {
    if (!searchDialogMeta || !Object.keys(searchDialogMeta).length) {
      return true;
    }

    return false;
  };

  const getIcon = () => {
    if (metaIsMissing()) {
      return <ErrorIcon />;
    }

    if (isLoading) {
      return <CircularProgress size={10} />;
    }
    if (hasError) {
      return <ErrorIcon className={classes.icon} />;
    }
    if (isTrueValue) {
      return <OkIcon className={classes.icon} />;
    }
    if (isSearchedOnce && !internalValue && internalText) {
      return <BadTextIcon className={classes.icon} />;
    }

    return <div />;
  };

  const handleKeyDown = event => {
    if (isEnterPressed(event)) {
      event.preventDefault();
      event.stopPropagation();

      getResult(internalText);
    }
  };

  const handleChange = event => setInternalText(event.target.value);

  const handleBlur = eventOrValue => {
    props.onBlur(eventOrValue);
    onBlur(eventOrValue);
  };

  const handleFocus = event => {
    props.onFocus(event);
    onFocus(event);
  };

  const handleClick = () => {
    if (myRef) {
      myRef.select();
    }
  };

  const getRef = ref => {
    const parentGetRef = lodashGet(props, 'options.inputRef');
    setMyRef(ref);

    if (typeof parentGetRef === 'function') {
      parentGetRef(ref);
    }
  };

  const hasValidationError = !!(touched && error);

  return (
    <div
      className={`${visibleClass} ${classes.searchInputContainer}`}
      disabled={disabled || isLoading}
      style={style}
    >
      <TextField
        {...rest}
        {...options}
        margin="normal"
        variant="outlined"
        error={!!customError || hasValidationError}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
        helperText={
          customError ? customError : hasValidationError ? error : undefined
        }
        value={internalText || ''}
        required={field.required}
        disabled={disabled || isLoading}
        InputProps={{
          endAdornment: (
            <InputAdornment style={{ margin: 0 }} position="end">
              {getIcon()}
            </InputAdornment>
          ),
          readOnly: metaIsMissing() ? true : readonly,
          classes: {
            error: classes.error,
            input: classes.input,
          },
        }}
        inputProps={{
          disabled: disabled || isLoading,
          'data-test-search-input-name': props.source,
        }}
        inputRef={getRef}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </div>
  );
};

SearchInput.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  formName: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  customError: PropTypes.string,
};

const emptyFunction = () => {};

SearchInput.defaultProps = {
  onChange: emptyFunction,
  onBlur: emptyFunction,
  onFocus: emptyFunction,
  customChangeHandler: emptyFunction,
};

const mapDispatchToProps = {
  findDropdownData: findDropdownDataAction,
  refreshPuzzlePageGrid: refreshPuzzlePageGridAction,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(null, mapDispatchToProps),
)(SearchInput);
