import { makeStyles } from '@material-ui/core';
import { CustomTheme, themeParams } from '../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  avatar: {
    width: 50,
    height: 50,
  },
  dateBox: {
    color: theme.palette.primary.appSecondaryTextColor,
  },
  contactInfo: {
    color: themeParams.palette.mail.primaryTextColor,
  },
}));
