import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({});

function DailyIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M6.9997 10.0001h1v4h-4v-4h3zm-2 3h2v-2h-2v2zM11 10h3v4h-4v-4h1zm0 3h2v-2h-2v2zm-1-8.9998h4v4L10 8V4.0002zm1 3h2v-2h-2v2zM4 16h4v4H4v-4zm1 3h2v-2H5v2zm5-3h4v4h-4v-4zm1 3h2v-2h-2v2zm5-9h4.001v4H16v-4zm1 3h2.001v-2H17v2zm-1.001-9H20v4h-4.001V4zm1 3H19V5h-2.001v2z"
      ></path>
    </SvgIcon>
  );
}

export default withStyles(styles)(DailyIcon);
