import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import lodashGet from 'lodash/get';
import { crudDelete as crudDeleteAction, translate } from 'react-admin';
import { connect } from 'react-redux';

import LazyReferenceManyField from '../../container/admin/LazyReferenceManyField';
import TodoTaskFileView from './TodoTaskFileView';
import ConfirmDialogHOC from '../../container/ConfirmDialogHOC';
import { showNotification } from '../../helper/general-function-helper';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const TodoTaskFileContainer = props => {
  const {
    task,
    resource,
    fileMeta,
    openConfirmDialog,
    translate,
    crudDelete,
    onChange,
    ...rest
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const fileDataPath = `${fileMeta.moduleName}/${fileMeta.moduleTableName}/${fileMeta.childFieldName}`;
  const fileResource = `${fileMeta.moduleName}/${fileMeta.moduleTableName}`;
  const attachmentArr = lodashGet(task, fileDataPath, []);

  // const fileStorageResource = `${resource}/${taskId}/${fileResource}`;

  const handleDeleteFile = fileId => {
    openConfirmDialog({
      content: translate('todo.areYouSureYouWantToDeleteThisFile'),
      onConfirm: deleteFile(fileId),
      color: theme.palette.error.main,
    });
  };

  const deleteFile = fileId => () => {
    try {
      crudDelete(fileResource, fileId, {}, null, false);
      // give a little timeout for delete request to complete, and then refresh
      setTimeout(() => {
        onChange();
      }, 100);
    } catch (error) {
      showNotification(error.message, 'warning');
    }
  };

  return (
    <div className={classes.container} data-test-file-container>
      <LazyReferenceManyField
        {...rest}
        basePath={`/${resource}`}
        resource={resource}
        record={task}
        addLabel={false}
        reference={fileResource}
        target={fileMeta.childFieldName}
        parentFieldName={fileMeta.parentFieldName}
        initialData={attachmentArr.Data}
        initialCount={attachmentArr.TotalCount}
        sort={{ field: 'createdate', order: 'ASC' }}
        isCompactMode
        showPagination
      >
        <TodoTaskFileView deleteFile={handleDeleteFile} />
      </LazyReferenceManyField>
    </div>
  );
};

TodoTaskFileContainer.propTypes = {
  resource: PropTypes.string.isRequired,
  fileMeta: PropTypes.object.isRequired,
  taskId: PropTypes.number.isRequired,
  task: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

const mapDispatchToProps = {
  crudDelete: crudDeleteAction,
};

export default compose(
  ConfirmDialogHOC,
  translate,
  connect(null, mapDispatchToProps),
)(TodoTaskFileContainer);
