import dataProvider, { GET_REPORT } from '../core/dataProvider';
import { actorOnDispatch } from '../type/actor-setup';

actorOnDispatch(
  'getPivotReport',
  async action => {
    const { params, successCallback, failureCallback } = action;

    try {
      const response = await dataProvider(GET_REPORT, '', params);
      successCallback({ data: response?.data ?? {}, total: response.total });
    } catch (error: unknown) {
      failureCallback(
        typeof error === 'string'
          ? (error as string)
          : (error as Record<string, unknown>).toString(),
      );
    }
  },
  {
    preserve: false,
  },
);
