import React, { ReactElement } from 'react';
import { LongtextInputViewInterface } from './longtext-input.type';
import { useStyles } from './longtext-input.style';
import { TextField } from '@material-ui/core';
import InputBase from '../../input-base';

const LongtextInputView = (props: LongtextInputViewInterface): ReactElement => {
  const {
    getRef,
    field,
    value,
    label,
    hint,
    inputMessage,
    visibleClass,
    handleBlur,
    handleChange,
    handleFocus,
    disabled,
  } = props;
  const classes = useStyles({ messageType: inputMessage?.messageType });
  const { name, required } = field;

  return (
    <InputBase
      className={`${visibleClass}`}
      label={label}
      inputMessage={inputMessage}
      required={required}
      hint={hint}
      field={field}
    >
      <TextField
        data-test-field-name={name}
        ref={getRef}
        value={value ?? ''}
        name={name}
        required={required}
        margin="normal"
        multiline
        rowsMax="1"
        classes={{
          root: classes.root,
        }}
        InputProps={{
          classes: {
            root: classes.outlineRoot,
            notchedOutline: classes.notchedOutline,
            input: classes.input,
            multiline: classes.multiline,
            disabled: classes.disabled,
          },
        }}
        variant="outlined"
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        disabled={disabled}
      />
    </InputBase>
  );
};

export default LongtextInputView;
