import React, { memo, ReactElement } from 'react';
import { LatLngExpression, MapViewInterface } from './map.type';
import { useStyles } from './map.style';
import { Map as MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import lodashMap from 'lodash/map';
import { icon } from 'leaflet';
import lodashFind from 'lodash/find';
import LoadingBox from '../LoadingBox';
import { isEmptyObject } from '../../helper/data-helper';
import Polyline from 'react-leaflet-arrowheads';

const MapView = (props: MapViewInterface): ReactElement => {
  const { mapCenter, zoom, polylinePositions, markerList, hasTracing } = props;

  const classes = useStyles({ isFullScreen: props.isFullScreen });

  const makeIcon = (iconName: string): any => {
    const iconList = [
      'defaultMarkerMap',
      'download',
      'mapIconGreen',
      'end',
      'start',
      'start1',
      'start2',
    ];

    let fileName = 'defaultMarkerMap';
    if (lodashFind(iconList, item => item == iconName)) {
      fileName = iconName;
    }

    return icon({
      iconUrl: require(`../../images/icon/map/${fileName}.svg`).default,
      iconSize: [32, 32],
      iconAnchor: [16, 32],
    });
  };

  if (!mapCenter) return <LoadingBox />;

  return (
    <MapContainer
      className={classes.mapContainer}
      id="mapId"
      center={mapCenter as LatLngExpression}
      zoom={zoom}
    >
      <TileLayer
        key={'mapComponent'}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {!isEmptyObject(markerList) &&
        lodashMap(markerList, ({ lat, long, icon, popupContent }) => {
          return (
            <Marker icon={makeIcon(icon)} key={Math.random()} position={[lat, long]}>
              {popupContent ? (
                <Popup>
                  <div dangerouslySetInnerHTML={{ __html: popupContent }} />
                </Popup>
              ) : null}
            </Marker>
          );
        })}

      {hasTracing && polylinePositions && (
        <Polyline
          positions={polylinePositions}
          arrowheads={{ size: '8px' }}
          smoothFactor={10}
        />
      )}
    </MapContainer>
  );
};

export default memo(MapView);
