import {
  Dialog,
  DialogContent,
  makeStyles,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import React, {
  ChangeEvent,
  FC,
  forwardRef,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslate } from 'react-admin';

import { showNotification } from '../../helper/general-function-helper';
import { actorDispatch, actorOnDispatch } from '../../type/actor-setup';
import QuickAccessActionList from './QuickAccessActionList';
import { CustomTheme } from '../../core/themeProvider';
import { isEmpty } from '../../helper/data-helper';
import { isEnterPressed } from '../../helper/FormHelper';
import {
  actionList,
  callQuickAccess,
  openNewTab,
} from '../../helper/QuickAccessHelper';

const useStyles = makeStyles((theme: CustomTheme) => ({
  dialogContent: {
    padding: '0 !important',
  },

  input: {
    padding: 10,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    '& input': {
      fontSize: 13,
      padding: 10,
      lineHeight: 0,
    },
  },

  dialogFooter: {
    padding: 10,
  },

  footerTypography: {
    color: theme.palette.primary.appSecondaryTextColor,
  },
}));

/**
 * Transition style for open `Dialog`.
 * @constant {ForwardRefExoticComponent} Transition
 */
const Transition = forwardRef(function Transition(props: TransitionProps, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const QuickAccessDialogContainer: FC = props => {
  const classes = useStyles();
  const translate = useTranslate();
  const textFieldRef = useRef<HTMLInputElement>();

  const [selectedAction, setSelectedAction] = useState<string>('quickAccess');
  const [textFieldValue, setTextFieldValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    actorOnDispatch('quickAccessOpenDialog', value => {
      setIsOpen(value);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (isOpen && textFieldRef && textFieldRef.current) {
        textFieldRef.current.focus();
      }
    }, 0);
  }, [isOpen, textFieldRef]);

  const quickAccessCloseDialog = () => {
    actorDispatch('quickAccessOpenDialog', false);
  };

  /**
   * Set `textFieldValue`.
   * @function handleOnChange
   * @param {HTMLInputElement} event
   * @returns {void}
   */
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (isOpen) {
      setTextFieldValue(event.target.value);
    }
  };

  /**
   * Call action if is `isEnterPressed`.
   * @function handleOnKeyDown
   * @param {HTMLDivElement} event
   * @returns {Promise<void>}
   */
  const handleOnKeyDown = async (
    event: KeyboardEvent<HTMLDivElement>,
  ): Promise<void> => {
    if (
      isEnterPressed(event) &&
      !isEmpty(textFieldValue) &&
      selectedAction === 'quickAccess'
    ) {
      try {
        const url: string = await callQuickAccess(textFieldValue);

        quickAccessCloseDialog();
        setTextFieldValue('');
        openNewTab(url);
      } catch (error) {
        showNotification(error, 'warning');
      }
    }
  };

  return (
    <Dialog
      id="quickAccessDialog"
      open={isOpen}
      onClose={quickAccessCloseDialog}
      TransitionComponent={Transition}
      aria-labelledby="quickAccessDialogTitle"
    >
      <DialogContent className={classes.dialogContent}>
        <TextField
          className={classes.input}
          variant="outlined"
          placeholder={translate('ra.action.search')}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          inputProps={{
            'data-test-input-name': 'quickAccessInput',
            'aria-label': translate('ra.action.search'),
          }}
          inputRef={textFieldRef}
        />
        <QuickAccessActionList
          selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
          actionList={actionList}
        />
        <div className={classes.dialogFooter}>
          <Typography variant="caption" className={classes.footerTypography}>
            {translate(`quickAccess.actionDescription.${selectedAction}`)}
          </Typography>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default QuickAccessDialogContainer;
