import React, { useCallback, useMemo, useState, useEffect, useRef, FC } from 'react';
import TextField from '@material-ui/core/TextField';
import { useInput, useTranslate } from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import lodashGet from 'lodash/get';

import sanitizeRestProps from './sanitizeRestProps';
import {
  checkNumberForMinus,
  convertDigitsToEnglish,
  getLastCharacter,
  getCountOfDecimalPoints,
  getDigitsAfterDecimalPoints,
  shouldHandleNumber,
  formatNumber,
} from '../../helper/NumberHelper';
import { isEmpty } from '../../helper/data-helper';
import { FieldType } from '../../helper/Types';
import { dummyFunc } from '../../helper/InputHelper';

interface InputProps {
  onBlur: Function;
  onFocus: Function;
  onChange: Function;
}

interface MetaProps {
  touched: boolean;
  error: string;
}

interface NumberInputInterface {
  className: string;
  input?: InputProps;
  label: string;
  meta?: MetaProps;
  name?: string;
  onBlur?: Function;
  onChange?: Function;
  onFocus?: Function;
  options: object;
  resource: string;
  source: string;
  step?: string | number;
  validate?: Function | Function[];
  formName?: string;
  textAlign?: string;
  basePath?: string;
  record?: object;
  disabled: boolean;
  allowEmpty?: boolean;
  field: Partial<FieldType>;
  visibleClass: string;
  formData?: object;
  decimalField?: boolean;
  customError?: string;
  onClick: Function | ((...args: any[]) => any);
}

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },
  },
  error: {
    '& + p': {
      position: 'absolute',
      right: 0,
    },
  },
}));

const NumberInput: FC<NumberInputInterface> = props => {
  const {
    className,
    options,
    textAlign,
    basePath,
    record,
    disabled,
    decimalField,
    visibleClass,
    field,
    customError,
    ...rest
  } = props;

  const {
    input,
    meta: { touched, error },
  } = useInput(props);

  const { onChange = dummyFunc, value = '' } = input;

  const {
    defaultValue,
    name = '',
    hidden,
    format,
    maxLength,
    precision = 0,
  } = field;

  const [internalValue, setInternalValue] = useState<string | number>(value);
  const lastInputValue = useRef<number | string>();

  useEffect(() => {
    const { formData = {} } = props;
    if (!Object.prototype.hasOwnProperty.call(formData, name) && hidden) {
      const { onChange: onChangeProps = dummyFunc } = props;

      onChangeProps(+defaultValue);
      onChange(+defaultValue);
    }
  }, []);

  const classes = useStyles();
  const translate = useTranslate();

  /**
   * it will join a "." on its entry.
   * if the field has format it should add "." on formatted entry else just add "." on intact entry
   * @function addDecimalPoint
   * @param {number | string} value
   * @param {number} precision fields precision in meta
   * @returns {void}
   */
  const addDecimalPoint = (value: number | string, precision: number) => {
    if (precision !== 0) {
      if (format) {
        // if the field has format should separate digits three by three also should handle decimal point base of its precision
        setInternalValue(String(formatNumber(value)).concat('.'));
      } else {
        // if the field has not formatted should not separate digits three by three but can have a decimal point base of its precision
        setInternalValue(String(value).concat('.'));
      }
    }
  };

  /**
   * it should cast its entry to number format and call the useInput onChange and props onChange with casted value if the number of
   * digits after the decimal point were not more than the field precision.
   * @function changeInputValue
   * @param {string} value
   * @param {boolean} isLastEntryAfterDecimalPoint
   * @param {number } digitsAfterDecimalPointCount
   * @param {number} precision
   * @returns {void}
   */
  const changeInputValue = (
    value: string,
    isLastEntryAfterDecimalPoint: boolean,
    digitsAfterDecimalPointCount: number,
    precision: number,
  ) => {
    const applaiedPrecisionValue = applayPrecisionOnValue(
      value,
      precision,
      digitsAfterDecimalPointCount,
    );

    const computedInputValue = applaiedPrecisionValue ? +applaiedPrecisionValue : '';
    if (input.value !== computedInputValue) {
      lastInputValue.current = computedInputValue;
      const { onChange: onChangeProps = dummyFunc } = props;

      onChangeProps(computedInputValue);
      onChange(computedInputValue);
    }
  };

  /**
   * it should get a value (a number with decimal value) and format it if it has fields format
   * and check if numbers after the decimal point were not more than fields precision, validate
   * new entry to be number and set the new value in the state.
   * @function handleNumbersAfterDecimalPoint
   * @param {number} precision
   * @param {number} digitsAfterDecimalPointCount
   * @param {string} digitsAfterDecimalPoint
   * @param {string} value
   * @returns {void}
   */
  const handleNumbersAfterDecimalPoint = (
    precision: number,
    digitsAfterDecimalPointCount: number,
    digitsAfterDecimalPoint: string,
    value: string,
  ) => {
    const applaiedPrecisionValue = applayPrecisionOnValue(
      value,
      precision,
      digitsAfterDecimalPointCount,
    );
    if (format) {
      if (String(formatNumber(+digitsAfterDecimalPoint)) !== 'NaN') {
        setInternalValue(
          applaiedPrecisionValue.includes(',')
            ? applaiedPrecisionValue
            : formatNumber(applaiedPrecisionValue),
        );
      }
    } else {
      if (String(+digitsAfterDecimalPoint) !== 'NaN') {
        setInternalValue(applaiedPrecisionValue);
      }
    }
  };

  /**
   * it will get a number(without decimal point) as its entry and format if if field has format and set it on state
   * @function handleNumbersBeforDecimalPoint
   * @param {string} value
   * @returns {void}
   */
  const handleNumbersBeforDecimalPoint = (value: string) => {
    if (format) {
      setInternalValue(formatNumber(value));
    } else {
      setInternalValue(value);
    }
  };

  /**
   * it should replace empty string with 0 if the value is 0 because number inputs can be null.
   * also should check and add a "." at the end of the number if the user press the "." key after a number
   * and call the related function to handle input value
   * @function handleVisibleValues
   * @param {string} numericValue
   * @param {boolean} shouldAddDot
   * @param {number} precision
   * @param {boolean} isLastEntryAfterDecimalPoint
   * @param {number} digitsAfterDecimalPointCount
   * @param {string} digitsAfterDecimalPoint
   * @param {string} value
   * @returns {void}
   */
  const handleVisibleValues = (
    numericValue: string,
    shouldAddDot: boolean,
    precision: number,
    isLastEntryAfterDecimalPoint: boolean,
    digitsAfterDecimalPointCount: number,
    digitsAfterDecimalPoint: string,
    value: string,
  ) => {
    // number inputs can be null so if its value be equal with "" should not format it. because formatting will change "" to 0
    if (isEmpty(numericValue)) {
      setInternalValue(numericValue);
    } else {
      // here we are sure its first time to user pressed "." so if field.precision is more than 0 it should join a "." to desplaying value.
      if (shouldAddDot) {
        addDecimalPoint(numericValue, precision);
      } else {
        if (isLastEntryAfterDecimalPoint) {
          handleNumbersAfterDecimalPoint(
            precision,
            digitsAfterDecimalPointCount,
            digitsAfterDecimalPoint,
            value,
          );
        } else {
          handleNumbersBeforDecimalPoint(numericValue);
        }
      }
    }
  };

  /**
   * Applay precision on value.
   * @function applayPrecisionOnValue
   * @param {number} value
   * @param {number} precision
   * @param {number} digitsAfterDecimalPointCount
   * @returns {string} tempValue
   */
  const applayPrecisionOnValue = (
    value: string,
    precision: number,
    digitsAfterDecimalPointCount: number,
  ): string => {
    let tempValue = String(value);
    const tempValueLength = tempValue.length;

    if (digitsAfterDecimalPointCount > precision) {
      if (precision > 0) {
        tempValue = tempValue.slice(
          0,
          tempValueLength - (digitsAfterDecimalPointCount - precision),
        );
      } else {
        tempValue = tempValue.slice(
          0,
          tempValueLength - (digitsAfterDecimalPointCount - -1),
        );
      }
    }

    return tempValue;
  };

  /**
   * it computes some values such as last entry and ... then call the handler functions with computed values
   * if the shouldHandleNumber function returns true.
   * @function handleValues
   * @param {string | number} value event value or input value from useEffect
   * @returns {void}
   */
  const handleValues = useCallback(
    value => {
      // it handle "-" key to join "000" in the end of number if "-" has been pressed
      const numericValue = checkNumberForMinus(convertDigitsToEnglish(value));

      // it will compute what was last character
      const lastEntry = getLastCharacter(value);

      // it will count number of "." that user typed
      const countOfDecimalPoints = getCountOfDecimalPoints(value);

      // if user typed "." one time and pres "." again should ignore this Event.
      if (countOfDecimalPoints > 1 && lastEntry == '.') {
        return;
      }

      // first character can not be "." so it it check that "." is next of a number
      const shouldAddDot = value.length > 1 && lastEntry === '.';

      // it showd numbers after "."
      const digitsAfterDecimalPoint =
        countOfDecimalPoints === 1 ? getDigitsAfterDecimalPoints(value) : '';

      // it computes number of digits after "."
      const digitsAfterDecimalPointCount = digitsAfterDecimalPoint.length;

      // it will check if last character after "." or not.
      const isLastEntryAfterDecimalPoint =
        countOfDecimalPoints === 1 && lastEntry !== '.';

      if (shouldHandleNumber(numericValue, shouldAddDot)) {
        // precision if count of digits that can be written after "."
        const handleValuesPrecision = precision;

        handleVisibleValues(
          numericValue,
          shouldAddDot,
          handleValuesPrecision,
          isLastEntryAfterDecimalPoint,
          digitsAfterDecimalPointCount,
          digitsAfterDecimalPoint,
          value,
        );
        changeInputValue(
          numericValue,
          isLastEntryAfterDecimalPoint,
          digitsAfterDecimalPointCount,
          handleValuesPrecision,
        );
      }
    },
    [value],
  );

  /**
   * Handle Numeric, Decimal, and formatted values with call handleValues function
   * @function handleChange
   * @param event {React.ChangeEvent<HTMLInputElemet>}
   * @returns void
   */
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      handleValues(value);
    },
    [props.onChange, onChange],
  );

  useEffect(() => {
    if (value !== lastInputValue.current) {
      handleValues(value);
    }
  }, [value]);

  const inputPropsParams = useMemo(
    (): object => ({
      classes: {
        error: classes.error,
      },
    }),
    [],
  );

  const hasError = !!(touched && error);

  return (
    <TextField
      {...sanitizeRestProps(rest as any)}
      {...options}
      {...input}
      error={!!customError || hasError}
      helperText={
        customError
          ? customError
          : hasError
          ? translate(lodashGet(error, 'message', error))
          : undefined
      }
      required={field.required}
      margin="normal"
      className={`${visibleClass} ${className}`}
      InputProps={inputPropsParams}
      inputProps={{
        'data-test-input-name': props.source,
        'data-test-precision': precision,
        maxLength: maxLength,
        inputMode: 'numeric',
      }}
      onChange={handleChange}
      variant="outlined"
      disabled={disabled}
      value={internalValue}
      data-test-has-error={!!customError || hasError}
    />
  );
};

export default NumberInput;
