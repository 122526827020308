import { ReactElement } from 'react';
import { Box, Checkbox, IconButton } from '@material-ui/core';
import ImportantIcon from '@material-ui/icons/LabelImportant';
import PrintIcon from '@material-ui/icons/Print';
import classNames from 'classnames';
import { useLocale } from 'react-admin';

import { MultipleMailDetailBodyViewInterface } from './multiple-mail-detail-body.type';
import { useStyles } from './multiple-mail-detail-body.style';
import { CustomAccordion } from '../../../custom-accordion';
import { MailDetailBody } from '../mail-detail-body';
import { SelectedMailInterface } from '../..';
import { MailDetailSkeleton } from '../../../skeleton/mail-detail-skeleton.view';
import { MailSummary } from './mail-summary';

const MultipleMailDetailBodyView = (
  props: MultipleMailDetailBodyViewInterface,
): ReactElement => {
  const {
    mailActionsHandler,
    selectedMail,
    selectedMailLoading,
    onImportantClick,
    onRecallClick,
    onForwardMailClick,
    onPrintMail,
    printComponentRef,
  } = props;

  const classes = useStyles();

  const locale = useLocale();

  const mainTitle = selectedMail?.[0]?.subject;

  if (!selectedMail || selectedMailLoading) {
    return <MailDetailSkeleton />;
  }

  return (
    <div
      dir={locale === 'fa' || locale === 'ar' ? 'rtl' : ''}
      ref={printComponentRef}
    >
      <Box p={1} data-test="mail-detail-item-container">
        <Box m={{ xs: 5, md: 1 }}>
          <Box
            component="span"
            fontSize={{ xs: 12, md: 17 }}
            fontWeight="bold"
            data-test="mail-detail-item-title"
          >
            {mainTitle}
          </Box>
          <Box className="dontShowInPrint" component="span" color="text.disabled">
            <Checkbox
              checked={Boolean(selectedMail?.[0]?.isimportant)}
              onChange={() =>
                onImportantClick(
                  Boolean(selectedMail?.[0]?.isimportant) ? false : true,
                )
              }
              icon={<ImportantIcon className={classes.iconImportant} />}
              checkedIcon={
                <ImportantIcon className={classes.iconImportantChecked} />
              }
            />
          </Box>
          <Box className="dontShowInPrint" component="span" color="text.disabled">
            <IconButton
              onClick={onPrintMail}
              data-test="mail-detail-header-archive-icon"
            >
              <PrintIcon />
            </IconButton>
          </Box>
        </Box>

        <Box>
          {selectedMail?.map((mail: SelectedMailInterface, index: number) => (
            <CustomAccordion
              id={`${mail.uniqueid}`}
              data-test="mail-detail-item"
              defaultExpanded={true}
              summary={
                <MailSummary
                  mailActionsHandler={mailActionsHandler}
                  mail={mail}
                  isMainMail={index === selectedMail?.length - 1}
                  onRecallClick={onRecallClick}
                />
              }
              customContainerClass={classes.accordionContainer}
              customDetailClass={classes.accordionDetailContainer}
              customSummaryContainerClass={classNames(
                'dontShowInPrint',
                classes.summaryContainer,
              )}
            >
              <MailDetailBody
                mailActionsHandler={mailActionsHandler}
                selectedMail={mail}
                selectedMailLoading={selectedMailLoading}
                isMainMail={index === selectedMail?.length - 1}
                onRecallClick={onRecallClick}
                onForwardMailClick={onForwardMailClick}
                isFromMultipleMailList
              />
            </CustomAccordion>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default MultipleMailDetailBodyView;
