import { FC, memo } from 'react';

import { isEmptyObject } from '../../../../helper/data-helper';
import { actorDispatch } from '../../../../type/actor-setup';
import { SelectedUserType } from '../../chat-section.type';
import { SearchListInterface } from './search-list.type';
import SearchListView from './search-list.view';

const SearchListController: FC<SearchListInterface> = memo(props => {
  const { contacts, messages, onUserSelect, selectedUser } = props;

  /**
   * @function handleOnUserSelect
   * @param { SelectedUserType } user
   * @returns { void }
   */
  const handleOnUserSelect = (user: SelectedUserType): void => {
    onUserSelect(user);
  };

  /**
   * @function onSelectMessage
   * @param { SelectedUserType } user
   * @returns { void }
   */
  const onSelectMessage = (user: SelectedUserType): void => {
    !isEmptyObject(user) && actorDispatch('foundedMessage', user);
  };

  return (
    <SearchListView
      contacts={contacts}
      messages={messages}
      onUserSelect={handleOnUserSelect}
      selectedUser={selectedUser}
      onSelectMessage={onSelectMessage}
    />
  );
});

export default SearchListController;
