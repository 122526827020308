import {
  SEND_CONFIRMATION,
  SEND_CONFIRMATION_FAILURE,
  SEND_CONFIRMATION_SUCCESS,
  SEND_PHONE_NUMBER,
  SEND_PHONE_NUMBER_FAILURE,
  SEND_PHONE_NUMBER_SUCCESS,
} from './constant';

export function sendPhoneAction(params) {
  return {
    ...params,
    type: SEND_PHONE_NUMBER,
  };
}

export function sendPhoneSuccessAction(params) {
  return {
    ...params,
    type: SEND_PHONE_NUMBER_SUCCESS,
  };
}

export function sendPhoneFailureAction(params) {
  return {
    ...params,
    type: SEND_PHONE_NUMBER_FAILURE,
  };
}

export function sendConfirmationAction(params) {
  return {
    ...params,
    type: SEND_CONFIRMATION,
  };
}

export function sendConfirmationSuccessAction(params) {
  return {
    ...params,
    type: SEND_CONFIRMATION_SUCCESS,
  };
}

export function sendConfirmationFailureAction(params) {
  return {
    ...params,
    type: SEND_CONFIRMATION_FAILURE,
  };
}
