import { FC, memo, useEffect, useRef, useState } from 'react';
import { MessagesDataInterface } from '..';
import { OnFetchMoreMessagesParams, OnMoveLastParams } from '../../..';
import { actorDispatch, actorOnDispatch } from '../../../../../type/actor-setup';
import {
  ChatActions,
  ContactInterface,
  CurrentUser,
} from '../../../chat-section.type';
import { MessagesListInterface } from './messages-list.type';
import MessagesListView from './messages-list.view';

const MessagesListController: FC<MessagesListInterface> = memo(props => {
  const {
    chatActionsHandler,
    userMessages,
    hasMoreMessages,
    authUser,
    selectedUser,
    messagesLoading,
  } = props;
  const [showScrollDownBtn, setShowScrollDownBtn] = useState<boolean>(false);

  const _authUser = authUser as CurrentUser;

  const senderUser: ContactInterface = {
    personinfo_id: _authUser.currentUserID,
    personname: _authUser.displayName,
    personimage: _authUser.photoURL ?? '',
    sumnotseen: 0,
  };

  //TODO: couldn't find correct type
  const scrollRef = useRef<any>(null);

  /**
   * @function onFetchMoreMessages
   * @param { boolean } IsUp
   * @returns { void }
   */
  const onFetchMoreMessages = (IsUp: boolean): void => {
    !messagesLoading &&
      chatActionsHandler(ChatActions.onfetchMoreMessages, {
        IsUp,
      } as OnFetchMoreMessagesParams);
  };

  /**
   * @function successMoveLastCallback
   * @param { MessagesDataInterface } response
   * @returns { void }
   */
  const successMoveLastCallback = (response: MessagesDataInterface): void => {
    if (response && response.data) {
      actorDispatch('loading', { messagesLoading: false });
      actorDispatch('messagesData', { data: response?.data, hasMore: true });
    }
    const element = document.getElementById('scrollableDiv');
    if (element) {
      element.scrollTo({
        top: 0,
        left: 0,
      });
    }
  };

  /**
   * @function onMoveLast
   * @returns { void }
   */
  const onMoveLast = (): void => {
    chatActionsHandler(ChatActions.onMoveLast, {
      successCallback: successMoveLastCallback,
    } as OnMoveLastParams);
  };

  /**
   * @function onScrollDown
   * @param { boolean } isSmooth
   * @param { boolean } shouldGetLastData
   * @returns { void }
   */
  const onScrollDown = (): void => {
    const element = document.getElementById('scrollableDiv');
    if (element) {
      element.scrollTo({
        top: 0,
        left: 0,
      });
    }
  };

  /**
   * @function onScrollTop
   * @returns { void }
   */
  const onScrollTop = (): void => {
    const element = document.getElementById('scrollableDiv');
    if (element) {
      element.scrollTo({
        top: -99999999999,
        left: 0,
      });
    }
  };

  /**
   * @function handleReachBottomOfScroll
   * @returns { void }
   */
  const handleReachBottomOfScroll = (): void => {
    onFetchMoreMessages(false);
  };

  /**
   * @function handleReachTopOfScroll
   * @returns { void }
   */
  const handleReachTopOfScroll = (): void => {
    onFetchMoreMessages(true);
  };

  /**
   * @function handleOnScroll
   * @param {UIEvent & { target: Element }} event
   * @returns {void}
   */
  const handleOnScroll = (event: UIEvent & { target: Element }): void => {
    // it's not on bottom position
    if (event.target.scrollTop < 0) {
      !showScrollDownBtn && setShowScrollDownBtn(true);
    } else if (event.target.scrollTop >= 0) {
      // reach bottom
      showScrollDownBtn && setShowScrollDownBtn(false);
      handleReachBottomOfScroll();
    }
  };

  // auto scroll down while sending new messages or file
  useEffect(() => {
    actorOnDispatch('isNewContentSent', flag => {
      if (flag) {
        onScrollDown();
      }
    });
  }, []);

  /**
   * when there are NOT SEEN messages
   * we should move scroll bar to top
   * then user can start reviewing messages
   * from top to bottom
   */
  useEffect(() => {
    if (selectedUser?.sumnotseen && selectedUser.sumnotseen > 0) {
      onScrollTop();
    }
  }, [selectedUser]);

  return (
    <MessagesListView
      chatActionsHandler={chatActionsHandler}
      userMessages={userMessages}
      hasMoreMessages={hasMoreMessages}
      authUser={senderUser}
      selectedUser={selectedUser}
      scrollRef={scrollRef}
      handleOnScroll={handleOnScroll}
      onScrollDown={onMoveLast}
      onScrollTop={onScrollTop}
      showScrollDownBtn={showScrollDownBtn}
      fetchMoreOnTop={handleReachTopOfScroll}
    />
  );
});

export default MessagesListController;
