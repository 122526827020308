import React, { useContext, useEffect, useCallback, useState } from 'react';
import compose from 'recompose/compose';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { UPDATE, translate, useLocale } from 'react-admin';
import lodashFindIndex from 'lodash/findIndex';
import lodashFilter from 'lodash/filter';
import lodashGet from 'lodash/get';
import { getIconClassName } from '@uifabric/styling';
import { Dialog, Typography } from '@material-ui/core';

import {
  closeTodoShareListDialogAction,
  removeMemberAction,
  getSharedListMemberAction,
  getTodoListAction,
} from '../../redux/todoList/action';
import { NewMetaContext } from '../../container/NewMetaContext';
import {
  getListMemberPatternInfo,
  getTodoListPatternInfo,
  getTodoSharedListMemberPatternInfo,
} from '../../helper/PatternMetaHelper';
import { getFieldByName } from '../../helper/MetaHelper';
import NewSubmittableForm from '../NewSubmittableForm';
import QuickCreateButtonToolbar from '../QuickCreateButtonToolbar-old';
import DynamicInput from '../../component/dynamicInput-old/DynamicInput';
import dataProvider from '../../core/dataProvider';
import { crudCreateWithCallbackAction } from '../../redux/crud/action';
import TodoSharedMemberItem from './TodoSharedMemberItem';
import ConfirmDialogHOC from '../../container/ConfirmDialogHOC';
import { getValue, USER_ID } from '../../core/configProvider';
import { isEmpty } from '../../helper/data-helper';
import { showNotification } from '../../helper/general-function-helper';

const useStyles = makeStyles(theme => ({
  simpleForm: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 400,
    maxHeight: 600,
    '& > div:first-child ': {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'flex-start',
      flexWrap: 'wrap',
      overflow: 'auto',
      '& > div': {
        margin: '7px 0',
        width: '100%',
      },
    },
  },

  icon: {
    fontSize: 20,
    color: theme.palette.secondary.main,
  },

  titleContainer: {
    display: 'flex',
    padding: '24px 24px 0',
    alignItems: 'center',
  },

  title: {
    margin: '0 10px',
  },
}));

const TodoShareListDialogContainer = props => {
  const {
    resource,
    listMemberResource,
    listItem,
    isOpen,
    translate,
    closeDialog,
    getTodoList,
    create,
    openConfirmDialog,
    removeMember,
    getSharedListMember,
    listSharedMember,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const locale = useLocale();
  const [currentUserId, setCurrentUserId] = useState(null);
  const { getMeta } = useContext(NewMetaContext);

  const dialogActionBarMeta = {
    config: {
      hasOneRow: true,
    },
  };

  useEffect(() => {
    if (resource) {
      fechSharedListMember();
    }
    setCurrentUserId(getValue(USER_ID));
  }, [resource]);

  const handleChange = useCallback(() => {
    // This handler doesn't do anything special but spread formChange awareness for FormSpy (To Enable Toolbar Buttons)
  }, [props.formData]);

  if (!resource || !listMemberResource) {
    return <div />;
  }

  const fechSharedListMember = () => {
    getSharedListMember(
      reportSharedListMember,
      {
        sort: { field: [fieldNameForShareList] },
        pagination,
        filter: { [listId]: listItem[listId] },
      },
      listSharedMember,
    );
  };

  const metaData = getMeta(listMemberResource);

  const { userId: fieldNameForShareList, listId } =
    getListMemberPatternInfo(listMemberResource);
  const {
    isSharedList,
    reportSharedListMember,
    pagination,
    sort,
    reportResource,
    title,
  } = getTodoListPatternInfo(resource);
  const {
    personId,
    listMemberId,
    listId: sharedListMemberListId,
    createUserId,
  } = getTodoSharedListMemberPatternInfo(reportSharedListMember);

  const field = getFieldByName(metaData, fieldNameForShareList);

  const handleSave = data => {
    const isAlreadyAdded = lodashFindIndex(listSharedMember, {
      [personId]: +data[personId],
    });

    if (data[personId] == currentUserId) {
      showNotification(translate('todo.notYourself'), 'error');
    } else if (isAlreadyAdded !== -1) {
      showNotification(translate('todo.listIsAlreadyShared'), 'error');
    } else if (!data || isEmpty(data[personId])) {
      showNotification(translate('todo.selectAPerson'), 'error');
    } else {
      try {
        const updateData = {
          data: {
            [isSharedList]: true,
          },
          id: data[listId],
        };
        create(listMemberResource, data, async () => {
          const response = await dataProvider(UPDATE, resource, updateData);
          getTodoList(resource, reportResource, sort, pagination);
          fechSharedListMember();
        });
      } catch (error) {
        console.log(error);
        showNotification(error.message || error.toString(), 'error');
      }
    }
  };

  const showConfirm = member => {
    openConfirmDialog({
      content: translate('ra.message.delete_content'),
      onConfirm: handleRemoveMember(resource, listMemberResource, member),
      color: theme.palette.error.main,
    });
  };

  const handleRemoveMember = (resource, listMemberResource, member) => () => {
    const options = {
      sort: { field: [fieldNameForShareList] },
      pagination,
      filter: { [listId]: listItem[listId] },
    };
    removeMember(
      resource,
      listMemberResource,
      member,
      { listMemberId, isSharedList, sharedListMemberListId },
      listSharedMember,
      sort,
      pagination,
      reportResource,
      translate,
      reportSharedListMember,
      options,
    );
  };

  return (
    <Dialog open={isOpen} onClose={closeDialog} maxWidth="xs" fullWidth>
      <div className={classes.titleContainer}>
        <i className={`${classes.icon} ${getIconClassName('addFriend')}`} />
        <Typography className={classes.title} variant="body1">
          {`${translate('todo.shareList')} ${listItem[title]}`}
        </Typography>
      </div>
      <NewSubmittableForm
        className={classes.simpleForm}
        redirect={false}
        resource={listMemberResource}
        save={handleSave}
        onFormChange={handleChange}
        // saving={isLoading}
        record={listItem}
        toolbar={
          <QuickCreateButtonToolbar
            metaData={dialogActionBarMeta}
            disableFullFormButton
            closeDialog={closeDialog}
          />
        }
        validationFieldList={[field]}
      >
        {field && (
          <DynamicInput
            key={field.id}
            source={field.name}
            field={field}
            label={lodashGet(field, ['translatedCaption', locale], field.caption)}
            isTodo
          />
        )}
        {listSharedMember &&
          !!listSharedMember.length &&
          listSharedMember.map(item =>
            item[personId] == currentUserId ||
            item[personId] == item[createUserId] ? null : (
              <TodoSharedMemberItem
                key={item[personId]}
                listResource={resource}
                sharedListMemberResource={reportSharedListMember}
                member={item}
                removeMember={showConfirm}
              />
            ),
          )}
      </NewSubmittableForm>
    </Dialog>
  );
};

TodoShareListDialogContainer.propTypes = {};

const mapStateToProps = state => {
  if (state.todoList.isOpenShareList) {
    const resource = state.todoList.shareResource;
    const { reportSharedListMember, id } = getTodoListPatternInfo(resource);

    const listItem = state.todoList.shareListItem;
    const sharedMembers = lodashFilter(
      lodashGet(state, ['todoList', 'resultList', reportSharedListMember]),
      {
        [id]: listItem[id],
      },
    );

    return {
      resource: resource,
      isOpen: true,
      listMemberResource: state.todoList.shareListMemberResource,
      listItem: listItem, // selected list data
      listSharedMember: sharedMembers, // list of shared members
    };
  } else {
    return { isOpen: false };
  }
};

const mapDispatchToProps = {
  closeDialog: closeTodoShareListDialogAction,
  getTodoList: getTodoListAction,
  create: crudCreateWithCallbackAction,
  removeMember: removeMemberAction,
  getSharedListMember: getSharedListMemberAction,
};

export default compose(
  ConfirmDialogHOC,
  translate,
  connect(mapStateToProps, mapDispatchToProps),
)(TodoShareListDialogContainer);
