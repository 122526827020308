import produce from 'immer';
import { USER_LOGOUT } from 'react-admin';

import { FIND_ONE, FIND_ONE_SUCCESS, FIND_ONE_FAILED } from './constant';

const initialState = {
  loadingList: {},
  list: {},
  errorList: {},
};

const myReducer = (state = initialState, { type, resource, data, error }) =>
  produce(state, draft => {
    // eslint-disable-next-line default-case
    switch (type) {
      case USER_LOGOUT:
        draft.loadingList = {};
        draft.list = {};
        draft.errorList = {};
        break;

      case FIND_ONE:
        draft.loadingList[resource] = true;
        draft.list[resource] = null;
        draft.errorList[resource] = null;
        break;

      case FIND_ONE_SUCCESS:
        draft.loadingList[resource] = false;
        draft.list[resource] = data[0]; // because dataProvider will give array for meta, take first item of it
        draft.errorList[resource] = null;
        break;

      case FIND_ONE_FAILED:
        draft.loadingList[resource] = false;
        draft.list[resource] = null;
        draft.errorList[resource] = error;
        break;
    }
  });

export default myReducer;
