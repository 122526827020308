import { FC, memo } from 'react';
import { MailActions } from '..';
import { OnSendNewMessageParams } from '..';
import { MailFormToolbarInterface } from './mail-form-toolbar.type';
import MailFormToolbarView from './mail-form-toolbar.view';
import { showNotification } from '../../../helper/general-function-helper';
const MailFormToolbarController: FC<MailFormToolbarInterface> = memo(props => {
  const { mailActionsHandler, title, onClose, isFullScreen } = props;

  /**
   * @function handleToggleFullScreen
   * @returns { void }
   */
  const handleToggleFullScreen = (): void => {
    mailActionsHandler && mailActionsHandler(MailActions.onToggleMailFormFullScreen);
  };

  /**
   * @function handleClose
   * @returns { void }
   */
  const handleClose = (): void => {
    mailActionsHandler?.(MailActions.onSendNewMessage, {
      successCallback: onClose,
      failureCallback: onClose,
      isSaveAsDraft: true,
    } as OnSendNewMessageParams);
  };

  /**
   * @function handleMinimize
   * @returns { void }
   */
  const handleMinimize = (): void => {
    mailActionsHandler?.(MailActions.onSetLocalStorage);
    onClose();
  };

  return (
    <MailFormToolbarView
      title={title}
      onClose={handleClose}
      handleToggleFullScreen={handleToggleFullScreen}
      isFullScreen={isFullScreen}
      onMinimize={handleMinimize}
    />
  );
});

export default MailFormToolbarController;
