import { FC, ReactElement, useEffect, useState } from 'react';
import { removeLastResource, setResource } from '../../../helper/resource-helper';
import {
  actorGetActionValue,
  actorSetActionValue,
  FormKeyMode,
} from '../../../type/actor-setup';
import { InputRefContent } from '../../form';
import { WMSLayoutWithTabsView } from './wms-layout-with-tabs.view';
import { WMSLayoutWithTabsPropsInterface } from './wms-layout-with-tabs.type';

const WMSLayoutWithTabsController: FC<WMSLayoutWithTabsPropsInterface> = (
  props,
): ReactElement => {
  const { wmsMetaData } = props;

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  useEffect(() => {
    const rootResource = actorGetActionValue('resources')!.stack[0];
    const newResource = `${rootResource.value}/tab-0`;

    // FIXME: Without this delay, we'll lose the whole form and our tabs
    setTimeout(() => {
      const refs = actorGetActionValue(
        'inputsRef',
        `${rootResource.value}.${rootResource.type}`,
      )! as Record<string, InputRefContent>;

      setResource(newResource, FormKeyMode.WMS);

      actorSetActionValue('inputsRef', refs, {
        path: `${newResource}.${FormKeyMode.WMS}`,
        callerScopeName: 'WMSLayoutWithTab => useEffect',
      });
    }, 1000);
  }, [wmsMetaData.id]);

  /**
   * It handles all stuffs about changing a tab, e.g save the current data in this tab if there is a form and etc...
   * @function handleTabChange
   * @param { string } index
   * @returns { void }
   */
  const handleTabChange = (index: string): void => {
    const rootResource = actorGetActionValue('resources')!.stack[0];
    const currentResource = actorGetActionValue('resources')!.current;

    const currentFormData = actorGetActionValue(
      'formData',
      `${currentResource.value}.${currentResource.type}`,
    );

    if (currentFormData) {
      actorSetActionValue('formData', currentFormData, {
        path: `${rootResource.value}.${rootResource.type}`,
        callerScopeName: 'WMSLayoutWithTab => handleTabChange',
      });
    }

    removeLastResource();

    const newResource = `${rootResource.value}/tab-${index}`;
    const metaData = actorGetActionValue('metaData', rootResource.value);
    actorSetActionValue('metaData', metaData, {
      path: newResource,
      callerScopeName: 'WMSLayoutWithTab => handleTabChange',
    });

    setResource(newResource, FormKeyMode.WMS);

    const indexNumber = Number(index);
    setCurrentTabIndex(indexNumber);
  };

  return (
    <WMSLayoutWithTabsView
      wmsMetaData={wmsMetaData}
      currentTabIndex={currentTabIndex}
      handleTabChange={handleTabChange}
    />
  );
};

export default WMSLayoutWithTabsController;
