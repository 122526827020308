import {
  REMOVE_BASKET_ITEM,
  SET_BASKET_ITEM,
  GET_ORDER_DETAIL_ITEM,
  GET_ORDER_DETAIL_ITEM_SUCCESS,
  GET_ORDER_DETAIL_ITEM_FAILED,
  GET_ORDER_DETAIL,
  GET_ORDER_DETAIL_SUCCESS,
  FINALIZE_BASKET,
  SET_BASKET_PRODUCT_ID,
  REFRESH_BASKET,
  GET_SUB_CATEGORY_DATA,
  GET_SUB_CATEGORY_SUCCESS,
} from './constant';

export const removeBasketItemAction = ({
  resource,
  id,
  parentResource,
  defaultSort,
}) => ({
  type: REMOVE_BASKET_ITEM,
  resource,
  id,
  parentResource,
  defaultSort,
});

export const setBasketItemAction = ({
  resource,
  data,
  parentResource,
  defaultSort,
}) => ({
  type: SET_BASKET_ITEM,
  data,
  resource,
  parentResource,
  defaultSort,
});

export const getOrderDetailItemAction = ({ resource, id }) => ({
  type: GET_ORDER_DETAIL_ITEM,
  resource,
  id,
});

export const getOrderDetailItemSuccessAction = ({
  resource,
  id,
  data,
  additionalData,
}) => ({
  type: GET_ORDER_DETAIL_ITEM_SUCCESS,
  resource,
  id,
  data,
  additionalData,
});

export const getOrderDetailItemFailedAction = ({ resource, error }) => ({
  type: GET_ORDER_DETAIL_ITEM_FAILED,
  resource,
  error,
});

export const getOrderDetailAction = ({ resource, cartResource }) => ({
  type: GET_ORDER_DETAIL,
  resource,
  cartResource,
});

export const getOrderDetailSuccessAction = ({ resource, data }) => ({
  type: GET_ORDER_DETAIL_SUCCESS,
  resource,
  data,
});

export const finalizeBasketAction = ({ resource, data, redirectResource }) => ({
  type: FINALIZE_BASKET,
  resource,
  data,
  redirectResource,
});

export const setBasketProductIdAction = ({ key, id }) => ({
  type: SET_BASKET_PRODUCT_ID,
  key,
  id,
});

export const getSubCategoryDataAction = (resource, filter, key) => ({
  type: GET_SUB_CATEGORY_DATA,
  resource,
  filter,
  key,
});

export const getSubCategorySuccess = ({ resource, data, key }) => ({
  type: GET_SUB_CATEGORY_SUCCESS,
  resource,
  data,
  key,
});

export const refreshBasketAction = ({ payload }) => ({
  type: REFRESH_BASKET,
  payload,
});
