import { ReactElement } from 'react';
import { ChatContentViewInterface } from './chat-content.type';
import { useStyles } from './chat-content.style';
import Box from '@material-ui/core/Box';
import clsx from 'classnames';
import NoUserScreen from './nouser-screen/nouser-screen.view';
import { MessagesScreen } from './messages-screen';

const ChatContentView = (props: ChatContentViewInterface): ReactElement => {
  const { chatActionsHandler, currentUser, selectedUser } = props;
  const classes = useStyles();

  return (
    <>
      {selectedUser ? (
        <Box className={classes.messagesScreenRoot} id="chatContent">
          <MessagesScreen
            chatActionsHandler={chatActionsHandler}
            currentUser={currentUser}
            selectedUser={selectedUser}
          />
        </Box>
      ) : (
        <Box
          p={4}
          className={clsx(classes.scrollChatNoUser, 'scroll-chat-nouser')}
          id="boxNoneUser"
        >
          <NoUserScreen />
        </Box>
      )}
    </>
  );
};

export default ChatContentView;
