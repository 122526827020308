import { memo, ReactElement, useContext, useEffect, useState } from 'react';
import { useLocale, useTranslate } from 'react-admin';

import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
  FormKeyMode,
  ResourceInterface,
} from '../../type/actor-setup';
import { NewMetaContext } from '../../container/NewMetaContext';
import {
  DialogData,
  DialogOption,
  DialogParams,
  DialogType,
} from './dialogs-stack.type';
import { DialogContainer } from './dialog-container.view';
import { QuickCreateDropdownDialogView } from './quick-create-dropdown-dialog.view';
import { QuickCreateDialogView } from './quick-create-dialog.view';
import { ServiceDialogView } from './service-dialog.view';
import { RelationEditDialogView } from './relation-edit-dialog.view';
import { ConfirmationDialogView } from './confirmation-dialog/confirmation-dialog.view';
import { ServiceDialogDataItem } from './dialogs-stack.type';
import { checkDialogType } from './dialog-stack.helper';
import { showNotification } from '../../helper/general-function-helper';
import { GridColumnsSelectionDialog } from './grid-column-selection-dialog';
import {
  DialogDataInterface,
  DynamicDialog,
  QuestionApiResponseInterface,
} from './dynamic-dialog';

let dialogData: Record<string, unknown> = {};
let dialogType: DialogType | undefined;
let currentResource: ResourceInterface | undefined;

/**
 * @function gatherDialogData
 * @param {DialogData} actorData
 * @param {Function} handleGetMetaData
 * @returns {void}
 */
const gatherDialogData = (
  actorData: DialogData,
  handleGetMetaData: (...args) => void,
): void => {
  currentResource = actorGetActionValue('resources')!.current;

  dialogType = checkDialogType(actorData);

  if (dialogType && dialogType !== 'serviceDialogForm') {
    const metaData = handleGetMetaData(currentResource.value);
    dialogData.metaData = metaData;
  }

  dialogData = {
    ...dialogData,
    ...actorData.data,
    onCloseDialogCallback: actorData?.onCloseDialogCallback,
    dropdownIsOpen: actorData.dropdownIsOpen,
    serviceIsOpen: actorData.serviceIsOpen,
    confirmationIsOpen: actorData.confirmationIsOpen,
  };
};

const DialogsStackController = (): ReactElement => {
  const [dialogs, setDialogs] = useState<DialogParams[]>([]);
  const locale = useLocale();
  const translate = useTranslate();

  const { getMeta } = useContext(NewMetaContext);

  useEffect(() => {
    actorOnDispatch('quickDialog', detail => {
      gatherDialogData(detail, getMeta);

      if (detail.dropdownIsOpen) {
        openQuickCreateDropdownDialog();
        return;
      }

      if (detail.createIsOpen) {
        openQuickCreateDialog();
        return;
      }

      if (detail.serviceIsOpen) {
        openServiceDialog();
        return;
      }

      if (detail.relationEditIsOpen) {
        openRelationEditDialog();
        return;
      }

      if (detail.confirmationIsOpen) {
        openConfirmationDialog();
        return;
      }

      if (detail.gridColumnsSelectionDialogIsOpen) {
        openGridColumnsSelectionDialog();
        return;
      }
      if (detail.dynamicDialogIsOpen) {
        openDynamicDialog();
        return;
      }
    });

    actorOnDispatch('closeDialogs', () => {
      setDialogs([]);
    });

    actorOnDispatch('closeCurrentDialog', () => {
      if (
        dialogData?.onCloseDialogCallback &&
        typeof dialogData.onCloseDialogCallback === 'function'
      ) {
        dialogData.onCloseDialogCallback();
      }

      closeDialogHandler();
    });
  }, []);

  /**
   * @function createDialog
   * @param {DialogOption} option
   * @returns {void}
   */
  const createDialog = (option: DialogOption): void => {
    const dialog = { ...option, open: true };
    setDialogs(dialogs => [...dialogs, dialog]);
  };

  /**
   * @function mainCloseDialog
   * @returns {void}
   */
  const mainCloseDialog = () => {
    setDialogs(dialogs => {
      const latestDialog = dialogs.pop();
      if (!latestDialog) return dialogs;
      if (latestDialog.onClose) latestDialog.onClose();
      return [...dialogs].concat({ ...latestDialog, open: false });
    });
  };

  /**
   * close dialog
   * @function closeDialogHandler
   * @returns {void}
   */
  const closeDialogHandler = () => {
    mainCloseDialog();

    if (dialogType === 'dropdownQuickCreateForm') {
      actorDispatch('remove', {
        resource: currentResource!.value,
        type: FormKeyMode.DROPDOWN,
      });

      return;
    }

    if (dialogType === 'serviceDialogForm') {
      const { isSending, service } = dialogData.data as ServiceDialogDataItem;
      if (isSending) {
        showNotification(translate('grid.serviceIsRunning'), 'error');
        return;
      }

      actorDispatch('remove', {
        resource: `action/${(service as Record<string, unknown>)?.uniqueId}`,
        type: FormKeyMode.SERVICE,
      });
    }

    if (dialogType === 'relationEditDialogForm') {
      actorDispatch('remove', {
        resource: currentResource!.value,
        type: FormKeyMode.RELATION,
      });

      actorRemoveAction({
        actionName: 'record',
        path: `${currentResource!.value}.${FormKeyMode.ROOT}`,
      });
    }

    if (dialogType !== 'serviceDialogForm' && dialogData.relationMode) {
      actorDispatch('remove', {
        resource: currentResource!.value,
        type: FormKeyMode.RELATION,
      });
    }

    actorSetActionValue('quickDialog', null, {
      replaceAll: true,
    });
  };

  /**
   * @function openDynamicDialog
   * @returns {void}
   */
  const openDynamicDialog = (): void => {
    createDialog({
      children: (
        <DynamicDialog
          dialogData={dialogData as unknown as DialogDataInterface}
          closeDialogHandler={closeDialogHandler}
          setDialogs={setDialogs}
          openDynamicDialog={openDynamicDialog}
          gatherDialogData={gatherDialogData}
        />
      ),
    });
  };

  /**
   * @function openQuickCreateDialog
   * @returns {void}
   */
  const openQuickCreateDialog = () => {
    createDialog({
      fullscreen: true,
      children: (
        <QuickCreateDialogView
          currentResource={currentResource!}
          locale={locale}
          dialogType={'quickCreateDialog'}
          dialogData={dialogData}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /**
   * @function openQuickCreateDropdownDialog
   * @returns {void}
   */
  const openQuickCreateDropdownDialog = (): void => {
    createDialog({
      fullscreen: true,
      children: (
        <QuickCreateDropdownDialogView
          currentResource={currentResource!}
          locale={locale}
          dialogType={'dropdownQuickCreateForm'}
          dialogData={dialogData}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /**
   * open service dialog
   * @function openServiceDialog
   * @returns {void}
   */
  const openServiceDialog = (): void => {
    createDialog({
      fullscreen: true,
      children: (
        <ServiceDialogView
          currentResource={currentResource!}
          locale={locale}
          dialogType="serviceDialogForm"
          dialogData={dialogData}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /**
   * open relation edit dialog
   * @function openRelationEditDialog
   * @returns {void}
   */
  const openRelationEditDialog = (): void => {
    createDialog({
      fullscreen: true,
      children: (
        <RelationEditDialogView
          currentResource={currentResource!}
          locale={locale}
          dialogType={'relationEditDialogForm'}
          dialogData={dialogData}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /**
   * open confirmation dialog
   * @function openRelationEditDialog
   * @returns {void}
   */
  const openConfirmationDialog = (): void => {
    createDialog({
      children: (
        <ConfirmationDialogView
          currentResource={currentResource!}
          locale={locale}
          dialogType={'quickCreateDialog'}
          dialogData={dialogData}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  const openGridColumnsSelectionDialog = (): void => {
    createDialog({
      children: (
        <GridColumnsSelectionDialog
          dialogType={'gridColumnsSelectionDialog'}
          dialogData={dialogData}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /**
   * @function handleKill
   * @param { DialogParams } dialog
   * @returns {void}
   *
   */
  const handleKill = (dialog: DialogParams): void => {
    if (dialog.onExited) dialog.onExited();
    setDialogs(dialogs => dialogs.slice(0, dialogs.length - 1));
  };

  return (
    <>
      {dialogs.map((dialog, i) => {
        const { onClose, ...dialogParams } = dialog;

        return (
          <DialogContainer
            key={i}
            onClose={onClose || closeDialogHandler}
            onKill={() => handleKill(dialog)}
            {...dialogParams}
          />
        );
      })}
    </>
  );
};

export default memo(DialogsStackController);
