import lodashGet from 'lodash/get';
import lodashFind from 'lodash/find';

import dataProvider, {
  GET_REPORT,
  prepareFilterFromObject,
} from '../core/dataProvider';
import { isEmptyObject } from './data-helper';
import { getNotificationPatternInfo } from './PatternMetaHelper';
import { NotificationItemInterface, NotificationListInterface } from './Types';
import { getValue, USER_ID } from '../core/configProvider';

interface FechDataFromNotificationListReturnInterface {
  lastData: NotificationItemInterface[];
  hasMore: boolean;
}
interface DataProviderResponse {
  data: NotificationItemInterface[];
  total: number;
}

/**
 * Get notification data from API with use `dataProvider`.
 * @function getNotificationList
 * @returns {Promise<NotificationListInterface>}
 */
export const getNotificationList = async (
  filter = {},
): Promise<NotificationListInterface> => {
  try {
    const { resource, reportId } = getNotificationPatternInfo('notification');

    const { data, total }: DataProviderResponse = await dataProvider(
      GET_REPORT,
      resource,
      {
        reportId,
        pagination: {
          page: 0,
          perPage: 999999,
        },
        sort: {
          field: 'id',
          order: 'DESC',
        },
        filters: prepareFilterFromObject(filter),
      },
    );

    let unSeenCounter = 0;

    if (data && data.length) {
      if (typeof data[0].notseencount != 'undefined') {
        unSeenCounter = Number(data[0].notseencount);
      } else {
        unSeenCounter = data.filter(item => !item.isseen).length;
      }
    }
    return { unSeenCounter, data, totalCount: total };
  } catch (error) {
    throw lodashGet(error, 'userMessage', error);
  }
};

/**
 * Fetch 10 row based on `lastData` index, from `allData`.
 * @function fetchDataFromNotificationList
 * @param {NotificationItemInterface[]} allData
 * @param {number} totalCount
 * @param {NotificationItemInterface[]} lastData
 * @returns {FechDataFromNotificationListReturnInterface}
 */
export const fetchDataFromNotificationList = (
  allData: NotificationItemInterface[],
  totalCount: number,
  lastData: NotificationItemInterface[] = [],
): FechDataFromNotificationListReturnInterface => {
  if (lastData && lastData.length >= totalCount) {
    return { lastData, hasMore: false };
  }

  const count = lastData.length;

  const tempData: NotificationItemInterface[] = [];
  for (let i = count; i < count + 20; i++) {
    if (!isEmptyObject(allData[i])) {
      tempData.push(allData[i]);
    }
  }

  return { lastData: [...lastData, ...tempData], hasMore: true };
};

/**
 * Find notification item beased on notification id.
 * @function getNotificationItem
 * @param {string} id
 * @param {NotificationItemInterface[]} notificationList
 * @param {string} notificationIdPttern
 * @returns {NotificationItemInterface}
 */
export const getNotificationItem = (
  id: string,
  notificationList: NotificationItemInterface[],
  notificationIdPttern: string,
): NotificationItemInterface => {
  return lodashFind(notificationList, { [notificationIdPttern]: id });
};
