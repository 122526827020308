import React, { FC, useState } from 'react';

import CardListFilterDialog from './CardListFilterDialog';
import CardListSortDialog from './CardListSortDialog';
import CardListActionView from './CardListActionView';
import { SellinListPatternType } from '../../helper/Types';

interface CardListActionProps {
  pattern: SellinListPatternType;
  setSort: (name: string) => void;
  setFilters: (filter: { [key: string]: any }) => void;
  currentSort: {
    field: string;
    order: string;
  };
  filterValues: any;
}

const CardListAction: FC<CardListActionProps> = props => {
  const { pattern, setSort, currentSort, setFilters, filterValues } = props;

  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const toggleFilterOpen = () => setIsFilterOpen(!isFilterOpen);
  const toggleSortOpen = () => setIsSortOpen(!isSortOpen);

  return (
    <CardListActionView openFilter={toggleFilterOpen}>
      <CardListFilterDialog
        open={isFilterOpen}
        onClose={toggleFilterOpen}
        filterFieldList={pattern.filterFieldList}
        sortFieldList={pattern.sortFieldList}
        setFilters={setFilters}
        setSort={setSort}
        filterValues={filterValues}
        currentSort={currentSort}
      />
    </CardListActionView>
  );
};

export default CardListAction;
