import { put, takeEvery, call, select } from 'redux-saga/effects';
import { CREATE, GET_ONE, showNotification } from 'react-admin';
import lodashGet from 'lodash/get';
import lodashFind from 'lodash/find';

import { FIND_ONE, UPDATE_PROFILE } from './constant';
import {
  findOneSuccessAction,
  findOneFailedAction,
  findOneAction,
  updateProfileSuccessAction,
  updateProfileFailedAction,
  setGlobalParametersAction,
} from './action';

import dataProvider, { GET_DROPDOWN } from '../../core/dataProvider';
import { objectToLowerCaseProperties } from '../../helper/data-helper';
import {
  getValue,
  API_NAME,
  USER_WAREHOUSE_ID,
  USER_WAREHOUSE_TITLE,
  setValue,
  USER_TOKEN,
} from '../../core/configProvider';
import { getProfileSettingDropdownPatternInfo } from '../../helper/PatternMetaHelper';
import { getDropdownRequestParams } from '../../helper/DropdownHelper';
import {
  actorSetActionValue,
  actorDispatch,
  DropdownDataKey,
} from '../../type/actor-setup';
import { getSessionIdInUrl } from '../../helper/UrlHelper';

function* findOne() {
  if (!getValue(USER_TOKEN) || getSessionIdInUrl()) {
    yield put(findOneFailedAction('error'));
    return;
  }

  try {
    const apiName = getValue(API_NAME);

    // https://crmapi.samiansoft.com/v2/account/crmweb/profile/
    const { data, additionalData } = yield dataProvider(
      GET_ONE,
      `account/${apiName}`,
      {
        id: 'profile',
        rawResponse: true,
        skipPrefix: true,
      },
    );
    yield put(findOneSuccessAction(data, additionalData));
    yield call(prepareGlobalParameters, data);
  } catch (error) {
    if (error.code && error.code === 401) {
      yield put(showNotification(error.userMessage, 'error', { logout: true }));
    }
    yield put(findOneFailedAction(error));
  }
}

function* updateProfile({ data }) {
  try {
    const apiName = getValue(API_NAME);

    const response = yield dataProvider(CREATE, `account/${apiName}/profile`, {
      data,
      rawResponse: true,
      skipPrefix: true,
    });

    yield put(findOneAction());
    yield put(updateProfileSuccessAction(response.data));
  } catch (error) {
    console.log(error);
    yield put(updateProfileFailedAction(error));
  }
}

function* getDropDownItem({ dropdownMeta, userWarehouse }) {
  try {
    const dropdownId = lodashGet(dropdownMeta, 'id');

    let dropdownData = yield select(state => {
      return lodashGet(state, ['dropdown', dropdownId]);
    });

    if (!(Array.isArray(dropdownData) && dropdownData.length > 0)) {
      const params = getDropdownRequestParams({
        dropdownMeta,
      });

      const data = yield dataProvider(GET_DROPDOWN, dropdownId, params);
      dropdownData = data.result;
    }

    actorDispatch(
      'dropDownData',
      {
        [DropdownDataKey.DATA]: dropdownData,
        [DropdownDataKey.ALL]: dropdownData,
      },
      { path: `${dropdownId}-0` }, // '0' is a field if and comes from profileSettingFields in HeaderProfileContainer.tsx
    );

    if (userWarehouse) {
      return lodashFind(dropdownData, {
        [dropdownMeta.valueMember]: +userWarehouse,
      });
    } else {
      return lodashGet(dropdownData, '0');
    }
  } catch (error) {
    console.log(error);
  }
}

function* prepareGlobalParameters(data) {
  try {
    const dropdownMeta = getProfileSettingDropdownPatternInfo(
      'profileSettingDropdown',
    );
    const globalParameters = objectToLowerCaseProperties(
      lodashGet(data, 'globalParameters'),
    );

    let userWarehouse = getValue(USER_WAREHOUSE_ID);

    if (!userWarehouse) {
      userWarehouse = globalParameters['currentWareHouseID'];
      setValue(USER_WAREHOUSE_ID, globalParameters['currentWareHouseID']);
    }

    const dropdownItem = yield call(getDropDownItem, {
      dropdownMeta,
      userWarehouse,
    });

    const newGlobalParameters = {
      ...globalParameters,
    };

    if (dropdownItem != null) {
      newGlobalParameters['currentdepid'] = dropdownItem['departmentinfo_id'];
      newGlobalParameters['currentDepID'] = dropdownItem['departmentinfo_id'];
      newGlobalParameters['currentwarehouseid'] = dropdownItem['warehouse_id'];
      newGlobalParameters['currentWareHouseID'] = dropdownItem['warehouse_id'];

      setValue(USER_WAREHOUSE_TITLE, dropdownItem['warehousetitle']);
    }

    // set to actor
    actorSetActionValue('globalParameters', newGlobalParameters);
    yield put(setGlobalParametersAction(newGlobalParameters));
  } catch (error) {
    console.log(error);
  }
}

export default function* dropdownSaga() {
  yield takeEvery(FIND_ONE, findOne);
  yield takeEvery(UPDATE_PROFILE, updateProfile);
}
