import { ReactElement } from 'react';

/**
 * @function formatTextBreakLines
 * @param { string } string
 * @returns { ReactElement[]  }
 */
export const formatTextBreakLines = (string: string): ReactElement[] => {
  return string.split(`\n`).map(item => (
    <span>
      {item}
      <br />
    </span>
  ));
};

/**
 * @function handleHighlightRepliedMessage
 * @param { number } messageId
 * @returns { void }
 */
export const handleHighlightRepliedMessage = (messageId: number): void => {
  const toBeHighlightedElement = document.getElementById(`messageId-${messageId}`);

  toBeHighlightedElement?.classList.add('chat-message-highlight');
};
