import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocale } from 'react-admin';

import { showImageDialogAction } from '../../../redux/showImage/action';
import MultiTabTableRelationView from './multi-tab-table-relation.view';
import useMultiReportMetaHandler from './use-multi-report-meta-handler';
import { getReportChildren, getTranslatedName } from '../../../helper/meta-helper';
import LoadingBox from '../../LoadingBox';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
  FormKeyMode,
  RecordKeyMode,
  RequestParametersInterface,
} from '../../../type/actor-setup';
import {
  getFieldsForDisplay,
  isReportEditable,
  isReportExecutable,
  requestRelationData,
} from '../relation-panel.helper';

import type {
  MultiTabTableRelationControllerInterface,
  ReportMetaDataInterface,
  ReportRelationChild,
} from './multi-tab-table-relation.type';
import { apiRequestResultRelationHandler } from '../../../helper/crud-api.helper';

const MultiTabTableRelationController: FC<
  MultiTabTableRelationControllerInterface
> = props => {
  const { relationPanelBaseProps } = props;
  const {
    relationData,
    relationDataCount,
    relationPermission,
    relationMetaData,
    settings,
    relationResource,
    childFieldName,
    parentFieldName,
    currentUrl,
    parentInfo,
    relationType,
  } = relationPanelBaseProps;
  const { defaultSelected, userSelected, userSort } = settings;
  const { disabledFieldList } = relationPermission;
  const { parentResource, parentId } = parentInfo;

  const reduxDispatch = useDispatch();

  const childResourceList: Array<ReportRelationChild> = [];
  const minimizedRelation = { parentFieldName, childFieldName };
  const isMultiResult = relationMetaData?.['reportType'] === 'MultiResult';

  const [relationsData, setRelationsData] = useState<{
    [resource: string]: { data: Array<Record<string, unknown>>; totalCount: number };
  }>({
    [relationResource]: { data: relationData, totalCount: relationDataCount },
  });

  const locale = useLocale();

  const reportChildren = getReportChildren(
    relationMetaData,
    locale,
  ) as unknown as Array<ReportMetaDataInterface>;

  const parentRecord = actorGetActionValue(
    'record',
    `${parentResource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
  ) as Record<string, unknown>; // when code arrive here , 100% parent record exists!

  useEffect(() => {
    actorOnDispatch('gridData', gridData => {
      const preparedData = {};

      if (!isMultiResult) {
        // because all of multi result relations have index at ent of their resource and its not necessary to
        // add main resource to prepared data
        preparedData[relationResource] = {
          data: gridData[relationResource]?.data,
          totalCount: gridData[relationResource]?.totalCount ?? 0,
        };
      }

      reportChildren.forEach(child => {
        preparedData[child.childResource] = {
          data: gridData[child.childResource]?.data,
          totalCount: gridData[child.childResource]?.totalCount ?? 0,
        };
      });

      setRelationsData(preparedData);
    });

    actorSetActionValue('activeTab', 0, { path: relationResource });
  }, []);

  /**
   * change sort in grid data object in actor and request data with new parameters
   * @function setSort
   * @param {string} resource
   * @returns {function}
   */
  const setSort =
    (resource: string) =>
    (field: string): void => {
      let newSort = {};

      const prevSort = actorGetActionValue(
        'gridData',
        `${resource}.requestParameters.sort`,
      ) as RequestParametersInterface['sort'];

      if (prevSort?.field === field) {
        newSort = {
          field,
          order: prevSort.order === 'asc' ? 'desc' : 'asc',
        };
      } else {
        newSort = {
          field,
          order: 'desc',
        };
      }

      actorSetActionValue('gridData', newSort, {
        path: `${resource}.requestParameters.sort`,
      });

      requestRelationData(
        resource,
        relationType,
        parentId,
        childFieldName,
        relationMetaData,
        undefined, // no custom request parameters
        apiRequestResultRelationHandler,
      );
    };

  /**
   * change selected ids in grid data object in actor a
   * @function onSelectCheckbox
   * @param {string} resource
   * @returns {function}
   */
  const onSelectCheckbox =
    (resource: string) =>
    (selectedIds: Array<string | number>): void => {
      const castedValues = selectedIds.map(id => id?.toString());

      actorDispatch('gridData', castedValues, {
        path: `${resource}.selectedIds`,
      });
    };

  // --------------- pagination ---------------
  /**
   * change page in grid data object in actor and request data with new parameters
   * @function setPage
   * @param {string} resource
   * @returns {function}
   */
  const setPage = resource => page => {
    const prevPage = actorGetActionValue(
      'gridData',
      `${resource}.requestParameters.pagination.page`,
    );
    if (prevPage === page) return;

    actorSetActionValue('gridData', page, {
      path: `${resource}.requestParameters.pagination.page`,
    });

    requestRelationData(
      resource,
      relationType,
      parentId,
      childFieldName,
      relationMetaData,
      undefined, // no custom request parameters
      apiRequestResultRelationHandler,
    );
  };

  /**
   * change perPage in grid data object in actor and request data with new parameters
   * @function serPerPage
   * @param {string} resource
   * @returns {function}
   */
  const setPerPage = resource => perPage => {
    const prevPerPage = actorGetActionValue(
      'gridData',
      `${resource}.requestParameters.pagination.perPage`,
    );
    if (prevPerPage === perPage) return;

    actorSetActionValue('gridData', 1, {
      path: `${resource}.requestParameters.pagination.page`,
    });
    actorSetActionValue('gridData', perPage, {
      path: `${resource}.requestParameters.pagination.perPage`,
    });

    requestRelationData(
      resource,
      relationType,
      parentId,
      childFieldName,
      relationMetaData,
      undefined, // no custom request parameters
      apiRequestResultRelationHandler,
    );
  };

  // --------------- table handlers ---------------
  /**
   * //TODO should complete
   * @function onRowClick
   */
  const onRowClick = resource => row => {
    console.log('row: ', row);
  };

  /**
   * //TODO should complete
   * @function quickEditRowCallback
   */
  const quickEditRowCallback = (resource: string) => (row: string) => {
    console.log('row: ', row);
  };

  /**
   * @function showImageDialog
   */
  const showImageDialog = (data: Record<string, unknown>): void => {
    reduxDispatch(showImageDialogAction({ url: data.url }));
  };

  /**
   * //TODO should complete
   * @function addToFilterRequestList
   */
  const addToFilterRequestList = (data: Record<string, unknown>): void => {
    console.log('field: ', data);
  };

  // --------------- tabs ---------------
  if (isReportExecutable(relationMetaData)) {
    const isParentReportEditable = isReportEditable(relationMetaData);

    const fields = getFieldsForDisplay(
      defaultSelected,
      userSelected,
      relationMetaData,
      disabledFieldList,
    );

    actorSetActionValue(
      'relationFieldsForDisplay',
      fields?.map(field => field.name).join(','),
      { path: relationResource },
    );

    const currentRequestParams = actorGetActionValue(
      'gridData',
      `${relationResource}.requestParameters`,
    ) as unknown as RequestParametersInterface;

    childResourceList.push({
      title: getTranslatedName(relationMetaData, locale),
      resource: relationResource,
      metaData: relationMetaData,
      quickEditButton: isParentReportEditable,
      quickEditRowCallback: isParentReportEditable ? quickEditRowCallback : null,
      fields,
      data: relationsData[relationResource]?.data,
      currentPage: currentRequestParams?.pagination.page ?? 1,
      currentPerPage: currentRequestParams?.pagination.perPage ?? 25,
      dataTotalCount: relationsData[relationResource]?.totalCount ?? 0,
    });
  }

  // --------------- handle childs meta data ---------------
  const childrenMetaDataList = useMultiReportMetaHandler(
    reportChildren,
    relationResource,
    relationMetaData,
  );

  if (!childrenMetaDataList) {
    return <LoadingBox />;
  }

  reportChildren.forEach((report, index) => {
    const { childResource, title } = report;

    const childMetaData = childrenMetaDataList[childResource];

    if (childMetaData) {
      const _isReportEditable = isReportEditable(childMetaData);
      const fields = getFieldsForDisplay(
        defaultSelected,
        userSelected,
        childMetaData,
        disabledFieldList,
      );

      actorSetActionValue(
        'relationFieldsForDisplay',
        fields?.map(field => field.name).join(','),
        { path: relationResource },
      );

      const currentRequestParams = actorGetActionValue(
        'gridData',
        `${childResource}.requestParameters`,
      ) as unknown as RequestParametersInterface;

      childResourceList.push({
        title: title || getTranslatedName(childMetaData, locale) || index.toString(),
        resource: childResource,
        metaData: childMetaData,
        quickEditButton: _isReportEditable,
        quickEditRowCallback: _isReportEditable ? quickEditRowCallback : null,
        fields,
        data: relationsData[childResource]?.data,
        currentPage: currentRequestParams?.pagination.page ?? 1,
        currentPerPage: currentRequestParams?.pagination.perPage ?? 25,
        dataTotalCount: relationsData[childResource]?.totalCount ?? 0,
      });
    }
  });

  /**
   * request multi tab data if they have not
   * @function onTabChange
   * @param {string} tabIndex as string
   * @returns {void}
   */
  const onTabChange = (tabIndex: string): void => {
    const resource = Object.keys(relationsData)[tabIndex];
    actorDispatch('activeTab', +tabIndex, { path: relationResource });

    if (!relationsData?.[resource]?.data) {
      requestRelationData(
        resource,
        'multiReport',
        parentInfo.parentId,
        childFieldName,
        childResourceList[tabIndex].metaData ?? null,
        undefined, // no custom request parameters
        apiRequestResultRelationHandler,
      );
    }
  };
  /**
   * change filter in grid data object in actor and request data with new parameters
   * @function setFilters
   * @param {string} resource
   * @returns {function}
   */
  const setFilters =
    resource =>
    (newFilters: Record<string, unknown>): void => {
      actorSetActionValue('gridData', newFilters, {
        path: `${resource}.requestParameters.searchFilters`,
        replaceAll: true,
      });

      requestRelationData(
        relationResource,
        relationType,
        parentId,
        childFieldName,
        relationMetaData,
        undefined, // no custom request parameters
        apiRequestResultRelationHandler,
      );
    };

  return (
    <div>
      <MultiTabTableRelationView
        addToFilterRequestList={addToFilterRequestList}
        quickEditRowCallback={quickEditRowCallback}
        childResourceList={childResourceList}
        onSelectCheckbox={onSelectCheckbox}
        showImageDialog={showImageDialog}
        relation={minimizedRelation}
        parentRecord={parentRecord}
        onTabChange={onTabChange}
        setFilters={setFilters}
        currentUrl={currentUrl}
        onRowClick={onRowClick}
        parentInfo={parentInfo}
        setPerPage={setPerPage}
        setSort={setSort}
        setPage={setPage}
        sort={userSort}
      />
    </div>
  );
};

export default MultiTabTableRelationController;
