import { FC, memo, useEffect, useState } from 'react';

import { ChatActions, OnSendContentParams, UploadedFile } from '..';
import { isEmpty, isEmptyObject } from '../../../helper/data-helper';
import { actorGetActionValue, actorOnDispatch } from '../../../type/actor-setup';
import { UploadFilesFormInterface } from './upload-files-form.type';
import UploadFilesFormView from './upload-files-form.view';

const UploadFilesFormController: FC<UploadFilesFormInterface> = memo(props => {
  const { chatActionsHandler } = props;

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  /**
   * @function handleSendMessage
   * @returns { void }
   */
  const handleSendMessage = (): void => {
    chatActionsHandler(ChatActions.onSendMessage);
  };

  /**
   * @function handleSendSingleFile
   * @param { UploadedFile } file
   * @param { string } text
   * @returns { void }
   */
  const handleSendSingleFile = (file: UploadedFile, text: string) => {
    chatActionsHandler(ChatActions.onSendContent, {
      chattext: text,
      fileurl: JSON.stringify(file),
    } as OnSendContentParams);
  };

  /**
   * @function handleSendFiles
   * @returns { void }
   */
  const handleSendFiles = (): void => {
    if (!isEmptyObject(uploadedFiles)) {
      if (uploadedFiles.length === 1) {
        const singleFile = uploadedFiles[0];
        const chatText = actorGetActionValue('chatText')!;
        const text = !isEmpty(chatText) ? chatText : singleFile?.realFileName;
        handleSendSingleFile(singleFile, text);
      } else {
        handleSendMessage();
        for (const file of uploadedFiles) {
          handleSendSingleFile(file, file.realFileName);
        }
      }
    }
  };

  useEffect(() => {
    actorOnDispatch('uploadedFiles', uploadedFiles => {
      uploadedFiles && setUploadedFiles(uploadedFiles);
    });
  }, []);

  return (
    <UploadFilesFormView
      chatActionsHandler={chatActionsHandler}
      uploadedFiles={uploadedFiles}
      handleSendFiles={handleSendFiles}
    />
  );
});

export default UploadFilesFormController;
