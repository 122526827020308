import React, { ReactElement, cloneElement } from 'react';
import { Menu, useTheme } from '@material-ui/core';
import {
  Collapse,
  Icon,
  IconButton,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { NavCollapseViewInterface } from './nav-collapse.type';
import { useStyles } from './nav-collapse.style';
import { NavCollapse } from '.';
import clsx from 'classnames';
import { NavItem } from '../nav-item';
import { RoutesConfigInterface } from '..';
import { isEmpty } from '../../../../helper/data-helper';

const NavCollapseView = (props: NavCollapseViewInterface): ReactElement => {
  const {
    item,
    level,
    open,
    onToggle,
    onIconToggle,
    baseUrl,
    isContextMenuOpen,
    onContextMenu,
    handleCloseContextMenu,
    contextMenuNode,
    openMenu,
    folderId,
  } = props;
  const classes = useStyles({ level });
  const theme = useTheme();

  return (
    <>
      <ListItem
        button
        component="li"
        className={clsx(classes.navItem, open && 'open')}
        onContextMenu={onContextMenu}
        data-test={item?.dataTest}
        data-style-navigation-collaps="navCollaps"
      >
        <Box p={0} clone mr={1}>
          <IconButton disableRipple>
            <Icon
              className="nav-item-icon-arrow"
              color="inherit"
              onClick={onIconToggle}
            >
              {open
                ? 'expand_more'
                : theme.direction === 'ltr'
                ? 'chevron_right'
                : 'chevron_left'}
            </Icon>
          </IconButton>
        </Box>

        {item.icon && (
          <Box component="span" mr={3}>
            <Icon color="action" className={classes.listIcon}>
              {item.icon}
            </Icon>
          </Box>
        )}
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary={`${item.title}`}
          onClick={onToggle}
        />
        {item?.count && item.count > 0 ? (
          <Box
            className={classes.countContainer}
            mr={1}
            component="span"
            onClick={onToggle}
          >
            {item.count}
          </Box>
        ) : null}

        {!isEmpty(isContextMenuOpen) && (
          <Menu
            open={isContextMenuOpen !== null}
            onClose={handleCloseContextMenu}
            anchorReference="anchorPosition"
            anchorPosition={
              isContextMenuOpen !== null
                ? { top: isContextMenuOpen.mouseY, left: isContextMenuOpen.mouseX }
                : undefined
            }
          >
            {contextMenuNode &&
              cloneElement(
                contextMenuNode as React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >,
                { ...item },
              )}
          </Menu>
        )}
      </ListItem>

      {item.children && Array.isArray(item.children) && (
        <Collapse in={open} className="collapse-children">
          {item.children.map((item: RoutesConfigInterface) => (
            <React.Fragment key={item.id}>
              {item.type === 'collapse' && (
                <NavCollapse item={item} level={level + 1} baseUrl={baseUrl} />
              )}

              {item.type === 'item' && (
                <NavItem
                  item={item}
                  level={level + 1}
                  baseUrl={baseUrl}
                  isContextMenuOpen={isContextMenuOpen}
                  setParentOpen={openMenu}
                  onContextMenu={onContextMenu}
                  handleCloseContextMenu={handleCloseContextMenu}
                  contextMenuNode={item?.contextMenuNode}
                  parentId={folderId}
                />
              )}
            </React.Fragment>
          ))}
        </Collapse>
      )}
    </>
  );
};

export default NavCollapseView;
