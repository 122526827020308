import lodashGet from 'lodash/get';
import { API_URL, API_VERSION, getValue } from '../core/configProvider';

export const getPrintResource = id => `print/${id}`;

export function hasPrint(list) {
  return !!lodashGet(list, ['prints', 'length']);
}

export function getPrintList(list) {
  if (!list || !list.prints || !list.prints.length) {
    return null;
  }

  return list.prints;
}

export class PrintPopupOpener {
  setTranslate(translate) {
    this.translate = translate;
  }

  openPrintInNewWindow = (title, meta, data, afterRenderCallback) => {
    const apiEnv = `${getValue(API_URL)}/${getValue(API_VERSION)}`;

    const newTab = window.open(
      '/print.html',
      '_blank',
      'width=800,height=600,scrollbars=1,resizable=1',
    );

    if (!newTab) {
      alert(this.translate('print.pleaseGiveBrowserAccessToOpenPopup'));
      return;
    }

    // window.focus(); // get focus back to current page

    this.checkIfNewWindowIsReady(newTab, () => {
      newTab.renderReport(title, meta, data, apiEnv);
      if (typeof afterRenderCallback === 'function') {
        afterRenderCallback();
      }
    });
  };

  checkIfNewWindowIsReady(newTab, readyCallback, tryCount = 0) {
    if (newTab && newTab.isReady && typeof newTab.renderReport === 'function') {
      readyCallback();
    } else if (tryCount > 100) {
      alert(this.translate('print.ErrorInDetectingOpenedWindow'));
      return;
    } else {
      setTimeout(() => {
        this.checkIfNewWindowIsReady(newTab, readyCallback, ++tryCount);
      }, 500);
    }
  }
}
