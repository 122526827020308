import React, { FC, useState, useEffect } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import { useTranslate, Record } from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { Button, Icon } from '@material-ui/core';

import placeholder from '../../images/placeholder.png';
import {
  getValue,
  API_URL,
  CONFIG_CURRENCY_SYMBOL,
} from '../../core/configProvider';
import { SELLIN_SHOW } from '../../core/configRouteConstant';
import ShopAddToCartButtonCounter from '../shop/ShopAddToCartButtonCounter';
import { CustomTheme } from '../../core/themeProvider';
import { SellinListPatternType, SellinSearchPatternType } from '../../helper/Types';

interface CardListItemViewProps {
  record: Record;
  pattern: SellinListPatternType | SellinSearchPatternType;
  onAddToCart?: Function;
  onRemoveFromCart?: Function;
  basketInfo?: object;
  count: number;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  },

  cardLink: {
    display: 'flex',
    flexBasis: '30%',
  },

  cardContentLink: {
    display: 'flex',
    flexDirection: 'column',
    height: 100,
    padding: '15px 0',
  },

  cardMedia: {
    width: '100%',
    backgroundImage: `url(${placeholder})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },

  cardContent: {
    flexGrow: 1,
    alignContent: 'flex-end',
    padding: '0 10px 15px 10px !important',
    display: 'flex',
    flexDirection: 'column',
  },

  productName: {
    flexGrow: 1,
  },

  productPrice: {
    fontWeight: 'bold',
  },

  addToCartButton: {
    padding: '7px 16px',
    alignSelf: 'flex-end',
    color: theme.palette.primary.appSecondaryTextColor,
  },

  shopAddToCartButtonCounter: {
    alignSelf: 'flex-end',
  },
}));

const formatNumberToPrice = (num: number) => {
  if (!num) {
    return 0;
  }

  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const CardListItemView: FC<CardListItemViewProps> = props => {
  const {
    record,
    pattern,
    onAddToCart,
    onRemoveFromCart,
    count,
    basketInfo = {},
  } = props;
  const { id, name, imagePath, imageFileName, price, targetResource, hasBarcode } =
    pattern;
  const classes = useStyles();
  const translate = useTranslate();
  const [counter, setCounter] = useState(count);

  useEffect(() => {
    setCounter(count);
  }, [count]);

  const apiUrl: string = getValue(API_URL);

  const addToCart = () => {
    setCounter(counter + 1);
    if (typeof onAddToCart === 'function') {
      onAddToCart(record, counter + 1);
    }
  };

  const inputType = event => {
    const parsedValue = parseFloat(event.target.value);
    const changedValue = isNaN(parsedValue) || parsedValue <= 1 ? 1 : parsedValue;
    if (typeof onAddToCart === 'function') {
      onAddToCart(record, changedValue);
    }
  };

  const removeOfCart = () => {
    if (counter === 1) {
      setCounter(counter - 1);
      if (typeof onRemoveFromCart === 'function') {
        onRemoveFromCart(basketInfo[id]);
      }
    } else {
      setCounter(counter - 1);
      if (typeof onAddToCart === 'function') {
        onAddToCart(record, counter - 1);
      }
    }
  };

  return (
    <Card className={classes.card}>
      <Link
        className={classes.cardLink}
        underline="none"
        component={RouterLink}
        to={`/${SELLIN_SHOW}/${targetResource}/${record[id]}`}
      >
        <CardMedia
          className={classes.cardMedia}
          image={
            record[imageFileName]
              ? `${apiUrl}/${record[imagePath]}/thumbnail/${record[imageFileName]}`
              : placeholder
          }
          title={record[name]}
        />
      </Link>
      <CardContent className={classes.cardContent}>
        <Link
          className={classes.cardContentLink}
          underline="none"
          component={RouterLink}
          to={`/${SELLIN_SHOW}/${targetResource}/${record.id}`}
        >
          <Typography
            component="h3"
            className={classes.productName}
            color="textPrimary"
          >
            {record[name]}
          </Typography>
          <Typography
            component="span"
            className={classes.productPrice}
            color="textPrimary"
          >
            {formatNumberToPrice(record[price])}{' '}
            {getValue(CONFIG_CURRENCY_SYMBOL) || translate('shop.basket.currency')}
          </Typography>
        </Link>

        {typeof onAddToCart === 'function' &&
          !record[hasBarcode] &&
          (counter > 0 ? (
            <ShopAddToCartButtonCounter
              className={classes.shopAddToCartButtonCounter}
              counter={counter}
              onRemoveButton={removeOfCart}
              onAddButton={addToCart}
              onInputType={inputType}
            />
          ) : (
            <Button
              className={classes.addToCartButton}
              color="secondary"
              variant="contained"
              size="medium"
              onClick={addToCart}
            >
              <Icon fontSize="small">shopping_cart</Icon>
              &nbsp;&nbsp;{translate('sellin.addToCart')}
            </Button>
          ))}
      </CardContent>
    </Card>
  );
};

export default CardListItemView;
