import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import {
  FoundedMessageInterface,
  SearchListViewInterface,
} from './search-list.type';
import { useStyles } from './search-list.style';
import Box from '@material-ui/core/Box';
import { isEmptyObject } from '../../../../helper/data-helper';
import { ContactInterface } from '../..';
import { UserItem } from '../user-list/user-item';
import { ChatItem } from '../user-list/chat-item';

const SearchListView = (props: SearchListViewInterface): ReactElement => {
  const { contacts, messages, onUserSelect, selectedUser, onSelectMessage } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box className={classes.container} width={1}>
      <Box mb={2} className={classes.titleContainer}>
        <span>{translate('chat.contacts')}</span>
      </Box>
      {!isEmptyObject(contacts) ? (
        contacts &&
        contacts?.map((item: ContactInterface) => {
          return (
            <UserItem
              key={item.personinfo_id}
              selectedUser={selectedUser}
              onUserSelect={item => onUserSelect(item)}
              item={item}
            />
          );
        })
      ) : (
        <span>{translate('chat.noUserFound')}</span>
      )}

      <Box mt={2} mb={2} className={classes.titleContainer}>
        <span>{translate('chat.messages')}</span>
      </Box>

      {!isEmptyObject(messages) ? (
        messages?.map((item: FoundedMessageInterface) => {
          return (
            <Box width={1}>
              {' '}
              <ChatItem
                key={item.chat_id}
                item={{
                  ...item,
                  personinfo_id: item.topersoninfo_id,
                }}
                selectedUser={selectedUser}
                onUserSelect={item => onSelectMessage(item)}
              />
            </Box>
          );
        })
      ) : (
        <span>{translate('chat.noMessagesFound')}</span>
      )}
    </Box>
  );
};

export default SearchListView;
