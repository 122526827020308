import React, { useState, useRef, useEffect } from 'react';
import { useLocale } from 'react-admin';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';

import DevExGrid from '../component/DevExGrid';
import ListActions from '../component/ListActions';
import SettingToolbar from '../component/SettingToolbar';
import ListContainer from './ListContainer';
import ListFilter from '../component/ListFilter';
import ListBulkActions from './ListBulkActions';
import {
  getGridColumns,
  getTranslatedName,
  getFieldsById,
} from '../helper/MetaHelper';
import NotFound from '../component/NotFound';
import LoadingBox from '../component/LoadingBox';
import { getAppSettings } from '../helper/settings-helper';
import { CONFIG_LIST_COLUMN_CHOICE, DEFAULT } from '../core/configProvider';
import {
  actorGetActionValue,
  actorOnDispatch,
  waitForAction,
  actorDispatch,
} from '../type/actor-setup';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
  },

  withHeight: {
    minHeight: 600, // FIXME: its temporary, add correct style
  },

  DynamicListContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
  },

  list: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  children: {
    flexGrow: 1,
    overflow: 'auto',
  },

  slide: {
    flexShrink: 0,
    padding: 10,
    borderLeft: `1px solid ${theme.palette.divider}`,
    overflowY: 'auto',
    backgroundColor: theme.palette.primary.appPrimaryBackgroundColor,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 40,
      right: 0,
      bottom: 0,
      zIndex: 2,
    },
  },

  sidebar: {
    width: '350px',
    flexShrink: 0,
    borderLeft: `1px solid ${theme.palette.divider}`,
    overflowY: 'auto',
    backgroundColor: theme.palette.primary.appPrimaryBackgroundColor,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
      width: '100%',
    },
  },

  listCard: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  childrenContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
}));

const DynamicList = props => {
  const {
    dispatch, // get it out
    resource,
    childResourceList,
    defaultTabIndex,
    crudGetList,
    isAdminLoading,
    metaData,
    metaDataLoading,
    metaDataError,
    theme,
    listActionComponent,
    listFilterComponent,
    viewComponent,
    slideComponent,
    sidebarItemComponent,
    isSlideOpen,
    isSidebarItemOpen,
    filter,
    filterDefaultValues,
    permanentFilter,
    onRootClick,
    onRowClick,
    enableSelection,
    perPage,
    pagination,
    sort,
    isTopFilterOpen,
    isGroupingOpen,
    isFilterEnable,
    isGroupingEnable,
    isColumnChoiceEnable,
    treeLevel,
    listBulkActionComponent,
    quickEditRowCallback,
    settingToolbarComponent,
    actionEditColumnCount,
    relationMode,
    additionalViewComponentProps,
    enableSetSetting,
    selectedIds,
    isWMS,
    hideFilters,
    accordionPortalId,
    isSelectedItemsCount,
    isAccessPath,
    isMultiReport,
    ...rest
  } = props;

  const [disableDelete, setDisableDelete] = useState(false); // will be true if check a parent row in tree
  const selectionRef = useRef();

  const classes = useStyles(props);
  const locale = useLocale();

  const [loading, setLoading] = useState(true); //fixme it will not turn true when resource change

  // loading
  useEffect(() => {
    //don't add actorOnDispatch('showLoading');it makes a bug when you change tab in multi report
    actorOnDispatch('showSettingsLoading', async showSettingsLoading => {
      await waitForAction('showLoading');
      const showLoading = actorGetActionValue('showLoading');
      if (showLoading === false && showSettingsLoading === false) {
        setLoading(false);
      }
    });

    actorOnDispatch(
      'showLoading',
      async showLoading => {
        await waitForAction('showSettingsLoading');
        const showSettingsLoading = actorGetActionValue('showSettingsLoading');
        if (showLoading === false && showSettingsLoading === false) {
          setLoading(false);
        }
      },
      {
        preserve: false,
      },
    );

    actorOnDispatch('refreshView', refreshResource => {
      if (refreshResource === resource) forceUpdate();
    });
  }, []);

  useEffect(() => {
    actorDispatch(
      'setDocumentTitle',
      { metaData: metaData, locale: locale },
      { replaceAll: true },
    );
  }, [resource]);

  const { hasCreate, hasEdit, hasShow, hasDelete } = props;
  const isMetaLoaded = !!metaData;

  const forceUpdate = React.useReducer(() => ({}))[1];

  if (metaDataError) {
    return <NotFound title={metaDataError} />;
  }

  if (!isMetaLoaded) {
    return <LoadingBox />;
  }

  const getFieldsForDisplay = (resource, metaData) => {
    if (!enableSetSetting) {
      return getGridColumns({ metaData });
    }

    const defaultSelected = getAppSettings(
      DEFAULT + '_' + CONFIG_LIST_COLUMN_CHOICE + '_' + resource,
    ).value;
    const userSelected = getAppSettings(
      CONFIG_LIST_COLUMN_CHOICE + '_' + resource,
      true,
    ).value;

    // if user has selected column order
    if (userSelected && userSelected.length) {
      return getFieldsById(metaData, userSelected);
    }
    // or admin as selected default order
    else if (defaultSelected && defaultSelected.length) {
      return getFieldsById(metaData, defaultSelected);
    }
    // else show all columns
    else {
      return getGridColumns({ metaData, filterFirstFive: true });
    }
  };

  const fields = getFieldsForDisplay(resource, metaData);

  const listActionProps = {
    resource,
    locale,
    metaData,
    hasCreate,
    hasDelete,
    treeLevel,
    disableDelete,
    selectionRef,
    selectedIds,
    accordionPortalId,
    isSelectedItemsCount,
    isAccessPath,
  };

  const listBulkActionProps = {
    locale,
    metaData,
    hasDelete,
    treeLevel,
    disableDelete,
  };

  const settingToolbarProps = {
    locale,
    metaData,
    resource,
    isFilterEnable,
    isGroupingEnable,
    isColumnChoiceEnable,
    fields,
  };

  const listFilterProps = {
    resource,
    metaData,
    locale,
  };

  const viewComponentProps = {
    resource,
    metaData,
    hasEdit,
    hasShow,
    enableSelection,
    className: classes.children,
    fields,
    onRowClick,
    isSlideOpen,
    isSidebarItemOpen,
    isTopFilterOpen,
    isGroupingOpen,
    treeLevel,
    quickEditButton: !!quickEditRowCallback,
    actionEditColumnCount:
      typeof actionEditColumnCount !== 'undefined'
        ? actionEditColumnCount
        : typeof quickEditRowCallback === 'function'
        ? 2
        : 1,
    quickEditRowCallback,
    setDisableDelete,
    selectionRef,
    isMultiReport,
    ...additionalViewComponentProps,
  };

  return (
    <div
      data-style-dynamic-list="dynamicList"
      className={classNames(
        classes.container,
        relationMode ? '' : classes.withHeight,
      )}
    >
      <div className={classes.DynamicListContainer} onClick={onRootClick}>
        <ListContainer
          {...rest}
          classes={{
            card: classes.listCard,
            childrenContainer: classes.childrenContainer,
          }}
          className={classes.list}
          resource={resource}
          childResourceList={childResourceList}
          actions={
            listActionComponent
              ? React.cloneElement(listActionComponent, listActionProps)
              : null
          }
          settingToolbar={
            settingToolbarComponent
              ? React.cloneElement(settingToolbarComponent, settingToolbarProps)
              : null
          }
          filter={filter}
          filters={
            listFilterComponent && !hideFilters
              ? React.cloneElement(listFilterComponent, listFilterProps)
              : null
          }
          filterDefaultValues={filterDefaultValues}
          title={getTranslatedName(metaData, locale)}
          debounce={1000}
          bulkActionButtons={
            listBulkActionComponent && isWMS
              ? React.cloneElement(listBulkActionComponent, listBulkActionProps)
              : null
          }
          perPage={perPage}
          pagination={pagination}
          sort={sort}
          gridProps={viewComponentProps}
          metaData={metaData}
          defaultTabIndex={defaultTabIndex}
          getFieldsForDisplay={getFieldsForDisplay}
          enableSetSetting={enableSetSetting}
          data-test-list-resource={resource}
          isWMS={isWMS}
          loading={loading}
        >
          {React.cloneElement(viewComponent, viewComponentProps)}
        </ListContainer>
      </div>
      {sidebarItemComponent && (
        <Slide
          className={classes.sidebar}
          direction="right"
          in={isSidebarItemOpen}
          mountOnEnter
          unmountOnExit
        >
          <div>
            {React.cloneElement(sidebarItemComponent, {
              parentResource: resource,
              parentMetaData: metaData,
            })}
          </div>
        </Slide>
      )}
      {slideComponent && (
        <Slide
          className={classes.slide}
          direction="right"
          in={isSlideOpen}
          mountOnEnter
          unmountOnExit
        >
          <div>
            <div className={classes.toolbar} />
            {React.cloneElement(slideComponent, {
              parentResource: resource,
              parentMetaData: metaData,
            })}
          </div>
        </Slide>
      )}
    </div>
  );
};

DynamicList.propTypes = {
  classes: PropTypes.object,
  resource: PropTypes.string.isRequired,
  childResourceList: PropTypes.array,
  metaData: PropTypes.object,
  metaDataLoading: PropTypes.bool,
  metaDataError: PropTypes.string,
  filterDefaultValues: PropTypes.object,
  filter: PropTypes.object,
  onRowClick: PropTypes.func,
  onRootClick: PropTypes.func,
  enableSelection: PropTypes.bool,
  perPage: PropTypes.number,
  pagination: PropTypes.element,
  sort: PropTypes.object,
  viewComponent: PropTypes.element,
  slideComponent: PropTypes.element,
  sidebarItemComponent: PropTypes.element,
  isSlideOpen: PropTypes.bool,
  isSidebarItemOpen: PropTypes.bool,
  isTopFilterOpen: PropTypes.bool,
  isGroupingOpen: PropTypes.bool,
  hasDelete: PropTypes.bool,
  isColumnChoiceEnable: PropTypes.bool,
  treeLevel: PropTypes.number,
  defaultTabIndex: PropTypes.number,
  hasReportEditable: PropTypes.bool,
  quickEditRowCallback: PropTypes.func,
  useSimpleApi: PropTypes.bool,
  relationMode: PropTypes.bool,
  additionalViewComponentProps: PropTypes.object,
  withFile: PropTypes.bool,
  enableSetSetting: PropTypes.bool,
  selectedIds: PropTypes.array,
  hideFilters: PropTypes.bool,
  isWMS: PropTypes.bool,
  match: PropTypes.object,
  location: PropTypes.object,
  hasList: PropTypes.bool,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  basePath: PropTypes.string,
  actionEditColumnCount: PropTypes.number,
  listFilterComponent: PropTypes.element,
  accordionPortalId: PropTypes.string,
  isMultiReport: PropTypes.bool,
};

DynamicList.defaultProps = {
  listBulkActionComponent: <ListBulkActions />,
  listActionComponent: (
    <ListActions quickCreateMustRefresh listColumnChoiceMustRefresh />
  ),
  settingToolbarComponent: <SettingToolbar />,
  listFilterComponent: <ListFilter />,
  viewComponent: <DevExGrid />,
  filterDefaultValues: null,
  isSlideOpen: false,
  isSidebarItemOpen: false,
  isFilterEnable: true,
  isGroupingEnable: true,
  hasDelete: false,
  isColumnChoiceEnable: true,
  withFile: false,
  enableSetSetting: true,
  match: {},
  location: {},
  hasList: false,
  hasCreate: false,
  hasEdit: false,
  hasShow: false,
  isMultiReport: false,
  basePath: '',
  actionEditColumnCount: 0,
  accordionPortalId: 0,
};

export default DynamicList;
