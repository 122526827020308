import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import { Checkbox, makeStyles, Icon, Link } from '@material-ui/core';

import { getTodoTaskDetailsPatternInfo } from '../../helper/PatternMetaHelper';
import { CustomTheme } from '../../core/themeProvider';
import { isEmptyObject } from '../../helper/data-helper';
import LoadingBox from '../LoadingBox';
import TodoItemEditView from './TodoItemEditView';
import { callQuickAccess, openNewTab } from '../../helper/QuickAccessHelper';
import { useTranslate } from 'react-admin';
interface TodoTaskItemEditProps {
  task: object;
  dataTask: Record<string, unknown>;
  resource: string;
  detailResource: string;
  onClick: Function;
  onChange: Function;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  importantIcon: {
    color: theme.palette.primary.appPrimaryDisableIconColor,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.appPrimaryIconColor,
    },
  },
  fieldLink: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    textAlign: 'center',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const TodoTaskItemEdit: FC<TodoTaskItemEditProps> = props => {
  const { task, dataTask, resource, onChange, detailResource } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const pattern = getTodoTaskDetailsPatternInfo(resource);
  const { isImportant, id } = pattern;

  const [itemIsImportant, setItemIsImportant] = useState(task[isImportant]);

  useEffect(() => {
    setItemIsImportant(task[isImportant]); // it causes pessimistic update
  }, [task]);

  const starItem = event => {
    setItemIsImportant(event.target.checked); // it causes optimistic update
    submitValues({ [isImportant]: event.target.checked });
  };

  /**
   * Submit changes to update task
   * @function submitValues
   * @param {object} input
   * @returns {undefined}
   */
  const submitValues = (input: object): undefined => {
    const data = {};
    let changeCounter = 0;
    Object.keys(input).forEach(key => {
      if (task[key] !== input[key]) {
        data[key] = input[key];
        changeCounter++;
      }
    });
    if (changeCounter) {
      onChange({
        data,
        ref: null,
        itemResource: detailResource,
        itemId: task[id],
      });
    }
    return;
  };
  /**
   * @function handleClickLink
   * @param event
   * @return {Promise<void>}
   */
  const handleClickLink = async (event): Promise<void> => {
    event.preventDefault();
    const { docid, doctypes_id } = dataTask;
    const url: string = await callQuickAccess(`${doctypes_id}.${docid}`);
    openNewTab(url);
  };

  if (!task || isEmptyObject(task)) {
    return <LoadingBox />;
  }

  return (
    <TodoItemEditView
      mode="task"
      pattern={pattern}
      item={task}
      submit={submitValues}
      dndType=""
      ActionElement={
        <>
          {dataTask?.docid && dataTask?.doctypes_id && (
            <Link className={classes.fieldLink} onClick={handleClickLink}>
              {translate('todo.link')}
            </Link>
          )}
          <Checkbox
            value={isImportant}
            checked={itemIsImportant}
            onChange={starItem}
            className={classes.importantIcon}
            color="secondary"
            icon={<Icon>star_border</Icon>}
            checkedIcon={<Icon>star</Icon>}
            disableRipple
            data-test-sidebar-task-important={itemIsImportant}
          />
        </>
      }
    />
  );
};

const mapStateToProps = (state, props) => {
  return {
    task: lodashGet(
      state,
      ['admin', 'resources', props.resource, 'data', props.taskId],
      {},
    ),
  };
};

export default connect(mapStateToProps, {})(TodoTaskItemEdit);
