import { makeStyles } from '@material-ui/core/styles';

import { CustomTheme, themeParams } from '../../../core/themeProvider';
import { ThemeProps } from './custom-app-bar.type';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  appBar: {
    background: themeParams.palette.primary.gradientAppBar,
    transition: 'all 0.5s ease !important',
  },

  mainHeader: {
    minHeight: 50,
    maxHeight: 50,
    paddingRight: 16,
    paddingLeft: 16,
    [theme.breakpoints.down('xs')]: {
      minHeight: 40,
      maxHeight: 40,
    },
    '& > button': {
      padding: 5,
      width: 'auto',
      height: 'auto',
    },
    transition: 'all 0.5s ease !important',
  },

  menuButton: {
    padding: 2,
  },

  appBarTitleParent: {
    height: '100%',
    padding: '0 10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },

    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
    color: 'white',
  },

  appBarTitle: {
    flexGrow: 1,
    padding: '0 10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  loading: {
    margin: 8,
  },

  appBarRight: {
    [theme.breakpoints.up('sm')]: {
      marginRight: ({ drawerWidth }: ThemeProps) => drawerWidth,
      width: ({ drawerWidth }: ThemeProps) => `calc(100% - ${drawerWidth}px)`,
    },
  },

  appBarLeft: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: ({ drawerWidth }: ThemeProps) => drawerWidth,
      width: ({ drawerWidth }: ThemeProps) => `calc(100% - ${drawerWidth}px)`,
    },
  },

  wmsLink: {
    color: theme.palette.primary.appPrimaryToolbarBackgroundColor,
  },
}));
