import React, { Fragment, useEffect, useState } from 'react';
import lodashGroupBy from 'lodash/groupBy';
import lodashFind from 'lodash/find';
import lodashGet from 'lodash/get';
import { useLocale } from 'react-admin';
import grey from '@material-ui/core/colors/grey';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { isStringWthStarsMatch } from '../../helper/TextHelper';

const useStyles = makeStyles(theme => ({
  innerparent: {
    position: 'relative',
    width: '100%',
    flexGrow: 1,
    height: 1,
  },

  menulist: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    overflowY: 'auto',
    height: '100%',
  },

  menulistLiContainer: {
    '&:hover': {
      backgroundColor: grey[200],
    },
    cursor: 'pointer',
    '& a': {
      padding: '12px 15px',
      display: 'block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },

  menulistLiParentContainer: {
    padding: '10px 15px',
    backgroundColor: '#F5F5F5',
    fontSize: 11,
    color: '#616161',
  },

  menucart: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    transition: 'all 300ms ease-in-out',
  },
}));

const MenuSearchList = props => {
  const { showMenuInList, childMenuSelectHandler, menuArray, searchMenuWord } =
    props;
  const locale = useLocale();
  const classes = useStyles();

  const [groupedEntries, setGroupedEntries] = useState([]);

  useEffect(() => {
    setGroupedEntries(Object.entries(groupMenuItemsIntoObject()));
  }, [menuArray?.length, locale]);

  const groupMenuItemsIntoObject = () =>
    lodashGroupBy(
      menuArray?.filter(row => !!row.fullPath),
      row => {
        // real parent id will be like 0#0#0#
        const parentId = parseInt(row.fullPath.split('#')[0]);
        let parentRow = lodashFind(menuArray, ['id', parentId]);
        if (!parentRow) {
          parentRow = lodashFind(menuArray, ['id', row.parentid]);
        }

        if (!parentRow) {
          return '';
        }

        return lodashGet(parentRow, ['translatedTitle', locale], parentRow.title);
      },
    );

  return (
    <div className={classes.innerparent}>
      <div className={classes.menucart}>
        <ul className={classes.menulist} id="menu-search-result-list">
          {groupedEntries.map(([parentTitle, menuArray]) => {
            const result = menuArray.filter(menuItem => {
              if (
                !menuItem ||
                (typeof menuItem.children !== 'undefined' &&
                  menuItem.children.length > 0) ||
                menuItem.type === 'text'
              ) {
                return false;
              }
              return isStringWthStarsMatch(
                lodashGet(menuItem, ['translatedTitle', locale], menuItem.title),
                searchMenuWord,
              );
            });

            if (!result || !result.length) {
              return null;
            }

            return (
              <Fragment key={result[0].id}>
                <li className={classes.menulistLiParentContainer}>{parentTitle}</li>
                {result.map(menu => (
                  <li key={menu.id} className={classes.menulistLiContainer}>
                    {showMenuInList(menu, childMenuSelectHandler)}
                  </li>
                ))}
              </Fragment>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default MenuSearchList;
