import React, { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import { Button, Divider, MenuItem } from '@material-ui/core';

import MoveToFolderIcon from '../../../../../../icon/MoveToFolderIcon';
import { MoveToActionViewInterface } from './move-to-action.type';
import { useStyles } from './move-to-action.style';
import { MailFolderInterface } from '../../../..';

const MoveToActionView = (props: MoveToActionViewInterface): ReactElement => {
  const {
    folders,
    isFolderListOpen,
    onOpenFolderList,
    onCloseFolderList,
    onCreateNewFolder,
    onSubmitMailToFolder,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
      <Tooltip title={translate('mail.moveTo')}>
        <Box
          style={{ cursor: 'pointer' }}
          mt={1}
          ml={4}
          component="span"
          color="text.disabled"
          onClick={onOpenFolderList}
        >
          <MoveToFolderIcon />
        </Box>
      </Tooltip>

      {isFolderListOpen && (
        <Menu
          anchorEl={isFolderListOpen}
          open={Boolean(isFolderListOpen)}
          onClose={onCloseFolderList}
          className={classes.menuContainer}
          style={{ marginTop: 15 }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Box className={classes.itemsContainer}>
            {folders?.map((folder: MailFolderInterface) => {
              return (
                <MenuItem
                  key={folder.uniqueid}
                  onClick={() => onSubmitMailToFolder(folder)}
                >
                  {folder.treetitle}
                </MenuItem>
              );
            })}
          </Box>

          <Divider />

          <Button
            onClick={onCreateNewFolder}
            className={classes.btn}
            size="small"
            variant="text"
            color="secondary"
          >
            {translate('mail.createFolder')}
          </Button>
        </Menu>
      )}
    </>
  );
};

export default MoveToActionView;
