import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  chatContent: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    paddingTop: 20,
  },
  input: {
    height: '20px',
  },
  chatSearch: {
    position: 'relative',
    marginTop: 0,
    marginBottom: 0,
    width: '100%',

    '& .MuiOutlinedInput-input': {
      padding: '6px 14px',
      paddingLeft: 0,
      [theme.breakpoints.up('xl')]: {
        padding: '10px 14px',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.secondary,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
    },
  },
}));
