import { memo, useCallback, useEffect, useState } from 'react';
import lodashGet from 'lodash/get';
import { useTranslate } from 'react-admin';

import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../../type/actor-setup';
import {
  chatsReportId,
  contactsReportId,
  deleteMessageServiceId,
  fileUploadResource,
  getSignalRMessageType,
  handleAfterSuccessSendMessage,
  handleCreateNewMessage,
  handleEditMessage,
  handleInsertContentOnView,
  handleInsertNewSignalRMessage,
  handleUpdateMessage,
  handleUpdateUnseenMessages,
  handleِDeleteMessage,
  sendMessageReportId,
  setReachEndOfMessages,
  userContentsReportId,
} from './chat-section.helper';

import {
  ChatActions,
  ChatTotalUnseenInterface,
  CurrentUser,
  GetUserContents,
  MessageInterface,
  OnDeleteMessageParams,
  OnFetchMoreMessagesParams,
  OnMoveLastParams,
  OnMoveToRepliedMessageParams,
  OnSelectUserParams,
  OnSendContentParams,
  OnSendFileParams,
  SelectedUserType,
} from './chat-section.type';
import ChatSectionView from './chat-section.view';
import { isEmpty, isEmptyObject, megaByteToByte } from '../../helper/data-helper';
import { FoundedMessageInterface } from './chat-sidebar/search-list';
import { generateErrorMessage } from '../dynamic-input/multi-file-stream-input/multi-file-stream-input.helper';
import { SuccessResponse } from '../dynamic-input/multi-file-stream-input/multi-file-stream-input.type';
import { CHAT_SIGNAL, getEventValue } from '../../hooks/useSignalRConnection';
import { getValue, SESSION_ID, WEB_SOCKET_API_URL } from '../../core/configProvider';
import { showNotification } from '../../helper/general-function-helper';

const ChatSectionController = memo(() => {
  const translate = useTranslate();
  const webSocketApiUrl = getValue(WEB_SOCKET_API_URL);
  const sessionId = getValue(SESSION_ID);

  const [contactPage, setContactPage] = useState<number>(1);
  const [chatPage, setChatPage] = useState<number>(1);
  const [isFileUploadDialogOpen, setIsFileUploadDialogOpen] =
    useState<boolean>(false);

  const globalParameters = actorGetActionValue('globalParameters');
  const fileUploadLimitMB = lodashGet(globalParameters, 'fileUploadLimitMB', null);
  const currentUserID = lodashGet(globalParameters, 'currentUserID', null);
  const currentUserName = lodashGet(globalParameters, 'currentUserName', null);
  const currentUser: CurrentUser = {
    currentUserID: +currentUserID,
    displayName: currentUserName,
  };

  /**
   * @function handleOpenFileUploadDialog
   * @returns { void }
   */
  const handleOpenFileUploadDialog = (): void => {
    setIsFileUploadDialogOpen(true);
  };

  /**
   * @function handleCloseFileUploadDialog
   * @returns { void }
   */
  const handleCloseFileUploadDialog = (): void => {
    actorDispatch('uploadedFiles', null, {
      replaceAll: true,
    });
    actorDispatch('chatText', '');
    setIsFileUploadDialogOpen(false);
  };

  /**
   * @function successGetContactsCallback
   * @param { ContactsInterface[] } data
   * @returns { void }
   */
  const successGetContactsCallback = ({ data }): void => {
    if (isEmpty(data) || isEmptyObject(data)) {
      actorDispatch('loading', { contactsLoading: false });
      return;
    }
    actorDispatch('loading', { contactsLoading: false });
  };

  /**
   * @function successGetChatsCallback
   * @param { ChatInterface[] } data
   * @returns { void }
   */
  const successGetChatsCallback = ({ data }): void => {
    const currentChatsData = actorGetActionValue('chatsData') ?? {
      data: [],
      hasMore: false,
    };
    if (isEmpty(data) || isEmptyObject(data)) {
      actorDispatch('chatsData', {
        ...currentChatsData,
        hasMore: false,
      });
      actorDispatch('loading', { chatsLoading: false });
      return;
    }
    actorDispatch('chatsData', {
      data: [...currentChatsData.data, ...data],
      hasMore: true,
    });
    actorDispatch('loading', { chatsLoading: false });
  };

  /**
   * @function handleAfterSuccessGetUserContents
   * @param { MessageInterface[] } data
   * @param { number } total
   * @returns { void }
   */
  const handleAfterSuccessGetUserContents = (
    data: MessageInterface[],
    total: number,
  ): void => {
    const currentSelectedUser = actorGetActionValue('selectedUser')!;
    actorDispatch('loading', { messagesLoading: false });

    if (currentSelectedUser?.sumnotseen > 0) {
      /**
       * the situation we get just unseen messages
       * and they are less than 10 items
       * so we need more items to active scroll
       * so we fetch prev items
       */
      if (data?.length <= 10) {
        getUserContents(
          currentSelectedUser.personinfo_id,
          // @ts-ignore
          data?.[data.length - 1]?.chatdate,
          true,
        );
      }
    }
  };

  /**
   * @function successGetUserContentsCallback
   * @param { MessageInterface[] } data
   * @param { boolean } isUp
   * @returns { void }
   */
  const successGetUserContentsCallback = (
    data: MessageInterface[],
    total,
    isUp: boolean,
  ): void => {
    if (isEmpty(data) || isEmptyObject(data)) {
      actorDispatch('loading', { messagesLoading: false });
      isUp && setReachEndOfMessages();
      return;
    } else {
      if (total === 0) {
        isUp && setReachEndOfMessages();
      }
      handleInsertContentOnView(data, isUp);
    }

    handleAfterSuccessGetUserContents(data, total);
  };

  /**
   * @function successSendMessageCallback
   * @param { MessageInterface } data
   * @param { boolean } shouldNotScrollDown
   * @returns { void }
   */
  const successSendMessageCallback = (
    data: MessageInterface,
    shouldNotScrollDown = false,
  ): void => {
    // it's edit mode
    if (data.isedited) {
      handleEditMessage(data);
    } else {
      // it's new message mode
      handleCreateNewMessage(data);
    }
    handleAfterSuccessSendMessage(shouldNotScrollDown);
    isFileUploadDialogOpen && handleCloseFileUploadDialog();
  };

  /**
   * @function failureCallback
   * @param error
   * @returns { void }
   */
  const failureCallback = (error: unknown): void => {
    showNotification(error, 'error');
  };

  /**
   * to get all contacts
   * @function getContacts
   * @param { boolean } shouldLoading
   * @param { number } page
   * @returns { void }
   */
  const getContacts = useCallback((shouldLoading = false, page = contactPage) => {
    shouldLoading && actorDispatch('loading', { contactsLoading: true });
    actorDispatch(
      'getChatReport',
      {
        successCallback: successGetContactsCallback,
        params: {
          reportId: contactsReportId,
          pagination: { page, perPage: 10 },
        },
        failureCallback,
      },
      {
        disableDebounce: true,
      },
    );
  }, []);

  /**
   * to get all chats
   * @function getChats
   * @param { boolean } shouldLoading
   * @param { number } page
   * @returns { void }
   */
  const getChats = useCallback((shouldLoading = false, page = chatPage) => {
    shouldLoading && actorDispatch('loading', { chatsLoading: true });
    actorDispatch(
      'getChatReport',
      {
        successCallback: successGetChatsCallback,
        params: {
          reportId: chatsReportId,
          pagination: { page, perPage: 10 },
        },
        failureCallback,
      },
      {
        disableDebounce: true,
      },
    );
  }, []);

  /**
   * @function fetchMoreContacts
   * @returns { void }
   */
  const fetchMoreContacts = (): void => {
    setContactPage(prev => prev + 1);
    getContacts(false, contactPage + 1);
  };

  /**
   * @function refreshContacts
   * @returns { void }
   */
  const refreshContacts = (): void => {
    getContacts(true, 1);
  };

  /**
   * @function fetchMoreChats
   * @returns { void }
   */
  const fetchMoreChats = (): void => {
    setChatPage(prev => prev + 1);
    getChats(false, chatPage + 1);
  };

  /**
   * @function refreshChats
   * @returns { void }
   */
  const refreshChats = (): void => {
    actorDispatch('chatsData', null, { replaceAll: true });
    actorDispatch('selectedUser', null, { replaceAll: true });
    setChatPage(1);
    getChats(true, 1);
  };

  /**
   * to get all contents of a specific user
   * @function getUserContents
   * @param { number } userId
   * @param { string | null } chatdate
   * @param { number } IsUp
   * @param { number } MoveLast
   * @param { number | null } ChatID
   * @param { Function | null } customSuccessCallback
   * @returns { void }
   */
  const getUserContents = useCallback<GetUserContents>(
    (
      userId,
      chatdate = null,
      IsUp = 0,
      MoveLast = 0,
      ChatID = null,
      customSuccessCallback = null,
    ): void => {
      actorDispatch('loading', { messagesLoading: true });
      actorDispatch(
        'getChatReport',
        {
          successCallback: ({ data, total }) =>
            customSuccessCallback
              ? // @ts-ignore
                customSuccessCallback({ data, total })
              : successGetUserContentsCallback(data, total, Boolean(IsUp)),
          params: {
            reportId: userContentsReportId,
            pagination: { perPage: 999999 },
            filters: [
              ['otherpersoninfo_id', 'equal', userId],
              ['chatdate', 'equal', chatdate],
              ['IsUp', 'equal', IsUp],
              ['MoveLast', 'equal', MoveLast],
              ['ChatID', 'equal', ChatID],
            ],
            sort: {
              field: 'chatdate',
              order: 'DESC',
            },
          },
          failureCallback: failureCallback,
        },
        {
          disableDebounce: true,
        },
      );
    },
    [],
  );

  /**
   * to fetch More messages
   * @function fetchMoreMessages
   * @param { boolean } IsUp
   * @returns { void }
   */
  const fetchMoreMessages = (IsUp: boolean): void => {
    const currentSelectedUser = actorGetActionValue('selectedUser')!;
    const currentMessagesData = actorGetActionValue('messagesData')!;
    const chatdate = IsUp
      ? currentMessagesData?.data?.[currentMessagesData?.data?.length - 1]?.chatdate
      : currentMessagesData?.data?.[0]?.chatdate;
    if (currentSelectedUser) {
      // @ts-ignore
      getUserContents(currentSelectedUser.personinfo_id, chatdate, +IsUp);
    }
  };

  /**
   * handle select sidebar contacts or chats
   * @function onSelectUser
   * @param { SelectedUserType } user
   * @returns { void }
   */
  const onSelectUser = (user: SelectedUserType): void => {
    actorDispatch('messagesData', { data: [], hasMore: true }, { replaceAll: true });
    actorDispatch('chatText', '');
    actorDispatch('selectedUser', user);
    getUserContents(user.personinfo_id);
    const chatInputRef = actorGetActionValue('chatInputRef')!;
    chatInputRef?.current?.focus();
  };

  /**
   * to send new content
   * @function onSendContent
   * @param { string } chattext
   * @param { string } filurl
   * @returns { Promise<void>  }
   */
  const onSendContent = async (
    chattext = '',
    fileurl: string | null = null,
  ): Promise<void> => {
    const selectedMessageData = actorGetActionValue('selectedMessageData')!;
    const currentSelectedUser = actorGetActionValue('selectedUser')!;
    await actorDispatch(
      'runChatService',
      {
        successCallback: data =>
          successSendMessageCallback(data, selectedMessageData?.mode === 'edit'),
        params: {
          actionUniqueId: sendMessageReportId,
          data: {
            params: {
              topersoninfo_id: currentSelectedUser?.personinfo_id,
              chattext,
              fileurl,
              replyofchat_id:
                selectedMessageData?.mode === 'reply'
                  ? selectedMessageData?.message?.chat_id
                  : '',
              chatid:
                selectedMessageData?.mode === 'edit'
                  ? selectedMessageData?.message?.chat_id
                  : '',
            },
          },
        },
        failureCallback: failureCallback,
      },
      {
        disableDebounce: true,
      },
    );
  };

  /**
   * to delete a message
   * @function onDeleteMessage
   * @param { Record<string, unknown> } params
   * @param { Function } successCallback
   * @returns { void }
   */
  const onDeleteMessage = (params, successCallback): void => {
    actorDispatch(
      'runChatService',
      {
        successCallback,
        params: {
          actionUniqueId: deleteMessageServiceId,
          data: {
            params,
          },
        },
        failureCallback: failureCallback,
      },
      {
        disableDebounce: true,
      },
    );
  };

  /**
   * @function  successUploadFilesCallback
   * @param { SuccessResponse } successResponse
   * @returns { void }
   */
  const successUploadFilesCallback = (successResponse: SuccessResponse): void => {
    if (isEmptyObject(successResponse.data)) return;
    const { filePath, realFileName } = successResponse.data;

    const currentUploadFilesData = actorGetActionValue('uploadedFiles')!;

    const prevUploadedFilesData = currentUploadFilesData ?? [];

    actorDispatch('uploadedFiles', [
      ...prevUploadedFilesData,
      { filePath, realFileName },
    ]);
  };

  /**
   * it should show error messages
   * @function handleSendFilesErrors
   * @param {string} error
   * @returns {void} void
   */
  const handleSendFilesErrors =
    (largerThanAllowedFiles: Array<File>) =>
    (apiErrors: Array<{ fileName: string; message: string }>) => {
      showNotification(
        generateErrorMessage(largerThanAllowedFiles, apiErrors, translate),
        'error',
      );
    };

  /**
   * to send new message
   * @function onSendMessage
   * @returns { void }
   */
  const onSendMessage = (): void => {
    const chattext = actorGetActionValue('chatText')!;
    !isEmpty(chattext) && onSendContent(chattext);
  };

  /**
   * @function onSendFile
   * @param { React.ChangeEvent<HTMLInputElement> } event
   * @returns {  Promise<void> }
   */
  const onSendFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (!event.target.files?.length) return;

    const validFiles: Array<File> = [];
    const largerThanAllowedFiles: Array<File> = [];

    for (const file of event.target.files) {
      if (!file) continue;

      // limit files size
      if (
        fileUploadLimitMB &&
        !isEmpty(fileUploadLimitMB) &&
        megaByteToByte(+fileUploadLimitMB) < file.size
      ) {
        largerThanAllowedFiles.push(file);
        continue;
      }

      validFiles.push(file);
    }

    if (validFiles.length) {
      actorDispatch('uploadStreamMultipleFile', {
        param: {
          resource: fileUploadResource,
          files: validFiles,
        },
        successCallback: successUploadFilesCallback,
        successAllFilesUploadedCallback: handleOpenFileUploadDialog,
        failureCallback: handleSendFilesErrors(largerThanAllowedFiles),
      });
    } else if (largerThanAllowedFiles.length) {
      handleSendFilesErrors(largerThanAllowedFiles)([]);
    }
  };

  /**
   * you can copy some files from everywhere
   * then ctrl + v on the messages list
   * it will trigger onSendFile
   * @function handlePasteFromClipboard
   * @param { React.ClipboardEvent<HTMLTextAreaElement> } event
   * @returns { void }
   */
  const handlePasteFromClipboard = (
    event: React.ClipboardEvent<HTMLTextAreaElement>,
  ) => {
    event.persist();
    const currentSelectedUser = actorGetActionValue('selectedUser')!;

    if (currentSelectedUser) {
      chatActionsHandler(ChatActions.onSendFile, {
        event: {
          target: {
            files: event.clipboardData.files,
          },
        },
      } as OnSendFileParams);
    }
  };

  /**
   * @function handleMoveToRepliedMessage
   * @param { number } chatId
   * @returns { void }
   */
  const handleMoveToRepliedMessage = (chatId: number, successCallback) => {
    const currentSelectedUser = actorGetActionValue('selectedUser')!;
    currentSelectedUser &&
      getUserContents(
        currentSelectedUser.personinfo_id,
        null,
        0,
        0,
        // @ts-ignore
        chatId,
        successCallback,
      );
  };

  /**
   * @async
   * @function chatActionsHandler
   * @param {string} type
   * @param {Record<string, unknown>} data
   * @returns {Promise<void>} promise of void
   */

  const chatActionsHandler = async (
    type: string,
    payload?: unknown,
  ): Promise<void> => {
    switch (type) {
      case ChatActions.onSendContent:
        {
          const { chattext, fileurl } = payload as OnSendContentParams;
          onSendContent(chattext, fileurl);
        }
        break;
      case ChatActions.onSendMessage:
        {
          onSendMessage();
        }
        break;
      case ChatActions.onSendFile:
        {
          const { event } = payload as OnSendFileParams;
          onSendFile(event);
        }
        break;

      case ChatActions.onRefreshContacts:
        {
          refreshContacts();
        }
        break;

      case ChatActions.onRefreshChats:
        {
          refreshChats();
        }
        break;
      case ChatActions.onfetchMoreContacts:
        {
          fetchMoreContacts();
        }
        break;
      case ChatActions.onfetchMoreChats:
        {
          fetchMoreChats();
        }
        break;
      case ChatActions.onfetchMoreMessages:
        {
          const { IsUp } = payload as OnFetchMoreMessagesParams;
          fetchMoreMessages(IsUp);
        }
        break;
      case ChatActions.onSelectUser:
        {
          const { user } = payload as OnSelectUserParams;
          onSelectUser(user);
        }
        break;
      case ChatActions.onMoveLast:
        {
          const { successCallback } = payload as OnMoveLastParams;
          const currentSelectedUser = actorGetActionValue('selectedUser')!;
          currentSelectedUser &&
            getUserContents(
              currentSelectedUser.personinfo_id,
              null,
              0,
              1,
              null,
              // @ts-ignore
              successCallback,
            );
        }
        break;
      case ChatActions.onMoveToRepliedMessage:
        {
          const { chatId, successCallback } =
            payload as OnMoveToRepliedMessageParams;

          handleMoveToRepliedMessage(chatId, successCallback);
        }
        break;
      case ChatActions.onDeleteMessage:
        {
          const { params, successCallback } = payload as OnDeleteMessageParams;

          onDeleteMessage(params, successCallback);
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const currentChatsData = actorGetActionValue('chatsData')!;
    isEmptyObject(currentChatsData) && getChats();
  }, []);

  /**
   * handle signalR Messages
   * @function signalRMessageCallback
   * @returns { void }
   */
  const signalRMessageCallback = useCallback(signalRMessage => {
    if (signalRMessage && !isEmptyObject(signalRMessage)) {
      const signalRMessageType = getSignalRMessageType(signalRMessage);

      switch (signalRMessageType) {
        case 'INSERT':
          handleInsertNewSignalRMessage(signalRMessage);
          break;
        case 'DELETE':
          handleِDeleteMessage(signalRMessage);
          break;

        case 'UPDATE':
          handleUpdateMessage(signalRMessage);

          break;

        default:
          break;
      }
    }
  }, []);

  /**
   * handle update unseen messages on sidebar
   * @function chatTotalUnSeenCallback
   * @param { unknown } message
   * @returns { void }
   */
  const chatTotalUnSeenCallback = useCallback((message: unknown) => {
    handleUpdateUnseenMessages(message as ChatTotalUnseenInterface);
  }, []);

  /**
   * @function getSignalRMessages
   * @returns { Promise<void> }
   *
   */
  const getSignalRMessages = async (): Promise<void> => {
    await getEventValue({
      connectionType: CHAT_SIGNAL,
      connectionUrl: `${webSocketApiUrl}/hub/chat?app=5&clientid=`,
      userId: sessionId,
      signalREvent: 'OnChatRecieved',
      onEventCallback: signalRMessageCallback,
    });
  };

  /**
   * @function getSignalRMessages
   * @returns { Promise<void> }
   *
   */
  const getChatTotalUnSeen = async (): Promise<void> => {
    await getEventValue({
      connectionType: CHAT_SIGNAL,
      connectionUrl: `${webSocketApiUrl}/hub/chat?app=5&clientid=`,
      userId: sessionId,
      signalREvent: 'OnSumNotSeenReceived',
      onEventCallback: chatTotalUnSeenCallback,
    });
  };

  useEffect(() => {
    getSignalRMessages();
    getChatTotalUnSeen();
  }, [getChatTotalUnSeen, getSignalRMessages]);

  /**
   * handle searched messages
   */
  useEffect(() => {
    actorOnDispatch('foundedMessage', (message: FoundedMessageInterface) => {
      if (!isEmpty(message) && !isEmptyObject(message)) {
        actorDispatch('selectedUser', {
          personinfo_id: message?.topersoninfo_id,
          personimage: message?.personimage,
          personname: message?.personname,
        } as SelectedUserType);
        actorDispatch('messagesData', { data: [message], hasMore: true });
        // @ts-ignore
        getUserContents(message?.topersoninfo_id, message.chatdate, 1);
      }
    });
  }, []);

  useEffect(() => {
    return () => {
      actorDispatch('selectedUser', null, { replaceAll: true });
    };
  }, []);

  return (
    <ChatSectionView
      chatActionsHandler={chatActionsHandler}
      currentUser={currentUser}
      isFileUploadDialogOpen={isFileUploadDialogOpen}
      handleCloseFileUploadDialog={handleCloseFileUploadDialog}
      handlePasteFromClipboard={handlePasteFromClipboard}
    />
  );
});

export default ChatSectionController;
