import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import { actorGetActionValue, actorDispatch } from '../type/actor-setup';
export const NOTIFICATION_SIGNAL = 'notificationHubConnection';
export const CHAT_SIGNAL = 'chatHubConnection';

type SignalRConnectionType = typeof NOTIFICATION_SIGNAL | typeof CHAT_SIGNAL;

interface SignalRConnectionInterfaceProps {
  connectionType: SignalRConnectionType;
  connectionUrl: string;
  userId: string;
  signalREvent: string;
  onEventCallback: (message: unknown) => void;
}

//FIXME: api should implement just only one connection url with separate events
// until then, we use this mess, then we can clean it up !!!

/**
 * @function handleConnectToSignalR
 * @param { string } connectionUrl
 * @param { string } userId
 * @param { SignalRConnectionType } connectionType
 * @returns { Promise<HubConnection | null> }
 */
const handleConnectToSignalR = async (
  connectionUrl: string,
  userId: string,
  connectionType: SignalRConnectionType,
): Promise<HubConnection | null> => {
  let hubConnection = actorGetActionValue<SignalRConnectionType>(connectionType);

  if (hubConnection != null) {
    return hubConnection;
  }

  try {
    hubConnection = new HubConnectionBuilder()
      .withUrl(`${connectionUrl}${userId}`)
      .withAutomaticReconnect()
      .build();

    await hubConnection.start();
    actorDispatch(connectionType, hubConnection);
  } catch (err) {
    console.warn('signalR connection:', err);
  }

  return hubConnection;
};

/**
 * to receive an event, connect to signalR if needed then
 * listen to that event and trigger the callback
 * @function getEventValue
 * @param { SignalRConnectionInterfaceProps } param0
 * @returns { Promise<void> }
 */
export const getEventValue = async ({
  connectionType,
  connectionUrl,
  userId,
  signalREvent,
  onEventCallback,
}: SignalRConnectionInterfaceProps): Promise<void> => {
  try {
    const signalRConnection = await handleConnectToSignalR(
      connectionUrl,
      userId,
      connectionType,
    );
    signalRConnection?.on(signalREvent, message => {
      console.log('signalR:', 'event:', signalREvent, 'message:', message);
      onEventCallback(message);
    });
  } catch (e) {
    console.log('Connection failed: ', e);
  }
};
