import React, { Fragment, useState, useEffect } from 'react';
import compose from 'recompose/compose';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Popper, Paper } from '@material-ui/core';
import { getIconClassName } from '@uifabric/styling';
import { translate } from 'react-admin';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import lodashFind from 'lodash/find';

import { isEmpty } from '../../helper/data-helper';
import {
  getTodoListPatternInfo,
  getTodoSharedListMemberPatternInfo,
  getListMemberPatternInfo,
} from '../../helper/PatternMetaHelper';
import TodoSharedMemberItem from './TodoSharedMemberItem';
import { getSharedListMemberAction } from '../../redux/todoList/action';
import { CustomTheme } from '../../core/themeProvider';
import { getValue, USER_ID } from '../../core/configProvider';

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  body: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  grow: {
    flexGrow: 1,
  },

  icon: {
    margin: 12,
    fontSize: 20,
    color: theme.palette.primary.appPrimaryIconColor,
  },

  menuTitle: {
    textAlign: 'center',
    padding: '15px 30px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  paper: {
    display: 'flex',
    flexDirection: 'column',
  },

  todoSharedMemberItem: {
    margin: 7,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },

  deleteFileIcon: {
    fontSize: 10,
    margin: 10,
    color: theme.palette.primary.appPrimaryIconColor,
    transition: 'all 200ms',
  },
}));

const TodoTaskAssign = props => {
  const {
    translate,
    onClick,
    value,
    isOpen,
    listSharedMember,
    listResource,
    getSharedListMember,
    listItem,
    onItemClick,
    onRemoveValue,
  } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);

  const { reportSharedListMember, pagination, listMemberResource } =
    getTodoListPatternInfo(listResource);

  const { personId } = getTodoSharedListMemberPatternInfo(reportSharedListMember);

  const { userId: fieldNameForShareList, listId } =
    getListMemberPatternInfo(listMemberResource);

  useEffect(() => {
    setCurrentUserId(getValue(USER_ID));
  }, []);

  useEffect(() => {
    getSharedListMember(
      reportSharedListMember,
      {
        sort: { field: [fieldNameForShareList] },
        pagination,
        filter: { [listId]: listItem[listId] },
      },
      listSharedMember,
    );
  }, []);

  const handleClick = event => {
    onClick();
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
  };

  const handleRemoveValue = event => {
    onRemoveValue();
  };

  const todoSharedMemberItemOnClick = member => event => {
    onItemClick(member[personId]);
    onClick();
  };

  const id = isOpen ? 'simple-popper' : undefined;

  const assignedMember = lodashFind(listSharedMember, { [personId]: value });

  return (
    <Fragment>
      {assignedMember && assignedMember[personId] != currentUserId ? (
        <div className={classes.body} data-test-task-view-shared={true}>
          <div aria-describedby={id} onClick={handleClick} className={classes.grow}>
            <TodoSharedMemberItem
              listResource={listResource}
              sharedListMemberResource={reportSharedListMember}
              member={lodashFind(listSharedMember, { [personId]: value })}
              classes={{ container: classes.todoSharedMemberItem }}
              disableRemoveMember
            />
          </div>
          <i
            onClick={handleRemoveValue}
            className={`${classes.deleteFileIcon} ${getIconClassName('cancel')}`}
          ></i>
        </div>
      ) : (
        <div
          aria-describedby={id}
          onClick={handleClick}
          className={classes.body}
          data-test-task-view-shared={false}
        >
          <i className={`${classes.icon} ${getIconClassName('addFriend')}`}></i>
          <div>
            <Typography variant="caption" color="textPrimary">
              {translate('todo.assignTo')}
            </Typography>
          </div>
        </div>
      )}
      {/* <div className={classes.container}>
        <div aria-describedby={id} onClick={handleClick} className={classes.body}>
          <i
            className={`${classes.icon} ${getIconClassName('addFriend')} ${
              !!value ? classes.withValue : ''
            }`}
          ></i>
          <div>
            <Typography variant="caption" color={!!value ? 'secondary' : 'textPrimary'}>
              {translate('todo.assignTo')}
            </Typography>
            {value && (
              <Typography variant="caption" color="textSecondary" className={classes.value}>
                {value}
              </Typography>
            )}
          </div>
        </div>
        {value && (
          <i
            onClick={handleRemoveValue}
            className={`${classes.deleteFileIcon} ${getIconClassName('cancel')}`}
          ></i>
        )}
      </div> */}
      <Popper
        style={{ zIndex: 1300 }}
        id={id}
        anchorEl={anchorEl}
        open={isOpen}
        disablePortal={false}
        placement="bottom"
      >
        {({ TransitionProps }) => (
          <Paper className={classes.paper}>
            <Typography variant="body2" className={classes.menuTitle}>
              {translate('todo.assignTo')}
            </Typography>
            {listSharedMember &&
              !!listSharedMember.length &&
              listSharedMember.map((item: any) =>
                item[personId] != currentUserId ? (
                  <TodoSharedMemberItem
                    key={item[personId]}
                    listResource={listResource}
                    sharedListMemberResource={reportSharedListMember}
                    member={item}
                    classes={{ container: classes.todoSharedMemberItem }}
                    disableRemoveMember
                    onClick={todoSharedMemberItemOnClick}
                  />
                ) : null,
              )}
          </Paper>
        )}
      </Popper>
    </Fragment>
  );
};

TodoTaskAssign.propTypes = {};

const mapStateToProps = (state, props) => {
  const reportSharedListMemberResource = getTodoListPatternInfo(
    props.listResource,
  ).reportSharedListMember;

  return {
    listSharedMember: !isEmpty(reportSharedListMemberResource)
      ? lodashGet(state, ['todoList', 'resultList', reportSharedListMemberResource])
      : '',
  };
};

const mapDispatchToProps = {
  getSharedListMember: getSharedListMemberAction,
};

export default compose(
  translate,
  connect(mapStateToProps, mapDispatchToProps),
)(TodoTaskAssign);
