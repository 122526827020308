import { FC, memo, useCallback, useEffect, useState } from 'react';
import {
  MailActions,
  MailInterface,
  OnArchiveParams,
  OnHandleMasterCheckboxParams,
  OnMarkUnReadParams,
} from '../..';
import lodashDebounce from 'lodash/debounce';
import { isEmptyObject } from '../../../../helper/data-helper';
import { MailContentHeaderInterface } from './mail-content-header.type';
import MailContentHeaderView from './mail-content-header.view';
import { actorDispatch, actorGetActionValue } from '../../../../type/actor-setup';

const MailContentHeaderController: FC<MailContentHeaderInterface> = memo(props => {
  const {
    mailActionsHandler,
    mailData,
    checkedMails,
    loading,
    pagination,
    onChangePageHandler,
    onStarClick,
    onImportantClick,
  } = props;

  const [shouldMarkAsRead, setShouldMarkAsRead] = useState<boolean>(false);

  /**
   * @function handleRefreshMailData
   * @returns { void }
   */
  const handleRefreshMailData = (): void => {
    mailActionsHandler(MailActions.OnRefreshMailData);
  };

  /**
   * @function onHandleMasterCheckbox
   * @param { React.ChangeEvent<HTMLInputElement> } event
   * @returns { void }
   */
  const onHandleMasterCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    mailActionsHandler(MailActions.onHandleMasterCheckbox, {
      event,
    } as OnHandleMasterCheckboxParams);
  };

  /**
   * @function onMarkUnReadClick
   * @param { MailInterface[] | null } mailsData
   * @param { boolean } IsUnRead
   * @returns { void }
   */
  const onMarkUnReadClick = (
    mailsData: MailInterface[] | null,
    IsUnRead: boolean,
  ): void => {
    mailActionsHandler(MailActions.onMarkUnRead, {
      mailsData,
      IsUnRead,
    } as OnMarkUnReadParams);
  };
  /**
   * @function onArchiveClick
   * @returns { void }
   */
  const onArchiveClick = (): void => {
    mailActionsHandler(MailActions.onArchive, {
      mailsData: null,
    } as OnArchiveParams);
  };
  /**
   * @function onUnArchiveClick
   * @returns { void }
   */
  const onUnArchiveClick = (): void => {
    mailActionsHandler(MailActions.onUnArchive, {
      mailsData: null,
    } as OnArchiveParams);
  };
  /**
   * @function handleDebouncedSearch
   * @param { string } value
   * @returns { void }
   */
  const handleDebouncedSearch = useCallback(
    lodashDebounce(() => {
      mailActionsHandler(MailActions.onSearchMailData);
    }, 1000),
    [],
  );

  /**
   * @function handleSearch
   * @param { React.ChangeEvent<HTMLInputElement> } event
   * @returns { void }
   */
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const currentMailData = actorGetActionValue('mailData')!;

    // should not miss any data when we want to send search request
    // so we should assure to start from first page
    actorDispatch('mailData', {
      ...currentMailData,
      pagination: { ...currentMailData.pagination, currentPage: 1 },
    });
    handleDebouncedSearch(event.target.value);
  };

  useEffect(() => {
    if (!isEmptyObject(checkedMails)) {
      const isUnReadItemExist = checkedMails.some((item: MailInterface) =>
        Boolean(item.isunread),
      );

      setShouldMarkAsRead(isUnReadItemExist);
    }
  }, [checkedMails]);

  return (
    <MailContentHeaderView
      mailActionsHandler={mailActionsHandler}
      mailData={mailData}
      loading={loading}
      handleSearch={handleSearch}
      checkedMails={checkedMails}
      pagination={pagination}
      onChangePageHandler={onChangePageHandler}
      onRefreshMailData={handleRefreshMailData}
      onHandleMasterCheckbox={onHandleMasterCheckbox}
      onStarClick={onStarClick}
      onImportantClick={onImportantClick}
      onMarkUnReadClick={onMarkUnReadClick}
      onArchiveClick={onArchiveClick}
      onUnArchiveClick={onUnArchiveClick}
      shouldMarkAsRead={shouldMarkAsRead}
    />
  );
});

export default MailContentHeaderController;
