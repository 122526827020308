import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  editButton: {
    padding: 7,
  },
  icon: {
    color: theme.palette.primary.main,
    margin: '0 !important',
    fontSize: 13,
  },

  fieldLink: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
    margin: '0 5px',
    fontSize: 11,
  },
  booleanField: {
    display: 'inline !important',
    '& svg': {
      fontSize: 20,
    },
  },

  iconButton: {
    padding: 7,
  },
  IconButton: {
    padding: 7,
  },
  textField: {
    display: 'inline',
    margin: '0 5px',
    fontSize: 12,
  },
  statusFields: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fieldValue: {
    flex: '1',
    // flexGrow: "2",
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12,
  },
}));
