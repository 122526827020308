import React, { FC, useState } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles, InputBase, Icon, Typography } from '@material-ui/core';
import useWidth from '../useWidth';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import CardListItemView from '../card/CardListItemView';
import LoadingBox from '../LoadingBox';
import { SellinSearchPatternType } from '../../helper/Types';
import { isEmptyObject } from '../../helper/data-helper';
import { CustomTheme } from '../../core/themeProvider';
import GridListTileView from '../category/GridListTileView';
import { SELLIN_SHOW } from '../../core/configRouteConstant';

interface SellinSearchViewInterfaceProps {
  ids: number[];
  data: object[];
  pattern: SellinSearchPatternType;
  setFilters: Function;
  hideFilter: Function;
  filterValues: object;
  isLoading: boolean;
  loadedOnce: boolean;
  redirectToPage: Function;
}

// This function must be refactor
const styleHelper = (props: SellinSearchViewInterfaceProps) => {
  const { isLoading, loadedOnce, ids } = props;
  if (!isLoading && !loadedOnce && !ids.length) {
    return 'center';
  }
  if (loadedOnce && !isLoading && !ids.length) {
    return 'center';
  }
  if (isLoading) {
    return 'center';
  }
  return 'unset';
};

const styles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: '100%',
  },

  containerSearch: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    boxShadow: theme.shadows[1],
    zIndex: 1,
  },

  iconSearch: {
    color: theme.palette.text.hint,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },

  input: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    margin: '0 8px',
    padding: '0 8px',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },

  containerProduct: {
    overflow: 'auto',
    flexGrow: 1,
    // This function must be refactor
    display: (props: SellinSearchViewInterfaceProps) => {
      const { isLoading, loadedOnce, ids } = props;
      if (!isLoading && !loadedOnce && !ids.length) {
        return 'flex';
      }
      if (loadedOnce && !isLoading && !ids.length) {
        return 'flex';
      }
      if (isLoading) {
        return 'flex';
      }
      return 'block';
    },
    // This function must be refactor
    flexDirection: (props: SellinSearchViewInterfaceProps) => {
      const { isLoading, loadedOnce, ids } = props;
      if (!isLoading && !loadedOnce && !ids.length) {
        return 'column';
      }
      if (loadedOnce && !isLoading && !ids.length) {
        return 'column';
      }
      if (isLoading) {
        return 'column';
      }
      return 'unset';
    },
    justifyContent: (props: SellinSearchViewInterfaceProps) => styleHelper(props),
    alignItems: (props: SellinSearchViewInterfaceProps) => styleHelper(props),
  },
}));

const SellinSearchView: FC<SellinSearchViewInterfaceProps> = props => {
  const {
    ids,
    data,
    pattern,
    isLoading,
    loadedOnce,
    setFilters,
    filterValues,
    hideFilter,
    redirectToPage,
  } = props;
  const { filterKeyName, targetResource, id } = pattern;
  const classes = styles(props);
  const translate = useTranslate();
  const width = useWidth();

  const [searchValue, setSearchValue] = useState(
    !isEmptyObject(filterValues) ? filterValues[filterKeyName][2] : '',
  );

  const handleSearch = event => {
    setSearchValue(event.target.value);
    setFilters({ [filterKeyName]: [filterKeyName, '=', event.target.value] });
  };

  const handleClear = event => {
    setSearchValue('');
    hideFilter(filterKeyName);
  };

  const handleClick = item => {
    redirectToPage(`/${SELLIN_SHOW}/${targetResource}/${item[id]}`);
  };

  return (
    <div className={classes.container}>
      <div className={classes.containerSearch}>
        <InputBase
          className={classes.input}
          placeholder={translate('ra.action.search')}
          value={searchValue}
          onChange={handleSearch}
          inputProps={{ 'aria-label': translate('ra.action.search') }}
          autoFocus
        />
        <Icon className={classes.iconSearch} onClick={handleClear}>
          close
        </Icon>
      </div>
      <div className={classes.containerProduct}>
        {!isLoading && !loadedOnce && !ids.length && (
          <Typography variant="caption">
            {translate('sellin.pleaseEnterTheNameOfTheDesiredProductInTheBoxAbove')}
          </Typography>
        )}
        {isLoading && <LoadingBox />}
        {loadedOnce && !isLoading && !ids.length && (
          <Typography variant="caption">
            {translate('sellin.noProductFound')}
          </Typography>
        )}
        {!isLoading && (
          <div>
            {width === 'xs' ? (
              ids
                .map(id => data[id])
                .map((record: any) => (
                  <CardListItemView
                    key={record.id}
                    record={record}
                    pattern={pattern}
                    count={0}
                  />
                ))
            ) : (
              <GridListTileView
                data={data}
                ids={ids}
                pattern={pattern}
                onClick={handleClick}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  redirectToPage: push,
};

export default connect(null, mapDispatchToProps)(SellinSearchView);
