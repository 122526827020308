import React, { FC, Fragment, useState } from 'react';
import {
  Button,
  Icon,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';

import { downloadFile, getBlobFile } from '../helper/FileHelper';
import { actorGetActionValue } from '../type/actor-setup';

type DownloadType = 'excel' | 'encodingText' | 'asciiText';
interface ExportButtonInterfaceProps {
  filter: object;
  resource: string;
  sort: { field: string; order: string };
  disabled: boolean;
  relationMode?: boolean;
  fields?: string;
}

const useStyles = makeStyles(() => ({
  container: {
    display: (props: { relationMode: boolean }) =>
      props.relationMode ? 'flex' : 'unset',
  },

  menuItem: {
    fontSize: 12,
  },
}));

const ExportButton: FC<ExportButtonInterfaceProps> = props => {
  const { sort, filter, resource, disabled, fields, relationMode = false } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles({ relationMode });
  const translate = useTranslate();

  /**
   * close menu
   * @function closeMenu
   * @returns {void}
   */
  const closeMenu = () => {
    setAnchorEl(null);
  };

  /**
   * open menu
   * @function onExportButtonClick
   * @param {Event} event
   * @returns {void}
   */
  const onExportButtonClick = event => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * prepare parameters base of download type then call downloadFile function
   * @function onDownloadFileClick
   * @param {DownloadType} downloadType
   * @returns {Function} download function
   */
  const onDownloadFileClick = (downloadType: DownloadType) => {
    let params;

    let finalFields = fields;
    if (relationMode) {
      finalFields =
        (actorGetActionValue('relationFieldsForDisplay', resource) as
          | string
          | null) ?? '';
    }

    if (downloadType === 'excel') {
      params = {
        link: `/${resource}/export/excel`,
        queryParams: {
          sort,
          filter,
          fields: finalFields,
        },
      };
    } else {
      params = {
        link: `/${resource}/export/text`,
        queryParams: {
          sort,
          filter,
          fields: finalFields,
          encoding: downloadType === 'encodingText' ? 'encoding' : 'ascii',
          withheader: downloadType === 'encodingText',
        },
      };
    }

    return async () => {
      closeMenu();
      downloadFile(
        await getBlobFile(params),
        resource,
        downloadType !== 'excel' ? 'txt' : 'xlsx',
      );
    };
  };

  return (
    <Fragment>
      <Tooltip title={translate('ra.action.export')}>
        <Button onClick={onExportButtonClick} color="primary" disabled={disabled}>
          <Icon>get_app</Icon>
        </Button>
      </Tooltip>

      <Menu
        id="export-button-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        onContextMenu={e => e.preventDefault()}
        variant="menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          className={classes.menuItem}
          disabled={disabled}
          onClick={onDownloadFileClick('excel')}
        >
          {translate('ra.action.downloadExcelFile')}
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          disabled={disabled}
          onClick={onDownloadFileClick('encodingText')}
        >
          {translate('ra.action.downloadEncodingTextFile')}
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          disabled={disabled}
          onClick={onDownloadFileClick('asciiText')}
        >
          {translate('ra.action.downloadAsciiTextFile')}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default ExportButton;
