import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lodashGet from 'lodash/get';

import {
  getOrderDetailItemAction,
  getOrderDetailAction,
} from '../redux/shop/action';
import { isEmpty } from '../helper/data-helper';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const ApiDetailGetter = props => {
  const {
    resource,
    id,
    children,
    basePath,
    ids,
    data,
    version,
    getOrderDetailItem,
    additionalData,
    loading,
    error,
    getOrderDetail,
    cartResource,
    ...rest
  } = props;

  const [apiDetailId, setApiDetailId] = useState(null);
  const [apiDetailVersion, setApiDetailVersion] = useState();

  useEffect(() => {
    if (apiDetailId !== id || apiDetailVersion !== version) {
      getData();
      setApiDetailId(id);
      setApiDetailVersion(version);
    }
  }, [id, version]);

  const getData = () => {
    if (!isEmpty(id)) {
      getOrderDetailItem({ resource, id });
    } else {
      getOrderDetail({ resource, cartResource });
    }
  };

  if (!children) {
    return null;
  }

  return children({
    ...rest,
    resource,
    basePath,
    id,
    data,
    additionalData,
    loading,
    error,
  });
};

function mapStateToProps(state, props) {
  const { resource, id } = props;

  return {
    data: !isEmpty(id)
      ? lodashGet(state, ['shop', resource, 'data', id], EMPTY_ARRAY)
      : lodashGet(state, ['shop', resource, 'data'], EMPTY_OBJECT),
    additionalData: lodashGet(state, [
      'shop',
      resource,
      'data',
      id + '_additionalData',
    ]),
    loading: lodashGet(state, ['shop', resource, 'loading']),
    error: lodashGet(state, ['shop', resource, 'error']),
    version: state.admin.ui.viewVersion,
  };
}

const mapDispatchToProps = {
  getOrderDetailItem: getOrderDetailItemAction,
  getOrderDetail: getOrderDetailAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ApiDetailGetter,
);
