import React, { FC, FormEvent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTheme } from '../../core/themeProvider';

interface ShopAddToCartButtonCounter {
  className?: string;
  counter: number;
  onAddButton: () => void;
  onRemoveButton: () => void;
  onInputType: (event: FormEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    border: `1px solid ${theme.palette.primary.appPrimaryTextColor}`,
    borderRadius: 50,
    display: 'inline-flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  inputItem: {
    color: theme.palette.primary.appPrimaryTextColor,
    backgroundColor: 'inherit',
    width: 50,
    textAlign: 'center',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
    '&::-webkit-inner-spin-button,&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
    },
    margin: '0 10px',
    fontFamily: theme.typography.fontFamily,
  },

  iconButton: {
    padding: 8,
  },
}));

const ShopAddToCartButtonCounter: FC<ShopAddToCartButtonCounter> = props => {
  const { className, onRemoveButton, onAddButton, onInputType, counter } = props;
  const classes = useStyles();

  const handleClick = event => {
    event.target.select();
  };

  return (
    <div className={classNames(classes.container, className)}>
      <IconButton onClick={onAddButton} className={classes.iconButton}>
        <Icon fontSize="small">add</Icon>
      </IconButton>

      <input
        className={classes.inputItem}
        value={counter}
        onKeyUp={onInputType}
        onChange={onInputType}
        onClick={handleClick}
        type="number"
      />

      {/* disabled={counter <= 1} */}
      <IconButton onClick={onRemoveButton} className={classes.iconButton}>
        <Icon fontSize="small">remove</Icon>
      </IconButton>
    </div>
  );
};

ShopAddToCartButtonCounter.propTypes = {
  onRemoveButton: PropTypes.func.isRequired,
  onAddButton: PropTypes.func.isRequired,
  onInputType: PropTypes.func.isRequired,
  counter: PropTypes.number.isRequired,
};

export default ShopAddToCartButtonCounter;
