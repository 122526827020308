import { cloneElement, memo, ReactElement } from 'react';

import { InputBaseViewInterface } from './input-base.type';
import { useStyles } from './input-base.style';
import InputMessage from './input-message';
import InputLabel from './input-label';

const InputBaseView = (props: InputBaseViewInterface): ReactElement => {
  const {
    label,
    hint = '',
    children,
    inputMessage,
    required,
    className,
    field,
  } = props;
  const classes = useStyles();

  const noLabel = field && field.customOption && field.customOption.noLabel;
  const dontShowErrorIcon =
    field && field.customOption && field.customOption.dontShowErrorIcon;

  return (
    <div className={`${className} ${classes.container}`}>
      {!noLabel && <InputLabel label={label} required={required} hint={hint} />}
      {cloneElement(children)}
      {!dontShowErrorIcon && <InputMessage inputMessage={inputMessage} />}
    </div>
  );
};

export default memo(InputBaseView);
