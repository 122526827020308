import './jalali-date-input.style.css';
import { ReactElement } from 'react';
import { useLocale } from 'react-admin';
import DatePicker from 'react-datepicker2-samian';
import { IconButton, InputAdornment } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Cleave from 'cleave.js/react';
import CloseIcon from '@material-ui/icons/Close';

import InputBase from '../../input-base';
import { useStyles } from './jalali-date-input.style';
import {
  checkValueMessageType,
  CleaveInputDateOptions,
  CleaveInputDateTimeOptions,
} from './jalali-date-input.helper';

// TODO: fix type DatePicker2-samian
const JalaliDateInputView = (props: any): ReactElement => {
  const {
    visibleClass,
    resource,
    disabled,
    inputMessage,
    DateInputInPuzzleForm,
    label,
    hint,
    required,
    name,
    inputFormat,
    handleChange,
    handleInputMaskChange,
    handleInputMaskBlur,
    internalValue,
    maskInputValue,
    calendarConfig,
    options,
    onFocus,
    onBlur,
    datePickerRef,
    simpleType,
    field,
    datePickerType,
    clearInput,
  } = props;
  const locale = useLocale();
  const classes = useStyles({ disabled });

  return (
    <InputBase
      className={`${visibleClass}`}
      label={label}
      hint={hint}
      required={required}
      inputMessage={inputMessage}
      field={field}
    >
      <fieldset
        data-test-field-name={`${resource}/${name}`}
        className={`${visibleClass} datePickerContainer ${classes.fieldset}`}
        data-error={`${checkValueMessageType(inputMessage?.messageType ?? '')}`}
        data-label={!!label || label !== ''}
        data-puzzle-form={!!DateInputInPuzzleForm}
        disabled={disabled}
      >
        <InputAdornment position="start" className={classes.InputAdornment}>
          <div
            data-test="date-picker-icon"
            onClick={onFocus}
            className={classes.datePickerIcon}
          >
            <DateRangeIcon style={{ fontSize: '18px' }} />
          </div>
        </InputAdornment>

        <div className={classes.containerInInput}>
          <div className={classes.flexInInput}>
            <Cleave
              options={
                datePickerType === 'dateTime'
                  ? CleaveInputDateTimeOptions
                  : CleaveInputDateOptions
              }
              value={maskInputValue}
              className={classes.inputMask}
              onChange={handleInputMaskChange}
              onBlur={handleInputMaskBlur}
              data-test="date-picker-input"
            />

            <DatePicker
              {...options}
              inputFormat={inputFormat}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              value={internalValue}
              disabled={disabled}
              isGregorian={calendarConfig === 'gregorian'}
              timePicker={simpleType === 'datetime'}
              showTodayButton={false}
              setTodayOnBlur={false}
              locale={locale === 'ar' ? 'fa' : locale}
              ref={datePickerRef}
              className={classes.dateInput}
            />
          </div>
          <div>
            {!required && !disabled && (
              <IconButton onClick={clearInput}>
                <CloseIcon className={classes.closeIcon} fontSize="small" />
              </IconButton>
            )}
          </div>
        </div>
      </fieldset>
    </InputBase>
  );
};

export default JalaliDateInputView;
