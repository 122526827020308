import { FC, memo, useEffect, useState } from 'react';

import { actorGetActionValue } from '../../../../type/actor-setup';
import JalaliDateInputController from '../../../dynamic-input/jalali-date-input/jalali-date-input.controller';
import { useStyles } from '../../grid.style';

import type { GridDateInputInterface } from './grid-date-input.type';

const GridDateInputController: FC<GridDateInputInterface> = props => {
  const { focusOnLastInput, onChangeInput, field, isDisabled } = props;
  const classes = useStyles();

  const [internalValue, setInternalValue] = useState('');

  useEffect(() => {
    const gridFormData = actorGetActionValue('gridFormData')!;
    const value = gridFormData[field.name] ?? '';
    setInternalValue(value as string);
  });

  /**
   * handle change date
   * @function handleChangeDate
   * @param { string } type
   * @param { unknown } payload
   * @returns { void } void
   */
  const handleChangeDate = (type, payload): void => {
    const { fieldName, value } = payload;
    onChangeInput({ fieldName, value });
    setInternalValue(value);
    focusOnLastInput();
  };

  return (
    <div className={classes.datePickerContainer}>
      <JalaliDateInputController
        formActionsHandler={handleChangeDate}
        value={internalValue}
        disabled={isDisabled}
        field={field}
        DateInputInGrid
      />
    </div>
  );
};

export default memo(GridDateInputController);
