import lodashGet from 'lodash/get';
import lodashFind from 'lodash/find';
import { MessageInterface } from '../../..';

/**
 * @function getFileSize
 * @param { number } bytes
 * @returns { string }
 */
export const getFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024,
    dm = 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

/**
 * @function getFileAddress
 * @param { string | null } fileUrl
 * @returns { string | null }
 */
export const getFileAddress = (fileUrl: string | null): string | null => {
  if (!fileUrl) {
    return '';
  }
  const fileAddress = JSON.parse(fileUrl);
  return lodashGet(fileAddress, 'filePath');
};
