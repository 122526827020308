import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { clone } from '../../helper/data-helper';

const useStyles = makeStyles(theme => ({
  input: {
    '&[disabled]': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },
  },
}));

const ComputedInput = props => {
  const { formula, record, className, label, visibleClass, formData, source } =
    props;

  const translate = useTranslate();
  const classes = useStyles();

  let value;
  if (!formula || !record) {
    value = translate('form.thereIsNoRecordOrFormula');
  }
  // every formula must have a return statement
  else if (formula.indexOf('return') === -1) {
    value = translate('form.thereIsNoReturnInTheFormula');
  } else {
    try {
      const computed = new Function('row', `${formula}`);
      value = computed(clone(formData));
    } catch (error) {
      value = translate('form.errorInComputing');
    }
  }

  return (
    <TextField
      margin="normal"
      className={`${visibleClass} ${className}`}
      variant="outlined"
      data-test-field-name={source}
      value={!isNaN(value) && value !== undefined && value !== null ? value : ''}
      label={label}
      InputProps={{
        classes: {
          input: classes.input,
        },
      }}
      disabled
    />
  );
};

ComputedInput.propTypes = {
  record: PropTypes.object,
  formula: PropTypes.string,
  className: PropTypes.string,
};

export default ComputedInput;
