import React, {
  createRef,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import lodashDebounce from 'lodash/debounce';
import type { FC, FocusEvent } from 'react';

import { isEmpty } from '../../../helper/data-helper';
import {
  actorGetActionValue,
  actorSetActionValue,
  DropdownData,
} from '../../../type/actor-setup';
import { FetchDropdownDataPayload, FormActions } from '../../form';
import { getLabelForDropdownOption } from '../dropdown-input-old/dropdown-input.helper';
import {
  convertToStringValue,
  findValuesNotInDropdownData,
  updateRenderOptions,
} from './auto-complete-input.helper';
import {
  AutoCompleteInputInterface,
  DropdownOption,
  onChangeFunc,
  SpecialDropdownVariables,
} from './auto-complete-input.type';
import AutoCompleteInputView from './auto-complete-input.view';
import { replaceFarsiCharactersWithArabic } from '../../../helper/TextHelper';

let tempRefTags;
const AutoCompleteInputController: FC<AutoCompleteInputInterface> = memo(props => {
  const {
    label,
    hint,
    source,
    dropdownMeta,
    loading,
    disabled,
    record,
    relationResource,
    relationSource,
    relationInfo,
    resource,
    inputMessage,
    value,
    field,
    formActionsHandler,
    isProfile,
    isServiceMode,
    inputInPuzzleForm,
    visibleClass,
    inputContainerClass,
    customTestAttribute,
    getRef,
  } = props;

  const currentResource = actorGetActionValue('resources')!.current;
  const formData = actorGetActionValue(
    'formData',
    `${currentResource.value}.${currentResource.type}`,
  ) as Record<string, unknown> | undefined;

  //ّFIXME: at first we had specialDropdownVariables as a Ref but because some bugs
  // and force we change it to state to fix that specific problem
  // check that how we can use ref again without any problem specially
  // in dialog forms. (at /mail - reply form)
  const [specialDropdownVariables, setSpecialDropdownVariables] =
    useState<SpecialDropdownVariables>({
      currentPage: 1,
      isFirstLoad: true,
      searchValue: '',
      currentResource,
      preparedOptions: [],
    });

  const [renderOptions, setRenderOptions] = useState<DropdownOption[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [limitTagCount, setLimitTagCount] = useState<number>(0);
  const [dropdownData, setDropdownData] = useState<{
    items: Record<string, unknown>[];
    totalItemsCount: number;
  }>({
    items: [],
    totalItemsCount: 0,
  });

  const wrapperRef = createRef<HTMLDivElement>();
  const buttonRefDialog = createRef<HTMLSpanElement>();
  const refTags = createRef<HTMLInputElement>();
  const valuesToFindBySearchRequestRef = useRef<string[]>([]);
  const inputBlurHasBeenRanManually = useRef(false);
  const findValueListByRequestInFirstLoad = useRef<boolean>(false);
  const valuesInFormData = useRef<Array<string | number>>([]);
  const relatedNamesInFormData = useRef<Record<string | number, string | number>>(
    {},
  );
  const selectedOptionsTemporaryRef = useRef<DropdownOption[]>([]);
  const originalRenderOptionsRef = useRef<DropdownOption[]>([]);

  const {
    displayMember,
    displayMember2,
    valueMember,
    id: dropdownId,
    moduleName,
    tableName,
  } = dropdownMeta;

  const { name: fieldName, id: fieldId, relatedName } = field;

  /**
   * this function delete empty value and return list of string
   * @constant preparedValue
   * @return {Array}
   */
  const preparedValue: string[] = useMemo(() => {
    const toStringValue = value?.toString();

    inputBlurHasBeenRanManually.current = false;
    valuesToFindBySearchRequestRef.current = [];
    selectedOptionsTemporaryRef.current = [];

    if (toStringValue) {
      return toStringValue.split(',').filter((item: string) => !isEmpty(item));
    }

    setSpecialDropdownVariables(prev => ({
      ...prev,
      isFirstLoad: false,
    }));

    return [];
  }, [value]);

  /**
   * handle more item with flex (when item is more than one line in flex-box)
   * @function updateSize
   * @return {void}
   */
  const updateSize = useCallback((): void => {
    if (tempRefTags == null || !specialDropdownVariables.isFirstLoad) return;

    const parentRect = tempRefTags.getBoundingClientRect();

    const maxTagsCountBasedOnInputContent = Math.round(parentRect.width / 100);

    if (maxTagsCountBasedOnInputContent - 3 <= 0) {
      setLimitTagCount(1);
    } else {
      setLimitTagCount(maxTagsCountBasedOnInputContent - 3);
    }
  }, [specialDropdownVariables.isFirstLoad]);

  /**
   * @function debouncedWindowResizeHandler
   * @returns void
   */
  const debouncedWindowResizeHandler = useCallback(
    lodashDebounce(updateSize, 1000),
    [],
  );

  useEffect(() => {
    /**
     * add listener when resize view port change
     */
    window.addEventListener('resize', debouncedWindowResizeHandler);

    return () => {
      window.removeEventListener('resize', debouncedWindowResizeHandler);

      setSpecialDropdownVariables({
        currentPage: 1,
        isFirstLoad: true,
        searchValue: '',
        currentResource,
        preparedOptions: [],
      });

      findValueListByRequestInFirstLoad.current = false;
    };
  }, []);

  // FIXME: Prevent to extra re-renders
  useEffect(() => {
    if (refTags.current) {
      tempRefTags = refTags.current;
      updateSize();
    }
  }, [refTags]);

  /**
   * It makes selected options based on current value and dropdown data
   * @function prepareSelectedItem
   * @param { number } value
   * @returns { void } void
   */
  const prepareSelectedItems = (valueList: string[]): void => {
    const items: DropdownOption[] = [];

    for (const item of valueList) {
      let title = relatedNamesInFormData.current[item];

      const targetItemInDropdownData = dropdownData.items.find(
        currentItem => currentItem[valueMember] == item,
      );

      if (isEmpty(title) && targetItemInDropdownData?.[displayMember] != null) {
        title = targetItemInDropdownData?.[displayMember] + '';
      }

      if (
        !isEmpty(displayMember2) &&
        targetItemInDropdownData?.[displayMember2] != null
      ) {
        title += ` - ${targetItemInDropdownData[displayMember2]}`;
      }

      const finalItem = {
        key: String(item),
        value: String(item),
        text: (title ?? item) as string,
      };

      items.push(finalItem);
    }

    setSpecialDropdownVariables(prev => ({
      ...prev,
      preparedOptions: items,
    }));
  };

  useEffect(() => {
    if (
      !(Array.isArray(preparedValue) && preparedValue.length > 0) ||
      formData == null
    ) {
      setSpecialDropdownVariables(prev => ({
        ...prev,
        isFirstLoad: true,
        preparedOptions: [],
      }));

      return;
    }

    let currentRelatedNamesInFormData: string[] = [];
    const currentValuesInFormData = String(formData[fieldName] ?? '')
      .split(',')
      .filter((item: string) => !isEmpty(item));

    if (Array.isArray(currentValuesInFormData) && currentValuesInFormData.length) {
      valuesInFormData.current = currentValuesInFormData;

      currentRelatedNamesInFormData = String(formData[relatedName] ?? '')
        .split(',')
        .filter((item: string) => !isEmpty(item));
    } else {
      actorSetActionValue(
        'formData',
        { [relatedName]: '' },
        {
          path: `${specialDropdownVariables.currentResource.value}.${specialDropdownVariables.currentResource.type}`,
        },
      );
    }

    valuesToFindBySearchRequestRef.current = findValuesNotInDropdownData(
      preparedValue,
      dropdownData.items,
    );

    if (specialDropdownVariables.isFirstLoad) {
      setSpecialDropdownVariables(prev => ({
        ...prev,
        isFirstLoad: false,
      }));

      /**
       * We have to return here because after search(handleOnSearch) some processes will be done and final result will be set to render and prepared options
       */
      if (dropdownData.totalItemsCount === 0) {
        findValueListByRequestInFirstLoad.current = true;
        handleOnSearch(preparedValue.join(','));
        return;
      }

      if (valuesToFindBySearchRequestRef.current.length > 0) {
        findValueListByRequestInFirstLoad.current = true;
        handleOnSearch(valuesToFindBySearchRequestRef.current.join(','));
        return;
      }
    }

    const finalRelatedNamesInFormData = {};
    if (
      specialDropdownVariables.isFirstLoad &&
      Array.isArray(currentRelatedNamesInFormData) &&
      currentRelatedNamesInFormData.length > 0
    ) {
      for (let index = 0; index < currentRelatedNamesInFormData.length; index++) {
        finalRelatedNamesInFormData[currentValuesInFormData[index]] =
          currentRelatedNamesInFormData[index];
      }

      relatedNamesInFormData.current = finalRelatedNamesInFormData;
    }

    if (
      valuesToFindBySearchRequestRef.current.length > 0 &&
      !specialDropdownVariables.isFirstLoad
    ) {
      handleOnSearch(valuesToFindBySearchRequestRef.current.join(','));
      return;
    }

    prepareSelectedItems(preparedValue);
  }, [preparedValue]);

  useEffect(() => {
    const items: DropdownOption[] = [];

    for (let index = 0; index < dropdownData.totalItemsCount; index++) {
      const formattedOption = {
        text: getLabelForDropdownOption(dropdownMeta, dropdownData.items[index]),
        value: dropdownData.items[index][valueMember] + '',
        key: dropdownData.items[index][valueMember] + '' + index,
      };

      if (
        specialDropdownVariables.preparedOptions.findIndex(
          item => item.value === formattedOption.value,
        ) > -1
      ) {
        continue;
      }

      items.push(formattedOption);
    }

    originalRenderOptionsRef.current = items;
    setRenderOptions(items);

    if (!findValueListByRequestInFirstLoad.current) {
      prepareSelectedItems(preparedValue);
      return;
    }

    const { relatedName, dropdown } = field;
    const { displayMember } = dropdown;
    relatedNamesInFormData.current = {};

    const currentValue = dropdownData.items.map(item => item[displayMember] ?? '');
    const names = String(currentValue)
      .split(',')
      .filter(item => item !== '');

    const valuesInFormDataLen = valuesInFormData.current.length;
    for (let index = 0; index < valuesInFormDataLen; index++) {
      relatedNamesInFormData.current[valuesInFormData.current[index]] = names[index];
    }
    actorSetActionValue('formData', currentValue, {
      path: `${currentResource.value}.${currentResource.type}.${relatedName}`,
    });

    prepareSelectedItems(preparedValue);
    findValueListByRequestInFirstLoad.current = false;
  }, [dropdownData.items]);

  /**
   * it should clear previous dropdown data in actor state every time it will
   * open if it has data then call formActionsHandler method to get data
   * @function fetchDropdownDataHandler
   * @returns {void} void
   */
  const fetchDropdownDataHandler = (): void => {
    formActionsHandler(FormActions.FetchDropdownData, {
      dropdownId,
      option: {
        dropdownInPuzzleForm: inputInPuzzleForm,
        isProfile,
        isService: isServiceMode,
        fieldName,
        fieldId,
        perPage: 99999, // َUnlike `DropdownInput`, this input doesn't have `InfiniteScroll`
      },
      dropdownMeta,
      resource: currentResource.value,
      resourceType: currentResource.type,
      hasMore: false,
      successCallback: updateDropdownData,
    } as FetchDropdownDataPayload);
  };

  /**
   * update dropdown data state
   * @function updateDropdownData
   * @param {object} data
   * @returns {void} void
   */
  const updateDropdownData = (data: DropdownData): void => {
    const { DATA = [], TOTAL = null } = data;

    setDropdownData({
      items: [...DATA, ...dropdownData.items],
      totalItemsCount: Number(TOTAL),
    });
  };

  /**
   * this function handle value change in search input drop down
   * @function handleChangeInput
   * @param {React.ChangeEvent<HTMLInputElement>} {event}
   * @return {void}
   */
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
    debouncedSearch(event.target.value, true);
  };

  /**
   * @function debouncedSearch
   * @param { string } value
   * @param { boolean } internalSearch
   * @returns { void } void
   */
  const debouncedSearch = useCallback<
    (value: string, internalSearch?: boolean) => void
  >(
    lodashDebounce((value: string, internalSearch = false) => {
      handleOnSearch(value, internalSearch);
    }, 700),
    [dropdownData.items],
  );

  /**
   * it should trigger `fetchDropdownData` function in form controller to make request
   * @function handleOnSearch
   * @param { string } searchValue
   * @returns { void } void
   */
  const handleOnSearch = (searchValue: string, internalSearch = false): void => {
    const { currentResource } = specialDropdownVariables;

    if (!internalSearch) {
      setSpecialDropdownVariables(prev => ({
        ...prev,
        isFirstLoad: false,
        currentPage: 1,
      }));

      actorSetActionValue(
        'dropDownData',
        { DATA: [], TOTAL: 0 },
        {
          path: String(dropdownId),
          replaceAll: true,
        },
      );

      const details = {
        dropdownId,
        option: {
          dropdownInPuzzleForm: inputInPuzzleForm,
          isProfile,
          isService: isServiceMode,
          fieldName,
          fieldId,
          perPage: 99999, // َUnlike `DropdownInput`, this input doesn't have `InfiniteScroll`
        },
        dropdownMeta,
        resource: currentResource.value,
        resourceType: currentResource.type,
        searchValue: searchValue,
        hasMore: false,
        successCallback: updateDropdownData,
      } as FetchDropdownDataPayload;

      formActionsHandler(FormActions.FetchDropdownData, details);
    } else {
      if (isEmpty(searchValue)) {
        setRenderOptions(originalRenderOptionsRef.current);
        return;
      }

      const _searchValue = replaceFarsiCharactersWithArabic(searchValue);
      const items = originalRenderOptionsRef.current.filter(
        item =>
          item?.text.indexOf(_searchValue) > -1 ||
          item?.value.indexOf(_searchValue) > -1,
      );

      setRenderOptions(items);
    }
  };

  /**
   * Turn values into a string chain and emit onChange
   * @function handleChange
   * @param {React.ChangeEvent<{}>} {event}
   * @param {string} {value}
   * @param {AutocompleteChangeReason} {reason}
   * @returns {void}
   */
  const handleChange: onChangeFunc = (event, newValueList) => {
    const newValue = convertToStringValue(newValueList as DropdownOption[]);
    changeFormValue(newValue);
    serviceRunner(newValue);

    selectedOptionsTemporaryRef.current = specialDropdownVariables.preparedOptions;

    updateRenderOptions(
      newValueList as DropdownOption[],
      renderOptions,
      selectedOptionsTemporaryRef.current,
    );

    setSpecialDropdownVariables(prev => ({
      ...prev,
      preparedOptions: newValueList as DropdownOption[],
    }));

    if (!isEmpty(inputValue)) {
      fetchDropdownDataHandler();
      setInputValue('');
    }
  };

  /**
   * Run form change handler to update formData and value
   * @function changeFormValue
   * @param {unknown} value
   * @returns {void}
   */
  const changeFormValue = (value: unknown): void => {
    formActionsHandler(FormActions.InputChange, {
      fieldName,
      value,
    });
  };

  const gridRowClickHandler = (newValue: Record<string, unknown>): void => {
    const dropdownOptionFormattedItem = {
      text: getLabelForDropdownOption(dropdownMeta, newValue),
      value: newValue[valueMember] + '',
      key: newValue[valueMember] + '',
    };

    if (isEmpty(value)) {
      changeFormValue(`${newValue[valueMember]},`);
      serviceRunner(`${newValue[valueMember]},`);

      setSpecialDropdownVariables(prev => ({
        ...prev,
        isFirstLoad: false,
        preparedOptions: [...prev.preparedOptions, dropdownOptionFormattedItem],
      }));
    } else {
      if (!value.includes(newValue[valueMember] as string)) {
        changeFormValue(value + ',' + newValue[valueMember]);
        setSpecialDropdownVariables(prev => ({
          ...prev,
          isFirstLoad: false,
          preparedOptions: [...prev.preparedOptions, dropdownOptionFormattedItem],
        }));
      }
    }
  };

  /**
   * it will get a value from row click or some values from checkbox and
   * add or replace it to input value state then trigger handleBlur
   * @function handleSelectionWithSearchDialog
   * @param {Object} {newValue}
   * @returns {void}
   */
  const handleSelectionWithSearchDialog = (newValue: {
    selectedIds: string[];
    selectedItemsData: Record<string, unknown>[];
  }): void => {
    const items: DropdownOption[] = [];
    newValue.selectedItemsData.map((item, index) => {
      const dropdownOptionFormattedItem = {
        text: getLabelForDropdownOption(dropdownMeta, item),
        value: item[valueMember] + '',
        key: item[valueMember] + '' + index,
      };

      items.push(dropdownOptionFormattedItem);
    });

    changeFormValue(newValue.selectedIds);
    serviceRunner(newValue.selectedIds);

    setSpecialDropdownVariables(prev => ({
      ...prev,
      isFirstLoad: false,
      preparedOptions: items,
    }));
  };

  /**
   * on click for open drop down options (first get data options and handleOpen for open drop down)
   * @function handleClick
   * @returns {void}
   */
  const handleInputBaseClick = (): void => {
    if (disabled) return;
    fetchDropdownDataHandler();
  };

  /**
   * on click for open drop down options (first get data options and handleOpen for open drop down)
   * @function handleClick
   * @param {string} {select}
   * @returns {void}
   */
  const handleDeleteItem = (select: string): void => {
    const filterValue =
      value &&
      String(value)
        ?.split(',')
        .filter((item: string) => item !== select)
        .join();

    changeFormValue(filterValue);

    const index = specialDropdownVariables.preparedOptions.findIndex(
      option => option.value == select,
    );

    if (index > -1) {
      setSpecialDropdownVariables(prev => {
        prev.preparedOptions.splice(index, 1);

        return {
          ...prev,
        };
      });
    }
  };

  /**
   *@function handleDeleteButton
   * @param { React.MouseEventHandler<HTMLButtonElement> } {event}
   * @returns { void }
   */
  const handleDeleteButton: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    if (disabled) return;
    handleDeleteItem(event.currentTarget.name);
  };

  /**
   * open search dialog
   * @function handleClickSearchDialog
   * @return {void}
   */
  const handleClickSearchDialog = (event): void => {
    event.stopPropagation();

    if (disabled) return;

    inputBlurHasBeenRanManually.current = true; // Prevent input blur

    if (buttonRefDialog.current) {
      buttonRefDialog.current.click();
      buttonRefDialog.current.style.display = 'none';
    }
  };

  const handleFocus = useCallback(() => {
    formActionsHandler(FormActions.InputFocus, {
      fieldName,
      value,
    });
  }, [value]);

  const handleBlur = useCallback(
    (_event: FocusEvent<HTMLInputElement> | null, newValue?: string) => {
      if (inputBlurHasBeenRanManually.current) return;

      formActionsHandler(FormActions.InputBlur, {
        fieldName,
        value: newValue ?? preparedValue.join(','),
      });
    },
    [value, inputBlurHasBeenRanManually.current],
  );

  /**
   * Calls `handleBlur` to run services with some delay
   * @function serviceRunner
   * @param { string } newValue it's optional
   * @requires void
   */
  const serviceRunner = useCallback(
    lodashDebounce((newValue: string) => {
      handleBlur(null, newValue);
      inputBlurHasBeenRanManually.current = true;
    }, 1000),
    [],
  );

  const required = useMemo(() => field.required, [field]);

  return (
    <AutoCompleteInputView
      inputContainerClass={inputContainerClass}
      visibleClass={visibleClass}
      required={required}
      preparedValue={preparedValue}
      wrapperRef={wrapperRef}
      label={label}
      hint={hint}
      source={source}
      dropdownMeta={dropdownMeta}
      formData={formData}
      loading={loading}
      dropdownData={dropdownData.items}
      disabled={disabled}
      record={record}
      relationResource={relationResource}
      relationSource={relationSource}
      relationInfo={relationInfo}
      resource={resource}
      handleDeleteButton={handleDeleteButton}
      preparedOptions={specialDropdownVariables?.preparedOptions}
      renderOptions={[...specialDropdownVariables.preparedOptions, ...renderOptions]}
      handleChangeInput={handleChangeInput}
      inputValue={inputValue}
      handleInputBaseClick={handleInputBaseClick}
      handleChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      handleClickSearchDialog={handleClickSearchDialog}
      dropResource={`${moduleName}/${tableName}`}
      handleSelectionWithSearchDialog={handleSelectionWithSearchDialog}
      gridRowClickHandler={gridRowClickHandler}
      buttonRefDialog={buttonRefDialog}
      inputMessage={inputMessage}
      id={dropdownId}
      getRef={getRef}
      refTags={refTags}
      limitTagCount={limitTagCount}
      field={field}
      formActionsHandler={formActionsHandler}
      parentResource={specialDropdownVariables.currentResource}
      customTestAttribute={customTestAttribute}
    />
  );
});

export default AutoCompleteInputController;
