import React, { ReactElement, useState } from 'react';

import { convertDigitsToEnglish } from '../../helper/NumberHelper';
import {
  ChangeFormValueParams,
  FormActions,
  OnBlurParams,
  OnFocusParams,
} from '../form/form.type';
import { TextInputProps } from './text-input.type';
import { TextInputView } from './text-input.view';
import { TextInputWMSView } from './text-input-wms.view';

const TextInputController = (props: TextInputProps): ReactElement => {
  const {
    value,
    formActionsHandler,
    inputMessage,
    resource,
    field,
    label,
    getRef,
    hint,
    className,
    disabled,
    visibleClass,
    inputContainerClass,
    customTestAttribute,
    inputInPuzzleForm,
  } = props;

  const { name } = field;
  const [passwordShown, setPasswordShown] = useState(false);

  /**
   * Handle Blur event
   * @function handleBlur
   * @returns {void} void
   */
  const handleBlur = (): void => {
    formActionsHandler(FormActions.InputBlur, {
      fieldName: name,
      value,
    } as OnBlurParams);
  };

  /**
   * Handle focus event
   * @function handleFocus
   * @returns {void} void
   */
  const handleFocus = (): void => {
    formActionsHandler(FormActions.InputFocus, {
      fieldName: name,
      value,
    } as OnFocusParams);
  };

  /**
   * Handle Change event
   * @function handleChange
   * @param {ChangeEvent} event
   * @returns void
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const formattedValue = convertDigitsToEnglish(event.target.value);
    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value: formattedValue,
    } as ChangeFormValueParams);
  };

  /**
   * Handle Change event
   * @function handleKeyDown
   * @param {ChangeEvent} event
   * @returns void
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    formActionsHandler(FormActions.InputKeyDown, {
      fieldName: name,
      value: (event.target as HTMLInputElement).value,
      event: event,
    } as ChangeFormValueParams);
  };

  const ViewComponent = inputInPuzzleForm ? TextInputWMSView : TextInputView;

  return (
    <ViewComponent
      getRef={getRef}
      field={field}
      value={value}
      inputMessage={inputMessage}
      className={className}
      label={label}
      hint={hint}
      disabled={disabled}
      resource={resource}
      handleBlur={handleBlur}
      handleFocus={handleFocus}
      handleKeyDown={handleKeyDown}
      handleChange={handleChange}
      visibleClass={visibleClass}
      inputContainerClass={inputContainerClass}
      customTestAttribute={customTestAttribute}
      passwordShown={passwordShown}
      setPasswordShown={setPasswordShown}
    />
  );
};

TextInputController.defaultProps = {
  className: '',
  disabled: false,
  errorMessage: '',
  field: undefined,
  formActionsHandler: undefined,
  inputRef: undefined,
  source: '',
  value: '',
  label: '',
};

export default TextInputController;
