import Settings from '@material-ui/icons/Settings';
import type { Translate } from '../../helper/Types';

import { RoutesConfigInterface } from '../app-drawer/navigation-container';

/**
 * sidebar config setting by item dynamic css
 * @function getCssSidebarRoutesConfig
 * @param {Translate} translate
 * @returns {void} void
 */
const getCssSidebarRoutesConfig = (
  translate: Translate,
): RoutesConfigInterface[] => {
  return [
    {
      id: 'css',
      title: `${translate('css.drawer')}`,
      type: 'item',
      dataTest: 'static-css',
      url: '/customcss',
      icon: <Settings />,
    },
  ];
};
export default getCssSidebarRoutesConfig;
