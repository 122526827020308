import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { NumberField, useTranslate } from 'react-admin';
import lodashDebounce from 'lodash/debounce';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import CartCardView from './CartCardView';
import { makeStyles } from '@material-ui/core/styles';
import { SELLIN_FINALIZE } from '../../core/configRouteConstant';
import { isEmptyObject } from '../../helper/data-helper';
import { CONFIG_CURRENCY_SYMBOL, getValue } from '../../core/configProvider';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: '100%',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },

  cards: {
    flexGrow: 1,
    overflow: 'auto',
    [theme.breakpoints.up('lg')]: {
      flex: 3,
    },
  },

  finalCart: {
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 6,
    alignItems: 'center',

    [theme.breakpoints.up('lg')]: {
      alignSelf: 'flex-start',
      borderTop: 'none',
      flex: 1,
      margin: 10,
      boxShadow: theme.shadows[1],
      borderRadius: theme.shape.borderRadius,
      padding: 10,

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },

  titlePrice: {
    [theme.breakpoints.up('lg')]: {
      marginBottom: 5,
    },
  },

  price: {
    fontWeight: 600,
    display: 'inline',
  },

  addToCartText: {
    flex: 1,
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      padding: '0 10px 10px',
    },
  },

  addToCartTextSpan: {
    margin: '0 5px',
  },

  finalizeBasket: {
    color: theme.palette.primary.appSecondaryTextColor,
  },
}));

const CartShowList = props => {
  const { ids, data, pattern, apiUrl, onCountChange, onDelete, redirectToPage } =
    props;
  const translate = useTranslate();
  const classes = useStyles();

  const debouncedNotifyChange = lodashDebounce(onCountChange, 500);

  const finalizeBasketButton = () => {
    redirectToPage(`/${SELLIN_FINALIZE}/${pattern.finalizeResource}`);
  };

  return (
    <div className={classes.container}>
      <div className={classes.cards}>
        {ids.map(id => (
          <CartCardView
            key={data[id][pattern.id]}
            resource={pattern.showProductResource}
            product={data[id]}
            pattern={pattern}
            apiUrl={apiUrl}
            notifyCountChange={debouncedNotifyChange}
            onDelete={onDelete}
          />
        ))}
      </div>
      <div className={classes.finalCart}>
        <div className={classes.addToCartText}>
          <Typography variant="caption" className={classes.titlePrice}>
            {translate('shop.basket.payableAmount')} :
          </Typography>
          <NumberField
            className={classes.price}
            source={'value'}
            record={{
              value: ids.reduce(
                (acc, id) => acc + parseFloat(data[id][pattern.totalPrice]),
                0,
              ),
            }}
            color="secondary"
          />
          <Typography
            color="secondary"
            variant="caption"
            className={classes.addToCartTextSpan}
          >
            {getValue(CONFIG_CURRENCY_SYMBOL) || translate('shop.basket.currency')}
          </Typography>
        </div>
        <Button
          disabled={!ids || !ids.length}
          variant="contained"
          color="secondary"
          onClick={finalizeBasketButton}
          className={classes.finalizeBasket}
        >
          {translate('shop.basket.finalizeBasket')}
        </Button>
      </div>
    </div>
  );
};

CartShowList.propTypes = {
  resource: PropTypes.string,
};

const mapDispatchToProps = {
  redirectToPage: push,
};

export default compose(connect(null, mapDispatchToProps))(CartShowList);
