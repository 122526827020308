import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import CheckResourceReady from '../container/CheckResourceReady';
import {
  CONFIG_CURRENCY_NAME,
  CONFIG_CURRENCY_SYMBOL,
  CONFIG_ROUTE_PREFIX,
  getValue,
  setValue,
  USER_TOKEN,
} from '../core/configProvider';
import ListContainerApiController from '../container/ListContainerApiController';
import GridListTileView from '../component/category/GridListTileView';
import { crudUpdateWithCallbackAction } from '../redux/crud/action';
import lodashGet from 'lodash/get';

const pattern = {
  name: 'title',
  imagePath: 'imagePath',
  imageFileName: 'imageFileName',
};

const SellinCompanyPage = props => {
  const { crudUpdateWithCallback, redirectToPage } = props;
  const resource = `account/${getValue(CONFIG_ROUTE_PREFIX)}/company`;

  const onClick = async item => {
    crudUpdateWithCallback(
      resource,
      item.id,
      {},
      item,
      action => {
        const { payload: response } = action;

        // change user info based on info from api
        setValue(USER_TOKEN, lodashGet(response, 'data.token'));
        setValue(
          CONFIG_CURRENCY_SYMBOL,
          lodashGet(response, 'data.extra.currencySymbol'),
        );
        setValue(
          CONFIG_CURRENCY_NAME,
          lodashGet(response, 'data.extra.currencyTypeTitle'),
        );

        // redirect to dashboard
        redirectToPage('/');
      },
      false,
      { skipPrefix: true },
    );
  };

  return (
    <CheckResourceReady resourceName={resource}>
      <ListContainerApiController
        {...props}
        skipPrefix
        basePath={`/${resource}`}
        page={1}
        perPage={1000}
        resource={resource}
      >
        {params => (
          <GridListTileView {...params} pattern={pattern} onClick={onClick} />
        )}
      </ListContainerApiController>
    </CheckResourceReady>
  );
};

const mapDispatchToProps = {
  crudUpdateWithCallback: crudUpdateWithCallbackAction,
  redirectToPage: push,
};

export default connect(null, mapDispatchToProps)(SellinCompanyPage);
