import React, { Fragment, useState, useEffect } from 'react';
import compose from 'recompose/compose';
import { Typography, withStyles } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { NumberField, translate } from 'react-admin';
import PropTypes from 'prop-types';
import lodashDebounce from 'lodash/debounce';
import lodashFind from 'lodash/find';
import classNames from 'classnames';

import SellinProductTagView from './SellinProductTagView';
import SellinProductAddToCartButton from './SellinProductAddToCartButton';
import LoadingBox from '../LoadingBox';
import placeholder from '../../images/placeholder.png';
import {
  API_URL,
  CONFIG_CURRENCY_SYMBOL,
  getValue,
  HEADER_HEIGHT_XS,
} from '../../core/configProvider';
import { isEmpty } from '../../helper/data-helper';
import SellinProductBarcodeView from './SellinProductBarcodeView';

const headerHeightXs = getValue(HEADER_HEIGHT_XS);

const styles = theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  },

  detailsContainer: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    '& .image-gallery-slide img': {
      width: '100%',
      height: 350,
      objectFit: 'contain',
    },
    '& .image-gallery.fullscreen-modal': {
      zIndex: 1200,
      backgroundColor: '#000000cc',
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      '& .image-gallery': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexBasis: '30%',
      },
      '& .image-gallery-content': {
        width: '100%',
      },
      '& .image-gallery .fullscreen': {
        top: 'unset',
        transform: 'unset',
      },

      '& .image-gallery.fullscreen-modal  .image-gallery-slide img': {
        width: 'auto',
        height: 'auto',
      },

      '& .image-gallery.fullscreen-modal  .image-gallery-content': {
        width: 'auto',
      },
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: headerHeightXs,
    },
  },

  cardContent: {
    padding: 16,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  productTagContainer: {
    paddingTop: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  productName: {
    textAlign: 'center',
    padding: '7px 0 10px',

    [theme.breakpoints.up('lg')]: {
      fontSize: '1.5em',
      textAlign: 'start',
      fontWeight: 500,
    },
  },

  productPrice: {
    padding: '15px 0 2px',
  },

  productPriceStrike: {
    textDecoration: 'line-through',
    color: theme.palette.text.disabled,
  },

  productDescriptionTitle: {
    padding: '15px 0 2px',
  },

  productDescription: {},

  chip: {
    fontSize: 10,
    height: 25,
    marginTop: 2,
  },

  productSimilar: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: 16,
  },

  productTagTitle: {
    marginBottom: 5,
  },

  shopAddToCartButton: {
    [theme.breakpoints.up('lg')]: {
      marginTop: 20,
      alignSelf: 'flex-end',
    },
  },

  numberFieldCaption: {
    fontSize: 12,
  },

  numberFieldProductPrice: {
    fontSize: 15,
    fontWeight: 600,
    display: 'inline',
  },
});

const SellinProductShowView = props => {
  const {
    classes,
    record,
    width,
    patternData,
    translate,
    basketData,
    basketIds,
    onCountChange,
    onDelete,
    ...rest
  } = props;

  const [images, setImages] = useState(null);

  const {
    images: imagesPattern,
    name,
    discount,
    price,
    description,
    imagePath,
    imageFileName,
    productId,
    id: idPattern,
    count: countPattern,
    hasBarcode,
    barcodeResource,
    tagResource,
  } = patternData;

  useEffect(() => {
    getProductImages();
  }, []);

  const getProductImages = () => {
    const imageListData = record[imagesPattern] ? record[imagesPattern] : [];
    if (imageListData && imageListData.length > 0) {
      const imageList = [];

      const apiUrl = getValue(API_URL);

      imageListData.map(image =>
        imageList.push({
          original: `${apiUrl}/${image[imagePath]}/${image[imageFileName]}`,
        }),
      );

      setImages(imageList);
    } else {
      setImages([{ original: placeholder }]);
    }
  };

  const debouncedNotifyChange = lodashDebounce(onCountChange, 500);

  if (!record || !images) {
    return <LoadingBox />;
  }

  const findCountRecordInBasket = () => {
    let tempCount = 0;
    basketIds.forEach(id => {
      if (record[idPattern] === basketData[id][productId]) {
        tempCount = basketData[id][countPattern];
      }
    });

    return tempCount;
  };

  const countRecord = findCountRecordInBasket();

  const basketInfo = lodashFind(basketData, [`${productId}`, record[idPattern]]);

  return (
    <div className={classes.card}>
      <div className={classes.detailsContainer}>
        <ImageGallery
          items={images}
          showFullscreenButton={true}
          showPlayButton={false}
          showThumbnails={false}
          showBullets={images.length > 1}
          useBrowserFullscreen={false}
          isRTL={true}
        />
        <div className={classes.cardContent}>
          <span className={classes.productName}>{record[name]}</span>
          <div>
            {(width === 'md' || width === 'sm' || width === 'xs') && (
              <Fragment>
                <Typography variant="subtitle2" className={classes.productPrice}>
                  {translate('sellin.price')}
                  :&nbsp;
                  <NumberField
                    className={classes.numberFieldProductPrice}
                    source={'value'}
                    record={{
                      value: !isEmpty(record[discount])
                        ? record[discount]
                        : record[price],
                    }}
                  />
                  &nbsp;
                  {getValue(CONFIG_CURRENCY_SYMBOL) ||
                    translate('shop.basket.currency')}
                </Typography>
                {!isEmpty(record[discount]) && (
                  <NumberField
                    className={classNames(
                      classes.productPriceStrike,
                      classes.numberFieldCaption,
                    )}
                    source={'value'}
                    record={{ value: record[price] }}
                  />
                )}
              </Fragment>
            )}
            <div>
              <Typography
                variant="subtitle2"
                className={classes.productDescriptionTitle}
              >
                {translate('sellin.description')}
              </Typography>
              <Typography variant="caption" className={classes.productDescription}>
                {record[description]}
              </Typography>
            </div>
            <SellinProductTagView
              resource={tagResource}
              parentId={record[idPattern]}
            />
            {record[hasBarcode] && (
              <SellinProductBarcodeView
                {...rest}
                record={record}
                resource={barcodeResource}
                parentId={record[idPattern]}
                notifyCountChange={debouncedNotifyChange}
                onDelete={onDelete}
              />
            )}
          </div>
          {!record[hasBarcode] && (width === 'lg' || width === 'xl') && (
            <SellinProductAddToCartButton
              field={record}
              classes={{ addToCartContainer: classes.shopAddToCartButton }}
              notifyCountChange={debouncedNotifyChange}
              onDelete={onDelete}
              count={countRecord}
              patternData={patternData}
              basketInfo={basketInfo}
            />
          )}
        </div>
      </div>
      {/*<div className={classes.productSimilar}>*/}
      {/*  <Typography variant="subtitle2" className={classes.productTagTitle}>*/}
      {/*    محصولات مشابه:*/}
      {/*  </Typography>*/}
      {/*  <HorizontalScrollCardListView cards={products} />*/}
      {/*</div>*/}
      {!record[hasBarcode] &&
        (width === 'md' || width === 'sm' || width === 'xs') && (
          <SellinProductAddToCartButton
            field={record}
            notifyCountChange={debouncedNotifyChange}
            onDelete={onDelete}
            count={countRecord}
            patternData={patternData}
            basketInfo={basketInfo}
          />
        )}
    </div>
  );
};

SellinProductShowView.propTypes = {
  patternData: PropTypes.object.isRequired,
  onCountChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default compose(
  withWidth(),
  withStyles(styles, { withTheme: true }),
  translate,
)(SellinProductShowView);
