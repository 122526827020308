import React from 'react';
import PropTypes from 'prop-types';
import { EditButton } from 'react-admin';
import ChangeProcessButton from './ChangeProcessButton';
import { Icon, IconButton, withStyles, CardActions } from '@material-ui/core';
import { useLocale } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import ServiceButtonContainer from '../container/ServiceButtonContainer';
import PrintButtonContainer from '../container/PrintButtonContainer';
import {
  getProcessLines,
  isRecordEditable,
  getProcessList,
  isMetaEditable,
  getSingleRecordReportRelationList,
  getRelationList,
} from '../helper/MetaHelper';
import { getVisibleServices } from '../helper/meta-helper';
import ShiftProcessButton from './ShiftProcessButton';
import { showImageDialogAction } from '../redux/showImage/action';
import { RelationActionTopToolbar } from './relation-action-top-toolbar';
import { PrevNextRecord } from './relation-action-top-toolbar/prev-next-record';

const styles = theme => ({
  cardActionStyle: {
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
  },

  iconButton: {
    padding: 5,
    margin: '0 6px',
  },
});

const ShowActions = ({
  classes,
  locale,
  basePath,
  data: record,
  metaData,
  resource,
  hasEdit,
  customRefresh,
  showImageDialog,
  ...rest
}) => {
  const processLineList = getProcessLines(
    metaData,
    record.__processuniqueid,
    record.positionid,
    record.stateid,
  );

  const localeLanguage = useLocale();

  const isEditEnabled =
    hasEdit && isRecordEditable(metaData, record) && isMetaEditable(metaData);

  const visibleServices = getVisibleServices(metaData, record);

  const processList = getProcessList(metaData);

  const processLineNamesTest =
    processLineList && processLineList.length
      ? Array.from(processLineList.map(x => x.title)).join(',')
      : '';

  /**
   * it will make image url and call showImageDialog with it.
   * @function getProcessSchematic
   * @returns {void}
   */
  const getProcessSchematic = () => {
    showImageDialog({
      url: `/${resource}/${record.id}/process/view`,
      isGetFileMode: true,
    });
  };

  const reportRelationList = getSingleRecordReportRelationList(metaData, {
    processuniqueid: record.__processuniqueid,
    positionid: record.positionid,
    stateid: record.stateid,
  });

  const relationList = getRelationList(metaData, {
    processuniqueid: record.__processuniqueid,
    positionid: record.positionid,
    stateid: record.stateid,
  })?.map(item => {
    return {
      title: item.translatedTitle?.[localeLanguage] ?? item.moduleTableTitle,
      id: item.moduleTableName,
    };
  });

  return (
    <>
      <RelationActionTopToolbar
        basePath={basePath}
        record={record}
        isType="show"
        list={[...relationList, ...reportRelationList]}
      />
      <CardActions
        className={classes.cardActionStyle}
        data-test-process-lines={processLineNamesTest}
        data-style-process-lines="processLineNamesTest"
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <PrevNextRecord />
          <div>
            {record['iseditable'] &&
              processLineList &&
              processLineList.map(line => (
                <ChangeProcessButton
                  {...rest}
                  key={line.id}
                  line={line}
                  record={record}
                  resource={resource}
                  customRefresh={customRefresh}
                  metaData={metaData}
                />
              ))}
          </div>
        </div>
        <div>
          {processList && processList.length && (
            <ShiftProcessButton
              resource={resource}
              metaData={metaData}
              processList={processList}
              recordId={record.id}
              defaultProcessUniqueId={record.__processuniqueid}
            />
          )}

          {processList && processList.length && (
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={getProcessSchematic}
              id="schematicViewButton"
            >
              <Icon>map</Icon>
            </IconButton>
          )}
          {visibleServices && (
            <ServiceButtonContainer
              locale={locale}
              resource={resource}
              selectedIds={[record.id]}
              metaData={metaData}
              customRefresh={customRefresh}
              visibleServices={visibleServices}
            />
          )}

          <PrintButtonContainer
            locale={locale}
            resource={resource}
            selectedIds={[record.id]}
          />

          {/* <IconButton
          color="primary"
          className={classes.iconButton}
          component={Link}
          to={`/form-layout/${resource}`}
          >
          <Icon>settings</Icon>
        </IconButton> */}

          <EditButton
            basePath={basePath}
            record={record}
            disabled={!isEditEnabled}
            id="editActionButton"
          />
        </div>
      </CardActions>
    </>
  );
};

ShowActions.defaultProps = {
  hasEdit: true,
};

ShowActions.propTypes = {
  record: PropTypes.object,
  metaData: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  hasEdit: PropTypes.bool.isRequired,
};

const mapDispatchTopProps = {
  showImageDialog: showImageDialogAction,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(null, mapDispatchTopProps),
)(ShowActions);
