import { FC, memo, useEffect, useState } from 'react';

import { NavItemInterface } from './nav-item.type';
import NavItemView from './nav-item.view';
import { useHistory, useLocation } from 'react-router-dom';

const NavItemController: FC<NavItemInterface> = memo(props => {
  const { item, level, baseUrl, setParentOpen, parentId } = props;
  const history = useHistory();
  const location = useLocation();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isContextMenuOpen, setContextMenuIsOpen] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  /**
   * @function handleSetActive
   * @param { boolean } isActive
   * @returns { void }
   */
  const handleSetActive = (isActive: boolean): void => {
    setIsActive(isActive);
  };

  /**
   * @function handleContextMenu
   * @param { React.MouseEvent } event
   * @returns { void }
   */
  const handleContextMenu = (event: React.MouseEvent): void => {
    if (item?.hasContextMenu) {
      event.preventDefault();
      setContextMenuIsOpen(
        isContextMenuOpen === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : null,
      );
    }
  };

  /**
   * @function handleCloseContextMenu
   * @returns { void }
   */
  const handleCloseContextMenu = (): void => {
    setContextMenuIsOpen(null);
  };

  /**
   * add badge number to title of web app
   */
  useEffect(() => {
    if (isActive) {
      document.title = item?.count ? `(${item?.count}) iMaster` : 'iMaster';
      if (location.search.includes('folder-id')) {
        history.push({
          hash: `#open${parentId ?? item?.folderId}`,
          pathname: location.pathname,
          search: location.search,
        });
      }
      setParentOpen?.();
    }
  }, [isActive]);

  return (
    <NavItemView
      item={item}
      level={level}
      baseUrl={baseUrl}
      isContextMenuOpen={isContextMenuOpen}
      onContextMenu={handleContextMenu}
      handleCloseContextMenu={handleCloseContextMenu}
      contextMenuNode={item?.contextMenuNode}
      isActive={isActive}
      handleSetActive={handleSetActive}
    />
  );
});

export default NavItemController;
