import { ReactElement } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { Box, useTheme } from '@material-ui/core';

import { DiagramContainerViewInterface } from './diagram-container.type';
import { useStyles } from './diagram-container.style';
import { DiagramCard } from './diagram-card';
import { findChildrenItems } from './diagram-container.helper';
import { ReferencesDataInterface } from '../../..';
import { CustomTheme } from '../../../../../core/themeProvider';
import { MapInteractionCSS } from 'react-map-interaction';

const DiagramContainerView = (
  props: DiagramContainerViewInterface,
): ReactElement => {
  const { data, rootItem } = props;
  const classes = useStyles();
  const theme: CustomTheme = useTheme();

  /**
   * @function renderTreeNode
   * @param targetId
   * @returns { ReactElement[] | undefined }
   */
  const renderTreeNode = (targetId: number): ReactElement[] | undefined => {
    const children = findChildrenItems(data, targetId);

    const TreeNodes = children?.map((item: ReferencesDataInterface) => (
      <TreeNode label={<DiagramCard item={item} />}>
        {renderTreeNode(item.messagereferences_id)}
      </TreeNode>
    ));

    return TreeNodes;
  };

  return (
    <MapInteractionCSS minScale={0.5} showControls={true}>
      <Box
        id="diagramContainer"
        width={1}
        style={{ direction: 'ltr' }}
        className={classes.container}
        data-test="diagram-container"
      >
        <Tree
          lineWidth={'2px'}
          lineColor={theme.palette.primary.appSecondaryDividerColor}
          lineBorderRadius={'10px'}
          label={<DiagramCard item={rootItem} />}
        >
          {renderTreeNode(rootItem.messagereferences_id)}
        </Tree>
      </Box>
    </MapInteractionCSS>
  );
};

export default DiagramContainerView;
