import { ReactElement } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';
import { MailFormViewInterface } from './mail-form.type';
import { Box } from '@material-ui/core';
import { DynamicInput } from '../../dynamic-input';
import { FormController, FormData } from '../../form';
import { useStyles } from './mail-fom.style';
import { DROPDOWN_FIELD, getTypeByField } from '../../../helper/InputHelper';
import { checkUiVisible } from '../../form/form-with-tabs/form-with-tabs.helper';
import { actorGetActionValue, FormKeyMode } from '../../../type/actor-setup';
import { FieldType } from '../../../helper/Types';

const MailFormView = (props: MailFormViewInterface): ReactElement => {
  const {
    fields,
    formContainerClass,
    inputContainerClass,
    handleActiveBCCInput,
    isBCCInputActive,
    showBCCTextButton,
    ...rest
  } = props;

  const classes = useStyles();
  const locale = useLocale();
  const translate = useTranslate();

  const currentFormData = actorGetActionValue(
    'formData',
    `automation/messages.${FormKeyMode.ROOT}`,
  );

  return (
    <FormController formName="serviceDialogForm">
      <Box {...rest} className={formContainerClass}>
        {fields &&
          fields.map((field, index) => {
            return (
              <>
                {index === 0 && showBCCTextButton && (
                  <Box display="flex" alignItems="center" width={1} mb={2}>
                    {!isBCCInputActive && (
                      <Box
                        className={classes.pointer}
                        onClick={handleActiveBCCInput}
                        component="span"
                        ml={2}
                        data-test="mail-form-bcc-show"
                      >
                        {translate('mail.bcc')}
                      </Box>
                    )}
                  </Box>
                )}
                <div className={inputContainerClass}>
                  <DynamicInput
                    key={field.id}
                    field={
                      field.uiVisible
                        ? {
                            ...field,
                            uiVisible: checkUiVisible(
                              field as FieldType,
                              currentFormData as FormData,
                            )
                              ? null
                              : field.uiVisible,
                          }
                        : field
                    }
                    label={lodashGet(
                      field,
                      ['translatedCaption', locale],
                      field.name,
                    )}
                    isServiceMode={getTypeByField(field) === DROPDOWN_FIELD}
                  />
                </div>
              </>
            );
          })}
      </Box>
    </FormController>
  );
};

export default MailFormView;
