import { SHOW_IMAGE_DIALOG, CLOSE_IMAGE_DIALOG } from './constant';

export function showImageDialogAction(data) {
  return {
    type: SHOW_IMAGE_DIALOG,
    data,
  };
}

export function closeImageDialogAction() {
  return {
    type: CLOSE_IMAGE_DIALOG,
  };
}
