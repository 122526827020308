import { put, takeEvery } from 'redux-saga/effects';
import { showNotification } from 'react-admin';

import { FIND_ONE } from './constant';
import { findOneSuccessAction, findOneFailedAction } from './action';
import dataProvider, { GET_DROPDOWN } from '../../core/dataProvider';
import { actorDispatch } from '../../type/actor-setup';

function* findOne({ id, params, callback, meta, uniqueId }) {
  try {
    const data = yield dataProvider(GET_DROPDOWN, id, { ...params, meta });
    actorDispatch('dropDownData', {
      [uniqueId]: {
        ALL: data.result,
        DATA: data.result,
        TOTAL: data.total,
      },
    });
    actorDispatch('loading', {
      [uniqueId]: false,
    });

    yield put(findOneSuccessAction({ id: uniqueId, data, meta }));

    if (data.userMessage && data.messageType !== 'ignore') {
      yield put(showNotification(data.userMessage, data.messageType));
    }

    if (typeof callback === 'function') {
      callback(null, data);
    }
  } catch (error) {
    actorDispatch('loading', {
      [uniqueId]: false,
    });
    yield put(findOneFailedAction({ id: uniqueId, error }));

    if (typeof callback === 'function') {
      callback(error, null);
    }
  }
}

export default function* dropdownSaga() {
  yield takeEvery(FIND_ONE, findOne);
}
