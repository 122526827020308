// https://docs.microsoft.com/en-us/javascript/api/?view=signalr-js-latest&preserve-view=true

import {
  Badge,
  Icon,
  IconButton,
  makeStyles,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import React, {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Identifier, useTranslate } from 'react-admin';
import { TriggerEvent, useContextMenu } from 'react-contexify';
import {
  getValue,
  SESSION_ID,
  USER_ID,
  WEB_SOCKET_API_URL,
} from '../../core/configProvider';
import dataProvider, { RUN_SERVICE } from '../../core/dataProvider';
import { isEmpty, isEmptyObject } from '../../helper/data-helper';
import { showNotification } from '../../helper/general-function-helper';
import {
  fetchDataFromNotificationList,
  getNotificationList,
} from '../../helper/NotificationHelper';
import {
  getNotificationItemPatternInfo,
  getNotificationPatternInfo,
} from '../../helper/PatternMetaHelper';
import {
  NotificationItemInterface,
  NotificationItemPatternType,
  NotificationListInterface,
} from '../../helper/Types';
import {
  getEventValue,
  NOTIFICATION_SIGNAL,
} from '../../hooks/useSignalRConnection';
import { actorDispatch, actorOnDispatch } from '../../type/actor-setup';
import { isNumber } from '../inputs/TimeInput';
import NotificationPanel from './NotificationPanel';
import WebNotification from './WebNotification';

const styles = makeStyles(theme => ({
  IconButton: {
    padding: 5,
  },
}));

const NotificationPanelContainer: FC = props => {
  const classes = styles();
  const translate = useTranslate();
  const theme = useTheme();

  const [unSeenCounter, setUnSeenCounter] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [finalData, setFinalData] = useState<NotificationItemInterface[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [apiMessage, setApiMessage] = useState<unknown>(null);

  const [webNotificationItem, setWebNotificationItem] =
    useState<NotificationItemInterface | null>(null);

  const notificationIconTriggerRef: RefObject<HTMLButtonElement> = useRef(null);
  const notificationList = useRef<NotificationItemInterface[]>();
  const itemIdRefs = useRef<(string | number)[]>([]);
  const { notificationItemPatternName } = getNotificationPatternInfo('notification');

  const currentUserId = getValue(USER_ID);

  const notificationItemPatternInfo: NotificationItemPatternType =
    getNotificationItemPatternInfo(notificationItemPatternName);
  const { id: notificationId } = notificationItemPatternInfo;

  const { show } = useContextMenu({
    id: 'notificationPanel',
  });

  /**
   * @function onSignalRNotificationEventCallback
   * @param message
   * @returns { void }
   */
  const onSignalRNotificationEventCallback = (message): void => {
    console.log('signalR Notification: ', message);
    actorDispatch('signalRNotification', message, { disableDebounce: true });
  };

  /**
   * @function getSignalRNotification
   * @returns { Promise<void> }
   */
  const getSignalRNotification = async (): Promise<void> => {
    const webSocketApiUrl = getValue(WEB_SOCKET_API_URL);

    await getEventValue({
      connectionType: NOTIFICATION_SIGNAL,
      connectionUrl: `${webSocketApiUrl}/hub/Notification?app=4&clientid=`,
      userId: currentUserId,
      signalREvent: 'RefreshNotification',
      onEventCallback: onSignalRNotificationEventCallback,
    });
  };

  useEffect(() => {
    getInitialNotification();
    currentUserId && getSignalRNotification();
    actorOnDispatch('signalRNotification', message => {
      setApiMessage(message);
    });
  }, []);

  /**
   * @function getInitialNotification
   * @returns {void}
   */
  const getInitialNotification = () => {
    const currentUserSessionId = getValue(SESSION_ID);
    if (isEmpty(currentUserSessionId)) return;

    getNotificationList()
      .then(response => {
        if (!isEmptyObject<NotificationListInterface>(response)) {
          setNewData(response, true);
        }
      })
      .catch(error => {
        showNotification(error, 'warning');
      });
  };

  /**
   * Handler for fetch data from `notificationList`.
   * @function fetchDataFromNotificationListHandler
   * @param {NotificationItemInterface[]} notificationList
   * @param {number} totalCount
   * @param {NotificationItemInterface[]} finalData
   * @return {void}
   */
  const fetchDataFromNotificationListHandler = (
    notificationList: NotificationItemInterface[],
    totalCount: number,
    finalData: NotificationItemInterface[],
  ): void => {
    if (notificationList && notificationList.length) {
      const { lastData, hasMore } = fetchDataFromNotificationList(
        notificationList,
        totalCount,
        finalData,
      );
      setFinalData(lastData);
      setHasMore(hasMore);
    }
  };

  useEffect(() => {
    if (!isEmpty(apiMessage)) {
      showNotificationItem(apiMessage);
    }
  }, [apiMessage]);

  /**
   * Show notification item based on web sockets.
   * @function showNotificationItem
   * @param {Identifier} id
   * @returns {void}
   */
  const showNotificationItem = (id: Identifier): void => {
    actorDispatch('refreshView', `RefreshMailbox`);

    getNotificationList({ [notificationId]: id })
      .then(response => {
        if (!isEmptyObject(response)) {
          setNewData(response, true);

          const notification = response.data[0];

          if (!isEmptyObject(notification)) {
            setWebNotificationItem(notification);
            if (notification?.type == 'popup') {
              showNotification(notification.notifytext, 'confirm', {
                forceShowInDialog: true,
              });
            }
          }
        }
      })
      .catch(error => {
        showNotification(error, 'warning');
      });
  };

  /**
   * Set state with new data.
   * @function setNewData
   * @param {NotificationListInterface} response
   * @returns {void}
   */
  const setNewData = (
    response: NotificationListInterface,
    initial = false,
  ): void => {
    const { totalCount, unSeenCounter, data } = response;

    setTotalCount(totalCount);
    setUnSeenCounter(unSeenCounter);
    notificationList.current = data;

    if (initial) {
      fetchDataFromNotificationListHandler(data, totalCount, []);
    } else {
      fetchDataFromNotificationListHandler(data, totalCount, finalData);
    }
  };

  /**
   * Get notification icon position in app header.
   * @function getMenuPosition
   * @returns {{x: number; y: number} | null | undefined}
   */
  const getMenuPosition = useCallback(():
    | { x: number; y: number }
    | null
    | undefined => {
    if (notificationIconTriggerRef && notificationIconTriggerRef.current) {
      const { left, right, bottom } =
        notificationIconTriggerRef.current.getBoundingClientRect();
      if (theme.direction === 'rtl') {
        return { y: bottom + 10, x: left - 20 };
      } else {
        return { y: bottom + 10, x: right - 285 };
      }
    } else {
      return { y: 0, x: 0 };
    }
  }, [notificationIconTriggerRef]);

  /**
   * Show menu contextify and run seen notification service and get `notificationList`.
   * @function handleNotificationIconClick
   * @param {TriggerEvent} event
   * @returns {Promise<void>}
   */
  const handleNotificationIconClick = async (event: TriggerEvent): Promise<void> => {
    show(event, {
      id: 'notificationPanel',
      position: getMenuPosition(),
    });
    try {
      const notifyResponse = await getNotificationList();
      if (!isEmptyObject(notifyResponse)) {
        setNewData(notifyResponse);
      }
    } catch (error) {
      console.log('error', error);
      showNotification(error, 'warning');
    }
  };

  /**
   * fetch data from `notificationList` when scroll menu contexify.
   * @function fetchMoreData
   * @returns {void}
   */
  const fetchMoreData = (): void => {
    if (notificationList.current && notificationList.current.length) {
      const { lastData, hasMore } = fetchDataFromNotificationList(
        notificationList.current,
        totalCount,
        finalData,
      );
      setFinalData(lastData);
      setHasMore(hasMore);
    }
  };

  /**
   * this function send action api for seen messages
   * @function handleSeenMessages
   * @param {string} ids
   */
  const handleSeenMessages = (ids: string) => {
    const { actionSeenId } = getNotificationPatternInfo('notification');

    dataProvider(RUN_SERVICE, null, {
      actionUniqueId: actionSeenId,
      data: {
        params: {
          notifyid: ids,
        },
      },
    })
      .then(res => {
        itemIdRefs.current = [];
        getInitialNotification();
      })
      .catch(er => {
        itemIdRefs.current = [];
      });
  };

  /**
   * Reset data when hidden menu contextify.
   * @function handleHiddenMenu
   * @returns {void}
   */
  const handleHiddenMenu = useCallback((): void => {
    if (notificationList.current && notificationList.current.length) {
      const { lastData, hasMore } = fetchDataFromNotificationList(
        notificationList.current,
        totalCount,
        [],
      );
      setFinalData(lastData);
      setHasMore(hasMore);
    }

    const listOfUnseen = [...new Set<string | number>(itemIdRefs.current)];
    if (listOfUnseen.length) {
      handleSeenMessages(listOfUnseen.join(','));
    }
  }, [notificationList.current]);

  return (
    <>
      <Tooltip title={translate('ra.notification.notifications')}>
        <div>
          <IconButton
            color="inherit"
            className={classes.IconButton}
            onClick={handleNotificationIconClick}
            ref={notificationIconTriggerRef}
            data-test-notification-icon
          >
            <Badge
              badgeContent={unSeenCounter}
              max={100}
              color="error"
              showZero={false}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              data-test-badge-notification={`${unSeenCounter}`}
            >
              <Icon>notifications_none</Icon>
            </Badge>
          </IconButton>
        </div>
      </Tooltip>

      <NotificationPanel
        uniqueKey="notificationPanel"
        notificationList={finalData}
        fetchMoreData={fetchMoreData}
        hasMore={hasMore}
        handleHiddenMenu={handleHiddenMenu}
        notificationItemPattern={notificationItemPatternInfo}
        itemIdRefs={itemIdRefs}
      />

      <WebNotification webNotificationItem={webNotificationItem}></WebNotification>
    </>
  );
};

export default NotificationPanelContainer;
