import { FC, memo, useMemo, useState } from 'react';
import { isEmptyObject } from '../../../helper/data-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../../../type/actor-setup';
import LoadingBoxView from '../../loading-box';

import { SimpleFormInterface } from './simple-form.type';
import SimpleFormView from './simple-form.view';

const SimpleFormController: FC<SimpleFormInterface> = memo(props => {
  const { fields, defaultValues, className } = props;

  const [isLoading, setIsLoading] = useState(true);

  const { current: currentResource } = actorGetActionValue('resources')!;

  useMemo(() => {
    if (defaultValues == null || isEmptyObject(defaultValues)) {
      setIsLoading(false);
      return;
    }

    actorDispatch('allFields', fields, {
      path: `${currentResource.value}.${currentResource.type}`,
      callerScopeName: 'SimpleFormController => useMemo[defaultValues]',
    });

    let finalCorrectFormData = {};

    const currentFormData = actorGetActionValue(
      'formData',
      `${currentResource.value}.${currentResource.type}`,
    );

    actorSetActionValue('initialData', defaultValues, {
      path: `${currentResource.value}.${currentResource.type}`,
    });

    if (currentFormData == null) {
      finalCorrectFormData = { ...defaultValues };
    } else {
      finalCorrectFormData = {
        ...defaultValues,
        ...currentFormData,
      };
    }
    actorSetActionValue('formData', finalCorrectFormData, {
      path: `${currentResource.value}.${currentResource.type}`,
    });

    setIsLoading(false);
  }, [defaultValues]);

  if (isLoading) {
    return <LoadingBoxView size={12} />;
  }

  return <SimpleFormView fields={fields} className={className} />;
});

export default SimpleFormController;
