import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({});

function MonthlyIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M19 6c0-.553-.448-1-1-1s-1 .447-1 1c0 .553.448 1 1 1s1-.447 1-1zm-2 6c0 .553.448 1 1 1s1-.447 1-1c0-.553-.448-1-1-1s-1 .447-1 1zm-6 0c0 .553.448 1 1 1s1-.447 1-1c0-.553-.448-1-1-1s-1 .447-1 1zm0 6c0 .553.448 1 1 1s1-.447 1-1c0-.553-.448-1-1-1s-1 .447-1 1zm-6-6c0 .553.448 1 1 1s1-.447 1-1c0-.553-.448-1-1-1s-1 .447-1 1zm0 6c0 .553.448 1 1 1s1-.447 1-1c0-.553-.448-1-1-1s-1 .447-1 1zm6-14h3v4h-4V4h1zm0 3h2V5h-2v2z"
      ></path>
    </SvgIcon>
  );
}

export default withStyles(styles)(MonthlyIcon);
