import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { withStyles } from '@material-ui/core';

export const ItemTypes = {
  FIELD: 'field',
};

const styles = theme => ({
  tableCell: {
    border: `2px dashed ${theme.palette.divider}`,
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    padding: '15px 0 15px 20px !important',
    cursor: 'grab',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '100%',
    },

    '& div span': {
      opacity: 0,
      transition: 'all 200ms',
    },
    '&:hover div span': {
      opacity: 1,
      transition: 'all 250ms',
    },
  },

  fieldItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const DroppableEmptyCell = props => {
  const { classes = {}, index, source, moveField } = props;
  const ref = useRef(null);
  // const [collectedProps, drop] = useDrop({
  // eslint-disable-next-line no-unused-vars
  const [collectedProps, drop] = useDrop({
    accept: ItemTypes.FIELD,
    drop(item) {
      if (!ref.current) {
        return;
      }
      const origin = item.source;
      const target = source;
      if (origin === target) {
        return;
      }
      moveField(origin, target);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.FIELD, source, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <td
      className={classes.tableCell}
      ref={ref}
      style={{
        opacity: isDragging ? 0 : 1,
      }}
    >
      <div className={classes.fieldItem} />
    </td>
  );
};

DroppableEmptyCell.propTypes = {
  index: PropTypes.number.isRequired,
};

export default withStyles(styles, { withTheme: true })(DroppableEmptyCell);
