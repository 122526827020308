import { useEffect, memo, useState } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import lodashOrderBy from 'lodash/orderBy';
import lodashGet from 'lodash/get';

import { handleServerSideValidationErrors } from '../../helper/validation-helper';
import { showNotification } from '../../helper/general-function-helper';
import {
  CreateLinkTarget,
  quickAccessMenuResource,
} from './quick-access-menu.helper';
import { openNewTab } from '../../helper/QuickAccessHelper';
import QuickAccessMenuView from './quick-access-menu.view';
import { setResource } from '../../helper/resource-helper';
import { clone, isEmpty } from '../../helper/data-helper';
import { ServiceDialogDataItem } from '../dialogs-stack';
import { getServices } from '../../helper/MetaHelper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  FormKeyMode,
  GetServiceDefaultValueType,
} from '../../type/actor-setup';

import type { ApiResponseWithValidationErrors } from '../form';
import type { FieldType } from '../../helper/Types';
import type {
  ErrorType,
  QuickAccessMenuDataInterface,
} from './quick-access-menu.type';

const QuickAccessMenuController = memo(() => {
  const [quickAccessMenuData, setQuickAccessMenuData] = useState<
    QuickAccessMenuDataInterface[]
  >([]);

  const locale = useLocale();
  const translate = useTranslate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const quicAccessMetaData = actorGetActionValue(
    'metaData',
    quickAccessMenuResource,
  )!;

  /**
   * @function setEmptyState
   * @returns { void }
   */
  const setEmptyState = (): void => {
    actorDispatch('selectedService', {
      service: null,
    });
  };

  /**
   * Handle error after service runs.
   * @function handleServiceError
   * @param { ErrorType } error
   * @returns { void }
   */
  const handleServiceError = (error: ErrorType): void => {
    const currentResource = actorGetActionValue('resources')!.current;

    const allFields = clone(
      actorGetActionValue('allFields', [
        currentResource.value,
        currentResource.type,
      ])!,
    ) as unknown as FieldType[];

    // We sure that here we always have `formData` and `initialData` already
    const formData = clone(
      (actorGetActionValue('formData', [
        currentResource.value,
        currentResource.type,
      ]) as FormData | null) ?? {},
    );

    const preparedApiErrorObject = {
      apiErrors: error?.data,
      requestId: error?.requestId,
    } as ApiResponseWithValidationErrors;

    handleServerSideValidationErrors(
      quicAccessMetaData as any,
      allFields,
      preparedApiErrorObject,
      formData,
      showNotification,
      translate,
      locale,
    );
  };

  /**
   * @function failureRunServiceCallback
   * @param { ErrorType } error
   * @param {  Record<string, unknown> } selectedService
   * @param selectedService
   */
  const failureRunServiceCallback = (
    error: ErrorType,
    selectedService: Record<string, unknown>,
  ) => {
    if (error?.['data']) {
      handleServiceError(error);
    } else if (error && !error?.['data']) {
      showNotification(error, 'error');
    }

    if (!selectedService?.manualExecute) {
      setEmptyState();
    }
  };

  /**
   * @function successRunServiceCallback
   * @param { unknown } response
   * @returns { void }
   */
  const successRunServiceCallback = (response: unknown): void => {
    const message = lodashGet(response, 'userMessage');

    showNotification(!isEmpty(message) ? message : translate('service.success')); // show success notification
    setEmptyState();
  };

  /**
   * this function check service and prepare param for run service.
   * @param { Record<string, unknown> } params
   * @param {  Record<string, unknown> } selectedService
   * @returns { Promise<void> }
   */
  const runService = async (
    params: Record<string, unknown> = {},
    selectedService: Record<string, unknown>,
  ): Promise<void> => {
    const [serviceModuleName, serviceModuleTableName] =
      quickAccessMenuResource.split('/');

    let serviceParameter: Record<string, unknown> = {
      actionUniqueId: selectedService ? selectedService?.uniqueId : null,
      data: {
        params,
      },
    };

    serviceParameter = {
      ...serviceParameter,
      serviceModuleName,
      serviceModuleTableName,
    };

    actorDispatch('runActionsService', {
      actionUniqueId: selectedService?.uniqueId,
      params: serviceParameter,
      failureCallback: error => failureRunServiceCallback(error, selectedService),
      successCallback: successRunServiceCallback,
    });
  };

  /**
   * Open quick dialog with `actorDispatch`.
   * @function openServiceDialog
   * @param {Record<string, unknown>} serviceParams
   * @param {Record<string, unknown>} service
   * @returns {void}
   */
  const openServiceDialog = (
    serviceParams: Record<string, unknown>,
    service: Record<string, unknown>,
  ): void => {
    actorDispatch(
      'quickDialog',
      {
        serviceIsOpen: true,
        onCloseDialogCallback: setEmptyState,
        data: {
          data: {
            locale: locale,
            service,
            isSending: false,
            serviceParams: {
              ...serviceParams,
              parentResource: quickAccessMenuResource,
            },
            parentResource: quickAccessMenuResource,
            runService: runService,
          } as ServiceDialogDataItem,
        },
      },
      {
        replaceAll: true,
      },
    );
  };

  /**
   * onSuccess for `getServiceDefaultValue` dispatch.
   * @function onSuccess
   * @param {Record<string, unknown>} params
   * @param {Record<string, unknown>} service
   * @returns {void}
   */
  const onSuccess = (
    params: Record<string, unknown>,
    service: Record<string, unknown>,
  ): void => {
    openServiceDialog(params, service);
  };

  /**
   * @function handlePrepareRunningService
   * @param { string } actionUniqueId
   * @returns { void }
   */
  const handlePrepareRunningService = (actionUniqueId: string) => {
    const allQuickAccessMenuServices = getServices(quicAccessMetaData);

    const currentSelectedService = allQuickAccessMenuServices.find(
      item => item.uniqueId === actionUniqueId,
    );

    actorDispatch('selectedService', {
      service: currentSelectedService,
    });

    if (currentSelectedService.manualExecute) {
      actorDispatch('getServiceDefaultValue', {
        service: currentSelectedService,
        parentResource: quickAccessMenuResource,
        params: {},
        onSuccess: onSuccess,
        onFailure: () => {},
      } as GetServiceDefaultValueType);
    }

    setResource(`action/${currentSelectedService.uniqueId}`, FormKeyMode.SERVICE);
  };

  /**
   * @function onQuickAccessMenuClick
   * @param { QuickAccessMenuDataInterface } item
   * @returns { void }
   */
  const onQuickAccessMenuClick = (item: QuickAccessMenuDataInterface): void => {
    if (item.actionuniqueid) {
      handlePrepareRunningService(item.actionuniqueid);
    } else {
      if (item.link) {
        openNewTab(
          item.link,
          item?.linktype ? CreateLinkTarget(item.linktype) : '_self',
        );
      }
    }
  };

  useEffect(() => {
    actorOnDispatch('quickAccessMenuData', quickAccessMenuData => {
      quickAccessMenuData && setQuickAccessMenuData(quickAccessMenuData);
    });
  }, []);

  useEffect(() => {
    actorOnDispatch('loading', loading => {
      loading &&
        lodashGet(loading, [quickAccessMenuResource]) !== undefined &&
        setIsLoading(lodashGet(loading, [quickAccessMenuResource]));
    });
  }, []);

  return (
    <QuickAccessMenuView
      data={lodashOrderBy(quickAccessMenuData, ['priority'], ['asc'])}
      isLoading={isLoading}
      onClick={onQuickAccessMenuClick}
    />
  );
});

export default QuickAccessMenuController;
