import FolderOpenIcon from '@material-ui/icons/FolderOpen';

import { MailActionsHandler, MailFolderInterface } from '..';
import { isEmptyObject } from '../../../helper/data-helper';
import { RoutesConfigInterface } from '../../app-drawer/navigation-container';
import { MailSidebarContextMenu } from './mail-sidebar-context-menu';

/**
 * @function generateChildren
 * @param { MailFolderInterface[] } listItems
 * @param { MailFolderInterface } item
 * @param { MailActionsHandler } mailActionsHandler
 * @returns { RoutesConfigInterface[] }
 */
export const generateChildren = (
  listItems: MailFolderInterface[],
  item: MailFolderInterface,
  mailActionsHandler: MailActionsHandler,
): RoutesConfigInterface[] => {
  return listItems
    ?.filter(
      (listItem: MailFolderInterface) =>
        listItem.parentfolders_id === item.folders_id,
    )
    .map((item: MailFolderInterface) => {
      if (isParentItemHasChildren(listItems, item)) {
        return createSidebarCollapseItem(listItems, item, mailActionsHandler);
      } else {
        return createSidebarItem(listItems, item, mailActionsHandler);
      }
    }) as RoutesConfigInterface[];
};

/**
 * @function createSidebarCollapseItem
 * @param { MailFolderInterface[] } listItems
 * @param { MailFolderInterface } item
 * @param { MailActionsHandler } mailActionsHandler
 * @returns { RoutesConfigInterface }
 */
const createSidebarCollapseItem = (
  listItems: MailFolderInterface[],
  item: MailFolderInterface,
  mailActionsHandler: MailActionsHandler,
): RoutesConfigInterface => {
  return {
    id: item.uniqueid,
    folderId: item?.folders_id,
    title: item.folderstitle,
    type: 'collapse',
    isOpen: item?.folders_id === 1,
    children: generateChildren(listItems, item, mailActionsHandler),
    url: `/mail?folder-id:${item.folders_id}`,
    count: item?.itemcount,
    hasContextMenu: true,
    contextMenuNode: (
      <MailSidebarContextMenu mailActionsHandler={mailActionsHandler} />
    ),
    dataTest: item.folderstitle,
  };
};

/**
 * @function createSidebarItem
 * @param { MailFolderInterface[] } listItems
 * @param { MailFolderInterface } item
 * @param { MailActionsHandler } mailActionsHandler
 * @returns { RoutesConfigInterface }
 */
const createSidebarItem = (
  listItems: MailFolderInterface[],
  item: MailFolderInterface,
  mailActionsHandler: MailActionsHandler,
): RoutesConfigInterface => ({
  id: item.uniqueid,
  folderId: item?.folders_id,
  title: item.folderstitle,
  icon: <FolderOpenIcon />,
  type: 'item',
  url: `/mail?folder-id:${item.folders_id}`,
  count: item?.itemcount,
  hasContextMenu: true,
  contextMenuNode: (
    <MailSidebarContextMenu mailActionsHandler={mailActionsHandler} />
  ),
  dataTest: item.folderstitle,
});

/**
 * @function isParentItemHasChildren
 * @param { MailFolderInterface[] } listItems
 * @param { MailFolderInterface } item
 * @returns { boolean }
 */
const isParentItemHasChildren = (
  listItems: MailFolderInterface[],
  item: MailFolderInterface,
): boolean =>
  listItems?.some(
    (listItem: MailFolderInterface) => listItem.parentfolders_id === item.folders_id,
  );

/**
 * @function generatePersonalFolders
 * @param { MailFolderInterface[] } currentFolders
 * @param { MailActionsHandler } mailActionsHandler
 * @returns { RoutesConfigInterface[] }
 */
export const generatePersonalFolders = (
  currentFolders: MailFolderInterface[],
  mailActionsHandler: MailActionsHandler,
): RoutesConfigInterface[] => {
  if (isEmptyObject(currentFolders)) {
    return [];
  }

  return currentFolders
    ?.filter((folder: MailFolderInterface) => !folder.parentfolders_id)
    .map((item: MailFolderInterface) => {
      if (isParentItemHasChildren(currentFolders, item)) {
        return createSidebarCollapseItem(currentFolders, item, mailActionsHandler);
      } else {
        return createSidebarItem(currentFolders, item, mailActionsHandler);
      }
    }) as RoutesConfigInterface[];
};
