import { ReactElement } from 'react';
import { RichTextEditorFieldViewInterface } from './rich-text-editor-field.type';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '@SamianSoft/editor/build/ckeditor';
import { Icon, IconButton } from '@material-ui/core';
import { useStyles } from './rich-text-editor-field.style';

const RichTextEditorFieldView = (
  props: RichTextEditorFieldViewInterface,
): ReactElement => {
  const { value, config, isPrintMode, handlePrint } = props;
  const classes = useStyles();

  let prevEditor: Editor = null;

  return (
    <div className={classes.container}>
      {!isPrintMode && (
        <div className={classes.toolbarContainer}>
          <IconButton onClick={handlePrint} color="primary" id="printButton">
            <Icon>print</Icon>
          </IconButton>
        </div>
      )}
      <CKEditor
        editor={Editor}
        config={config}
        disabled={true}
        data={value}
        onReady={editor => {
          // You can store the "editor" and use when it is needed.
          // Insert the toolbar before the editable area.
          editor &&
            editor.ui
              ?.getEditableElement()
              .parentElement.insertBefore(
                editor.ui.view?.toolbar.element,
                editor.ui.getEditableElement(),
              );

          prevEditor = editor;
        }}
        onError={({ willEditorRestart }) => {
          // If the editor is restarted, the toolbar element will be created once again.
          // The `onReady` callback will be called again and the new toolbar will be added.
          // This is why you need to remove the older toolbar.
          if (willEditorRestart) {
            prevEditor?.ui.view.toolbar.element.remove();
          }
        }}
      />
    </div>
  );
};

export default RichTextEditorFieldView;
