import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  appsSidebar: {
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '20rem',
    },
    [theme.breakpoints.up('xl')]: {
      width: '22rem',
    },
  },
  appSidebarDrawer: {
    width: '24rem',
    '& .listItem': {
      zIndex: 1305,
    },
  },
}));
