import produce from 'immer';
import { USER_LOGOUT } from 'react-admin';
import {
  SEND_CONFIRMATION,
  SEND_CONFIRMATION_FAILURE,
  SEND_CONFIRMATION_SUCCESS,
  SEND_PHONE_NUMBER,
  SEND_PHONE_NUMBER_FAILURE,
  SEND_PHONE_NUMBER_SUCCESS,
} from './constant';

const initialState = {
  isPhoneSent: false,
  isLoading: false,
  errorMessage: null,
};

const myReducer = (state = initialState, { type, error }) =>
  produce(state, draft => {
    // eslint-disable-next-line default-case
    switch (type) {
      case USER_LOGOUT:
        return initialState;

      case SEND_PHONE_NUMBER:
        draft.isLoading = true;
        draft.isPhoneSent = false;
        draft.errorMessage = null;
        break;

      case SEND_PHONE_NUMBER_SUCCESS:
        draft.isLoading = false;
        draft.isPhoneSent = true;
        draft.errorMessage = null;
        break;

      case SEND_PHONE_NUMBER_FAILURE:
        draft.isLoading = false;
        draft.isPhoneSent = false;
        draft.errorMessage = error;
        break;

      case SEND_CONFIRMATION:
        draft.isLoading = true;
        draft.errorMessage = null;
        break;

      case SEND_CONFIRMATION_SUCCESS:
        draft.isLoading = false;
        draft.isPhoneSent = false;
        draft.errorMessage = null;
        break;

      case SEND_CONFIRMATION_FAILURE:
        draft.isLoading = false;
        draft.errorMessage = error;
        break;
    }
  });

export default myReducer;
