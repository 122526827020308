import { makeStyles, TextField } from '@material-ui/core';
import { useInput, useTranslate } from 'react-admin';
import React, {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  MouseEventHandler,
} from 'react';
import lodashGet from 'lodash/get';

import { convertDigitsToEnglish } from '../../helper/NumberHelper';
import { dummyFunc } from '../../helper/InputHelper';
import sanitizeRestProps from './sanitizeRestProps';
import { FieldType } from '../../helper/Types';
import { CustomTheme } from '../../core/themeProvider';
import { UseInputType } from '../dynamicInput/InputTypes';

const useStyles = makeStyles((theme: CustomTheme) => ({
  error: {
    '& + p': {
      position: 'absolute',
      right: 0,
    },
  },

  input: {
    '&[disabled]': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },
  },
}));

interface LongTextInputType {
  className: string;
  options: object;
  textAlign?: string;
  basePath?: string;
  record?: object;
  disabled: boolean;
  visibleClass: string;
  customError: string;
  field: Partial<FieldType>;
  rest?: object;
  onFocus?: Function;
  onBlur?: Function;
  onChange: Function;
  onClick: MouseEventHandler<HTMLDivElement>;
}

const LongTextInput: FC<LongTextInputType> = props => {
  const {
    className,
    options,
    textAlign = 'right',
    basePath,
    record,
    disabled,
    visibleClass,
    customError,
    field,
    ...rest
  } = props;

  const classes = useStyles();
  const translate: Function = useTranslate();

  const {
    input,
    meta: { touched, error },
  }: UseInputType = useInput(props);

  /**
   * Handle blur event
   * @function handleBlur
   * @param {Event} e
   * @returns {void}
   */
  const handleBlur: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = e => {
    const { onBlur = dummyFunc } = props;
    onBlur(e);
    input.onBlur(e);
  };

  /**
   * Handle focus event
   * @function handleFocus
   * @param {Event} e
   * @returns {void}
   */
  const handleFocus: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = e => {
    const { onFocus = dummyFunc } = props;
    onFocus(e);
    input.onFocus(e);
  };

  /**
   * Handle change event
   * @function handleChange
   * @param {Event} e
   * @returns {void}
   */
  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = e => {
    const userInput = convertDigitsToEnglish(e.target.value);

    props.onChange(userInput);
    input.onChange(userInput);
  };

  return (
    <TextField
      {...rest}
      {...options}
      {...input}
      {...sanitizeRestProps(rest as any)}
      error={!!customError || !!(touched && error)}
      helperText={
        customError
          ? customError
          : touched && translate(lodashGet(error, 'message', error))
      }
      required={field.required}
      margin="normal"
      multiline
      rowsMax="4"
      className={`${visibleClass} ${className}`}
      InputProps={{
        classes: {
          error: classes.error,
          input: classes.input,
        },
      }}
      variant="outlined"
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

export default LongTextInput;
