import React, { useState, FC, useEffect } from 'react';
import compose from 'recompose/compose';
import { Button, Icon } from '@material-ui/core';
import { translate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { isEnterPressed } from '../../helper/FormHelper';
import { isEmpty } from '../../helper/data-helper';

interface TodoAddNewTaskPropsInterface {
  submit: Function;
  placeholder: string;
  translate: Function;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  addIcon: {
    margin: '0 15px 0 24px',
  },

  textInput: {
    border: 'none',
    outline: 'none',
    padding: '15px 0',
    backgroundColor: 'transparent',
    flexGrow: 1,
    fontFamily: theme.typography.fontFamily,
  },

  button: {
    margin: '0 10px',
  },
}));

const TodoAddNewTaskView: FC<TodoAddNewTaskPropsInterface> = props => {
  const { placeholder, submit, translate } = props;
  const classes = useStyles();

  const [inputValue, setInputValue] = useState('');

  const inputRef: any = React.createRef();

  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  const handleInputChange = event => {
    if (!isEmpty(event.target.value)) {
      setInputValue(event.target.value);
    } else {
      setInputValue('');
    }
  };

  const handleIconClick = () => {
    inputRef.current.focus();
  };

  const handleKeyDown = event => {
    if (isEnterPressed(event)) {
      event.preventDefault();
      event.stopPropagation();
      submitForm();
    }
  };

  const submitForm = () => {
    submit(inputValue);
    setInputValue('');
  };

  return (
    <div className={classes.container} data-test-field-name="addTask">
      <Icon
        color="secondary"
        className={classes.addIcon}
        fontSize="small"
        onClick={handleIconClick}
      >
        add
      </Icon>
      <input
        className={classes.textInput}
        placeholder={placeholder}
        ref={inputRef}
        onChange={handleInputChange}
        value={inputValue}
        onKeyDown={handleKeyDown}
      />
      {inputValue && (
        <Button
          size="small"
          color="secondary"
          disabled={!inputValue}
          onClick={submitForm}
          className={classes.button}
        >
          {translate('todo.add')}
        </Button>
      )}
    </div>
  );
};

export default compose(translate)(TodoAddNewTaskView);
