import { cloneElement, ReactElement, useState } from 'react';
import samianStyle from '../samian-style/samian-style.module.css';
import { CustomFormInterface } from './form.type';
import LoadingBox from '../loading-box';
import { useStyles } from './form.style';
import PinnedNotesController from '../new-relation-panel/note-relation/pinned-note/pinned-notes-controller';
import { RelationNoteDataInterface } from '../show-record-with-relation';
import { actorDispatch } from '../../type/actor-setup';

const CustomForm = (props: CustomFormInterface): ReactElement => {
  const {
    children,
    childProps,
    Toolbar,
    toolbarProps,
    isBottomToolbar,
    loading,
    formName,
  } = props;

  const classes = useStyles({ formName });

  /**
   // TODO: add loading logic in https://jira.samiansoft.com/browse/RCT-1484
   *  if (!isEmpty(computedId) && !record) {
   *    return <LoadingBox />;
   *  }
   *
   *  if (metaDataError) {
   *    return <NotFound title={metaDataError} />;
   *  }
   *
   *  if (!metaData) {
   *    return <LoadingBox />;
   *  }
   */

  return (
    <div className={`${samianStyle.flexColumn} ${classes.formViewContainer}`}>
      {loading && <LoadingBox absolute></LoadingBox>}
      {!isBottomToolbar && Toolbar && cloneElement(Toolbar, toolbarProps)}
      {cloneElement(children, { ...childProps })}
      {isBottomToolbar && Toolbar && cloneElement(Toolbar, toolbarProps)}
    </div>
  );
};

CustomForm.defaultProps = {
  Toolbar: <></>,
  childProps: undefined,
  children: <></>,
  toolbarProps: {},
  isBottomToolbar: false,
  loading: false,
};

export { CustomForm };
