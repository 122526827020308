import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useSafeSetState } from 'ra-core';
import { connect } from 'react-redux';

import {
  sendPhoneAction,
  sendConfirmationAction,
} from '../../redux/loginWithSMS/action';
import { isEnterPressed } from '../../helper/FormHelper';
import { isEmpty } from '../../helper/data-helper';
import { CustomTheme } from '../../core/themeProvider';

interface Props {
  isLoading: boolean;
  isPhoneSent: boolean;
  redirectTo?: string;
  errorMessage?: string | null;
  sendPhoneNumber: (params: object) => void;
  sendConfirmation: (params: object) => void;
}

const useStyles = makeStyles(
  (theme: CustomTheme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },

    input: {
      marginTop: '1em',
    },

    button: {
      width: '100%',
      color: theme.palette.primary.appSecondaryTextColor,
    },

    icon: {
      marginRight: theme.spacing(1),
    },

    textFieldStyle: {
      color: theme.palette.primary.appPrimaryTextColor,
    },

    textFieldRoot: {
      '& label.Mui-focused': {
        color: theme.palette.primary.appPrimaryTextColor,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.primary.appPrimaryTextColor,
      },
    },
  }),
  { name: 'RaLoginForm' },
);

const LoginWithSMSForm: FunctionComponent<Props> = ({
  redirectTo,
  isLoading,
  isPhoneSent,
  errorMessage,
  sendPhoneNumber,
  sendConfirmation,
}) => {
  const [mobile, setMobile] = useSafeSetState<string>('');
  const [confirmation, setConfirmation] = useSafeSetState('');
  const translate = useTranslate();
  const classes = useStyles({});

  const handleMobileChange = event => setMobile(event.target.value);
  const handleConfirmationChange = event => setConfirmation(event.target.value);
  const handleMobileKeyPress = event => {
    if (isEnterPressed(event)) {
      handleMobileSendButton();
    }
  };

  const handleMobileSendButton = () => sendPhoneNumber({ mobile });
  const handleConfirmationSendButton = () =>
    sendConfirmation({ mobile, confirmation });
  const handleConfirmKeyPress = event => {
    if (isEnterPressed(event)) {
      handleConfirmationSendButton();
    }
  };

  const textFieldStyle = {
    root: classes.textFieldRoot,
  };

  return (
    <div>
      <div className={classes.form}>
        {!isPhoneSent ? (
          <div className={classes.input} key="mobile">
            <TextField
              fullWidth
              autoFocus
              id="mobile"
              name="mobile"
              label={translate('login.mobile')}
              value={mobile}
              onChange={handleMobileChange}
              disabled={isLoading}
              onKeyPress={handleMobileKeyPress}
              classes={textFieldStyle}
            />
          </div>
        ) : (
          <div className={classes.input} key="confirmation">
            <TextField
              fullWidth
              autoFocus
              id="confirmation"
              name="confirmation"
              label={translate('login.confirmation')}
              value={confirmation}
              onChange={handleConfirmationChange}
              disabled={isLoading}
              onKeyPress={handleConfirmKeyPress}
              classes={textFieldStyle}
            />
          </div>
        )}
      </div>
      <CardActions>
        <Button
          variant="contained"
          type="submit"
          color="secondary"
          disabled={isLoading || isEmpty(mobile)}
          className={classes.button}
          onClick={
            !isPhoneSent ? handleMobileSendButton : handleConfirmationSendButton
          }
        >
          {isLoading && (
            <CircularProgress
              color="secondary"
              className={classes.icon}
              size={18}
              thickness={2}
            />
          )}
          {!isPhoneSent
            ? translate('login.send_mobile')
            : translate('login.send_confirmation')}
        </Button>
      </CardActions>
    </div>
  );
};

LoginWithSMSForm.propTypes = {
  redirectTo: PropTypes.string,
};

const mapStateToProps = state => ({
  isLoading: state.loginWithSMS.isLoading,
  isPhoneSent: state.loginWithSMS.isPhoneSent,
  errorMessage: state.loginWithSMS.errorMessage,
});

const mapDispatchToProps = {
  sendPhoneNumber: sendPhoneAction,
  sendConfirmation: sendConfirmationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginWithSMSForm);
