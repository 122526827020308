import React, { FC, useEffect, useMemo } from 'react';
import { useInput } from 'react-admin';
import { dummyFunc } from '../../../helper/InputHelper';

import { NumberInputControllerInterface } from './number-input.type';
import BaseNumberInput from './base-number-input';
import NumberInputView from './number-input.view';
import { useStyles } from './number-input.style';

const NumberInputController: FC<NumberInputControllerInterface> = props => {
  const {
    className,
    field,
    onChange: onChangeProps = dummyFunc,
    formData,
    ...rest
  } = props;

  const {
    input,
    meta: { touched, error },
  } = useInput(props);

  const { defaultValue, name = '', hidden } = field;

  useEffect(() => {
    if (!Object.prototype.hasOwnProperty.call(formData, name) && hidden) {
      onChangeProps(+defaultValue);
      input.onChange(+defaultValue);
    }
  }, []);

  const classes = useStyles(props);

  const inputPropsParams = useMemo(
    (): object => ({
      classes: {
        error: classes.error,
      },
      inputComponent: BaseNumberInput as any,
    }),
    [],
  );

  const hasError = !!(touched && error);

  return (
    <NumberInputView
      {...rest}
      inputPropsParams={inputPropsParams}
      input={input}
      hasError={hasError}
      field={field}
      error={error}
      className={className}
    />
  );
};

export default NumberInputController;
