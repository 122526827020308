import dropdown from './dropdown/reducer';
import dropdownOld from './dropdown-old/reducer';
import tag from './tag/reducer';
import puzzleMetaData from './puzzleMetaData/reducer';
import puzzlePage from './puzzlePage/reducer';
import listPage from './listPage/reducer';
import profile from './profile/reducer';
import showImage from './showImage/reducer';
import quickCreate from './quickCreate/reducer';
import dropdownQuickCreate from './dropdownQuickCreate/reducer';
import listFilter from './listFilter/reducer';
import listColumnChoice from './listColumnChoice/reducer';
// import searchPopup from './searchPopup/reducer';
import menu from './menu/reducer';
// import quickEdit from './quickEdit/reducer';
import todoList from './todoList/reducer';
import loginWithSMS from './loginWithSMS/reducer';
import shop from './shop/reducer';
import gridList from './gridList/reducer';
import breadcrumb from './breadcrumb/reducer';
import relation from './relation/reducer';
import viewPage from './viewPage/reducer';

export default {
  dropdownOld,
  dropdown,
  tag,
  puzzleMetaData,
  puzzlePage,
  listPage,
  profile,
  showImage,
  quickCreate,
  dropdownQuickCreate,
  listFilter,
  listColumnChoice,
  // searchPopup,
  menu,
  // quickEdit,
  todoList,
  loginWithSMS,
  shop,
  gridList,
  breadcrumb,
  relation,
  viewPage,
};
