import React, {
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import lodashGet from 'lodash/get';
import lodashDebounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslate, useInput } from 'react-admin';
import { Dropdown } from 'semantic-ui-react';

import { convertDigitsToEnglish } from '../../helper/NumberHelper';
import { findOneAction as findDropdownDataAction } from '../../redux/dropdown-old/action';
import {
  getLabelForDropdownOption,
  findSelectedItemFromDropdownData,
  dropdownPreFilledName,
  triggerDropdownFetchData,
  getDropdownRequestParams,
} from '../../helper/DropdownHelper';
import { isEmpty } from '../../helper/data-helper';
import SearchPopupButton from '../search-popup-old/SearchPopupButton-old';
import { CustomTheme } from '../../core/themeProvider';
import { dummyFunc } from '../../helper/InputHelper';

type onChangeFunc = (
  event: SyntheticEvent<HTMLElement>,
  data: {
    value?: any;
  },
) => void;

type onSearchChangeFunc = (
  event: SyntheticEvent<HTMLElement>,
  data: {
    searchQuery: string;
  },
) => void;

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    width: '100%',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    paddingBottom: 0,
    paddingTop: 0,
    margin: 0,
    height: 17,

    '&[disabled]': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },

    '& .ui.fluid.dropdown': {
      border: 'none',
      borderRadius: 0,
      background: 'none',
      padding: '2px 0',
      minHeight: 'auto',
      boxShadow: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      flexBasis: '100%',
      height: 17,
    },

    '& .ui.selection.active.dropdown:hover': {
      border: 'none',
      boxShadow: 'none',
    },

    '& input': {
      fontFamily: theme.typography.fontFamily,
      padding: '2px 0 !important',
    },

    '& .dropdown.icon': {
      color: theme.palette.primary.appPrimaryDisableIconColor,
      padding: '2px 5px !important',
    },

    '& .ui.loading.selection.dropdown>i.icon': {
      padding: '8px 10px !important',
    },

    '& .ui.dropdown .menu': {
      top: '133%',
      [theme.breakpoints.down('sm')]: {
        top: '112%',
      },
    },

    '& .ui.selection.active.dropdown .menu': {
      border: `1px solid ${theme.palette.primary.appPrimaryDividerColor}`,
    },

    '& .ui.selection.active.dropdown:hover .menu': {
      border: `1px solid ${theme.palette.primary.appPrimaryDividerColor}`,
    },

    '& .ui.selection.dropdown .menu>.item': {
      fontSize: 13,
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },

    '& .ui.search.dropdown>.text': {
      fontSize: 13,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      flexBasis: '90%',
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },

    '& .ui.selection.dropdown .menu': {
      width: 'max-content',
      minWidth: 'auto',
    },

    '& .ui.upward.dropdown>.menu': {
      bottom: 'auto',
    },

    '& .ui.fluid.dropdown>.dropdown.icon': {
      float: 'none',
    },
  },

  legend: {
    fontSize: 10,
    lineHeight: 0,
    color: theme.palette.primary.appSecondaryTextColor,
    display: 'block',
    padding: '0 3px',

    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
    },
  },

  fieldsetError: {
    border: `1px solid ${theme.palette.error.main}`,
  },

  legendError: {
    color: theme.palette.error.main,
  },

  dropdownContainer: {
    display: 'flex',
    height: 17,
    alignItems: 'center',
    '& span': {
      cursor: 'pointer',
    },

    margin: 0,
    justifyContent: 'space-between',
    flexBasis: '100%',

    position: 'absolute',
    width: '100%',
  },

  errorText: {
    position: 'absolute',
    right: 90,
    top: 10,
    width: 100,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 8,
    [theme.breakpoints.down('lg')]: {
      right: 18,
    },
    [theme.breakpoints.down('sm')]: {
      top: 3,
    },
  },

  label: {
    fontSize: 13,
    color: theme.palette.primary.appSecondaryTextColor,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },

  dropdownInPuzzleForm: {
    margin: '7px 3px 0',
  },
}));

const DropdownInput = props => {
  const {
    allLoadedData,
    dropdownData,
    dropdownMeta,
    customChangeHandler,
    loading,
    source,
    record,
    field,
    disableDropdownSearchPopup,
    relationResource,
    relationSource,
    relationInfo,
    addToRelationArray,
    formData,
    disabled,
    parentResource,
  } = props;
  const { valueMember, filterValueMember } = dropdownMeta;
  const translate = useTranslate();
  const classes = useStyles(props);

  const [params, setParams] = useState(
    getDropdownRequestParams({
      dropdownMeta,
      record: formData,
    }),
  );

  const {
    input: { onChange, onBlur, onFocus, value },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  useEffect(() => {
    const isDropdownParameters = !!lodashGet(
      props,
      'dropdownMeta.parameters.length',
    );

    const preFilledName = dropdownPreFilledName(field);

    // already have data for this
    if (
      loading ||
      !record ||
      (dropdownData && dropdownData.length) ||
      (record && !record[source] && isDropdownParameters) ||
      (!isEmpty(value) && !isEmpty(record[preFilledName]))
    ) {
      // if it's a new record, and we already have this dropdown's value, means we have not set mapped fields
      if (record && record.isNewRecord) {
        const dropdownItem = allLoadedData.find(item => {
          // keep loose comparison, because server might give number inside string
          // eslint-disable-next-line eqeqeq
          return item[valueMember] == value;
        });

        if (dropdownItem) {
          customChangeHandler(value, dropdownItem, true);
        }
      }

      return;
    }

    triggerDropdownFetchData(props, value);
  }, []);

  useEffect(() => {
    const selectedItem = findSelectedItemFromDropdownData({
      dropdownMeta,
      dataArray: allLoadedData,
      record,
      value,
      field,
    });
    // if we have a selected value, and no item can be found with this data
    if (!isEmpty(value)) {
      if (!selectedItem) {
        triggerDropdownFetchData(props, value);
      } else {
        customChangeHandler(lodashGet(selectedItem, 'value', null), selectedItem);
      }
    }
  }, [value]);

  const handleBlur = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      onBlur(event);
      props.onBlur(event);
    },
    [props.onBlur, onBlur],
  );

  const handleFocus = useCallback(
    (event: SyntheticEvent) => {
      onFocus(event);
      props.onFocus(event);

      const newParams = getDropdownRequestParams({
        dropdownMeta,
        record: formData,
      });

      if (!dropdownData || !dropdownData.length || newParams !== params) {
        triggerDropdownFetchData(props, '');
        setParams(newParams);
      }
    },
    [props.onFocus, onFocus, formData, params],
  );

  const handleChange: onChangeFunc = useCallback(
    (event, { value: newValue }) => {
      const userInput = convertDigitsToEnglish(newValue);

      onChange(userInput);
      props.onChange(userInput);
    },
    [props.onChange, onChange],
  );

  const changeFormValue = (field, value) => {
    if (value == null) return;
    onChange(value.toString());
    props.onChange(value.toString());
  };

  const handleSearchChange: onSearchChangeFunc = useCallback(
    lodashDebounce((event, { searchQuery }) => {
      triggerDropdownFetchData(props, searchQuery);
    }, 700),
    [props.formData],
  );

  const hasError = !!(touched && error);

  const preparedOptions = useMemo(
    () =>
      dropdownData.map((item, index) => ({
        text: getLabelForDropdownOption(dropdownMeta, item),
        value: item[filterValueMember] + '',
        key: item[valueMember] + '' + index,
      })),
    [dropdownData],
  );

  const selectedValueLabel = useMemo(() => {
    // if we have any data from api, use that first!
    const dropItem = findSelectedItemFromDropdownData({
      dropdownMeta,
      dataArray: allLoadedData,
      record,
      value,
      field,
    });

    if (dropItem) {
      return dropItem.label;
    }

    return value;
  }, [value, allLoadedData.length]);

  const dropdownText = !isEmpty(selectedValueLabel) ? selectedValueLabel : '';

  const getSelectRef = dropManager => {
    const parentGetRef = lodashGet(props, 'options.inputRef');
    if (
      !dropManager ||
      !parentGetRef ||
      !lodashGet(dropManager, 'searchRef.current')
    ) {
      return;
    }

    parentGetRef(lodashGet(dropManager, 'searchRef.current'));
  };

  const { moduleName, tableName, id } = dropdownMeta;
  const dropResource = `${moduleName}/${tableName}`;

  const onKeyDown = lodashGet(props, 'options.onKeyDown');
  const searchInputParams = useMemo(
    () => ({
      onKeyDown,
    }),
    [onKeyDown],
  );

  return (
    <div className={classes.root} data-test-field-name={props.input.name}>
      <div className={classes.dropdownContainer}>
        <Dropdown
          data-test-dropdown-search-input
          fluid
          selection
          clearable
          closeOnBlur
          error={hasError}
          selectOnNavigation={false}
          ref={getSelectRef}
          noResultsMessage={translate('ra.navigation.no_results')}
          text={Array.isArray(dropdownText) ? dropdownText[2] : dropdownText}
          loading={loading}
          open={undefined}
          value={Array.isArray(value) ? value[2] : value}
          options={preparedOptions}
          onChange={handleChange}
          onSearchChange={handleSearchChange}
          selectOnBlur={false}
          onBlur={handleBlur}
          onFocus={handleFocus}
          searchInput={searchInputParams}
          search={preparedOptions => preparedOptions}
          disabled={disabled}
        />

        {!disabled && !disableDropdownSearchPopup && (
          <SearchPopupButton
            resource={dropResource}
            parentResource={parentResource}
            source={source}
            dropdownId={id}
            dropdownMeta={dropdownMeta}
            record={record}
            relationResource={relationResource}
            relationSource={relationSource}
            relationInfo={relationInfo}
            disabled={disabled || loading}
            changeFormValue={changeFormValue}
            addToRelationArray={addToRelationArray}
            formData={formData}
            useInFilterInput={true}
          />
        )}
      </div>

      {hasError && (
        <Typography className={classes.errorText} variant="caption" color="error">
          {translate(lodashGet(error, 'message', error))}
        </Typography>
      )}
    </div>
  );
};

DropdownInput.defaultProps = {
  onFocus: dummyFunc,
  onBlur: dummyFunc,
  onChange: dummyFunc,
  customChangeHandler: dummyFunc,
  options: {},
  step: 'any',
  textAlign: 'right',
};

const defaultEmptyArray = [];

const mapStateToProps = (state, props) => {
  const dropdownId = lodashGet(props, 'dropdownMeta.id');

  return {
    loading: !!lodashGet(state, ['dropdownOld', dropdownId + '_loading']), // loading status of data
    dropdownData: lodashGet(state, ['dropdownOld', dropdownId], defaultEmptyArray), // app dropdown data object
    allLoadedData: lodashGet(
      state,
      ['dropdownOld', dropdownId + '_all'],
      defaultEmptyArray,
    ), // all previously loaded data
    dropdownError: lodashGet(state, ['dropdownOld', dropdownId + '_error']), // app dropdown data object
  };
};

const mapDispatchToProps = {
  findDropdownData: findDropdownDataAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownInput);
