import { ReactElement } from 'react';
import { TextField } from '@material-ui/core';

import { useStyles } from './number-text-field.style';
import { NumberTextFieldViewInterface } from './number-text-field.type';

const NumberTextFieldWMSView = (
  props: NumberTextFieldViewInterface,
): ReactElement => {
  const {
    getRef,
    field,
    resource,
    inputMessage,
    label,
    hint,
    customOnChange,
    onKeyDown,
    onMouseUp,
    onFocus,
    onBlur,
    value,
    inputMode,
    type,
    onClick,
    visibleClass,
    disabled,
    customTestAttribute,
  } = props;
  const classes = useStyles({ messageType: inputMessage?.messageType });
  const { required, precision, name, maxLength } = field;

  return (
    <TextField
      {...customTestAttribute}
      ref={getRef}
      type={type}
      value={value ?? ''}
      name={name}
      label={label}
      required={required!}
      margin="normal"
      className={`${visibleClass} ${classes.wmsInput} ${classes.inputStylePuzzleForm}`}
      style={{ width: `${field.widthPercent ?? 100}%` }}
      inputMode={inputMode}
      variant="outlined"
      onChange={customOnChange}
      onFocus={onFocus}
      onBlur={onBlur}
      InputProps={{
        autoComplete: 'off',
        classes: {
          disabled: classes.disabled,
        },
      }}
      inputProps={{
        'data-test-input-name': `${resource}/${name}`,
        'data-test-precision': precision,
        maxLength,
      }}
      disabled={disabled}
      data-test="inputContainerTextField"
      data-test-has-error={inputMessage?.messageType === 'error'}
      data-test-has-warning={inputMessage?.messageType === 'warning'}
      size={'small'}
    />
  );
};

export default NumberTextFieldWMSView;
