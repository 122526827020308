import React, {
  SyntheticEvent,
  ReactNode,
  useMemo,
  useEffect,
  useState,
  useRef,
} from 'react';
import lodashGet from 'lodash/get';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import {
  useUpdate,
  Toolbar,
  useTranslate,
  Button as ReactAdminButton,
  useCreate,
} from 'react-admin';
import { Icon, Button, makeStyles, IconButton } from '@material-ui/core';
import ProcessIcon from '@material-ui/icons/CallMade';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import {
  getProcessLines,
  getProcessList,
  getProcessTaskInfo,
  isRecordEditable,
  isSingleRecordTable,
} from '../helper/MetaHelper';
import { CustomTheme } from '../core/themeProvider';
import useWidth from './useWidth';
import CustomFormButton from './form-component-old/CustomFormButton';
import { handleServerSideValidationErrors } from '../helper/validation-helper';
import { FieldType, MetaData, MetaDataBase, ValidationError } from '../helper/Types';
import {
  actorGetActionValue,
  RecordKeyMode,
  actorOnDispatch,
} from '../type/actor-setup';
import { sanitizeFormData } from './form/form.helper';
import { SaveType } from './QuickCreateButtonToolbar';
import { ExtraParamsInterface, FormActionProps, FormActions } from './form';
import PrintButtonContainer from '../container/PrintButtonContainer';
import ServiceButtonContainer from '../container/ServiceButtonContainer';
import { showImageDialogAction } from '../redux/showImage/action';
import { showNotification } from '../helper/general-function-helper';
import { getVisibleServices } from '../helper/meta-helper';

interface DumbButtonType {
  className?: string;
  disabled: boolean;
  onClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  children: ReactNode;
}

interface ProcessLineChangeButtonType {
  processLine: { id: string | undefined; title: string; color?: string };
  locale;
  record: Record<string, unknown>;
  redirect: string | boolean;
  handleSubmitWithRedirect: Function;
  disabled: boolean;
  metaData: MetaData;
  isCreateMode: boolean;
  resource: string;
  redirectToPage: Function;
  editedFormData: object;
  basePath: string;
}

interface FormActionButtonListType {
  allFormData: object[];
  closeDialog: Function;
  redirectToPage: Function;
  resetForm: Function;
  redirect: string | boolean;
  record: Record<string, unknown>;
  crudCreateWithCallback: Function;
  submitOnEnter: boolean;
  metaData: MetaData;
  locale;
  handleSubmitWithRedirect: Function;
  customSubmit: Function;
  isLoading: boolean;
  processLineList: { id: string | undefined; title: string }[];
  processTaskInfo: object;
  formData: object;
  isCreateMode: boolean;
  resource: string;
  isDefaultMode: boolean;
  editedFormData: object;
  setRedirect: Function;
  rest: Record<string, unknown>;
  showImageDialog: Function;
  formName?: keyof FormActionProps;
}

const useStyles = makeStyles<CustomTheme>(theme => ({
  container: {
    margin: 0,
    padding: '0 16px',
    borderTop: `1px solid ${theme.palette.primary.appPrimaryDividerColor}`,
    backgroundColor: `${theme.palette.primary.appPrimaryToolbarBackgroundColor}`,
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.45)',
    zIndex: 3,
  },

  dumbButton: {
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      padding: '5px 0 !important',
    },
  },

  grow: {
    flexGrow: 1,
  },

  iconButton: {
    padding: 5,
    margin: '0 6px',
  },
  spacer: {
    display: 'none',
  },

  mobileToolbar: {
    padding: 0,
  },
}));

const DumbButton = (props: DumbButtonType) => {
  const { onClick, children, className, disabled = false } = props;
  return (
    <Button disabled={disabled} className={className} onClick={onClick}>
      {children}
    </Button>
  );
};

const ProcessLineChangeButton = (props: ProcessLineChangeButtonType) => {
  const {
    processLine,
    locale,
    disabled,
    record,
    metaData,
    isCreateMode,
    redirectToPage,
    basePath,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  const [formData, setFormData] = useState<Record<string, unknown>>(record);

  const currentResource = actorGetActionValue('resources')!.current;

  const recordWithoutRelationData = actorGetActionValue(
    'record',
    `${currentResource.value}.${currentResource.type}.${RecordKeyMode.FORM}`,
  )! as Record<string, unknown>;

  const { checkValidationClientSide } = actorGetActionValue('formGlobalProps')!;

  const prevValidationMessages =
    (actorGetActionValue('formMessages', [
      currentResource.value,
      currentResource.type,
    ]) as ValidationError | null) ?? {};

  const allFields = actorGetActionValue('allFields', [
    currentResource.value,
    currentResource.type,
  ])! as unknown as Array<FieldType>;

  useEffect(() => {
    actorOnDispatch('formData', newFormData => {
      newFormData &&
        setFormData(
          newFormData?.[currentResource.value]?.[currentResource.type] ?? {},
        );
    });
  }, []);

  const sanitizedFormData = sanitizeFormData(formData);

  const additionalData = {
    ...sanitizedFormData,
    __processuniqueid: record.__processuniqueid,
    __lineid: processLine.id,
  };

  const onSuccess = data => {
    const _record = data?.data;
    showNotification('ra.notification.updated', 'info');
    redirectToPage(`${linkToRecord(basePath, _record && _record?.id)}/show`);
  };
  const onFailure = error => {
    if (typeof error === 'string') {
      showNotification(error, 'error');
    } else {
      const { data, requestId } = error;
      try {
        handleServerSideValidationErrors(
          metaData as MetaDataBase,
          [],
          { apiErrors: data, requestId },
          formData,
          showNotification,
          translate,
          locale,
        );
      } catch (error) {
        console.log('FormActionButtonList:142.js >> error in catch', { error });
      }
    }
  };

  const [create, { loading: createLoading }] = useCreate(
    currentResource.value,
    additionalData,
    {
      onSuccess,
      onFailure,
    },
  );
  const [updateProcess, { loading }] = useUpdate(
    currentResource.value,
    lodashGet(recordWithoutRelationData, 'id'),
    additionalData,
    recordWithoutRelationData, //previousData
    {
      undoable: false,
      onSuccess,
      onFailure,
    },
  );

  /**
   * Submit and redirect to specified page
   * @function handleClick
   * @returns void
   */
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (
      !checkValidationClientSide(
        prevValidationMessages,
        formData,
        allFields,
        currentResource,
      )
    ) {
      return;
    }
    if (isCreateMode) {
      create();
    } else {
      updateProcess();
    }
  };

  return (
    <Button
      color="primary"
      className={classes.dumbButton}
      onClick={handleClick}
      disabled={disabled || loading || createLoading}
      data-test-process-id={processLine.id}
    >
      <ProcessIcon htmlColor={processLine?.color} /> &nbsp;
      {lodashGet(processLine, ['translatedTitle', locale], processLine.title)}
    </Button>
  );
};

const FormActionButtonList = (props: FormActionButtonListType) => {
  const {
    redirectToPage,
    redirect,
    locale,
    handleSubmitWithRedirect,
    isLoading,
    isCreateMode,
    resource,
    isDefaultMode,
    editedFormData,
    formName,
    showImageDialog,
    ...rest
  } = props;

  const classes = useStyles();
  const translate = useTranslate();
  const width = useWidth();

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [isSaving, setIsSaving] = useState(false);

  //---------------- ACTOR ---------------------------------------------------
  const { current: currentResource } = actorGetActionValue('resources')!;
  const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;
  const basePath = `/${currentResource.value}`;

  const metaData = actorGetActionValue(
    'metaData',
    currentResource.value,
  )! as unknown as MetaData;

  const record = actorGetActionValue('record', [
    currentResource.value,
    currentResource.type,
    RecordKeyMode.FULL,
  ])! as Record<string, unknown>;

  const processUniqueId = lodashGet(record, '__processuniqueid', null);
  const processPositionId = lodashGet(record, 'positionid', null);
  const processStateId = lodashGet(record, 'stateid', null);

  // TODO: Check if .toString() is necessary or not
  const processLineList = getProcessLines(
    metaData,
    lodashGet(record, '__processuniqueid', null),
    lodashGet(record, 'positionid', null)?.toString(),
    lodashGet(record, 'stateid', null)?.toString(),
  );

  // TODO: Check where should we use it
  const processTaskInfo = getProcessTaskInfo(
    metaData,
    processUniqueId,
    processPositionId,
    processStateId,
  );

  const isSaveDisabled = !isCreateMode ? !isRecordEditable(metaData, record) : false;
  const isSingleRecord = isSingleRecordTable(metaData);
  const processList = getProcessList(metaData);

  const visibleServices = getVisibleServices(metaData, record);

  useEffect(() => {
    const { current: currentResource } = actorGetActionValue('resources')!;

    actorOnDispatch('loading', response => {
      setIsSaving(response?.[currentResource?.value as string]);
    });
  }, []);

  /**
   * Redirect to specified page
   * @function handleDumbBtnClick
   * @returns void
   */
  const handleDumbBtnClick = () => {
    if (
      redirect &&
      redirect !== 'show' &&
      redirect !== 'edit' &&
      redirect !== 'list' &&
      redirect !== 'false'
    ) {
      redirectToPage(redirect);
    } else if (location.hash.includes(record?.id as string)) {
      redirectToPage(basePath);
    } else {
      if (
        actorGetActionValue('urlInfo')?.location.hash.includes('fromDropdown=true')
      ) {
        window.close();
      } else {
        window.history.back();
      }
    }
  };

  /**
   * disable setting button base on width and `processLineList`
   * @function disabledSettings
   * @returns {boolean}
   */
  const disabledSettings = useMemo(() => {
    if (
      width === 'md' ||
      width === 'sm' ||
      width === 'xs' ||
      (processLineList && processLineList.length)
    ) {
      return true;
    } else {
      return false;
    }
  }, [width, processLineList]);

  /**
   * useful to prevent click bubbling in a datagrid with rowClick
   * @function stopPropagations
   * @param {event}
   * @returns {void}
   */
  const stopPropagation = event => event.stopPropagation();

  /**
   * Handle 3 save type.
   * @function handleCustomSave
   * @param {SaveType} type
   * @returns {void}
   */
  const handleCustomSave = (type: SaveType) => (): void => {
    const params = {
      // dropdownId,
      // dropdownMeta,
      // onCreate,
      // mustRefresh,
      isSaveAndNew: false,
      isSaveAndView: false,
      formName,
    };

    switch (type) {
      case 'save':
        formActionsHandler?.(FormActions.Save, {
          ...params,
        } as ExtraParamsInterface);
        break;

      case 'saveAndNew':
        formActionsHandler?.(FormActions.Save, {
          ...params,
          isSaveAndNew: true,
        } as ExtraParamsInterface);
        break;

      case 'saveAndView':
        formActionsHandler?.(FormActions.Save, {
          ...params,
          isSaveAndView: true,
        } as ExtraParamsInterface);
        break;

      default:
        console.log(`This type ${type} is not support`);
        break;
    }
  };
  /**
   * it will make image url and call showImageDialog with it.
   * @function getProcessSchematic
   * @returns {void}
   */
  const getProcessSchematic = () => {
    showImageDialog({
      url: `${basePath}/${record.id}/process/view`,
      isGetFileMode: true,
    });
  };

  return (
    <Toolbar
      {...rest}
      className={classes.container}
      classes={{
        spacer: classes.spacer,
        mobileToolbar: classes.mobileToolbar,
      }}
      handleSubmitWithRedirect={handleSubmitWithRedirect}
      data-action-list="true"
      data-style-toolbar-form="toolbarForm"
    >
      <CustomFormButton
        id="formMainSaveButton"
        onClick={handleCustomSave('save')}
        disabled={isLoading || isSaveDisabled || isSaving}
        variant="contained"
        label={translate('ra.action.save')}
        buttonRef={buttonRef}
      />

      {!isSingleRecord && isCreateMode && (
        <CustomFormButton
          id="formSaveAndNewButton"
          onClick={handleCustomSave('saveAndNew')}
          disabled={
            isLoading || isSaveDisabled || !!record?.__processuniqueid || isSaving
          }
          variant="text"
          label={translate('form.createAndNew')}
          buttonRef={buttonRef}
        />
      )}

      {record?.['iseditable'] &&
        processLineList &&
        processLineList.map(processLine => (
          <ProcessLineChangeButton
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            key={processLine.id}
            processLine={processLine}
            locale={locale}
            disabled={isLoading || isSaving}
            redirect={redirect}
            record={record}
            metaData={metaData}
            isCreateMode={isCreateMode}
            resource={resource}
            redirectToPage={redirectToPage}
            editedFormData={editedFormData}
            basePath={basePath}
          />
        ))}

      <DumbButton
        className={classes.dumbButton}
        onClick={handleDumbBtnClick}
        data-test-form-cancel-button="true"
        disabled={isSaving}
      >
        {translate('ra.action.cancel')}
      </DumbButton>
      {!isDefaultMode && (
        <>
          <div className={classes.grow}></div>

          {/* TODO: FIXME */}

          {/* {!disabledSettings && (
            <IconButton
              color="primary"
              className={classes.iconButton}
              component={Link}
              to={`/form-layout/${resource}`}
            >
              <Icon>settings</Icon>
            </IconButton>
          )} */}

          {record && !!record.id && processList && processList.length && (
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={getProcessSchematic}
              id="schematicViewButton"
            >
              <Icon>map</Icon>
            </IconButton>
          )}

          {visibleServices && record && !!record.id && (
            <ServiceButtonContainer
              locale={locale}
              resource={currentResource.value}
              selectedIds={[record.id]}
              metaData={metaData}
              initialData={[record]}
              visibleServices={visibleServices}
            />
          )}
          {record && !!record.id && (
            <PrintButtonContainer
              locale={locale}
              resource={currentResource.value}
              selectedIds={[record.id]}
            />
          )}

          {record && !!record.id && (
            <ReactAdminButton
              color="primary"
              component={Link}
              to={`${linkToRecord(basePath, record && record.id)}/show`}
              onClick={stopPropagation}
              {...(rest as any)}
            >
              <Icon>visibility</Icon>
            </ReactAdminButton>
          )}
        </>
      )}
    </Toolbar>
  );
};

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
});

const mapDispatchToProps = {
  redirectToPage: push,
  showImageDialog: showImageDialogAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormActionButtonList);
