import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    '& .user-info': {
      width: '100%',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
}));
