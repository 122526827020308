import React, { FC } from 'react';
import TextField from '@material-ui/core/TextField';
import lodashGet from 'lodash/get';
import { useTranslate } from 'react-admin';

import sanitizeRestProps from '../sanitizeRestProps';
import { NumberInputViewInterface } from './number-input.type';

const NumberInputView: FC<NumberInputViewInterface> = props => {
  const {
    className,
    options,
    disabled,
    visibleClass,
    field,
    customError,
    source,
    input,
    hasError,
    error,
    inputPropsParams,
    ...rest
  } = props;

  const { format, maxLength, precision = 0 } = field;

  const translate = useTranslate();

  return (
    <TextField
      {...sanitizeRestProps(rest as any)}
      {...options}
      {...input}
      error={!!customError || hasError}
      helperText={
        customError
          ? customError
          : hasError
          ? translate(lodashGet(error, 'message', error))
          : undefined
      }
      required={field.required}
      margin="normal"
      className={`${visibleClass} ${className}`}
      InputProps={inputPropsParams}
      inputProps={{
        source,
        precision,
        format,
        maxLength,
        inputMode: 'numeric',
      }}
      variant="outlined"
      disabled={disabled}
      data-test-has-error={!!customError || hasError}
    />
  );
};

export default NumberInputView;
