import { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import lodashGet from 'lodash/get';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';
import Menu from '@material-ui/core/Menu';
import { getProcessList } from '../helper/MetaHelper';
import { withStyles } from '@material-ui/core/styles';
import { Icon, IconButton, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';

import { actorSetActionValue, FormKeyMode } from '../type/actor-setup';

const styles = theme => ({
  IconButton: {
    padding: 7,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },

  icon: {
    fontSize: 20,
  },

  menuItem: {
    fontSize: 12,
  },
});

const getEmptyState = () => ({
  anchorEl: null,
  isDialogOpen: false,
  selectedService: null,
  isSending: false,
  serviceParams: {},
});

/**
 * define wheter we have more than one process in list or not
 * @function shouldOpenMenu
 * @param { Object } list
 * @returns { boolean }
 */
const shouldOpenMenu = list => {
  return Array.isArray(list) && list.length > 1;
};

class CreateWithProcessButtonContainer extends PureComponent {
  state = getEmptyState();

  /**
   * @function handleOpenMenu
   * @param { ChangeEvent } event
   * @returns { void }
   */
  handleOpenMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  /**
   * @function handleOpenProcess
   * @param { Object } processList
   * @returns { void }
   */
  handleOpenProcess = processList => {
    const { history, resource, relationMode, data } = this.props;
    const process = processList?.[0];

    if (relationMode) {
      actorSetActionValue(
        'quickCreateSupplementaryData',
        { ...data, parentUrl: history.location.pathname },
        {
          path: `${resource}.${FormKeyMode.RELATION}`,
          replaceAll: true,
        },
      );
    }

    history.push(
      `/${resource}/create?__processuniqueid=${process.uniqueId}` +
        `&positionid=${lodashGet(process, 'firstTask.positionId')}` +
        `&stateid=${lodashGet(process, 'firstTask.stateId')}`,
    );
  };

  /**
   * @function handleCloseMenu
   * @param {ChangeEvent } event
   * @returns { void }
   */
  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, locale, metaData, resource, translate } = this.props;
    const processList = getProcessList(metaData, true);

    if (!processList) {
      return <div />;
    }

    return (
      <Fragment>
        <Tooltip title={translate('ra.action.create')}>
          <IconButton
            className={`${classes.IconButton} list-create-button`}
            ref={node => {
              this.button = node;
            }}
            onClick={
              shouldOpenMenu(processList)
                ? this.handleOpenMenu
                : () => this.handleOpenProcess(processList)
            }
            color="primary"
            id="createProcessButton"
          >
            <Icon className={classNames(classes.icon)}>add</Icon>
          </IconButton>
        </Tooltip>
        <Menu
          id="create-with-process-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleCloseMenu}
        >
          {processList.map(
            process =>
              process?.isActive && (
                <MenuItem
                  data-test-uniqueid={process.uniqueId.slice(0, 4)}
                  key={process.uniqueId}
                  component={Link}
                  className={`${classes.menuItem} list-create-with-process-link`}
                  to={
                    `/${resource}/create?__processuniqueid=${process.uniqueId}` +
                    `&positionid=${lodashGet(process, 'firstTask.positionId')}` +
                    `&stateid=${lodashGet(process, 'firstTask.stateId')}`
                  }
                >
                  {lodashGet(process, ['translatedTitle', locale], process.title)}
                </MenuItem>
              ),
          )}
        </Menu>
      </Fragment>
    );
  }
}

CreateWithProcessButtonContainer.propTypes = {
  locale: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  metaData: PropTypes.object.isRequired,
};

export default compose(
  translate,
  withStyles(styles, { withTheme: true }),
  withRouter,
)(CreateWithProcessButtonContainer);
