import produce from 'immer';
import { USER_LOGOUT } from 'react-admin';

import { CLEAR_BREADCRUMB_LINK, SET_BREADCRUMB_LINK } from './constant';

interface BreadcrumbReducerProps {
  type: string;
  title: string;
  link: string;
}

interface BreadcrumbReducerStoreInterface {
  title?: string;
  link?: string;
}

const initialState: BreadcrumbReducerStoreInterface = {
  title: undefined,
  link: undefined,
};

const myReducer = (
  state = initialState,
  { type, title, link }: BreadcrumbReducerProps,
) =>
  produce(state, draft => {
    // eslint-disable-next-line default-case
    switch (type) {
      case USER_LOGOUT:
      case CLEAR_BREADCRUMB_LINK:
        draft.title = undefined;
        draft.link = undefined;
        break;

      case SET_BREADCRUMB_LINK:
        draft.title = title;
        draft.link = link;
        break;
    }
  });

export default myReducer;
