import { ReactElement } from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { NewMessageViewInterface } from './new-message.type';
import { useStyles } from './new-message.style';
import { Box, IconButton, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';
import EditIcon from '@material-ui/icons/Edit';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import { isEmpty } from '../../../../../helper/data-helper';
import { checkIsRtlLanguage } from './new-message.helper';
import { Picker } from 'emoji-mart';
import { CustomTheme } from '../../../../../core/themeProvider';

const NewMessageView = (props: NewMessageViewInterface): ReactElement => {
  const {
    chatInputRef,
    emojiContainerRef,
    message,
    handleInputChange,
    onSendMessage,
    onSendFile,
    onKeyPress,
    selectedMessageData,
    onCancelSelectedMessage,
    showEmojis,
    toggleEmojisHandler,
    onAddEmoji,
  } = props;
  const classes = useStyles();
  const theme: CustomTheme = useTheme();

  return (
    <>
      {' '}
      {showEmojis && (
        <div
          ref={emojiContainerRef}
          style={{ position: 'fixed', bottom: '10%', zIndex: 1 }}
        >
          <Picker onSelect={onAddEmoji} />
        </div>
      )}
      {selectedMessageData && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={0.2}
          className={classes.replySection}
        >
          <Box display="flex" alignItems="center">
            {selectedMessageData.mode === 'reply' ? (
              <ReplyIcon
                style={{
                  color: theme.palette.secondary.main,
                  transform: 'rotate(180deg)',
                }}
              />
            ) : (
              <EditIcon
                style={{
                  color: theme.palette.secondary.main,
                }}
              />
            )}
            <Typography style={{ marginRight: '1rem' }} component="p">
              {selectedMessageData?.message?.chattext}
            </Typography>
          </Box>
          <IconButton onClick={onCancelSelectedMessage}>
            <CancelIcon />
          </IconButton>
        </Box>
      )}
      <Box display="flex" style={{ flexDirection: 'column' }}>
        <Box display="flex">
          <IconButton onClick={toggleEmojisHandler}>
            <EmojiEmotionsIcon
              style={{ color: theme.palette.grey[400], fontSize: '1.8rem' }}
            />
          </IconButton>
          <TextField
            dir={checkIsRtlLanguage(message) ? 'rtl' : 'ltr'}
            autoFocus={true}
            inputProps={{
              autoFocus: true,
            }}
            multiline
            style={{ width: '100%' }}
            variant="outlined"
            value={message}
            onChange={handleInputChange}
            onKeyPress={onKeyPress}
            inputRef={chatInputRef}
            className={classes.chatInput}
            InputProps={{
              classes: {
                input: classes.chatInput,
              },
            }}
          />
          <Box ml={2} display="flex" flexDirection="row" alignItems="center">
            <IconButton
              className={classes.btnRoot}
              onClick={onSendMessage}
              style={{ height: 40, width: 40, marginRight: 10 }}
            >
              <SendIcon />
            </IconButton>
            {isEmpty(message) ? (
              <Box>
                <input
                  id="icon-button-file"
                  type="file"
                  multiple
                  style={{ display: 'none' }}
                  onChange={onSendFile}
                />
                <label htmlFor="icon-button-file">
                  <IconButton
                    color="primary"
                    aria-label="upload file"
                    component="span"
                  >
                    <AttachFileIcon
                      style={{
                        color: theme.palette.grey[400],
                        fontSize: '1.8rem',
                        transform: 'rotate(30deg)',
                      }}
                    />
                  </IconButton>
                </label>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NewMessageView;
