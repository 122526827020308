import lodashMerge from 'lodash/merge';
import lodashPick from 'lodash/pick';
import lodashGet from 'lodash/get';
import packageJson from '../../package.json';

import { getRelationsInForm } from './MetaHelper';
import { MetaData } from './Types';
import dataProvider, { LOG_ERROR } from '../core/dataProvider';
import {
  getValue,
  USER_WAREHOUSE_ID,
  USER_WAREHOUSE_TITLE,
} from '../core/configProvider';
import { actorGetActionValue } from '../type/actor-setup';

type arrayResultToObjectWithLowerCase = (
  result: RequestResult[],
  pageMeta?: {
    page?: any;
    perPage?: any;
  },
) => void;

type arrayResultToObjectWithLowerCaseForDropDown = (
  result: RequestResult[],
  fieldName,
) => void;

type objectToLowerCaseProperties = (RequestResult) => RequestResult;

interface RequestResult {
  id: number;
  row: object;
}

// for places that don't give back id
let fakeIdCounter = 0;

const GregorianMonthList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const clone = <T extends unknown>(data: T): T => {
  try {
    return JSON.parse(JSON.stringify(data));
  } catch (error) {
    return data;
  }
};

export const mergeAndClone = (defaultData, overrideData) => {
  return lodashMerge(clone(defaultData), overrideData);
};

export const isJsonEncodedString = value => {
  if (typeof value !== 'string') {
    return false;
  }

  try {
    const parsed = JSON.parse(value);

    return typeof parsed === 'object' && parsed !== null;
  } catch (error) {
    // console.log('error: ', error);
  }

  return false;
};

export const getFirstItemFromObject = (
  object: Record<string, unknown>,
): unknown | null => {
  if (!object) {
    return null;
  }

  const propertyList = Object.keys(object);
  return propertyList && propertyList[0] ? object[propertyList[0]] : null;
};

/**
 * Check value is empty or not.
 * @function isEmpty
 * @param {string | number | Array<any> | null | undefined} value
 * @returns {boolean}
 */
export const isEmpty = (value: unknown): boolean => {
  return typeof value === 'undefined' || value === null || value === '';
};

// TODO: isEmptyObject should only use for Objects as its name ! it should return false if its entry's be anything but Object
export const isEmptyObject = <T>(object: T): boolean => {
  return object == null || Object.keys(object).length === 0;
};

/**
 * find valueMember in an Object
 * @function findValueMemberInObject
 * @param {Object} object
 * @param {string} propertyName
 * @returns {number}
 */
export const findValueMemberInObject = (
  object: Object,
  propertyName: string,
): number => {
  return lodashGet(object, propertyName);
};

export const arrayResultToObjectWithLowerCaseForDropDown: arrayResultToObjectWithLowerCaseForDropDown =
  (result, fieldName) => {
    if (isEmpty(result) || !result.length) {
      return [];
    }

    return result.map(item => {
      return objectToLowerCaseProperties({
        ...item,
        id: findValueMemberInObject(item, fieldName),
      });
    });
  };

export const arrayResultToObjectWithLowerCase: arrayResultToObjectWithLowerCase = (
  result,
  pageMeta = {},
) => {
  const { perPage, page } = pageMeta;
  if (isEmpty(result) || !result.length) {
    return [];
  }

  const isPageMetaAvailable = !isEmpty(perPage) && !isEmpty(page);
  let indexId = page * perPage - perPage + 1;

  return result.map(item => {
    if (isPageMetaAvailable && typeof item.id === 'undefined') {
      item.id = indexId++;
    }
    return objectToLowerCaseProperties(item);
  });
};

export const objectToLowerCaseProperties: objectToLowerCaseProperties = (
  row = {},
  id = undefined,
) => {
  if (row === null || row === undefined || typeof row === 'undefined') {
    row = {};
  }
  const cloned = clone(row);

  Object.keys(row).forEach(property => {
    cloned[property.toLowerCase()] = row[property];
  });

  if (typeof cloned.id === 'undefined') {
    cloned.id = id ? id : ++fakeIdCounter;
  }

  return cloned;
};

/**
 * check equality of two objects. return true if there were equal and false if the were not equal.
 * @function areTwoObjectsShallowEqual
 * @param {Object} firstObject
 * @param {Object} secondObject
 * @returns {boolean} true if same
 * */
export const areTwoObjectsShallowEqual = <T, K>(
  firstObject: T,
  secondObject: K,
): boolean => {
  const firstObjectKeys = isEmptyObject<T>(firstObject)
    ? []
    : Object.keys(firstObject);
  const secondObjectKeys = isEmptyObject<K>(secondObject)
    ? []
    : Object.keys(secondObject);

  if (firstObjectKeys.length !== secondObjectKeys.length) {
    return false;
  }

  let areEqual = true;

  for (const key of firstObjectKeys) {
    if (firstObject[key] !== secondObject[key]) {
      areEqual = false;
      break;
    }
  }

  return areEqual;
};

interface RelationList {
  moduleName: string;
  moduleTableName: string;
  childFieldName: string;
}

/**
 * finds relation keys based on `metaData` and removes keys from the `record`.
 * @function removeRelationFromRecord
 * @param {object} record
 * @param {object} metaData
 * @returns {object}
 */
export const separateRecordAndRelationRecord = (
  record: Record<string, unknown>,
  metaData: MetaData | null,
  higherPriorityRecord: Record<string, unknown> | null,
): {
  recordWithoutRelationData?: Record<string, unknown>;
  relationRecord?: Record<string, unknown>;
} => {
  if (!record || isEmptyObject(record)) {
    return higherPriorityRecord ?? {};
  }

  const relationList: RelationList[] = getRelationsInForm(metaData, null) ?? [];

  if (!relationList || !relationList.length) {
    const mergedRecord = lodashMerge(record, higherPriorityRecord);
    return { recordWithoutRelationData: mergedRecord };
  }

  const relationPathList: string[] = [];

  relationList.forEach(relation => {
    const relationResource = `${relation.moduleName}/${relation.moduleTableName}`;
    relationPathList.push(`${relationResource}/${relation.childFieldName}`);
  });

  const filterKeys = Object.keys(record).filter(
    key => !relationPathList.includes(key),
  );

  relationPathList.push('id'); // this is parent id for `quickCreateData` in `relationPanel`

  const mergedRecord = lodashMerge(
    lodashPick(record, filterKeys),
    higherPriorityRecord,
  );
  return {
    recordWithoutRelationData: mergedRecord,
    relationRecord: lodashPick(record, relationPathList),
  };
};

/**
 * this function will receive an array of unorganized objects and sort them by a key on these
 * objects in order "asc" or "desc".
 * @function customSort
 * @param {Array<object>} data unsorted data
 * @param {string} key
 * @param {string} order
 * @returns {Array<object>} sorted data
 */
export function customSort<T>(data: Array<T>, key: string, order = 'asc'): Array<T> {
  try {
    if (isEmpty(key)) {
      return data;
    }

    if (!data) {
      return [];
    }

    const sortedData: Array<T> = clone(data).sort((firstParam, secondParam) => {
      let keyValueInFirstParam = lodashGet(firstParam, key);
      let keyValueInSecondParam = lodashGet(secondParam, key);

      if (
        typeof keyValueInFirstParam === 'number' &&
        typeof keyValueInSecondParam === 'number'
      ) {
        return keyValueInFirstParam - keyValueInSecondParam;
      }

      if (
        typeof keyValueInFirstParam === 'string' &&
        typeof keyValueInSecondParam === 'string'
      ) {
        keyValueInFirstParam = keyValueInFirstParam.toLowerCase();
        keyValueInSecondParam = keyValueInSecondParam.toLowerCase();

        if (keyValueInFirstParam < keyValueInSecondParam) {
          return -1;
        }

        if (keyValueInFirstParam > keyValueInSecondParam) {
          return 1;
        }
      }

      return 0;
    });

    return order === 'asc' ? sortedData : sortedData.reverse();
  } catch {
    return [];
  }
}

/**
 *  this function receive two objects with an only one or zero different value and compare them to return name of key of
 *  the value has been changed in second object .
 *  @function findDifferentKeyFromTwoObject
 *  @param {Object | null} formData prev form data
 *  @param {Object | null} prevFormData next form data
 *  @returns {string | undefined} different key
 */
export const findDifferentKeyFromTwoObject = (
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>,
): string | undefined => {
  // TODO: this function work currently only when obj1 have sth more than obj2 but should support both
  if (areTwoObjectsShallowEqual(obj2, obj1)) {
    return;
  }

  let differentKey: string | undefined;
  const prevKeys = isEmptyObject(obj2) ? [] : Object.keys(obj2);
  const newKeys = isEmptyObject(obj1) ? [] : Object.keys(obj1);

  // if first object was empty
  if ((!prevKeys || prevKeys.length < 1) && newKeys && newKeys.length > 0) {
    differentKey = newKeys[0];
  } else if (prevKeys.length !== newKeys.length) {
    // if second object has new key
    newKeys.forEach(newKey => {
      if (!prevKeys.includes(newKey)) {
        differentKey = newKey;
      }
    });
  } else {
    // if keys was equal but values was different
    newKeys.forEach(newKey => {
      if (obj2[newKey] !== obj1[newKey]) {
        differentKey = newKey;
      }
    });
  }

  return differentKey;
};

/**
 * @function arrayToObjectWithSpecificKey
 * @param { Object[] } arr
 * @param { string } key
 * @returns {Object}
 */
export const arrayToObjectWithSpecificKey = (arr: Object[], key: string): Object => {
  return arr?.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item,
    }),
    {},
  );
};

/**
 * convert megaByteToByte to Byte
 * @function megaByteToByte
 * @param { number } megabyte
 * @returns { number }
 */
export const megaByteToByte = (megabyte: number): number => {
  return megabyte * 1000000;
};

/**
 * to convert a string to array of strings
 * @param { string } string
 * @param { string } symbol
 * @returns { string[] | undefined }
 */
export const convertStringToArray = (
  string: string,
  symbol: string,
): string[] | undefined => {
  if (isEmpty(string)) {
    return undefined;
  }

  return string?.split(`${symbol}`);
};

/**
 * check error to see if it is a network error
 * @function isNetworkError
 * @param {unknown} error
 * @returns {boolean}
 */
export const isNetworkError = (error: unknown): boolean => {
  if (!error) return false;

  try {
    if (typeof error === 'string') {
      if (error.includes('Invalid URL') || error.includes('Network Error')) {
        return true;
      } else {
        return false;
      }
    } else if (typeof error === 'object') {
      const errorMessage = String(error?.['message']);
      if (!errorMessage) return false;

      if (
        errorMessage.includes('Invalid URL') ||
        errorMessage.includes('Network Error')
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (catchError) {
    return false;
  }
};

/**
 * check error to see if it is a system error
 * @function isSystemError
 * @param {unknown} error
 * @returns {boolean}
 */
export const isSystemError = (error: unknown): boolean => {
  if (!error) return false;

  try {
    if (typeof error === 'string') {
      if (
        error.includes('TypeError') ||
        (error.includes('Uncaught') && !error.includes('Network Error'))
      ) {
        return true;
      } else {
        return false;
      }
    } else if (typeof error === 'object') {
      const errorMessage = String(error?.['message']);
      if (!errorMessage) return false;

      if (
        errorMessage.includes('TypeError') ||
        (errorMessage.includes('Uncaught') &&
          !errorMessage.includes('Network Error'))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (catchError) {
    return false;
  }
};

interface WindowInterface {
  configFile: { API_URL: string };
}

/**
 * @function logErrorToGraylog
 * @param {string} error in json format
 * @returns {Promise<void>}
 */
export const logErrorToGraylog = (
  error: unknown,
  errorInfo: unknown,
  type = 'no_crash',
): void => {
  dataProvider(LOG_ERROR, null, {
    version: packageJson.version,
    host: (window as unknown as WindowInterface)?.configFile?.API_URL,
    short_message: `web_${type}_error`,
    full_message: { errorInfo, error },
    level: 1,
    _user_id: getValue(USER_WAREHOUSE_ID),
    _some_info: getValue(USER_WAREHOUSE_TITLE),
    _some_env_var: actorGetActionValue('globalParameters'),
    app: 'web',
  });
};

/**
 * create date object in readable format for big calender
 * @function convertDateToBigCalenderReadableFormat
 * @param {string} serverDate
 * @returns {void}
 */
export const convertDateToBigCalenderReadableFormat = (serverDate: string): Date => {
  try {
    const [year, month, rest] = serverDate.split('-');
    const [day, time] = rest.split(' ');

    const convertedDate = `${
      GregorianMonthList[Number(month) - 1]
    } ${day}, ${year} ${time.slice(0, 8)}`;

    return new Date(convertedDate);
  } catch (error) {
    console.log('could not convert date to big calendar readable format: ', error);
    return new Date(serverDate);
  }
};
