import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { getIconClassName } from '@uifabric/styling';

import { getValue, API_URL } from '../../core/configProvider';
import { showImageDialogAction } from '../../redux/showImage/action';
import { CustomTheme } from '../../core/themeProvider';
import { isFileTypeImage } from '../../helper/FileHelper';
import { isEmptyObject } from '../../helper/data-helper';

const useStyles = makeStyles((theme: CustomTheme) => ({
  attachmentContainer: {
    margin: 0,
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
  },

  cardMediaContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 200ms',
    '&:hover': {
      backgroundColor: theme.palette.primary.appPrimaryLightBackgroundColor,
      transition: 'all 200ms',
      '& i': {
        opacity: 1,
        transition: 'all 200ms',
      },
    },
  },

  mediaContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    textDecoration: 'none',
    overflow: 'hidden',
  },

  media: {
    margin: 6,
    height: 36,
    maxWidth: 36,
    minWidth: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#fff',
    fontWeight: 600,
    fontSize: 11,
    textTransform: 'uppercase',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
  },

  fileTitle: {
    flexGrow: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  deleteFileIcon: {
    fontSize: 10,
    margin: 10,
    opacity: 0,
    color: theme.palette.primary.appPrimaryIconColor,
    transition: 'all 200ms',
  },
}));

const TodoTaskFileView = props => {
  const { data, ids, showImageDialog, deleteFile } = props;
  const classes = useStyles();

  const apiUrl = getValue(API_URL);

  const getFileType = (type: string) => {
    const parts = type.split('/');
    return parts[parts.length - 1] ? parts[parts.length - 1] : '?';
  };

  const handleOnClick = id => event => {
    deleteFile(id);
  };

  const openImage = (url: string, title: string) => event =>
    showImageDialog({
      url,
      title,
    });
  return (
    <div className={classes.attachmentContainer}>
      {!isEmptyObject(data) &&
        ids &&
        ids.map(attachment => {
          const fileUrl = `${apiUrl}/${data[attachment].folderpath}/${data[attachment].filename}`;
          const isImage = isFileTypeImage(data[attachment].realname);
          if (isImage) {
            return (
              <div key={fileUrl} className={classes.cardMediaContainer}>
                <div
                  className={classes.mediaContainer}
                  onClick={openImage(fileUrl, data[attachment].realname)}
                >
                  <div className={classes.media}>
                    {getFileType(data[attachment].mimetype)}
                  </div>
                  <Typography
                    className={classes.fileTitle}
                    variant="caption"
                    color="textSecondary"
                  >
                    {data[attachment].realname}
                  </Typography>
                </div>
                <i
                  className={`${classes.deleteFileIcon} ${getIconClassName(
                    'cancel',
                  )}`}
                  onClick={handleOnClick(data[attachment].id)}
                ></i>
              </div>
            );
          }

          return (
            <div key={fileUrl} className={classes.cardMediaContainer}>
              <a
                href={fileUrl}
                download
                target="_blank"
                rel="noopener noreferrer"
                className={classes.mediaContainer}
              >
                <div className={classes.media}>
                  {getFileType(data[attachment].mimetype)}
                </div>
                <Typography
                  className={classes.fileTitle}
                  variant="caption"
                  color="textSecondary"
                >
                  {data[attachment].realname}
                </Typography>
              </a>
              <i
                className={`${classes.deleteFileIcon} ${getIconClassName('cancel')}`}
                onClick={handleOnClick(data[attachment].id)}
              ></i>
            </div>
          );
        })}
    </div>
  );
};

TodoTaskFileView.propTypes = {
  deleteFile: PropTypes.func,
};

const mapDispatchToProps = {
  showImageDialog: showImageDialogAction,
};

export default compose(connect(null, mapDispatchToProps))(TodoTaskFileView);
