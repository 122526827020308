import { FC, memo, useEffect } from 'react';
import { getValue, USER_WAREHOUSE_ID } from '../../../core/configProvider';
import { isEmptyObject } from '../../../helper/data-helper';
import { ValidationError } from '../../../helper/Types';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
} from '../../../type/actor-setup';
import { FormData, InputRefContent } from '../form.type';
import { updateInputValues } from './profile-form.helper';

import { ProfileFormInterface } from './profile-form.type';
import ProfileFormView from './profile-form.view';

const ProfileFormController: FC<ProfileFormInterface> = memo(props => {
  const { fields } = props;
  const { current: currentResource } = actorGetActionValue('resources')!;

  /**
   * Finding input values from `formData`, if corresponding input name found in `formErrors`, replace it
   * @function updateInputsState
   * @param {object} inputsRef
   * @param {object} formErrors
   * @param {object} formData
   * @returns {void} void
   */
  const updateInputsState = (
    inputsRef: Record<string, InputRefContent> | null | undefined,
    formErrors: Record<string, ValidationError> | undefined,
    formData: FormData,
  ): void => {
    if (isEmptyObject(inputsRef)) return;

    //TODO: move to main parent component
    for (const inputName in inputsRef) {
      if (formErrors?.[inputName]) {
        inputsRef[inputName].setInputMessage?.({
          message: formErrors?.[inputName].message ?? '',
          messageType: 'error',
        });

        // FIXME: Because we have some many re-renders, without this `delay`, the correct value will be removed
        setTimeout(() => {
          inputsRef[inputName].setInputValue?.(formErrors?.[inputName].value ?? '');
        }, 0);

        continue;
      }

      inputsRef[inputName].setInputMessage?.(undefined);
      inputsRef[inputName].setInputValue?.(formData?.[inputName] ?? '');
    }
  };

  useEffect(() => {
    actorDispatch('allFields', fields, {
      path: `${currentResource.value}.${currentResource.type}`,
      callerScopeName: 'ProfileFormController => useEffect',
    });
  }, []);

  // to compute and dispatch default data
  useEffect(() => {
    const delegationData = actorGetActionValue('delegationData')!;
    const currentWareHouseID = getValue(USER_WAREHOUSE_ID);
    const authorityDelegation =
      delegationData?.find(item => item.selected)?.path ?? '';

    actorSetActionValue(
      'formData',
      {
        currentWareHouseID,
        authorityDelegation,
      },
      {
        path: `${currentResource.value}.${currentResource.type}`,
        replaceAll: false,
      },
    );

    actorSetActionValue(
      'initialData',
      {
        currentWareHouseID,
        authorityDelegation,
      },
      {
        path: `${currentResource.value}.${currentResource.type}`,
      },
    );

    updateInputValues();
  }, []);

  useEffect(() => {
    actorOnDispatch('formMessages', formMessages => {
      const formData = actorGetActionValue('formData', [
        currentResource.value,
        currentResource.type,
      ]) as FormData | null;

      const inputsRef = actorGetActionValue('inputsRef');
      if (formData) {
        updateInputsState(
          inputsRef?.[currentResource.value]?.[currentResource.type],
          formMessages?.[currentResource.value]?.[currentResource.type],
          formData,
        );
      }
    });
  }, []);

  return <ProfileFormView fields={fields} />;
});

export default ProfileFormController;
