import {
  dispatchRunner,
  onDispatchRunner,
  setActionValueRunner,
  getActionValueRunner,
  resetActionList,
  waitForActionRunner,
  removeActionRunner,
} from '@SamianSoft/actor';
import {
  CodingInputData,
  CustomChangeHandler,
  FormActionsHandler,
  FormData,
  InitialData,
  InputRefContent,
  SelectedService,
  tagInputData,
  ValidationErrors,
} from '../component/form';
import {
  FieldType,
  MetaData,
  MetaDataBase,
  NotificationOptions,
} from '../helper/Types';
import { ValidationError } from '../helper/Types';
import {
  FetchDropdownDataPayload,
  FileInputData,
  CheckValidationClientSide,
} from '../component/form/form.type';
import { DialogData } from '../component/dialogs-stack';
import { SearchPopupDialogData } from '../component/search-popup-dialog';
import { MenuItemParams } from '../component/menu/sidebar-menu';
import { GridParametersInterface } from '../component/search-popup-dialog';
import { HubConnection } from '@microsoft/signalr';
import {
  MessageInterface,
  SelectedUserType,
  UploadedFile,
} from '../component/chat-section';
import { EventTypeRemote } from '../component/big-calendar';
import { FoundedMessageInterface } from '../component/chat-section/chat-sidebar/search-list';
import { FilesPopupDialogData } from '../component/files-popup-dialog/files-popup-dialog.type';
import { ChatsDataInterface } from '../component/chat-section/chat-sidebar/chat-sidebar.type';
import {
  MailFolderInterface,
  MailInterface,
  SelectedMailInterface,
} from '../component/mail-section';
import { PaginationParams } from '../component/mail-section/mail-list';
import { SelectedMessageDataInterface } from '../component/chat-section/chat-content/messages-screen/new-message';
import { SaveType } from '../component/QuickCreateButtonToolbar';
import { QuickAccessMenuDataInterface } from '../component/quick-access-menu';
import {
  CrudCreateApiPayload,
  CrudDeleteOneApiPayload,
  CrudGetListApiPayload,
  CrudGetManyReferenceApiPayload,
  CrudGetOneApiPayload,
  CrudResultInterface,
  CrudRunServiceApiPayload,
  CrudUpdateApiPayload,
} from './data-provider';

import type { MultiFileStreamItem } from '../component/dynamic-input/multi-file-stream-input/multi-file-stream-input.type';
import type { LogConfigInterface } from '@SamianSoft/actor/types';
import type { GeneralMetaData, Translate } from './../helper/Types';
import type { Locale } from './global-types';
import { QuestionApiResponseInterface } from '../component/dialogs-stack/dynamic-dialog';

// export type Resource = 'root' | 'relation' | 'dropdown';
export enum FormKeyMode {
  ROOT = 'ROOT', // The first form that will display when a page loaded
  RELATION = 'RELATION',
  DROPDOWN = 'DROPDOWN',
  SERVICE = 'SERVICE', // The service dialog form
  PROFILE = 'PROFILE', // The profile form
  VISITOR = 'VISITOR',
  WMS = 'WMS',
}

export enum RecordKeyMode {
  FORM = 'FORM',
  PARENT_RECORD = 'PARENT_RECORD',
  FULL = 'FULL',
}

export interface ResourceInterface {
  type: FormKeyMode;
  value: string;
}

export interface ResourcesInterface {
  stack: ResourceInterface[];
  current: ResourceInterface;
}

export enum DropdownDataKey {
  ALL = 'ALL',
  DATA = 'DATA',
  TOTAL = 'TOTAL',
}

export interface GetServiceDefaultValueType {
  service: Record<string, unknown>;
  params: Record<string, unknown>;
  parentResource?: string;
  onSuccess: (
    params: Record<string, unknown>,
    service: Record<string, unknown>,
  ) => void;
  onFailure: (error: unknown, service: Record<string, unknown>) => void;
}

export interface URLInfo {
  location: {
    hash: string;
    pathname: string;
    search: string;
    state: string;
    href: string;
    origin: string;
  };
  params: {
    id: string;
    moduleName: string;
    moduleTableName: string;
    module?: string;
    url?: string;
  };
}

export interface FocusManagementInterface {
  focusOnInput: (...args) => void;
  focusOnNextInput: (
    inputsRef: Record<string, InputRefContent>,
    currentName: string | undefined,
    formData: FormData | null,
    submitFormCallback?: (...args) => void,
  ) => string | undefined;
  focusOnFirstInput: (inputsRef: Record<string, InputRefContent>) => void;
  focusOnFirstInputAfterSubmit: () => void;
}

export interface DropdownData {
  [DropdownDataKey.ALL]?: Record<string, unknown>[];
  [DropdownDataKey.DATA]?: Record<string, unknown>[];
  [DropdownDataKey.TOTAL]?: number;
}

export type SortOrderType = 'desc' | 'asc' | null;

export interface RequestParametersInterface {
  pagination: {
    page: number;
    perPage: number;
  };
  sort: {
    field: string | null;
    order: SortOrderType;
  };
  filter: Record<string, unknown>;
  parentModule?: string;
  parentTable?: string;
  customRecordNotePin?: string;
}

export interface NoteRequestParametersInterface {
  pagination: {
    page: number;
    perPage: number;
  };
  sort: {
    field: string;
    order: 'desc' | 'asc';
  };
  filter: (string | string[])[];
  parentModule?: string;
  parentTable?: string;
  customRecordNotePin: string;
  IsPin: number;
  KeyID: string | undefined;
  tableid: number | null;
}

export interface GetListRequestParametersInterface {
  pagination: {
    page: number;
    perPage: number;
  };
  sort: {
    field: string | null;
    order: SortOrderType;
  };
  filter?: Array<Array<string> | string>; // ex: [['A', '=', '123'], 'and', ['B', '=', '456']]
  searchFilters?: Record<string, unknown>;
}

export interface NotificationInterface {
  message: string | unknown;
  type?: 'info' | 'error' | 'success' | 'warning' | 'confirm';
  options?: NotificationOptions;
}

export interface GridDataInterface {
  data?: Array<Record<string, unknown>>;
  totalCount?: number;
  requestParameters?: RequestParametersInterface | GetListRequestParametersInterface;
  selectedIds?: Array<string>;
  lastRequestId?: string;
  error?: string;
}

export interface ApiRequestResultInterface {
  [actionType: string]: {
    [entity: string]: CrudResultInterface;
  };
}

export interface ScrollParamsInterface {
  uniqIdToScroll: string;
}

declare global {
  // Because our lint doesn't understand a definition in a global scope, its temporary
  // eslint-disable-next-line no-unused-vars
  interface ActorActionList {
    // common
    loading: { [resource: string]: boolean };
    resources: ResourcesInterface;
    setDocumentTitle: {
      metaData: GeneralMetaData;
      recordTitle: string;
      locale: string;
    };

    urlInfo: URLInfo;

    globalParameters: Record<string, unknown>;
    remove: { resource: string; type: FormKeyMode };

    // dialogs
    quickAccessOpenDialog: boolean;
    quickDialog: DialogData;
    closeDialogs: boolean;
    closeCurrentDialog: boolean;
    searchDialog: SearchPopupDialogData;
    filesDialog: FilesPopupDialogData;

    // data
    record: {
      [resource: string]: {
        [FormKeyMode.ROOT]?: {
          [RecordKeyMode.FORM]?: Record<string, unknown>;
          [RecordKeyMode.PARENT_RECORD]?: Record<string, unknown>;
          [RecordKeyMode.FULL]?: Record<string, unknown>;
        };
        [FormKeyMode.DROPDOWN]?: {
          [RecordKeyMode.FORM]?: Record<string, unknown>;
          [RecordKeyMode.PARENT_RECORD]?: Record<string, unknown>;
          [RecordKeyMode.FULL]?: Record<string, unknown>;
        };
      };
    };

    recordAdditionalData: {
      [resource: string]: {
        disabled: Record<string, boolean> | null;
        hidden: Record<string, boolean> | null;
      };
    };

    initialData: {
      [resource: string]: {
        [FormKeyMode.ROOT]?: InitialData;
        [FormKeyMode.RELATION]?: InitialData;
        [FormKeyMode.DROPDOWN]?: InitialData;
      };
    };

    formData: {
      [resource: string]: {
        [FormKeyMode.ROOT]?: FormData;
        [FormKeyMode.RELATION]?: FormData;
        [FormKeyMode.DROPDOWN]?: FormData;
      };
    };

    quickCreateSupplementaryData: {
      [resource: string]: {
        [FormKeyMode.ROOT]?: FormData;
        [FormKeyMode.RELATION]?: FormData;
        [FormKeyMode.DROPDOWN]?: FormData;
      };
    };

    dropDownData: {
      [uniqueId: string]: DropdownData;
    };

    broadcastChannel: BroadcastChannel;

    metaData: {
      [resource: string]: MetaData;
    };

    getQuickAccessMenuData: boolean;

    quickAccessMenuData: QuickAccessMenuDataInterface[];

    /**
     * Pay attention:
     * If we needed to type of save(e.g. `saveAndNew`) we can use `SaveType` keys,
     *  otherwise we have to set `null`
     */
    resetForm: { saveType?: SaveType | null; resource?: ResourceInterface };

    // tabs
    checkTabErrors: (formErrors?: Record<string, ValidationError> | null) => void;

    quickCreateButtonToolbar: {
      [resource: string]: {
        [FormKeyMode.ROOT]?: Record<string, HTMLButtonElement | undefined>;
        [FormKeyMode.RELATION]?: Record<string, HTMLButtonElement | undefined>;
        [FormKeyMode.DROPDOWN]?: Record<string, HTMLButtonElement | undefined>;
      };
    };

    // form
    inputsRef: {
      [resource: string]: {
        [FormKeyMode.ROOT]?: Record<string, InputRefContent>;
        [FormKeyMode.RELATION]?: Record<string, InputRefContent>;
        [FormKeyMode.DROPDOWN]?: Record<string, InputRefContent>;
      };
    };

    allFields: { [resource: string]: Array<FieldType> };
    formMessages: {
      [resource: string]: {
        [FormKeyMode.ROOT]?: ValidationErrors;
        [FormKeyMode.RELATION]?: ValidationErrors;
        [FormKeyMode.DROPDOWN]?: ValidationErrors;
      };
    };

    formGlobalProps: {
      formActionsHandler: FormActionsHandler;
      customChangeHandler: CustomChangeHandler;
      formFocusManagementFunctions: FocusManagementInterface;
      dropdownCreateSuccess: (newRecord: Record<string, unknown>) => void;
      checkValidationClientSide: CheckValidationClientSide;
      metaData: MetaDataBase;
      isCreateMode: boolean;
      keepValueAfterSubmitFieldNameList: string[];
      keepFocusAfterSubmitFieldNameList: string[];
      ignoreToFocusFieldNameList: string[];
      inputNameListSortedByPriority: string[];
    };

    gridIDs: {
      allIDs: Array<number>;
      selectedIDs: Array<number>;
    };

    selectedService: {
      service: SelectedService | null;
    };
    runServiceDirectly: {
      service: SelectedService | null;
      serviceParams?: Record<string, unknown>;
    };

    handleFinishServiceDialog: {
      handleFinishServiceDialog: () => void;
    };

    handleCloseProfileFormDialog: {
      handleCloseProfileFormDialog: () => void;
    };

    showLoading: boolean;
    showSettingsLoading: boolean;
    profileSetting: Record<string, unknown>;

    // inputs
    codingInputData: CodingInputData;
    tagInputData: tagInputData;
    fileInputData: { [resource: string]: FileInputData };
    uploadStreamFile: {
      param: {
        resource: string;
        file: Record<string, unknown>;
      };
      successCallback: (prop: Record<string, unknown>) => void;
      failureCallback: (error: string) => void;
    };

    uploadStreamMultipleFile: {
      param: {
        resource: string;
        files: Array<File>;
      };
      successCallback: (response: Record<string, unknown>) => void;
      successAllFilesUploadedCallback?: (response: MultiFileStreamItem[]) => void;
      failureCallback: (errors: Array<unknown>) => void;
    };

    isSearchingOnDropDown: { [uniqueId: string]: boolean };
    isSearchPopUpDialogOpen: boolean;
    notification: NotificationInterface | null;
    notificationClosed: null;

    crudAction:
      | CrudCreateApiPayload
      | CrudUpdateApiPayload
      | CrudGetListApiPayload
      | CrudDeleteOneApiPayload
      | CrudGetOneApiPayload
      | CrudRunServiceApiPayload
      | CrudGetManyReferenceApiPayload;

    // side-bar-menu

    menuData: MenuItemParams[];
    childMenuData: MenuItemParams[];
    favoriteItemsData: MenuItemParams[];
    recentItemsData: MenuItemParams[];
    selectedParentMenuId: number;
    drawerWidth: number;
    isModuleItemSelected: boolean;
    isChildMenuOpen: boolean;
    shouldAllItemsExpand: { [menuId: string]: boolean };
    expandedItemsId: { [menuId: string]: number[] };
    profile: Record<string, unknown>;

    /**
     * current session id will use in mobile / tablet devices, it will use for authentication without token
     * if `sessionid` key exist in url , we fill it on this state in actor and all of request url,s to server
     * will be changed. if this value change in url ( or first time of existence in url ) should get profile
     * data and set new global parameters of user in that will handle in data provider component.
     */
    currentSessionIdInUrl: string;

    fetchDropdownData: FetchDropdownDataPayload; // Fetch dropdown data from API.
    getDropdownData: Record<string, unknown>; // Get dropdown data from store.

    gridParameters: GridParametersInterface;

    changePassword: {
      successCallback: (props) => void;
      failureCallback: (error) => void;
      data: Record<string, unknown>;
    };
    changeProfile: { data: Record<string, unknown>; successCallback: () => void };
    delegationData: { caption: string; path: string; selected: boolean }[];
    getDelegationData: null;
    putPathDelegation: { resource: string; successCallback: () => void };
    getServiceDefaultValue: GetServiceDefaultValueType;
    viewVersion: number;

    //visitor calendar
    getCalendarData: {
      params: {
        VisitorPersonInfo_ID: string;
        FromData: string;
        ToDate: string;
      };
      reportId: string;
      successCallback: (props) => void;
    };
    runActionsService: {
      params: EventTypeRemote;
      actionUniqueId: string;
      __questionsResponse?: QuestionApiResponseInterface[];
      successCallback?: (props) => void;
      failureCallback?: (props) => void;
    };

    // SignalR
    notificationHubConnection: HubConnection;
    chatHubConnection: HubConnection;
    signalRNotification: unknown;

    // chat
    getChatReport: {
      params: Record<string, unknown>;
      successCallback: (prop: Record<string, unknown>) => void;
      failureCallback: (error: unknown) => void;
    };
    runChatService: {
      params: Record<string, unknown>;
      successCallback: (prop: Record<string, unknown>) => void;
      failureCallback: (error: unknown) => void;
    };
    uploadedFiles: UploadedFile[];
    chatsData: ChatsDataInterface | null;
    selectedUser: SelectedUserType | null;
    messagesData: {
      data: MessageInterface[];
      hasMore: boolean;
    };
    chatInputRef: React.RefObject<HTMLInputElement>;
    chatText: string;
    toReplyMessage: MessageInterface | null;
    selectedMessageData: SelectedMessageDataInterface | null;
    foundedMessage: FoundedMessageInterface;
    isNewContentSent: boolean;

    //map
    getMetaForMapReport;
    getMapReport: {
      params: Record<string, unknown>;
      successCallback: (prop: Record<string, unknown>) => void;
      failureCallback: (error: string) => void;
    };

    //pivot
    getPivotReport: {
      params: Record<string, unknown>;
      successCallback: (prop: Record<string, unknown>) => void;
      failureCallback: (error: string) => void;
    };

    //mail
    getMailReport: {
      params: Record<string, unknown>;
      successCallback: (prop: Record<string, unknown>) => void;
      failureCallback: (error: unknown) => void;
    };

    //print report
    getPrintReportData: {
      successCallback: (prop: Record<string, unknown>) => void;
      failureCallback?: (error: unknown) => void;
      metaData: GeneralMetaData;
      reportId: string;
      filterValues: Record<string, unknown>;
    };

    runMailService: {
      params: Record<string, unknown>;
      successCallback: (prop: Record<string, unknown>) => void;
      failureCallback: (error: unknown) => void;
    };
    mailData: {
      data: MailInterface[];
      pagination: PaginationParams;
    };
    selectedMail: SelectedMailInterface[] | null;
    selectedDoc: Pick<
      SelectedMailInterface,
      'doc_id' | 'doctype_id' | 'refrences_id'
    > | null;
    mailReplyData: string;
    mailInboxUnreadCount: number;
    mailFolders: MailFolderInterface[];
    newLabelFormData: FormData;

    appSettings: {
      original: Array<Record<string, unknown>> | null;
      objectList: Record<string, Record<string, unknown>>;
    };

    isDrawerOpen: boolean;

    // grid
    gridData: {
      [resource: string]: GridDataInterface;
    };
    gridFormData: Record<string, unknown>;

    apiRequestResult: ApiRequestResultInterface;

    // TODO: its a temporary actor state and should implement in dialog stack in another card
    isNoteCreateEditDialogOpen: boolean;

    isTopFilterOpen: boolean;

    refreshView: string;

    activeTab: {
      [resource: string]: number;
    };

    relationFieldsForDisplay: {
      [resource: string]: string;
    };

    wmsAsyncActionList: Promise<unknown>[];

    additionalData: {
      summary: Record<string, unknown>;
    };

    reactAdminHelpers: {
      translate: Translate;
      locale: Locale;
    };

    toggleAccordion: {
      accordionId: string;
    };

    scroll: ScrollParamsInterface;
  }
}

let logConfig: LogConfigInterface<ActorActionList> | undefined;
if (process.env.NODE_ENV === 'development') {
  logConfig = {
    actionNames: [],
    showActionHistory: true,
  };

  // To show logs in console
  localStorage.setItem('ALWATR_LOG', '*');
} else {
  localStorage.removeItem('ALWATR_LOG');
}

export const actorDispatch = dispatchRunner<ActorActionList>(logConfig);
export const actorOnDispatch = onDispatchRunner<ActorActionList>();
export const actorGetActionValue = getActionValueRunner<ActorActionList>();
export const actorSetActionValue = setActionValueRunner<ActorActionList>(logConfig);
export const waitForAction = waitForActionRunner<ActorActionList>();
export const actorRemoveAction = removeActionRunner<ActorActionList>();
export const actorResetActionList = resetActionList;
