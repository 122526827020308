import produce from 'immer';
import { USER_LOGOUT } from 'react-admin';
import lodashFindIndex from 'lodash/findIndex';

import {
  TOGGLE_TODO_MENU_SIDEBAR,
  CLOSE_TODO_MENU_SIDEBAR,
  GET_LIST_SUCCESS_ACTION,
  GET_TODO_LIST_ACTION,
  GET_LIST_FAILED_ACTION,
  INSERT_TASK_FAILED_ACTION,
  PLUS_LIST_COUNTER_ACTION,
  OPEN_DIALOG,
  CLOSE_DIALOG,
  OPEN_TODO_SHARE_LIST_DIALOG_ACTION,
  CLOSE_TODO_SHARE_LIST_DIALOG_ACTION,
  TOGGLE_GROUP_EXPANDED,
  ADD_NEW_TODO_LIST_OR_GROUP,
  ADD_NEW_TODO_LIST_OR_GROUP_END,
  SELECT_TODO_LIST,
  SET_SYSTEM_TASK_FILTERS,
} from './constant';
import {
  CONFIG_CACHED_TODO_EXPANDED_GROUP,
  getValue,
  setValue,
} from '../../core/configProvider';

const initialState = {
  showTodoMenuSidebar: true,
  loadingList: {},
  createLoading: false,
  resultList: {},
  errorList: {},
  isOpen: false,
  dialogDataTask: null,
  dialogTaskResource: null,
  dialogListResource: null,
  dialogSelectedTodoList: null,
  loadedOnes: {},
  isOpenShareList: false,
  shareResource: null,
  shareListMemberResource: null,
  shareListItem: null,
  expandedGroupObj: getValue(CONFIG_CACHED_TODO_EXPANDED_GROUP) || {},
  selectedListMember: {},
  sysTaskFilters: [],
};

const myReducer = (
  state = initialState,
  {
    type,
    resource,
    data,
    id,
    error,
    patternListId,
    patternCounter,
    listMemberResource,
    todolistItem,
    selectedTodoList,
    listResource,
    selectedListMember,
    sysTaskFilters,
    shareListParentId,
  },
) =>
  produce(state, draft => {
    // eslint-disable-next-line default-case
    switch (type) {
      case USER_LOGOUT:
        draft.showTodoMenuSidebar = true;
        draft.loadingList = {};
        draft.resultList = {};
        draft.createLoading = false;
        draft.errorList = {};
        draft.isOpen = false;
        draft.dialogDataTask = null;
        draft.dialogSelectedTodoList = null;
        draft.dialogTaskResource = null;
        draft.loadedOnes = {};
        draft.expandedGroupObj = {};

        setValue(CONFIG_CACHED_TODO_EXPANDED_GROUP, null);
        break;

      case ADD_NEW_TODO_LIST_OR_GROUP:
        draft.createLoading = true;
        break;

      case ADD_NEW_TODO_LIST_OR_GROUP_END:
        draft.createLoading = false;
        break;

      case TOGGLE_GROUP_EXPANDED:
        draft.expandedGroupObj[id] = !draft.expandedGroupObj[id];
        setValue(CONFIG_CACHED_TODO_EXPANDED_GROUP, draft.expandedGroupObj);

        break;

      case TOGGLE_TODO_MENU_SIDEBAR:
        draft.showTodoMenuSidebar = !draft.showTodoMenuSidebar;
        break;

      case CLOSE_TODO_MENU_SIDEBAR:
        draft.showTodoMenuSidebar = false;
        break;

      case GET_TODO_LIST_ACTION:
        draft.loadingList[resource] = true;
        draft.loadedOnes[resource] = true;
        draft.errorList[resource] = null;
        break;

      case GET_LIST_SUCCESS_ACTION:
        draft.loadingList[resource] = false;
        draft.resultList[resource] = data;
        draft.errorList[resource] = null;
        break;

      case GET_LIST_FAILED_ACTION:
        draft.loadingList[resource] = false;
        draft.resultList[resource] = null;
        draft.errorList[resource] = error;
        break;

      case INSERT_TASK_FAILED_ACTION:
        draft.loadingList[resource] = false;
        draft.errorList[resource] = error;
        break;

      case PLUS_LIST_COUNTER_ACTION:
        const listIndex = lodashFindIndex(draft.resultList[resource], [
          [patternListId],
          id,
        ]);
        if (listIndex !== -1) {
          draft.resultList[resource][listIndex][patternCounter] += 1;
        }
        break;

      case CLOSE_DIALOG:
        draft.isOpen = false;
        draft.dialogDataTask = null;
        draft.dialogTaskResource = null;
        draft.dialogSelectedTodoList = null;
        draft.dialogListResource = null;
        break;

      case OPEN_DIALOG:
        draft.isOpen = true;
        draft.dialogDataTaskId = id;
        draft.dialogTaskResource = resource;
        draft.dialogListResource = listResource;
        draft.dialogSelectedTodoList = selectedTodoList;
        break;

      case OPEN_TODO_SHARE_LIST_DIALOG_ACTION:
        draft.isOpenShareList = true;
        draft.shareResource = resource;
        draft.shareListMemberResource = listMemberResource;
        draft.shareListItem = todolistItem;
        draft.shareListParentId = shareListParentId;
        break;

      case CLOSE_TODO_SHARE_LIST_DIALOG_ACTION:
        draft.isOpenShareList = false;
        draft.shareResource = null;
        draft.shareListMemberResource = null;
        draft.shareListItem = null;
        break;

      case SELECT_TODO_LIST:
        draft.selectedListMember = selectedListMember;
        break;

      case SET_SYSTEM_TASK_FILTERS:
        draft.sysTaskFilters = sysTaskFilters;
        break;
    }
  });

export default myReducer;
