import { FC, useState, useEffect, useRef, memo } from 'react';

import RelationPanelSummaryView from '../custom-accordion/custom-summary-views/relation-panel-summary-view';
import { getAppSettings } from '../../helper/settings-helper';
import { CustomAccordion } from '../custom-accordion';
import RelationPanelView from './relation-panel.view';
import { useStyles } from './relation-panel.style';
import useRelationData from './use-relation-data';
import LoadingBox from '../LoadingBox';
import NotFound from '../NotFound';
import {
  RelationPanelBasePropsInterface,
  RelationPanelControllerInterface,
  RelationPermissions,
} from './relation-panel.type';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
  FormKeyMode,
  RecordKeyMode,
} from '../../type/actor-setup';
import {
  KEY_SCROLL_TO,
  preparedRelationPermission,
  shouldRelationRefresh,
  requestRelationData,
} from './relation-panel.helper';
import {
  CONFIG_LIST_COLUMN_CHOICE,
  CONFIG_LIST_COLUMN_WIDTH,
  CONFIG_LIST_SORT,
  DEFAULT,
} from '../../core/configProvider';

import type { GeneralMetaData, MetaData } from '../../helper/Types';
import { apiRequestResultRelationHandler } from '../../helper/crud-api.helper';
import { fieldSort } from '../grid/grid.type';
import { getRootTableId } from '../../helper/meta-helper';
import { scrollToNote } from './note-relation/pinned-note/pinned-notes-helper';
import { customRecordNotePin } from './note-relation/create-edit-note/consistent';

const RelationPanel: FC<RelationPanelControllerInterface> = props => {
  const {
    relationItemInMetaData,
    relationType,
    parentResource,
    parentRecordIsEditable,
    parentRecordId,
    relationIndex,
  } = props;

  const {
    moduleName,
    moduleTableName,
    id,
    childFieldName,
    parentFieldName,
    reportId,
  } = relationItemInMetaData;

  const classes = useStyles();

  const relationResource = reportId
    ? `report/${id}`
    : `${moduleName}/${moduleTableName}`;

  const [refreshSignal, setRefreshSignal] = useState(0);
  const actorOnDispatchCallbackIdRef = useRef<symbol>();

  useEffect(() => {
    actorOnDispatchCallbackIdRef.current = actorOnDispatch(
      'refreshView',
      resource =>
        shouldRelationRefresh(resource, relationResource) &&
        setRefreshSignal(prevVersion => prevVersion + 1),
    );

    return () => {
      actorRemoveAction({
        actionName: 'refreshView',
        listenerId: actorOnDispatchCallbackIdRef.current,
      });
    };
  }, []);

  useEffect(() => {
    if (refreshSignal) {
      refreshRelation();
    }
  }, [refreshSignal]);

  const relationPath = `${moduleName}/${moduleTableName}/${childFieldName}`;

  // --------------- Parent Information ---------------
  const parentMetaData = actorGetActionValue(
    'metaData',
    parentResource,
  ) as MetaData | null;

  const parentRecord = actorGetActionValue(
    'record',
    `${parentResource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
  ) as Record<string, unknown>; // when code arrive here , 100% parent record exists!

  const clearAllSelectedCheckboxes = (): void => {
    console.log('clearAllSelectedCheckboxes');
  };

  // --------------- Handle Meta ---------------
  const [
    relationMetaData,
    relationData,
    isRelationDataReady,
    getNewRelationMetaAndRefreshData,
    metaDataError,
    handleGetRelationDataRequestFailure,
  ] = useRelationData({
    relationResource,
    childFieldName,
    parentIdentifier: parentRecord?.[parentFieldName] as string,
    relationIndex: relationIndex!, // in map loop we always have index
    relationType,
  });

  /**
   * get relation meta data if not exist, then request again and set new data in relation data
   * @function refreshRelation
   * @returns {void} void
   */
  const refreshRelation = (): void => {
    const metaDataInActor = actorGetActionValue('metaData');
    if (!relationMetaData || !metaDataInActor?.[relationResource]) {
      getNewRelationMetaAndRefreshData(
        (parentRecord?.[parentFieldName] as string) ?? parentRecordId,
      );
      return;
    }

    const isMultiResult =
      metaDataInActor?.[relationResource]?.['reportType'] === 'MultiResult';

    requestRelationData(
      isMultiResult && !relationData ? relationResource + '/0' : relationResource,
      relationType,
      (parentRecord?.[parentFieldName] as string) ?? parentRecordId, // FIXME
      childFieldName,
      metaDataInActor?.[relationResource] as GeneralMetaData,
      {},
      apiRequestResultRelationHandler,
      handleGetRelationDataRequestFailure,
    );
  };

  useEffect(() => {
    if (relationData == null || relationType !== 'note') return;
    const getUniqIdToScroll = actorGetActionValue('scroll');

    setTimeout(() => {
      scrollToNote(getUniqIdToScroll?.uniqIdToScroll);
      actorSetActionValue('scroll', { uniqIdToScroll: '' });
    }, 150);
  }, [relationData?.lastRequestId]);

  if (!isRelationDataReady) {
    return <LoadingBox />;
  }

  // --------------- Handle Permissions ---------------
  const relationPermission: RelationPermissions = preparedRelationPermission(
    parentMetaData!,
    parentRecord!,
    relationItemInMetaData,
    relationMetaData,
    parentRecordIsEditable,
  );

  // --------------- Handle Setting ---------------
  const defaultSelected = getAppSettings(
    `${DEFAULT}_${CONFIG_LIST_COLUMN_CHOICE}_${relationResource}`,
  ).value;

  const userSelected = getAppSettings(
    `${CONFIG_LIST_COLUMN_CHOICE}_${relationResource}`,
    true,
  ).value;

  const defaultColumnWidth = getAppSettings(
    `${DEFAULT}_${CONFIG_LIST_COLUMN_WIDTH}_${relationResource}`,
  ).value;
  const userColumnWidth = getAppSettings(
    `${CONFIG_LIST_COLUMN_WIDTH}_${relationResource}`,
    true,
  ).value;
  const userSort = getAppSettings(
    `${CONFIG_LIST_SORT}_${relationResource}`,
    true,
  ).value;
  // --------------- prepare base props ---------------
  const currentUrl =
    actorGetActionValue('urlInfo')?.location?.href ?? window.location.href ?? '';

  const parentInfo = {
    parentResource,
    parentId: parentRecord?.id as string,
    parentProcessUniqueId: parentRecord?.__processuniqueid as string | null,
    parentPositionId: parentRecord?.positionid as string | null,
    parentStateId: parentRecord?.stateid as string | null,
  };

  const relationPanelBaseProps: RelationPanelBasePropsInterface = {
    relationData: relationData?.data ?? [],
    relationDataCount: relationData?.totalCount ?? 0,
    relationPermission,
    relationMetaData: relationMetaData as GeneralMetaData,
    relationType,
    currentUrl,
    parentInfo,
    settings: {
      defaultSelected: defaultSelected as number[] | null,
      userSelected: userSelected as number[] | null,
      defaultColumnWidth: defaultColumnWidth as number | null,
      userColumnWidth: userColumnWidth as number | null,
      userSort: userSort as fieldSort,
    },
    relationResource,
    childFieldName,
    parentFieldName,
    refreshSignal,
  };

  const idTarget =
    relationType === 'note'
      ? 'notes'
      : relationType === 'report' || relationType === 'multiReport'
      ? id
      : moduleTableName;

  return (
    <div className={classes.relationPanelContainer} id={KEY_SCROLL_TO + idTarget}>
      <CustomAccordion
        id={idTarget}
        enableChild
        defaultExpanded={relationIndex === 0}
        // if relation data has not lastRequestId, it means that this relation never requested data at all
        // its possible when the relations was not in first index and never has been opened
        onChangeCallback={relationData?.lastRequestId ? undefined : refreshRelation}
        summary={
          <RelationPanelSummaryView
            relationType={relationType}
            relationItemInMetaData={relationItemInMetaData}
            relationResource={relationResource}
            parentResource={parentResource}
            relationPermission={relationPermission}
            relationPath={relationPath}
            currentUrl={currentUrl}
            relationMetaData={relationMetaData}
            relationData={relationData}
            parentInfo={parentInfo}
            parentRecord={parentRecord}
            clearAllSelectedCheckboxes={clearAllSelectedCheckboxes}
            refreshRelation={refreshRelation}
            hideActions={!!relationData?.error}
          />
        }
      >
        {relationMetaData && relationData?.lastRequestId ? (
          <RelationPanelView
            relationType={relationType}
            relationPanelBaseProps={relationPanelBaseProps}
          />
        ) : metaDataError ? (
          <NotFound title={metaDataError} hideBackButton />
        ) : relationData?.error ? (
          <NotFound
            title={relationData.error}
            hideBackButton
            style={{ marginBottom: 10 }}
          />
        ) : (
          <LoadingBox />
        )}
      </CustomAccordion>
    </div>
  );
};

export default memo(RelationPanel, (prevProps, nextProps) => {
  if (
    prevProps.parentRecordId !== nextProps.parentRecordId ||
    prevProps.parentResource !== nextProps.parentResource ||
    prevProps.processInfo.positionid !== nextProps.processInfo.positionid ||
    prevProps.processInfo.processuniqueid !==
      nextProps.processInfo.processuniqueid ||
    prevProps.processInfo.stateid !== nextProps.processInfo.stateid
  ) {
    return false;
  }
  return true;
});
