/* eslint-disable no-use-before-define */
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createRef,
} from 'react';
import {
  Checkbox,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
  Typography,
  Icon,
  Button,
  OutlinedInput,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ClearIcon from '@material-ui/icons/Clear';
import { Autocomplete } from '@material-ui/lab';
import lodashDebounce from 'lodash/debounce';
import lodashGet from 'lodash/get';
import { useInput, useTranslate } from 'react-admin';
import { connect } from 'react-redux';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import SearchPopupButton from '../../container/SearchPopupButton';
import {
  convertToStringValue,
  getTextSelectedOption,
} from '../../helper/AutocompleteHelper';
import { isEmpty } from '../../helper/data-helper';
import {
  getLabelForDropdownOption,
  triggerDropdownFetchData,
} from '../../helper/DropdownHelper';
import { getComponentsDefaultParams } from '../../helper/PatternMetaHelper';
import { FieldType } from '../../helper/Types';
import {
  useAutoLimitTags,
  useHandlePopup,
  useOptionsData,
} from '../../hooks/useAutocompeletHandler';
import { findOneAction as findDropdownDataAction } from '../../redux/dropdown/action';
import InputLabelComponent from './InputLabelComponent';
import useStyles from './styles/AutocompleteInput.style';
import { useOutsideAlerter } from '../../hooks/useAutocompeletHandler';

export interface DropDownMetaInterface {
  valueMember: string;
  displayMember: string;
  moduleName: string;
  tableName: string;
  id: string;
}

interface AutocompleteInputInterface {
  label: string;
  source: string;
  field: FieldType;
  onChange: Function;
  visibleClass: string;
  inputInPuzzleForm: boolean;
  loading: boolean;
  style: CSSProperties | undefined;
  findDropdownData: Function;
  formData: object;
  onFocus: Function;
  onBlur: Function;
  OnChange: Function;
  disabled: boolean;
  dropdownMeta: DropDownMetaInterface;
  record: {
    isNewRecord: boolean;
  };
  dropdownData: object[];
  allLoadedData: object[];
  customError?: string;
  relationResource: string;
  relationSource: string;
  relationInfo: object;
  additionalProps: object;
  resource: string;
  options: object;
  step: string;
  textAlign: string;
}
export interface DropdownOption {
  text: string;
  value: string;
  key: string;
}

type onChangeFunc = (
  event: React.ChangeEvent<{}>,
  newValue: any[],
  reason: any,
) => void;

const AutocompleteInput: FC<AutocompleteInputInterface> = props => {
  const {
    label,
    source,
    dropdownMeta,
    formData,
    loading,
    dropdownData,
    disabled,
    customError,
    record,
    relationResource,
    relationSource,
    relationInfo,
    additionalProps,
    resource,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const wrapperRef = createRef<HTMLDivElement>();
  const buttonRefDialog = createRef<HTMLSpanElement>();
  const [inputValue, setInputValue] = useState<string>('');
  const { moduleName, tableName, valueMember, id } = dropdownMeta;
  const {
    input: { onChange, value, onBlur, onFocus },
    meta: { touched, error },
  } = useInput(props);

  /**
   * this function delete empty value and return list of string
   * @function preparedValue
   * @return {Array}
   */
  const preparedValue: [] = useMemo(
    () =>
      value
        .toString()
        .split(',')
        .filter((item: string) => !isEmpty(item)),
    [value],
  );

  const hasError = !!(touched && error);
  const dropResource = `${moduleName}/${tableName}`;
  const { limitTagCount, refTags, refRootTags } = useAutoLimitTags(preparedValue, [
    value,
  ]);
  const { open, handleClose, handleOpen } = useHandlePopup();
  const { preparedOptions } = useOptionsData({
    valueMember,
    value,
    dropdownData,
    dropdownMeta,
  });

  useEffect(() => {
    /**
     * fetch data for first time render component
     */
    triggerDropdownFetchData(
      props,
      '',
      getComponentsDefaultParams('DropBaseMultiSelect'),
    );
  }, []);

  /**
   * this function handle value change in search input drop down
   * @function handleChangeInput
   * @param {React.ChangeEvent<HTMLInputElement>} {event}
   * @return {void}
   */
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
    handleChangeSearch(event.target.value);
  };

  /**
   * Turn values into a string chain and emit onChange
   * @function handleChange
   * @param {React.ChangeEvent<{}>} {event}
   * @param {string} {value}
   * @param {AutocompleteChangeReason} {reason}
   * @returns {void}
   */
  const handleChange: onChangeFunc = useCallback(
    (event, Value, reason) => {
      const newValue = convertToStringValue(Value);
      onChange(newValue);
      props.onChange(newValue);
    },
    [props.OnChange, onChange],
  );

  /**
   * it will get a value from row click or some values from checkbox and add or replace it to input value state
   * @function handleSelectionWithSearchDialog
   * @param {string} {newValue}
   * @param {boolean} {rowClick}
   * @returns {void}
   */
  const handleSelectionWithSearchDialog = (
    newValue: string,
    rowClick = false,
  ): void => {
    if (rowClick) {
      if (isEmpty(value)) {
        onChange(newValue);
        props.onChange(newValue);
      } else {
        if (!value.includes(newValue)) {
          onChange(value + ',' + newValue);
          props.onChange(value + ',' + newValue);
        }
      }
    } else {
      onChange(newValue);
      props.onChange(newValue);
    }
  };

  /**
   * convert data to options list object
   * @function renderOptions
   * @returns {Array<object>}
   */
  const renderOptions: DropdownOption[] = useMemo(
    () =>
      dropdownData.map((item, index) => ({
        text: getLabelForDropdownOption(dropdownMeta, item),
        value: item[valueMember] + '',
        key: item[valueMember] + '' + index,
      })),
    [dropdownData],
  );

  /**
   * send request search value
   * @function handleChangeSearch
   * @param {string} {value}
   * @returns {void}
   */
  const handleChangeSearch = useCallback(
    lodashDebounce((value: string) => {
      triggerDropdownFetchData(props, value);
    }, 700),
    [props.formData],
  );

  /**
   * on click for open drop down options (first get data options and handleOpen for open drop down)
   * @function handleClick
   * @param {React.MouseEvent<HTMLElement>} {event}
   * @returns {void}
   */
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    triggerDropdownFetchData(
      props,
      '',
      getComponentsDefaultParams('DropBaseMultiSelect'),
    );
    handleOpen(event.currentTarget);
  };

  /**
   * on click for open drop down options (first get data options and handleOpen for open drop down)
   * @function handleClick
   * @param {string} {select}
   * @returns {void}
   */
  const handleDeleteItem = (select: string): void => {
    const filterValue = value
      .split(',')
      .filter((item: string) => item !== select)
      .join();
    onChange(filterValue);
    props.onChange(filterValue);
  };

  /**
   * this function stopPropagation when user press backspace search input
   * @function handleOnKeyDown
   * @param {React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>} {event}
   * @return {void}
   */
  const handleOnKeyDown:
    | React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined = (event): void => {
    if (event.key === 'Backspace') {
      event.stopPropagation();
    }
  };

  /**
   * disable default render tag autocomplete material ui
   * @function renderTags
   * @returns {null}
   */
  const renderTags = () => null;

  /**
   * onclick for close drop down
   * @function onClickIcon
   * @param {React.ChangeEvent<{}>} {e}
   * @returns {void}
   */
  const onClickIcon = (e: React.ChangeEvent<{}>): void => handleClose(e, 'escape');

  /**
   *@function handleDeleteButton
   * @param {any} {e}
   * @returns {void}
   */
  const handleDeleteButton = (e: any): void => {
    handleDeleteItem(e.currentTarget.name);
  };

  /**
   * open search dialog
   * @function handleClickSearchDialog
   * @return {void}
   */
  const handleClickSearchDialog = (): void => {
    if (buttonRefDialog.current) {
      buttonRefDialog.current.click();
      buttonRefDialog.current.style.display = 'none';
    }
  };

  /**
   * outside alerter hook for close drop down when user click out side drop
   */
  useOutsideAlerter(wrapperRef, onClickIcon);
  return (
    <InputLabelComponent label={label}>
      <div
        ref={wrapperRef}
        className={classes.rootDropdown}
        data-test-field-name={source}
      >
        <div
          ref={refRootTags}
          className={`${classes.root} ${open ? classes.rootPopup : ''}`}
        >
          {preparedValue.length ? (
            <div
              className={classes.rootTags}
              ref={refTags}
              data-test-text-field-name={source}
            >
              {preparedValue
                .slice(0, limitTagCount)
                .map((item: string, index: any) => (
                  <Button
                    disabled={disabled}
                    classes={{ root: classes.button }}
                    name={item}
                    onClick={handleDeleteButton}
                  >
                    <Typography className={classes.typography}>
                      {getTextSelectedOption(preparedOptions.selectedOptions, item)}
                    </Typography>
                    <ClearIcon className={classes.clearIcon} />
                  </Button>
                ))}
            </div>
          ) : null}
          {limitTagCount && preparedValue.slice(limitTagCount).length ? (
            <Tooltip
              classes={{
                tooltip: classes.rootTooltip,
                arrow: classes.arrowTooltip,
              }}
              arrow
              placement="left-start"
              title={
                <>
                  {preparedValue.slice(limitTagCount).map(item => (
                    <p data-test-input-name="popover-more-test">
                      {getTextSelectedOption(preparedOptions.selectedOptions, item)}
                    </p>
                  ))}
                </>
              }
            >
              <p
                data-test-input-name="mouse-over-more-test"
                className={classes.mouseOver}
              >
                ...
              </p>
            </Tooltip>
          ) : null}
          {preparedValue.length ? null : (
            <InputBase
              disabled={disabled}
              className={classes.paddingInput}
              inputProps={{
                placeholder: translate('dropdown.placeholder'),
                onClick: handleClick,
                disabled: disabled,
              }}
            />
          )}
          {!open ? (
            <IconButton
              onClick={handleClick}
              className={classes.dropButton}
              disabled={disabled}
              data-test-input-name="auto-complete-input"
            >
              <ArrowDropDownIcon className={classes.drop} />
            </IconButton>
          ) : (
            <IconButton
              data-test-input-name="auto-complete-close-paper"
              onClick={onClickIcon}
              className={classes.dropButton}
              disabled={disabled}
            >
              <ArrowDropUpIcon className={classes.drop} />
            </IconButton>
          )}
        </div>
        {open ? (
          <Paper
            data-test-input-name="auto-complete-input-paper"
            // elevation={3}
            variant="outlined"
            className={classes.listbox}
          >
            <Autocomplete
              open
              onClose={handleClose}
              multiple
              classes={{
                root: classes.rootAutocomplete,
                paper: classes.paper,
                option: classes.option,
                popperDisablePortal: classes.popperDisablePortal,
                listbox: classes.listboxAuto,
              }}
              value={value ? value.toString().split(',') : []}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              disableCloseOnSelect
              disablePortal
              disableClearable
              filterOptions={options => options}
              renderTags={renderTags}
              loadingText={translate('dropdown.loading')}
              noOptionsText={translate('dropdown.noOptionsMessage')}
              getOptionSelected={(options, value) => options.value == value}
              renderOption={(option, { selected }) => (
                <div
                  data-test-input-name={
                    'auto-complete-options-typo-' + option['text']
                  }
                  data-test-grid-row={option['value']}
                  className={classes.ulBox}
                >
                  <Checkbox
                    className={classes.checkBox}
                    icon={
                      <CheckBoxOutlineBlankIcon
                        className={classes.checkBoxOutlineBlank}
                        fontSize="small"
                      />
                    }
                    checkedIcon={
                      <CheckBoxOutlinedIcon
                        className={classes.checkBoxOutline}
                        fontSize="small"
                      />
                    }
                    checked={selected}
                  />
                  {option['text']}
                </div>
              )}
              loading={loading}
              options={renderOptions}
              renderInput={params => (
                <OutlinedInput
                  data-test-input-name="auto-complete-input-box"
                  ref={params.InputProps.ref}
                  classes={{
                    root: classes.inputRoot,
                    notchedOutline: classes.notched,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    onChange: handleChangeInput,
                    value: inputValue,
                  }}
                  autoFocus
                  onKeyDown={handleOnKeyDown}
                  endAdornment={
                    <IconButton
                      className={`${classes.searchIcon} ${classes.padding}`}
                      data-test-input-name="auto-complete-search-button"
                      onClick={handleClickSearchDialog}
                    >
                      <Icon fontSize="small">search</Icon>
                    </IconButton>
                  }
                  disabled={disabled}
                />
              )}
            />
          </Paper>
        ) : null}
      </div>
      <SearchPopupButton
        resource={dropResource}
        parentResource={resource}
        source={source}
        dropdownId={id}
        dropdownMeta={dropdownMeta}
        record={record}
        relationResource={relationResource}
        relationSource={relationSource}
        relationInfo={relationInfo}
        disabled={disabled || loading}
        changeFormValue={handleSelectionWithSearchDialog}
        formData={formData}
        additionalProps={additionalProps}
        dropdownMultipleSelection
        dropdownData={dropdownData}
        buttonRef={buttonRefDialog}
        dropBaseValue={preparedValue}
        buttonCustomStyle={{ display: 'none' }}
      />
      {(!!customError || hasError) && (
        <Typography className={classes.errorText} variant="caption" color="error">
          {customError ? customError : translate(lodashGet(error, 'message', error))}
        </Typography>
      )}
    </InputLabelComponent>
  );
};
const dummyFunc = () => {};

AutocompleteInput.defaultProps = {
  onFocus: dummyFunc,
  onBlur: dummyFunc,
  onChange: dummyFunc,
  options: {},
  step: 'any',
  textAlign: 'right',
};

const defaultEmptyArray = [];

const mapStateToProps = (state, props) => {
  const dropdownId = lodashGet(props, 'dropdownMeta.id');

  return {
    loading: !!lodashGet(state, ['dropdown', dropdownId + '_loading']), // loading status of data
    dropdownData: lodashGet(state, ['dropdown', dropdownId], defaultEmptyArray), // app dropdown data object
  };
};

const mapDispatchToProps = {
  findDropdownData: findDropdownDataAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteInput);
