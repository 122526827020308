/**
 * this function find format byte
 *@function formatBytes
 * @param {number} bytes
 * @param {number} decimals
 * @returns {string}
 */
export function formatBytes(bytes: number, decimals = 0): string {
  if (bytes === 0) return '0';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Specify whether the file is an image or not
 * @param {string} fileName The file name with its extention
 * @returns {Boolean}
 */
export function isImageFile(fileName: string): boolean {
  let result = false;
  if (!fileName) {
    return result;
  }
  const fileTypeList = ['jpg', 'png', 'jpeg', 'bmp', 'webp'];
  for (const item of fileTypeList) {
    if (fileName.includes(item)) {
      result = true;
      break;
    }
  }
  return result;
}
