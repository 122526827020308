import React, { useMemo } from 'react';
import { useTranslate, useInput, useLocale } from 'react-admin';
import DatePicker from 'react-datepicker2-samian';
import moment from 'moment-jalaali';
import { withStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodashGet from 'lodash/get';

import './JalaliDateInput.css';
import { SERVER_DATE_FORMAT } from '../core/configProvider';
import { isEmpty } from '../helper/data-helper';
import { getCalendarType, getInputFormat } from '../helper/DateHelper';

const styles = theme => ({
  error: {
    position: 'absolute',
    right: 0,
    top: 0,
  },

  legend: {
    fontSize: 10,
    lineHeight: 0,
    color: theme.palette.primary.appSecondaryTextColor,
    display: 'block',
    padding: '0 3px',

    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
    },
  },
});

const JalaliDateInput = props => {
  const {
    classes,
    label,
    DateInputInPuzzleForm,
    source,
    disabled,
    visibleClass,
    options,
    customError,
    style,
    field,
  } = props;

  const translate = useTranslate();

  const { fixCalendar } = field;
  const calendarConfig = getCalendarType(fixCalendar);
  const locale = useLocale();
  const inputFormat = getInputFormat(fixCalendar);
  const {
    input: { onChange, value },
    meta: { touched, error },
  } = useInput(props);
  const isRequired = field.required;

  const internalValue = useMemo(() => {
    if (!value) {
      return null;
    }

    // `getdate()` is sql function for default value
    if (value.toLowerCase().includes('getdate()')) {
      return moment(moment().format(SERVER_DATE_FORMAT));
    }
    return moment(value, SERVER_DATE_FORMAT);
  }, [value]);

  const handleChange = changedDate => {
    const newValue = changedDate
      ? changedDate.locale('en').format(SERVER_DATE_FORMAT)
      : null;
    if (newValue === value) {
      return;
    }

    props.onChange(newValue);
    onChange(newValue);
  };

  const getRef = ref => {
    const parentGetRef = lodashGet(props, 'options.inputRef');

    if (typeof parentGetRef === 'function') {
      parentGetRef(ref);
    }
  };

  const hasError = !!(touched && error);

  return (
    <fieldset
      data-test-field-name={source}
      className={`${visibleClass} datePickerContainer`}
      data-error={!!customError || hasError}
      data-label={!!label || label !== ''}
      data-puzzle-form={!!DateInputInPuzzleForm}
      disabled={disabled}
    >
      {label && !isEmpty(label) && (
        <legend
          className={`${classes.legend} ${
            !!customError || hasError ? classes.legendError : ''
          }`}
        >
          {label}
          {isRequired ? '*' : ''}
        </legend>
      )}
      <DatePicker
        {...options}
        ref={getRef}
        inputFormat={inputFormat}
        onChange={handleChange}
        value={internalValue}
        disabled={disabled}
        isGregorian={calendarConfig === 'gregorian'}
        timePicker={false}
        showTodayButton={false}
        setTodayOnBlur={false}
        locale={locale === 'ar' ? 'fa' : locale}
      />
      {(!!customError || hasError) && (
        <FormHelperText error className={classes.error}>
          {translate(lodashGet(error, 'message', error))}
        </FormHelperText>
      )}
    </fieldset>
  );
};

JalaliDateInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  options: {},
};

export default withStyles(styles, { withTheme: true })(JalaliDateInput);
