import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth/withWidth';
import { withStyles } from '@material-ui/core';

import DynamicList from '../container/DynamicList';
import NoteStreamSidebar from '../component/NoteStreamSidebar';
import { hasNote } from '../helper/MetaHelper';
import { closeNoteStreamAction } from '../redux/listPage/action';
import LoadingBox from '../component/LoadingBox';
import { NewMetaContext } from '../container/NewMetaContext';
import CalendarListView from '../component/CalendarListView';
import { CONFIG_CALENDAR, getValue } from '../core/configProvider';
import { getCalendarDefaultFilter } from '../helper/CalendarMetaHelper';
import CheckResourceReady from '../container/CheckResourceReady';

const CalendarPage = props => {
  const {
    staticContext,
    classes,
    showNoteStream,
    closeNoteStream,
    showDevExtremeTopFilter,
    showDevExtremeGrouping,
    resource,
    redirectToPage,
    ...rest
  } = props;

  const { getMeta } = useContext(NewMetaContext);
  const metaData = getMeta(resource);
  if (!metaData) {
    return <LoadingBox />;
  }

  const isReport = resource.indexOf('report') === 0;
  const isNoteStreamEnabled = hasNote(metaData);

  const isSlideOpen = () => {
    const { showNoteStream } = props;
    const isNoteStreamEnabled = hasNote(metaData);

    return isNoteStreamEnabled && showNoteStream;
  };

  const onRootClick = () => {
    const { theme, width, closeNoteStream } = props;
    if (theme.breakpoints.width(width) < theme.breakpoints.width('md')) {
      if (isSlideOpen()) {
        closeNoteStream();
      }
    }
  };

  const onRowClick = row => {
    redirectToPage(`/${resource}/${row.id}/show`);
  };

  const isJalali = getValue(CONFIG_CALENDAR) === 'jalali';
  const filterDefaultValues = getCalendarDefaultFilter(resource, isJalali);

  return (
    <CheckResourceReady resourceName={resource}>
      <DynamicList
        {...rest}
        resource={resource}
        metaData={metaData}
        hasList={true}
        hasCreate={!isReport}
        hasEdit={!isReport}
        hasShow={!isReport}
        enableSelection={true}
        basePath={`/${resource}`}
        slideComponent={isNoteStreamEnabled ? <NoteStreamSidebar /> : null}
        isSlideOpen={isSlideOpen()}
        onRootClick={onRootClick}
        onRowClick={onRowClick}
        isTopFilterOpen={showDevExtremeTopFilter}
        isGroupingOpen={showDevExtremeGrouping}
        viewComponent={<CalendarListView isJalali={isJalali} />}
        perPage={1000000}
        pagination={null}
        filterDefaultValues={filterDefaultValues}
      />
    </CheckResourceReady>
  );
};

CalendarPage.propTypes = {
  resource: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { match }) => {
  const { moduleName, moduleTableName } = match.params;
  const resource = `${moduleName}/${moduleTableName}`;

  return {
    resource,
    showNoteStream: state.listPage.showNoteStream,
    showDevExtremeTopFilter: state.listPage.showDevExtremeTopFilter,
    showDevExtremeGrouping: state.listPage.showDevExtremeGrouping,
  };
};

const mapDispatchToProps = {
  closeNoteStream: closeNoteStreamAction,
  redirectToPage: push,
};

export default compose(
  withWidth(),
  withStyles({}, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(CalendarPage);
