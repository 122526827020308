import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

const useStyles = makeStyles((theme: CustomTheme) => ({
  rootDropdown: {
    width: '100%',
    position: 'relative',
    height: '100%',
  },

  rootTooltip: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid' + theme.palette.secondary.main,
    color: theme.palette.common.black,
  },

  paper: {
    boxShadow: 'none',
    margin: 0,
    color: theme.palette.primary.appPrimaryTextColor,
    fontSize: 13,
  },

  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 0,
    direction: 'ltr',
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
  },

  popperDisablePortal: {
    position: 'relative',
    width: '100% !important',
    '& .MuiAutocomplete-loading': {
      direction: 'ltr',
    },
    '& .MuiAutocomplete-noOptions': {
      direction: 'ltr',
    },
  },

  rootAutocomplete: {
    '& .MuiInputBase-root': {
      padding: 0,
    },
  },

  search: {
    width: '100%',
  },

  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    border: '1px solid ' + theme.palette.primary.appPrimaryDividerColor,
    borderRadius: 4,
    padding: '4px 7px',
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
  },

  rootPopup: {
    borderRadius: '4px 4px 0 0 !important',
    borderBottom: 'none',
  },

  rootAuto: {
    flex: 'auto',
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: '0 !important',
    },
  },

  inputRoot: {
    '& .MuiOutlinedInput-input': {
      padding: 8,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: '0px !important',
      borderColor: theme.palette.primary.appPrimaryDividerColor + ' !important',
    },
    margin: '0px 11px',
    display: 'flex !important',
    direction: 'ltr',
  },

  notched: {
    '& > legend': {
      display: 'none !important',
    },
  },

  label: {
    display: 'block',
  },

  input: {
    width: 200,
  },

  listbox: {
    width: '100%',
    margin: 0,
    paddingTop: 0,
    border: '1px solid ' + theme.palette.primary.appPrimaryDividerColor,
    zIndex: 100,
    borderTop: 'none',
    position: 'absolute',
    borderRadius: '0 0 4px 4px !important',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    direction: 'rtl',
    '& .MuiAutocomplete-option[data-focus="true"]': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .MuiAutocomplete-option:hover': {
      backgroundColor: theme.palette.primary.appPrimaryDividerColor,
    },
  },

  button: {
    padding: '1px 8px',
    margin: '0px 4px',
    borderRadius: 6,
    background: theme.palette.primary.appPrimaryDividerColor,
    '&:hover': {
      background: theme.palette.primary.appPrimaryLightBackgroundColor,
    },
  },

  typography: {
    margin: '0px 8px',
  },

  clearIcon: {
    color: theme.palette.common.white,
    width: 16,
    height: 16,
    fill: 'currentColor',
    background: theme.palette.primary.appPrimaryIconColor,
    borderRadius: '50%',
    pointerEvents: 'auto',
  },

  drop: {
    pointerEvents: 'auto',
    cursor: 'pointer',
  },

  ulBox: {
    listStyleType: 'none',
    overflow: 'auto',
    maxHeight: 200,
    padding: 0,
    direction: 'ltr',
    '& li[data-focus="true"]': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
  },

  rootTags: {
    display: 'flex',
    padding: '0px',
    flexWrap: 'wrap',
  },

  padding: {
    padding: 0,
  },

  paddingInput: {
    padding: '0px !important',
  },

  errorText: {
    position: 'absolute',
    right: 100,
    top: 10,
  },

  mouseOver: {
    cursor: 'pointer',
    pointerEvents: 'auto',
    margin: 0,
  },

  dropButton: {
    padding: 0,
    marginLeft: 'auto',
  },

  searchIcon: {
    marginRight: 4,
  },

  checkBox: {
    padding: '8px 0px',
  },

  arrowTooltip: {
    '&::before': {
      backgroundColor: theme.palette.common.white,
      border: '1px solid' + theme.palette.secondary.main,
    },
  },

  checkBoxOutline: {
    color: theme.palette.primary.appSecondaryTextColor,
    backgroundColor: theme.palette.primary.appSecondaryTextColor,
    fill: 'none !important',
    borderRadius: 4,
  },

  checkBoxOutlineBlank: {
    borderRadius: 4,
  },

  listboxAuto: {
    margin: '8px 0px !important',
    padding: '8px !important',
  },
}));

export default useStyles;
