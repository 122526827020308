import React, { Fragment, FC } from 'react';
import { useTranslate } from 'react-admin';
import compose from 'recompose/compose';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getIconClassName } from '@uifabric/styling';

import { getTodoTaskStepPatternInfo } from '../../helper/PatternMetaHelper';
import TodoAddNewTaskView from './TodoAddNewTaskView';
import { isEmpty } from '../../helper/data-helper';
import ConfirmDialogHOC from '../../container/ConfirmDialogHOC';
import TodoItemEditView from './TodoItemEditView';
import { CustomTheme } from '../../core/themeProvider';

interface TodoTaskStepProps {
  taskDetailId: number;
  resource: string;
  steps: Array<object>;
  translate: Function;
  onInsert: Function;
  onChange: Function;
  onDelete: Function;
  openConfirmDialog: Function;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  deleteButton: {
    color: theme.palette.primary.appPrimaryDisableIconColor,
    fontSize: '1em',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.appPrimaryIconColor,
    },
  },
}));

const TodoTaskStep: FC<TodoTaskStepProps> = props => {
  const {
    taskDetailId,
    resource,
    steps,
    onInsert,
    onChange,
    openConfirmDialog,
    onDelete,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();

  const pattern = getTodoTaskStepPatternInfo(resource);
  const { title, rowOrder, parentId, dndType, id } = pattern;

  const showConfirm = itemId => () => {
    openConfirmDialog({
      content: translate('todo.areYouSureYouWantToDeleteThisStep'),
      onConfirm: () => onDelete(itemId, resource),
      color: theme.palette.error.main,
    });
  };

  /**
   * update step details by dispatching an action without refresh view after update
   * @function handleUpdateStep
   * @param {object} data The data which need to be updated
   * @param {number} id The step id for PUT request
   * @returns {void}
   */
  const handleUpdateStep = (data: object, id: number): void =>
    onChange({ data, ref: null, itemResource: resource, itemId: id });

  const handleInsertStep = inputValue => {
    if (!isEmpty(inputValue)) {
      const body = {
        [title]: inputValue,
        [parentId]: taskDetailId,
        [rowOrder]: steps.length ? steps[steps.length - 1][rowOrder] + 1 : 1,
      };
      onInsert(resource, body);
    }
  };

  let lastItemRowOrder = 0;
  return (
    <Fragment>
      {/* @ts-ignore */}
      <DndProvider backend={HTML5Backend}>
        {steps &&
          steps.map(step => {
            const itemView = (
              <TodoItemEditView
                mode="step"
                key={step[id]}
                pattern={pattern}
                item={step}
                submit={handleUpdateStep}
                dndType={dndType}
                lastItemRowOrder={lastItemRowOrder}
                ActionElement={
                  <IconButton
                    className={classes.deleteButton}
                    onClick={showConfirm(step[id])}
                    data-test-task-step-delete
                  >
                    <i className={`${getIconClassName('cancel')}`}></i>
                  </IconButton>
                }
              />
            );
            lastItemRowOrder = step[rowOrder];
            return itemView;
          })}
      </DndProvider>
      <TodoAddNewTaskView
        placeholder={translate('todo.newStep')}
        submit={handleInsertStep}
      />
    </Fragment>
  );
};

export default compose(ConfirmDialogHOC)(TodoTaskStep);
