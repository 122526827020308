import { FC, memo, useState } from 'react';
import { useTranslate } from 'react-admin';

import { MessagesDataInterface } from '../..';
import {
  ChatActions,
  MessageInterface,
  OnDeleteMessageParams,
  OnFetchMoreMessagesParams,
  OnMoveToRepliedMessageParams,
} from '../../../..';
import { isEmpty } from '../../../../../../helper/data-helper';
import { actorDispatch } from '../../../../../../type/actor-setup';
import { ConfirmDialog } from '../../../../../confirm-dialog';
import { handleِDeleteMessage } from '../../../../chat-section.helper';
import { SelectedMessageDataInterface } from '../../new-message';
import { handleHighlightRepliedMessage } from './message-item.helper';
import { MessageItemInterface } from './message-item.type';
import MessageItemView from './message-item.view';

const MessageItemController: FC<MessageItemInterface> = memo(props => {
  const { chatActionsHandler, item, user, isSender = false, onScrollTop } = props;
  const translate = useTranslate();

  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState<boolean>(false);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  /**
   * @function handleContextMenu
   * @param {  React.MouseEvent } event
   * @returns { void }
   */
  const handleContextMenu = (event: React.MouseEvent): void => {
    event.preventDefault();
    if (!contextMenu) {
      setContextMenu({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    } else {
      setContextMenu(null);
    }
  };

  /**
   * @function handleCloseContextMenu
   * @returns { void }
   */
  const handleCloseContextMenu = (): void => {
    setContextMenu(null);
  };

  /**
   * to reply message
   * @function onReplyMessage
   * @param { MessageInterface } message
   * @returns { void }
   */
  const onReplyMessage = (message: MessageInterface): void => {
    if (!isEmpty(message))
      actorDispatch('selectedMessageData', {
        message,
        mode: 'reply',
      });
  };

  /**
   * @function onReplyClick
   * @returns { void }
   */
  const onReplyClick = (): void => {
    handleCloseContextMenu();
    onReplyMessage(item);
  };

  /**
   * @function successMoveToRepliedMessageCallback
   * @param { MessagesDataInterface } response
   * @returns { void }
   */
  const successMoveToRepliedMessageCallback = (
    response: MessagesDataInterface,
  ): void => {
    if (response) {
      actorDispatch('loading', { messagesLoading: false });
      actorDispatch('messagesData', { data: response?.data, hasMore: true });

      // to highlight the replied message
      const toBeHighlightedMessageId =
        response?.data?.[response?.data?.length - 1]?.chat_id;
      toBeHighlightedMessageId &&
        handleHighlightRepliedMessage(toBeHighlightedMessageId);

      if (response?.data?.length <= 10) {
        chatActionsHandler(ChatActions.onfetchMoreMessages, {
          IsUp: true,
        } as OnFetchMoreMessagesParams);
        return;
      } else {
        setTimeout(() => {
          onScrollTop();

          toBeHighlightedMessageId &&
            handleHighlightRepliedMessage(toBeHighlightedMessageId);
        }, 500);
      }
    }
  };

  /**
   * @function onMoveToRepliedMessage
   * @param { number } chatId
   * @returns { void }
   */
  const onMoveToRepliedMessage = (): void => {
    chatActionsHandler(ChatActions.onMoveToRepliedMessage, {
      chatId: item.replyofchat_id,
      successCallback: successMoveToRepliedMessageCallback,
    } as OnMoveToRepliedMessageParams);
  };

  /**
   * @function handleOpenDeleteConfirmDialog
   * @returns { void }
   */
  const handleOpenDeleteConfirmDialog = (): void => {
    handleCloseContextMenu();
    setIsDeleteConfirmDialogOpen(true);
  };

  /**
   * @function handleCloseDeleteConfirmDialog
   * @returns { void }
   */
  const handleCloseDeleteConfirmDialog = (): void => {
    setIsDeleteConfirmDialogOpen(false);
  };

  /**
   * @function successDeleteMessageCallback
   * @returns { void }
   */
  const successDeleteMessageCallback = (): void => {
    handleِDeleteMessage(item);
    handleCloseDeleteConfirmDialog();
  };

  /**
   * @function onConfirmDeleteMessage
   * @returns { void }
   */
  const onConfirmDeleteMessage = (): void => {
    chatActionsHandler(ChatActions.onDeleteMessage, {
      params: {
        OtherPersonInfo_ID: isSender ? item.topersoninfo_id : item.frompersoninfo_id,
        ChatID: item.chat_id,
      },
      successCallback: successDeleteMessageCallback,
    } as OnDeleteMessageParams);
  };

  /**
   * @function onEditClick
   * @returns { void }
   */
  const onEditClick = (): void => {
    actorDispatch('selectedMessageData', {
      message: item,
      mode: 'edit',
    } as SelectedMessageDataInterface);
    actorDispatch('chatText', item.chattext);
    handleCloseContextMenu();
  };

  return (
    <>
      <MessageItemView
        item={item}
        user={user}
        isSender={isSender}
        onReplyMessage={onReplyMessage}
        onReplyClick={onReplyClick}
        contextMenu={contextMenu}
        handleCloseContextMenu={handleCloseContextMenu}
        handleContextMenu={handleContextMenu}
        onMoveToRepliedMessage={onMoveToRepliedMessage}
        handleOpenDeleteConfirmDialog={handleOpenDeleteConfirmDialog}
        onEditClick={onEditClick}
      />
      {isDeleteConfirmDialogOpen && (
        <ConfirmDialog
          open={isDeleteConfirmDialogOpen}
          handleClose={handleCloseDeleteConfirmDialog}
          title={translate('chat.deleteConfirmMessage')}
          content=""
          onConfirm={onConfirmDeleteMessage}
        />
      )}
    </>
  );
});

export default MessageItemController;
