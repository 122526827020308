import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import { useTranslate } from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link, Icon } from '@material-ui/core';

import { closeImageDialogAction } from '../redux/showImage/action';
import useWidth from '../component/useWidth';
import { getBase64File } from '../helper/FileHelper';
import LoadingBox from '../component/LoadingBox';
import { isEmpty } from '../helper/data-helper';
import { showNotification } from '../helper/general-function-helper';

const useStyles = makeStyles(theme => ({
  imageContainer: {
    borderRadius: 5,
    padding: '10px 10px 5px',
  },

  downloadLink: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      textDecoration: 'none',
    },
  },

  icon: {
    margin: 7,
  },

  image: {
    width: '100%',
  },
}));

interface ShowImageDialogContainerType {
  closeImageDialog: Function;
  showImageIsOpen: boolean;
  showImageTitle: string;
  showImageUrl: string;
  showImageInfo: string;
  isGetFileMode: boolean;
  fullUrl: string;
  fileName: string;
}

const ShowImageDialogContainer = (props: ShowImageDialogContainerType) => {
  const {
    closeImageDialog,
    showImageIsOpen,
    showImageTitle,
    showImageUrl,
    showImageInfo,
    isGetFileMode,
    fullUrl,
    fileName,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();
  const width = useWidth();

  const [fileSrc, setFileSrc] = useState<string>('');
  const [isImage, setIsImage] = useState<boolean>(false);
  const [isLoding, setIsLoding] = useState<boolean>(true);

  /**
   * Get File from API and set data to specified variables
   * @function getFile
   * @return {void}
   */
  const getFile = async () => {
    try {
      const { src, isImage } = await getBase64File({ link: showImageUrl });
      setIsImage(isImage);
      setFileSrc(src);
      setIsLoding(false);
    } catch (error: any) {
      closeImageDialog();
      const errorMessage = error?.errorMessage ?? 'imageDialog.dataNotFound';
      showNotification(errorMessage, 'error');
    }
  };

  useEffect(() => {
    if (!showImageUrl) {
      return;
    }
    if (showImageIsOpen) {
      getFile();
    }
    return () => {
      setFileSrc('');
      setIsImage(false);
      setIsLoding(true);
    };
  }, [showImageUrl, fileName, showImageIsOpen]);

  useEffect(() => {
    if (!isEmpty(fullUrl)) {
      setIsLoding(false);
    }

    return () => {
      setFileSrc('');
      setIsImage(false);
      setIsLoding(true);
    };
  }, [fullUrl]);

  /**
   * Handle `closeImageDialog` function.
   * @function handleCloseDialog
   * @returns {void}
   */
  const handleCloseDialog = (): void => {
    closeImageDialog();
  };

  return (
    <>
      {isLoding && showImageIsOpen ? (
        <LoadingBox absolute />
      ) : (
        <Dialog
          open={showImageIsOpen}
          onClose={handleCloseDialog}
          maxWidth={width}
          aria-labelledby="show-image-dialog-title"
        >
          {isGetFileMode ? (
            <div className={classes.imageContainer}>
              {isImage ? (
                <img
                  data-test-popup-image
                  className={classes.image}
                  src={fileSrc}
                  alt={showImageTitle}
                />
              ) : (
                <Link
                  data-test-popup-link
                  href={fileSrc}
                  download={fileName || 'download'}
                  className={classes.downloadLink}
                >
                  {translate('form.download')}
                  <Icon className={classes.icon}>cloud_download</Icon>
                </Link>
              )}
            </div>
          ) : (
            <div className={classes.imageContainer}>
              <img
                className={classes.image}
                src={fullUrl ? fullUrl : showImageUrl}
                alt={showImageTitle}
              />
            </div>
          )}
          <div>
            <Typography>{showImageInfo}</Typography>
          </div>
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  showImageIsOpen: state.showImage.isOpen,
  showImageUrl: state.showImage.imageUrl,
  fullUrl: state.showImage.fullUrl,
  showImageTitle: state.showImage.imageTitle,
  showImageInfo: state.showImage.imageInfo,
  isGetFileMode: state.showImage.isGetFileMode,
  fileName: state.showImage.fileName,
});

const mapDispatchToProps = {
  closeImageDialog: closeImageDialogAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(ShowImageDialogContainer));
