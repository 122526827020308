import { USER_LOGOUT } from 'react-admin';
import { OPEN_DIALOG, CLOSE_DIALOG } from './constant';
import produce from 'immer';

const initialState = {
  resource: null,
  isOpen: false,
  isLoading: false,
  defaultData: null,
  dropdownId: null,
  source: null,
  mustRefresh: false,
  redirect: null,
  onCreate: null,
  disabledFieldList: null,
  parentInfo: null,
  dropdownMeta: null,
  additionalProps: {},
  relationMode: false,
  childFieldName: null,
  parentFieldName: null,
};

const myReducer = (
  state = initialState,
  {
    type,
    resource,
    data,
    dropdownId,
    source,
    mustRefresh,
    redirect,
    onCreate,
    disabledFieldList,
    parentInfo,
    dropdownMeta,
    additionalProps,
    relationMode,
    childFieldName,
    parentFieldName,
  },
) =>
  produce(state, draft => {
    switch (type) {
      case USER_LOGOUT:
      case CLOSE_DIALOG:
        draft.resource = null;
        draft.isOpen = false;
        draft.isLoading = false;
        draft.defaultData = null;
        draft.dropdownId = null;
        draft.source = null;
        draft.mustRefresh = false;
        draft.redirect = null;
        draft.onCreate = null;
        draft.disabledFieldList = null;
        draft.parentInfo = null;
        draft.dropdownMeta = null;
        draft.additionalProps = {};
        draft.relationMode = false;
        draft.childFieldName = null;
        draft.parentFieldName = null;
        break;

      case OPEN_DIALOG:
        draft.resource = resource;
        draft.isOpen = true;
        draft.isLoading = false;
        draft.defaultData = data;
        draft.dropdownId = dropdownId;
        draft.source = source;
        draft.mustRefresh = mustRefresh;
        draft.redirect = redirect;
        draft.onCreate = onCreate;
        draft.disabledFieldList = disabledFieldList;
        draft.parentInfo = parentInfo;
        draft.dropdownMeta = dropdownMeta;
        draft.additionalProps = additionalProps;
        draft.relationMode = relationMode;
        draft.childFieldName = childFieldName;
        draft.parentFieldName = parentFieldName;
        break;
    }
  });

export default myReducer;
