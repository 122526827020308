import React, { FC, useEffect, useMemo } from 'react';
import lodashGet from 'lodash/get';
import Button from '@material-ui/core/Button';
import { useUpdate, useTranslate, useLocale } from 'react-admin';
import ProcessIcon from '@material-ui/icons/CallMade';
import { refreshRelationAction as refreshRelation } from '../redux/relation/action';
import { useDispatch } from 'react-redux';

import { separateRecordAndRelationRecord } from '../helper/data-helper';
import { handleServerSideValidationErrors } from '../helper/validation-helper';
import { MetaData, MetaDataBase } from '../helper/Types';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../type/actor-setup';
import { prepareOverrideParams } from './form/form.helper';
import { showNotification } from '../helper/general-function-helper';

interface ChangeProcessButtonInterface {
  locale: string;
  line: { id: number; title: string; color?: string };
  record: { __processuniqueid: number };
  resource: string;
  customRefresh: Function;
  metaData: MetaData;
}

const ChangeProcessButton: FC<ChangeProcessButtonInterface> = props => {
  const { line, record, resource, customRefresh, metaData } = props;
  const translate = useTranslate();
  const locale = useLocale();
  const reduxDispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const currentResource = actorGetActionValue('resources')!.current;

  const formData =
    (actorGetActionValue('formData', [
      currentResource.value,
      currentResource.type,
    ]) as FormData | null) ?? {};

  //TODO: We have to use `record` from actor and other necessary form data, we don't need to any re-calculation
  const computedOverrideParams = useMemo(prepareOverrideParams, []);
  const { recordWithoutRelationData } = separateRecordAndRelationRecord(
    record,
    metaData,
    computedOverrideParams,
  );

  const [updateProcess] = useUpdate(
    resource,
    lodashGet(recordWithoutRelationData, 'id'),
    {
      // ...data,
      __processuniqueid: record.__processuniqueid,
      __lineid: line.id,
    },
    recordWithoutRelationData, //previousData
    {
      undoable: false,
      onSuccess: () => {
        showNotification('ra.notification.updated', 'info');
        customRefresh();
        reduxDispatch(refreshRelation());
        actorDispatch('refreshView', 'allRelations', { disableDebounce: true });
      },
      onFailure: error => {
        actorDispatch('loading', false, { path: 'processChangeLineButtons' });
        actorDispatch('refreshView', 'allRelations', { disableDebounce: true });
        if (typeof error === 'string') {
          showNotification(error, 'error');
        } else {
          const { data, requestId } = error;
          try {
            handleServerSideValidationErrors(
              metaData as MetaDataBase,
              [],
              { apiErrors: data, requestId },
              formData,
              showNotification,
              translate,
              locale,
            );
          } catch (error) {
            console.log('ChangeProcessButton.tsx:60 >> error in catch', {
              error,
            });
          }
        }

        customRefresh();
      },
    },
  );

  const handleClick = event => {
    actorDispatch('loading', true, { path: 'processChangeLineButtons' });
    updateProcess(event);
  };

  useEffect(() => {
    actorOnDispatch('loading', loadingInActor => {
      setIsLoading(loadingInActor.processChangeLineButtons);
    });
  }, []);

  return (
    <Button
      color="primary"
      onClick={handleClick}
      disabled={isLoading}
      data-test-process-name={line.title}
    >
      <ProcessIcon htmlColor={line?.color} />
      {lodashGet(line, ['translatedTitle', locale], line.title)}
    </Button>
  );
};

export default ChangeProcessButton;
