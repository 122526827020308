import React, { Fragment, useState, useEffect, FC } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { getIconClassName } from '@uifabric/styling';
import { useContextMenu } from 'react-contexify';

import { CustomTheme } from '../../core/themeProvider';
import TodoListContextMenu from './TodoListContextMenu';
import { ContextMenuItemType } from '../../helper/Types';
import { parseDate } from '../../helper/DateHelper';
import { isEmptyObject } from '../../helper/data-helper';

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  body: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flexGrow: 1,
  },

  icon: {
    margin: 12,
    fontSize: 20,
    color: theme.palette.primary.appPrimaryIconColor,
  },

  menuTitle: {
    textAlign: 'center',
    padding: '15px 30px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  value: {
    fontSize: 10,
    paddingTop: 4,
  },

  withValue: {
    color: theme.palette.secondary.main,
  },

  deleteFileIcon: {
    fontSize: 10,
    margin: 10,
    color: theme.palette.primary.appPrimaryIconColor,
    transition: 'all 200ms',
  },

  titleAndValue: {
    display: 'flex',
    flexDirection: 'column',
  },

  hasSubMenu: {
    borderTop: '1px solid #999',
  },

  subMenu: {
    backgroundColor: 'rgba(200, 200, 200, 0.5)',
    zIndex: 1350,
  },
}));

interface TodoTaskDateInterfaceProps {
  onItemClick: Function;
  fieldName?: string;
  onRemoveValue: Function;
  title: string;
  icon: string;
  items: ContextMenuItemType[];
  value: string;
}

const TodoTaskDate: FC<TodoTaskDateInterfaceProps> = props => {
  const {
    onItemClick,
    fieldName,
    onRemoveValue,
    title,
    icon,
    items,
    value: serverValue,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const [value, setValue] = useState(serverValue);

  const { show, hideAll } = useContextMenu({
    id: icon,
  });

  useEffect(() => {
    setValue(serverValue);
  }, [serverValue]);

  /**
   * show specified based on thier unique id (icon name)
   * @function handleClick
   * @param {Event} event
   * @returns {void}
   */
  const handleClick = event => {
    show(event, { id: icon });
  };

  /**
   * Update selected date in view (instead of send a GET request to get updated data)
   * @function handleSelectedValue
   * @param {object}  item selected date item
   * @returns {void}
   */
  const handleSelectedValue = (item: { value?: string; title?: string }): void => {
    if (isEmptyObject(item)) {
      setValue('');
    } else {
      if (item.value) {
        const date = parseDate(item.value, translate) || '';
        setValue(date);
        // Hide context menu on select custom date
        hideAll();
      } else if (item.title) {
        setValue(item.title);
      }
    }
  };

  /**
   * Update task with selected pre-defined date and update the view
   * @function handleItemClick
   * @param {object} item
   * @returns {void}
   */
  const handleItemClick = item => {
    onItemClick(item, fieldName);
    handleSelectedValue(item);
  };

  /**
   * Remove old selected date from task
   * @function handleRemoveValue
   * @returns {void}
   */
  const handleRemoveValue = () => {
    onRemoveValue(fieldName);
    handleSelectedValue({});
  };

  return (
    <Fragment>
      <div className={classes.container} data-test-task-date={fieldName}>
        <div
          aria-describedby={fieldName}
          onClick={handleClick}
          className={classes.body}
        >
          <i
            className={`${classes.icon} ${getIconClassName(icon)} ${
              value ? classes.withValue : ''
            }`}
          ></i>
          <div className={classes.titleAndValue}>
            <Typography
              variant="caption"
              color={value ? 'secondary' : 'textPrimary'}
            >
              {title}
            </Typography>
            {value && (
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.value}
                data-test-task-date-value={value}
              >
                {value}
              </Typography>
            )}
          </div>
        </div>
        {value && (
          <i
            onClick={handleRemoveValue}
            className={`${classes.deleteFileIcon} ${getIconClassName('cancel')}`}
          ></i>
        )}
      </div>
      <TodoListContextMenu
        contextMenuItems={items}
        uniqueKey={icon}
        handleItemClick={handleItemClick}
      />
    </Fragment>
  );
};

export default TodoTaskDate;
