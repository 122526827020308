import { FC, memo } from 'react';

import { SavedFabData } from '../../mail-section';
import { MailFabInterface } from './mail-fab.type';
import MailFabView from './mail-fab.view';

const MailFabController: FC<MailFabInterface> = memo(props => {
  const { handleFabOpen, handleFabRemove, mail } = props;

  /**
   * @function onClickOpen
   * @param {SavedFabData} mail
   * @returns {void}
   */
  const onClickOpen =
    (mail: SavedFabData) =>
    (event: React.MouseEvent<HTMLElement>): void => {
      handleFabOpen && handleFabOpen(mail);
    };

  /**
   * @function onClickRemove
   * @param {SavedFabData} mail
   * @param {MouseEvent<HTMLElement, MouseEvent>} event
   * @returns {void}
   */
  const onClickRemove =
    (mail: SavedFabData) =>
    (event: React.MouseEvent<HTMLElement>): void => {
      handleFabRemove && handleFabRemove(event, mail);
    };

  return (
    <MailFabView
      onClickOpen={onClickOpen}
      onClickRemove={onClickRemove}
      mail={mail}
    />
  );
});
export default MailFabController;
