import React, { useContext, useState } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton, Tooltip, withStyles, Icon } from '@material-ui/core';
import { actorDispatch } from '../type/actor-setup';
import { findOneAction as findDropdownDataAction } from '../redux/dropdown/action';
import { triggerDropdownFetchData } from '../component/dynamic-input/dropdown-input-old/dropdown-input.helper';
import SearchPopupDialogContainer from './SearchPopupDialogContainer';
import { NewMetaContext } from './NewMetaContext';

const styles = theme => ({
  icon: {
    marginRight: '2px',
    fontSize: '13px',
    color: 'hsl(0, 0%, 60%)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      margin: 5,
    },
  },

  IconButton: {
    padding: 7,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      padding: 14,
      margin: 0,
    },
  },
});

const SearchPopupButton = props => {
  const {
    classes,
    label,
    resource,
    source,
    dropdownId,
    dropdownMeta,
    findDropdownData,
    relationResource,
    relationSource,
    relationInfo,
    disabled,
    changeFormValue,
    addToRelationArray,
    formData,
    dropdownMultipleSelection,
    parentResource,
    dropBaseValue = '',
    dropdownData = [],
    fieldName,
    dropdownInPuzzleForm,
    buttonRef,
    buttonCustomStyle,
    formActionsHandler,
  } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { getMeta } = useContext(NewMetaContext);

  const findDropdownDataAndOpenDialog = () => {
    // get data for dropdown
    triggerDropdownFetchData({ ...props, resource: parentResource }, null, {
      dropId: dropdownId,
      fieldName,
      dropResource: parentResource,
    });

    actorDispatch('isSearchPopUpDialogOpen', true);
    // open dialog to show data
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    actorDispatch('isSearchPopUpDialogOpen', false);
    setIsDialogOpen(false);
  };

  const Button = React.forwardRef(({ style }, ref) =>
    label ? (
      <Tooltip title={label}>
        <IconButton
          color="primary"
          ref={ref}
          size="small"
          style={style}
          className={classes.IconButton}
          onClick={findDropdownDataAndOpenDialog}
          onTouchStart={findDropdownDataAndOpenDialog}
          disabled={disabled}
        >
          <Icon fontSize="small">search</Icon>
        </IconButton>
      </Tooltip>
    ) : (
      <Icon
        className={classes.icon}
        fontSize="small"
        ref={ref}
        style={style}
        onClick={findDropdownDataAndOpenDialog}
        onTouchStart={findDropdownDataAndOpenDialog}
        disabled={disabled}
        id="searchPopupButton"
      >
        search
      </Icon>
    ),
  );

  const metaData = getMeta(resource);
  const relationMetaData = relationResource ? getMeta(relationResource) : null;

  return (
    <>
      <Button ref={buttonRef} style={buttonCustomStyle} />
      {isDialogOpen && (
        <SearchPopupDialogContainer
          formActionsHandler={formActionsHandler}
          isOpen={isDialogOpen}
          resource={resource}
          dropdownId={dropdownId}
          dropdownMeta={dropdownMeta}
          source={source}
          record={formData}
          relationResource={relationResource}
          relationSource={relationSource}
          relationInfo={relationInfo}
          closeDialog={closeDialog}
          metaData={metaData}
          relationMetaData={relationMetaData}
          changeFormValue={changeFormValue}
          addToRelationArray={addToRelationArray}
          dropdownMultipleSelection={dropdownMultipleSelection}
          parentResource={parentResource}
          dropBaseValue={dropBaseValue}
          dropdownData={dropdownData}
          fieldName={fieldName}
          dropdownInPuzzleForm={dropdownInPuzzleForm}
        />
      )}
    </>
  );
};

SearchPopupButton.propTypes = {
  findDropdownData: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
  dropdownMeta: PropTypes.object.isRequired,
  dropdownId: PropTypes.number,
  source: PropTypes.string.isRequired,
  changeFormValue: PropTypes.func.isRequired,
  addToRelationArray: PropTypes.func,
  formActionsHandler: PropTypes.func,
  label: PropTypes.string,
  relationResource: PropTypes.string,
  relationSource: PropTypes.string,
  relationInfo: PropTypes.object,
  disabled: PropTypes.bool,
  dropdownMultipleSelection: PropTypes.bool,
  parentResource: PropTypes.string,
  buttonRef: PropTypes.object,
  buttonCustomStyle: PropTypes.object,
};

SearchPopupButton.defaultProps = {
  buttonRef: null,
  buttonCustomStyle: {},
};

const mapDispatchToProps = {
  findDropdownData: findDropdownDataAction,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(null, mapDispatchToProps),
)(SearchPopupButton);
