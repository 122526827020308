import { put, takeEvery } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import { push as redirectTo } from 'connected-react-router';
import lodashGet from 'lodash/get';

import { SEND_PHONE_NUMBER, SEND_CONFIRMATION } from './constant';
import {
  sendPhoneSuccessAction,
  sendPhoneFailureAction,
  sendConfirmationSuccessAction,
  sendConfirmationFailureAction,
} from './action';
import { getResponseMessage } from '../../core/dataProvider';
import authProvider, {
  AUTH_SEND_MOBILE,
  AUTH_SEND_CONFIRMATION,
} from '../../core/authProvider';

function* sendPhone({ mobile }) {
  try {
    const { data } = yield authProvider(AUTH_SEND_MOBILE, { mobile });

    if (data.status) {
      yield put(sendPhoneSuccessAction({ mobile }));
    } else {
      yield put(
        sendPhoneFailureAction({
          error: getResponseMessage(data),
        }),
      );

      yield put(showNotification(data.userMessage, 'error'));
    }
  } catch (error) {
    yield put(
      sendPhoneFailureAction({
        error: getResponseMessage(error),
      }),
    );
  }
}

function* sendConfirmation({ mobile, confirmation }) {
  try {
    const { data } = yield authProvider(AUTH_SEND_CONFIRMATION, {
      mobile,
      confirmation,
    });
    yield put(sendConfirmationSuccessAction({ mobile }));

    if (data.userMessage && data.messageType !== 'ignore') {
      yield put(showNotification(data.userMessage, data.messageType));
    }

    yield put(redirectTo('/'));
  } catch (error) {
    const userMessage = lodashGet(
      error,
      ['response', 'data', 'userMessage'],
      error,
    ).toString();
    yield put(showNotification(userMessage, 'error'));
    yield put(
      sendConfirmationFailureAction({
        error: getResponseMessage(error),
      }),
    );
  }
}

export default function* dropdownSaga() {
  yield takeEvery(SEND_PHONE_NUMBER, sendPhone);
  yield takeEvery(SEND_CONFIRMATION, sendConfirmation);
}
