import { FC, memo } from 'react';

import { NavigationContainerInterface } from './navigation-container.type';
import NavigationContainerView from './navigation-container.view';

const NavigationContainerController: FC<NavigationContainerInterface> = memo(
  props => {
    const { routesConfig, baseUrl } = props;

    return <NavigationContainerView routesConfig={routesConfig} baseUrl={baseUrl} />;
  },
);

export default NavigationContainerController;
