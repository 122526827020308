import { put, takeEvery } from 'redux-saga/effects';
import lodashGet from 'lodash/get';

import { FIND_ONE } from './constant';
import { findOneSuccessAction, findOneFailedAction } from './action';
import dataProvider, { GET_META } from '../../core/dataProvider';

const loadingList = {};

function* findOne({ resource, params }) {
  try {
    if (loadingList[resource]) {
      return;
    }

    // add to list to prevent multiple calls to same place
    loadingList[resource] = true;

    const data = yield dataProvider(GET_META, resource, params);

    yield put(findOneSuccessAction({ resource, data }));
  } catch (catchError) {
    const error = lodashGet(
      catchError,
      ['response', 'data', 'userMessage'],
      catchError.toString(),
    );
    yield put(findOneFailedAction({ resource, error }));
  } finally {
    delete loadingList[resource];
  }
}

export default function* dropdownSaga() {
  yield takeEvery(FIND_ONE, findOne);
}
