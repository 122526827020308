import React, { useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth/withWidth';
import { Typography, IconButton } from '@material-ui/core';
import { getIconClassName } from '@uifabric/styling';
import momentJalaali from 'moment-jalaali';
import { translate } from 'react-admin';

import RemindTomorrowIcon from '../../icon/RemindTomorrowIcon';
import RemindNextWeekIcon from '../../icon/RemindNextWeekIcon';
import LaterTodayIcon from '../../icon/LaterTodayIcon';
import TomorrowIcon from '../../icon/TomorrowIcon';
import NextWeekIcon from '../../icon/NextWeekIcon';
import TodayIcon from '../../icon/TodayIcon';
import WeekDaysIcon from '../../icon/WeekDaysIcon';

import { closeDialogAction } from '../../redux/todoList/action';
import TodoTaskItemEdit from './TodoTaskItemEdit';
import TodoTaskDate from './TodoTaskDate';
import ConfirmDialogHOC from '../../container/ConfirmDialogHOC';
import TodoTaskAddToMyDay from './TodoTaskAddToMyDay';
import TodoTaskAddNote from './TodoTaskAddNote';
import TodoTaskRepeatDate from './TodoTaskRepeatDate';
import TodoTaskAddFile from './TodoTaskAddFile';
import TodoTaskFileContainer from './TodoTaskFileContainer';
import { getFileInfo } from '../../helper/MetaHelper';
import TodoTaskAssign from './TodoTaskAssign';
import { CustomTheme } from '../../core/themeProvider';
import TodoTaskStep from './TodoTaskStep';
import { createDateWithType } from '../../helper/DateHelper';
import { jalaliDateTimeFormat } from '../../helper/CalendarMetaHelper';

const TODO_TASK_DATE_RECURRING = 'TodoTaskDateRecurring';
const TODO_TASK_ASSIGN = 'TodoTaskAssign';

type handleTodoTaskDateItemClickType = (
  item: { submenu?: boolean; dataTestAttribute: string; value: string },
  name: string,
) => void;

const useStyles = makeStyles((theme: CustomTheme) => ({
  dialogContentContainer: {
    backgroundColor: theme.palette.primary.appPrimaryBackgroundColor,
    margin: 0,
    padding: '0 !important',
    overFlowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  dialogContentSection: {
    margin: '10px 20px',
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    borderRadius: theme.shape.borderRadius,
  },

  dialogContentFooter: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
    bottom: 0,
    padding: '5px',
    backgroundColor: '#eef1f5',
  },

  deleteTaskButton: {
    padding: 7,
  },

  deleteTaskIcon: {
    fontSize: 17,
  },

  closeSidebarButton: {
    padding: 7,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  closeSidebarIcon: {
    fontSize: 17,
  },

  createdTaskDate: {
    flexGrow: 1,
    textAlign: 'center',
  },
  addNoteContent: {
    flex: 2,
  },
}));

const getEmptyTodoTaskDateIsOpenList = () => {
  return {
    [TODO_TASK_DATE_RECURRING]: false,
    [TODO_TASK_ASSIGN]: false,
  };
};

const TodoTaskItemSidebarView = props => {
  const {
    dataTask,
    resource,
    format,
    taskId,
    handleUpdateTask,
    handleParseDateTask,
    metaData,
    translate,
    handleRefreshDataTask,
    openConfirmDialog,
    handleDeleteTask,
    handleInsertTask,
    selectedTodoList,
    listResource,
    taskDetailsPattern,
    steps,
    closeDialog,
    isTaskListShared,
    handleDeleteStep,
    detailResource,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const [TodoTaskDateIsOpenList, setTodoTaskDateIsOpenList] = useState(
    getEmptyTodoTaskDateIsOpenList(),
  );

  const {
    createDate,
    remindMeDate,
    dueDate,
    repeatType,
    repeatEveryX,
    assignUser,
    stepsResource,
    deleteTaskServiceResource,
  } = taskDetailsPattern;

  const handleOpenSelect = name => event => {
    const temp = getEmptyTodoTaskDateIsOpenList();
    temp[name] = !TodoTaskDateIsOpenList[name];
    setTodoTaskDateIsOpenList(temp);
  };

  const emptyIsOpenListWhenClicked = () => event => {
    setTodoTaskDateIsOpenList(getEmptyTodoTaskDateIsOpenList());
  };

  const showConfirm = event => {
    emptyIsOpenListWhenClicked()(event);

    openConfirmDialog({
      content: translate('todo.areYouSureYouWantToDeleteThisTask'),
      onConfirm: () => handleDeleteTask(deleteTaskServiceResource),
      color: theme.palette.error.main,
    });
  };

  /**
   *
   * @function handleTodoTaskDateItemClick
   * @param {object} item
   * @param {string} name
   * @returns {void}
   */
  const handleTodoTaskDateItemClick: handleTodoTaskDateItemClickType = (
    item,
    name,
  ) => {
    const value = !item.submenu
      ? createDateWithType(item.dataTestAttribute)
      : item.value;

    if (dataTask[name] !== value) {
      handleUpdateTask()({ data: { [name]: value } });
    }
  };

  /**
   * @function handleTodoTaskDateRemoveValue
   * @param {string} name field name
   * @returns {void}
   */
  const handleTodoTaskDateRemoveValue = (name: string): void => {
    handleUpdateTask()({ data: { [name]: null } });
  };

  /**
   * @function handleRepeatDateRemoveValue
   * @returns {void}
   */
  const handleRepeatDateRemoveValue = (): void => {
    handleUpdateTask()({ data: { [repeatEveryX]: null, [repeatType]: null } });
  };
  /**
   * set repeat date
   * @function handleRepeatDateItemClick
   * @param {object} item task
   * @returns {void}
   */
  const handleRepeatDateItemClick = (item: object): void => {
    if (item[repeatEveryX]) {
      handleUpdateTask()({
        data: {
          [repeatEveryX]: item[repeatEveryX],
          [repeatType]: item[repeatType],
        },
      });
    }
  };

  /**
   * Change task asignee
   * @function handleTaskAssignClick
   * @param id user id
   * @returns {void}
   */
  const handleTaskAssignClick = (id: number): void => {
    handleUpdateTask()({ data: { [assignUser]: id }, shouldRefresh: true });
  };

  /**
   * unassinge task and refresh view
   * @function handleTaskAssignRemoveValue
   * @returns {void}
   */
  const handleTaskAssignRemoveValue = (): void => {
    handleUpdateTask()({ data: { [assignUser]: null }, shouldRefresh: true });
  };

  if (!resource) {
    return <div />;
  }

  const fileRelation = getFileInfo(metaData);

  return (
    <div className={classes.dialogContentContainer} data-test-task-sidebar>
      <div className={classes.dialogContentSection}>
        <TodoTaskItemEdit
          taskId={taskId}
          resource={resource}
          detailResource={detailResource}
          onClick={emptyIsOpenListWhenClicked}
          onChange={handleUpdateTask()}
          dataTask={dataTask}
        />
        <TodoTaskStep
          taskDetailId={taskId}
          resource={stepsResource}
          steps={steps}
          refreshTaskData={handleRefreshDataTask}
          onChange={handleUpdateTask()}
          onDelete={handleDeleteStep}
          onInsert={handleInsertTask}
        />
      </div>
      <div className={classes.dialogContentSection}>
        <TodoTaskAddToMyDay
          taskId={taskId}
          resource={resource}
          onClick={emptyIsOpenListWhenClicked}
          onChange={handleUpdateTask()}
        />
      </div>
      <div className={classes.dialogContentSection}>
        <TodoTaskDate
          icon="AlarmClock"
          title={translate('todo.remindMe')}
          fieldName={remindMeDate}
          items={[
            {
              dataTestAttribute: 'today',
              title: translate('todo.today'),
              icon: <LaterTodayIcon />,
            },
            {
              dataTestAttribute: 'tomorrow',
              title: translate('todo.tomorrow'),
              icon: <RemindTomorrowIcon />,
            },
            {
              dataTestAttribute: 'nextWeek',
              title: translate('todo.nextWeek'),
              icon: <RemindNextWeekIcon />,
            },
            {
              dataTestAttribute: 'customDate',
              title: translate('todo.customDateTime'),
              icon: <WeekDaysIcon />,
              submenu: true,
            },
          ]}
          onItemClick={handleTodoTaskDateItemClick}
          value={handleParseDateTask(dataTask[remindMeDate])}
          onRemoveValue={handleTodoTaskDateRemoveValue}
        />
        <TodoTaskDate
          icon="Calendar"
          title={translate('todo.dueDate')}
          fieldName={dueDate}
          items={[
            {
              dataTestAttribute: 'today',
              title: translate('todo.today'),
              icon: <TodayIcon />,
            },
            {
              dataTestAttribute: 'tomorrow',
              title: translate('todo.tomorrow'),
              icon: <TomorrowIcon />,
            },
            {
              dataTestAttribute: 'nextWeek',
              title: translate('todo.nextWeek'),
              icon: <NextWeekIcon />,
            },
            {
              dataTestAttribute: 'customDate',
              title: translate('todo.customDate'),
              icon: <WeekDaysIcon />,
              submenu: true,
            },
          ]}
          onItemClick={handleTodoTaskDateItemClick}
          value={handleParseDateTask(dataTask[dueDate])}
          onRemoveValue={handleTodoTaskDateRemoveValue}
        />
        <TodoTaskRepeatDate
          onClick={handleOpenSelect(TODO_TASK_DATE_RECURRING)}
          resource={resource}
          onItemClick={handleRepeatDateItemClick}
          onRemoveValue={handleRepeatDateRemoveValue}
          taskId={taskId}
        />
      </div>
      {isTaskListShared && (
        <div className={classes.dialogContentSection}>
          <TodoTaskAssign
            resource={resource}
            isOpen={TodoTaskDateIsOpenList[TODO_TASK_ASSIGN]}
            onClick={handleOpenSelect(TODO_TASK_ASSIGN)}
            listResource={listResource}
            listItem={selectedTodoList}
            onItemClick={handleTaskAssignClick}
            value={dataTask[assignUser]}
            onRemoveValue={handleTaskAssignRemoveValue}
          />
        </div>
      )}

      {fileRelation && (
        <div className={classes.dialogContentSection}>
          <TodoTaskAddFile
            taskId={taskId}
            resource={detailResource}
            onClick={emptyIsOpenListWhenClicked}
            onChange={handleRefreshDataTask}
          />
          <TodoTaskFileContainer
            taskId={taskId}
            resource={detailResource}
            task={dataTask}
            fileMeta={fileRelation}
            onChange={handleRefreshDataTask}
          />
        </div>
      )}
      <div className={`${classes.dialogContentSection} ${classes.addNoteContent}`}>
        <TodoTaskAddNote
          taskId={taskId}
          resource={resource}
          onChange={handleUpdateTask()}
        />
      </div>
      <div className={classes.dialogContentFooter}>
        <IconButton
          className={classes.closeSidebarButton}
          onClick={closeDialog}
          data-test-task-close
        >
          <i
            className={`${classes.closeSidebarIcon} ${getIconClassName(
              'DoubleChevronLeft',
            )}`}
          ></i>
        </IconButton>
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.createdTaskDate}
        >
          {dataTask[createDate] &&
            momentJalaali(dataTask[createDate]).format(
              format || jalaliDateTimeFormat,
            )}
        </Typography>
        <IconButton
          className={classes.deleteTaskButton}
          onClick={showConfirm}
          id="deleteButton"
        >
          <i
            className={`${classes.deleteTaskIcon} ${getIconClassName('delete')}`}
          ></i>
        </IconButton>
      </div>
    </div>
  );
};

TodoTaskItemSidebarView.propTypes = {
  handleUpdateTask: PropTypes.func.isRequired,
  handleRefreshDataTask: PropTypes.func,
  dataTask: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  detailResource: PropTypes.string.isRequired,
  handleCreateDateTask: PropTypes.func,
  handleParseDateTask: PropTypes.func,
  handleDeleteTask: PropTypes.func,
  handleInsertTask: PropTypes.func,
  locale: PropTypes.string,
  selectedTodoList: PropTypes.object,
  listResource: PropTypes.string,
};

const mapDispatchToProps = {
  closeDialog: closeDialogAction,
};

export default compose(
  ConfirmDialogHOC,
  translate,
  withWidth(),
  connect(null, mapDispatchToProps),
)(TodoTaskItemSidebarView);
