import { useEffect, useState } from 'react';
import { useLocale } from 'react-admin';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { clone, isEmpty } from '../helper/data-helper';
import { FormController } from '../component/form';
import { CustomForm } from '../component/form/form.view';
import { FormWithTabs } from '../component/form/form-with-tabs';
import FormActionButtonList from '../component/FormActionButtonList';
import { getParamFromUrl } from '../helper/UrlHelper';
import LoadingBox from '../component/loading-box';
import {
  actorGetActionValue,
  actorOnDispatch,
  RecordKeyMode,
  waitForAction,
} from '../type/actor-setup';
import HeaderTitleController from '../component/header-title/header-title.controller';
import ShowTitle from '../component/ShowTitle';
import { getTranslatedName } from '../helper/MetaHelper';

import type { GeneralMetaData } from '../helper/Types';

const useStyles = makeStyles(() => ({
  createEditRecordPageContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: 1,
  },

  viewRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  viewMain: {
    height: '100%',
    margin: 0,
  },

  viewCard: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    boxShadow: 'none',
  },

  iconButton: {
    padding: 5,
    margin: '0 6px',
  },
}));

const CreateEditRecordPage = props => {
  const { ...rest } = props;
  const { current: currentResource } = actorGetActionValue('resources')!;
  const history = useHistory();
  const urlInfo = actorGetActionValue('urlInfo');

  const [loading, setLoading] = useState(true);
  const locale = useLocale();
  const classes = useStyles();

  useEffect(() => {
    actorOnDispatch(
      'showLoading',
      async showLoading => {
        await waitForAction('showSettingsLoading');
        const showSettingsLoading = actorGetActionValue('showSettingsLoading');
        if (showLoading === false && showSettingsLoading === false) {
          setLoading(false);
        } else {
          setLoading(true);
        }
      },
      {
        preserve: false,
      },
    );

    actorOnDispatch('showSettingsLoading', async showSettingsLoading => {
      await waitForAction('showLoading');
      const showLoading = actorGetActionValue('showLoading');
      if (showLoading === false && showSettingsLoading === false) {
        setLoading(false);
      }
    });
  }, []);

  if (loading) {
    return <LoadingBox />;
  }

  const isReport = currentResource.value.indexOf('report') === 0;
  const redirect = getParamFromUrl(location.search, 'redirect') || 'show';

  const metaData = clone(
    actorGetActionValue('metaData', currentResource.value),
  ) as unknown as GeneralMetaData;

  const hashUrl = urlInfo?.location?.hash ?? window.location.href;

  if (
    Array.isArray(metaData?.processes) &&
    metaData.processes.length > 0 &&
    hashUrl.includes('/create') && // in create mode
    (!hashUrl.includes('__processuniqueid') ||
      !hashUrl.includes('positionid') ||
      !hashUrl.includes('stateid'))
  ) {
    history.replace(`/${currentResource.value}`);
  }

  const record = clone(
    actorGetActionValue('record', [
      currentResource.value,
      currentResource.type,
      RecordKeyMode.FULL,
    ]),
  )!;

  return (
    <>
      <div className={classes.createEditRecordPageContainer}>
        <HeaderTitleController
          title={<ShowTitle metaData={metaData} record={record} />}
          record={record}
          defaultTitle={getTranslatedName(metaData, locale)}
        />
        <FormController
          {...rest}
          formName="createEditRecordPage"
          classes={{
            root: classes.viewRoot,
            main: classes.viewMain,
            card: classes.viewCard,
          }}
          actions={null}
          hasList={true}
          hasCreate={!isReport}
          hasEdit={!isReport}
          hasShow={!isReport}
        >
          <CustomForm
            Toolbar={
              <FormActionButtonList
                submitOnEnter={false}
                redirect={redirect}
                locale={locale}
                isCreateMode={isEmpty(urlInfo?.params.id)}
                isDefaultMode={false}
                formName="createEditRecordPage"
                {...rest}
              />
            }
          >
            <FormWithTabs />
          </CustomForm>
        </FormController>
      </div>
    </>
  );
};

export default CreateEditRecordPage;
