import { isEmpty } from '../../../../helper/data-helper';

/**
 * @function markWordInString
 * @param { string } string
 * @param { string } word
 * @param { number} elementId
 * @returns { void }
 */
export const markWordInString = (
  string: string,
  word: string,
  elementId: number,
): void => {
  if (isEmpty(string) && isEmpty(word)) return;

  const newText = string.replace(
    new RegExp(word, 'g'), // search for all instances,
    `<mark style="background-color: yellow;">${word}</mark>`,
  );

  const element = document.getElementById(`chatId-${elementId}`);

  if (element != null) {
    element.innerHTML = newText;
  }
};
