import React, { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { StringselectInputViewInterface } from './stringselect-input.type';
import { useStyles } from './stringselect-input.style';
import { Chip, TextField, Tooltip, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import InputBase from '../../input-base';

const StringselectInputView = (
  props: StringselectInputViewInterface,
): ReactElement => {
  const {
    visibleClass,
    required,
    wrapperRef,
    label,
    hint,
    source,
    loading,
    disabled,
    preparedOptions,
    renderOptions,
    inputValue,
    handleChange,
    onFocus,
    onBlur,
    inputMessage,
    getRef,
    refTags,
    limitTagCount,
    customTestAttribute,
    multiple,
    handleChangeInput,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <InputBase
      className={visibleClass}
      label={label}
      hint={hint}
      inputMessage={inputMessage}
      required={required}
      data-test-field-name={source}
    >
      <div ref={refTags} className={classes.rootRef}>
        <div
          ref={wrapperRef}
          className={classes.rootDropdown}
          data-test-field-name={source}
        >
          <Autocomplete
            multiple={multiple}
            disableCloseOnSelect={multiple}
            classes={{
              root: classes.rootAutocomplete,
              paper: classes.paper,
              option: classes.option,
              popperDisablePortal: classes.popperDisablePortal,
              listbox: classes.listboxAuto,
            }}
            limitTags={limitTagCount}
            disabled={disabled}
            loading={loading}
            options={renderOptions}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            loadingText={translate('dropdown.loading')}
            noOptionsText={translate('dropdown.noOptionsMessage')}
            filterOptions={options => options}
            getOptionLabel={options => options.text}
            value={preparedOptions}
            defaultValue={preparedOptions}
            getOptionSelected={(option, selectedItem) =>
              option.value == selectedItem.value
            }
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={translate('dropdown.placeholder')}
                  value={inputValue}
                  InputProps={{
                    ...params.InputProps,
                    onChange: handleChangeInput,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    ...customTestAttribute,
                    className:
                      classes.paddingInput +
                      ' ' +
                      (params.inputProps as Record<string, string>)?.className,
                  }}
                />
              );
            }}
            renderTags={(value, props) =>
              value.map((item, index) => (
                <Tooltip
                  {...props({ index })}
                  classes={{
                    tooltip: classes.rootTooltip,
                    arrow: classes.arrowTooltip,
                  }}
                  arrow
                  placement="bottom-start"
                  title={item.text}
                >
                  <Chip
                    disabled={disabled}
                    {...props({ index })}
                    className={classes.button}
                    label={
                      <Typography
                        component="span"
                        noWrap
                        className={classes.spanItemButton}
                      >
                        {item.text}
                      </Typography>
                    }
                  />
                </Tooltip>
              ))
            }
          />
        </div>
      </div>
    </InputBase>
  );
};

export default StringselectInputView;
