import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { Box } from '@material-ui/core';

import { UploadFilesFormViewInterface } from './upload-files-form.type';
import { useStyles } from './upload-files-form.style';
import { NewMessage } from '../chat-content/messages-screen/new-message';
import { AppList } from '../../app-list';
import { UploadedFile } from '..';
import { FileCard } from './file-card';

const UploadFilesFormView = (props: UploadFilesFormViewInterface): ReactElement => {
  const { chatActionsHandler, uploadedFiles, handleSendFiles } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box className={classes.root}>
      <Box component="span" fontWeight="bold" fontSize={14}>
        {translate('chat.selectedFiles', {
          count: uploadedFiles.length,
        })}
      </Box>

      <AppList
        className={classes.filesArea}
        data={uploadedFiles}
        renderRow={item => <FileCard file={item as UploadedFile} />}
      />

      <Box>
        <NewMessage
          chatActionsHandler={chatActionsHandler}
          customSendAction={handleSendFiles}
        />
      </Box>
    </Box>
  );
};

export default UploadFilesFormView;
