import { actorGetActionValue } from '../type/actor-setup';

/**
 * it will compare current session id in url with session id in actor store
 * and return the equality compare result as reverse
 * @function shouldUpdateProfile
 * @param {string} sessionIdInUrl
 * @returns {boolean}
 */
export const shouldUpdateProfile = (sessionIdInUrl: string): boolean => {
  const sessionIdInActor = actorGetActionValue('currentSessionIdInUrl');

  return sessionIdInUrl == sessionIdInActor ? false : true;
};
