import { ReactElement } from 'react';
import lodashGet from 'lodash/get';
import { FileManager } from '../component/file-manager';
import { actorGetActionValue } from '../type/actor-setup';

const FileManagerPage = (): ReactElement => {
  const globalParameters = actorGetActionValue('globalParameters');
  const fileUploadLimitMB = lodashGet(globalParameters, 'fileUploadLimitMB', null);
  return <FileManager fileUploadLimitMB={fileUploadLimitMB} />;
};

export default FileManagerPage;
