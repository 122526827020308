import { Dialog } from '@material-ui/core';
import { ReactElement } from 'react';
import { DialogContainerProps } from '.';

export const DialogContainer = (props: DialogContainerProps): ReactElement => {
  const { children, open, fullscreen, onClose, onKill } = props;

  return (
    <Dialog
      fullScreen={fullscreen}
      open={open}
      onClose={onClose}
      onExited={onKill}
      data-style-dialog="dialog"
    >
      {children}
    </Dialog>
  );
};
