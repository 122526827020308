import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },

    '.MuiInputBase-root': {
      width: '100%',
    },
  },

  error: {
    '& + p': {
      position: 'absolute',
      right: 0,
    },
  },
}));
