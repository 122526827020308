import { FC, memo } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { ChangeFormValueParams, FormActions, OnBlurParams } from '../../form';
import Editor from '@SamianSoft/editor/build/ckeditor';
import { RichTextEditorInputInterface } from './rich-text-editor-input.type';
import RichTextEditorInputView from './rich-text-editor-input.view';
import { isEmpty } from '../../../helper/data-helper';
import {
  getValue,
  RICH_TEXT_EDITOR_DEFAULT_FONT,
} from '../../../core/configProvider';
import UploadAdapterPlugin from './uploader-adapter-plugin';
const RichTextEditorInputController: FC<RichTextEditorInputInterface> = memo(
  props => {
    const {
      value,
      formActionsHandler,
      inputMessage,
      field,
      label,
      disabled,
      visibleClass,
      inputContainerClass,
      resource,
    } = props;

    const { name, required } = field;
    const locale = useLocale();
    const translate = useTranslate();

    const defaultFont = getValue(RICH_TEXT_EDITOR_DEFAULT_FONT) ?? 'IranSans';
    const staticVariables = [
      '{{عنوان_حساب}}',
      '{{تاريخ_نامه}}',
      '{{شماره_نامه}}',
      '{{شخص_گيرنده}}',
      '{{سازمان_گيرنده}}',
      '{{رونوشت}}',
      '{{نام_امضاكننده}}',
      '{{سمت_امضاكننده}}',
      '{{امضا}}',
      '{{مدير_عامل}}',
    ];

    const editorConfiguration = {
      extraPlugins: [UploadAdapterPlugin(`${resource}/multifilestream/attachfiles`)],
      typing: {
        transformations: {
          remove: [
            'mathematical', // Remove all transformations from the 'mathematical' group.
          ],
        },
      },
      toolbar: {
        items: [
          'heading',
          '|',
          'fontfamily',
          'fontsize',
          '|',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          '|',
          'alignment',
          '|',
          'outdent',
          'indent',
          '|',
          'bulletedList',
          'numberedList',
          'todoList',
          'highlight',
          '|',
          'horizontalLine',
          'pageBreak',
          'link',
          'blockQuote',
          'insertTable',
          'insertImage',
          '|',
          'undo',
          'redo',
          'findAndReplace',
          'sourceEditing',
          'htmlEmbed',
          '|',
          'removeFormat',
          '|',
          'placeholder',
        ],
        shouldNotGroupWhenFull: true,
      },
      language: locale ? locale : 'fa',
      fontFamily: {
        options: [
          'IranSans',
          'BNAZANIN',
          'BMITRA',
          'BLOTUS',
          'BTitr',
          'BYagut',
          'Ubuntu, Arial, sans-serif',
          'Ubuntu Mono, Courier New, Courier, monospace',
        ],
      },

      fontSize: {
        options: [9, 11, 13, 'default', 17, 19, 21, 24, 30, 36],
      },
      image: {
        toolbar: [
          'imageTextAlternative',
          'toggleImageCaption',
          '|',
          'imageStyle:inline',
          'imageStyle:side',
          'imageStyle:alignLeft',
          'imageStyle:alignCenter',
          'imageStyle:alignRight',
        ],
      },
      table: {
        contentToolbar: [
          'toggleTableCaption',
          '|',
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableProperties',
          'tableCellProperties',
        ],
      },
      placeholderConfig: {
        label: translate('editor.staticVariables'),
        types: staticVariables,
      },
      highlight: {
        options: [
          {
            model: 'greenMarker',
            class: 'marker-green',
            title: 'Green marker',
            color: 'rgb(25, 156, 25)',
            type: 'marker',
          },
          {
            model: 'yellowMarker',
            class: 'marker-yellow',
            title: 'Yellow marker',
            color: '#cac407',
            type: 'marker',
          },
          {
            model: 'redPen',
            class: 'pen-red',
            title: 'Red pen',
            color: 'hsl(343, 82%, 58%)',
            type: 'pen',
          },
        ],
      },
      htmlEmbed: {
        showPreviews: true,
        sanitizeHtml: inputHtml => {
          // Strip unsafe elements and attributes, e.g.:
          // the `<script>` elements and `on*` attributes.
          const outputHtml = inputHtml;

          return {
            html: outputHtml,
            // true or false depending on whether the sanitizer stripped anything.
            hasChanged: true,
          };
        },
      },
      placeholder: translate('editor.placeholder'),
    };

    /**
     * Handle Blur event
     * @function handleBlur
     * @returns {void} void
     */
    const handleBlur = (): void => {
      formActionsHandler(FormActions.InputBlur, {
        fieldName: name,
        value,
      } as OnBlurParams);
    };

    /**
     * Handle Change event
     * @function handleChange
     * @param {Event} event
     * @param { Editor } editor
     * @returns {void}
     */
    const handleChange = (event: Event, editor: Editor): void => {
      const data = editor.getData();
      if (isEmpty(data)) {
        editor.execute('fontFamily', { value: defaultFont });
      }
      formActionsHandler(FormActions.InputChange, {
        fieldName: name,
        value: data,
      } as ChangeFormValueParams);
    };

    return (
      <RichTextEditorInputView
        value={value}
        label={label}
        inputMessage={inputMessage}
        required={required}
        disabled={disabled}
        visibleClass={visibleClass}
        inputContainerClass={inputContainerClass}
        handleChange={handleChange}
        handleBlur={handleBlur}
        config={editorConfiguration}
        field={field}
        defaultFont={defaultFont}
      />
    );
  },
);

export default RichTextEditorInputController;
