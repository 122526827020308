import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import {
  getValue,
  API_URL,
  API_NAME,
  API_VERSION,
  USER_COMPANY_ID,
} from '../core/configProvider';
import { withStyles } from '@material-ui/core/styles';
import avatarPlaceholder from '../images/avatar.jpg';
import classNames from 'classnames';

const styles = {
  avatar: {
    width: 30,
    height: 30,
    backgroundImage: `url(${avatarPlaceholder})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

  bigAvatar: {
    width: 48,
    height: 48,
  },
};

class ProfileAvatar extends PureComponent {
  getUrl() {
    const { userId, dummyCounter = 0 } = this.props;
    const apiUrl = getValue(API_URL);
    const apiName = getValue(API_NAME);
    const apiVersion = getValue(API_VERSION);
    const companyId = getValue(USER_COMPANY_ID);

    // https://crmapi.samiansoft.com/v2/account/crmweb/profile/image?userId=8296&companyId=1
    return `${apiUrl}/${apiVersion}/account/${apiName}/profile/image?userId=${userId}&companyId=${companyId}&dummyCounter=${dummyCounter}`;
  }

  handleClick = () => {
    const { onClick } = this.props;
    if (!onClick) {
      return;
    }

    onClick(this.getUrl());
  };

  render() {
    const { alt, classes, bigAvatar } = this.props;

    return (
      <Avatar
        data-test="avatarChange"
        className={classNames(classes.avatar, bigAvatar && classes.bigAvatar)}
        onClick={this.handleClick}
        alt={alt}
        src={this.getUrl()}
      />
    );
  }
}

ProfileAvatar.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  bigAvatar: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(ProfileAvatar);
