import { FC, memo, useCallback, useEffect } from 'react';
import { InputRefContent } from '..';
import { isEmptyObject } from '../../../helper/data-helper';
import { getFormDefaultValue } from '../../../helper/MetaHelper';
import { ValidationError } from '../../../helper/Types';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../../../type/actor-setup';

import { ServiceDialogFormInterface } from './service-dialog-form.type';
import ServiceDialogFormView from './service-dialog-form.view';
import { RecordKeyMode } from '../../../type/actor-setup';

const ServiceDialogFormController: FC<ServiceDialogFormInterface> = memo(props => {
  const { fields, isServiceMode, serviceParams } = props;

  const { current: currentResource, stack } = actorGetActionValue('resources')!;
  const rootResource = stack[stack.length - 2];

  /**
   * Finding input values from `formData`, if corresponding input name found in `formErrors`, replace it
   * @function updateInputsState
   * @param {object} inputsRef
   * @param {object} formErrors
   * @param {object} formData
   * @returns {void} void
   */
  const updateInputsState = (
    inputsRef: Record<string, InputRefContent> | null | undefined,
    formErrors: Record<string, ValidationError> | undefined,
    formData: FormData,
  ): void => {
    if (isEmptyObject(inputsRef)) return;

    //TODO: move to main parent component
    for (const inputName in inputsRef) {
      if (formErrors?.[inputName]) {
        inputsRef[inputName].setInputMessage?.({
          message: formErrors?.[inputName].message ?? '',
          messageType: 'error',
        });

        // FIXME: Because we have some many re-renders, without this `delay`, the correct value will be removed
        setTimeout(() => {
          inputsRef[inputName].setInputValue?.(formErrors?.[inputName].value ?? '');
        }, 0);

        continue;
      }

      inputsRef[inputName]?.setInputMessage?.(undefined);
      inputsRef[inputName]?.setInputValue?.(formData?.[inputName] ?? '');
    }
  };

  useEffect(() => {
    actorDispatch('allFields', fields, {
      path: `${currentResource.value}.${currentResource.type}`,
      callerScopeName: 'ServiceDialogFormController => useEffect',
    });
  }, []);

  let defaultValues = {};
  const getDefaultValues = useCallback(async () => {
    const globalParameters = actorGetActionValue('globalParameters');
    const response = await getFormDefaultValue(fields, globalParameters);

    return response;
  }, []);

  useEffect(() => {
    defaultValues = getDefaultValues();
  }, [getDefaultValues]);

  // to compute and dispatch default data
  useEffect(() => {
    const recordData = {};
    const record = actorGetActionValue(
      'record',
      `${rootResource?.value}.${rootResource?.type}.${RecordKeyMode.FORM}`,
    )!;

    for (const item of fields) {
      recordData[item.name] = record?.[item.name] ?? defaultValues[item.name];
    }

    actorDispatch(
      'formData',
      { ...defaultValues, ...recordData, ...serviceParams },
      {
        path: `${currentResource.value}.${currentResource.type}`,
      },
    );
    actorDispatch('initialData', defaultValues, {
      path: `${currentResource.value}.${currentResource.type}`,
    });
  }, []);

  useEffect(() => {
    actorOnDispatch('formMessages', formMessages => {
      const formData = actorGetActionValue('formData', [
        currentResource.value,
        currentResource.type,
      ]) as FormData | null;
      const inputsRef = actorGetActionValue('inputsRef');
      if (formData) {
        updateInputsState(
          inputsRef?.[currentResource.value]?.[currentResource.type],
          formMessages?.[currentResource.value]?.[currentResource.type],
          formData,
        );
      }
    });

    //TODO: convert to hook if possible
    actorOnDispatch(
      'formData',
      formData => {
        const inputsRef = actorGetActionValue('inputsRef');
        for (const inputName in inputsRef) {
          inputsRef?.[currentResource.value]?.[currentResource.type]?.[
            inputName
          ]?.setInputValue(
            formData?.[currentResource.value][currentResource.type]?.[inputName],
          );
        }
      },
      {
        onDispatchOnce: true,
      },
    );
  }, []);

  return (
    <ServiceDialogFormView
      serviceParams={serviceParams}
      fields={fields}
      isServiceMode={isServiceMode}
    />
  );
});

export default ServiceDialogFormController;
