import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import {
  Dialog,
  Button,
  DialogContent,
  withStyles,
  DialogActions,
} from '@material-ui/core';
import { useInput, translate as withTranslate } from 'react-admin';
import {
  Map as MapContainer,
  Marker,
  Popup,
  Polygon,
  TileLayer,
} from 'react-leaflet';
import { clone } from '../../helper/data-helper';

const styles = () => ({
  dialogContent: {
    padding: '0 !important',
  },

  dialogActions: {
    position: 'absolute',
    zIndex: 500,
    bottom: 0,
    right: 0,
  },
});

const PolygonInput = props => {
  const { translate, classes } = props;
  // const polygon = [[51.515, -0.09], [51.52, -0.1], [51.52, -0.12]];

  const [isOpen, setIsOpen] = useState(false);
  const [prevValue, setPrevValue] = useState(null);
  const [polygon, setPolygon] = useState([]);

  const {
    input: { onChange, value },
  } = useInput(props);

  useEffect(() => {
    if (
      typeof value !== 'undefined' &&
      value !== prevValue &&
      value.toString().indexOf(',') > 1
    ) {
      let result = null;
      try {
        result = JSON.parse(value);
      } catch (error) {
        console.log('unable to parse json polygon', { value, error });
      }

      setPrevValue(value);
      setPolygon(result);
    }
  }, [value]);

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleMapClick = ({ latlng }) => {
    const clonedPolygon = clone(polygon);
    clonedPolygon.push([latlng.lat, latlng.lng]);

    setPolygon(clonedPolygon);
  };

  const handleCancelClick = () => {
    setIsOpen(false);
    setPrevValue(null);
    setPolygon([]);
  };

  const handleOkClick = () => {
    onChange(JSON.stringify(polygon));
    toggleOpen();
  };

  const removeLocation = index => () => {
    const clonedPolygon = clone(polygon);

    clonedPolygon.splice(index, 1);

    setPolygon(clonedPolygon);
  };

  return (
    <div>
      <Button onClick={toggleOpen}>{translate('ra.input.map.open_map')}</Button>
      <Dialog open={isOpen} onClose={handleCancelClick} fullWidth>
        <DialogContent className={classes.dialogContent}>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOkClick}
              disabled={!polygon || polygon.length < 3}
            >
              {translate('ra.action.confirm')}
            </Button>
            <Button variant="contained" onClick={handleCancelClick}>
              {translate('ra.action.cancel')}
            </Button>
          </DialogActions>
          <MapContainer
            center={[35.6839961, 51.3973511]}
            zoom={12.25}
            style={{ width: '100%', height: 500 }}
            onClick={handleMapClick}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {polygon &&
              polygon.map((location, index) => (
                <Marker position={location} key={location.join('-')}>
                  <Popup>
                    <Button onClick={removeLocation(index)}>
                      {translate('ra.action.delete')} {index + 1}
                    </Button>
                  </Popup>
                </Marker>
              ))}
            <Polygon color="purple" positions={polygon} />
          </MapContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default compose(
  withTranslate,
  withStyles(styles, { withTheme: true }),
)(PolygonInput);
