import {
  FC,
  useState,
  memo,
  ChangeEvent,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { SelectedMessageDataInterface } from '.';
import { ChatActions, OnSendFileParams } from '../../..';
import { isEnterPressed, isEscapePressed } from '../../../../../helper/FormHelper';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../../../../../type/actor-setup';
import { NewMessageInterface } from './new-message.type';
import NewMessageView from './new-message.view';

const NewMessageController: FC<NewMessageInterface> = memo(props => {
  const { chatActionsHandler, customSendAction } = props;

  const chatInputRef = useRef<HTMLInputElement>(null);
  const emojiContainerRef = useRef<HTMLDivElement>(null);

  const [showEmojis, setShowEmojis] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [selectedMessageData, setSelectedMessageData] =
    useState<SelectedMessageDataInterface | null>(null);
  useOnClickOutside(emojiContainerRef, () => setShowEmojis(false));

  /**
   * @function toggleEmojisHandler
   * @returns { void }
   */
  const toggleEmojisHandler = (): void => {
    setShowEmojis(prev => !prev);
  };

  /**
   * @function onAddEmoji
   * @param event
   * @returns { void }
   */
  const onAddEmoji = (event: any): void => {
    const currentChatText = actorGetActionValue('chatText') ?? '';
    const sym = event.unified.split('-');
    const codesArray = [] as any[];
    sym.forEach((el: any) => codesArray.push('0x' + el));
    const emoji = String.fromCodePoint(...codesArray);
    actorDispatch('chatText', `${currentChatText}${emoji}`);
    setMessage(`${currentChatText}${emoji}`);
  };

  /**
   * @function onSendMessage
   * @returns { void }
   */
  const onSendMessage = (): void => {
    if (typeof customSendAction === 'function') {
      customSendAction();
    } else {
      chatActionsHandler(ChatActions.onSendMessage);
    }
  };

  /**
   * @function onSendFile
   * @param { React.ChangeEvent<HTMLElement> } event
   * @returns { void }
   */
  const onSendFile = (event: React.ChangeEvent<HTMLElement>): void => {
    chatActionsHandler(ChatActions.onSendFile, {
      event,
    } as OnSendFileParams);
  };

  /**
   * to cancel reply a message
   * @function onCancelSelectedMessage
   * @returns { void }
   */
  const onCancelSelectedMessage = (): void => {
    actorDispatch('selectedMessageData', null, {
      replaceAll: true,
    });
    actorDispatch('chatText', '', {
      replaceAll: true,
    });
  };

  /**
   * Close selectedMessage Box  by esc
   * @function escFunction
   * @param event
   */
  const escFunction = useCallback(event => {
    if (isEscapePressed(event)) {
      onCancelSelectedMessage();
    }
  }, []);

  /**
   * @function handleInputChange
   * @param { ChangeEvent<HTMLInputElement> } event
   * @returns { void }
   */
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value !== '\n') {
      actorDispatch('chatText', event.target.value);
      setMessage(event.target.value);
    }
  };

  /**
   * send message by Enter and go to next line by shift + enter
   * @function handleKeyPress
   * @param { React.KeyboardEvent<HTMLDivElement> } event
   * @returns { void }
   */
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (isEnterPressed(event) && !event.shiftKey) {
      event.preventDefault();
      onSendMessage();
    }
  };

  useEffect(() => {
    actorOnDispatch('chatText', text => {
      //reset textfield value
      if (text == '') setMessage(text);
    });
  }, []);

  useEffect(() => {
    actorOnDispatch('selectedMessageData', selectedMessageData => {
      setSelectedMessageData(selectedMessageData);
      chatInputRef?.current?.focus();
    });
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    actorDispatch('chatInputRef', chatInputRef);
  }, [chatInputRef]);

  return (
    <NewMessageView
      chatInputRef={chatInputRef}
      emojiContainerRef={emojiContainerRef}
      message={message}
      handleInputChange={handleInputChange}
      onSendMessage={onSendMessage}
      onSendFile={onSendFile}
      onKeyPress={handleKeyPress}
      selectedMessageData={selectedMessageData}
      onCancelSelectedMessage={onCancelSelectedMessage}
      showEmojis={showEmojis}
      toggleEmojisHandler={toggleEmojisHandler}
      onAddEmoji={onAddEmoji}
    />
  );
});

export default NewMessageController;
