import React, { FC } from 'react';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import { createStyles, makeStyles } from '@material-ui/core';
import { Responsive } from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { linkToRecord } from 'ra-core';

import { getValue, CONFIG_CALENDAR } from '../../core/configProvider';
import SellinHistoryOrderWebView from './SellinHistoryOrderWebView';
import SellinHistoryOrderMobieView from './SellinHistoryOrderMobieView';
import { CustomTheme } from '../../core/themeProvider';

interface SellinHistoryOrderViewInterfaceProps {
  ids: number[];
  data: object[];
  patternData: PatternDataInterfaceProps;
  redirectToPage: Function;
}

interface PatternDataInterfaceProps {
  [x: string]: string;
}

const styles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      height: '100%',
    },
  }),
);

const SellinHistoryOrderView: FC<SellinHistoryOrderViewInterfaceProps> = props => {
  const { ids, data, patternData, redirectToPage } = props;
  const { historyOrderDetailResource } = patternData;

  const classes = styles();

  const preparedData = ids ? ids.map(key => data[key]) : [];
  const currentCalendar = getValue(CONFIG_CALENDAR);

  const handleOpen = (historyOrderID: number) => event => {
    redirectToPage(`/${linkToRecord(historyOrderDetailResource, historyOrderID)}`);
  };

  return (
    <div className={classes.container}>
      <Responsive
        small={
          <SellinHistoryOrderMobieView
            patternData={patternData}
            preparedData={preparedData}
            currentCalendar={currentCalendar}
            handleOpenSellinHistoryOrderDetail={handleOpen}
          />
        }
        medium={
          <SellinHistoryOrderWebView
            patternData={patternData}
            preparedData={preparedData}
            currentCalendar={currentCalendar}
            handleOpenSellinHistoryOrderDetail={handleOpen}
          />
        }
      />
    </div>
  );
};

const mapDispatchToProps = {
  redirectToPage: push,
};

export default compose(
  translate,
  connect(null, mapDispatchToProps),
)(SellinHistoryOrderView);
