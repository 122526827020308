import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardMedia, Icon, IconButton, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ShopAddToCartButtonCounter from './ShopAddToCartButtonCounter';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Responsive, NumberField, useTranslate } from 'react-admin';

import useWidth from '../useWidth';
import { SELLIN_SHOW } from '../../core/configRouteConstant';
import placeholder from '../../images/placeholder.png';
import { CONFIG_CURRENCY_SYMBOL, getValue } from '../../core/configProvider';

const useStyles = makeStyles(theme => ({
  card: {
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 15,
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },

  cardContainer: {
    display: 'flex',
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      flex: 2,
      alignItems: 'center',
    },
    minHeight: 120,
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    padding: '10px',
  },

  image: {
    flex: 1,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 200,
      height: 200,
      margin: 5,
    },
    backgroundImage: `url(${placeholder})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },

  detailPrice: {
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      borderTop: 'none',
      flex: 0.7,
    },
  },

  detailPriceItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3px 10px',
    alignItems: 'center',
  },

  fontBold: {
    fontWeight: 600,
  },

  unitPrice: {
    color: theme.palette.text.disabled,
  },

  totalPrice: {
    color: theme.palette.text.primary,
    [theme.breakpoints.up('lg')]: {
      color: theme.palette.text.disabled,
    },
  },

  discount: {
    color: theme.palette.error.main,
    [theme.breakpoints.up('lg')]: {
      color: theme.palette.text.disabled,
    },
  },

  finalPrice: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
    },
  },

  action: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      flex: 0.7,
      justifyContent: 'center',
    },
  },

  IconButton: {
    padding: 5,
  },

  numberFieldCaption: {
    fontSize: 12,
  },

  currency: {
    color: theme.palette.text.disabled,
  },

  priceHolder: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CartCardView = props => {
  const {
    resource,
    apiUrl,
    product,
    pattern,
    notifyCountChange,
    onDelete,
    disableAction,
  } = props;
  const translate = useTranslate();
  const classes = useStyles();
  const width = useWidth();
  const {
    count,
    id,
    imagePath,
    imageFileName,
    name,
    itemPrice,
    discount,
    totalPrice,
    productId,
  } = pattern;

  const [counter, setCounter] = useState(product[count]);

  const addToCart = () => {
    setCounter(counter + 1);
    notifyCountChange(product, counter + 1);
  };

  const removeOfCart = () => {
    if (counter === 1) {
      setCounter(counter - 1);
      onDelete(product[id]);
    } else {
      setCounter(counter - 1);
      notifyCountChange(product, counter - 1);
    }
  };

  const inputType = event => {
    const parsedValue = parseFloat(event.target.value);

    setCounter(parsedValue);

    if (!isNaN(parsedValue)) {
      notifyCountChange(product, parsedValue);
    }
  };

  const handleDelete = () => onDelete(product[id]);

  return (
    <Card className={classes.card}>
      <div className={classes.cardContainer}>
        {!disableAction && (width === 'lg' || width === 'xl') && (
          <IconButton className={classes.IconButton} onClick={handleDelete}>
            <Icon fontSize="small">close</Icon>
          </IconButton>
        )}
        <CardMedia
          className={classes.image}
          image={`${apiUrl}/${product[imagePath]}/thumbnail/${product[imageFileName]}`}
          title=""
          component={Link}
          to={`/${SELLIN_SHOW}/${resource}/${product[productId]}`}
        />
        <div className={classes.details}>
          <Responsive
            medium={
              <Fragment>
                <Typography variant="subtitle2">{product[name]}</Typography>
                {!disableAction && (
                  <div className={classes.action}>
                    <ShopAddToCartButtonCounter
                      counter={counter}
                      onRemoveButton={removeOfCart}
                      onAddButton={addToCart}
                      onInputType={inputType}
                    />
                    <IconButton
                      className={classes.IconButton}
                      onClick={handleDelete}
                    >
                      <Icon fontSize="small">delete</Icon>
                    </IconButton>
                  </div>
                )}
              </Fragment>
            }
            large={
              <Fragment>
                <Typography variant="h6">{product[name]}</Typography>
                <Typography variant="caption">{product[name]}</Typography>
              </Fragment>
            }
          />
        </div>
      </div>
      {!disableAction && (width === 'lg' || width === 'xl') && (
        <div className={classes.action}>
          <ShopAddToCartButtonCounter
            counter={counter}
            onRemoveButton={removeOfCart}
            onAddButton={addToCart}
            onInputType={inputType}
          />
          {(width === 'md' || width === 'sm' || width === 'xs') && (
            <IconButton className={classes.IconButton} onClick={handleDelete}>
              <Icon fontSize="small">delete</Icon>
            </IconButton>
          )}
        </div>
      )}
      <div className={classes.detailPrice}>
        <div className={classes.detailPriceItem}>
          <Typography variant="caption" className={classes.unitPrice}>
            {translate('shop.basket.unitPrice')}
          </Typography>
          <div className={classes.priceHolder}>
            <NumberField
              className={classNames(
                classes.fontBold,
                classes.unitPrice,
                classes.numberFieldCaption,
              )}
              source={'value'}
              record={{ value: product[itemPrice] }}
            />
            <Typography variant="caption" className={classes.currency}>
              &nbsp;
              {getValue(CONFIG_CURRENCY_SYMBOL) || translate('shop.basket.currency')}
            </Typography>
          </div>
        </div>
        <div className={classes.detailPriceItem}>
          <Typography variant="caption" className={classes.totalPrice}>
            {translate('shop.basket.totalPrice')}
          </Typography>
          <div className={classes.priceHolder}>
            <NumberField
              className={classNames(
                classes.fontBold,
                classes.totalPrice,
                classes.numberFieldCaption,
              )}
              source={'value'}
              record={{ value: product[totalPrice] }}
            />
            <Typography variant="caption" className={classes.currency}>
              &nbsp;
              {getValue(CONFIG_CURRENCY_SYMBOL) || translate('shop.basket.currency')}
            </Typography>
          </div>
        </div>
        {product[discount] > 0 && (
          <div className={classes.detailPriceItem}>
            <Typography variant="caption" className={classes.discount}>
              {translate('shop.basket.discount')}
            </Typography>
            <div className={classes.priceHolder}>
              <NumberField
                className={classNames(
                  classes.fontBold,
                  classes.discount,
                  classes.numberFieldCaption,
                )}
                source={'value'}
                record={{ value: product[discount] }}
              />
              <Typography variant="caption" className={classes.currency}>
                &nbsp;
                {getValue(CONFIG_CURRENCY_SYMBOL) ||
                  translate('shop.basket.currency')}
              </Typography>
            </div>
          </div>
        )}
        <div className={classes.detailPriceItem}>
          <Typography variant="caption" className={classes.finalPrice}>
            {translate('shop.basket.finalPrice')}
          </Typography>
          <div className={classes.priceHolder}>
            <NumberField
              className={classNames(classes.fontBold, classes.finalPrice)}
              source={'value'}
              record={{ value: product[totalPrice] }}
            />
            <Typography variant="caption" className={classes.currency}>
              &nbsp;
              {getValue(CONFIG_CURRENCY_SYMBOL) || translate('shop.basket.currency')}
            </Typography>
          </div>
        </div>
      </div>
    </Card>
  );
};

CartCardView.propTypes = {
  onDelete: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  pattern: PropTypes.object.isRequired,
  apiUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  notifyCountChange: PropTypes.func,
  disableAction: PropTypes.bool,
};

export default CartCardView;
