import { ReactElement, useEffect, useRef, useState } from 'react';

import { isJsonEncodedString } from '../../helper/data-helper';
import { actorOnDispatch } from '../../type/actor-setup';
import { addFakeIdsToArrayItems } from './files-popup-dialog.helper';
import { FilesPopupDialogData } from './files-popup-dialog.type';
import FilesPopupDialogView from './files-popup-dialog.view';

const FilesPopupDialogController = (): ReactElement => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const dialogDataRef = useRef<FilesPopupDialogData | null>();

  useEffect(() => {
    actorOnDispatch(
      'filesDialog',
      details => {
        dialogDataRef.current = details;
        setIsDialogOpen(details.isOpen);
      },
      { preserve: false },
    );
  }, []);

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const rawData = dialogDataRef?.current?.data ?? [];
  const parsedData = isJsonEncodedString(rawData)
    ? JSON.parse(rawData as string)
    : rawData;
  const { items, ids } = addFakeIdsToArrayItems(parsedData);

  // because it may be in first render of `AppLayout.js` without any data
  if (!isDialogOpen) return <></>;

  return (
    <FilesPopupDialogView
      data={items}
      isOpen={isDialogOpen}
      closeDialog={closeDialog}
      ids={ids}
      title={dialogDataRef?.current?.title ?? ''}
    />
  );
};

export default FilesPopupDialogController;
