import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import {
  Dialog,
  Button,
  DialogContent,
  withStyles,
  DialogActions,
} from '@material-ui/core';
import { translate as withTranslate, useInput } from 'react-admin';
import { Map as MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';

const styles = () => ({
  dialogContent: {
    padding: '0 !important',
  },

  dialogActions: {
    position: 'absolute',
    zIndex: 500,
    bottom: 0,
    right: 0,
  },
});

const LatLngInput = props => {
  const { translate, classes } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [prevValue, setPrevValue] = useState(null);
  const [location, setLocation] = useState(null);

  const {
    input: { onChange, value },
  } = useInput(props);

  useEffect(() => {
    if (
      typeof value !== 'undefined' &&
      value !== prevValue &&
      value.toString().indexOf(',') > 1
    ) {
      setPrevValue(value);
      setLocation(value.split(','));
    }
  }, [value]);

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleMapClick = ({ latlng }) => setLocation([latlng.lat, latlng.lng]);

  const handleCancelClick = () => {
    setIsOpen(false);
    setPrevValue(null);
    setLocation(null);
  };

  const handleOkClick = () => {
    onChange(`${location[0]},${location[1]}`);
    toggleOpen();
  };

  return (
    <div>
      <Button onClick={toggleOpen}>{translate('ra.input.map.open_map')}</Button>
      <Dialog open={isOpen} onClose={handleCancelClick} fullWidth>
        <DialogContent className={classes.dialogContent}>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOkClick}
              disabled={!location || !location.length}
            >
              {translate('ra.action.confirm')}
            </Button>
            <Button variant="contained" onClick={handleCancelClick}>
              {translate('ra.action.cancel')}
            </Button>
          </DialogActions>
          <MapContainer
            center={[35.6839961, 51.3973511]}
            zoom={12.25}
            style={{ width: '100%', height: 500 }}
            onClick={handleMapClick}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {location && (
              <Marker position={location}>
                <Popup>{translate('ra.action.bulk_actions', 1)}</Popup>
              </Marker>
            )}
          </MapContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default compose(
  withTranslate,
  withStyles(styles, { withTheme: true }),
)(LatLngInput);
