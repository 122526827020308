/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import lodashMerge from 'lodash/merge';
import lodashGet from 'lodash/get';
import lodashFind from 'lodash/find';

import { actorSetActionValue, DropdownDataKey } from '../type/actor-setup';
import { getValue, USER_WAREHOUSE_ID } from '../core/configProvider';
import { isEmpty, isEmptyObject } from './data-helper';
import dataProvider, { GET_DROPDOWN } from '../core/dataProvider';

/**
 * Get dropdown request parameter from form data.
 * @function getDropdownRequestParams
 * @param {object} -
 * {
 *   dropdownMeta: object,
 *   page: number,
 *   perPage: number,
 *   record: object,
 *   search: string,
 *   filterValues: object,
 *   forceTreeLevel: boolean,
 *   additionalProps: object,
 *   resource: string,
 *   sort:string
 * }
 * @returns {object}
 */
export const getDropdownRequestParams = ({
  dropdownMeta,
  page = 1,
  perPage = 10,
  record = {},
  search = '',
  filterValues = {},
  forceTreeLevel = dropdownMeta.forceTreeLevel,
  additionalProps = {},
  resource = '',
  sort = '',
}) => {
  const { parameters: dropdownRequiredParameters } = dropdownMeta;

  const result = {
    pagination: {
      page,
      perPage,
    },
    sort,
    search,
    filter: filterValues,
  };

  const parameters = {};
  dropdownRequiredParameters.forEach(item => {
    if (!item) {
      return;
    }
    const { originalRecord } = additionalProps;
    const { to, from, moduleName, moduleTableName, defaultValue } = item;
    const dropdownParameterResource = `${moduleName}/${moduleTableName}`;
    const formFieldName = !isEmpty(from) ? from.toLowerCase() : null;
    const paramFieldName = !isEmpty(to) ? to.toLowerCase() : null;

    if (dropdownParameterResource === resource) {
      const parameterValue = computeParameterValue(
        record,
        formFieldName,
        defaultValue,
      );
      if (!isEmpty(parameterValue)) {
        parameters[paramFieldName] = parameterValue;
      }
    } else {
      const parameterValue = computeParameterValue(
        originalRecord,
        formFieldName,
        defaultValue,
      );

      if (!isEmpty(parameterValue)) {
        parameters[paramFieldName] = parameterValue;
      }
    }
  });

  if (Object.keys(parameters).length > 0) {
    result.parameters = JSON.stringify(parameters);
  }

  result.forceTreeLevel = forceTreeLevel;

  return result;
};

/**
 * Check `fieldName` in `data` and return value.
 * @function computeParameterValue
 * @param {object} data
 * @param {string} fieldName
 * @param {number | string | null} defaultValue
 * @returns {number | string | null}
 */
const computeParameterValue = (data, fieldName, defaultValue) => {
  return data && !isEmpty(data[fieldName])
    ? data[fieldName]
    : !isEmpty(defaultValue)
    ? defaultValue
    : null;
};

export const getLabelForDropdownOption = (dropdownMeta, row) => {
  const { displayMember, displayMember2 } = dropdownMeta;

  let label = row[displayMember];

  if (typeof row[displayMember2] !== 'undefined') {
    label += ' - ' + row[displayMember2];
  }

  return label;
};

/**
 * it will compute the dropdown label that should be displayed and the dropdown value that
 * should send in requests and returns this info in an object to use in the dropdown field.
 * @function findSelectedItemFromDropdownData
 * @param {Object} includes dropdownMeta,dataArray,record,value,field
 * @returns {Object} dropdown lable and value
 */
export const findSelectedItemFromDropdownData = ({
  dropdownMeta,
  dataArray,
  record,
  value,
  field,
}) => {
  const { valueMember, filterValueMember } = dropdownMeta;
  // if we have any data from api, use that first!
  if (dataArray && dataArray.length && !isEmpty(value)) {
    const dropdownItem = dataArray.find(item => {
      // keep loose comparison, because server might give number inside string
      // eslint-disable-next-line eqeqeq
      const computedValue = Array.isArray(value) ? lodashGet(value, 2) : value;
      return item[filterValueMember] == computedValue;
    });

    if (dropdownItem) {
      return {
        ...dropdownItem,
        value: dropdownItem[valueMember],
        label: getLabelForDropdownOption(dropdownMeta, dropdownItem),
      };
    }
  }

  const preFilledName = dropdownPreFilledName(field);
  if (!isEmpty(value) && record && record[preFilledName]) {
    return {
      value: value,
      label: record[preFilledName],
    };
  }

  return null;
};

export const dropdownPreFilledName = field => {
  return field.relatedName;
};
/**
 * this function will call the getDropdownRequestParams function to compute dropdown parameters
 * for HTTP requests then merge them with custom params that have been received from the third parameter.
 * then trigger the findDropdownData action that has been destructed from the first parameter (props)
 * @function triggerDropdownFetchData
 * @param {object} props
 * @param {string} value
 * @param {object} customParams constant parameters
 * @returns {void}
 */
export const triggerDropdownFetchData = (props, value, customParams = {}) => {
  const {
    formData,
    record = {},
    dropdownMeta,
    findDropdownData = () => {},
    additionalProps,
    resource,
    perPage,
    forceTreeLevel,
    filterValues,
    page,
    dropdownInPuzzleForm,
    isTodo,
    isProfile,
    isService,
    sort,
  } = props;

  const { id } = dropdownMeta;

  const finalParams =
    dropdownInPuzzleForm || isTodo || isProfile || isService
      ? { ...customParams, fieldName: undefined, dropId: undefined }
      : customParams;

  const searchValue = !isEmpty(value)
    ? Array.isArray(value) && !isEmpty(value[2])
      ? value[2]
      : value
    : '';

  const params = lodashMerge(
    getDropdownRequestParams({
      dropdownMeta,
      record: !isEmptyObject(formData) ? formData : record,
      search: searchValue,
      additionalProps,
      resource,
      perPage: !isEmpty(perPage) ? perPage : null,
      page: !isEmpty(page) ? page : null,
      forceTreeLevel: !isEmpty(forceTreeLevel) ? forceTreeLevel : null,
      filterValues: !isEmpty(filterValues) ? filterValues : null,
      sort: !isEmptyObject(sort) ? sort : '',
    }),
    finalParams,
  );

  findDropdownData({ id, params, meta: dropdownMeta });
};

/**
 * it will make request to get dropdown data with data provider component
 * and dispatch the result in actor , then rerun the result value too
 * @function getDropDownItem
 * @param { Record<string,unknown> } dropdownMeta
 * @returns { Array<Record<string, unknown>> }
 */
export const getDropDownItem = async dropdownMeta => {
  // todo: it will use only to get branch of user , not real dropdown data and should handle with new logic of
  // todo: dropdown in mono repo its only a copy of src/redux/profile/saga.js `getDropDownItem` generator function
  try {
    const userWarehouse = getValue(USER_WAREHOUSE_ID);
    const dropdownId = lodashGet(dropdownMeta, 'id');

    const params = getDropdownRequestParams({
      dropdownMeta,
    });

    const data = await dataProvider(GET_DROPDOWN, dropdownId, params);
    const dropdownData = data.result;

    actorSetActionValue(
      'dropDownData',
      {
        [DropdownDataKey.DATA]: dropdownData,
        [DropdownDataKey.ALL]: dropdownData,
      },
      { path: dropdownId },
    );

    if (userWarehouse !== 'null') {
      return lodashFind(dropdownData, {
        [dropdownMeta.valueMember]: +userWarehouse,
      });
    } else {
      return lodashGet(dropdownData, '0');
    }
  } catch (error) {
    console.log(error);
  }
};
