import React, { useRef } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { Icon, Typography, withStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import classNames from 'classnames';

import ConfirmDialogHOC from '../../container/ConfirmDialogHOC';

export const ItemTypes = {
  FIELD: 'field',
};

const styles = theme => ({
  tableCell: {
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: '15px 0 15px 20px',
    cursor: 'grab',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '100%',
    },

    '& div span': {
      opacity: 0,
      transition: 'all 200ms',
    },
    '&:hover div span': {
      opacity: 1,
      transition: 'all 250ms',
    },
  },

  fieldItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  caption: {
    display: 'inline',
    flexGrow: 1,
  },

  fieldIcon: {
    color: theme.palette.grey[500],
    cursor: 'pointer',
    fontSize: 20,
    margin: '0 5px',
  },
});

const DroppableFieldCell = ({
  classes = {},
  className,
  caption,
  field,
  index,
  source,
  moveField,
  removeField,
  openConfirmDialog,
  translate,
  openFieldInfoDialog,
  isTable,
  theme,
}) => {
  const ref = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [collectedProps, drop] = useDrop({
    accept: ItemTypes.FIELD,
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const origin = item.source;
      const target = source;
      if (origin === target) {
        return;
      }
      moveField(origin, target);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.FIELD, source, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const content = (
    <div className={classes.fieldItem}>
      <Typography className={classes.caption} variant="body2">
        {caption}
      </Typography>
      {openFieldInfoDialog && (
        <Icon
          className={classes.fieldIcon}
          onClick={() => openFieldInfoDialog(source)}
        >
          settings
        </Icon>
      )}
      {removeField && (
        <Icon
          className={classes.fieldIcon}
          onClick={() =>
            openConfirmDialog({
              content: translate('ra.message.delete_content'),
              onConfirm: () => {
                removeField(source);
              },
              color: theme.palette.error.main,
            })
          }
        >
          close
        </Icon>
      )}
    </div>
  );

  return isTable ? (
    <td
      className={classNames(classes.tableCell, className)}
      ref={ref}
      style={{
        opacity: isDragging ? 0 : 1,
      }}
    >
      {content}
    </td>
  ) : (
    <div
      className={classNames(classes.tableCell, className)}
      ref={ref}
      style={{
        opacity: isDragging ? 0 : 1,
      }}
    >
      {content}
    </div>
  );
};

DroppableFieldCell.propTypes = {
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  caption: PropTypes.string.isRequired,
  moveField: PropTypes.func.isRequired,
  removeField: PropTypes.func,
  openFieldInfoDialog: PropTypes.func,
  isTable: PropTypes.bool,
};

export default compose(
  ConfirmDialogHOC,
  withStyles(styles, { withTheme: true }),
  translate,
)(DroppableFieldCell);
