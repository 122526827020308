import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslate, useRefresh, useLocale, crudDeleteMany } from 'react-admin';
import { useDispatch } from 'react-redux';

import { getMetaData } from '../../../api/wms-api';
import { getPrintResource, PrintPopupOpener } from '../../../helper/PrintMetaHelper';
import { actorDispatch, actorGetActionValue } from '../../../type/actor-setup';
import { FormActions, FormActionsHandler } from '../../form';
import WMSButtonToolbarView from './wms-button-toolbar.view';

import type { TranslatedEntity } from '../../../helper/Types';
import type { WMSButtonToolbarControllerProps } from './wms-button-toolbar.type';

let formActionsHandler: FormActionsHandler;
const WMSButtonToolbarController = (
  props: WMSButtonToolbarControllerProps,
): ReactElement => {
  const { wmsMetaData, selectedTabIndex } = props;
  const { saveActionId, validationActionId, tabs } = wmsMetaData;

  const [isLoading, setIsLoading] = useState(false);

  const reduxDispatch = useDispatch();
  const locale = useLocale();
  const translate = useTranslate();
  const refreshView = useRefresh();

  const isDeleteAvailable = useMemo(() => {
    const tabs = wmsMetaData.tabs[selectedTabIndex ?? 0];

    let moduleName: string | undefined;
    let tableName: string | undefined;
    if (tabs?.item?.table?.config) {
      moduleName = tabs.item.table.config['moduleName'];
      tableName = tabs.item.table.config['moduleTableName'];
    }

    return !!(tableName && moduleName);
  }, [selectedTabIndex]);

  useEffect(() => {
    formActionsHandler = actorGetActionValue('formGlobalProps')!.formActionsHandler;
  }, []);

  useEffect(() => {
    checkForMeta();
  }, [selectedTabIndex]);

  /**
   * It gets `metaData` for received `print` data and registers that to `redux`
   * @function checkForMeta
   * @returns { Promise<void> }
   */
  const checkForMeta = async (): Promise<void> => {
    const print = tabs[selectedTabIndex ?? 0]?.item.print ?? null;

    if (print?.id) {
      const printResource = getPrintResource(print.id);

      await getMetaData(printResource);

      reduxDispatch({
        type: 'RA/REGISTER_RESOURCE',
        payload: {
          name: printResource,
          options: {},
        },
      });
    }
  };

  /**
   * It shows a `dialog` to get the user confirmation and then runs `formActionsHandler` in the main controller to save the data
   * @function onSaveClick
   * @returns { void }
   */
  const onSaveClick = (): void => {
    setIsLoading(true);

    actorDispatch('quickDialog', {
      confirmationIsOpen: true,
      data: {
        content: translate('ra.message.are_you_sure'),
        onConfirm: () => {
          formActionsHandler(FormActions.Save, {
            validationActionId,
            actionUniqueId: saveActionId,
            onSuccess: printProcess,
            onFailure: () => {
              setIsLoading(false);
            },
          });
        },
        onCancel: () => {
          setIsLoading(false);
        },
      },
    });
  };

  /**
   * Based on `report` and `print` data shows the `print` with page to the user
   * @function printProcess
   * @param { object } reportData
   * @returns { Promise<void> }
   */
  const printProcess = async (
    reportData: Record<string, unknown>,
  ): Promise<void> => {
    setIsLoading(false);

    requestAnimationFrame(() => {
      actorDispatch('resetForm', {
        saveType: 'wmsSaveAfterServiceHasBeenRan',
      });
      refreshView();
    });

    const printData = wmsMetaData.tabs[selectedTabIndex ?? 0]?.item.print ?? null;
    if (!(printData?.id && printData.reportId)) return;

    actorDispatch('crudAction', {
      entity: 'wms',
      type: 'GET_LIST',
      payload: {
        resource: `report/${printData.reportId}`,
        filter: reportData.data,
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'id', order: 'DESC' },
        onSuccess: async response => {
          const reportData = response.data;

          actorDispatch('quickDialog', {
            confirmationIsOpen: true,
            data: {
              content: response.userMessage,
            },
          });

          const opener = new PrintPopupOpener();
          opener.setTranslate(translate);
          opener.openPrintInNewWindow(
            (printData['translatedTitle'] as TranslatedEntity)[locale] ??
              printData.id,
            await getMetaData(getPrintResource(printData.id)),
            reportData,
          );
        },
        onFailure: response => {
          actorDispatch('quickDialog', {
            confirmationIsOpen: true,
            data: {
              content: response.userMessage,
            },
          });

          actorDispatch('notification', {
            message: 'Getting report data failed',
            type: 'error',
          });
        },
      },
    });
  };

  /**
   * It shows a `dialog` to get the user confirmation and then sends some data to the server to delete
   * @function onDeleteClick
   * @returns { void }
   */
  const onDeleteClick = (): void => {
    if (!isDeleteAvailable) {
      console.warn('delete is not available!');
      return;
    }

    const tabs = wmsMetaData.tabs[selectedTabIndex ?? 0];

    const moduleName = tabs.item.table?.config?.['moduleName'];
    const tableName = tabs.item.table?.config?.['moduleTableName'];
    const resource = `${moduleName}/${tableName}`;

    let selectedIdsForDelete =
      actorGetActionValue('gridIDs', resource)?.selectedIDs ?? [];

    let deleteMessage;
    if (selectedIdsForDelete.length > 0) {
      deleteMessage = 'confirm.areYouSureYouWantToDeleteThisCountOfItem';
    }

    // delete the whole data
    else {
      deleteMessage = 'confirm.areYouSureYouWantToDeleteWholeTable';
      selectedIdsForDelete = actorGetActionValue('gridIDs', resource)?.allIDs ?? [];
    }

    actorDispatch('quickDialog', {
      confirmationIsOpen: true,
      data: {
        content: translate(deleteMessage, {
          smart_count: selectedIdsForDelete.length,
        }),
        onConfirm: () => {
          if (selectedIdsForDelete.length > 0) {
            reduxDispatch(crudDeleteMany(resource, selectedIdsForDelete));
          }
          // FIXME: Set `loading` to `false` in the action's callback
          setIsLoading(false);
        },
        onCancel: () => {
          setIsLoading(false);
        },
      },
    });
  };

  return (
    <WMSButtonToolbarView
      isDeleteAvailable={isDeleteAvailable}
      saveActionId={saveActionId}
      onDeleteClick={onDeleteClick}
      onSaveClick={onSaveClick}
      isLoading={isLoading}
    />
  );
};

export default WMSButtonToolbarController;
