import React, { ChangeEvent } from 'react';
import { InputBase } from '@material-ui/core';

import {
  checkNumberForMinus,
  convertDigitsToEnglish,
} from '../../../../helper/NumberHelper';
import { NumberFormatCustomProps } from './number-format-custom.type';

export default function NumberFormatCustom(props: NumberFormatCustomProps) {
  const {
    onChange,
    inputCustomProps: { inputRef, source, precision, onFocus, ...other },
    ...rest
  } = props;

  /**
   * @function customChange
   * @param {HTMLInputElement} event
   * @returns {void}
   */
  const customChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.target.value = checkNumberForMinus(
      convertDigitsToEnglish(event.target.value),
    );
    event.target.focus = onFocus;
    onChange(event);
  };

  return (
    <InputBase
      inputRef={inputRef}
      inputProps={{
        'data-test-input-name': source,
        'data-test-precision': precision,
        ...other,
      }}
      onChange={customChange}
      {...rest}
    />
  );
}
