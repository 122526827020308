import {
  FC,
  memo,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NewMetaContext } from '../../container/NewMetaContext';
import { API_URL, API_VERSION, getValue } from '../../core/configProvider';
import { isEmptyObject } from '../../helper/data-helper';
import { getPrintResource } from '../../helper/PrintMetaHelper';
import { actorDispatch, actorGetActionValue } from '../../type/actor-setup';
import { PrintReportInterface } from './print-report.type';
import PrintReportView from './print-report.view';
const PrintReportController: FC<PrintReportInterface> = memo(props => {
  const [iframeKey, setIframeKey] = useState(0);
  const { metaData, filterValues = {} } = props;
  const { getMeta } = useContext(NewMetaContext);
  const apiEnv = useMemo(() => `${getValue(API_URL)}/${getValue(API_VERSION)}`, []);
  const printResource = getPrintResource(
    (metaData?.prints[0] as Record<string, unknown>)?.id,
  );
  useEffect(() => {
    getMeta(printResource);
  }, []);

  //refresh iframe when filter changed
  useEffect(() => {
    if (!isEmptyObject(filterValues)) {
      setIframeKey(Math.random());
    }
  }, [filterValues]);

  /**
   * @function getData
   * @param {SyntheticEvent<HTMLIFrameElement, Event>} load
   * @return {void}
   */
  const getData = (load: SyntheticEvent<HTMLIFrameElement, Event>): void => {
    if (metaData?.prints.length) {
      actorDispatch('getPrintReportData', {
        successCallback: (data: Record<string, unknown>) =>
          successCallback(data, load),
        metaData,
        reportId: metaData?.id,
        filterValues,
      });
    }
  };

  /**
   * @function successCallback
   * @param {Record<string,unknown>} data
   * @param {SyntheticEvent<HTMLIFrameElement, Event>} load
   * @returns {void}
   */
  const successCallback = (
    data: Record<string, unknown>,
    load: SyntheticEvent<HTMLIFrameElement, Event>,
  ): void => {
    const contentWindow = (load?.target as HTMLIFrameElement)
      ?.contentWindow as unknown as Record<string, unknown>;

    const printMeta = actorGetActionValue('metaData', printResource);

    setTimeout(() => {
      if (
        contentWindow?.isReady &&
        typeof contentWindow?.renderReport === 'function'
      ) {
        contentWindow?.renderReport('', printMeta, data, apiEnv);
      }
    }, 1000);
  };

  return <PrintReportView iframeKey={iframeKey} getData={getData} />;
});

export default PrintReportController;
