import { makeStyles } from '@material-ui/core';
import { StyleProps } from '.';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  navItem: {
    height: 40,
    marginTop: 2,
    marginBottom: 2,
    cursor: 'pointer',
    textDecoration: 'none !important',
    width: 'calc(100% - 16px)',
    borderRadius: '0 30px 30px 0',
    paddingLeft: theme.direction === 'ltr' ? ({ level }) => 24 + 40 * level : 12,
    paddingRight: theme.direction === 'rtl' ? ({ level }) => 24 + 40 * level : 12,
    '&.nav-item-header': {
      textTransform: 'uppercase',
    },
    '&.active': {
      backgroundColor: theme.palette.primary.main,
      pointerEvents: 'none',
      transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
      '& .nav-item-text': {
        color: theme.palette.common.white + '!important',
        fontWeight: 300,
      },
      '& .nav-item-icon': {
        color: theme.palette.common.white + '!important',
      },
    },

    '&:hover, &:focus': {
      '& .nav-item-text': {
        color: theme.palette.primary.appPrimaryTextColor,
      },

      '& .nav-item-icon': {
        color: theme.palette.primary.appPrimaryTextColor,
      },

      '& .nav-item-icon-arrow': {
        color: theme.palette.primary.appPrimaryTextColor,
      },
    },
    '& .nav-item-icon': {
      color: theme.palette.primary.appPrimaryTextColor,
    },
    '& .nav-item-text': {
      color: theme.palette.primary.appPrimaryTextColor,
      fontSize: 13,
    },
  },
  listIcon: {
    fontSize: 18,
  },
  listItemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 13,
  },
}));
