import { RefObject, useEffect, useState } from 'react';

type UseOnScreenInterface = (ref: RefObject<HTMLDivElement>) => boolean;

export const useOnScreen: UseOnScreenInterface = ref => {
  const [isIntersecting, setIntersecting] = useState<boolean>(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting),
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};
