import { ReactElement } from 'react';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { IconButton, Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';

import { FileStreamInputViewInterface } from './file-stream-input.type';
import { useStyles } from './file-stream-input.style';
import InputBaseLabel from '../../input-base';
import LoadingBox from '../../loading-box';
import { isEmpty } from '../../../helper/data-helper';
import ClearIcon from '@material-ui/icons/Clear';

const FileStreamInputView = (props: FileStreamInputViewInterface): ReactElement => {
  const {
    disabled,
    label,
    hint,
    required,
    name,
    onFileChange,
    inputFileRef,
    handleClick,
    dragging,
    fileName,
    inputMessage,
    loading,
    visibleClass,
    fieldsetRef,
    clearClick,
    field,
  } = props;

  const classes = useStyles({ messageType: inputMessage?.messageType });
  const translate = useTranslate();
  const fileNameExist = !isEmpty(fileName);

  return (
    <InputBaseLabel
      className={`${visibleClass}`}
      label={label}
      hint={hint}
      required={required}
      inputMessage={inputMessage}
      field={field}
    >
      <fieldset
        className={`${classes.root} ${
          inputMessage?.message ? classes.fieldsetMessage : ''
        } ${disabled && classes.disabled}`}
        disabled={disabled}
        ref={fieldsetRef}
      >
        <input
          name={name}
          id={name}
          onChange={onFileChange}
          type="file"
          className={classes.customFileInput}
          disabled={disabled}
          required={required}
          ref={inputFileRef}
        />
        <div className={classes.wrap}>
          <div onClick={handleClick} className={classes.uploadArea}>
            {dragging ? (
              <div className={classes.uploadBox} />
            ) : (
              <InsertDriveFileIcon className={classes.fileIcon} />
            )}
          </div>
          <div className={classes.inputFileValueName} onClick={handleClick}>
            {fileNameExist ? (
              <div className={classes.fileName}>
                <Typography
                  noWrap
                  title={fileName ? fileName : ''}
                  variant="caption"
                >
                  {fileName}
                </Typography>
              </div>
            ) : (
              <Typography noWrap variant="caption">
                {translate('ra.input.selectFile')}
              </Typography>
            )}
          </div>
          {fileNameExist ? (
            <IconButton onClick={clearClick} disabled={disabled}>
              <ClearIcon />
            </IconButton>
          ) : null}
          {loading ? <LoadingBox size={15} /> : null}
        </div>
      </fieldset>
    </InputBaseLabel>
  );
};

export default FileStreamInputView;
