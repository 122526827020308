import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '@SamianSoft/dropdown';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    width: '100%',
    height: '4rem',
    fontWeight: 700,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderBottom: '1px solid theme.palette.primary.appPrimaryBackgroundColor',
    padding: '0 40px',
    background: theme.palette.primary.appPrimaryBackgroundColor,
  },

  crMailInfo: {
    width: '100%',
    padding: '5px 0 8px 15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 400px)',
      padding: 0,
      paddingLeft: 16,
    },
  },

  subjectContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },

  crMailTime: {
    width: '100px',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
}));
