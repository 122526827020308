import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({});

function NextWeekIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M15.9997 4.9997h1v1h3.001v14H3.9997v-14h3v-1h1v1h8v-1zm-11 14h14v-9h-14v9zm12-10.999h-1v-1h-8v1h-1v-1h-2v2h14.001l-.001-2h-2v1z"></path>
      <path d="M13.3535 17.3535l2.854-2.854-2.854-2.853-.707.707 2.146 2.146-2.146 2.147zm-4 0l2.854-2.854-2.854-2.853-.707.707 2.146 2.146-2.146 2.147z"></path>
    </SvgIcon>
  );
}

export default withStyles(styles)(NextWeekIcon);
