import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmptyObject } from '../../../../helper/data-helper';
import {
  actorGetActionValue,
  actorSetActionValue,
} from '../../../../type/actor-setup';
import { FormData } from '../../../form';

import { WMSLayoutRow } from '../../wms.type';
import { WMSReportControllerProps } from './wms-report.type';
import WMSReportView from './wms-report.view';

/**
 * Check filter gird for show data.
 * @function showGridWithoutSearch
 * @returns {boolean}
 */
const showGridWithoutSearch = (
  layoutRows: Record<number, Array<WMSLayoutRow>>,
  formData: Record<string, unknown>,
): boolean => {
  if (isEmptyObject(formData)) return true;

  let shouldShowGrid = true;

  // FIXME: Check if `layoutRows` is null or undefined and do the same for `formData`
  // check all rows for required fields
  for (const key in layoutRows) {
    // -1 is hidden fields
    if (parseInt(key) === -1) {
      continue;
    }

    layoutRows[key].forEach(row => {
      if (
        row.required === true &&
        !Object.prototype.hasOwnProperty.call(formData, row.name)
      ) {
        shouldShowGrid = false;
      }
    });
  }

  return shouldShowGrid;
};

const WMSReportController = (props: WMSReportControllerProps): ReactElement => {
  const { reportMetaData, layoutRows, formTabIndex, activeTabIndex } = props;

  const reportId = reportMetaData.id;
  const reportType = reportMetaData.reportType;

  const [showGrid, setShowGrid] = useState({ [reportId]: false });
  const [filterValues, setFilterValues] = useState<FormData | null>(null);

  const resource = `report/${reportId}`;

  const dispatch = useDispatch();
  useEffect(() => {
    actorSetActionValue('metaData', reportMetaData, {
      path: resource,
      callerScopeName: 'WMSReportController',
    });

    dispatch({
      type: 'RA/REGISTER_RESOURCE',
      payload: {
        name: resource,
        options: {},
        hasList: true,
        hasEdit: false,
        hasShow: false,
        hasCreate: false,
      },
    });

    const rootResource = actorGetActionValue('resources')!.stack[0];
    if (rootResource) {
      const formData = actorGetActionValue(
        'formData',
        `${rootResource.value}.${rootResource.type}`,
      ) as FormData | null;

      setFilterValues(formData);
    }
  }, []);

  useEffect(() => {
    if (formTabIndex !== activeTabIndex) return;
    setShowGrid({ [reportId]: false });

    const rootResource = actorGetActionValue('resources')!.stack[0];

    const formData = actorGetActionValue(
      'formData',
      `${rootResource!.value}.${rootResource!.type}`,
    ) as FormData | null;

    requestAnimationFrame(() => {
      setFilterValues(formData);

      setShowGrid({
        [reportId]: showGridWithoutSearch(layoutRows, formData ?? {}),
      });
    });

    return () => clearSelectedGridItems(); // un-mount
  }, [reportId, activeTabIndex]);

  if (formTabIndex !== activeTabIndex) return <></>;

  // FIXME: Where can we get `setListSelectedIds`? from `actor`?
  const clearSelectedGridItems = () => {
    // setListSelectedIds(`report/${reportId}`, []);
  };

  // FIXME: We have to call this when needed
  const onSubmit = (formData: FormData) => {
    setShowGrid({ [reportId]: false });

    // when `formData` doesn't change, I want just re-render the grid to make new request with the same data
    setTimeout(() => {
      setFilterValues(formData);
      setShowGrid({ [reportId]: showGridWithoutSearch(layoutRows, formData) });

      const formGlobalProps = actorGetActionValue('formGlobalProps')!;
      formGlobalProps.formFocusManagementFunctions.focusOnFirstInputAfterSubmit();
    }, 100);
  };

  return (
    <WMSReportView
      resource={resource}
      reportId={reportId}
      reportType={reportType}
      showGrid={showGrid}
      reportMetaData={reportMetaData}
      filterValues={filterValues}
      onSubmit={onSubmit}
      formTabIndex={formTabIndex}
      activeTabIndex={formTabIndex}
    />
  );
};

export default WMSReportController;
