import produce from 'immer';
import { USER_LOGOUT } from 'react-admin';
import { OPEN_DIALOG, CLOSE_DIALOG } from './constant';

const initialState = {
  isDialogOpen: {},
};

const myReducer = (state = initialState, { type, resource }) =>
  produce(state, draft => {
    // eslint-disable-next-line default-case
    switch (type) {
      case USER_LOGOUT:
        draft.isDialogOpen = {};
        break;

      case CLOSE_DIALOG:
        draft.isDialogOpen = {};
        break;

      case OPEN_DIALOG:
        draft.isDialogOpen = {
          [resource]: true,
        };
        break;
    }
  });

export default myReducer;
