import {
  actorGetActionValue,
  RecordKeyMode,
  ResourceInterface,
} from '../type/actor-setup';

/**
 * get current resource from actor
 * @function getCurrentResource
 * @returns {ResourceInterface | null}
 */
export const getCurrentResource = (): ResourceInterface | null => {
  return actorGetActionValue('resources')?.current ?? null;
};

/**
 * get current record from actor
 * @function getCurrentRecord
 * @param {String} recordKeyMode
 * @returns {Object | null}
 */
export const getCurrentRecord = (
  recordKeyMode: RecordKeyMode = RecordKeyMode.FULL,
): Record<string, unknown> | null => {
  const currentResource = getCurrentResource();
  if (!currentResource) return null;

  return (actorGetActionValue(
    'record',
    `${currentResource.value}.${currentResource.type}.${recordKeyMode}`,
  ) ?? null) as Record<string, unknown> | null;
};
