import React, { FC, useEffect, useState } from 'react';
import lodashGet from 'lodash/get';
import { useLocale } from 'react-admin';
import compose from 'recompose/compose';
import {
  makeStyles,
  BottomNavigation,
  BottomNavigationAction,
  Icon,
  Typography,
  Badge,
} from '@material-ui/core';
import { connect } from 'react-redux';
import lodashSortBy from 'lodash/sortBy';
import { push } from 'connected-react-router';
import lodashFind from 'lodash/find';
import { withRouter } from 'react-router-dom';

import { findAllAction as findAllMenuAction } from '../../redux/menu/action';
import { refreshBasketAction } from '../../redux/shop/action';
import LoadingBox from '../LoadingBox';
import { isEmptyObject } from '../../helper/data-helper';
import { CustomTheme } from '../../core/themeProvider';

interface BottomNavigationMenuInterfaceProps {
  menuIsLoading: boolean;
  menuArray: MenuArray[];
  menuIsLoadedOnce: boolean;
  findAllMenu: Function;
  refreshBasket: Function;
  basketItemCount: number;
  redirectToPage: Function;
  location: Location;
}

interface Location {
  pathname: string;
}

interface MenuArray {
  url: string;
  id: number;
}

interface MenuItem {
  id: number;
  translatedTitle: object;
  icon: string;
  url: string;
  showInBottomMenu: boolean;
}

const styles = makeStyles((theme: CustomTheme) => ({
  bottomNavigation: {
    minHeight: 56,
    zIndex: 1,
    boxShadow:
      '0px -1px 1px -1px rgba(0,0,0,0.2), 0px 0px 1px 0px rgba(0,0,0,0.14), 0px 0px 3px 0px rgba(0,0,0,0.12)',
  },

  bottomNavigationActionRoot: {
    padding: '5px 0',
    minWidth: 'unset',
  },

  bottomNavigationActionSelected: {
    padding: 0,
    color: theme.palette.secondary.main,
    '& span': {
      color: theme.palette.secondary.main,
    },
  },

  label: {
    fontSize: 10,
  },

  badgeColorError: {
    color: `${theme.palette.primary.appSecondaryTextColor} !important`,
  },
}));

const sort = {
  field: 'updateDate',
  order: 'DESC',
};
const basketListResource = 'basket/getlist';
const basketListUrl = '#/cart/basket/getlist';

const emptyObject = {};
const emptyArray = [];

const BottomNavigationMenu: FC<BottomNavigationMenuInterfaceProps> = props => {
  const {
    menuIsLoading,
    menuArray,
    menuIsLoadedOnce,
    findAllMenu,
    redirectToPage,
    location,
    refreshBasket,
    basketItemCount,
  } = props;
  const classes = styles();
  const locale = useLocale();
  const [menuList, setMenuList] = useState(emptyArray);
  const [badgeList, setBadgeList] = useState(emptyObject);

  const FindSelectedMenu = () => {
    let menuId = 0;
    if (menuArray) {
      menuArray.forEach(menu => {
        if (!menu || !menu.url) {
          return;
        }
        const url = menu.url.split('#')[1];
        if (url === location.pathname) {
          menuId = menu.id;
        }
      });
    }
    return menuId;
  };

  const [bottomNavigationValue, setBottomNavigationValue] = useState(
    FindSelectedMenu(),
  );

  useEffect(() => {
    if (
      // if menu is not loaded once from api
      (!menuIsLoadedOnce && !menuIsLoading) ||
      // or it has not data
      (!menuIsLoading && (!menuArray || !menuArray.length))
    ) {
      findAllMenu();
    }

    if (menuArray) {
      const sortedList = lodashSortBy(menuArray, ['id']);
      setMenuList(sortedList);
    }
  }, [menuArray]);

  useEffect(() => {
    setBottomNavigationValue(FindSelectedMenu);
  }, [location]);

  useEffect(() => {
    refreshBasket({
      payload: {
        data: {
          parentResource: basketListResource,
          defaultSort: sort,
        },
      },
    });

    setBadgeList({ [basketListUrl]: basketItemCount });
  }, [basketItemCount]);

  if (!menuList) {
    return <LoadingBox size={50} />;
  }

  const handleChange = (event, newValue) => {
    setBottomNavigationValue(newValue);
    const menuItem = lodashFind(menuList, { id: newValue });
    if (!menuItem || !menuItem.url) {
      console.log('url on menu item not found:', menuItem);
      return;
    }

    const url = menuItem.url.split('#')[1];
    redirectToPage(url);
  };

  const customStyle = {
    root: classes.bottomNavigationActionRoot,
    selected: classes.bottomNavigationActionSelected,
  };

  const badgeCustomClasses = {
    colorError: classes.badgeColorError,
  };

  return (
    <BottomNavigation
      value={bottomNavigationValue}
      onChange={handleChange}
      showLabels
      className={classes.bottomNavigation}
    >
      {menuList.map((menu: MenuItem) => {
        return menu.showInBottomMenu ? (
          <BottomNavigationAction
            key={menu.id}
            label={
              <Typography className={classes.label} variant="caption">
                {lodashGet(menu, ['translatedTitle', locale], menu.id)}
              </Typography>
            }
            value={menu.id}
            icon={
              <Badge
                badgeContent={badgeList[menu.url]}
                color="error"
                showZero={false}
                classes={badgeCustomClasses}
              >
                <Icon fontSize="small">{menu.icon ? menu.icon : 'layers'}</Icon>
              </Badge>
            }
            classes={customStyle}
          />
        ) : null;
      })}
    </BottomNavigation>
  );
};

const mapStateToProps = state => {
  const basketList = state.admin.resources[basketListResource]
    ? state.admin.resources[basketListResource]
    : {};
  const basketItemCount =
    !isEmptyObject(basketList.list) && basketList.list.ids
      ? basketList.list.ids.length
      : 0;
  return {
    menuIsLoading: state.menu.isLoading,
    menuArray: state.menu.list,
    menuIsLoadedOnce: state.menu.isLoadedOnce,
    basketItemCount: basketItemCount,
  };
};

const mapDispatchToProps = {
  findAllMenu: findAllMenuAction,
  refreshBasket: refreshBasketAction,
  redirectToPage: push,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(BottomNavigationMenu);
