import { FC, memo, useCallback, useEffect, useState } from 'react';
import { ChangeFormValueParams, FormActions, OnBlurParams } from '../../form';
import { NoOptionsMessage, Placeholder } from '../../DropdownCommonComponents';
import { Control } from './control';
import { ClearIndicator } from './clear-indicator';
import { IndicatorSeparator } from './indicator-separator';
import { MultiValue } from './multi-value';
import { ValueContainer } from './value-container';
import { SelectPropsValueType, TagInputInterface } from './tag-input.type';
import TagInputView from './tag-input.view';
import { actorGetActionValue, actorOnDispatch } from '../../../type/actor-setup';

const TagInputController: FC<TagInputInterface> = memo(props => {
  //-----------------------------DESTRUCTURE PROPS--------------------------------------------------------
  const {
    label,
    hint,
    value,
    field,
    resource,
    inputMessage,
    visibleClass,
    disabled,
    getRef,
    ...rest
  } = props;
  const { name, required } = field;

  //-----------------------------State--------------------------------------------------------
  const [isResultsLosding, setIsResultsLosding] = useState<boolean>(false);
  const [resultList, setResultList] = useState<string[] | null>(null);

  //-----------------------------ACTOR--------------------------------------------------------

  const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;

  const currentResource = actorGetActionValue('resources')!.current;
  const allInputsRef = actorGetActionValue('inputsRef');
  const inputRef =
    allInputsRef?.[currentResource.value]?.[currentResource.type]?.[name];

  /** this function call actorOnDispatch to track tagInputData action
   *  and then set in states
   * @function getTagInputDataFromActor
   * @returns {void}
   */
  const getTagInputDataFromActor = useCallback(() => {
    actorOnDispatch('tagInputData', tagInputData => {
      setIsResultsLosding(tagInputData?.isLoading);
      setResultList(tagInputData?.resultList);
    });
  }, []);

  // trigger getTagInputDataFromActor()
  useEffect(() => {
    getTagInputDataFromActor();
  }, [getTagInputDataFromActor]);

  const currentValueArray = value && value.length ? value.split('#') : [];
  const preparedValue = currentValueArray.map(string => {
    return string && string.length ? { value: string, label: string } : null;
  });

  /**
   * Handle Change event
   * @function handleChange
   * @param {SelectPropsValueType} selectedItem
   * @returns void
   */
  const handleChange = (selectedItem: SelectPropsValueType[]): void => {
    const currentValueArray =
      selectedItem && selectedItem.length ? selectedItem : [];
    const value = currentValueArray.map(item => item.value).join('#');

    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value: value,
    } as ChangeFormValueParams);
  };

  /**
   * Handle Blur event
   * @function handleBlur
   * @returns {void} void
   */
  const handleBlur = (): void => {
    formActionsHandler(FormActions.InputBlur, {
      fieldName: name,
      value,
    } as OnBlurParams);
  };

  const components = {
    NoOptionsMessage,
    Placeholder,
    Control,
    MultiValue,
    IndicatorSeparator,
    ValueContainer,
    ClearIndicator,
  };

  return (
    <TagInputView
      {...rest}
      getRef={getRef}
      inputMessage={inputMessage}
      source={name}
      resource={resource}
      label={label}
      hint={hint}
      required={required}
      disabled={disabled}
      isLoading={isResultsLosding}
      resultList={resultList}
      preparedValue={preparedValue}
      components={components}
      handleChange={handleChange}
      handleBlur={handleBlur}
      visibleClass={visibleClass}
      field={field}
    />
  );
});

export default TagInputController;
