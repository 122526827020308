import { FC, memo, useEffect } from 'react';
import { ReferencesDataInterface } from '../../..';
import { isEmptyObject } from '../../../../../helper/data-helper';
import { findRoot } from './diagram-container.helper';

import { DiagramContainerInterface } from './diagram-container.type';
import DiagramContainerView from './diagram-container.view';

let rootItem: ReferencesDataInterface;
const DiagramContainerController: FC<DiagramContainerInterface> = memo(props => {
  const { data } = props;

  if (data && !isEmptyObject(data)) {
    rootItem = findRoot(data);
  }

  /**
   * to center the container when there are too many TreeNodes
   */
  useEffect(() => {
    const diagramContainer: HTMLElement | null =
      document.getElementById('diagramContainer');
    if (diagramContainer) {
      diagramContainer.scrollLeft +=
        diagramContainer.clientWidth - diagramContainer.clientWidth / 2.7;
    }
  }, []);

  return <DiagramContainerView data={data} rootItem={rootItem} />;
});

export default DiagramContainerController;
