import type { FieldType, MetaData } from '../../../helper/Types';
import type { InputMessage } from '../../form';

export interface TranslatedCaption {
  ar: string;
  en: string;
  fa: string;
}

export enum TextAlign {
  right = 'right',
  left = 'left',
}

export interface CodingValue {
  caption: string;
  defaultValue: string | null;
  name: string;
  translatedCaption: TranslatedCaption;
}

export interface CodingInputInterface {
  value: string;
  metaData: MetaData;
  field: FieldType;
  label: string;
  hint: string;
  textAlign: TextAlign;
  viewVersion: string;
  inputMessage?: InputMessage;
  visibleClass: string;
  customTestAttribute: Record<string, unknown>;
  getRef: (ref: unknown) => void;
  className?: string;
  disabled?: boolean;
}

export interface CodingInputViewInterface {
  field: FieldType;
  value: string;
  isLoading: boolean;
  inputMessage?: InputMessage;
  label: string;
  hint: string;
  maxLength: number | null;
  codingValues: CodingValue[];
  visibleClass: string;
  customTestAttribute: Record<string, unknown>;
  getRef: (ref: unknown) => void;
  handleBlur: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFocus: () => void;
  disabled?: boolean;
  className?: string;
}
