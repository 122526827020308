import React, { FC } from 'react';
import { Identifier } from 'react-admin';
import lodashDebounce from 'lodash/debounce';
import lodashFind from 'lodash/find';
import { makeStyles } from '@material-ui/core';

import CardListItemView from './CardListItemView';
import { ListViewProps } from '../../container/ListContainerApiController';
import { SellinListPatternType } from '../../helper/Types';
import CardListAction from './CardListAction';
import HeaderButtonPortal from '../HeaderButtonPortal';
import { CustomTheme } from '../../core/themeProvider';

interface CardListInterface extends ListViewProps {
  pattern: SellinListPatternType;
  onAddToCart: Function;
  onRemoveFromCart: Function;
  basketResource: string;
  basketIds: Identifier[];
  basketData: { [key: string]: object };
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    height: '100%',
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
  },
}));

const ProductCardListView: FC<CardListInterface> = props => {
  const {
    ids,
    data,
    pattern,
    onAddToCart,
    onRemoveFromCart,
    setFilters,
    filterValues,
    setSort,
    currentSort,
    basketData,
  } = props;
  const classes = useStyles();

  const debouncedOnAddToCart = lodashDebounce(onAddToCart, 500);

  return (
    <div className={classes.container}>
      <HeaderButtonPortal
        button={
          <CardListAction
            pattern={pattern}
            setFilters={setFilters}
            filterValues={filterValues}
            setSort={setSort}
            currentSort={currentSort}
          />
        }
      />

      <div>
        {ids.map(id => {
          const basketInfo = lodashFind(basketData, [
            `${pattern.basketProductId}`,
            id,
          ]);
          return (
            <CardListItemView
              key={id}
              record={data[id]}
              pattern={pattern}
              onAddToCart={debouncedOnAddToCart}
              onRemoveFromCart={onRemoveFromCart}
              basketInfo={basketInfo}
              count={
                basketInfo && basketInfo[pattern.count] > 0
                  ? basketInfo[pattern.count]
                  : 0
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProductCardListView;
