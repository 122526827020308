import {
  RequestParametersInterface,
  GridDataInterface,
  actorSetActionValue,
  actorDispatch,
} from './../../../type/actor-setup';
import { actorGetActionValue } from '../../../type/actor-setup';

/**
 * @function getGridData
 * @param {string} resource
 * @param {Record<string, unknown>} data
 * @returns {void}
 */
export const getGridData = (
  resource: string,
  data: Record<string, unknown>,
): void => {
  const currentGridInfo = actorGetActionValue(
    'gridData',
    resource,
  ) as GridDataInterface;

  const gridData = currentGridInfo?.data ?? [];
  const _newData = [data ?? {}, ...gridData];

  const validPerPage =
    (currentGridInfo?.requestParameters as RequestParametersInterface)?.pagination
      .perPage ?? 25;

  // remove extra data
  if (_newData.length > validPerPage) {
    _newData.splice(validPerPage - 1, 10);
  }

  // set totalCount
  actorSetActionValue('gridData', _newData.length, {
    path: `${resource}.totalCount`,
  });

  actorSetActionValue('gridData', (Math.random() * 10000).toFixed(), {
    path: `${resource}.lastRequestId`,
  });

  actorDispatch('gridData', _newData, {
    path: `${resource}.data`,
    disableDebounce: true,
  });
};
