import dataProvider, { GET_REPORT, RUN_SERVICE } from '../core/dataProvider';
import { actorOnDispatch } from '../type/actor-setup';

actorOnDispatch(
  'getMailReport',
  async action => {
    const { params, successCallback, failureCallback } = action;
    try {
      const response = await dataProvider(GET_REPORT, '', params);
      successCallback({ data: response?.data ?? {}, total: response.total });
    } catch (error) {
      failureCallback(error);
    }
  },
  {
    preserve: false,
  },
);

actorOnDispatch(
  'runMailService',
  async action => {
    const { params, successCallback, failureCallback } = action;
    try {
      const response = await dataProvider(RUN_SERVICE, '', params);
      successCallback(response?.data ?? {});
    } catch (error) {
      failureCallback(error);
    }
  },
  {
    preserve: false,
  },
);
