import lodashFindIndex from 'lodash/findIndex';
import lodashFind from 'lodash/find';
import lodashGet from 'lodash/get';
import { clone, isEmpty, isEmptyObject } from './data-helper';
import { getParamFromUrl } from './UrlHelper';

export const prepareGroupList = (list: [], todoPattern, idListMember) => {
  if (
    !list ||
    list.length === 0 ||
    isEmptyObject(todoPattern) ||
    !idListMember ||
    !idListMember.length
  ) {
    return;
  }

  const { groupName, rowOrder, id: listId, groupId } = todoPattern;
  const sortedList: any = clone(list).sort((a, b) => {
    return a[rowOrder] - b[rowOrder];
  });
  const preparedGroup: any = [];
  sortedList.forEach(item => {
    // Add item as LIST
    if (!item[groupId] && item[listId]) {
      preparedGroup.push(item);
      return;
    }

    // Add Item as a list in a GROUP
    const index = lodashFindIndex(preparedGroup, [groupId, item[groupId]]);
    if (index > -1) {
      if (item[listId]) {
        preparedGroup[index].listChild.push(item);
      }
      return;
    }

    // Add Item as Group
    if (item[groupId]) {
      preparedGroup.push({
        [groupName]: item[groupName],
        [groupId]: item[groupId],
        listChild: item[listId] !== null ? [item] : [],
        [rowOrder]: item[rowOrder],
        [idListMember]: item[idListMember],
      });
    }
  });

  return preparedGroup;
};

export const largestRowOrder = (list, rowOrder) => {
  if (list && list.length) {
    return list.reduce((max, item) => (max[rowOrder] > item[rowOrder] ? max : item))[
      rowOrder
    ];
  } else {
    return 0;
  }
};

export const prepareTaskList = (data, ids, hideCompleted) => {
  if (!data || isEmptyObject(data)) {
    return null;
  }

  if (hideCompleted) {
    return ids.filter(key => !data[key].isdone).map(key => data[key]);
  } else {
    return ids.map(key => data[key]);
  }
};

/**
 * Find `selectedTodoList` from `todoList` whit `todoListId`
 * @param {object} pattern
 * @param {object} todoList
 * @param {object} selectedListMember
 * @param {string} listId
 * @returns {object}
 */
export const findSelectedTodoList = (
  pattern: { listId: string },
  todoList: object,
  selectedListMember: object,
  listId: string,
): undefined | object => {
  if (!isEmptyObject(selectedListMember)) {
    return selectedListMember;
  }

  return lodashFind(todoList, { [pattern.listId]: +listId });
};

/**
 * Find todo list id from url.
 * @function findTodoListId
 * @param {object} pattern
 * @param {string} urlString
 * @returns {void | string}
 */
export const findTodoListId = (
  pattern: { listId: string },
  urlString: string,
): void | string => {
  if (!isEmpty(urlString)) {
    const filter = getParamFromUrl(urlString, 'filter');
    if (!isEmpty(filter)) {
      const parsedFilter = JSON.parse(filter!);
      const listFilter = lodashGet(parsedFilter, pattern.listId);
      if (listFilter) {
        return listFilter[2];
      } else {
        return;
      }
    }
  }
};

/**
 * Returns sort paramteres to add into URL
 * @function getSortParams
 * @param {object} sort field name & order of current sort
 * @returns {string} URL parameters
 */
export const getSortParams = (
  sort: { field?: string; order?: string } = {},
): string => {
  const { field, order } = sort;
  let params = '';

  if (field) {
    params += `&sort=${field}`;
  }

  if (order) {
    params += `&order=${order}`;
  }

  return params;
};
