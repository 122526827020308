import querystring from 'qs';
import { getFieldByName, getFormDefaultValue } from '../../helper/MetaHelper';
import {
  clone,
  isEmpty,
  isEmptyObject,
  logErrorToGraylog,
} from '../../helper/data-helper';
import { findFieldByName } from '../../helper/meta-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorRemoveAction,
  actorSetActionValue,
  FormKeyMode,
  RecordKeyMode,
  ResourceInterface,
  URLInfo,
} from '../../type/actor-setup';
import { FieldType, MetaData, ValidationError } from '../../helper/Types';
import {
  handleServerSideValidationErrors,
  validateClientSideFields,
} from '../../helper/validation-helper';

import {
  AfterValidationInputs,
  FormData,
  InitialData,
  InputRefContent,
  CheckValidationErrorsInApiResponse,
  ExtractAllFieldsFromTabList,
  ValidateInput,
  CheckFormInputsSpecsInterface,
} from './form.type';
import dataProvider, {
  GET_DEFAULT_FORM_VALUES,
  GET_DROPDOWN_DEFAULT_VALUES,
} from '../../core/dataProvider';
import { getTypeByField, MULTI_FILE_STREAM_FIELD } from '../../helper/InputHelper';
import { ParentInfo } from './form-with-tabs';
import { checkUiControl } from './form-with-tabs/form-with-tabs.helper';

/**
 * Prepare override param with url.
 * @function prepareOverrideParams
 * @returns {Record<string,unknown>}
 */
export const prepareOverrideParams = (): Record<string, unknown> => {
  const urlParams = window.location.href.split('?')[1];
  return querystring.parse(urlParams, { strictNullHandling: true });
};

/**
 * the validation adapter between dynamic input and main validation function in validation helper
 * @function validateInput
 * @param {string} fieldName
 * @param {unknown} value
 * @param {object} validationParams the required parameters for validation function
 * @param {React.MutableRefObject<Record<string, ValidationError> | undefined>} validationParams.validationErrorsRef
 * @param {React.MutableRefObject<Record<string, unknown> | undefined>} validationParams.formDataRef
 * @param {MetaData} validationParams.metaData
 * @param {Array<FieldType>} validationParams.allFields
 * @param {ShowNotification} validationParams.showNotification
 * @param {Translate} validationParams.translate
 * @param {Locale} validationParams.locale
 * @param {string} validationParams.resource
 * @param {string} validationParams.relationResource
 * @param {ChangeFormValue} validationParams.changeFormValueWithoutValidate
 * @param {boolean} validationParams.isQuickForm
 * @returns {boolean} promise of boolean
 */
export const validateInput: ValidateInput = async (
  fieldName,
  value,
  validationParams,
) => {
  const {
    validationErrorsRef,
    formDataRef,
    metaData,
    allFields,
    showNotification,
    translate,
    locale,
    isQuickForm,
  } = validationParams;

  const prevValidationErrors = validationErrorsRef?.current ?? {};
  const prevFormData = formDataRef?.current ?? {};
  const fieldToValidate = findFieldByName(metaData, allFields, fieldName);
  const fieldType = fieldToValidate ? getTypeByField(fieldToValidate) : null;
  const singleFieldInListStyle = fieldToValidate ? [fieldToValidate] : [];
  const formData = { ...prevFormData, [fieldName]: value };

  const validationErrors = validateClientSideFields(
    prevValidationErrors,
    formData,
    singleFieldInListStyle,
    showNotification,
    translate,
    locale,
    isQuickForm,
    false,
  );

  validationErrorsRef.current = validationErrors;
  return [!validationErrors[fieldName], validationErrors, fieldType];
};

/**
 * @function checkValidationErrorsInApiResponse
 * @param {} apiResponse
 * @returns {}
 */
export const checkValidationErrorsInApiResponse: CheckValidationErrorsInApiResponse =
  (formData, apiResponse, validationParams) => {
    const currentResource = actorGetActionValue('resources')!.current;
    const { metaData, allFields, showNotification, translate, locale } =
      validationParams;

    const computedValidationErrors = handleServerSideValidationErrors(
      metaData,
      allFields,
      apiResponse,
      formData,
      showNotification,
      translate,
      locale,
    );

    actorDispatch(
      'formMessages',
      computedValidationErrors.preparedValidationErrors ?? {},
      {
        path: `${currentResource.value}.${currentResource.type}`,
      },
    );
  };

export const emptyArrowFunction = (): void => {
  console.log('empty arrow function called');
};

export function emptyFunction(): void {
  console.log('empty function called');
}

/**
 * it will extract field from each tab
 * @function extractAllFieldsFromTabList
 * @param {Array<Tab>} tabs
 * @param {boolean} isQuickForm
 * @returns {Array<FieldType>}
 */
export const extractAllFieldsFromTabList: ExtractAllFieldsFromTabList = tabs => {
  const fieldList: Array<FieldType> = [];

  tabs?.forEach(tab => {
    tab?.groupList.forEach(group => {
      group?.layout?.forEach(layout => {
        layout?.forEach(field => {
          if (field && field !== 'empty') {
            fieldList.push(field);
          }
        });
      });
    });
  });

  return fieldList;
};

/**
 * this function handle value and errors after validation services run
 * @function afterValidationInputs
 * @param {[boolean, Record<string, ValidationError>]}validationResult
 * @param {string} fieldName string
 * @param {unknown} value Can be any type, boolean, string, array, etc...
 * @returns {void} void
 */
export const afterValidationInputs: AfterValidationInputs = (
  validationResult,
  fieldName,
) => {
  const inputsRef = actorGetActionValue('inputsRef')!;
  const checkTabErrors = actorGetActionValue('checkTabErrors');
  const currentResource = actorGetActionValue('resources')!.current;
  const [isFieldValid, fieldErrorMessageList] = validationResult;

  if (!isFieldValid) {
    const { message: fieldErrorMessage, errorMessageType } =
      fieldErrorMessageList[fieldName];
    // Set error message and don't touch `formData`
    inputsRef?.[currentResource.value]?.[currentResource.type][
      fieldName
    ].setInputMessage({ message: fieldErrorMessage, messageType: errorMessageType });

    actorSetActionValue(
      'formMessages',
      { ...fieldErrorMessageList[fieldName] },
      {
        path: `${currentResource.value}.${currentResource.type}.${fieldName}`,
      },
    );
  } else {
    // Ok, the input value is valid, update `formData`
    inputsRef?.[currentResource.value]?.[currentResource.type][
      fieldName
    ].setInputMessage(undefined);

    actorRemoveAction({
      actionName: 'formMessages',
      path: `${currentResource.value}.${currentResource.type}.${fieldName}`,
    });

    const formMessages = actorGetActionValue('formMessages', [
      currentResource.value,
      currentResource.type,
    ]) as Record<string, ValidationError> | null;

    if (formMessages) {
      actorSetActionValue('formMessages', formMessages, {
        path: `${currentResource.value}.${currentResource.type}`,
      });
    }
  }

  if (typeof checkTabErrors === 'function') {
    checkTabErrors();
  }
};

/**
 * Remove default values from formData to prevent sending them to API
 * @param formData FormData before save
 * @returns {Record<string, unknown>} cleaned formData from defaultValues
 */
export const sanitizeFormData = (
  formData: Record<string, unknown>,
): Record<string, unknown> => {
  const cleanData = clone(formData);

  const currentResource = actorGetActionValue('resources')!.current;

  const quickCreateSupplementaryData = actorGetActionValue(
    'quickCreateSupplementaryData',
    `${currentResource.value}.${currentResource.type}`,
  );

  if (!isEmptyObject(quickCreateSupplementaryData)) {
    for (const key in quickCreateSupplementaryData) {
      cleanData[key] = quickCreateSupplementaryData[key];
    }
  }

  return cleanData;
};

/**
 * it should call data provider with type `GET_DROPDOWN_DEFAULT_VALUES` and remove
 * process information keys from response then return the result
 * @param {string} resource
 * @param {string} processUniqueId
 * @param {string} urlParameters
 * @returns {Promise<Record<string,unknown>}
 */
export const getDropdownDefaultValue = async (
  resource: string,
  processUniqueId = '',
  urlParameters = '',
): Promise<Record<string, unknown>> => {
  const result = await dataProvider(GET_DROPDOWN_DEFAULT_VALUES, resource, {
    processUniqueId,
    urlParameters,
  }).catch(error => {
    console.log('form.helper.ts:286 detDropdownDefaultValue error: ', error);
    return {};
  });

  delete result?.data?.stateid;
  delete result?.data?.positionid;

  return result?.data ?? {};
};

/**
 * this function switch between type of default form values
 * @function getDefaultValuesFromApi
 * @param {string} resource
 * @param {Record<unknown,unknown>} option
 * @returns {Promise<Record<string, unknown>>}
 */
export const getDefaultValuesFromApi = async (
  resource: string,
  option: Record<string, unknown>,
): Promise<Record<string, unknown>> => {
  const result = await dataProvider(GET_DEFAULT_FORM_VALUES, resource, option).catch(
    error => {
      console.log('`getDefaultValuesFromApi` Error: ', error);
      throw error;
    },
  );

  actorSetActionValue(
    'recordAdditionalData',
    (result as { additionalData: Record<string, unknown> })?.additionalData,
    {
      path: resource,
    },
  );

  delete result?.data?.stateid;
  delete result?.data?.positionid;

  actorSetActionValue('record', result?.data, {
    path: `${resource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
  });

  actorSetActionValue('formData', result?.data, {
    path: `${resource}.${FormKeyMode.ROOT}`,
  });

  return result?.data ?? {};
};

/**
 * it will look for arrays related to file stream multiple fields and convert them to json
 * @function convertMultiFileStreamArraysToJson
 * @param {object} formData
 * @param {object} metaData
 * @returns {void} void
 */
export const convertMultiFileStreamArraysToJson = (
  formData: FormData,
  metaData: MetaData,
): void => {
  if (isEmptyObject(formData) || isEmptyObject(metaData)) return;

  for (const fieldName in formData) {
    const field = getFieldByName(metaData, fieldName);

    if (isEmptyObject(field)) continue;

    if (getTypeByField(field) === MULTI_FILE_STREAM_FIELD) {
      formData[fieldName] = JSON.stringify(formData[fieldName]);
    }
  }
};

/**
 * Get all default values of a form.
 * @function getDefaultValues
 * @returns
 */
export const getFormDefaultValues = async (
  urlInfo: URLInfo,
  parentInfo: ParentInfo,
  allFields: FieldType[],
  globalParameters: Record<string, unknown> | null,
  resource: ResourceInterface,
  isCreateMode: boolean,
): Promise<Record<string, unknown> | null> => {
  try {
    const currentUrl = urlInfo.location.href.split('?')[1];

    const urlParameters =
      querystring.parse(currentUrl, { ignoreQueryPrefix: true }) ?? {};
    const processUniqueId = urlParameters.__processuniqueid;

    delete urlParameters?.__processuniqueid;
    delete urlParameters?.stateid;
    delete urlParameters?.positionid;

    const stringUrlParameters = querystring.stringify(urlParameters);
    const { parentId, parentResource } = parentInfo;

    return (await getFormDefaultValue(
      allFields,
      globalParameters,
      {
        processUniqueId,
        stringUrlParameters,
        resource: (resource as ResourceInterface).value,
        parentResource,
        parentId,
      },
      isCreateMode as boolean,
    )) as Record<string, unknown>;
  } catch (error) {
    console.error('`getFormDefaultValues` Error: %o', error);
    if (typeof error === 'string') {
      throw error;
    }

    const _error = error as Record<string, unknown>;
    if (typeof _error?.message === 'string') {
      throw _error.message;
    }

    logErrorToGraylog(JSON.stringify(_error), {});
  }

  return null;
};

/**
 * Finding input values from `formData`, if corresponding input name found in `formErrors`, replace it
 * @function updateInputsState
 * @param {object} inputsRef
 * @param {object} formErrors
 * @param {object} formData
 * @returns {void}
 */
export const updateInputsState = (
  inputsRef: Record<string, InputRefContent> | null | undefined,
  formErrors: Record<string, ValidationError> | undefined,
  formData: FormData | InitialData,
  isWMSForm = false,
): void => {
  if (isEmptyObject(inputsRef)) {
    return;
  }

  for (const inputName in inputsRef) {
    if (formErrors?.[inputName]) {
      inputsRef[inputName].setInputMessage?.({
        message: formErrors![inputName].message ?? '',
        messageType: 'error',
      });
      inputsRef[inputName].setInputValue?.(formErrors![inputName].value ?? '');
      continue;
    }

    inputsRef[inputName]?.setInputMessage?.(undefined);

    let finalValue: unknown = null;

    if (isWMSForm && formData?.[inputName] == null) {
      continue;
    }

    if (!isEmpty(formData?.[inputName])) {
      if (typeof formData[inputName] === 'boolean') {
        finalValue = Boolean(Number(formData[inputName] ?? 0));
      } else {
        finalValue = formData[inputName];
      }
    } else {
      // TODO: We have to handle `value = null` into all inputs to change them to the blank state
      finalValue = '';
    }

    inputsRef[inputName].setInputValue?.(finalValue);

    if (!isWMSForm) {
      checkUiControl(inputName);
    }
  }
};

/**
 * It handle buttons `disabled` status
 * @param { currentResource: ResourceInterface, disabled?: boolean } options
 * @returns { void }
 */
export const changeFormToolbarDisableState = (options: {
  currentResource: ResourceInterface;
  disabled?: boolean;
}): void => {
  const { currentResource, disabled } = options;
  const buttonList = actorGetActionValue(
    'quickCreateButtonToolbar',
    `${currentResource.value}.${currentResource.type}`,
  )! as Record<string, HTMLButtonElement | undefined>;

  if (!isEmpty(buttonList)) {
    for (const buttonRef of Object.values(buttonList)) {
      if (disabled) {
        buttonRef?.setAttribute('disabled', '');
      } else {
        buttonRef?.removeAttribute('disabled');
      }
    }
  }
};

/**
 * @function checkFormInputsSpecs
 * @param { Record<string, InputRefContent> } inputsRef
 * @returns {
 *  keepValueAfterSubmitFieldNameList: string[],
 *  keepFocusAfterSubmitFieldNameList:  string[],
 *  ignoreToFocusFieldNameList: string[],
 *  inputNameListSortedByPriority: string[]
 * } an object
 */
export const checkFormInputsSpecs = (
  inputsRef: Record<string, InputRefContent>,
): CheckFormInputsSpecsInterface => {
  const keepValueAfterSubmitFieldNameList: Array<string> = [];
  const keepFocusAfterSubmitFieldNameList: Array<string> = [];
  const ignoreToFocusFieldNameList: Array<string> = [];

  const inputsFieldNameList = Object.keys(inputsRef);
  inputsFieldNameList.sort(
    (inputName1, inputName2) =>
      inputsRef[inputName1].field.priority - inputsRef[inputName2].field.priority,
  );

  if (isEmptyObject(inputsRef)) {
    return {
      keepValueAfterSubmitFieldNameList,
      keepFocusAfterSubmitFieldNameList,
      ignoreToFocusFieldNameList,
      inputNameListSortedByPriority: inputsFieldNameList,
    };
  }

  for (const inputFieldName of inputsFieldNameList) {
    if (inputsRef[inputFieldName] == null) continue;

    const { keepFocusAfterSubmit, keepValueAfterSubmit, readOnly } =
      inputsRef[inputFieldName].field;

    if (keepValueAfterSubmit) {
      keepValueAfterSubmitFieldNameList.push(inputFieldName);
    }

    if (keepFocusAfterSubmit) {
      keepFocusAfterSubmitFieldNameList.push(inputFieldName);
    }

    if (readOnly) {
      ignoreToFocusFieldNameList.push(inputFieldName);
    }
  }

  return {
    keepValueAfterSubmitFieldNameList,
    keepFocusAfterSubmitFieldNameList,
    ignoreToFocusFieldNameList,
    inputNameListSortedByPriority: inputsFieldNameList,
  };
};
