import React from 'react';
import HorizontalScrollCardListView from './shop/HorizontalScrollCardListView';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ImageGallery from 'react-image-gallery';

import ListContainerApiSimpleController from '../container/ListContainerApiSimpleController';
import { arrayResultToObjectWithLowerCase } from '../helper/data-helper';
import { getAdvertisementPatternInfo } from '../helper/PatternMetaHelper';
import { getValue, API_URL } from '../core/configProvider';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    overflow: 'auto',

    '& .image-gallery-slide img': {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      '& .image-gallery': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .image-gallery-slide img': {
        width: 'auto',
        height: 300,
      },
    },
  },
}));

const DashboardAdvertiseView = props => {
  const { ids, data, pattern, location } = props;
  const classes = useStyles();

  const slideData = [];
  const apiUrl = getValue(API_URL);

  return (
    <div className={classes.container}>
      {ids.map((id, index) => {
        const item = data[id];

        switch (item.type) {
          case 'slide':
            arrayResultToObjectWithLowerCase(item.content).forEach(item => {
              slideData.push({
                original: `${apiUrl}/${item[pattern.imagePath]}/${
                  item[pattern.imageFileName]
                }`,
                id: item[pattern.id],
              });
            });

            return (
              <ImageGallery
                key={item.id}
                items={slideData}
                showFullscreenButton={false}
                showPlayButton={false}
                showThumbnails={false}
                showBullets={slideData.length > 1}
                useBrowserFullscreen={false}
                isRTL={true}
                autoPlay={true}
                slideDuration={1000}
                slideInterval={4000}
              />
            );

          case 'product':
            if (typeof item.content[pattern.productTagId] !== 'undefined') {
              return (
                <ListContainerApiSimpleController
                  key={item.id}
                  location={location}
                  basePath={`/${pattern.productTagResource}`}
                  page={1}
                  perPage={1000}
                  resource={pattern.productTagResource}
                  resourceId={`${pattern.productTagResource}_${index}`}
                  classes={classes}
                  filter={{
                    parentId: item.content[pattern.productTagId],
                    justavailablenumber: false,
                  }}
                  sort={pattern.productTagResourceSort}
                >
                  {params => (
                    <HorizontalScrollCardListView
                      {...params}
                      pattern={getAdvertisementPatternInfo(
                        pattern.productTagResource,
                      )}
                      title={item[pattern.slideTitle]}
                    />
                  )}
                </ListContainerApiSimpleController>
              );
            }
            return <div key={item.id} />;

          default:
            return <div key={item.id} />;
        }
      })}
    </div>
  );
};

export default DashboardAdvertiseView;
