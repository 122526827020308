import React, { FC, useEffect, useState } from 'react';

import { showNotification } from '../../helper/general-function-helper';
import { isEmptyObject } from '../../helper/data-helper';
import { FieldType } from '../../helper/Types';
import PivotTableView from './pivot-table.view';
import { getGridColumns } from '../../helper/MetaHelper';
import lodashMap from 'lodash/map';
import LoadingBox from '../LoadingBox';
import {
  DefaultSettingInterface,
  PivotTableControllerPropsInterface,
} from './pivot-table.type';
import lodashGet from 'lodash/get';
import lodashFilter from 'lodash/filter';
import { useTranslate } from 'react-admin';

import { useLocale } from 'react-admin';
import {
  CONFIG_PIVOT_FIELD_CHOOSER_SETTING,
  getValue,
  USER_ID,
} from '../../core/configProvider';
import { getAppSettings, setAppSettings } from '../../helper/settings-helper';

//fixme: remove partial from interface
const PivotTableController: FC<
  Partial<PivotTableControllerPropsInterface>
> = props => {
  const { metaData, data, resource } = props;

  const [gridFieldsList, setGridFieldsList] = useState<
    Array<Record<string, unknown>>
  >([]);
  const locale = useLocale();
  const translate = useTranslate();

  useEffect(() => {
    getFields();
  }, []);

  /**
   * @function getFields
   * @returns {Promise<void>}
   */
  const getFields = async (): Promise<void> => {
    if (!isEmptyObject(metaData)) {
      const columns = getGridColumns({ metaData });

      const defaultFieldListFromSetting = getAppSettings(getKeySetting()).value;

      const fields: Array<Record<string, unknown>> = [];
      let defaultSetting: DefaultSettingInterface;

      lodashMap(columns, row => {
        if (!isEmptyObject(row) && row.relatedName != '' && row.caption != '') {
          defaultSetting = {};
          if (
            Array.isArray(defaultFieldListFromSetting) &&
            defaultFieldListFromSetting.length > 0
          ) {
            const { area, areaIndex } = lodashFilter(
              defaultFieldListFromSetting,
              setting => setting.dataField == row.relatedName,
            )[0];

            if (area) {
              defaultSetting = {
                area: area,
                areaIndex: areaIndex,
              };
            }
          }

          fields.push({
            caption: lodashGet(row, ['translatedCaption', locale], row.name),
            dataField: row.relatedName,
            summaryType: 'sum',
            format: ',##0.###', // 123,456.789
            ...defaultSetting,
          });
        }
      });
      setGridFieldsList(fields);
    }
  };

  /**
   * @function getData
   * @returns {Record<string, unknown> | Array<Record<string, unknown>>}
   */
  const getData = (): Record<string, unknown> | Array<Record<string, unknown>> => {
    if (isEmptyObject(data)) {
      return {};
    }

    const preparedData = [{}];

    lodashMap(data, row => {
      if (row.id) preparedData.push(row);
    });

    return preparedData;
  };

  /**
   * get key for chooser config
   * @function getKeySetting
   * @returns {void}
   */
  const getKeySetting = (): string => {
    const userId = getValue(USER_ID);
    return userId + '_' + CONFIG_PIVOT_FIELD_CHOOSER_SETTING + '_' + resource;
  };

  /**
   * show notification callback
   * @function showSucceedMessage
   * @returns {void}
   */
  const showSucceedMessage = (): void => {
    showNotification(translate('pivot.settingSavedSuccessfully'), 'success');
  };

  /**
   * save grid setting in webSetting
   * @function onSaveSetting
   * @param {FieldType} fields
   * @returns {void}
   */
  const onSaveSetting = (fields: FieldType): void => {
    setAppSettings({
      key: getKeySetting(),
      value: fields,
      onSuccess: showSucceedMessage,
    });
  };

  if (isEmptyObject(metaData) || isEmptyObject(data) || gridFieldsList === null) {
    return <LoadingBox />;
  }

  return (
    <PivotTableView
      fields={gridFieldsList}
      data={getData()}
      resource={resource}
      saveSetting={onSaveSetting}
    />
  );
};

export default PivotTableController;
