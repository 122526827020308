import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { getIconClassName } from '@uifabric/styling';

import { TODO } from '../../core/configRouteConstant';
import { getTodoListPatternInfo } from '../../helper/PatternMetaHelper';
import { getSortParams } from '../../helper/TodoListHelper';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 15px',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 200ms',
    '&:hover': {
      backgroundColor: '#FAFAFA',
      transition: 'background-color 200ms',
    },
  },
  linkContainer: {
    textDecoration: 'none',
  },

  title: {
    flexGrow: 1,
  },

  icon: {
    margin: '0 10px 0 0',
    fontSize: 18,
    padding: 2,
  },
}));

const TodoMenuItem = props => {
  const {
    title,
    icon,
    resource,
    filterType,
    color,
    todoListSelectedFilter,
    todoListSelectedId,
    onClick,
    userSort,
  } = props;
  const classes = useStyles();
  const { staticFilterList, reportTaskDetailsResource } =
    getTodoListPatternInfo(resource);
  const { filterName, listId } = staticFilterList;

  const [url, setUrl] = useState(`{"${listId}":["${listId}","is","null"]}`);

  useEffect(() => {
    setUrl(
      `#/${TODO}/${resource}?detail=${reportTaskDetailsResource}&filter=${filterUrl()}${getSortParams(
        userSort,
      )}`,
    );
  }, [userSort]);

  useEffect(() => {
    setUrl(
      `#/${TODO}/${resource}?detail=${reportTaskDetailsResource}&filter=${filterUrl()}`,
    );
  }, [resource, filterType]);

  const overrideStyle = useMemo(
    () => ({
      color,
    }),
    [color],
  );

  const selectedStyle = useMemo(() => {
    return {
      backgroundColor:
        isNaN(todoListSelectedId) &&
        ((todoListSelectedFilter && filterType === todoListSelectedFilter) ||
          (!todoListSelectedFilter && !filterType))
          ? '#e4eaec'
          : 'unset',
    };
  }, [todoListSelectedId, filterType, todoListSelectedFilter]);

  const filterUrl = () => {
    if (!filterType) return `{"${listId}":["${listId}","is","null"]}`;

    if (filterType === 'DueDate') {
      return `{"${filterName}":["DueDate","isnot","null"]}`;
    } else {
      return `{"${filterName}":["${filterType}","=","true"]}`;
    }
  };

  return (
    <a
      className={classes.linkContainer}
      href={url}
      onClick={onClick}
      data-test-todo-menu={title}
      data-test-todo-menu-icon={icon}
    >
      <div className={classes.container} style={selectedStyle}>
        <i
          className={`${classes.icon} ${getIconClassName(icon)}`}
          style={overrideStyle}
        ></i>

        <Typography
          variant="caption"
          className={classes.title}
          style={overrideStyle}
        >
          {title}
        </Typography>
      </div>
    </a>
  );
};

TodoMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  selected: PropTypes.bool,
  resource: PropTypes.string,
  filterType: PropTypes.string,
  todoListSelectedFilter: PropTypes.string,
  todoListSelectedId: PropTypes.number,
  onClick: PropTypes.func,
  userSort: PropTypes.object,
};

TodoMenuItem.defaultProps = {
  color: 'rgba(0, 0, 0, 0.54)',
};

export default TodoMenuItem;
