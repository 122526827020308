import { OPEN_DIALOG, CLOSE_DIALOG } from './constant';

interface OpenDialogAction {
  resource: string;
  data: object;
  dropdownId: number | null;
  source: string | null;
  mustRefresh: boolean;
  redirect: object | null;
  onCreate: Function;
  disabledFieldList: object | null;
  parentInfo: object | null;
  dropdownMeta: object | null;
}

export function openDialogAction({
  resource,
  data = {},
  dropdownId = null,
  source = null,
  mustRefresh = false,
  redirect = null,
  onCreate = () => {},
  disabledFieldList = null,
  parentInfo = null,
  dropdownMeta = null,
}: OpenDialogAction): object {
  return {
    type: OPEN_DIALOG,
    resource,
    data,
    dropdownId,
    source,
    mustRefresh,
    redirect,
    onCreate,
    disabledFieldList,
    parentInfo,
    dropdownMeta,
  };
}

export function closeDialogAction() {
  return {
    type: CLOSE_DIALOG,
  };
}
