import { FC, memo } from 'react';
import { ChatItemInterface } from './chat-item.type';
import ChatItemView from './chat-item.view';

const ChatItemController: FC<ChatItemInterface> = memo(props => {
  const { selectedUser, item, onUserSelect } = props;

  /**
   * @function handleOnUserSelect
   * @returns { void }
   */
  const handleOnUserSelect = (): void => {
    onUserSelect(item);
  };

  return (
    <ChatItemView
      selectedUser={selectedUser}
      item={item}
      handleOnUserSelect={handleOnUserSelect}
    />
  );
});

export default ChatItemController;
