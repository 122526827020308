import {
  FC,
  useState,
  useEffect,
  memo,
  useCallback,
  useMemo,
  createRef,
} from 'react';
import { isEmpty } from '../../../helper/data-helper';
import { actorGetActionValue } from '../../../type/actor-setup';
import { FormActions } from '../../form';
import lodashDebounce from 'lodash/debounce';

import { StringselectInputInterface } from './stringselect-input.type';
import StringselectInputView from './stringselect-input.view';
import { DropdownOption, SpecialDropdownVariables } from '../auto-complete-input';
import { convertToStringValue } from '../auto-complete-input/auto-complete-input.helper';
import { replaceFarsiCharactersWithArabic } from '../../../helper/TextHelper';

let tempRefTags;

const StringselectInputController: FC<StringselectInputInterface> = memo(props => {
  //-----------------------------DESTRUCTURE PROPS--------------------------------------------------------
  const {
    label,
    hint,
    source,
    loading,
    disabled,
    resource,
    inputMessage,
    value,
    field,
    formActionsHandler,
    inputInPuzzleForm,
    visibleClass,
    inputContainerClass,
    customTestAttribute,
    getRef,
    multiple,
    style,
  } = props;

  const { name: fieldName, values } = field;

  const currentResource = actorGetActionValue('resources')!.current;
  const formData = actorGetActionValue(
    'formData',
    `${currentResource.value}.${currentResource.type}`,
  ) as Record<string, unknown> | undefined;

  const wrapperRef = createRef<HTMLDivElement>();
  const [inputValue, setInputValue] = useState<string>('');
  const [limitTagCount, setLimitTagCount] = useState<number>(0);
  const refTags = createRef<HTMLInputElement>();

  //-----------------------------STATE--------------------------------------------------------

  const [specialDropdownVariables, setSpecialDropdownVariables] =
    useState<SpecialDropdownVariables>({
      currentPage: 1,
      isFirstLoad: true,
      searchValue: '',
      currentResource,
      preparedOptions: [],
    });

  const [options, setOptions] = useState<DropdownOption[]>([]);

  /**
   * handle more item with flex (when item is more than one line in flex-box)
   * @function updateSize
   * @return {void}
   */
  const updateSize = useCallback((): void => {
    if (tempRefTags == null) return;

    const parentRect = tempRefTags.getBoundingClientRect();

    const maxTagsCountBasedOnInputContent = Math.round(parentRect.width / 100);

    if (maxTagsCountBasedOnInputContent - 3 <= 0) {
      setLimitTagCount(1);
    } else {
      setLimitTagCount(maxTagsCountBasedOnInputContent - 3);
    }
  }, [tempRefTags?.className]);

  /**
   * @function debouncedWindowResizeHandler
   * @returns { void }
   */
  const debouncedWindowResizeHandler = useCallback(
    lodashDebounce(updateSize, 1000),
    [],
  );

  useEffect(() => {
    /**
     * add listener when resize view port change
     */
    window.addEventListener('resize', debouncedWindowResizeHandler);

    if (refTags?.current) {
      tempRefTags = refTags.current;
      updateSize();
    }

    return () => {
      window.removeEventListener('resize', debouncedWindowResizeHandler);
    };
  }, []);

  /**
   * this function delete empty value and return list of string
   * @constant preparedValue
   * @return {Array}
   */
  const preparedValue: string[] = useMemo(() => {
    const toStringValue = value?.toString();

    if (toStringValue) {
      return toStringValue.split(',').filter((item: string) => !isEmpty(item));
    }

    return [];
  }, [value]);

  /**
   * convert data to options list object
   * @function renderOptions
   * @returns {Array<object>}
   */
  const renderOptions: DropdownOption[] = useMemo(() => {
    const items: DropdownOption[] = [];

    if (!(Array.isArray(values) && values.length > 0)) return items;

    for (let index = 0; index < values.length; index++) {
      const formattedOption: DropdownOption = {
        text: values[index] + '',
        value: values[index] + '',
        key: index + '',
      };

      items.push(formattedOption);
    }

    setOptions(items);

    return items;
  }, []);

  /**
   * it should trigger `fetchDropdownData` function in form controller to make request
   * @function handleOnSearch
   * @param { string } searchValue
   * @returns { void } void
   */
  const handleOnSearch = (searchValue: string): void => {
    if (isEmpty(searchValue)) {
      setOptions(renderOptions);
      return;
    }

    const foundItems = renderOptions.filter(
      option =>
        option.text.indexOf(replaceFarsiCharactersWithArabic(searchValue)) > -1,
    );
    setOptions(foundItems);
  };

  /**
   * this function handle value change in search input drop down
   * @function handleChangeInput
   * @param {React.ChangeEvent<HTMLInputElement>} {event}
   * @return {void}
   */
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
    debouncedSearch(event.target.value);
  };

  /**
   * @function debouncedSearch
   * @param { string } value
   * @returns { void }
   */
  const debouncedSearch = useCallback(
    lodashDebounce((value: string) => {
      handleOnSearch(value);
    }, 500),
    [],
  );

  /**
   * Run form change handler to update formData and value
   * @function changeFormValue
   * @param {unknown} value
   * @returns {void}
   */
  const changeFormValue = (value: unknown): void => {
    formActionsHandler(FormActions.InputChange, {
      fieldName,
      value,
    });
  };

  /**
   * Turn values into a string chain and emit onChange
   * @function handleChange
   * @param {React.ChangeEvent<{}>} {event}
   * @param {string} {value}
   * @param {AutocompleteChangeReason} {reason}
   * @returns {void}
   */
  const handleChange = (event, newValueList) => {
    const newValue = convertToStringValue(!multiple ? [newValueList] : newValueList);
    changeFormValue(newValue);

    setSpecialDropdownVariables(prev => ({
      ...prev,
      preparedOptions: newValueList,
    }));

    if (!isEmpty(inputValue)) {
      setInputValue('');
      setOptions(renderOptions);
    }
  };

  /**
   * It handles the focus of the input
   * @function handleFocus
   * @returns { void } void
   */
  const handleFocus = () => {
    formActionsHandler(FormActions.InputFocus, {
      fieldName,
      value,
    });
  };

  /**
   * It handles blur of the input
   * @function handleBlur
   * @returns { void } void
   */
  const handleBlur = () => {
    formActionsHandler(FormActions.InputBlur, {
      fieldName,
      value,
    });
  };

  const required = useMemo(() => field.required, [field]);

  return (
    <StringselectInputView
      inputContainerClass={inputContainerClass}
      visibleClass={visibleClass}
      required={required}
      preparedValue={preparedValue}
      wrapperRef={wrapperRef}
      label={label}
      hint={hint}
      source={source}
      formData={formData}
      loading={loading}
      disabled={disabled}
      resource={resource}
      preparedOptions={specialDropdownVariables?.preparedOptions}
      renderOptions={options}
      inputValue={inputValue}
      handleChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      inputMessage={inputMessage}
      getRef={getRef}
      refTags={refTags}
      limitTagCount={limitTagCount}
      field={field}
      formActionsHandler={formActionsHandler}
      customTestAttribute={customTestAttribute}
      multiple={multiple}
      style={style}
      handleChangeInput={handleChangeInput}
    />
  );
});

export default StringselectInputController;
