import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({});

function YearlyIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M12.002 9h2v3h-3V9h1zm0 2h1v-1h-1v1zm0-4h1V4h-1zm0 10h1v-3h-1zm4.001-6h3v-1h-3zm-10 0h3v-1h-3zm3.2494-4.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75.336.75.75.75.75-.336.75-.75zm8 0c0-.414-.336-.75-.75-.75s-.75.336-.75.75.336.75.75.75.75-.336.75-.75zm-1.5 8c0 .414.336.75.75.75s.75-.336.75-.75-.336-.75-.75-.75-.75.336-.75.75zm-6.5517-1.3984c-1.991 1.508-3.187 3.898-3.198 6.396l-.003.5 1 .004.003-.5c.01-2.187 1.057-4.282 2.802-5.603l.398-.302-.604-.797-.398.302z"
      ></path>
    </SvgIcon>
  );
}

export default withStyles(styles)(YearlyIcon);
