import React, { FC, useState, useCallback, useMemo, useRef, RefObject } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { refreshView as refreshViewAction, translate } from 'react-admin';
import { Theme } from '@material-ui/core';
import lodashDebounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { getIconClassName } from '@uifabric/styling';

import TodoTaskItem from './TodoTaskItem';
import TodoSystemTaskItem from './TodoSystemTaskItem';
import TodoAddNewTask from './TodoAddNewTask';
import {
  getTodoTaskDetailsPatternInfo,
  getTodoListPatternInfo,
} from '../../helper/PatternMetaHelper';
import { prepareTaskList, largestRowOrder } from '../../helper/TodoListHelper';
import LoadingBox from '../LoadingBox';
import { crudUpdateWithCallbackAction } from '../../redux/crud/action';
import {
  deleteTodoListAction,
  renameTodoListAction,
  updateTodoTaskDetailAction,
} from '../../redux/todoList/action';
import ConfirmDialogHOC from '../../container/ConfirmDialogHOC';
import ContextMenu from './ContextMenu';
import CustomElementEditable from '../CustomElementEditable';
import { isEmpty, isEmptyObject } from '../../helper/data-helper';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { CustomTheme } from '../../core/themeProvider';
import { showNotification } from '../../helper/general-function-helper';

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  listContainer: {
    overflow: 'auto',
    padding: '0 10px',
  },

  listTitle: {
    padding: '5px 10px',
    margin: '0 24px',
    outline: 'none',
    border: '1px solid transparent',
    '&:focus': {
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.primary.appPrimaryDividerColor}`,
    },
  },

  noTaskContainer: {
    display: 'flex',
    padding: '0 25px',
    alignItems: 'center',
  },

  noTask: {
    padding: '0 5px',
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 10,
  },
}));

interface TodoTaskListStyleInterface {
  container: string;
  listContainer: string;
  listTitle: string;
  noTaskContainer: string;
  noTask: string;
  titleContainer: string;
}

interface TodoCurrentSortType {
  field: string;
  order: string;
}

interface TodoTaskListViewProps {
  classes: TodoTaskListStyleInterface;
  data: object[];
  selectedTodoList: object;
  resource: string;
  ids: number[];
  currentSort: TodoCurrentSortType;
  isLoading: boolean;
  todoListResource: string;
  translate: Function;
  refreshView: Function;
  update: Function;
  deleteTodoList: Function;
  renameTodoList: Function;
  reOrderTasksDetailsList: Function;
  setFilters: Function;
  openConfirmDialog: Function;
  theme: Theme;
  rowOrder: number;
  todoListSelectedFilter: string;
  detailResource: string;
  filterValues: object;
}

const TodoTaskListView: FC<TodoTaskListViewProps> = props => {
  const {
    data,
    selectedTodoList,
    resource,
    ids,
    currentSort,
    isLoading,
    todoListResource,
    translate,
    refreshView,
    update,
    deleteTodoList,
    renameTodoList,
    reOrderTasksDetailsList,
    openConfirmDialog,
    todoListSelectedFilter,
    detailResource,
    filterValues,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const todoListNameElement: RefObject<HTMLDivElement> = useRef(null);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [hideCompleted, setHideCompleted] = useState(false);
  const [selectedTaskItemId, setSelectedTaskItemId] = useState(null);

  const todoListPattern = getTodoListPatternInfo(todoListResource);
  const todoTaskDetailsPattern = getTodoTaskDetailsPatternInfo(detailResource);
  const { title: todoListTitle, id: todoListId } = todoListPattern;
  const { title, id, dragTaskAcceptType, rowOrder, assignUser, sysItem } =
    todoTaskDetailsPattern;

  const isDefaultList = isEmptyObject(selectedTodoList) ? true : false;

  const handleUpdateTask = lodashDebounce(
    (id: number, data: object, previousData: object) => {
      update(detailResource, id, data, previousData, () => {
        refreshView();
      });
    },
    500,
  );

  const handleOpenSelect = name => event => {
    setIsContextMenuOpen(!isContextMenuOpen);
  };

  /**
   * Always show addNewTask except in assingToMe and SystemTasks
   * @constant showAddNewTaskComponent
   * @type boolean
   */
  const showAddNewTaskComponent =
    !todoListSelectedFilter ||
    (todoListSelectedFilter !== assignUser && todoListSelectedFilter !== sysItem);

  const reOrderTasks = (id: number, newOrder: number): void => {
    const { sort, pagination } = getTodoListPatternInfo(todoListResource);
    const data = {
      data: {
        [rowOrder]: newOrder,
      },
      id: id,
    };
    const options = {
      sort: { ...sort },
      pagination: { ...pagination },
    };
    reOrderTasksDetailsList(detailResource, data, options);
  };

  const addNextOrder = (list = [{}]): Array<object> => {
    if (list && list.length) {
      for (let i = 0; i < list.length; i++) {
        const nextItemIndex = i + 1;
        if (list[nextItemIndex]) {
          list[i]['nextRowOrder'] = list[nextItemIndex][rowOrder];
        } else if (list[i]) {
          list[i]['nextRowOrder'] = list[i][rowOrder] + 1;
        }
      }
    }
    return list;
  };

  /**
   * prepare task list: add next row order (for drag & drop) for all list except system tasks
   * @function preparedTaskList
   * @returns {object[]}
   */
  const preparedTaskList = useMemo(
    () =>
      todoListSelectedFilter !== sysItem
        ? addNextOrder(prepareTaskList(data, ids, hideCompleted))
        : prepareTaskList(data, ids, hideCompleted),
    [data, ids, hideCompleted, todoListSelectedFilter],
  );

  /**
   * Handle delete todo list.
   * @function deleteTodo
   * @returns {void}
   */
  const deleteTodo = (): void => {
    const {
      reportResource,
      sort,
      pagination,
      deleteService,
      id,
      staticFilterList,
      reportTaskDetailsResource,
    } = getTodoListPatternInfo(todoListResource);

    const data = { [id]: selectedTodoList[id] };
    const url = `?filter={"${staticFilterList.listId}":["${staticFilterList.listId}","=","null"]}`;

    deleteTodoList(
      todoListResource,
      deleteService,
      data,
      reportResource,
      sort,
      pagination,
      url,
      reportTaskDetailsResource,
    );
  };

  const renameTodo = newTitle => {
    const { reportResource, sort, pagination, title, id } =
      getTodoListPatternInfo(todoListResource);

    const data = {
      data: {
        [title]: newTitle,
      },
      id: selectedTodoList[id],
    };

    renameTodoList(
      todoListResource,
      data,
      reportResource,
      sort,
      pagination,
      todoListResource,
    );
    showNotification(translate('todo.renamedSuccessfully'));
  };

  const ShowConfirm = () => {
    openConfirmDialog({
      content: translate('todo.areYouSureYouWantToDeleteThisList'),
      onConfirm: deleteTodo,
      color: theme.palette.error.main,
    });
  };

  const handleItemClick = item => {
    switch (item.key) {
      case 'renameList': {
        todoListNameElement.current!.click();
        todoListNameElement.current!.focus();
        break;
      }

      case 'deleteList':
        ShowConfirm();
        break;

      case 'hideCompleted':
        setHideCompleted(!hideCompleted);
        break;

      default:
        break;
    }
  };

  /**
   * Represents a list name based on filters, selected list (in redux) or resource
   * @function todoListName
   * @return {String}
   */
  const todoListName: string = useMemo((): string => {
    if (
      filterValues &&
      Object.keys(filterValues)[0] === 'filterName' &&
      !isEmpty(filterValues['filterName'][0])
    ) {
      // PreDefined Lists like IsAddedToMyDay, IsImportant and DueDate, assignedtome
      return translate(`todo.${filterValues['filterName'][0]}`);
    } else if (selectedTodoList) {
      // User's List
      return selectedTodoList[todoListTitle];
    } else {
      // Default List
      return translate('todo.tasks');
    }
  }, [selectedTodoList, filterValues]);

  const changeName = lodashDebounce(event => {
    renameTodo(event.target.value);
  }, 2000);

  const handleSelectedTaskItem = useCallback(taskId => {
    setSelectedTaskItemId(taskId);
  }, []);

  const viewComponent =
    todoListSelectedFilter !== sysItem ? (
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <CustomElementEditable
            data-test-todo-list-view-name={todoListName}
            ref={todoListNameElement}
            html={todoListName}
            onChange={changeName}
            disabled={isDefaultList}
            className={classes.listTitle}
          />
          <ContextMenu
            icon="more"
            title={translate('todo.options')}
            btnTitle=" "
            items={[
              {
                key: 'renameList',
                title: translate('todo.renameTodoList'),
                icon: <i className={getIconClassName('Rename')}></i>,
                disable: isDefaultList,
              },
              {
                key: 'deleteList',
                title: translate('todo.deleteTodoList'),
                icon: <i className={getIconClassName('Delete')}></i>,
                disable: isDefaultList,
              },
              {
                key: 'hideCompleted',
                title: hideCompleted
                  ? translate('todo.showCompleted')
                  : translate('todo.hideCompleted'),
                icon: <i className={getIconClassName('Clock')}></i>,
              },
            ]}
            isOpen={isContextMenuOpen}
            onItemClick={handleItemClick}
            onClick={handleOpenSelect(isContextMenuOpen)}
          />
          {isLoading && <LoadingBox size={20} />}
        </div>
        <div className={classes.listContainer} data-test-task-list-container={true}>
          {/* @ts-ignore */}
          <DndProvider backend={HTML5Backend}>
            {preparedTaskList &&
              preparedTaskList.map((task, index) => {
                if (id && task && task[id]) {
                  return (
                    <TodoTaskItem
                      key={`${task[id]}-${index}`} // NOTE: DO NOT remove index
                      taskTitle={task[title]}
                      task={task}
                      currentSort={currentSort}
                      taskId={task[id]}
                      resource={resource}
                      onChange={handleUpdateTask}
                      selectedTodoList={selectedTodoList}
                      listResource={todoListResource}
                      dragTaskAcceptType={dragTaskAcceptType}
                      reOrderTasks={reOrderTasks}
                      setSelectedTaskItem={handleSelectedTaskItem}
                      selected={selectedTaskItemId === task[id]}
                      detailResource={detailResource}
                    />
                  );
                } else {
                  return <div></div>;
                }
              })}
          </DndProvider>
        </div>
        {showAddNewTaskComponent && (
          <TodoAddNewTask
            resource={detailResource}
            parentResource={todoListResource}
            todoListPattern={todoListPattern}
            todoTaskPattern={todoTaskDetailsPattern}
            listId={selectedTodoList ? selectedTodoList[todoListId] : null}
            largestRowOrder={largestRowOrder(preparedTaskList, rowOrder)}
            todoListSelectedFilter={todoListSelectedFilter}
          />
        )}
      </div>
    ) : (
      <div className={classes.container}>
        <div className={classes.titleContainer} data-test-todo-system-task-list-view>
          <span
            data-test-todo-list-view-name={todoListName}
            className={classes.listTitle}
          >
            {todoListName}
          </span>
        </div>
        {preparedTaskList &&
          preparedTaskList.map((task, index) => {
            if (id && task && task[id]) {
              return (
                <TodoSystemTaskItem
                  key={`${task[id]}-${index}`} // NOTE: DO NOT remove index
                  task={task}
                  taskId={task[id]}
                  resource={resource}
                  listResource={todoListResource}
                  detailResource={detailResource}
                />
              );
            } else {
              return <div></div>;
            }
          })}
      </div>
    );

  return viewComponent;
};

TodoTaskListView.propTypes = {
  resource: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  refreshView: refreshViewAction,
  update: crudUpdateWithCallbackAction,
  deleteTodoList: deleteTodoListAction,
  renameTodoList: renameTodoListAction,
  reOrderTasksDetailsList: updateTodoTaskDetailAction,
};

export default compose(
  ConfirmDialogHOC,
  translate,
  connect(null, mapDispatchToProps),
)(TodoTaskListView);
