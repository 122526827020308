import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { getValue, setValue, CONFIG_CALENDAR } from '../core/configProvider';

const styles = theme => ({
  calendarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  selectCalendar: {
    color: theme.palette.secondary.main,
  },
});

const calendarList = {
  jalali: 'جلالی',
  gregorian: 'Gregorain',
  // ghamari: 'قمری',
};

class ChangeCalendarContainer extends PureComponent {
  state = { dummyCounter: 0 };

  handleChangeLocale = value => {
    setValue(CONFIG_CALENDAR, value);
    const { dummyCounter } = this.state;
    this.setState({ dummyCounter: dummyCounter + 1 });
  };

  render() {
    const { classes } = this.props;
    const currentCalendar = getValue(CONFIG_CALENDAR);

    return (
      <div className={classes.calendarContainer}>
        {Object.keys(calendarList).map(key => (
          <Tooltip key={key} title={calendarList[key]} data-test={`cal-${key}`}>
            <Button
              className={classes.IconButton}
              onClick={() => this.handleChangeLocale(key)}
            >
              <Typography
                className={currentCalendar === key ? classes.selectCalendar : null}
                variant="caption"
              >
                {calendarList[key]}
              </Typography>
            </Button>
          </Tooltip>
        ))}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ChangeCalendarContainer);
