import { FC, useState, useEffect, memo, ChangeEvent } from 'react';
import { isEmpty } from '../../../helper/data-helper';
import { actorGetActionValue } from '../../../type/actor-setup';
import { ChangeFormValueParams, FormActions } from '../../form';
import { isNumber, validateTimeAndCursor } from './time-input.helper';

import {
  DEFAULT_COLON,
  DEFAULT_VALUE_FULL,
  DEFAULT_VALUE_SHORT,
  LastPositionInterface,
  TimeInputInterface,
} from './time-input.type';
import TimeInputView from './time-input.view';

const TimeInputController: FC<TimeInputInterface> = memo(props => {
  //---------------------------- Destructure Props -------------------------
  const {
    showSeconds = false,
    colon = DEFAULT_COLON,
    disabled,
    label,
    hint,
    source,
    resource,
    field,
    inputMessage,
    className,
    visibleClass,
    customTestAttribute,
    getRef,
  } = props;

  const { name } = field;

  //---------------------------- Actor -------------------------
  const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;

  //---------------------------- States -------------------------
  const [value, setValue] = useState<string>('');
  const [lastPosition, setLastPosition] = useState<
    LastPositionInterface | undefined
  >();

  //---------------------------- Constants -------------------------
  const defaultValue = showSeconds ? DEFAULT_VALUE_FULL : DEFAULT_VALUE_SHORT;
  const selectedColon = colon && colon.length === 1 ? colon : DEFAULT_COLON;
  const maxLength = defaultValue.length; // it will be 5 or 7

  // set initial value
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  // should change the value of state when user type sth
  useEffect(() => {
    const [validatedTime] = validateTimeAndCursor(
      showSeconds,
      value,
      defaultValue,
      selectedColon,
    );

    if (value && value !== validatedTime) {
      setValue(validatedTime); // set the new value of input
    }
  }, [value]);

  // for set default value
  useEffect(() => {
    // computing formatted time
    const [validatedTime] = validateTimeAndCursor(
      showSeconds,
      value,
      defaultValue,
      selectedColon,
    );

    if (!field.required && isEmpty(value)) {
      // it can be null
      setValue('');
    } else {
      // save computed data into an state
      setValue(validatedTime);
    }
  }, []);

  // for handle position
  useEffect(() => {
    if (lastPosition && lastPosition.input) {
      const inputEl = lastPosition.input;
      inputEl.selectionStart = lastPosition.position;
      inputEl.selectionEnd = lastPosition.position;
    }
  }, [lastPosition]);

  /**
   *  it will clear input value
   *  @function apiErrorHandler
   *  @returns {void}
   */
  const clearInput = (): void => {
    const resetValue = '';
    setValue(resetValue);
    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value: resetValue,
    } as ChangeFormValueParams);
  };

  /**
   * this function should analyse the new character that recently has been changed and decide how to treat to it.
   * @function handleChange
   * @param {ChangeEvent<HTMLInputElement>} event
   * @returns {void}
   */
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const oldValue: string | null = value;
    const inputEl: HTMLInputElement = event.target;
    const inputValue: string | null = inputEl.value;
    const position: number = inputEl.selectionEnd || 0;
    const isTyped: boolean = inputValue.length > oldValue.length;
    const cursorCharacter: string = inputValue[position - 1];
    const addedCharacter: string | null = isTyped ? cursorCharacter : null;
    const removedCharacter: string | null = isTyped ? null : oldValue[position];
    const replacedSingleCharacter: string | null =
      inputValue.length === oldValue.length ? oldValue[position - 1] : null;

    let newValue = oldValue;
    let newPosition = position;
    // when user type a new character
    if (addedCharacter !== null) {
      // if it will more than max length

      if (position > maxLength) {
        // shoule set new position on position before typing for ignore what has been typed
        newPosition = maxLength;
      }

      // if its colon on prev colon position
      else if (
        (position === 3 || position === 6) &&
        addedCharacter === selectedColon
      ) {
        // compute new value
        newValue = `${inputValue.substr(
          0,
          position - 1,
        )}${selectedColon}${inputValue.substr(position + 1)}`;
      }

      //  if its number on prev colon position
      else if ((position === 3 || position === 6) && isNumber(addedCharacter)) {
        newValue = `${inputValue.substr(
          0,
          position - 1,
        )}${selectedColon}${addedCharacter}${inputValue.substr(position + 2)}`;
        newPosition = position + 1;
      }

      // if its number on not prev colon position
      else if (isNumber(addedCharacter)) {
        // user typed a number
        newValue =
          inputValue.substr(0, position - 1) +
          addedCharacter +
          inputValue.substr(position + 1);
        if (position === 2 || position === 5) {
          newPosition = position + 1;
        }
      }

      // if user typed NOT a number, then keep old value & position
      else {
        newPosition = position - 1;
      }
    }

    // when user change an exist character
    else if (replacedSingleCharacter !== null) {
      // user replaced only a single character
      if (isNumber(cursorCharacter)) {
        if (position === 3 || position === 6) {
          newValue = `${inputValue.substr(
            0,
            position - 1,
          )}${selectedColon}${inputValue.substr(position)}`;
        } else {
          newValue = inputValue;
        }
      }

      // user replaced a number on some non-number character
      else {
        newValue = oldValue;
        newPosition = position - 1;
      }
    }

    // when user type a NAN character
    else if (
      typeof cursorCharacter !== 'undefined' &&
      cursorCharacter !== selectedColon &&
      !isNumber(cursorCharacter)
    ) {
      // set of characters replaced by non-number (ignore it)
      newValue = oldValue;
      newPosition = position - 1;
    }

    // if user remove a colon
    else if (removedCharacter !== null) {
      if ((position === 2 || position === 5) && removedCharacter === selectedColon) {
        // reset cocon
        newValue = `${inputValue.substr(
          0,
          position - 1,
        )}0${selectedColon}${inputValue.substr(position)}`;
        newPosition = position - 1;
      }

      // if user removed a number
      else {
        // replace it with 0
        newValue = `${inputValue.substr(0, position)}0${inputValue.substr(
          position,
        )}`;
      }
    }

    const [validatedTime, validatedCursorPosition] = validateTimeAndCursor(
      showSeconds,
      newValue,
      oldValue,
      selectedColon,
      newPosition,
    );

    setLastPosition({ input: inputEl, position: validatedCursorPosition });
    setValue(validatedTime);

    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value: validatedTime,
    } as ChangeFormValueParams);
    event.persist();
  };

  return (
    <TimeInputView
      field={field}
      value={value}
      inputMessage={inputMessage}
      resource={resource}
      source={source}
      className={className}
      label={label}
      hint={hint}
      disabled={disabled}
      clearInput={clearInput}
      handleChange={handleChange}
      visibleClass={visibleClass}
      customTestAttribute={customTestAttribute}
      getRef={getRef}
    />
  );
});

export default TimeInputController;
