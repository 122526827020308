import React, { useEffect, useCallback, ReactElement, memo } from 'react';
import 'react-contexify/dist/ReactContexify.min.css';
import { locale as devExpressLocale } from 'devextreme/localization';
import { useLocale } from 'react-admin';

import { removeLastResource } from '../../helper/resource-helper';
import { AppLayoutInterface } from './app-layout.type';
import { isEmpty } from '../../helper/data-helper';
import AppLayoutView from './app-layout.view';
import {
  CONFIG_IS_BOTTOM_MENU_ENABLED,
  getValue,
  SESSION_ID,
} from '../../core/configProvider';
import {
  actorDispatch,
  actorOnDispatch,
  actorRemoveAction,
} from '../../type/actor-setup';

const AppLayout = (props: AppLayoutInterface): ReactElement => {
  const { children, dashboard, logout, container, theme } = props;
  const locale = useLocale();

  // configure devExpress
  devExpressLocale(locale);

  const hasDashboard = !!dashboard;
  const isBottomMenuEnabled: boolean = getValue(CONFIG_IS_BOTTOM_MENU_ENABLED);

  /**
   * it should open the quick access dialog
   * the second parameter shows the dialog should open or close
   * if pass `true` it will open the quick access dialog, and if pass `false` it will close the dialog
   * @function showHotKeysDialog
   * @returns {void}
   */
  const showHotKeysDialog = () => {
    actorDispatch('quickAccessOpenDialog', true);
  };

  const keyMap = { SHOW_ALL_HOTKEYS: '.' };
  const handlers = { SHOW_ALL_HOTKEYS: showHotKeysDialog };

  /**
   * set drawer state to close in actor
   * @function onCloseChildMenu
   * @returns {void}
   */
  const onCloseChildMenu = () => {
    actorDispatch('isChildMenuOpen', false);
  };

  /**
   * close child menu by esc
   * @function escFunction
   * @param {Event} event
   * @returns {void}
   */
  const escFunction = useCallback(event => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      onCloseChildMenu();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    actorOnDispatch('remove', detail => {
      if (detail == null) return;

      const { resource, type } = detail;
      if (!isEmpty(resource)) {
        removeLastResource(type);
        removeFormMessage(resource, type);
        actorRemoveAction({
          actionName: 'formData',
          path: `${resource}.${type}`,
        });
        actorRemoveAction({
          actionName: 'record',
          path: `${resource}.${type}`,
        });
      }
    });
  }, []);

  /**
   * remove `formError` from actor.
   * @function removeFormError
   * @param {string} resourceName
   * @param {FormKeyMode} type
   * @returns {void}
   */
  const removeFormMessage = (resourceName, type) => {
    actorDispatch(
      'formMessages',
      {},
      { path: `${resourceName}.${type}`, replaceAll: true },
    );
  };

  /**
   * to fetch quickAccessMenuData
   * TODO: refactor this structure
   * to new CrudAction Structure
   */
  useEffect(() => {
    const userSessionId = getValue(SESSION_ID);
    !isEmpty(userSessionId) && actorDispatch('getQuickAccessMenuData', true);
  }, []);

  return (
    <AppLayoutView
      isBottomMenuEnabled={isBottomMenuEnabled}
      logout={logout}
      keyMap={keyMap}
      handlers={handlers}
      hasDashboard={hasDashboard}
      container={container}
      theme={theme}
    >
      {children}
    </AppLayoutView>
  );
};

export default memo(AppLayout, () => true);
