import { FC, memo, useEffect, useState } from 'react';
import _ from 'lodash';
import lodashFilter from 'lodash/filter';

import {
  GridDataInterface,
  LatLngExpression,
  MapConfig,
  MapControllerPropsInterface,
  MapDataInterface,
  MarkerInterface,
} from './map.type';
import MapView from './map.view';
import { getGridColumns } from '../../helper/MetaHelper';
import DevExGrid from '../DevExGrid';
import lodashMap from 'lodash/map';
import LoadingBox from '../LoadingBox';
import { LatLngTuple } from 'leaflet';
import { isEmptyObject } from '../../helper/data-helper';

const tehranLatLong: LatLngExpression = [35.6892, 51.389];

const MapController: FC<MapControllerPropsInterface> = props => {
  const { metaData, data, resource } = props;
  const [preparedMapData, setPreparedMapData] = useState<MapDataInterface>({
    markerList: [],
    polyLines: [],
  });
  const [mapCenter, setMapCenter] = useState<LatLngExpression>(tehranLatLong);
  const [mapZoom, setMapZoom] = useState<number>(9);

  useEffect(() => {
    if (!isEmptyObject(data)) {
      prepareData(data);
    } else {
      setPreparedMapData({
        markerList: [],
        polyLines: [],
      });
    }
  }, [data]);

  /**
   * @function prepareData
   * @param data
   * @returns { void }
   */
  const prepareData = data => {
    const { dataForGrid, dataMapIds } = prepareDataForGrid(data);
    const { markerList, polyLines } = prepareDataForMap(data);

    setPreparedMapData({
      markerList: markerList,
      polyLines: polyLines,
      dataForGrid: dataForGrid,
      dataMapIds: dataMapIds,
    });
  };

  /**
   * @function prepareDataForGrid
   * @param data
   * @returns { GridDataInterface }
   */
  const prepareDataForGrid = (data): GridDataInterface => {
    const preparedData = {};
    const preparedIds: Array<number> = [];

    lodashMap(data, row => {
      const rand = Math.round(Math.random() * 100000);
      preparedData[rand] = row;
      preparedIds.push(rand);
    });

    return {
      dataForGrid: preparedData,
      dataMapIds: preparedIds,
    };
  };

  /**
   * @function mapConfigColumns
   * @returns { mapConfig }
   */
  const mapConfigColumns = (): MapConfig => {
    return _.mapValues(metaData?.mapConfig, _.method('toLowerCase'));
  };

  /**
   * @function prepareDataForMap
   * @param data
   * @returns { void }
   */
  const prepareDataForMap = data => {
    if (isEmptyObject(data) || isEmptyObject(metaData) || !metaData?.mapConfig)
      return {
        markerList: [],
        polyLines: [],
      };

    const { latitude, longitude, markerIcon, markerToolTip } = mapConfigColumns();

    const prepareMarkers: Array<MarkerInterface> = [];
    const preparePolyLines: Array<LatLngTuple> = [];

    lodashMap(data, row => {
      if (row?.[latitude] && row?.[longitude]) {
        preparePolyLines.push([row[latitude], row[longitude]]);
        prepareMarkers.push({
          lat: row[latitude],
          long: row[longitude],
          icon: row[markerIcon],
          popupContent: row[markerToolTip],
        });
      }
    });

    //set first point as map center
    if (preparePolyLines.length > 0) setMapCenter(preparePolyLines[0]);

    return {
      markerList: prepareMarkers,
      polyLines: preparePolyLines,
    };
  };

  /**
   * @function getColumnsForGrid
   * @returns { Record<string, unknown> }
   */
  const getColumnsForGrid = (): Record<string, unknown> => {
    if (isEmptyObject(metaData)) {
      return {};
    }

    const columns = getGridColumns({ metaData });
    const { markerToolTip } = mapConfigColumns();

    return lodashFilter(columns, column => column.id != markerToolTip);
  };

  /**
   * @function handleGridRowClick
   * @params { Record<string, unknown> }row
   * @returns { void }
   */
  const handleGridRowClick = (row: Record<string, unknown>): void => {
    const { latitude, longitude } = mapConfigColumns();
    setMapCenter({
      lat: row?.[latitude] as number,
      lng: row?.[longitude] as number,
    });
    setMapZoom(15);
  };

  if (!metaData) {
    return <LoadingBox />;
  }

  return (
    <>
      <MapView
        polylinePositions={preparedMapData.polyLines ?? []}
        markerList={preparedMapData.markerList}
        mapCenter={mapCenter}
        zoom={mapZoom}
        key={Math.random()}
        isFullScreen={!metaData.mapConfig?.showInGrid}
        hasTracing={metaData.mapConfig?.tracing}
      />

      {metaData?.mapConfig && metaData.mapConfig.showInGrid && (
        <DevExGrid
          isDropDown={false}
          hasList={true}
          hasShow={false}
          hasEdit={false}
          resource={resource}
          data={preparedMapData.dataForGrid ?? []}
          ids={preparedMapData.dataMapIds ?? []}
          fields={getColumnsForGrid()}
          relationMode={false}
          isTopFilterOpen={false}
          isGroupingOpen={false}
          metaData={metaData}
          enableSelection={false}
          isRemoteFiltering
          onRowClick={handleGridRowClick}
          enableClientExportExcel={true}
        />
      )}
    </>
  );
};

export default memo(MapController);
