import { Button } from '@material-ui/core';
import { FC, memo, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';

import { MailActions, OnComposeParams } from '..';
import ArchivedIcon from '../../../icon/ArchivedIcon';
import DraftIcon from '../../../icon/DraftIcon';
import ImportantIcon from '../../../icon/ImportantIcon';
import InboxIcon from '../../../icon/InboxIcon';
import OutboxIcon from '../../../icon/OutboxIcon';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import StartIcon from '../../../icon/StartIcon';
import { RoutesConfigInterface } from '../../app-drawer/navigation-container';
import { generatePersonalFolders } from './mail-sidebar.helper';
import { MailSidebarInterface } from './mail-sidebar.type';
import MailSidebarView from './mail-sidebar.view';
import { useStyles } from './mail-sidebar.style';
import { actorOnDispatch } from '../../../type/actor-setup';
import { isEmptyObject } from '../../../helper/data-helper';

const MailSidebarController: FC<MailSidebarInterface> = memo(props => {
  const { mailActionsHandler } = props;

  const translate = useTranslate();
  const classes = useStyles();

  const inboxFolderCountId = 1;
  const [dynamicMailFolders, setDynamicMailFolders] = useState<
    RoutesConfigInterface[]
  >([]);
  const [userCreatedFolders, setUserCreatedFolders] = useState<
    RoutesConfigInterface[]
  >([]);

  const [inputBoxUnreadCount, setInputBoxUnreadCount] = useState<number | undefined>(
    undefined,
  );
  /**
   * @function onCompose
   * @param { number } selectedIndex
   * @returns { void }
   */
  const onCompose = (selectedIndex: number): void => {
    mailActionsHandler(MailActions.onCompose, {
      selectedIndex,
    } as OnComposeParams);
  };

  /**
   * @function onOpenNewFolderOpen
   * @returns { void }
   */
  const onOpenNewFolderForm = (): void => {
    mailActionsHandler(MailActions.onOpenNewFolderForm);
  };

  useEffect(() => {
    actorOnDispatch('mailFolders', mailFolders => {
      if (!Array.isArray(mailFolders)) return;

      const inboxFolder = mailFolders.find(
        folder => folder.folders_id === inboxFolderCountId,
      );
      setInputBoxUnreadCount(inboxFolder?.itemcount);

      const filteredMailFolders = mailFolders.filter(
        folder => folder.folders_id !== inboxFolderCountId && folder.folders_id < 0,
      );
      setDynamicMailFolders(
        generatePersonalFolders(filteredMailFolders, mailActionsHandler),
      );

      const userNewFolders = mailFolders.filter(
        folder => folder.folders_id !== inboxFolderCountId && folder.folders_id > 0,
      );
      setUserCreatedFolders(
        generatePersonalFolders(userNewFolders, mailActionsHandler),
      );
    });
  }, []);
  // sidebar menu config
  const mailSidebarRoutesConfig: RoutesConfigInterface[] = [
    ...dynamicMailFolders,
    {
      id: 'folders',
      title: `${translate('mail.folders')}`,
      type: 'collapse',
      dataTest: 'static-folders',
      children: [
        {
          id: 'inbox',
          title: `${translate('mail.docType.inbox')}`,
          type: 'item',
          icon: <InboxIcon color="#fff" />,
          url: '/mail?inbox',
          dataTest: 'static-folder-inbox',
          count: inputBoxUnreadCount,
        },
        {
          id: 'outbox',
          title: `${translate('mail.docType.outbox')}`,
          type: 'item',
          icon: <OutboxIcon />,
          url: '/mail?outbox',
          dataTest: 'static-folder-outbox',
        },
        {
          id: 'unread',
          title: `${translate('mail.docType.unread')}`,
          type: 'item',
          icon: <MailOutlineIcon color="action" />,
          url: '/mail?unread',
          dataTest: 'static-folder-unread',
        },
        {
          id: 'archived',
          title: `${translate('mail.docType.archived')}`,
          type: 'item',
          icon: <ArchivedIcon />,
          url: '/mail?archived',
          dataTest: 'static-folder-archived',
        },
        {
          id: 'starred',
          title: `${translate('mail.docType.starred')}`,
          type: 'item',
          icon: <StartIcon />,
          url: '/mail?starred',
          dataTest: 'static-folder-starred',
        },
        {
          id: 'important',
          title: `${translate('mail.docType.important')}`,
          type: 'item',
          icon: <ImportantIcon />,
          url: '/mail?important',
          dataTest: 'static-folder-important',
        },
        {
          id: 'draft',
          title: `${translate('mail.docType.draft')}`,
          type: 'item',
          icon: <DraftIcon />,
          url: '/mail?draft',
          dataTest: 'static-folder-drafts',
        },
        ...userCreatedFolders,
      ],
    },
    {
      id: 'create-new-folder',
      title: translate('mail.personalFolders'),
      type: 'action',
      actionNode: (
        <Button
          onClick={onOpenNewFolderForm}
          variant="text"
          color="secondary"
          className={classes.createFolderBtn}
          data-test="mail-sidebar-new-folder-button"
        >
          {translate('mail.createFolder')}
        </Button>
      ),
    },
  ];

  return (
    <MailSidebarView
      mailSidebarRoutesConfig={mailSidebarRoutesConfig}
      onCompose={onCompose}
    />
  );
});

export default MailSidebarController;
