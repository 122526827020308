import { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslate } from 'react-admin';

import { WMSFormHelperVariablesRef, WMSFormProps } from './wms-form.type';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
} from '../../../../type/actor-setup';
import { FormActions, InputRefContent, OnBlurParams } from '../../../form';
import WMSButtonToolbar from '../../wms-button-toolbar';
import { isEnterPressed } from '../../../../helper/FormHelper';
import { checkFormInputsSpecs, updateInputsState } from '../../../form/form.helper';
import { isEmptyObject } from '../../../../helper/data-helper';
import { WMSFormView } from './wms-form.view';
import {
  prepareRowListWMSForm,
  updateValuesOfInputsAfterSearchInputChanges,
} from '../../wms.helper';
import {
  checkCurrentFocusedFieldIsSearchField,
  formHandlerOnPressingEnterKey,
  resetWMSForm,
  submitHandler,
} from './wms-form.helper';
import { showNotification } from '../../../../helper/general-function-helper';

let wmsFormHelperVariablesRef: WMSFormHelperVariablesRef = {
  currentFormIndex: -1,
  submitFunctions: {},
  lastInputSelectedData: {},
  rowList: [],
  fieldList: [],
  fields: {},
  formFocusManagementFunctions: null,
  refs: null,
};

const WMSFormController = (props: WMSFormProps): ReactElement | null => {
  const { wmsMetaData, formTabIndex, onSubmit, activeTabIndex } = props;

  const correctFormTabIndex = activeTabIndex ?? 0;

  const translate = useTranslate();

  const dropdownInProgress = useRef(false);
  const serviceValidationExist = useRef(false);
  const serviceValidationDoneSuccessfully = useRef(false);
  const formContainerRef = useRef<HTMLDivElement>(null);
  const onDispatchDataRef = useRef<
    { actionName: keyof ActorActionList; id: symbol }[]
  >([]);

  const onPressingEnterKey = useCallback(async event => {
    if (
      !isEnterPressed(event) ||
      checkCurrentFocusedFieldIsSearchField(wmsFormHelperVariablesRef.refs)
    ) {
      return;
    }

    wmsFormHelperVariablesRef.submitFunctions[correctFormTabIndex] = submitHandler(
      wmsFormHelperVariablesRef,
      onSubmit,
    );

    fieldServiceRunner();
    if (
      serviceValidationExist.current &&
      !serviceValidationDoneSuccessfully.current
    ) {
      return;
    }

    await formHandlerOnPressingEnterKey(wmsFormHelperVariablesRef);

    actorSetActionValue('wmsAsyncActionList', [], {
      replaceAll: true,
      callerScopeName: 'WMSFormController => wmsFormHandlerOnPressingEnterKey',
    });
  }, []);

  useEffect(() => {
    const currentResource = actorGetActionValue('resources')!.current;
    const inputsRef = actorGetActionValue(
      'inputsRef',
      `${currentResource.value}.${currentResource.type}`,
    ) as Record<string, InputRefContent> | null;

    if (inputsRef) {
      const formInputsSpecs = checkFormInputsSpecs(inputsRef);

      actorSetActionValue(
        'formGlobalProps',
        formInputsSpecs.ignoreToFocusFieldNameList,
        {
          path: 'ignoreToFocusFieldNameList',
          callerScopeName: 'WMSFormController 1 => useEffect',
        },
      );

      actorSetActionValue(
        'formGlobalProps',
        formInputsSpecs.keepValueAfterSubmitFieldNameList,
        {
          path: 'keepValueAfterSubmitFieldNameList',
          callerScopeName: 'WMSFormController 2 => useEffect',
        },
      );

      actorSetActionValue(
        'formGlobalProps',
        formInputsSpecs.keepFocusAfterSubmitFieldNameList,
        {
          path: 'keepFocusAfterSubmitFieldNameList',
          callerScopeName: 'WMSFormController 3 => useEffect',
        },
      );

      actorSetActionValue(
        'formGlobalProps',
        formInputsSpecs.inputNameListSortedByPriority,
        {
          path: 'inputNameListSortedByPriority',
          callerScopeName: 'WMSFormController 4 => useEffect',
        },
      );
    }

    if (activeTabIndex != null) {
      wmsFormHelperVariablesRef.currentFormIndex = activeTabIndex;
    }

    wmsFormHelperVariablesRef.submitFunctions[activeTabIndex ?? 0] = onSubmit;

    let id = actorOnDispatch(
      'resetForm',
      params => {
        const fieldNameList =
          actorGetActionValue('formGlobalProps')!.inputNameListSortedByPriority;

        resetWMSForm(
          fieldNameList,
          params.saveType === 'wmsSaveAfterServiceHasBeenRan',
        );
      },
      {
        preserve: false,
      },
    );

    onDispatchDataRef.current.push({ actionName: 'resetForm', id });

    wmsFormHelperVariablesRef.formFocusManagementFunctions =
      actorGetActionValue('formGlobalProps')!.formFocusManagementFunctions;

    id = actorOnDispatch(
      'dropDownData',
      async dropDownData => {
        const currentResource = actorGetActionValue('resources')!.current;
        if (
          !dropDownData?.[currentResource.value]?.[currentResource.type] ||
          dropdownInProgress.current ||
          !checkCurrentFocusedFieldIsSearchField(wmsFormHelperVariablesRef.refs) // Prevent to process when dropdown is not a search field
        ) {
          return;
        }

        dropdownInProgress.current = true;

        updateValuesOfInputsAfterSearchInputChanges(
          dropDownData[currentResource.value][currentResource.type],
          wmsFormHelperVariablesRef.refs,
          wmsMetaData?.tabs[correctFormTabIndex].item.table != null, // In `table` types that we have a grid in the current tab, we have to refresh it after searching
        );

        wmsFormHelperVariablesRef.submitFunctions[correctFormTabIndex] =
          submitHandler(wmsFormHelperVariablesRef, onSubmit);

        fieldServiceRunner();
        if (
          serviceValidationExist.current &&
          !serviceValidationDoneSuccessfully.current
        ) {
          dropdownInProgress.current = false;
          return;
        }

        await formHandlerOnPressingEnterKey(wmsFormHelperVariablesRef);
        dropdownInProgress.current = false;
      },
      {
        preserve: false,
      },
    );

    onDispatchDataRef.current.push({ actionName: 'formData', id });

    id = actorOnDispatch(
      'formMessages',
      formMessages => {
        const currentResource = actorGetActionValue('resources')!.stack[0];
        const formData = actorGetActionValue('formData');

        if (
          formData?.[currentResource.value]?.[currentResource.type] == null ||
          formMessages?.[currentResource.value]?.[currentResource.type] == null
        ) {
          return;
        }

        updateInputsState(
          wmsFormHelperVariablesRef.refs,
          formMessages[currentResource.value][currentResource.type],
          formData[currentResource.value][currentResource.type],
        );
      },
      {
        preserve: false,
      },
    );

    onDispatchDataRef.current.push({ actionName: 'resources', id });

    formContainerRef.current?.addEventListener('keyup', onPressingEnterKey);

    return () => {
      formContainerRef.current?.removeEventListener('keyup', onPressingEnterKey);

      for (const actionData of onDispatchDataRef.current) {
        const { actionName, id } = actionData;
        actorRemoveAction({
          actionName,
          listenerId: id,
        });
      }
    };
  }, []);

  useMemo(() => {
    wmsFormHelperVariablesRef = {
      currentFormIndex: correctFormTabIndex,
      submitFunctions: {},
      lastInputSelectedData: {},
      rowList: [],
      fieldList: [],
      fields: {},
      formFocusManagementFunctions: null,
      refs: null,
    };

    if (wmsMetaData == null) return;

    const rowData = prepareRowListWMSForm(wmsMetaData.tabs[correctFormTabIndex]);

    wmsFormHelperVariablesRef.rowList = rowData.rowList;
    wmsFormHelperVariablesRef.fields = rowData.fields;
    wmsFormHelperVariablesRef.fieldList = rowData.fieldList;

    const currentResource = actorGetActionValue('resources')!.stack[0];

    actorSetActionValue('metaData', rowData.fields, {
      path: `${currentResource.value}.fields`,
      callerScopeName: 'WMSFormController => useMemo',
    });

    actorSetActionValue('metaData', [], {
      path: `${currentResource.value}.processes`,
      callerScopeName: 'WMSFormController => useMemo',
    });
  }, [activeTabIndex]);

  // FIXME: To use the following codes, first fix all issues about ref of an input
  useEffect(() => {
    const currentResource = actorGetActionValue('resources')!.current;
    const rootResource = actorGetActionValue('resources')!.stack[0];

    wmsFormHelperVariablesRef.refs = actorGetActionValue(
      'inputsRef',
      `${currentResource.value}.${currentResource.type}`,
    ) as Record<string, InputRefContent> | null;

    const allFormData = actorGetActionValue('formData');
    const rootFormData = allFormData?.[rootResource.value]?.[rootResource.type];
    const currentFormData =
      allFormData?.[currentResource.value]?.[currentResource.type];

    const finalFormData = {};
    if (isEmptyObject(rootFormData)) {
      for (const field of wmsFormHelperVariablesRef.fieldList) {
        finalFormData[field.name] = field.defaultValue ?? null;
      }
    } else {
      if (isEmptyObject(currentFormData)) {
        // We have to set only the fields that are in current layout
        for (const field of wmsFormHelperVariablesRef.fieldList) {
          finalFormData[field.name] = rootFormData[field.name];
        }
      } else {
        const currentFormDataKeys = Object.keys(currentFormData);
        // We have to update only the fields that are in current `formData` with the fields that are in root `formData`
        for (const key of currentFormDataKeys) {
          finalFormData[key] = rootFormData[key] ?? null;
        }
      }
    }

    actorSetActionValue('formData', finalFormData, {
      path: `${currentResource.value}.${currentResource.type}`,
      callerScopeName: 'WMSFormController => useEffect',
    });

    updateInputsState(
      wmsFormHelperVariablesRef.refs,
      undefined,
      finalFormData,
      true,
    );

    setTimeout(() => {
      if (!wmsFormHelperVariablesRef.refs) return;

      if (activeTabIndex != null) {
        wmsFormHelperVariablesRef.lastInputSelectedData[correctFormTabIndex] =
          Object.keys(wmsFormHelperVariablesRef.refs)[0];
      }

      wmsFormHelperVariablesRef.formFocusManagementFunctions!.focusOnFirstInput(
        wmsFormHelperVariablesRef.refs,
      );
    }, 0);
  }, [wmsFormHelperVariablesRef.rowList]);

  useEffect(() => {
    const currentResource = actorGetActionValue('resources')!.stack[0];
    actorSetActionValue('allFields', wmsFormHelperVariablesRef.fieldList, {
      path: `${currentResource.value}.${currentResource.type}`,
      callerScopeName: 'WMSFormController => useEffect',
    });
  }, [wmsFormHelperVariablesRef.fieldList]);

  const fieldServiceRunner = useCallback(() => {
    // Only type of `table` may have ‍a `service`
    if (
      isEmptyObject(
        wmsMetaData?.tabs[correctFormTabIndex].item.table?.validationActions,
      )
    ) {
      return;
    }

    serviceValidationExist.current = true;

    const currentFocusedInputName = Object.keys(
      wmsFormHelperVariablesRef.refs!,
    ).find(
      inputName => wmsFormHelperVariablesRef.refs![inputName]['focused'] === true,
    );

    const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;
    formActionsHandler(FormActions.InputBlur, {
      fieldName: currentFocusedInputName,
      value:
        wmsFormHelperVariablesRef.refs![currentFocusedInputName!].inputRef.current!
          .value,
      successRunValidationCallback: () => {
        serviceValidationDoneSuccessfully.current = true;
        showNotification(translate('service.success'), 'success');
      },
    } as OnBlurParams);
  }, [activeTabIndex]);

  if (activeTabIndex !== formTabIndex) return null;

  return (
    <>
      <WMSButtonToolbar wmsMetaData={wmsMetaData!} selectedTabIndex={formTabIndex} />
      <WMSFormView
        formRef={formContainerRef}
        fullHeight
        rowList={wmsFormHelperVariablesRef.rowList}
        fieldList={wmsFormHelperVariablesRef.fieldList}
        formTabIndex={formTabIndex}
        onSubmit={submitHandler(wmsFormHelperVariablesRef, onSubmit)}
      />
    </>
  );
};

WMSFormController.defaultProps = {
  wmsMetaData: null,
};

export default WMSFormController;
