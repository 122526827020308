import React, { FC, useState, useEffect } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { connect } from 'react-redux';
import {
  IconButton,
  Icon,
  Menu,
  MenuItem,
  makeStyles,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import lodashGet from 'lodash/get';

import { crudCustomUpdate } from '../redux/crud/action';
import { prepareShiftProcess } from '../helper/MetaHelper';
import { refreshRelationAction } from '../redux/relation/action';
import { actorDispatch } from '../type/actor-setup';

export interface ProcessTaskInterface {
  allowEdit: boolean;
  deactiveSubpanels: [];
  description: string;
  fields: object;
  lines: [];
  positionId: number;
  stateId: number;
  title: string;
  translatedTitle: object;
}

interface ProcessInterface {
  title: string;
  uniqueId: string;
  isActive?: boolean;
}

interface ShiftProcessButtonInterfaceProps {
  resource: string;
  recordId: number;
  crudUpdate: Function;
  metaData: object;
  processList: ProcessInterface[];
  defaultProcessUniqueId: string;
  refreshRelation: () => void;
}

const useStyles = makeStyles(theme => ({
  iconButton: {
    padding: 7,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },

  icon: {
    fontSize: 20,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },

  selectInput: {
    padding: 10,
    fontSize: 13,
  },
}));

const ShiftProcessButton: FC<ShiftProcessButtonInterfaceProps> = props => {
  const {
    recordId,
    resource,
    crudUpdate,
    metaData,
    defaultProcessUniqueId,
    processList,
    refreshRelation,
  } = props;
  const locale = useLocale();
  const classes = useStyles();
  const translate = useTranslate();

  const [processUniqueId, setProcessUniqueId] = useState(defaultProcessUniqueId);
  const [preparedShiftProcess, setPreparedShiftProcess] =
    useState<ProcessTaskInterface[]>();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setPreparedShiftProcess(prepareShiftProcess(metaData, processUniqueId));
  }, [processUniqueId]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShiftProcess = (task: ProcessTaskInterface) => () => {
    const data = [
      `process/${processUniqueId}/`,
      `toState/${task.stateId}/`,
      `toPosition/${task.positionId}/`,
    ];

    crudUpdate(resource, recordId, data, false, () => {
      actorDispatch('refreshView', 'showRecordPage', { disableDebounce: true });
      actorDispatch('refreshView', 'allRelations', { disableDebounce: true });
      refreshRelation();
    });
    handleClose();
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setProcessUniqueId(event.target.value as string);
  };

  return (
    <>
      <Tooltip title={translate('grid.shiftProcess')}>
        <IconButton
          className={classes.iconButton}
          onClick={handleClick}
          color="primary"
          id="shiftProcessButton"
        >
          <Icon className={classes.icon}>perm_data_setting</Icon>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        style={{ marginTop: '50px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>{translate('form.processSelection')}</InputLabel>
          <Select
            value={processUniqueId}
            onChange={handleChange}
            label={translate('form.processSelection')}
            classes={{ select: classes.selectInput }}
            id="selectProcessDropdown"
          >
            {processList &&
              processList.length &&
              processList.map((process: ProcessInterface) => {
                return (
                  process?.isActive && (
                    <MenuItem
                      value={process.uniqueId}
                      data-test-process-id={process.uniqueId.slice(0, 4)}
                      key={`process-MenuItem-${process.uniqueId}`}
                    >
                      {lodashGet(
                        process,
                        ['translatedTitle', locale],
                        lodashGet(process, 'title'),
                      )}
                    </MenuItem>
                  )
                );
              })}
          </Select>
        </FormControl>

        {preparedShiftProcess &&
          preparedShiftProcess.map((task: ProcessTaskInterface) => (
            <MenuItem
              key={`${task.positionId}${task.stateId}${task.title}`}
              data-test-prepared-shift-process={`${task.positionId}${task.stateId}${task.title}`}
              onClick={handleShiftProcess(task)}
            >
              {lodashGet(
                task,
                ['translatedTitle', locale],
                lodashGet(task, 'title'),
              )}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

const mapDispatchToProps = {
  crudUpdate: crudCustomUpdate,
  refreshRelation: refreshRelationAction,
};
export default connect(null, mapDispatchToProps)(ShiftProcessButton);
