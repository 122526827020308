export const SET_BASKET_ITEM = 'SHOP/SET_BASKET_ITEM';
export const REMOVE_BASKET_ITEM = 'SHOP/REMOVE_BASKET_ITEM';
export const GET_ORDER_DETAIL_ITEM = 'SHOP/GET_ORDER_DETAIL_ITEM';
export const GET_ORDER_DETAIL_ITEM_SUCCESS = 'SHOP/GET_ORDER_DETAIL_ITEM_SUCCESS';
export const GET_ORDER_DETAIL_ITEM_FAILED = 'SHOP/GET_ORDER_DETAIL_ITEM_FAILED';
export const GET_ORDER_DETAIL = 'SHOP/GET_ORDER_DETAIL';
export const GET_ORDER_DETAIL_SUCCESS = 'SHOP/GET_ORDER_DETAIL_SUCCESS';
export const FINALIZE_BASKET = 'SHOP/FINALIZE_BASKET';
export const SET_BASKET_PRODUCT_ID = 'SHOP/SET_BASKET_PRODUCT_ID';
export const GET_SUB_CATEGORY_DATA = 'SHOP/GET_SUB_CATEGORY_DATA';
export const GET_SUB_CATEGORY_SUCCESS = 'SHOP/GET_SUB_CATEGORY_SUCCESS';
export const REFRESH_BASKET = 'SHOP/REFRESH_BASKET';
