import { customRecordNotePin } from './note-relation/create-edit-note/consistent';
import { useEffect, useState, useRef } from 'react';

import { getRelationMetaData, requestRelationData } from './relation-panel.helper';
import { clone, isEmpty } from '../../helper/data-helper';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
  GridDataInterface,
} from '../../type/actor-setup';

import type {
  RelationAPIResultInterface,
  UseRelationDataType,
} from './relation-panel.type';
import type { GeneralMetaData } from '../../helper/Types';
import { apiRequestResultRelationHandler } from '../../helper/crud-api.helper';
import { getRootTableId } from '../../helper/meta-helper';

const useRelationData: UseRelationDataType = props => {
  const {
    relationResource,
    childFieldName,
    parentIdentifier,
    relationIndex,
    relationType,
  } = props;

  const relationCachedMetaData = actorGetActionValue(
    'metaData',
    relationResource,
  ) as GeneralMetaData | null;

  const [isRelationDataReady, setRelationDataReady] = useState<boolean>(false);
  const [relationData, setRelationData] = useState<GridDataInterface | null>(null);
  const [relationMetaData, setRelationMetaData] = useState<GeneralMetaData | null>(
    relationCachedMetaData,
  );
  const [metaDataError, setMetaDataError] = useState<string | null>(null);

  const relationDataAccessRef = useRef(relationData);
  const relationResourceAccessRef = useRef(relationResource);

  /**
   * remove request id from error message if exist , then set new message into meta error state
   * @function handleGetMetaError
   * @param {string} error
   * @returns {void} void
   */
  const handleGetMetaError = (error: string): void => {
    let splittedError: string | null = null;
    try {
      if (error.includes('^')) {
        splittedError = error.split('^')[0];
      }
    } catch (splitError) {
      console.log('splitError: ', splitError);
    }
    setMetaDataError(splittedError ?? error);
    setRelationDataReady(true);
  };

  const handleGetRelationDataRequestFailure = (
    apiResult: RelationAPIResultInterface,
  ): void => {
    let errorMessage = '';

    if (apiResult) {
      const getManyReferenceRelationErrorMessage = (
        apiResult.GET_MANY_REFERENCE?.relation?.data as string | null
      )
        ?.toString()
        .split('^')?.[0];

      const getListRelationErrorMessage = (
        apiResult.GET_LIST?.relation?.data as string | null
      )
        ?.toString()
        .split('^')?.[0];

      errorMessage =
        getManyReferenceRelationErrorMessage ?? getListRelationErrorMessage ?? '';
    }

    setRelationData({
      error: errorMessage,
    });
    setRelationDataReady(true);
  };

  /**
   * @function prepareRelationData
   * @returns {void} void
   */
  const prepareRelationData = async () => {
    // --------- handle meta data ---------

    const metaDataInActor =
      actorGetActionValue('metaData')?.[relationResourceAccessRef.current];

    if (!relationMetaData || !metaDataInActor) {
      if (relationIndex !== 0) {
        setRelationDataReady(true);
      } else {
        try {
          const metaData = await getRelationMetaData(
            relationResourceAccessRef.current,
            null,
            true,
          );

          setRelationMetaData(metaData?.[relationResourceAccessRef.current]);
          actorSetActionValue(
            'metaData',
            metaData?.[relationResourceAccessRef.current],
            {
              path: relationResourceAccessRef.current,
            },
          );

          requestRelationData(
            relationResourceAccessRef.current,
            relationType,
            parentIdentifier,
            childFieldName,
            metaData?.[relationResourceAccessRef.current],
            undefined,
            apiRequestResultRelationHandler,
            handleGetRelationDataRequestFailure,
          );

          return;
        } catch (error) {
          handleGetMetaError(error as string);
        }
      }
    } else {
      Promise.resolve().then(() => {
        if (
          (!relationResourceAccessRef.current.includes('report') &&
            relationCachedMetaData?.config?.moduleName !==
              (metaDataInActor as GeneralMetaData)?.config?.moduleName) ||
          relationCachedMetaData?.config?.moduleTableName !==
            (metaDataInActor as GeneralMetaData)?.config?.moduleTableName
        ) {
          setRelationMetaData(metaDataInActor as GeneralMetaData);
        } else {
          setRelationMetaData(relationCachedMetaData);
        }

        setRelationDataReady(true);
      });
    }

    // --------- handle data ---------
    if (relationIndex === 0) {
      requestRelationData(
        relationResource,
        relationType,
        parentIdentifier,
        childFieldName,
        relationMetaData,
        {},
        apiRequestResultRelationHandler,
        handleGetRelationDataRequestFailure,
      );
    }
  };

  /**
   * get new metaData and then request new data
   * @function getNewRelationMetaAndRefreshData
   * @param {string} parentRecordIdentifier
   * @returns {Promise<void>} void
   */
  const getNewRelationMetaAndRefreshData = async (
    parentRecordIdentifier: string,
  ): Promise<void> => {
    try {
      const metaData = await getRelationMetaData(relationResource);

      actorSetActionValue('metaData', metaData?.[relationResource], {
        path: relationResource,
      });
      setRelationMetaData(metaData?.[relationResource]);

      let preparedResource = relationResource;

      const isMultiResult =
        metaData?.[relationResource]['reportType'] === 'MultiResult';

      if (isMultiResult && relationData?.lastRequestId === undefined) {
        preparedResource = preparedResource + '/0';
      }

      requestRelationData(
        preparedResource,
        relationType,
        parentRecordIdentifier,
        childFieldName,
        metaData?.[relationResource],
        undefined,
        apiRequestResultRelationHandler,
        handleGetRelationDataRequestFailure,
      );
    } catch (error) {
      handleGetMetaError(error as string);
    }
  };

  useEffect(() => {
    relationDataAccessRef.current = relationData;
  }, [relationData?.lastRequestId]);

  useEffect(() => {
    if (!isEmpty(relationResource)) {
      Promise.resolve().then(() => {
        prepareRelationData();
      });
    }

    relationResourceAccessRef.current = relationResource;
  }, [relationResource]);

  useEffect(() => {
    actorOnDispatch('gridData', gridData => {
      const _relationMetaData = actorGetActionValue(
        'metaData',
        relationResourceAccessRef?.current,
      ) as unknown as GeneralMetaData;
      const isMultiResult = _relationMetaData?.['reportType'] === 'MultiResult';

      const _relationData: GridDataInterface = clone(
        isMultiResult
          ? gridData[relationResourceAccessRef?.current + '/0']
          : gridData[relationResourceAccessRef?.current],
      );

      const prevRequestId = relationDataAccessRef.current?.lastRequestId;
      const nextRequestId = _relationData?.lastRequestId;

      if (prevRequestId !== nextRequestId) {
        // Promise.resolve has been used because of batch state updating
        // it will reduce number of renders that they has been triggered by setState from three to one
        Promise.resolve().then(() => {
          setRelationData(_relationData);
          setRelationDataReady(true);
        });
      }
    });
  }, []);

  return [
    relationMetaData,
    relationData,
    isRelationDataReady,
    getNewRelationMetaAndRefreshData,
    metaDataError,
    handleGetRelationDataRequestFailure,
  ];
};

export default useRelationData;
