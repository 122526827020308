import { ReactElement } from 'react';
import { MessagesHeaderViewInterface } from './messages-header.type';
import { useStyles } from './messages-header.style';
import Box from '@material-ui/core/Box';
import { UserInfo } from '../../../chat-sidebar/user-info';

const MessagesHeaderView = (props: MessagesHeaderViewInterface): ReactElement => {
  const { selectedUser } = props;
  const classes = useStyles();

  const selectedUserInfo = {
    currentUserID: selectedUser.personinfo_id,
    displayName: selectedUser.personname,
    photoURL: selectedUser.personimage,
  };

  return (
    <Box
      width={1}
      display="flex"
      alignItems="center"
      className={classes.root}
      id="messageHeader"
    >
      <UserInfo user={selectedUserInfo} showStatus={false} />
    </Box>
  );
};

export default MessagesHeaderView;
