import { ChangeEvent, FC, memo, useCallback, useEffect, useState } from 'react';
import lodashDebounce from 'lodash/debounce';
import lodashGet from 'lodash/get';
import lodashOrderBy from 'lodash/orderBy';
import { isEmpty } from '../../../helper/data-helper';
import { actorDispatch, actorOnDispatch } from '../../../type/actor-setup';
import { contactsReportId, searchInMessagesReportId } from '../chat-section.helper';
import ChatSidebarView from './chat-sidebar.view';
import { FoundedMessageInterface } from './search-list';
import { markWordInString } from './search-list/search-list.helper';
import { ChatsDataInterface, ChatSidebarInterface } from './chat-sidebar.type';
import {
  ChatActions,
  ContactInterface,
  OnSelectUserParams,
  SelectedUserType,
} from '../chat-section.type';
import { showNotification } from '../../../helper/general-function-helper';

const ChatSidebarController: FC<ChatSidebarInterface> = memo(props => {
  const { chatActionsHandler, currentUser } = props;
  const [selectedUser, setSelectedUser] = useState<SelectedUserType | null>(null);

  const [chatsData, setChatsData] = useState<ChatsDataInterface>({
    data: [],
    hasMore: false,
  });
  const [searchValue, setSearchValue] = useState<string>('');
  const [foundedContacts, setFoundedContacts] = useState<ContactInterface[]>([]);
  const [foundedMessages, setFoundedMessages] = useState<FoundedMessageInterface[]>(
    [],
  );
  const [chatsLoading, setChatsLoading] = useState<boolean>(false);

  /**
   * @function onUserSelect
   * @returns { void }
   */
  const onUserSelect = (user: SelectedUserType): void => {
    chatActionsHandler &&
      chatActionsHandler(ChatActions.onSelectUser, {
        user,
      } as OnSelectUserParams);
  };

  /**
   * @function onRefreshChats
   * @returns { void }
   */
  const onRefreshChats = (): void => {
    chatActionsHandler && chatActionsHandler(ChatActions.onRefreshChats);
  };

  /**
   * @function onFetchMoreChats
   * @returns { void }
   */
  const onFetchMoreChats = (): void => {
    chatActionsHandler && chatActionsHandler(ChatActions.onfetchMoreChats);
  };

  /**
   * @function successSearchContactsCallback
   * @param { ContactsInterface[] } data
   * @returns { void }
   */
  const successSearchContactsCallback = ({ data }): void => {
    data && setFoundedContacts(data);
  };

  /**
   * @function successSearchInMessagesCallback
   * @param { ContactsInterface[] } data
   * @returns { void }
   */
  const successSearchInMessagesCallback = ({ data }): void => {
    data && setFoundedMessages(data);
  };

  /**
   * @function failureCallback
   * @param error
   * @returns { void }
   */
  const failureCallback = (error: any): void => {
    showNotification(error, 'error');
  };

  /**
   * to search in contacts
   * @function searchContacts
   * @param { Function } setLoading
   * @returns { void }
   */
  const searchContacts = useCallback(
    (
      setLoading: ((isLoading: boolean) => void) | null = null,
      ddsearchcode: string,
    ) => {
      setLoading && setLoading(true);
      actorDispatch(
        'getChatReport',
        {
          successCallback: successSearchContactsCallback,
          params: {
            reportId: contactsReportId,
            pagination: { perPage: 25 },
            filters: [['ddsearchcode', 'equal', ddsearchcode]],
          },
          failureCallback,
        },
        {
          disableDebounce: true,
        },
      );
    },
    [],
  );

  /**
   * to search in contacts
   * @function searchInMessages
   * @param { Function } setLoading
   * @returns { void }
   */
  const searchInMessages = useCallback(
    (
      setLoading: ((isLoading: boolean) => void) | null = null,
      ddsearchcode: string,
    ) => {
      setLoading && setLoading(true);
      actorDispatch(
        'getChatReport',
        {
          successCallback: successSearchInMessagesCallback,
          params: {
            reportId: searchInMessagesReportId,
            pagination: { perPage: 100 },
            filters: [['ddsearchcode', 'equal', ddsearchcode]],
          },
          failureCallback,
        },
        {
          disableDebounce: true,
        },
      );
    },
    [],
  );

  /**
   * @function handleDebouncedSearch
   * @param { string } value
   * @returns { void }
   */
  const handleDebouncedSearch = useCallback(
    lodashDebounce((value: string) => {
      searchContacts(null, value);
      searchInMessages(null, value);
    }, 1000),
    [],
  );

  /**
   * @function onSearch
   * @param { ChangeEvent<HTMLInputElement> } event
   * @returns { void }
   */
  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);

    if (!isEmpty(event.target.value)) {
      handleDebouncedSearch(event.target.value);
    }
  };

  // highlight search keyword
  useEffect(() => {
    foundedMessages?.map((item: FoundedMessageInterface) => {
      markWordInString(item.chattext, searchValue, item.chat_id);
    });
  }, [foundedMessages]);

  useEffect(() => {
    actorOnDispatch('loading', loading => {
      setChatsLoading(lodashGet(loading, [`chatsLoading`]));
    });

    actorOnDispatch('chatsData', chatsData => {
      chatsData && setChatsData(chatsData);
    });

    actorOnDispatch('selectedUser', selectedUser => {
      selectedUser !== undefined && setSelectedUser(selectedUser);
    });
  }, []);

  return (
    <ChatSidebarView
      chatListData={lodashOrderBy(chatsData.data, ['chatdate'], ['desc'])} //order all the chats by date
      hasMoreChats={chatsData.hasMore}
      chatsLoading={chatsLoading}
      fetchMoreChats={onFetchMoreChats}
      onUserSelect={onUserSelect}
      selectedUser={selectedUser}
      currentUser={currentUser}
      searchValue={searchValue}
      onSearch={onSearch}
      foundedContacts={foundedContacts}
      foundedMessages={foundedMessages}
      refreshChats={onRefreshChats}
    />
  );
});

export default ChatSidebarController;
