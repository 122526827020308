import React, {
  useState,
  useCallback,
  useMemo,
  FC,
  SyntheticEvent,
  useEffect,
  useRef,
} from 'react';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Icon, Tooltip, IconButton, Button, Tabs } from '@material-ui/core';
import { connect } from 'react-redux';
import lodashIsEqual from 'lodash/isEqual';
import lodashGet from 'lodash/get';

import { toggleTodoMenuSidebarAction } from '../../redux/todoList/action';
import TodoSearchBox from './TodoSearchBox';
import { getIconClassName } from '@uifabric/styling';
import ContextMenu from './ContextMenu';
import {
  getTodoListPatternInfo,
  getTodoTaskDetailsPatternInfo,
} from '../../helper/PatternMetaHelper';
import TodoShareListButton from './TodoShareListButton';
import { setSelectTodoList, getSysTaskFilters } from '../../redux/todoList/action';
import { isEmptyObject } from '../../helper/data-helper';

interface SysFiltersInterface {
  title: string;
  tableid: number;
}

interface FilterValuesType {
  [key: string]: string[];
}
interface TodoActionsProps {
  toggleTodoMenuSidebar: (event: SyntheticEvent<HTMLElement>) => void;
  translate: Function;
  currentSort: { field: string; order: string };
  selectedTodoList: object;
  todoListResource: string;
  setSort: Function;
  setFilters: Function;
  detailResource: string;
  setSelectTodoList: Function;
  filterValues: FilterValuesType;
  getSysTaskFilters: Function;
  resource: string;
  sysTaskFilters: SysFiltersInterface[];
  setUserSort: Function;
}

const useStyles = makeStyles(() => ({
  actionContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
  },

  actionContainerwithFilter: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'end',
    marginBottom: '-12px',
  },

  myRoot: {
    display: 'flex',
    overflow: 'hidden,',
    minHeight: '48px,',
    marginTop: -'13px,',
  },

  filter: {
    fontWeight: 800,
    minHeight: 28,
    paddingTop: 7,
  },

  filterContainer: {
    padding: '15px 15px 0 15px',
    display: 'flex',
  },

  filterBtn: {
    border: 'none',
  },

  myFlexContainer: {
    display: 'unset',
  },

  iconButton: {
    padding: 5,
    margin: '0 7px',
  },

  iconOrder: {
    fontSize: 16,
  },

  grow: {
    flexGrow: 1,
  },
}));

/**
 * A custom hook which specify the old value of input state
 * @function usePrevious
 * @param {object} oldState
 * @returns {any}
 */
const usePrevious = oldState => {
  const ref = useRef();
  useEffect(() => {
    ref.current = oldState;
  });
  return ref.current;
};

const TodoActions: FC<TodoActionsProps> = props => {
  const {
    toggleTodoMenuSidebar,
    translate,
    currentSort: activeSort,
    selectedTodoList,
    todoListResource,
    setSort,
    setFilters,
    detailResource,
    setSelectTodoList,
    filterValues,
    getSysTaskFilters,
    resource,
    sysTaskFilters,
    setUserSort,
  } = props;

  const classes = useStyles();

  const [currentSort, setCurrentSort] = useState(activeSort);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [preparedFilters, setPreparedFilters] = useState<SysFiltersInterface[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  useEffect(() => {
    if (isSortAvailable) {
      setUserSort({ ...currentSort });
    }
  }, [currentSort]);

  const prevSelectedFilters = usePrevious(selectedFilters);

  /**
   * Check if we should show sort button or not based on filters
   * @function isSortAvailable
   * @returns {boolean}
   */
  const isSortAvailable: boolean = useMemo(() => {
    if (!filterValues || isEmptyObject(filterValues)) {
      return true;
    }
    const { list_id: listID, detailtitle, filterName } = filterValues;
    return (
      !!listID ||
      !!detailtitle ||
      (filterName && filterName.length > 1 && !filterName[0].includes('sysitem'))
    );
  }, [filterValues]);

  /* Set filters based on selected filters for system tasks */
  useEffect(() => {
    if (isSortAvailable || !prevSelectedFilters) return;

    const sysAssignedValue =
      selectedFilters.length && selectedFilters.includes(' ') ? 1 : 0;
    const myFilter = {
      filterName: ['sysitem', '=', 1],
      SysAssigned: ['SysAssigned', '=', sysAssignedValue],
    };

    if (selectedFilters.length) {
      Object.assign(myFilter, {
        SysSection: ['SysSection', '=', selectedFilters.toString()],
      });
    }

    setFilters(myFilter);
  }, [selectedFilters]);

  /* Get/Set system task filtrs */
  useEffect(() => {
    if (!isSortAvailable) {
      if (sysTaskFilters && sysTaskFilters.length) {
        setPreparedFilters(sysTaskFilters);
      } else {
        // Request Filters list from API
        const { pagination } = getTodoListPatternInfo(todoListResource);
        const options = {
          filter: {
            sysitem: ['sysitem', '=', 1],
            SysHeader: ['SysHeader', '=', 1],
          },
          sort: {
            field: 'tableid',
            order: 'ASC',
          },
          pagination,
        };
        getSysTaskFilters(resource, options);
      }
    } else {
      setSelectedFilters([]);
    }
  }, [isSortAvailable, sysTaskFilters]);

  /* set filters based on filter values on first render */
  useEffect(() => {
    if (!filterValues || isSortAvailable) {
      return;
    }

    const tempFilters: string[] = [];

    if (filterValues.SysAssigned && filterValues.SysAssigned[2] == `${1}`) {
      // Assigned To me
      tempFilters.push(' ');
    }

    if (filterValues.SysSection && filterValues.SysSection[2] != ' ') {
      // Set selected filters
      tempFilters.push(filterValues.SysSection[2].replace(' ,', ''));
    }

    setSelectedFilters([...tempFilters]);
  }, []);

  /**
   * Specify sort label based on active sort field name
   * @function sortName
   * @returns {String}
   */
  const sortName: string = useMemo((): string => {
    let translateKey = '';
    switch (activeSort.field) {
      case 'isaddedtomyday':
        translateKey = 'IsAddedToMyDay';
        break;
      case 'isimportant':
        translateKey = 'IsImportant';
        break;
      case 'duedate':
        translateKey = 'DueDate';
        break;
      case 'detailtitle':
        translateKey = 'title';
        break;
      case 'createdate':
        translateKey = 'createDate';
        break;
      case 'isdone':
        translateKey = 'isDone';
        break;

      default:
        translateKey = 'sort';
        break;
    }
    return translate(`todo.${translateKey}`);
  }, [activeSort.field]);

  const { isImportant, dueDate, isAddToMyDay, isDone, title, createDate } =
    getTodoTaskDetailsPatternInfo(detailResource);

  /**
   * Open sort context menu
   * @function handleOpenSelect
   * @returns {void}
   */
  const handleOpenSelect = useCallback(
    name => event => {
      setIsContextMenuOpen(!isContextMenuOpen);
    },
    [isContextMenuOpen],
  );

  /**
   * Set sort based on user selected sort from context menu with 'ASC' order by default
   * @function sortItemClick
   * @returns {void}
   */
  const sortItemClick = useCallback(
    item => {
      const order = currentSort.order;
      const { name: field } = item;
      setCurrentSort({ field, order });
      setSort(field, order);
    },
    [currentSort],
  );

  /**
   * Dispatch setSelectTodoList to remove selected list in redux store
   * @function handleSelectedList
   * @returns {void}
   */
  const handleSelectedList = () => {
    setSelectTodoList({});
  };

  /**
   * Change sort order to opposite of current order
   * @function orderSort
   * @returns {void}
   */
  const orderSort = useCallback(() => {
    const { field, order } = currentSort;
    const newOrder = order === 'ASC' ? 'DESC' : 'ASC';
    setCurrentSort({ field, order: newOrder });
    setSort(field, newOrder);
  }, [setSort, currentSort]);

  /**
   * highlight selected filter buttons
   * @function isFilterSelected
   * @param {number|string} id
   * @returns {boolean}
   */
  const isFilterSelected = (id: number | string) =>
    selectedFilters.includes(`${id}`);

  /**
   * Update selected filters on state based on user click
   * @function handleFilterClick
   * @param {string} filterTableId
   * @returns {void}
   */
  const handleFilterClick = (filterTableId: string) => () => {
    if (selectedFilters.includes(filterTableId)) {
      setSelectedFilters(selectedFilters.filter(i => i != filterTableId));
    } else {
      setSelectedFilters([...selectedFilters, filterTableId]);
    }
  };

  //TODO: use translate for line 343 >> <Tooltip title="list">

  return isSortAvailable ? (
    <div className={classes.actionContainer}>
      <Tooltip title="list">
        <div>
          <IconButton
            color="primary"
            className={classes.iconButton}
            onClick={toggleTodoMenuSidebar}
          >
            <Icon fontSize="small">list</Icon>
          </IconButton>
        </div>
      </Tooltip>

      <TodoSearchBox
        resource={detailResource}
        translate={translate}
        setFilters={setFilters}
        handleSelectedList={handleSelectedList}
      />

      <div className={classes.grow}></div>
      <TodoShareListButton
        resource={todoListResource}
        record={selectedTodoList}
      ></TodoShareListButton>

      <Tooltip
        title={
          currentSort.order
            ? translate(`todo.${currentSort.order.toLowerCase()}`)
            : ''
        }
      >
        <IconButton
          color="primary"
          className={classes.iconButton}
          onClick={orderSort}
        >
          {currentSort.order === 'DESC' && (
            <i
              className={`${classes.iconOrder} ${getIconClassName('ChevronUp')}`}
              data-test-sort-order="DESC"
            ></i>
          )}
          {currentSort.order === 'ASC' && (
            <i
              className={`${classes.iconOrder} ${getIconClassName('ChevronDown')}`}
              data-test-sort-order="ASC"
            ></i>
          )}
        </IconButton>
      </Tooltip>
      <ContextMenu
        icon="Sort"
        title={translate('todo.sortBy')}
        btnTitle={sortName}
        items={[
          {
            name: isImportant,
            key: 'isImportant',
            title: translate('todo.IsImportant'),
            icon: (
              <i
                className={`${classes.iconOrder} ${getIconClassName(
                  'FavoriteStar',
                )}`}
              ></i>
            ),
          },
          {
            name: dueDate,
            key: 'dueDate',
            title: translate('todo.dueDate'),
            icon: (
              <i
                className={`${classes.iconOrder} ${getIconClassName('Calendar')}`}
              ></i>
            ),
          },
          {
            name: isAddToMyDay,
            key: 'isAddToMyDay',
            title: translate('todo.IsAddedToMyDay'),
            icon: (
              <i className={`${classes.iconOrder} ${getIconClassName('Sunny')}`}></i>
            ),
          },
          {
            name: isDone,
            key: 'isDone',
            title: translate('todo.isDone'),
            icon: (
              <i className={`${classes.iconOrder} ${getIconClassName('Clock')}`}></i>
            ),
          },
          {
            name: title,
            key: 'title',
            title: translate('todo.title'),
            icon: (
              <i className={`${classes.iconOrder} ${getIconClassName('Sort')}`}></i>
            ),
          },
          {
            name: createDate,
            key: 'createDate',
            title: translate('todo.createDate'),
            icon: (
              <i
                className={`${classes.iconOrder} ${getIconClassName(
                  'CalendarMirrored',
                )}`}
              ></i>
            ),
          },
        ]}
        isOpen={isContextMenuOpen}
        onItemClick={sortItemClick}
        onClick={handleOpenSelect(isContextMenuOpen)}
      />
    </div>
  ) : (
    <>
      <div className={classes.actionContainerwithFilter}>
        <Tooltip title="list">
          <div>
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={toggleTodoMenuSidebar}
            >
              <Icon fontSize="small">list</Icon>
            </IconButton>
          </div>
        </Tooltip>

        <TodoSearchBox
          resource={detailResource}
          translate={translate}
          setFilters={setFilters}
          handleSelectedList={handleSelectedList}
        />
      </div>
      <div
        className={classes.filterContainer}
        data-test-todo="system-task-filters-list"
      >
        <span className={classes.filter}>{translate('todo.quickFilters')}: </span>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          classes={{
            flexContainer: classes.myFlexContainer,
            root: classes.myRoot,
          }}
          data-test-todo-system-task-filter-tab
        >
          <Button
            onClick={handleFilterClick(' ')}
            className={classes.filterBtn}
            variant={isFilterSelected(' ') ? 'contained' : 'text'}
            color="primary"
            data-test-system-task-filter="my-tasks"
          >
            {translate('todo.assingedToMe')}
          </Button>
          {preparedFilters.length &&
            preparedFilters.map(filter => (
              <Button
                onClick={handleFilterClick(`${filter['tableid']}`)}
                className={classes.filterBtn}
                variant={
                  isFilterSelected(`${filter['tableid']}`) ? 'contained' : 'text'
                }
                color="primary"
                data-test-system-task-filter={filter['title']}
              >
                {filter['title']}
              </Button>
            ))}
        </Tabs>
      </div>
    </>
  );
};

TodoActions.propTypes = {
  toggleTodoMenuSidebar: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  sysTaskFilters: lodashGet(state, ['todoList', 'sysTaskFilters']),
});
const mapDispatchToProps = {
  toggleTodoMenuSidebar: toggleTodoMenuSidebarAction,
  setSelectTodoList,
  getSysTaskFilters,
};

export default compose(
  translate,
  connect(mapStateToProps, mapDispatchToProps),
)(TodoActions);
