export default {
  meta: {
    resourceIsNotDefined: 'منبع داده در متا به درستی تعریف نشده است!',
    getMetaFailure: 'خطا در دریافت متا',
  },
  general: {
    download: 'دانلود',
    requestId: 'شناسه درخواست',
    copied: 'کپی شد',
    noRecordFound: 'داده ای یافت نشد!',
    back: 'بازگشت',
    more: 'بیشتر',
    apply: 'ثبت',
    decline: 'لغو',
    search: 'جستجو ...',
    delete: 'حذف',
    start: 'شروع',
    pause: 'مکث',
    reset: 'ریست',
    noData: 'دیتایی برای نمایش وجود ندارد',
    pin: 'پین کردن پیام',
    theMenusAreNotAvailable: 'منوها در دسترس نیستند.',
  },
  form: {
    thereIsNoReturnInTheFormula: 'در فرمول "return" وجود ندارد',
    thereIsNoRecordOrFormula: 'رکورد یا فرمول وجود ندارد',
    recordNotExist: 'رکورد وجود ندارد',
    formulaIsNotValid: 'فرمول محاسباتی نامعتبر می باشد',
    errorInComputing: 'خطا در فرمول محاسبه',
    showLess: 'نمایش کمتر',
    showMore: 'نمایش بیشتر',
    showFull: 'نمایش کامل',
    chooseFile: 'انتخاب فایل',
    sendingData: 'در حال ارسال داده',
    createAndNew: 'ذخیره و جدید',
    createAndView: 'ذخیره و مشاهده',
    saveAsDefault: 'ذخیره به عنوان پیش فرض',
    restoreDefault: 'برگرداندن پیش فرض',
    newTab: 'تب جدید',
    editTabInfo: 'ویرایش تب',
    editGroupInfo: 'ویرایش گروه',
    editFieldInfo: 'ویرایش فیلد',
    translatedTitle: {
      fa: 'ترجمه فارسی',
      en: 'ترجمه انگلیسی',
      ar: 'ترجمه عربی',
    },
    translatedCaption: {
      fa: 'ترجمه فارسی',
      en: 'ترجمه انگلیسی',
      ar: 'ترجمه عربی',
    },
    columnCount: 'تعداد ستون',
    submitWasAlmostSuccessfulPleaseCheckRelationsAndFixErrors:
      'ارسال داده موفقیت آمیز بود ولی بعضی ریلیشن ها نیاز به اصلاح دارند. لطفا خطاها را برطرف نمایید و مجدد ذحیره کنید.',
    areYouSureAboutThisOperation: 'آیا از این عملیات اطمینان دارید؟',
    download: 'دانلود کنید.',
    processSelection: 'انتخاب فرایند',
  },
  grid: {
    groupByColumn: 'هدر ستون را برای گروه بندی بر اساس آن ستون، بکشید',
    filterPlaceholder: '',
    services: 'سرویس ها',
    shiftProcess: 'تغییر فرآیند',
    serviceIsRunning: 'سرویس در حال اجرا است، لطفا اندکی صبر بفرمایید ...',
    notes: 'یادداشت ها',
    columnFilters: 'فیلتر های ستونها',
    grouping: 'گروه بندی',
    quickCreate: 'ایجاد سریع',
    filter: 'فیلتر',
    sum: 'مجموع',
    resourceIsDisabled: 'منبع غیر فعال است',
    print: 'چاپ',
    quickEdit: 'ویرایش سریع',
    refresh: 'بروز‌رسانی',
    inlineEdit: 'برای ویرایش کلیک کنید',
    noColumns: 'ستونی برای نمایش وجود ندارد',
    groupingEmptyText: 'برای گروه بندی ستون را بکشید',
  },
  noteStream: {
    fetch: 'نمایش بیشتر',
    newNote: 'نوشته جدید',
    invalidNote: 'یادداشت بیش از 50 کاراکتر بدون کاراکتر فاصله غیرمعتبر است.',
  },
  process: {
    name: 'فرآیند',
  },
  menu: {
    home: 'خانه',
    uploadProfilePicture: 'آپلود عکس جدید برای پروفایل',
    menuNotFound: 'منو پیدا نشد',
    expand: 'باز شدن',
    shrink: 'جمع شدن',
    close: 'بستن',
    favorites: 'مورد علاقه ها',
    recent: 'اخیرا',
    module: 'ماژول',
    changePassword: 'تغییر کلمه عبور',
  },
  css: {
    drawer: 'داینامیک استایل',
    save: 'ذخیره',
    success: 'با موفقیت انجام شد',
  },
  dropdown: {
    placeholder: 'لطفا انتخاب کنید',
    noOptionsMessage: 'مقداری پیدا نشد',
    error: 'خطا در دریافت اطلاعات دراپ داون',
    loading: 'در حال دریافت اطلاعات',
    searchPlaceholder: 'جستجو کنید',
    noneLabel: 'هیچ کدام',
  },
  filter: {
    list: 'فیلتر های ذخیره شده',
    showEveryFilterInList: 'نمایش همه فیلتر های موجود',
    clearOnlyFilterValuesFromList: 'خالی کردن همه مقادیر از فیلتر های گرید',
    clearList: 'حذف فیلتر های گرید',
    saveFilter: 'ذخیره فیلتر',
    pleaseGiveANameToYourNewFilter: 'لطفا برای فیلتر جدید خود، نامی مناسب برگزینید.',
    name: 'نام',
    nameIsNotValid: 'لطفا نام بهتری وارد کنید.',
    clearFilterValue: 'خالی کردن',
    mode: {
      onDate: 'در تاریخ',
      fromDate: 'از تاریخ',
      untilDate: 'تا تاریخ',
      between: 'در بازه',
      contains: 'دارای',
      equals: 'دقیقا برابر',
      greaterThanEquals: 'بیشتر مساوی',
      lessThanEquals: 'کمتر مساوی',
      opposite: 'مخالف',
    },
    apply: 'اعمال',
  },
  listColumnChoice: {
    columnConfig: 'تنظیمات ستون ها',
    selectColumns: 'انتخاب ستون ها',
    selectColumnsThatYouWantToShow:
      'لطفا ستون هایی که می خواهید برای شما به نمایش دربیاید را انتخاب کنید',
    changeAll: 'انتخاب همه',
    currentChoicesAreByDefault: 'انتخاب های جاری بر اساس انتخاب پیش فرض هستند.',
    currentChoicesAreByUser: 'انتخاب های جاری بر اساس انتخاب کاربر هستند.',
    noColumnsToSelect: 'هیچ ستونی برای انتخاب نیست.',
  },
  quickCreate: {
    fullForm: 'فرم کامل',
  },
  confirm: {
    areYouSureYouWantToDeleteThisNote: 'آیا از حذف این یادداشت مطمئن هستید؟',
    areYouSureYouWantToDeleteThisItem: 'آیا از حذف این مورد مطمئن هستید؟',
    areYouSureYouWantToDeleteWholeTable: 'آیا از حذف کامل این جدول مطمئن هستید؟',
    areYouSureYouWantToUnPinWholeTable: 'آیا از برداشتن سنجاق مطمئن هستید؟',
    areYouSureYouWantToPinWholeTable: 'آیا از سنجاق کردن مطمئن هستید؟',
    areYouSureYouWantToDeleteThisCountOfItem:
      'آیا از حذف %{smart_count} مورد مطمئن هستید؟',
    yes: 'بله',
    no: 'خیر',
    ok: 'باشه',
    cancel: 'انصراف',
  },
  puzzle: {
    reset: 'بازنشانی',
  },
  profile: {
    profileSettings: 'تنظیمات پروفایل',
    editProfile: 'ویرایش پروفایل',
    firstName: 'نام',
    lastName: 'نام خانوادگی',
    address: 'آدرس',
    email: 'ایمیل',
    nationalCode: 'کد ملی',
    phoneNumber: 'شماره تلفن ثابت',
    mobileNumber: 'شماره تلفن همراه',
    accountingSoftware: 'نرم افزار حسابداری',
  },
  formLayout: {
    addNewSection: 'اضافه کردن بخش جدید',
  },
  print: {
    pleaseGiveBrowserAccessToOpenPopup:
      'لطفا به مرورگر اجازه باز کردن پاپ آپ بدهید!',
    ErrorInDetectingOpenedWindow: 'خطا در تشخیص پنجره باز شده!',
    printIdNotFoundInMeta: 'شناسه پرینت در متا وجود ندارد',
  },
  tree: {
    tree: 'درخت',
    noDataToDisplay: 'داده ای برای نمایش وجود ندارد',
    itIsNotPossibleToSelectFromThisLevel: 'انتخاب از این سطح امکان پذیر نیست.',
  },
  pos: {
    discount: 'تخفیف',
    subtotal: 'مجموع',
    tax: 'مالیات',
    total: 'قابل پرداخت',
    addCustomer: 'اضافه کردن مشتری',
    note: 'یادداشت...',
    discard: 'لغو سفارش',
    park: 'پارک',
    pay: 'پرداخت',
    searchForProductNameOrBarcode: 'اسم یا بارکد محصول را جست‌وجو کنید',
    giftCard: 'کارت هدیه',
    customSale: 'فروش سفارشی',
  },
  todo: {
    thereIsNothingTapNewTaskToGetStarted:
      'کاری وجود ندارد. برای شروع "کار جدید" را بزنید.',
    newTask: 'کار جدید',
    newStep: 'مرحله جدید',
    add: 'ایجاد',
    tasks: 'کارها',
    newList: 'لیست جدید',
    newGroup: 'گروه جدید',
    deleteGroup: 'حذف گروه',
    ungroup: 'جداسازی گروه',
    renameGroup: 'تغییر نام گروه',
    IsAddedToMyDay: 'روز من',
    IsImportant: 'مهم ها',
    DueDate: 'برنامه ریزی شده ها',
    assignedtome: 'سپرده شده ها',
    sysitem: 'وظایف سیستمی',
    deleteTodoList: 'حذف لیست',
    renameTodoList: 'ویرایش نام لیست',
    renamedSuccessfully: 'ویرایش نام لیست انجام شد!',
    areYouSureYouWantToDeleteThisTask: 'آیا از حذف این کار مطمئن هستید؟',
    areYouSureYouWantToDeleteThisStep: 'آیا از حذف این مرحله مطمئن هستید؟',
    areYouSureYouWantToDeleteThisList: 'آیا از حذف این لیست مطمئن هستید؟',
    note: 'یادداشت',
    isDone: 'انجام شده',
    hideCompleted: 'مخفی کردن انجام‌شده‌ها',
    showCompleted: 'نمایش انجام‌شده‌ها',
    title: 'نام',
    createDate: 'تاریخ ایجاد',
    options: 'گزینه ها',
    sort: 'مرتب سازی',
    sortBy: 'مرتب سازی بر اساس',
    asc: 'صعودی',
    desc: 'نزولی',
    today: 'امروز',
    tomorrow: 'فردا',
    overmorrow: 'پس‌فردا',
    nextWeek: 'هفته بعد',
    customDateTime: 'انتخاب تاریخ و ساعت',
    customDate: 'انتخاب تاریخ',
    remindMe: 'به من یادآوری کن',
    dueDate: 'تاریخ تمام شدن کار',
    addToMyDay: 'به روز من اضافه کن',
    daily: 'روزانه',
    weekly: 'هفته‌ای',
    monthly: 'ماهانه',
    yearly: 'سالانه',
    repeat: 'تکرار',
    noOptionsMessage: 'قابلیت تکرار تعریف نشده',
    areYouSureYouWantToDeleteThisFile: 'آیا از حذف این فایل مطمئن هستید؟',
    myDay: 'روز من',
    duplicateList: 'کپی لیست',
    shareList: 'اشتراک لیست',
    assignTo: 'محول کردن',
    dragListToEmptyGroup: 'لیستی را بکشید',
    listIsAlreadyShared: 'این لیست قبلا با این شخص به اشتراک گذاشته شده است.',
    selectAPerson: 'لطفا یک شخص را انتخاب کنید.',
    notYourself: 'شما نمی‌توانید لیست خود را با خودتان به اشتراک بگذارید.',
    assingedToMe: 'کارهای من',
    assingToMe: 'بسپار به من',
    quickFilters: 'فیلترها',
    invalidDate: 'تاریخ نامعتبر',
    link: 'لینک',
  },
  shop: {
    basket: {
      payableAmount: 'مبلغ قابل پرداخت',
      finalizeBasket: 'نهایی کردن خرید',
      currency: 'ریال',
      unitPrice: 'قیمت واحد',
      totalPrice: 'قیمت کل',
      discount: 'تخفیف',
      finalPrice: 'قیمت نهایی',
    },
  },
  login: {
    mobile: 'موبایل',
    confirmation: 'کد تایید',
    send_mobile: 'ارسال تلفن همراه',
    send_confirmation: 'ارسال کد تایید',
  },
  sellin: {
    id: 'شناسه',
    createDate: 'تاریخ ایجاد',
    itemCount: 'تعداد',
    totalItemCount: 'تعداد کل',
    totalDiscountPrice: 'تخفیف کل',
    totalWholePrice: 'قیمت کل',
    finalPrice: 'قیمت نهایی',
    settlementTypeTitle: 'تسویه',
    orderStatusTitle: 'وضعیت',
    successfulOnlinePayment: 'پرداخت آنلاین موفق',
    orderInformation: 'اطلاعات سفارش',
    orderStatusDescription: 'وضعیت سفارش',
    detail: 'جزئیات',
    showProduct: 'نمایش محصول',
    price: 'قیمت',
    tags: 'برچسب ها',
    description: 'توضیحات',
    addToCart: 'افزودن به سبد خرید',
    priceDetails: 'جزئیات قیمت',
    settlementType: 'نوع تسویه',
    submitOrder: 'ثبت سفارش',
    areYouConfidentInFinalizingYourOrder:
      'آیا برای نهایی کردن سفارش خود اطمینان دارید؟',
    yourOrderHasBeenRegistered: 'سفارش شما ثبت شد.',
    addedToBasketSuccessfully: 'به سبد خرید با موفقیت اضافه شد',
    sortBy: 'مرتب‌سازی براساس',
    filterBy: 'فیلتر کردن براساس',
    sort: 'مرتب‌سازی',
    filter: 'فیلتر کردن',
    pleaseEnterTheNameOfTheDesiredProductInTheBoxAbove:
      'لطفا نام کالای مورد نظر را در کادر بالا وارد کنید.',
    noProductFound: 'کالایی پیدا نشد!',
    inventory: 'موجودی',
    ordering: 'مرتب سازی',
    barcodes: 'بارکدها',
  },
  customValidation: {
    validationErrorOnTab: 'خطای اعتبارسنجی فیلد ها در پنجره %{tabName}',
    separator: 'و',
    valueOfField: 'مقدار فیلد ',
    valueOfFields: 'مقدار فیلد های ',
    notValid: ' باید اصلاح شود',
    areNotValid: ' باید اصلاح شوند',
    invalidValue: 'مقدار وارد شده نامعتبر میباشد',
    unknownTab: 'تب نامشخص',
  },
  service: {
    success: 'سرویس با موفقیت انجام شد.',
  },
  toolbar: {
    importComponent: {
      import: 'وارد کردن',
      importExcelFile: 'وارد کردن فایل اکسل',
      downloadSampleFile: 'دانلود فایل نمونه',
      uploadExcelFile: 'بارگذاری',
      chooseAfile: 'پرونده ای را انتخاب کنید',
    },
  },
  quickAccess: {
    quickAccess: 'دسترسی سریع',
    actionDescription: {
      quickAccess: 'میانبر مورد نظر خود را در کادر بنویسید و دکمه اینتر را بزنید.',
    },
  },
  imageDialog: {
    dataNotFound: 'دیتایی برای نمایش وجود ندارد',
  },
  editor: {
    placeholder: 'محتوایی را ایجاد کنید!',
    staticVariables: 'متغیر های نامه',
  },
  fileManager: {
    properties: 'مشخصات',
    propertiesError: 'جهت نمایش مشخصات لطفا آیتمی را انتخاب کنید',
  },
  notification: {
    changePassword: {
      success: 'تغییر کلمه عبور با موفقیت انجام شد',
    },
    messages: 'پیام ها',
  },
  blog: {
    recentNotes: 'اعلانات',
    continueReading: 'ادامه مطالب ...',
  },
  file: {
    fileUploadLimitMB: 'حجم فایل بیشتر از %{fileUploadLimitMB} مگابایت می باشد.',
    file: 'فایل',
    files: 'فایل های ',
    and: 'و',
    couldNotSendFileBecauseItsLargeSize:
      'به دلیل حجم بالا تر از مقدار مجاز ارسال نشد',
    couldNotSendFilesBecauseItsLargeSize:
      'به دلیل حجم بالا تر از مقدار مجاز ارسال نشدند',
  },
  calendar: {
    items: 'آتیم ها',
    today: 'امروز',
    back: 'قبل',
    next: 'بعد',
    month: 'ماه',
    week: 'هفته',
    day: 'روز',
    require: 'اجباری',
    calendar: 'تقویم ',
  },
  chat: {
    startChat: 'کاربری را برای شروع گپ انتخاب کنید',
    sayHi: 'سلام کنید به',
    messages: 'گفتگوها',
    contacts: 'مخاطبین',
    search: 'جستجو ...',
    reply: 'پاسخ دادن',
    deleteMessage: 'حذف پیام',
    deleteConfirmMessage: 'آیا از حذف این پیام اطمینان دارید؟',
    edit: 'ویرایش',
    messagePlaceHolder: 'تایپ کنید ...',
    noUserFound: 'مخاطبی یافت نشد!',
    noMessagesFound: 'گفتگویی یافت نشد!',
    selectedFiles: ' %{count} فایل انتخاب شده',
  },
  mail: {
    automation: 'کارتابل',
    newMessage: 'پیام داخلی',
    downloadAll: 'دانلود همه',
    newMail: 'نامه صادره',
    inMail: 'نامه وارده',
    folders: 'پیام ها',
    labels: 'برچسب ها',
    send: 'ارسال',
    saveAsDraft: 'ذخیره به عنوان پیشنویس',
    reply: 'پاسخ دادن',
    replySubject: 'پاسخ',
    replyAll: 'پاسخ به همه',
    attachedFiles: 'فایل های ضمیمه',
    successReply: 'پاسخ پیام با موفقیت ارسال شد',
    successArchive: 'عملیات ارجاع و آرشیو با موفقیت انجام شد',
    successRecall: 'عملیات ریکال با موفقیت انجام شد',
    recallConfirmMessage: 'از اجرای سرویس ریکال اطمینان دارید؟',
    confirmDeleteFolderMessage: 'از حذف پوشه : %{folderName} اطمینان دارید ؟',
    mailNotFound: 'پیام یافت نشد!',
    forward: 'ارسال به دیگران',
    sender: 'فرستنده',
    toStar: 'ستاره دار کردن',
    toArchive: 'آرشیو',
    toUnArchive: 'حذف از آرشیو',
    toReference: 'ارجاع',
    markAsRead: 'علامت گذاری به عنوان خوانده شده',
    markAsUnRead: 'علامت گذاری به عنوان خوانده نشده',
    markAsImportant: 'علامت گذاری به عنوان مهم',
    markAsStarred: 'ستاره دار کردن',
    removeStarred: 'حذف ستاره',
    removeImportant: 'حذف مهم',
    applyLabel: 'تخصیص برچسب',
    newLabel: 'برچسب جدید',
    labelTitle: 'عنوان برچسب',
    colorCode: 'کد رنگ',
    parentLabel: 'برچسب والد',
    template: 'الگو های پیام',
    noTemplateData: 'الگوی پیام یافت نشد',
    followUp: 'پیگیری',
    messageText: 'متن پیام',
    references: 'ارجاعات',
    cycle: 'گردش',
    recall: 'ریکال',
    personalFolders: 'پوشه های شخصی',
    createFolder: 'ایجاد پوشه جدید',
    moveTo: 'انتقال به',
    cc: 'رونوشت',
    bcc: 'رونوشت پنهان',
    from: 'از',
    to: 'به',
    mailSubject: 'موضوع نامه',
    notSeen: 'مشاهده نشده',
    delegation: 'تفویض',
    seeAll: 'مشاهده',
    docType: {
      allMail: 'تمام نامه ها',
      inbox: 'صندوق ورودی',
      outbox: 'صندوق خروجی',
      unread: 'خوانده نشده',
      archived: 'آرشیو شده',
      starred: 'ستاره دار',
      important: 'مهم',
      innerMail: 'نامه داخلی',
      systemTasks: 'وظایف سیستمی',
      message: 'پیام ها',
      draft: 'پیش نویس',
    },
    referenceListHeader: {
      date: 'تاریخ',
      sender: 'فرستنده',
      receiver: 'گیرنده',
      referenceParaph: 'متن ارجاع',
      seenDate: 'تاریخ مشاهده',
      kind: 'نوع',
    },
  },
  pivot: {
    fieldChooserTitle: 'تنظیمات جدول',
    allFields: 'همه ستون ها',
    filterFields: 'فیلد های جستجو',
    dataFields: 'اطلاعات جدول',
    columnFields: 'ستون های جدول',
    rowFields: 'سطر های جدول',
    excelExportFileName: 'گزارش',
    sortColumnBySummary: 'مرتب سازی ستون ها',
    sortRowBySummary: 'مرتب سازی سطرها',
    showFieldChooser: 'نمایش دیالوگ انتخاب فیلدها',
    exportToExcel: 'خروجی اکسل',
    expandAll: 'باز کردن همه',
    dataNotAvailable: 'دیتایی وجود ندارد',
    collapseAll: 'بستن همه',
    grandTotal: 'مجموع',

    columnFieldArea: 'ستون های جدول را اینجا قرار دهید',
    dataFieldArea: 'اطلاعات جدول را اینجا قرار دهید',
    filterFieldArea: 'فیلد های جستجو را اینجا قرار دهید',
    rowFieldArea: 'سطر های جدول را اینجا قرار دهید',

    settingSavedSuccessfully: 'تنظیمات با موفقیت ذخیره شد',
  },
  dynamicInput: {
    emptyValue: 'خالی',
  },
  datePicker: {
    placeholder: 'روز/ماه/سال',
    dateTimePlaceholder: 'روز/ماه/سال دقیقه:ساعت',
  },
};
