import { isEmptyObject } from '../../../helper/data-helper';
import { InitialData, InputRefContent } from '../../form';

export const staticNewMessageFormFieldsData: Record<string, unknown>[] = [
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: true,
    comment: 'ایا رونوشت پنهان دارد؟',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'checkBox',
      sql: 'bit',
      simple: 'boolean',
      id: 104,
      defaultOperator: 'Equals',
    },
    disabled: false,
    defaultValue: false,
    parentField: null,
    moduleName: 'automation',
    id: 639761,
    caption: 'ایا رونوشت پنهان دارد؟',
    translatedCaption: {
      fa: 'ایا رونوشت پنهان دارد؟',
      en: 'IsFlag',
      ar: 'IsFlag',
    },
    translatedComment: {
      fa: 'ایا رونوشت پنهان دارد؟',
      en: 'ایا رونوشت پنهان دارد؟',
      ar: 'ایا رونوشت پنهان دارد؟',
    },
    relatedName: 'bccinputisactive',
    linkName: null,
    name: 'bccinputisactive',
    required: true,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: true,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: false,
    comment: 'گيرنده اصلي',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'stringMultiSelectDropBase',
      sql: 'nvarchar(max)',
      simple: 'string',
      id: 194,
      defaultOperator: 'Contains',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 63957,
    caption: 'گيرنده اصلي',
    translatedCaption: {
      fa: 'گيرنده اصلي',
      en: 'ToPersonInfo_ID',
      ar: 'ToPersonInfo_ID',
    },
    translatedComment: {
      fa: 'گيرنده اصلي',
      en: 'گيرنده اصلي',
      ar: 'گيرنده اصلي',
    },
    relatedName: '__topersoninfo_id_value',
    linkName: '__topersoninfo_id_link',
    name: 'topersoninfo_id',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: {
      id: 6497,
      uniqueId: '746ac586-51e9-4bba-813d-9f5c2501743a',
      comment: '',
      displayMember: 'fullname',
      displayMember2: null,
      valueMember: 'personinfo_id',
      moduleName: 'person',
      tableName: 'personinfo',
      type: 'SearchDialog',
      title: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
      translatedTitle: {
        fa: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
        en: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
        ar: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
      },
      translatedComment: null,
      treeLevel: 0,
      forceTreeLevel: false,
      remoteSearch: true,
      parameters: [
        {
          from: 'departmentinfo_id',
          to: 'departmentinfo_id',
          defaultValue: null,
          moduleName: 'automation',
          moduleTableName: 'messages',
        },
      ],
      maps: [],
      columns: [
        {
          isHidden: false,
          width: 100,
          priority: 0,
          title: 'personinfo_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'PersonInfo_ID',
            en: 'PersonInfo_ID',
            ar: 'PersonInfo_ID',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 1,
          title: 'fullname',
          format: '',
          dataType: {
            erp: 'computedPersist',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 16,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'نام',
            en: 'Full Name',
            ar: 'الاسم الكامل',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 5,
          title: 'mobile',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'همراه',
            en: 'همراه',
            ar: 'همراه',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 6,
          title: 'usergroupid',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'چارت سازماني',
            en: 'چارت سازماني',
            ar: 'چارت سازماني',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 7,
          title: 'mobilephone',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'MobilePhone',
            en: 'mobilephone',
            ar: 'mobilephone',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 8,
          title: 'organizationunit_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'OrganizationUnit_ID',
            en: 'OrganizationUnit_ID',
            ar: 'OrganizationUnit_ID',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 8,
          title: 'persondepartmentinfo_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'شعبه',
            en: 'شعبه',
            ar: 'شعبه',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 9,
          title: 'tel',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'تلفن تماس',
            en: 'Tel',
            ar: 'Tel',
          },
        },
      ],
      table: null,
    },
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: false,
    comment: 'رونوشت',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'stringMultiSelectDropBase',
      sql: 'nvarchar(max)',
      simple: 'string',
      id: 194,
      defaultOperator: 'Contains',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 63958,
    caption: 'رونوشت',
    translatedCaption: {
      fa: 'رونوشت',
      en: 'CCPersonInfo_ID',
      ar: 'CCPersonInfo_ID',
    },
    translatedComment: {
      fa: 'رونوشت',
      en: 'رونوشت',
      ar: 'رونوشت',
    },
    relatedName: '__ccpersoninfo_id_value',
    linkName: '__ccpersoninfo_id_link',
    name: 'ccpersoninfo_id',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: {
      id: 6497,
      uniqueId: '746ac586-51e9-4bba-813d-9f5c2501743a',
      comment: '',
      displayMember: 'fullname',
      displayMember2: null,
      valueMember: 'personinfo_id',
      moduleName: 'person',
      tableName: 'personinfo',
      type: 'SearchDialog',
      title: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
      translatedTitle: {
        fa: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
        en: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
        ar: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
      },
      translatedComment: null,
      treeLevel: 0,
      forceTreeLevel: false,
      remoteSearch: true,
      parameters: [
        {
          from: 'departmentinfo_id',
          to: 'departmentinfo_id',
          defaultValue: null,
          moduleName: 'automation',
          moduleTableName: 'messages',
        },
      ],
      maps: [],
      columns: [
        {
          isHidden: false,
          width: 100,
          priority: 0,
          title: 'personinfo_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'PersonInfo_ID',
            en: 'PersonInfo_ID',
            ar: 'PersonInfo_ID',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 1,
          title: 'fullname',
          format: '',
          dataType: {
            erp: 'computedPersist',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 16,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'نام',
            en: 'Full Name',
            ar: 'الاسم الكامل',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 5,
          title: 'mobile',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'همراه',
            en: 'همراه',
            ar: 'همراه',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 6,
          title: 'usergroupid',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'چارت سازماني',
            en: 'چارت سازماني',
            ar: 'چارت سازماني',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 7,
          title: 'mobilephone',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'MobilePhone',
            en: 'mobilephone',
            ar: 'mobilephone',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 8,
          title: 'organizationunit_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'OrganizationUnit_ID',
            en: 'OrganizationUnit_ID',
            ar: 'OrganizationUnit_ID',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 8,
          title: 'persondepartmentinfo_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'شعبه',
            en: 'شعبه',
            ar: 'شعبه',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 9,
          title: 'tel',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'تلفن تماس',
            en: 'Tel',
            ar: 'Tel',
          },
        },
      ],
      table: null,
    },
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: false,
    comment: 'رونوشت مخفي',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'stringMultiSelectDropBase',
      sql: 'nvarchar(max)',
      simple: 'string',
      id: 194,
      defaultOperator: 'Contains',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 63959,
    caption: 'رونوشت مخفي',
    translatedCaption: {
      fa: 'رونوشت مخفي',
      en: 'BCCPersonInfo_ID',
      ar: 'BCCPersonInfo_ID',
    },
    translatedComment: {
      fa: 'رونوشت مخفي',
      en: 'رونوشت مخفي',
      ar: 'رونوشت مخفي',
    },
    relatedName: '__bccpersoninfo_id_value',
    linkName: '__bccpersoninfo_id_link',
    name: 'bccpersoninfo_id',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: {
      id: 6497,
      uniqueId: '746ac586-51e9-4bba-813d-9f5c2501743a',
      comment: '',
      displayMember: 'fullname',
      displayMember2: null,
      valueMember: 'personinfo_id',
      moduleName: 'person',
      tableName: 'personinfo',
      type: 'SearchDialog',
      title: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
      translatedTitle: {
        fa: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
        en: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
        ar: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
      },
      translatedComment: null,
      treeLevel: 0,
      forceTreeLevel: false,
      remoteSearch: true,
      parameters: [
        {
          from: 'departmentinfo_id',
          to: 'departmentinfo_id',
          defaultValue: null,
          moduleName: 'automation',
          moduleTableName: 'messages',
        },
      ],
      maps: [],
      columns: [
        {
          isHidden: false,
          width: 100,
          priority: 0,
          title: 'personinfo_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'PersonInfo_ID',
            en: 'PersonInfo_ID',
            ar: 'PersonInfo_ID',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 1,
          title: 'fullname',
          format: '',
          dataType: {
            erp: 'computedPersist',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 16,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'نام',
            en: 'Full Name',
            ar: 'الاسم الكامل',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 5,
          title: 'mobile',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'همراه',
            en: 'همراه',
            ar: 'همراه',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 6,
          title: 'usergroupid',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'چارت سازماني',
            en: 'چارت سازماني',
            ar: 'چارت سازماني',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 7,
          title: 'mobilephone',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'MobilePhone',
            en: 'mobilephone',
            ar: 'mobilephone',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 8,
          title: 'organizationunit_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'OrganizationUnit_ID',
            en: 'OrganizationUnit_ID',
            ar: 'OrganizationUnit_ID',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 8,
          title: 'persondepartmentinfo_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'شعبه',
            en: 'شعبه',
            ar: 'شعبه',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 9,
          title: 'tel',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'تلفن تماس',
            en: 'Tel',
            ar: 'Tel',
          },
        },
      ],
      table: null,
    },
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: [['bccinputisactive', '', 'value = true']],
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: ' return  formData.bccinputisactive  ==  true',
  },

  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: false,
    comment: 'عطف به نامه',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'dropdown',
      sql: 'bigint',
      simple: 'number',
      id: 194,
      defaultOperator: 'Equals',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 6603,
    caption: 'عطف به نامه',
    translatedCaption: {
      fa: 'عطف به نامه',
      en: 'Letters_ID',
      ar: 'Letters_ID',
    },
    translatedComment: {
      fa: 'عطف به نامه',
      en: 'عطف به نامه',
      ar: 'عطف به نامه',
    },
    relatedName: '__letters_id_value',
    linkName: '__letters_id_link',
    name: 'letters_id',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: {
      id: 6603,
      uniqueId: '51623dfe-c7e3-4530-b9b5-55a301600050',
      comment: '',
      displayMember: 'abstract',
      displayMember2: null,
      valueMember: 'letters_id',
      moduleName: 'automation',
      tableName: 'letters',
      type: 'SearchDialog',
      title: 'عطف به نامه',
      translatedTitle: {
        fa: 'عطف به نامه',
        en: 'عطف به نامه',
        ar: 'عطف به نامه',
      },
      translatedComment: null,
      treeLevel: 0,
      forceTreeLevel: false,
      remoteSearch: true,
      parameters: [],
      maps: [],
      columns: [
        {
          isHidden: false,
          width: 100,
          priority: 0,
          title: 'letters_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'شناسه نامه',
            en: 'letters_id',
            ar: 'letters_id',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 5,
          title: 'letterno',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'شماره نامه',
            en: 'letterno',
            ar: 'letterno',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 5,
          title: 'abstract',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'چكيده',
            en: 'چكيده',
            ar: 'چكيده',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 5,
          title: 'letterdate',
          format: null,
          dataType: {
            erp: 'date',
            sql: 'date',
            simple: 'date',
            id: 167,
            defaultOperator: 'Between',
          },
          translatedTitle: {
            fa: 'تاريخ نامه',
            en: 'تاريخ نامه',
            ar: 'تاريخ نامه',
          },
        },
      ],
      table: null,
    },
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },

  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 200,
    allowSort: true,
    hasValidationActions: false,
    comment: 'موضوع پیام',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'string',
      sql: 'nvarchar(max)',
      simple: 'string',
      id: 167,
      defaultOperator: 'Contains',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 62528,
    caption: 'موضوع پیام',
    translatedCaption: {
      fa: 'موضوع پیام',
      en: 'Subject',
      ar: 'Subject',
    },
    translatedComment: {
      fa: 'موضوع پیام',
      en: 'موضوع پیام',
      ar: 'موضوع پیام',
    },
    relatedName: 'subject',
    linkName: null,
    name: 'subject',
    required: true,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: 100,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },

  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: false,
    comment: 'ضمائم',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'multiFileStream',
      sql: 'nvarchar(max)',
      simple: 'string',
      id: 204,
      defaultOperator: 'Equals',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 63975,
    caption: 'ضمائم',
    translatedCaption: {
      fa: 'ضمائم',
      en: 'AttachFiles',
      ar: 'AttachFiles',
    },
    translatedComment: {
      fa: 'ضمائم',
      en: 'ضمائم',
      ar: 'ضمائم',
    },
    relatedName: 'attachfiles',
    linkName: null,
    name: 'attachfiles',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: 'G18',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },

  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: false,
    comment: 'متن پيام',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'html',
      sql: 'nvarchar(max)',
      simple: 'html',
      id: 193,
      defaultOperator: 'Contains',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 62532,
    caption: 'متن پيام',
    translatedCaption: {
      fa: 'متن پيام',
      en: 'MessageBody',
      ar: 'MessageBody',
    },
    translatedComment: {
      fa: 'متن پيام',
      en: 'MessageBody',
      ar: 'متن پيام',
    },
    relatedName: 'messagebody',
    linkName: null,
    name: 'messagebody',
    required: true,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },
];

export const staticFollowUpFormFields: Record<string, unknown>[] = [
  {
    fixCalendar: 'jalali',
    regex: null,
    regexDescription: null,
    width: 130,
    allowSort: true,
    hasValidationActions: true,
    comment: 'مهلت پاسخ',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'dateTime',
      sql: 'dateTime',
      simple: 'datetime',
      id: 61,
      defaultOperator: 'Between',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 62530,
    caption: 'مهلت پاسخ',
    translatedCaption: {
      fa: 'مهلت پاسخ',
      en: 'DeadLineDate',
      ar: 'DeadLineDate',
    },
    translatedComment: {
      fa: 'مهلت پاسخ',
      en: 'مهلت پاسخ',
      ar: 'مهلت پاسخ',
    },
    relatedName: 'deadlinedate',
    linkName: null,
    name: 'deadlinedate',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: true,
    comment: 'پيگيري',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'checkBox',
      sql: 'bit',
      simple: 'boolean',
      id: 104,
      defaultOperator: 'Equals',
    },
    disabled: false,
    defaultValue: false,
    parentField: null,
    moduleName: 'automation',
    id: 63976,
    caption: 'پيگيري',
    translatedCaption: {
      fa: 'پيگيري',
      en: 'IsFlag',
      ar: 'IsFlag',
    },
    translatedComment: {
      fa: 'پيگيري',
      en: 'پيگيري',
      ar: 'پيگيري',
    },
    relatedName: 'isflag',
    linkName: null,
    name: 'isflag',
    required: true,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: true,
    comment: 'تاريخ شروع',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'date',
      sql: 'date',
      simple: 'date',
      id: 40,
      defaultOperator: 'Between',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 63977,
    caption: 'تاريخ شروع',
    translatedCaption: {
      fa: 'تاريخ شروع',
      en: 'FlagStartDate',
      ar: 'FlagStartDate',
    },
    translatedComment: {
      fa: 'تاريخ شروع',
      en: 'تاريخ شروع',
      ar: 'تاريخ شروع',
    },
    relatedName: 'flagstartdate',
    linkName: null,
    name: 'flagstartdate',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: [['isflag', '', 'value = true']],
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: ' return  formData.isflag  ==  true',
  },
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: false,
    comment: 'مهلت انجام',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'date',
      sql: 'date',
      simple: 'date',
      id: 40,
      defaultOperator: 'Between',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 63978,
    caption: 'مهلت انجام',
    translatedCaption: {
      fa: 'مهلت انجام',
      en: 'FlagDueDate',
      ar: 'FlagDueDate',
    },
    translatedComment: {
      fa: 'مهلت انجام',
      en: 'مهلت انجام',
      ar: 'مهلت انجام',
    },
    relatedName: 'flagduedate',
    linkName: null,
    name: 'flagduedate',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: [['isflag', '', 'value = true']],
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: ' return  formData.isflag  ==  true',
  },
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: true,
    comment: 'يادآوري',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'checkBox',
      sql: 'bit',
      simple: 'boolean',
      id: 104,
      defaultOperator: 'Equals',
    },
    disabled: false,
    defaultValue: false,
    parentField: null,
    moduleName: 'automation',
    id: 63979,
    caption: 'يادآوري',
    translatedCaption: {
      fa: 'يادآوري',
      en: 'FlagReminderActive',
      ar: 'FlagReminderActive',
    },
    translatedComment: {
      fa: 'يادآوري',
      en: 'FlagReminderActive',
      ar: 'يادآوري',
    },
    relatedName: 'flagreminderactive',
    linkName: null,
    name: 'flagreminderactive',
    required: true,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: [['isflag', '', 'value = true']],
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: ' return  formData.isflag  ==  true',
  },

  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 130,
    allowSort: true,
    hasValidationActions: true,
    comment: 'تاريخ يادآوري',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'dateTime',
      sql: 'dateTime',
      simple: 'datetime',
      id: 61,
      defaultOperator: 'Between',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 63980,
    caption: 'تاريخ يادآوري',
    translatedCaption: {
      fa: 'تاريخ يادآوري',
      en: 'RemindDateTime',
      ar: 'RemindDateTime',
    },
    translatedComment: {
      fa: 'تاريخ يادآوري',
      en: 'تاريخ يادآوري',
      ar: 'تاريخ يادآوري',
    },
    relatedName: 'reminddatetime',
    linkName: null,
    name: 'reminddatetime',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: [
      ['flagreminderactive', '', 'value = true'],
      'and',
      ['isflag', '', 'value = true'],
    ],
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible:
      ' return  formData.flagreminderactive  ==  true &&  formData.isflag  ==  true',
  },
];

export const staticArchiveFormFields: Record<string, unknown>[] = [
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: false,
    comment: 'گيرنده اصلي',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'stringMultiSelectDropBase',
      sql: 'nvarchar(max)',
      simple: 'string',
      id: 194,
      defaultOperator: 'Contains',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 63957,
    caption: 'گيرنده اصلي',
    translatedCaption: {
      fa: 'گيرنده اصلي',
      en: 'ToPersonInfo_ID',
      ar: 'ToPersonInfo_ID',
    },
    translatedComment: {
      fa: 'گيرنده اصلي',
      en: 'گيرنده اصلي',
      ar: 'گيرنده اصلي',
    },
    relatedName: '__topersoninfo_id_value',
    linkName: '__topersoninfo_id_link',
    name: 'topersoninfo_id',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: {
      id: 6497,
      uniqueId: '746ac586-51e9-4bba-813d-9f5c2501743a',
      comment: '',
      displayMember: 'fullname',
      displayMember2: null,
      valueMember: 'personinfo_id',
      moduleName: 'person',
      tableName: 'personinfo',
      type: 'SearchDialog',
      title: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
      translatedTitle: {
        fa: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
        en: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
        ar: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
      },
      translatedComment: null,
      treeLevel: 0,
      forceTreeLevel: false,
      remoteSearch: true,
      parameters: [
        {
          from: 'departmentinfo_id',
          to: 'departmentinfo_id',
          defaultValue: null,
          moduleName: 'automation',
          moduleTableName: 'messages',
        },
      ],
      maps: [],
      columns: [
        {
          isHidden: false,
          width: 100,
          priority: 0,
          title: 'personinfo_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'PersonInfo_ID',
            en: 'PersonInfo_ID',
            ar: 'PersonInfo_ID',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 1,
          title: 'fullname',
          format: '',
          dataType: {
            erp: 'computedPersist',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 16,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'نام',
            en: 'Full Name',
            ar: 'الاسم الكامل',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 5,
          title: 'mobile',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'همراه',
            en: 'همراه',
            ar: 'همراه',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 6,
          title: 'usergroupid',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'چارت سازماني',
            en: 'چارت سازماني',
            ar: 'چارت سازماني',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 7,
          title: 'mobilephone',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'MobilePhone',
            en: 'mobilephone',
            ar: 'mobilephone',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 8,
          title: 'organizationunit_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'OrganizationUnit_ID',
            en: 'OrganizationUnit_ID',
            ar: 'OrganizationUnit_ID',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 8,
          title: 'persondepartmentinfo_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'شعبه',
            en: 'شعبه',
            ar: 'شعبه',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 9,
          title: 'tel',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'تلفن تماس',
            en: 'Tel',
            ar: 'Tel',
          },
        },
      ],
      table: null,
    },
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: false,
    comment: 'رونوشت',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'stringMultiSelectDropBase',
      sql: 'nvarchar(max)',
      simple: 'string',
      id: 194,
      defaultOperator: 'Contains',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 63958,
    caption: 'رونوشت',
    translatedCaption: {
      fa: 'رونوشت',
      en: 'CCPersonInfo_ID',
      ar: 'CCPersonInfo_ID',
    },
    translatedComment: {
      fa: 'رونوشت',
      en: 'CCPersonInfo',
      ar: 'رونوشت',
    },
    relatedName: '__ccpersoninfo_id_value',
    linkName: '__ccpersoninfo_id_link',
    name: 'ccpersoninfo_id',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: {
      id: 6497,
      uniqueId: '746ac586-51e9-4bba-813d-9f5c2501743a',
      comment: '',
      displayMember: 'fullname',
      displayMember2: null,
      valueMember: 'personinfo_id',
      moduleName: 'person',
      tableName: 'personinfo',
      type: 'SearchDialog',
      title: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
      translatedTitle: {
        fa: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
        en: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
        ar: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
      },
      translatedComment: null,
      treeLevel: 0,
      forceTreeLevel: false,
      remoteSearch: true,
      parameters: [
        {
          from: 'departmentinfo_id',
          to: 'departmentinfo_id',
          defaultValue: null,
          moduleName: 'automation',
          moduleTableName: 'messages',
        },
      ],
      maps: [],
      columns: [
        {
          isHidden: false,
          width: 100,
          priority: 0,
          title: 'personinfo_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'PersonInfo_ID',
            en: 'PersonInfo_ID',
            ar: 'PersonInfo_ID',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 1,
          title: 'fullname',
          format: '',
          dataType: {
            erp: 'computedPersist',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 16,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'نام',
            en: 'Full Name',
            ar: 'الاسم الكامل',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 5,
          title: 'mobile',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'همراه',
            en: 'همراه',
            ar: 'همراه',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 6,
          title: 'usergroupid',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'چارت سازماني',
            en: 'چارت سازماني',
            ar: 'چارت سازماني',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 7,
          title: 'mobilephone',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'MobilePhone',
            en: 'mobilephone',
            ar: 'mobilephone',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 8,
          title: 'organizationunit_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'OrganizationUnit_ID',
            en: 'OrganizationUnit_ID',
            ar: 'OrganizationUnit_ID',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 8,
          title: 'persondepartmentinfo_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'شعبه',
            en: 'شعبه',
            ar: 'شعبه',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 9,
          title: 'tel',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'تلفن تماس',
            en: 'Tel',
            ar: 'Tel',
          },
        },
      ],
      table: null,
    },
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: false,
    comment: 'رونوشت مخفي',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'stringMultiSelectDropBase',
      sql: 'nvarchar(max)',
      simple: 'string',
      id: 194,
      defaultOperator: 'Contains',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 63959,
    caption: 'رونوشت مخفي',
    translatedCaption: {
      fa: 'رونوشت مخفي',
      en: 'BCCPersonInfo_ID',
      ar: 'BCCPersonInfo_ID',
    },
    translatedComment: {
      fa: 'رونوشت مخفي',
      en: 'BCCPersonInfo',
      ar: 'رونوشت مخفي',
    },
    relatedName: '__bccpersoninfo_id_value',
    linkName: '__bccpersoninfo_id_link',
    name: 'bccpersoninfo_id',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: {
      id: 6497,
      uniqueId: '746ac586-51e9-4bba-813d-9f5c2501743a',
      comment: '',
      displayMember: 'fullname',
      displayMember2: null,
      valueMember: 'personinfo_id',
      moduleName: 'person',
      tableName: 'personinfo',
      type: 'SearchDialog',
      title: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
      translatedTitle: {
        fa: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
        en: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
        ar: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
      },
      translatedComment: null,
      treeLevel: 0,
      forceTreeLevel: false,
      remoteSearch: true,
      parameters: [
        {
          from: 'departmentinfo_id',
          to: 'departmentinfo_id',
          defaultValue: null,
          moduleName: 'automation',
          moduleTableName: 'messages',
        },
      ],
      maps: [],
      columns: [
        {
          isHidden: false,
          width: 100,
          priority: 0,
          title: 'personinfo_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'PersonInfo_ID',
            en: 'PersonInfo_ID',
            ar: 'PersonInfo_ID',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 1,
          title: 'fullname',
          format: '',
          dataType: {
            erp: 'computedPersist',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 16,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'نام',
            en: 'Full Name',
            ar: 'الاسم الكامل',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 5,
          title: 'mobile',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'همراه',
            en: 'همراه',
            ar: 'همراه',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 6,
          title: 'usergroupid',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'چارت سازماني',
            en: 'چارت سازماني',
            ar: 'چارت سازماني',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 7,
          title: 'mobilephone',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'MobilePhone',
            en: 'mobilephone',
            ar: 'mobilephone',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 8,
          title: 'organizationunit_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'OrganizationUnit_ID',
            en: 'OrganizationUnit_ID',
            ar: 'OrganizationUnit_ID',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 8,
          title: 'persondepartmentinfo_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'شعبه',
            en: 'شعبه',
            ar: 'شعبه',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 9,
          title: 'tel',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'تلفن تماس',
            en: 'Tel',
            ar: 'Tel',
          },
        },
      ],
      table: null,
    },
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: [['bccinputisactive', '', 'value = true']],
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: ' return  formData.bccinputisactive  ==  true',
  },
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: null,
    allowSort: false,
    hasValidationActions: false,
    comment: null,
    tableName: null,
    numberOfLines: 0,
    dataType: {
      erp: 'dropdown',
      sql: 'bigint',
      simple: 'number',
      id: 1,
      defaultOperator: 'Equals',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: null,
    id: 0,
    caption: 'نوع ارجاع',
    translatedCaption: {
      fa: 'نوع ارجاع',
      en: 'Reference Type',
      ar: 'نوع ارجاع',
    },
    translatedComment: null,
    relatedName: '__referencetype_value',
    linkName: '__referencetype_link',
    name: 'referencetype',
    required: false,
    isHashed: null,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: {
      id: 5435,
      uniqueId: '0866d553-7b34-4352-9d48-295140b04ff2',
      comment: null,
      displayMember: 'referencetypetitle',
      displayMember2: null,
      valueMember: 'referencetype_id',
      moduleName: 'automation',
      tableName: 'referencetype',
      type: 'Simple',
      title: 'اتوماسيون اداري-انواع ارجاع',
      translatedTitle: {
        fa: 'اتوماسيون اداري-انواع ارجاع',
        en: 'اتوماسيون اداري-انواع ارجاع',
        ar: 'اتوماسيون اداري-انواع ارجاع',
      },
      translatedComment: null,
      treeLevel: 0,
      forceTreeLevel: false,
      remoteSearch: false,
      parameters: [],
      maps: null,
      columns: [
        {
          isHidden: true,
          width: 0,
          priority: 0,
          title: 'referencetype_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'referencetype_id',
            en: 'referencetype_id',
            ar: 'referencetype_id',
          },
        },
        {
          isHidden: false,
          width: 500,
          priority: 1,
          title: 'referencetypetitle',
          format: '',
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'referencetypetitle',
            en: 'referencetypetitle',
            ar: 'referencetypetitle',
          },
        },
      ],
      table: null,
    },
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 0,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: false,
    relatedParameterName: 'referencetype',
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: null,
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: null,
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },

  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: null,
    allowSort: false,
    hasValidationActions: false,
    comment: null,
    tableName: null,
    numberOfLines: 0,
    dataType: {
      erp: 'dropdown',
      sql: 'bigint',
      simple: 'number',
      id: 1,
      defaultOperator: 'Equals',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: null,
    id: 0,
    caption: 'پاسخ آماده',
    translatedCaption: {
      fa: 'پاسخ آماده',
      en: 'Paraph Template',
      ar: 'پاسخ آماده',
    },
    translatedComment: null,
    relatedName: '__paraphtemplate_id_value',
    linkName: '__paraphtemplate_id_link',
    name: 'paraphtemplate_id',
    required: false,
    isHashed: null,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: {
      id: 5440,
      uniqueId: '06950d43-fde0-499c-974e-70b8b4ad001c',
      comment: '',
      displayMember: 'paraphtemplatetitle',
      displayMember2: null,
      valueMember: 'paraphtemplate_id',
      moduleName: 'automation',
      tableName: 'paraphtemplate',
      type: 'Simple',
      title: 'اتوماسيون اداري-شرح هاي آماده',
      translatedTitle: {
        fa: 'اتوماسيون اداري-شرح هاي آماده',
        en: 'اتوماسيون اداري-شرح هاي آماده',
        ar: 'اتوماسيون اداري-شرح هاي آماده',
      },
      translatedComment: null,
      treeLevel: 0,
      forceTreeLevel: false,
      remoteSearch: false,
      parameters: [],
      maps: null,
      columns: [
        {
          isHidden: false,
          width: 100,
          priority: 0,
          title: 'paraphtemplate_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'ParaphTemplate_ID',
            en: 'paraphtemplate_id',
            ar: 'paraphtemplate_id',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 1,
          title: 'paraphtemplatetitle',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'ParaphTemplateTitle',
            en: 'paraphtemplatetitle',
            ar: 'paraphtemplatetitle',
          },
        },
      ],
      table: null,
    },
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 0,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: false,
    relatedParameterName: 'paraphtemplate_id',
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: null,
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: null,
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },

  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 196,
    allowSort: true,
    hasValidationActions: false,
    comment: 'موضوع',
    tableName: 'LetterParaph',
    numberOfLines: 1,
    dataType: {
      erp: 'stringMax',
      sql: 'nvarchar(max)',
      simple: 'string',
      id: 14,
      defaultOperator: 'Contains',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 62545,
    caption: 'موضوع',
    translatedCaption: {
      fa: 'موضوع',
      en: 'LetterParaph',
      ar: 'LetterParaph',
    },
    translatedComment: {
      fa: 'موضوع',
      en: 'LetterParaph',
      ar: 'موضوع',
    },
    relatedName: 'LetterParaph',
    linkName: null,
    name: 'LetterParaph',
    required: true,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
    colSpan: 3,
    rowSpan: 2,
    tabTitle: 'ارجاعات پيام',
    tabId: 0,
    translatedTabTitle: {
      fa: 'ارجاعات پيام',
      en: 'Messages References',
      ar: 'ارجاعات پيام',
    },
  },
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: false,
    comment: 'ضمائم',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'multiFileStream',
      sql: 'nvarchar(max)',
      simple: 'string',
      id: 204,
      defaultOperator: 'Equals',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 63975,
    caption: 'ضمائم',
    translatedCaption: {
      fa: 'ضمائم',
      en: 'AttachFiles',
      ar: 'AttachFiles',
    },
    translatedComment: {
      fa: 'ضمائم',
      en: 'AttachFiles',
      ar: 'AttachFiles',
    },
    relatedName: 'attachfiles',
    linkName: null,
    name: 'attachfiles',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: 'G18',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },

  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: false,
    comment: 'آرشیو شود',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'checkBox',
      sql: 'bit',
      simple: 'boolean',
      id: 104,
      defaultOperator: 'Equals',
    },
    disabled: false,
    defaultValue: false,
    parentField: null,
    moduleName: 'automation',
    id: 63826,
    caption: 'آرشیو شود',
    translatedCaption: {
      fa: 'آرشیو شود',
      en: 'IsArchieved',
      ar: 'IsArchieved',
    },
    translatedComment: {
      fa: 'آرشیو شود',
      en: 'IsArchieved',
      ar: 'IsArchieved',
    },
    relatedName: 'IsArchieved',
    linkName: null,
    name: 'IsArchieved',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
    colSpan: null,
    rowSpan: null,
    tabTitle: 'پيام',
    tabId: 0,
    translatedTabTitle: {
      fa: 'پيام',
      en: 'messages',
      ar: 'پيام',
    },
  },
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: false,
    comment: 'متن',
    tableName: 'ParaphText',
    numberOfLines: 1,
    dataType: {
      erp: 'html',
      sql: 'nvarchar(max)',
      simple: 'html',
      id: 193,
      defaultOperator: 'Contains',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 62532,
    caption: 'متن',
    translatedCaption: {
      fa: 'متن',
      en: 'paraphtext',
      ar: 'paraphtext',
    },
    translatedComment: {
      fa: 'متن',
      en: 'paraphtext',
      ar: 'متن',
    },
    relatedName: 'paraphtext',
    linkName: null,
    name: 'paraphtext',
    required: true,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },
];

export const staticNewLabelFormFields: Record<string, unknown>[] = [
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 200,
    allowSort: true,
    hasValidationActions: false,
    comment: 'عنوان برچسب',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'string',
      sql: 'nvarchar(max)',
      simple: 'string',
      id: 167,
      defaultOperator: 'Contains',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 62528,
    caption: 'عنوان برچسب',
    translatedCaption: {
      fa: 'عنوان برچسب',
      en: 'LablesTitle',
      ar: 'LablesTitle',
    },
    translatedComment: {
      fa: 'عنوان برچسب',
      en: 'عنوان برچسب',
      ar: 'عنوان برچسب',
    },
    relatedName: 'LablesTitle',
    linkName: null,
    name: 'LablesTitle',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: 100,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 200,
    allowSort: true,
    hasValidationActions: false,
    comment: 'کد رنگ',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'colorPicker',
      sql: 'nvarchar(max)',
      simple: 'string',
      id: 167,
      defaultOperator: 'Contains',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 62529,
    caption: 'کد رنگ',
    translatedCaption: {
      fa: 'کد رنگ',
      en: 'ColorCode',
      ar: 'ColorCode',
    },
    translatedComment: {
      fa: 'کد رنگ',
      en: 'ColorCode',
      ar: 'ColorCode',
    },
    relatedName: 'ColorCode',
    linkName: null,
    name: 'ColorCode',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: 100,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },
  {
    caption: 'برچسب والد',
    dataType: {
      erp: 'dropdown',
      sql: 'bigint',
      simple: 'number',
      id: 1,
      defaultOperator: 'Equals',
    },
    disabled: false,
    javaScriptFormula: '',
    dropdown: {
      columns: null,
      displayMember: 'lablestitle',
      displayMember2: null,
      forceTreeLevel: false,
      id: '5220',
      moduleName: 'task',
      parameters: [],
      remoteSearch: false,
      translatedComment: null,
      translatedTitle: {
        fa: 'برچسب والد',
        en: 'lablestitle',
        ar: 'lablestitle',
      },
      type: 'Simple',
      uniqueId: '3941a6fc-806e-4b8b-ad3f-ede876a753f2',
      valueMember: 'lables_id',
      name: 'lables_id',
      title: 'lablestitle',
    },
    hidden: true,
    id: 0,
    name: 'ParentLables_ID',
    translatedCaption: {
      fa: 'برچسب والد',
      en: 'ParentLables_ID',
      ar: 'ParentLables_ID',
    },
    relatedName: '__LablesTitle_value',
    required: false,
    values: null,
    resource: '',
  },
];

export const staticNewFolderFields: Record<string, unknown>[] = [
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 200,
    allowSort: true,
    hasValidationActions: false,
    comment: 'عنوان پوشه',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'string',
      sql: 'nvarchar(max)',
      simple: 'string',
      id: 167,
      defaultOperator: 'Contains',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 62528,
    caption: 'عنوان پوشه',
    translatedCaption: {
      fa: 'عنوان پوشه',
      en: 'FoldersTitle',
      ar: 'FoldersTitle',
    },
    translatedComment: {
      fa: 'عنوان پوشه',
      en: 'عنوان پوشه',
      ar: 'عنوان پوشه',
    },
    relatedName: 'FoldersTitle',
    linkName: null,
    name: 'FoldersTitle',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: 100,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },

  {
    caption: 'پوشه والد',
    dataType: {
      erp: 'dropdown',
      sql: 'bigint',
      simple: 'number',
      id: 1,
      defaultOperator: 'Equals',
    },
    disabled: false,
    javaScriptFormula: '',
    dropdown: {
      columns: [
        {
          isHidden: false,
          width: 100,
          priority: 0,
          title: 'folders_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'Folders_ID',
            en: 'Folders_ID',
            ar: 'Folders_ID',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 1,
          title: 'folderstitle',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'FoldersTitle',
            en: 'FoldersTitle',
            ar: 'العنوان',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 5,
          title: 'issystemfolder',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'IsSystemFolder',
            en: 'issystemfolder',
            ar: 'issystemfolder',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 5,
          title: 'levelid',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'LevelID',
            en: 'levelid',
            ar: 'levelid',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 6,
          title: 'isdeleted',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'حذف شده',
            en: 'IsDeleted',
            ar: 'IsDeleted',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 8,
          title: 'parentfolders_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'ParentFolders_ID',
            en: 'ParentFolders_ID',
            ar: 'ParentFolders_ID',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 8,
          title: 'parentfolders_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'ParentFolders_ID',
            en: 'ParentFolders_ID',
            ar: 'ParentFolders_ID',
          },
        },
      ],
      displayMember: 'folderstitle',
      displayMember2: null,
      forceTreeLevel: false,
      id: '5456',
      moduleName: 'task',
      parameters: [],
      remoteSearch: false,
      translatedComment: null,
      translatedTitle: {
        fa: 'پوشه والد',
        en: 'ParentFolders_ID',
        ar: 'ParentFolders_ID',
      },
      type: 'Simple',
      uniqueId: 'f771c530-2d96-4702-bebe-6652570a0f4f',
      valueMember: 'folders_id',
      name: 'folders_id',
      title: 'folderstitle',
    },
    hidden: false,
    id: 0,
    name: 'ParentFolders_ID',
    translatedCaption: {
      fa: 'پوشه والد',
      en: 'ParentFolders_ID',
      ar: 'ParentFolders_ID',
    },
    relatedName: '__ParentFolders_ID_value',
    required: false,
    values: null,
    resource: '',
  },
];

/**
 * Finding input values from `formData`, if corresponding input name found in `formErrors`, replace it
 * @function updateInputsState
 * @param {object} inputsRef
 * @param {object} formData
 * @returns {void} void
 */
export const updateInputsState = (
  inputsRef: Record<string, InputRefContent> | null | undefined,
  formData: FormData | InitialData,
): void => {
  if (isEmptyObject(inputsRef)) {
    return;
  }

  for (const inputName in inputsRef) {
    inputsRef[inputName]?.setInputMessage?.(undefined);

    if (typeof formData![inputName] === 'boolean') {
      inputsRef[inputName].setInputValue?.(
        Boolean(Number(formData?.[inputName] ?? 0)),
      );
    } else {
      inputsRef[inputName].setInputValue?.(formData?.[inputName] ?? '');
    }
  }
};
