import { ReactElement, memo } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { GlobalHotKeys } from 'react-hotkeys';

import {
  getValue,
  HEADER_HEIGHT_XS,
  HEADER_HEIGHT,
  localStorageGetItem,
} from '../../core/configProvider';
import QuickAccessDialogContainer from '../quickAccess/QuickAccessDialogContainer';
import ShowImageDialogContainer from '../../container/ShowImageDialogContainer';
import TodoShareListDialogContainer from '../todo/TodoShareListDialogContainer';
import MetaResourceRegisterer from '../../container/MetaResourceRegisterer';
import BottomNavigationMenu from '../menu/BottomNavigationMenu';
import { SearchPopupDialog } from '../search-popup-dialog';
import { AppLayoutViewInterface } from './app-layout.type';
import { FilesPopupDialog } from '../files-popup-dialog';
import { CustomChildMenu } from './custom-child-menu';
import { CustomAppBar } from './custom-app-bar';
import { useStyles } from './app-layout.style';
import DialogsStack from '../dialogs-stack';
import Notification from '../Notification';
import useWidth from '../useWidth';
import { CustomNav } from './custom-nav';
import LoadingBox from '../LoadingBox';
import { isEmbeddedPage } from '../../helper/UrlHelper';
import { apiUrl } from '../../core/dataProvider';

const AppLayoutView = (props: AppLayoutViewInterface): ReactElement => {
  const {
    children,
    logout,
    isBottomMenuEnabled,
    keyMap,
    handlers,
    hasDashboard,
    container,
    theme,
  } = props;

  // compute heder height
  const isEmbedPage = isEmbeddedPage();
  const headerHeightXs = isEmbedPage ? 0 : getValue(HEADER_HEIGHT_XS);
  const headerHeight = isEmbedPage ? 0 : getValue(HEADER_HEIGHT);

  const classes = useStyles({ headerHeight, headerHeightXs });
  const width = useWidth();

  if (!width) return <LoadingBox />;
  const infoUser = localStorageGetItem('USER_COMPANY_ID');

  return (
    <MuiThemeProvider theme={theme}>
      <Helmet>
        <link
          rel="stylesheet"
          href={`${apiUrl}/filesuploaded/company_${infoUser}/customCss.css?keyCash=${Math.random()}`}
          type="text/css"
        />
        <meta name="theme-color" content={theme.palette.primary.main} />
      </Helmet>
      {/* it completely separated to two parts , because some another props will change soon if it be a embed page */}
      {isEmbedPage ? (
        <div className={classes.root}>
          <MetaResourceRegisterer />
          <CssBaseline />

          <div className={classes.container}>
            <main className={classes.content}>
              <div className={classes.toolbar} id="main-toolbar-samian" />
              {children}
            </main>
            {isBottomMenuEnabled && width === 'xs' && <BottomNavigationMenu />}
          </div>
          <Notification />
          <ShowImageDialogContainer />
          <TodoShareListDialogContainer />
          <DialogsStack />
          <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
          <QuickAccessDialogContainer />
          <SearchPopupDialog />
          <FilesPopupDialog />
        </div>
      ) : (
        <div className={classes.root}>
          <MetaResourceRegisterer />
          <CssBaseline />

          <CustomAppBar logout={logout} />

          <div className={classes.container}>
            <CustomNav
              container={container}
              logout={logout}
              hasDashboard={hasDashboard}
            />
            <main className={classes.content}>
              <div className={classes.toolbar} id="main-toolbar-samian" />
              {children}
              <CustomChildMenu width={width} />
            </main>
            {isBottomMenuEnabled && width === 'xs' && <BottomNavigationMenu />}
          </div>
          <Notification />
          <ShowImageDialogContainer />
          <TodoShareListDialogContainer />
          <DialogsStack />
          <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
          <QuickAccessDialogContainer />
          <SearchPopupDialog />
          <FilesPopupDialog />
        </div>
      )}
    </MuiThemeProvider>
  );
};

// export default AppLayoutView;
export default memo(AppLayoutView, () => true);
