import { ReactElement } from 'react';
import { Box, IconButton } from '@material-ui/core';
import DocIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

import { FileCardViewInterface } from './file-card.type';
import { useStyles } from './file-card.style';
import { isImageFile } from '../../../dynamic-input/file-input/file-input.helper';
import { API_URL, getValue } from '../../../../core/configProvider';

const FileCardView = (props: FileCardViewInterface): ReactElement => {
  const { file, onDeleteFileHandler } = props;
  const classes = useStyles();
  const apiUrl = getValue(API_URL);

  return isImageFile(file.filePath) ? (
    <Box
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      <Box
        component="span"
        className="pointer"
        display="flex"
        alignItems="center"
        flexWrap="nowrap"
      >
        <Box mr={2}>
          <img
            alt=""
            src={`${apiUrl}/${file?.filePath}`}
            className={classes.imageBox}
          />
        </Box>

        <Box component="span" display="inline-block" m={0} mr={2} fontSize={13}>
          <Box>{file.realFileName}</Box>
        </Box>
      </Box>
      <IconButton onClick={onDeleteFileHandler}>
        <DeleteIcon />
      </IconButton>
    </Box>
  ) : (
    <Box
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      <Box
        component="span"
        className="pointer"
        display="flex"
        alignItems="center"
        flexWrap="nowrap"
      >
        <a
          className={classes.docIcon}
          style={{
            marginLeft: '1rem',
          }}
        >
          <DocIcon style={{ color: '#fff' }} />
        </a>
        <Box component="span" display="inline-block" m={0} mr={2} fontSize={13}>
          <Box>{file.realFileName}</Box>
        </Box>
      </Box>
      <IconButton onClick={onDeleteFileHandler}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default FileCardView;
