import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../core/themeProvider';
import { FormStyleProps } from './form.type';

export const useStyles = makeStyles<CustomTheme, FormStyleProps>(() => ({
  formViewContainer: {
    position: 'relative',
    width: '100%',
    height: props => (props.formName === 'wms' ? 'auto' : '100%'),
    marginBottom: props => (props.formName === 'wms' ? 10 : 'unset'),
  },
}));
