import { isEmptyObject } from './data-helper';
import { findFieldByName } from './meta-helper';
import { fieldFileList } from './MetaHelper';
import lodashGet from 'lodash/get';
import {
  getTypeByField,
  DECIMAL_FIELD,
  NUMBER_FIELD,
  BOOLEAN_FIELD,
  runAsyncValidation,
} from './InputHelper';
import { getAsyncValidationInfoForField } from './MetaHelper';
import {
  RunServiceValidationClient,
  HandleNotificationValidationErrors,
  MetaDataBase,
  ValidationErrorMessageType,
} from './Types';
import {
  ValidationError,
  RawServerSideValidationErrors,
  RawClientSideValidationError,
  FieldType,
  APIError,
  AsyncValidations,
  Translate,
  ValidateClientSideFields,
  HandleServerSideValidationErrors,
  HandleClientSideValidationErrors,
  ValidateClientSideData,
  CheckFieldValidation,
} from './Types';

// ------------------------------------------ client side validation ------------------------------------------
/**
 *  this function will receive two objects of form data. compare them to find new key that has been changed.
 *  call the validate function with form data. make a correct message for notification.
 *  show notification if need and returns the result of validation.
 *  @async
 *  @function validateFields
 *  @param {Record<string, ValidationError>} prevValidationErrors
 *  @param {Record<string, unknown>} formData
 *  @param {Array<FieldType>} validationFields
 *  @param {ShowNotification} showNotification
 *  @param {Translate} translate
 *  @param {string} locale
 *  @param {boolean} notifyInSnackBar
 *  @param {boolean} disableValidationErrorNotification
 *  @returns {Record<string, ValidationError>} object
 */
export const validateClientSideFields: ValidateClientSideFields = (
  prevValidationErrors,
  formData,
  validationFields = [],
  showNotification,
  translate,
  locale,
  notifyInSnackBar = false,
  disableValidationErrorNotification = false,
) => {
  if (!validationFields || validationFields.length === 0) {
    return {};
  }

  const isSingleFieldValidation = validationFields.length === 1;

  const result = checkFieldValidation(
    formData,
    validationFields,
    isSingleFieldValidation,
    locale,
    prevValidationErrors,
    translate,
  );

  return handleNotificationValidationErrors(
    result,
    translate,
    isSingleFieldValidation,
    disableValidationErrorNotification,
    showNotification,
    notifyInSnackBar,
  );
};

/**
 * this function handle notification after validation handled
 * @function handleNotificationValidationErrors
 * @param {Record<string, ValidationError>} result
 * @param {Translate} translate
 * @param {boolean} isSingleFieldValidation
 * @param {boolean} disableValidationErrorNotification
 * @param {ShowNotification} showNotification
 * @param {boolean} notifyInSnackBar
 * @return {Record<string, ValidationError>}
 */
const handleNotificationValidationErrors: HandleNotificationValidationErrors = (
  result,
  translate,
  isSingleFieldValidation,
  disableValidationErrorNotification,
  showNotification,
  notifyInSnackBar,
) => {
  const allTabTitles: Array<string> = Array.from(
    Object.values(result).map(
      err => err['tabTitle'] ?? translate('customValidation.unknownTab'),
    ),
  );
  const distinctTabTitles: Array<string> = [];

  // for prevent repeat a tab title in notification (toast).
  allTabTitles.forEach(tabTitle => {
    if (!distinctTabTitles.includes(tabTitle)) {
      distinctTabTitles.push(tabTitle);
    }
  });

  const tabNamesWithError = distinctTabTitles.join(
    ' ' + translate('customValidation.separator') + ' ',
  );

  // notification action dispatches only when this function called from send button.
  if (
    !isSingleFieldValidation &&
    !disableValidationErrorNotification &&
    !isEmptyObject(result)
  ) {
    showNotification(
      translate('customValidation.validationErrorOnTab', {
        tabName: tabNamesWithError,
      }),
      'error',
      {
        forceSnackbar: true,
        fromQuickCreateDialog: notifyInSnackBar, // to render notification in <portal/> with id: customSnackContainer
      },
    );
    console.log('validation errors: ', result); // ** dont remove this line, its for debug in production mode **
  }

  return result;
};

/**
 * this function should do the validation on every field and push them into an array if
 * an error happen.
 * @function validateClientSideData
 * @param {Record<string, unknown>} values - form data
 * @param {string} locale current language
 * @param {Array<FieldType>} fieldList - all fields
 * @returns {Array<RawClientSideValidationError>}  dirtyFields and asyncValidations
 * */
const validateClientSideData: ValidateClientSideData = (
  values,
  locale,
  fieldList,
) => {
  const dirtyFields: Record<string, RawClientSideValidationError> = {};

  for (const field of fieldList) {
    const {
      name,
      maxLength,
      maxValue,
      minValue,
      required,
      tabTitle,
      tabId,
      translatedTabTitle,
    } = field;

    const fieldType = getTypeByField(field);
    const value =
      values[name] || values[name] === false || values[name] === 0
        ? values[name]
        : null;
    const preparedTabTitle = lodashGet(translatedTabTitle, locale, tabTitle);
    const computedMaxValue =
      !maxValue || maxValue > Number.MAX_SAFE_INTEGER
        ? Number.MAX_SAFE_INTEGER
        : maxValue;

    const staticProps = {
      value,
      name,
      tabId,
      tabTitle: preparedTabTitle,
    };

    // check require
    if (
      required &&
      fieldType === BOOLEAN_FIELD &&
      value !== false &&
      value !== true &&
      value !== 'false' &&
      value !== 'true'
    ) {
      dirtyFields[name] = {
        ...staticProps,
        type: 'required',
        validValue: null,
        messageType: 'error',
      };
    } else if (
      fieldType !== BOOLEAN_FIELD &&
      required &&
      value !== 0 &&
      (value == null || value === '')
    ) {
      dirtyFields[name] = {
        ...staticProps,
        type: 'required',
        validValue: null,
        messageType: 'error',
      };
    }

    // check maxLength
    else if (maxLength && (value as string)?.length > maxLength) {
      dirtyFields[name] = {
        ...staticProps,
        type: 'maxLength',
        validValue: maxLength,
        messageType: 'error',
      };
    }

    // check minValue
    else if (
      (fieldType === NUMBER_FIELD || fieldType === DECIMAL_FIELD) &&
      minValue &&
      value &&
      minValue > (value as number)
    ) {
      dirtyFields[name] = {
        ...staticProps,
        type: 'minValue',
        validValue: minValue,
        messageType: 'error',
      };
    }

    // check maxValue
    else if (
      (fieldType === NUMBER_FIELD || fieldType === DECIMAL_FIELD) &&
      computedMaxValue &&
      value &&
      (value as number) > computedMaxValue
    ) {
      dirtyFields[name] = {
        ...staticProps,
        type: 'maxValue',
        validValue: computedMaxValue,
        messageType: 'error',
      };
    }

    // check NaN
    else if (
      (fieldType === NUMBER_FIELD || fieldType === DECIMAL_FIELD) &&
      value &&
      isNaN(value as number)
    ) {
      dirtyFields[name] = {
        ...staticProps,
        type: 'NaN',
        validValue: null,
        messageType: 'error',
      };
    }
  }

  return dirtyFields;
};

/**
 * this function should do the validation by run service validation client
 * @function runServiceValidationClient
 * @param {Array<FieldType>} allFields
 * @param {string} fieldName
 * @param {Record<string, unknown>} formData
 * @param {string} locale current language
 * @param {Record<string, unknown>}  metaData meta data
 * @param {string}  resource resource
 * @param {string}  relationResource relation resource
 * @param {ChangeFormValue}  changeFormValue for async validations
 * @param {Record<string, ValidationError>} prevValidationErrors
 * @param {Translate} translate
 * @param {boolean} disableValidationErrorNotification
 * @param {ShowNotification} showNotification
 * @param {boolean} notifyInSnackBar
 * @returns {Promise<Record<string, ValidationError>>}  asyncValidations
 * */
export const runServiceValidationClient: RunServiceValidationClient = async (
  allFields,
  fieldName,
  formData,
  locale,
  metaData,
  resource,
  relationResource,
  changeFormValue,
  prevValidationErrors,
  translate,
  disableValidationErrorNotification,
  showNotification,
  notifyInSnackBar,
  successRunValidationCallback = undefined,
) => {
  const asyncValidations: Record<string, AsyncValidations> = {};
  const clientSideRawErrors: Record<string, RawClientSideValidationError> = {};
  const fieldToValidate = findFieldByName(metaData, allFields, fieldName);
  const validationFields: Array<FieldType> = fieldToValidate
    ? [fieldToValidate]
    : [];

  if (validationFields.length === 0) {
    return Promise.resolve({});
  }

  const isSingleFieldValidation = validationFields.length === 1;
  let specificFieldName = undefined;
  if (isSingleFieldValidation) {
    specificFieldName = fieldFileList(allFields)?.[0]?.name;
  }

  for (const field of validationFields) {
    const { name, id, tabTitle, tabId, translatedTabTitle } = field;

    const preparedTabTitle = lodashGet(translatedTabTitle, locale, tabTitle);
    const asyncValidationInfo = getAsyncValidationInfoForField(metaData, id);

    // check async validation
    if (asyncValidationInfo) {
      const preparedValidationFunction = runAsyncValidation(
        fieldName,
        relationResource ? relationResource : resource,
        asyncValidationInfo,
        changeFormValue,
        name,
        successRunValidationCallback,
      );

      asyncValidations[name] = {
        promise: preparedValidationFunction,
        name,
        tabTitle: preparedTabTitle,
        tabId,
      };
    }
  }

  if (!isEmptyObject(asyncValidations)) {
    const promises: Array<
      Promise<{ message: string; messageType: ValidationErrorMessageType } | void>
    > = [];

    const fieldNames: string[] = [];
    for (const key in asyncValidations) {
      promises.push(asyncValidations[key].promise(null, formData));
      fieldNames.push(asyncValidations[key].name);
    }

    const values = await Promise.all(promises).catch(error => {
      console.log('checkRunServiceValidation error: ', error);
      return Promise.reject('checkFieldValidation error');
    });

    if (Array.isArray(values)) {
      values.map((errorMessage, index) => {
        if (typeof errorMessage?.message === 'string') {
          const { name, tabId, tabTitle } = asyncValidations[fieldNames[index]];
          const textByAt = errorMessage.message.includes('^')
            ? errorMessage.message.split('^')[0]
            : errorMessage.message;

          clientSideRawErrors[name] = {
            name,
            value: formData[name],
            type: 'async',
            validValue: textByAt,
            tabTitle,
            tabId,
            messageType: errorMessage.messageType,
          };
        }
      });
    }
  }

  const handleClientSide = handleClientSideValidationErrors(
    clientSideRawErrors,
    prevValidationErrors,
    translate,
    specificFieldName,
  );

  return Promise.resolve(
    handleNotificationValidationErrors(
      handleClientSide,
      translate,
      isSingleFieldValidation,
      disableValidationErrorNotification,
      showNotification,
      notifyInSnackBar,
    ),
  );
};

/**
 * this function should call error separator function and then request to server for each
 * async validation object . then return the result to handler function.
 *  @function checkFieldValidation
 *  @param {Record<string, unknown>} formData
 *  @param {Array<FieldType>} validationFields
 *  @param {boolean} isSpecificField
 *  @param {string} locale
 *  @param {Record<string, ValidationError>} prevValidationErrors
 *  @param {Translate} translate
 *  @returns {Promise<Record<string, ValidationError>>}
 * */
export const checkFieldValidation: CheckFieldValidation = (
  formData,
  validationFields,
  isSpecificField,
  locale,
  prevValidationErrors,
  translate,
) => {
  let specificFieldName: string | undefined = undefined;
  if (isSpecificField) {
    specificFieldName = validationFields?.[0]?.name;
  }

  const clientSideRawErrors = validateClientSideData(
    formData,
    locale,
    validationFields,
  );

  return handleClientSideValidationErrors(
    clientSideRawErrors,
    prevValidationErrors,
    translate,
    specificFieldName,
  );
};

const isServiceValidationErrorWithErrorTypeBaseOfMessage = error => {
  return (
    error?.message?.includes('^') && // it means its a service validation error
    error?.errorMessageType === 'error' // with error type
  );
};

/**
 *  this function receive an array of errors and separate them by their types to make right message showing to user and also
 *  check if error is for an specific field or not and setState validationErrors with new array of errors.
 *  @function handleClientSideValidationErrors
 *  @param {Array<RawClientSideValidationError>} rawErrors
 *  @param {Record<string, ValidationError>} prevClientSideValidationErrors
 *  @param {Translate} translate
 *  @param {String} specificFieldName
 *  @returns {Record<string, ValidationError>}  dirtyFields and asyncValidations
 * */
export const handleClientSideValidationErrors: HandleClientSideValidationErrors = (
  rawErrors,
  prevClientSideValidationErrors = {},
  translate,
  specificFieldName,
) => {
  const preparedValidatingErrors: Record<string, ValidationError> = {};

  if (isEmptyObject(rawErrors)) {
    if (!specificFieldName) {
      if (!isEmptyObject(prevClientSideValidationErrors)) {
        if (
          Object.values(prevClientSideValidationErrors).some(error => {
            error.errorMessageType === 'error';
          })
        ) {
          return prevClientSideValidationErrors;
        } else {
          return {};
        }
      }
      return prevClientSideValidationErrors;
    }

    // should remove the field error from validationErrors state.
    if (
      isServiceValidationErrorWithErrorTypeBaseOfMessage(
        prevClientSideValidationErrors[specificFieldName],
      )
    ) {
      return prevClientSideValidationErrors;
    }

    delete prevClientSideValidationErrors[specificFieldName];
    return prevClientSideValidationErrors;
  }

  Object.values(rawErrors).forEach(error => {
    const { name, value, type, validValue, tabTitle, tabId, messageType } = error;
    const commonProperties = {
      name,
      value,
      tabTitle,
      tabId,
      errorMessageType: messageType,
    };

    // to make right error message.
    switch (type) {
      case 'required':
        preparedValidatingErrors[name] = {
          message: translate('ra.validation.required'),
          ...commonProperties,
        };
        break;

      case 'maxLength':
        preparedValidatingErrors[name] = {
          message: translate('ra.validation.maxLength', { max: validValue }),
          ...commonProperties,
        };
        break;

      case 'minValue':
        preparedValidatingErrors[name] = {
          message: translate('ra.validation.minValue', { min: validValue }),
          ...commonProperties,
        };
        break;

      case 'maxValue':
        preparedValidatingErrors[name] = {
          message: translate('ra.validation.maxValue', { max: validValue }),
          ...commonProperties,
        };
        break;

      case 'NaN':
        preparedValidatingErrors[name] = {
          message: translate('ra.validation.number'),
          ...commonProperties,
        };
        break;

      case 'async':
        preparedValidatingErrors[name] = {
          message: String(validValue),
          ...commonProperties,
        };
        break;

      default:
        console.log('unknown validation error');
        break;
    }
  });

  if (!specificFieldName) {
    // set all new errors to validationErrors state when it wasn't limited to an specific field.
    return preparedValidatingErrors;
  }

  const newError = preparedValidatingErrors?.[0] ?? null; // because in this situation always has only one error in it.

  if (newError) {
    // check if error exist in validationErrors state or not
    if (prevClientSideValidationErrors[specificFieldName]) {
      // should only change error message and update state.
      prevClientSideValidationErrors[specificFieldName].message = newError.message;
      return prevClientSideValidationErrors;
    }

    // add new error to validationErrors state.
    prevClientSideValidationErrors[specificFieldName] = newError;
    return prevClientSideValidationErrors;
  }

  return preparedValidatingErrors;
};

// ------------------------------------------ server side validation ------------------------------------------
/**
 *  this function receives an array of API validation Errors , separate them by their types and names,
 *  make an array of dirty field names to show on notification.
 *  make a correct error message for each field and make compatible objects with validationErrors State then push to it.
 *  @function handleServerSideValidationErrors
 *  @param {Object} meta meta data
 *  @param {Array<FieldType> | null} fieldList array of field list
 *  @param {Object} errorPackage includes response and request id
 *  @param {FormData} formData formData
 *  @param {ShowNotification | null} notificationCallback show notification action
 *  @param {Translate} translate react admin translate function
 *  @param {string} locale current language
 *  @returns {HandleApiErrorsResult} includes response and request id
 */
export const handleServerSideValidationErrors: HandleServerSideValidationErrors = (
  meta,
  fieldList: Array<FieldType> = [],
  errorPackage,
  formData,
  notificationCallback,
  translate,
  locale,
) => {
  const apiErrors = errorPackage.apiErrors;
  const requestId = errorPackage.requestId;

  if (Object.keys(apiErrors).length === 0) {
    return {
      preparedValidationErrors: {},
      preparedErrorMessage: null,
    };
  }

  const rawServerSideValidationErrors = prepareRawServerSideValidationErrors(
    apiErrors,
    meta,
    fieldList,
    translate,
  );
  const dirtyFieldsNames: string[] = [];
  const preparedValidationErrors: Record<string, ValidationError> = {};

  // preparing new error objects to push in Validation errors state:
  for (const rawServerSideValidationError in rawServerSideValidationErrors) {
    const {
      field,
      type,
      correctValue: correctValue,
    } = rawServerSideValidationErrors[rawServerSideValidationError];
    const { name, tabTitle, tabId } = field;

    // push dirtyFieldNames to an array to show in notification
    dirtyFieldsNames.push(
      lodashGet(
        field,
        ['translatedCaption', locale],
        lodashGet(field, 'caption', lodashGet(field, 'name')),
      ),
    );

    const errorMessage = computeServerSideErrorMessage(
      type,
      translate,
      correctValue,
    );

    preparedValidationErrors[name] = {
      name,
      value: formData?.[name],
      tabTitle,
      tabId,
      message: errorMessage,
      errorMessageType: 'error',
    };
  }

  // make a correct message for show in notification
  let preparedMessageForNotification: string;
  if (dirtyFieldsNames.length > 1) {
    // We have multiple errors
    preparedMessageForNotification = `${translate(
      'customValidation.valueOfFields',
    )}${dirtyFieldsNames.join(
      ' ' + translate('customValidation.separator') + ' ',
    )}${translate('customValidation.areNotValid')}`;
  } else {
    // We have one error
    preparedMessageForNotification = `${translate('customValidation.valueOfField')}${
      dirtyFieldsNames[0]
    }${translate('customValidation.notValid')}`;
  }

  // show notification as dialog
  if (typeof notificationCallback === 'function') {
    notificationCallback(preparedMessageForNotification, 'error', { requestId });
    // TODO: check this section Accurate in unit tests
  }

  return {
    preparedValidationErrors,
    preparedErrorMessage: preparedMessageForNotification,
  };
};
/**
 * it computes server side error message for validation
 * @function computeServerSideErrorMessage
 * @param {string} type
 * @param {Translate} translate
 * @param {string} correctValue
 * @returns {string}
 */
const computeServerSideErrorMessage = (
  type: string,
  translate: Translate,
  correctValue?: string,
): string => {
  let message = '';
  switch (type) {
    case 'required':
      message = translate('ra.validation.required');
      break;

    case 'wrongvalue':
      message = translate('customValidation.invalidValue');
      break;

    case 'maxLength':
      message = translate('ra.validation.maxLength', { max: correctValue });
      break;

    case 'maxValue':
      message = translate('ra.validation.maxValue', { max: correctValue });
      break;

    case 'minValue':
      message = translate('ra.validation.minValue', { min: correctValue });
      break;

    case 'stage':
      message = translate('customValidation.invalidValue'); // not show all of correct value
      break;

    case 'regex':
      message = correctValue
        ? correctValue
        : translate('customValidation.invalidValue');
      break;

    default:
      console.log('unknown validation error');
  }
  return message;
};

/**
 *  this function receive api error object and separate the response with their types and
 *  push them with current message in dirty objects array and return it.
 *  @function prepareRawServerSideValidationErrors
 *  @param {Object } apiErrors api response
 *  @param {Object } meta meta data
 *  @param {Array | null} fieldList array of field list
 *  @param {Function} translate react admin translate function
 *  @returns {Array} dirty objects
 */
const prepareRawServerSideValidationErrors = (
  apiErrors: APIError,
  meta: MetaDataBase,
  fieldList: Array<FieldType>,
  translate: Translate,
): Record<string, RawServerSideValidationErrors> => {
  const rawServerSideValidationErrors: Record<
    string,
    RawServerSideValidationErrors
  > = {};
  const apiErrorKeys = Object.keys(apiErrors);

  // get error types
  for (const errorType of apiErrorKeys) {
    const errorTypeKeys = Object.keys(apiErrors[errorType]);

    if (
      errorType === 'maxLength' ||
      errorType === 'maxValue' ||
      errorType === 'minValue' ||
      errorType === 'regex'
    ) {
      // get dirty field keys per each type
      errorTypeKeys.forEach(dirtyFieldName => {
        // found field from field list || meta
        const field = findFieldByName(meta, fieldList, dirtyFieldName);

        if (field) {
          // fields that have single correct value in api response
          rawServerSideValidationErrors[field.name] = {
            field: field,
            type: errorType,
            correctValue: (apiErrors[errorType]?.[dirtyFieldName] as string) ?? '',
          };
        } else {
          //fieldNotFound
          console.log(
            'server validation return an error in a field but field does not exist',
          );
          rawServerSideValidationErrors[dirtyFieldName] = {
            field: { name: dirtyFieldName },
            type: errorType,
          };
        }
      });
    } else if (errorType === 'wrongvalue') {
      // get dirty field keys per each type
      errorTypeKeys.forEach(dirtyFieldName => {
        // found field from field list || meta
        const field = findFieldByName(meta, fieldList, dirtyFieldName);

        if (field) {
          // fields that have an array of correct values in api response
          rawServerSideValidationErrors[field.name] = {
            field: field,
            type: errorType,
            correctValue: apiErrors[errorType]?.[dirtyFieldName].join(
              ' ' + translate('customValidation.separator') + ' ',
            ),
          };
        } else {
          //fieldNotFound
          console.log(
            'server validation return an error in a field but field does not exist',
          );
          rawServerSideValidationErrors[dirtyFieldName] = {
            field: { name: dirtyFieldName },
            type: errorType,
          };
        }
      });
    } else if (errorType === 'required' || errorType === 'stage') {
      // get dirty field keys per each type
      apiErrors[errorType]?.forEach(dirtyFieldName => {
        // found field from field list || meta
        const field = findFieldByName(meta, fieldList, dirtyFieldName);

        if (field) {
          // fields that haven't correct value in api response
          rawServerSideValidationErrors[field.name] = {
            field: field,
            type: errorType,
          };
        } else {
          //fieldNotFound
          console.log(
            'server validation return an error in a field but field does not exist',
          );
          rawServerSideValidationErrors[dirtyFieldName] = {
            field: { name: dirtyFieldName },
            type: errorType,
          };
        }
      });
    } else {
      console.log('ValidationHelper.ts:146 Not handled error 😱', {
        apiErrors,
      });
    }
  }

  return rawServerSideValidationErrors;
};
