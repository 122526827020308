import { FC, useRef } from 'react';

import useWidth from '../../useWidth';
import { useStyles } from './table-row.style';
import { DynamicInput } from '../../dynamic-input';
import { TableRowProps } from '.';

export const TableRow: FC<TableRowProps> = props => {
  const { key, fields, dynamicInputProps } = props;
  const { currentTabIndex, inputInPuzzleForm } = dynamicInputProps;

  const classes = useStyles();
  const width = useWidth();

  const tableRowRef = useRef<HTMLTableRowElement>(null);

  const fieldsInRowVisibilityStatus: Array<boolean> = [];

  return (
    <tr key={key} ref={tableRowRef} data-style-row-tabel="rowTabel">
      {fields.map((field, index, array) => {
        const isFieldHidden =
          (field === 'empty' && (width === 'lg' || width === 'xl')) ||
          field?.['hidden'];

        fieldsInRowVisibilityStatus.push(!isFieldHidden);

        //if all td's is hidden then hide the tr
        if (
          tableRowRef.current &&
          index + 1 === array.length && //is last array item
          !fieldsInRowVisibilityStatus.includes(true)
        ) {
          tableRowRef.current.className = 'displayNone';
        }

        if (field && field !== 'empty') {
          return (
            <td
              key={index}
              rowSpan={field.rowSpan ? field.rowSpan : 1}
              colSpan={field.colSpan ? field.colSpan : 1}
              className={
                isFieldHidden
                  ? `${classes.emptyTableCell} displayNone`
                  : classes.tableCell
              }
              data-test-td-name={field.name}
              data-style-td-name="tableItem"
            >
              <DynamicInput
                field={field}
                currentTabIndex={currentTabIndex}
                inputInPuzzleForm={inputInPuzzleForm}
              />
            </td>
          );
        }

        return null; // !(field && field !== 'empty')
      })}
    </tr>
  );
};
