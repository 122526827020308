import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core';
import { useInput, FieldTitle } from 'ra-core';
import { useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';

import sanitizeRestProps from './sanitizeRestProps';
import { dummyFunc } from '../../helper/InputHelper';

const styles = () => ({
  error: {
    position: 'absolute',
    right: 2,
  },
});

const BooleanInput = props => {
  const {
    className,
    id,
    input,
    label,
    source,
    resource,
    options,
    fullWidth,
    classes,
    formName,
    visibleClass,
    disabled,
    customError,
    field,
    ...rest
  } = props;

  const [state, setState] = useState({ focus: false, blur: false });

  const translate = useTranslate();

  const {
    input: { name, onChange, onBlur, onFocus, value },
    meta: { touched, error },
  } = useInput(props);

  const [cleanValue, setCleanValue] = useState(value);

  /**
   * Convert string values to boolean
   */
  useEffect(() => {
    if (value === 'false') {
      setCleanValue(false);
    } else if (value === 'true') {
      setCleanValue(true);
    } else {
      setCleanValue(!!value);
    }
  }, [value]);

  const handleBlur = event => {
    const { onBlur: onBlurProps = dummyFunc } = props;
    setState({ focus: false, blur: true });
    onBlur(event);
    onBlurProps(event);
  };

  const handleFocus = event => {
    const { onFocus: onFocusProps = dummyFunc } = props;
    setState({ focus: true, blur: false });
    onFocus(event);
    onFocusProps(event);
  };

  const handleChange = event => {
    const { onChange: onChangeProps = dummyFunc } = props;
    onChange(event.target.checked);
    onChangeProps(event.target.checked);
  };

  return (
    <FormGroup
      className={`${visibleClass} ${className}`}
      data-focus={!!state.focus}
      data-blur={!!state.blur}
      {...sanitizeRestProps(rest)}
      id="switchButton"
    >
      <FormControlLabel
        htmlFor={id}
        disabled={disabled}
        control={
          <Switch
            data-test-bool-input-value={!!value}
            {...options}
            id={id}
            color="primary"
            checked={cleanValue}
            name={name}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
          />
        }
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={field.required}
          />
        }
      />
      {!!customError || (error && touched) ? (
        <FormHelperText error className={classes.error}>
          {customError ? customError : translate(lodashGet(error, 'message', error))}
        </FormHelperText>
      ) : null}
    </FormGroup>
  );
};

BooleanInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
  options: PropTypes.object,
  changeBooleanFocus: PropTypes.func,
  changeBooleanBlur: PropTypes.func,
  formName: PropTypes.string,
  customError: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(BooleanInput);
