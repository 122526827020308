import { ReactElement } from 'react';
import { EventsCalendarViewInterface } from './events-calendar.type';
import { useStyles } from './events-calendar.style';
import { Card, Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { visitorItems } from '../visitor-calendar/visitor-calendar.helper';

const EventsCalendarView = (props: EventsCalendarViewInterface): ReactElement => {
  const { handleDragStart } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Typography variant="subtitle2" className={classes.title}>
          {translate('calendar.items')}
        </Typography>
        <div className={classes.rootItems}>
          {Object.entries(visitorItems).map(([VisitCalendarType_ID, name]) => (
            <div
              className={`${classes.items} ${
                parseInt(VisitCalendarType_ID) === 1
                  ? classes.freeType
                  : parseInt(VisitCalendarType_ID) === 2
                  ? classes.vacationType
                  : ''
              }`}
              draggable="true"
              key={name}
              onDragStart={() =>
                handleDragStart({
                  title: name,
                  VisitCalendarType_ID: parseInt(VisitCalendarType_ID),
                })
              }
            >
              <Typography variant="button" className={classes.text}>
                {name}
              </Typography>
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default EventsCalendarView;
