import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../core/themeProvider';
import grey from '@material-ui/core/colors/grey';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  loadingContainer: {
    backgroundImage: ` url("/assets/image/telegram-bg.jpg")`,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  scrollChatNomain: {
    backgroundImage: ` url("/assets/image/telegram-bg.jpg")`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100% !important',
  },
  noMessage: {
    fontSize: 18,
    color: grey[500],
  },
  headerContainer: {
    height: '55px !important',
  },

  messagesScreenFooter: {
    background: '#fff !important',
    zIndex: 2,
  },
}));
