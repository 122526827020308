import {
  PrepareRelationList,
  RelationMetaType,
} from '../component/relation/RelationTypes';
import {
  getFieldsById,
  getGridColumns,
  getPrimaryField,
  getRelationsInForm,
  isGridInlineEditable,
} from './MetaHelper';
import { clone, isEmptyObject } from './data-helper';
import {
  FormRelation,
  RawRelationInMetaData,
  RelationAdditionalProps,
  RelationsCommonProps,
} from '../component/form';
import { getProcessInformationFromRecord } from './meta-helper';
import { FieldType, MetaData } from './Types';
import { getTypeByField } from './InputHelper';
import { getAppSettings } from './settings-helper';
import { CONFIG_LIST_COLUMN_CHOICE, DEFAULT } from '../core/configProvider';

/**
 * Prepare relation grid columns
 * @function getFieldsForDisplay
 * @param {number[] | null} defaultSelected default columns
 * @param {number[] | null} userSelected user selected columns in setting
 * @param {object} relationMetaData
 * @param {number[] | null} disabledFieldList disabled columns
 * @returns {object[]}
 */
export const getFieldsForDisplay = (
  defaultSelected: number[] | null,
  userSelected: number[] | null,
  relationMetaData: RelationMetaType,
  disabledFieldList?: { [x: number]: boolean } | null,
): object[] => {
  let fieldList;
  const gridColumns = getGridColumns({
    metaData: relationMetaData,
    filterFirstFive: false,
    isRelation: true,
  });

  // if user has selected column order
  if (userSelected && userSelected.length) {
    fieldList = getFieldsById(relationMetaData, userSelected);
  }
  // or admin as selected default order
  else if (defaultSelected && defaultSelected.length) {
    fieldList = getFieldsById(relationMetaData, defaultSelected);
  } else if (relationMetaData?.reportId) {
    fieldList = gridColumns;
  }
  // else show all columns
  else {
    const primaryField = getPrimaryField(relationMetaData);
    let allFields = gridColumns;
    // in relations, we don't need to show primary field
    if (primaryField) {
      allFields =
        gridColumns && gridColumns.length
          ? gridColumns.filter(field => field.id !== primaryField.id)
          : [];
      fieldList = [...allFields];
    }
  }

  if (!disabledFieldList) {
    return fieldList;
  }

  if (fieldList && fieldList.length) {
    return clone(fieldList).map(field => {
      if (disabledFieldList[field.id]) {
        field.disabled = true;
      }

      return field;
    });
  }

  return [];
};

/**
 * it should compute the relations in form from meta data , then prepare the require properties
 * for each relation panel , then push each relation panel necessary pops to an array.
 * @function prepareRelationList
 * @param { Record<string, unknown> } relationRecord
 * @param { MetaData } parentMetaData
 * @param { ResourceInterface } currentResource
 * @param { Record<string, unknown> | undefined } fullRecord
 * @param { FormData } formData
 * @param { string } basePath
 * @param { Match } match
 * @returns { Array<FormRelation> } Array<FormRelation>
 */
export const prepareRelationList: PrepareRelationList = (
  relationRecord,
  parentMetaData,
  currentResource,
  fullRecord,
  formData,
  basePath,
  match,
) => {
  const relationList: Array<FormRelation> = [];

  const processInfo = getProcessInformationFromRecord(fullRecord);
  const relationsInForm = getRelationsInForm(parentMetaData, processInfo);

  relationsInForm.forEach((relation: RawRelationInMetaData) => {
    const relationResource = `${relation.moduleName}/${relation.moduleTableName}`;
    const relationPath = `${relationResource}/${relation.childFieldName}`;

    const parentRecordIsEditable =
      (formData?.iseditable as boolean) ??
      (fullRecord?.iseditable as boolean) ??
      false;

    const additionalProps: RelationAdditionalProps = {
      relationPath,
      resource: currentResource.value,
      originalRecord: fullRecord,
      allowUsePropsAfterCreateRelation: true,
    };

    const defaultSelected =
      getAppSettings<Record<string, unknown>>(
        `${DEFAULT}_${CONFIG_LIST_COLUMN_CHOICE}_${relationPath}`,
      ).value ?? {};

    const userSelected =
      getAppSettings<Record<string, unknown>>(
        `${CONFIG_LIST_COLUMN_CHOICE}_${relationPath}`,
        true,
      ).value ?? {};

    const relationsCommonProps: RelationsCommonProps = {
      relationRecord,
      fullRecord,
      resource: currentResource.value,
      basePath,
      match,
    };

    const formRelationProps: FormRelation = {
      relationResource,
      relationPath,
      additionalProps,
      defaultSelected,
      userSelected,
      relation,
      relationsCommonProps,
      parentRecordIsEditable,
    };

    relationList.push(formRelationProps);
  });

  return relationList;
};

/**
 * Determine if the input is disabled or not
 * @function isRelationFieldDisabled
 * @param {FieldType} field
 * @returns {boolean}
 */
const isRelationFieldDisabled = (field: FieldType): boolean => {
  const { disabled: fieldDisabled, uiEnable } = field;
  return fieldDisabled || (uiEnable && fieldDisabled);
};

/**
 * @function isRelationCellEditableWithoutTypeChecking
 * @param {FieldType} field
 * @returns {boolean}
 */
export const isRelationCellEditableWithoutTypeChecking = (
  field: FieldType,
  metaData: MetaData,
  hasEdit: boolean,
): boolean => !!(isGridInlineEditable(metaData) && hasEdit && !field?.disabled);
