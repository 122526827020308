import React from 'react';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { connect } from 'react-redux';

import TodoMenuItem from './TodoMenuItem';
import TodoAddNewList from './TodoAddNewList';
import TodoMenuListItem from './TodoMenuListItem';
import TodoMenuGroupItem from './TodoMenuGroupItem';
import { prepareGroupList, largestRowOrder } from '../../helper/TodoListHelper';
import { getTodoTaskDetailsPatternInfo } from '../../helper/PatternMetaHelper';
import {
  addListToGroupAction,
  updateTodoTaskDetailAction,
  updateListMemberAction,
  renameTodoListAction,
  deleteEmptyGroupAction,
  setSelectTodoList,
} from '../../redux/todoList/action';
import LoadingBox from '../LoadingBox';
import TodoMenuAssignToMeItem from './TodoMenuAssignToMeItem';
import TodoMenuSystemTaskItem from './TodoMenuSystemTaskItem';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },

  listContainer: {
    marginTop: 20,
    flexGrow: 1,
    overflow: 'auto',
  },

  emptyGroup: {
    padding: '5px 10px',
    textAlign: 'center',
    fontSize: '0.8em',
    color: theme.palette.text.secondary,
  },

  addlistContainer: {},
}));

const TodoMenuSidebarListView = props => {
  const {
    data,
    todoListPattern,
    todoListSelectedId,
    resource,
    detailResource,
    addListToGroup,
    updateTaskDetailListId,
    updateListMember,
    renameGroup,
    todoListSelectedFilter,
    translate,
    deleteEmptyGroup,
    listLoading,
    createLoading,
    setSelectTodoList,
    userSort,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const {
    id,
    groupName,
    groupId,
    dragListAcceptType,
    dragGroupAcceptType,
    dragTaskAcceptType,
    rowOrder,
    reportResource,
    sort,
    pagination,
    listMemberResource,
    groupResource,
    deleteGroupService,
    ungroupService,
    idListMember,
  } = todoListPattern;

  const preparedGroupList = prepareGroupList(data, todoListPattern, [idListMember]);

  const addChildToGroup = data => {
    addListToGroup(
      listMemberResource,
      data,
      reportResource,
      sort,
      pagination,
      resource,
    );
  };

  const addTaskToNewList = (taskId, newListId) => {
    const { parentId, id } = getTodoTaskDetailsPatternInfo(detailResource);

    const data = {
      data: {
        [parentId]: newListId,
      },
      [id]: taskId,
    };
    const options = {
      sort: { ...sort },
      pagination: { ...pagination },
      reportResource,
    };
    updateTaskDetailListId(detailResource, data, options, resource);
  };

  const updateRowOrder = data => {
    updateListMember(
      listMemberResource,
      data,
      resource,
      reportResource,
      sort,
      pagination,
    );
  };

  const updateGroupName = data => {
    renameGroup(groupResource, data, reportResource, sort, pagination, resource);
  };

  const deleteGroup = data => {
    deleteEmptyGroup(
      resource,
      deleteGroupService,
      data,
      reportResource,
      sort,
      pagination,
    );
  };

  const ungroup = data => {
    deleteEmptyGroup(
      resource,
      ungroupService,
      data,
      reportResource,
      sort,
      pagination,
    );
  };

  const handleDefaultListClick = () => {
    setSelectTodoList({});
  };

  const menuItemProps = {
    resource,
    todoListSelectedFilter,
    todoListSelectedId,
    userSort,
    onClick: handleDefaultListClick,
  };

  let lastRowOrder = 0;

  return (
    <div className={classes.container}>
      <div data-test-todo-default-lists>
        <TodoMenuItem
          {...menuItemProps}
          filterType="IsAddedToMyDay"
          title={translate('todo.IsAddedToMyDay')}
          icon="sunny"
        />
        <TodoMenuItem
          {...menuItemProps}
          filterType="IsImportant"
          title={translate('todo.IsImportant')}
          icon="favoriteStar"
        />
        <TodoMenuItem
          {...menuItemProps}
          title={translate('todo.DueDate')}
          filterType="DueDate"
          icon="calendar"
        />
        <TodoMenuAssignToMeItem
          {...menuItemProps}
          filterType="agentuser"
          title={translate('todo.assignedtome')}
          icon="contact"
          color="#4CAF50"
        />
        <TodoMenuSystemTaskItem
          {...menuItemProps}
          filterType="sysitem"
          title={translate('todo.sysitem')}
          icon="system"
        />
        <TodoMenuItem
          {...menuItemProps}
          title={translate('todo.tasks')}
          icon="home"
          color={theme.palette.primary.main}
        />
      </div>

      <div className={classes.listContainer} data-test="listContainer">
        {/* @ts-ignore */}
        <DndProvider backend={HTML5Backend}>
          {preparedGroupList &&
            preparedGroupList.map(list => {
              let todoListItems;
              if (list.listChild) {
                todoListItems = (
                  <TodoMenuGroupItem
                    key={`${list[id]}-${list[groupId]}${list.listMemberId}`}
                    uniqueKey={`G-${list[groupId]}`}
                    title={list[groupName]}
                    dragListAcceptType={dragListAcceptType}
                    dragGroupAcceptType={dragGroupAcceptType}
                    resource={resource}
                    addChildToGroup={addChildToGroup}
                    listChild={list.listChild}
                    lastRowOrder={lastRowOrder}
                    record={list}
                    todoListPattern={todoListPattern}
                    updateRowOrder={updateRowOrder}
                    updateGroupName={updateGroupName}
                    deleteGroup={deleteGroup}
                    ungroup={ungroup}
                  >
                    {list.listChild.length ? (
                      list.listChild.map(listChild => {
                        return (
                          <TodoMenuListItem
                            key={listChild[id]}
                            uniqueKey={'L-' + listChild[id]}
                            record={listChild}
                            todoListPattern={todoListPattern}
                            selected={todoListSelectedId === listChild[id]}
                            dragListAcceptType={dragListAcceptType}
                            dragGroupAcceptType={dragGroupAcceptType}
                            dropTaskAcceptType={dragTaskAcceptType}
                            addTaskToNewList={addTaskToNewList}
                            listChild={listChild}
                            updateRowOrder={updateRowOrder}
                            resource={resource}
                            todoListSelectedId={todoListSelectedId}
                          />
                        );
                      })
                    ) : (
                      <span className={classes.emptyGroup}>
                        {translate('todo.dragListToEmptyGroup')}
                      </span>
                    )}
                  </TodoMenuGroupItem>
                );
              } else {
                todoListItems = (
                  <TodoMenuListItem
                    key={list[id]}
                    uniqueKey={'L-' + list[id]}
                    record={list}
                    todoListPattern={todoListPattern}
                    selected={todoListSelectedId === list[id]}
                    dragListAcceptType={dragListAcceptType}
                    dragGroupAcceptType={dragGroupAcceptType}
                    dropTaskAcceptType={dragTaskAcceptType}
                    addTaskToNewList={addTaskToNewList}
                    lastRowOrder={lastRowOrder}
                    updateRowOrder={updateRowOrder}
                    resource={resource}
                    todoListSelectedId={todoListSelectedId}
                  />
                );
              }
              lastRowOrder = list[rowOrder];
              return todoListItems;
            })}
        </DndProvider>

        {(listLoading || createLoading) && <LoadingBox size={30} />}
      </div>
      <div className={classes.addlistContainer}>
        <TodoAddNewList
          largestRowOrder={largestRowOrder(preparedGroupList, rowOrder)}
          resource={resource}
        />
      </div>
    </div>
  );
};

// TODO: add interface instead
// TodoMenuSidebarListView.propTypes = {
//   todoListPattern: PropTypes.object,
//   data: PropTypes.array.isRequired,
//   metaData: PropTypes.object,
//   todoListSelectedId: PropTypes.number,
// };

const mapDispatchToProps = {
  addListToGroup: addListToGroupAction,
  updateTaskDetailListId: updateTodoTaskDetailAction,
  updateListMember: updateListMemberAction,
  renameGroup: renameTodoListAction,
  deleteEmptyGroup: deleteEmptyGroupAction,
  setSelectTodoList,
};

export default compose(
  translate,
  connect(null, mapDispatchToProps),
)(TodoMenuSidebarListView);
