import React, { FC, useState } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';

import ListContainerApiController from '../container/ListContainerApiController';
import SellinSearchView from '../component/shop/SellinSearchView';
import { getSellinSearchPatternInfo } from '../helper/PatternMetaHelper';
import CheckResourceReady from '../container/CheckResourceReady';

interface SellinSearchPageInterfaceProps {
  [x: string]: any;
}

const SellinSearchPage: FC<SellinSearchPageInterfaceProps> = props => {
  const { resource, ...rest } = props;

  const pattern = getSellinSearchPatternInfo(resource);

  return (
    <CheckResourceReady resourceName={resource}>
      <ListContainerApiController
        {...rest}
        basePath={`/${resource}`}
        resource={resource}
        perPage={1000}
      >
        {controllerProps => (
          <SellinSearchView {...rest} {...controllerProps} pattern={pattern} />
        )}
      </ListContainerApiController>
    </CheckResourceReady>
  );
};

const mapStateToProps = (state, { match }) => {
  const { moduleName } = match.params;
  const resource = `${moduleName}`.toLowerCase();

  return {
    resource,
  };
};

export default connect(mapStateToProps)(SellinSearchPage);
