import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  dragHandelListItem: {
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1301,
    backgroundColor: 'rgba(247, 247, 247, 0.78)',
    border: '1px solid rgba(230, 230, 230, 0.41);',
    justifyContent: 'space-between',
  },

  widthContainer: {
    textAlign: 'end',
    flexGrow: 1,
    margin: '0 10px',
  },

  widthInput: {
    width: 70,
    direction: 'rtl',
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
  },

  widthInputBase: {
    padding: 0,
  },

  htmlInput: {
    padding: 5,
    '&::-webkit-inner-spin-button,&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
    },
  },

  adornedEnd: {
    margin: 5,
  },

  disabled: {
    backgroundColor: theme.palette.grey[300],
  },
}));
