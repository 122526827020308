// in src/Dashboard.js
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import lodashFind from 'lodash/find';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Title, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { Icon, InputBase } from '@material-ui/core';
import lodashIsEqual from 'lodash/isEqual';
import lodashGet from 'lodash/get';

import NotFound from '../component/NotFound';
import { findAllAction as findAllMenuAction } from '../redux/menu/action';
import { isStringWthStarsMatch } from '../helper/TextHelper';
import LocaleHoc from '../component/LocaleHoc';
import { apiUrl, apiVersion } from '../core/dataProvider';
import { getValue, SESSION_ID } from '../core/configProvider';
import { actorDispatch, actorGetActionValue } from '../type/actor-setup';

const styles = theme => ({
  menuPageContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  iframeContainer: {
    height: '100%',
  },

  cardContent: {
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    overflow: 'auto',
  },

  card: {
    display: 'flex',
    margin: 16,
    textAlign: 'center',
    flexGrow: 1,
    textDecoration: 'none',
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    [theme.breakpoints.up('xs')]: {
      width: '39%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '39%',
    },
    [theme.breakpoints.up('md')]: {
      width: '28%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '15%',
    },

    [theme.breakpoints.down('xs')]: {
      margin: 10,
    },
  },

  cardActionArea: {
    fontFamily: theme.typography.fontFamily,
    padding: '25px 0',
  },

  menuLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },

  containerSearch: {
    backgroundColor: '#ffffff',
    display: 'inline-flex',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    padding: '0 5px',
  },

  iconSearch: {
    color: theme.palette.text.hint,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },

  input: {
    marginLeft: 8,
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
});

class MenuPage extends PureComponent {
  state = { searchMenu: '', menu: [] };

  componentDidMount() {
    const { menuIsLoading, menuIsLoadedOnce, findAllMenu, menuArray } = this.props;

    if (!menuIsLoadedOnce && !menuIsLoading && !menuArray) {
      findAllMenu();
    } else {
      this.findPageFromMenu(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!lodashIsEqual(this.props.menuArray, nextProps.menuArray)) {
      this.findPageFromMenu(nextProps);
    }
  }

  findPageFromMenu({ match, menuArray }) {
    const menuList = menuArray || [];

    const menu = lodashFind(menuList, {
      id: parseInt(match.params.id, 10),
    });

    if (menu) {
      this.setState({ menu: menu });
    }
  }
  render() {
    const { classes, translate, locale } = this.props;
    const { menu, searchMenu } = this.state;
    const { list, type, url, data } = menu;

    // In `wms` => menuItems are in `menu.data`
    const menuList = data ?? list;

    if (url && type && type === 'iFrame') {
      return <iframe className={classes.iframeContainer} src={url} />;
    } else if (url && type && type === 'dashboard') {
      const locale = actorGetActionValue('reactAdminHelpers')?.locale;
      const pageTitle = menu.translatedTitle?.[locale] ?? menu.pageName;

      actorDispatch(
        'setDocumentTitle',
        {
          metaData: null,
          recordTitle: pageTitle,
          locale: locale,
        },
        { replaceAll: true },
      );

      const sessionId = getValue(SESSION_ID);
      const preparedUrl = `${apiUrl}/oauth2/${sessionId}/${apiVersion}/${url}`;

      return <iframe className={classes.iframeContainer} src={preparedUrl} />;
    }

    if (!menuList) {
      return (
        <NotFound
          title={translate('general.theMenusAreNotAvailable')}
          notFoundIcon="block"
        />
      );
    }

    return (
      <Card className={classes.menuPageContainer}>
        <Title
          title={
            <Fragment>
              <div className={classes.containerSearch}>
                <Icon className={classes.iconSearch}>search</Icon>
                <InputBase
                  className={classes.input}
                  placeholder={translate('ra.action.search')}
                  value={searchMenu}
                  onChange={event =>
                    this.setState({ searchMenu: event.target.value })
                  }
                  inputProps={{ 'aria-label': translate('ra.action.search') }}
                  autoFocus
                />
              </div>
            </Fragment>
          }
        />
        <CardContent className={classes.cardContent}>
          {menuList
            .filter(itemElement => {
              if (!itemElement) {
                return false;
              }

              // prettier-ignore
              const isMatch1 = lodashGet(itemElement, ['translatedTitle', locale], false)  && isStringWthStarsMatch(lodashGet(itemElement, ['translatedTitle', locale]), searchMenu);
              const isMatch2 = isStringWthStarsMatch(itemElement.title, searchMenu);

              return isMatch1 || isMatch2;
            })
            .map(item => (
              <Link key={item.id} className={classes.card} to={`/${item.route}`}>
                <CardActionArea className={classes.cardActionArea}>
                  {lodashGet(
                    item,
                    ['translatedTitle', locale],
                    lodashGet(item, ['title']),
                  )}
                </CardActionArea>
              </Link>
            ))}
        </CardContent>
      </Card>
    );
  }
}

MenuPage.propTypes = {
  locale: PropTypes.string.isRequired,
  findAllMenu: PropTypes.func.isRequired,
  menuIsLoading: PropTypes.bool.isRequired,
  menuIsLoadedOnce: PropTypes.bool.isRequired,
  menuArray: PropTypes.array,
};

const mapStateToProps = state => ({
  menuIsLoading: state.menu.isLoading,
  menuArray: state.menu.list,
  menuIsLoadedOnce: state.menu.isLoadedOnce,
});

const mapDispatchToProps = {
  findAllMenu: findAllMenuAction,
};

export default compose(
  translate,
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  LocaleHoc,
)(MenuPage);
