import { FC, memo, useRef, useState } from 'react';

import { SplitButtonInterface } from './split-button.type';
import SplitButtonView from './split-button.view';

const SplitButtonController: FC<SplitButtonInterface> = memo(props => {
  const {
    options,
    onClickItem,
    className,
    buttonClassName,
    toggleButtonClassName,
    disableButton,
    ...rest
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  /**
   * @function  handleClick
   * @returns { void }
   */
  const handleClick = (): void => {
    onClickItem(selectedIndex);
  };

  /**
   * @function handleMenuItemClick
   * @param { React.MouseEvent<HTMLLIElement, MouseEvent> } event
   * @param { number } index
   * @returns { void }
   */
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ): void => {
    onClickItem(index);
    setOpen(false);
  };

  /**
   * @function handleToggle
   * @returns { void }
   */
  const handleToggle = (): void => {
    setOpen(prevOpen => !prevOpen);
  };

  /**
   * @function handleClose
   * @param { React.MouseEvent<Document, MouseEvent> } event
   * @returns { void }
   */
  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <SplitButtonView
      {...rest}
      disableButton={disableButton}
      className={className}
      buttonClassName={buttonClassName}
      toggleButtonClassName={toggleButtonClassName}
      anchorRef={anchorRef}
      selectedIndex={selectedIndex}
      open={open}
      options={options}
      handleClick={handleClick}
      handleToggle={handleToggle}
      handleClose={handleClose}
      handleMenuItemClick={handleMenuItemClick}
    />
  );
});

export default SplitButtonController;
