export const TODO = 'todo';
export const FILE_MANAGER = 'filemanager';
export const MAIL = 'mail';
export const CHAT = 'chat';
export const POS = 'pos';
export const SELLIN_SHOW = 'sellin-show';
export const SELLIN_LIST = 'sellin-list';
export const CART = 'cart';
export const TREE = 'tree';
export const MENU = 'menu';
export const WMS = 'wms';
export const PUZZLE = 'puzzle';
export const CALENDAR = 'calendar';
export const FORM_LAYOUT = 'form-layout';
export const MULTI_REPORT = 'multi-report';
export const SINGLE_RECORD = 'single-record';
export const CATEGORY_LIST = 'category-list';
export const SELLIN_HISTORY_ORDER = 'sellin-history-order';
export const SELLIN_HISTORY_ORDER_DETAIL = 'sellin-history-order-detail';
export const SELLIN_FINALIZE = 'sellin-finalize';
export const SELLIN_SEARCH = 'sellin-search';
export const SELLIN_SELECT_COMPANY = 'sellin-company';
export const VISITOR_CALENDAR = 'visitor-calendar';
export const REPORT_CALENDAR = 'report-calendar';
export const MAP = 'map';
export const PIVOT_TABLE = 'pivot-table';
