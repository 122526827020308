import { delay, call, put, takeEvery } from 'redux-saga/effects';
import {
  DELETE,
  GET_LIST,
  CREATE,
  refreshView,
  showNotification,
  UPDATE,
} from 'react-admin';
import lodashGet from 'lodash/get';
import lodashIsEqual from 'lodash/isEqual';
import { push } from 'connected-react-router';

import dataProvider, { RUN_SERVICE } from '../../core/dataProvider';
import {
  ADD_LIST_TO_GROUP_ACTION,
  UPDATE_TODO_TASK_DETAIL,
  GET_TODO_LIST_ACTION,
  INSERT_TASK_ACTION,
  INSERT_STEP_ACTION,
  ADD_NEW_TODO_LIST_OR_GROUP,
  DELETE_TODO_LIST,
  RENAME_TODO_LIST,
  REMOVE_MEMBER_ACTION,
  GET_SHARED_LIST_MEMBER_ACTION,
  UPDATE_LIST_MEMBER,
  DELETE_TODO_GROUP,
  DELETE_TODO_TASK,
  SYSTEM_TASK_ASSIGN,
  GET_SYSTEM_TASK_FILTERS,
} from './constant';
import { TODO } from '../../core/configRouteConstant';
import {
  getListSuccessAction,
  getListFailedAction,
  insertTaskFailedAction,
  plusListCounterAction,
  getTodoListAction,
  closeTodoShareListDialogAction as closeDialog,
  addNewListOrGroupEndAction,
  getSharedListMemberAction,
  setSelectTodoList,
  toggleGroupExpandedAction,
  setSysTaskFilters,
} from './action';

const loadingList = {};

function* getTodoList({ resource, reportResource, sort, pagination }) {
  try {
    if (loadingList[resource]) {
      return;
    }

    // add to list to prevent multiple calls to same place
    loadingList[resource] = true;

    const { data } = yield dataProvider(GET_LIST, reportResource, {
      sort,
      pagination,
    });
    yield put(getListSuccessAction({ resource, data }));
  } catch (error) {
    const userMessage = lodashGet(
      error,
      ['response', 'data', 'userMessage'],
      error,
    ).toString();
    yield put(getListFailedAction({ resource, userMessage }));
  } finally {
    delete loadingList[resource];
  }
}

// eslint-disable-next-line require-yield
function* insertNewTask({ resource, body, parentList }) {
  try {
    if (loadingList[resource]) {
      return;
    }
    // add to list to prevent multiple calls to same place
    loadingList[resource] = true;
    const response = yield dataProvider(CREATE, resource, { data: body });
    yield put(refreshView());

    if (response) {
      yield put(plusListCounterAction(parentList));
    }
  } catch (error) {
    const userMessage = lodashGet(
      error,
      ['response', 'data', 'userMessage'],
      error,
    ).toString();
    yield put(insertTaskFailedAction({ resource, userMessage }));
  } finally {
    delete loadingList[resource];
  }
}

// eslint-disable-next-line require-yield
function* insertNewStep({ resource, body }) {
  try {
    if (loadingList[resource]) {
      return;
    }
    // add to list to prevent multiple calls to same place
    loadingList[resource] = true;
    yield dataProvider(CREATE, resource, { data: body });
  } catch (error) {
    const userMessage = lodashGet(
      error,
      ['response', 'data', 'userMessage'],
      error,
    ).toString();
    yield put(insertTaskFailedAction({ resource, userMessage }));
  } finally {
    delete loadingList[resource];
  }
}

const savingList = {};
function* saveNewTodoListOrGroup({
  resources,
  data,
  listMemberRowOrder,
  userId,
  sort,
  pagination,
  userIdPattern,
  insertFieldIdPattern, // list_id OR foldernames_id
  todoListPattern,
}) {
  try {
    if (savingList[resources.listResource]) {
      return;
    }

    // add to list to prevent multiple calls to same place
    savingList[resources.listResource] = true;

    let fieldId;
    yield dataProvider(CREATE, resources.fieldResource, { data }).then(
      ({ data }) => {
        fieldId = data[insertFieldIdPattern];
      },
    );

    let preparedDataToProcess;
    yield dataProvider(CREATE, resources.listMemberResource, {
      data: {
        [insertFieldIdPattern]: fieldId,
        [userIdPattern]: userId,
        ...listMemberRowOrder,
      },
    }).then(({ data }) => {
      preparedDataToProcess = {
        data,
        todoListPattern,
        resource: resources['listResource'],
      };
    });

    yield call(getTodoList, {
      resource: resources.listResource,
      reportResource: resources.reportResource,
      sort,
      pagination,
    });

    yield delay(100);
    yield call(focusListOrExpandGroupAfterCreate, preparedDataToProcess);
  } catch (error) {
    const message = error.message || error.toString();
    yield put(showNotification(message, 'warning'));
  } finally {
    delete savingList[resources.listResource];
    yield put(addNewListOrGroupEndAction());
  }
}

function* focusListOrExpandGroupAfterCreate({
  data,
  todoListPattern = {},
  resource,
}) {
  const {
    staticFilterList = '',
    reportTaskDetailsResource = '',
    groupId = '',
    listId = '',
  } = todoListPattern;
  if (data[listId] && !data[groupId]) {
    yield put(setSelectTodoList(data));
    yield put(
      push(
        `/${TODO}/${resource}?detail=${reportTaskDetailsResource}&filter={"${staticFilterList.listId}":["${staticFilterList.listId}","=","${data[listId]}"]}`,
      ),
    );
  } else if (!data[listId] && data[groupId]) {
    yield put(toggleGroupExpandedAction(data['id']));
  }
}

function* updateListMember({
  resource,
  data,
  listResource,
  reportResource,
  sort,
  pagination,
  getList,
}) {
  try {
    yield dataProvider(UPDATE, resource, data);
    if (getList) {
      yield put(getTodoListAction(listResource, reportResource, sort, pagination));
    }
  } catch (error) {
    const message = error.message || error.toString();
    yield put(showNotification(message, 'warning'));
  } finally {
    delete savingList[resource];
  }
}

function* updateTodoTaskDetail({ resource, data, options, listResource }) {
  try {
    yield dataProvider(UPDATE, resource, data);
    yield put(refreshView());
    if (listResource) {
      yield put(
        getTodoListAction(
          listResource,
          options.reportResource,
          options.sort,
          options.pagination,
        ),
      );
    }
  } catch (error) {
    const userMessage = lodashGet(
      error,
      ['response', 'data', 'userMessage'],
      error,
    ).toString();
    yield put(getListFailedAction({ resource, userMessage }));
  } finally {
    delete savingList[resource];
  }
}

function* addListToGroup({
  resource, // listMemberResource
  data,
  reportResource,
  sort,
  pagination,
  getTodoListResource,
}) {
  try {
    yield dataProvider(UPDATE, resource, data);
    yield put(
      getTodoListAction(getTodoListResource, reportResource, sort, pagination),
    );
  } catch (error) {
    const message = error.message || error.toString();
    yield put(showNotification(message, 'warning'));
  } finally {
    delete savingList[resource];
  }
}

function* renameTodoList({
  resource,
  data,
  reportResource,
  sort,
  pagination,
  getTodoListResource,
}) {
  try {
    yield dataProvider(UPDATE, resource, data);
    yield put(
      getTodoListAction(getTodoListResource, reportResource, sort, pagination),
    );
  } catch (error) {
    const message = error.message || error.toString();
    yield put(showNotification(message, 'warning'));
  } finally {
    delete savingList[resource];
  }
}

function* deleteTodoList({
  resource,
  deleteService,
  data,
  reportResource,
  sort,
  pagination,
  isCurrentList,
  reportTaskDetailsResource,
}) {
  try {
    if (savingList[resource]) {
      return;
    }
    // add to list to prevent multiple calls to same place
    savingList[resource] = true;

    yield dataProvider(RUN_SERVICE, resource, {
      actionUniqueId: deleteService,
      data: { params: data },
    });

    yield put(getTodoListAction(resource, reportResource, sort, pagination));
    if (isCurrentList) {
      yield put(push(`/todo/task/list?detail=${reportTaskDetailsResource}`));
      yield put(setSelectTodoList({}));
    }
  } catch (error) {
    const message = error.message || error.toString();
    yield put(showNotification(message, 'warning'));
  } finally {
    delete savingList[resource];
  }
}

function* deleteEmptyGroup({
  resource,
  deleteService,
  data,
  reportResource,
  sort,
  pagination,
}) {
  try {
    if (savingList[resource]) {
      return;
    }
    // add to list to prevent multiple calls to same place
    savingList[resource] = true;

    yield dataProvider(RUN_SERVICE, resource, {
      actionUniqueId: deleteService,
      data: { params: data },
    });

    yield put(getTodoListAction(resource, reportResource, sort, pagination));
  } catch (error) {
    const message = error.message || error.toString();
    yield put(showNotification(message, 'warning'));
  } finally {
    delete savingList[resource];
  }
}

function* removeMember({
  resource,
  listMemberResource,
  data,
  pattern,
  listSharedMember,
  sort,
  pagination,
  reportResource,
  translate,
  reportSharedListMember,
  options,
}) {
  try {
    const updateData = {
      data: {
        [pattern.isSharedList]: false,
      },
      id: data[pattern.sharedListMemberListId],
    };

    yield dataProvider(DELETE, listMemberResource, {
      id: data[pattern.listMemberId],
    });
    yield put(
      getSharedListMemberAction(reportSharedListMember, options, listSharedMember),
    );
    yield put(showNotification(translate('ra.notification.deleted'), 'info'));
    if (listSharedMember.length - 1 === 1) {
      yield dataProvider(UPDATE, resource, updateData);
    }
    yield put(getTodoListAction(resource, reportResource, sort, pagination));
  } catch (error) {
    const userMessage = lodashGet(
      error,
      ['response', 'data', 'userMessage'],
      error,
    ).toString();
    yield put(showNotification(userMessage, 'error'));
  }
}

function* getSharedListMember({ resource, option = {}, previousData }) {
  try {
    if (loadingList[resource]) {
      return;
    }
    loadingList[resource] = true;
    const { data } = yield dataProvider(GET_LIST, resource, {
      ...option,
      rawResponse: true,
    });

    if (!lodashIsEqual(previousData, data)) {
      yield put(getListSuccessAction({ resource, data }));
    }
  } catch (error) {
    const userMessage = lodashGet(
      error,
      ['response', 'data', 'userMessage'],
      error,
    ).toString();
    yield put(getListFailedAction({ resource, userMessage }));
  } finally {
    delete loadingList[resource];
  }
}

function* deleteTask({
  serviceId,
  data,
  resource,
  reportResource,
  sort,
  pagination,
  parentList = '',
}) {
  try {
    if (savingList[resource]) {
      return;
    }

    const response = yield dataProvider(RUN_SERVICE, '', {
      actionUniqueId: serviceId,
      data: { params: data },
    });

    yield put(getTodoListAction(resource, reportResource, sort, pagination));
    yield put(refreshView());
    if (response) {
      yield put(plusListCounterAction(parentList));
    }
  } catch (error) {
    const message = error.message || error.toString();
    yield put(showNotification(message, 'error'));
  } finally {
    delete savingList[resource];
  }
}

/**
 * Assign specified system task to current user
 * @generator
 * @function systemTaskAssign
 * @yields {action} dispatch some other actions with provided response
 */
function* systemTaskAssign({
  serviceId,
  data,
  resource,
  reportResource,
  sort,
  pagination,
  parentList = '',
}) {
  try {
    if (savingList[resource]) {
      return;
    }

    const response = yield dataProvider(RUN_SERVICE, '', {
      actionUniqueId: serviceId,
      data: { params: data },
    });

    yield put(getTodoListAction(resource, reportResource, sort, pagination));
    yield put(refreshView());
    if (response) {
      yield put(plusListCounterAction(parentList));
    }
  } catch (error) {
    const message = error.message || error.toString();
    yield put(showNotification(message, 'error'));
  } finally {
    delete savingList[resource];
  }
}

/**
 * Get System Tasks filters list from API
 * @generator
 * @function getSysTaskFilters
 * @yields {action} dispatch some other actions with provided response
 */
function* getSysTaskFilters({ resource, options }) {
  try {
    const response = yield dataProvider(GET_LIST, resource, options);

    if (response && response.data) {
      yield put(setSysTaskFilters(response.data));
    }
  } catch (error) {
    const message = error.message || error.toString();
    yield put(showNotification(message, 'error'));
  }
}

export default function* dropdownSaga() {
  yield takeEvery(GET_TODO_LIST_ACTION, getTodoList);
  yield takeEvery(ADD_NEW_TODO_LIST_OR_GROUP, saveNewTodoListOrGroup);
  yield takeEvery(UPDATE_LIST_MEMBER, updateListMember);
  yield takeEvery(INSERT_TASK_ACTION, insertNewTask);
  yield takeEvery(INSERT_STEP_ACTION, insertNewStep);
  yield takeEvery(ADD_LIST_TO_GROUP_ACTION, addListToGroup);
  yield takeEvery(UPDATE_TODO_TASK_DETAIL, updateTodoTaskDetail);
  yield takeEvery(DELETE_TODO_LIST, deleteTodoList);
  yield takeEvery(RENAME_TODO_LIST, renameTodoList);
  yield takeEvery(REMOVE_MEMBER_ACTION, removeMember);
  yield takeEvery(GET_SHARED_LIST_MEMBER_ACTION, getSharedListMember);
  yield takeEvery(DELETE_TODO_GROUP, deleteEmptyGroup);
  yield takeEvery(DELETE_TODO_TASK, deleteTask);
  yield takeEvery(SYSTEM_TASK_ASSIGN, systemTaskAssign);
  yield takeEvery(GET_SYSTEM_TASK_FILTERS, getSysTaskFilters);
}
