import { FC, memo, useCallback, useEffect, useState } from 'react';
import { actorOnDispatch, actorRemoveAction } from '../../type/actor-setup';

import {
  CustomAccordionInterface,
  ToogleAccordionInterface,
} from './custom-accordion.type';
import CustomAccordionView from './custom-accordion.view';

const CustomAccordionController: FC<CustomAccordionInterface> = memo(props => {
  const { defaultExpanded = false, onChangeCallback, id } = props;
  const [expand, setExpand] = useState<boolean>(defaultExpanded);

  const onChange = (event, newValue: boolean) => {
    setExpand(newValue);
    newValue && onChangeCallback?.();
  };

  const checkPropagation = event => {
    if (event?.target?.className?.includes('MuiTypography-root')) {
      return;
    } else if (
      event?.target?.id !== 'summary-target' &&
      event?.target?.tagName !== 'span' &&
      event?.target?.getAttribute('data-tag') !== 'accordion-summuary'
    ) {
      event.stopPropagation();
    }
  };

  /**
   * check id relation by id pinned note
   * @function toggleAccordion
   * @param {ToogleAccordionInterface} accordionData
   * @returns {void} void
   */
  const toggleAccordion = useCallback(
    (accordionData: ToogleAccordionInterface): void => {
      if (id === accordionData?.accordionId) {
        onChange(null, !expand);
      }
    },
    [expand],
  );

  useEffect(() => {
    const id = actorOnDispatch('toggleAccordion', toggleAccordion, {
      preserve: false,
    });

    return () => {
      actorRemoveAction({
        actionName: 'toggleAccordion',
        listenerId: id,
      });
    };
  }, []);

  return (
    <CustomAccordionView
      {...props}
      expand={expand}
      onChange={onChange}
      checkPropagation={checkPropagation}
    />
  );
});

export default CustomAccordionController;
