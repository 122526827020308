import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  dialogActions: {
    margin: 0,
    padding: '24px 24px',
  },

  icon: {
    margin: '0 5px',
  },

  dialogTitle: {
    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  cancelBtn: {
    margin: '0 5px',
  },
}));
