import produce from 'immer';
import { CRUD_GET_ONE_SUCCESS } from 'react-admin';

const initialState = {
  additionalData: {},
};

const myReducer = (state = initialState, { type, meta, payload }) =>
  produce(state, draft => {
    // eslint-disable-next-line default-case
    switch (type) {
      case CRUD_GET_ONE_SUCCESS:
        draft.additionalData[meta.resource] = payload.additionalData;
        break;
    }
  });

export default myReducer;
