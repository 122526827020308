import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    height: '100%',
    width: '100%',
    minHeight: 700,
  },
}));
