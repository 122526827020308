import { ReactElement } from 'react';
import { useLocale } from 'react-admin';
import lodashGet from 'lodash/get';
import { ServiceDialogFormViewInterface } from './service-dialog-form.type';
import { useStyles } from './service-dialog-form.style';
import { DynamicInput } from '../../dynamic-input';
import { computeDisableField } from './service-dialog-form.helper';

const ServiceDialogFormView = (
  props: ServiceDialogFormViewInterface,
): ReactElement => {
  const { fields, isServiceMode, serviceParams } = props;
  const locale = useLocale();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <fieldset>
        {fields &&
          fields.map(field =>
            field ? (
              <div className={classes.inputContainer}>
                <DynamicInput
                  key={field.id}
                  field={field}
                  currentTabIndex={0}
                  label={lodashGet(
                    field,
                    ['translatedCaption', locale],
                    field?.name,
                  )}
                  isServiceMode={isServiceMode}
                  disabled={computeDisableField(field, serviceParams)}
                />
              </div>
            ) : (
              <></>
            ),
          )}
      </fieldset>
    </div>
  );
};

export default ServiceDialogFormView;
