import React, { FC, ReactElement } from 'react';
import { createPortal } from 'react-dom';

interface HeaderButtonPortalInterfaceProps {
  button: ReactElement;
}

const HeaderButtonPortal: FC<HeaderButtonPortalInterfaceProps> = props => {
  const { button } = props;
  const buttonContainer =
    typeof document !== 'undefined'
      ? document.getElementById('reactAdminHeaderButtonContainer')
      : null;

  return createPortal(button, buttonContainer as Element);
};

export default HeaderButtonPortal;
