import React, { ReactElement, ReactNode } from 'react';
import clsx from 'classnames';

import { ListContainerInterface } from './list-container.type';
import { useStyles } from './list-container.style';
import Box from '@material-ui/core/Box/Box';
import { isEmptyObject } from '../../../helper/data-helper';

const ListContainerView = (props: ListContainerInterface): ReactElement => {
  const {
    renderRow,
    data,
    loading,
    containerStyle,
    ListFooterComponent,
    ListEmptyComponent,
    className,
  } = props;
  const classes = useStyles();

  /**
   * @function getEmptyContainer
   * @param { any } ListEmptyComponent
   * @returns { ReactElement | null }
   */
  const getEmptyContainer = (ListEmptyComponent): ReactElement | null => {
    if (ListEmptyComponent)
      return React.isValidElement(ListEmptyComponent) ? (
        ListEmptyComponent
      ) : (
        <ListEmptyComponent />
      );
    return null;
  };

  /**
   * @function getFooterContainer
   * @param ListFooterComponent
   * @returns { ReactNode | null }
   */
  const getFooterContainer = (ListFooterComponent): ReactNode | null => {
    if (ListFooterComponent)
      return React.isValidElement(ListFooterComponent) ? (
        ListFooterComponent
      ) : (
        <ListFooterComponent />
      );
    return null;
  };

  return (
    <Box
      className={clsx(classes.container, className)}
      style={{ ...containerStyle }}
      flex={1}
    >
      {isEmptyObject(data) || loading
        ? getEmptyContainer(ListEmptyComponent)
        : data?.map((item: unknown, index: number) => (
            <Box key={'list-item-' + index}>{renderRow(item, index)}</Box>
          ))}
      {getFooterContainer(ListFooterComponent)}
    </Box>
  );
};

export default ListContainerView;
