import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { WMSFormViewProps } from './wms-form.type';

export const useStyles = makeStyles<Theme, WMSFormViewProps>(theme => ({
  groupContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  simpleForm: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: props => (props.fullHeight ? 'unset' : '50vh'),
    height: props => (props.fullHeight ? '100%' : 'auto'),
  },

  formRow: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: 35,
      marginBottom: 10,
    },

    [theme.breakpoints.down('sm')]: {
      marginBottom: 5,
    },
  },

  toolbar: {
    display: 'none',
  },
}));
