import React, { FC, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import { crudGetList as crudGetListAction, Identifier } from 'react-admin';

export interface CartDataListContainerChildProps {
  basketResource: string;
  basketIds: Identifier[];
  basketData: { [key: string]: object };
}

interface CartDataListContainerProps {
  basketResource: string;
  children: (props: CartDataListContainerChildProps) => ReactElement;
  crudGetList: Function;
  basketIds?: Identifier[];
  basketData?: { [key: string]: object };
}

const CartDataListContainer: FC<CartDataListContainerProps> = props => {
  const {
    children,
    basketIds = [],
    basketData = {},
    crudGetList,
    basketResource,
    ...rest
  } = props;

  useEffect(() => {
    // get list based on basket resource
    crudGetList(
      basketResource,
      { page: 1, perPage: 100000 },
      { field: 'createdate', order: 'DESC' },
    );
  }, [basketResource]);

  return children({
    ...rest,
    basketResource,
    basketIds,
    basketData,
  });
};

const mapStateToProps = (state, { basketResource }) => ({
  basketData: lodashGet(state, ['admin', 'resources', basketResource, 'data']),
  basketIds: state.admin.resources[basketResource].list.ids,
});

const mapDispatchToProps = {
  crudGetList: crudGetListAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartDataListContainer);
