import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({});

function DailyIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M19,16.5 L19,6.5 C19,5.673 18.327,5 17.5,5 L6.5,5 C5.673,5 5,5.673 5,6.5 L5,16.5 C5,17.327 5.673,18 6.5,18 L17.5,18 C18.327,18 19,17.327 19,16.5 Z M6.5,4 L17.5,4 C18.878,4 20,5.121 20,6.5 L20,16.5 C20,17.879 18.878,19 17.5,19 L6.5,19 C5.122,19 4,17.879 4,16.5 L4,6.5 C4,5.121 5.122,4 6.5,4 Z M8,15 L8,8 L9,8 L9,15 L8,15 Z"></path>
    </SvgIcon>
  );
}

export default withStyles(styles)(DailyIcon);
