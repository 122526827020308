import { GET_MANY_REFERENCE, GET_LIST } from 'react-admin';
import lodashFind from 'lodash/find';

import { getMetaFromApi } from '../../container/NewMetaContext';
import { clone, isEmpty, isEmptyObject } from '../../helper/data-helper';
import {
  RequestParametersInterface,
  actorSetActionValue,
  actorGetActionValue,
  actorDispatch,
  GetListRequestParametersInterface,
  FormKeyMode,
  RecordKeyMode,
  SortOrderType,
} from '../../type/actor-setup';
import {
  getDefaultSort,
  getFieldsById,
  getGridColumns,
  getPrimaryField,
  prepareReportFilter,
} from '../../helper/MetaHelper';

import type {
  RelationPermissions,
  RelationInMetaData,
  RequestRelationData,
} from './relation-panel.type';
import type { FieldType, GeneralMetaData, MetaData } from '../../helper/Types';
import { getAppSettings } from '../../helper/settings-helper';
import { CONFIG_LIST_PER_PAGE, CONFIG_LIST_SORT } from '../../core/configProvider';
import {
  CrudGetListApiPayload,
  CrudGetManyReferenceApiPayload,
} from '../../type/data-provider';
import { getRootTableId } from '../../helper/meta-helper';
import { customRecordNotePin } from './note-relation/create-edit-note/consistent';

export const KEY_SCROLL_TO = 'scrollTo_';

/**
 * get meta data
 * @function getRelationMetaData
 * @param {string} relationResource
 * @returns {Promise<Record<string, GeneralMetaData> | null>}
 */
export const getRelationMetaData = async (
  relationResource: string,
  neededResources: Array<string> | null = null,
  disableNotification = false,
): Promise<Record<string, GeneralMetaData>> => {
  const metaData: Record<string, GeneralMetaData> = {};
  try {
    const allMetaData = await getMetaFromApi(relationResource);

    if (neededResources) {
      neededResources.forEach(resource => {
        metaData[resource] = allMetaData.find(item => item.name === resource)?.meta;
      });
    } else {
      metaData[relationResource] = allMetaData.find(
        item => item.name === relationResource,
      )?.meta;
    }
  } catch (error) {
    if (!disableNotification) {
      actorDispatch('notification', { message: error, type: 'error' });
    }
    throw error;
  }
  return metaData;
};

/**
 * check permissions
 * @function checkPermission
 * @param {boolean | null} permissionType
 * @param {MetaData} relationMetaData
 * @param {string} relationPermissionType
 * @param {boolean} parentRecordIsEditable
 * @returns {boolean}
 */
export const checkPermission = (
  permissionType: boolean | null,
  relationMetaData: MetaData,
  relationPermissionType: 'allowAdd' | 'allowEdit' | 'allowDelete',
  parentRecordIsEditable: boolean,
): boolean => {
  if (!parentRecordIsEditable) return false;

  if (permissionType !== null) {
    return permissionType;
  }

  if (!isEmptyObject(relationMetaData)) {
    return (relationMetaData as GeneralMetaData)?.config?.[relationPermissionType];
  }

  return true;
};

/**
 * check relation permissions
 * @function preparedRelationPermission
 * @param {MetaData} parentMetaData
 * @param {Object} parentRecord
 * @param {Object} relationItemInMetaData
 * @param {Object} relationMetaData
 * @param {boolean} parentRecordIsEditable
 * @returns {Object}
 */
export const preparedRelationPermission = (
  parentMetaData: MetaData,
  parentRecord: Record<string, unknown>,
  relationItemInMetaData: RelationInMetaData,
  relationMetaData: MetaData | null,
  parentRecordIsEditable: boolean,
): RelationPermissions => {
  if (!relationMetaData) {
    return {
      hasCreate: false,
      hasEdit: false,
      hasDelete: false,
      disabledFieldList: null,
    };
  }

  let allowAdd: boolean | null = null;
  let allowEdit: boolean | null = null;
  let allowDelete: boolean | null = null;
  const disabledFieldList: Record<string, boolean> = {};

  const parentProcessInformation = {
    processuniqueid: parentRecord?.__processuniqueid as string,
    positionid: parentRecord?.positionid as string,
    stateid: parentRecord?.stateid as string,
  };

  const processDetailsInParentMetaData = lodashFind(
    (parentMetaData as GeneralMetaData).processes,
    {
      uniqueId: parentProcessInformation.processuniqueid,
    },
  );

  const currentTaskDetailsInParentProcessDetails = lodashFind(
    processDetailsInParentMetaData?.tasks,
    {
      positionId: parseInt(parentProcessInformation.positionid),
      stateId: parseInt(parentProcessInformation.stateid),
    },
  );

  const deactiveSubPanelsInCurrentTask = lodashFind(
    currentTaskDetailsInParentProcessDetails?.deactiveSubpanels,
    {
      moduleName: relationItemInMetaData.moduleName,
      moduleTableName: relationItemInMetaData.moduleTableName,
    },
  );

  if (deactiveSubPanelsInCurrentTask) {
    allowAdd = !deactiveSubPanelsInCurrentTask.disableAdd;
    allowEdit = !deactiveSubPanelsInCurrentTask.disableEdit;
    allowDelete = !deactiveSubPanelsInCurrentTask.disableDelete;

    for (const fieldId of currentTaskDetailsInParentProcessDetails?.deActiveFields ??
      []) {
      disabledFieldList[fieldId] = true;
    }
  }

  //fixme check carefully
  const preparedPermissions = {
    hasCreate: checkPermission(
      allowAdd,
      relationMetaData,
      'allowAdd',
      parentRecordIsEditable,
    ),
    hasEdit: checkPermission(
      allowEdit,
      relationMetaData,
      'allowEdit',
      parentRecordIsEditable,
    ),
    hasDelete: checkPermission(
      allowDelete,
      relationMetaData,
      'allowDelete',
      parentRecordIsEditable,
    ),
    disabledFieldList,
  };

  return preparedPermissions;
};

/**
 * Prepare relation grid columns
 * @function getFieldsForDisplay
 * @param {number[] | null} defaultSelected default columns
 * @param {number[] | null} userSelected user selected columns in setting
 * @param {object} relationMetaData
 * @param {number[] | null} disabledFieldList disabled columns
 * @returns {object[]}
 */
export const getFieldsForDisplay = (
  defaultSelected: number[] | null,
  userSelected: number[] | null,
  relationMetaData: GeneralMetaData,
  disabledFieldList?: { [x: number]: boolean } | null,
): Array<FieldType> => {
  let fieldList: FieldType[] = [];
  let gridColumns = getGridColumns({
    metaData: relationMetaData,
    filterFirstFive: false,
    isRelation: true,
  });

  // if user has selected column order
  if (userSelected && userSelected.length) {
    fieldList = getFieldsById(relationMetaData, userSelected);
  }
  // or admin as selected default order
  else if (defaultSelected && defaultSelected.length) {
    fieldList = getFieldsById(relationMetaData, defaultSelected);
  } else if (relationMetaData?.reportId) {
    fieldList = getGridColumns({
      metaData: relationMetaData,
      filterFirstFive: true,
      isRelation: true,
    });
  }

  if (!fieldList.length) {
    const primaryField = getPrimaryField(relationMetaData);
    gridColumns = getGridColumns({
      metaData: relationMetaData,
      filterFirstFive: true,
      isRelation: true,
    });
    let allFields = gridColumns;
    // in relations, we don't need to show primary field
    if (primaryField) {
      allFields =
        gridColumns && gridColumns.length
          ? gridColumns.filter(field => field.id !== primaryField.id)
          : [];
    }

    fieldList = [...allFields];
  }

  if (isEmptyObject(disabledFieldList)) {
    return fieldList;
  }

  if (fieldList?.length) {
    return clone(fieldList).map(field => {
      if (disabledFieldList![field.id]) {
        field.disabled = true;
      }

      return field;
    });
  }

  return [];
};

/**
 * compute if metadata allows execute
 * @function isReportExecutable
 * @param {GeneralMetaData} metaData
 * @returns {boolean}
 */
export const isReportExecutable = (metaData: GeneralMetaData): boolean => {
  return !!metaData?.executable;
};

/**
 * compute if metadata allows dit
 * @function isReportEditable
 * @param {GeneralMetaData} metaData
 * @returns {boolean}
 */
export const isReportEditable = (metaData: GeneralMetaData): boolean => {
  return !!metaData?.editable;
};

/**
 * get relation data and put it in actor
 * @function requestRelationData
 * @param {string} resource
 * @param {string} parentIdentifier
 * @param {string} childFieldName
 * @param {GeneralMetaData | null} metaData
 * @param { Partial<RequestParametersInterface>} params
 * @returns {void} void
 */
export const requestRelationData: RequestRelationData = (
  resource,
  relationType,
  parentIdentifier,
  childFieldName,
  metaData,
  params = {},
  onSuccess = undefined,
  onFailure = undefined, // TODO: search this function and pass on failure everywhere it have been called
): void => {
  if (!resource || !metaData) return;

  let prevRequestParams = actorGetActionValue(
    'gridData',
    `${resource}.requestParameters`,
  ) as unknown as RequestParametersInterface | GetListRequestParametersInterface;

  if (!prevRequestParams) {
    let defaultRequestParameters:
      | RequestParametersInterface
      | GetListRequestParametersInterface;
    // get user config
    const userChooseSort = getAppSettings(`${CONFIG_LIST_SORT}_${resource}`, true)
      .value as {
      field: string;
      order: 'desc' | 'asc';
    } | null;
    const perPagePagination = getAppSettings(
      `${CONFIG_LIST_PER_PAGE}_${resource}`,
      true,
    ).value as {
      page: number;
      perPage: number;
    } | null;
    if (
      relationType === 'report' ||
      relationType === 'multiReport' ||
      relationType === 'note'
    ) {
      // get parent resource
      const currentResource = actorGetActionValue('resources')?.current.value;

      // get parent record from parent resource
      const parentRecord =
        actorGetActionValue('record', currentResource)?.[FormKeyMode.ROOT]?.[
          RecordKeyMode.FULL
        ] ?? {};
      // prepare report parameters
      defaultRequestParameters = {
        pagination: perPagePagination ?? { page: 1, perPage: 25 },
        sort:
          userChooseSort ??
          (getDefaultSort(metaData) || {
            field: null,
            order: null,
          }),

        filter: prepareReportFilter(metaData, parentRecord),
      };
    } else {
      let sortField: string | null = null;
      let sortType: SortOrderType | null = null;
      if (metaData?.config?.sort) {
        sortField = metaData.config.sort.fieldName;
        sortType = metaData.config.sort.type;
      }

      defaultRequestParameters = {
        pagination: perPagePagination ?? { page: 1, perPage: 100 },
        sort: userChooseSort ?? {
          field: sortField,
          order: sortType,
        },
        filter: {},
      };
    }

    prevRequestParams = defaultRequestParameters;

    actorSetActionValue('gridData', defaultRequestParameters, {
      path: `${resource}.requestParameters`,
    });
  }

  if (relationType === 'note') {
    params = {
      customRecordNotePin,
      IsPin: 0,
      KeyID: parentIdentifier,
      tableid: getRootTableId(),
    };
  }

  if (
    relationType === 'report' ||
    relationType === 'multiReport' ||
    relationType === 'note'
  ) {
    actorDispatch(
      'crudAction',
      {
        type: GET_LIST,
        entity: 'relation',
        resource,
        requestParameters: { ...prevRequestParams, ...params },
        onSuccess,
        onFailure,
      } as CrudGetListApiPayload,
      {
        disableDebounce: true,
      },
    );
  } else {
    actorDispatch(
      'crudAction',
      {
        type: GET_MANY_REFERENCE,
        entity: 'relation',
        requestParameters: { ...prevRequestParams, ...params },
        resource,
        id: parentIdentifier,
        target: childFieldName,
        onSuccess,
        onFailure,
      } as CrudGetManyReferenceApiPayload,
      {
        disableDebounce: true,
      },
    );
  }
};

/**
 * add fake ids to each record of an array by reference
 * @function addFakeIdsToEachRecordOfDataArrayByRefrence
 * @param {Array<Record<string, unknown>> | null}
 * @returns {void}
 */
export const addFakeIdsToEachRecordOfDataArrayByReference = (
  data: Array<Record<string, unknown>> | null,
): void => {
  if (Array.isArray(data)) {
    data.forEach((item, index) => {
      if (typeof item === 'object' && isEmpty(item.id)) {
        item.id = index.toString();
      }
    });
  }
};

/**
 * check if relation should be to refresh
 * @function relationShouldRefresh
 * @param {string} resource
 * @param {relationResource} relationResource
 * @returns boolean
 */
export const shouldRelationRefresh = (
  resource: string,
  relationResource: string,
): boolean => {
  const lastRequestId = actorGetActionValue(
    'gridData',
    `${relationResource}.lastRequestId`,
  )!;

  return (
    resource?.includes(relationResource) ||
    (resource === 'allRelations' && !isEmpty(lastRequestId))
  );
};
