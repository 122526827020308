import { FC, memo, useEffect, useState } from 'react';
import lodashFind from 'lodash/find';
import { ChatInterface, SelectedUserType } from '..';
import { actorGetActionValue, actorOnDispatch } from '../../../type/actor-setup';
import { ChatContentInterface } from './chat-content.type';
import ChatContentView from './chat-content.view';
import { isEmpty } from '../../../helper/data-helper';
import { handleCreateNewChat } from '../chat-section.helper';

const ChatContentController: FC<ChatContentInterface> = memo(props => {
  const { chatActionsHandler, currentUser } = props;
  const [selectedUser, setSelectedUser] = useState<SelectedUserType | null>(null);

  /**
   * when we search a user
   * and start to make a chat with him/her
   * but it's not existed in chatsData on sidebar
   * then we create one
   */
  useEffect(() => {
    if (selectedUser) {
      const currentChatsData = actorGetActionValue('chatsData')?.data;
      const existedChat = lodashFind(currentChatsData, [
        'personinfo_id',
        selectedUser.personinfo_id,
      ]);
      if (isEmpty(existedChat)) {
        const currentDate = new Date();

        const newChat = {
          chatdate: currentDate.toString(),
          chattext: '',
          personimage: selectedUser.personimage,
          personinfo_id: selectedUser.personinfo_id,
          personname: selectedUser.personname,
          sumnotseen: 0,
        } as ChatInterface;

        handleCreateNewChat(newChat);
      }
    }
  }, [selectedUser]);

  useEffect(() => {
    actorOnDispatch('selectedUser', selectedUser => {
      selectedUser !== undefined && setSelectedUser(selectedUser);
    });
  }, []);

  return (
    <ChatContentView
      chatActionsHandler={chatActionsHandler}
      selectedUser={selectedUser}
      currentUser={currentUser}
    />
  );
});

export default ChatContentController;
