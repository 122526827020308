import React, { ReactElement, useEffect, useState } from 'react';
import WMS from '../component/wms';

import LoadingBox from '../component/LoadingBox';
import { actorOnDispatch } from '../type/actor-setup';

const WMSPage = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    actorOnDispatch('showLoading', setIsLoading);
  }, []);

  if (isLoading) {
    return <LoadingBox />;
  }

  return <WMS />;
};

export default WMSPage;
