import React, {
  ChangeEvent,
  CSSProperties,
  FC,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useInput, useTranslate } from 'react-admin';
import ClearIcon from '@material-ui/icons/Clear';
import lodashGet from 'lodash/get';

import sanitizeRestProps from './sanitizeRestProps';
import { onChangeType } from '../dynamicInput/InputTypes';
import { isEmpty } from '../../helper/data-helper';
import { FieldType } from '../../helper/Types';

const DEFAULT_COLON = ':';
const DEFAULT_VALUE_SHORT = `00${DEFAULT_COLON}00`; // it will be like 00:00
const DEFAULT_VALUE_FULL = `00${DEFAULT_COLON}00${DEFAULT_COLON}00`; // it will be like 00:00:00

// declare interfaces
interface Props {
  value?: string;
  onChange?: onChangeType;
  onClick: Function;
  showSeconds?: boolean;
  colon?: string;
  style?: CSSProperties | {};
  disabled?: boolean;
  customError?: string;
  className: string;
  options: object;
  basePath?: string;
  label?: string;
  source: string;
  field: Partial<FieldType>;
  visibleClass: string;
}

const useStyles = makeStyles(theme => ({
  input: {
    '&[disabled]': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },
  },

  error: {
    '& + p': {
      position: 'absolute',
      right: 0,
    },
  },
}));

/**
 * it should findout that income value number or not
 * @param {string|number} value
 * @returns {boolean} true if number false if false
 */
export function isNumber<T>(value: T): boolean {
  const number = Number(value);

  return !isNaN(number) && String(value) === String(number);
}

/**
 * it should add 0 or 00 if value be empty or one digit
 * @param {string|number} value
 * @returns {string} formated value
 */
export function formatTimeItem(value?: string | number): string {
  return `${value || ''}00`.substr(0, 2);
}

/**
 * it should receve last time and new time
 * seprate hour, minute and second to diffrent variables
 * make each part formatted in tow digits and handle new position
 * then assemble all parts to a single clean string to show base of
 * show second prop.
 * @param {boolean} showSeconds it came from props
 * @param {string|number} value
 * @param {string|number} defaultValue
 * @param {string} colon declared on top of the page
 * @param {number} cursorPosition
 * @returns {Array} validatedValue is compelete formated value and newCursorPosition is last position in string has been changed
 */
export function validateTimeAndCursor(
  showSeconds = false,
  value = '',
  defaultValue = '',
  colon = DEFAULT_COLON,
  cursorPosition = 0,
): [string, number] {
  // cast new cursor position to number
  let newCursorPosition = Number(cursorPosition);

  // extract previous hour, minute and second from default value prop
  const [oldHour, oldMinute, oldSecond] = defaultValue.split(colon);

  // extract new hour, minute and second from value prop
  let [newHour, newMinute, newSecond] = String(value).split(colon);

  // formatting hour to currect format of show (tow digits)
  newHour = formatTimeItem(newHour);

  // seprate hour digits and cast to number
  const oldHourFirstDigit = Number(oldHour[0]);
  const newHourFirstDigit = Number(newHour[0]);
  const newHourSecondDigit = Number(newHour[1]);

  // making currect format of hour after new changes
  if (newHourFirstDigit > 2) {
    newHour = oldHour;
    newCursorPosition -= 1;
  } else if (newHourFirstDigit === 2) {
    if (oldHourFirstDigit === 2 && newHourSecondDigit > 3) {
      newHour = `2${oldHour[1]}`;
      newCursorPosition -= 2;
    } else if (newHourSecondDigit > 3) {
      newHour = '23';
    }
  }

  // formatting minute to currect format of show (tow digits)
  newMinute = formatTimeItem(newMinute);

  // seprate minute digits and cast to number
  const newMinuteFirstDigit = Number(newMinute[0]);

  // making currect format of minute after new changes
  if (newMinuteFirstDigit > 5) {
    newMinute = oldMinute;
    newCursorPosition -= 1;
  }

  // it should check if show second prop has true value should do these commands
  if (showSeconds) {
    // formatting second to currect format of show (tow digits)
    newSecond = formatTimeItem(newSecond);

    // seprate second digits and cast to number
    const newSecondFirstDigit = Number(newSecond[0]);

    // making currect format of second after new changes
    if (newSecondFirstDigit > 5) {
      newSecond = oldSecond;
      newCursorPosition -= 1;
    }
  }

  // assemble new time parts to a single formatted string
  const validatedValue = showSeconds
    ? `${newHour}${colon}${newMinute}${colon}${newSecond}`
    : `${newHour}${colon}${newMinute}`;

  return [validatedValue, newCursorPosition];
}

const TimeInput: FC<Props> = props => {
  const {
    showSeconds = false,
    style = {},
    colon = DEFAULT_COLON,
    disabled,
    className,
    options,
    basePath,
    label,
    source,
    field,
    visibleClass,
    customError,
    ...rest
  } = props;

  const [value, setValue] = useState<string>('');
  const [lastPosition, setLastPosition] = useState<any>();

  const defaultValue = showSeconds ? DEFAULT_VALUE_FULL : DEFAULT_VALUE_SHORT;
  const selectedColon = colon && colon.length === 1 ? colon : DEFAULT_COLON;
  const maxLength = defaultValue.length; // it will be 5 or 7

  const classes = useStyles();
  const translate = useTranslate();

  const {
    input,
    meta: { touched, error },
  } = useInput(props);

  // should change the value of state when user type sth
  useEffect(() => {
    const [validatedTime] = validateTimeAndCursor(
      showSeconds,
      input.value,
      defaultValue,
      selectedColon,
    );

    if (value && value !== validatedTime) {
      setValue(validatedTime); // set the new value of input
    }
  }, [input.value]);

  // for set default value
  useEffect(() => {
    // computing formatted time
    const [validatedTime] = validateTimeAndCursor(
      showSeconds,
      input.value,
      defaultValue,
      selectedColon,
    );

    if (!field.required && isEmpty(input.value)) {
      // it can be null
      setValue('');
    } else {
      // save computed data into an state
      setValue(validatedTime);
    }
  }, []);

  // for handle position
  useEffect(() => {
    if (lastPosition && lastPosition.input) {
      const inputEl = lastPosition.input;
      inputEl.selectionStart = lastPosition.position;
      inputEl.selectionEnd = lastPosition.position;
    }
  }, [lastPosition]);

  /**
   * this function should analyse the new character that recently has been changed and decide how to treat to it.
   * @function onInputChange
   * @param {ChangeEvent<HTMLInputElement>} event
   * @returns {void}
   */
  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const oldValue: string | null = value;
      const inputEl: HTMLInputElement = event.target;
      const inputValue: string | null = inputEl.value;
      const position: number = inputEl.selectionEnd || 0;
      const isTyped: boolean = inputValue.length > oldValue.length;
      const cursorCharacter: string = inputValue[position - 1];
      const addedCharacter: string | null = isTyped ? cursorCharacter : null;
      const removedCharacter: string | null = isTyped ? null : oldValue[position];
      const replacedSingleCharacter: string | null =
        inputValue.length === oldValue.length ? oldValue[position - 1] : null;

      let newValue = oldValue;
      let newPosition = position;

      // when user type a new character
      if (addedCharacter !== null) {
        // if it will more than max length

        if (position > maxLength) {
          // shoule set new position on position before typing for ignore what has been typed
          newPosition = maxLength;
        }

        // if its colon on prev colon position
        else if (
          (position === 3 || position === 6) &&
          addedCharacter === selectedColon
        ) {
          // compute new value
          newValue = `${inputValue.substr(
            0,
            position - 1,
          )}${selectedColon}${inputValue.substr(position + 1)}`;
        }

        //  if its number on prev colon position
        else if ((position === 3 || position === 6) && isNumber(addedCharacter)) {
          newValue = `${inputValue.substr(
            0,
            position - 1,
          )}${selectedColon}${addedCharacter}${inputValue.substr(position + 2)}`;
          newPosition = position + 1;
        }

        // if its number on not prev colon position
        else if (isNumber(addedCharacter)) {
          // user typed a number
          newValue =
            inputValue.substr(0, position - 1) +
            addedCharacter +
            inputValue.substr(position + 1);
          if (position === 2 || position === 5) {
            newPosition = position + 1;
          }
        }

        // if user typed NOT a number, then keep old value & position
        else {
          newPosition = position - 1;
        }
      }

      // when user change an exist character
      else if (replacedSingleCharacter !== null) {
        // user replaced only a single character
        if (isNumber(cursorCharacter)) {
          if (position === 3 || position === 6) {
            newValue = `${inputValue.substr(
              0,
              position - 1,
            )}${selectedColon}${inputValue.substr(position)}`;
          } else {
            newValue = inputValue;
          }
        }

        // user replaced a number on some non-number character
        else {
          newValue = oldValue;
          newPosition = position - 1;
        }
      }

      // when user type a NAN character
      else if (
        typeof cursorCharacter !== 'undefined' &&
        cursorCharacter !== selectedColon &&
        !isNumber(cursorCharacter)
      ) {
        // set of characters replaced by non-number (ignore it)
        newValue = oldValue;
        newPosition = position - 1;
      }

      // if user remove a colon
      else if (removedCharacter !== null) {
        if (
          (position === 2 || position === 5) &&
          removedCharacter === selectedColon
        ) {
          // reset cocon
          newValue = `${inputValue.substr(
            0,
            position - 1,
          )}0${selectedColon}${inputValue.substr(position)}`;
          newPosition = position - 1;
        }

        // if user removed a number
        else {
          // replace it with 0
          newValue = `${inputValue.substr(0, position)}0${inputValue.substr(
            position,
          )}`;
        }
      }

      const [validatedTime, validatedCursorPosition] = validateTimeAndCursor(
        showSeconds,
        newValue,
        oldValue,
        selectedColon,
        newPosition,
      );

      setLastPosition({ input: inputEl, position: validatedCursorPosition });
      setValue(validatedTime);

      props.onChange && props.onChange(event, validatedTime);
      input.onChange(validatedTime);
      event.persist();
    },
    [props.onChange, input.onChange],
  );

  const inputPropsParams = useMemo(
    (): object => ({
      classes: {
        error: classes.error,
        input: classes.input,
      },
    }),
    [],
  );

  /**
   *  it will clear input value
   *  @function apiErrorHandler
   *  @returns {void}
   */
  const clearInput = () => {
    setValue('');
    input.onChange('');
  };

  const hasError = !!(touched && error);

  return (
    <TextField
      {...options}
      {...input}
      {...sanitizeRestProps(rest as any)}
      error={!!customError || hasError}
      helperText={
        customError
          ? customError
          : hasError
          ? translate(lodashGet(error, 'message', error))
          : undefined
      }
      required={field.required}
      margin="normal"
      className={`${visibleClass} ${className}`}
      InputProps={{
        ...inputPropsParams,
        endAdornment: field.required ? null : (
          <InputAdornment position="end">
            <IconButton onClick={clearInput} edge="end">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={{
        'data-test-input-name': props.source,
        maxLength: field.maxLength,
      }}
      onChange={onInputChange}
      variant="outlined"
      disabled={disabled}
      value={value}
      label={props.label}
      data-test-has-error={!!customError || hasError}
    />
  );
};

export default TimeInput;
