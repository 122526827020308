import querystring from 'qs';
import { FC, memo } from 'react';

import { QuickEditButtonToolbarInterface } from './quick-edit-button-toolbar.type';
import QuickEditButtonToolbarView from './quick-edit-button-toolbar.view';
import { push as redirectToPage } from 'connected-react-router';
import { FormActions, ExtraParamsInterface } from '../../form/form.type';
import { useDispatch } from 'react-redux';

const QuickEditButtonToolbarController: FC<QuickEditButtonToolbarInterface> = memo(
  props => {
    const { closeDialog, resource, id, redirect, formActionsHandler, loading } =
      props;
    const reduxDispatch = useDispatch();

    /**redirect to full form with data
     * @function redirectWithData
     * @return {void}
     */
    const redirectWithData: React.MouseEventHandler<
      HTMLButtonElement
    > = (): void => {
      closeDialog();
      reduxDispatch(
        redirectToPage(
          // TODO: fix querystring
          `/${resource}/${id}?${querystring.stringify({})}` +
            (redirect ? `&redirect=${redirect}` : ''),
        ),
      );
    };

    /** submit form
     * @function handleSubmit
     * @return {void}
     */
    const handleSubmit = (): void => {
      formActionsHandler?.(FormActions.Save, {
        isSaveAndNew: false,
        isSaveAndView: false,
        closeDialog,
      } as ExtraParamsInterface);
    };

    return (
      <QuickEditButtonToolbarView
        redirectWithData={redirectWithData}
        closeDialog={closeDialog}
        handleSubmit={handleSubmit}
        loading={loading}
      />
    );
  },
);

export default QuickEditButtonToolbarController;
