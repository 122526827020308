import { FC } from 'react';

import { TableRelation } from './table-relation';
import { NoteRelation } from './note-relation';
import { MultiTabTableRelation } from './multi-tab-table-relation';

import type { RelationPanelViewInterface } from './relation-panel.type';

const relationViews = {
  table: TableRelation,
  oneToOneTable: TableRelation, // fixMe: complete this type in another card
  report: TableRelation,
  multiReport: MultiTabTableRelation,
  note: NoteRelation,
};

const RelationPanelView: FC<RelationPanelViewInterface> = props => {
  const { relationType, relationPanelBaseProps } = props;

  const ViewComponent = relationViews[relationType];

  return (
    <div>
      <ViewComponent relationPanelBaseProps={relationPanelBaseProps} />
    </div>
  );
};

export default RelationPanelView;
