import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';
import BulkDeleteButton from './BulkDeleteButton';
import ServiceButtonContainer from './ServiceButtonContainer';
import PrintButtonContainer from './PrintButtonContainer';
import MetaProvider from './MetaProvider';

class ListBulkActions extends PureComponent {
  render() {
    const { translate, locale, resource, hasDelete, ...rest } = this.props;

    return (
      <Fragment>
        <PrintButtonContainer {...rest} locale={locale} resource={resource} />

        <MetaProvider resourceName={resource}>
          <ServiceButtonContainer {...rest} locale={locale} resource={resource} />
        </MetaProvider>

        {hasDelete && <BulkDeleteButton {...rest} resource={resource} />}
      </Fragment>
    );
  }
}

ListBulkActions.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default translate(ListBulkActions);
